import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import CCardGamesView from '../CCardGamesView';
import { AuthData } from '~/Dialogs/DlgConnect';
import { Platform, Pressable, Image } from 'react-native';
import { AuthRequestConfig, DiscoveryDocument, makeRedirectUri, useAuthRequest } from 'expo-auth-session';
import config from '~/config';
import GlobalVariables from '../GlobalVariables';
import { SvgUri } from 'react-native-svg';
import GlobalConstants from '../GlobalConstants';

WebBrowser.maybeCompleteAuthSession();

type Props = {
    cCardGamesViewRef: CCardGamesView;
};

const discovery: DiscoveryDocument = {
    authorizationEndpoint: 'https://www.facebook.com/v6.0/dialog/oauth',
    tokenEndpoint: 'https://graph.facebook.com/v6.0/oauth/access_token',
};

const configFB: AuthRequestConfig = {
    clientId: `${config.FACEBOOK_LOGIN}`,
    scopes: ['public_profile', 'email'],
    redirectUri: makeRedirectUri({
        native: `fb${config.FACEBOOK_LOGIN}://authorize`,
    }),
    extraParams: {
        display: Platform.select({ web: 'popup' })!,
    },
    responseType: 'token',
};

export default function FacebookLogin({ cCardGamesViewRef }: Props) {
    const [, response, promptAsync] = useAuthRequest(configFB, discovery);
    const [uid, setUid] = React.useState('null');

    React.useEffect(() => {
        async function fetchData() {
            let suid = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_facebook_id', null);
            setUid(suid);
        }
        fetchData();
    }, [cCardGamesViewRef.m_main]);

    React.useEffect(() => {
        if (response?.type === 'success') {
            let accessToken = response.params;
            console.log('access_token', accessToken, response);
            async function fetchData() {
                if (accessToken && cCardGamesViewRef) {
                    fetch(
                        'https://graph.facebook.com/v6.0/me?fields=email,first_name,last_name,picture.type(large)&access_token=' +
                            accessToken.access_token
                    )
                        .then((fbResponse) => fbResponse.json())
                        .then((fbData) => {
                            let data: AuthData = {
                                email: fbData.email,
                                fname: fbData.first_name,
                                lname: fbData.last_name,
                                image: fbData.picture ? fbData.picture.data.url : '-',
                                uid: fbData.id,
                            };
                            if (data.uid) {
                                cCardGamesViewRef.m_con.loginWithAuthData(data);
                                if (cCardGamesViewRef.m_con.m_save) {
                                    setUid(data.uid);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_email', data.email);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_fname', data.fname);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_lname', data.lname);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_picture', data.image);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_id', data.uid);
                                } else {
                                    setUid('null');
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_email', null);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_fname', null);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_lname', null);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_picture', null);
                                    cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_id', null);
                                }
                            }
                        })
                        .catch((e) => {
                            console.error('ERROR GETTING DATA FROM FACEBOOK', e);
                        });
                }
            }
            fetchData();
        }
    }, [cCardGamesViewRef, response]);

    async function deleteAuthData() {
        setUid('null');
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_email', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_fname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_lname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_picture', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_facebook_id', null);
    }

    async function signinwithFacebook() {
        console.log('signinwithFacebook');
        if (uid !== null && uid !== 'null') {
            let email: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_facebook_email', null);
            let fname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_facebook_fname', null);
            let lname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_facebook_lname', null);
            let image: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_facebook_picture', null);
            let data: AuthData = {
                email: email,
                fname: fname,
                lname: lname,
                image: image,
                uid: uid,
            };
            console.log('data', data);
            cCardGamesViewRef.m_con.loginWithAuthData(data);
            if (!cCardGamesViewRef.m_con.m_save) {
                deleteAuthData();
            }
        } else {
            promptAsync();
        }
    }

    return (
        <Pressable
            onPress={() => {
                signinwithFacebook();
            }}
            onLongPress={() => {
                deleteAuthData();
            }}
        >
            {Platform.OS === 'web' ? (
                <Image
                    source={require('../../../assets/Images/HomeScreen/Facebook.svg')}
                    style={{
                        width: 44 * GlobalVariables.scale,
                        height: 44 * GlobalVariables.scale,
                        marginHorizontal: 10 * GlobalVariables.scale,
                        resizeMode: 'stretch',
                    }}
                />
            ) : (
                <SvgUri
                    style={{
                        width: 44 * GlobalVariables.scale,
                        height: 44 * GlobalVariables.scale,
                        marginHorizontal: 10 * GlobalVariables.scale,
                    }}
                    uri={GlobalConstants.IMAGES_FOLDER + '/Images/HomeScreen/Facebook.svg'}
                />
            )}
        </Pressable>
    );
}
