import DlgRezultatB2P from '~/Dialogs/DlgRezultatB2P';
import CPoint from '../../cppComponents/CPoint';
import CBelot from './CBelot';
import React from 'react';
import { View } from 'react-native';
import CSize from '~/Components/cppComponents/CSize';
import CRect from '~/Components/cppComponents/CRect';
import GlobalVariables from '~/Components/GlobalVariables';
import GlobalConstants from '~/Components/GlobalConstants';

class CBelot2Players extends CBelot {
    m_rez = new DlgRezultatB2P(null);
    loading: boolean = true;

    Niama: boolean[] = [];
    LIz4istena: number[] = [];
    MVtoriPuska: number[] = [];

    Init2() {
        this.props.m_pwin.ShowInfoBar(true);
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        this.m_orez.m_zagubi = 0;
        this.m_orez.m_pobedi = 0;
        this.Players = 2;
        this.Init();
        this.pause = 0;
        for (let i: number = 1; i < 3; i++) {
            this.m_orez.Player[i] = this.Name[i];
        }
        this.m_orez.Players = 2;

        this.NamePos[1].x = 360;
        this.NamePos[1].y = 388;
        this.NamePos[2].x = 360;
        this.NamePos[2].y = 136;

        this.PosNaddavane[1].x = 340;
        this.PosNaddavane[1].y = 350;
        this.PosNaddavane[2].x = 340;
        this.PosNaddavane[2].y = 112;

        this.StrelkaPos[1].x = 550;
        this.StrelkaPos[1].y = 350;
        this.StrelkaPos[2].x = 220;
        this.StrelkaPos[2].y = 130;
        this.StrelkaPos[3].x = 220;
        this.StrelkaPos[3].y = 130;

        this.PosBelot[1].x = 330;
        this.PosBelot[1].y = 368;
        this.PosBelot[2].x = 330;
        this.PosBelot[2].y = 112;

        this.PosTerci[1][0].x = 389;
        this.PosTerci[1][0].y = 368;
        this.PosTerci[1][1].x = 446;
        this.PosTerci[1][1].y = 368;
        this.PosTerci[2][0].x = 389;
        this.PosTerci[2][0].y = 112;
        this.PosTerci[2][1].x = 446;
        this.PosTerci[2][1].y = 112;

        this.CardsPos[0].x = 100;
        this.CardsPos[0].y = 190;

        this.CardsPos[1].x = 167;
        this.CardsPos[1].y = 418;
        this.CardsPos[2].x = 224;
        this.CardsPos[2].y = 418;
        this.CardsPos[3].x = 281;
        this.CardsPos[3].y = 418;
        this.CardsPos[4].x = 338;
        this.CardsPos[4].y = 418;
        this.CardsPos[5].x = 395;
        this.CardsPos[5].y = 418;
        this.CardsPos[6].x = 452;
        this.CardsPos[6].y = 418;
        this.CardsPos[7].x = 509;
        this.CardsPos[7].y = 418;
        this.CardsPos[8].x = 566;
        this.CardsPos[8].y = 418;

        this.CardsPos[9].x = 505;
        this.CardsPos[9].y = 22;
        this.CardsPos[10].x = 465;
        this.CardsPos[10].y = 22;
        this.CardsPos[11].x = 425;
        this.CardsPos[11].y = 22;
        this.CardsPos[12].x = 385;
        this.CardsPos[12].y = 22;
        this.CardsPos[13].x = 345;
        this.CardsPos[13].y = 22;
        this.CardsPos[14].x = 305;
        this.CardsPos[14].y = 22;
        this.CardsPos[15].x = 265;
        this.CardsPos[15].y = 22;
        this.CardsPos[16].x = 225;
        this.CardsPos[16].y = 22;

        this.CardsPos[33].x = 360;
        this.CardsPos[33].y = 270;
        this.CardsPos[34].x = 360;
        this.CardsPos[34].y = 170;
    }

    async InitGame2() {
        this.op_start = false;
        for (let i: number = 1; i < 9; i++) this.vz[i] = 0;
        for (let i: number = 1; i < 17; i++) this.kd[i] = 0;
        this.m_rez.m_rez1 = 0;
        this.m_rez.m_rez2 = 0;
        this.m_rez.m_bel1 = '';
        this.m_rez.m_bel2 = '';
        this.m_rez.m_ter1pl1 = '';
        this.m_rez.m_ter2pl1 = '';
        this.m_rez.m_ter1pl2 = '';
        this.m_rez.m_ter2pl2 = '';
        if ((this.OP && this.Server) || this.SP) {
            if (this.KoiZapochva === 1) this.KoiZapochva = 2;
            else this.KoiZapochva = 1;
        }
        //
        for (let i: number = 0; i < 5; i++) {
            this.Niama[i] = false;
            this.LIz4istena[i] = 0;
            this.MVtoriPuska[i] = 200;
        }
        this.InitGame();
        for (let i: number = 0; i < 25; i++) {
            this.k[i] = 0;
            this.CardsPoint[i] = this.CardsPos[0];
        }
        this.P1puska = 0;
        this.P2puska = 0;
        this.strelka = 0;
        this.Naddavane = 0;
        if ((this.OP && this.Server) || this.SP) this.RandCards();
        if ((this.OP && this.Server) || this.SP) {
            this.LoadCards(false, true);
        }
        this.open_rez_dlg = false;
        this.open_orez_dlg = false;
        if (this.OP) {
            if (!this.watch) this.SendGame('$|y');
        }
        for (let i: number = 1; i <= 2; i++) {
            if (this.last_Name[i] === '') this.last_Name[i] = this.Name[i];
        }
    }

    RandCards() {
        let Numbers: number[] = [];
        for (let i: number = 0; i < 24; i++) {
            Numbers[i] = i + 1;
        }
        let randomArray = this.randomShuffle(Numbers);
        for (let i: number = 1; i < 25; i++) {
            if (randomArray[i - 1] === 1) {
                this.k[i] = 13;
            }
            if (randomArray[i - 1] === 2) {
                this.k[i] = 14;
            }
            if (randomArray[i - 1] === 3) {
                this.k[i] = 15;
            }
            if (randomArray[i - 1] === 4) {
                this.k[i] = 16;
            }
            if (randomArray[i - 1] === 5) {
                this.k[i] = 17;
            }
            if (randomArray[i - 1] === 6) {
                this.k[i] = 18;
            }

            if (randomArray[i - 1] === 7) {
                this.k[i] = 23;
            }
            if (randomArray[i - 1] === 8) {
                this.k[i] = 24;
            }
            if (randomArray[i - 1] === 9) {
                this.k[i] = 25;
            }
            if (randomArray[i - 1] === 10) {
                this.k[i] = 26;
            }
            if (randomArray[i - 1] === 11) {
                this.k[i] = 27;
            }
            if (randomArray[i - 1] === 12) {
                this.k[i] = 28;
            }

            if (randomArray[i - 1] === 13) {
                this.k[i] = 33;
            }
            if (randomArray[i - 1] === 14) {
                this.k[i] = 34;
            }
            if (randomArray[i - 1] === 15) {
                this.k[i] = 35;
            }
            if (randomArray[i - 1] === 16) {
                this.k[i] = 36;
            }
            if (randomArray[i - 1] === 17) {
                this.k[i] = 37;
            }
            if (randomArray[i - 1] === 18) {
                this.k[i] = 38;
            }

            if (randomArray[i - 1] === 19) {
                this.k[i] = 43;
            }
            if (randomArray[i - 1] === 20) {
                this.k[i] = 44;
            }
            if (randomArray[i - 1] === 21) {
                this.k[i] = 45;
            }
            if (randomArray[i - 1] === 22) {
                this.k[i] = 46;
            }
            if (randomArray[i - 1] === 23) {
                this.k[i] = 47;
            }
            if (randomArray[i - 1] === 24) {
                this.k[i] = 48;
            }
        }
    }

    async InitOP(server: boolean) {
        this.Server = server;
        this.OP = true;
        this.SP = false;
        this.Init2();
        await this.InitGame2();
        this.InitTimer(20);
    }

    async InitRezDlg() {
        if (this.watch) {
            this.Faza = 6;
        }
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            this.m_rez.m_name1 = this.Name[1];
            this.m_rez.m_name2 = this.Name[2];
            if (this.Belot[1][0] === 1) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 20;
                this.m_rez.m_bel1 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[1][0] === 2) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 40;
                this.m_rez.m_bel1 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[1][0] === 3) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 60;
                this.m_rez.m_bel1 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[1][0] === 4) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 80;
                this.m_rez.m_bel1 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 1) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 20;
                this.m_rez.m_bel2 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[2][0] === 2) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 40;
                this.m_rez.m_bel2 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 3) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 60;
                this.m_rez.m_bel2 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 4) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 80;
                this.m_rez.m_bel2 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Naddavane !== 5) {
                this.m_rez.m_ter1pl1 = this.StringTerci(this.PTerci[1][0]);
                this.m_rez.m_ter2pl1 = this.StringTerci(this.PTerci[1][1]);
                this.m_rez.m_ter1pl2 = this.StringTerci(this.PTerci[2][0]);
                this.m_rez.m_ter2pl2 = this.StringTerci(this.PTerci[2][1]);
                if (
                    (this.PTerci[1][0] > 30 && this.PTerci[1][0] > this.PTerci[2][0] && this.PTerci[1][0] > this.PTerci[2][1]) ||
                    (this.PTerci[1][1] > 30 && this.PTerci[1][1] > this.PTerci[2][0] && this.PTerci[1][1] > this.PTerci[2][1])
                ) {
                    if (this.PTerci[1][0] > 30) {
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + this.PointTerci(this.PTerci[1][0]);
                        this.m_rez.dis[1][0] = true;
                    }
                    if (this.PTerci[1][1] > 30) {
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + this.PointTerci(this.PTerci[1][1]);
                        this.m_rez.dis[1][1] = true;
                    }
                    if (this.PTerci[2][0] > 30) this.m_rez.dis[2][0] = false;
                    if (this.PTerci[2][1] > 30) this.m_rez.dis[2][1] = false;
                }
                if (
                    (this.PTerci[1][0] > 0 &&
                        this.PTerci[1][0] < 30 &&
                        (this.PTerci[1][0] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[1][0] >= this.PTerci[2][1] || this.PTerci[2][1] > 30)) ||
                    (this.PTerci[1][1] > 0 &&
                        this.PTerci[1][1] < 30 &&
                        (this.PTerci[1][1] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[1][1] >= this.PTerci[2][1] || this.PTerci[2][1] > 30))
                ) {
                    if (
                        (this.PTerci[1][0] > 0 &&
                            this.PTerci[1][0] < 30 &&
                            (this.PTerci[1][0] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[1][0] > this.PTerci[2][1] || this.PTerci[2][1] > 30)) ||
                        (this.PTerci[1][1] > 0 &&
                            this.PTerci[1][1] < 30 &&
                            (this.PTerci[1][1] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[1][1] > this.PTerci[2][1] || this.PTerci[2][1] > 30))
                    ) {
                        if (this.PTerci[1][0] > 0 && this.PTerci[1][0] < 30) {
                            this.m_rez.m_rez1 = this.m_rez.m_rez1 + this.PointTerci(this.PTerci[1][0]);
                            this.m_rez.dis[1][0] = true;
                        }
                        if (this.PTerci[1][1] > 0 && this.PTerci[1][1] < 30) {
                            this.m_rez.m_rez1 = this.m_rez.m_rez1 + this.PointTerci(this.PTerci[1][1]);
                            this.m_rez.dis[1][1] = true;
                        }
                    }
                    if (this.PTerci[2][0] > 0 && this.PTerci[2][0] < 30) this.m_rez.dis[2][0] = false;
                    if (this.PTerci[2][1] > 0 && this.PTerci[2][1] < 30) this.m_rez.dis[2][1] = false;
                }
                if (
                    (this.PTerci[2][0] > 30 && this.PTerci[2][0] >= this.PTerci[1][0] && this.PTerci[2][0] >= this.PTerci[1][1]) ||
                    (this.PTerci[2][1] > 30 && this.PTerci[2][1] >= this.PTerci[1][0] && this.PTerci[2][1] >= this.PTerci[1][1])
                ) {
                    if (this.PTerci[2][0] > 30) {
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + this.PointTerci(this.PTerci[2][0]);
                        this.m_rez.dis[2][0] = true;
                    }
                    if (this.PTerci[2][1] > 30) {
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + this.PointTerci(this.PTerci[2][1]);
                        this.m_rez.dis[2][1] = true;
                    }
                    if (this.PTerci[1][0] > 30) this.m_rez.dis[1][0] = false;
                    if (this.PTerci[1][1] > 30) this.m_rez.dis[1][1] = false;
                }
                if (
                    (this.PTerci[2][0] > 0 &&
                        this.PTerci[2][0] < 30 &&
                        (this.PTerci[2][0] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[2][0] >= this.PTerci[1][1] || this.PTerci[1][1] > 30)) ||
                    (this.PTerci[2][1] > 0 &&
                        this.PTerci[2][1] < 30 &&
                        (this.PTerci[2][1] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[2][1] >= this.PTerci[1][1] || this.PTerci[1][1] > 30))
                ) {
                    if (
                        (this.PTerci[2][0] > 0 &&
                            this.PTerci[2][0] < 30 &&
                            (this.PTerci[2][0] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[2][0] > this.PTerci[1][1] || this.PTerci[1][1] > 30)) ||
                        (this.PTerci[2][1] > 0 &&
                            this.PTerci[2][1] < 30 &&
                            (this.PTerci[2][1] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[2][1] > this.PTerci[1][1] || this.PTerci[1][1] > 30))
                    ) {
                        if (this.PTerci[2][0] > 0 && this.PTerci[2][0] < 30) {
                            this.m_rez.m_rez2 = this.m_rez.m_rez2 + this.PointTerci(this.PTerci[2][0]);
                            this.m_rez.dis[2][0] = true;
                        }
                        if (this.PTerci[2][1] > 0 && this.PTerci[2][1] < 30) {
                            this.m_rez.m_rez2 = this.m_rez.m_rez2 + this.PointTerci(this.PTerci[2][1]);
                            this.m_rez.dis[2][1] = true;
                        }
                    }
                    if (this.PTerci[1][0] > 0 && this.PTerci[1][0] < 30) this.m_rez.dis[1][0] = false;
                    if (this.PTerci[1][1] > 0 && this.PTerci[1][1] < 30) this.m_rez.dis[1][1] = false;
                }
            }
            this.m_rez.m_info = '';
            if (this.Naddavane < 5) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true) {
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true) {
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Kontra === 1) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 2 * ((this.m_rez.m_rez2 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = 2 * ((this.m_rez.m_rez2 + 5) / 10);
                            else this.m_rez.m_crez2 = (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Kontra === 2) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 2 * ((this.m_rez.m_rez1 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = 2 * ((this.m_rez.m_rez1 + 5) / 10);
                            else this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 4 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 4 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Rekontra === 1) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 4 * ((this.m_rez.m_rez2 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = 4 * ((this.m_rez.m_rez2 + 5) / 10);
                            else this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Rekontra === 2) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 4 * ((this.m_rez.m_rez1 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = 4 * ((this.m_rez.m_rez1 + 5) / 10);
                            else this.m_rez.m_crez1 = (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            if (this.Naddavane === 5) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true) {
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true) {
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez1 === this.m_rez.m_rez2) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Kontra === 1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Kontra === 2) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 4 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 4 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Rekontra === 1) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                            else this.m_rez.m_crez2 = (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Rekontra === 2) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = 2 * ((this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10);
                            else this.m_rez.m_crez1 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true) {
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true) {
                        if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                            this.m_rez.m_crez2 = 0;
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 2 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 2 * ((this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Kontra === 1) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Kontra === 2) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez1 = this.m_rez.m_rez1 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 90;
                    }
                    if (this.m_rez.m_rez1 > this.m_rez.m_rez2) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = this.Visiashti + 4 * ((this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10);
                        else this.m_rez.m_crez1 = this.Visiashti + (this.m_rez.m_rez1 + this.m_rez.m_rez2 + 5) / 10;
                        this.m_rez.m_crez2 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 > this.m_rez.m_rez1) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = this.Visiashti + 4 * ((this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10);
                        else this.m_rez.m_crez2 = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                        this.m_rez.m_crez1 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez2 === this.m_rez.m_rez1) {
                        if (this.Rekontra === 1) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez2 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            else this.m_rez.m_crez2 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez1 = 0;
                        }
                        if (this.Rekontra === 2) {
                            if (!this.kapo1 && !this.kapo2) this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            else this.Visiashti = this.Visiashti + (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            if (!this.kapo1 && !this.kapo2) this.m_rez.m_crez1 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            else this.m_rez.m_crez1 = (this.m_rez.m_rez2 + this.m_rez.m_rez1 + 5) / 10;
                            this.m_rez.m_crez2 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            for (let i: number = 1; i < 9; i++) this.m_rez.vz[i] = this.vz[i];
            for (let i: number = 1; i < 17; i++) this.m_rez.kd[i] = this.kd[i];
            this.open_rez_dlg = true;
            this.m_rez.DoModal();
            this.open_rez_dlg = false;
            this.online_timer = this.online_timer_time;
        }
    }

    async InitSP() {
        this.loading = true;
        this.SP = true;
        this.OP = false;
        this.Init2();
        await this.InitGame2();
        this.loading = false;
    }

    InitVziatka() {
        for (let i: number = 1; i <= 2; i++) this.Belot[i][1] = 0;
        this.KoiENaRed = this.KoiPuskaParvi = this.strelka;
        //proveriava dali ima dadena boia i ako ne ia igrae
        if (this.KoiPuskaParvi === 2) {
            let ParviPuska11: number;
            let VtoriPuska11: number;
            if (this.ParviPuska > 100) ParviPuska11 = (this.ParviPuska % 10) + this.Naddavane * 10;
            else ParviPuska11 = this.ParviPuska;
            if (this.VtoriPuska > 100) VtoriPuska11 = (this.VtoriPuska % 10) + this.Naddavane * 10;
            else VtoriPuska11 = this.VtoriPuska;
            if (VtoriPuska11 / 10 !== ParviPuska11 / 10) {
                this.Niama[ParviPuska11 / 10] = true;
                if (VtoriPuska11 > 0) {
                    this.LIz4istena[VtoriPuska11 / 10] = VtoriPuska11 - 2;
                }
            }
            if (VtoriPuska11 / 10 === ParviPuska11 / 10 && ParviPuska11 > VtoriPuska11 && VtoriPuska11 > 0) {
                //
                this.LIz4istena[VtoriPuska11 / 10] = VtoriPuska11 - 2;
                if (ParviPuska11 > VtoriPuska11) {
                    this.MVtoriPuska[VtoriPuska11 / 10] = ParviPuska11 - 2;
                }
            }
        }
        //
        this.strelka = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.online_timer = this.online_timer_time;
    }

    ObiaviavanePlayer(player: number) {
        let anons = this.Anonsi();
        this.DrawObiaviavane(2, anons, 0);
        this.KoiObiaviava = 1;
        let position: string = 'center';
        if (this.Zvuk[0] === 1) {
            this.props.m_pwin.m_sndSound.Play(anons + 2, position, this.stereo, this.pls[player]);
        }
    }

    Anonsi(): number {
        if (this.Kontra > 0) {
        }
        if (this.Rekontra > 0) {
        }
        ///
        let BrBoia: number[] = [0, 0, 0, 0, 0];
        let BoiaPP: number[] = [0, 0, 0, 0, 0, 0, 0];
        let BoiaVale: boolean[] = [false, false, false, false, false];
        let BoiaDev: boolean[] = [false, false, false, false, false];
        let BoiaAso: boolean[] = [false, false, false, false, false];
        let BoiaDes: boolean[] = [false, false, false, false, false];
        for (let i: number = 9; i < 15; i++) {
            BrBoia[this.k[i] / 10]++;
            if (this.k[i] % 10 === 5) BoiaVale[this.k[i] / 10] = true;
            if (this.k[i] % 10 === 3) BoiaDev[this.k[i] / 10] = true;
            if (this.k[i] % 10 === 8) BoiaAso[this.k[i] / 10] = true;
            if (this.k[i] % 10 === 4) BoiaDes[this.k[i] / 10] = true;
        }
        //dobavia koef pri dulga boia
        for (let i: number = 1; i < 5; i++) {
            if (BrBoia[i] === 1) {
                BoiaPP[i] -= 50;
            }
            if (BrBoia[i] === 3) BoiaPP[i] += 40;
            if (BrBoia[i] === 4) BoiaPP[i] += 80;
            if (BrBoia[i] === 5) BoiaPP[i] += 150;
            if (BrBoia[i] === 6) BoiaPP[i] += 300;
            if (BoiaVale[i] && BoiaDev[i]) BoiaPP[i] += 50;
        }
        ///
        for (let i: number = 1; i < 5; i++) {
            if (BoiaVale[i] && BoiaDev[i]) {
                BoiaPP[6] += 30;
            }
            if (BrBoia[i] === 1) {
                if (BoiaDev[i]) {
                    BoiaPP[6] -= 12;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] -= 13;
                }
            }
            if (BrBoia[i] === 3) {
                if (BoiaVale[i] && BoiaDev[i]) BoiaPP[6] += 30;
                if (BoiaVale[i] && BoiaAso[i]) {
                    BoiaPP[6] += 15;
                }
                if (BoiaVale[i]) {
                    BoiaPP[6] += 20;
                }
                if (BoiaDev[i]) {
                    BoiaPP[6] += 15;
                }
                if (BoiaAso[i] && BoiaDes[i]) BoiaPP[5] += 30;
                if (BoiaAso[i]) {
                    BoiaPP[5] += 20;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] += 15;
                }
            }
            if (BrBoia[i] === 4) {
                if (BoiaVale[i] && BoiaDev[i]) BoiaPP[6] += 50;
                if (BoiaVale[i] && BoiaAso[i]) {
                    BoiaPP[6] += 25;
                }
                if (BoiaVale[i]) {
                    BoiaPP[6] += 30;
                }
                if (BoiaDev[i]) {
                    BoiaPP[6] += 45;
                }
                if (BoiaAso[i] && BoiaDes[i]) BoiaPP[5] += 50;
                if (BoiaAso[i]) {
                    BoiaPP[5] += 30;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] += 25;
                }
            }
            if (BrBoia[i] === 5) {
                if (BoiaVale[i]) {
                    if (this.KoiPuskaParvi === 2) {
                        BoiaPP[6] += 220;
                    } else {
                        BoiaPP[6] += 80;
                    }
                } else {
                    if (this.KoiPuskaParvi === 2) {
                        BoiaPP[6] += 140;
                    } else {
                        BoiaPP[6] += 50;
                    }
                }
                if (BoiaAso[i]) {
                    if (this.KoiPuskaParvi === 2) {
                        BoiaPP[5] += 220;
                    } else {
                        BoiaPP[5] += 80;
                    }
                } else {
                    if (this.KoiPuskaParvi === 2) {
                        BoiaPP[5] += 140;
                    } else {
                        BoiaPP[5] += 50;
                    }
                }
            }
            if (BrBoia[i] === 6) {
                if (this.KoiPuskaParvi === 2) {
                    BoiaPP[6] += 400;
                    BoiaPP[5] += 390;
                } else {
                    BoiaPP[6] += 80;
                    BoiaPP[5] += 60;
                }
            }
        }
        //
        let spatia: number;
        let karo: number;
        let kupa: number;
        let pika: number;
        let bezkoz: number;
        let vsichkokoz: number;
        spatia = karo = kupa = pika = bezkoz = vsichkokoz = 0;
        if (this.Naddavane < 2) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 15) spatia = spatia + 120;
                if (this.k[a] === 13) spatia = spatia + 80;
                if (this.k[a] === 18) spatia = spatia + 70;
                if (this.k[a] === 14) spatia = spatia + 50;
                if (this.k[a] === 17) spatia = spatia + 40;
                if (this.k[a] === 16) spatia = spatia + 30;
                if (this.k[a] === 12) spatia = spatia + 25;
                if (this.k[a] === 11) spatia = spatia + 20;
                if (this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) spatia = spatia + 60;
                if (this.k[a] === 24 || this.k[a] === 34 || this.k[a] === 44) spatia = spatia + 30;
            }
            spatia += BoiaPP[1];
            spatia = spatia * 0.8;
        }
        //////////////KARO///////////////////////////////////////////
        if (this.Naddavane < 3) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 25) karo = karo + 120;
                if (this.k[a] === 23) karo = karo + 80;
                if (this.k[a] === 28) karo = karo + 70;
                if (this.k[a] === 24) karo = karo + 50;
                if (this.k[a] === 27) karo = karo + 40;
                if (this.k[a] === 26) karo = karo + 30;
                if (this.k[a] === 22) karo = karo + 25;
                if (this.k[a] === 21) karo = karo + 20;
                if (this.k[a] === 18 || this.k[a] === 38 || this.k[a] === 48) karo = karo + 60;
                if (this.k[a] === 14 || this.k[a] === 34 || this.k[a] === 44) karo = karo + 30;
            }
            karo += BoiaPP[2];
            karo = karo * 0.8;
        }
        //////////////KUPA////////////////////////////////////////////
        if (this.Naddavane < 4) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 35) kupa = kupa + 120;
                if (this.k[a] === 33) kupa = kupa + 80;
                if (this.k[a] === 38) kupa = kupa + 70;
                if (this.k[a] === 34) kupa = kupa + 50;
                if (this.k[a] === 37) kupa = kupa + 40;
                if (this.k[a] === 36) kupa = kupa + 30;
                if (this.k[a] === 32) kupa = kupa + 25;
                if (this.k[a] === 31) kupa = kupa + 20;
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 48) kupa = kupa + 60;
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 44) kupa = kupa + 30;
            }
            kupa += BoiaPP[3];
            kupa = kupa * 0.8;
        }
        //////////////////////////PIKA//////////////////////////////
        if (this.Naddavane < 5) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 45) pika = pika + 120;
                if (this.k[a] === 43) pika = pika + 80;
                if (this.k[a] === 48) pika = pika + 70;
                if (this.k[a] === 44) pika = pika + 50;
                if (this.k[a] === 47) pika = pika + 40;
                if (this.k[a] === 46) pika = pika + 30;
                if (this.k[a] === 42) pika = pika + 25;
                if (this.k[a] === 41) pika = pika + 20;
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38) pika = pika + 60;
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 34) pika = pika + 30;
            }
            pika += BoiaPP[4];
            pika = pika * 0.8;
        }
        ////////////BEZKOZ/////////////////////////////////////////
        if (this.Naddavane < 6) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) bezkoz = bezkoz + 93;
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 34 || this.k[a] === 44) bezkoz = bezkoz + 65;
                if (this.k[a] === 17 || this.k[a] === 27 || this.k[a] === 37 || this.k[a] === 47) bezkoz = bezkoz + 20;
            }
            bezkoz += BoiaPP[5];
            bezkoz = bezkoz * 0.92;
        }
        /////////////////Vsi4ko koz/////////////////////////
        if (this.Naddavane < 7) {
            for (let a: number = 9; a < 15; a++) {
                if (this.k[a] === 15 || this.k[a] === 25 || this.k[a] === 35 || this.k[a] === 45) vsichkokoz = vsichkokoz + 90;
                if (this.k[a] === 13 || this.k[a] === 23 || this.k[a] === 33 || this.k[a] === 43) vsichkokoz = vsichkokoz + 60;
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) vsichkokoz = vsichkokoz + 20;
            }
            vsichkokoz += BoiaPP[6];
            vsichkokoz = vsichkokoz * 0.92;
        }
        if (this.m_rez.m_crez1 < 145 || this.m_rez.m_crez1 - this.m_rez.m_crez2 < 12) {
            let tercaf: number = this.CheckTerci(false, 2);
            if (tercaf === 36) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 200;
            }
            if (tercaf === 35) {
                spatia = spatia + 150;
                karo = karo + 170;
                kupa = kupa + 160;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf === 34) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                bezkoz = bezkoz + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf === 33) {
                spatia = spatia + 120;
                karo = karo + 120;
                kupa = kupa + 120;
                pika = pika + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf === 32 || tercaf === 31) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                bezkoz = bezkoz + 40;
            }
            if (tercaf > 20 && tercaf < 30) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf > 10 && tercaf < 20) {
                spatia = spatia + 70;
                karo = karo + 70;
                kupa = kupa + 70;
                pika = pika + 70;
                vsichkokoz = vsichkokoz + 40;
            }
            ////////////////////////////////////////////////////
            if (tercaf > 0 && tercaf < 6) {
                spatia = spatia + 30;
                karo = karo + 30;
                kupa = kupa + 30;
                pika = pika + 30;
                vsichkokoz = vsichkokoz + 30;
            }
        }
        if (this.m_rez.m_rez2 > 135 && this.m_rez.m_rez1 < 120 && this.m_rez.m_rez1 > 60) {
            spatia = spatia - 20;
            karo = karo - 20;
            kupa = kupa - 20;
            pika = pika - 20;
            bezkoz = bezkoz - 30;
            vsichkokoz = vsichkokoz - 30;
        }
        if (this.m_rez.m_rez1 > 140 && this.m_rez.m_rez2 < 132 && this.m_rez.m_rez1 > 60) {
            spatia = spatia - (this.m_rez.m_rez1 - 140) * 8;
            karo = karo - (this.m_rez.m_rez1 - 140) * 8;
            kupa = kupa - (this.m_rez.m_rez1 - 140) * 8;
            pika = pika - (this.m_rez.m_rez1 - 140) * 8;
            bezkoz = bezkoz - (this.m_rez.m_rez1 - 140) * 12;
            vsichkokoz = vsichkokoz - (this.m_rez.m_rez1 - 140) * 12;
        }
        if (
            this.m_rez.m_rez1 > 140 &&
            this.m_rez.m_rez2 > 140 &&
            this.m_rez.m_rez2 < 149 &&
            ((this.Naddavane === 1 && this.Pkazal[1][1] === true) ||
                (this.Naddavane === 2 && this.Pkazal[1][2] === true) ||
                (this.Naddavane === 3 && this.Pkazal[1][3] === true) ||
                (this.Naddavane === 4 && this.Pkazal[1][4] === true) ||
                (this.Naddavane === 5 && this.Pkazal[1][5] === true))
        ) {
            karo = karo + 20;
            kupa = kupa + 20;
            pika = pika + 20;
            bezkoz = bezkoz + 20;
            vsichkokoz = vsichkokoz + 20;
        }
        if (this.KoiPuskaParvi === 2) {
            spatia = spatia + 20;
            karo = karo + 20;
            kupa = kupa + 20;
            pika = pika + 20;
            bezkoz = bezkoz + 40;
            vsichkokoz = vsichkokoz + 40;
        }
        if (this.Pkazal[1][1] === true && this.Naddavane === 1) {
            vsichkokoz = vsichkokoz - 20;
            if (spatia < 80) {
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 20;
            }
            if (spatia > 120) {
                karo = karo - 20;
                kupa = kupa - 20;
                pika = pika - 20;
                bezkoz = bezkoz - 20;
            }
        }
        if (this.Pkazal[1][2] === true && this.Naddavane === 2) {
            vsichkokoz = vsichkokoz - 20;
            if (karo < 80) {
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 20;
            }
            if (karo > 120) {
                kupa = kupa - 20;
                pika = pika - 20;
                bezkoz = bezkoz - 20;
            }
        }
        if (this.Pkazal[1][3] === true && this.Naddavane === 3) {
            vsichkokoz = vsichkokoz - 20;
            if (kupa < 80) {
                pika = pika + 20;
                bezkoz = bezkoz + 20;
            }
            if (kupa > 120) {
                pika = pika - 20;
                bezkoz = bezkoz - 20;
            }
        }
        if (this.Pkazal[1][4] === true && this.Naddavane === 4) {
            vsichkokoz = vsichkokoz - 20;
            if (pika < 80) bezkoz = bezkoz + 20;
            if (pika > 120) bezkoz = bezkoz - 20;
        }
        if (this.Pkazal[1][5] === true && this.Naddavane === 1) {
            if (bezkoz < 100) vsichkokoz = vsichkokoz + 30;
            if (bezkoz > 150) vsichkokoz = vsichkokoz - 30;
        }
        let Nadd: number = 0;
        if (spatia > 210 && this.Naddavane < 1) Nadd = 1;
        if (karo > spatia && karo > 215 && this.Naddavane < 2) Nadd = 2;
        if (kupa > karo && kupa > spatia && kupa > 220 && this.Naddavane < 3) Nadd = 3;
        if (pika > kupa && pika > karo && pika > spatia && pika > 225 && this.Naddavane < 4) Nadd = 4;
        if (bezkoz > pika && bezkoz > kupa && bezkoz > karo && bezkoz > spatia && bezkoz > 240 && this.Naddavane < 5) Nadd = 5;
        if (
            vsichkokoz > bezkoz &&
            vsichkokoz > pika &&
            vsichkokoz > kupa &&
            vsichkokoz > karo &&
            vsichkokoz > spatia &&
            vsichkokoz > 230 &&
            this.Naddavane < 6
        )
            Nadd = 6;
        if (this.Kontra === 0 && this.Rekontra === 0) {
            if (this.Pkazal[1][this.Naddavane] === true) {
                if (this.Naddavane < 5) {
                    if (this.m_rez.m_crez1 > 140 && this.m_rez.m_crez2 < 135 && this.BrPas > 0) {
                        Nadd = 7;
                    } else {
                        if (this.Naddavane === 1 && spatia > 230) Nadd = 7;
                        if (this.Naddavane === 2 && karo > 235) Nadd = 7;
                        if (this.Naddavane === 3 && kupa > 240) Nadd = 7;
                        if (this.Naddavane === 4 && pika > 245) Nadd = 7;
                    }
                }
                if (this.Naddavane === 5) {
                    if (this.m_rez.m_crez1 > 137 && this.m_rez.m_crez2 < 125 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (bezkoz > 265) Nadd = 7;
                }
                if (this.Naddavane === 6) {
                    if (this.m_rez.m_crez1 > 135 && this.m_rez.m_crez2 < 121 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (vsichkokoz > 255) Nadd = 7;
                }
            }
        }
        if (this.Kontra === 1) {
            if (this.Naddavane < 5) {
                if (this.m_rez.m_crez1 > 118 && this.m_rez.m_crez2 < 118 && this.BrPas > 0) {
                    Nadd = 8;
                } else {
                    if (this.Naddavane === 1 && spatia > 250) Nadd = 8;
                    if (this.Naddavane === 2 && karo > 255) Nadd = 8;
                    if (this.Naddavane === 3 && kupa > 260) Nadd = 8;
                    if (this.Naddavane === 4 && pika > 265) Nadd = 8;
                }
            }
            if (this.Naddavane === 5) {
                if (this.m_rez.m_crez1 > 98 && this.m_rez.m_crez2 < 98 && this.BrPas > 0) {
                    Nadd = 8;
                } else if (bezkoz > 265) Nadd = 8;
            }
            if (this.Naddavane === 6) {
                if (this.m_rez.m_crez1 > 94 && this.m_rez.m_crez2 < 94 && this.BrPas > 0) {
                    Nadd = 8;
                } else if (vsichkokoz > 255) Nadd = 8;
            }
        }
        if (Nadd > 0 && Nadd < 7) {
            this.Kontra = 0;
            this.Rekontra = 0;
            this.Naddavane = Nadd;
            this.BrPas = 0;
            this.Pkazal[2][Nadd] = true;
        }
        if (Nadd > 6) {
            if (Nadd === 7) {
                this.Kontra = 2;
            }
            if (Nadd === 8) {
                this.Kontra = 0;
                this.Rekontra = 2;
            }

            this.BrPas = 0;
        }
        if (Nadd === 0) this.BrPas = this.BrPas + 1;
        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[2], this.GameName(Nadd));
        return Nadd;
    }

    Obiaviavane(anons: number) {
        this.online_timer = this.online_timer_time;
        if (this.OP === true) {
            if (anons > 0) this.BrPas = 0;
        }
        this.DrawObiaviavane(this.KoiObiaviava, anons, 0);
        if (this.KoiObiaviava === 1) this.KoiObiaviava = 2;
        else this.KoiObiaviava = 1;
        let position: string = 'center';
        if (this.Zvuk[0] === 1) {
            this.props.m_pwin.m_sndSound.Play(anons + 2, position, this.stereo, this.pls[2]);
        }
    }

    OnRButtonDown(point: CPoint) {
        if (this.watch) {
            if (this.Name[2] === 'Computer2') {
                let s: CSize = this.GetTextExtent(this.Name[2], 13);
                let width: number = s.cx;
                if (
                    this.ScaleRect(new CRect(this.NamePos[2].x, this.NamePos[2].y, this.NamePos[2].x + width, this.NamePos[2].y + 20)).PtInRect(point)
                ) {
                    this.props.m_pwin.GoToLobby();
                    this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                }
            }
        }
    }

    async OnLButtonDown(point: CPoint) {
        if (this.OP) {
            for (let i: number = 1; i <= 2; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            let begin: number = 2;
            if (this.watch) begin = 1;
            for (let i: number = begin; i <= 2; i++) {
                if (this.show_user[i]) {
                    this.show_user[i] = false;
                    this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                }
            }
        }
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                if (this.OP && this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }
            if (this.strelka < 1 && this.P1puska === 0) {
                for (let i: number = 1; i <= 8; i++) {
                    if (
                        this.CardsPos[i].x * GlobalVariables.scale < point.x &&
                        (this.CardsPos[i].x + GlobalConstants.CARD_WIDTH) * GlobalVariables.scale > point.x &&
                        this.CardsPos[i].y * GlobalVariables.scale < point.y &&
                        (this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT) * GlobalVariables.scale > point.y &&
                        this.k[i] !== 0
                    ) {
                        if (this.ProverkaCursor(i)) {
                            if (this.SP) {
                                await this.PuskaneNaKarta(i);
                            }
                            if (this.OP) {
                                await this.PuskaneNaKartaOP(i);
                            }
                            if (this.OP && !this.watch) {
                                this.sit_out_count = 0;
                                if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                                    if (!this.op_start) {
                                        this.SendGame('L');
                                        this.op_start = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async PuskaneNaKartaOP(card: number) {
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            if (this.KoiENaRed === 1) {
                await this.PuskaneNaKarta(card);
                if (this.Server) {
                    if (!this.P2OP) {
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    }
                    if (this.P2OP || this.HaveWatch) this.SendGame('$|k|' + this.IntToString(card));
                } else {
                    this.SendGame('$|l|' + this.IntToString(card));
                }
            }
        }
    }

    async PuskaneNaKarta(card: number) {
        this.AddToDetails(card);
        if (this.CheckBelot(this.k[card], 1) === true) {
            this.m_ann.InitWindow(true, '', '');
            if (!this.watch) {
                if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                    if (this.m_ann.m_belote) {
                        this.ObiaviavaneBelot(1, true);
                        this.Belot[1][0] = this.Belot[1][0] + 1;
                        if (this.OP) {
                            if (this.Server === true) {
                                this.SendGame('$|o|' + this.IntToString(this.hand));
                            } else {
                                this.SendGame('$|p|' + this.IntToString(this.hand));
                            }
                        }
                    }
                }
            } else {
                if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                    this.ObiaviavaneBelot(this.KoiENaRed, true);
                    this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                }
            }
            this.m_ann.start = 0;
        }
        if (this.hand === 0) {
            this.setState({});
            if (this.watch) {
                for (let b: number = 0; b <= 1; b++) {
                    if (this.PTerci[this.KoiENaRed][b] > 0) {
                        if (this.terci[this.KoiENaRed][b]) {
                            if (
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                    this.PTerci[this.KoiENaRed][1] < 7
                                ) &&
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                    this.PTerci[this.KoiENaRed][1] < 16
                                ) &&
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                    this.PTerci[this.KoiENaRed][1] < 37
                                )
                            )
                                this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                            if (b === 0) {
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                    this.PTerci[this.KoiENaRed][1] < 7
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                }
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                    this.PTerci[this.KoiENaRed][1] < 16
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                }
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                    this.PTerci[this.KoiENaRed][1] < 37
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                }
                            }
                        } else this.PTerci[this.KoiENaRed][b] = 0;
                    }
                }
            } else {
                if (this.PTerci[1][0] > 0 || this.PTerci[1][1] > 0) {
                    this.m_ann.InitWindow(false, this.StringTerci(this.PTerci[1][0]), this.StringTerci(this.PTerci[1][1]));
                    if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                        this.terci[1][0] = this.m_ann.m_announce1;
                        this.terci[1][1] = this.m_ann.m_announce2;
                        for (let b: number = 0; b <= 1; b++) {
                            if (this.PTerci[1][b] > 0 && this.terci[1][b]) {
                                if (
                                    !(
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    )
                                )
                                    this.ObiaviavaneTerci(1, this.PTerci[1][b], b, true);
                                if (b === 0) {
                                    if (
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) {
                                        this.ObiaviavaneTerci(1, 7, 0, true);
                                    }
                                    if (
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) {
                                        this.ObiaviavaneTerci(1, 16, 0, true);
                                    }
                                    if (
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    ) {
                                        this.ObiaviavaneTerci(1, 37, 0, true);
                                    }
                                }
                                if (this.OP && !this.watch) {
                                    if (this.Server) {
                                        this.SendGame('$|q|1|' + this.IntToString(b));
                                    } else {
                                        this.SendGame('$|r|' + this.IntToString(b));
                                    }
                                }
                            } else {
                                this.PTerci[1][b] = 0;
                                this.terci[1][b] = false;
                            }
                        }
                    } else {
                        this.PTerci[1][0] = 0;
                        this.terci[1][0] = false;
                        this.PTerci[1][1] = 0;
                        this.terci[1][1] = false;
                    }
                    this.m_ann.start = 0;
                }
            }
        }
        if (this.KoiPuskaParvi === 1) this.ParviPuska = this.k[card];
        if (this.KoiPuskaParvi === 2) this.VtoriPuska = this.k[card];

        this.P1puska = this.k[card];
        this.CardsPoint[33] = this.CardsPoint[card];
        this.CardP[33] = this.CardP[card];
        this.BeginMove(this.CardsPoint[33], this.CardsPos[33], 33);
        if (this.KoiPuskaParvi !== 2) this.KoiENaRed = 2;
        if (this.SP === true) {
            this.props.m_pwin.KillTimer(3);
            this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
        }
        if (this.KoiPuskaParvi === 1) {
            this.ParviPuska = this.k[card];
            if (this.Naddavane > 4) {
                if (
                    this.k[card] > 10 &&
                    this.k[card] < 17 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 1)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 1) || this.k[card] === 17)
                )
                    this.Piska[1][1] = true;
                if (
                    this.k[card] > 20 &&
                    this.k[card] < 27 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 2)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 2) || this.k[card] === 27)
                )
                    this.Piska[1][2] = true;
                if (
                    this.k[card] > 30 &&
                    this.k[card] < 37 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 3)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 3) || this.k[card] === 37)
                )
                    this.Piska[1][3] = true;
                if (
                    this.k[card] > 40 &&
                    this.k[card] < 47 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 4)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 4) || this.k[card] === 47)
                )
                    this.Piska[1][4] = true;
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) this.Pniama[1][1] = true;
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) this.Pniama[1][2] = true;
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) this.Pniama[1][3] = true;
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) this.Pniama[1][4] = true;
            if (this.ParviPuska > 100 && this.k[card] < 100) this.Pniama[1][this.Naddavane] = true;
            if (this.Naddavane === 6 && this.KoiPuskaParvi === 2) {
                if (this.ParviPuska === 12 && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 22 && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 32 && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 42 && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) this.Pniama[1][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) this.Pniama[1][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) this.Pniama[1][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11)
                    this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21)
                    this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31)
                    this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41)
                    this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12)
                    this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22)
                    this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32)
                    this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42)
                    this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13)
                    this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23)
                    this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33)
                    this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43)
                    this.Pniama[1][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.k[card] === 11
                )
                    this.Pniama[1][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.k[card] === 21
                )
                    this.Pniama[1][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.k[card] === 31
                )
                    this.Pniama[1][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.k[card] === 41
                )
                    this.Pniama[1][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 12
                )
                    this.Pniama[1][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 22
                )
                    this.Pniama[1][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 32
                )
                    this.Pniama[1][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 42
                )
                    this.Pniama[1][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 13
                )
                    this.Pniama[1][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 23
                )
                    this.Pniama[1][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 33
                )
                    this.Pniama[1][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 43
                )
                    this.Pniama[1][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 14
                )
                    this.Pniama[1][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 24
                )
                    this.Pniama[1][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 34
                )
                    this.Pniama[1][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 44
                )
                    this.Pniama[1][4] = true;
            }
            if (this.Naddavane < 5 && this.KoiPuskaParvi === 2) {
                if (this.ParviPuska === 102 && this.k[card] === 101) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(102) === false && this.k[card] === 101)
                    this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(101) === false && this.k[card] === 102)
                    this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(102) === false && this.CheckCard(101) === false && this.k[card] === 103)
                    this.Pniama[1][this.Naddavane] = true;
            }
        }
        if (this.KoiPuskaParvi === 2) this.VtoriPuska = this.k[card];
        this.DeleteCard(card);
        this.setState({});
        this.props.m_pwin.SetCursor();
    }

    ProverkaCursor(card: number): boolean {
        if (this.Faza < 5) return false;
        if (this.Move === true || this.KoiENaRed !== 1) return false;
        if (this.KoiPuskaParvi === 1) return true;
        if (this.Naddavane === 5) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.k[card] > 20) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) return false;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.k[card] < 20 || this.k[card] > 30) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) return false;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.k[card] < 30 || this.k[card] > 40) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) return false;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.k[card] < 40 || this.k[card] > 50) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) return false;
                    }
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////
        if (this.Naddavane < 5) {
            if (this.ParviPuska > 100) {
                if (this.k[card] < 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 100) return false;
                    }
                }
                if (this.k[card] > 100 && this.k[card] < this.ParviPuska) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > this.ParviPuska) return false;
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.k[card] > 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) return false;
                    }
                }
                if (this.k[card] > 20 && this.k[card] < 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 100 || (this.k[i] > 10 && this.k[i] < 20)) return false;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.k[card] > 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) return false;
                    }
                }
                if ((this.k[card] > 30 && this.k[card] < 100) || this.k[card] < 20) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 100 || (this.k[i] > 20 && this.k[i] < 30)) return false;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.k[card] > 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) return false;
                    }
                }
                if ((this.k[card] > 40 && this.k[card] < 100) || this.k[card] < 30) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 100 || (this.k[i] > 30 && this.k[i] < 40)) return false;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.k[card] > 100) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) return false;
                    }
                }
                if (this.k[card] < 100 && this.k[card] < 40) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 100 || (this.k[i] > 40 && this.k[i] < 50)) return false;
                    }
                }
            }
        }
        if (this.Naddavane === 6) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.k[card] > 20) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) return false;
                    }
                }
                if (this.k[card] > 10 && this.k[card] < 20 && this.k[card] < this.ParviPuska) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] < 20 && this.k[i] > this.ParviPuska) return false;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.k[card] < 20 || this.k[card] > 30) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) return false;
                    }
                }
                if (this.k[card] > 20 && this.k[card] < 30 && this.k[card] < this.ParviPuska) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska) return false;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.k[card] < 30 || this.k[card] > 40) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) return false;
                    }
                }
                if (this.k[card] > 30 && this.k[card] < 40 && this.k[card] < this.ParviPuska) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska) return false;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.k[card] < 40 || this.k[card] > 50) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) return false;
                    }
                }
                if (this.k[card] > 40 && this.k[card] < 50 && this.k[card] < this.ParviPuska) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska) return false;
                    }
                }
            }
        }
        return true;
    }

    AddToDetails(card: number) {
        let kdcard: number = this.k[card];
        if (this.Naddavane === 6) {
            kdcard = this.k[card];
            if (this.k[card] === 18) kdcard = 14;
            if (this.k[card] === 28) kdcard = 24;
            if (this.k[card] === 38) kdcard = 34;
            if (this.k[card] === 48) kdcard = 44;
            if (this.k[card] === 17) kdcard = 13;
            if (this.k[card] === 27) kdcard = 23;
            if (this.k[card] === 37) kdcard = 33;
            if (this.k[card] === 47) kdcard = 43;
            if (this.k[card] === 16) kdcard = 18;
            if (this.k[card] === 26) kdcard = 28;
            if (this.k[card] === 36) kdcard = 38;
            if (this.k[card] === 46) kdcard = 48;
            if (this.k[card] === 15) kdcard = 17;
            if (this.k[card] === 25) kdcard = 27;
            if (this.k[card] === 35) kdcard = 37;
            if (this.k[card] === 45) kdcard = 47;
            if (this.k[card] === 14) kdcard = 16;
            if (this.k[card] === 24) kdcard = 26;
            if (this.k[card] === 34) kdcard = 36;
            if (this.k[card] === 44) kdcard = 46;
            if (this.k[card] === 13) kdcard = 15;
            if (this.k[card] === 23) kdcard = 25;
            if (this.k[card] === 33) kdcard = 35;
            if (this.k[card] === 43) kdcard = 45;
        }
        if (this.Naddavane < 5) {
            kdcard = this.k[card];
            if (this.k[card] === 108) kdcard = this.Naddavane * 10 + 4;
            if (this.k[card] === 107) kdcard = this.Naddavane * 10 + 3;
            if (this.k[card] === 106) kdcard = this.Naddavane * 10 + 8;
            if (this.k[card] === 105) kdcard = this.Naddavane * 10 + 7;
            if (this.k[card] === 104) kdcard = this.Naddavane * 10 + 6;
            if (this.k[card] === 103) kdcard = this.Naddavane * 10 + 5;
        }
        for (let i: number = 1; i < 17; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                break;
            }
        }
    }

    SendCards() {
        if (this.P2OP) {
            this.P2G = false;
            for (let a: number = 9; a < 17; a++) {
                this.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (let a: number = 1; a < 9; a++) {
                this.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (let a: number = 17; a < 25; a++) {
                this.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
        }
        if (this.HaveWatch) {
            for (let a: number = 1; a < 25; a++) {
                this.SendGame('$|j|' + this.IntToString(this.k[a]));
            }
        }
    }

    KartaZaRazdavane(): number {
        if (this.KoiZapochva === 1) {
            if (this.Razdavane1 === true) {
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) return 1;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) return 2;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) return 3;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) return 9;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) return 10;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) return 11;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) return 4;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) return 5;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) return 12;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) return 13;
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) return 6;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) return 7;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) return 8;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) return 14;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) return 15;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) return 16;
                this.online_timer = this.online_timer_time;
            }
        }
        if (this.KoiZapochva === 2) {
            if (this.Razdavane1 === true) {
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) return 9;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) return 10;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) return 11;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) return 1;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) return 2;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) return 3;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) return 12;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) return 13;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) return 4;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) return 5;
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) return 14;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) return 15;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) return 16;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) return 6;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) return 7;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) return 8;
                this.online_timer = this.online_timer_time;
            }
        }
        return 0;
    }

    SortCards(type: number) {
        let sort: number[] = [];
        let temps: number;
        let tempk: number;
        let tempc: string;
        if (type === 0) {
            for (let i: number = 1; i < 6; i++) {
                for (let a: number = 1; a < 5; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1] = this.CardP[a];
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i: number = 9; i < 14; i++) {
                for (let a: number = 9; a < 13; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1] = this.CardP[a];
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (type === 1) {
            for (let i: number = 1; i < 9; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i: number = 1; i < 9; i++) {
                for (let a: number = 1; a < 8; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1] = this.CardP[a];
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i: number = 9; i < 17; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i: number = 9; i < 17; i++) {
                for (let a: number = 9; a < 16; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1] = this.CardP[a];
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
    }

    ReturnSortCards(card: number): number {
        if (this.Naddavane === 1) {
            if (card === 13) return 107;
            if (card === 14) return 105;
            if (card === 15) return 108;
            if (card === 16) return 103;
            if (card === 17) return 104;
            if (card === 18) return 106;
            if (card === 23) return 23;
            if (card === 24) return 27;
            if (card === 25) return 24;
            if (card === 26) return 25;
            if (card === 27) return 26;
            if (card === 28) return 28;
            if (card === 33) return 33;
            if (card === 34) return 37;
            if (card === 35) return 34;
            if (card === 36) return 35;
            if (card === 37) return 36;
            if (card === 38) return 38;
            if (card === 43) return 43;
            if (card === 44) return 47;
            if (card === 45) return 44;
            if (card === 46) return 45;
            if (card === 47) return 46;
            if (card === 48) return 48;
        }
        if (this.Naddavane === 2) {
            if (card === 13) return 13;
            if (card === 14) return 17;
            if (card === 15) return 14;
            if (card === 16) return 15;
            if (card === 17) return 16;
            if (card === 18) return 18;
            if (card === 23) return 107;
            if (card === 24) return 105;
            if (card === 25) return 108;
            if (card === 26) return 103;
            if (card === 27) return 104;
            if (card === 28) return 106;
            if (card === 33) return 33;
            if (card === 34) return 37;
            if (card === 35) return 34;
            if (card === 36) return 35;
            if (card === 37) return 36;
            if (card === 38) return 38;
            if (card === 43) return 43;
            if (card === 44) return 47;
            if (card === 45) return 44;
            if (card === 46) return 45;
            if (card === 47) return 46;
            if (card === 48) return 48;
        }
        if (this.Naddavane === 3) {
            if (card === 13) return 13;
            if (card === 14) return 17;
            if (card === 15) return 14;
            if (card === 16) return 15;
            if (card === 17) return 16;
            if (card === 18) return 18;
            if (card === 23) return 23;
            if (card === 24) return 27;
            if (card === 25) return 24;
            if (card === 26) return 25;
            if (card === 27) return 26;
            if (card === 28) return 28;
            if (card === 33) return 107;
            if (card === 34) return 105;
            if (card === 35) return 108;
            if (card === 36) return 103;
            if (card === 37) return 104;
            if (card === 38) return 106;
            if (card === 43) return 43;
            if (card === 44) return 47;
            if (card === 45) return 44;
            if (card === 46) return 45;
            if (card === 47) return 46;
            if (card === 48) return 48;
        }
        if (this.Naddavane === 4) {
            if (card === 13) return 13;
            if (card === 14) return 17;
            if (card === 15) return 14;
            if (card === 16) return 15;
            if (card === 17) return 16;
            if (card === 18) return 18;
            if (card === 23) return 23;
            if (card === 24) return 27;
            if (card === 25) return 24;
            if (card === 26) return 25;
            if (card === 27) return 26;
            if (card === 28) return 28;
            if (card === 33) return 33;
            if (card === 34) return 37;
            if (card === 35) return 34;
            if (card === 36) return 35;
            if (card === 37) return 36;
            if (card === 38) return 38;
            if (card === 43) return 107;
            if (card === 44) return 105;
            if (card === 45) return 108;
            if (card === 46) return 103;
            if (card === 47) return 104;
            if (card === 48) return 106;
        }
        if (this.Naddavane === 5) {
            if (card === 13) return 13;
            if (card === 14) return 17;
            if (card === 15) return 14;
            if (card === 16) return 15;
            if (card === 17) return 16;
            if (card === 18) return 18;
            if (card === 23) return 23;
            if (card === 24) return 27;
            if (card === 25) return 24;
            if (card === 26) return 25;
            if (card === 27) return 26;
            if (card === 28) return 28;
            if (card === 33) return 33;
            if (card === 34) return 37;
            if (card === 35) return 34;
            if (card === 36) return 35;
            if (card === 37) return 36;
            if (card === 38) return 38;
            if (card === 43) return 43;
            if (card === 44) return 47;
            if (card === 45) return 44;
            if (card === 46) return 45;
            if (card === 47) return 46;
            if (card === 48) return 48;
        }
        if (this.Naddavane === 6) {
            if (card === 13) return 17;
            if (card === 14) return 15;
            if (card === 15) return 18;
            if (card === 16) return 13;
            if (card === 17) return 14;
            if (card === 18) return 16;
            if (card === 23) return 27;
            if (card === 24) return 25;
            if (card === 25) return 28;
            if (card === 26) return 23;
            if (card === 27) return 24;
            if (card === 28) return 26;
            if (card === 33) return 37;
            if (card === 34) return 35;
            if (card === 35) return 38;
            if (card === 36) return 33;
            if (card === 37) return 34;
            if (card === 38) return 36;
            if (card === 43) return 47;
            if (card === 44) return 45;
            if (card === 45) return 48;
            if (card === 46) return 43;
            if (card === 47) return 44;
            if (card === 48) return 46;
        }
        return 0;
    }

    Strelka(): number {
        this.hand = this.hand + 1;
        let addrez: number = 0;
        if (this.Naddavane < 5) {
            if (this.P1puska === 14 || this.P2puska === 14) addrez = addrez + 2;
            if (this.P1puska === 15 || this.P2puska === 15) addrez = addrez + 3;
            if (this.P1puska === 16 || this.P2puska === 16) addrez = addrez + 4;
            if (this.P1puska === 17 || this.P2puska === 17) addrez = addrez + 10;
            if (this.P1puska === 18 || this.P2puska === 18) addrez = addrez + 11;
            if (this.P1puska === 24 || this.P2puska === 24) addrez = addrez + 2;
            if (this.P1puska === 25 || this.P2puska === 25) addrez = addrez + 3;
            if (this.P1puska === 26 || this.P2puska === 26) addrez = addrez + 4;
            if (this.P1puska === 27 || this.P2puska === 27) addrez = addrez + 10;
            if (this.P1puska === 28 || this.P2puska === 28) addrez = addrez + 11;
            if (this.P1puska === 34 || this.P2puska === 34) addrez = addrez + 2;
            if (this.P1puska === 35 || this.P2puska === 35) addrez = addrez + 3;
            if (this.P1puska === 36 || this.P2puska === 36) addrez = addrez + 4;
            if (this.P1puska === 37 || this.P2puska === 37) addrez = addrez + 10;
            if (this.P1puska === 38 || this.P2puska === 38) addrez = addrez + 11;
            if (this.P1puska === 44 || this.P2puska === 44) addrez = addrez + 2;
            if (this.P1puska === 45 || this.P2puska === 45) addrez = addrez + 3;
            if (this.P1puska === 46 || this.P2puska === 46) addrez = addrez + 4;
            if (this.P1puska === 47 || this.P2puska === 47) addrez = addrez + 10;
            if (this.P1puska === 48 || this.P2puska === 48) addrez = addrez + 11;
            if (this.P1puska === 103 || this.P2puska === 103) addrez = addrez + 3;
            if (this.P1puska === 104 || this.P2puska === 104) addrez = addrez + 4;
            if (this.P1puska === 105 || this.P2puska === 105) addrez = addrez + 10;
            if (this.P1puska === 106 || this.P2puska === 106) addrez = addrez + 11;
            if (this.P1puska === 107 || this.P2puska === 107) addrez = addrez + 14;
            if (this.P1puska === 108 || this.P2puska === 108) addrez = addrez + 20;
            if (this.hand === 8) addrez = addrez + 10;
        }
        if (this.Naddavane === 5) {
            if (this.P1puska === 14 || this.P2puska === 14) addrez = addrez + 4;
            if (this.P1puska === 15 || this.P2puska === 15) addrez = addrez + 6;
            if (this.P1puska === 16 || this.P2puska === 16) addrez = addrez + 8;
            if (this.P1puska === 17 || this.P2puska === 17) addrez = addrez + 20;
            if (this.P1puska === 18 || this.P2puska === 18) addrez = addrez + 22;
            if (this.P1puska === 24 || this.P2puska === 24) addrez = addrez + 4;
            if (this.P1puska === 25 || this.P2puska === 25) addrez = addrez + 6;
            if (this.P1puska === 26 || this.P2puska === 26) addrez = addrez + 8;
            if (this.P1puska === 27 || this.P2puska === 27) addrez = addrez + 20;
            if (this.P1puska === 28 || this.P2puska === 28) addrez = addrez + 22;
            if (this.P1puska === 34 || this.P2puska === 34) addrez = addrez + 4;
            if (this.P1puska === 35 || this.P2puska === 35) addrez = addrez + 6;
            if (this.P1puska === 36 || this.P2puska === 36) addrez = addrez + 8;
            if (this.P1puska === 37 || this.P2puska === 37) addrez = addrez + 20;
            if (this.P1puska === 38 || this.P2puska === 38) addrez = addrez + 22;
            if (this.P1puska === 44 || this.P2puska === 44) addrez = addrez + 4;
            if (this.P1puska === 45 || this.P2puska === 45) addrez = addrez + 6;
            if (this.P1puska === 46 || this.P2puska === 46) addrez = addrez + 8;
            if (this.P1puska === 47 || this.P2puska === 47) addrez = addrez + 20;
            if (this.P1puska === 48 || this.P2puska === 48) addrez = addrez + 22;
            if (this.hand === 8) addrez = addrez + 20;
        }
        if (this.Naddavane === 6) {
            if (this.P1puska === 13 || this.P2puska === 13) addrez = addrez + 3;
            if (this.P1puska === 14 || this.P2puska === 14) addrez = addrez + 4;
            if (this.P1puska === 15 || this.P2puska === 15) addrez = addrez + 10;
            if (this.P1puska === 16 || this.P2puska === 16) addrez = addrez + 11;
            if (this.P1puska === 17 || this.P2puska === 17) addrez = addrez + 14;
            if (this.P1puska === 18 || this.P2puska === 18) addrez = addrez + 20;
            if (this.P1puska === 23 || this.P2puska === 23) addrez = addrez + 3;
            if (this.P1puska === 24 || this.P2puska === 24) addrez = addrez + 4;
            if (this.P1puska === 25 || this.P2puska === 25) addrez = addrez + 10;
            if (this.P1puska === 26 || this.P2puska === 26) addrez = addrez + 11;
            if (this.P1puska === 27 || this.P2puska === 27) addrez = addrez + 14;
            if (this.P1puska === 28 || this.P2puska === 28) addrez = addrez + 20;
            if (this.P1puska === 33 || this.P2puska === 33) addrez = addrez + 3;
            if (this.P1puska === 34 || this.P2puska === 34) addrez = addrez + 4;
            if (this.P1puska === 35 || this.P2puska === 35) addrez = addrez + 10;
            if (this.P1puska === 36 || this.P2puska === 36) addrez = addrez + 11;
            if (this.P1puska === 37 || this.P2puska === 37) addrez = addrez + 14;
            if (this.P1puska === 38 || this.P2puska === 38) addrez = addrez + 20;
            if (this.P1puska === 43 || this.P2puska === 43) addrez = addrez + 3;
            if (this.P1puska === 44 || this.P2puska === 44) addrez = addrez + 4;
            if (this.P1puska === 45 || this.P2puska === 45) addrez = addrez + 10;
            if (this.P1puska === 46 || this.P2puska === 46) addrez = addrez + 11;
            if (this.P1puska === 47 || this.P2puska === 47) addrez = addrez + 14;
            if (this.P1puska === 48 || this.P2puska === 48) addrez = addrez + 20;
            if (this.hand === 8) addrez = addrez + 10;
        }
        if (this.P1puska > 100 || this.P2puska > 100) {
            if (this.P1puska > this.P2puska) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                return 1;
            }
            if (this.P2puska > this.P1puska) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                return 2;
            }
        }
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            for (let i: number = 48; i > 40; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska kupa
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            for (let i: number = 38; i > 30; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska karo
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            for (let i: number = 28; i > 20; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska spatia
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            for (let i: number = 18; i > 10; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        return 1;
    }

    Intelect(player: number) {
        let card: number;
        this.online_timer = this.online_timer_time;
        if (this.Naddavane < 5) card = this.IntelectColor();
        if (this.Naddavane === 5) card = this.IntelectNT();
        if (this.Naddavane === 6) card = this.IntelectTrumps();
        let kdcard: number = this.k[card];
        if (this.Naddavane === 6) {
            kdcard = this.k[card];
            if (this.k[card] === 18) kdcard = 14;
            if (this.k[card] === 28) kdcard = 24;
            if (this.k[card] === 38) kdcard = 34;
            if (this.k[card] === 48) kdcard = 44;
            if (this.k[card] === 17) kdcard = 13;
            if (this.k[card] === 27) kdcard = 23;
            if (this.k[card] === 37) kdcard = 33;
            if (this.k[card] === 47) kdcard = 43;
            if (this.k[card] === 16) kdcard = 18;
            if (this.k[card] === 26) kdcard = 28;
            if (this.k[card] === 36) kdcard = 38;
            if (this.k[card] === 46) kdcard = 48;
            if (this.k[card] === 15) kdcard = 17;
            if (this.k[card] === 25) kdcard = 27;
            if (this.k[card] === 35) kdcard = 37;
            if (this.k[card] === 45) kdcard = 47;
            if (this.k[card] === 14) kdcard = 16;
            if (this.k[card] === 24) kdcard = 26;
            if (this.k[card] === 34) kdcard = 36;
            if (this.k[card] === 44) kdcard = 46;
            if (this.k[card] === 13) kdcard = 15;
            if (this.k[card] === 23) kdcard = 25;
            if (this.k[card] === 33) kdcard = 35;
            if (this.k[card] === 43) kdcard = 45;
        }
        if (this.Naddavane < 5) {
            kdcard = this.k[card];
            if (this.k[card] === 108) kdcard = this.Naddavane * 10 + 4;
            if (this.k[card] === 107) kdcard = this.Naddavane * 10 + 3;
            if (this.k[card] === 106) kdcard = this.Naddavane * 10 + 8;
            if (this.k[card] === 105) kdcard = this.Naddavane * 10 + 7;
            if (this.k[card] === 104) kdcard = this.Naddavane * 10 + 6;
            if (this.k[card] === 103) kdcard = this.Naddavane * 10 + 5;
            if (this.k[card] === 102) kdcard = this.Naddavane * 10 + 2;
            if (this.k[card] === 101) kdcard = this.Naddavane * 10 + 1;
        }
        for (let i: number = 1; i < 17; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                break;
            }
        }

        if (this.CheckBelot(this.k[card], player) === true) {
            this.ObiaviavaneBelot(player, true);
            this.Belot[player][0] = this.Belot[player][0] + 1;
            if (this.OP) {
                this.SendGame('$|o|' + this.IntToString(this.hand));
            }
        }

        if (player !== this.KoiPuskaParvi) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) this.Pniama[player][1] = true;
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) this.Pniama[player][2] = true;
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) this.Pniama[player][3] = true;
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) this.Pniama[player][4] = true;
            if (this.ParviPuska > 100 && this.k[card] < 100) this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 12 && this.k[card] === 11) this.Pniama[player][1] = true;
            if (this.ParviPuska === 22 && this.k[card] === 21) this.Pniama[player][2] = true;
            if (this.ParviPuska === 32 && this.k[card] === 31) this.Pniama[player][3] = true;
            if (this.ParviPuska === 42 && this.k[card] === 41) this.Pniama[player][4] = true;
            if (this.Naddavane === 6) {
                if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) this.Pniama[player][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) this.Pniama[player][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) this.Pniama[player][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) this.Pniama[player][4] = true;

                if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) this.Pniama[player][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) this.Pniama[player][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) this.Pniama[player][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) this.Pniama[player][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11)
                    this.Pniama[player][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21)
                    this.Pniama[player][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31)
                    this.Pniama[player][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41)
                    this.Pniama[player][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12)
                    this.Pniama[player][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22)
                    this.Pniama[player][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32)
                    this.Pniama[player][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42)
                    this.Pniama[player][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13)
                    this.Pniama[player][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23)
                    this.Pniama[player][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33)
                    this.Pniama[player][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43)
                    this.Pniama[player][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.k[card] === 11
                )
                    this.Pniama[player][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.k[card] === 21
                )
                    this.Pniama[player][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.k[card] === 31
                )
                    this.Pniama[player][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.k[card] === 41
                )
                    this.Pniama[player][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 12
                )
                    this.Pniama[player][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 22
                )
                    this.Pniama[player][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 32
                )
                    this.Pniama[player][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 42
                )
                    this.Pniama[player][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 13
                )
                    this.Pniama[player][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 23
                )
                    this.Pniama[player][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 33
                )
                    this.Pniama[player][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 43
                )
                    this.Pniama[player][4] = true;

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 14
                )
                    this.Pniama[player][1] = true;
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 24
                )
                    this.Pniama[player][2] = true;
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 34
                )
                    this.Pniama[player][3] = true;
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 44
                )
                    this.Pniama[player][4] = true;
            }
        }
        if (this.Naddavane < 5) {
            if (this.ParviPuska === 102 && this.k[card] === 101) this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(102) === false && this.k[card] === 101)
                this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(101) === false && this.k[card] === 102)
                this.Pniama[player][this.Naddavane] = true;
            if (this.ParviPuska === 104 && this.CheckCard(102) === false && this.CheckCard(101) === false && this.k[card] === 103)
                this.Pniama[player][this.Naddavane] = true;
        }
        if (this.hand === 0) {
            this.terci[player][0] = true;
            this.terci[player][1] = true;
            for (let b: number = 0; b <= 1; b++) {
                if (this.PTerci[player][b] > 0) {
                    if (
                        !(
                            this.PTerci[player][0] > 0 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 7 &&
                            this.PTerci[player][1] > 0 &&
                            this.PTerci[player][1] < 7
                        ) &&
                        !(
                            this.PTerci[player][0] > 10 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 16 &&
                            this.PTerci[player][1] > 10 &&
                            this.PTerci[player][1] < 16
                        ) &&
                        !(
                            this.PTerci[player][0] > 30 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 37 &&
                            this.PTerci[player][1] > 30 &&
                            this.PTerci[player][1] < 37
                        )
                    ) {
                        this.ObiaviavaneTerci(player, this.PTerci[player][b], b, true);
                    }
                    if (b === 0) {
                        if (
                            this.PTerci[player][0] > 0 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 7 &&
                            this.PTerci[player][1] > 0 &&
                            this.PTerci[player][1] < 7
                        ) {
                            this.ObiaviavaneTerci(player, 7, 0, true);
                        }
                        if (
                            this.PTerci[player][0] > 10 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 16 &&
                            this.PTerci[player][1] > 10 &&
                            this.PTerci[player][1] < 16
                        ) {
                            this.ObiaviavaneTerci(player, 16, 0, true);
                        }
                        if (
                            this.PTerci[player][0] > 30 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 37 &&
                            this.PTerci[player][1] > 30 &&
                            this.PTerci[player][1] < 37
                        ) {
                            this.ObiaviavaneTerci(player, 37, 0, true);
                        }
                    }
                    if (this.OP) {
                        let message: string = '$|q|' + this.IntToString(player) + '|' + this.IntToString(b);
                        this.SendGame(message);
                    }
                }
            }
        }
        if (this.OP === true) {
            //char c[3];
            let ca: number = card;
            while (ca > 8) ca = ca - 8;
            let car: string = this.IntToString(ca);
            if (this.Server === true) {
                this.SendGame('$|k|' + car);
            } else {
                this.SendGame('$|l|' + car);
            }
        }
        this.CardsPoint[player + 32] = this.CardsPoint[card];
        this.CardP[32 + player] = this.CardP[card];
        this.BeginMove(this.CardsPoint[card], this.CardsPos[player + 32], 32 + player);
        if (player === 1) this.P1puska = this.k[card];
        if (player === 2) this.P2puska = this.k[card];

        if (this.KoiPuskaParvi === 1) {
            if (player === 2) {
                if (this.Naddavane === 6 && this.ParviPuska === 12 && this.k[card] === 11) this.Pniama[player][1] = true;
                if (this.Naddavane === 6 && this.ParviPuska === 22 && this.k[card] === 21) this.Pniama[player][2] = true;
                if (this.Naddavane === 6 && this.ParviPuska === 32 && this.k[card] === 31) this.Pniama[player][3] = true;
                if (this.Naddavane === 6 && this.ParviPuska === 42 && this.k[card] === 41) this.Pniama[player][4] = true;
                if (this.Naddavane < 5 && this.ParviPuska === 102 && this.k[card] === 101) this.Pniama[player][this.Naddavane] = true;
                this.VtoriPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (player === 2) this.ParviPuska = this.k[card];
        }
        this.DeleteCard(card);
        //	if(this.P1puska==0||this.P2puska==0)
        {
            if (this.KoiENaRed < 2) this.KoiENaRed = this.KoiENaRed + 1;
            else this.KoiENaRed = 1;
        }
    }

    IntelectColor(): number {
        let Broi: number = 0;
        let NiamaBoia: boolean[] = [false, false, false, false, false];
        let MBroiOP: number[] = [0, 0, 0, 0, 0];
        let MyCards: number[][][] = []; //[5][6][3]//boia nomer karta i bigger than
        for (let i = 0; i < 5; i++) {
            MyCards[i] = [];
            for (let a = 0; a < 6; a++) {
                MyCards[i][a] = [];
            }
        }
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let k1: number[] = [];
        for (let i: number = 1; i < 25; i++) {
            k1[i] = 0;
        }
        for (let i: number = 1; i < 25; i++) {
            if (i < 17 && this.k[i] < 100) {
                k1[i] = this.k[i] - 2;
            }
            if (i < 17 && this.k[i] > 100) {
                k1[i] = ((this.k[i] - 2) % 10) + this.Naddavane * 10;
            }
            if (this.k[i] < 100 && this.k[i] / 10 !== this.Naddavane && this.k[i] > 0 && i > 17) {
                if (this.k[i] % 10 === 3) {
                    k1[i] = this.k[i] - 2;
                }
                if (this.k[i] % 10 === 5) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 6) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 7) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 4) {
                    k1[i] = this.k[i] + 1;
                }
                if (this.k[i] % 10 === 8) {
                    k1[i] = this.k[i] - 2;
                }
            }
            if ((this.k[i] > 100 || this.k[i] / 10 === this.Naddavane) && this.k[i] > 0 && i > 17) {
                if (this.k[i] % 10 === 6) {
                    k1[i] = this.Naddavane * 10 + 1;
                }
                if (this.k[i] % 10 === 7) {
                    k1[i] = this.Naddavane * 10 + 2;
                }
                if (this.k[i] % 10 === 4) {
                    k1[i] = this.Naddavane * 10 + 3;
                }
                if (this.k[i] % 10 === 8) {
                    k1[i] = this.Naddavane * 10 + 4;
                }
                if (this.k[i] % 10 === 3) {
                    k1[i] = this.Naddavane * 10 + 5;
                }
                if (this.k[i] % 10 === 5) {
                    k1[i] = this.Naddavane * 10 + 6;
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            if (k1[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[k1[i] / 10]++;
            }
        }
        for (let i: number = 17; i < 25; i++) {
            if (k1[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[k1[i] / 10]++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            if (MBroiOP[i] === 0 || this.Niama[i]) {
                NiamaBoia[i] = true;
                MBroiOP[i] = 0;
            }
        }
        for (let i: number = 0; i < 5; i++)
            for (let j: number = 0; j < 6; j++) {
                MyCards[i][j][1] = 0;
                MyCards[i][j][0] = -1;
                MyCards[i][j][2] = 0;
            }
        for (let i: number = 9; i < 17; i++) {
            if (this.k[i] > 0) {
                MyCards[k1[i] / 10][BroiBoia[k1[i] / 10]][0] = k1[i];
                BroiBoia[k1[i] / 10]++;
                Broi++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            for (let j: number = 0; j < 6; j++) {
                for (let k1: number = j + 1; k1 < 6; k1++) {
                    if (MyCards[i][j][0] < MyCards[i][k1][0]) {
                        let temp: number = MyCards[i][j][0];
                        MyCards[i][j][0] = MyCards[i][k1][0];
                        MyCards[i][k1][0] = temp;
                    }
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        for (let i: number = 17; i < 25; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (
                    k1[i] > MyCards[k1[i] / 10][j][0] &&
                    MyCards[k1[i] / 10][j][0] !== 0 &&
                    (k1[i] < this.MVtoriPuska[k1[i] / 10] || k1[i] / 10 !== this.Naddavane)
                ) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        let Card11: number = -1;
        if (this.ParviPuska === 0) {
            let DaTegli: boolean = false;
            let ProbHaveT: boolean = true;
            if (this.Niama[this.Naddavane] || MBroiOP[this.Naddavane] === 0) {
                ProbHaveT = false;
            }
            let gotoPPP: boolean = false;
            for (let i: number = 1; i < 25; i++) {
                if (i < 9 || i > 16) {
                    if (k1[i] / 10 === this.Naddavane) {
                        if (k1[i] > this.LIz4istena[this.Naddavane]) {
                            gotoPPP = true;
                        }
                    }
                }
            }
            if (gotoPPP === false) {
                ProbHaveT = false;
            }
            if (ProbHaveT && BroiBoia[this.Naddavane] > 0 && BroiBoia[this.Naddavane] >= MBroiOP[this.Naddavane]) {
                DaTegli = true;
            }
            if (DaTegli) {
                //tegli kozovete
                if (BroiBoia[this.Naddavane] > 0) {
                    if (MyCards[this.Naddavane][0][2] === 0) {
                        Card11 = MyCards[this.Naddavane][0][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                    if (MyCards[this.Naddavane][0][2] === 1) {
                        if (MyCards[this.Naddavane][1][2] === 1 && BroiBoia[this.Naddavane] >= 2) {
                            if (MyCards[this.Naddavane][2][2] === 1 && BroiBoia[this.Naddavane] >= 3) {
                                if (MyCards[this.Naddavane][3][2] === 1 && BroiBoia[this.Naddavane] >= 4) {
                                    Card11 = MyCards[this.Naddavane][3][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[this.Naddavane][2][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[this.Naddavane][1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                        if (MyCards[this.Naddavane][0][0] % 10 < 4 || BroiBoia[this.Naddavane] <= 2) {
                            Card11 = MyCards[this.Naddavane][0][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    Card11 = MyCards[this.Naddavane][BroiBoia[this.Naddavane] - 1][0];
                    return this.IntelectColorEnd(Card11, Broi, k1);
                }
            }
            if (!DaTegli) {
                if (ProbHaveT) {
                    //ako protivnika ima kozove
                    for (let i: number = 1; i < 5; i++) {
                        if (i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 0 && !this.Niama[i]) {
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (i !== this.Naddavane) {
                            if (MyCards[i][0][2] === 0 && !this.Niama[i]) {
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (i !== this.Naddavane) {
                            if (this.Niama[i]) {
                                if (MyCards[i][BroiBoia[i] - 1][0] % 10 <= 4) {
                                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3 && i !== this.Naddavane && MyCards[i][0][1] >= 2) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && i !== this.Naddavane && MyCards[i][0][1] >= 2) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] > MBroiOP[i] && BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 1) {
                                if (MyCards[i][1][1] === 1) {
                                    if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                if (MyCards[i][0][0] % 10 !== 5) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            if (MyCards[i][0][2] === 1) {
                                if (MyCards[i][1][2] === 1) {
                                    if (MyCards[i][2][2] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][2] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                if (MyCards[i][0][0] % 10 !== 5) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= 1 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 1) {
                                if (MyCards[i][1][1] === 1 && BroiBoia[i] >= 2) {
                                    if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                }
                if (!ProbHaveT) {
                    //ako veroiatno niama
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 0) {
                                if (MyCards[i][1][1] === 0) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 0) {
                                if (MyCards[i][1][2] === 0) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 0) {
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            if (MyCards[i][0][2] === 0) {
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3 && i !== this.Naddavane) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (
                            BroiBoia[i] <= 2 &&
                            BroiBoia[i] > 0 &&
                            MBroiOP[i] >= 3 &&
                            MyCards[i][0][0] % 10 <= 3 &&
                            Broi >= 4 &&
                            i !== this.Naddavane
                        ) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] === 2 && i !== this.Naddavane) {
                            if (MyCards[i][0][2] === 0) {
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] > MBroiOP[i] && BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 1) {
                                if (MyCards[i][1][1] === 1) {
                                    if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                if (MyCards[i][0][0] % 10 !== 5) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            if (MyCards[i][0][2] === 1) {
                                if (MyCards[i][1][2] === 1) {
                                    if (MyCards[i][2][2] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][2] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                if (MyCards[i][0][0] % 10 !== 5) {
                                    Card11 = MyCards[i][0][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3 && i !== this.Naddavane) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (MyCards[i][0][2] === 0 && BroiBoia[i] > 0 && i !== this.Naddavane) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= 1 && i !== this.Naddavane) {
                            if (MyCards[i][0][1] === 1) {
                                if (MyCards[i][1][1] === 1 && BroiBoia[i] >= 2) {
                                    if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                        if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                            Card11 = MyCards[i][3][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                        Card11 = MyCards[i][2][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    Card11 = MyCards[i][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                        }
                    }
                    for (let i: number = 1; i < 5; i++) {
                        if (BroiBoia[i] >= 2 && i !== this.Naddavane) {
                            Card11 = MyCards[i][BroiBoia[i] - 1][0];
                            return this.IntelectColorEnd(Card11, Broi, k1);
                        }
                    }
                }
                for (let i: number = 1; i < 5; i++) {
                    if (BroiBoia[i] >= 2) {
                        Card11 = MyCards[i][BroiBoia[i] - 1][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 1) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectColorEnd(Card11, Broi, k1);
                }
            }
        }
        //////ako komp igrae vtori
        if (this.ParviPuska !== 0) {
            let ParviPuska1: number = 0;
            if (this.ParviPuska > 100) {
                //preobrazuva parvi puska v po udoben vid
                ParviPuska1 = (this.ParviPuska % 10) - 2 + 10 * this.Naddavane;
            }
            if (this.ParviPuska > 0 && this.ParviPuska < 100) {
                ParviPuska1 = this.ParviPuska - 2;
            }
            if (ParviPuska1 / 10 === this.Naddavane) {
                //ako parvia e igral koz
                if (BroiBoia[this.Naddavane] > 0) {
                    if (MyCards[this.Naddavane][0][0] > ParviPuska1) {
                        if (BroiBoia[this.Naddavane] >= 2) {
                            if (BroiBoia[this.Naddavane] >= 3) {
                                //ako ima pone 3 karti v koza
                                for (let w: number = BroiBoia[this.Naddavane] - 1; w >= 0; w--) {
                                    if (
                                        MyCards[this.Naddavane][w][0] > ParviPuska1 &&
                                        (MyCards[this.Naddavane][w - 1][1] < MyCards[this.Naddavane][w][1] || w === 0)
                                    ) {
                                        Card11 = MyCards[this.Naddavane][w][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                }
                            }
                            if (MyCards[this.Naddavane][1][0] > ParviPuska1) {
                                if (MyCards[this.Naddavane][1][0] > ParviPuska1) {
                                    if (MyCards[this.Naddavane][0][1] === 0) {
                                        Card11 = MyCards[this.Naddavane][1][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                    if (MyCards[this.Naddavane][0][1] === 1 && MyCards[this.Naddavane][0][1] >= 2) {
                                        if (MBroiOP[this.Naddavane] <= 2) {
                                            Card11 = MyCards[this.Naddavane][1][0];
                                            return this.IntelectColorEnd(Card11, Broi, k1);
                                        }
                                    }
                                }
                            }
                        }
                        Card11 = MyCards[this.Naddavane][0][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    } else {
                        Card11 = MyCards[this.Naddavane][BroiBoia[this.Naddavane] - 1][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                } else {
                    Card11 = this.Chistene(k1);
                    return this.IntelectColorEnd(Card11, Broi, k1);
                }
            }
            //
            if (ParviPuska1 / 10 !== this.Naddavane) {
                if (BroiBoia[ParviPuska1 / 10] > 0) {
                    //otgovaria
                    if (MyCards[ParviPuska1 / 10][0][0] < ParviPuska1) {
                        Card11 = MyCards[ParviPuska1 / 10][BroiBoia[ParviPuska1 / 10] - 1][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                    if (MyCards[ParviPuska1 / 10][0][0] > ParviPuska1) {
                        if (BroiBoia[this.ParviPuska / 10] >= 2) {
                            if (
                                ParviPuska1 % 10 >= 4 ||
                                MyCards[ParviPuska1 / 10][0][1] > 0 ||
                                MyCards[ParviPuska1 / 10][1][1] === 0 ||
                                MBroiOP[ParviPuska1 / 10] === 0
                            ) {
                                Card11 = MyCards[ParviPuska1 / 10][0][0];
                                return this.IntelectColorEnd(Card11, Broi, k1);
                            }
                            if (MBroiOP[ParviPuska1 / 10] >= 2) {
                                for (let w: number = BroiBoia[ParviPuska1 / 10] - 1; w >= 0; w--) {
                                    if (
                                        MyCards[ParviPuska1 / 10][w][0] > ParviPuska1 &&
                                        (MyCards[ParviPuska1 / 10][w - 1][1] < MyCards[ParviPuska1 / 10][w][1] || w === 0)
                                    ) {
                                        Card11 = MyCards[ParviPuska1 / 10][w][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                }
                            }
                        }
                        Card11 = MyCards[ParviPuska1 / 10][0][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                }
                if (BroiBoia[ParviPuska1 / 10] === 0) {
                    if (BroiBoia[this.Naddavane] > 0) {
                        //caka(zaduljitelno e)
                        if (BroiBoia[this.Naddavane] >= 2) {
                            if (MyCards[this.Naddavane][0][1] === 0 || BroiBoia[this.Naddavane] >= 3) {
                                for (let w: number = BroiBoia[this.Naddavane] - 1; w >= 0; w--) {
                                    if (MyCards[this.Naddavane][w - 1][1] < MyCards[this.Naddavane][w][1] || w === 0) {
                                        Card11 = MyCards[this.Naddavane][w][0];
                                        return this.IntelectColorEnd(Card11, Broi, k1);
                                    }
                                }
                            }
                            if (MyCards[this.Naddavane][0][1] === 1 && MyCards[this.Naddavane][0][1] >= 2) {
                                if (MBroiOP[this.Naddavane] <= 2) {
                                    Card11 = MyCards[this.Naddavane][1][0];
                                    return this.IntelectColorEnd(Card11, Broi, k1);
                                }
                            }
                        }
                        Card11 = MyCards[this.Naddavane][0][0];
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    } else {
                        Card11 = this.Chistene(k1);
                        return this.IntelectColorEnd(Card11, Broi, k1);
                    }
                }
            }
        }
        return this.IntelectColorEnd(Card11, Broi, k1);
    }

    Chistene(k1: number[]): number {
        let Broi: number = 0;
        let NiamaBoia: boolean[] = [false, false, false, false, false];
        let MBroiOP: number[] = [0, 0, 0, 0, 0];
        let MyCards: number[][][] = []; //[5][6][2];//boia nomer karta i bigger than
        for (let i = 0; i < 5; i++) {
            MyCards[i] = [];
            for (let a = 0; a < 6; a++) {
                MyCards[i][a] = [];
            }
        }
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        for (let i: number = 1; i < 9; i++) {
            if (this.k[i] > 0) {
                MBroiOP[k1[i] / 10]++;
            }
        }
        for (let i: number = 17; i < 25; i++) {
            if (this.k[i] > 0) {
                MBroiOP[k1[i] / 10]++;
            }
        }
        for (let i: number = 0; i < 5; i++) {
            if (MBroiOP[i] === 0 || this.Niama[i]) {
                NiamaBoia[i] = true;
                MBroiOP[i] = 0;
            }
        }
        for (let i: number = 0; i < 5; i++)
            for (let j: number = 0; j < 6; j++) {
                MyCards[i][j][1] = 0;
                MyCards[i][j][0] = -1;
            }
        for (let i: number = 9; i < 17; i++) {
            MyCards[k1[i] / 10][BroiBoia[k1[i] / 10]][0] = k1[i];
            BroiBoia[k1[i] / 10]++;
            Broi++;
        }
        for (let i: number = 1; i < 5; i++) {
            for (let j: number = 0; j < 6; j++) {
                for (let k1: number = j + 1; k1 < 6; k1++) {
                    if (MyCards[i][j][0] < MyCards[i][k1][0]) {
                        let temp = MyCards[i][j][0];
                        MyCards[i][j][0] = MyCards[i][k1][0];
                        MyCards[i][k1][0] = temp;
                    }
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    MyCards[k1[i] / 10][j][1]++;
                }
            }
        }
        for (let i: number = 17; i < 25; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    MyCards[k1[i] / 10][j][1]++;
                }
            }
        }
        let ques: number[] = [0, 0, 0, 0, 0];
        for (let i: number = 1; i < 5; i++) {
            if (BroiBoia[i] === 0) {
                ques[i] += 20000;
            }
            if (BroiBoia[i] > 0) {
                //dava bonus za to4kite
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 6) ques[i] += 1000;
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 5) ques[i] += 800;
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 4) {
                    if (i === this.Naddavane || this.Naddavane === 6) ques[i] += 600;
                    else {
                        if (this.Naddavane === 5) {
                            ques[i] += 250;
                        } else {
                            ques[i] += 100;
                        }
                    }
                }
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 3) {
                    if (i === this.Naddavane || this.Naddavane === 6) ques[i] += 500;
                    else {
                        if (this.Naddavane === 5) {
                            ques[i] += 150;
                        } else {
                            ques[i] += 50;
                        }
                    }
                }
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 2) {
                    ques[i] += 25;
                }
                if (MyCards[i][BroiBoia[i] - 1][0] % 10 === 1) {
                    if ((i === this.Naddavane || this.Naddavane === 6) && BroiBoia[i] >= 2 && MyCards[i][BroiBoia[i] - 2][0] % 10 === 2)
                        ques[i] += 200;
                }
                //dava bonus za miastoto
                if (NiamaBoia || MyCards[i][BroiBoia[i] - 1][1] === 0) {
                    ques[i] += 400;
                }
                //dava bonus za prikrivane
                if (BroiBoia[i] === 2 || BroiBoia[i] === 3) {
                    if (MyCards[i][0][1] === 1 || MyCards[i][0][1] === 2) {
                        ques[i] += 150;
                    }
                }
                //dava binus za duljina
                if (BroiBoia[i] - MBroiOP[i] >= 0) {
                    ques[i] += 250;
                }
                if (MBroiOP[i] <= 1 && BroiBoia[i] <= 3) {
                    ques[i] -= 100;
                }
                //maha bonus ako ne moje da se izpolzva
            }
        }
        let Ca: number = 1;
        let qq: number = ques[1];
        for (let i: number = 1; i < 5; i++) {
            if (ques[i] < qq) {
                Ca = i;
                qq = ques[i];
            }
        }
        return MyCards[Ca][BroiBoia[Ca] - 1][0];
    }

    IntelectColorEnd(Card11: number, Broi: number, k1: number[]): number {
        if (Card11 < 10) {
            return 9;
        }
        if (Broi === 1) {
            return 9;
        }
        for (let i: number = 9; i < 17; i++) {
            if (k1[i] === Card11) {
                if (Broi === 1) {
                    //let zaab: number=0;
                }
                return i;
            }
        }
    }
    IntelectNT(): number {
        let Broi: number = 0;
        let NiamaBoia: boolean[] = [false, false, false, false, false];
        let MBroiOP: number[] = [0, 0, 0, 0, 0];
        let MyCards: number[][][] = []; //[5][6][3]//boia nomer karta i bigger than
        for (let i = 0; i < 5; i++) {
            MyCards[i] = [];
            for (let a = 0; a < 6; a++) {
                MyCards[i][a] = [];
            }
        }
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let k1: number[] = [];
        for (let i: number = 1; i < 25; i++) {
            k1[i] = 0;
        }
        for (let i: number = 1; i < 25; i++) {
            if (this.k[i] > 0 && i <= 16) {
                k1[i] = this.k[i] - 2;
            }
            if (this.k[i] > 0 && i > 16) {
                if (this.k[i] % 10 === 3) {
                    k1[i] = this.k[i] - 2;
                }
                if (this.k[i] % 10 === 5) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 6) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 7) {
                    k1[i] = this.k[i] - 3;
                }
                if (this.k[i] % 10 === 4) {
                    k1[i] = this.k[i] + 1;
                }
                if (this.k[i] % 10 === 8) {
                    k1[i] = this.k[i] - 2;
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            if (this.k[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[this.k[i] / 10]++;
            }
        }
        for (let i: number = 17; i < 25; i++) {
            if (this.k[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[this.k[i] / 10]++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            if (MBroiOP[i] === 0 || this.Niama[i]) {
                NiamaBoia[i] = true;
                MBroiOP[i] = 0;
            }
        }
        for (let i: number = 0; i < 5; i++)
            for (let j: number = 0; j < 6; j++) {
                MyCards[i][j][1] = 0;
                MyCards[i][j][0] = -1;
                MyCards[i][j][2] = 0;
            }
        for (let i: number = 9; i < 17; i++) {
            if (this.k[i] > 0) {
                MyCards[k1[i] / 10][BroiBoia[k1[i] / 10]][0] = k1[i];
                BroiBoia[k1[i] / 10]++;
                Broi++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            for (let j: number = 0; j < 6; j++) {
                for (let k1: number = j + 1; k1 < 6; k1++) {
                    if (MyCards[i][j][0] < MyCards[i][k1][0]) {
                        let temp: number = MyCards[i][j][0];
                        MyCards[i][j][0] = MyCards[i][k1][0];
                        MyCards[i][k1][0] = temp;
                    }
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        for (let i: number = 17; i < 25; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        let Card11: number = -1;
        if (this.ParviPuska === 0) {
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        if (MyCards[i][1][1] === 0) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        if (MyCards[i][1][2] === 0) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectNTEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][2] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectNTEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] <= 2 && BroiBoia[i] > 0 && MBroiOP[i] >= 3 && MyCards[i][0][0] % 10 <= 3 && Broi >= 4) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] === 2) {
                    if (MyCards[i][0][2] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectNTEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] > MBroiOP[i] && BroiBoia[i] >= 2) {
                    if (MyCards[i][0][1] === 1) {
                        if (MyCards[i][1][1] === 1) {
                            if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectNTEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectNTEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                        if (MyCards[i][0][0] % 10 !== 5) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2) {
                    if (MyCards[i][0][2] === 1) {
                        if (MyCards[i][1][2] === 1) {
                            if (MyCards[i][2][2] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][2] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectNTEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectNTEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                        if (MyCards[i][0][0] % 10 !== 5) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 0 && BroiBoia[i] > 0) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (MyCards[i][0][2] === 0 && BroiBoia[i] > 0) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 1) {
                    if (MyCards[i][0][1] === 1) {
                        if (MyCards[i][1][1] === 1 && BroiBoia[i] >= 2) {
                            if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectNTEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectNTEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                        Card11 = MyCards[i][0][0];
                        return this.IntelectNTEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 2) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 1) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
        }
        //////ako komp igrae vtori
        if (this.ParviPuska !== 0) {
            let ParviPuska1: number = 0;
            ParviPuska1 = this.ParviPuska - 2;
            if (BroiBoia[ParviPuska1 / 10] > 0) {
                if (MyCards[ParviPuska1 / 10][0][0] < ParviPuska1) {
                    Card11 = MyCards[ParviPuska1 / 10][BroiBoia[ParviPuska1 / 10] - 1][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
                if (MyCards[ParviPuska1 / 10][0][0] > ParviPuska1) {
                    if (BroiBoia[this.ParviPuska / 10] >= 2) {
                        if (MyCards[ParviPuska1 / 10][1][0] > ParviPuska1) {
                            if (MyCards[ParviPuska1 / 10][0][1] === 0 || BroiBoia[ParviPuska1 / 10] >= 3) {
                                for (let w: number = BroiBoia[ParviPuska1 / 10] - 1; w >= 0; w--) {
                                    if (
                                        MyCards[ParviPuska1 / 10][w][0] > ParviPuska1 &&
                                        (MyCards[ParviPuska1 / 10][w - 1][1] < MyCards[ParviPuska1 / 10][w][1] || w === 0)
                                    ) {
                                        Card11 = MyCards[ParviPuska1 / 10][w][0];
                                        return this.IntelectNTEnd(Card11, Broi, k1);
                                    }
                                }
                            }
                        }
                        if (
                            MyCards[ParviPuska1 / 10][1][0] < ParviPuska1 &&
                            ParviPuska1 % 10 <= 3 &&
                            BroiBoia[this.ParviPuska / 10] === 2 &&
                            MyCards[ParviPuska1 / 10][0][1] === 0 &&
                            MBroiOP[ParviPuska1 / 10] >= 2
                        ) {
                            Card11 = MyCards[ParviPuska1 / 10][1][0];
                            return this.IntelectNTEnd(Card11, Broi, k1);
                        }
                    }
                    Card11 = MyCards[ParviPuska1 / 10][0][0];
                    return this.IntelectNTEnd(Card11, Broi, k1);
                }
            }
            if (BroiBoia[ParviPuska1 / 10] === 0) {
                Card11 = this.Chistene(k1);
                return this.IntelectNTEnd(Card11, Broi, k1);
            }
        }
        return this.IntelectNTEnd(Card11, Broi, k1);
    }

    IntelectNTEnd(Card11: number, Broi: number, k1: number[]): number {
        if (Card11 < 10) {
            return 9;
        }
        if (Broi === 1) {
            return 9;
        }
        for (let i: number = 9; i < 17; i++) {
            if (k1[i] === Card11) {
                return i;
            }
        }
    }

    IntelectTrumps(): number {
        let Broi: number = 0;
        let NiamaBoia: boolean[] = [false, false, false, false, false];
        let MBroiOP: number[] = [0, 0, 0, 0, 0];
        let MyCards: number[][][] = []; //[5][6][3]//boia nomer karta i bigger than
        for (let i = 0; i < 5; i++) {
            MyCards[i] = [];
            for (let a = 0; a < 6; a++) {
                MyCards[i][a] = [];
            }
        }
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let k1: number[] = [];
        for (let i: number = 1; i < 25; i++) {
            k1[i] = 0;
        }
        for (let i: number = 1; i < 25; i++) {
            if (i <= 16 && this.k[i] > 0) k1[i] = this.k[i] - 2;
            if (i > 16) {
                if (this.k[i] % 10 === 6) {
                    k1[i] = this.k[i] - 5;
                }
                if (this.k[i] % 10 === 7) {
                    k1[i] = this.k[i] - 5;
                }
                if (this.k[i] % 10 === 4) {
                    k1[i] = this.k[i] - 1;
                }
                if (this.k[i] % 10 === 8) {
                    k1[i] = this.k[i] - 4;
                }
                if (this.k[i] % 10 === 3) {
                    k1[i] = this.k[i] + 2;
                }
                if (this.k[i] % 10 === 5) {
                    k1[i] = this.k[i] + 1;
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            if (this.k[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[this.k[i] / 10]++;
            }
        }
        for (let i: number = 17; i < 25; i++) {
            if (this.k[i] > 0 && k1[i] < this.MVtoriPuska[this.k[i] / 10]) {
                MBroiOP[this.k[i] / 10]++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            if (MBroiOP[i] === 0 || this.Niama[i]) {
                NiamaBoia[i] = true;
                MBroiOP[i] = 0;
            }
        }
        for (let i: number = 0; i < 5; i++)
            for (let j: number = 0; j < 6; j++) {
                MyCards[i][j][1] = 0;
                MyCards[i][j][0] = -1;
                MyCards[i][j][2] = 0;
            }
        for (let i: number = 9; i < 17; i++) {
            if (this.k[i] > 0) {
                MyCards[k1[i] / 10][BroiBoia[k1[i] / 10]][0] = k1[i];
                BroiBoia[k1[i] / 10]++;
                Broi++;
            }
        }
        for (let i: number = 1; i < 5; i++) {
            for (let j: number = 0; j < 6; j++) {
                for (let k1: number = j + 1; k1 < 6; k1++) {
                    if (MyCards[i][j][0] < MyCards[i][k1][0]) {
                        let temp: number = MyCards[i][j][0];
                        MyCards[i][j][0] = MyCards[i][k1][0];
                        MyCards[i][k1][0] = temp;
                    }
                }
            }
        }
        for (let i: number = 1; i < 9; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        for (let i: number = 17; i < 25; i++) {
            for (let j: number = 0; j < 6; j++) {
                if (k1[i] > MyCards[k1[i] / 10][j][0] && MyCards[k1[i] / 10][j][0] !== 0 && k1[i] < this.MVtoriPuska[k1[i] / 10]) {
                    if (!NiamaBoia[k1[i] / 10]) {
                        MyCards[k1[i] / 10][j][1]++;
                        if (k1[i] > this.LIz4istena[k1[i] / 10]) {
                            MyCards[k1[i] / 10][j][2]++;
                        }
                    }
                }
            }
        }
        let Card11: number = -1;
        if (this.ParviPuska === 0) {
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        if (MyCards[i][1][1] === 0) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectTrumpsEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        if (MyCards[i][1][2] === 0) {
                            Card11 = MyCards[i][0][0];
                            return this.IntelectTrumpsEnd(Card11, Broi, k1);
                        }
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][1] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] > 0) {
                    if (MyCards[i][0][2] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] === 2) {
                    if (MyCards[i][0][2] === 0) {
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] > MBroiOP[i] && BroiBoia[i] >= 2) {
                    if (MyCards[i][0][1] === 1) {
                        if (MyCards[i][1][1] === 1) {
                            if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectTrumpsEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectTrumpsEnd(Card11, Broi, k1);
                        }
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 2) {
                    if (MyCards[i][0][1] === 1) {
                        if (MyCards[i][1][1] === 1) {
                            if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectTrumpsEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectTrumpsEnd(Card11, Broi, k1);
                        }
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= MBroiOP[i] && BroiBoia[i] >= 3) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 0 && BroiBoia[i] > 0) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (MyCards[i][0][2] === 0 && BroiBoia[i] > 0) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 1) {
                    if (MyCards[i][0][1] === 1) {
                        if (MyCards[i][1][1] === 1 && BroiBoia[i] >= 2) {
                            if (MyCards[i][2][1] === 1 && BroiBoia[i] >= 3) {
                                if (MyCards[i][3][1] === 1 && BroiBoia[i] >= 4) {
                                    Card11 = MyCards[i][3][0];
                                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                                }
                                Card11 = MyCards[i][2][0];
                                return this.IntelectTrumpsEnd(Card11, Broi, k1);
                            }
                            Card11 = MyCards[i][1][0];
                            return this.IntelectTrumpsEnd(Card11, Broi, k1);
                        }
                        Card11 = MyCards[i][0][0];
                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                    }
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 2) {
                    Card11 = MyCards[i][BroiBoia[i] - 1][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
            for (let i: number = 1; i < 5; i++) {
                if (BroiBoia[i] >= 1) {
                    Card11 = MyCards[i][0][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
        }
        //////ako komp igrae vtori
        if (this.ParviPuska !== 0) {
            let ParviPuska1: number = 0;
            if (this.ParviPuska > 0) {
                //preobrazuva parvi puska v po udoben vid
                ParviPuska1 = this.ParviPuska - 2;
            }
            if (BroiBoia[ParviPuska1 / 10] > 0) {
                if (MyCards[ParviPuska1 / 10][0][0] < ParviPuska1) {
                    Card11 = MyCards[ParviPuska1 / 10][BroiBoia[ParviPuska1 / 10] - 1][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
                if (MyCards[ParviPuska1 / 10][0][0] > ParviPuska1) {
                    if (BroiBoia[this.ParviPuska / 10] >= 2) {
                        if (MyCards[ParviPuska1 / 10][1][0] > ParviPuska1) {
                            if (MyCards[ParviPuska1 / 10][0][1] === 0 || BroiBoia[ParviPuska1 / 10] >= 3) {
                                for (let w: number = BroiBoia[ParviPuska1 / 10] - 1; w >= 0; w--) {
                                    if (
                                        MyCards[ParviPuska1 / 10][w][0] > ParviPuska1 &&
                                        (MyCards[ParviPuska1 / 10][w - 1][1] < MyCards[ParviPuska1 / 10][w][1] || w === 0)
                                    ) {
                                        Card11 = MyCards[ParviPuska1 / 10][w][0];
                                        return this.IntelectTrumpsEnd(Card11, Broi, k1);
                                    }
                                }
                            }
                        }
                    }
                    Card11 = MyCards[ParviPuska1 / 10][0][0];
                    return this.IntelectTrumpsEnd(Card11, Broi, k1);
                }
            }
            if (BroiBoia[ParviPuska1 / 10] === 0) {
                Card11 = this.Chistene(k1);
                return this.IntelectTrumpsEnd(Card11, Broi, k1);
            }
        }
        return this.IntelectTrumpsEnd(Card11, Broi, k1);
    }

    IntelectTrumpsEnd(Card11: number, Broi: number, k1: number[]): number {
        if (Card11 < 10) {
            return 9;
        }
        if (Broi === 1) {
            return 9;
        }
        for (let i: number = 9; i < 17; i++) {
            if (k1[i] === Card11) {
                return i;
            }
        }
    }

    async Timer2() {
        if (this.Move !== true) {
            if (this.SP) {
                if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true) {
                    if (this.BrPas === 2) {
                        await this.InitGame2();
                        this.setState({});
                        return;
                    }
                    if (this.BrPas === 1 && this.Naddavane > 0) {
                        this.Faza = 3;
                        this.Razdavane2 = true;
                        this.obiaviavaneRN = null;
                        this.setState({});
                        if (GlobalVariables.Zvuk === 1) {
                            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                        }
                        return;
                    }
                    if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 2 && (this.BrPas !== 1 || this.Naddavane === 0)) {
                        this.KoiObiaviava = 0;
                        this.m_n.naddavane = this.Naddavane;

                        if (this.Naddavane === 0) {
                            this.m_n.kontra = false;
                            this.m_n.rekontra = false;
                        }
                        if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[2][this.Naddavane] === true) {
                            this.m_n.kontra = true;
                            this.m_n.rekontra = false;
                        }
                        if (this.Kontra === 2) {
                            this.m_n.rekontra = true;
                            this.m_n.kontra = false;
                        }
                        if (this.Rekontra > 0) {
                            this.m_n.rekontra = false;
                            this.m_n.kontra = false;
                        }
                        ///////////////////////////////////////////////
                        await this.m_n.DoModal();
                        ///////////////////////////////////////////////
                        if (this.m_n.m_pas === -1) {
                            this.BrPas = this.BrPas + 1;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                        }
                        if (this.m_n.m_pas === 0) {
                            this.Pkazal[1][1] = true;
                            this.Naddavane = 1;
                        }
                        if (this.m_n.m_pas === 1) {
                            this.Pkazal[1][2] = true;
                            this.Naddavane = 2;
                        }
                        if (this.m_n.m_pas === 2) {
                            this.Pkazal[1][3] = true;
                            this.Naddavane = 3;
                        }
                        if (this.m_n.m_pas === 3) {
                            this.Pkazal[1][4] = true;
                            this.Naddavane = 4;
                        }
                        if (this.m_n.m_pas === 4) {
                            this.Pkazal[1][5] = true;
                            this.Naddavane = 5;
                        }
                        if (this.m_n.m_pas === 5) {
                            this.Pkazal[1][6] = true;
                            this.Naddavane = 6;
                        }
                        if (this.m_n.m_pas === 6) {
                            this.Kontra = 1;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                        }
                        if (this.m_n.m_pas === 7) {
                            this.Rekontra = 1;
                            this.Kontra = 0;
                            this.Pkazal[1][8] = true;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                        }
                        if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                            this.Rekontra = 0;
                            this.Kontra = 0;
                            this.BrPas = 0;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                        }
                        this.KoiObiaviava = 2;
                        this.props.m_pwin.KillTimer(2);
                        this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                        return;
                    }
                    if (this.KoiObiaviava === 2) {
                        this.ObiaviavanePlayer(this.KoiObiaviava);
                    }
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true) {
                        if (this.BrPas === 2) {
                            this.Faza = 6;
                            this.setState({});
                            return;
                        }
                        if (this.BrPas === 1 && this.Naddavane > 0) {
                            this.Faza = 3;
                            this.Razdavane2 = true;
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                            }
                            this.obiaviavaneRN = null;
                            this.setState({});
                            return;
                        }
                        if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 2 && (this.BrPas !== 1 || this.Naddavane === 0)) {
                            this.KoiObiaviava = 0;
                            this.m_n.naddavane = this.Naddavane;

                            if (this.Naddavane === 0) {
                                this.m_n.kontra = false;
                                this.m_n.rekontra = false;
                            }
                            if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[2][this.Naddavane] === true) {
                                this.m_n.kontra = true;
                                this.m_n.rekontra = false;
                            }
                            if (this.Kontra === 2) {
                                this.m_n.rekontra = true;
                                this.m_n.kontra = false;
                            }
                            if (this.Rekontra > 0) {
                                this.m_n.rekontra = false;
                                this.m_n.kontra = false;
                            }
                            this.online_timer = this.online_timer_time;
                            ///////////////////////////////////////////////
                            await this.m_n.DoModal();
                            ///////////////////////////////////////////////
                            if (this.m_n.m_pas === -1) {
                                this.SendGame('$|m|0');
                                this.BrPas = this.BrPas + 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                            }
                            if (this.m_n.m_pas >= 0) {
                                if (this.OP) {
                                    if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0 && this.m_orez.m_rez3 === 0) {
                                        if (!this.op_start) {
                                            this.SendGame('');
                                            this.op_start = true;
                                        }
                                    }
                                }
                            }
                            if (this.m_n.m_pas === 0) {
                                this.SendGame('$|m|1');
                                this.Pkazal[1][1] = true;
                                this.Naddavane = 1;
                            }
                            if (this.m_n.m_pas === 1) {
                                this.SendGame('$|m|2');
                                this.Pkazal[1][2] = true;
                                this.Naddavane = 2;
                            }
                            if (this.m_n.m_pas === 2) {
                                this.SendGame('$|m|3');
                                this.Pkazal[1][3] = true;
                                this.Naddavane = 3;
                            }
                            if (this.m_n.m_pas === 3) {
                                this.SendGame('$|m|4');
                                this.Pkazal[1][4] = true;
                                this.Naddavane = 4;
                            }
                            if (this.m_n.m_pas === 4) {
                                this.SendGame('$|m|5');
                                this.Pkazal[1][5] = true;
                                this.Naddavane = 5;
                            }
                            if (this.m_n.m_pas === 5) {
                                this.SendGame('$|m|6');
                                this.Pkazal[1][6] = true;
                                this.Naddavane = 6;
                            }
                            if (this.m_n.m_pas === 6) {
                                this.Kontra = 1;
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                                this.SendGame('$|m|7');
                            }
                            if (this.m_n.m_pas === 7) {
                                this.Rekontra = 1;
                                this.Kontra = 0;
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                                this.SendGame('$|m|8');
                            }
                            if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                                this.Kontra = 0;
                                this.Rekontra = 0;
                                this.BrPas = 0;
                            }
                            this.setState({});
                            this.KoiObiaviava = 2;
                            if (this.P2OP !== true) {
                                this.props.m_pwin.KillTimer(2);
                                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                            }
                            return;
                        }
                        if (this.KoiObiaviava === 2) {
                            if (this.KoiObiaviava === 2 && this.P2OP && this.CheckAMessage()) {
                                let Nadd: number = this._ttoi(this.ReadAMessage());
                                if ((Nadd === 7 || Nadd === 8 || (this.Kontra === 0 && Nadd === 8)) && this.Naddavane === 0) {
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (this.Server):' + this.IntToString(Nadd) + ' sled pas');
                                    return;
                                }
                                if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Obiaviavane na greshen annons (this.Server):' +
                                            this.IntToString(Nadd) +
                                            ' sled ' +
                                            this.IntToString(this.Naddavane)
                                    );
                                }
                                if (Nadd === 0) {
                                    this.BrPas = this.BrPas + 1;
                                }
                                if (Nadd > 0 && Nadd < 7) {
                                    this.BrPas = 0;
                                    this.Naddavane = Nadd;
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.Pkazal[this.KoiObiaviava][Nadd] = true;
                                }
                                if (Nadd === 7) {
                                    this.BrPas = 0;
                                    this.Kontra = this.KoiObiaviava;
                                }
                                if (Nadd === 8) {
                                    this.BrPas = 0;
                                    this.Kontra = 0;
                                    this.Rekontra = this.KoiObiaviava;
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd));
                                this.Obiaviavane(Nadd);
                                return;
                            }
                            if (this.KoiObiaviava === 2 && this.P2OP !== true) {
                                if (this.CheckAMessage()) {
                                    let anons: number = this._ttoi(this.ReadAMessage());
                                    this.Obiaviavane(anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2 LU:' + this.last_Name[2]);
                                } else {
                                    let anons: number = this.Anonsi();
                                    let ano: string = this.IntToString(anons);
                                    this.SendGame('$|m|' + ano);
                                    this.Obiaviavane(anons);
                                }
                                return;
                            }
                        }
                    }
                } else {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true) {
                        if (this.BrPas === 2) {
                            this.Faza = 6;
                            if (!this.watch) {
                                this.SendGame('R');
                            }
                            this.setState({});
                            return;
                        }
                        if (this.BrPas === 1 && this.Naddavane > 0) {
                            this.Faza = 3;
                            this.Razdavane2 = true;
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                            }
                            this.obiaviavaneRN = null;
                            this.setState({});
                            return;
                        }
                        if (
                            this.watch &&
                            this.KoiObiaviava === 1 &&
                            this.Faza === 2 &&
                            this.BrPas !== 2 &&
                            (this.BrPas !== 1 || this.Naddavane === 0)
                        ) {
                            if (this.CheckAMessage()) {
                                let Nadd: number = this._ttoi(this.ReadAMessage());
                                if ((Nadd === 7 || Nadd === 8) && this.Naddavane === 0) {
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (Client):' + this.IntToString(Nadd) + ' sled pas');
                                    return;
                                }
                                if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Obiaviavane na greshen annons (Client):' +
                                            this.IntToString(Nadd) +
                                            ' sled ' +
                                            this.IntToString(this.Naddavane)
                                    );
                                    return;
                                }
                                if (Nadd === 0) {
                                    this.BrPas = this.BrPas + 1;
                                }
                                if (Nadd > 0 && Nadd < 7) {
                                    this.BrPas = 0;
                                    this.Naddavane = Nadd;
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                }
                                if (Nadd === 7) {
                                    this.BrPas = 0;
                                    this.Kontra = this.KoiObiaviava;
                                }
                                if (Nadd === 8) {
                                    this.BrPas = 0;
                                    this.Kontra = 0;
                                    this.Rekontra = this.KoiObiaviava;
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd));
                                this.Obiaviavane(Nadd);
                                this.setState({});
                                return;
                            }
                        }
                        if (!this.watch) {
                            if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 2 && (this.BrPas !== 1 || this.Naddavane === 0)) {
                                this.KoiObiaviava = 0;
                                this.m_n.naddavane = this.Naddavane;
                                if (this.Naddavane === 0) {
                                    this.m_n.kontra = false;
                                    this.m_n.rekontra = false;
                                }
                                if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[2][this.Naddavane] === true) {
                                    this.m_n.kontra = true;
                                    this.m_n.rekontra = false;
                                }
                                if (this.Kontra === 2) {
                                    this.m_n.rekontra = true;
                                    this.m_n.kontra = false;
                                }
                                if (this.Rekontra > 0) {
                                    this.m_n.rekontra = false;
                                    this.m_n.kontra = false;
                                }
                                ///////////////////////////////////////////////
                                this.online_timer = this.online_timer_time;
                                await this.m_n.DoModal();
                                ///////////////////////////////////////////////
                                let message: string = '$|m|';
                                if (this.m_n.m_pas === -1) {
                                    this.SendGame(message + '0');
                                    this.BrPas = this.BrPas + 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                                }
                                if (this.m_n.m_pas >= 0) {
                                    if (this.OP) {
                                        if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0 && this.m_orez.m_rez3 === 0) {
                                            if (!this.op_start) {
                                                this.SendGame('');
                                                this.op_start = true;
                                            }
                                        }
                                    }
                                }
                                if (this.m_n.m_pas === 0) {
                                    this.SendGame(message + '1');
                                    this.Pkazal[1][1] = true;
                                    this.Naddavane = 1;
                                }
                                if (this.m_n.m_pas === 1) {
                                    this.SendGame(message + '2');
                                    this.Pkazal[1][2] = true;
                                    this.Naddavane = 2;
                                }
                                if (this.m_n.m_pas === 2) {
                                    this.SendGame(message + '3');
                                    this.Pkazal[1][3] = true;
                                    this.Naddavane = 3;
                                }
                                if (this.m_n.m_pas === 3) {
                                    this.SendGame(message + '4');
                                    this.Pkazal[1][4] = true;
                                    this.Naddavane = 4;
                                }
                                if (this.m_n.m_pas === 4) {
                                    this.SendGame(message + '5');
                                    this.Pkazal[1][5] = true;
                                    this.Naddavane = 5;
                                }
                                if (this.m_n.m_pas === 5) {
                                    this.SendGame(message + '6');
                                    this.Pkazal[1][6] = true;
                                    this.Naddavane = 6;
                                }
                                if (this.m_n.m_pas === 6) {
                                    this.Kontra = 1;
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                                    this.SendGame(message + '7');
                                }
                                if (this.m_n.m_pas === 7) {
                                    this.Rekontra = 1;
                                    this.Kontra = 0;
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                                    this.SendGame(message + '8');
                                }
                                if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.BrPas = 0;
                                }
                                this.KoiObiaviava = 2;
                                this.setState({});
                                return;
                            }
                        }
                        if (this.KoiObiaviava > 1 && this.CheckAMessage()) {
                            this.setState({});
                            let Nadd: number = this._ttoi(this.ReadAMessage());
                            if ((Nadd === 7 || Nadd === 8) && this.Naddavane === 0) {
                                this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (Client):' + this.IntToString(Nadd) + ' sled pas');
                                return;
                            }
                            if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Obiaviavane na greshen annons (Client):' + this.IntToString(Nadd) + ' sled ' + this.IntToString(this.Naddavane)
                                );
                                return;
                            }
                            if (Nadd === 0) {
                                this.BrPas = this.BrPas + 1;
                            }
                            if (Nadd > 0 && Nadd < 7) {
                                this.BrPas = 0;
                                this.Naddavane = Nadd;
                                this.Kontra = 0;
                                this.Rekontra = 0;
                                this.Pkazal[this.KoiObiaviava][Nadd] = true;
                            }
                            if (Nadd === 7) {
                                this.BrPas = 0;
                                this.Kontra = this.KoiObiaviava;
                            }
                            if (Nadd === 8) {
                                this.BrPas = 0;
                                this.Kontra = 0;
                                this.Rekontra = this.KoiObiaviava;
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd));
                            this.Obiaviavane(Nadd);
                            return;
                        }
                    }
                }
            }
        }
    }

    async Pusk1KartaZaPuskanePl1() {
        if (this.props.m_pwin.state.CAnnouncementVisible) {
            return;
        }
        let vc: number = 0;
        let pc: number = 0;
        if (this.KoiENaRed === 1 && this.P1puska === 0) {
            for (let i: number = 1; i <= 8; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[1] !== 0 && this.k[i] !== 0) {
                        vc = vc + 1;
                        pc = i;
                    }
                }
            }
        }
        if (vc === 1 && pc > 0) {
            if (this.ProverkaCursor(pc)) {
                if (this.k[pc] !== 0) {
                    if (this.SP === true) {
                        await this.PuskaneNaKarta(pc);
                    }
                    if (this.OP === true) {
                        await this.PuskaneNaKartaOP(pc);
                    }
                    return;
                }
            }
        }
    }

    async AutoPuskKard() {
        for (let i: number = 1; i <= 8; i++) {
            if (this.ProverkaCursor(i)) {
                if (this.k[1] !== 0) {
                    if (this.props.m_pwin.state.CAnnouncementVisible) {
                        this.m_ann.Exit();
                        this.online_timer = this.online_timer_time;
                        return;
                    }
                    await this.PuskaneNaKartaOP(i);
                    return;
                }
            }
        }
    }

    async Timer3() {
        if (this.Move !== true) {
            if (this.SP) {
                if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 2 && this.hand < 8) {
                    this.Intelect(2);
                    this.setState({});
                    return;
                }
                if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 1 && this.hand < 8 && this.P1puska === 0) {
                    if (GlobalVariables.automatic_release && this.m_ann.start !== 1) {
                        await this.Pusk1KartaZaPuskanePl1();
                    }
                    return;
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed !== 1 && this.hand < 8 && this.P2puska === 0) {
                        if (this.KoiENaRed === 2 && this.P2OP && this.P2puska === 0 && this.CheckMessage()) {
                            let ca = this._ttoi(this.ReadMessage());
                            let card: number;

                            if (this.KoiENaRed === 2) card = 8 + ca;
                            if (this.k[card] === 0 || ca === 0) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Nevalidna Karta this.Server: ' +
                                        this.IntToString(card) +
                                        ' Hand=' +
                                        this.IntToString(this.hand) +
                                        'Koi e na red=' +
                                        this.IntToString(this.KoiENaRed)
                                );
                                return;
                            }
                            this.online_timer = this.online_timer_time;
                            this.AddToDetails(card);
                            if (this.KoiENaRed === 2) this.P2puska = this.k[card];
                            this.CardsPoint[this.KoiENaRed + 32] = this.CardsPoint[card];
                            this.CardP[32 + this.KoiENaRed] = this.CardP[card];
                            if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                                this.ObiaviavaneBelot(this.KoiENaRed, true);
                                this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                            }
                            if (this.hand === 0) {
                                for (let b: number = 0; b <= 1; b++) {
                                    if (this.PTerci[this.KoiENaRed][b] > 0) {
                                        if (this.terci[this.KoiENaRed][b]) {
                                            if (
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                                    this.PTerci[this.KoiENaRed][1] < 7
                                                ) &&
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                                    this.PTerci[this.KoiENaRed][1] < 16
                                                ) &&
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                                    this.PTerci[this.KoiENaRed][1] < 37
                                                )
                                            )
                                                this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                            if (b === 0) {
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                                    this.PTerci[this.KoiENaRed][1] < 7
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                }
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                                    this.PTerci[this.KoiENaRed][1] < 16
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                }
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                                    this.PTerci[this.KoiENaRed][1] < 37
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                }
                                            }
                                        } else this.PTerci[this.KoiENaRed][b] = 0;
                                    }
                                }

                                if (this.KoiENaRed === 2) {
                                    for (let b: number = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                )
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else this.PTerci[this.KoiENaRed][b] = 0;
                                        }
                                    }
                                }
                            }
                            this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 32], 32 + this.KoiENaRed);
                            if (this.KoiENaRed === 2) {
                                this.props.m_pwin.KillTimer(3);
                                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                this.setState({});
                            }
                            if (this.KoiPuskaParvi === 1) {
                                if (this.KoiENaRed === 2) this.VtoriPuska = this.k[card];
                            }
                            if (this.KoiPuskaParvi === 2) {
                                if (this.KoiENaRed === 2) this.ParviPuska = this.k[card];
                            }
                            this.DeleteCard(card);
                            if (this.P1puska === 0 || this.P2puska === 0) {
                                if (this.KoiENaRed < 2) this.KoiENaRed = this.KoiENaRed + 1;
                                else this.KoiENaRed = 1;
                            }
                            return;
                        }
                        if (this.KoiENaRed === 2 && this.P2OP !== true) {
                            this.Intelect(2);
                            this.setState({});
                            return;
                        }
                    }
                    if (this.Faza > 4 && this.strelka < 1 && this.hand < 8 && this.KoiENaRed === 1 && this.P1puska === 0 && this.Faza < 6) {
                        if (this.online_timer === 0) {
                            this.sit_out_count = this.sit_out_count + 1;
                            if (this.sit_out_count >= this.sit_out_drop) {
                                this.props.m_pwin.AutoSitOut();
                                return;
                            }
                            await this.AutoPuskKard();
                        } else if (GlobalVariables.automatic_release === true && this.KoiENaRed === 1 && this.m_ann.start !== 1) {
                            await this.Pusk1KartaZaPuskanePl1();
                        }
                        return;
                    }
                } else {
                    if (this.watch) {
                        if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 1 && this.hand < 8 && this.P1puska === 0) {
                            if (this.CheckMessage()) {
                                let ca = this._ttoi(this.ReadMessage());
                                let card = ca;

                                if (this.k[card] === 0 || ca === 0) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Nevalidna Karta Client: ' +
                                            this.IntToString(ca) +
                                            ' Hand=' +
                                            this.IntToString(this.hand) +
                                            'Koi e na red=' +
                                            this.IntToString(this.KoiENaRed)
                                    );
                                    return;
                                }
                                this.online_timer = this.online_timer_time;
                                this.AddToDetails(card);
                                this.P1puska = this.k[card];
                                this.CardsPoint[this.KoiENaRed + 32] = this.CardsPoint[card];
                                this.CardP[32 + this.KoiENaRed] = this.CardP[card];
                                await this.PuskaneNaKarta(card);
                            }
                            return;
                        }
                    }
                    if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 2 && this.hand < 8 && this.P2puska === 0) {
                        if (this.CheckMessage()) {
                            let ca = this._ttoi(this.ReadMessage());
                            let card;

                            if (this.KoiENaRed === 2) card = 8 + ca;
                            if (this.k[card] === 0 || ca === 0) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Nevalidna Karta Client: ' +
                                        this.IntToString(ca) +
                                        ' Hand=' +
                                        this.IntToString(this.hand) +
                                        'Koi e na red=' +
                                        this.IntToString(this.KoiENaRed)
                                );
                                return;
                            }
                            this.online_timer = this.online_timer_time;
                            this.AddToDetails(card);
                            if (this.KoiENaRed === 2) this.P2puska = this.k[card];
                            this.CardsPoint[this.KoiENaRed + 32] = this.CardsPoint[card];
                            this.CardP[32 + this.KoiENaRed] = this.CardP[card];
                            if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                                this.ObiaviavaneBelot(this.KoiENaRed, true);
                                this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                            }
                            if (this.hand === 0) {
                                for (let b: number = 0; b <= 1; b++) {
                                    if (this.PTerci[this.KoiENaRed][b] > 0) {
                                        if (this.terci[this.KoiENaRed][b]) {
                                            if (
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                                    this.PTerci[this.KoiENaRed][1] < 7
                                                ) &&
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                                    this.PTerci[this.KoiENaRed][1] < 16
                                                ) &&
                                                !(
                                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                                    this.PTerci[this.KoiENaRed][1] < 37
                                                )
                                            )
                                                this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                            if (b === 0) {
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                                    this.PTerci[this.KoiENaRed][1] < 7
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                }
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                                    this.PTerci[this.KoiENaRed][1] < 16
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                }
                                                if (
                                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                                    this.terci[this.KoiENaRed][0] &&
                                                    this.terci[this.KoiENaRed][1] &&
                                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                                    this.PTerci[this.KoiENaRed][1] < 37
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                }
                                            }
                                        } else this.PTerci[this.KoiENaRed][b] = 0;
                                    }
                                }
                            }
                            this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 32], 32 + this.KoiENaRed);
                            this.setState({});
                            if (this.KoiPuskaParvi === 1) {
                                if (this.KoiENaRed === 2) this.VtoriPuska = this.k[card];
                            }
                            if (this.KoiPuskaParvi === 2) {
                                if (this.KoiENaRed === 2) this.ParviPuska = this.k[card];
                            }
                            this.DeleteCard(card);
                            if (this.P1puska === 0 || this.P2puska === 0) {
                                if (this.KoiENaRed < 2) this.KoiENaRed = this.KoiENaRed + 1;
                                else this.KoiENaRed = 1;
                            }
                        }
                        return;
                    }
                    if (!this.watch) {
                        if (this.Faza > 4 && this.strelka < 1 && this.hand < 8 && this.P1puska === 0 && this.KoiENaRed === 1 && this.Faza < 6) {
                            if (this.online_timer === 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                await this.AutoPuskKard();
                            } else if (GlobalVariables.automatic_release === true && this.KoiENaRed === 1 && this.m_ann.start !== 1) {
                                await this.Pusk1KartaZaPuskanePl1();
                            }
                            return;
                        }
                    }
                }
            }
        }
    }

    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string;
        if (f === 'a') {
            //Poluchavane na imenata client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let name: string = this.props.m_pwin.ReceiveString(12);
            if (this.Name[2] === '') {
                if (name !== 'Computer2') this.Name[2] = name;
                else {
                    this.Name[2] = 'Computer2';
                }
                this.m_orez.Player[2] = this.Name[2];
                this.props.m_pwin.GetPlayerRating(2);
                return true;
            }
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.Zvuk[0] === 1) this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            await this.InitGame2();
            if (this.watch) {
                this.setState({});
            }
            let koj: string = this.props.m_pwin.ReceiveString(1);
            if (koj === '1' || koj === '2') {
                this.KoiZapochva = this.KoiPuskaParvi = this.KoiObiaviava = this.KoiENaRed = this._ttoi(koj);
            }
            return true;
        }
        if (f === 'c') {
            //Poluchavane na kartite client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rstr: string = this.props.m_pwin.ReceiveString(2);
            for (let i: number = 1; i < 25; i++) {
                if (this.k[i] < 1) {
                    if (i === 1) {
                        if (this.CheckMessage()) {
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.ClearAMessages();
                        }
                    }
                    this.k[i] = this._ttoi(rstr);
                    if (i === 24) {
                        this.LoadCards(false, true);
                        this.Razdavane1 = true;
                        this.Razdavane2 = false;
                        this.online_timer = this.online_timer_time;
                        this.setState({});
                    }
                    return true;
                }
            }
            return true;
        }
        if (f === 'd') {
            //Poluchavane na anons
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.setState({});
            let annons: string = this.props.m_pwin.ReceiveString(1);
            if (
                annons === '0' ||
                annons === '1' ||
                annons === '2' ||
                annons === '3' ||
                annons === '4' ||
                annons === '5' ||
                annons === '6' ||
                annons === '7' ||
                annons === '8'
            ) {
                this.WriteAMessage(annons);
                await this.Timer2();
            } else {
                this.props.m_pwin.SendErrorToLog('Nevaliden Anons: ' + annons);
            }
            return true;
        }
        if (f === 'e') {
            //Poluchavane na signal za gotovnost ot servera
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let vs: string = this.props.m_pwin.ReceiveString(4);
            this.Visiashti = this._ttoi(vs);
            return true;
        }
        if (f === 'f') {
            //Poluchavane na karta
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let card: string = this.props.m_pwin.ReceiveString(1);
            if (card === '1' || card === '2' || card === '3' || card === '4' || card === '5' || card === '6' || card === '7' || card === '8') {
                this.WriteMessage(card);
                await this.Timer3();
            } else {
                this.props.m_pwin.SendErrorToLog('Poluchavane na greshna karta: ' + card);
            }
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name: string = strRecvd;
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        let ss: boolean = this.show_user[2];
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.wait_opplayers[2] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.props.m_pwin.InvalidateStolove(3);
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            this.P3G = true;
            return true;
        }
        if (f === 'j') {
            this.P4G = true;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') this.P2OP = false;
            if (strRecvd === '3') this.P3OP = false;
            if (strRecvd === '4') this.P4OP = false;
            if (strRecvd === '5') this.P2OP = true;
            if (strRecvd === '6') this.P3OP = true;
            if (strRecvd === '7') this.P4OP = true;
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') this.Server = false;
            this.setState({});
            return true;
        }
        if (f === 'm') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            this.op_belot[this._ttoi(strRecvd)] = true;
            return true;
        }
        if (f === 'n') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            let player: string = this.props.m_pwin.ReceiveString(1);
            this.terci[this._ttoi(player)][this._ttoi(strRecvd)] = true;
            return true;
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2G = false;
                if (this.Faza === 6) this.P2G = true;
            }
            if (strRecvd === '3') {
                this.P3G = false;
                if (this.Faza === 6) this.P3G = true;
            }
            if (strRecvd === '4') {
                this.P4G = false;
                if (this.Faza === 6) this.P4G = true;
            }
            if (strRecvd === '5') this.P2G = true;
            if (strRecvd === '6') this.P3G = true;
            if (strRecvd === '7') this.P4G = true;
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra this.Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let pistr = this.props.m_pwin.ReceiveString(1);
            let rating = this.props.m_pwin.ReceiveString(30);
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let player_index = this._ttoi(pistr);
                if (this.watch) {
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    this.setState({});
                    return true;
                }
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[player_index] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    let message: string = '$|s|' + pistr + '|1';
                    this.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                } else {
                    let message: string = '$|s|' + pistr + '|0';
                    this.SendGame(message);
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        let title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title + '?');
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message: string = '$|x|' + player_name;
                this.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane na Igrach po vreme na igra
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please wait for the next deal'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user"));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rez1 = this.props.m_pwin.ReceiveString(5);
            let rez2 = this.props.m_pwin.ReceiveString(5);
            if (this.watch) {
                if (rez1 === '0' && rez2 === '0') {
                    this.m_orez.m_rez1 = 0;
                    this.m_orez.m_rez2 = 0;
                    this.Visiashti = 0;
                    for (let i: number = 0; i < 2; i++) {
                        for (let a: number = 0; a < 100; a++) {
                            this.m_orez.orez[i][a] = -10;
                        }
                    }
                    this.setState({});
                    return true;
                } else {
                    for (let a: number = 0; a < 30; a++) {
                        if (this.m_orez.orez[0][a] === -10) {
                            this.m_orez.orez[0][a] = this._ttoi(rez1);
                            this.m_orez.orez[1][a] = this._ttoi(rez2);
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                            this.setState({});
                            return true;
                        }
                    }
                }
            } else {
                for (let a: number = 0; a < 30; a++) {
                    if (this.m_orez.orez[0][a] === -10) {
                        this.m_orez.orez[0][a] = this._ttoi(rez1);
                        this.m_orez.orez[1][a] = this._ttoi(rez2);
                        this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                        this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                        this.setState({});
                        return true;
                    }
                }
            }
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index = this._ttoi(pistr);
            let player_name = this.props.m_pwin.ReceiveString(12);
            this.Name[player_index] = player_name;
            this.props.m_pwin.GetPlayerRating(player_index);
            this.setState({});
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        if (f === 'u') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'v') {
            //ReceiveWachGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(500);
            await this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!b2 : ' + f);
        return true;
    }

    async ReceiveWachGameInitialization(init: string) {
        await this.OnLButtonDown(new CPoint(0, 0));
        await this.InitGame2();

        let a: number;
        let brrez: number;
        let kojkazal = 1;
        let vpos = init.indexOf('?');
        let n1: string = init.substring(0, vpos);
        if (n1 != '') this.Name[1] = n1;
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        if (this.Faza < 0) {
            this.ReceiveWachGameInitializationEnd();
            return;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Naddavane = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.hand = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiPuskaParvi = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P1puska = this._ttoi(s1);
        if (this.P1puska > 0) {
            this.ConvertCard(this.P1puska, 33);
            this.CardsPoint[33] = this.CardsPos[33];
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P2puska = this._ttoi(s1);
        if (this.P2puska > 0) {
            this.ConvertCard(this.P2puska, 34);
            this.CardsPoint[34] = this.CardsPos[34];
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ParviPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.VtoriPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.strelka = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiObiaviava = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        if (this.KoiObiaviava === 0) this.KoiObiaviava = 1;

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.BrPas = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mcard = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Kontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Rekontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        kojkazal = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane1 = true;
            if (this.Zvuk[0] === 1 && this.Faza <= 4) this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        } else this.Razdavane1 = false;
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane2 = true;
            if (this.Zvuk[0] === 1 && this.Faza <= 4) this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        } else this.Razdavane2 = false;
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') this.Move = true;
        else this.Move = false;
        init = init.substring(vpos + 1, init.length);

        for (let i: number = 1; i < 25; i++) {
            let vpos = init.indexOf('?');
            let s1 = init.substring(0, vpos);
            this.k[i] = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].x = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].y = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        brrez = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (brrez > 0) {
            for (let a: number = 0; a < brrez; a++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez1 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez2 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                this.m_orez.orez[0][a] = rez1;
                this.m_orez.orez[1][a] = rez2;
            }
        }
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        for (a = 0; a < brrez; a++) {
            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
        }

        if (this.Faza > 4) {
            this.LoadCards(true, false);
        } else {
            this.LoadCards(false, false);
        }
        if ((this.Razdavane1 || this.Razdavane2) && this.Mcard > 0 && this.Move) {
            this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
        } else {
            if (this.Move) {
                if (this.Mcard > 0) {
                    this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
                }
            }
        }

        this.ReceiveWachGameInitializationEnd();
    }

    ReceiveWachGameInitializationEnd() {
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.setState({});
    }

    SendWatchGameInitialization(watch_index: number) {
        let message: string = '$|z|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[1] + '?' + this.Name[2] + '?';
        //this.Faza
        message = message + this.IntToString(this.Faza) + '?';
        message = message + this.IntToString(this.Naddavane) + '?';
        message = message + this.IntToString(this.hand) + '?';
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.KoiPuskaParvi) + '?';
        message = message + this.IntToString(this.KoiZapochva) + '?';
        message = message + this.IntToString(this.P1puska) + '?';
        message = message + this.IntToString(this.P2puska) + '?';

        message = message + this.IntToString(this.ParviPuska) + '?';
        message = message + this.IntToString(this.VtoriPuska) + '?';
        message = message + this.IntToString(this.strelka) + '?';
        message = message + this.IntToString(this.KoiObiaviava) + '?';
        message = message + this.IntToString(this.BrPas) + '?';
        message = message + this.IntToString(this.Mcard) + '?';
        message = message + this.IntToString(this.Kontra) + '?';
        message = message + this.IntToString(this.Rekontra) + '?';
        let kojkazal = '1';
        if (this.Naddavane > 0 && this.Naddavane < 7) {
            if (this.Pkazal[2][this.Naddavane]) kojkazal = '2';
        }
        if (this.Naddavane === 7) {
            kojkazal = this.IntToString(this.Kontra);
        }
        if (this.Naddavane === 8) {
            kojkazal = this.IntToString(this.Rekontra);
        }
        message = message + kojkazal + '?';

        if (this.Razdavane1) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }

        if (this.Razdavane2) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.Move) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        for (let i: number = 1; i < 25; i++) {
            message =
                message +
                this.IntToString(this.k[i]) +
                '?' +
                this.IntToString(this.CardsPoint[i].x) +
                '?' +
                this.IntToString(this.CardsPoint[i].y) +
                '?';
        }
        let brrez = '0';
        let mrez = '';
        for (let a: number = 0; a < 30; a++) {
            if (this.m_orez.orez[0][a] > -10) {
                brrez = this.IntToString(a + 1);
                mrez = mrez + this.IntToString(this.m_orez.orez[0][a]) + '?';
                mrez = mrez + this.IntToString(this.m_orez.orez[1][a]) + '?';
            } else {
                break;
            }
        }
        message = message + brrez + '?' + mrez + '?';
        if (message != '') {
            this.SendGame(message);
        }
    }

    async Timer1() {
        if (this.SP) {
            if (this.strelka === 0 && this.Move != true && this.P1puska > 0 && this.P2puska > 0) {
                this.strelka = this.Strelka();
                if (this.strelka === 1) this.kapo1 = false;
                else this.kapo2 = false;
                this.vz[this.hand] = this.strelka;
                this.setState({});
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
            }
            if (this.Move) {
                let x1 = this.CardsPoint[this.Mcard].x;
                let y1 = this.CardsPoint[this.Mcard].y;
                this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                this.setState({});
            }
            if ((this.Razdavane1 === true || this.Razdavane2 === true) && this.Move != true && this.Faza < 4) {
                let karta = this.KartaZaRazdavane();
                this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
            }
            if (this.Razdavane1 != true && this.Razdavane2 != true && this.Move != true && this.Faza === 1) {
                this.Faza = 2;
                this.SortCards(0);
                this.setState({});
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            }
            if (this.Faza === 4 && this.Razdavane2 != true && this.Move != true) {
                this.Faza = 5;
                for (let f: number = 1; f <= 2; f++) this.CheckTerci(true, f);
                this.SortCards(1);
                this.setState({});
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, this.Speed[2] * 100);
            }
        }
        if (this.OP) {
            if (this.Server) {
                if (this.Faza === 6 && this.P2G) {
                    if (this.P2OP === true) this.P2G = false;
                    await this.InitGame2();
                    this.SendKoiENaRed();
                    this.SendCards();
                    this.setState({});
                    this.Razdavane1 = true;
                    this.online_timer = this.online_timer_time;
                }
                if (this.strelka === 0 && this.Move != true && this.P1puska > 0 && this.P2puska > 0) {
                    this.strelka = this.Strelka();
                    if (this.strelka === 1) this.kapo1 = false;
                    else this.kapo2 = false;
                    this.vz[this.hand] = this.strelka;
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move) {
                    this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                    this.setState({});
                }
                if ((this.Razdavane1 === true || this.Razdavane2 === true) && this.Move != true && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (this.Razdavane1 != true && this.Razdavane2 != true && this.Move != true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
                if (this.Faza === 4 && this.Razdavane2 != true && this.Move != true) {
                    this.Faza = 5;
                    for (let f: number = 1; f <= 2; f++) this.CheckTerci(true, f);
                    this.SortCards(1);
                    this.setState({});
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[2] * 100);
                }
            } else {
                if (this.strelka === 0 && !this.Move && this.P1puska > 0 && this.P2puska > 0) {
                    this.strelka = this.Strelka();
                    if (this.strelka === 1) this.kapo1 = false;
                    else this.kapo2 = false;
                    this.vz[this.hand] = this.strelka;
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move) {
                    this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                    this.setState({});
                }
                if ((this.Razdavane1 || this.Razdavane2) && !this.Move && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (!this.Razdavane1 && !this.Razdavane2 && !this.Move && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
                if (this.Faza === 4 && this.Razdavane2 != true && this.Move != true) {
                    this.Faza = 5;
                    for (let f: number = 1; f <= 2; f++) this.CheckTerci(true, f);
                    this.SortCards(1);
                    this.setState({});
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[2] * 100);
                }
            }
        }
    }

    SendResult(player: number) {
        if (player === 1) {
            for (let a: number = 0; a < 29; a++) {
                if (this.m_orez.orez[0][a + 1] === -10) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message: string =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]);
                        this.SendGame(message);
                    } else {
                        let message: string = '$|u|' + this.IntToString(player) + '|0|0';
                        this.SendGame(message);
                    }
                    return;
                }
            }
        } else {
            if (player === 2) {
                for (let a: number = 0; a < 30; a++) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message: string =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]);
                        this.SendGame(message);
                    }
                }
            }
            this.SendGame('$|*|b|' + this.IntToString(this.Visiashti));
        }
    }

    async InitNewGame() {
        this.op_start = false;
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        this.Visiashti = 0;
        for (let i: number = 0; i < 2; i++) {
            for (let a: number = 0; a < 100; a++) {
                this.m_orez.orez[i][a] = -10;
            }
        }
        this.pause = 0;
        this.pause_player = '';
        if (this.SP) {
            await this.InitGame2();
        }
        for (let i: number = 1; i < 3; i++) {
            for (let a: number = 0; a < 2; a++) {
                this.PTerci[i][a] = 0;
            }
            this.last_Name[i] = this.Name[i];
        }
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
    }

    async InitORezDlg(FName: string[]) {
        if (!this.watch) {
            if (this.OP) {
                if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                    if (!this.op_start) {
                        this.SendGame('');
                        this.op_start = true;
                    }
                }
            }
            for (let a: number = 0; a < 30; a++) {
                if (this.m_orez.orez[0][a] === -10) {
                    this.m_orez.orez[0][a] = this.m_rez.m_crez1;
                    this.m_orez.orez[1][a] = this.m_rez.m_crez2;
                    break;
                }
            }
            this.m_orez.m_rez1 = this.m_orez.m_rez2 = 0;
            if (this.OP) {
                if (this.Server) {
                    if (this.HaveWatch) {
                        this.SendResult(1);
                    }
                }
            }
            for (let i: number = 0; i < 2; i++) {
                for (let a: number = 0; a < 30; a++) {
                    if (this.m_orez.orez[i][a] > -10) {
                        if (i === 0) {
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[i][a];
                        } else {
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[i][a];
                        }
                    }
                }
            }
            this.open_orez_dlg = true;
            for (let i: number = 1; i < 3; i++) this.m_orez.Player[i] = FName[i];
            if (this.OP) {
                let rezult = 'X|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                this.SendGame(rezult);
            }
            if (this.props.m_pwin.state.DlgORezultatVisible) {
                this.m_orez.AddRezults();
                this.m_orez.setState({});
            } else {
                this.m_orez.DoModal();
            }
            this.open_orez_dlg = false;
            if (this.m_orez.m_rez1 < 151 && this.m_orez.m_rez2 < 151) {
                if (this.SP) {
                    await this.InitGame2();
                }
            } else {
                if (this.kapo1 === true || this.kapo2 === true) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't end the game with capot."));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.SP) {
                        await this.InitGame2();
                    }
                    this.EndOrezDlg();
                    return;
                }
                if (this.m_orez.m_rez1 === this.m_orez.m_rez2) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The score is even. There will be one more deal.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.SP) {
                        await this.InitGame2();
                    }
                    this.EndOrezDlg();
                    return;
                }

                let b2_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Belot2', '');
                let crezult = '';
                let ctime = this.getCDateTime();
                let player1 = FName[1];
                let player2 = FName[2];
                crezult = ctime + ' ' + player1 + ' ' + this.GetLanguageText('against') + ' ' + player2 + ' ';
                crezult =
                    crezult +
                    this.GetLanguageText('scoring') +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez1) +
                    ' - ' +
                    this.IntToString(this.m_orez.m_rez2);
                if (this.m_orez.m_rez1 > this.m_orez.m_rez2) {
                    crezult = crezult + 'w;';
                } else {
                    crezult = crezult + 'l;';
                }
                this.WriteProfileString('Options', 'Belot2', b2_rezult + crezult);
                if (this.m_orez.m_rez1 > this.m_orez.m_rez2) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult = '$|n|1|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                        this.SendGame(rezult);
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + FName[1]);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else {
                    this.m_orez.m_zagubi = this.m_orez.m_zagubi + 1;
                    if (this.OP) {
                        let rezult = '$|n|0|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                        this.SendGame(rezult);
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + FName[2]);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                if (this.OP) {
                    let rezult = 'X|0 : 0';
                    this.SendGame(rezult);
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    await this.InitNewGame();
                    if (this.OP && !this.watch) {
                        if (this.Server) {
                            if (this.HaveWatch) {
                                this.SendResult(1);
                            }
                        }
                    }
                } else {
                    if (this.OP) {
                        this.props.m_pwin.GoToLobby();
                    } else {
                        this.props.m_pwin.OnNewGame();
                    }
                    return;
                }
            }
        }
    }

    EndOrezDlg() {
        if (this.OP) {
            this.Faza = 6;
            if (this.Server) {
                for (let i: number = 2; i <= 2; i++) {
                    if (this.wait_opplayers[i]) {
                        this.Name[i] = this.wait_opnames[i];
                        this.show_user[i] = false;
                        this.props.m_pwin.GetPlayerRating(i);
                        if (i === 2) {
                            this.P2OP = true;
                            this.P2G = true;
                        }
                        this.SendResult(i);
                        let message: string = '$|v|' + this.IntToString(i);
                        this.SendGame(message);
                    }
                }
                for (let i: number = 2; i <= 2; i++) {
                    if (this.wait_opplayers[i]) {
                        this.wait_opplayers[i] = false;
                        this.wait_opnames[i] = '';
                    }
                }
            }
        }
        this.setState({});
    }

    async Timer4() {
        if (this.Move != true) {
            if (this.strelka > 0) {
                if (this.hand === 1) {
                    this.obiaviavaneRN = null;
                    this.setState({});
                }
                for (let i: number = 1; i <= 2; i++) {
                    if (this.Belot[i][1] === 1) {
                        this.setState({});
                    }
                }
            }
            if (this.SP) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    for (let i: number = 1; i <= 16; i++) {
                        if (this.k[i] != 0) {
                            return;
                        }
                    }
                    let FName: string[];
                    for (let i: number = 1; i <= this.Players; i++) {
                        FName[i] = this.Name[i];
                    }
                    await this.InitRezDlg();
                    await this.InitORezDlg(FName);
                    this.setState({});
                }
            }
            if (this.OP) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.setState({});
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] != 0) {
                            return;
                        }
                    }
                    this.Faza = 7;
                    let FName: string[];
                    for (let i: number = 1; i <= this.Players; i++) {
                        FName[i] = this.Name[i];
                    }
                    await this.InitRezDlg();
                    await this.InitORezDlg(FName);
                    if (this.props.m_pwin.game !== '') {
                        if (this.CheckMessage()) {
                            this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Karti:' + this.GetAllMessages());
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Annonsi' + this.GetAllAMessages());
                            this.ClearAMessages();
                        }
                        if (!this.Server && !this.watch) this.SendGame('R');
                    }
                    this.setState({});
                }
            }
        }
    }

    async Timer7() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (!this.watch && this.OP) {
            if (this.P2OP || this.wait_opplayers[2] || !this.Server) {
                if (this.online_timer > 0) this.online_timer = this.online_timer - 1;
                if (this.online_timer === 5) {
                    if (this.P2OP || this.wait_opplayers[2] || !this.Server) {
                        if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                            if (this.Zvuk[0] === 1) this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                        }
                    }
                }

                if (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas != 2 && (this.BrPas != 1 || this.Naddavane === 0)) {
                    if (this.online_timer === 0) {
                        this.online_timer = this.online_timer_time;
                        this.m_n.klik_o = true;
                        this.m_n.OnOK();
                    }
                }
                if (this.Faza === 7) {
                    if (this.online_timer === 0) {
                        if (this.open_rez_dlg) {
                            if (this.m_rez.dlg_det_start) {
                                this.m_rez.dlg_det.OnOK();
                            }
                            this.m_rez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                        if (this.open_orez_dlg && !this.open_rez_dlg) {
                            this.m_orez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                    }
                }
            }
        }
    }

    constructor(props) {
        super(props);
    }

    DrawView() {
        return null;
    }

    render() {
        if (this.props.m_pwin.game === 'Belot2') {
            return <View key={'Belot2'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CBelot2Players;
