import React, { Component } from 'react';
import { Platform, StyleSheet, Switch, View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class CAnnouncement extends Component<ParentDlgProps> {
    start: number;
    belot: boolean;
    an1: string;
    an2: string;
    m_belote: boolean;
    m_announce1: boolean;
    m_announce2: boolean;
    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    Exit() {
        this.start = 0;
    }

    toggleSwitchBelote() {
        this.m_belote = !this.m_belote;
        this.setState({});
    }

    toggleSwitchAn1() {
        this.m_announce1 = !this.m_announce1;
        this.setState({});
    }

    toggleSwitchAn2() {
        this.m_announce2 = !this.m_announce2;
        this.setState({});
    }

    async DoModal() {
        this.props.m_pwin.state.CAnnouncementVisible = true;
        this.start = 1;
        this.m_belote = true;
        this.m_announce1 = true;
        this.m_announce2 = true;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CAnnouncementVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ CAnnouncementVisible: false });
        return GlobalConstants.IDOK;
    }

    InitWindow(belote: boolean, can1: string, can2: string) {
        this.belot = belote;
        this.an1 = can1;
        this.an2 = can2;
    }

    render() {
        if (this.props.m_pwin.state.CAnnouncementVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 180 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 220 * GlobalVariables.scale,
                                width: 350 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Announcement</Text>
                        </View>
                        {this.belot ? (
                            <View style={[styles.row, cstyles.textWithSwith]}>
                                <View>
                                    <Switch
                                        style={{
                                            height: GlobalConstants.MEDIUM_FONT_SIZE * GlobalVariables.scale,
                                            margin: 4 * GlobalVariables.scale,
                                            transform: Platform.OS === 'ios' ? [{ scaleX: 0.5 }, { scaleY: 0.5 }] : [{ scaleX: 1 }, { scaleY: 1 }],
                                        }}
                                        onValueChange={this.toggleSwitchBelote.bind(this)}
                                        value={this.m_belote}
                                    />
                                </View>
                                <View style={cstyles.text}>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{'Belote'}</Text>
                                </View>
                            </View>
                        ) : null}
                        {this.an1 !== '' ? (
                            <View style={[styles.row, cstyles.textWithSwith]}>
                                <View>
                                    <Switch
                                        style={{
                                            height: GlobalConstants.MEDIUM_FONT_SIZE * GlobalVariables.scale,
                                            margin: 4 * GlobalVariables.scale,
                                            transform: Platform.OS === 'ios' ? [{ scaleX: 0.5 }, { scaleY: 0.5 }] : [{ scaleX: 1 }, { scaleY: 1 }],
                                        }}
                                        onValueChange={this.toggleSwitchAn1.bind(this)}
                                        value={this.m_announce1}
                                    />
                                </View>
                                <View style={cstyles.text}>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{this.an1}</Text>
                                </View>
                            </View>
                        ) : null}
                        {this.an2 !== '' ? (
                            <View style={[styles.row, cstyles.textWithSwith]}>
                                <View>
                                    <Switch
                                        style={{
                                            height: GlobalConstants.MEDIUM_FONT_SIZE * GlobalVariables.scale,
                                            margin: 4 * GlobalVariables.scale,
                                            transform: Platform.OS === 'ios' ? [{ scaleX: 0.5 }, { scaleY: 0.5 }] : [{ scaleX: 1 }, { scaleY: 1 }],
                                        }}
                                        onValueChange={this.toggleSwitchAn2.bind(this)}
                                        value={this.m_announce2}
                                    />
                                </View>
                                <View style={cstyles.text}>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{this.an2}</Text>
                                </View>
                            </View>
                        ) : null}
                        <View style={styles.row}>
                            <Button
                                containerStyle={modal.buttonStyle1PerRow}
                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                onPress={this.Exit.bind(this)}
                                text="OK"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CAnnouncement;

const cstyles = StyleSheet.create({
    textWithSwith: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5 * GlobalVariables.scale,
        paddingBottom: 5 * GlobalVariables.scale,
        height: 30 * GlobalVariables.scale,
    },
    text: { height: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' },
});
