import { Component } from 'react';
import { View } from 'react-native';
import React from 'react';
import DrawName from './DrawName';
import { styles } from '../../../css/container';
import { DrawNamesProps } from '../props/DrawNamesProps';
import CPoint from '../cppComponents/CPoint';

class DrawNames extends Component<DrawNamesProps> {
    constructor(props: DrawNamesProps) {
        super(props);
    }

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.view}>
                    {this.props.name.map((name, index) => (
                        <View key={index}>
                            {index > 0 ? (
                                <DrawName
                                    text={name}
                                    position={new CPoint(this.props.position[index].x - 7, this.props.position[index].y - 4)}
                                    cursor={this.props.cursor}
                                />
                            ) : null}
                        </View>
                    ))}
                </View>
            </View>
        );
    }
}

export default DrawNames;
