import React, { Component } from 'react';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import { StyleSheet, TextInput, View, TouchableOpacity, Image, ScrollView, Platform } from 'react-native';
import GlobalVariables from '../../Components/GlobalVariables';
import GlobalConstants from '../../Components/GlobalConstants';
import Emote from './Emote';
import Watchers from './Watchers';
import ChatMessage from './ChatMessage';

export type MessageParts = {
    type: 'emote' | 'text';
    text?: string;
    emoteIndex?: number;
};

class CChatBar extends Component<BaseDlgProps> {
    enable_chat: boolean = true;
    watch: boolean = false;
    nick: string = '';
    m_history: any = [];
    m_text: string = '';
    scrollView: any;
    m_watch: Watchers = new Watchers(null);
    watchers: string[] = [];

    constructor(props: BaseDlgProps) {
        super(props);
    }
    componentDidMount() {
        this.props.onRef(this);
    }

    AddMessage(message: string) {
        message = this.props.m_pwin.m_main.TranslateMessage(message);
        let ctime: string = this.props.m_pwin.m_main.getCTime();
        this.m_history.push(this.props.m_pwin.m_main.formatMessage(ctime + message));
        this.setState({});
    }

    ClearWatchers() {
        this.watchers = [];
        this.setState({});
    }

    AddString(username: string) {
        this.watchers.push(username);
        this.setState({});
    }

    DeleteString(username: string) {
        for (let i = 0; i < this.watchers.length; i++) {
            if (this.watchers[i] === username) {
                if (this.m_watch.selectedUser === username) {
                    this.m_watch.selectedUser = null;
                }
                this.watchers.splice(i, 1);
                break;
            }
        }
        this.setState({});
    }

    EnableChat(enable: boolean) {
        this.enable_chat = enable;
        this.setState({});
    }

    logInWatchUser(player_name: string) {
        this.AddString(player_name);
    }

    async OnStaticKick() {
        if (!this.props.m_pwin.m_main.watch) {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText(
                        'In order to be able to kick watchers from the table, you must log in with your username.'
                    )
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            if (this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('In order to be able to kick watchers from the table, you must be a VIP user.')
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
            }
            if (this.props.m_pwin.isVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                if (this.m_watch.selectedUser !== null) {
                    let text: string = '(kick) ' + this.m_watch.selectedUser;
                    this.m_text = text;
                    this.OnStaticSend();
                    this.DeleteString(this.m_watch.selectedUser);
                }
            }
        }
        this.setState({});
    }
    async OnStaticAdd() {
        if (!this.props.m_pwin.m_main.watch) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('In order to be able to invite watchers, you must log in with your username.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                if (this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('In order to be able to invite watchers, you must be a VIP user.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
                }
            }
            if (this.props.m_pwin.isVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                if (this.props.m_pwin.IsGameOnlineServer()) {
                    if (this.m_watch.selectedUser !== null && this.props.m_pwin.HaveFreePositionsToTable()) {
                        this.props.m_pwin.close_game_m_filename = 'files_1.0\\online\\open.gif';
                        let par = '*|E|1';
                        this.props.m_pwin.SendGame(par);
                        this.props.m_pwin.setState({});

                        let message = '*|G|' + this.m_watch.selectedUser;
                        this.props.m_pwin.SendGame(message);
                    }
                } else {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('Only the creator of the game can invite other users.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
            }
        }
    }
    ChatOff(username: string, enable: boolean, send: boolean) {
        this.m_watch.ChatOff(username, enable, send);
    }
    logOutWatchUser(username: string) {
        this.DeleteString(username);
        this.ChatOff(username, true, false);
        this.setState({});
    }

    async OnStaticSend() {
        if (!this.enable_chat) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('Your chat is blocked!'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            this.m_text = '';
            this.setState({});
            return;
        }
        let text: string;

        text = this.m_text;
        if (text !== '') {
            let kick_index = text.indexOf('(kick) ', 0);
            if (kick_index >= 0) {
                let username: string = text.substring(7 + kick_index, text.length);
                if (this.props.m_pwin.FindUsernameInTable(username)) {
                    if (this.props.m_pwin.isVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                        if (this.props.m_pwin.isVipPlayer(username)) {
                            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                this.props.m_pwin.m_main.GetLanguageText("You can't kick VIP users.")
                            );
                            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                            return;
                        } else if (this.props.m_pwin.isNoVipPlayer(username) || this.props.m_pwin.IsGuestUser(username)) {
                            let question_text: string = this.props.m_pwin.m_main.GetLanguageText('Do you want to kick (username) from the game?');
                            question_text.split('(username)').join(username);
                            this.props.m_pwin.m_yesnochips.SetTitleAndChips(
                                this.props.m_pwin.m_main.GetLanguageText('You must have a good reason to kick a user out of the game.'),
                                question_text,
                                this.props.m_pwin.m_main.kick_price
                            );
                            if ((await this.props.m_pwin.m_yesnochips.DoModal()) === GlobalConstants.IDOK) {
                                if (
                                    this.props.m_pwin.m_main.GetDBCash(this.props.m_pwin.m_con.getUsername()) >= this.props.m_pwin.m_main.kick_price
                                ) {
                                    this.props.m_pwin.SendGame(
                                        '*|P|' + this.props.m_pwin.m_main.IntToString(this.props.m_pwin.m_main.kick_price) + '|kick user: ' + username
                                    );
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.kick(text);
                                    return;
                                } else {
                                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
                                    return;
                                }
                            }
                        }
                    } else {
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                            this.props.m_pwin.m_main.GetLanguageText('You must be a VIP user to kick user from the game.')
                        );
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();

                        await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
                    }
                    this.m_text = '';
                    this.setState({});
                    return;
                } else {
                    if (!this.props.m_pwin.FindUsernameInWatchers(username)) {
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('This username not exist');
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return;
                    }
                }
            }
            this.kick(text);
        }
    }

    async kick(text: string) {
        if (!this.props.m_pwin.m_main.CheckSymbols(text)) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
        } else {
            let ctime = this.props.m_pwin.m_main.getCTime();
            this.m_history.push(this.props.m_pwin.m_main.formatMessage(ctime + this.nick + ' : ' + text));
            let message: string = 'K|' + this.nick + ' : ' + text;
            this.props.m_pwin.SendGame(message);
            this.m_text = '';
            this.setState({});
        }
    }

    SendMessage() {
        let message = this.m_text;
        let chatoff = message.indexOf('(chatoff) ', 0);
        let chaton = message.indexOf('(chaton) ', 0);
        if (chatoff >= 0) {
            this.ChatOff(message.substring(message.length - chatoff - 10, message.length), false, true);
            return;
        }
        if (chaton >= 0) {
            this.ChatOff(message.substring(message.length - chaton - 9, message.length), true, true);
            return;
        }
        this.OnStaticSend();
    }

    async onChangeChatText(text: string) {
        if(this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) && this.props.m_pwin.m_dbo.EnableChatMessagesForSelectedRoom() !== 'GUEST') {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('In order to be able to write chat messages in this room you must log in with your username.'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            this.m_text = '';
            this.setState({});
            return;
        }
        if (this.props.m_pwin.m_dbo.GetEnableWatchForSelectedRoom() === 'VIP' && this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('You must be a VIP user to write chat messages in this room.'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
            this.m_text = '';
            this.setState({});
            return;
        }
        this.m_text = text;
        if (this.m_text.length > 100) {
            this.SendMessage();
        }
        this.setState({});
    }

    OnExit() {
        this.props.m_pwin.setState({ CChatBarVisible: false });
    }

    onEmote(emote: number) {
        this.m_text = this.m_text + this.props.m_pwin.m_main.spec[emote];
        if (this.m_text.length > 100) {
            this.SendMessage();
        }
        this.setState({});
    }

    render() {
        //
        let emotePerRow = 10;
        if (GlobalVariables.WindowWidth > 870) {
            emotePerRow = Math.floor((GlobalVariables.WindowWidth - 600) / 27);
        }
        if (emotePerRow > 46) {
            emotePerRow = 46;
        }
        if (this.props.m_pwin.state.CChatBarVisible) {
            return (
                <View
                    style={[
                        cstyle.chatDockBarContainer,
                        {
                            top:
                                Platform.OS === 'ios'
                                    ? Math.max(
                                          GlobalVariables.positionTop +
                                              GlobalConstants.GAME_HEIGHT * GlobalVariables.scale -
                                              GlobalConstants.CHAT_BAR_HEIGHT,
                                          GlobalVariables.WindowHeight - GlobalConstants.CHAT_BAR_HEIGHT
                                      )
                                    : Math.max(
                                          GlobalVariables.positionTop + GlobalConstants.GAME_HEIGHT * GlobalVariables.scale,
                                          GlobalVariables.WindowHeight
                                      ),

                            width: Math.min(
                                GlobalVariables.WindowWidth - GlobalVariables.positionLeft,
                                Math.max(GlobalConstants.GAME_WIDTH * GlobalVariables.scale, 1024)
                            ),
                        },
                    ]}
                >
                    <View style={[cstyle.chatBarContainer]}>
                        <View style={[cstyle.chatContainer]}>
                            <View
                                style={[
                                    cstyle.historyContainer,
                                    { width: GlobalVariables.WindowWidth - 2 - emotePerRow * 16 - 180 - GlobalVariables.positionLeft },
                                ]}
                            >
                                <ScrollView
                                    style={cstyle.historyScrollContainer}
                                    ref={(ref) => (this.scrollView = ref)}
                                    onContentSizeChange={(width, height) => (this.scrollView ? this.scrollView.scrollTo({ y: height }) : null)}
                                >
                                    {this.m_history.map((parts: MessageParts[], index: number) => (
                                        <ChatMessage parts={parts} spec={this.props.m_pwin.m_main.spec} key={index} index={index} />
                                    ))}
                                </ScrollView>
                            </View>
                            <View
                                style={[
                                    cstyle.sendContainer,
                                    { width: GlobalVariables.WindowWidth - 2 - emotePerRow * 16 - 180 - GlobalVariables.positionLeft },
                                ]}
                            >
                                <View
                                    style={[
                                        cstyle.sendTextContainer,
                                        { width: GlobalVariables.WindowWidth - 2 - emotePerRow * 16 - 180 - 37 - GlobalVariables.positionLeft },
                                    ]}
                                >
                                    <TextInput
                                        value={this.m_text}
                                        onSubmitEditing={this.OnStaticSend.bind(this)}
                                        onChangeText={(text) => this.onChangeChatText(text)}
                                    />
                                </View>
                                <TouchableOpacity onPress={() => this.OnStaticSend()}>
                                    <Image source={require('../../../assets/Images/chat/send.png')} style={cstyle.sendButton} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <ScrollView style={{ width: 2 + emotePerRow * 16 }} horizontal={true}>
                            <Emote onEmote={this.onEmote.bind(this)} emotePerRow={emotePerRow} />
                        </ScrollView>
                        <Watchers parent={this} m_pwin={this.props.m_pwin} onRef={(ref: Watchers) => (this.m_watch = ref)} />
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CChatBar;

const cstyle = StyleSheet.create({
    chatContainer: {
        height: GlobalConstants.CHAT_BAR_HEIGHT,
    },
    chatDockBarContainer: {
        left: 0,
        backgroundColor: 'rgb(240,240,240)',
    },
    chatBarContainer: {
        flexDirection: 'row',
    },
    historyContainer: {
        borderColor: '#555555',
        borderStyle: 'solid',
        borderWidth: 1,
        margin: 1,
        maxWidth: 600,
    },
    historyScrollContainer: {
        height: 77,
    },
    sendContainer: {
        flexDirection: 'row',
        margin: 1,
        maxWidth: 600,
    },
    sendTextContainer: {
        borderColor: '#555555',
        borderStyle: 'solid',
        borderWidth: 1,
        maxWidth: 600 - 37,
        marginRight: 1,
    },
    sendButton: { width: 36, height: 21, paddingLeft: 1 },
});
