import React, { Component } from 'react';
import { View, Pressable, StyleSheet, TextInput, Keyboard, Dimensions } from 'react-native';
import { styles } from '../../../css/container';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import GlobalConstants from '../../Components/GlobalConstants';
import Text from '../../Components/core/Text';
import GlobalVariables from '../../Components/GlobalVariables';
import { dialog } from '../../../css/dialog';
import { modal } from '../../../css/modal';
import InputField from '~/Components/core/InputField';

class DeleteProfileScreen extends Component<BaseDlgProps> {
    ok: boolean = false;
    start: number;
    Title: string = 'Delete Profile';
    deleteProfileRef: TextInput;
    m_deleteProfile: string = '';

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    async validatePassword(): Promise<boolean> {
        if (this.m_deleteProfile.length > 0 && this.props.m_pwin.m_con.m_password !== this.m_deleteProfile) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Invalid Password!');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (this.m_deleteProfile.length === 0) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please enter a Password');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        return true;
    }

    async deleteProfile() {
        if(this.props.m_pwin.m_con.m_hiddenUsername !== '') {
            this.m_deleteProfile = await this.props.m_pwin.m_main.GetProfileString('Options', 'last_password', '');
            let message: string = 'S|' + this.m_deleteProfile + '|deleted_user?|deleted_user?';
            this.props.m_pwin.SendGame(message);
            this.props.m_pwin.CancelDlgOnline();
        }
        else {
            if (await this.validatePassword()) {
                let message: string = 'S|' + this.props.m_pwin.m_con.m_password + '|deleted_user?|deleted_user?';
                this.props.m_pwin.SendGame(message);
                this.props.m_pwin.CancelDlgOnline();
            }
        }
    }

    async DoModal() {
        this.props.m_pwin.state.DeleteProfileScreenVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DeleteProfileScreenVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDCANCEL;
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    OnOk() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ DeleteProfileScreenVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ DeleteProfileScreenVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.DeleteProfileScreenVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 100 * GlobalVariables.scale,
                                width: 600 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.Title}</Text>
                        </View>
                        <Pressable>
                            <View style={cstyles.changePasswordContainer}>
                                <View style={styles.row}>
                                    <Text textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE}>
                                        Your account will be deleted and cannot be recovered!
                                    </Text>
                                </View>
                                { this.props.m_pwin.m_con.m_hiddenUsername === '' ? 
                                <View style={styles.row}>
                                    <View style={cstyles.changePasswordInput}>
                                        <InputField
                                            blurOnSubmit={false}
                                            refinput={(ref: TextInput) => {
                                                this.deleteProfileRef = ref;
                                            }}
                                            onSubmitEditing={() => {
                                                Keyboard.dismiss;
                                            }}
                                            autoFocus={false}
                                            textContentType="none"
                                            onChangeText={(password) => (this.m_deleteProfile = password)}
                                            placeholder={'Password'}
                                            placeholderTextColor="#8b9cb5"
                                            keyboardType="default"
                                            maxLength={20}
                                            height={40 * GlobalVariables.scale}
                                            underlineColorAndroid="#f000"
                                            returnKeyType="done"
                                            leftIcon="lock"
                                            secureTextEntry={false}
                                            defaultValue=""
                                            required={true}
                                        />
                                    </View>
                                </View> : null }
                                <View style={styles.row}>
                                    <Button
                                        textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                        onPress={() => this.deleteProfile()}
                                        text="Confirm"
                                        containerStyle={modal.buttonStyle1PerRow}
                                    />
                                </View>
                            </View>
                        </Pressable>
                        <View style={[styles.row, cstyles.closeButtonPadding]}>
                            <Button
                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                containerStyle={modal.buttonStyle2PerRow}
                                onPress={() => this.OnOk()}
                                text="Close"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DeleteProfileScreen;

const cstyles = StyleSheet.create({
    changePasswordContainer: {
        borderWidth: 1,
        borderColor: 'gray',
        padding: 1,
        marginBottom: 1,
    },
    changePasswordInput: { width: '98%' },
    changePassword: { width: '17%' },
    closeButtonPadding: {
        paddingTop: 50 * GlobalVariables.scale,
    },
    text: { height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 * GlobalVariables.scale },
});
