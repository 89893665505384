import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import CCardGamesView from '../CCardGamesView';
import { AuthData } from '~/Dialogs/DlgConnect';
import config from '~/config';
import { Pressable, Image, Platform } from 'react-native';
import GlobalVariables from '../GlobalVariables';
import { SvgUri } from 'react-native-svg';
import GlobalConstants from '../GlobalConstants';

WebBrowser.maybeCompleteAuthSession();

type Props = {
    cCardGamesViewRef: CCardGamesView;
};

export default function GoogleLogin({ cCardGamesViewRef }: Props) {
    const [request, response, promptAsync] = Google.useAuthRequest({
        expoClientId: `${config.GOOGLE_LOGIN_EXPO}`,
        iosClientId: `${config.GOOGLE_LOGIN_IOS}`,
        androidClientId: `${config.GOOGLE_LOGIN_ANDROID}`,
        webClientId: `${config.GOOGLE_LOGIN_WEB}`,
        scopes: ['openid', 'profile', 'email'],
    });

    const [uid, setUid] = React.useState('null');

    React.useEffect(() => {
        async function fetchData() {
            let suid = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_google_id', null);
            setUid(suid);
        }
        fetchData();
    }, [cCardGamesViewRef.m_main]);

    React.useEffect(() => {
        if (response?.type === 'success') {
            let accessToken = response.authentication.accessToken;
            async function fetchData() {
                if (accessToken && cCardGamesViewRef) {
                    let userInfoResponse = await fetch('https://www.googleapis.com/userinfo/v2/me', {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    });
                    userInfoResponse.json().then((googleData) => {
                        let data: AuthData = {
                            email: googleData.email,
                            fname: googleData.given_name,
                            lname: googleData.family_name,
                            image: googleData.picture,
                            uid: googleData.id,
                        };
                        if (data.uid) {
                            cCardGamesViewRef.m_con.loginWithAuthData(data);
                            if (cCardGamesViewRef.m_con.m_save) {
                                setUid(data.uid);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_email', data.email);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_fname', data.fname);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_lname', data.lname);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_picture', data.image);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_id', data.uid);
                            } else {
                                setUid('null');
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_email', null);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_fname', null);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_lname', null);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_picture', null);
                                cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_id', null);
                            }
                        }
                    });
                }
            }
            fetchData();
        }
    }, [cCardGamesViewRef, response]);

    async function signinwithGoogle() {
        if (uid !== null && uid !== 'null') {
            let email: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_google_email', null);
            let fname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_google_fname', null);
            let lname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_google_lname', null);
            let image: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_google_picture', null);
            let data: AuthData = {
                email: email,
                fname: fname,
                lname: lname,
                image: image,
                uid: uid,
            };
            cCardGamesViewRef.m_con.loginWithAuthData(data);
            if (!cCardGamesViewRef.m_con.m_save) {
                deleteAuthData();
            }
        } else {
            request.clientId === `${config.GOOGLE_LOGIN_EXPO}`
                ? await promptAsync({ useProxy: true, showInRecents: true })
                : await promptAsync({ useProxy: false, showInRecents: true });
        }
    }

    async function deleteAuthData() {
        console.log('deleteAuthData');
        setUid('null');
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_email', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_fname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_lname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_picture', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_google_id', null);
    }

    return (
        <Pressable
            onLongPress={() => {
                deleteAuthData();
            }}
            onPress={() => signinwithGoogle()}
        >
            {Platform.OS === 'web' ? (
                <Image
                    source={require('../../../assets/Images/HomeScreen/Google.svg')}
                    style={{
                        width: 44 * GlobalVariables.scale,
                        height: 44 * GlobalVariables.scale,
                        marginHorizontal: 10 * GlobalVariables.scale,
                        resizeMode: 'stretch',
                    }}
                />
            ) : (
                <SvgUri
                    style={{
                        width: 44 * GlobalVariables.scale,
                        height: 44 * GlobalVariables.scale,
                        marginHorizontal: 10 * GlobalVariables.scale,
                    }}
                    uri={GlobalConstants.IMAGES_FOLDER + '/Images/HomeScreen/Google.svg'}
                />
            )}
        </Pressable>
    );
}
