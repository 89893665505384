import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import GlobalConstants from '~/Components/GlobalConstants';
import { styles } from '../../../css/container';

class IgraSantaseDlg extends Component<BaseDlgProps> {
    Game: number = 0;

    constructor(props) {
        super(props);
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraSantaseDlgVisible: true });
    }

    OnSp() {
        this.Game = 1;
        this.props.m_pwin.CloseIgraSantase();
    }

    async OnOp() {
        this.Game = 3;
        await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Schnapsen (66)');
        this.props.m_pwin.CloseIgraSantase();
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    OnIzhod() {
        this.props.m_pwin.setState({ IgraSantaseDlgVisible: false });
        this.props.m_pwin.OnNewGame();
    }

    render() {
        if (this.props.m_pwin.state.IgraSantaseDlgVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                {this.props.m_pwin.m_main.isGameVisible('Santase', true) ? (
                                    <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                ) : null}
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnIzhod.bind(this)} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraSantaseDlg;
