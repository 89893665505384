import { Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GamesProps } from './props/GamesProps';
import GlobalConstants from './GlobalConstants';
import * as qs from 'query-string';
import { Platform } from 'react-native';
import * as Localization from 'expo-localization';

class CCardGames extends Component<GamesProps> {
    constructor(props: GamesProps) {
        super(props);
    }

    GetOnlineVersion() {
        return GlobalConstants.ONLINE_VERSION;
    }

    GetShowAllGames(): boolean {
        if (Platform.OS === 'web') {
            let params = qs.parse(location.search);
            if (params && params.showAllGames) {
                if (Array.isArray(params.fgid)) {
                    return params.showAllGames[0] === 'true' ? true : false;
                } else {
                    return params.showAllGames === 'true' ? true : false;
                }
            }
        }
        return false;
    }

    GetServerAddress() {
        if (Platform.OS === 'web') {
            let params = qs.parse(location.search);
            if (params && params.server) {
                if (Array.isArray(params.server)) {
                    return params.server[0] !== '' ? params.server[0] : 'localhost:443';
                } else {
                    return params.server !== '' ? params.server : 'localhost:443';
                }
            }
        }
        return GlobalConstants.WS_SERVER;
    }

    async GetAffiliate(): Promise<string> {
        if (Platform.OS === 'web') {
            let params = qs.parse(location.search);
            if (params && params.fgid) {
                if (Array.isArray(params.fgid)) {
                    if (params.fgid[0] !== '') {
                        this.WriteProfileString('Options', 'fgid', params.fgid[0]);
                    }
                    return params.fgid[0] !== '' ? params.fgid[0] : '1';
                } else {
                    if (params.fgid !== '') {
                        this.WriteProfileString('Options', 'fgid', params.fgid);
                    }
                    return params.fgid !== '' ? params.fgid : '1';
                }
            } else {
                return await this.GetProfileString('Options', 'fgid', '1');
            }
        }
        return '1';
    }

    GetGameVersion() {
        return GlobalConstants.GAME_VERSION;
    }

    IntToString(num: number) {
        if (num === undefined || num === null) {
            return '0';
        }
        return num.toFixed(0);
    }

    async GetSavedLanguage() {
        return await this.GetProfileString('Options', 'language', this.GetDefaultLanguage());
    }

    GetDefaultLanguage(): string {
        if (Platform.OS === 'web') {
            let params = qs.parse(location.search);
            if (params && params.lang) {
                if (Array.isArray(params.lang)) {
                    return params.lang[0];
                } else {
                    return params.lang;
                }
            }
        }
        try {
            let locale: string = Localization.locale;
            if (locale === 'ar' || locale.indexOf('ar-') === 0) {
                return 'Arabic';
            }
            if (locale === 'bg' || locale.indexOf('bg-') === 0) {
                return 'Bulgarian';
            }
            if (locale === 'hr' || locale.indexOf('hr-') === 0) {
                return 'Croatian';
            }
            if (locale === 'cs' || locale.indexOf('cs-') === 0) {
                return 'Czech';
            }
            if (locale === 'da' || locale.indexOf('da-') === 0) {
                return 'Danish';
            }
            if (locale === 'nl' || locale.indexOf('nl-') === 0) {
                return 'Dutch';
            }
            if (locale === 'fr' || locale.indexOf('fr-') === 0) {
                return 'French';
            }
            if (locale === 'de' || locale.indexOf('de-') === 0) {
                return 'German';
            }
            if (locale === 'el' || locale.indexOf('el-') === 0) {
                return 'Greek';
            } 
            if (locale === 'hu' || locale.indexOf('hu-') === 0) {
                return 'Hungarian';
            }
            if (locale === 'it' || locale.indexOf('it-') === 0) {
                return 'Italian';
            }
            if (locale === 'mk' || locale.indexOf('mk-') === 0) {
                return 'Macedonian';
            }
            if (locale === 'pl' || locale.indexOf('pl-') === 0) {
                return 'Polish';
            }
            if (locale === 'pt' || locale.indexOf('pt-') === 0) {
                return 'Portuguese';
            }
            if (locale === 'ro' || locale.indexOf('ro-') === 0) {
                return 'Romanian';
            }
            if (locale === 'ru' || locale.indexOf('ru-') === 0) {
                return 'Russian';
            }
            if (locale === 'sr' || locale.indexOf('sr-') === 0) {
                return 'Serbian';
            }
            if (locale === 'sl' || locale.indexOf('sl-') === 0) {
                return 'Slovenian';
            }
            if (locale === 'es' || locale.indexOf('es-') === 0) {
                return 'Spanish';
            }
            if (locale === 'sv' || locale.indexOf('sv-') === 0) {
                return 'Swedish';
            }
            if (locale === 'tr' || locale.indexOf('tr-') === 0) {
                return 'Turkish';
            }
            if (locale === 'ua' || locale.indexOf('ua-') === 0) {
                return 'Ukrainian';
            }
        } catch (e) {
            console.log(e);
        }

        return 'English';
    }

    async GetProfileString(prefix: string, name: string, defaultValue: string) {
        let val: string = await AsyncStorage.getItem(prefix + '_' + name);
        if (val !== undefined && val !== null) {
            return val;
        }
        return defaultValue;
    }

    async GetProfileInt(prefix: string, name: string, defaultValue: number) {
        let val: string = await AsyncStorage.getItem(prefix + '_' + name);
        if (val !== undefined && val !== null) {
            return Number(val);
        }
        return defaultValue;
    }

    async WriteProfileInt(prefix: string, name: string, value: number) {
        if (value !== undefined && value !== null) {
            await AsyncStorage.setItem(prefix + '_' + name, value.toString());
        } else {
            AsyncStorage.removeItem(prefix + '_' + name);
        }
        return value;
    }

    async WriteProfileString(prefix: string, name: string, value: string) {
        if (value !== undefined && value !== null) {
            await AsyncStorage.setItem(prefix + '_' + name, value);
        } else {
            AsyncStorage.removeItem(prefix + '_' + name);
        }
        return value;
    }
}

export default CCardGames;
