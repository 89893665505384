import React, { Component } from 'react';
import { Keyboard, KeyboardAvoidingView, View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import InputField from '../Components/core/InputField';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';

class CNewTablePassword extends Component<BaseDlgProps> {
    start: number;
    ok: boolean = false;
    m_new_table_password: string = '';
    passwordVisibility: boolean = true;

    constructor(props: BaseDlgProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    setPasswordVisibility(passwordVisibility: boolean) {
        this.passwordVisibility = passwordVisibility;
        this.setState({});
    }

    ChangeTablePassword(password: string): void {
        this.m_new_table_password = password;
        this.setState({});
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    async DoModal() {
        this.props.m_pwin.state.CNewTablePasswordVisible = true;
        this.ok = false;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CNewTablePasswordVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDNO;
    }

    OnOK() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ CNewTablePasswordVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ CNewTablePasswordVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.CNewTablePasswordVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 80 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>New Table Password</Text>
                        </View>
                        <View style={styles.row}>
                            <KeyboardAvoidingView behavior={'padding'}>
                                <View style={{ width: 250 * GlobalVariables.scale }}>
                                    <InputField
                                        height={35 * GlobalVariables.scale}
                                        onChangeText={(m_new_table_password) => this.ChangeTablePassword(m_new_table_password)}
                                        placeholder={'Password'}
                                        placeholderTextColor="#8b9cb5"
                                        keyboardType="default"
                                        maxLength={12}
                                        onSubmitEditing={Keyboard.dismiss}
                                        blurOnSubmit={false}
                                        underlineColorAndroid="#f000"
                                        returnKeyType="done"
                                        leftIcon="lock"
                                        secureTextEntry={this.passwordVisibility}
                                        rightIcon={this.passwordVisibility ? 'eye' : 'eye-off'}
                                        onRightIconPress={() => this.setPasswordVisibility(!this.passwordVisibility)}
                                        value={this.m_new_table_password}
                                        textContentType="none"
                                        key={'CNewTablePasswordPass'}
                                    />
                                </View>
                            </KeyboardAvoidingView>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle3PerRow} onPress={this.OnOK.bind(this)} text="OK" />
                            <Button containerStyle={modal.buttonStyle3PerRow} onPress={this.OnCancel.bind(this)} text="Cancel" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CNewTablePassword;
