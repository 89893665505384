import CMain from '../../CMain';

class CDomino extends CMain {
    m_rez: number[] = [];
    constructor(props) {
        super(props);
    }
    async LoadBitmaps() {}
}

export default CDomino;
