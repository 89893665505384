import React, { Component } from 'react';
import { Dimensions, View } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class DlgObiaviavaneBla extends Component<ParentDlgProps> {
    type: number;
    Obiavi: boolean[] = [];
    m_pas: number = -1;
    start: number;
    m_rez1: number;
    m_rez2: number;
    m_rez3: number;
    name: string[] = [];
    constructor(props: ParentDlgProps) {
        super(props);
    }

    Onspa() {
        if (this.Obiavi[1] !== true || this.type === 21) {
            this.m_pas = 0;
            this.OnOk();
        }
    }

    Onkar() {
        if (this.Obiavi[2] !== true || this.type === 21) {
            this.m_pas = 1;
            this.OnOk();
        }
    }

    Onkup() {
        if (this.Obiavi[3] !== true || this.type === 21) {
            this.m_pas = 2;
            this.OnOk();
        }
    }

    Onpik() {
        if (this.Obiavi[4] !== true || this.type === 21) {
            this.m_pas = 3;
            this.OnOk();
        }
    }

    Onbez() {
        if (this.Obiavi[5] !== true || this.type === 21) {
            this.m_pas = 4;
            this.OnOk();
        }
    }

    AutoObiava() {
        if (this.Obiavi[1] !== true) {
            this.m_pas = 0;
            this.Onspa();
            return;
        }
        if (this.Obiavi[2] !== true) {
            this.m_pas = 1;
            this.Onkar();
            return;
        }
        if (this.Obiavi[3] !== true) {
            this.m_pas = 2;
            this.Onkup();
            return;
        }
        if (this.Obiavi[4] !== true) {
            this.m_pas = 3;
            this.Onpik();
            return;
        }
        if (this.Obiavi[5] !== true) {
            this.m_pas = 4;
            this.Onbez();
            return;
        }
    }

    OnPass() {
        this.m_pas = -1;
        this.OnOk();
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.OnOk();
    }

    OnOk() {
        this.start = 0;
    }

    OnInitDialog() {}

    async DoModal() {
        this.props.m_pwin.state.DlgObiaviavaneBlaVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgObiaviavaneBlaVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgObiaviavaneBlaVisible: false });
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.state.DlgObiaviavaneBlaVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 80 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Announcement</Text>
                        </View>
                        <View style={styles.row}>
                            <Button
                                disabled={this.Obiavi[1] && this.type !== 21}
                                image={'bid/1-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onspa.bind(this)}
                                text="Club"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.Obiavi[2] && this.type !== 21}
                                image={'bid/2-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onkar.bind(this)}
                                text="Diamond"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.Obiavi[3] && this.type !== 21}
                                image={'bid/3-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onkup.bind(this)}
                                text="Heart"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                        </View>
                        <View style={styles.row}>
                            <Button
                                disabled={this.Obiavi[4] && this.type !== 21}
                                image={'bid/4-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onpik.bind(this)}
                                text="Spade"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.Obiavi[5] && this.type !== 21}
                                image={'bid/5-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onbez.bind(this)}
                                text="No Trump"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.Obiavi[0]}
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                containerStyle={[modal.buttonStyle3PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.OnPass.bind(this)}
                                text="Pass"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgObiaviavaneBla;
