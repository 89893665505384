import t from '../../translation';
import CCardGames from './CCardGames';
import CPoint from './cppComponents/CPoint';
import React from 'react';
import CPicture from './cppComponents/CPicture';
import CRect from './cppComponents/CRect';
import GlobalConstants from './GlobalConstants';
import { Image, ImageBackground, Linking, Platform, StyleSheet, View } from 'react-native';
import GlobalVariables from './GlobalVariables';
import CSize from './cppComponents/CSize';
import CPictureParams from './cppComponents/CPictureParams';
import BorderText from './cppComponents/BorderText';
import DrawAnons from './Draw/DrawAnons';
import DrawChatMessage from './Draw/DrawChatMessage';
import { GamesProps } from './props/GamesProps';
import { MessageParts } from '../DockBars/ChatBar/CChatBar';
import Text from './core/Text';
import config from '~/config';

class CMain extends CCardGames {
    Name: string[] = [];
    HaveWatch: boolean = false;
    language: string;
    watch: boolean = false;
    k: number[] = [];
    frame: string;
    uil: string;
    uic: string;
    uir: string;
    p_bmp: string;
    rating: number[] = [];
    Players: number;
    StrelkaPos: CPoint[] = [];
    CardsPoint: CPoint[] = [];
    CardsPosDeal: CPoint[] = [];
    CardsPos: CPoint[] = [];
    cursor: string = GlobalConstants.CURSOR_ARROW;
    zar: boolean = false;
    CardPicture: string[] = [];
    spec_size: number;
    Title: string = '';
    Title2: string = '';
    hand: number = 0;
    P2OP: boolean = false;
    P3OP: boolean = false;
    P4OP: boolean = false;
    P5OP: boolean = false;
    P6OP: boolean = false;
    P7OP: boolean = false;
    P8OP: boolean = false;
    P9OP: boolean = false;
    P10OP: boolean = false;
    Razdavane1: boolean = false;
    Razdavane2: boolean = false;
    Server: boolean = false;
    Move: boolean = false;
    kapo1: boolean = false;
    kapo2: boolean = false;
    kapo3: boolean = false;
    OP: boolean = false;
    SP: boolean = false;
    P1G: boolean = false;
    P2G: boolean = false;
    P3G: boolean = false;
    P4G: boolean = false;
    P5G: boolean = false;
    P6G: boolean = false;
    P7G: boolean = false;
    P8G: boolean = false;
    P9G: boolean = false;
    P10G: boolean = false;
    op_start: boolean = false;
    open_rez_dlg: boolean = false;
    open_orez_dlg: boolean = false;
    show_timer: boolean = false;
    amessage: string[] = [];
    message: string[] = [];
    kick_price: number;
    show_ad_price: number = -500;
    vip_price: number;
    delete_statistic_price: number;
    poker_commission_bet_100: number;
    poker_commission_bet_10: number;
    LPoint: CPoint = new CPoint(0, 0);
    chat_message: string[] = [];
    ignore_list: string[] = [];
    friends_list: string[] = [];
    client_log_min_ver: number;
    sms_vip: string;
    sms_chips: string;
    cash: number[] = [];
    wait_opnames: string[] = [];
    sit_out_count: number = 0;
    sit_out_drop: number = 3;
    KoiPuskaParvi: number = 0;
    KoiZapochva: number = 0;
    KoiENaRed: number = 0;
    Faza: number = 0;
    Movx: number = 0;
    Movy: number = 0;
    mx: number = 0;
    my: number = 0;
    Kontra: number = 0;
    Mcard: number = 0;
    pause_timer: number = 0;
    P1puska: number = 0;
    P2puska: number = 0;
    P3puska: number = 0;
    P4puska: number = 0;
    Rekontra: number = 0;
    showrcard: number = 0;
    strelka: number = 0;
    TretiPuska: number = 0;
    ChetvartiPuska: number = 0;
    ParviPuska: number = 0;
    VtoriPuska: number = 0;
    Naddavane: number = 0;
    NamePos: CPoint[] = [];
    PosNaddavane: CPoint[] = [];
    last_Name: string[] = [];
    Zvuk: number[] = [];
    pause: number = 0;
    online_timer: number = 0;
    online_timer_time: number = 0;
    pause_player: string = '';
    wait_opplayers: boolean[] = [];
    spec: string[] = [];
    Speed: number[] = [];
    show_user = [];
    kd: number[] = [];
    vz: number[] = [];
    CardP: CPicture[] = []; // masiv ot kartite
    pause_rect: CRect;
    Tactic: number[] = [];
    enable_watch_game: boolean;
    pls: number[] = [];
    stereo: number;
    last_refused_user: string;

    strelkaPng: string[] = [];
    who: string;

    Pkazal = []; //double array
    user_details = []; //double array
    custom_image = [];

    uname: string[] = [];
    ucountry: string[] = [];
    ucity: string[] = [];
    ulanguage: string[] = [];
    urating: string[] = [];
    uvip: string[] = [];
    usex: number[] = [];
    uage: string[] = [];
    udescription: string[] = [];
    uversion: number[] = []; //Masiv ot versiite na igrachite
    uos: string[] = []; //Masiv ot OS na igrachite
    users: CPictureParams[] = [];
    no_img: string[] = [];
    loadingMain: boolean = true;
    obiaviavaneRN: any = null;
    diceColor: number = 1;
    endgame: boolean;
    Piska: boolean[][] = []; //[5][5],
    Pneiska: boolean[][] = []; //[5][5]
    Pniama: boolean[][] = []; //[5][5];//Pokazva koj kvo iska kvo neiska i kakvo niama
    show_stol: number[] = [];
    stol: any[][] = [];

    constructor(props: GamesProps) {
        super(props);
        this.InitMainSync();
        this.InitMainAsync();
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    TranslateMessage(str: string) {
        str = str.replace('<Create a game', '<' + this.GetLanguageText('Create a game'));
        str = str.replace('<Connect to', '<' + this.GetLanguageText('Connect to'));
        str = str.replace('<Login>', '<' + this.GetLanguageText('Login') + '>');
        str = str.replace('<Logout>', '<' + this.GetLanguageText('Logout') + '>');
        str = str.replace('<Begin Game>', '<' + this.GetLanguageText('Begin Game') + '>');
        str = str.replace('<Watch Table>', '<' + this.GetLanguageText('Watch Table') + '>');
        str = str.replace(': Belote 4 Players', ': ' + this.GetLanguageText('Belote 4 Players'));
        str = str.replace(': Belote 3 Players', ': ' + this.GetLanguageText('Belote 3 Players'));
        str = str.replace(': Belote 2 Players', ': ' + this.GetLanguageText('Belote 2 Players'));
        str = str.replace(': Domino 4 Players', ': ' + this.GetLanguageText('Domino 4 Players'));
        str = str.replace(': Domino 3 Players', ': ' + this.GetLanguageText('Domino 3 Players'));
        str = str.replace(': Domino 2 Players', ': ' + this.GetLanguageText('Domino 2 Players'));
        str = str.replace(': Schnapsen (66)', ': ' + this.GetLanguageText('Schnapsen (66)'));
        str = str.replace(': Backgammon - Tapa', ': ' + this.GetLanguageText('Backgammon - Tapa'));
        str = str.replace(': Backgammon - Gulbara', ': ' + this.GetLanguageText('Backgammon - Gulbara'));
        str = str.replace(': Bridge Rubber', ': ' + this.GetLanguageText('Bridge Rubber'));
        str = str.replace(': Duplicate Bridge', ': ' + this.GetLanguageText('Duplicate Bridge'));
        str = str.replace(': Chess', ': ' + this.GetLanguageText('Chess'));
        str = str.replace(': Poker', ': ' + this.GetLanguageText('Poker'));
        str = str.replace(': OKEY', ': ' + this.GetLanguageText('OKEY'));
        str = str.replace(': Ludo', ': ' + this.GetLanguageText('Ludo'));
        str = str.replace(': Sergeant Major (3-5-8)', ': ' + this.GetLanguageText('Sergeant Major (3-5-8)'));
        str = str.replace(': Backgammon', ': ' + this.GetLanguageText('Backgammon'));
        return str;
    }

    ClearMessages() {
        for (let i = 0; i < 100; i++) {
            this.message[i] = undefined;
        }
    }

    GetAllMessages() {
        let msg: string = '';
        for (let i = 0; i < 100; i++) {
            if (this.message[i] !== undefined) {
                msg = msg + this.message[i];
            }
        }
        return msg;
    }

    GetAllAMessages() {
        let msg: string = '';
        for (let i = 0; i < 100; i++) {
            if (this.amessage[i] !== undefined) {
                msg = msg + this.amessage[i];
            }
        }
        return msg;
    }

    ClearAMessages() {
        for (let i = 0; i < 100; i++) {
            this.amessage[i] = undefined;
        }
    }

    DrawKoiENaRed(x: number, y: number) {
        x = x - 23;
        return <CPicture image={this.who} m_x={x} m_y={y} m_Width={17} m_Height={13} cursor={GlobalConstants.CURSOR_ARROW} key={'DrawKoiENaRed'} />;
    }

    DrawStrelkaPng(strelka: number, game: string) {
        if (game === 'Tabla') {
            return (
                <CPicture
                    image={this.strelkaPng[3]}
                    m_x={this.StrelkaPos[strelka].x}
                    m_y={this.StrelkaPos[strelka].y}
                    m_Width={33}
                    m_Height={26}
                    cursor={GlobalConstants.CURSOR_ARROW}
                />
            );
        }
        if (game !== 'Tabla') {
            if (strelka === 1) {
                return (
                    <CPicture
                        image={this.strelkaPng[0]}
                        m_x={this.StrelkaPos[strelka].x}
                        m_y={this.StrelkaPos[strelka].y}
                        m_Width={26}
                        m_Height={33}
                        cursor={GlobalConstants.CURSOR_ARROW}
                    />
                );
            }
            if (strelka === 2 && this.Players !== 2) {
                return (
                    <CPicture
                        image={this.strelkaPng[1]}
                        m_x={this.StrelkaPos[strelka].x}
                        m_y={this.StrelkaPos[strelka].y}
                        m_Width={33}
                        m_Height={26}
                        cursor={GlobalConstants.CURSOR_ARROW}
                    />
                );
            }
            if (strelka === 3 || this.Players === 2) {
                return (
                    <CPicture
                        image={this.strelkaPng[2]}
                        m_x={this.StrelkaPos[3].x}
                        m_y={this.StrelkaPos[3].y}
                        m_Width={26}
                        m_Height={33}
                        cursor={GlobalConstants.CURSOR_ARROW}
                    />
                );
            }
            if (strelka === 4) {
                return (
                    <CPicture
                        image={this.strelkaPng[3]}
                        m_x={this.StrelkaPos[strelka].x}
                        m_y={this.StrelkaPos[strelka].y}
                        m_Width={33}
                        m_Height={26}
                        cursor={GlobalConstants.CURSOR_ARROW}
                    />
                );
            }
        }
    }

    DrawObiaviavanePNG(x: number, y: number, text: string) {
        return <DrawAnons text={text} position={new CPoint(x, y)} key={x + '_' + y + '_' + text} />;
    }

    changeLanguage(newLanguage: string): void {
        this.language = newLanguage;
        t.setTranslation(this.language);
    }

    _ttof(str: string) {
        return Number(str);
    }

    _ttoi(str: string) {
        return Number(str);
    }

    ScaleRect(rect: CRect) {
        return new CRect(
            rect.left * GlobalVariables.scale,
            rect.top * GlobalVariables.scale,
            (rect.left + rect.Width()) * GlobalVariables.scale,
            (rect.top + rect.Height()) * GlobalVariables.scale
        );
    }

    ShellExecute(url: string) {
        if (Platform.OS !== 'web') {
            if (url.indexOf('?') > 0) {
                url = url + '&store=' + this.GetAppStoreCode() + '&platform=' + Platform.OS;
            } else {
                url = url + '?store=' + this.GetAppStoreCode() + '&platform=' + Platform.OS;
            }
        }
        Linking.openURL(url);
    }

    ReadMessage() {
        let msg: string;
        if (this.CheckMessage()) {
            msg = this.message[0];
            for (let i = 0; i < 99; i++) {
                this.message[i] = this.message[i + 1];
            }
            this.message[99] = undefined;
        }
        return msg;
    }

    ReadAMessage() {
        let msg: string;
        if (this.CheckAMessage()) {
            msg = this.amessage[0];
            for (let i = 0; i < 99; i++) {
                this.amessage[i] = this.amessage[i + 1];
            }
            this.amessage[99] = undefined;
        }
        return msg;
    }

    CheckMessage() {
        if (this.message[0] !== undefined) {
            return true;
        }
        return false;
    }

    CheckAMessage() {
        if (this.amessage[0] !== undefined) {
            return true;
        }
        return false;
    }

    GameName(game: number, translate: boolean = true) {
        if (game === 0) {
            return translate ? this.GetLanguageText('Pass') : 'Pass';
        }
        if (game === 1) {
            return translate ? this.GetLanguageText('Club') : 'Club';
        }
        if (game === 2) {
            return translate ? this.GetLanguageText('Diamond') : 'Diamond';
        }
        if (game === 3) {
            return translate ? this.GetLanguageText('Heart') : 'Heart';
        }
        if (game === 4) {
            return translate ? this.GetLanguageText('Spade') : 'Spade';
        }
        if (game === 5) {
            return translate ? this.GetLanguageText('No Trump') : 'No Trump';
        }
        if (game === 6) {
            return translate ? this.GetLanguageText('Trumps') : 'Trumps';
        }
        if (game === 7) {
            return translate ? this.GetLanguageText('Double') : 'Double';
        }
        if (game === 8) {
            return translate ? this.GetLanguageText('Redouble') : 'Redouble';
        }
        if (game === 11) {
            return '1 ' + this.GetLanguageText('Club');
        }
        if (game === 12) {
            return '2 ' + this.GetLanguageText('Clubs');
        }
        if (game === 13) {
            return '3 ' + this.GetLanguageText('Clubs');
        }
        if (game === 14) {
            return '4 ' + this.GetLanguageText('Clubs');
        }
        if (game === 15) {
            return '5 ' + this.GetLanguageText('Clubs');
        }
        if (game === 16) {
            return '6 ' + this.GetLanguageText('Clubs');
        }
        if (game === 17) {
            return '7 ' + this.GetLanguageText('Clubs');
        }
        if (game === 21) {
            return '1 ' + this.GetLanguageText('Diamond');
        }
        if (game === 22) {
            return '2 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 23) {
            return '3 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 24) {
            return '4 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 25) {
            return '5 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 26) {
            return '6 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 27) {
            return '7 ' + this.GetLanguageText('Diamonds');
        }
        if (game === 31) {
            return '1 ' + this.GetLanguageText('Heart');
        }
        if (game === 32) {
            return '2 ' + this.GetLanguageText('Hearts');
        }
        if (game === 33) {
            return '3 ' + this.GetLanguageText('Hearts');
        }
        if (game === 34) {
            return '4 ' + this.GetLanguageText('Hearts');
        }
        if (game === 35) {
            return '5 ' + this.GetLanguageText('Hearts');
        }
        if (game === 36) {
            return '6 ' + this.GetLanguageText('Hearts');
        }
        if (game === 37) {
            return '7 ' + this.GetLanguageText('Hearts');
        }
        if (game === 41) {
            return '1 ' + this.GetLanguageText('Spade');
        }
        if (game === 42) {
            return '2 ' + this.GetLanguageText('Spades');
        }
        if (game === 43) {
            return '3 ' + this.GetLanguageText('Spades');
        }
        if (game === 44) {
            return '4 ' + this.GetLanguageText('Spades');
        }
        if (game === 45) {
            return '5 ' + this.GetLanguageText('Spades');
        }
        if (game === 46) {
            return '6 ' + this.GetLanguageText('Spades');
        }
        if (game === 47) {
            return '7 ' + this.GetLanguageText('Spades');
        }
        if (game === 51) {
            return '1 ' + this.GetLanguageText('No Trump');
        }
        if (game === 52) {
            return '2 ' + this.GetLanguageText('No Trumps');
        }
        if (game === 53) {
            return '3 ' + this.GetLanguageText('No Trumps');
        }
        if (game === 54) {
            return '4 ' + this.GetLanguageText('No Trumps');
        }
        if (game === 55) {
            return '5 ' + this.GetLanguageText('No Trumps');
        }
        if (game === 56) {
            return '6 ' + this.GetLanguageText('No Trumps');
        }
        if (game === 57) {
            return '7 ' + this.GetLanguageText('No Trumps');
        }
        return '';
    }

    SetTitle() {
        if (Platform.OS === 'web') {
            document.title = 'Favorite Games ' + GlobalConstants.GAME_VERSION;
        }
    }

    RandKoiZapochva(players: number) {
        return Math.floor(Math.random() * players) + 1;
    }

    BeginMove(fpoint: CPoint, lpoint: CPoint, card: number) {
        if (card <= 0) {
            return;
        }
        this.Movx = this.CardsPoint[card].x;
        this.Movy = this.CardsPoint[card].y;
        this.LPoint = lpoint;
        this.Mcard = card;
        this.Move = true;
        let x1, x2, y1, y2;
        x1 = fpoint.x;
        x2 = lpoint.x;
        y1 = fpoint.y;
        y2 = lpoint.y;
        if (x2 > x1 && y2 > y1) {
            //
            if (x2 - x1 > y2 - y1) {
                this.mx = this.Speed[1];
                this.my = (this.Speed[1] * (y2 - y1)) / (x2 - x1);
            } else {
                this.my = this.Speed[1];
                this.mx = (this.Speed[1] * (x2 - x1)) / (y2 - y1);
            }
        }
        if (x2 > x1 && y1 > y2) {
            //
            if (x2 - x1 > y1 - y2) {
                this.mx = this.Speed[1];
                this.my = (this.Speed[1] * (y2 - y1)) / (x2 - x1);
            } else {
                this.my = -this.Speed[1];
                this.mx = (this.Speed[1] * (x2 - x1)) / (y1 - y2);
            }
        }
        if (x1 > x2 && y2 > y1) {
            if (x1 - x2 > y2 - y1) {
                this.mx = -this.Speed[1];
                this.my = (this.Speed[1] * (y2 - y1)) / (x1 - x2);
            } else {
                this.my = this.Speed[1];
                this.mx = (this.Speed[1] * (x2 - x1)) / (y2 - y1);
            }
        }
        if (x1 > x2 && y1 > y2) {
            if (x1 - x2 > y1 - y2) {
                this.mx = -this.Speed[1];
                this.my = (this.Speed[1] * (y2 - y1)) / (x1 - x2);
            } else {
                this.my = -this.Speed[1];
                this.mx = (this.Speed[1] * (x1 - x2)) / (y2 - y1);
            }
        }
        //////////////////////////
        if (x1 === x2) {
            this.mx = 0;
            if (y2 > y1) {
                this.my = this.Speed[1];
            }
            if (y1 > y2) {
                this.my = -this.Speed[1];
            }
        }
        if (y1 === y2) {
            this.my = 0;
            if (x2 > x1) {
                this.mx = this.Speed[1];
            }
            if (x1 > x2) {
                this.mx = -this.Speed[1];
            }
        }
        ///////////////////////////
    }

    MoveCard(sound: boolean) {
        this.Movx = this.Movx + this.mx;
        this.Movy = this.Movy + this.my;
        this.CardsPoint[this.Mcard].x = this.Movx;
        this.CardsPoint[this.Mcard].y = this.Movy;
        if (
            this.CardsPoint[this.Mcard].x > this.LPoint.x - this.Speed[1] - 1 &&
            this.CardsPoint[this.Mcard].x < this.LPoint.x + this.Speed[1] + 1 &&
            this.CardsPoint[this.Mcard].y > this.LPoint.y - this.Speed[1] - 1 &&
            this.CardsPoint[this.Mcard].y < this.LPoint.y + this.Speed[1] + 1
        ) {
            if (GlobalVariables.Zvuk === 1 && sound) {
                this.props.m_pwin.m_sndSound.PlayFile('card', false);
            }
            this.Move = false;
            this.CardsPoint[this.Mcard].x = this.LPoint.x;
            this.CardsPoint[this.Mcard].y = this.LPoint.y;
        }
    }

    GetPlayer1Name() {
        let player1Name = 'Player1';
        return player1Name;
    }

    GetTextExtent(text: string, fontSize: number): CSize {
        return new CSize((text.length * fontSize) / 1.6, fontSize);
    }

    async InitMain() {
        this.InitMainSync();
        await this.InitMainAsync();
    }

    async InitMainSync() {
        for (let i = 0; i < 5; i++) {
            this.Pniama[i] = [];
        }
        for (let i = 0; i < 1000; i++) {
            this.user_details[i] = [];
            this.custom_image[i] = [];
        }
        for (let i = 0; i < 57; i++) {
            this.CardP[i] = new CPicture(null);
        }
        for (let i = 1; i < 11; i++) {
            this.rating[i] = -1;
            this.users[i] = new CPictureParams();
            this.StrelkaPos[i] = new CPoint(0, 0);
            this.chat_message[i] = '';
            this.NamePos[i] = new CPoint(0, 0);
            this.PosNaddavane[i] = new CPoint(0, 0);
            this.CardsPosDeal[i] = new CPoint(0, 0);
            this.Pkazal[i] = [];
            if (i !== 1) {
                this.Name[i] = '';
            }
            this.wait_opplayers[i] = false;
        }
        for (let i = 0; i < 58; i++) {
            this.CardsPoint[i] = new CPoint(0, 0);
            this.CardsPos[i] = new CPoint(0, 0);
        }
        this.watch = false;
        this.HaveWatch = false;
        this.pause_rect = new CRect(676, 478, 700, 498);
        if (this.getIsTest()) {
            this.online_timer = 0;
            this.online_timer_time = 0;
        } else {
            this.online_timer = 30;
            this.online_timer_time = 30;
        }
        this.enable_watch_game = false;

        this.stereo = 0;
        if (this.getIsTest()) {
            this.Speed[1] = 500;
            this.Speed[2] = 0;
            this.Speed[3] = 0;
            this.Speed[4] = 0;
        }
        this.frame = GlobalConstants.IMAGES_FOLDER + '/files_1.0/frame.gif';
        this.uil = 'user_information_01.gif';
        this.uic = 'user_information_02.gif';
        this.uir = 'user_information_03.gif';
        this.no_img[0] = '/files_1.0/no_img0.gif';
        this.no_img[1] = '/files_1.0/no_img1.gif';
        this.no_img[2] = '/files_1.0/no_img2.gif';
        for (let i = 1; i <= 10; i++) {
            this.show_user[i] = false;
            this.usex[i] = -1;
        }
        this.sit_out_count = 0;
        this.sit_out_drop = 3;
        if (this.getIsTest()) {
            this.sit_out_drop = 10000000;
        }
        this.KoiPuskaParvi = 0;
        this.KoiZapochva = 0;
        this.KoiENaRed = 0;
        this.Faza = 0;
        this.hand = 0;
        this.Kontra = 0;
        this.Mcard = 0;
        this.pause_timer = 0;
        GlobalVariables.OtkritiKarti = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.pause = 0;
        this.Players = 0;
        this.Rekontra = 0;
        this.showrcard = 0;
        this.strelka = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.Naddavane = 0;

        this.spec_size = 140;
        this.spec[0] = ':)';
        this.spec[1] = ':(';
        this.spec[2] = ':D';
        this.spec[3] = '(cool)';
        this.spec[4] = '(oh)';
        this.spec[5] = ';)';
        this.spec[6] = ';(';
        this.spec[7] = '(grin)';
        this.spec[8] = '(blush)';
        this.spec[9] = '(kiss)';
        this.spec[10] = ':P';
        this.spec[11] = '(sleep)';
        this.spec[12] = '(puke)';
        this.spec[13] = '(wasntme)';
        this.spec[14] = '(hi)';
        this.spec[15] = '(envy)';
        this.spec[16] = '(clap)';
        this.spec[17] = ':?';
        this.spec[18] = '(whew)';
        this.spec[19] = '(nod)';
        this.spec[20] = '(happy)';
        this.spec[21] = '(rofl)';
        this.spec[22] = '(box)';
        this.spec[23] = '(shake)';
        this.spec[24] = '(y)';
        this.spec[25] = '(n)';
        this.spec[26] = '(chuckle)';
        this.spec[27] = '(clock)';
        this.spec[28] = '(club)';
        this.spec[29] = '(diamond)';
        this.spec[30] = '(heart)';
        this.spec[31] = '(spade)';

        this.spec[32] = '(flex)';
        this.spec[33] = '(music)';
        this.spec[34] = ';/';
        this.spec[35] = '(yawn)';
        this.spec[36] = ':@';
        this.spec[37] = '(emo)';
        this.spec[38] = '(deal)';
        this.spec[39] = '(devil)';
        this.spec[40] = '(wait)';
        this.spec[41] = '(makeup)';
        this.spec[42] = '(:[';
        this.spec[43] = ':[';
        this.spec[44] = '(dull)';
        this.spec[45] = '(doh)';
        this.spec[46] = ':8';
        this.spec[47] = ':s';
        this.spec[48] = '(bow)';
        this.spec[49] = '(party)';
        this.spec[50] = '(call)';
        this.spec[51] = '(hug)';
        this.spec[52] = '(h)';
        this.spec[53] = '(u)';
        this.spec[54] = '(rain)';
        this.spec[55] = '(sun)';
        this.spec[56] = '(smirk)';
        this.spec[57] = '(mail)';
        this.spec[58] = '(pizza)';
        this.spec[59] = '(cake)';
        this.spec[60] = '(cash)';
        this.spec[61] = '(flower)';
        this.spec[62] = '(movie)';
        this.spec[63] = '(dance)';
        this.spec[64] = '(drink)';
        this.spec[65] = '(drunk)';
        this.spec[66] = '(smoke)';
        this.spec[67] = '(rock)';
        this.spec[68] = '(headbang)';
        this.spec[69] = '(fubar)';
        this.spec[70] = '(mmm)';
        this.spec[71] = '(phone)';
        this.spec[72] = '(coffee)';
        this.spec[73] = '(beer)';
        this.spec[74] = ':x';
        this.spec[75] = '(highfive)';
        this.spec[76] = '(fingercrossed)';
        this.spec[77] = '(facepalm)';
        this.spec[78] = '(nonono)';
        this.spec[79] = '(lalala)';
        this.spec[80] = '(swear)';
        this.spec[81] = '(stop)';
        this.spec[82] = '(proofy)';
        this.spec[83] = '(talktohand)';
        this.spec[84] = '(innocent)';
        this.spec[85] = '(beardy)';
        this.spec[86] = '(idea)';
        this.spec[87] = '(bowler)';
        this.spec[88] = '(inlove)';
        this.spec[89] = '(emoglasses)';
        this.spec[90] = '(giggle)';
        this.spec[91] = '(robber)';
        this.spec[92] = '(football)';
        this.spec[93] = '(angryred)';
        this.spec[94] = '(bike)';
        this.spec[95] = '(gift)';
        this.spec[96] = '(dog)';
        this.spec[97] = '(greeny)';
        this.spec[98] = '(lucky)';
        this.spec[99] = '(ninja)';
        this.spec[100] = '(bluehead)';
        this.spec[101] = '(blackcat)';
        this.spec[102] = '(neonglasses)';
        this.spec[103] = '(bug)';
        this.spec[104] = '(calendar)';
        this.spec[105] = '(car)';
        this.spec[106] = '(camera)';
        this.spec[107] = '(home)';
        this.spec[108] = '(island)';
        this.spec[109] = '(squirrel)';
        this.spec[110] = '(pc)';
        this.spec[111] = '(fist2fist)';
        this.spec[112] = '(police)';
        this.spec[113] = '(poolparty)';
        this.spec[114] = '(journalist)';
        this.spec[115] = '(mandog)';
        this.spec[116] = '(lock)';
        this.spec[117] = '(nailpolish)';
        this.spec[118] = '(redwoman)';
        this.spec[119] = '(running)';
        this.spec[120] = '(mooning)';
        this.spec[121] = '(star)';
        this.spec[122] = '(bomb)';
        this.spec[123] = '(cartoon)';
        this.spec[124] = '(questionexclam)';
        this.spec[125] = '(give5)';
        this.spec[126] = '(hourglass)';
        this.spec[127] = '(officeman)';
        this.spec[128] = '(victory)';
        this.spec[129] = '(plain)';
        this.spec[130] = '(monkey)';
        this.spec[131] = '(phonenoway)';
        this.spec[132] = '(rainbow)';
        this.spec[133] = '(joystick)';
        this.spec[134] = '(yourfalt)';
        this.spec[135] = '(sheep)';
        this.spec[136] = '(snail)';
        this.spec[137] = '(tumbleweed)';
        this.spec[138] = '(umbrella)';
        this.spec[139] = '(brb)';
        this.P2OP = false;
        this.P3OP = false;
        this.P4OP = false;
        this.P5OP = false;
        this.P6OP = false;
        this.P7OP = false;
        this.P8OP = false;
        this.P9OP = false;
        this.P10OP = false;

        for (let i = 0; i < 5; i++) {
            this.stol[i] = [];
            this.show_stol[i] = -1;
        }
        for (let i = 0; i < 5; i++) {
            for (let a = 0; a < 3; a++) {
                this.stol[i][a] = new CPicture(null);
            }
        }

        this.stol[0][0].m_x = 0;
        this.stol[0][0].m_y = 533;
        this.stol[0][0].image = 'skins/0/0.gif';
        this.stol[0][0].m_Width = 149;
        this.stol[0][0].m_Height = 160;
        this.stol[1][0].m_x = 150;
        this.stol[1][0].m_y = 533;
        this.stol[1][0].image = 'skins/0/1.gif';
        this.stol[1][0].m_Width = 163;
        this.stol[1][0].m_Height = 160;
        this.stol[2][0].m_x = 314;
        this.stol[2][0].m_y = 533;
        this.stol[2][0].image = 'skins/0/2.gif';
        this.stol[2][0].m_Width = 166;
        this.stol[2][0].m_Height = 160;
        this.stol[3][0].m_x = 800;
        this.stol[3][0].m_y = 176;
        this.stol[3][0].image = 'skins/0/3.gif';
        this.stol[3][0].m_Width = 215;
        this.stol[3][0].m_Height = 168;
        this.stol[4][0].m_x = 800;
        this.stol[4][0].m_y = 14;
        this.stol[4][0].image = 'skins/0/4.gif';
        this.stol[4][0].m_Width = 215;
        this.stol[4][0].m_Height = 162;

        this.stol[0][1].m_x = 0;
        this.stol[0][1].m_y = 502;
        this.stol[0][1].image = 'skins/1/0.gif';
        this.stol[0][1].m_Width = 148;
        this.stol[0][1].m_Height = 191;
        this.stol[1][1].m_x = 149;
        this.stol[1][1].m_y = 502;
        this.stol[1][1].image = 'skins/1/1.gif';
        this.stol[1][1].m_Width = 163;
        this.stol[1][1].m_Height = 191;
        this.stol[2][1].m_x = 313;
        this.stol[2][1].m_y = 502;
        this.stol[2][1].image = 'skins/1/2.gif';
        this.stol[2][1].m_Width = 170;
        this.stol[2][1].m_Height = 191;
        this.stol[3][1].m_x = 772;
        this.stol[3][1].m_y = 174;
        this.stol[3][1].image = 'skins/1/3.gif';
        this.stol[3][1].m_Width = 243;
        this.stol[3][1].m_Height = 168;
        this.stol[4][1].m_x = 772;
        this.stol[4][1].m_y = 15;
        this.stol[4][1].image = 'skins/1/4.gif';
        this.stol[4][1].m_Width = 243;
        this.stol[4][1].m_Height = 159;

        this.stol[0][2].m_x = 0;
        this.stol[0][2].m_y = 502;
        this.stol[0][2].image = 'skins/2/0.gif';
        this.stol[0][2].m_Width = 148;
        this.stol[0][2].m_Height = 191;
        this.stol[1][2].m_x = 149;
        this.stol[1][2].m_y = 502;
        this.stol[1][2].image = 'skins/2/1.gif';
        this.stol[1][2].m_Width = 163;
        this.stol[1][2].m_Height = 191;
        this.stol[2][2].m_x = 313;
        this.stol[2][2].m_y = 502;
        this.stol[2][2].image = 'skins/2/2.gif';
        this.stol[2][2].m_Width = 170;
        this.stol[2][2].m_Height = 191;
        this.stol[3][2].m_x = 772;
        this.stol[3][2].m_y = 174;
        this.stol[3][2].image = 'skins/2/3.gif';
        this.stol[3][2].m_Width = 243;
        this.stol[3][2].m_Height = 168;
        this.stol[4][2].m_x = 772;
        this.stol[4][2].m_y = 15;
        this.stol[4][2].image = 'skins/2/4.gif';
        this.stol[4][2].m_Width = 243;
        this.stol[4][2].m_Height = 159;
    }

    async InitMainAsync() {
        this.Name[1] = await this.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());

        GlobalVariables.Zvuk = await this.GetProfileInt('Options', 'Sound', 1);
        GlobalVariables.show_pictures = (await this.GetProfileInt('Options', 'show_pictures', 1)) === 1;
        GlobalVariables.show_rating = (await this.GetProfileInt('Options', 'show_rating', 1)) === 1;
        let poolsColor = await this.GetProfileString('Options', 'player_pools_color', 'Black');
        if (poolsColor === 'White' || poolsColor === 'Black') {
            GlobalVariables.player_pools_color = poolsColor;
        }
        this.language = await this.GetProfileString('Options', 'language', this.GetDefaultLanguage());
        let cs = this.GetSexSound();
        if (cs === 2 || cs === -1) {
            this.pls[1] = await this.GetProfileInt('Options', 'pl1s', 0);
            this.pls[2] = await this.GetProfileInt('Options', 'pl2s', 1);
            this.pls[3] = await this.GetProfileInt('Options', 'pl3s', 0);
            this.pls[4] = await this.GetProfileInt('Options', 'pl4s', 1);
            this.pls[5] = await this.GetProfileInt('Options', 'pl5s', 1);
            this.pls[6] = await this.GetProfileInt('Options', 'pl6s', 0);
            this.pls[7] = await this.GetProfileInt('Options', 'pl7s', 0);
            this.pls[8] = await this.GetProfileInt('Options', 'pl8s', 1);
            this.pls[9] = await this.GetProfileInt('Options', 'pl9s', 0);
            this.pls[10] = await this.GetProfileInt('Options', 'pl10s', 1);
        }
        if (cs === 0) {
            for (let i = 1; i <= 10; i++) {
                this.pls[i] = 0;
            }
        }
        if (cs === 1) {
            for (let i = 1; i <= 10; i++) {
                this.pls[i] = 1;
            }
        }
        this.stereo = 0;
        this.Speed[1] = await this.GetProfileInt('Options', 'Speed[1]', 80);
        this.Speed[2] = await this.GetProfileInt('Options', 'Speed[2]', 10);
        this.Speed[3] = await this.GetProfileInt('Options', 'Speed[3]', 10);
        this.Speed[4] = await this.GetProfileInt('Options', 'Speed[4]', 15);
        if (this.getIsTest()) {
            this.Speed[1] = 500;
            this.Speed[2] = 0;
            this.Speed[3] = 0;
            this.Speed[4] = 0;
        }
        GlobalVariables.automatic_release = (await this.GetProfileInt('Options', 'automatic_release', 0)) === 1;
        GlobalVariables.automatic_td = (await this.GetProfileInt('Options', 'automatic_td', 1)) === 1;
        this.SetTitle();
        this.loadingMain = false;
    }

    GetCustomImage(username: string) {
        for (let i = 0; i < 1000; i++) {
            if (this.custom_image[i][0] === username) {
                return this.custom_image[i][1];
            }
        }
        return '-';
    }

    randomShuffle(array) {
        var tmp,
            current,
            top = array.length;
        if (top) {
            while (--top) {
                current = Math.floor(Math.random() * (top + 1));
                tmp = array[current];
                array[current] = array[top];
                array[top] = tmp;
            }
        }
        return array;
    }

    GetCloseDialogTimer() {
        if (this.getIsTest()) {
            return 0;
        }
        return 15;
    }

    GetSexSound() {
        return 2;
    }

    CheckUsernameAndPassword(s: string) {
        for (let i = 0; i < s.length; i++) {
            if (!this.UsernameAndPasswordSymbols(s.charAt(i))) {
                return false;
            }
        }
        return true;
    }

    convertStringToByteArray(str: string) {
        var bytes = [];
        for (var i = 0; i < str.length; ++i) {
            bytes.push(str.charCodeAt(i));
        }

        return bytes;
    }

    UsernameAndPasswordSymbols(s) {
        if (s === 'a') {
            return true;
        }
        if (s === 'b') {
            return true;
        }
        if (s === 'c') {
            return true;
        }
        if (s === 'd') {
            return true;
        }
        if (s === 'e') {
            return true;
        }
        if (s === 'f') {
            return true;
        }
        if (s === 'g') {
            return true;
        }
        if (s === 'h') {
            return true;
        }
        if (s === 'i') {
            return true;
        }
        if (s === 'j') {
            return true;
        }
        if (s === 'k') {
            return true;
        }
        if (s === 'l') {
            return true;
        }
        if (s === 'm') {
            return true;
        }
        if (s === 'n') {
            return true;
        }
        if (s === 'o') {
            return true;
        }
        if (s === 'p') {
            return true;
        }
        if (s === 'q') {
            return true;
        }
        if (s === 'r') {
            return true;
        }
        if (s === 's') {
            return true;
        }
        if (s === 't') {
            return true;
        }
        if (s === 'u') {
            return true;
        }
        if (s === 'v') {
            return true;
        }
        if (s === 'w') {
            return true;
        }
        if (s === 'x') {
            return true;
        }
        if (s === 'y') {
            return true;
        }
        if (s === 'z') {
            return true;
        }
        if (s === 'A') {
            return true;
        }
        if (s === 'B') {
            return true;
        }
        if (s === 'C') {
            return true;
        }
        if (s === 'D') {
            return true;
        }
        if (s === 'E') {
            return true;
        }
        if (s === 'F') {
            return true;
        }
        if (s === 'G') {
            return true;
        }
        if (s === 'H') {
            return true;
        }
        if (s === 'I') {
            return true;
        }
        if (s === 'J') {
            return true;
        }
        if (s === 'K') {
            return true;
        }
        if (s === 'L') {
            return true;
        }
        if (s === 'M') {
            return true;
        }
        if (s === 'N') {
            return true;
        }
        if (s === 'O') {
            return true;
        }
        if (s === 'P') {
            return true;
        }
        if (s === 'Q') {
            return true;
        }
        if (s === 'R') {
            return true;
        }
        if (s === 'S') {
            return true;
        }
        if (s === 'T') {
            return true;
        }
        if (s === 'U') {
            return true;
        }
        if (s === 'V') {
            return true;
        }
        if (s === 'W') {
            return true;
        }
        if (s === 'X') {
            return true;
        }
        if (s === 'Y') {
            return true;
        }
        if (s === 'Z') {
            return true;
        }

        if (s === '0') {
            return true;
        }
        if (s === '1') {
            return true;
        }
        if (s === '2') {
            return true;
        }
        if (s === '3') {
            return true;
        }
        if (s === '4') {
            return true;
        }
        if (s === '5') {
            return true;
        }
        if (s === '6') {
            return true;
        }
        if (s === '7') {
            return true;
        }
        if (s === '8') {
            return true;
        }
        if (s === '9') {
            return true;
        }
        if (s === '_') {
            return true;
        }
        if (s === '-') {
            return true;
        }
        return false;
    }

    DrawBorderText(text: string, x: number, y: number, key: string) {
        let pos = new CPoint(x, y);
        return <BorderText text={text} position={pos} fontSize={13} key={key} />;
    }

    WriteAMessage(msg: string) {
        for (let i = 0; i < 100; i++) {
            if (this.amessage[i] === undefined) {
                this.amessage[i] = msg;
                break;
            }
        }
    }

    WriteMessage(msg: string) {
        for (let i = 0; i < 100; i++) {
            if (this.message[i] === undefined) {
                this.message[i] = msg;
                break;
            }
        }
    }

    getPlayerStatus(username: string) {
        let status: string = '';
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                return this.user_details[i][12];
            }
        }
        return status;
    }

    getUserCountry(username: string): string {
        let country: string = '';
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                return this.user_details[i][2];
            }
        }
        return country;
    }

    getVipStatus(username: string) {
        let status: string = 'VIP';
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                return this.user_details[i][12];
            }
        }
        return status;
    }

    LoadFiles() {
        this.p_bmp = 'pause.gif';
        this.who = 'arrow/who.png';
        this.strelkaPng[0] = 'arrow/down.png';
        this.strelkaPng[1] = 'arrow/right.png';
        this.strelkaPng[2] = 'arrow/up.png';
        this.strelkaPng[3] = 'arrow/left.png';
    }

    GetDefaultServerName(): string {
        return 'Server1';
    }

    SetPlayerRating(name: string, prating: number) {
        for (let i = 1; i <= this.Players; i++) {
            if (this.Name[i] === name) {
                this.rating[i] = prating;
            }
        }
        this.setState({});
    }

    DrawRating() {
        if (this.OP) {
            if (GlobalVariables.show_rating) {
                let res = [];
                for (let i = 1; i <= this.Players; i++) {
                    if (this.Name[i] !== '' && this.rating[i] >= 0) {
                        let greenLineHeight = Math.max(1 * GlobalVariables.scale, 1);
                        let redLineHeight = greenLineHeight + 2;
                        let ratingPositionX = (this.NamePos[i].x - 4) * GlobalVariables.scale;
                        let ratingPositionY = (this.NamePos[i].y - 8) * GlobalVariables.scale;
                        let pr = this.rating[i];
                        if (pr > 100) {
                            pr = 100;
                        }
                        res.push(
                            <View
                                style={[
                                    cstyles.ratingContainer,
                                    {
                                        width: 50 * GlobalVariables.scale + 2,
                                        height: redLineHeight,
                                        top: ratingPositionY,
                                        left: ratingPositionX,
                                    },
                                ]}
                                key={'Rating' + i}
                            >
                                <View style={[cstyles.ratingGreen, { width: 1 + (pr / 2) * GlobalVariables.scale + 2, height: greenLineHeight }]} />
                            </View>
                        );
                    }
                }
                return res;
            }
        } else {
            return null;
        }
    }

    DrawUsers(game: string) {
        if (this.OP) {
            let difx: number = -150 / GlobalVariables.scale;
            let dify: number = -130 / GlobalVariables.scale;
            let begin = 2;
            if (this.watch) {
                begin = 1;
            }
            for (let i = begin; i <= this.Players; i++) {
                if (
                    this.show_user[i] !== false &&
                    this.Name[i] !== 'Computer2' &&
                    this.Name[i] !== 'Computer3' &&
                    this.Name[i] !== 'Computer4' &&
                    this.Name[i] !== 'Computer5' &&
                    this.Name[i] !== 'Computer6' &&
                    this.Name[i] !== 'Computer7' &&
                    this.Name[i] !== 'Computer8' &&
                    this.Name[i] !== 'Computer9' &&
                    this.Name[i] !== 'Computer10' &&
                    this.Name[i] !== ''
                ) {
                    if (this.NamePos[i].x > 500) {
                        this.users[i].m_x = this.NamePos[i].x + difx;
                    } else {
                        this.users[i].m_x = this.NamePos[i].x;
                    }
                    if (this.NamePos[i].y > 350) {
                        this.users[i].m_y = this.NamePos[i].y + dify;
                    } else {
                        this.users[i].m_y = this.NamePos[i].y;
                    }
                    let image = this.props.m_pwin.m_main.GetCustomImage(this.Name[i]);
                    if (this.usex[i] !== 0 && this.usex[i] !== 1 && this.usex[i] !== 2) {
                        this.usex[i] = 0;
                    }
                    if (image === '-') {
                        image = GlobalConstants.IMAGES_FOLDER + this.no_img[this.usex[i]];
                    }
                    let imageNoImg = GlobalConstants.IMAGES_FOLDER + this.no_img[this.usex[i]];

                    let s1: string = this.GetLanguageText('Name') + ': ' + this.uname[i];
                    let s2 = this.GetLanguageText('Country') + ': ' + this.GetLanguageText(this.ucountry[i]);
                    let s3 = this.GetLanguageText('City') + ': ' + this.ucity[i];
                    let s4 = this.GetLanguageText('Language') + ': ' + this.GetLanguageText(this.ulanguage[i]);
                    let sex = '-';
                    if (this.usex[i] === 1) {
                        sex = this.GetLanguageText('Man');
                    }
                    if (this.usex[i] === 2) {
                        sex = this.GetLanguageText('Woman');
                    }
                    let s5 = this.GetLanguageText('Sex') + ': ' + sex;
                    let s6 = this.GetLanguageText('Age') + ': ' + this.uage[i];
                    if (this.uage[i] === '0') {
                        s6 = this.GetLanguageText('Age') + ': -';
                    }
                    let s7 = this.GetLanguageText('Description') + ': ' + this.udescription[i];
                    let s8 = '';
                    if (game === 'Poker' || game === 'PokerOmaha' || game === 'Svara' || game === 'DrawPoker') {
                        s8 = this.GetLanguageText('Fun Money') + ': ' + this.urating[i];
                    } else {
                        s8 = this.GetLanguageText('Rating') + ': ' + this.IntToString(this.rating[i]);
                    }

                    return (
                        <View
                            style={[
                                cstyles.drawUserContainer,
                                {
                                    top: this.users[i].m_y * GlobalVariables.scale,
                                    left: this.users[i].m_x * GlobalVariables.scale,
                                },
                            ]}
                        >
                            <View>
                                <ImageBackground
                                    source={{
                                        uri: imageNoImg,
                                    }}
                                    style={[cstyles.avatarStyleNoImg]}
                                >
                                    <ImageBackground
                                        source={{
                                            uri: image,
                                        }}
                                        style={[cstyles.avatarStyle]}
                                    >
                                        <Image
                                            source={{
                                                uri: this.props.m_pwin.m_main.frame,
                                            }}
                                            style={cstyles.frameStyle}
                                        />
                                    </ImageBackground>
                                </ImageBackground>
                                <CPicture
                                    image={'online/vip/' + this.uvip[i] + '.png'}
                                    folder={'files_1.0'}
                                    m_x={74}
                                    m_y={9}
                                    m_Width={20}
                                    m_Height={20}
                                    noScale={true}
                                />
                                <CPicture
                                    image={'online/os/' + this.uos[i] + '.png'}
                                    folder={'files_1.0'}
                                    m_x={6}
                                    m_y={9}
                                    m_Width={20}
                                    m_Height={20}
                                    noScale={true}
                                />
                            </View>
                            <View>
                                <CPicture
                                    image={this.uil}
                                    folder={'files_1.0'}
                                    m_x={105}
                                    m_y={0}
                                    m_Width={6}
                                    m_Height={130}
                                    noScale={true}
                                    relative={true}
                                />
                            </View>
                            <View>
                                <ImageBackground
                                    source={{
                                        uri: GlobalConstants.IMAGES_FOLDER + '/files_1.0/' + this.uic,
                                    }}
                                    resizeMode="stretch"
                                    style={cstyles.drawUserDetailsCenter}
                                >
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s1}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s2}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s3}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s4}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s5}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s6}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s7}
                                        </Text>
                                    </View>
                                    <View style={cstyles.userDetail}>
                                        <Text scaleText={false} textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color="black">
                                            {s8}
                                        </Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View>
                                <CPicture
                                    image={this.uir}
                                    folder={'files_1.0'}
                                    m_x={105}
                                    m_y={0}
                                    m_Width={6}
                                    m_Height={130}
                                    noScale={true}
                                    relative={true}
                                />
                            </View>
                        </View>
                    );
                }
            }
        }
        return null;
    }

    formatMessage(message: string): MessageParts[] {
        let messageParts: MessageParts[] = [];
        let textWithoutEmote = '';
        for (let i = 0; i < message.length; i++) {
            for (let a = 0; a < this.spec.length; a++) {
                if (message.indexOf(this.spec[a]) === i) {
                    if (textWithoutEmote !== '') {
                        messageParts.push({ type: 'text', text: textWithoutEmote });
                    }
                    messageParts.push({ type: 'emote', emoteIndex: a });
                    message = message.substring(i + this.spec[a].length, message.length);
                    i = -1;
                    textWithoutEmote = '';
                    break;
                }
            }
            textWithoutEmote = textWithoutEmote + message.charAt(i);
        }
        if (textWithoutEmote !== '') {
            messageParts.push({ type: 'text', text: textWithoutEmote });
        }
        return messageParts;
    }

    DrawChatMessages() {
        let res = [];
        for (let player = 1; player <= this.Players; player++) {
            if (this.chat_message[player] !== '') {
                let x = this.PosNaddavane[player].x;
                let y = this.PosNaddavane[player].y - 6;
                res.push(
                    <DrawChatMessage
                        text={this.chat_message[player]}
                        position={new CPoint(x, y)}
                        spec={this.spec}
                        m_main={this}
                        key={this.chat_message[player] + '_' + player}
                    />
                );
            }
        }
        return res;
    }

    GetDBCash(username: string): number {
        let dbcash: number = 0;
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                let cashstr = this.user_details[i][15];
                return this._ttof(cashstr);
            }
        }
        return dbcash;
    }

    CheckSymbols(s: string): boolean {
        for (let i = 0; i < s.length; i++) {
            if (!this.IsToEnabledSymbols(s.charAt(i))) {
                return false;
            }
        }
        return true;
    }

    IsToEnabledSymbols(s: string): boolean {
        if (s === '*') {
            return false;
        }
        if (s === '|') {
            return false;
        }
        if (s === '^') {
            return false;
        }
        if (s === '#') {
            return false;
        }
        if (s === '$') {
            return false;
        }
        if (s === '\\') {
            return false;
        }
        if (s === '}') {
            return false;
        }
        if (s === '{') {
            return false;
        }
        if (s === '~') {
            return false;
        }
        return true;
    }

    RandNumber(number: number): number {
        return Math.floor(Math.random() * number);
    }

    getCTime(): string {
        let time = new Date();
        let ctime = '<';
        let hour = time.getHours() < 10 ? '0' + this.IntToString(time.getHours()) : this.IntToString(time.getHours());
        let minute = time.getMinutes() < 10 ? '0' + this.IntToString(time.getMinutes()) : this.IntToString(time.getMinutes());
        let seconds = time.getSeconds() < 10 ? '0' + this.IntToString(time.getSeconds()) : this.IntToString(time.getSeconds());
        ctime = ctime + hour + ':' + minute + ':' + seconds + '>';
        return ctime;
    }

    getCDateTime(): string {
        let time = new Date();
        let ctime = '<';
        let day = time.getDate() < 10 ? '0' + this.IntToString(time.getDate()) : this.IntToString(time.getDate());
        let month = time.getMonth() + 1 < 10 ? '0' + this.IntToString(time.getMonth() + 1) : this.IntToString(time.getMonth() + 1);
        let year = this.IntToString(time.getFullYear());
        let hour = time.getHours() < 10 ? '0' + this.IntToString(time.getHours()) : this.IntToString(time.getHours());
        let minute = time.getMinutes() < 10 ? '0' + this.IntToString(time.getMinutes()) : this.IntToString(time.getMinutes());
        ctime = ctime + day + '.' + month + '.' + year + ' ' + hour + ':' + minute + '>';
        return ctime;
    }

    InitTimer(timer: number) {
        if (this.getIsTest()) {
            timer = 0;
        }
        this.online_timer = timer;
        this.online_timer_time = timer;
    }

    GetLanguageText(text: string): string {
        return t.t(text);
    }

    GetUserVersion(name: string): number {
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === name) {
                let ver = this.user_details[i][8];
                return this._ttoi(ver);
            }
        }
        return 0;
    }

    async GetLanguage() {
        if (this.language === undefined) {
            this.language = await this.GetSavedLanguage();
        }
        return this.language;
    }

    FloatToString(num: number) {
        if (num === undefined || num === null) {
            return '0';
        }
        return num.toString();
    }

    detectBrowser() {
        if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf('Safari') !== -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
            return 'Firefox';
        } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.DOCUMENT_NODE === true) {
            return 'IE'; //crap
        } else {
            return 'Unknown';
        }
    }

    GetAppStoreCode(): string {
        let appStoreCode: string = `${config.APP_STORE_CODE}`;
        if (appStoreCode && appStoreCode !== undefined && appStoreCode !== 'undefined') {
            return appStoreCode;
        } else {
            if (Platform.OS === 'android') {
                return 'gp';
            }
        }
        // sa for Samsung : https://seller.samsungapps.com/
        // hu for Huawei
        // am for Amazon
        // Empty for Google Play and App Store
        return '';
    }

    GetGamePrefix(): string {
        let gameConfig: string = `${config.GAME}`;
        if (gameConfig === 'All') {
            return 'all';
        }
        if (gameConfig === 'Belote') {
            return 'be';
        }
        if (gameConfig === 'Bridge') {
            return 'br';
        }
        if (gameConfig === 'Tabla') {
            return 'ba';
        }
        if (gameConfig === 'NSSC') {
            return 'lu';
        }
        if (gameConfig === 'Blato') {
            return 'bl';
        }
        if (gameConfig === 'Santase') {
            return 'sc';
        }
        return '';
    }

    GetOSVersion(): string {
        if (Platform.OS === 'android') {
            return 'an';
        }
        if (Platform.OS === 'ios') {
            return 'ip';
        }
        if (Platform.OS === 'web') {
            if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Edg/') !== -1) {
                return 'h5i'; //crap
            } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
                return 'h5f';
            } else if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
                return 'h5o';
            } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
                return 'h5c';
            } else if (navigator.userAgent.indexOf('Safari') !== -1) {
                return 'h5s';
            } else {
                return 'h5c';
            }
        }
        return 'web';
    }

    AddUserDetails(
        username: string,
        name: string,
        country: string,
        city: string,
        language: string,
        sex: string,
        age: string,
        description: string,
        ver: string,
        image: string,
        rating: string,
        os: string,
        vip: string,
        time: string,
        fcash: string,
        dbcash: string
    ) {
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username || this.user_details[i][0] === '' || this.user_details[i][0] === undefined) {
                this.user_details[i][0] = username;
                this.user_details[i][1] = name;
                this.user_details[i][2] = country;
                this.user_details[i][3] = city;
                this.user_details[i][4] = language;
                this.user_details[i][5] = sex;
                this.user_details[i][6] = age;
                this.user_details[i][7] = description;
                this.user_details[i][8] = ver;
                this.user_details[i][9] = image;
                this.user_details[i][10] = rating;
                this.user_details[i][11] = os;
                this.user_details[i][12] = vip;
                this.user_details[i][13] = time;
                this.user_details[i][14] = fcash;
                this.user_details[i][15] = dbcash;
                break;
            }
        }
    }

    async GetGameNumber(): Promise<string> {
        let gameNumber: string = await this.GetProfileString('Options', 'gameNumber', null);
        if (gameNumber === null) {
            let gameNumberNum = this.RandNumber(999999999999999);
            gameNumber = this.IntToString(gameNumberNum);
            await this.WriteProfileString('Options', 'gameNumber', gameNumber);
        }
        return this.GetOSVersion() + this.GetAppStoreCode() + this.GetGamePrefix() + gameNumber;
    }

    getIsTest() {
        return false;
    }

    getVisibleGames(): { label: string; value: string }[] {
        let games: { label: string; value: string }[] = [];
        if (this.isGameVisible('Belot4', true)) {
            games.push({ label: this.GetLanguageText('Belote 4 Players'), value: 'Belote 4 Players' });
        }
        if (this.isGameVisible('Belot3', true)) {
            games.push({ label: this.GetLanguageText('Belote 3 Players'), value: 'Belote 3 Players' });
        }
        if (this.isGameVisible('Belot2', true)) {
            games.push({ label: this.GetLanguageText('Belote 2 Players'), value: 'Belote 2 Players' });
        }
        if (this.isGameVisible('Santase', true)) {
            games.push({ label: this.GetLanguageText('Schnapsen (66)'), value: 'Schnapsen (66)' });
        }
        if (this.isGameVisible('Blato', true)) {
            games.push({ label: this.GetLanguageText('Sergeant Major (3-5-8)'), value: 'Sergeant Major (3-5-8)' });
        }
        if (this.isGameVisible('Bridge', true)) {
            games.push({ label: this.GetLanguageText('Bridge Rubber'), value: 'Bridge Rubber' });
            if (this.isGameVisible('Duplicate Bridge', true)) {
                games.push({ label: this.GetLanguageText('Duplicate Bridge'), value: 'Duplicate Bridge' });
            }
        }
        if (this.isGameVisible('Poker', true)) {
            games.push({ label: this.GetLanguageText('Poker Texas Hold em'), value: 'Poker Texas Hold em' });
        }
        if (this.isGameVisible('PokerOmaha', true)) {
            games.push({ label: this.GetLanguageText('Poker Omaha Hi'), value: 'Poker Omaha Hi' });
        }
        if (this.isGameVisible('Monopoly', true)) {
            games.push({ label: this.GetLanguageText('Monopoly'), value: 'Monopoly' });
        }
        if (this.isGameVisible('Tabla', true)) {
            games.push({ label: this.GetLanguageText('Backgammon'), value: 'Backgammon' });
            games.push({ label: this.GetLanguageText('Backgammon - Tapa'), value: 'Backgammon - Tapa' });
            games.push({ label: this.GetLanguageText('Backgammon - Gulbara'), value: 'Backgammon - Gulbara' });
            games.push({ label: this.GetLanguageText('Backgammon 3 in 1'), value: 'Backgammon 3 in 1' });
        }
        if (this.isGameVisible('Domino2', true)) {
            games.push({ label: this.GetLanguageText('Domino 2 Players'), value: 'Domino 2 Players' });
        }
        if (this.isGameVisible('OKEY', true)) {
            games.push({ label: this.GetLanguageText('OKEY'), value: 'OKEY' });
        }
        if (this.isGameVisible('NSSC', true)) {
            games.push({ label: this.GetLanguageText('Ludo'), value: 'Ludo' });
        }
        return games;
    }

    isGameVisible(
        game:
            | 'Belot4'
            | 'Belot3'
            | 'Belot2'
            | 'Belote'
            | 'Santase'
            | 'Bridge'
            | 'Svara'
            | 'Poker'
            | 'PokerOmaha'
            | 'DrawPoker'
            | 'BJ'
            | 'Blato'
            | 'Tabla'
            | 'OKEY'
            | 'Monopoly'
            | 'NSSC'
            | 'Domino2'
            | 'Domino3'
            | 'Domino4'
            | 'Duplicate Bridge',
        op: boolean
    ): boolean {
        console.log(op);
        let gameConfig: string = `${config.GAME}`;
        if (gameConfig === 'All') {
            if (
                game === 'Belote' ||
                game === 'Belot4' ||
                game === 'Bridge' ||
                game === 'NSSC' ||
                game === 'Tabla' ||
                game === 'Blato' ||
                game === 'Santase'
            ) {
                return true;
            }
            if (this.GetShowAllGames()) {
                return true;
            }
        }
        if (gameConfig === 'Belote') {
            if (game === 'Belote' || game === 'Belot4') {
                return true;
            }
        } else if (gameConfig === 'Bridge') {
            if (game === 'Bridge') {
                return true;
            }
        } else if (gameConfig === game) {
            return true;
        }
        return false;
    }

    isGameEnable(
        game:
            | 'Belot4'
            | 'Belot3'
            | 'Belot2'
            | 'Belote'
            | 'Santase'
            | 'Bridge'
            | 'Svara'
            | 'Poker'
            | 'PokerOmaha'
            | 'DrawPoker'
            | 'BJ'
            | 'Blato'
            | 'Tabla'
            | 'OKEY'
            | 'Monopoly'
            | 'NSSC'
            | 'Domino2'
            | 'Domino3'
            | 'Domino4'
            | 'Duplicate Bridge'
    ): boolean {
        if (
            game === 'Belote' ||
            game === 'Belot4' ||
            game === 'Bridge' ||
            game === 'NSSC' ||
            game === 'Tabla' ||
            game === 'Blato' ||
            game === 'Santase'
        ) {
            return true;
        }
        if (this.GetShowAllGames()) {
            return true;
        }
        return false;
    }

    GetNextDesign(current: number, element: string) {
        if (element === 'dice') {
            if (current < 4) {
                return current + 1;
            }
        }
        if (element === 'pools') {
            if (current < 5) {
                return current + 1;
            }
        }
        if (element === 'board') {
            if (current < 3) {
                return current + 1;
            }
        }
        if (element === 'tilesnew') {
            if (current < 1) {
                return current + 1;
            }
        }
        return 1;
    }

    SetPlayerParameters(
        username: string,
        name: string,
        country: string,
        city: string,
        language: string,
        sex: string,
        age: string,
        description: string,
        ver: string,
        image: string,
        rating: string,
        vip: string,
        os: string
    ) {
        if (image !== '-') {
            this.AddToCustomImage(username, image);
        }
        let clang = this.language;
        if (clang !== 'Bulgarian' && clang !== 'Russian' && clang !== 'Macedonian') {
            name = this.GetLatString(name);
            city = this.GetLatString(city);
            description = this.GetLatString(description);
        }
        for (let i = 1; i <= this.Players; i++) {
            if (this.Name[i] === username) {
                this.uname[i] = name;
                this.ucountry[i] = country;
                this.ucity[i] = city;
                this.ulanguage[i] = language;
                this.usex[i] = this._ttoi(sex);
                if (this.usex[i] > 0) {
                    this.pls[i] = this.usex[i] - 1;
                }
                this.uage[i] = age;
                this.udescription[i] = description;
                this.uversion[i] = this._ttoi(ver);
                this.urating[i] = rating;
                this.uvip[i] = vip;
                this.uos[i] = os;
            }
        }
    }

    ExistInCustomImage(username: string) {
        for (let i = 0; i < 1000; i++) {
            if (this.custom_image[i][0] !== '') {
                let u1: string = this.custom_image[i][0];
                let u2 = username;
                if (u1 === undefined || u1 === '') {
                    return false;
                }
                u1 = u1.toLocaleLowerCase();
                u2 = u2.toLocaleLowerCase();
                if (u1 === u2) {
                    return true;
                }
            }
        }
        return false;
    }

    AddToCustomImage(
        username: string,
        image: string //Refactor - Use User Details
    ) {
        if (!this.ExistInCustomImage(username)) {
            for (let i = 0; i < 1000; i++) {
                if (this.custom_image[i][0] === '' || this.custom_image[i][0] === undefined) {
                    this.custom_image[i][0] = username;
                    this.custom_image[i][1] = image;
                    return;
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    OnGetFile(file_name: string, qfile: string, ch: boolean) {}

    GetLatString(str: string): string {
        return str;
    }

    GetCountPitankiInText(text: string): number {
        if (text) {
            return 0;
        }
        return 0;
    }

    CodePageToUnicode(codePage: number, src: string): string {
        if (codePage) {
            return src;
        }
        return src;
    }

    GetCodePage(userLanguage: string) {
        let codePage: number = 1252;
        if (userLanguage === 'Bulgarian' || userLanguage === 'Macedonian' || userLanguage === 'Russian') {
            codePage = 1251;
        }
        if (userLanguage === 'Greek') {
            codePage = 1253;
        }
        if (userLanguage === 'Turkish') {
            codePage = 1254;
        }
        if (userLanguage === 'Arabic') {
            codePage = 1256;
        }
        return codePage;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    FixLanguageMessagesFromServer(text: string, userLanguage: string = this.language) {
        /*    let initialText = text;
        let initialPitankiCount = this.GetCountPitankiInText(text);
        text = this.CodePageToUnicode(this.GetCodePage(userLanguage), text);
        let pitankiCountAfterConvert = this.GetCountPitankiInText(text);
        if (initialPitankiCount !== pitankiCountAfterConvert) {
            text = this.GetLatString(initialText);
        }*/
        return text;
    }

    GetUserDetails(username: string): string[] {
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                return this.user_details[i];
            }
        }
        return [];
    }

    GetUserLanguage(username: string) {
        for (let i = 0; i < 1000; i++) {
            if (this.user_details[i][0] === username) {
                return this.user_details[i][4];
            }
        }
        return this.language;
    }
}

export default CMain;

const cstyles = StyleSheet.create({
    ratingContainer: {
        borderColor: '#000000',
        backgroundColor: 'red',
        borderWidth: 1,
        position: 'absolute',
    },
    ratingGreen: {
        borderColor: '#000000',
        backgroundColor: 'green',
        paddingTop: 1,
        paddingLeft: 1,
    },
    avatarStyleNoImg: {
        width: 100,
        height: 120,
        top: 5,
        left: 0,
        position: 'absolute',
    },
    avatarStyle: {
        width: 100,
        height: 120,
        left: 0,
        position: 'absolute',
    },
    frameStyle: {
        width: 110,
        height: 130,
        marginLeft: -5,
        marginTop: -5,
    },
    userDetail: {
        height: 16,
        justifyContent: 'center',
    },
    drawUserContainer: {
        position: 'absolute',
        flexDirection: 'row',
    },
    drawUserDetailsCenter: { flex: 1, height: 130, width: '100%', left: 105 },
});
