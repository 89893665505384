import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import { Platform, View } from 'react-native';
import DlgORezultat from '../../Dialogs/DlgORezultat';
import { GamesProps } from '../props/GamesProps';
import GlobalVariables from '../GlobalVariables';
import DrawBid from '../Draw/DrawBid';
import DrawNames from '../Draw/DrawNames';
import CRect from '../cppComponents/CRect';
import GlobalConstants from '../GlobalConstants';
import DlgRezultatBlato from '~/Dialogs/DlgRezultatBlato';
import CSize from '../cppComponents/CSize';
import CPicture from '../cppComponents/CPicture';
import CBlatoGetCards from '~/Dialogs/CBlatoGetCards/CBlatoGetCards';
import DlgObiaviavaneBla from '~/Dialogs/DlgObiaviavaneBla';
import DrawCard from '../Draw/DrawCard';

class CBlato extends CMain {
    m_orez: DlgORezultat = new DlgORezultat(null);
    INT_MIN: number = -2147483647;
    talon_ready: boolean;
    get_cards: boolean;
    obiaviavane: boolean;
    OtkritTalon: number;
    brgame: number;
    invalidate: number;
    LastResult: number[] = [];
    GCPL: boolean[] = [];
    kt: number[] = [];
    SureCardsBr: number[] = [];
    limithand: number[] = [];
    smiana_talon: number[][] = []; //[4][2];
    SelectT: number;
    anons: number;
    SureCards: number[][][] = []; //[5][14][2];
    ChCards: number[][][] = []; //[4][5][4];//Player1,Color,Player2=broi
    type: number = 15; //vida na igrata 15: 15 round, 9: 9 round, 21: to 21 points
    loading: boolean = true;
    NiamaBoia: boolean[][] = []; //[4][5];
    Obiavi: boolean[][] = []; //[4][6];
    handspoint: CPoint[] = []; //[3]
    ButtonStartPoint: CPoint = new CPoint(0, 0);
    TalonRect: CRect[] = [];
    CardRect: CRect[] = [];
    m_rez: DlgRezultatBlato = new DlgRezultatBlato(null);
    is: boolean = false;
    get_cardsdlg: CBlatoGetCards = new CBlatoGetCards(null);
    m_obbla: DlgObiaviavaneBla = new DlgObiaviavaneBla(null);
    obiaviavaneRN: any = null;
    m_rez1: number = 0;
    m_rez2: number = 0;
    m_rez3: number = 0;

    InitArrays() {
        for (let i1 = 0; i1 < 3; i1++) {
            this.handspoint[i1] = new CPoint(0, 0);
        }
        for (let i1 = 0; i1 < 4; i1++) {
            this.smiana_talon[i1] = [];
            this.ChCards[i1] = [];
            this.NiamaBoia[i1] = [];
            this.Obiavi[i1] = [];
            for (let i2 = 0; i2 < 5; i2++) {
                this.ChCards[i1][i2] = [];
            }
        }
        for (let i1 = 0; i1 < 5; i1++) {
            this.SureCards[i1] = [];
            for (let i2 = 0; i2 < 14; i2++) {
                this.SureCards[i1][i2] = [];
            }
        }
    }

    constructor(props: GamesProps) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        this.InitArrays();
        this.ResetIntVariables();
        this.talon_ready = false;
        this.get_cards = false;
        this.obiaviavane = false;
        for (let i = 0; i < 5; i++) {
            this.wait_opplayers[i] = false;
        }
        this.P2OP = false;
        this.P3OP = false;
        this.P4OP = false;
        this.OtkritTalon = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.Naddavane = 0;
        this.hand = 0;
        this.KoiENaRed = 0;
        this.KoiPuskaParvi = 0;
        this.KoiZapochva = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.Mcard = 0;
        this.brgame = 0;
        this.invalidate = -1;
        this.LastResult[1] = 0;
        this.LastResult[2] = 0;
        this.LastResult[3] = 0;
        this.CardsPos[0].x = -100;
        this.CardsPos[0].y = -100;
        for (let i = 1; i < 53; i++) {
            this.k[i] = 0;
            this.CardsPoint[i].x = -100;
            this.CardsPoint[i].y = -100;
        }
        this.Move = false;
        this.Server = false;
        this.op_start = false;
        for (let i = 0; i < 4; i++) {
            this.wait_opplayers[i] = false;
            this.GCPL[i] = false;
        }
        this.pause = 0;
        this.Razdavane1 = false;
        this.pause_rect = new CRect(694, 484, 718, 504);
    }

    ResetIntVariables() {
        for (let i = 0; i < 5; i++) {
            this.kt[i] = this.INT_MIN;
            this.SureCardsBr[i] = this.INT_MIN;
        }
        this.OtkritTalon = this.INT_MIN;
        for (let i = 0; i < 4; i++) {
            this.limithand[i] = this.INT_MIN;
            this.smiana_talon[i][0] = this.INT_MIN;
            this.smiana_talon[i][1] = this.INT_MIN;
            this.LastResult[i] = this.INT_MIN;
        }
        this.SelectT = this.INT_MIN;
        this.type = this.INT_MIN;
        this.invalidate = this.INT_MIN;
        this.brgame = this.INT_MIN;
        this.anons = this.INT_MIN;
        for (let a = 0; a < 5; a++) {
            for (let b = 0; b < 5; b++) {
                this.SureCards[a][b][0] = this.INT_MIN;
                this.SureCards[a][b][1] = this.INT_MIN;
            }
        }
        for (let a = 0; a < 4; a++) {
            for (let b = 0; b < 5; b++) {
                this.ChCards[a][b][0] = this.INT_MIN;
                this.ChCards[a][b][1] = this.INT_MIN;
                this.ChCards[a][b][2] = this.INT_MIN;
                this.ChCards[a][b][3] = this.INT_MIN;
            }
        }
    }

    Intelect(player: number) {
        let card: number;
        this.online_timer = this.online_timer_time;
        if (this.Naddavane < 5) {
            card = this.IntelectColour(player, this.Naddavane);
        }
        if (this.Naddavane === 5) {
            card = this.IntelectNT(player);
        }

        if (this.OP === true) {
            if (this.Server === true) {
                this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
            }
        }

        let kdcard = this.k[card];
        if (this.Naddavane < 5) {
            kdcard = this.k[card] - this.Naddavane * 100 - 800;
        }
        for (let i = 1; i < 53; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                break;
            }
        }
        if (player !== this.KoiPuskaParvi) {
            if (this.ParviPuska > 100 && this.ParviPuska < 200 && this.k[card] > 200) {
                this.Pniama[player][1] = true;
            }
            if (this.ParviPuska > 200 && this.ParviPuska < 300 && (this.k[card] < 200 || this.k[card] > 300)) {
                this.Pniama[player][2] = true;
            }
            if (this.ParviPuska > 300 && this.ParviPuska < 400 && (this.k[card] < 300 || this.k[card] > 400)) {
                this.Pniama[player][3] = true;
            }
            if (this.ParviPuska > 400 && this.ParviPuska < 500 && (this.k[card] < 400 || this.k[card] > 500)) {
                this.Pniama[player][4] = true;
            }
            if (this.ParviPuska > 1000 && this.k[card] < 1000) {
                this.Pniama[player][this.Naddavane] = true;
            }
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        if (player === 3) {
            this.P3puska = this.k[card];
        }
        this.CardsPoint[player + 52].x = this.CardsPoint[card].x;
        this.CardsPoint[player + 52].y = this.CardsPoint[card].y;
        this.CardP[52 + this.KoiENaRed].image = this.CardP[card].image;
        this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 52], 52 + this.KoiENaRed);
        if (player === 1) {
            this.P1puska = this.k[card];
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        if (player === 3) {
            this.P3puska = this.k[card];
        }
        if (this.KoiPuskaParvi === 1) {
            if (player === 1) {
                this.ParviPuska = this.k[card];
            }
            if (player === 2) {
                this.VtoriPuska = this.k[card];
            }
            if (player === 3) {
                this.TretiPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (player === 2) {
                this.ParviPuska = this.k[card];
            }
            if (player === 3) {
                this.VtoriPuska = this.k[card];
            }
            if (player === 1) {
                this.TretiPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 3) {
            if (player === 3) {
                this.ParviPuska = this.k[card];
            }
            if (player === 1) {
                this.VtoriPuska = this.k[card];
            }
            if (player === 2) {
                this.TretiPuska = this.k[card];
            }
        }
        this.DeleteCard(card);
        if (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0) {
            if (this.KoiENaRed <= 2) {
                this.KoiENaRed = this.KoiENaRed + 1;
            } else {
                this.KoiENaRed = 1;
            }
        }
    }

    CheckNGCard(player: number, boia: number) {
        let h: number;
        if (boia === this.Naddavane) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 1000) {
                    return this.k[h];
                }
            }
        }
        if (boia === 1) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 100 && this.k[h] < 200) {
                    return this.k[h];
                }
            }
        }
        if (boia === 2) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 200 && this.k[h] < 300) {
                    return this.k[h];
                }
            }
        }
        if (boia === 3) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 300 && this.k[h] < 400) {
                    return this.k[h];
                }
            }
        }
        if (boia === 4) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 400 && this.k[h] < 500) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    async InitSP(type: number) {
        console.log('type', type);
        this.loading = true;
        this.type = type;
        this.SP = true;
        this.OP = false;
        await this.Init();
        this.InitGame(false);
        this.loading = false;
    }

    async InitOP(server: boolean, type: number) {
        console.log('InitOP', type);
        this.loading = true;
        this.type = type;
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        if (this.Server) {
            this.InitGame(false);
        } else {
        }
        this.InitTimer(25);
        this.loading = false;
    }

    async Init() {
        this.props.m_pwin.ShowInfoBar(true);
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 5; j++) {
                for (let kk = 0; kk < 4; kk++) {
                    this.ChCards[i][j][kk] = 0;
                }
            }
        }
        for (let i = 1; i < 4; i++) {
            for (let a = 0; a < 6; a++) {
                this.Obiavi[i][a] = false;
            }
            this.GCPL[i] = false;
        }
        this.talon_ready = false;
        this.get_cards = false;
        this.obiaviavane = false;
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        if (this.P3OP === true) {
            this.P3G = false;
        } else {
            this.P3G = true;
        }
        this.m_rez.m_rez1 = 0;
        this.m_rez.m_rez2 = 0;
        this.m_rez.m_rez3 = 0;
        this.m_rez1 = 0;
        this.m_rez2 = 0;
        this.m_rez3 = 0;
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        this.m_orez.m_rez3 = 0;
        this.LastResult[1] = 0;
        this.LastResult[2] = 0;
        this.LastResult[3] = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.brgame = 0;
        this.Players = 3;
        this.handspoint[0].x = 340;
        this.handspoint[0].y = 358;
        this.handspoint[1].x = 590;
        this.handspoint[1].y = 230;
        this.handspoint[2].x = 340;
        this.handspoint[2].y = 112;

        this.ButtonStartPoint.x = 306;
        this.ButtonStartPoint.y = 386;
        GlobalVariables.OtkritiKarti = 0;
        this.SelectT = 0;
        this.OtkritTalon = 0;
        this.op_start = false;
        this.showrcard = 52;
        this.invalidate = 0;
        this.Faza = 0;
        this.Naddavane = 0;
        this.hand = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.strelka = 0;
        if (!this.OP) {
            this.Name[1] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[2]', 'Computer2');
            this.Name[3] = await this.props.m_pwin.m_main.GetProfileString('Options', 'this.Name[3]', 'Computer3');
        }
        for (let i = 1; i < 4; i++) {
            this.m_orez.Player[i] = this.Name[i];
        }
        await this.LoadBitmaps();
        this.KoiZapochva = this.KoiPuskaParvi = this.RandKoiZapochva(this.Players);
        this.StrelkaPos[1].x = 550;
        this.StrelkaPos[1].y = 350;
        this.StrelkaPos[2].x = 650;
        this.StrelkaPos[2].y = 280;
        this.StrelkaPos[3].x = 220;
        this.StrelkaPos[3].y = 130;

        this.NamePos[1].x = 366;
        this.NamePos[1].y = 388;
        this.NamePos[2].x = 610;
        this.NamePos[2].y = 255;
        this.NamePos[3].x = 362;
        this.NamePos[3].y = 138;

        this.PosNaddavane[1].x = 340;
        this.PosNaddavane[1].y = 350;
        this.PosNaddavane[2].x = 590;
        this.PosNaddavane[2].y = 230;
        this.PosNaddavane[3].x = 340;
        this.PosNaddavane[3].y = 110;

        this.CardsPos[0].x = 181;
        this.CardsPos[0].y = 179;

        this.CardsPos[1].x = 59;
        this.CardsPos[1].y = 418;
        this.CardsPos[2].x = 95;
        this.CardsPos[2].y = 418;
        this.CardsPos[3].x = 131;
        this.CardsPos[3].y = 418;
        this.CardsPos[4].x = 167;
        this.CardsPos[4].y = 418;
        this.CardsPos[5].x = 203;
        this.CardsPos[5].y = 418;
        this.CardsPos[6].x = 239;
        this.CardsPos[6].y = 418;
        this.CardsPos[7].x = 275;
        this.CardsPos[7].y = 418;
        this.CardsPos[8].x = 311;
        this.CardsPos[8].y = 418;
        this.CardsPos[9].x = 347;
        this.CardsPos[9].y = 418;
        this.CardsPos[10].x = 383;
        this.CardsPos[10].y = 418;
        this.CardsPos[11].x = 419;
        this.CardsPos[11].y = 418;
        this.CardsPos[12].x = 455;
        this.CardsPos[12].y = 418;
        this.CardsPos[13].x = 491;
        this.CardsPos[13].y = 418;
        this.CardsPos[14].x = 527;
        this.CardsPos[14].y = 418;
        this.CardsPos[15].x = 563;
        this.CardsPos[15].y = 418;
        this.CardsPos[16].x = 599;
        this.CardsPos[16].y = 418;

        this.CardsPos[17].x = 710;
        this.CardsPos[17].y = 370;
        this.CardsPos[18].x = 710;
        this.CardsPos[18].y = 350;
        this.CardsPos[19].x = 710;
        this.CardsPos[19].y = 330;
        this.CardsPos[20].x = 710;
        this.CardsPos[20].y = 310;
        this.CardsPos[21].x = 710;
        this.CardsPos[21].y = 290;
        this.CardsPos[22].x = 710;
        this.CardsPos[22].y = 270;
        this.CardsPos[23].x = 710;
        this.CardsPos[23].y = 250;
        this.CardsPos[24].x = 710;
        this.CardsPos[24].y = 230;
        this.CardsPos[25].x = 710;
        this.CardsPos[25].y = 210;
        this.CardsPos[26].x = 710;
        this.CardsPos[26].y = 190;
        this.CardsPos[27].x = 710;
        this.CardsPos[27].y = 170;
        this.CardsPos[28].x = 710;
        this.CardsPos[28].y = 150;
        this.CardsPos[29].x = 710;
        this.CardsPos[29].y = 130;
        this.CardsPos[30].x = 710;
        this.CardsPos[30].y = 110;
        this.CardsPos[31].x = 710;
        this.CardsPos[31].y = 90;
        this.CardsPos[32].x = 710;
        this.CardsPos[32].y = 70;

        this.CardsPos[33].x = 45;
        this.CardsPos[33].y = 22;
        this.CardsPos[34].x = 78;
        this.CardsPos[34].y = 22;
        this.CardsPos[35].x = 111;
        this.CardsPos[35].y = 22;
        this.CardsPos[36].x = 144;
        this.CardsPos[36].y = 22;
        this.CardsPos[37].x = 177;
        this.CardsPos[37].y = 22;
        this.CardsPos[38].x = 210;
        this.CardsPos[38].y = 22;
        this.CardsPos[39].x = 243;
        this.CardsPos[39].y = 22;
        this.CardsPos[40].x = 276;
        this.CardsPos[40].y = 22;
        this.CardsPos[41].x = 309;
        this.CardsPos[41].y = 22;
        this.CardsPos[42].x = 342;
        this.CardsPos[42].y = 22;
        this.CardsPos[43].x = 375;
        this.CardsPos[43].y = 22;
        this.CardsPos[44].x = 408;
        this.CardsPos[44].y = 22;
        this.CardsPos[45].x = 441;
        this.CardsPos[45].y = 22;
        this.CardsPos[46].x = 474;
        this.CardsPos[46].y = 22;
        this.CardsPos[47].x = 507;
        this.CardsPos[47].y = 22;
        this.CardsPos[48].x = 540;
        this.CardsPos[48].y = 22;

        this.CardsPos[49].x = 20;
        this.CardsPos[49].y = 140;
        this.CardsPos[50].x = 45;
        this.CardsPos[50].y = 140;
        this.CardsPos[51].x = 70;
        this.CardsPos[51].y = 140;
        this.CardsPos[52].x = 95;
        this.CardsPos[52].y = 140;

        this.CardsPos[53].x = 335;
        this.CardsPos[53].y = 270;
        this.CardsPos[54].x = 410;
        this.CardsPos[54].y = 219;
        this.CardsPos[55].x = 335;
        this.CardsPos[55].y = 170;

        this.TalonRect[1] = new CRect(
            this.CardsPos[49].x,
            this.CardsPos[49].y - 3,
            this.CardsPos[50].x,
            this.CardsPos[49].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.TalonRect[2] = new CRect(
            this.CardsPos[50].x,
            this.CardsPos[50].y - 3,
            this.CardsPos[51].x,
            this.CardsPos[50].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.TalonRect[3] = new CRect(
            this.CardsPos[51].x,
            this.CardsPos[51].y - 3,
            this.CardsPos[52].x,
            this.CardsPos[51].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.TalonRect[4] = new CRect(
            this.CardsPos[52].x,
            this.CardsPos[52].y - 3,
            this.CardsPos[52].x + GlobalConstants.PLAYER_CARD_WIDTH,
            this.CardsPos[52].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );

        this.CardRect[1] = new CRect(
            this.CardsPos[1].x,
            this.CardsPos[1].y,
            this.CardsPos[2].x,
            this.CardsPos[1].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[2] = new CRect(
            this.CardsPos[2].x,
            this.CardsPos[2].y,
            this.CardsPos[3].x,
            this.CardsPos[2].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[3] = new CRect(
            this.CardsPos[3].x,
            this.CardsPos[3].y,
            this.CardsPos[4].x,
            this.CardsPos[3].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[4] = new CRect(
            this.CardsPos[4].x,
            this.CardsPos[4].y,
            this.CardsPos[5].x,
            this.CardsPos[4].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[5] = new CRect(
            this.CardsPos[5].x,
            this.CardsPos[5].y,
            this.CardsPos[6].x,
            this.CardsPos[5].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[6] = new CRect(
            this.CardsPos[6].x,
            this.CardsPos[6].y,
            this.CardsPos[7].x,
            this.CardsPos[6].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[7] = new CRect(
            this.CardsPos[7].x,
            this.CardsPos[7].y,
            this.CardsPos[8].x,
            this.CardsPos[7].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[8] = new CRect(
            this.CardsPos[8].x,
            this.CardsPos[8].y,
            this.CardsPos[9].x,
            this.CardsPos[8].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[9] = new CRect(
            this.CardsPos[9].x,
            this.CardsPos[9].y,
            this.CardsPos[10].x,
            this.CardsPos[9].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[10] = new CRect(
            this.CardsPos[10].x,
            this.CardsPos[10].y,
            this.CardsPos[11].x,
            this.CardsPos[10].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[11] = new CRect(
            this.CardsPos[11].x,
            this.CardsPos[11].y,
            this.CardsPos[12].x,
            this.CardsPos[11].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[12] = new CRect(
            this.CardsPos[12].x,
            this.CardsPos[12].y,
            this.CardsPos[13].x,
            this.CardsPos[12].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[13] = new CRect(
            this.CardsPos[13].x,
            this.CardsPos[13].y,
            this.CardsPos[14].x,
            this.CardsPos[13].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[14] = new CRect(
            this.CardsPos[14].x,
            this.CardsPos[14].y,
            this.CardsPos[15].x,
            this.CardsPos[14].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[15] = new CRect(
            this.CardsPos[15].x,
            this.CardsPos[15].y,
            this.CardsPos[16].x,
            this.CardsPos[15].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        this.CardRect[16] = new CRect(
            this.CardsPos[16].x,
            this.CardsPos[16].y,
            this.CardsPos[16].x + GlobalConstants.PLAYER_CARD_WIDTH,
            this.CardsPos[16].y + GlobalConstants.PLAYER_CARD_HEIGHT
        );
        for (let a = 0; a < 100; a++) {
            this.m_orez.orez[0][a] = -10;
            this.m_orez.orez[1][a] = -10;
            this.m_orez.orez[2][a] = -10;
        }
    }
    InitGame(pass: boolean) {
        this.pause_player = '';
        this.talon_ready = false;
        this.get_cards = false;
        this.obiaviavane = false;
        this.op_start = false;
        this.showrcard = 52;
        this.invalidate = 0;
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 5; j++) {
                for (let kk = 0; kk < 4; kk++) {
                    this.ChCards[i][j][kk] = 0;
                }
            }
            this.GCPL[i] = false;
        }
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 5; j++) {
                this.NiamaBoia[i][j] = false;
            }
        }
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 14; j++) {
                this.SureCards[i][j][0] = 0;
                this.SureCards[i][j][1] = 0;
            }
            this.SureCardsBr[i] = 0;
        }
        if (pass !== true) {
            if ((this.OP && this.Server) || this.SP) {
                if (this.KoiZapochva < 3) {
                    this.KoiZapochva = this.KoiZapochva + 1;
                } else {
                    this.KoiZapochva = 1;
                }
            }
            if (!this.watch) {
                this.brgame = this.brgame + 1;
            }
            if (this.OP) {
                if (!this.watch) {
                    this.props.m_pwin.SendGame('$|y');
                }
            }
            this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        }
        if (this.KoiZapochva === 1) {
            this.limithand[1] = 8;
            this.limithand[2] = 3;
            this.limithand[3] = 5;
        }
        if (this.KoiZapochva === 2) {
            this.limithand[1] = 5;
            this.limithand[2] = 8;
            this.limithand[3] = 3;
        }
        if (this.KoiZapochva === 3) {
            this.limithand[1] = 3;
            this.limithand[2] = 5;
            this.limithand[3] = 8;
        }
        if (pass !== true) {
            this.LastResult[1] = this.m_rez.m_rez1;
            this.LastResult[2] = this.m_rez.m_rez2;
            this.LastResult[3] = this.m_rez.m_rez3;
        }
        this.m_rez1 = -this.limithand[1];
        this.m_rez2 = -this.limithand[2];
        this.m_rez3 = -this.limithand[3];
        this.m_rez.m_rez1 = this.m_rez1;
        this.m_rez.m_rez2 = this.m_rez2;
        this.m_rez.m_rez3 = this.m_rez3;
        if ((this.OP && this.Server) || this.SP) {
            this.KoiPuskaParvi = this.KoiENaRed = this.KoiZapochva;
        }
        this.Faza = 0;
        this.Naddavane = 0;
        this.hand = 0;
        if (this.SP || (this.OP && this.Server)) {
            this.Razdavane1 = true;
        }
        for (let i = 0; i < 53; i++) {
            this.k[i] = 0;
            this.CardsPoint[i].x = this.CardsPos[0].x;
            this.CardsPoint[i].y = this.CardsPos[0].y;
        }
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.strelka = 0;
        if ((this.OP && this.Server) || this.SP) {
            this.RandCards();
        }
        if ((this.OP && this.Server) || this.SP) {
            this.LoadCards(false, true);
        }
        for (let i = 0; i < 4; i++) {
            for (let a = 0; a < 2; a++) {
                this.smiana_talon[i][a] = 0;
            }
        }
        for (let i = 1; i <= 3; i++) {
            if (this.last_Name[i] === '') {
                this.last_Name[i] = this.Name[i];
            }
        }
        this.obiaviavaneRN = null;
        this.setState({});
    }

    LoadCards(begin: boolean, sound: boolean) {
        for (let i = 1; i < 53; i++) {
            if (!begin) {
                if (this.k[i] === 102) {
                    this.CardP[i].image = this.CardPicture[1];
                }
                if (this.k[i] === 103) {
                    this.CardP[i].image = this.CardPicture[2];
                }
                if (this.k[i] === 104) {
                    this.CardP[i].image = this.CardPicture[3];
                }
                if (this.k[i] === 105) {
                    this.CardP[i].image = this.CardPicture[4];
                }
                if (this.k[i] === 106) {
                    this.CardP[i].image = this.CardPicture[5];
                }
                if (this.k[i] === 107) {
                    this.CardP[i].image = this.CardPicture[6];
                }
                if (this.k[i] === 108) {
                    this.CardP[i].image = this.CardPicture[7];
                }
                if (this.k[i] === 109) {
                    this.CardP[i].image = this.CardPicture[8];
                }
                if (this.k[i] === 110) {
                    this.CardP[i].image = this.CardPicture[9];
                }
                if (this.k[i] === 111) {
                    this.CardP[i].image = this.CardPicture[10];
                }
                if (this.k[i] === 112) {
                    this.CardP[i].image = this.CardPicture[11];
                }
                if (this.k[i] === 113) {
                    this.CardP[i].image = this.CardPicture[12];
                }
                if (this.k[i] === 114) {
                    this.CardP[i].image = this.CardPicture[13];
                }
                if (this.k[i] === 202) {
                    this.CardP[i].image = this.CardPicture[14];
                }
                if (this.k[i] === 203) {
                    this.CardP[i].image = this.CardPicture[15];
                }
                if (this.k[i] === 204) {
                    this.CardP[i].image = this.CardPicture[16];
                }
                if (this.k[i] === 205) {
                    this.CardP[i].image = this.CardPicture[17];
                }
                if (this.k[i] === 206) {
                    this.CardP[i].image = this.CardPicture[18];
                }
                if (this.k[i] === 207) {
                    this.CardP[i].image = this.CardPicture[19];
                }
                if (this.k[i] === 208) {
                    this.CardP[i].image = this.CardPicture[20];
                }
                if (this.k[i] === 209) {
                    this.CardP[i].image = this.CardPicture[21];
                }
                if (this.k[i] === 210) {
                    this.CardP[i].image = this.CardPicture[22];
                }
                if (this.k[i] === 211) {
                    this.CardP[i].image = this.CardPicture[23];
                }
                if (this.k[i] === 212) {
                    this.CardP[i].image = this.CardPicture[24];
                }
                if (this.k[i] === 213) {
                    this.CardP[i].image = this.CardPicture[25];
                }
                if (this.k[i] === 214) {
                    this.CardP[i].image = this.CardPicture[26];
                }
                if (this.k[i] === 302) {
                    this.CardP[i].image = this.CardPicture[27];
                }
                if (this.k[i] === 303) {
                    this.CardP[i].image = this.CardPicture[28];
                }
                if (this.k[i] === 304) {
                    this.CardP[i].image = this.CardPicture[29];
                }
                if (this.k[i] === 305) {
                    this.CardP[i].image = this.CardPicture[30];
                }
                if (this.k[i] === 306) {
                    this.CardP[i].image = this.CardPicture[31];
                }
                if (this.k[i] === 307) {
                    this.CardP[i].image = this.CardPicture[32];
                }
                if (this.k[i] === 308) {
                    this.CardP[i].image = this.CardPicture[33];
                }
                if (this.k[i] === 309) {
                    this.CardP[i].image = this.CardPicture[34];
                }
                if (this.k[i] === 310) {
                    this.CardP[i].image = this.CardPicture[35];
                }
                if (this.k[i] === 311) {
                    this.CardP[i].image = this.CardPicture[36];
                }
                if (this.k[i] === 312) {
                    this.CardP[i].image = this.CardPicture[37];
                }
                if (this.k[i] === 313) {
                    this.CardP[i].image = this.CardPicture[38];
                }
                if (this.k[i] === 314) {
                    this.CardP[i].image = this.CardPicture[39];
                }
                if (this.k[i] === 402) {
                    this.CardP[i].image = this.CardPicture[40];
                }
                if (this.k[i] === 403) {
                    this.CardP[i].image = this.CardPicture[41];
                }
                if (this.k[i] === 404) {
                    this.CardP[i].image = this.CardPicture[42];
                }
                if (this.k[i] === 405) {
                    this.CardP[i].image = this.CardPicture[43];
                }
                if (this.k[i] === 406) {
                    this.CardP[i].image = this.CardPicture[44];
                }
                if (this.k[i] === 407) {
                    this.CardP[i].image = this.CardPicture[45];
                }
                if (this.k[i] === 408) {
                    this.CardP[i].image = this.CardPicture[46];
                }
                if (this.k[i] === 409) {
                    this.CardP[i].image = this.CardPicture[47];
                }
                if (this.k[i] === 410) {
                    this.CardP[i].image = this.CardPicture[48];
                }
                if (this.k[i] === 411) {
                    this.CardP[i].image = this.CardPicture[49];
                }
                if (this.k[i] === 412) {
                    this.CardP[i].image = this.CardPicture[50];
                }
                if (this.k[i] === 413) {
                    this.CardP[i].image = this.CardPicture[51];
                }
                if (this.k[i] === 414) {
                    this.CardP[i].image = this.CardPicture[52];
                }
            } else {
                this.ConvertCard(this.k[i], i);
            }
        }
        //if(GlobalVariables.Zvuk==1 && begin === false && sound === true)m_sndSound.PlayFile("rand_cards", true);
        if (GlobalVariables.Zvuk === 1 && begin === false && sound === true) {
            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        }
    }

    ConvertCard(num: number, index: number) {
        if (this.Naddavane === 1) {
            if (num === 1002) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 1003) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 1004) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 1005) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 1006) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 1007) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 1008) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 1009) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 1010) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 1011) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 1012) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 1013) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 1014) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 2) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 1002) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 1003) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 1004) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 1005) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 1006) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 1007) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 1008) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 1009) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 1010) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 1011) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 1012) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 1013) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 1014) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 3) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 1002) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 1003) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 1004) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 1005) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 1006) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 1007) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 1008) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 1009) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 1010) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 1011) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 1012) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 1013) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 1014) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 4) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 1002) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 1003) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 1004) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 1005) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 1006) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 1007) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 1008) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 1009) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 1010) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 1011) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 1012) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 1013) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 1014) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 5) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
    }

    RandCards() {
        let Numbers: number[] = [];
        for (let i = 0; i < 52; i++) {
            Numbers[i] = i + 1;
        }
        let randomArray = this.randomShuffle(Numbers);
        for (let i = 1; i < 53; i++) {
            if (randomArray[i - 1] === 1) {
                this.k[i] = 102;
            }
            if (randomArray[i - 1] === 2) {
                this.k[i] = 103;
            }
            if (randomArray[i - 1] === 3) {
                this.k[i] = 104;
            }
            if (randomArray[i - 1] === 4) {
                this.k[i] = 105;
            }
            if (randomArray[i - 1] === 5) {
                this.k[i] = 106;
            }
            if (randomArray[i - 1] === 6) {
                this.k[i] = 107;
            }
            if (randomArray[i - 1] === 7) {
                this.k[i] = 108;
            }
            if (randomArray[i - 1] === 8) {
                this.k[i] = 109;
            }
            if (randomArray[i - 1] === 9) {
                this.k[i] = 110;
            }
            if (randomArray[i - 1] === 10) {
                this.k[i] = 111;
            }
            if (randomArray[i - 1] === 11) {
                this.k[i] = 112;
            }
            if (randomArray[i - 1] === 12) {
                this.k[i] = 113;
            }
            if (randomArray[i - 1] === 13) {
                this.k[i] = 114;
            }
            if (randomArray[i - 1] === 14) {
                this.k[i] = 202;
            }
            if (randomArray[i - 1] === 15) {
                this.k[i] = 203;
            }
            if (randomArray[i - 1] === 16) {
                this.k[i] = 204;
            }
            if (randomArray[i - 1] === 17) {
                this.k[i] = 205;
            }
            if (randomArray[i - 1] === 18) {
                this.k[i] = 206;
            }
            if (randomArray[i - 1] === 19) {
                this.k[i] = 207;
            }
            if (randomArray[i - 1] === 20) {
                this.k[i] = 208;
            }
            if (randomArray[i - 1] === 21) {
                this.k[i] = 209;
            }
            if (randomArray[i - 1] === 22) {
                this.k[i] = 210;
            }
            if (randomArray[i - 1] === 23) {
                this.k[i] = 211;
            }
            if (randomArray[i - 1] === 24) {
                this.k[i] = 212;
            }
            if (randomArray[i - 1] === 25) {
                this.k[i] = 213;
            }
            if (randomArray[i - 1] === 26) {
                this.k[i] = 214;
            }
            if (randomArray[i - 1] === 27) {
                this.k[i] = 302;
            }
            if (randomArray[i - 1] === 28) {
                this.k[i] = 303;
            }
            if (randomArray[i - 1] === 29) {
                this.k[i] = 304;
            }
            if (randomArray[i - 1] === 30) {
                this.k[i] = 305;
            }
            if (randomArray[i - 1] === 31) {
                this.k[i] = 306;
            }
            if (randomArray[i - 1] === 32) {
                this.k[i] = 307;
            }
            if (randomArray[i - 1] === 33) {
                this.k[i] = 308;
            }
            if (randomArray[i - 1] === 34) {
                this.k[i] = 309;
            }
            if (randomArray[i - 1] === 35) {
                this.k[i] = 310;
            }
            if (randomArray[i - 1] === 36) {
                this.k[i] = 311;
            }
            if (randomArray[i - 1] === 37) {
                this.k[i] = 312;
            }
            if (randomArray[i - 1] === 38) {
                this.k[i] = 313;
            }
            if (randomArray[i - 1] === 39) {
                this.k[i] = 314;
            }
            if (randomArray[i - 1] === 40) {
                this.k[i] = 402;
            }
            if (randomArray[i - 1] === 41) {
                this.k[i] = 403;
            }
            if (randomArray[i - 1] === 42) {
                this.k[i] = 404;
            }
            if (randomArray[i - 1] === 43) {
                this.k[i] = 405;
            }
            if (randomArray[i - 1] === 44) {
                this.k[i] = 406;
            }
            if (randomArray[i - 1] === 45) {
                this.k[i] = 407;
            }
            if (randomArray[i - 1] === 46) {
                this.k[i] = 408;
            }
            if (randomArray[i - 1] === 47) {
                this.k[i] = 409;
            }
            if (randomArray[i - 1] === 48) {
                this.k[i] = 410;
            }
            if (randomArray[i - 1] === 49) {
                this.k[i] = 411;
            }
            if (randomArray[i - 1] === 50) {
                this.k[i] = 412;
            }
            if (randomArray[i - 1] === 51) {
                this.k[i] = 413;
            }
            if (randomArray[i - 1] === 52) {
                this.k[i] = 414;
            }
        }
    }

    DeleteCard(card: number) {
        if (card > 0 && card < 17) {
            for (let i = card; i < 16; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 16; i > 0; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 16 && card < 33) {
            for (let i = card; i < 32; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 32; i > 16; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 32 && card < 49) {
            for (let i = card; i < 48; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 48; i > 32; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
    }

    IntelectColour(Player: number, Color: number): number {
        let tochange1: boolean = false;
        let tochange2: boolean = false;
        if (this.ParviPuska > 1000 && this.ParviPuska < 1500) {
            this.ParviPuska = this.ParviPuska + (Color - 10) * 100;
            tochange1 = true;
        }
        if (this.VtoriPuska > 1000 && this.VtoriPuska < 1500) {
            this.VtoriPuska = this.VtoriPuska + (Color - 10) * 100;
            tochange2 = true;
        }

        let k1: number[] = [];
        for (let i = 0; i < 53; i++) {
            if (this.k[i] < 1000) {
                k1[i] = this.k[i];
            } else {
                k1[i] = this.k[i] + (Color - 10) * 100;
            }
        }
        let ToPlay1: number = 0;
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
        let Broi: number = 0;
        let BestCards: number[][][] = []; //[5][13][2];
        for (let i1 = 0; i1 < 5; i1++) {
            BestCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                BestCards[i1][i2] = [];
            }
        }
        for (let i1 = 0; i1 < 5; i1++) {
            BestCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                BestCards[i1][i2] = [];
            }
        }
        let MyCards: number[][][] = []; //[5][13][2];
        for (let i1 = 0; i1 < 5; i1++) {
            MyCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                MyCards[i1][i2] = [];
            }
        }
        for (let i1 = 0; i1 < 5; i1++) {
            MyCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                MyCards[i1][i2] = [];
            }
        }
        let Stopers: boolean[] = [true, true, true, true, true];
        for (let i = 1; i < 49; i++) {
            if (this.k[i] > 0) {
                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][0] = k1[i];
                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                BroiBoia[Math.floor(k1[i] / 100)]++;
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                for (let k = j + 1; k < BroiBoia[i]; k++) {
                    if (BestCards[i][j][0] < BestCards[i][k][0]) {
                        let t0 = BestCards[i][j][0];
                        let t1 = BestCards[i][j][1];
                        BestCards[i][j][0] = BestCards[i][k][0];
                        BestCards[i][j][1] = BestCards[i][k][1];
                        BestCards[i][k][0] = t0;
                        BestCards[i][k][1] = t1;
                    }
                }
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                if (BestCards[i][j][1] === Player) {
                    MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                    MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                    BroiBoiaPl[i]++;
                    Broi++;
                }
            }
        }
        if (this.ParviPuska === 0) {
            //igra4ut igrae Parvi
            let TegliKozove: boolean = false;
            let c: number;
            let c1: number;
            if (!this.NiamaBoia[1][Color] && !this.NiamaBoia[2][Color] && !this.NiamaBoia[3][Color]) {
                c1 = Math.floor((BroiBoia[Color] - BroiBoiaPl[Color]) / 2) + 1;
            }
            if (!(!this.NiamaBoia[1][Color] && !this.NiamaBoia[2][Color] && !this.NiamaBoia[3][Color])) {
                c1 = BroiBoia[Color] - BroiBoiaPl[Color];
            }
            c = BroiBoiaPl[Color] - c1;
            let SureHands: number = 0;
            let ToGet: number = 0;
            for (let i = 1; i < 5; i++) {
                if (i !== Color) {
                    for (let j = 0; j < BroiBoiaPl[i]; j++) {
                        if (MyCards[i][j][1] === 0) {
                            SureHands++;
                        }
                    }
                }
                let d: number;
                let d1: number;
                if (!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                    d1 = Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1;
                }
                if (!(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])) {
                    d1 = BroiBoia[i] - BroiBoiaPl[i];
                }
                d = BroiBoiaPl[i] - d1;
                if (d > 0) {
                    ToGet += d;
                }
            }
            ToGet += SureHands;
            if (ToGet + c >= Math.floor((Broi - BroiBoiaPl[Color]) / 3) && c >= 0) {
                TegliKozove = true;
            }
            if (BroiBoiaPl[Color] === BroiBoia[Color]) {
                TegliKozove = false;
            }
            if (c >= 0 && MyCards[Color][0][1] === 0 && BroiBoia[Color] > BroiBoiaPl[Color]) {
                //???FIXED
                TegliKozove = true;
            }
            if (TegliKozove) {
                if (MyCards[Color][0][1] === 0) {
                    ToPlay1 = MyCards[Color][0][0];
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                } else {
                    if (BroiBoiaPl[Color] >= 3) {
                        ToPlay1 = MyCards[Color][2][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                ToPlay1 = MyCards[Color][BroiBoiaPl[Color] - 1][0];
                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
            }
            if (BroiBoiaPl[Color] === BroiBoia[Color]) {
                //drupnal e kozovete
                if (BroiBoiaPl[Color] > 0) {
                    if (Broi - BroiBoiaPl[Color] === 1) {
                        ToPlay1 = MyCards[Color][0][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BroiBoia[i] > 0 && i !== Color) {
                            SureHands = 0; //ako ima sigurna boia si ia vzima
                            for (let j = 0; j < BroiBoiaPl[i]; j++) {
                                if (MyCards[i][j][1] === 0 && MyCards[i][j][0] > 0) {
                                    SureHands++;
                                }
                            }
                            if (
                                (SureHands > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (SureHands >= BroiBoia[i] - BroiBoiaPl[i] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                ToPlay1 = MyCards[i][0][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (i !== Color) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 3 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 3 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    for (
                        let i = 1;
                        i < 5;
                        i++ //tursi ruce ot duljina
                    ) {
                        if (i !== Color) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 2 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 2 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 1;
                        i < 5;
                        i++ //tursi ruce ot duljina
                    ) {
                        if (i !== Color) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 1 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && MyCards[i][1][1] <= 3 && BroiBoiaPl[i] >= 4 && i !== Color) {
                            //???FIXED
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][1][1] === 0 && MyCards[i][1][0] !== 0 && i !== Color) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][0] === 0 && MyCards[i][1][0] === 1 && BroiBoia[i] - BroiBoiaPl[i] <= 2 && i !== Color) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0 && MyCards[i][1][1] !== 1 && i !== Color) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0 && i !== Color) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5 && i !== Color) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 1 &&
                            BroiBoiaPl[i] === 2 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i] &&
                            i !== Color
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 2 &&
                            BroiBoiaPl[i] === 3 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i] &&
                            i !== Color
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 3 &&
                            BroiBoiaPl[i] === 4 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i] &&
                            i !== Color
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BroiBoiaPl[i] >= BroiBoiaPl[1] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[2] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[3] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[4] &&
                            i !== Color
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                }
                if (BroiBoiaPl[Color] === 0) {
                    for (let i = 1; i < 5; i++) {
                        if (BroiBoia[i] > 0) {
                            SureHands = 0; //ako ima sigurna boia si ia vzima
                            for (let j = 0; j < BroiBoiaPl[i]; j++) {
                                if (MyCards[i][j][1] === 0 && MyCards[i][j][0] > 0) {
                                    SureHands++;
                                }
                            }
                            if (
                                (SureHands > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (SureHands >= BroiBoia[i] - BroiBoiaPl[i] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                ToPlay1 = MyCards[i][0][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    let BrStopers: number = 4;
                    for (
                        let i = 1;
                        i < 5;
                        i++ //proveriava za stoperi
                    ) {
                        if (BroiBoia[i] >= 3) {
                            if (MyCards[i][0][1] >= BroiBoiaPl[i] || MyCards[i][0][0] === 0) {
                                BrStopers--;
                                Stopers[i] = false;
                            }
                        }
                    }
                    if (Stopers[1] && Stopers[2] && Stopers[3] && Stopers[4] && Broi >= 6) {
                        //ako sa nali4ni stoperi
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 3 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 3 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 2 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 2 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 1 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 0 && MyCards[i][1][1] <= 3 && BroiBoiaPl[i] >= 4) {
                                //???FIXED
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5) {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    let ToMakeHands: boolean = false;
                    if (Broi >= 7) {
                        if (BrStopers === 3) {
                            ToMakeHands = true;
                        }
                    }
                    let GoodStopers: number = 0;
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] < BroiBoiaPl[i] && MyCards[i][0][0] > 0) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) > MyCards[i][0][1]) {
                                    GoodStopers++;
                                }
                            }
                        }
                    }
                    if (GoodStopers >= 2 && Broi >= 6) {
                        ToMakeHands = true;
                    }
                    if (ToMakeHands) {
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 3 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 3 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 2 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 2 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi ruce ot duljina
                        ) {
                            if (
                                (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1 &&
                                    !this.NiamaBoia[1][i] &&
                                    !this.NiamaBoia[2][i] &&
                                    !this.NiamaBoia[3][i]) ||
                                (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 1 &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                            ) {
                                if (MyCards[i][0][1] === 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                        if (MyCards[i][1][1] === 0) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        } else {
                                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    } else {
                                        ToPlay1 = MyCards[i][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                                    //ako nai silnata e v nego
                                    if (BroiBoiaPl[i] >= 3) {
                                        if (
                                            MyCards[i][0][1] === MyCards[i][1][1] &&
                                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                        ) {
                                            ToPlay1 = MyCards[i][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                            ToPlay1 = MyCards[i][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    } else {
                                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 0 && MyCards[i][1][1] <= 3 && BroiBoiaPl[i] >= 4) {
                                //???FIXED
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][1][1] === 0 && MyCards[i][1][0] !== 0) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][0] === 0 && MyCards[i][1][0] === 1 && BroiBoia[i] - BroiBoiaPl[i] <= 2) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0 && MyCards[i][1][1] !== 1) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 1 &&
                            BroiBoiaPl[i] === 2 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 2 &&
                            BroiBoiaPl[i] === 3 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            MyCards[i][0][1] === 3 &&
                            BroiBoiaPl[i] === 4 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BroiBoiaPl[i] >= BroiBoiaPl[1] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[2] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[3] &&
                            BroiBoiaPl[i] >= BroiBoiaPl[4]
                        ) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BroiBoiaPl[i] >= 1) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
            }
            let ShteCaka: boolean[] = [false, false, false, false, false];
            for (let i = 1; i < 4; i++) {
                for (let j = 1; j < 5; j++) {
                    if (this.NiamaBoia[i][j] && !this.NiamaBoia[i][Color] && i !== Player && BroiBoiaPl[j] > 0) {
                        //???FIXED
                        ShteCaka[j] = true;
                    }
                }
            }
            if (BroiBoiaPl[Color] === 0) {
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][0] === 1 && i !== Color) {
                        if (!ShteCaka[i]) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] === 2 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] === 3 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (ShteCaka[i] && i !== Color) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] === 4 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        BroiBoiaPl[i] >= BroiBoiaPl[1] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[2] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[3] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[4]
                    ) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
            }
            if (BroiBoiaPl[Color] > 0) {
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][0] === 1 && i !== Color) {
                        if (!ShteCaka[i]) {
                            ToPlay1 = MyCards[i][0][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //osvobojdava si za cakane
                ) {
                    if (BroiBoiaPl[i] === 1 && MyCards[i][0][1] !== 0 && i !== Color && BroiBoia[i] >= 5) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                    if (BroiBoiaPl[i] === 2 && i !== Color && BroiBoia[i] >= 7) {
                        if (MyCards[i][0][1] !== 1 && MyCards[i][0][1] !== 2 && MyCards[i][1][1] >= 2) {
                            ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3 && i !== Color) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4 && i !== Color) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5 && i !== Color) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] === 2 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        MyCards[i][0][1] === 2 &&
                        BroiBoiaPl[i] === 3 &&
                        i !== Color &&
                        !this.NiamaBoia[1][i] &&
                        !this.NiamaBoia[2][i] &&
                        !this.NiamaBoia[3][i]
                    ) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (ShteCaka[i] && i !== Color) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        MyCards[i][0][1] === 3 &&
                        BroiBoiaPl[i] === 4 &&
                        i !== Color &&
                        !this.NiamaBoia[1][i] &&
                        !this.NiamaBoia[2][i] &&
                        !this.NiamaBoia[3][i]
                    ) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        BroiBoiaPl[i] >= BroiBoiaPl[1] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[2] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[3] &&
                        BroiBoiaPl[i] >= BroiBoiaPl[4] &&
                        i !== Color
                    ) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (BroiBoiaPl[i] > 0) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                }
            }
        }
        if (this.ParviPuska > 0 && this.VtoriPuska === 0) {
            //igra4at igrae vtori
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] > 0) {
                if (MyCards[Math.floor(this.ParviPuska / 100)][0][0] < this.ParviPuska) {
                    //Puska nai malkata
                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                } else {
                    if (Math.floor(this.ParviPuska / 100) === Color || BroiBoiaPl[Color] === BroiBoia[Color]) {
                        if (Player === 2) {
                            if (this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)]) {
                                //ako tretia niama ot tazi boia
                                for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                                    if (this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0]) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                            if (!this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)]) {
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 0) {
                                            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 0) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                }
                                            }
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1) {
                                            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    if (
                                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                        2
                                                    ) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    if (
                                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                        2
                                                    ) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                            }
                                            if (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <= 2) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2) {
                                            if (
                                                MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2 &&
                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                            ) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                            if (
                                                BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                                BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                                    (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) /
                                                        2 +
                                                        1
                                            ) {
                                                ToPlay1 =
                                                    MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                    }
                                    if (
                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                            (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 + 1
                                    ) {
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 1) {
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            let bad: boolean = false;
                                            for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                if (
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] <
                                                        MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                ) {
                                                    bad = true;
                                                }
                                            }
                                            if (!bad) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                        let br = 0;
                                        for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                            if (
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                    MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                            ) {
                                                br++;
                                            }
                                        }
                                        if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 2 && BroiBoia[Math.floor(this.ParviPuska / 100)] >= 4) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    }
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 2) {
                                    let br = 0;
                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                        if (
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                        ) {
                                            br++;
                                        }
                                    }
                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] >= 3) {
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][0][1] === MyCards[Math.floor(this.ParviPuska / 100)][1][1] &&
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        if (Player === 3) {
                            if (this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)]) {
                                //ako tretia niama ot tazi boia
                                for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                                    if (this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0]) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                            if (!this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)]) {
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 0) {
                                            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 0) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                }
                                            }
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1) {
                                            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    if (
                                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                        2
                                                    ) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                    let br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    br = 0;
                                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                        if (
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                                MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                        ) {
                                                            br++;
                                                        }
                                                    }
                                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    if (
                                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                        2
                                                    ) {
                                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                    }
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                            }
                                            if (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <= 2) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                        if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2) {
                                            if (
                                                MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2 &&
                                                MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                            ) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                            if (
                                                BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                                BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                                    (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) /
                                                        2 +
                                                        1
                                            ) {
                                                ToPlay1 =
                                                    MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                    }
                                    if (
                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                            (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 + 1
                                    ) {
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 1) {
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            let bad: boolean = false;
                                            for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                if (
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] <
                                                        MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                    this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                ) {
                                                    bad = true;
                                                }
                                            }
                                            if (!bad) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                        let br = 0;
                                        for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                            if (
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                            ) {
                                                br++;
                                            }
                                        }
                                        if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 2 && BroiBoia[Math.floor(this.ParviPuska / 100)] >= 4) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    }
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 2) {
                                    let br = 0;
                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                        if (
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                        ) {
                                            br++;
                                        }
                                    }
                                    if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] >= 3) {
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                    }
                    if (Math.floor(this.ParviPuska / 100) !== Color && BroiBoiaPl[Color] !== BroiBoia[Color]) {
                        let NP: number;
                        let PP: number;
                        if (Player === 2) {
                            NP = 3;
                            PP = 1;
                        }
                        if (Player === 3) {
                            NP = 1;
                            PP = 2;
                        }
                        if (this.NiamaBoia[NP][Math.floor(this.ParviPuska / 100)]) {
                            //ako tretia niama ot tazi boia
                            for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                                if (
                                    u === 0 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0 &&
                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                                if (this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0]) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                            }
                        }
                        if (!this.NiamaBoia[NP][Math.floor(this.ParviPuska / 100)]) {
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 0) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 0) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                            }
                                        }
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === NP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === NP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === NP
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                            }
                                        }
                                        if (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <= 2) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    }
                                }
                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 1) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        let bad: boolean = false;
                                        for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                            if (
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] <
                                                    MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === NP
                                            ) {
                                                bad = true;
                                            }
                                        }
                                        if (!bad) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    }
                                    let br = 0;
                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                        if (
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                        ) {
                                            br++;
                                        }
                                    }
                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 2 && BroiBoia[Math.floor(this.ParviPuska / 100)] >= 4) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 2) {
                                let br = 0;
                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                    if (
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] > MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === PP
                                    ) {
                                        br++;
                                    }
                                }
                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] >= 3) {
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                }
                            }
                        }
                    }
                }
                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
            }
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 0) {
                if (Math.floor(this.ParviPuska / 100) === Color || BroiBoiaPl[Color] === 0) {
                    ToPlay1 = this.Chistene(Player, Color);
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                }
                if (Math.floor(this.ParviPuska / 100) !== Color && BroiBoiaPl[Color] > 0) {
                    if (
                        (Player === 2 &&
                            (!this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)] ||
                                this.NiamaBoia[3][Color] ||
                                BroiBoia[Color] === BroiBoiaPl[Color])) ||
                        (Player === 3 &&
                            (!this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)] ||
                                this.NiamaBoia[1][Color] ||
                                BroiBoia[Color] === BroiBoiaPl[Color]) &&
                            BroiBoia[Math.floor(this.ParviPuska / 100)] > 0)
                    ) {
                        ToPlay1 = MyCards[Color][BroiBoiaPl[Color] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    } else {
                        ToPlay1 = this.Chistene(Player, Color);
                    }
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                }
            }
        }
        if (this.ParviPuska > 0 && this.VtoriPuska > 0 && this.TretiPuska === 0) {
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] > 0) {
                if (Math.floor(this.ParviPuska / 100) === Color) {
                    //ako e pusnal koz
                    if (
                        this.ParviPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0] ||
                        (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                            this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                    ) {
                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                    if (
                        this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                        (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                            this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                    ) {
                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                if (
                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] < this.ParviPuska ||
                                    (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                        this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][1][0])
                                ) {
                                    if (
                                        BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 6 ||
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 4)
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                    }
                                }
                            }
                        }
                        for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                            if (
                                MyCards[Math.floor(this.ParviPuska / 100)][u][0] > this.ParviPuska &&
                                (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                                    this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0])
                            ) {
                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                }
                if (Math.floor(this.ParviPuska / 100) !== Color) {
                    //Parvia ne e pusnal koz
                    if (
                        this.ParviPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0] ||
                        Math.floor(this.VtoriPuska / 100) === Color ||
                        (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                            this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                    ) {
                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                    if (
                        this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                        Math.floor(this.VtoriPuska / 100) !== Color &&
                        (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                            this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                    ) {
                        if (BroiBoia[Color] - BroiBoiaPl[Color] < BroiBoiaPl[Color]) {
                            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0 && MyCards[Math.floor(this.ParviPuska / 100)][1][1] > 0) {
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] < this.ParviPuska ||
                                        (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                            this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][1][0])
                                    ) {
                                        if (
                                            BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 6 ||
                                                BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 4)
                                        ) {
                                            ToPlay1 =
                                                MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                            return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                                        }
                                    }
                                }
                            }
                        }
                        for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                            if (
                                MyCards[Math.floor(this.ParviPuska / 100)][u][0] > this.ParviPuska &&
                                (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                                    this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0])
                            ) {
                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                }
            }
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 0) {
                //ako niama ot sa6tata boia
                if (Math.floor(this.ParviPuska / 100) === Color || BroiBoiaPl[Color] === 0) {
                    ToPlay1 = this.Chistene(Player, Color);
                    return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                }
                if (Math.floor(this.ParviPuska / 100) !== Color && BroiBoiaPl[Color] > 0) {
                    if (Math.floor(this.VtoriPuska / 100) !== Color) {
                        ToPlay1 = MyCards[Color][BroiBoiaPl[Color] - 1][0];
                        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                    }
                    if (Math.floor(this.VtoriPuska / 100) === Color) {
                        for (let u = BroiBoiaPl[Color] - 1; u >= 0; u--) {
                            if (MyCards[Color][u][0] > this.VtoriPuska) {
                                ToPlay1 = MyCards[Color][u][0];
                                return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
                            }
                        }
                    }
                }
            }
        }
        return this.IntelectColourEnd(Player, ToPlay1, tochange1, tochange2, Color);
    }

    PuskCard(card: number, player: number) {
        for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
            if (this.k[i] === card) {
                return i;
            }
        }
        return 1 + (player - 1) * 16;
    }

    Chistene(Player: number, Boia: number): number {
        let k1: number[] = [];
        for (let i = 0; i < 53; i++) {
            if (this.k[i] < 1000) {
                k1[i] = this.k[i];
            } else {
                k1[i] = this.k[i] + (Boia - 10) * 100;
            }
        }
        let ForChistene: number = 0;
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
        let Broi: number = 0;
        let BestCards: number[][][] = []; //[5][13][2];
        let MyCards: number[][][] = []; //[5][13][2];
        for (let i1 = 0; i1 < 5; i1++) {
            BestCards[i1] = [];
            MyCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                BestCards[i1][i2] = [];
                MyCards[i1][i2] = [];
            }
        }
        for (let i = 1; i < 49; i++) {
            if (this.k[i] > 0) {
                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][0] = k1[i];
                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                BroiBoia[Math.floor(k1[i] / 100)]++;
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                for (let k = j + 1; k < BroiBoia[i]; k++) {
                    if (BestCards[i][j][0] < BestCards[i][k][0]) {
                        let t0 = BestCards[i][j][0];
                        let t1 = BestCards[i][j][1];
                        BestCards[i][j][0] = BestCards[i][k][0];
                        BestCards[i][j][1] = BestCards[i][k][1];
                        BestCards[i][k][0] = t0;
                        BestCards[i][k][1] = t1;
                    }
                }
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                if (BestCards[i][j][1] === Player) {
                    MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                    MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                    BroiBoiaPl[i]++;
                    Broi++;
                }
            }
        }
        let ques: number[] = [0, 0, 0, 0, 0];
        for (let i = 1; i < 5; i++) {
            if (BroiBoiaPl[i] > 0) {
                if (i === Boia) {
                    ques[i] += 20000;
                }
                if (i !== Boia && Boia !== 5 && BroiBoiaPl[Boia] > 0) {
                    if (BroiBoiaPl[i] === 1) {
                        if (MyCards[i][0][1] >= 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] -= 5000;
                            } else {
                                ques[i] -= 2000;
                            }
                        }
                        if (MyCards[i][0][1] === 1) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] -= 500;
                            } else {
                                ques[i] -= 1500;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] === 2) {
                        if (MyCards[i][0][1] >= 3) {
                            ques[i] -= 1000;
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] -= 400;
                            } else {
                                ques[i] -= 800;
                            }
                        }
                        if (MyCards[i][0][1] === 0) {
                            if (MyCards[i][1][1] > 0) {
                                ques[i] -= 500;
                            }
                        }
                    }
                }
                if (MyCards[i][BroiBoiaPl[i] - 1][1] === 0) {
                    ques[i] += 300;
                    if (Broi >= 3) {
                        ques[i] += 600;
                    }
                    if (Broi >= 6) {
                        ques[i] += 1800;
                    }
                    if (BroiBoia[i] - BroiBoiaPl[i] >= 1) {
                        ques[i] += 1200;
                    }
                    if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                        ques[i] += 2000;
                    }
                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                        ques[i] += (BroiBoia[i] - BroiBoiaPl[i] - 2) * 500;
                    }
                }
                if (MyCards[i][BroiBoiaPl[i] - 1][1] === 1) {
                    if (BroiBoiaPl[i] === 1) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                            ques[i] += 700;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                            ques[i] += 700;
                        }
                    }
                    if (BroiBoiaPl[i] === 2) {
                        if (MyCards[i][0][1] === 1) {
                            ques[i] += 1000;
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                                ques[i] += 1000;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 1000;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 1000;
                            }
                        }
                        if (MyCards[i][0][1] === 0) {
                            ques[i] += 500;
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                                ques[i] += 800;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 800;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 800;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] > 2) {
                        ques[i] += 600;
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                            ques[i] += 700;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                            ques[i] += 800;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                            ques[i] += 900;
                        }
                    }
                }
                if (MyCards[i][BroiBoiaPl[i] - 1][1] === 2) {
                    if (BroiBoiaPl[i] === 1) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                            ques[i] += 300;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                            ques[i] += 300;
                        }
                    }
                    if (BroiBoiaPl[i] === 2) {
                        ques[i] += 100;
                        if (MyCards[i][0][1] === 0) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 700;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                                ques[i] += 400;
                            }
                        }
                        if (MyCards[i][0][1] === 1) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                                ques[i] += 200;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 600;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                                ques[i] += 800;
                            }
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                                ques[i] += 100;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 500;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                                ques[i] += 900;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] === 3) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                            ques[i] += 200;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 5) {
                            ques[i] += 500;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                            ques[i] += 900;
                        }
                    }
                    if (BroiBoiaPl[i] > 3) {
                        ques[i] += 400;
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                            ques[i] += 400;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 5) {
                            ques[i] += 600;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                            ques[i] += 900;
                        }
                    }
                    if (BroiBoiaPl[i] > 6) {
                        ques[i] += 400;
                    }
                }
                if (MyCards[i][BroiBoiaPl[i] - 1][1] === 3) {
                    if (BroiBoiaPl[i] === 1) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 5) {
                            ques[i] += 90;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 10) {
                            ques[i] += 60;
                        }
                    }
                    if (BroiBoiaPl[i] === 2) {
                        if (MyCards[i][0][1] === 0) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 100;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                                ques[i] += 80;
                            }
                        }
                        if (MyCards[i][0][1] === 1) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                ques[i] += 800;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 500;
                            }
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                ques[i] += 300;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 200;
                            }
                        }
                        if (MyCards[i][0][1] === 3) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                ques[i] += 60;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 20;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] === 3) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                            ques[i] += 400;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                            ques[i] += 300;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                            ques[i] += 200;
                        }
                    }
                    if (BroiBoiaPl[i] > 3) {
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                            ques[i] += 400;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                            ques[i] += 400;
                        }
                        if (BroiBoia[i] - BroiBoiaPl[i] >= 7) {
                            ques[i] += 200;
                        }
                    }
                }
                if (MyCards[i][BroiBoiaPl[i] - 1][1] >= 4) {
                    if (BroiBoiaPl[i] === 2) {
                        if (MyCards[i][0][1] === 1) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 2) {
                                ques[i] += 700;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 500;
                            }
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                ques[i] += 150;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                ques[i] += 350;
                            }
                        }
                        if (MyCards[i][0][1] === 3) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 8) {
                                ques[i] += 30;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] === 3) {
                        if (MyCards[i][0][1] === 0) {
                            if (MyCards[i][1][1] === 1) {
                                if (BroiBoia[i] - BroiBoiaPl[i] >= 3 || Broi > 6) {
                                    ques[i] += 300;
                                }
                                if (BroiBoia[i] - BroiBoiaPl[i] >= 6 || Broi > 11) {
                                    ques[i] += 250;
                                }
                            }
                            if (MyCards[i][1][1] === 2) {
                                if (BroiBoia[i] - BroiBoiaPl[i] >= 3 || Broi > 6) {
                                    ques[i] += 200;
                                }
                                if (BroiBoia[i] - BroiBoiaPl[i] >= 6 || Broi > 11) {
                                    ques[i] += 150;
                                }
                            }
                        }
                        if (MyCards[i][0][1] === 1) {
                            if (MyCards[i][1][1] === 1) {
                                ques[i] += 300;
                            }
                            if (MyCards[i][1][1] === 2) {
                                ques[i] += 180;
                            }
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 250;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 8) {
                                ques[i] += 150;
                            }
                        }
                        if (MyCards[i][0][1] === 3) {
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 5 || Broi > 6) {
                                ques[i] += 150;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 10 || Broi > 10) {
                                ques[i] += 200;
                            }
                        }
                    }
                    if (BroiBoiaPl[i] >= 4) {
                        if (MyCards[i][0][1] === 0) {
                            if (MyCards[i][1][1] === 0) {
                                if ((BroiBoia[i] - BroiBoiaPl[i] < 6 || BroiBoiaPl[i] >= 5) && Broi > 6) {
                                    ques[i] += 500;
                                }
                            }
                            if (MyCards[i][1][1] === 1) {
                                if ((BroiBoia[i] - BroiBoiaPl[i] < 6 || BroiBoiaPl[i] >= 5) && Broi > 6) {
                                    ques[i] += 300;
                                }
                            }
                            if (MyCards[i][1][1] <= 3 && MyCards[i][1][1] >= 2) {
                                if ((BroiBoia[i] - BroiBoiaPl[i] < 5 || BroiBoiaPl[i] >= 5) && Broi > 6) {
                                    ques[i] += 200;
                                }
                            }
                            if (MyCards[i][1][1] > 4) {
                                if ((BroiBoia[i] - BroiBoiaPl[i] < 4 || BroiBoiaPl[i] >= 5) && Broi > 6) {
                                    ques[i] += 100;
                                }
                            }
                        }
                        if (MyCards[i][0][1] === 1) {
                            if (Broi >= 7 || BroiBoia[i] - BroiBoiaPl[i] >= 4) {
                                ques[i] += 50;
                                if (MyCards[i][1][1] <= 2) {
                                    ques[i] += 50;
                                }
                                if (Broi >= 11) {
                                    ques[i] += 60;
                                }
                                if (BroiBoia[i] - BroiBoiaPl[i] >= 6) {
                                    ques[i] += 80;
                                }
                                if (BroiBoiaPl[i] >= 6 && Broi >= 10) {
                                    ques[i] += 190;
                                }
                            }
                        }
                        if (MyCards[i][0][1] === 2) {
                            if (Broi >= 8) {
                                ques[i] += 100;
                            }
                            if (BroiBoiaPl[i] >= 6 && Broi >= 10) {
                                ques[i] += 120;
                            }
                        }
                        if (MyCards[i][0][1] === 3) {
                            if (Broi >= 8) {
                                ques[i] += 60;
                            }
                            if (BroiBoiaPl[i] >= 6 && Broi >= 10) {
                                ques[i] += 40;
                            }
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 6 && BroiBoiaPl[i] === 4) {
                                ques[i] += 120;
                            }
                        }
                        if (MyCards[i][0][1] > 3) {
                            if (Broi >= 10 && BroiBoiaPl[i] >= 5) {
                                ques[i] += 40;
                            }
                        }
                    }
                }
            }
        }
        //intelect
        for (let i = 1; i < 5; i++) {
            if (
                BroiBoiaPl[i] > 0 &&
                (ques[i] <= ques[1] || BroiBoiaPl[1] === 0) &&
                (ques[i] <= ques[2] || BroiBoiaPl[2] === 0) &&
                (ques[i] <= ques[3] || BroiBoiaPl[3] === 0) &&
                (ques[i] <= ques[4] || BroiBoiaPl[4] === 0)
            ) {
                ForChistene = MyCards[i][BroiBoiaPl[i] - 1][0];
                return ForChistene;
            }
        }
        return ForChistene;
    }

    IntelectColourEnd(Player: number, ToPlay1: number, tochange1: boolean, tochange2: boolean, Color: number) {
        if (tochange1) {
            this.ParviPuska += 1000 - Color * 100;
        }
        if (tochange2) {
            this.VtoriPuska += 1000 - Color * 100;
        }
        if (Math.floor(ToPlay1 / 100) === Color) {
            ToPlay1 += 1000 - Color * 100;
        }
        return this.PuskCard(ToPlay1, Player);
    }

    IntelectNT(Player: number): number {
        let ToPlay1: number = 0;
        let BroiBoia: number[] = [0, 0, 0, 0, 0];
        let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
        let Broi: number = 0;
        let BestCards: number[][][] = []; //[5][13][2];
        let MyCards: number[][][] = []; //[5][13][2];
        for (let i1 = 0; i1 < 5; i1++) {
            BestCards[i1] = [];
            MyCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                BestCards[i1][i2] = [];
                MyCards[i1][i2] = [];
            }
        }
        let Stopers: boolean[] = [true, true, true, true, true];
        for (let i = 1; i < 49; i++) {
            if (this.k[i] > 0) {
                BestCards[Math.floor(this.k[i] / 100)][BroiBoia[Math.floor(this.k[i] / 100)]][0] = this.k[i];
                BestCards[Math.floor(this.k[i] / 100)][BroiBoia[Math.floor(this.k[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                BroiBoia[Math.floor(this.k[i] / 100)]++;
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                for (let k = j + 1; k < BroiBoia[i]; k++) {
                    if (BestCards[i][j][0] < BestCards[i][k][0]) {
                        let t0 = BestCards[i][j][0];
                        let t1 = BestCards[i][j][1];
                        BestCards[i][j][0] = BestCards[i][k][0];
                        BestCards[i][j][1] = BestCards[i][k][1];
                        BestCards[i][k][0] = t0;
                        BestCards[i][k][1] = t1;
                    }
                }
            }
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < BroiBoia[i]; j++) {
                if (BestCards[i][j][1] === Player) {
                    MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                    MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                    BroiBoiaPl[i]++;
                    Broi++;
                }
            }
        }
        if (this.KoiPuskaParvi === Player) {
            //igra4ut igrae Parvi
            for (let i = 1; i < 5; i++) {
                if (BroiBoia[i] > 0) {
                    let SureHands: number = 0; //ako ima sigurna boia si ia vzima
                    for (let j = 0; j < BroiBoiaPl[i]; j++) {
                        if (MyCards[i][j][1] === 0 && MyCards[i][j][0] > 0) {
                            SureHands++;
                        }
                    }
                    if (
                        (SureHands > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (SureHands >= BroiBoia[i] - BroiBoiaPl[i] && !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        ToPlay1 = MyCards[i][0][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
            }
            let BrStopers = 4;
            for (
                let i = 1;
                i < 5;
                i++ //proveriava za stoperi
            ) {
                if (BroiBoia[i] >= 3) {
                    if (MyCards[i][0][1] >= BroiBoiaPl[i] || MyCards[i][0][0] === 0) {
                        BrStopers--;
                        Stopers[i] = false;
                    }
                }
            }
            if (Stopers[1] && Stopers[2] && Stopers[3] && Stopers[4] && Broi >= 6) {
                //ako sa nali4ni stoperi
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 3 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 3 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 2 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 2 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 1 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 0 && MyCards[i][1][1] <= 3 && BroiBoiaPl[i] >= 4) {
                        //???FIXED
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
            }
            let ToMakeHands: boolean = false;
            if (Broi >= 7) {
                if (BrStopers === 3) {
                    ToMakeHands = true;
                }
            }
            let GoodStopers: number = 0;
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] < BroiBoiaPl[i] && MyCards[i][0][0] > 0) {
                    if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                        if (Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) > MyCards[i][0][1]) {
                            GoodStopers++;
                        }
                    }
                }
            }
            if (GoodStopers >= 2 && Broi >= 6) {
                ToMakeHands = true;
            }
            if (ToMakeHands) {
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 3 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 3 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 2 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 2 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi ruce ot duljina
                ) {
                    if (
                        (BroiBoiaPl[i] > Math.floor((BroiBoia[i] - BroiBoiaPl[i]) / 2) + 1 &&
                            !this.NiamaBoia[1][i] &&
                            !this.NiamaBoia[2][i] &&
                            !this.NiamaBoia[3][i]) ||
                        (BroiBoiaPl[i] > BroiBoiaPl[i] - BroiBoiaPl[i] + 1 &&
                            !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]))
                    ) {
                        if (MyCards[i][0][1] === 0) {
                            //ako nai silnata e v nego
                            if (BroiBoia[i] - BroiBoiaPl[i] >= 3) {
                                if (MyCards[i][1][1] === 0) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                } else {
                                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            } else {
                                ToPlay1 = MyCards[i][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                        if (MyCards[i][0][1] <= 3 && MyCards[i][0][1] > 0) {
                            //ako nai silnata e v nego
                            if (BroiBoiaPl[i] >= 3) {
                                if (
                                    MyCards[i][0][1] === MyCards[i][1][1] &&
                                    !(!this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i])
                                ) {
                                    ToPlay1 = MyCards[i][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (MyCards[i][2][1] <= MyCards[i][0][1] + 2) {
                                    ToPlay1 = MyCards[i][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            } else {
                                ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 0 && MyCards[i][1][1] <= 3 && BroiBoiaPl[i] >= 4) {
                        //???FIXED
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                        ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                        return this.PuskCard(ToPlay1, Player);
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][1][1] === 0 && MyCards[i][1][0] !== 0) {
                    ToPlay1 = MyCards[i][0][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][0] === 0 && MyCards[i][1][0] === 1 && BroiBoia[i] - BroiBoiaPl[i] <= 2) {
                    ToPlay1 = MyCards[i][0][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0 && MyCards[i][1][1] !== 1) {
                    ToPlay1 = MyCards[i][0][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 0 && MyCards[i][0][0] !== 0) {
                    ToPlay1 = MyCards[i][0][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] >= 3) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] >= 4) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] >= 5) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 1 && BroiBoiaPl[i] === 2 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 2 && BroiBoiaPl[i] === 3 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (MyCards[i][0][1] === 3 && BroiBoiaPl[i] === 4 && !this.NiamaBoia[1][i] && !this.NiamaBoia[2][i] && !this.NiamaBoia[3][i]) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
            for (let i = 1; i < 5; i++) {
                if (
                    BroiBoiaPl[i] >= BroiBoiaPl[1] &&
                    BroiBoiaPl[i] >= BroiBoiaPl[2] &&
                    BroiBoiaPl[i] >= BroiBoiaPl[3] &&
                    BroiBoiaPl[i] >= BroiBoiaPl[4]
                ) {
                    ToPlay1 = MyCards[i][BroiBoiaPl[i] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
        }
        if ((this.KoiPuskaParvi === 1 && Player === 2) || (this.KoiPuskaParvi === 2 && Player === 3)) {
            //igra4ut igrae vtori
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 0) {
                ToPlay1 = this.Chistene(Player, 5);
                return this.PuskCard(ToPlay1, Player);
            } else {
                if (this.ParviPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0]) {
                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
                if (MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                    //ako moje da vzeme
                    if (Player === 2) {
                        if (this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)]) {
                            //ako tretia niama ot tazi boia
                            for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                                if (this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0]) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                        }
                        if (!this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)]) {
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 0) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 0) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                            }
                                        }
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                        }
                                        if (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <= 2) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2) {
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                        if (
                                            BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                                BroiBoia[Math.floor(this.ParviPuska / 100)] -
                                                    Math.floor(BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 +
                                                    1
                                        ) {
                                            ToPlay1 =
                                                MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                }
                                if (
                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                        BroiBoia[Math.floor(this.ParviPuska / 100)] -
                                            Math.floor(BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 +
                                            1
                                ) {
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 1) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        let bad: boolean = false;
                                        for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                            if (
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] <
                                                    MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 3
                                            ) {
                                                bad = true;
                                            }
                                        }
                                        if (!bad) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                    let br = 0;
                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                        if (
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                        ) {
                                            br++;
                                        }
                                    }
                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 2 && BroiBoia[Math.floor(this.ParviPuska / 100)] >= 4) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 2) {
                                let br = 0;
                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                    if (
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] > MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                    ) {
                                        br++;
                                    }
                                }
                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] >= 3) {
                                if (
                                    MyCards[Math.floor(this.ParviPuska / 100)][0][1] === MyCards[Math.floor(this.ParviPuska / 100)][1][1] &&
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                        }
                    }
                    if (Player === 3) {
                        if (this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)]) {
                            //ako tretia niama ot tazi boia
                            for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                                if (this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0]) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                        }
                        if (!this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)]) {
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 0) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 0) {
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                            }
                                        }
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1) {
                                        if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3) {
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 1) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                            if (MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2) {
                                                let br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                br = 0;
                                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                                    if (
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                            MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                                    ) {
                                                        br++;
                                                    }
                                                }
                                                if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                if (
                                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <=
                                                    2
                                                ) {
                                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                                    return this.PuskCard(ToPlay1, Player);
                                                }
                                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                                return this.PuskCard(ToPlay1, Player);
                                            }
                                        }
                                        if (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] <= 2) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                    if (MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2) {
                                        if (
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][1] === 2 &&
                                            MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                        ) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                        if (
                                            BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                            BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                                BroiBoia[Math.floor(this.ParviPuska / 100)] -
                                                    Math.floor(BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 +
                                                    1
                                        ) {
                                            ToPlay1 =
                                                MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                }
                                if (
                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >=
                                        BroiBoia[Math.floor(this.ParviPuska / 100)] -
                                            Math.floor(BroiBoiaPl[Math.floor(this.ParviPuska / 100)]) / 2 +
                                            1
                                ) {
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                return this.PuskCard(ToPlay1, Player);
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 1) {
                                if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 1 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        MyCards[Math.floor(this.ParviPuska / 100)][1][1] === 2 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        let bad: boolean = false;
                                        for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                            if (
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] <
                                                    MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                                this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 1
                                            ) {
                                                bad = true;
                                            }
                                        }
                                        if (!bad) {
                                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                            return this.PuskCard(ToPlay1, Player);
                                        }
                                    }
                                    let br = 0;
                                    for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                        if (
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] >
                                                MyCards[Math.floor(this.ParviPuska / 100)][1][0] &&
                                            this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                        ) {
                                            br++;
                                        }
                                    }
                                    if (br === 1 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                        MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                    ) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 2 && BroiBoia[Math.floor(this.ParviPuska / 100)] >= 4) {
                                        ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                        return this.PuskCard(ToPlay1, Player);
                                    }
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 2) {
                                let br = 0;
                                for (let q = 0; q < this.SureCardsBr[Math.floor(this.ParviPuska / 100)]; q++) {
                                    if (
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][0] > MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                                        this.SureCards[Math.floor(this.ParviPuska / 100)][q][1] === 2
                                    ) {
                                        br++;
                                    }
                                }
                                if (br === 2 && MyCards[Math.floor(this.ParviPuska / 100)][0][0] > this.ParviPuska) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][0][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][2][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][2][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                            if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] >= 3) {
                                if (
                                    BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2 &&
                                    MyCards[Math.floor(this.ParviPuska / 100)][1][0] > this.ParviPuska
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                        }
                    }
                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
            }
        }
        if ((this.KoiPuskaParvi === 3 && Player === 2) || (this.KoiPuskaParvi === 1 && Player === 3)) {
            //igra4ut igrae treti
            if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] === 0) {
                ToPlay1 = this.Chistene(Player, 5);
                return this.PuskCard(ToPlay1, Player);
            } else {
                if (
                    this.ParviPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0] ||
                    (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                        this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                ) {
                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                    return this.PuskCard(ToPlay1, Player);
                }
                if (
                    this.ParviPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0] &&
                    (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                        this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][0][0])
                ) {
                    if (BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 2) {
                        if (MyCards[Math.floor(this.ParviPuska / 100)][0][1] === 0) {
                            if (
                                MyCards[Math.floor(this.ParviPuska / 100)][1][0] < this.ParviPuska ||
                                (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                    this.VtoriPuska > MyCards[Math.floor(this.ParviPuska / 100)][1][0])
                            ) {
                                if (
                                    BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 3 &&
                                    (BroiBoia[Math.floor(this.ParviPuska / 100)] - BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 6 ||
                                        BroiBoiaPl[Math.floor(this.ParviPuska / 100)] >= 4)
                                ) {
                                    ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                                    return this.PuskCard(ToPlay1, Player);
                                }
                            }
                        }
                    }
                    for (let u = BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1; u >= 0; u--) {
                        if (
                            MyCards[Math.floor(this.ParviPuska / 100)][u][0] > this.ParviPuska &&
                            (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                                this.VtoriPuska < MyCards[Math.floor(this.ParviPuska / 100)][u][0])
                        ) {
                            ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][u][0];
                            return this.PuskCard(ToPlay1, Player);
                        }
                    }
                }
                ToPlay1 = MyCards[Math.floor(this.ParviPuska / 100)][BroiBoiaPl[Math.floor(this.ParviPuska / 100)] - 1][0];
                return this.PuskCard(ToPlay1, Player);
            }
        }
        //	ToPlay1=1+(Player-1)*16;

        return this.PuskCard(ToPlay1, Player);
    }

    SendCards() {
        let a: number;
        if (this.P2OP) {
            this.P2G = false;
            for (a = 17; a < 49; a++) {
                this.props.m_pwin.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (a = 1; a < 17; a++) {
                this.props.m_pwin.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (a = 49; a < 53; a++) {
                this.props.m_pwin.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
        }
        if (this.P3OP) {
            this.P3G = false;
            for (a = 33; a < 49; a++) {
                this.props.m_pwin.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
            for (a = 1; a < 33; a++) {
                this.props.m_pwin.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
            for (a = 49; a < 53; a++) {
                this.props.m_pwin.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
        }
        if (this.HaveWatch) {
            for (a = 1; a < 53; a++) {
                this.props.m_pwin.SendGame('$|j|' + this.IntToString(this.k[a]));
            }
        }
    }

    SendKoiZapochva() {
        if (this.P2OP) {
            let koi: string = '3';
            if (this.KoiZapochva > 1) {
                koi = this.IntToString(this.KoiZapochva - 1);
            }
            this.props.m_pwin.SendGame('$|b|' + koi);
        }
        if (this.P3OP) {
            let koi = '2';
            if (this.KoiZapochva === 1) {
                koi = '2';
            }
            if (this.KoiZapochva === 2) {
                koi = '3';
            }
            if (this.KoiZapochva === 3) {
                koi = '1';
            }
            this.props.m_pwin.SendGame('$|e|' + koi);
        }
        if (this.HaveWatch) {
            this.props.m_pwin.SendGame('$|*|a|' + this.IntToString(this.KoiZapochva));
        }
    }

    SendNames() {
        if (this.P2OP) {
            let message = '$|a|' + this.Name[3];
            this.props.m_pwin.SendGame(message);
            message = '$|a|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
        }
        if (this.P3OP) {
            let message = '$|d|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
            message = '$|d|' + this.Name[2];
            this.props.m_pwin.SendGame(message);
        }
        if (this.HaveWatch) {
            let message = '$|t|1|' + this.Name[2] + '|' + this.Name[3];
            this.props.m_pwin.SendGame(message);
        }
    }

    DrawPoints() {
        if (this.Faza > 0) {
            let res = [];
            let p1 = this.IntToString(this.m_rez1);
            p1 = p1 + ' ' + this.GetLanguageText('p.');
            res.push(this.DrawBorderText(p1, 263, 385, 'p1'));
            let p2 = this.IntToString(this.m_rez2);
            p2 = p2 + ' ' + this.GetLanguageText('p.');
            res.push(this.DrawBorderText(p2, 640, 210, 'p2'));
            let p3 = this.IntToString(this.m_rez3);
            p3 = p3 + ' ' + this.GetLanguageText('p.');
            res.push(this.DrawBorderText(p3, 267, 138, 'p3'));
            return res;
        }
        return null;
    }

    DrawResult() {
        if (this.Name[1] !== '') {
            return this.DrawBorderText(
                this.GetLanguageText('Scoring') +
                    ' ' +
                    this.IntToString(this.m_orez.m_rez1) +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez2) +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez3),
                620,
                30,
                'DrawResult'
            );
        }
    }

    GetBrojKarti(boia: number, player: number) {
        let br = 0;
        ///za da tarsi vav vsichkite karti player za Belot4players i Belot3players triabva da e 0
        if (boia === 1) {
            if (player === 0) {
                if (this.Naddavane === 1) {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 100 && this.k[i] < 200) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 1) {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 100 && this.k[i] < 200) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 2) {
            if (player === 0) {
                if (this.Naddavane === 2) {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 200 && this.k[i] < 300) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 2) {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 200 && this.k[i] < 300) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 3) {
            if (player === 0) {
                if (this.Naddavane === 3) {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 300 && this.k[i] < 400) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 3) {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 300 && this.k[i] < 400) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 4) {
            if (player === 0) {
                if (this.Naddavane === 4) {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 400 && this.k[i] < 500) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 4) {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 1000) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (let i = 1 + (player - 1) * 16; i < 17 - this.hand + (player - 1) * 16; i++) {
                        if (this.k[i] > 400 && this.k[i] < 500) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        return br;
    }

    CheckVlastnaKarta(card: number, player: number) {
        let a: number;
        let talon: boolean = true;
        let cards: boolean = true;
        if (player === 1) {
            if (card > 100 && card < 200) {
                for (a = 17; a < 49; a++) {
                    if (this.k[a] > 100 && this.k[a] < 200 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 200 && card < 300) {
                for (a = 17; a < 49; a++) {
                    if (this.k[a] > 200 && this.k[a] < 300 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 300 && card < 400) {
                for (a = 17; a < 49; a++) {
                    if (this.k[a] > 300 && this.k[a] < 400 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 400 && card < 500) {
                for (a = 17; a < 49; a++) {
                    if (this.k[a] > 400 && this.k[a] < 500 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 1000) {
                for (a = 17; a < 49; a++) {
                    if (this.k[a] > 1000 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
        }
        if (player === 2) {
            if (card > 100 && card < 200) {
                for (a = 1; a < 17; a++) {
                    if (this.k[a] > 100 && this.k[a] < 200 && this.k[a] > card) {
                        cards = false;
                    }
                }
                for (a = 33; a < 49; a++) {
                    if (this.k[a] > 100 && this.k[a] < 200 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 200 && card < 300) {
                for (a = 1; a < 17; a++) {
                    if (this.k[a] > 200 && this.k[a] < 300 && this.k[a] > card) {
                        cards = false;
                    }
                }
                for (a = 33; a < 49; a++) {
                    if (this.k[a] > 200 && this.k[a] < 300 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 300 && card < 400) {
                for (a = 1; a < 17; a++) {
                    if (this.k[a] > 300 && this.k[a] < 400 && this.k[a] > card) {
                        cards = false;
                    }
                }
                for (a = 33; a < 49; a++) {
                    if (this.k[a] > 300 && this.k[a] < 400 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 400 && card < 500) {
                for (a = 1; a < 17; a++) {
                    if (this.k[a] > 400 && this.k[a] < 500 && this.k[a] > card) {
                        cards = false;
                    }
                }
                for (a = 33; a < 49; a++) {
                    if (this.k[a] > 400 && this.k[a] < 500 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 1000) {
                for (a = 1; a < 17; a++) {
                    if (this.k[a] > 1000 && this.k[a] > card) {
                        cards = false;
                    }
                }
                for (a = 33; a < 49; a++) {
                    if (this.k[a] > 1000 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
        }
        if (player === 3) {
            if (card > 100 && card < 200) {
                for (a = 1; a < 33; a++) {
                    if (this.k[a] > 100 && this.k[a] < 200 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 200 && card < 300) {
                for (a = 1; a < 33; a++) {
                    if (this.k[a] > 200 && this.k[a] < 300 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 300 && card < 400) {
                for (a = 1; a < 33; a++) {
                    if (this.k[a] > 300 && this.k[a] < 400 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 400 && card < 500) {
                for (a = 1; a < 33; a++) {
                    if (this.k[a] > 400 && this.k[a] < 500 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
            if (card > 1000) {
                for (a = 1; a < 33; a++) {
                    if (this.k[a] > 1000 && this.k[a] > card) {
                        cards = false;
                    }
                }
            }
        }
        if (card > 100 && card < 200) {
            for (a = 49; a < 53; a++) {
                if (this.k[a] > 100 && this.k[a] < 200 && this.k[a] > card) {
                    talon = false;
                }
            }
        }
        if (card > 200 && card < 300) {
            for (a = 49; a < 53; a++) {
                if (this.k[a] > 200 && this.k[a] < 300 && this.k[a] > card) {
                    talon = false;
                }
            }
        }
        if (card > 300 && card < 400) {
            for (a = 49; a < 53; a++) {
                if (this.k[a] > 300 && this.k[a] < 400 && this.k[a] > card) {
                    talon = false;
                }
            }
        }
        if (card > 400 && card < 500) {
            for (a = 49; a < 53; a++) {
                if (this.k[a] > 400 && this.k[a] < 500 && this.k[a] > card) {
                    talon = false;
                }
            }
        }
        if (card > 1000) {
            for (a = 49; a < 53; a++) {
                if (this.k[a] > 1000 && this.k[a] > card) {
                    talon = false;
                }
            }
        }
        if (player === this.KoiZapochva && cards === true) {
            return true;
        }
        if (player !== this.KoiZapochva) {
            if (talon === true && cards === true) {
                return true;
            } //???FIXED
        }

        return false;
    }

    GetVlastnaKarta(player: number) {
        for (let h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
            if (this.CheckVlastnaKarta(this.k[h], player) && this.k[h] < 1000) {
                return this.k[h];
            }
        }
        return 0;
    }

    GetBigerCard(player: number, card: number) {
        let h;
        if (card > 100 && card < 200) {
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (this.k[h] > 100 && this.k[h] < 200 && this.k[h] > card) {
                    return this.k[h];
                }
            }
        }
        if (card > 200 && card < 300) {
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (this.k[h] > 200 && this.k[h] < 300 && this.k[h] > card) {
                    return this.k[h];
                }
            }
        }
        if (card > 300 && card < 400) {
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (this.k[h] > 300 && this.k[h] < 400 && this.k[h] > card) {
                    return this.k[h];
                }
            }
        }
        if (card > 400 && card < 500) {
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (this.k[h] > 400 && this.k[h] < 500 && this.k[h] > card) {
                    return this.k[h];
                }
            }
        }
        if (card > 1000) {
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (this.k[h] > 1000 && this.k[h] > card) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    GetSmallerCard(player: number, card: number) {
        let h;
        if (card > 100 && card < 200) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 100 && this.k[h] < 200 && this.k[h] < card) {
                    return this.k[h];
                }
            }
        }
        if (card > 200 && card < 300) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 200 && this.k[h] < 300 && this.k[h] < card) {
                    return this.k[h];
                }
            }
        }
        if (card > 300 && card < 400) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 300 && this.k[h] < 400 && this.k[h] < card) {
                    return this.k[h];
                }
            }
        }
        if (card > 400 && card < 500) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 400 && this.k[h] < 500 && this.k[h] < card) {
                    return this.k[h];
                }
            }
        }
        if (card > 1000) {
            for (h = 16 - this.hand + (player - 1) * 16; h > (player - 1) * 16; h--) {
                if (this.k[h] > 1000 && this.k[h] < card) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    GetBigestCard(player: number, boia: number, pp: number, vp: number) {
        //pp-parvi puska , vp-vtori puska
        //izpolzva se samo kogato e nared poslednia (3-tia igrach);
        let h;
        if (boia === 1) {
            if (pp < 100 || pp > 200) {
                return 0;
            }
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if ((vp < 100 || vp > 200) && vp < 1000) {
                    if (this.k[h] > pp && this.k[h] > 100 && this.k[h] < 200) {
                        return this.k[h];
                    }
                }
                if (vp > 100 && vp < 200) {
                    if (this.k[h] > pp && this.k[h] > vp && this.k[h] > 100 && this.k[h] < 200) {
                        return this.k[h];
                    }
                }
            }
        }
        if (boia === 2) {
            if (pp < 200 || pp > 300) {
                return 0;
            }
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if ((vp < 200 || vp > 300) && vp < 1000) {
                    if (this.k[h] > pp && this.k[h] > 200 && this.k[h] < 300) {
                        return this.k[h];
                    }
                }
                if (vp > 200 && vp < 300) {
                    if (this.k[h] > pp && this.k[h] > vp && this.k[h] > 200 && this.k[h] < 300) {
                        return this.k[h];
                    }
                }
            }
        }
        if (boia === 3) {
            if (pp < 300 || pp > 400) {
                return 0;
            }
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if ((vp < 300 || vp > 400) && vp < 1000) {
                    if (this.k[h] > pp && this.k[h] > 300 && this.k[h] < 400) {
                        return this.k[h];
                    }
                }
                if (vp > 300 && vp < 400) {
                    if (this.k[h] > pp && this.k[h] > vp && this.k[h] > 300 && this.k[h] < 400) {
                        return this.k[h];
                    }
                }
            }
        }
        if (boia === 4) {
            if (pp < 400 || pp > 500) {
                return 0;
            }
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if ((vp < 400 || vp > 500) && vp < 1000) {
                    if (this.k[h] > pp && this.k[h] > 400 && this.k[h] < 500) {
                        return this.k[h];
                    }
                }
                if (vp > 400 && vp < 500) {
                    if (this.k[h] > pp && this.k[h] > vp && this.k[h] > 400 && this.k[h] < 500) {
                        return this.k[h];
                    }
                }
            }
        }
        if (boia === this.Naddavane) {
            if (pp < 1000) {
                return 0;
            }
            for (h = 1 + (player - 1) * 16; h < 17 - this.hand + (player - 1) * 16; h++) {
                if (vp < 1000) {
                    if (this.k[h] > pp) {
                        return this.k[h];
                    }
                }
                if (vp > 1000) {
                    if (this.k[h] > pp && this.k[h] > vp) {
                        return this.k[h];
                    }
                }
            }
        }
        return 0;
    }

    GetGameTypeName() {
        if (this.type === 9) {
            return '9 ' + this.GetLanguageText('Round');
        }
        if (this.type === 21) {
            return this.GetLanguageText('To 21 Points');
        }
        return '15 ' + this.GetLanguageText('Round');
    }

    SendResult(player: number) {
        if (player === 1) {
            for (let a = 0; a < 100; a++) {
                if (this.m_orez.orez[0][a + 1] === -10) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message: string =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[2][a]);
                        this.props.m_pwin.SendGame(message);
                    } else {
                        let message: string = '$|u|' + this.IntToString(player) + '|0|0|0';
                        this.props.m_pwin.SendGame(message);
                    }
                    return;
                }
            }
        } else {
            if (player === 2) {
                for (let a = 0; a < 100; a++) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message: string =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[2][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]);
                        this.props.m_pwin.SendGame(message);
                    }
                }
            }
            if (player === 3) {
                for (let a = 0; a < 100; a++) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[2][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]);
                        this.props.m_pwin.SendGame(message);
                    }
                }
            }
            this.SendObiavi(player);
        }
    }

    SendObiavi(player: number) {
        for (let i = 0; i < 6; i++) {
            for (let a = 1; a < 4; a++) {
                let opl: number = 1;
                if (player === 2) {
                    if (a === 1) {
                        opl = 3;
                    }
                    if (a === 2) {
                        opl = 1;
                    }
                    if (a === 3) {
                        opl = 2;
                    }
                }
                if (player === 3) {
                    if (a === 1) {
                        opl = 2;
                    }
                    if (a === 2) {
                        opl = 3;
                    }
                    if (a === 3) {
                        opl = 1;
                    }
                }
                let message: string = '$|h|' + this.IntToString(player) + '|' + this.IntToString(opl) + '|' + this.IntToString(i) + '|';
                if (this.Obiavi[a][i]) {
                    message = message + '1';
                } else {
                    message = message + '0';
                }
                this.props.m_pwin.SendGame(message);
            }
        }
    }

    SendCHCards(cplayer: number) {
        if (this.Server) {
            if (this.P2OP) {
                if (cplayer === 1) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 2; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                if (p === 2) {
                                    pl2 = 1;
                                }
                                if (p === 3) {
                                    pl2 = 2;
                                }
                                let message: string =
                                    '$|i|2|3|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message = '$|i|2|3|' + this.IntToString(0) + '|' + this.IntToString(0) + '|' + this.IntToString(0); //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
                if (cplayer === 3) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 1; p <= 2; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                if (p === 1) {
                                    pl2 = 3;
                                }
                                if (p === 2) {
                                    pl2 = 1;
                                }
                                let message: string =
                                    '$|i|2|2|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message = '$|i|2|2|' + this.IntToString(0) + '|' + this.IntToString(0) + '|' + this.IntToString(0); //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
            }
            if (this.P3OP) {
                if (cplayer === 1) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 2; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2: number = p;
                                if (p === 2) {
                                    pl2 = 3;
                                }
                                if (p === 3) {
                                    pl2 = 1;
                                }
                                let message =
                                    '$|i|3|2|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message = '$|i|3|2|' + this.IntToString(0) + '|' + this.IntToString(0) + '|' + this.IntToString(0); //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
                if (cplayer === 2) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 1; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                if (p === 1) {
                                    pl2 = 2;
                                }
                                if (p === 3) {
                                    pl2 = 1;
                                }
                                let message: string =
                                    '$|i|3|3|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message: string = '$|i|3|3|' + this.IntToString(0) + '|' + this.IntToString(0) + '|' + this.IntToString(0); //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
            }
            if (this.HaveWatch) {
                if (cplayer === 1) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 1; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                let message: string =
                                    '$|i|1|1|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message: string = '$|i|1|1|0|0|0'; //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
                if (cplayer === 2) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 1; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                let message: string =
                                    '$|i|1|2|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message: string = '$|i|1|2|0|0|0'; //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
                if (cplayer === 3) {
                    for (let i = 1; i <= 4; i++) {
                        for (let p = 1; p <= 3; p++) {
                            if (this.ChCards[cplayer][i][p] > 0) {
                                let pl2 = p;
                                let message: string =
                                    '$|i|1|3|' +
                                    this.IntToString(i) +
                                    '|' +
                                    this.IntToString(pl2) +
                                    '|' +
                                    this.IntToString(this.ChCards[cplayer][i][p]);
                                this.props.m_pwin.SendGame(message);
                            }
                        }
                    }
                    let message: string = '$|i|1|3|0|0|0'; //Finish Send Change Cards
                    this.props.m_pwin.SendGame(message);
                }
            }
        } else {
            if (cplayer === 1) {
                for (let i = 1; i <= 4; i++) {
                    for (let p = 2; p <= 3; p++) {
                        if (this.ChCards[cplayer][i][p] > 0) {
                            let pl2 = p;
                            let message =
                                '$|i|0|0|' + this.IntToString(i) + '|' + this.IntToString(pl2) + '|' + this.IntToString(this.ChCards[cplayer][i][p]);
                            this.props.m_pwin.SendGame(message);
                        }
                    }
                }
                let message = '$|i|0|0|' + this.IntToString(0) + '|' + this.IntToString(0) + '|' + this.IntToString(0); //Finish Send Change Cards
                this.props.m_pwin.SendGame(message);
            }
        }
        this.GCPL[cplayer] = true;
    }

    SendNamesP(player: number) {
        if (player === 2) {
            let message: string = '$|t|2|' + this.Name[3] + '|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
        }
        if (player === 3) {
            let message: string = '$|t|3|' + this.Name[1] + '|' + this.Name[2];
            this.props.m_pwin.SendGame(message);
        }
    }

    InitNewGame() {
        this.talon_ready = false;
        this.get_cards = false;
        this.op_start = false;
        this.pause = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.brgame = 0;
        for (let i = 1; i < 4; i++) {
            for (let a = 0; a < 6; a++) {
                this.Obiavi[i][a] = false;
            }
            this.GCPL[i] = false;
        }
        for (let a = 0; a < 100; a++) {
            this.m_orez.orez[0][a] = -10;
            this.m_orez.orez[1][a] = -10;
            this.m_orez.orez[2][a] = -10;
        }
        this.m_rez.m_rez1 = 0;
        this.m_rez.m_rez2 = 0;
        this.m_rez.m_rez3 = 0;
        this.m_rez1 = 0;
        this.m_rez2 = 0;
        this.m_rez3 = 0;
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        this.m_orez.m_rez3 = 0;
        this.LastResult[1] = 0;
        this.LastResult[2] = 0;
        this.LastResult[3] = 0;
        if (this.SP) {
            this.InitGame(false);
        }
        for (let i = 1; i <= 3; i++) {
            this.last_Name[i] = this.Name[i];
        }
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        if (this.SP === true || !this.Server || (this.OP && !this.P2OP && !this.P3OP)) {
            //    this.props.m_pwin.showAd();
        }
        this.setState({});
    }

    InvalidateBidding() {
        this.obiaviavaneRN = null;
        this.setState({});
    }

    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string;
        if (f === 'a') {
            //Poluchavane na imenata client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let name = this.props.m_pwin.ReceiveString(12);
            for (let i = 2; i < 4; i++) {
                if (this.Name[i] === '') {
                    if (name !== 'Computer2' && name !== 'Computer3') {
                        this.Name[i] = name;
                    } else {
                        if (i === 2) {
                            this.Name[2] = 'Computer2';
                            this.setState({});
                        }
                        if (i === 3) {
                            this.Name[3] = 'Computer3';
                        }
                    }
                    this.m_orez.Player[i] = this.Name[i];
                    this.props.m_pwin.GetPlayerRating(i);
                    return true;
                }
            }
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            let koj = this.props.m_pwin.ReceiveString(1);
            if (koj === '1' || koj === '2' || koj === '3') {
                this.KoiPuskaParvi = this.KoiENaRed = this.KoiZapochva = this._ttoi(koj);
            }
            if (this.anons === 0) {
                this.InitGame(true);
            } else {
                this.InitGame(false);
            }

            if (
                this.watch &&
                ((this.type === 15 && this.brgame > 15) ||
                    (this.type === 9 && this.brgame > 9) ||
                    (this.type === 21 && (this.m_orez.m_rez1 >= 21 || this.m_orez.m_rez2 >= 21 || this.m_orez.m_rez3 >= 21)))
            ) {
                //TODO Fix
                this.InitNewGame();
                this.brgame = 1;
            }
            this.setState({});
            return true;
        }
        if (f === 'c') {
            //Poluchavane na kartite client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rstr = this.props.m_pwin.ReceiveString(3);
            for (let i = 1; i < 53; i++) {
                if (this.k[i] < 1) {
                    if (i === 1) {
                        if (this.CheckMessage()) {
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.ClearAMessages();
                        }
                    }
                    this.k[i] = this._ttoi(rstr);
                    if (i === 52) {
                        this.Razdavane1 = true;
                        this.LoadCards(false, true);
                        this.online_timer = this.online_timer_time;
                        this.setState({});
                    }
                    return true;
                }
            }
            return true;
        }
        if (f === 'd') {
            //Poluchavane na anons
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let annons: string = this.props.m_pwin.ReceiveString(1);
            if (annons === '0' || annons === '1' || annons === '2' || annons === '3' || annons === '4' || annons === '5') {
                this.WriteAMessage(annons);
                await this.Timer2();
            } else {
                this.props.m_pwin.SendErrorToLog('Nevaliden Anons: ' + annons);
            }
            return true;
        }
        if (f === 'e') {
            // Poluchavane na smiana na karti ot talona
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let c1 = this.props.m_pwin.ReceiveString(4);
            let c2 = this.props.m_pwin.ReceiveString(4);
            if (c1 !== '-' && c2 !== '-') {
                let card1 = this._ttoi(c1);
                let card2 = this._ttoi(c2);
                for (let i = 0; i < 4; i++) {
                    if (this.smiana_talon[i][0] <= 0 && this.smiana_talon[i][1] <= 0) {
                        this.smiana_talon[i][0] = card1;
                        this.smiana_talon[i][1] = card2;
                        return true;
                    }
                }
                this.props.m_pwin.SendErrorToLog(
                    'this.KoiENaRed: ' +
                        this.IntToString(this.KoiENaRed) +
                        'Ne e nameren svoboden element ot masiva za smiana ot talona' +
                        ' Smiana na ' +
                        this.IntToString(card1) +
                        ' sas ' +
                        this.IntToString(card2)
                );
            } else {
                this.talon_ready = true;
                this.OtkritTalon = 0;
            }
            return true;
        }
        if (f === 'f') {
            //Poluchavane na karta
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let card = this.props.m_pwin.ReceiveString(4);
            this.WriteMessage(card);
            this.Timer3();
            this.online_timer = this.online_timer_time;
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[3]) {
                        this.P3OP = false;
                        this.P3G = true;
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.wait_opplayers[2] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[3]) {
                        this.wait_opnames[3] = '';
                        this.wait_opplayers[3] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    if (name === this.Name[3]) {
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            this.P3G = true;
            return true;
        }
        if (f === 'j') {
            //Poluchavane na Kazani obiavi
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let sopl = this.props.m_pwin.ReceiveString(1);
            let sob = this.props.m_pwin.ReceiveString(1);
            let sval = this.props.m_pwin.ReceiveString(1);
            let res = false;
            if (sval === '1') {
                res = true;
            }
            this.Obiavi[this._ttoi(sopl)][this._ttoi(sob)] = res;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '3') {
                this.P3OP = false;
            }

            if (strRecvd === '5') {
                this.P2OP = true;
            }
            if (strRecvd === '6') {
                this.P3OP = true;
            }
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            this.setState({});
            return true;
        }
        if (f === 'm') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 4) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let scp = this.props.m_pwin.ReceiveString(1);
            let scolor = this.props.m_pwin.ReceiveString(1);
            let s2pl = this.props.m_pwin.ReceiveString(1);
            let sval = this.props.m_pwin.ReceiveString(1);
            let cp = this._ttoi(scp);
            if (scolor !== '0' && s2pl !== '0' && sval !== '0') {
                this.ChCards[cp][this._ttoi(scolor)][this._ttoi(s2pl)] = this._ttoi(sval);
            } else {
                this.GCPL[cp] = true;
            }
            await this.Timer2();
            return true;
        }
        if (f === 'n') {
            return true;
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2G = false;
                if (this.Faza === 6) {
                    this.P2G = true;
                }
            }
            if (strRecvd === '3') {
                this.P3G = false;
                if (this.Faza === 6) {
                    this.P3G = true;
                }
            }
            if (strRecvd === '5') {
                this.P2G = true;
            }
            if (strRecvd === '6') {
                this.P3G = true;
            }
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let pistr = this.props.m_pwin.ReceiveString(1);
            let rating = this.props.m_pwin.ReceiveString(30);
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let player_index = this._ttoi(pistr);
                if (this.watch) {
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    this.setState({});
                    return true;
                }
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[player_index] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    let title =
                        this.GetLanguageText('Would you like to replace the computer') +
                        ' ( ' +
                        this.Name[player_index] +
                        ' )' +
                        ' ' +
                        this.GetLanguageText('with') +
                        ' ' +
                        this.GetLanguageText('user') +
                        ' ' +
                        player_name +
                        ' / ' +
                        this.GetLanguageText('Rating') +
                        ': ' +
                        rating +
                        '?';
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(title);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.props.m_pwin.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.props.m_pwin.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                } else {
                    message = '$|s|' + pistr + '|0';
                    this.props.m_pwin.SendGame(message);
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        let title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title);
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.props.m_pwin.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane na Igrach po vreme na igra
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please wait for the next deal'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user"));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rez1 = this.props.m_pwin.ReceiveString(5);
            let rez2 = this.props.m_pwin.ReceiveString(5);
            let rez3 = this.props.m_pwin.ReceiveString(5);
            if (this.watch) {
                if (this.IsGameFinished()) {
                    this.m_orez.m_rez1 = 0;
                    this.m_orez.m_rez2 = 0;
                    this.m_orez.m_rez3 = 0;
                    this.setState({});
                    for (let i = 0; i < 3; i++) {
                        for (let a = 0; a < 100; a++) {
                            this.m_orez.orez[i][a] = -10;
                        }
                    }
                    return true;
                } else {
                    for (let a = 0; a < 100; a++) {
                        if (this.m_orez.orez[0][a] === -10) {
                            this.m_orez.orez[0][a] = this._ttoi(rez1);
                            this.m_orez.orez[1][a] = this._ttoi(rez2);
                            this.m_orez.orez[2][a] = this._ttoi(rez3);
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                            this.m_orez.m_rez3 = this.m_orez.m_rez3 + this.m_orez.orez[2][a];
                            this.brgame = a + 2;
                            this.setState({});
                            return true;
                        }
                    }
                }
            } else {
                for (let a = 0; a < 100; a++) {
                    if (this.m_orez.orez[0][a] === -10) {
                        this.m_orez.orez[0][a] = this._ttoi(rez1);
                        this.m_orez.orez[1][a] = this._ttoi(rez2);
                        this.m_orez.orez[2][a] = this._ttoi(rez3);
                        this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                        this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                        this.m_orez.m_rez3 = this.m_orez.m_rez3 + this.m_orez.orez[2][a];
                        this.brgame = a + 1; //???
                        this.m_rez1 = this._ttoi(rez1);
                        this.m_rez2 = this._ttoi(rez2);
                        this.m_rez3 = this._ttoi(rez3);
                        this.LastResult[1] = this.m_orez.orez[0][a];
                        this.LastResult[2] = this.m_orez.orez[1][a];
                        this.LastResult[3] = this.m_orez.orez[2][a];
                        console.log('InitLastrezult:', this.LastResult);
                        this.m_rez.m_rez1 = this.m_rez1;
                        this.m_rez.m_rez2 = this.m_rez2;
                        this.m_rez.m_rez3 = this.m_rez3;
                        this.setState({});
                        return true;
                    }
                }
            }
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index = this._ttoi(pistr);
            let player_name = this.props.m_pwin.ReceiveString(12);
            this.Name[player_index] = player_name;
            this.props.m_pwin.GetPlayerRating(player_index);
            this.setState({});
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        if (f === 'u') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'v') {
            //ReceiveWachGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(1000);
            this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!bla : ' + f);
        return true;
    }

    ReceiveWachGameInitialization(init: string) {
        this.OnLButtonDown(new CPoint(0, 0));
        this.InitGame(false);
        let brrez = 0;
        let vpos = init.indexOf('?');
        let n1: string = init.substring(0, vpos);
        if (n1 !== '') {
            this.Name[1] = n1;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[3] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        if (this.Faza < 0 && this.Faza !== -10 && this.Faza !== -15) {
            this.ReceiveWachGameInitializationEnd();
            return;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Naddavane = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        this.anons = this.Naddavane;

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.hand = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.brgame = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiPuskaParvi = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (this.KoiZapochva === 1) {
            this.limithand[1] = 8;
            this.limithand[2] = 3;
            this.limithand[3] = 5;
        }
        if (this.KoiZapochva === 2) {
            this.limithand[1] = 5;
            this.limithand[2] = 8;
            this.limithand[3] = 3;
        }
        if (this.KoiZapochva === 3) {
            this.limithand[1] = 3;
            this.limithand[2] = 5;
            this.limithand[3] = 8;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P1puska = this._ttoi(s1);
        if (this.P1puska > 0) {
            this.ConvertCard(this.P1puska, 53);
            this.CardsPoint[53].x = this.CardsPos[53].x;
            this.CardsPoint[53].y = this.CardsPos[53].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P2puska = this._ttoi(s1);
        if (this.P2puska > 0) {
            this.ConvertCard(this.P2puska, 54);
            this.CardsPoint[54].x = this.CardsPos[54].x;
            this.CardsPoint[54].y = this.CardsPos[54].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P3puska = this._ttoi(s1);
        if (this.P3puska > 0) {
            this.ConvertCard(this.P3puska, 55);
            this.CardsPoint[55].x = this.CardsPos[55].x;
            this.CardsPoint[55].y = this.CardsPos[55].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ParviPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.VtoriPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.TretiPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.strelka = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mcard = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.invalidate = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.LastResult[1] = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.LastResult[2] = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.LastResult[3] = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez2 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez3 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        this.m_rez.m_rez1 = this.m_rez1;
        this.m_rez.m_rez2 = this.m_rez2;
        this.m_rez.m_rez3 = this.m_rez3;

        if (this.Faza === 3 && this.invalidate === 3) {
            this.invalidate = 0;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane1 = true;
            if (GlobalVariables.Zvuk === 1 && this.Faza <= 4) {
                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
            }
        } else {
            this.Razdavane1 = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Move = true;
        } else {
            this.Move = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.is = true;
        } else {
            this.is = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.talon_ready = true;
        } else {
            this.talon_ready = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.GCPL[1] = true;
        } else {
            this.GCPL[1] = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.GCPL[2] = true;
        } else {
            this.GCPL[2] = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.GCPL[3] = true;
        } else {
            this.GCPL[3] = false;
        }
        init = init.substring(vpos + 1, init.length);

        for (let i = 1; i < 53; i++) {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.k[i] = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].x = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].y = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        brrez = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (brrez > 0) {
            for (let a = 0; a < brrez; a++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez1 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez2 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez3 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                this.m_orez.orez[0][a] = rez1;
                this.m_orez.orez[1][a] = rez2;
                this.m_orez.orez[2][a] = rez3;
            }
        }
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        this.m_orez.m_rez3 = 0;
        for (let a = 0; a < brrez; a++) {
            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
            this.m_orez.m_rez3 = this.m_orez.m_rez3 + this.m_orez.orez[2][a];
        }
        if (this.Faza > 4) {
            this.LoadCards(true, false);
        } else {
            this.LoadCards(false, false);
        }

        if (this.Faza === 0 && this.Mcard > 0 && this.Razdavane1 && this.Move) {
            this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
        } else {
            if (this.Move) {
                if (this.Mcard > 0) {
                    this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
                }
            }
        }
        this.ReceiveWachGameInitializationEnd();
    }

    ReceiveWachGameInitializationEnd() {
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.props.m_pwin.GetPlayerRating(3);
        this.setState({});
    }

    IsGameFinished() {
        if ((this.type === 15 && this.brgame === 15) || (this.type === 9 && this.brgame === 9)) {
            return true;
        }
        if (this.type === 21) {
            if (
                (this.m_orez.m_rez1 >= 21 && this.m_orez.m_rez1 > this.m_orez.m_rez2 && this.m_orez.m_rez1 > this.m_orez.m_rez3) ||
                (this.m_orez.m_rez2 >= 21 && this.m_orez.m_rez2 > this.m_orez.m_rez1 && this.m_orez.m_rez2 > this.m_orez.m_rez3) ||
                (this.m_orez.m_rez3 >= 21 && this.m_orez.m_rez3 > this.m_orez.m_rez1 && this.m_orez.m_rez3 > this.m_orez.m_rez2)
            ) {
                return true;
            }
        }
        return false;
    }

    SendWatchGameInitialization(watch_index: number) {
        let message = '$|z|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[1] + '?' + this.Name[2] + '?' + this.Name[3] + '?';
        //this.Faza
        message = message + this.IntToString(this.Faza) + '?';
        message = message + this.IntToString(this.Naddavane) + '?';
        message = message + this.IntToString(this.hand) + '?';
        message = message + this.IntToString(this.brgame) + '?';
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.KoiPuskaParvi) + '?';
        message = message + this.IntToString(this.KoiZapochva) + '?';
        message = message + this.IntToString(this.P1puska) + '?';
        message = message + this.IntToString(this.P2puska) + '?';
        message = message + this.IntToString(this.P3puska) + '?';

        message = message + this.IntToString(this.ParviPuska) + '?';
        message = message + this.IntToString(this.VtoriPuska) + '?';
        message = message + this.IntToString(this.TretiPuska) + '?';
        message = message + this.IntToString(this.strelka) + '?';
        message = message + this.IntToString(this.Mcard) + '?';
        message = message + this.IntToString(this.invalidate) + '?';
        message = message + this.IntToString(this.LastResult[1]) + '?';
        message = message + this.IntToString(this.LastResult[2]) + '?';
        message = message + this.IntToString(this.LastResult[3]) + '?';
        message = message + this.IntToString(this.m_rez1) + '?';
        message = message + this.IntToString(this.m_rez2) + '?';
        message = message + this.IntToString(this.m_rez3) + '?';

        if (this.Razdavane1) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }

        if (this.Move) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.is) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.talon_ready) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.GCPL[1]) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.GCPL[2]) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.GCPL[3]) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        for (let i = 1; i < 53; i++) {
            message =
                message +
                this.IntToString(this.k[i]) +
                '?' +
                this.IntToString(this.CardsPoint[i].x) +
                '?' +
                this.IntToString(this.CardsPoint[i].y) +
                '?';
        }
        let brrez = '0';
        let mrez = '';
        for (let a = 0; a < 100; a++) {
            if (this.m_orez.orez[0][a] > -10) {
                brrez = this.IntToString(a + 1);
                mrez = mrez + this.IntToString(this.m_orez.orez[0][a]) + '?';
                mrez = mrez + this.IntToString(this.m_orez.orez[1][a]) + '?';
                mrez = mrez + this.IntToString(this.m_orez.orez[2][a]) + '?';
            } else {
                break;
            }
        }
        message = message + brrez + '?' + mrez + '?';
        if (message !== '') {
            this.props.m_pwin.SendGame(message);
        }
    }

    SortCards(sortType: number) {
        let sort: number[] = [];
        let temps: number;
        let tempk: number;
        let tempc: string;
        if (sortType === 0) {
            for (let i = 1; i < 17; i++) {
                for (let a = 1; a < 16; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 17; i < 33; i++) {
                for (let a = 17; a < 32; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 33; i < 49; i++) {
                for (let a = 33; a < 48; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (sortType === 1) {
            for (let i = 1; i < 17; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 1; i < 17; i++) {
                for (let a = 1; a < 16; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 17; i < 33; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 17; i < 33; i++) {
                for (let a = 17; a < 32; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 33; i < 49; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 33; i < 49; i++) {
                for (let a = 33; a < 48; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 49; i < 53; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 49; i < 53; i++) {
                for (let a = 49; a < 52; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (sortType === 2) {
            for (let i = 1; i < 17; i++) {
                sort[i] = this.k[i];
            }
            for (let i = 1; i < 17; i++) {
                for (let a = 1; a < 16; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
    }

    ReturnSortCards(card: number) {
        if (this.Naddavane === 1) {
            if (card > 100 && card < 200) {
                return card + 900;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 2) {
            if (card > 200 && card < 300) {
                return card + 800;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 3) {
            if (card > 300 && card < 400) {
                return card + 700;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 4) {
            if (card > 400 && card < 500) {
                return card + 600;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 5) {
            return card;
        }
        return 0;
    }

    InitVziatka() {
        if (this.ParviPuska > 1000) {
            this.ParviPuska = (this.ParviPuska % 100) + this.Naddavane * 100;
        }
        if (this.VtoriPuska > 1000) {
            this.VtoriPuska = (this.VtoriPuska % 100) + this.Naddavane * 100;
        }
        if (this.ParviPuska > 1000) {
            this.VtoriPuska = (this.VtoriPuska % 100) + this.Naddavane * 100;
        }
        if (this.KoiPuskaParvi === 1) {
            if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[2][Math.floor(this.ParviPuska / 100)] = true;
            }
            if (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)] = true;
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[3][Math.floor(this.ParviPuska / 100)] = true;
            }
            if (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)] = true;
            }
        }
        if (this.KoiPuskaParvi === 3) {
            if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[1][Math.floor(this.ParviPuska / 100)] = true;
            }
            if (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                this.NiamaBoia[2][Math.floor(this.ParviPuska / 100)] = true;
            }
        }
        for (let i = 1; i < 5; i++) {
            //proveriava dali niakoia ot sigurnite karti e igrana
            for (let j = 0; j < this.SureCardsBr[i]; j++) {
                if (
                    this.SureCards[i][j][0] === this.ParviPuska ||
                    this.SureCards[i][j][0] === this.VtoriPuska ||
                    this.SureCards[i][j][0] === this.TretiPuska
                ) {
                    for (let u = j; u < this.SureCardsBr[i]; u++) {
                        this.SureCards[i][u][0] = this.SureCards[i][u + 1][0];
                        this.SureCards[i][u][1] = this.SureCards[i][u + 1][1];
                        this.SureCardsBr[i]--;
                    }
                    j--;
                }
            }
        }
        this.KoiENaRed = this.KoiPuskaParvi = this.strelka;
        this.strelka = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.online_timer = this.online_timer_time;
    }

    StartChangeCards() {
        let start = this.KoiZapochva;
        if (start < 3) {
            start++;
        } else {
            start = 1;
        }
        for (
            let i = 1;
            i < 5;
            i++ //razcukva kartite
        ) {
            for (let p = start; p < 4; p++) {
                while (this.ChCards[1][i][p] + this.ChCards[2][i][p] + this.ChCards[3][i][p] > 0) {
                    let Pl = this.KoiZapochva;
                    for (let j = 0; j < 3; j++) {
                        if (this.ChCards[Pl][i][p] > 0) {
                            this.ChangeCards(Pl, p, i);
                            this.ChCards[Pl][i][p]--;
                        }
                        if (Pl < 3) {
                            Pl++;
                        } else {
                            Pl = 1;
                        }
                    }
                }
            }
            if (start > 1) {
                for (let p = 1; p < start; p++) {
                    while (this.ChCards[1][i][p] + this.ChCards[2][i][p] + this.ChCards[3][i][p] > 0) {
                        let Pl = this.KoiZapochva;
                        for (let j = 0; j < 3; j++) {
                            if (this.ChCards[Pl][i][p] > 0) {
                                this.ChangeCards(Pl, p, i);
                                this.ChCards[Pl][i][p]--;
                            }
                            if (Pl < 3) {
                                Pl++;
                            } else {
                                Pl = 1;
                            }
                        }
                    }
                }
            }
        }
        this.setState({});
        this.Faza = 3;
        this.invalidate = 2;
    }

    Strelka() {
        this.hand = this.hand + 1;
        if (this.P1puska > 1000 || this.P2puska > 1000 || this.P3puska > 1000) {
            if (this.P1puska > this.P2puska && this.P1puska > this.P3puska) {
                return 1;
            }
            if (this.P2puska > this.P1puska && this.P2puska > this.P3puska) {
                return 2;
            }
            if (this.P3puska > this.P2puska && this.P3puska > this.P1puska) {
                return 3;
            }
        }
        //Ako  se iska pika
        if (this.ParviPuska > 400 && this.ParviPuska < 500) {
            for (let i = 414; i > 401; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
            }
        }
        //Ako se iska kupa
        if (this.ParviPuska > 300 && this.ParviPuska < 400) {
            for (let i = 314; i > 301; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
            }
        }
        if (this.ParviPuska > 200 && this.ParviPuska < 300) {
            for (let i = 214; i > 201; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
            }
        }
        if (this.ParviPuska > 100 && this.ParviPuska < 200) {
            for (let i = 114; i > 101; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
            }
        }
        return 1;
    }

    ChangeCards(Player1: number, Player2: number, Color: number) {
        let AllCardsPL1C: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let AllCardsPL1CBr: number = 0;
        let AllCardsPL2C: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let AllCardsPL2CBr: number = 0;
        if (Color === this.Naddavane) {
            Color = 10;
        }
        let MinCardP1: number = 10000;
        let MaxCardP2: number = 0;
        for (let i = (Player1 - 1) * 16 + 1; i <= Player1 * 16; i++) {
            if (Math.floor(this.k[i] / 100) === Color) {
                AllCardsPL1C[AllCardsPL1CBr] = this.k[i];
                AllCardsPL1CBr++;
                if (this.k[i] < MinCardP1) {
                    MinCardP1 = this.k[i];
                }
            }
        }
        for (let i = (Player2 - 1) * 16 + 1; i <= Player2 * 16; i++) {
            if (Math.floor(this.k[i] / 100) === Color) {
                AllCardsPL2C[AllCardsPL2CBr] = this.k[i];
                AllCardsPL2CBr++;
                if (this.k[i] > MaxCardP2) {
                    MaxCardP2 = this.k[i];
                }
            }
        }
        if (MaxCardP2 > MinCardP1) {
            for (let i = (Player1 - 1) * 16 + 1; i <= Player1 * 16; i++) {
                if (MinCardP1 === this.k[i]) {
                    for (let j = (Player2 - 1) * 16 + 1; j <= Player2 * 16; j++) {
                        if (MaxCardP2 === this.k[j]) {
                            let t = this.k[i];
                            this.k[i] = this.k[j];
                            this.k[j] = t;
                            let temp: string;
                            temp = this.CardP[i].image;
                            this.CardP[i].image = this.CardP[j].image;
                            this.CardP[j].image = temp;
                            if (Color < 10) {
                                this.SureCards[Color][this.SureCardsBr[Color]][0] = this.k[i];
                                this.SureCards[Color][this.SureCardsBr[Color]][1] = Player1;
                                this.SureCardsBr[Color]++;
                                this.SureCards[Color][this.SureCardsBr[Color]][0] = this.k[j];
                                this.SureCards[Color][this.SureCardsBr[Color]][1] = Player2;
                                this.SureCardsBr[Color]++;
                            }
                            if (Color === 10) {
                                this.SureCards[Color][this.SureCardsBr[this.Naddavane]][0] = (this.k[i] % 100) + this.Naddavane * 100;
                                this.SureCards[Color][this.SureCardsBr[this.Naddavane]][1] = Player1;
                                this.SureCardsBr[Color]++;
                                this.SureCards[Color][this.SureCardsBr[this.Naddavane]][0] = (this.k[j] % 100) + this.Naddavane * 100;
                                this.SureCards[Color][this.SureCardsBr[this.Naddavane]][1] = Player2;
                                this.SureCardsBr[Color]++;
                            }
                        }
                    }
                }
            }
            return true;
        } else {
            return false;
        }
    }

    KartaZaRazdavane() {
        if (this.KoiZapochva === 1) {
            this.showrcard = 51;
            if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                return 1;
            }
            this.showrcard = 50;
            if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                return 17;
            }
            this.showrcard = 49;
            if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                return 33;
            }
            this.showrcard = 48;
            if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                return 2;
            }
            this.showrcard = 47;
            if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                return 18;
            }
            this.showrcard = 46;
            if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                return 34;
            }
            this.showrcard = 45;
            if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                return 3;
            }
            this.showrcard = 44;
            if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                return 19;
            }
            this.showrcard = 43;
            if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                return 35;
            }
            this.showrcard = 42;
            if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                return 4;
            }
            this.showrcard = 41;
            if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                return 20;
            }
            this.showrcard = 40;
            if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                return 36;
            }
            this.showrcard = 39;
            if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                return 5;
            }
            this.showrcard = 38;
            if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                return 21;
            }
            this.showrcard = 37;
            if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                return 37;
            }
            this.showrcard = 36;
            if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                return 6;
            }
            this.showrcard = 35;
            if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                return 22;
            }
            this.showrcard = 34;
            if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                return 38;
            }
            this.showrcard = 33;
            if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                return 7;
            }
            this.showrcard = 32;
            if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                return 23;
            }
            this.showrcard = 31;
            if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                return 39;
            }
            this.showrcard = 30;
            if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                return 8;
            }
            this.showrcard = 29;
            if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                return 24;
            }
            this.showrcard = 28;
            if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                return 40;
            }
            this.showrcard = 27;
            if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                return 9;
            }
            this.showrcard = 26;
            if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                return 25;
            }
            this.showrcard = 25;
            if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                return 41;
            }
            this.showrcard = 24;
            if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                return 10;
            }
            this.showrcard = 23;
            if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                return 26;
            }
            this.showrcard = 22;
            if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                return 42;
            }
            this.showrcard = 21;
            if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                return 11;
            }
            this.showrcard = 20;
            if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                return 27;
            }
            this.showrcard = 19;
            if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                return 43;
            }
            this.showrcard = 18;
            if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                return 12;
            }
            this.showrcard = 17;
            if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                return 28;
            }
            this.showrcard = 16;
            if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                return 44;
            }
            this.showrcard = 15;
            if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                return 13;
            }
            this.showrcard = 14;
            if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                return 29;
            }
            this.showrcard = 13;
            if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                return 45;
            }
            this.showrcard = 12;
            if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                return 14;
            }
            this.showrcard = 11;
            if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                return 30;
            }
            this.showrcard = 10;
            if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                return 46;
            }
            this.showrcard = 9;
            if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                return 15;
            }
            this.showrcard = 8;
            if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                return 31;
            }
            this.showrcard = 7;
            if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                return 47;
            }
            this.showrcard = 6;
            if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                return 16;
            }
            this.showrcard = 5;
            if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                return 32;
            }
            this.showrcard = 4;
            if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                return 48;
            }
            this.showrcard = 3;
            if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                return 49;
            }
            this.showrcard = 2;
            if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                return 50;
            }
            this.showrcard = 1;
            if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                return 51;
            }
            this.showrcard = 0;
            this.Razdavane1 = false;
            this.Faza = 1;
            this.online_timer = this.online_timer_time;
            if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                return 52;
            }
        }
        if (this.KoiZapochva === 2) {
            this.showrcard = 51;
            if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                return 17;
            }
            this.showrcard = 50;
            if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                return 33;
            }
            this.showrcard = 49;
            if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                return 1;
            }
            this.showrcard = 48;
            if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                return 18;
            }
            this.showrcard = 47;
            if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                return 34;
            }
            this.showrcard = 46;
            if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                return 2;
            }
            this.showrcard = 45;
            if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                return 19;
            }
            this.showrcard = 44;
            if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                return 35;
            }
            this.showrcard = 43;
            if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                return 3;
            }
            this.showrcard = 42;
            if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                return 20;
            }
            this.showrcard = 41;
            if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                return 36;
            }
            this.showrcard = 40;
            if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                return 4;
            }
            this.showrcard = 39;
            if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                return 21;
            }
            this.showrcard = 38;
            if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                return 37;
            }
            this.showrcard = 37;
            if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                return 5;
            }
            this.showrcard = 36;
            if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                return 22;
            }
            this.showrcard = 35;
            if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                return 38;
            }
            this.showrcard = 34;
            if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                return 6;
            }
            this.showrcard = 33;
            if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                return 23;
            }
            this.showrcard = 32;
            if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                return 39;
            }
            this.showrcard = 31;
            if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                return 7;
            }
            this.showrcard = 30;
            if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                return 24;
            }
            this.showrcard = 29;
            if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                return 40;
            }
            this.showrcard = 28;
            if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                return 8;
            }
            this.showrcard = 27;
            if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                return 25;
            }
            this.showrcard = 26;
            if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                return 41;
            }
            this.showrcard = 25;
            if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                return 9;
            }
            this.showrcard = 24;
            if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                return 26;
            }
            this.showrcard = 23;
            if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                return 42;
            }
            this.showrcard = 22;
            if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                return 10;
            }
            this.showrcard = 21;
            if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                return 27;
            }
            this.showrcard = 20;
            if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                return 43;
            }
            this.showrcard = 19;
            if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                return 11;
            }
            this.showrcard = 18;
            if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                return 28;
            }
            this.showrcard = 17;
            if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                return 44;
            }
            this.showrcard = 16;
            if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                return 12;
            }
            this.showrcard = 15;
            if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                return 29;
            }
            this.showrcard = 14;
            if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                return 45;
            }
            this.showrcard = 13;
            if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                return 13;
            }
            this.showrcard = 12;
            if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                return 30;
            }
            this.showrcard = 11;
            if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                return 46;
            }
            this.showrcard = 10;
            if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                return 14;
            }
            this.showrcard = 9;
            if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                return 31;
            }
            this.showrcard = 8;
            if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                return 47;
            }
            this.showrcard = 7;
            if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                return 15;
            }
            this.showrcard = 6;
            if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                return 32;
            }
            this.showrcard = 5;
            if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                return 48;
            }
            this.showrcard = 4;
            if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                return 16;
            }
            this.showrcard = 3;
            if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                return 49;
            }
            this.showrcard = 2;
            if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                return 50;
            }
            this.showrcard = 1;
            if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                return 51;
            }
            this.showrcard = 0;
            this.Razdavane1 = false;
            this.Faza = 1;
            this.online_timer = this.online_timer_time;
            if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                return 52;
            }
        }
        if (this.KoiZapochva === 3) {
            this.showrcard = 51;
            if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                return 33;
            }
            this.showrcard = 50;
            if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                return 1;
            }
            this.showrcard = 49;
            if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                return 17;
            }
            this.showrcard = 48;
            if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                return 34;
            }
            this.showrcard = 47;
            if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                return 2;
            }
            this.showrcard = 46;
            if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                return 18;
            }
            this.showrcard = 45;
            if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                return 35;
            }
            this.showrcard = 44;
            if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                return 3;
            }
            this.showrcard = 43;
            if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                return 19;
            }
            this.showrcard = 42;
            if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                return 36;
            }
            this.showrcard = 41;
            if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                return 4;
            }
            this.showrcard = 40;
            if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                return 20;
            }
            this.showrcard = 39;
            if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                return 37;
            }
            this.showrcard = 38;
            if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                return 5;
            }
            this.showrcard = 37;
            if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                return 21;
            }
            this.showrcard = 36;
            if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                return 38;
            }
            this.showrcard = 35;
            if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                return 6;
            }
            this.showrcard = 34;
            if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                return 22;
            }
            this.showrcard = 33;
            if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                return 39;
            }
            this.showrcard = 32;
            if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                return 7;
            }
            this.showrcard = 31;
            if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                return 23;
            }
            this.showrcard = 30;
            if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                return 40;
            }
            this.showrcard = 29;
            if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                return 8;
            }
            this.showrcard = 28;
            if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                return 24;
            }
            this.showrcard = 27;
            if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                return 41;
            }
            this.showrcard = 26;
            if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                return 9;
            }
            this.showrcard = 25;
            if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                return 25;
            }
            this.showrcard = 24;
            if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                return 42;
            }
            this.showrcard = 23;
            if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                return 10;
            }
            this.showrcard = 22;
            if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                return 26;
            }
            this.showrcard = 21;
            if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                return 43;
            }
            this.showrcard = 20;
            if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                return 11;
            }
            this.showrcard = 19;
            if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                return 27;
            }
            this.showrcard = 18;
            if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                return 44;
            }
            this.showrcard = 17;
            if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                return 12;
            }
            this.showrcard = 16;
            if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                return 28;
            }
            this.showrcard = 15;
            if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                return 45;
            }
            this.showrcard = 14;
            if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                return 13;
            }
            this.showrcard = 13;
            if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                return 29;
            }
            this.showrcard = 12;
            if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                return 46;
            }
            this.showrcard = 11;
            if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                return 14;
            }
            this.showrcard = 10;
            if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                return 30;
            }
            this.showrcard = 9;
            if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                return 47;
            }
            this.showrcard = 8;
            if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                return 15;
            }
            this.showrcard = 7;
            if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                return 31;
            }
            this.showrcard = 6;
            if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                return 48;
            }
            this.showrcard = 5;
            if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                return 16;
            }
            this.showrcard = 4;
            if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                return 32;
            }
            this.showrcard = 3;
            if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                return 49;
            }
            this.showrcard = 2;
            if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                return 50;
            }
            this.showrcard = 1;
            if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                return 51;
            }
            this.showrcard = 0;
            this.Razdavane1 = false;
            this.Faza = 1;
            this.online_timer = this.online_timer_time;
            if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                return 52;
            }
        }
        return 0;
    }

    ProverkaCursor(card: number) {
        //	return true;
        if (this.Faza < 5) {
            return false;
        }
        if (this.Move === true || this.KoiENaRed !== 1) {
            return false;
        }
        if (this.KoiPuskaParvi === 1) {
            return true;
        }
        //////////////////////////////////////////////////////////
        if (this.ParviPuska > 100 && this.ParviPuska < 200) {
            if (this.k[card] > 200) {
                for (let i = 1; i < 17 - this.hand; i++) {
                    if (this.k[i] > 100 && this.k[i] < 200) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 200 && this.ParviPuska < 300) {
            if (this.k[card] < 200 || this.k[card] > 300) {
                for (let i = 1; i < 17 - this.hand; i++) {
                    if (this.k[i] > 200 && this.k[i] < 300) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 300 && this.ParviPuska < 400) {
            if (this.k[card] < 300 || this.k[card] > 400) {
                for (let i = 1; i < 17 - this.hand; i++) {
                    if (this.k[i] > 300 && this.k[i] < 400) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 400 && this.ParviPuska < 500) {
            if (this.k[card] < 400 || this.k[card] > 500) {
                for (let i = 1; i < 17 - this.hand; i++) {
                    if (this.k[i] > 400 && this.k[i] < 500) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 1000 && this.ParviPuska < 1100) {
            if (this.k[card] < 1000 || this.k[card] > 1100) {
                for (let i = 1; i < 17 - this.hand; i++) {
                    if (this.k[i] > 1000 && this.k[i] < 1100) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    Pusk1KartaZaPuskanePl1() {
        let kzp = 0;
        let pk = 0;
        for (let i = 1; i <= 16 - this.hand; i++) {
            if (this.ProverkaCursor(i)) {
                kzp = kzp + 1;
                if (kzp === 1) {
                    pk = i;
                }
                if (kzp > 1) {
                    return;
                }
            }
        }
        if (kzp === 1) {
            if (pk > 0 && this.k[pk] > 0 && this.ProverkaCursor(pk)) {
                if (this.SP) {
                    this.PuskaneNaKarta(pk);
                }
                if (this.OP) {
                    this.PuskaneNaKartaOP(pk);
                }
                return;
            }
        }
    }

    PuskaneNaKartaOP(card: number) {
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            if (this.KoiENaRed === 1) {
                if (this.Server === true) {
                    if (this.P2OP !== true) {
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    }
                    this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
                } else {
                    this.props.m_pwin.SendGame('$|l|' + this.IntToString(this.k[card]));
                }
                this.PuskaneNaKarta(card);
            }
        }
    }

    PuskaneNaKarta(card: number) {
        let player = 1;
        if (card > 16 && card < 33) {
            player = 2;
        }
        if (card > 32 && card < 49) {
            player = 3;
        }
        if (this.k[card] === 0) {
            this.props.m_pwin.SendErrorToLog('PuskaneNaKarta this.k[card]==0');
            for (let i = (player - 1) * 16 + 1; i <= player * 16; i++) {
                if (this.AviablePuskaneNaKarta(i, player)) {
                    card = i;
                    break;
                }
            }
        }
        let kdcard = this.k[card];
        if (this.k[card] > 1000) {
            kdcard = this.k[card] - 1000 + this.Naddavane * 100;
        }
        for (let i = 1; i < 49; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                break;
            }
        }
        if (player === 1) {
            if (this.KoiPuskaParvi === 1) {
                this.ParviPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 2) {
                this.TretiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 3) {
                this.VtoriPuska = this.k[card];
            }
            this.P1puska = this.k[card];
            this.CardsPoint[53].x = this.CardsPoint[card].x;
            this.CardsPoint[53].y = this.CardsPoint[card].y;
            this.CardP[53].image = this.CardP[card].image;
            this.BeginMove(this.CardsPoint[53], this.CardsPos[53], 53);
            if (this.SP === true) {
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
            }
            if (this.KoiPuskaParvi > 1) {
                if (this.ParviPuska > 100 && this.ParviPuska < 200 && this.k[card] > 200) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska > 200 && this.ParviPuska < 300 && (this.k[card] < 200 || this.k[card] > 300)) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska > 300 && this.ParviPuska < 400 && (this.k[card] < 300 || this.k[card] > 400)) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska > 400 && this.ParviPuska < 500 && (this.k[card] < 400 || this.k[card] > 500)) {
                    this.Pniama[1][4] = true;
                }
                if (this.ParviPuska > 1000 && this.k[card] < 1000) {
                    this.Pniama[1][this.Naddavane] = true;
                }
            }
        } else {
            if (player !== this.KoiPuskaParvi) {
                if (this.ParviPuska > 100 && this.ParviPuska < 200 && this.k[card] > 200) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska > 200 && this.ParviPuska < 300 && (this.k[card] < 200 || this.k[card] > 300)) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska > 300 && this.ParviPuska < 400 && (this.k[card] < 300 || this.k[card] > 400)) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska > 400 && this.ParviPuska < 500 && (this.k[card] < 400 || this.k[card] > 500)) {
                    this.Pniama[player][4] = true;
                }
                if (this.ParviPuska > 1000 && this.k[card] < 1000) {
                    this.Pniama[player][this.Naddavane] = true;
                }
            }
            if (player === 2) {
                this.P2puska = this.k[card];
            }
            if (player === 3) {
                this.P3puska = this.k[card];
            }
            this.CardsPoint[player + 52].x = this.CardsPoint[card].x;
            this.CardsPoint[player + 52].y = this.CardsPoint[card].y;
            this.CardP[52 + this.KoiENaRed].image = this.CardP[card].image;
            this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 52], 52 + this.KoiENaRed);
            if (player === 1) {
                this.P1puska = this.k[card];
            }
            if (player === 2) {
                this.P2puska = this.k[card];
            }
            if (player === 3) {
                this.P3puska = this.k[card];
            }
            if (this.KoiPuskaParvi === 1) {
                if (player === 1) {
                    this.ParviPuska = this.k[card];
                }
                if (player === 2) {
                    this.VtoriPuska = this.k[card];
                }
                if (player === 3) {
                    this.TretiPuska = this.k[card];
                }
            }
            if (this.KoiPuskaParvi === 2) {
                if (player === 2) {
                    this.ParviPuska = this.k[card];
                }
                if (player === 3) {
                    this.VtoriPuska = this.k[card];
                }
                if (player === 1) {
                    this.TretiPuska = this.k[card];
                }
            }
            if (this.KoiPuskaParvi === 3) {
                if (player === 3) {
                    this.ParviPuska = this.k[card];
                }
                if (player === 1) {
                    this.VtoriPuska = this.k[card];
                }
                if (player === 2) {
                    this.TretiPuska = this.k[card];
                }
            }
        }
        this.DeleteCard(card);
        if (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0) {
            if (this.KoiENaRed <= 2) {
                this.KoiENaRed = this.KoiENaRed + 1;
            } else {
                this.KoiENaRed = 1;
            }
        }
        this.setState({});
    }

    AviablePuskaneNaKarta(card: number, player: number) {
        if (this.ParviPuska === 0) {
            if (this.k[card] === 0) {
                this.props.m_pwin.SendErrorToLog('this.k[card]==0 - 1');
                return false;
            }
            return true;
        } else {
            let HaveCard = false;
            if (this.k[card] === 0) {
                this.props.m_pwin.SendErrorToLog('this.k[card]==0 - 2');
                return false;
            }
            let hc = 0;
            for (let i = (player - 1) * 16 + 1; i <= player * 16; i++) {
                if (Math.floor(this.ParviPuska / 100) === Math.floor(this.k[i] / 100)) {
                    HaveCard = true;
                    hc = this.k[i];
                }
            }
            if (HaveCard) {
                if (Math.floor(this.ParviPuska / 100) === Math.floor(this.k[card] / 100)) {
                    return true;
                } else {
                    this.props.m_pwin.SendErrorToLog(
                        '!PP/100, PP : ' +
                            this.IntToString(this.ParviPuska) +
                            ' this.k[card] : ' +
                            this.IntToString(this.k[card]) +
                            ', hc: ' +
                            this.IntToString(hc) +
                            ' ,card : ' +
                            this.IntToString(card) +
                            ',pl:' +
                            this.IntToString(player)
                    );
                    return false;
                }
            } else {
                return true;
            }
        }
    }

    PuskaneNaKartaN(Num: number, player: number) {
        for (let i = (player - 1) * 16 + 1; i <= player * 16; i++) {
            if (Num === this.k[i]) {
                if (this.AviablePuskaneNaKarta(i, player)) {
                } else {
                    this.props.m_pwin.SendErrorToLog(
                        'NotAviablePuskaneNaKarta - player: ' + this.IntToString(player) + ' this.k[i]=' + this.IntToString(this.k[i])
                    );
                }
                this.PuskaneNaKarta(i);
                this.online_timer = this.online_timer_time;
                return true;
            }
        }
        this.props.m_pwin.SendErrorToLog('Dont find Card : ' + this.IntToString(Num) + ' player : ' + this.IntToString(player));
        return false;
    }

    GetPlayerPosition() {
        let pos = 1;
        if (this.m_orez.m_rez1 < this.m_orez.m_rez2) {
            pos = pos + 1;
        }
        if (this.m_orez.m_rez1 < this.m_orez.m_rez3) {
            pos = pos + 1;
        }
        return pos;
    }

    OnRButtonDown(point: CPoint) {
        if (this.watch) {
            for (let i = 2; i <= 3; i++) {
                if (this.Name[i] === 'Computer2' || this.Name[i] === 'Computer3') {
                    let s: CSize = this.GetTextExtent(this.Name[i], 13);
                    let width = s.cx;
                    if (
                        this.ScaleRect(new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)).PtInRect(
                            point
                        )
                    ) {
                        this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                        this.props.m_pwin.GoToLobby();
                    }
                }
            }
        }
    }

    DrawTimer() {
        let res = [];
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server)) {
                if (
                    (this.OP && this.Faza >= 2 && !this.Move && (this.KoiENaRed === 1 || this.Faza > 5)) ||
                    (this.Faza === -15 && !this.is && this.props.m_pwin.state.CBlatoGetCardsVisible)
                ) {
                    res.push(this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer'));
                }
            }
            if (this.Faza === 5 && this.strelka < 1 && this.KoiENaRed !== 1 && !this.Move) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    res.push(
                        this.DrawBorderText(
                            this.GetLanguageText('Pause') + ': ' + this.pause_player,
                            this.pause_rect.left,
                            this.pause_rect.top,
                            'DrawTimerPause'
                        )
                    );
                }
            }
        }
        return res;
    }

    DrawPause() {
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server)) {
                if (
                    (this.OP && this.Faza >= 2 && !this.Move && (this.KoiENaRed === 1 || this.Faza > 5)) ||
                    (this.Faza === -15 && !this.is && this.props.m_pwin.state.CBlatoGetCardsVisible)
                ) {
                    if (this.pause === 0 && this.Faza === 5) {
                        return (
                            <CPicture
                                image={this.p_bmp}
                                m_x={this.pause_rect.left}
                                m_y={this.pause_rect.top}
                                m_Width={20}
                                m_Height={20}
                                cursor={this.cursor}
                                key={'pause'}
                            />
                        );
                    }
                }
            }
        }
    }

    Timer1() {
        if (this.SP) {
            if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0) {
                this.strelka = this.Strelka();
                this.vz[this.hand] = this.strelka;
                if (this.strelka === 1) {
                    this.m_rez1 = this.m_rez1 + 1;
                }
                if (this.strelka === 2) {
                    this.m_rez2 = this.m_rez2 + 1;
                }
                if (this.strelka === 3) {
                    this.m_rez3 = this.m_rez3 + 1;
                }
                this.setState({});
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
            }
            if (this.Move === true) {
                this.MoveCard(!this.Razdavane1);
                this.setState({});
            }
            if (this.Razdavane1 && !this.Move && this.Faza < 2 && this.Faza !== -15 && this.Faza !== -10) {
                let karta = this.KartaZaRazdavane();
                this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                this.setState({});
            }
            if (!this.Razdavane1 && !this.Move && this.Faza === 1) {
                this.Faza = 2;
                this.SortCards(0);
                this.setState({});
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            }
        }
        if (this.OP) {
            if (this.Server) {
                if (this.Faza === 6 && this.P2G && this.P3G) {
                    if (this.P2OP === true) {
                        this.P2G = false;
                    }
                    if (this.P3OP === true) {
                        this.P3G = false;
                    }
                    this.Razdavane1 = true;
                    this.InitGame(false);
                    this.SendKoiZapochva();
                    this.SendCards();
                    this.setState({});
                    this.online_timer = this.online_timer_time;
                }
                if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0) {
                    this.strelka = this.Strelka();
                    this.vz[this.hand] = this.strelka;
                    if (this.strelka === 1) {
                        this.m_rez1 = this.m_rez1 + 1;
                    }
                    if (this.strelka === 2) {
                        this.m_rez2 = this.m_rez2 + 1;
                    }
                    if (this.strelka === 3) {
                        this.m_rez3 = this.m_rez3 + 1;
                    }
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1);
                    this.setState({});
                }
                if (this.Razdavane1 && !this.Move && this.Faza < 2 && this.Faza !== -15 && this.Faza !== -10) {
                    let karta = this.KartaZaRazdavane();
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                    this.setState({});
                }
                if (!this.Razdavane1 && !this.Move && this.Faza === 1) {
                    this.Faza = 2;
                    this.online_timer = this.online_timer_time;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
            } else {
                if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0) {
                    this.strelka = this.Strelka();
                    this.vz[this.hand] = this.strelka;
                    if (this.strelka === 1) {
                        this.m_rez1 = this.m_rez1 + 1;
                    }
                    if (this.strelka === 2) {
                        this.m_rez2 = this.m_rez2 + 1;
                    }
                    if (this.strelka === 3) {
                        this.m_rez3 = this.m_rez3 + 1;
                    }
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1);
                    this.setState({});
                }
                if (this.Razdavane1 && !this.Move && this.Faza < 2 && this.Faza !== -15 && this.Faza !== -10) {
                    let karta = this.KartaZaRazdavane();
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                    this.setState({});
                }
                if (!this.Razdavane1 && !this.Move && this.Faza === 1) {
                    this.Faza = 2;
                    this.online_timer = this.online_timer_time;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
            }
        }
    }

    Timer3() {
        if (!this.Move) {
            if (this.SP) {
                if (
                    this.Faza > 4 &&
                    this.strelka < 1 &&
                    this.KoiENaRed !== 1 &&
                    this.hand < 16 &&
                    (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0)
                ) {
                    this.Intelect(this.KoiENaRed);
                    this.setState({});
                    return;
                }
                if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 1 && this.hand < 16 && this.P1puska === 0 && !this.Move) {
                    if (GlobalVariables.automatic_release === true) {
                        this.Pusk1KartaZaPuskanePl1();
                    }
                    return;
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.KoiENaRed !== 1 &&
                        this.hand < 16 &&
                        (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0)
                    ) {
                        if (
                            ((this.KoiENaRed === 2 && this.P2OP && this.P2puska === 0) ||
                                (this.KoiENaRed === 3 && this.P3OP && this.P3puska === 0)) &&
                            this.CheckMessage()
                        ) {
                            let card = this._ttoi(this.ReadMessage());
                            if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                            } else {
                            }
                            return;
                        }
                        if (this.KoiENaRed === 2 && this.P2OP !== true) {
                            this.Intelect(this.KoiENaRed);
                            this.setState({});
                            return;
                        }
                        if (this.KoiENaRed === 3 && this.P3OP !== true) {
                            this.Intelect(this.KoiENaRed);
                            this.setState({});
                            return;
                        }
                    }
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.hand < 16 &&
                        this.P1puska === 0 &&
                        !this.Move &&
                        this.Faza < 6 &&
                        this.KoiENaRed === 1
                    ) {
                        if (this.online_timer === 0) {
                            this.sit_out_count = this.sit_out_count + 1;
                            if (this.sit_out_count >= this.sit_out_drop) {
                                this.props.m_pwin.AutoSitOut();
                                return;
                            }
                            this.AutoPuskKard();
                        } else if (GlobalVariables.automatic_release) {
                            this.Pusk1KartaZaPuskanePl1();
                        }
                        return;
                    }
                } else {
                    if (this.watch) {
                        if (
                            this.Faza > 4 &&
                            this.strelka < 1 &&
                            this.KoiENaRed === 1 &&
                            this.hand < 16 &&
                            (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0)
                        ) {
                            if (this.CheckMessage()) {
                                let card = this._ttoi(this.ReadMessage());
                                if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                                } else {
                                }
                                return;
                            }
                        }
                    }
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.KoiENaRed !== 1 &&
                        this.hand < 16 &&
                        (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0)
                    ) {
                        if (this.CheckMessage()) {
                            let card = this._ttoi(this.ReadMessage());
                            if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                            } else {
                            }
                            return;
                        }
                    }
                    if (!this.watch) {
                        if (
                            this.Faza > 4 &&
                            this.strelka < 1 &&
                            this.hand < 16 &&
                            this.P1puska === 0 &&
                            !this.Move &&
                            this.Faza < 6 &&
                            this.KoiENaRed === 1
                        ) {
                            if (this.online_timer === 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                this.AutoPuskKard();
                            } else if (GlobalVariables.automatic_release) {
                                this.Pusk1KartaZaPuskanePl1();
                            }
                            return;
                        }
                    }
                }
            }
        }
    }

    AutoPuskKard() {
        for (let i = 1; i <= 16; i++) {
            if (this.ProverkaCursor(i)) {
                if (this.k[i] !== 0) {
                    this.PuskaneNaKartaOP(i);
                    return;
                }
            }
        }
    }

    async Timer4() {
        if (!this.Move) {
            if (this.SP) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    for (let i = 1; i < 17; i++) {
                        if (this.k[i] !== 0) {
                            return;
                        }
                    }
                    let FName: string[] = [];
                    for (let i = 1; i <= this.Players; i++) {
                        FName[i] = this.Name[i];
                    }
                    await this.InitRezDlg();
                    await this.InitORezDlg(FName);
                    this.setState({});
                }
            }
            if (this.OP) {
                if (this.strelka > 0) {
                    if (this.Server) {
                        this.InitVziatka();
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                        this.setState({});
                        for (let i = 1; i < 17; i++) {
                            if (this.k[i] !== 0) {
                                return;
                            }
                        }
                        this.Faza = 7;
                        let FName: string[] = [];
                        for (let i = 1; i <= this.Players; i++) {
                            FName[i] = this.Name[i];
                        }
                        await this.InitRezDlg();
                        await this.InitORezDlg(FName);
                        this.setState({});
                    } else {
                        this.InitVziatka();
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                        this.setState({});
                        for (let i = 1; i < 17; i++) {
                            if (this.k[i] !== 0) {
                                return;
                            }
                        }
                        this.Faza = 7;
                        let FName: string[] = [];
                        for (let i = 1; i <= this.Players; i++) {
                            FName[i] = this.Name[i];
                        }
                        await this.InitRezDlg();
                        await this.InitORezDlg(FName);
                        if (this.OP && this.props.m_pwin.game !== '') {
                            if (this.CheckMessage()) {
                                if (!this.watch) {
                                    this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Karti' + this.GetAllMessages());
                                }
                                this.ClearMessages();
                            }
                            if (this.CheckAMessage()) {
                                if (!this.watch) {
                                    this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Annonsi: ' + this.GetAllAMessages());
                                }
                                this.ClearAMessages();
                            }
                            if (!this.watch) {
                                this.props.m_pwin.SendGame('R');
                            }
                        }
                        this.setState({});
                    }
                }
            }
        }
    }

    async InitORezDlg(FName: string[]) {
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            if (this.OP) {
                if (this.brgame === 1) {
                    if (!this.op_start) {
                        this.props.m_pwin.SendGame('L');
                        this.op_start = true;
                    }
                }
            }
            for (let a = 0; a < 100; a++) {
                if (this.m_orez.orez[0][a] === -10) {
                    this.m_orez.orez[0][a] = this.m_rez1;
                    this.m_orez.orez[1][a] = this.m_rez2;
                    this.m_orez.orez[2][a] = this.m_rez3;
                    break;
                }
            }
            this.m_orez.m_rez1 = this.m_orez.m_rez2 = this.m_orez.m_rez3 = 0;
            for (let i = 0; i < 3; i++) {
                for (let a = 0; a < 100; a++) {
                    if (this.m_orez.orez[i][a] > -10) {
                        if (i === 0) {
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                        }
                        if (i === 1) {
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                        }
                        if (i === 2) {
                            this.m_orez.m_rez3 = this.m_orez.m_rez3 + this.m_orez.orez[2][a];
                        }
                    }
                }
            }
            this.setState({});
            if (this.OP) {
                if (this.Server) {
                    if (this.HaveWatch) {
                        this.SendResult(1);
                    }
                }
            }

            for (let i = 1; i < 4; i++) {
                this.m_orez.Player[i] = FName[i];
            }
            if (this.OP) {
                let rezult =
                    'X|' +
                    this.IntToString(this.m_orez.m_rez1) +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez2) +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez3);
                this.props.m_pwin.SendGame(rezult);
            }
            if (this.props.m_pwin.state.DlgORezultatVisible) {
                this.m_orez.DeleteAllItems();
                this.m_orez.AddRezults();
                this.m_orez.setState({});
            } else {
                this.m_orez.Players = 3;
                await this.m_orez.DoModal();
            }
            if (!this.IsGameFinished()) {
                if (this.SP) {
                    this.InitGame(false);
                }
            } else {
                let b_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Blato', '');
                let crezult = '';
                let ctime = this.getCDateTime();
                let player1 = FName[1];
                let player2 = FName[2];
                let player3 = FName[3];
                crezult =
                    ctime +
                    ' ' +
                    player1 +
                    ' ' +
                    this.GetLanguageText('against') +
                    ' ' +
                    player2 +
                    ' ' +
                    this.GetLanguageText('against') +
                    ' ' +
                    player3 +
                    ' ';
                crezult =
                    crezult +
                    this.GetLanguageText('scoring') +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez1) +
                    ' - ' +
                    this.IntToString(this.m_orez.m_rez2) +
                    ' - ' +
                    this.IntToString(this.m_orez.m_rez3);
                if (this.m_orez.m_rez1 >= this.m_orez.m_rez2 && this.m_orez.m_rez1 >= this.m_orez.m_rez3) {
                    crezult = crezult + 'w;';
                } else {
                    crezult = crezult + 'l;';
                }
                await this.WriteProfileString('Options', 'Blato', b_rezult + crezult);
                if (this.m_orez.m_rez1 > this.m_orez.m_rez2 && this.m_orez.m_rez1 > this.m_orez.m_rez3) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + FName[1]);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez2 > this.m_orez.m_rez1 && this.m_orez.m_rez2 > this.m_orez.m_rez3) {
                    this.m_orez.m_zagubi = this.m_orez.m_zagubi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + FName[2]);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez3 > this.m_orez.m_rez2 && this.m_orez.m_rez3 > this.m_orez.m_rez1) {
                    this.m_orez.m_zagubi = this.m_orez.m_zagubi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + FName[3]);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez1 === this.m_orez.m_rez2 && this.m_orez.m_rez1 === this.m_orez.m_rez3) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The score is even'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez1 === this.m_orez.m_rez2 && this.m_orez.m_rez1 > this.m_orez.m_rez3) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[2]
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez1 === this.m_orez.m_rez3 && this.m_orez.m_rez1 > this.m_orez.m_rez2) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else if (this.m_orez.m_rez2 === this.m_orez.m_rez3 && this.m_orez.m_rez2 > this.m_orez.m_rez1) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult =
                            '$|n|' +
                            this.IntToString(this.GetPlayerPosition()) +
                            '|' +
                            this.IntToString(this.m_orez.m_rez1) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez2) +
                            ' : ' +
                            this.IntToString(this.m_orez.m_rez3);
                        this.props.m_pwin.SendGame(rezult);
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The game profit') + ' ' + FName[2] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                    );
                }
                if (this.OP) {
                    let rezult = 'X|0 : 0 : 0';
                    this.props.m_pwin.SendGame(rezult);
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    this.InitNewGame();
                } else {
                    if (this.OP) {
                        this.props.m_pwin.GoToLobby();
                    } else {
                        this.props.m_pwin.OnNewGame();
                    }
                    this.props.m_pwin.showAd();
                    return;
                }
            }
            if (this.OP) {
                this.Faza = 6;
                if (this.Server) {
                    for (let i = 2; i <= 3; i++) {
                        if (this.wait_opplayers[i]) {
                            this.Name[i] = this.wait_opnames[i];
                            this.show_user[i] = false;
                            this.props.m_pwin.GetPlayerRating(i);
                            if (i === 2) {
                                this.P2OP = true;
                                this.P2G = true;
                            }
                            if (i === 3) {
                                this.P3OP = true;
                                this.P3G = true;
                            }
                            this.SendResult(i);
                            let message = '$|v|' + this.IntToString(i);
                            this.props.m_pwin.SendGame(message);
                            this.setState({});
                        }
                    }
                    for (let i = 2; i <= 3; i++) {
                        if (this.wait_opplayers[i]) {
                            let new_name = '$|w|' + this.IntToString(i) + '|' + this.wait_opnames[i];
                            this.props.m_pwin.SendGame(new_name);
                            this.wait_opplayers[i] = false;
                            this.wait_opnames[i] = '';
                        }
                    }
                }
            }
            this.setState({});
        }
        this.setState({});
    }

    async InitRezDlg() {
        if (this.watch) {
            this.Faza = 6;
        }
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            this.m_rez.name[1] = this.Name[1];
            this.m_rez.name[2] = this.Name[2];
            this.m_rez.name[3] = this.Name[3];
            this.m_rez.m_rez1 = this.m_rez1;
            this.m_rez.m_rez2 = this.m_rez2;
            this.m_rez.m_rez3 = this.m_rez3;
            this.online_timer = this.online_timer_time;
        }
    }

    Timer7() {
        if (!this.watch && this.OP) {
            if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server) {
                if (this.online_timer > 0) {
                    this.online_timer = this.online_timer - 1;
                }
                if (this.online_timer === 5) {
                    if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server) {
                        if (this.Faza > 2 && !this.Move && this.KoiENaRed === 1) {
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                            }
                        }
                    }
                }
                this.setState({});
                if (this.Faza === 3 && this.KoiZapochva === 1 && this.obiaviavane) {
                    if (this.online_timer === 0) {
                        this.online_timer = this.online_timer_time;
                        this.m_obbla.AutoObiava();
                        return;
                    }
                }
                if (this.Faza === 4 && this.KoiZapochva === 1) {
                    if (this.online_timer === 0) {
                        this.OnLButtonDown(
                            new CPoint(this.ButtonStartPoint.x * GlobalVariables.scale + 1, this.ButtonStartPoint.y * GlobalVariables.scale + 1)
                        );
                        this.online_timer = this.online_timer_time;
                        return;
                    }
                }
                if (this.Faza === -15 && !this.is && this.props.m_pwin.state.CBlatoGetCardsVisible) {
                    if (this.online_timer === 0) {
                        this.get_cardsdlg.OnOk();
                        this.online_timer = this.online_timer_time;
                    }
                    return;
                }
                if (this.Faza === 7) {
                    if (this.online_timer === 0) {
                        if (this.props.m_pwin.state.DlgRezultatBlatoVisible) {
                            this.m_rez.OnOk();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                        if (this.props.m_pwin.state.DlgORezultatVisible && !this.props.m_pwin.state.DlgRezultatBlatoVisible) {
                            this.m_orez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                    }
                }
            }
        }
    }

    Anonsi1(Player: number) {
        let Boia: number[] = [0, 0, 0, 0, 0, 0];
        let BrBoia: number[] = [0, 0, 0, 0, 0, 0];
        let Stopers: boolean[] = [false, false, false, false, false];
        for (let a = (Player - 1) * 16 + 1; a < (Player - 1) * 16 + 17; a++) {
            BrBoia[Math.floor(this.k[a] / 100)]++;
        }
        for (let i = 1; i < 5; i++) {
            if (BrBoia[i] === 4) {
                Boia[i] += 10;
            }
            if (BrBoia[i] === 5) {
                Boia[i] += 60;
            }
            if (BrBoia[i] === 6) {
                Boia[i] += 110;
            }
            if (BrBoia[i] === 7) {
                Boia[i] += 180;
            }
            if (BrBoia[i] === 8) {
                Boia[i] += 280;
            }
            if (BrBoia[i] >= 9) {
                Boia[i] += 400;
            }
        }
        for (let a = (Player - 1) * 16 + 1; a < (Player - 1) * 16 + 17; a++) {
            if (this.k[a] % 100 === 14) {
                Boia[Math.floor(this.k[a] / 100)] += 50;
                Stopers[Math.floor(this.k[a] / 100)] = true;
                Boia[5] += 60;
            }
            if (this.k[a] % 100 === 13) {
                Boia[Math.floor(this.k[a] / 100)] += 35;
                Boia[5] += 35;
                if (BrBoia[Math.floor(this.k[a] / 100)] >= 2) {
                    Stopers[Math.floor(this.k[a] / 100)] = true;
                }
            }
            if (this.k[a] % 100 === 12) {
                Boia[Math.floor(this.k[a] / 100)] += 20;
                Boia[5] += 20;
                if (BrBoia[Math.floor(this.k[a] / 100)] >= 3) {
                    Stopers[Math.floor(this.k[a] / 100)] = true;
                }
            }
            if (this.k[a] % 100 === 11) {
                Boia[Math.floor(this.k[a] / 100)] += 10;
                Boia[5] += 10;
                if (BrBoia[Math.floor(this.k[a] / 100)] >= 4) {
                    Stopers[Math.floor(this.k[a] / 100)] = true;
                }
            }
            if (this.k[a] % 100 === 10) {
                Boia[Math.floor(this.k[a] / 100)] += 5;
                Boia[5] += 5;
            }
        }
        for (let i = 1; i < 6; i++) {
            Boia[i] += Boia[5];
        }
        for (let i = 1; i < 5; i++) {
            if (BrBoia[i] === 2) {
                for (let j = 1; j < 5; j++) {
                    if (i === j) {
                        Boia[i] -= 60;
                    } else {
                        Boia[j] += 40;
                    }
                }
                Boia[5] -= 30;
            }
            if (BrBoia[i] === 1) {
                for (let j = 1; j < 5; j++) {
                    if (i === j) {
                        Boia[i] -= 120;
                    } else {
                        Boia[j] += 80;
                    }
                }
                Boia[5] -= 60;
            }
        }
        for (let i = 1; i < 5; i++) {
            if (!Stopers[i]) {
                Boia[5] -= 50;
            }
            if (BrBoia[i] >= 6) {
                Boia[5] += (BrBoia[i] - 5) * 30;
            }
        }
        let OstVuzm = 5;
        for (let i = 1; i < 6; i++) {
            if (this.type !== 21) {
                if (this.Obiavi[Player][i] === true) {
                    Boia[i] = -1000;
                    OstVuzm--;
                }
            }
        }
        let Nadd = 1;
        Boia[0] = 140 + 23 * OstVuzm;
        if (this.Obiavi[Player][0] === true) {
            Boia[0] = -1000;
        }
        let max = -1000;
        Boia[5] = Boia[5] * 0.88;
        Boia[5] -= this.LastResult[Player] * 22;
        Boia[4] += this.LastResult[Player] * 22;
        Boia[3] += this.LastResult[Player] * 22;
        Boia[2] += this.LastResult[Player] * 22;
        Boia[1] += this.LastResult[Player] * 22;

        for (let i = 0; i < 6; i++) {
            if (max <= Boia[i]) {
                max = Boia[i];
                Nadd = i;
            }
        }
        if (Nadd > 0) {
            this.Naddavane = Nadd;
        }
        this.Pkazal[Player][Nadd] = true;
        this.Obiavi[Player][Nadd] = true;
        return Nadd;
    }

    OnLButtonDown(point: CPoint) {
        if (this.OP) {
            for (let i = 1; i <= 3; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 3; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 3; i++) {
                        if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!this.Move && !this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server)) {
                if (this.OP && this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0 && this.Faza === 5) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.props.m_pwin.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }

            if (this.Faza === 5 && this.strelka < 1 && this.P1puska === 0) {
                if (
                    new CRect(
                        this.CardsPos[16 - this.hand].x * GlobalVariables.scale,
                        this.CardsPos[16 - this.hand].y * GlobalVariables.scale,
                        (this.CardsPos[16 - this.hand].x + GlobalConstants.PLAYER_CARD_WIDTH) * GlobalVariables.scale,
                        (this.CardsPos[16 - this.hand].y + GlobalConstants.PLAYER_CARD_HEIGHT) * GlobalVariables.scale
                    ).PtInRect(point) &&
                    this.k[16 - this.hand] !== 0
                ) {
                    if (this.ProverkaCursor(16 - this.hand) === true) {
                        if (this.SP) {
                            this.PuskaneNaKarta(16 - this.hand);
                        }
                        if (this.OP) {
                            this.PuskaneNaKartaOP(16 - this.hand);
                        }
                        if (this.OP && !this.watch) {
                            this.sit_out_count = 0;
                            if (this.brgame === 1) {
                                if (!this.op_start) {
                                    this.props.m_pwin.SendGame('L');
                                    this.op_start = true;
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 1; i < 16 - this.hand; i++) {
                        if (this.ScaleRect(this.CardRect[i]).PtInRect(point) && this.k[i] !== 0) {
                            if (this.ProverkaCursor(i) === true) {
                                if (this.SP) {
                                    this.PuskaneNaKarta(i);
                                }
                                if (this.OP) {
                                    this.PuskaneNaKartaOP(i);
                                }
                                if (this.OP && !this.watch) {
                                    this.sit_out_count = 0;
                                    if (this.brgame === 1) {
                                        if (!this.op_start) {
                                            this.props.m_pwin.SendGame('L');
                                            this.op_start = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (this.Faza === 4 && this.KoiZapochva === 1) {
                if (
                    new CRect(
                        this.ButtonStartPoint.x * GlobalVariables.scale,
                        this.ButtonStartPoint.y * GlobalVariables.scale,
                        this.ButtonStartPoint.x * GlobalVariables.scale + 44 * GlobalVariables.scale,
                        this.ButtonStartPoint.y * GlobalVariables.scale + 18 * GlobalVariables.scale
                    ).PtInRect(point)
                ) {
                    this.Faza = 5;
                    this.online_timer = this.online_timer_time;
                    this.OtkritTalon = 0;
                    this.SortCards(2);

                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    if (this.OP) {
                        for (let i = 1; i < 5; i++) {
                            let ch = 1;
                            let gotonext: boolean = false;
                            for (let a = 1; a < 5; a++) {
                                if (this.k[48 + i] === this.kt[a]) {
                                    gotonext = true;
                                }
                            }
                            if (!gotonext) {
                                for (let q = 1; q < 5; q++) {
                                    if (
                                        this.kt[ch] !== this.k[49] &&
                                        this.kt[ch] !== this.k[50] &&
                                        this.kt[ch] !== this.k[51] &&
                                        this.kt[ch] !== this.k[52] &&
                                        this.kt[ch] !== this.smiana_talon[0][1] &&
                                        this.kt[ch] !== this.smiana_talon[1][1] &&
                                        this.kt[ch] !== this.smiana_talon[2][1] &&
                                        this.kt[ch] !== this.smiana_talon[3][1]
                                    ) {
                                        break;
                                    }
                                    ch = q;
                                }
                                this.smiana_talon[i - 1][0] = this.k[48 + i];
                                this.smiana_talon[i - 1][1] = this.kt[ch];
                            }
                        }
                        for (let i = 0; i < 4; i++) {
                            if (this.smiana_talon[i][0] > 0 && this.smiana_talon[i][1] > 0) {
                                this.props.m_pwin.SendGame(
                                    '$|g|' + this.IntToString(this.smiana_talon[i][0]) + '|' + this.IntToString(this.smiana_talon[i][1])
                                );
                            }
                        }
                        this.props.m_pwin.SendGame('$|g|-|-');
                    }
                    if (this.SelectT > 0) {
                        this.SelectT = 0;
                        this.setState({});
                    }
                }

                let OldSelect;
                if (this.OtkritTalon === 1) {
                    OldSelect = this.SelectT;
                    if (this.ScaleRect(this.TalonRect[1]).PtInRect(point)) {
                        if (this.SelectT === 49) {
                            this.SelectT = 0;
                        } else {
                            this.SelectT = 49;
                        }
                    }
                    if (this.ScaleRect(this.TalonRect[2]).PtInRect(point)) {
                        if (this.SelectT === 50) {
                            this.SelectT = 0;
                        } else {
                            this.SelectT = 50;
                        }
                    }
                    if (this.ScaleRect(this.TalonRect[3]).PtInRect(point)) {
                        if (this.SelectT === 51) {
                            this.SelectT = 0;
                        } else {
                            this.SelectT = 51;
                        }
                    }
                    if (this.ScaleRect(this.TalonRect[4]).PtInRect(point)) {
                        if (this.SelectT === 52) {
                            this.SelectT = 0;
                        } else {
                            this.SelectT = 52;
                        }
                    }
                    if (this.SelectT > 0) {
                        this.setState({});
                    }
                    if (OldSelect !== 0) {
                        this.setState({});
                    }
                }
                if (this.SelectT > 0 && this.OtkritTalon === 1) {
                    for (let i = 1; i < 17; i++) {
                        if (this.ScaleRect(this.CardRect[i]).PtInRect(point)) {
                            let temp: string;
                            let card: number;
                            card = this.k[this.SelectT];
                            temp = this.CardP[this.SelectT].image;
                            this.CardP[this.SelectT].image = this.CardP[i].image;
                            this.k[this.SelectT] = this.k[i];
                            this.CardP[i].image = temp;
                            this.k[i] = card;
                            this.setState({});
                            this.SelectT = 0;
                            return;
                        }
                    }
                }
            }
        }
    }

    DrawHands() {
        if (this.Faza === 2 && this.invalidate === 0) {
            let res = [];
            let text = '3 ' + this.GetLanguageText('Hands');
            let addx: number;
            let addy: number;
            for (let i = 0; i < 45; i++) {
                if (this.KoiZapochva === 1) {
                    addx = this.handspoint[1].x;
                    addy = this.handspoint[1].y;
                }
                if (this.KoiZapochva === 2) {
                    addx = this.handspoint[2].x;
                    addy = this.handspoint[2].y;
                }
                if (this.KoiZapochva === 3) {
                    addx = this.handspoint[0].x;
                    addy = this.handspoint[0].y;
                }
            }
            res.push(this.DrawObiaviavanePNG(addx, addy, text));

            text = '5 ' + this.GetLanguageText('Hands');
            for (let i = 0; i < 45; i++) {
                if (this.KoiZapochva === 1) {
                    addx = this.handspoint[2].x;
                    addy = this.handspoint[2].y;
                }
                if (this.KoiZapochva === 2) {
                    addx = this.handspoint[0].x;
                    addy = this.handspoint[0].y;
                }
                if (this.KoiZapochva === 3) {
                    addx = this.handspoint[1].x;
                    addy = this.handspoint[1].y;
                }
            }
            res.push(this.DrawObiaviavanePNG(addx, addy, text));

            text = '8 ' + this.GetLanguageText('Hands');
            for (let i = 0; i < 45; i++) {
                if (this.KoiZapochva === 1) {
                    addx = this.handspoint[0].x;
                    addy = this.handspoint[0].y;
                }
                if (this.KoiZapochva === 2) {
                    addx = this.handspoint[1].x;
                    addy = this.handspoint[1].y;
                }
                if (this.KoiZapochva === 3) {
                    addx = this.handspoint[2].x;
                    addy = this.handspoint[2].y;
                }
            }
            res.push(this.DrawObiaviavanePNG(addx, addy, text));
            return res;
        } else {
            return null;
        }
    }

    FindBestCards(Player: number, Boia: number, autoch: boolean) {
        if (autoch) {
            let a: number[] = [];
            for (let i = (Player - 1) * 16 + 1; i <= Player * 16; i++) {
                a[i - ((Player - 1) * 16 + 1)] = this.k[i];
            }
            for (let i = 0; i < 4; i++) {
                a[16 + i] = this.k[49 + i];
            }
            for (let i = 0; i < 20; i++) {
                if (a[i] > 900) {
                    a[i] = (a[i] % 100) + Boia * 100;
                }
            }
            let que: number[] = [];
            for (let i = 0; i < 20; i++) {
                que[i] = 0;
            }
            let Broi: number[] = [0, 0, 0, 0, 0];
            let ByCol: number[][] = []; //[5][20];
            for (let j = 0; j < 5; j++) {
                ByCol[j] = [];
                for (let i = 0; i < 20; i++) {
                    ByCol[j][i] = 0;
                }
            }
            for (let i = 0; i < 20; i++) {
                ByCol[Math.floor(a[i] / 100)][Broi[Math.floor(a[i] / 100)]] = a[i];
                Broi[Math.floor(a[i] / 100)]++;
            }
            for (let i = 1; i < 5; i++) {
                for (let j = 0; j < Broi[i]; j++) {
                    for (let k = j; k < Broi[i]; k++) {
                        if (ByCol[i][k] > ByCol[i][j]) {
                            let t = ByCol[i][k];
                            ByCol[i][k] = ByCol[i][j];
                            ByCol[i][j] = t;
                        }
                    }
                }
            }
            if (Boia === 5) {
                //BK
                for (let i = 1; i < 5; i++) {
                    let ques: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    for (let u = 0; u < Broi[i]; u++) {
                        ques[u] += ByCol[i][u] % 100;
                    }
                    if (ByCol[i][0] % 100 === 14) {
                        //ako nai goliamata karta e aso
                        ques[0] += 12000;
                        if (ByCol[i][1] % 100 === 13) {
                            ques[1] += 12000;
                            if (Broi[i] === 3) {
                                if (ByCol[i][2] % 100 === 12) {
                                    ques[2] += 12000;
                                }
                                if (ByCol[i][2] % 100 === 11) {
                                    ques[2] += 6000;
                                }
                                if (ByCol[i][2] % 100 === 10) {
                                    ques[2] += 2500;
                                }
                                if (ByCol[i][2] % 100 === 9) {
                                    ques[2] += 1500;
                                }
                                if (ByCol[i][2] % 100 < 9) {
                                    ques[2] += 800;
                                }
                            }
                            if (Broi[i] === 4) {
                                if (ByCol[i][2] % 100 === 12) {
                                    ques[2] += 12000;
                                    if (ByCol[i][3] % 100 === 11) {
                                        ques[3] += 12000;
                                    }
                                    if (ByCol[i][3] % 100 === 10) {
                                        ques[3] += 8500;
                                    }
                                    if (ByCol[i][3] % 100 === 9) {
                                        ques[3] += 5500;
                                    }
                                    if (ByCol[i][3] % 100 < 9) {
                                        ques[3] += 2500;
                                    }
                                }
                                if (ByCol[i][2] % 100 === 11) {
                                    ques[2] += 8000;
                                    if (ByCol[i][3] % 100 >= 9) {
                                        ques[3] += 3200;
                                    } else {
                                        ques[3] += 2500;
                                    }
                                }
                                if (ByCol[i][2] % 100 === 10) {
                                    ques[2] += 2500;
                                    ques[3] += 1500;
                                }
                                if (ByCol[i][2] % 100 === 9) {
                                    ques[2] += 1500;
                                    ques[3] += 1000;
                                }
                                if (ByCol[i][2] % 100 < 9) {
                                    ques[2] += 1000;
                                    ques[3] += 900;
                                }
                            }
                            if (Broi[i] >= 5) {
                                if (ByCol[i][2] % 100 === 12) {
                                    ques[2] += 12000;
                                    if (ByCol[i][3] % 100 === 11) {
                                        ques[3] += 12000;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 8000;
                                        }
                                    }
                                    if (ByCol[i][3] % 100 === 10) {
                                        ques[3] += 8500;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 5000;
                                        }
                                        if (Broi[i] >= 6) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 2000;
                                            }
                                        }
                                        if (Broi[i] >= 8) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 2000;
                                            }
                                        }
                                    }
                                    if (ByCol[i][3] % 100 === 9) {
                                        ques[3] += 5500;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 3000;
                                        }
                                        if (Broi[i] >= 6) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 2000;
                                            }
                                        }
                                        if (Broi[i] >= 8) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 2000;
                                            }
                                        }
                                    }
                                    if (ByCol[i][3] % 100 < 9) {
                                        ques[3] += 2500;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 1500;
                                        }
                                        if (Broi[i] >= 6) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 1800;
                                            }
                                        }
                                        if (Broi[i] >= 8) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 2000;
                                            }
                                        }
                                    }
                                }
                                if (ByCol[i][2] % 100 === 11) {
                                    ques[2] += 8000;
                                    if (ByCol[i][3] % 100 >= 9) {
                                        ques[3] += 3200;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 800;
                                        }
                                        if (Broi[i] >= 6) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 1200;
                                            }
                                        }
                                        if (Broi[i] >= 8) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 1700;
                                            }
                                        }
                                    } else {
                                        ques[3] += 2500;
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 1000;
                                        }
                                        if (Broi[i] >= 6) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 1300;
                                            }
                                        }
                                        if (Broi[i] >= 8) {
                                            for (let q = 0; q < Broi[i]; q++) {
                                                ques[q] += 1800;
                                            }
                                        }
                                    }
                                }
                                if (ByCol[i][2] % 100 === 10) {
                                    ques[2] += 2500;
                                    ques[3] += 1500;
                                    for (let q = 0; q < Broi[i]; q++) {
                                        ques[q] += 800;
                                    }
                                    if (Broi[i] >= 6) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 900;
                                        }
                                    }
                                    if (Broi[i] >= 8) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 1200;
                                        }
                                    }
                                }
                                if (ByCol[i][2] % 100 === 9) {
                                    ques[2] += 1500;
                                    ques[3] += 1000;
                                    for (let q = 0; q < Broi[i]; q++) {
                                        ques[q] += 700;
                                    }
                                    if (Broi[i] >= 6) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 800;
                                        }
                                    }
                                    if (Broi[i] >= 8) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 1000;
                                        }
                                    }
                                }
                                if (ByCol[i][2] % 100 < 9) {
                                    ques[2] += 1000;
                                    ques[3] += 900;
                                    for (let q = 0; q < Broi[i]; q++) {
                                        ques[q] += 600;
                                    }
                                    if (Broi[i] >= 6) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 600;
                                        }
                                    }
                                    if (Broi[i] >= 8) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 800;
                                        }
                                    }
                                }
                            }
                        }
                        if (ByCol[i][1] % 100 === 12) {
                            if (Broi[i] === 2) {
                                ques[1] += 3000;
                            }
                            if (Broi[i] === 3) {
                                if (ByCol[i][2] % 100 >= 10) {
                                    ques[2] += 1300;
                                } else {
                                    ques[2] += 700;
                                }
                            }
                            if (Broi[i] >= 4) {
                                if (ByCol[i][2] % 100 >= 10) {
                                    ques[2] += 1400;
                                    for (let q = 0; q < Broi[i]; q++) {
                                        ques[q] += 500;
                                    }
                                    if (Broi[i] >= 6) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 400;
                                        }
                                    }
                                    if (Broi[i] >= 8) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 400;
                                        }
                                    }
                                } else {
                                    ques[2] += 500;
                                    for (let q = 0; q < Broi[i]; q++) {
                                        ques[q] += 400;
                                    }
                                    if (Broi[i] >= 6) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 300;
                                        }
                                    }
                                    if (Broi[i] >= 8) {
                                        for (let q = 0; q < Broi[i]; q++) {
                                            ques[q] += 350;
                                        }
                                    }
                                }
                            }
                        }
                        if (ByCol[i][1] % 100 === 11) {
                            if (Broi[i] === 2) {
                                ques[1] += 220;
                            }
                            if (Broi[i] >= 3) {
                                ques[1] += 350;
                                ques[2] += 250;
                            }
                            if (Broi[i] >= 4) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 150;
                                }
                            }
                            if (Broi[i] >= 6) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 400;
                                }
                            }
                            if (Broi[i] >= 8) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 300;
                                }
                            }
                        }
                        if (ByCol[i][1] % 100 <= 10 && ByCol[i][1] % 100 !== 0) {
                            if (Broi[i] === 2) {
                                ques[1] += 450;
                            }
                            if (Broi[i] >= 3) {
                                ques[1] += 500;
                                ques[2] += 250;
                            }
                            if (Broi[i] >= 4) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 100;
                                }
                            }
                            if (Broi[i] >= 6) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 300;
                                }
                            }
                            if (Broi[i] >= 8) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 300;
                                }
                            }
                        }
                    }
                    if (ByCol[i][0] % 100 === 13) {
                        //ako nai goliamata karta e pop
                        if (Broi[i] === 1) {
                            ques[0] += 1500;
                        }
                        if (Broi[i] >= 2) {
                            ques[0] += 4500;
                            ques[1] += 3500;
                        }
                        if (Broi[i] >= 3) {
                            ques[2] += 1800;
                        }
                        if (ByCol[i][1] % 100 === 12) {
                            if (Broi[i] >= 3) {
                                ques[1] += 700;
                                ques[2] += 1600;
                            }
                            if (Broi[i] >= 4) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 500;
                                }
                                ques[1] += 50;
                            }
                            if (Broi[i] >= 6) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 300;
                                }
                            }
                            if (Broi[i] >= 8) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 200;
                                }
                            }
                        }
                        if (ByCol[i][1] % 100 === 11) {
                            if (Broi[i] >= 3) {
                                ques[1] += 400;
                                ques[2] += 1000;
                            }
                            if (Broi[i] >= 4) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 420;
                                }
                                ques[1] += 50;
                            }
                            if (Broi[i] >= 6) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 280;
                                }
                            }
                            if (Broi[i] >= 8) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 200;
                                }
                            }
                        }
                        if (ByCol[i][1] % 100 <= 10 && ByCol[i][1] % 100 !== 0) {
                            if (Broi[i] >= 4) {
                                ques[2] += 400;
                            }
                            if (Broi[i] >= 4) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 180;
                                }
                                ques[1] += 50;
                            }
                            if (Broi[i] >= 6) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 140;
                                }
                            }
                            if (Broi[i] >= 8) {
                                for (let q = 0; q < Broi[i]; q++) {
                                    ques[q] += 140;
                                }
                            }
                        }
                    }
                    if (ByCol[i][0] % 100 === 12) {
                        //ako nai goliamata karta e dama
                        if (Broi[i] === 1) {
                            ques[0] += 800;
                        }
                        if (Broi[i] === 2) {
                            ques[0] += 2000;
                            ques[1] += 1700;
                        }
                        if (Broi[i] >= 3) {
                            ques[0] += 4000;
                            ques[1] += 3000;
                            ques[2] += 2000;
                        }
                        if (ByCol[i][1] % 100 === 11) {
                            ques[1] += 800;
                        }
                        if (ByCol[i][1] % 100 === 10 && Broi[i] >= 3) {
                            ques[1] += 500;
                        }
                        if (ByCol[i][2] % 100 === 10) {
                            ques[2] += 600;
                        }
                        if (Broi[i] >= 4) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 210;
                            }
                        }
                        if (Broi[i] >= 6) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 170;
                            }
                        }
                        if (Broi[i] >= 8) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 170;
                            }
                        }
                    }
                    if (ByCol[i][0] % 100 === 11) {
                        //ako nai goliamata karta e vale
                        if (Broi[i] === 1) {
                            ques[0] += 300;
                        }
                        if (Broi[i] === 2) {
                            ques[0] += 600;
                            ques[1] += 500;
                        }
                        if (Broi[i] === 3) {
                            ques[0] += 1200;
                            ques[1] += 1000;
                            ques[2] += 8000;
                        }
                        if (Broi[i] >= 4) {
                            ques[0] += 2000;
                            ques[1] += 1800;
                            ques[2] += 1600;
                            ques[3] += 1400;
                        }
                        if (Broi[i] >= 6) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 200;
                            }
                        }
                        if (Broi[i] >= 8) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 200;
                            }
                        }
                    }
                    if (ByCol[i][0] % 100 <= 10 && ByCol[i][1] % 100 !== 0) {
                        //ako nai goliamata karta e vale
                        if (Broi[i] >= 6) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 100;
                            }
                        }
                        if (Broi[i] >= 8) {
                            for (let q = 0; q < Broi[i]; q++) {
                                ques[q] += 100;
                            }
                        }
                    }
                    ////
                    for (let j = 0; j < Broi[i]; j++) {
                        for (let k = 0; k < 20; k++) {
                            if (a[k] === ByCol[i][j]) {
                                que[k] = ques[j];
                            }
                        }
                    }
                }
            }
            if (Boia < 5 && Boia >= 1) {
                for (let i = 1; i < 5; i++) {
                    let ques: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    for (let u = 0; u < Broi[i]; u++) {
                        ques[u] += ByCol[i][u] % 100;
                    }
                    if (i === Boia) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 10000;
                        }
                    }
                    if (Broi[i] >= 7) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 800;
                        }
                    }
                    if (Broi[i] === 6) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 700;
                        }
                    }
                    if (Broi[i] === 5) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 550;
                        }
                    }
                    if (Broi[i] === 4) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 150;
                        }
                    }
                    if (Broi[i] === 3) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 30;
                        }
                    }
                    if (Broi[i] === 2) {
                        for (let j = 0; j < Broi[i]; j++) {
                            ques[j] += 8;
                        }
                    }
                    if (ByCol[i][0] % 100 === 14) {
                        //nai silnata karta v boiata e aso
                        ques[0] += 8000;
                    }
                    if (ByCol[i][0] % 100 === 13) {
                        //nai silnata karta v boiata e pop
                        if (Broi[i] === 1) {
                            ques[0] += 800;
                        }
                        if (Broi[i] === 2) {
                            ques[0] += 3000;
                            ques[1] += 1000;
                        }
                        if (Broi[i] >= 3) {
                            ques[0] += 5000;
                            ques[1] += 1000;
                            ques[2] += 300;
                        }
                    }
                    if (ByCol[i][0] % 100 === 12) {
                        //nai silnata karta v boiata e dama
                        if (Broi[i] === 1) {
                            ques[0] += 100;
                        }
                        if (Broi[i] === 2) {
                            ques[0] += 800;
                            ques[1] += 400;
                        }
                        if (Broi[i] === 3) {
                            ques[0] += 1200;
                            ques[1] += 500;
                            ques[2] += 300;
                        }
                        if (Broi[i] >= 4) {
                            ques[0] += 1400;
                            ques[1] += 600;
                            ques[2] += 350;
                            ques[3] += 150;
                        }
                    }
                    if (ByCol[i][0] % 100 === 11) {
                        //nai silnata karta v boiata e vale
                        if (Broi[i] === 1) {
                            ques[0] += 10;
                        }
                        if (Broi[i] === 2) {
                            ques[0] += 30;
                            ques[1] += 10;
                        }
                        if (Broi[i] === 3) {
                            ques[0] += 70;
                            ques[1] += 40;
                            ques[2] += 10;
                        }
                        if (Broi[i] >= 4) {
                            ques[0] += 100;
                            ques[1] += 60;
                            ques[2] += 20;
                            ques[3] += 10;
                        }
                    }
                    if (ByCol[i][0] % 100 <= 10 && ByCol[i][0] % 100 !== 0) {
                        for (let p = 1; p < i; p++) {
                            if (Broi[i] <= Broi[1] && Broi[i] <= Broi[3] && Broi[i] <= Broi[3] && Broi[i] <= Broi[4]) {
                                if (Broi[i] === Broi[p]) {
                                    for (let q = 1; q < Broi[i]; q++) {
                                        ques[i] -= 100;
                                    }
                                }
                            }
                        }
                    }
                    ////
                    if (ByCol[i][1] % 100 === 13) {
                        //vtorata po sila e pop
                        ques[1] += 6000;
                        for (let q = 1; q < Broi[i]; q++) {
                            ques[i] += 120;
                        }
                    }
                    if (ByCol[i][1] % 100 === 12) {
                        //vtorata po sila e dama
                        if (ByCol[i][0] % 100 === 14) {
                            if (Broi[i] === 2 || Broi[i] === 3) {
                                ques[1] += 1300;
                            }
                            if (Broi[i] >= 4) {
                                ques[1] += 1500;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 80;
                            }
                        }
                        if (ByCol[i][0] % 100 === 13) {
                            if (Broi[i] === 2) {
                                ques[1] += 800;
                            }
                            if (Broi[i] > 2) {
                                ques[1] += 1000;
                                ques[2] += 280;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 40;
                            }
                        }
                    }
                    if (ByCol[i][1] % 100 === 11) {
                        //vtorata po sila e vale
                        if (ByCol[i][0] % 100 === 14) {
                            if (Broi[i] === 2 || Broi[i] === 3) {
                                ques[1] += 400;
                            }
                            if (Broi[i] >= 4) {
                                ques[1] += 600;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 50;
                            }
                        }
                        if (ByCol[i][0] % 100 === 13) {
                            if (Broi[i] === 2) {
                                ques[1] += 200;
                            }
                            if (Broi[i] === 3) {
                                ques[1] += 300;
                                ques[2] += 120;
                            }
                            if (Broi[i] >= 4) {
                                ques[1] += 350;
                                ques[2] += 120;
                                ques[3] += 80;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 30;
                            }
                        }
                        if (ByCol[i][0] % 100 === 12) {
                            if (Broi[i] === 2) {
                                ques[1] += 60;
                            }
                            if (Broi[i] === 3) {
                                ques[1] += 80;
                                ques[2] += 40;
                            }
                            if (Broi[i] >= 4) {
                                ques[1] += 140;
                                ques[2] += 60;
                                ques[3] += 20;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 20;
                            }
                        }
                    }
                    if (ByCol[i][1] % 100 === 10) {
                        //vtorata po sila e desetka
                        if (ByCol[i][0] % 100 >= 13) {
                            if (Broi[i] >= 3) {
                                ques[1] += 140;
                                ques[2] += 60;
                                for (let q = 1; q < Broi[i]; q++) {
                                    ques[i] += 30;
                                }
                            }
                        }
                        if (ByCol[i][0] % 100 === 12) {
                            if (Broi[i] >= 3) {
                                ques[1] += 120;
                                ques[2] += 40;
                            }
                            for (let q = 1; q < Broi[i]; q++) {
                                ques[i] += 10;
                            }
                        }
                        if (ByCol[i][0] % 100 === 11) {
                            if (Broi[i] >= 4) {
                                ques[1] += 50;
                                ques[2] += 30;
                                ques[3] += 10;
                            }
                        }
                    }
                    if (ByCol[i][2] % 100 === 12) {
                        for (let q = 2; q < Broi[i]; q++) {
                            ques[i] += 600;
                        }
                    }
                    if (ByCol[i][2] % 100 === 11) {
                        for (let q = 2; q < Broi[i]; q++) {
                            ques[i] += 500;
                        }
                    }
                    if (ByCol[i][2] % 100 === 10) {
                        for (let q = 2; q < Broi[i]; q++) {
                            ques[i] += 300;
                        }
                    }
                    ////
                    for (let j = 0; j < Broi[i]; j++) {
                        for (let k = 0; k < 20; k++) {
                            if (a[k] === ByCol[i][j]) {
                                que[k] = ques[j];
                            }
                        }
                    }
                }
            }
            let BadCards: number[][] = [
                [50000, 0],
                [50000, 0],
                [50000, 0],
                [50000, 0],
            ];
            for (let i = 0; i < 20; i++) {
                if (BadCards[3][0] > que[i]) {
                    BadCards[3][0] = que[i];
                    BadCards[3][1] = i;
                    if (BadCards[2][0] > BadCards[3][0]) {
                        let t1: number;
                        let t2: number;
                        t1 = BadCards[2][0];
                        t2 = BadCards[2][1];
                        BadCards[2][0] = BadCards[3][0];
                        BadCards[2][1] = BadCards[3][1];
                        BadCards[3][1] = t2;
                        BadCards[3][0] = t1;
                        if (BadCards[1][0] > BadCards[2][0]) {
                            t1 = BadCards[1][0];
                            t2 = BadCards[1][1];
                            BadCards[1][0] = BadCards[2][0];
                            BadCards[1][1] = BadCards[2][1];
                            BadCards[2][1] = t2;
                            BadCards[2][0] = t1;
                            if (BadCards[0][0] > BadCards[1][0]) {
                                t1 = BadCards[0][0];
                                t2 = BadCards[0][1];
                                BadCards[0][0] = BadCards[1][0];
                                BadCards[0][1] = BadCards[1][1];
                                BadCards[1][1] = t2;
                                BadCards[1][0] = t1;
                            }
                        }
                    }
                }
            }
            let used = 0;
            for (let i = 0; i < 4; i++) {
                if (BadCards[i][1] < 16) {
                    for (let j = 0; j < 4; j++) {
                        if (BadCards[j][1] === 16 + used) {
                            used++;
                        }
                    }
                    if (used < 4) {
                        let t = this.k[BadCards[i][1] + (Player - 1) * 16 + 1];
                        this.k[BadCards[i][1] + (Player - 1) * 16 + 1] = this.k[49 + used];
                        this.k[49 + used] = t;
                        if (this.OP) {
                            this.props.m_pwin.SendGame(
                                '$|g|' + this.IntToString(t) + '|' + this.IntToString(this.k[BadCards[i][1] + (Player - 1) * 16 + 1])
                            );
                        }
                        let temp: string;
                        temp = this.CardP[BadCards[i][1] + (Player - 1) * 16 + 1].image;
                        this.CardP[BadCards[i][1] + (Player - 1) * 16 + 1].image = this.CardP[49 + used].image;
                        this.CardP[49 + used].image = temp;
                        used++;
                    }
                }
            }
            if (this.OP) {
                this.props.m_pwin.SendGame('$|g|-|-');
            }
        } else {
            for (let i = 0; i < 4; i++) {
                if (this.smiana_talon[i][0] > 0) {
                    for (let a = (Player - 1) * 16 + 1; a < (Player - 1) * 16 + 17; a++) {
                        if (this.k[a] === this.smiana_talon[i][0]) {
                            for (let aa = 49; aa < 53; aa++) {
                                if (this.k[aa] === this.smiana_talon[i][1]) {
                                    let t = this.k[a];
                                    this.k[a] = this.k[aa];
                                    this.k[aa] = t;
                                    let temp: string;
                                    temp = this.CardP[a].image;
                                    this.CardP[a].image = this.CardP[aa].image;
                                    this.CardP[aa].image = temp;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    IntelectCC(Player: number, t: number[][] /*[5][4]*/, Boia: number) {
        for (
            let i = 0;
            i < 5;
            i++ //???
        ) {
            for (let j = 0; j < 4; j++) {
                t[i][j] = 0;
            }
        }
        let BroiWanted = 0;
        let BroiToWant = this.LastResult[Player];
        let Both: boolean;
        let Wanted: number[] = [0, 0, 0, 0, 0];
        if (
            (Player === 2 && this.LastResult[1] < 0 && this.LastResult[3] < 0) ||
            (Player === 3 && this.LastResult[1] < 0 && this.LastResult[2] < 0)
        ) {
            Both = true;
        } else {
            Both = false;
        }
        let a: number[] = []; //[16];
        for (let i = (Player - 1) * 16 + 1; i <= Player * 16; i++) {
            a[i - ((Player - 1) * 16 + 1)] = this.k[i];
        }
        for (let i = 0; i < 16; i++) {
            if (a[i] > 900) {
                a[i] = (a[i] % 100) + Boia * 100;
            }
        }
        let que: number[] = []; //[20];
        for (let i = 0; i < 20; i++) {
            que[i] = 0;
        }
        let Broi: number[] = [0, 0, 0, 0, 0];
        let ByCol: number[][] = []; //[5][16];
        for (let j = 0; j < 5; j++) {
            ByCol[j] = [];
            for (let i = 0; i < 16; i++) {
                ByCol[j][i] = 0;
            }
        }
        for (let i = 0; i < 16; i++) {
            ByCol[Math.floor(a[i] / 100)][Broi[Math.floor(a[i] / 100)]] = a[i];
            Broi[Math.floor(a[i] / 100)]++;
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 0; j < Broi[i]; j++) {
                for (let k = j; k < Broi[i]; k++) {
                    if (ByCol[i][k] > ByCol[i][j]) {
                        let t1 = ByCol[i][k];
                        ByCol[i][k] = ByCol[i][j];
                        ByCol[i][j] = t1;
                    }
                }
            }
        }
        if (Boia === 5) {
            let Stoppers: boolean[] = [false, false, false, false, false];
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) <= Broi[i] && Broi[i] > 0) {
                    Stoppers[i] = true;
                }
            }
            for (let i = 1; i < 5; i++) {
                if (!Stoppers[i]) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) >= 3 && Stoppers[i] && Broi[i] > 0) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 2 && Stoppers[i] && Broi[i] > 0) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Broi[i] > 5) {
                    if (Wanted[i] < 2) {
                        if (BroiWanted < BroiToWant) {
                            Wanted[i]++;
                            BroiWanted++;
                        }
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Broi[i] > 5) {
                    if (Wanted[i] < 2) {
                        if (BroiWanted < BroiToWant) {
                            Wanted[i]++;
                            BroiWanted++;
                        }
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Broi[i] === 5) {
                    if (Wanted[i] < 2) {
                        if (BroiWanted < BroiToWant) {
                            Wanted[i]++;
                            BroiWanted++;
                        }
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Broi[i] === 4) {
                    if (Wanted[i] < 2) {
                        if (BroiWanted < BroiToWant) {
                            Wanted[i]++;
                            BroiWanted++;
                        }
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Broi[i] >= 4 && ByCol[i][3] % 100 !== 1) {
                    if (Wanted[i] < 3) {
                        if (BroiWanted < BroiToWant) {
                            Wanted[i]++;
                            BroiWanted++;
                        }
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Stoppers[i] && Broi[i] > 0) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] < Broi[i]) {
                    if (BroiWanted < BroiToWant && Wanted[i] < 3) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] < Broi[i]) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
        }
        if (Boia < 5) {
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) >= 2 && Broi[i] > 5 && Wanted[i] < 2 && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Broi[i] > 5 && Wanted[i] < 2 && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) >= 2 && Broi[i] > 2 && Broi[i] <= 5 && Wanted[i] < 2 && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (12 - (ByCol[i][2] % 100) >= 2 && Broi[i] > 5 && Wanted[i] < 2 && Both && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (12 - (ByCol[i][2] % 100) >= 1 && Broi[i] > 5 && Wanted[i] < 2 && Both && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) >= 2 && Broi[i] > 2 && Wanted[i] < 2 && Both && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Broi[i] >= 2 && Broi[i] <= 5 && Wanted[i] < 2 && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Broi[i] >= 2 && Wanted[i] < 2 && Both && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Broi[i] >= 5 && Wanted[i] < Broi[i] && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (14 - (ByCol[i][0] % 100) === 1 && Broi[i] >= 5 && Wanted[i] < Broi[i] && Both && i !== Boia) {
                    if (BroiWanted < BroiToWant) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] < Broi[i]) {
                    if (BroiWanted < BroiToWant && Wanted[i] < 3 && i !== Boia) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] < Broi[i]) {
                    if (BroiWanted < BroiToWant && i !== Boia) {
                        Wanted[i]++;
                        BroiWanted++;
                    }
                }
            }
        }
        let wh = BroiWanted;
        let OtherPlayer: number;
        if (Player === 2) {
            OtherPlayer = 3;
        } else {
            OtherPlayer = 2;
        }
        let wh1 = 0 - this.LastResult[1];
        let wh2 = 0 - this.LastResult[OtherPlayer];
        while (wh > 0) {
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] > 0 && wh1 > 0) {
                    t[i][1]++;
                    wh--;
                    Wanted[i]--;
                    wh1--;
                }
            }
            for (let i = 1; i < 5; i++) {
                if (Wanted[i] > 0 && wh2 > 0) {
                    t[i][OtherPlayer]++;
                    wh--;
                    Wanted[i]--;
                    wh2--;
                }
            }
        }
    }

    async GetCardsAlert() {
        let S1: string = '';
        if (this.ChCards[2][1][1] + this.ChCards[2][2][1] + this.ChCards[2][3][1] + this.ChCards[2][4][1] > 0) {
            S1 += this.Name[2];
            S1 += ' ' + this.GetLanguageText('draw from you');
            S1 += ':';
            if (this.ChCards[2][1][1] > 0) {
                S1 += this.IntToString(this.ChCards[2][1][1]);
                if (this.ChCards[2][1][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Club') + ' ';
                }
                if (this.ChCards[2][1][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Clubs') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[2][2][1] > 0) {
                S1 += this.IntToString(this.ChCards[2][2][1]);
                if (this.ChCards[2][2][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Diamond') + ' ';
                }
                if (this.ChCards[2][2][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Diamonds') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[2][3][1] > 0) {
                S1 += this.IntToString(this.ChCards[2][3][1]);
                if (this.ChCards[2][3][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Heart') + ' ';
                }
                if (this.ChCards[2][3][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Hearts') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[2][4][1] > 0) {
                S1 += this.IntToString(this.ChCards[2][4][1]);
                if (this.ChCards[2][4][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Spade') + ' ';
                }
                if (this.ChCards[2][4][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Spades') + ' ';
                }
                S1 += ' ';
            }
        }
        S1 += '\n';
        if (this.ChCards[3][1][1] + this.ChCards[3][2][1] + this.ChCards[3][3][1] + this.ChCards[3][4][1] > 0) {
            S1 += this.Name[3];
            S1 += ' ' + this.GetLanguageText('draw from you');
            S1 += ':';
            if (this.ChCards[3][1][1] > 0) {
                S1 += this.IntToString(this.ChCards[3][1][1]);
                if (this.ChCards[3][1][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Club') + ' ';
                }
                if (this.ChCards[3][1][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Clubs') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[3][2][1] > 0) {
                S1 += this.IntToString(this.ChCards[3][2][1]);
                if (this.ChCards[3][2][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Diamond') + ' ';
                }
                if (this.ChCards[3][2][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Diamonds') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[3][3][1] > 0) {
                S1 += this.IntToString(this.ChCards[3][3][1]);
                if (this.ChCards[3][3][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Heart') + ' ';
                }
                if (this.ChCards[3][3][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Hearts') + ' ';
                }
                S1 += ' ';
            }
            if (this.ChCards[3][4][1] > 0) {
                S1 += this.IntToString(this.ChCards[3][4][1]);
                if (this.ChCards[3][4][1] === 1) {
                    S1 += ' ' + this.GetLanguageText('Spade') + ' ';
                }
                if (this.ChCards[3][4][1] > 1) {
                    S1 += ' ' + this.GetLanguageText('Spades') + ' ';
                }
                S1 += ' ';
            }
        }
        if (S1 !== '\n') {
            if (this.Naddavane > 0 && this.Naddavane <= 5) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(S1);
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
        }
    }

    DrawObiaviavane(player: number, anons: number) {
        let text = this.GameName(anons);
        if (anons >= 0 && anons < 10) {
            return this.DrawObiaviavanePNG(this.PosNaddavane[player].x, this.PosNaddavane[player].y, text);
        }
    }

    Obiaviavane(player: number, anons: number) {
        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[player], this.GameName(anons, false));
        this.obiaviavaneRN = this.DrawObiaviavane(player, anons);
        this.online_timer = this.online_timer_time;
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(2 + anons, 'center', 0, 0);
        }
    }

    async Timer2() {
        if (!this.Move) {
            if (this.SP) {
                if (this.Faza === 2 && this.invalidate === 0) {
                    this.invalidate = 1;
                    this.setState({});
                    return;
                }
                if (this.invalidate === 1 && this.Faza !== -15 && this.Faza !== -10) {
                    this.setState({});
                    this.Faza = 3;
                    this.invalidate = 0;
                    return;
                }
                if (this.invalidate === 2 && this.Faza !== -15 && this.Faza !== -10) {
                    if (this.KoiZapochva === 1) {
                        this.Faza = 4;
                        this.OtkritTalon = 1;
                        this.invalidate = 0;
                        this.setState({});
                    } else {
                        this.InvalidateBidding();
                        this.Faza = -10;
                    }
                    return;
                }
                if (this.Faza === -10) {
                    this.Faza = 5;
                    this.invalidate = 0;
                    this.invalidate = 3;
                    if (this.KoiZapochva > 1) {
                        if (this.anons !== 0) {
                            this.SortCards(1);
                            this.FindBestCards(this.KoiZapochva, this.anons, true);
                            this.SortCards(1);
                            this.setState({});
                            this.props.m_pwin.KillTimer(3);
                            this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                        } else {
                            this.InitGame(true);
                            this.setState({});
                        }
                    }
                }
                if (this.Faza === -15 && this.is) {
                    if (this.Naddavane === 5) {
                        this.LastResult[1] = 0;
                        this.LastResult[2] = 0;
                        this.LastResult[3] = 0;
                    }
                    let k1: number[] = []; //[53]
                    let Player = 1;
                    let Color = this.Naddavane;
                    for (let i = 0; i < 53; i++) {
                        if (this.k[i] < 1000) {
                            k1[i] = this.k[i];
                        } else {
                            k1[i] = this.k[i] + (Color - 10) * 100;
                        }
                    }
                    let BroiBoia: number[] = [0, 0, 0, 0, 0];
                    let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
                    let BestCards: number[][][] = []; //[5][13][2];
                    let MyCards: number[][][] = []; //[5][13][2];
                    for (let i1 = 0; i1 < 5; i1++) {
                        BestCards[i1] = [];
                        MyCards[i1] = [];
                        for (let i2 = 0; i2 < 13; i2++) {
                            BestCards[i1][i2] = [];
                            MyCards[i1][i2] = [];
                        }
                    }
                    for (let i = 1; i < 49; i++) {
                        if (this.k[i] > 0) {
                            BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][0] = k1[i];
                            BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                            BroiBoia[Math.floor(k1[i] / 100)]++;
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        for (let j = 0; j < BroiBoia[i]; j++) {
                            for (let k = j + 1; k < BroiBoia[i]; k++) {
                                if (BestCards[i][j][0] < BestCards[i][k][0]) {
                                    let t0 = BestCards[i][j][0];
                                    let t1 = BestCards[i][j][1];
                                    BestCards[i][j][0] = BestCards[i][k][0];
                                    BestCards[i][j][1] = BestCards[i][k][1];
                                    BestCards[i][k][0] = t0;
                                    BestCards[i][k][1] = t1;
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        for (let j = 0; j < BroiBoia[i]; j++) {
                            if (BestCards[i][j][1] === Player) {
                                MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                                MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                                BroiBoiaPl[i]++;
                            }
                        }
                    }
                    this.is = false;
                    this.get_cardsdlg.Color = this.Naddavane;
                    this.get_cardsdlg.LastRez[1] = this.LastResult[1];
                    this.get_cardsdlg.LastRez[2] = this.LastResult[2];
                    this.get_cardsdlg.LastRez[3] = this.LastResult[3];
                    for (let i = 0; i < 5; i++) {
                        this.get_cardsdlg.BroiBoiaPl[1][i] = BroiBoiaPl[i];
                    }
                    let Pl = this.KoiZapochva;
                    for (let i = 0; i < 3; i++) {
                        if (Pl === 1) {
                            if (this.LastResult[1] > 0 && this.Naddavane > 0 && this.Naddavane < 5) {
                                //prov dali da tegli
                                this.get_cardsdlg.m_name2 = this.Name[2];
                                this.get_cardsdlg.m_name3 = this.Name[3];
                                await this.get_cardsdlg.DoModal();
                                for (let p = 1; p < 14; p++) {
                                    if (this.get_cardsdlg.ObiaveniC[p] > 0) {
                                        this.ChCards[1][this.get_cardsdlg.ObiaveniC[p]][this.get_cardsdlg.ObiaveniP[p] + 1]++;
                                    }
                                }
                            }
                        }
                        if (Pl > 1) {
                            if (this.LastResult[Pl] > 0 && this.Naddavane > 0 && this.Naddavane < 5) {
                                //tuk gi puska6 masiva s kartite za onlaina
                                this.IntelectCC(Pl, this.ChCards[Pl], this.Naddavane);
                            }
                        }
                        if (Pl < 3) {
                            Pl++;
                        } else {
                            Pl = 1;
                        }
                    }
                    if (this.LastResult[1] < 0) {
                        await this.GetCardsAlert();
                    }
                    this.StartChangeCards();
                }
                if (this.Razdavane1 !== true && this.Faza === 3 && this.invalidate === 0) {
                    this.invalidate = 3;
                    if (this.KoiZapochva === 1) {
                        for (let i = 0; i < 6; i++) {
                            this.m_obbla.Obiavi[i] = this.Obiavi[1][i];
                        }
                        this.m_obbla.type = this.type;
                        await this.m_obbla.DoModal();
                        this.Faza = -15;
                        this.is = true;
                        if (this.m_obbla.m_pas === 0) {
                            this.Pkazal[1][1] = true;
                            this.Naddavane = 1;
                        }
                        if (this.m_obbla.m_pas === 1) {
                            this.Pkazal[1][2] = true;
                            this.Naddavane = 2;
                        }
                        if (this.m_obbla.m_pas === 2) {
                            this.Pkazal[1][3] = true;
                            this.Naddavane = 3;
                        }
                        if (this.m_obbla.m_pas === 3) {
                            this.Pkazal[1][4] = true;
                            this.Naddavane = 4;
                        }
                        if (this.m_obbla.m_pas === 4) {
                            this.Pkazal[1][5] = true;
                            this.Naddavane = 5;
                        }
                        if (this.m_obbla.m_pas === -1) {
                            this.Naddavane = 0;
                        }
                        this.Obiavi[1][this.Naddavane] = true;
                        if (this.Naddavane > 0) {
                            this.SortCards(1);
                            this.setState({});
                        } else {
                            this.InitGame(true);
                            this.setState({});
                        }
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane, false));
                    } else {
                        this.Faza = -15;
                        this.is = true;
                        this.anons = this.Anonsi1(this.KoiZapochva);
                        this.Obiaviavane(this.KoiZapochva, this.anons);
                    }
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza === 2 && this.invalidate === 0) {
                        this.invalidate = 1;
                        this.setState({});
                        return;
                    }
                    if (this.invalidate === 1 && this.Faza !== -15 && this.Faza !== -10) {
                        this.setState({});
                        this.Faza = 3;
                        this.online_timer = this.online_timer_time;
                        this.invalidate = 0;
                        return;
                    }
                    if (this.invalidate === 2 && this.Faza !== -15 && this.Faza !== -10) {
                        if (this.KoiZapochva === 1) {
                            this.Faza = 4;
                            for (let i = 49; i < 53; i++) {
                                this.kt[i - 48] = this.k[i];
                            }
                            this.online_timer = this.online_timer_time * 2;
                            this.OtkritTalon = 1;
                            this.invalidate = 0;
                            this.setState({});
                        } else {
                            this.InvalidateBidding();
                            this.Faza = -10;
                        }
                        return;
                    }
                    if (this.Faza === -10) {
                        if (this.anons !== 0) {
                            if (this.KoiZapochva > 1) {
                                if ((this.KoiZapochva === 2 && !this.P2OP) || (this.KoiZapochva === 3 && !this.P3OP)) {
                                    this.Faza = 5;
                                    this.online_timer = this.online_timer_time;
                                    this.invalidate = 0;
                                    this.invalidate = 3;
                                    this.SortCards(1);
                                    this.FindBestCards(this.KoiZapochva, this.anons, true);
                                    this.SortCards(1);
                                    this.setState({});
                                    this.props.m_pwin.KillTimer(3);
                                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                } else {
                                    if (this.talon_ready) {
                                        this.Faza = 5;
                                        this.online_timer = this.online_timer_time;
                                        this.invalidate = 0;
                                        this.invalidate = 3;
                                        this.SortCards(1);
                                        this.FindBestCards(this.KoiZapochva, this.anons, false);
                                        this.SortCards(1);
                                        this.setState({});
                                        this.props.m_pwin.KillTimer(3);
                                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                    }
                                }
                            }
                        } else {
                            if (this.P2G && this.P3G) {
                                this.Faza = 5;
                                this.online_timer = this.online_timer_time;
                                this.invalidate = 0;
                                this.invalidate = 3;
                                if (this.P2OP === true) {
                                    this.P2G = false;
                                }
                                if (this.P3OP === true) {
                                    this.P3G = false;
                                }
                                this.Razdavane1 = true;
                                this.InitGame(true);
                                this.SendKoiZapochva();
                                this.SendCards();
                                this.setState({});
                                this.online_timer = this.online_timer_time;
                            }
                        }
                    }
                    if (this.Faza === -15 && !this.is && !this.props.m_pwin.state.CBlatoGetCardsVisible && this.anons > 0) {
                        let next: boolean = false;
                        for (let i = 1; i <= 3; i++) {
                            if (this.LastResult[i] > 0 && !this.GCPL[i]) {
                                next = true;
                            }
                        }
                        if (!next && !this.get_cards) {
                            this.get_cards = true;
                            if (this.LastResult[1] < 0) {
                                await this.GetCardsAlert();
                            }
                            this.StartChangeCards();
                            this.get_cards = false;
                        }
                    }
                    if (this.Faza === -15 && this.is && this.anons > 0) {
                        if (this.Naddavane === 5) {
                            this.LastResult[1] = 0;
                            this.LastResult[2] = 0;
                            this.LastResult[3] = 0;
                        }
                        let k1: number[] = []; //[53];
                        let Player = 1;
                        let Color = this.Naddavane;
                        for (let i = 0; i < 53; i++) {
                            if (this.k[i] < 1000) {
                                k1[i] = this.k[i];
                            } else {
                                k1[i] = this.k[i] + (Color - 10) * 100;
                            }
                        }
                        let BroiBoia: number[] = [0, 0, 0, 0, 0];
                        let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
                        let BestCards: number[][][] = []; //[5][13][2];
                        let MyCards: number[][][] = []; //[5][13][2];
                        for (let i1 = 0; i1 < 5; i1++) {
                            BestCards[i1] = [];
                            MyCards[i1] = [];
                            for (let i2 = 0; i2 < 13; i2++) {
                                BestCards[i1][i2] = [];
                                MyCards[i1][i2] = [];
                            }
                        }
                        for (let i = 1; i < 49; i++) {
                            if (this.k[i] > 0) {
                                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][0] = k1[i];
                                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                                BroiBoia[Math.floor(k1[i] / 100)]++;
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            for (let j = 0; j < BroiBoia[i]; j++) {
                                for (let k = j + 1; k < BroiBoia[i]; k++) {
                                    if (BestCards[i][j][0] < BestCards[i][k][0]) {
                                        let t0 = BestCards[i][j][0];
                                        let t1 = BestCards[i][j][1];
                                        BestCards[i][j][0] = BestCards[i][k][0];
                                        BestCards[i][j][1] = BestCards[i][k][1];
                                        BestCards[i][k][0] = t0;
                                        BestCards[i][k][1] = t1;
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            for (let j = 0; j < BroiBoia[i]; j++) {
                                if (BestCards[i][j][1] === Player) {
                                    MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                                    MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                                    BroiBoiaPl[i]++;
                                }
                            }
                        }
                        this.is = false;
                        this.get_cardsdlg.Color = this.Naddavane;
                        this.get_cardsdlg.LastRez[1] = this.LastResult[1];
                        this.get_cardsdlg.LastRez[2] = this.LastResult[2];
                        this.get_cardsdlg.LastRez[3] = this.LastResult[3];
                        for (let i = 0; i < 5; i++) {
                            this.get_cardsdlg.BroiBoiaPl[1][i] = BroiBoiaPl[i];
                        }
                        let Pl = this.KoiZapochva;
                        for (let i = 0; i < 3; i++) {
                            if (Pl === 1) {
                                if (this.LastResult[1] > 0 && this.Naddavane > 0 && this.Naddavane < 5) {
                                    //prov dali da tegli
                                    this.get_cardsdlg.m_name2 = this.Name[2];
                                    this.get_cardsdlg.m_name3 = this.Name[3];
                                    this.online_timer = this.online_timer_time;
                                    await this.get_cardsdlg.DoModal();
                                    this.online_timer = this.online_timer_time;
                                    for (let p = 1; p < 14; p++) {
                                        if (this.get_cardsdlg.ObiaveniC[p] > 0) {
                                            this.ChCards[1][this.get_cardsdlg.ObiaveniC[p]][this.get_cardsdlg.ObiaveniP[p] + 1]++;
                                        }
                                    }
                                    this.SendCHCards(1);
                                }
                            }
                            if (Pl > 1) {
                                if (this.LastResult[Pl] > 0 && this.Naddavane > 0 && this.Naddavane < 5) {
                                    //tuk gi puska6 masiva s kartite za onlaina
                                    if ((Pl === 2 && !this.P2OP) || (Pl === 3 && !this.P3OP)) {
                                        this.IntelectCC(Pl, this.ChCards[Pl], this.Naddavane);
                                        this.SendCHCards(Pl);
                                    }
                                }
                            }
                            if (Pl < 3) {
                                Pl++;
                            } else {
                                Pl = 1;
                            }
                        }
                        return;
                    }
                    if (!this.Razdavane1 && this.Faza === 3 && this.invalidate === 0) {
                        if (this.KoiZapochva === 1) {
                            this.invalidate = 3;
                            for (let i = 0; i < 6; i++) {
                                this.m_obbla.Obiavi[i] = this.Obiavi[1][i];
                            }
                            this.m_obbla.type = this.type;
                            this.online_timer = this.online_timer_time;
                            this.obiaviavane = true;
                            await this.m_obbla.DoModal();
                            this.obiaviavane = false;
                            //	this.invalidate=2;
                            this.Faza = -15;
                            this.is = true;
                            if (this.m_obbla.m_pas === 0) {
                                this.props.m_pwin.SendGame('$|m|1');
                                this.Pkazal[1][1] = true;
                                this.Naddavane = 1;
                            }
                            if (this.m_obbla.m_pas === 1) {
                                this.props.m_pwin.SendGame('$|m|2');
                                this.Pkazal[1][2] = true;
                                this.Naddavane = 2;
                            }
                            if (this.m_obbla.m_pas === 2) {
                                this.props.m_pwin.SendGame('$|m|3');
                                this.Pkazal[1][3] = true;
                                this.Naddavane = 3;
                            }
                            if (this.m_obbla.m_pas === 3) {
                                this.props.m_pwin.SendGame('$|m|4');
                                this.Pkazal[1][4] = true;
                                this.Naddavane = 4;
                            }
                            if (this.m_obbla.m_pas === 4) {
                                this.props.m_pwin.SendGame('$|m|5');
                                this.Pkazal[1][5] = true;
                                this.Naddavane = 5;
                            }
                            if (this.m_obbla.m_pas === -1) {
                                this.props.m_pwin.SendGame('$|m|0');
                                this.Naddavane = 0;
                            }
                            this.Obiavi[1][this.Naddavane] = true;
                            this.online_timer = this.online_timer_time;
                            if (this.Naddavane > 0) {
                                this.SortCards(1);
                                this.setState({});
                            } else {
                                this.Faza = -10;
                                if (this.P2G && this.P3G) {
                                    if (this.P2OP === true) {
                                        this.P2G = false;
                                    }
                                    if (this.P3OP === true) {
                                        this.P3G = false;
                                    }
                                    this.Razdavane1 = true;
                                    this.InitGame(true);
                                    this.SendKoiZapochva();
                                    this.SendCards();
                                    this.setState({});
                                }
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiZapochva], this.GameName(this.Naddavane, false));
                            this.anons = this.Naddavane;
                        }
                        if (this.KoiZapochva > 1) {
                            if (((this.KoiZapochva === 2 && this.P2OP) || (this.KoiZapochva === 3 && this.P3OP)) && this.CheckAMessage()) {
                                this.invalidate = 3;
                                this.Faza = -15;
                                this.is = true;
                                this.anons = this._ttoi(this.ReadAMessage());
                                this.Naddavane = this.anons;
                                this.Pkazal[this.KoiZapochva][this.Naddavane] = true;
                                this.Obiavi[this.KoiZapochva][this.Naddavane] = true;
                                this.Obiaviavane(this.KoiZapochva, this.anons);
                                if (this.anons === 0) {
                                    this.Faza = -10;
                                }
                                return;
                            }
                            if (this.KoiZapochva === 2 && this.P2OP !== true) {
                                this.invalidate = 3;
                                this.Faza = -15;
                                this.is = true;
                                if (this.CheckAMessage()) {
                                    this.anons = this._ttoi(this.ReadAMessage());
                                    this.Naddavane = this.anons;
                                    this.Pkazal[this.KoiZapochva][this.Naddavane] = true;
                                    this.Obiavi[this.KoiZapochva][this.Naddavane] = true;
                                    this.Obiaviavane(this.KoiZapochva, this.anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2 LU:' + this.last_Name[2]);
                                } else {
                                    this.anons = this.Anonsi1(this.KoiZapochva);
                                    let ano = this.IntToString(this.anons);
                                    this.props.m_pwin.SendGame('$|m|' + ano);
                                    this.Obiaviavane(this.KoiZapochva, this.anons);
                                }
                                if (this.anons === 0) {
                                    this.Faza = -10;
                                }
                                return;
                            }
                            if (this.KoiZapochva === 3 && this.P3OP !== true) {
                                this.invalidate = 3;
                                this.Faza = -15;
                                this.is = true;
                                if (this.CheckAMessage()) {
                                    this.anons = this._ttoi(this.ReadAMessage());
                                    this.Naddavane = this.anons;
                                    this.Pkazal[this.KoiZapochva][this.Naddavane] = true;
                                    this.Obiavi[this.KoiZapochva][this.Naddavane] = true;
                                    this.Obiaviavane(this.KoiZapochva, this.anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2 LU:' + this.last_Name[3]);
                                } else {
                                    this.anons = this.Anonsi1(this.KoiZapochva);
                                    let ano = this.IntToString(this.anons);
                                    this.props.m_pwin.SendGame('$|m|' + ano);
                                    this.Obiaviavane(this.KoiZapochva, this.anons);
                                }
                                if (this.anons === 0) {
                                    this.Faza = -10;
                                }
                                return;
                            }
                        }
                    }
                } else {
                    if (this.Faza === 2 && this.invalidate === 0) {
                        this.invalidate = 1;
                        this.setState({});
                        return;
                    }
                    if (this.invalidate === 1 && this.Faza !== -15 && this.Faza !== -10) {
                        this.setState({});
                        this.Faza = 3;
                        this.online_timer = this.online_timer_time;
                        this.invalidate = 0;
                        return;
                    }
                    if (this.invalidate === 2 && this.Faza !== -15 && this.Faza !== -10) {
                        if (this.KoiZapochva === 1) {
                            this.Faza = 4;
                            if (this.watch) {
                                this.InvalidateBidding();
                                this.Faza = -10;
                            }
                            for (let i = 49; i < 53; i++) {
                                this.kt[i - 48] = this.k[i];
                            }
                            this.online_timer = this.online_timer_time * 2;
                            this.OtkritTalon = 1;
                            this.invalidate = 0;
                            this.setState({});
                        } else {
                            this.InvalidateBidding();
                            this.Faza = -10;
                        }
                        return;
                    }
                    if (this.Faza === -10 && (this.talon_ready || this.anons === 0)) {
                        this.Faza = 5;
                        this.online_timer = this.online_timer_time;
                        this.invalidate = 0;
                        this.invalidate = 3;
                        if (this.watch && this.KoiZapochva === 1) {
                            if (this.anons !== 0) {
                                this.SortCards(1);
                                this.FindBestCards(this.KoiZapochva, this.anons, false);
                                this.SortCards(1);
                                this.setState({});
                                this.props.m_pwin.KillTimer(3);
                                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                            }
                        }
                        if (this.KoiZapochva > 1) {
                            if (this.anons !== 0) {
                                this.SortCards(1);
                                this.FindBestCards(this.KoiZapochva, this.anons, false);
                                this.SortCards(1);
                                this.setState({});
                                this.props.m_pwin.KillTimer(3);
                                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                            } else {
                                if (!this.watch) {
                                    this.props.m_pwin.SendGame('R');
                                }
                            }
                        }
                    }
                    if (this.Faza === -15 && !this.is && !this.props.m_pwin.state.CBlatoGetCardsVisible && this.anons > 0) {
                        let next2: boolean = false;
                        for (let i = 1; i <= 3; i++) {
                            if (this.LastResult[i] > 0 && !this.GCPL[i]) {
                                next2 = true;
                            }
                        }
                        if (!next2 && !this.get_cards) {
                            this.get_cards = true;
                            if (this.LastResult[1] < 0 && !this.watch) {
                                await this.GetCardsAlert();
                            }
                            this.StartChangeCards();
                            this.get_cards = false;
                        }
                    }
                    if (this.Faza === -15 && this.is && this.anons > 0) {
                        if (this.Naddavane === 5) {
                            this.LastResult[1] = 0;
                            this.LastResult[2] = 0;
                            this.LastResult[3] = 0;
                        }
                        let k1: number[] = []; //[53];
                        let Player = 1;
                        let Color = this.Naddavane;
                        for (let i = 0; i < 53; i++) {
                            if (this.k[i] < 1000) {
                                k1[i] = this.k[i];
                            } else {
                                k1[i] = this.k[i] + (Color - 10) * 100;
                            }
                        }
                        let BroiBoia: number[] = [0, 0, 0, 0, 0];
                        let BroiBoiaPl: number[] = [0, 0, 0, 0, 0];
                        let BestCards: number[][][] = []; //[5][13][2];
                        let MyCards: number[][][] = []; //[5][13][2];
                        for (let i1 = 0; i1 < 5; i1++) {
                            BestCards[i1] = [];
                            MyCards[i1] = [];
                            for (let i2 = 0; i2 < 13; i2++) {
                                BestCards[i1][i2] = [];
                                MyCards[i1][i2] = [];
                            }
                        }
                        for (let i = 1; i < 49; i++) {
                            if (this.k[i] > 0) {
                                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][0] = k1[i];
                                BestCards[Math.floor(k1[i] / 100)][BroiBoia[Math.floor(k1[i] / 100)]][1] = Math.floor((i - 1) / 16) + 1;
                                BroiBoia[Math.floor(k1[i] / 100)]++;
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            for (let j = 0; j < BroiBoia[i]; j++) {
                                for (let k = j + 1; k < BroiBoia[i]; k++) {
                                    if (BestCards[i][j][0] < BestCards[i][k][0]) {
                                        let t0 = BestCards[i][j][0];
                                        let t1 = BestCards[i][j][1];
                                        BestCards[i][j][0] = BestCards[i][k][0];
                                        BestCards[i][j][1] = BestCards[i][k][1];
                                        BestCards[i][k][0] = t0;
                                        BestCards[i][k][1] = t1;
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            for (let j = 0; j < BroiBoia[i]; j++) {
                                if (BestCards[i][j][1] === Player) {
                                    MyCards[i][BroiBoiaPl[i]][0] = BestCards[i][j][0];
                                    MyCards[i][BroiBoiaPl[i]][1] = j - BroiBoiaPl[i];
                                    BroiBoiaPl[i]++;
                                }
                            }
                        }
                        this.is = false;
                        if (!this.watch) {
                            this.get_cardsdlg.Color = this.Naddavane;
                            this.get_cardsdlg.LastRez[1] = this.LastResult[1];
                            this.get_cardsdlg.LastRez[2] = this.LastResult[2];
                            this.get_cardsdlg.LastRez[3] = this.LastResult[3];
                            for (let i = 0; i < 5; i++) {
                                this.get_cardsdlg.BroiBoiaPl[1][i] = BroiBoiaPl[i];
                            }
                            let Pl = this.KoiZapochva;
                            for (let i = 0; i < 3; i++) {
                                if (Pl === 1) {
                                    if (this.LastResult[1] > 0 && this.Naddavane > 0 && this.Naddavane < 5) {
                                        //prov dali da tegli
                                        this.get_cardsdlg.m_name2 = this.Name[2];
                                        this.get_cardsdlg.m_name3 = this.Name[3];
                                        this.online_timer = this.online_timer_time;
                                        await this.get_cardsdlg.DoModal();
                                        this.online_timer = this.online_timer_time;
                                        for (let p = 1; p < 14; p++) {
                                            if (this.get_cardsdlg.ObiaveniC[p] > 0) {
                                                this.ChCards[1][this.get_cardsdlg.ObiaveniC[p]][this.get_cardsdlg.ObiaveniP[p] + 1]++;
                                            }
                                        }
                                        this.SendCHCards(1);
                                    }
                                }
                                if (Pl < 3) {
                                    Pl++;
                                } else {
                                    Pl = 1;
                                }
                            }
                        }
                        return;
                    }
                    if (this.Razdavane1 !== true && this.Faza === 3 && this.invalidate === 0) {
                        if (!this.watch) {
                            if (this.KoiZapochva === 1) {
                                this.invalidate = 3;
                                for (let i = 0; i < 6; i++) {
                                    this.m_obbla.Obiavi[i] = this.Obiavi[1][i];
                                }
                                this.m_obbla.type = this.type;
                                this.online_timer = this.online_timer_time;
                                this.obiaviavane = true;
                                await this.m_obbla.DoModal();
                                this.obiaviavane = false;
                                this.Faza = -15;
                                this.is = true;
                                if (this.m_obbla.m_pas === 0) {
                                    this.props.m_pwin.SendGame('$|m|1');
                                    this.Pkazal[1][1] = true;
                                    this.Naddavane = 1;
                                }
                                if (this.m_obbla.m_pas === 1) {
                                    this.props.m_pwin.SendGame('$|m|2');
                                    this.Pkazal[1][2] = true;
                                    this.Naddavane = 2;
                                }
                                if (this.m_obbla.m_pas === 2) {
                                    this.props.m_pwin.SendGame('$|m|3');
                                    this.Pkazal[1][3] = true;
                                    this.Naddavane = 3;
                                }
                                if (this.m_obbla.m_pas === 3) {
                                    this.props.m_pwin.SendGame('$|m|4');
                                    this.Pkazal[1][4] = true;
                                    this.Naddavane = 4;
                                }
                                if (this.m_obbla.m_pas === 4) {
                                    this.props.m_pwin.SendGame('$|m|5');
                                    this.Pkazal[1][5] = true;
                                    this.Naddavane = 5;
                                }
                                if (this.m_obbla.m_pas === -1) {
                                    this.props.m_pwin.SendGame('$|m|0');
                                    this.Naddavane = 0;
                                }
                                this.Obiavi[1][this.Naddavane] = true;
                                this.anons = this.Naddavane;
                                this.online_timer = this.online_timer_time;
                                if (this.Naddavane > 0) {
                                    this.SortCards(1);
                                    this.setState({});
                                } else {
                                    if (this.Server) {
                                        this.Faza = -10;
                                        if (this.P2G && this.P3G) {
                                            if (this.P2OP === true) {
                                                this.P2G = false;
                                            }
                                            if (this.P3OP === true) {
                                                this.P3G = false;
                                            }
                                            this.Razdavane1 = true;
                                            this.InitGame(true);
                                            this.SendKoiZapochva();
                                            this.SendCards();
                                            this.setState({});
                                        }
                                    } else {
                                        if (!this.watch) {
                                            this.props.m_pwin.SendGame('R');
                                        }
                                    }
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiZapochva], this.GameName(this.Naddavane, false));
                                return;
                            }
                        }
                        if (this.watch && this.KoiZapochva === 1 && this.CheckAMessage()) {
                            this.invalidate = 3;
                            this.Faza = -15;
                            this.is = true;
                            this.anons = this._ttoi(this.ReadAMessage());
                            this.Naddavane = this.anons;
                            this.Pkazal[this.KoiZapochva][this.Naddavane] = true;
                            this.Obiavi[this.KoiZapochva][this.Naddavane] = true;
                            this.Obiaviavane(this.KoiZapochva, this.anons);
                            return;
                        }
                        if (this.KoiZapochva > 1 && this.CheckAMessage()) {
                            this.invalidate = 3;
                            this.Faza = -15;
                            this.is = true;
                            this.anons = this._ttoi(this.ReadAMessage());
                            this.Naddavane = this.anons;
                            this.Pkazal[this.KoiZapochva][this.Naddavane] = true;
                            this.Obiavi[this.KoiZapochva][this.Naddavane] = true;
                            this.Obiaviavane(this.KoiZapochva, this.anons);
                            if (this.anons === 0) {
                                if (!this.watch) {
                                    this.props.m_pwin.SendGame('R');
                                }
                            }
                            return;
                        }
                    }
                }
            }
        }
    }

    OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);
        if (this.OP) {
            if (this.OP && GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                if (this.cursor !== GlobalConstants.CURSOR_WATCH) {
                    this.cursor = GlobalConstants.CURSOR_WATCH;
                    this.setState({});
                }
                return;
            }
            if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1 && this.pause === 0) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                        this.setState({});
                        return;
                    }
                }
            }
        }
        if (
            this.Faza > 4 &&
            this.ScaleRect(
                new CRect(
                    this.CardsPos[16 - this.hand].x,
                    this.CardsPos[16 - this.hand].y,
                    this.CardsPos[16 - this.hand].x + GlobalConstants.PLAYER_CARD_WIDTH,
                    this.CardsPos[16 - this.hand].y + GlobalConstants.PLAYER_CARD_HEIGHT
                )
            ).PtInRect(point) &&
            this.k[16 - this.hand] !== 0
        ) {
            if (this.ProverkaCursor(16 - this.hand)) {
                this.cursor = GlobalConstants.CURSOR_HAND;
            } else {
                if (this.KoiENaRed !== 1) {
                    this.cursor = GlobalConstants.CURSOR_WAIT;
                } else {
                    this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                }
            }
            this.setState({});
            return;
        } else {
            for (let i = 1; i < 16 - this.hand; i++) {
                if (this.Faza > 4 && this.k[i] !== 0 && this.ScaleRect(this.CardRect[i]).PtInRect(point)) {
                    if (this.ProverkaCursor(i)) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                    } else {
                        if (this.KoiENaRed !== 1) {
                            this.cursor = GlobalConstants.CURSOR_WAIT;
                        } else {
                            this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                        }
                    }
                    this.setState({});
                    return;
                }
            }
        }
        if (!this.Move && !this.watch) {
            if (this.Faza === 4 && this.KoiZapochva === 1 && this.OtkritTalon === 1) {
                for (let i = 1; i < 5; i++) {
                    if (this.ScaleRect(this.TalonRect[i]).PtInRect(point)) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                        this.setState({});
                        return;
                    }
                }
                if (this.SelectT > 0) {
                    for (let i = 1; i < 17; i++) {
                        if (this.ScaleRect(this.CardRect[i]).PtInRect(point)) {
                            this.cursor = GlobalConstants.CURSOR_HAND;
                            this.setState({});
                            return;
                        }
                    }
                }
            }
        }

        if (!this.watch) {
            if (this.Faza === 4 && this.KoiZapochva === 1) {
                if (
                    new CRect(
                        this.ButtonStartPoint.x * GlobalVariables.scale,
                        this.ButtonStartPoint.y * GlobalVariables.scale,
                        this.ButtonStartPoint.x * GlobalVariables.scale + 44 * GlobalVariables.scale,
                        this.ButtonStartPoint.y * GlobalVariables.scale + 18 * GlobalVariables.scale
                    ).PtInRect(point)
                ) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                    this.setState({});
                    return;
                }
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    DrawCards() {
        if (this.KoiENaRed < 0) {
            return null;
        }
        var res = [];
        if (this.Faza === 4 && this.KoiZapochva === 1 && !this.watch) {
            res.push(
                <CPicture
                    image={'blato/UP.png'}
                    m_x={this.ButtonStartPoint.x}
                    m_y={this.ButtonStartPoint.y}
                    m_Width={51}
                    m_Height={22}
                    key={'StartButton'}
                    cursor={this.cursor}
                />
            );
        }
        for (let i = 1; i <= 48; i++) {
            if (this.k[i] !== 0) {
                if (this.CardsPoint[i].x !== this.CardsPos[0].x && this.CardsPoint[i].y !== this.CardsPos[0].y) {
                    if (i > 16) {
                        res.push(
                            <DrawCard
                                image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                m_x={this.CardsPoint[i].x}
                                m_y={this.CardsPoint[i].y}
                                key={'card' + i}
                                cursor={this.cursor}
                            />
                        );
                    } else {
                        res.push(
                            <DrawCard
                                image={this.CardP[i].image}
                                m_x={this.CardsPoint[i].x}
                                m_y={this.CardsPoint[i].y}
                                cursor={this.cursor}
                                key={'card' + i}
                                big={true}
                            />
                        );
                    }
                }
            }
        }
        for (let i = 49; i <= 52; i++) {
            if (this.k[i] !== 0) {
                if (this.CardsPoint[i].x !== this.CardsPos[0].x && this.CardsPoint[i].y !== this.CardsPos[0].y) {
                    if (this.SelectT === i) {
                        res.push(
                            <DrawCard
                                image={this.CardP[i * this.OtkritTalon].image}
                                m_x={this.CardsPoint[i].x}
                                m_y={this.CardsPoint[i].y - 4}
                                key={'card' + i}
                                cursor={this.cursor}
                                big={this.OtkritTalon ? true : false}
                            />
                        );
                    } else {
                        res.push(
                            <DrawCard
                                image={this.CardP[i * this.OtkritTalon].image}
                                m_x={this.CardsPoint[i].x}
                                m_y={this.CardsPoint[i].y}
                                key={'card' + i}
                                cursor={this.cursor}
                                big={this.OtkritTalon ? true : false}
                            />
                        );
                    }
                }
            }
        }
        if (this.Razdavane1 === true) {
            for(let i=0;i<this.showrcard;i++) {
                res.push(
                    <DrawCard
                        image={this.CardP[0].image}
                        m_x={this.CardsPoint[0].x - (i+1) * 0.2}
                        m_y={this.CardsPoint[0].y - (i+1) * 0.4}
                        cursor={this.cursor}
                        key={'card_'+(i+1).toString()}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 1) {
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card53'}
                    />
                );
            }
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card54'}
                    />
                );
            }
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[55].y}
                        cursor={this.cursor}
                        key={'card55'}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card54'}
                    />
                );
            }
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[55].y}
                        cursor={this.cursor}
                        key={'card55'}
                    />
                );
            }
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card53'}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 3) {
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[55].y}
                        cursor={this.cursor}
                        key={'card55'}
                    />
                );
            }
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card53'}
                    />
                );
            }
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        big={true}
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                        cursor={this.cursor}
                        key={'card54'}
                    />
                );
            }
        }
        return res;
    }

    async LoadBitmaps() {
        let face = await this.GetProfileString('Options', 'facenew', 'cards_1');
        let cardPicture = 1;
        for (let i = 100; i <= 400; i = i + 100) {
            for (let a = 2; a <= 14; a++) {
                this.CardPicture[cardPicture] = 'cards/' + face + '/' + this.IntToString(i + a) + '.gif';
                cardPicture++;
            }
        }

        let back = await this.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        let grab = await this.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.CardPicture[0] = 'cards/' + back + '/' + this.IntToString(grab) + '.gif';
        this.CardP[0].image = this.CardPicture[0];

        this.LoadFiles();
    }

    DrawObiaviavaneRN() {
        return this.obiaviavaneRN;
    }

    DrawView() {
        return (
            <View>
                <DrawNames name={this.Name} position={this.NamePos} cursor={this.cursor} key={'names'} />
                {this.DrawTimer()}
                {this.DrawPause()}
                {this.DrawRating()}
                {this.DrawResult()}
                {this.DrawPoints()}
                {this.DrawHands()}
                {this.DrawCards()}
                <DrawBid
                    Naddavane={this.Naddavane}
                    kojKazal={this.KoiZapochva}
                    positionBid={this.CardsPos[this.KoiZapochva === 1 ? 1 : this.KoiZapochva === 2 ? 17 : 33]}
                    game={'Blato'}
                />
                {this.DrawObiaviavaneRN()}
                {this.DrawStrelkaPng(this.strelka, this.props.m_pwin.game)}
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
            </View>
        );
    }

    render() {
        if (this.props.m_pwin.game === 'Blato' && !this.loading && !this.loadingMain) {
            if (Platform.OS === 'web') {
                return (
                    <View key={'webview'}>
                        <div key={'mousemoveBlato'} onMouseMove={this.OnMouseMove.bind(this)}>
                            {this.DrawView()}
                        </div>
                    </View>
                );
            } else {
                return <View key={'mobileviewBlato'}>{this.DrawView()}</View>;
            }
        } else {
            return null;
        }
    }
}

export default CBlato;
