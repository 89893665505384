import React, { Component } from 'react';
import { View, KeyboardAvoidingView, TextInput, Keyboard, Pressable, Dimensions, Platform, StyleSheet, Image, ImageBackground } from 'react-native';
import AppleLogin from '~/Components/AuthButtons/AppleLogin';
import FacebookLogin from '~/Components/AuthButtons/FacebookLogin';
import GoogleLogin from '~/Components/AuthButtons/GoogleLogin';
import Checkbox from '~/Components/core/Checkbox';
import LanguagePicker from '~/Components/core/LanguagePicker';
import Text from '~/Components/core/Text';
import GlobalConstants from '~/Components/GlobalConstants';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import Loader from '../Components/core/Loader';
import GlobalVariables from '../Components/GlobalVariables';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import { Ionicons } from '@expo/vector-icons';
import t from '../../translation';
import config from '~/config';

class OnlineRegistration extends Component<BaseDlgProps> {
    m_email: string = '';
    m_fname: string = '';
    m_lname: string = '';
    m_password: string = '';
    m_username: string = '';
    m_server: string;
    m_gamenum: string = '';
    passwordVisibility: boolean = true;
    passwordConfirmVisibility: boolean = true;
    lnameRef: TextInput;
    emailRef: TextInput;
    usernameRef: TextInput;
    passwordRef: TextInput;
    m_hiddenUsername: string = '';
    m_guest: boolean = false;
    action: string = '';
    agree: boolean = true;
    lp: LanguagePicker;

    constructor(props: BaseDlgProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    loading: boolean = false;

    CheckEmail(email: string) {
        const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) === true) {
            return true;
        } else {
            return false;
        }
    }

    CheckUsernameAndPassword(s: string) {
        return this.props.m_pwin.m_main.CheckUsernameAndPassword(s);
    }

    CheckForm() {
        this.m_email = this.m_email.trim();
        if (this.m_fname.indexOf('|') >= 0) {
            this.m_fname = this.m_fname.replace('|', '');
        }
        if (this.m_fname.indexOf('*') >= 0) {
            this.m_fname = this.m_fname.replace('*', '');
        }
        if (this.m_lname.indexOf('|') >= 0) {
            this.m_lname = this.m_lname.replace('|', '');
        }
        if (this.m_lname.indexOf('*') >= 0) {
            this.m_lname = this.m_lname.replace('*', '');
        }
        if (this.m_fname === '') {
            this.m_fname = '-';
        }
        if (this.m_lname === '') {
            this.m_lname = '-';
        }
        if (this.m_email === '') {
            return 'Please enter a Email';
        }
        if (!this.CheckEmail(this.m_email)) {
            return 'Please enter a valid Email';
        }
        if (this.m_username === '') {
            return 'Please enter a Username';
        }
        if (this.m_password === '') {
            return 'Please enter a Password';
        }
        if (!this.CheckUsernameAndPassword(this.m_username)) {
            return 'Unacceptable characters in the username';
        }
        if (!this.agree) {
            return 'Please agree to the privacy policy';
        }
        if (!this.CheckUsernameAndPassword(this.m_password)) {
            return 'Unacceptable characters in the password';
        }
        if (this.m_password.length < 3) {
            return 'The password must be greater than 2 chars';
        }
        if (this.m_username.length < 3) {
            return 'The username must be greater than 2 chars';
        }
        return '';
    }

    async OnOK() {
        let error = this.CheckForm();
        this.m_server = await this.props.m_pwin.m_main.GetProfileString('Options', 'cserver', this.props.m_pwin.m_main.GetDefaultServerName());
        if (error !== '') {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(error);
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        this.loading = true;
        this.setState({});
        await this.props.m_pwin.Registration(
            'I|' +
                this.m_fname +
                '|' +
                this.m_lname +
                '|' +
                this.m_email +
                '|' +
                this.m_username +
                '|' +
                this.m_password +
                '|' +
                (await this.props.m_pwin.m_main.GetGameNumber()) +
                '|' +
                this.props.m_pwin.m_main.GetOnlineVersion() +
                '|' +
                (await this.props.m_pwin.m_main.GetLanguage()) +
                '|Auto|' +
                (await this.props.m_pwin.m_main.GetAffiliate()) +
                '|' +
                this.props.m_pwin.m_main.GetOSVersion() +
                '|' +
                this.props.m_pwin.ipAddress +
                '|'
        );
        this.loading = false;
        this.setState({});
    }

    setPasswordVisibility(passwordVisibility: boolean) {
        this.passwordVisibility = passwordVisibility;
        this.setState({});
    }
    setPasswordConfirmVisibility(passwordConfirmVisibility) {
        this.passwordConfirmVisibility = passwordConfirmVisibility;
        this.setState({});
    }

    async loginAsGuest() {
        this.m_hiddenUsername = '';
        this.loading = true;
        this.setState({});
        this.m_guest = true;
        this.props.m_pwin.login = false;
        this.action = '';
        await this.props.m_pwin.ConnectToServer();
        this.setState({});
    }

    toggleAgree() {
        this.agree = !this.agree;
        this.setState({});
    }

    OnCancel() {
        this.props.m_pwin.setState({ OnlineRegistrationVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.OnlineRegistrationVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={[dialog.dialogView, cstyle.screenSize]}>
                        <View style={cstyle.content}>
                            <View style={cstyle.leftSide}>
                                <View style={cstyle.logoRow}>
                                    <Image
                                        source={require('../../assets/Images/favicon.png')}
                                        style={{
                                            width: 66 * GlobalVariables.scale,
                                            height: 66 * GlobalVariables.scale,
                                        }}
                                    />
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                        FAVORITE
                                    </Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                        GAMES
                                    </Text>
                                </View>
                                <View style={cstyle.leftSideCenterContent}>
                                    <View style={[cstyle.loginTitleContainer, { paddingTop: 24 * GlobalVariables.scale }]}>
                                        <Text textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                            Create Account
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            cstyle.loginDescriptionContainer,
                                            { paddingTop: 7 * GlobalVariables.scale, paddingBottom: 21 * GlobalVariables.scale },
                                        ]}
                                    >
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            Play online
                                        </Text>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            with real Players
                                        </Text>
                                    </View>
                                    <KeyboardAvoidingView behavior={'position'} style={[{ paddingBottom: 5 * GlobalVariables.scale }]}>
                                        <View style={cstyle.fieldsContainer}>
                                            <View
                                                style={[
                                                    cstyle.homeInputStyle,
                                                    { height: 40 * GlobalVariables.scale, marginVertical: 10 * GlobalVariables.scale },
                                                ]}
                                            >
                                                <TextInput
                                                    blurOnSubmit={false}
                                                    ref={(ref) => {
                                                        this.emailRef = ref;
                                                    }}
                                                    onSubmitEditing={() => {
                                                        this.usernameRef.focus();
                                                    }}
                                                    autoFocus={false}
                                                    placeholder={t.t('Email')}
                                                    autoCapitalize="none"
                                                    maxLength={64}
                                                    underlineColorAndroid="#f000"
                                                    keyboardType="email-address"
                                                    returnKeyType="next"
                                                    textContentType="emailAddress"
                                                    onChangeText={(email) => (this.m_email = email)}
                                                    placeholderTextColor="#BCC1CB"
                                                    testID={'emailTestId'}
                                                    autoCorrect={false}
                                                    multiline={false}
                                                    style={{
                                                        height: 40 * GlobalVariables.scale,
                                                        fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale,
                                                    }}
                                                />
                                            </View>
                                            <View
                                                style={[
                                                    cstyle.homeInputStyle,
                                                    { height: 40 * GlobalVariables.scale, marginVertical: 10 * GlobalVariables.scale },
                                                ]}
                                            >
                                                <TextInput
                                                    blurOnSubmit={false}
                                                    ref={(ref) => {
                                                        this.usernameRef = ref;
                                                    }}
                                                    onSubmitEditing={() => {
                                                        this.passwordRef.focus();
                                                    }}
                                                    autoFocus={false}
                                                    onChangeText={(username) => (this.m_username = username)}
                                                    placeholder={t.t('Username')}
                                                    autoCapitalize="none"
                                                    keyboardType="default"
                                                    returnKeyType="next"
                                                    maxLength={10}
                                                    underlineColorAndroid="#f000"
                                                    defaultValue=""
                                                    textContentType="oneTimeCode"
                                                    placeholderTextColor="#BCC1CB"
                                                    testID={'usernameRegTestId'}
                                                    autoCorrect={false}
                                                    multiline={false}
                                                    style={{
                                                        height: 40 * GlobalVariables.scale,
                                                        fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale,
                                                    }}
                                                />
                                            </View>
                                            <View
                                                style={[
                                                    cstyle.homeInputStyle,
                                                    { height: 40 * GlobalVariables.scale, marginVertical: 10 * GlobalVariables.scale },
                                                ]}
                                            >
                                                <TextInput
                                                    blurOnSubmit={false}
                                                    ref={(ref) => {
                                                        this.passwordRef = ref;
                                                    }}
                                                    onSubmitEditing={() => {
                                                        Keyboard.dismiss;
                                                        this.OnOK();
                                                    }}
                                                    autoFocus={false}
                                                    textContentType="password"
                                                    onChangeText={(password) => (this.m_password = password)}
                                                    placeholder={t.t('Password')}
                                                    keyboardType="default"
                                                    maxLength={12}
                                                    underlineColorAndroid="#f000"
                                                    returnKeyType="done"
                                                    secureTextEntry={this.passwordVisibility}
                                                    defaultValue=""
                                                    autoCapitalize="none"
                                                    placeholderTextColor="#BCC1CB"
                                                    testID={'passwordRegTestId'}
                                                    autoCorrect={false}
                                                    multiline={false}
                                                    style={{
                                                        height: 40 * GlobalVariables.scale,
                                                        fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale,
                                                    }}
                                                />
                                            </View>
                                            <View style={cstyle.saveContainer}>
                                                <View style={cstyle.rememberContainer}>
                                                    <Checkbox checked={this.agree} setChecked={this.toggleAgree.bind(this)} />
                                                    <View style={{ paddingLeft: 5 * GlobalVariables.scale }}>
                                                        <Pressable onPress={this.toggleAgree.bind(this)}>
                                                            <Text textSize={GlobalConstants.EXTRA_SMALL_FONT_SIZE} color={'black'}>
                                                                I agree to the privacy policy
                                                            </Text>
                                                        </Pressable>
                                                    </View>
                                                    <Pressable
                                                        onPress={() =>
                                                            this.props.m_pwin.openPage(
                                                                this.props.m_pwin.m_main.language === 'Bulgarian'
                                                                    ? 'https://favorite-games.com/html/privacy_policy.php'
                                                                    : 'https://favorite-games.com/htmlen/privacy_policy.php',
                                                                'Favorite-Games.com'
                                                            )
                                                        }
                                                        style={{ paddingLeft: 5 * GlobalVariables.scale }}
                                                    >
                                                        <Ionicons name={'help-circle-outline'} color={'black'} size={18 * GlobalVariables.scale} />
                                                    </Pressable>
                                                </View>
                                            </View>
                                        </View>
                                    </KeyboardAvoidingView>
                                    <View>
                                        <Pressable onPress={() => this.OnOK()} style={[cstyle.loginButton, { height: 40 * GlobalVariables.scale }]}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>Register</Text>
                                        </Pressable>
                                    </View>
                                    <View style={[cstyle.orLoginWithContainer, { paddingTop: 15 * GlobalVariables.scale }]}>
                                        <View style={cstyle.grayBorder} />
                                        <Text textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE} color={'#BCC1CB'}>
                                            or login with
                                        </Text>
                                        <View style={cstyle.grayBorder} />
                                    </View>
                                    <View style={[cstyle.loginWithContainer, { paddingTop: 15 * GlobalVariables.scale }]}>
                                        {Platform.OS === 'ios' ? <AppleLogin cCardGamesViewRef={this.props.m_pwin} /> : null}
                                        <FacebookLogin cCardGamesViewRef={this.props.m_pwin} />
                                        {Platform.OS !== 'android' ? <GoogleLogin cCardGamesViewRef={this.props.m_pwin} /> : null}
                                    </View>
                                </View>
                                <View style={cstyle.leftSideRightContent} />
                            </View>
                            <View style={cstyle.rightSide}>
                                <ImageBackground
                                    source={config.GAME === 'Bridge' ? require('../../assets/Images/Bridge/home_screen_background.jpg') :  require('../../assets/Images/HomeScreen/home_screen_background.jpg')}
                                    resizeMode="cover"
                                    style={[styles.W100, styles.h100]}
                                >
                                    <View style={cstyle.closeContainer}>
                                        <View style={cstyle.navigationContainer}>
                                            <Pressable
                                                onPress={() => this.props.m_pwin.OnNewGame()}
                                                style={{ paddingTop: 24 * GlobalVariables.scale, paddingRight: 20 * GlobalVariables.scale }}
                                            >
                                                <Ionicons name={'arrow-back-circle-outline'} color={'black'} size={48 * GlobalVariables.scale} />
                                            </Pressable>
                                            <Pressable
                                                onPress={() => this.OnCancel()}
                                                style={{ paddingTop: 24 * GlobalVariables.scale, paddingRight: 20 * GlobalVariables.scale }}
                                            >
                                                <Ionicons name={'close-circle-outline'} color={'black'} size={48 * GlobalVariables.scale} />
                                            </Pressable>
                                        </View>
                                    </View>
                                    <View style={cstyle.titleContainer}>
                                        <Text textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                            PLAY NOW
                                        </Text>
                                    </View>
                                    <View style={cstyle.descriptionContainer}>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            Play online as guest
                                        </Text>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            or against computer
                                        </Text>
                                    </View>
                                    <View style={cstyle.buttonsContainer}>
                                        <Pressable
                                            onPress={() => {
                                                this.loginAsGuest();
                                                this.props.m_pwin.showAd();
                                            }}
                                            style={[cstyle.playButton, { height: 40 * GlobalVariables.scale }]}
                                        >
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>Login as guest</Text>
                                        </Pressable>
                                        <Pressable
                                            onPress={async () => {
                                                this.props.m_pwin.OnOfflineGame();
                                                this.props.m_pwin.showAd();
                                            }}
                                            style={[cstyle.playButton, { height: 40 * GlobalVariables.scale }]}
                                        >
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>Local Game</Text>
                                        </Pressable>
                                    </View>
                                    <View style={cstyle.languageContainer}>
                                        <View style={cstyle.closeContainer}>
                                            <View style={[cstyle.languagePicker, { paddingRight: 20 * GlobalVariables.scale }]}>
                                                <LanguagePicker onRef={(ref: LanguagePicker) => (this.lp = ref)} m_pwin={this.props.m_pwin} />
                                            </View>
                                        </View>
                                    </View>
                                </ImageBackground>
                            </View>
                        </View>
                        <Loader loading={this.loading} />
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default OnlineRegistration;

const cstyle = StyleSheet.create({
    navigationContainer: { flexDirection: 'row' },
    registerContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '12%' },
    loginWithContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
    orLoginWithContainer: { flexDirection: 'row', width: '96%', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: '2%' },
    grayBorder: { width: '20%', height: 1, backgroundColor: '#BCC1CB' },
    loginDescriptionContainer: {
        alignItems: 'center',
        height: '16%',
    },
    loginTitleContainer: { justifyContent: 'center', alignItems: 'center', height: '10.5%' },
    loginButton: { width: '100%', backgroundColor: '#FF0D0D', justifyContent: 'center', alignItems: 'center' },
    rememberContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingVertical: 12 * GlobalVariables.scale },
    homeInputStyle: {
        backgroundColor: '#F2F4F8',
        width: '100%',
    },
    saveContainer: { flexDirection: 'row', width: '100%', justifyContent: 'space-between' },
    form: {
        flexDirection: 'row',
        width: '100%',
        height: '42%',
    },
    fieldsContainer: {
        alignItems: 'center',
        paddingHorizontal: 5 * GlobalVariables.scale,
        width: '100%',
    },
    leftSideCenterContent: { width: '60%' },
    leftSideRightContent: { width: '20%' },
    languagePicker: {},
    descriptionText: { fontWeight: '600' },
    playNowText: { fontWeight: '700' },
    textStyle: { color: 'black' },
    playButton: {
        width: '46%',
        backgroundColor: 'black',
        marginHorizontal: '2%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeContainer: { justifyContent: 'flex-start', alignItems: 'flex-end', height: '25%' },
    titleContainer: { justifyContent: 'center', alignItems: 'center', height: '15%' },
    descriptionContainer: { justifyContent: 'center', alignItems: 'center', height: '20%' },
    buttonsContainer: { flexDirection: 'row', height: '25%' },
    languageContainer: { height: '15%' },
    logoRow: { width: '20%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 20 * GlobalVariables.scale },
    leftSide: { width: '50%', height: Platform.OS === 'web' ? '100vh' : '100%', flexDirection: 'row', backgroundColor: 'white' },
    rightSide: { width: '50%', height: Platform.OS === 'web' ? '100vh' : '100%' },
    content: { flexDirection: 'row', width: '100%' },
    screenSize: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});
