import { Component } from 'react';
import React from 'react';
import CPicture from '../cppComponents/CPicture';
import { DrawCardProps } from '../props/DrawCardProps';
import GlobalConstants from '../GlobalConstants';

class DrawCard extends Component<DrawCardProps> {
    constructor(props: DrawCardProps) {
        super(props);
    }

    render() {
        return (
            <CPicture
                image={this.props.image}
                m_x={this.props.m_x}
                m_y={this.props.m_y}
                m_Width={
                    this.props.rotate
                        ? GlobalConstants.CARD_HEIGHT
                        : this.props.big
                        ? GlobalConstants.PLAYER_CARD_WIDTH
                        : this.props.middle
                        ? GlobalConstants.CARD_WIDTH + (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2
                        : GlobalConstants.CARD_WIDTH
                }
                m_Height={
                    this.props.rotate
                        ? GlobalConstants.CARD_WIDTH
                        : this.props.big
                        ? GlobalConstants.PLAYER_CARD_HEIGHT
                        : this.props.middle
                        ? GlobalConstants.CARD_HEIGHT + (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2
                        : GlobalConstants.CARD_HEIGHT
                }
                cursor={this.props.cursor}
                folder={this.props.big || this.props.middle ? 'files_2.0' : null}
            />
        );
    }
}

export default DrawCard;
