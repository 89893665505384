import React, { Component } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import Button from '../Components/core/Button';
import GlobalConstants from '../Components/GlobalConstants';
import Text from '../Components/core/Text';
import GlobalVariables from '../Components/GlobalVariables';
import { dialog } from '../../css/dialog';
import { Picker } from '@react-native-picker/picker';
import { modal } from '../../css/modal';
import PlayersFilter from './PlayersFilter';

class Dlg432Players extends Component<BaseDlgProps> {
    Name: string[] = [];
    Title: string;
    Players: number;
    m_pl1: string;
    ok: boolean = false;
    start: number;
    m_pl2 = [{ label: 'Computer', value: 'Computer' }];
    m_pl3 = [{ label: 'Computer', value: 'Computer' }];
    m_pl4 = [{ label: 'Computer', value: 'Computer' }];
    m_pl2_selected: string;
    m_pl3_selected: string;
    m_pl4_selected: string;
    pf: PlayersFilter;

    state = {
        PlayersFilterVisible: false,
    };

    constructor(props: BaseDlgProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    OnInitDialog() {
        this.m_pl2 = [{ label: 'Computer', value: 'Computer' }];
        this.m_pl3 = [{ label: 'Computer', value: 'Computer' }];
        this.m_pl4 = [{ label: 'Computer', value: 'Computer' }];
        this.m_pl2_selected = 'Computer';
        this.m_pl3_selected = 'Computer';
        this.m_pl4_selected = 'Computer';
        this.Name[2] = 'Computer';
        this.Name[3] = 'Computer';
        this.Name[4] = 'Computer';
    }

    async DoModal() {
        this.props.m_pwin.state.Dlg432PlayersVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.Dlg432PlayersVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDCANCEL;
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    GetConnectedPlayers() {
        let players = 1;
        if (this.Name[2] !== 'Computer') {
            players = players + 1;
        }
        if (this.Name[3] !== 'Computer') {
            players = players + 1;
        }
        if (this.Name[4] !== 'Computer') {
            players = players + 1;
        }
        return players;
    }

    ConnectPlayer(name: string) {
        if (this.Players === 4) {
            this.m_pl2.push({ label: name, value: name });
            this.m_pl3.push({ label: name, value: name });
            this.m_pl4.push({ label: name, value: name });
            if (this.Name[2] === 'Computer') {
                this.m_pl2_selected = name;
                this.Name[2] = name;
                this.EndConnectPlayer();
                return;
            }
            if (this.Name[3] === 'Computer') {
                this.m_pl3_selected = name;
                this.Name[3] = name;
                this.EndConnectPlayer();
                return;
            }
            if (this.Name[4] === 'Computer') {
                this.m_pl4_selected = name;
                this.Name[4] = name;
                this.EndConnectPlayer();
                return;
            }
        }
        if (this.Players === 3) {
            this.m_pl2.push({ label: name, value: name });
            this.m_pl3.push({ label: name, value: name });
            if (this.Name[2] === 'Computer') {
                this.m_pl2_selected = name;
                this.Name[2] = name;
                this.EndConnectPlayer();
                return;
            }
            if (this.Name[3] === 'Computer') {
                this.m_pl3_selected = name;
                this.Name[3] = name;
                this.EndConnectPlayer();
                return;
            }
        }
        if (this.Players === 2) {
            this.m_pl2 = [{ label: name, value: name }];
            this.m_pl2_selected = name;
            this.Name[2] = name;
            this.EndConnectPlayer();
            return;
        }
    }

    EndConnectPlayer() {
        this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
        this.setState({});
    }
    DisconnectPlayer(name: string) {
        if (this.Players === 4) {
            if (this.m_pl2_selected === name) {
                this.Name[2] = 'Computer';
                this.m_pl2_selected = 'Computer';
            }
            if (this.m_pl3_selected === name) {
                this.Name[3] = 'Computer';
                this.m_pl3_selected = 'Computer';
            }
            if (this.m_pl4_selected === name) {
                this.Name[4] = 'Computer';
                this.m_pl4_selected = 'Computer';
            }
            this.m_pl2 = this.m_pl2.filter((item) => item.value !== name);
            this.m_pl3 = this.m_pl3.filter((item) => item.value !== name);
            this.m_pl4 = this.m_pl4.filter((item) => item.value !== name);
        }
        if (this.Players === 3) {
            if (this.m_pl2_selected === name) {
                this.Name[2] = 'Computer';
                this.m_pl2_selected = 'Computer';
            }
            if (this.m_pl3_selected === name) {
                this.Name[3] = 'Computer';
                this.m_pl3_selected = 'Computer';
            }
            this.m_pl2 = this.m_pl2.filter((item) => item.value !== name);
            this.m_pl3 = this.m_pl3.filter((item) => item.value !== name);
        }
        if (this.Players === 2) {
            this.m_pl2 = [{ label: 'Computer', value: 'Computer' }];
            this.m_pl2_selected = 'Computer';
            this.Name[2] = 'Computer';
        }
        this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
        this.setState({});
    }

    OnStart() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ Dlg432PlayersVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ Dlg432PlayersVisible: false });
    }

    OnSelchangePlayer2(selected: string) {
        this.m_pl2_selected = selected;
        if (this.m_pl3_selected === this.m_pl2_selected) {
            this.Name[3] = 'Computer';
            this.m_pl3_selected = 'Computer';
        }
        if (this.m_pl4_selected === this.m_pl2_selected) {
            this.Name[4] = 'Computer';
            this.m_pl4_selected = 'Computer';
        }

        if (this.Players === 2) {
            this.m_pl2_selected = this.Name[2];
        }
        this.Name[2] = this.m_pl2_selected;
        this.setState({});
    }
    OnSelchangePlayer3(selected: string) {
        this.m_pl3_selected = selected;

        if (this.m_pl2_selected === this.m_pl3_selected) {
            this.Name[2] = 'Computer';
            this.m_pl2_selected = 'Computer';
        }
        if (this.m_pl4_selected === this.m_pl3_selected) {
            this.Name[4] = 'Computer';
            this.m_pl4_selected = 'Computer';
        }

        this.Name[3] = this.m_pl3_selected;
        this.setState({});
    }

    OnSelchangePlayer4(selected: string) {
        this.m_pl4_selected = selected;
        if (this.m_pl3_selected === this.m_pl4_selected) {
            this.Name[3] = 'Computer';
            this.m_pl3_selected = 'Computer';
        }
        if (this.m_pl2_selected === this.m_pl4_selected) {
            this.Name[2] = 'Computer';
            this.m_pl2_selected = 'Computer';
        }
        this.Name[4] = this.m_pl4_selected;
        this.setState({});
    }

    getPl2() {
        return (
            <Picker
                style={[cstyles.pickerRow, { width: 190 * GlobalVariables.scale, height: 35, backgroundColor: 'white', color: 'black' }]}
                itemStyle={cstyles.pickerRow}
                selectedValue={this.m_pl2_selected}
                onValueChange={(itemValue) => this.OnSelchangePlayer2(itemValue)}
            >
                {this.m_pl2.map((player, index) => (
                    <Picker.Item label={player.label} value={player.value} key={'m_pl2' + index} />
                ))}
            </Picker>
        );
    }

    getPl4() {
        return (
            <Picker
                style={[cstyles.pickerRow, { width: 190 * GlobalVariables.scale, height: 35, backgroundColor: 'white', color: 'black' }]}
                itemStyle={cstyles.pickerRow}
                selectedValue={this.m_pl4_selected}
                onValueChange={(itemValue) => this.OnSelchangePlayer4(itemValue)}
            >
                {this.m_pl4.map((player, index) => (
                    <Picker.Item label={player.label} value={player.value} key={'m_pl4' + index} />
                ))}
            </Picker>
        );
    }

    getPl3() {
        return (
            <Picker
                style={[cstyles.pickerRow, { width: 190 * GlobalVariables.scale, height: 35, backgroundColor: 'white', color: 'black' }]}
                itemStyle={cstyles.pickerRow}
                selectedValue={this.m_pl3_selected}
                onValueChange={(itemValue) => this.OnSelchangePlayer3(itemValue)}
            >
                {this.m_pl3.map((player, index) => (
                    <Picker.Item label={player.label} value={player.value} key={'m_pl3' + index} />
                ))}
            </Picker>
        );
    }

    render() {
        if (this.props.m_pwin.state.Dlg432PlayersVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 28 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.Title}</Text>
                        </View>
                        <View style={[styles.row, { padding: '5%' }]}>
                            {this.Players !== 3 ? (this.Players === 2 ? this.getPl2() : this.getPl3()) : null}
                        </View>
                        {this.Players !== 2 ? (
                            <View style={cstyles.container}>
                                <View style={cstyles.left}>
                                    {this.props.m_pwin.game === 'Bridge' ? this.getPl2() : this.Players === 3 ? this.getPl3() : this.getPl4()}
                                </View>
                                <View style={cstyles.right}>{this.props.m_pwin.game === 'Bridge' ? this.getPl4() : this.getPl2()}</View>
                            </View>
                        ) : null}
                        <View style={[styles.row, { padding: '5%' }]}>
                            <Text noTranslate={true} textSize={GlobalConstants.LARGE_FONT_SIZE}>
                                {this.m_pl1}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Button
                                textSize={GlobalConstants.LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle3PerRow}
                                onPress={() => this.OnCancel()}
                                text="Exit"
                            />
                            <Button
                                textSize={GlobalConstants.LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle3PerRow}
                                onPress={() => this.props.m_pwin.OnOnlinegameIgnorelist(this.pf)}
                                text="Ignore Players"
                            />
                            <Button
                                textSize={GlobalConstants.LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle3PerRow}
                                onPress={() => this.OnStart()}
                                text="Start"
                            />
                        </View>
                    </View>
                    <PlayersFilter onRef={(ref: PlayersFilter) => (this.pf = ref)} parent={this} m_pwin={this.props.m_pwin} key={'PlayersFilter'} />
                </View>
            );
        } else {
            return null;
        }
    }
}

export default Dlg432Players;

const cstyles = StyleSheet.create({
    container: { flexDirection: 'row', width: '100%' },
    left: { alignItems: 'flex-start', width: '50%', padding: '5%' },
    right: { alignItems: 'flex-end', width: '50%', padding: '5%' },
    pickerRow: {
        height: 35,
        fontSize: GlobalConstants.MEDIUM_FONT_SIZE,
    },
});
