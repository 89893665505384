import React from 'react';
import { Text as RNText, TextProps } from 'react-native';

import t from '../../../translation';
import GlobalConstants from '../GlobalConstants';
import GlobalVariables from '../GlobalVariables';

type Props = TextProps & {
    textSize?: number;
    noTranslate?: boolean;
    scaleText?: boolean;
    disabled?: boolean;
    color?: string;
};

const activeColor = '#FFFFFF';
const disableColor = '#888888';

const Text = ({
    children,
    textSize = GlobalConstants.SMALL_FONT_SIZE,
    scaleText = true,
    noTranslate = false,
    disabled = false,
    color = activeColor,
    style,
    ...textProps
}: Props) => {
    const text = noTranslate ? (children as string) : t.t(children as string);

    return (
        <RNText
            style={[
                style,
                {
                    fontSize: scaleText ? textSize * GlobalVariables.scale : textSize,
                    color: disabled ? disableColor : color ? color : activeColor,
                },
            ]}
            children={text}
            {...textProps}
        />
    );
};

export default Text;
