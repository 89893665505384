import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class DlgDetails extends Component<ParentDlgProps> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    OnInitDialog() {}

    DoModal() {
        this.OnInitDialog();
        this.setState({});
    }

    OnOK() {
        this.props.m_pwin.setState({ DlgDetailsVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.DlgDetailsVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 80 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Details</Text>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle1PerRow} onPress={this.OnOK.bind(this)} text="Ок" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgDetails;
