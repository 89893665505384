import React, { Component } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { styles } from '../../../css/container';
import { dialog } from '../../../css/dialog';
import Button from '../../Components/core/Button';
import Text from '../../Components/core/Text';
import CPicture from '../../Components/cppComponents/CPicture';
import GlobalConstants from '../../Components/GlobalConstants';
import GlobalVariables from '../../Components/GlobalVariables';
import { ParentDlgProps } from '../../Components/props/ParentDlgProps';
import BiddingRow from './BiddingRow';

class Bidding_Bridge extends Component<ParentDlgProps> {
    naddavane: number;
    kontra: boolean = false;
    rekontra: boolean = false;
    klik_o: boolean;
    start: number;
    m_spa: number = -1;
    m_kar: number = -1;
    m_kup: number = -1;
    m_bk: number = -1;
    m_pik: number = -1;
    diskontra: boolean = false;
    disrekontra: boolean = false;
    pas: boolean;
    level: number;

    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnInitDialog() {
        this.pas = true;
        this.rekontra = false;
        this.kontra = false;
    }

    async DoModal() {
        this.props.m_pwin.state.Bidding_BridgeVisible = true;
        this.start = 1;
        this.OnInitDialog();
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.Bidding_BridgeVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ Bidding_BridgeVisible: false });
        return GlobalConstants.IDOK;
    }

    OnPass() {
        this.start = 0;
        this.kontra = false;
        this.rekontra = false;
        this.pas = true;
    }

    OnDouble() {
        this.kontra = true;
        this.pas = false; //
        this.start = 0;
    }

    OnRedouble() {
        this.rekontra = true;
        this.pas = false; //
        this.start = 0;
    }

    onPressOption(radioLevel: number, radioNaddavane: number) {
        this.naddavane = radioNaddavane;
        this.level = radioLevel;
        this.pas = false;
        this.kontra = false;
        this.rekontra = false;
        this.start = 0;
    }

    isDisableRadio(radioLevel: number, radioNaddavane: number): boolean {
        if (this.level > radioLevel) {
            return true;
        }
        if (this.level === radioLevel && this.naddavane >= radioNaddavane) {
            return true;
        }
        return false;
    }

    render() {
        if (this.props.m_pwin.state.Bidding_BridgeVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 70 * GlobalVariables.scale,
                                width: 650 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Bidding</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyles.borderContainer}>
                                <View style={cstyles.iconContainer}>
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]} />
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]}>
                                        <CPicture
                                            image={'bid/1-n.png'}
                                            m_x={0}
                                            m_y={0}
                                            folder="files_2.0"
                                            m_Width={45}
                                            m_Height={45}
                                            relative={true}
                                        />
                                    </View>
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]}>
                                        <CPicture
                                            image={'bid/2-n.png'}
                                            m_x={0}
                                            m_y={0}
                                            folder="files_2.0"
                                            m_Width={45}
                                            m_Height={45}
                                            relative={true}
                                        />
                                    </View>
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]}>
                                        <CPicture
                                            image={'bid/3-n.png'}
                                            m_x={0}
                                            m_y={0}
                                            folder="files_2.0"
                                            m_Width={45}
                                            m_Height={45}
                                            relative={true}
                                        />
                                    </View>
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]}>
                                        <CPicture
                                            image={'bid/4-n.png'}
                                            m_x={0}
                                            m_y={0}
                                            folder="files_2.0"
                                            m_Width={45}
                                            m_Height={45}
                                            relative={true}
                                        />
                                    </View>
                                    <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 50 * GlobalVariables.scale }]}>
                                        <CPicture
                                            image={'bid/5-n.png'}
                                            m_x={0}
                                            m_y={0}
                                            folder="files_2.0"
                                            m_Width={45}
                                            m_Height={45}
                                            relative={true}
                                        />
                                    </View>
                                </View>
                                <View>
                                    <BiddingRow parent={this} level={1} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={2} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={3} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={4} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={5} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={6} isDisableRadio={() => this.isDisableRadio} />
                                    <BiddingRow parent={this} level={7} isDisableRadio={() => this.isDisableRadio} />
                                </View>
                            </View>
                            <View>
                                <Button containerStyle={{ width: 150 * GlobalVariables.scale }} onPress={this.OnPass.bind(this)} text="Pass" />
                                <Button
                                    containerStyle={{ width: 150 * GlobalVariables.scale }}
                                    onPress={this.OnDouble.bind(this)}
                                    disabled={!this.diskontra}
                                    text="Double"
                                />
                                <Button
                                    containerStyle={{ width: 150 * GlobalVariables.scale }}
                                    onPress={this.OnRedouble.bind(this)}
                                    disabled={!this.disrekontra}
                                    text="Redouble"
                                />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default Bidding_Bridge;

const cstyles = StyleSheet.create({
    cell: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2 * GlobalVariables.scale,
    },
    iconContainer: { flexDirection: 'row' },
    borderContainer: {
        borderWidth: 1,
        borderColor: '#888888',
        marginBottom: 5 * GlobalVariables.scale,
    },
});
