import { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import GlobalVariables from '../GlobalVariables';
import { DrawTextProps } from '../props/DrawTextProps';
import CPicture from '../cppComponents/CPicture';

class DrawName extends Component<DrawTextProps> {
    constructor(props: DrawTextProps) {
        super(props);
    }

    render() {
        if (
            this.props.text !== '' &&
            this.props.text !== '*' &&
            this.props.position !== undefined &&
            this.props.position.x > 0 &&
            this.props.position.y > 0
        ) {
            let fontSize = 13;
            return (
                <View
                    style={[
                        styles.container,
                        {
                            left: this.props.position.x * GlobalVariables.scale,
                            top: this.props.position.y * GlobalVariables.scale,
                        },
                    ]}
                >
                    <CPicture relative={true} image={'names/left.png'} m_x={0} m_y={0} m_Height={26} m_Width={7} />
                    <View
                        style={[
                            styles.textBackground,
                            {
                                marginVertical: 2 * GlobalVariables.scale,
                                paddingLeft: 1 * GlobalVariables.scale,
                                paddingRight: 1 * GlobalVariables.scale,
                                marginLeft: -2 * GlobalVariables.scale,
                                marginRight: -2 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <Text
                            style={[
                                styles.text,
                                {
                                    fontSize: GlobalVariables.scale * fontSize,
                                },
                            ]}
                        >
                            {this.props.text}
                        </Text>
                    </View>
                    <CPicture relative={true} image={'names/right.png'} m_x={0} m_y={0} m_Height={26} m_Width={7} />
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DrawName;

export const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        flexDirection: 'row',
    },
    textBackground: {
        backgroundColor: 'rgb(232, 230, 179)',
        borderTopColor: 'rgb(44, 35, 29)',
        borderBottomColor: 'rgb(44, 35, 29)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
    },
    text: {
        fontWeight: 'bold',
    },
});
