import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../../css/container';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import { RadioButton } from 'react-native-paper';
import Text from '../../Components/core/Text';
import GlobalConstants from '~/Components/GlobalConstants';

class IgraBelotDlg extends Component<BaseDlgProps> {
    Game: number = 1;
    m_broi: number = 2;
    constructor(props) {
        super(props);
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraBelotDlgVisible: true });
    }

    OnSp() {
        if (this.m_broi === 0) {
            this.Game = 1;
        }
        if (this.m_broi === 1) {
            this.Game = 2;
        }
        if (this.m_broi === 2) {
            this.Game = 3;
        }
        this.props.m_pwin.CloseIgraBelot4();
    }

    async OnOp() {
        if (this.m_broi === 0) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Belote 2 Players');
            this.Game = 7;
        }
        if (this.m_broi === 1) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Belote 3 Players');
            this.Game = 8;
        }
        if (this.m_broi === 2) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Belote 4 Players');
            this.Game = 9;
        }
        this.props.m_pwin.CloseIgraBelot4();
    }

    async componentDidMount() {
        this.props.onRef(this);
        this.loadFinished();
    }

    async loadFinished() {
        let belot_players = await this.props.m_pwin.m_main.GetProfileInt('Options', 'belot_players', 4);
        this.m_broi = belot_players - 2;
        this.setState({});
    }

    OnIzhod() {
        this.props.m_pwin.setState({ IgraBelotDlgVisible: false });
        this.props.m_pwin.OnNewGame();
    }

    render() {
        if (this.props.m_pwin.state.IgraBelotDlgVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View style={styles.row}>
                                <RadioButton value="4" status={this.m_broi === 2 ? 'checked' : 'unchecked'} />
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Belote 4 Players</Text>
                            </View>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnIzhod.bind(this)} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraBelotDlg;
