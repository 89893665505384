import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import GlobalVariables from '../../Components/GlobalVariables';
import { BiddingRowProps } from '~/Components/props/BiddingRowProps';
import BiddingOption from './BiddingOption';
import Text from '~/Components/core/Text';
import GlobalConstants from '~/Components/GlobalConstants';

export default class BiddingRow extends Component<BiddingRowProps> {
    constructor(props: BiddingRowProps) {
        super(props);
    }

    render() {
        return (
            <View style={[cstyles.biddRow, { width: 70 * GlobalVariables.scale }]}>
                <View style={[cstyles.cell, { width: 70 * GlobalVariables.scale, height: 40 * GlobalVariables.scale }]}>
                    <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>{this.props.level.toString()}</Text>
                </View>
                <BiddingOption
                    level={this.props.level}
                    naddavane={1}
                    isDisableRadio={this.props.parent.isDisableRadio(this.props.level, 1)}
                    parent={this.props.parent}
                />
                <BiddingOption
                    level={this.props.level}
                    naddavane={2}
                    isDisableRadio={this.props.parent.isDisableRadio(this.props.level, 2)}
                    parent={this.props.parent}
                />
                <BiddingOption
                    level={this.props.level}
                    naddavane={3}
                    isDisableRadio={this.props.parent.isDisableRadio(this.props.level, 3)}
                    parent={this.props.parent}
                />
                <BiddingOption
                    level={this.props.level}
                    naddavane={4}
                    isDisableRadio={this.props.parent.isDisableRadio(this.props.level, 4)}
                    parent={this.props.parent}
                />
                <BiddingOption
                    level={this.props.level}
                    naddavane={5}
                    isDisableRadio={this.props.parent.isDisableRadio(this.props.level, 5)}
                    parent={this.props.parent}
                />
            </View>
        );
    }
}

const cstyles = StyleSheet.create({
    cell: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2 * GlobalVariables.scale,
    },
    biddRow: {
        flexDirection: 'row',
    },
});
