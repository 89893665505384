import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import { View } from 'react-native';
import CResultOKEY from '../../Dialogs/CResultOKEY';

class COKEY extends CMain {

    OKEY: number;

    Boards: number[][][] = [];//[5][2][20];
    Talon1: number[][] = []; //[4][50];
    Talon2: number[][] = [];//[10][5],
    Talon1br: number[] = [];//[10];
    Talon2br: number[] = [];//[10];
    PosToIn: number;
    moveblock: number;
    FinnishBlock: number;
    BroiHodove: number;
    FPlayer: number;
    HaveToTake: boolean[] = [];
    m_rez: CResultOKEY = new CResultOKEY(null);
    rez: number[][] = [];
    Points: number[] = [];
    BroiOstavashti: number;
    IsReady: boolean;
    Izlishni: number[][] = [];
    fin1: boolean;
    Shown: boolean;
    Talon1Pl: CPoint[] = [];
    Talon2Pl: CPoint[] = [];
    Talon1Re: CPoint[] = [];
    OKEYPlace: CPoint = new CPoint(0, 0);
    Talon2Re = [];
    NumbersPl: CPoint[] = [];
    BoardPlaces = [];
    BoardRect = [];
    FinnishPlaces = [];
    FinnishPlace: CPoint = new CPoint(0, 0);
    PointsPos: CPoint[] = [];
    StrelkaP: CPoint[] = [];


    constructor(props) {
        super(props);
        this.OKEY = 0;
        this.P2OP = false;
        this.P3OP = false;
        this.P4OP = false;
        this.Server = false;
        this.Faza = 0;
        this.Naddavane = 0;
        this.hand = 0;
        this.KoiENaRed = 0;
        this.Move = false;
        for (let i = 0; i < 5000; i++) {
            this.Izlishni[i] = [];
        }
        for (let i = 0; i < 5; i++) {
            this.rez[i] = [];
            this.Boards[i] = []
            for (let j: number = 0; j < 20; j++) {
                this.Boards[i][0] = [];
                this.Boards[i][1] = [];
                this.Boards[i][0][j] = 0;
                this.Boards[i][1][j] = 0;
            }
            for (let j: number = 0; j < 50; j++) {
                this.Talon1[i] = [];
                this.Talon1[i][j] = 0;
            }
            this.Talon1br[i] = 0;
        }
        this.PosToIn = -1;
        this.moveblock = -1;
        this.FinnishBlock = 0;
        this.BroiHodove = 0;
        this.FPlayer = 0;
        for (let i = 0; i < 4; i++) {
            this.Points[i] = 20;
            this.m_rez.frez[i + 1] = this.Points[i];
            this.HaveToTake[i] = false;
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 0; a < 50; a++) {
                this.rez[i][a] = -100;
            }
        }
        for (let a = 0; a < 2; a++) {
            this.BoardPlaces[a] = [];
            this.BoardRect[a] = [];
            this.FinnishPlaces[a] = [];
            for (let i = 0; i < 17; i++) {
                this.BoardPlaces[a][i] = new CPoint(0, 0);
                this.BoardRect[a][i] = new CPoint(0, 0);
                this.FinnishPlaces[a][i] = new CPoint(0, 0);
            }
        }
        for (let i = 0; i < 10; i++) {
            this.Talon2Re[i] = [];
            this.Talon2[i] = [];
            for (let j = 0; j < 5; j++) {
                this.Talon2[i][j] = 0;
                this.Talon2Re[i][j] = new CPoint(0, 0);
            }
            this.Talon2br[i] = 0;
        }
    }

    async InitSP() {
        this.SP = true;
        this.OP = false;
        await this.Init();
        this.InitGame();
    }

    async Init() {
        this.pause = 0;
        this.BroiOstavashti = 48;
        this.IsReady = false;
        this.Izlishni[0][0] = 20;
        this.FPlayer = 0;
        this.fin1 = false;
        this.Shown = false;
        this.BroiHodove = 0;
        this.Players = 4;
        this.Faza = 0;
        this.moveblock = -1;
        this.PosToIn = -1;
        if (this.P2OP === true) {
            this.P2G = false;
        }
        else this.P2G = true;
        if (this.P3OP === true) {
            this.P3G = false;
        }
        else this.P3G = true;
        if (this.P4OP === true) {
            this.P4G = false;
        }
        else this.P4G = true;
        this.FinnishBlock = 0;
        this.Talon1Pl[0].x = 695;
        this.Talon1Pl[0].y = 380;
        this.Talon1Pl[1].x = 695;
        this.Talon1Pl[1].y = 60;
        this.Talon1Pl[2].x = 70;
        this.Talon1Pl[2].y = 60;
        this.Talon1Pl[3].x = 70;
        this.Talon1Pl[3].y = 380;
        for (let i = 0; i < 4; i++) {
            this.Talon1Re[i].x = this.Talon1Pl[i].x;
            this.Talon1Re[i].y = this.Talon1Pl[i].y;
            this.Talon1br[i] = 0;
        }
        this.OKEYPlace.x = 500;
        this.OKEYPlace.y = 219;

        for (let i = 0; i < 5; i++) {
            this.Talon2Pl[i][0].x = 220 + i * 50;
            this.Talon2Pl[i][0].y = 150;
            this.Talon2Pl[i + 5][0].x = 220 + i * 50;
            this.Talon2Pl[i + 5][0].y = 220;
        }
        for (let i = 0; i < 10; i++) {
            for (let j: number = 0; j < 5; j++) {
                this.Talon2Pl[i][j].x = this.Talon2Pl[i][0].x - (j);
                this.Talon2Re[i][j].x = this.Talon2Pl[i][j].x;
                this.Talon2Pl[i][j].y = this.Talon2Pl[i][0].y - (j);
                this.Talon2Re[i][j].y = this.Talon2Pl[i][j].y;
            }
        }
        for (let i = 0; i < 5; i++) {
            this.NumbersPl[i].x = this.Talon2Pl[i][0].x + 5;
            this.NumbersPl[i].y = this.Talon2Pl[i][0].y - 22;
            this.NumbersPl[i + 5].x = this.Talon2Pl[i + 5][0].x + 5;
            this.NumbersPl[i + 5].y = this.Talon2Pl[i + 5][0].y + 48;
        }
        for (let i = 0; i < 17; i++) {
            this.BoardPlaces[0][i].x = 125 + 31 * i;
            this.BoardPlaces[0][i].y = 380;
            this.BoardPlaces[1][i].x = 125 + 31 * i;
            this.BoardPlaces[1][i].y = 440;
        }
        for (let i = 0; i < 17; i++) {
            this.BoardRect[0][i].x = 125 + 31 * i;
            this.BoardRect[0][i].y = 380;
            this.BoardRect[1][i].x = 125 + 31 * i;
            this.BoardRect[1][i].y = 440;
        }
        for (let i = 0; i < 17; i++) {
            this.FinnishPlaces[0][i].x = 125 + 31 * i;
            this.FinnishPlaces[0][i].y = 110;
            this.FinnishPlaces[1][i].x = 125 + 31 * i;
            this.FinnishPlaces[1][i].y = 170;
        }
        this.FinnishPlace.x = 350;
        this.FinnishPlace.y = 230;
        this.LoadBitmaps();
        this.KoiENaRed = this.RandKoiZapochva(this.Players);

        if (!this.OP) {
            this.Name[1] = await this.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.GetProfileString('Options', 'Name[2]', 'Computer2');
            this.Name[3] = await this.GetProfileString('Options', 'Name[2]', 'Computer2');
            this.Name[4] = await this.GetProfileString('Options', 'Name[4]', 'Computer2');
        }
        this.NamePos[1].x = 360; this.NamePos[1].y = 330;
        this.NamePos[2].x = 630; this.NamePos[2].y = 240;
        this.NamePos[3].x = 360; this.NamePos[3].y = 65;
        this.NamePos[4].x = 80; this.NamePos[4].y = 240;

        this.PosNaddavane[1].x = 340; this.PosNaddavane[1].y = 300;
        this.PosNaddavane[2].x = 610; this.PosNaddavane[2].y = 210;
        this.PosNaddavane[3].x = 340; this.PosNaddavane[3].y = 60;
        this.PosNaddavane[4].x = 100; this.PosNaddavane[4].y = 210;

        this.PointsPos[0].x = 360; this.PointsPos[0].y = 354;
        this.PointsPos[1].x = 630; this.PointsPos[1].y = 264;
        this.PointsPos[2].x = 360; this.PointsPos[2].y = 89;
        this.PointsPos[3].x = 80; this.PointsPos[3].y = 264;
        this.StrelkaP[1].x = 360; this.StrelkaP[1].y = 330;
        this.StrelkaP[2].x = 630; this.StrelkaP[2].y = 240;
        this.StrelkaP[3].x = 360; this.StrelkaP[3].y = 65;
        this.StrelkaP[4].x = 80; this.StrelkaP[4].y = 240;

    }

    async InitOP(server: boolean) {
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        this.InitGame();
        this.InitTimer(10);
        this.online_timer = this.online_timer_time * 4;
    }

    /*


void COKEY::OnLButtonUp(CCardGamesView*view, CPoint point)
{
    if(!watch) {
        if(this.moveblock>=0)
        {
            if(this.moveblock<200)
            {
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[0][i].x*scale && point.x < this.BoardRect[0][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[0][i].y*scale && point.y < this.BoardRect[0][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][0][i] === 0)
                        {
                            ChangePlaces(this.moveblock,i,1);
                        }
                    }
                }
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[1][i].x*scale && point.x < this.BoardRect[1][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[1][i].y*scale && point.y < this.BoardRect[1][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][1][i] === 0)
                        {
                            ChangePlaces(this.moveblock,i+100,1);
                        }
                    }
                }
                if(this.HaveToTake[0]&& Boards[1][this.moveblock/100][this.moveblock%100]<1000)
                {
                	
                    if(point.x > this.Talon1Pl[0].x*scale && point.x < this.Talon1Pl[0].x*scale+Joker.m_Width
                        && point.y > this.Talon1Pl[0].y*scale && point.y < this.Talon1Pl[0].y*scale+Joker.m_Height)
                    {
                        TakePool(this.moveblock,1);
                        sit_out_count=0;
                        if(this.OP) {
                            if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                if(!op_start) {
                                    SendGame(L"L");
                                    op_start=true;
                                }
                            }
                        }
                    }
                }
                if(this.HaveToTake[0])
                {
                    if(point.x > this.OKEYPlace.x*scale && point.x < this.OKEYPlace.x*scale+Joker.m_Width
                        && point.y > this.OKEYPlace.y*scale && point.y < this.OKEYPlace.y*scale+Joker.m_Height)
                    {
                        if(Finnished(1,this.moveblock)>0)
                        {
                            Finnished2(1,this.moveblock,Finnished(1,this.moveblock));
                        }
                    }
                }
                int x1=this.BoardPlaces[this.moveblock/100][this.moveblock%100].x;
                int y1=this.BoardPlaces[this.moveblock/100][this.moveblock%100].y;
                this.BoardPlaces[this.moveblock/100][this.moveblock%100].x=this.BoardRect[this.moveblock/100][this.moveblock%100].x;
                this.BoardPlaces[this.moveblock/100][this.moveblock%100].y=this.BoardRect[this.moveblock/100][this.moveblock%100].y;
            view->InvalidateRectScale(CRect(this.BoardPlaces[this.moveblock/100][this.moveblock%100].x-17,
                this.BoardPlaces[this.moveblock/100][this.moveblock%100].y-17,
                this.BoardPlaces[this.moveblock/100][this.moveblock%100].x+Joker.m_Width+17,
                this.BoardPlaces[this.moveblock/100][this.moveblock%100].y+Joker.m_Height+17),false);
            view->InvalidateRectScale(CRect(x1-17,y1-17,x1+Joker.m_Width+17,y1+Joker.m_Height+17),false);
            }
            if(this.moveblock>=200 && this.moveblock<300)
            {
                bool Played=false;
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[0][i].x*scale && point.x < this.BoardRect[0][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[0][i].y*scale && point.y < this.BoardRect[0][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][0][i] === 0)
                        {
                            GetPool(this.moveblock%100,1,i);
                            sit_out_count=0;
                            if(this.OP) {
                                if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                    if(!op_start) {
                                        SendGame(L"L");
                                        op_start=true;
                                    }
                                }
                            }
                            Played=true;
                        }
                    }
                }
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[1][i].x*scale && point.x < this.BoardRect[1][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[1][i].y*scale && point.y < this.BoardRect[1][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][1][i] === 0)
                        {
                            GetPool(this.moveblock%100,1,i+100);
                            sit_out_count=0;
                            if(this.OP) {
                                if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                    if(!op_start) {
                                        SendGame(L"L");
                                        op_start=true;
                                    }
                                }
                            }
                            Played=true;
                        }
                    }
                }
                if(!Played)
                    Talon2br[this.moveblock%100]--;
                int x1=this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].x;
                int y1=this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].y;
                this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].x=this.Talon2Re[this.moveblock%100][Talon2br[this.moveblock%100]].x;
                this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].y=this.Talon2Re[this.moveblock%100][Talon2br[this.moveblock%100]].y;
            view->InvalidateRectScale(CRect(this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].x-17,
                this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].y-17,
                this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].x+Joker.m_Width+17,
                this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]].y+Joker.m_Height+17),false);
            view->InvalidateRectScale(CRect(x1-25,y1-30,x1+Joker.m_Width+25,y1+Joker.m_Height+30),false);
                if(!Played)
                    Talon2br[this.moveblock%100]++;
            }
            if(this.moveblock==300)
            {
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[0][i].x*scale && point.x < this.BoardRect[0][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[0][i].y*scale && point.y < this.BoardRect[0][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][0][i] === 0)
                        {
                            GetPool(10,1,i);
                            sit_out_count=0;
                            if(this.OP) {
                                if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                    if(!op_start) {
                                        SendGame(L"L");
                                        op_start=true;
                                    }
                                }
                            }
                        }
                    }
                }
                for(int i=0;i<17;i++)
                {
                    if(point.x > this.BoardRect[1][i].x*scale && point.x < this.BoardRect[1][i].x*scale+Joker.m_Width
                        && point.y > this.BoardRect[1][i].y*scale && point.y < this.BoardRect[1][i].y*scale+Joker.m_Height)
                    {
                        if(Boards[1][1][i] === 0)
                        {
                            GetPool(10,1,i+100);
                            sit_out_count=0;
                            if(this.OP) {
                                if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                    if(!op_start) {
                                        SendGame(L"L");
                                        op_start=true;
                                    }
                                }
                            }
                        }
                    }
                }
                int x1=this.Talon1Pl[3].x;
                int y1=this.Talon1Pl[3].y;
                this.Talon1Pl[3].x=this.Talon1Re[3].x;
                this.Talon1Pl[3].y=this.Talon1Re[3].y;
            view->InvalidateRectScale(CRect(this.Talon1Pl[3].x-17,
                this.Talon1Pl[3].y-17,
                this.Talon1Pl[3].x+Joker.m_Width+17,
                this.Talon1Pl[3].y+Joker.m_Height+17),false);
            view->InvalidateRectScale(CRect(x1-17,y1-17,x1+Joker.m_Width+17,y1+Joker.m_Height+17),false);
            }

        }
        view->InvalidateRectScale(CRect(this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x-17,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y-25,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x+Joker.m_Width+17,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y+Joker.m_Height+25),false);
        this.PosToIn=-1;
        this.moveblock=-1;
    }
}

void COKEY::OnLButtonDown(CCardGamesView*view, CPoint point)
{
    if(this.OP) {
        for(int i=1;i<=4;i++) {
            if(chat_message[i]!=L"") {
                chat_message[i]=L"";
                m_pwin->InvalidateRectScale(CRect(this.PosNaddavane[i].x-10,this.PosNaddavane[i].y-80,this.PosNaddavane[i].x+600,this.PosNaddavane[i].y+20),true);
            }
        }
        int begin=2;
        if(watch)begin=1;
        for(int i=begin;i<=4;i++) {
            if(show_user[i]) {
                show_user[i]=false;
                ShellExecute(m_pwin->m_hWnd,L"open",GetLanguageText(L"domain")+L"/server/public/show_user.php?username="+Name[i]+L"&lang="+GetLanguage(), NULL,NULL,SW_SHOWNORMAL);
            }
        }
        if(!watch) {
            if(this.P2OP||this.P3OP||this.P4OP||!this.Server) {
                if(this.Faza==1&&this.KoiENaRed==1) {
                    if(ScaleRect(pause_rect).PtInRect(point)) {
                        if(this.pause==0) {
                            this.pause=1;
                            online_timer=180;
                            m_pwin->InvalidateRectScale(CRect(676,475,732, 505),false);
                            CString p_str=L"*|A|"+Name[1];
                            SendGame(p_str);
                        }
                    }
                }
            }
        }
    }
    if(!watch) {
        for(int i=0;i<17;i++)
        {
            if(point.x<this.BoardPlaces[0][i].x*scale+Joker.m_Width && point.x>this.BoardPlaces[0][i].x*scale &&
                point.y<this.BoardPlaces[0][i].y*scale+Joker.m_Height && point.y>this.BoardPlaces[0][i].y*scale)
            {
                if(Boards[1][0][i] > 0 )
                {
                    sit_out_count=0;
                    this.moveblock=i;
                }
            }
            if(point.x<this.BoardPlaces[1][i].x*scale+Joker.m_Width && point.x>this.BoardPlaces[1][i].x*scale &&
                point.y<this.BoardPlaces[1][i].y*scale+Joker.m_Height && point.y>this.BoardPlaces[1][i].y*scale)
            {
                if(Boards[1][1][i] > 0)
                {
                    sit_out_count=0;
                    this.moveblock=i+100;
                }
            }	
        }
        if(this.KoiENaRed==1) {
            if(HaveToGet[0])
            {
                for(int i=0;i<10;i++)
                {
                    if(Talon2br[i]>0 && (i==0 || Talon2br[i-1]==0))
                    {
                        if(point.x<this.Talon2Pl[i][Talon2br[i]-1].x*scale+Joker.m_Width && point.x>this.Talon2Pl[i][Talon2br[i]-1].x*scale &&
                            point.y<this.Talon2Pl[i][Talon2br[i]-1].y*scale+Joker.m_Height&& point.y>this.Talon2Pl[i][Talon2br[i]-1].y*scale)
                        {
                            sit_out_count=0;
                            this.moveblock=i+200;
                        }
                    }
                }
                if(this.Talon1br[3]>0)
                {
                    if(point.x<this.Talon1Pl[3].x*scale+Joker.m_Width && point.x>this.Talon1Pl[3].x*scale &&
                                point.y<this.Talon1Pl[3].y*scale+Joker.m_Height&& point.y>this.Talon1Pl[3].y*scale)
                    {	
                        sit_out_count=0;
                        this.moveblock=300;	
                    }
                }
            }
        }
    }
}

void COKEY::OnRButtonDown(CCardGamesView*view, CPoint point)
{
    if(watch) {
        CClientDC dc(m_pwin);
        for(int i=2;i<=4;i++) {
            if(Name[i]==L"Computer2"||Name[i]==L"Computer3"||Name[i]==L"Computer4") {
                CSize s=dc.GetTextExtent(Name[i]);
                int width=s.cx;
                if(ScaleRect(CRect(this.NamePos[i].x,this.NamePos[i].y,this.NamePos[i].x+width,this.NamePos[i].y+20)).PtInRect(point)) {					
                    m_pwin->GoToLobby();
                    m_pwin->m_dbo.auto_connect=Name[1];
                }
            }
        }
    }
    if(!watch) {
        for(int i=0;i<17;i++)
        {
            if(point.x<this.BoardPlaces[0][i].x*scale+Joker.m_Width && point.x>this.BoardPlaces[0][i].x*scale &&
                point.y<this.BoardPlaces[0][i].y*scale+Joker.m_Height && point.y>this.BoardPlaces[0][i].y*scale)
            {
                if(Boards[1][0][i] > 0 && Boards[1][0][i] < 1000)
                {
                    Boards[1][0][i]+=1000;
                    int HaveToBeInvalidate=i+1000;
                    int c=HaveToBeInvalidate%1000;
                    m_pwin->InvalidateRect(CRect((this.BoardPlaces[c/100][c%100].x-2)*scale,(this.BoardPlaces[c/100][c%100].y-3)*scale,(this.BoardPlaces[c/100][c%100].x+2)*scale+Joker.m_Width,(this.BoardPlaces[c/100][c%100].y+3)*scale+Joker.m_Height),false);
                    goto end;
                }
                if(Boards[1][0][i] > 1000)
                {
                    Boards[1][0][i]-=1000;
                    int HaveToBeInvalidate=i+1000;
                    int c=HaveToBeInvalidate%1000;
                    m_pwin->InvalidateRect(CRect((this.BoardPlaces[c/100][c%100].x-2)*scale,(this.BoardPlaces[c/100][c%100].y-3)*scale,(this.BoardPlaces[c/100][c%100].x+2)*scale+Joker.m_Width,(this.BoardPlaces[c/100][c%100].y+3)*scale+Joker.m_Height),false);
                    goto end;
                }
            	
            }
            if(point.x<this.BoardPlaces[1][i].x*scale+Joker.m_Width && point.x>this.BoardPlaces[1][i].x*scale &&
                point.y<this.BoardPlaces[1][i].y*scale+Joker.m_Height && point.y>this.BoardPlaces[1][i].y*scale)
            {
                if(Boards[1][1][i] > 0 && Boards[1][1][i] < 1000)
                {
                    Boards[1][1][i]+=1000;
                    int HaveToBeInvalidate=i+1100;
                    int c=HaveToBeInvalidate%1000;
                    m_pwin->InvalidateRect(CRect((this.BoardPlaces[c/100][c%100].x-2)*scale,(this.BoardPlaces[c/100][c%100].y-3)*scale,(this.BoardPlaces[c/100][c%100].x+2)*scale+Joker.m_Width,(this.BoardPlaces[c/100][c%100].y+3)*scale+Joker.m_Height),false);
                    goto end;
                }
                if(Boards[1][1][i] > 1000)
                {
                    Boards[1][1][i]-=1000;
                    int HaveToBeInvalidate=i+1100;
                    int c=HaveToBeInvalidate%1000;
                    m_pwin->InvalidateRect(CRect((this.BoardPlaces[c/100][c%100].x-2)*scale,(this.BoardPlaces[c/100][c%100].y-3)*scale,(this.BoardPlaces[c/100][c%100].x+2)*scale+Joker.m_Width,(this.BoardPlaces[c/100][c%100].y+3)*scale+Joker.m_Height),false);
                    goto end;
                }	
            }	
        }
    }
end:;
}

void COKEY::SendGame(CString msg)
{
    m_pwin->SendGame(msg);
}

void COKEY::SendWatchGameInitialization(int watch_index)
{
    CString message=L"$|z|"+IntToString(watch_index)+L"|";
    message=message+Name[1]+L"?"+Name[2]+L"?"+Name[3]+L"?"+Name[4]+L"?";
    //this.Faza
    message=message+IntToString(this.Faza)+L"?";
    message=message+IntToString(this.KoiENaRed)+L"?";
    for(int i=1;i<5;i++)
    {
        for(int a=0;a<=1;a++)
        {
            for(let j: number=0;j<17;j++)
            {
                message=message+IntToString(Boards[i][a][j])+L"?";
            }
        }
    }
    for(int i=1;i<=9;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            message=message+IntToString(Talon2[i][j])+L"?";
        }
    }
    for(let j: number=0;j<3;j++)
    {
        message=message+IntToString(Talon2[0][j])+L"?";
    }
    message=message+IntToString(OKEY)+L"?";
    for(int i=0;i<=9;i++)
    {
        message=message+IntToString(Talon2br[i])+L"?";
    }
    for(int i=0;i<4;i++)
    {
        message=message+IntToString(this.Talon1br[i])+L"?";
    }

    for(int i=0;i<4;i++)//risuva 4-te talona
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            message=message+IntToString(Talon1[i][j])+L"?";
        }
    }
    for(int i=0;i<4;i++)
    {
        message=message+IntToString(this.Points[i])+L"?";
    }
    message=message+IntToString(this.BroiHodove)+L"?";
    message=message+IntToString(this.FinnishBlock)+L"?";
    message=message+IntToString(this.FPlayer)+L"?";
    if(message!=L"") {
        SendGame(message);
    }
}

void COKEY::ReceiveWachGameInitialization(CString init)
{
    OnLButtonDown(m_pwin, CPoint(0,0));
    InitGame();
    int vpos=init.Find(L"?");
    CString n1str=init.Left(vpos);
    if(n1str!=L"")Name[1]=n1str;
    init.Delete(0,vpos+1);
    vpos=init.Find(L"?");
    Name[2]=init.Left(vpos);
    init.Delete(0,vpos+1);
    vpos=init.Find(L"?");
    Name[3]=init.Left(vpos);
    init.Delete(0,vpos+1);
    vpos=init.Find(L"?");
    Name[4]=init.Left(vpos);
    init.Delete(0,vpos+1);
	
    vpos=init.Find(L"?");
    CString s1=init.Left(vpos);
    this.Faza=_ttoi(s1);
    if(this.Faza<0) {
        goto end;
    }
    init.Delete(0,vpos+1);

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    this.KoiENaRed=_ttoi(s1);
    init.Delete(0,vpos+1);

    for(int i=1;i<5;i++)
    {
        for(int a=0;a<=1;a++)
        {
            for(let j: number=0;j<17;j++)
            {
                int vpos=init.Find(L"?");
                CString s1=init.Left(vpos);
                Boards[i][a][j]=_ttoi(s1);
                init.Delete(0,vpos+1);
            }
        }
    }
    for(int i=1;i<=9;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            int vpos=init.Find(L"?");
            CString s1=init.Left(vpos);
            Talon2[i][j]=_ttoi(s1);
            init.Delete(0,vpos+1);
        }
    }
    for(let j: number=0;j<3;j++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        Talon2[0][j]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    OKEY=_ttoi(s1);
    init.Delete(0,vpos+1);
    for(int i=0;i<=9;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        Talon2br[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    for(int i=0;i<4;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        this.Talon1br[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    for(int i=0;i<4;i++)
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            int vpos=init.Find(L"?");
            CString s1=init.Left(vpos);
            Talon1[i][j]=_ttoi(s1);
            init.Delete(0,vpos+1);
        }
    }
    for(int i=0;i<4;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        this.Points[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    this.BroiHodove=_ttoi(s1);
    init.Delete(0,vpos+1);

    if(this.Faza==2) {
        vpos=init.Find(L"?");
        s1=init.Left(vpos);
        this.FinnishBlock=_ttoi(s1);
        init.Delete(0,vpos+1);

        vpos=init.Find(L"?");
        s1=init.Left(vpos);
        this.FPlayer=_ttoi(s1);
        init.Delete(0,vpos+1);
    }

end:;
    m_pwin->GetPlayerRating(1);
    m_pwin->GetPlayerRating(2);
    m_pwin->GetPlayerRating(3);
    m_pwin->GetPlayerRating(4);
    m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);
}

void COKEY::SendNames(int player)
{
    if(player==2)
    {
        CString message=L"$|t|2|"+Name[3]+L"|"+Name[4]+L"|"+Name[1];
        SendGame(message);
    }
    if(player==3)
    {
        CString message=L"$|t|3|"+Name[4]+L"|"+Name[1]+L"|"+Name[2];
        SendGame(message);
    }
    if(player==4)
    {
        CString message=L"$|t|4|"+Name[1]+L"|"+Name[2]+L"|"+Name[3];
        SendGame(message);
    }
}

void COKEY::InvalidateNames(int player)
{
    if (player === 0) {
        for (int i = 1; i <= this.Players; i++) {
            m_pwin->InvalidateRect(CRect((this.NamePos[i].x - 1) * scale + 1 - nameLeft.GetWidth(), (this.NamePos[i].y - 4) * scale, (this.NamePos[i].x - 1) * scale + 1 - nameLeft.GetWidth() + 150 * scale, this.NamePos[i].y * scale + nameLeft.GetHeight()));
        }
    }
    else {
        m_pwin->InvalidateRect(CRect((this.NamePos[player].x - 1) * scale + 1 - nameLeft.GetWidth(), (this.NamePos[player].y - 4) * scale, (this.NamePos[player].x - 1) * scale + 1 - nameLeft.GetWidth() + 150 * scale, this.NamePos[player].y * scale + nameLeft.GetHeight()));
    }
}

void COKEY::InvalidatePoints()
{
    for(int i=0;i<4;i++)
    {
        m_pwin->InvalidateRectScale(CRect(this.PointsPos[i].x-5,this.PointsPos[i].y-5,this.PointsPos[i].x+25,this.PointsPos[i].y+25),false);
    }
}

void COKEY::SendKoiENaRed()
{
    if(this.P2OP)
    {
        CString koi=L"4";
        if(this.KoiENaRed>1)koi=IntToString(this.KoiENaRed-1);
        SendGame(L"$|b|"+koi);
    }
    if(this.P3OP)
    {
        CString koi=IntToString(this.KoiENaRed+2);
        if(this.KoiENaRed>2)koi=IntToString(this.KoiENaRed-2);
        SendGame(L"$|e|"+koi);
    }
    if(this.P4OP)
    {
        CString koi=IntToString(this.KoiENaRed+1);
        if(this.KoiENaRed==4)koi=IntToString(1);
        SendGame(L"$|h|"+koi);
    }
    if(HaveWatch) {
        SendGame(L"$|*|a|"+IntToString(this.KoiENaRed));
    }
}

void COKEY::SendNames()
{
    if(this.P2OP)
    {
        CString message=L"$|a|"+Name[3];
        SendGame(message);
        message=L"$|a|"+Name[4];
        SendGame(message);
        message=L"$|a|"+Name[1];
        SendGame(message);
    }
    if(this.P3OP)
    {
        CString message=L"$|d|"+Name[4];
        SendGame(message);
        message=L"$|d|"+Name[1];
        SendGame(message);
        message=L"$|d|"+Name[2];
        SendGame(message);
    }
    if(this.P4OP)
    {
        CString message=L"$|g|"+Name[1];
        SendGame(message);
        message=L"$|g|"+Name[2];
        SendGame(message);
        message=L"$|g|"+Name[3];
        SendGame(message);
    }
    if(HaveWatch) {
        CString message=L"$|t|1|"+Name[2]+L"|"+Name[3]+L"|"+Name[4];
        SendGame(message);
    }
}

void COKEY::SendCards()
{
    CString p2;
    if(this.P2OP)
    {
        this.P2G=false;
        for(int i=2;i<5;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|c|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<2;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|c|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<=9;i++)
        {
            for(let j: number=0;j<5;j++)
            {
                SendGame(L"$|c|"+IntToString(Talon2[i][j]));
            }
        }
        for(let j: number=0;j<3;j++)
        {
            SendGame(L"$|c|"+IntToString(Talon2[0][j]));
        }
        SendGame(L"$|c|"+IntToString(Boards[this.KoiENaRed][0][14]));
        SendGame(L"$|c|"+IntToString(OKEY));
    }
    if(this.P3OP)
    {
        this.P3G=false;
        for(int i=3;i<5;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|f|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<3;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|f|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<=9;i++)
        {
            for(let j: number=0;j<5;j++)
            {
                SendGame(L"$|f|"+IntToString(Talon2[i][j]));
            }
        }
        for(let j: number=0;j<3;j++)
        {
            SendGame(L"$|f|"+IntToString(Talon2[0][j]));
        }
        SendGame(L"$|f|"+IntToString(Boards[this.KoiENaRed][0][14]));
        SendGame(L"$|f|"+IntToString(OKEY));
    }
    if(this.P4OP)
    {
        this.P4G=false;
        for(int i=4;i<5;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|i|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<4;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|i|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<=9;i++)
        {
            for(let j: number=0;j<5;j++)
            {
                SendGame(L"$|i|"+IntToString(Talon2[i][j]));
            }
        }
        for(let j: number=0;j<3;j++)
        {
            SendGame(L"$|i|"+IntToString(Talon2[0][j]));
        }
        SendGame(L"$|i|"+IntToString(Boards[this.KoiENaRed][0][14]));
        SendGame(L"$|i|"+IntToString(OKEY));
    }
    if(HaveWatch) 
    {
        for(int i=1;i<5;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                SendGame(L"$|p|"+IntToString(Boards[i][0][j]));
            }
        }
        for(int i=1;i<=9;i++)
        {
            for(let j: number=0;j<5;j++)
            {
                SendGame(L"$|p|"+IntToString(Talon2[i][j]));
            }
        }
        for(let j: number=0;j<3;j++)
        {
            SendGame(L"$|p|"+IntToString(Talon2[0][j]));
        }
        SendGame(L"$|p|"+IntToString(Boards[this.KoiENaRed][0][14]));
        SendGame(L"$|p|"+IntToString(OKEY));
    }
}

bool COKEY::ReceiveGame(CString f)
{
    CClientDC dc(m_pwin);
    CString strRecvd;
    CWinApp* pApp = AfxGetApp();
    if(f==L"a")//Poluchavane na imenata client
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString name=m_pwin->ReceiveString(12);
        for(int i=2;i<5;i++)
        {
            if(Name[i]==L"")
            {
                if(name!=L"Computer2"&&name!=L"Computer3"&&name!=L"Computer4")Name[i]=name;
                else {
                    if(i==2)
                    {
                        Name[2]=L"Computer2";
                        m_pwin->InvalidateStolove(3);
                    }
                    if(i==3)Name[3]=L"Computer3";
                    if(i==4)Name[4]=L"Computer4";
                }
                m_pwin->GetPlayerRating(i);
                goto end;
            }
        }
        goto end;
    }
    if(f==L"b")//Poluchavane na koi zapochva client
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString koj=m_pwin->ReceiveString(1);
        if(koj==L"1"||koj==L"2"||koj==L"3"||koj==L"4") {
            this.KoiENaRed=_ttoi(koj);
            InitGame();
            this.HaveToTake[this.KoiENaRed-1]=true;
        }
        if(watch) {
            m_pwin->Invalidate(true);
        }
        goto end;
    }
    if(f==L"c")//Poluchavane na plochkite client
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString rstr=m_pwin->ReceiveString(4);
        for(int i=1;i<5;i++)
        {
            for(let j: number=0;j<14;j++)
            {
                if(Boards[i][0][j]<1)
                {
                    Boards[i][0][j]=_ttoi(rstr);
                    goto end;
                }
            }
        }
        for(int i=1;i<=9;i++)
        {
            for(let j: number=0;j<5;j++)
            {
                if(Talon2[i][j]<1) {
                    Talon2[i][j]=_ttoi(rstr);
                    goto end;
                }
            }
        }
        for(let j: number=0;j<3;j++)
        {
            if(Talon2[0][j]<1) {
                Talon2[0][j]=_ttoi(rstr);
                goto end;
            }
        }
        if(Boards[this.KoiENaRed][0][14]<1) {
            Boards[this.KoiENaRed][0][14]=_ttoi(rstr);
            goto end;
        }
        if(OKEY<1) {
            OKEY=_ttoi(rstr);

            int OKEY1;
            if(OKEY%100==13)OKEY1=(OKEY-(OKEY%100))+1;
            else OKEY1=OKEY+1;

            for(let j: number=0;j<15;j++)
            {
                if(Boards[1][0][j]==OKEY1)Boards[1][0][j]=Boards[1][0][j]+1000;
            }

            DrawNames(&dc);
            online_timer=online_timer_time*4;
            if(Zvuk[0]==1)m_sndSound.Play(1112,L"center", 0,0);
            for(int i=1;i<=9;i++)
            {
                Talon2br[i]=5;
            }
            Talon2br[0]=3;
            this.Faza=1;
            m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);
            goto end;
        }
        goto end;
    }
    if(f==L"d")
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<2) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        if(pause_player!=L"") {
            pause_player=L"";
            m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        }
        CString pl1=m_pwin->ReceiveString(4);
        CString pl2=m_pwin->ReceiveString(4);
        GetPool(_ttoi(pl1),this.KoiENaRed,_ttoi(pl2));
        goto end;
    }
    if(f==L"e")//Finnished1
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        if(pause_player!=L"") {
            pause_player=L"";
            m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        }
        CString plstr=m_pwin->ReceiveString(4);
        this.Shown=true;
        this.fin1=true;
        Finnished1(this.KoiENaRed,_ttoi(plstr));
        this.Shown=false;
        goto end;
    }
    if(f==L"f")//Poluchavane na TakePool
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        if(pause_player!=L"") {
            pause_player=L"";
            m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        }
        CString plstr=m_pwin->ReceiveString(4);
        TakePool(_ttoi(plstr),this.KoiENaRed);
        goto end;
    }
    if(f==L"g")//Razpadane na vrazkata s niakoi ot igrachite
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(12);
        if(m_pwin->m_d432.Dlg==true)m_pwin->m_d432.DisconnectPlayer(strRecvd);
        else
        {
            CWinApp* pApp = AfxGetApp();
            CString name=strRecvd;
            online_timer=online_timer_time*4;
            if(pause_player!=L""&&pause_player==name) {
                pause_player=L"";
                m_pwin->InvalidateRectScale(CRect(628,475,820, 505),false);
            }
            if(this.Server) {
                if(name==Name[2]) {
                    this.P2OP=false;
                    this.P2G=true;
                    Name[2]=L"Computer2";
                    m_pwin->InvalidateStolove(3);
                    bool ss=show_user[2];
                    show_user[2]=false;
                    m_pwin->GetPlayerRating(2);
                    InvalidateNames(2);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[2].m_x-6,users[2].m_y-6,users[2].m_x+106,users[2].m_y+126), true);
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==Name[3]) {
                    this.P3OP=false;
                    this.P3G=true;
                    Name[3]=L"Computer3";
                    bool ss=show_user[3];
                    show_user[3]=false;
                    m_pwin->GetPlayerRating(3);
                    InvalidateNames(3);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[3].m_x-6,users[3].m_y-6,users[3].m_x+106,users[3].m_y+126), true);
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==Name[4]) {
                    this.P4OP=false;
                    this.P4G=true;
                    Name[4]=L"Computer4";
                    bool ss=show_user[4];
                    show_user[4]=false;
                    m_pwin->GetPlayerRating(4);
                    InvalidateNames(4);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[4].m_x-6,users[4].m_y-6,users[4].m_x+106,users[4].m_y+126), true);
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==wait_opnames[2]) {
                    wait_opnames[2]=L"";
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==wait_opnames[3]) {
                    wait_opnames[3]=L"";
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==wait_opnames[4]) {
                    wait_opnames[4]=L"";
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
            }
            else {
                if(name==Name[2]) {
                    Name[2]=L"Computer2";
                    m_pwin->InvalidateStolove(3);
                    bool ss=show_user[2];
                    show_user[2]=false;
                    m_pwin->GetPlayerRating(2);
                    InvalidateNames(2);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[2].m_x-6,users[2].m_y-6,users[2].m_x+106,users[2].m_y+126), true);
                }
                if(name==Name[3]) {
                    Name[3]=L"Computer3";
                    bool ss=show_user[3];
                    show_user[3]=false;
                    m_pwin->GetPlayerRating(3);
                    InvalidateNames(3);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[3].m_x-6,users[3].m_y-6,users[3].m_x+106,users[3].m_y+126), true);
                }
                if(name==Name[4]) {
                    Name[4]=L"Computer4";
                    bool ss=show_user[4];
                    show_user[4]=false;
                    m_pwin->GetPlayerRating(4);
                    InvalidateNames(4);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[4].m_x-6,users[4].m_y-6,users[4].m_x+106,users[4].m_y+126), true);
                }
                COK m_ok=new COK();
                m_ok.SetTitle(name+L" "+GetLanguageText(L"has left the game"));
                m_ok.DoModal();
                goto end;
            }
        }
        goto end;
    }
    if(f==L"h")
    {
        this.P2G=true;
        goto end;
    }
    if(f==L"i")
    {
        this.P3G=true;
        goto end;
    }
    if(f==L"j")
    {
        this.P4G=true;
        goto end;
    }
    if(f==L"k")
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd==L"2")this.P2OP=false;
        if(strRecvd==L"3")this.P3OP=false;
        if(strRecvd==L"4")this.P4OP=false;
        if(strRecvd==L"5")this.P2OP=true;
        if(strRecvd==L"6")this.P3OP=true;
        if(strRecvd==L"7")this.P4OP=true;
        goto end;
    }
    if(f==L"l")
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd==L"1") {
            this.Server=true;
            m_pwin->InitIconsTooltips();
        }
        if(strRecvd==L"0")this.Server=false;
        m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        goto end;
    }
    if(f==L"m")//ChangePlaces
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<3) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        if(pause_player!=L"") {
            pause_player=L"";
            m_pwin->InvalidateRectScale(CRect(628,475,820, 505),false);
        }
        CString pl1=m_pwin->ReceiveString(4);
        CString pl2=m_pwin->ReceiveString(4);
        CString pla=m_pwin->ReceiveString(1);
        ChangePlaces(_ttoi(pl1),_ttoi(pl2),_ttoi(pla));
        goto end;
    }
    if(f==L"n")
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<2) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        if(pause_player!=L"") {
            pause_player=L"";
            m_pwin->InvalidateRectScale(CRect(628,475,820, 505),false);
        }
        CString pool=m_pwin->ReceiveString(4);
        CString way=m_pwin->ReceiveString(4);
        Finnished2(this.KoiENaRed,_ttoi(pool),_ttoi(way));
        goto end;
    }
    if(f==L"o")
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd==L"2") {
            this.P2G=false;
            if(this.Faza==3)this.P2G=true;
        }
        if(strRecvd==L"3") {
            this.P3G=false;
            if(this.Faza==3)this.P3G=true;
        }
        if(strRecvd==L"4") {
            this.P4G=false;
            if(this.Faza==3)this.P4G=true;
        }
        if(strRecvd==L"5")this.P2G=true;
        if(strRecvd==L"6")this.P3G=true;
        if(strRecvd==L"7")this.P4G=true;
        goto end;
    }
    if(f==L"p")//Prisaediniavane na Igrach po vreme na igra this.Server
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<3) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        online_timer=online_timer_time*4;
        CString player_name=m_pwin->ReceiveString(12);
        CString pistr=m_pwin->ReceiveString(1);
        CString rating = m_pwin->ReceiveString(30);
        int player_index=_ttoi(pistr);
        wait_opnames[player_index]=player_name;
        if(watch) {
            Name[player_index]=player_name;
            show_user[player_index]=false;
            m_pwin->GetPlayerRating(player_index);
            InvalidateNames(player_index);
            goto end;
        }
        if(!m_pwin->m_main->ExistInIgnoreList(player_name)) {
        	
            CString message=GetLanguageText(L"Would you like to replace the computer")+L" ( "+Name[player_index]+L" )"+L" "+GetLanguageText(L"with")+L" "+GetLanguageText(L"user")+L" "+player_name+L" / "+GetLanguageText(L"Rating")+L": " + rating + L"?";
            if(last_Name[player_index]==player_name || m_pwin->isNoVipPlayer(Name[1]) || m_pwin->IsGuestUser(Name[1])) {
                COK m_ok=new COK();
                m_ok.SetTitle(message);
                m_ok.DoModal();
                if(wait_opnames[player_index]!=player_name)goto end;
                Name[player_index]=player_name;
                show_user[player_index]=false;
                m_pwin->GetPlayerRating(player_index);
                if(player_index==2) {
                    this.P2OP=true;
                    if(this.Faza==1)this.P2G=false;
                    else this.P2G=true;
                }
                if(player_index==3) {
                    this.P3OP=true;
                    if(this.Faza==1)this.P3G=false;
                    else this.P3G=true;
                }
                if(player_index==4) {
                    this.P4OP=true;
                    if(this.Faza==1)this.P4G=false;
                    else this.P4G=true;
                }
                InvalidateNames(player_index);
                if(HaveWatch) {
                    CString message=L"$|s|"+pistr+L"|1";
                    SendGame(message);
                }
                CString new_name=L"$|w|"+IntToString(player_index)+L"|"+player_name;
                SendGame(new_name);
                SendInitialization(player_index);
                m_pwin->GetPlayerParameters(player_name);
                goto end;
            }
            CYESNO m_yesno=new CYESNO();
            m_yesno.SetTitle(message);
            if(m_yesno.DoModal()==IDOK) {
                if(wait_opnames[player_index]!=player_name)goto end;
                Name[player_index]=player_name;
                show_user[player_index]=false;
                m_pwin->GetPlayerRating(player_index);
                if(player_index==2) {
                    this.P2OP=true;
                    if(this.Faza==1)this.P2G=false;
                    else this.P2G=true;
                }
                if(player_index==3) {
                    this.P3OP=true;
                    if(this.Faza==1)this.P3G=false;
                    else this.P3G=true;
                }
                if(player_index==4) {
                    this.P4OP=true;
                    if(this.Faza==1)this.P4G=false;
                    else this.P4G=true;
                }
                InvalidateNames(player_index);
                if(HaveWatch) {
                    CString message=L"$|s|"+pistr+L"|1";
                    SendGame(message);
                }
                CString new_name=L"$|w|"+IntToString(player_index)+L"|"+player_name;
                SendGame(new_name);
                SendInitialization(player_index);
                m_pwin->GetPlayerParameters(player_name);
                goto end;
            }
            else {
                if(wait_opnames[player_index]!=player_name)goto end;
                CString message=L"$|s|"+pistr+L"|0";
                SendGame(message);
                if(m_pwin->m_main->last_refused_user==player_name)
                {
                    CYESNO m_yesno2=new CYESNO();
                    CString title=GetLanguageText(L"Do you want to add (username) to your ignore list");
                    title.Replace(L"(username)",player_name);
                    m_yesno2.SetTitle(title+L"?");
                    if(m_yesno2.DoModal()==IDOK) {
                        m_pwin->AddToIgnoreList(player_name);
                    }
                }
                m_pwin->m_main->last_refused_user=player_name;
            }
        }
        else {
            CString message=L"$|x|"+player_name;
            SendGame(message);
            m_pwin->pFrame->m_chat_bar.AddMessage(GetLanguageText(L"user")+L" "+player_name+L" "+GetLanguageText(L"is in the Ignore List")+L"!");
        }
        goto end;
    }
    if(f==L"q")//Prisaediniavane na Igrach po vreme na igra
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString ok=m_pwin->ReceiveString(1);
        if(ok==L"1") {
            COK m_ok=new COK();
            m_ok.SetTitle(GetLanguageText(L"Please wait for the next deal"));
            m_ok.DoModal();
            InvalidateNames(0);
        }
        if(ok==L"0") {
            m_pwin->game=L"";
            m_pwin->ShowChatBar(false, false);
            m_pwin->MessageBox(GetLanguageText(L"This user refused to join this play")+L"!");
            m_pwin->GoToLobby();
        }
        if(ok==L"2") {
            m_pwin->game=L"";
            m_pwin->ShowChatBar(false, false);
            m_pwin->MessageBox(GetLanguageText(L"You can't connect to this user"));
            m_pwin->GoToLobby();
        }
        goto end;
    }
    if(f==L"r")//Poluchavane na rezultat ot WaitConnecting
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<4) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString rez1=m_pwin->ReceiveString(5);
        CString rez2=m_pwin->ReceiveString(5);
        CString rez3=m_pwin->ReceiveString(5);
        CString rez4=m_pwin->ReceiveString(5);
        this.Points[0]=_ttoi(rez1);
        this.Points[1]=_ttoi(rez2);
        this.Points[2]=_ttoi(rez3);
        this.Points[3]=_ttoi(rez4);
        InvalidatePoints();
        goto end;
    }
    if(f==L"s")//Promiana na Imeto na igrach
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<2) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString pistr=m_pwin->ReceiveString(1);
        int player_index=_ttoi(pistr);
        CString player_name=m_pwin->ReceiveString(12);
        Name[player_index]=player_name;
        m_pwin->GetPlayerRating(player_index);
        InvalidateNames(player_index);
        goto end;
    }
    if(f==L"t")//Ignoriran igrach
    {
        m_pwin->game=L"";
        m_pwin->ShowChatBar(false, false);
        goto end;
    }
    if(f==L"u")//SendWatchGameInitialization
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        HaveWatch=true;
        CString windex=m_pwin->ReceiveString(3);
        SendWatchGameInitialization(_ttoi(windex));
        goto end;
    }
    if(f==L"v")//ReceiveWachGameInitialization
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString initstr=m_pwin->ReceiveString(800);
        ReceiveWachGameInitialization(initstr);
        goto end;
    }
    if(f==L"w")//ReceiveInitialization
    {
        int br_=0;
        for(int i=0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage(L"$|"+f+L"|");
            return false;
        }
        CString initstr=m_pwin->ReceiveString(800);
        ReceiveInitialization(initstr);
        goto end;
    }
    if(f==L"x")
    {
        goto end;
    }
    m_pwin->SendErrorToLog(L"Izpusnato e saobshtenie!okey : "+f);
    end:;
    return true;
}

void COKEY::OnMouseMove(CCardGamesView*view, CPoint point)
{
    if(this.moveblock>-1)
    {
        if(this.moveblock<100)
        {
            view->InvalidateRectScale(CRect(this.BoardPlaces[0][this.moveblock].x-2,this.BoardPlaces[0][this.moveblock].y-3,this.BoardPlaces[0][this.moveblock].x+Joker.m_Width/scale+2,this.BoardPlaces[0][this.moveblock].y+Joker.m_Height/scale+3),false);
            this.BoardPlaces[0][this.moveblock].x=(point.x-Joker.m_Width/2)/scale;
            this.BoardPlaces[0][this.moveblock].y=(point.y-Joker.m_Height/2)/scale;
            view->InvalidateRectScale(CRect(this.BoardPlaces[0][this.moveblock].x-2,this.BoardPlaces[0][this.moveblock].y-3,this.BoardPlaces[0][this.moveblock].x+Joker.m_Width/scale+2,this.BoardPlaces[0][this.moveblock].y+Joker.m_Height/scale+3),false);
        }
        if(this.moveblock>=100 && this.moveblock<200)
        {
            view->InvalidateRectScale(CRect(this.BoardPlaces[1][this.moveblock%100].x-2,this.BoardPlaces[1][this.moveblock%100].y-3,this.BoardPlaces[1][this.moveblock%100].x+Joker.m_Width/scale+2,this.BoardPlaces[1][this.moveblock%100].y+Joker.m_Height/scale+3),false);
            this.BoardPlaces[1][this.moveblock%100].x=(point.x-Joker.m_Width/2)/scale;
            this.BoardPlaces[1][this.moveblock%100].y=(point.y-Joker.m_Height/2)/scale;
            view->InvalidateRectScale(CRect(this.BoardPlaces[1][this.moveblock%100].x-2,this.BoardPlaces[1][this.moveblock%100].y-3,this.BoardPlaces[1][this.moveblock%100].x+Joker.m_Width/scale+2,this.BoardPlaces[1][this.moveblock%100].y+Joker.m_Height/scale+3),false);
        }
        if(this.moveblock>=200 && this.moveblock<300)
        {
            view->InvalidateRectScale(CRect(this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x-2,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y-3,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x+Joker.m_Width/scale+2,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y+Joker.m_Height/scale+3),false);
            this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x=(point.x-Joker.m_Width/2)/scale;
            this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y=(point.y-Joker.m_Height/2)/scale;
            view->InvalidateRectScale(CRect(this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x-2,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y-3,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x+Joker.m_Width/scale+2,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y+Joker.m_Height/scale+3),false);
        }
        if(this.moveblock==300)
        {
            view->InvalidateRectScale(CRect(this.Talon1Pl[3].x-2,this.Talon1Pl[3].y-3,this.Talon1Pl[3].x+Joker.m_Width/scale+2,this.Talon1Pl[3].y+Joker.m_Height/scale+3),false);
            this.Talon1Pl[3].x=(point.x-Joker.m_Width/2)/scale;
            this.Talon1Pl[3].y=(point.y-Joker.m_Height/2)/scale;
            view->InvalidateRectScale(CRect(this.Talon1Pl[3].x-2,this.Talon1Pl[3].y-3,this.Talon1Pl[3].x+Joker.m_Width/scale+2,this.Talon1Pl[3].y+Joker.m_Height/scale+3),false);
        }
        if(this.PosToIn>=0)
        {
            view->InvalidateRectScale(CRect(this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x-2,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y-3,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x+Joker.m_Width/scale+2,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y+Joker.m_Height/scale+3),false);
        }
        this.PosToIn=-1;
        for(int i=0;i<17;i++)
        {
            if(point.x > this.BoardRect[0][i].x*scale && point.x < this.BoardRect[0][i].x*scale+Joker.m_Width
                && point.y > this.BoardRect[0][i].y*scale && point.y < this.BoardRect[0][i].y*scale+Joker.m_Height)
            {
                if(Boards[1][0][i] === 0)
                {
                    this.PosToIn=i;
                }
            }
        }
        for(int i=0;i<17;i++)
        {
            if(point.x > this.BoardRect[1][i].x*scale && point.x < this.BoardRect[1][i].x*scale+Joker.m_Width
                && point.y > this.BoardRect[1][i].y*scale && point.y < this.BoardRect[1][i].y*scale+Joker.m_Height)
            {
                if(Boards[1][1][i] === 0)
                {
                    this.PosToIn=i+100;
                }
            }
        }
        if(this.PosToIn>=0)
        {
            view->InvalidateRectScale(CRect(this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x-2,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y-3,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x+Joker.m_Width/scale+2,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y+Joker.m_Height/scale+3),false);
        }
    }
}

void COKEY::Timer1()
{
}

void COKEY::Timer2()
{
}

void COKEY::AutoPlay()
{
    if(this.moveblock>=0)
    {
        CPoint p;
        p.x=0;p.y=0;
        OnLButtonUp(m_pwin,p);
        return;
    }
    if(this.Faza==1&&this.KoiENaRed==1&&!this.Shown) {
        if(HaveToGet[0])
        {
            for(int i=0;i<10;i++)
            {
                if(Talon2br[i]>0 && (i==0 || Talon2br[i-1]==0))
                {
                    for(let j: number=0;j<17;j++)
                    {
                        if(Boards[1][0][j]==0)
                        {
                            GetPool(i,1,j);
                            int HaveToBeInvalidate1=1000+j;
                            int c=HaveToBeInvalidate1%1000;
                            m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                            this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                            goto end;
                        }
                    }
                }
            }
        }
        if(this.HaveToTake[0]) {
            for(int i=0;i<17;i++)
            {
                if(Boards[1][0][i] != 0 && Boards[1][0][i] <1000)
                {
                    TakePool(i,1);
                    int HaveToBeInvalidate1=1000+i;
                    int c=HaveToBeInvalidate1%1000;
                    m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                    this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                    goto end;
                }
            }
            for(int i=0;i<17;i++)
            {
                if(Boards[1][1][i] != 0 && Boards[1][1][i] <1000)
                {
                    TakePool(i+100,1);
                    int HaveToBeInvalidate1=1100+i;
                    int c=HaveToBeInvalidate1%1000;
                    m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                    this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                    goto end;
                }
            }
            for(int i=0;i<17;i++)
            {
                if(Boards[1][0][i] != 0 && Boards[1][0][i] >1000)
                {
                    Boards[1][0][i]=Boards[1][0][i]-1000;
                    TakePool(i,1);
                    int HaveToBeInvalidate1=1000+i;
                    int c=HaveToBeInvalidate1%1000;
                    m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                    this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                    goto end;
                }
            }
            for(int i=0;i<17;i++)
            {
                if(Boards[1][1][i] != 0 && Boards[1][1][i] >1000)
                {
                    Boards[1][1][i]=Boards[1][1][i]-1000;
                    TakePool(i+100,1);
                    int HaveToBeInvalidate1=1100+i;
                    int c=HaveToBeInvalidate1%1000;
                    m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                    this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                    goto end;
                }
            }
        }
    }
end:;
}

void COKEY::Timer3()
{
    if(this.Faza>0&&!this.Shown) {
        if(this.SP) {
            if(ToCount)
            {
                this.BroiOstavashti=0;
                for(int i=0;i<10;i++)
                {
                    this.BroiOstavashti+=Talon2br[i];
                }
                ToCount=false;
            }
            if(this.BroiHodove<4 && this.KoiENaRed==1 && this.Faza==1 && !this.Shown&&!this.fin1&&(HaveToGet[this.KoiENaRed-1]||this.BroiHodove==0))
            {
                if(!this.Shown)
                {
                    for(int i=0;i<17;i++)
                    {
                        if(Boards[1][0][i]==OKEY)
                        {
                            this.fin1=true;
                            this.Shown=true;
                            if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                            CYESNO m_yesno=new CYESNO();
                            m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                            if(m_yesno.DoModal()==IDOK)
                            {
                                Finnished1(1,i);
                                this.Shown=false;
                                goto end;
                            }
                            else {
                                this.Shown=false;
                                goto end;
                            }
                        }
                        if(Boards[1][1][i]==OKEY)
                        {
                            this.fin1=true;
                            this.Shown=true;
                            if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                            CYESNO m_yesno=new CYESNO();
                            m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                            if(m_yesno.DoModal()==IDOK)
                            {
                                Finnished1(1,i+100);
                                this.Shown=false;
                                goto end;
                            }
                            else {
                                this.Shown=false;
                                goto end;
                            }
                        }
                    }
                }
            }
            if(this.BroiOstavashti==0 && this.Faza<2&&HaveToGet[this.KoiENaRed-1])
            {
                InitRezDlg(1,1,4);
            }
            if(this.KoiENaRed!=1 && this.Faza<2)
            {
                Intelect(this.KoiENaRed);
            }
        }
        if(this.OP) 
        {
            if(this.Server) {
                if(ToCount)
                {
                    this.BroiOstavashti=0;
                    for(int i=0;i<10;i++)
                    {
                        this.BroiOstavashti+=Talon2br[i];
                    }
                    ToCount=false;
                }
                if(this.BroiHodove<4 && this.KoiENaRed==1 && this.Faza==1 && !this.Shown&&!this.fin1&&(HaveToGet[this.KoiENaRed-1]||this.BroiHodove==0))
                {
                    if(!this.Shown)
                    {
                        for(int i=0;i<17;i++)
                        {
                            if(Boards[1][0][i]==OKEY)
                            {
                                this.fin1=true;
                                this.Shown=true;
                                if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                                CYESNO m_yesno=new CYESNO();
                                m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                                if(m_yesno.DoModal()==IDOK)
                                {
                                    Finnished1(1,i);
                                    this.Shown=false;
                                    goto end;
                                }
                                else {
                                    this.Shown=false;
                                    goto end;
                                }
                            }
                            if(Boards[1][1][i]==OKEY)
                            {
                                this.fin1=true;
                                this.Shown=true;
                                if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                                CYESNO m_yesno=new CYESNO();
                                m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                                if(m_yesno.DoModal()==IDOK)
                                {
                                    Finnished1(1,i+100);
                                    this.Shown=false;
                                    goto end;
                                }
                                else {
                                    this.Shown=false;
                                    goto end;
                                }
                            }
                        }
                    }
                }
                if(this.BroiOstavashti==0 && this.Faza<2&&HaveToGet[this.KoiENaRed-1])
                {
                    InitRezDlg(1,1,4);
                }
                if(this.KoiENaRed!=1 && this.Faza<2)
                {
                    if((this.KoiENaRed==2&&this.P2OP!=true)||(this.KoiENaRed==3&&this.P3OP!=true)||(this.KoiENaRed==4&&this.P4OP!=true))
                    {
                        Intelect(this.KoiENaRed);
                    }
                }
                if(this.Faza==1&&this.KoiENaRed==1&&online_timer==0) {
                    sit_out_count=sit_out_count+1;
                    if(sit_out_count>=sit_out_drop)
                    {
                        m_pwin->AutoSitOut();
                        goto end;
                    }
                    AutoPlay();
                }
            }
            else {
                if(ToCount)
                {
                    this.BroiOstavashti=0;
                    for(int i=0;i<10;i++)
                    {
                        this.BroiOstavashti+=Talon2br[i];
                    }
                    ToCount=false;
                }
                if(!watch) {
                    if(this.BroiHodove<4 && this.KoiENaRed==1 && this.Faza==1 && !this.Shown&&!this.fin1&&(HaveToGet[this.KoiENaRed-1]||this.BroiHodove==0))
                    {
                        if(!this.Shown)
                        {
                            for(int i=0;i<17;i++)
                            {
                                if(Boards[1][0][i]==OKEY)
                                {
                                    this.fin1=true;
                                    this.Shown=true;
                                    if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                                    CYESNO m_yesno=new CYESNO();
                                    m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                                    if(m_yesno.DoModal()==IDOK)
                                    {
                                        Finnished1(1,i);
                                        this.Shown=false;
                                        goto end;
                                    }
                                    else {
                                        this.Shown=false;
                                        goto end;
                                    }
                                }
                                if(Boards[1][1][i]==OKEY)
                                {
                                    this.fin1=true;
                                    this.Shown=true;
                                    if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                                    CYESNO m_yesno=new CYESNO();
                                    m_yesno.SetTitle(GetLanguageText(L"You have a SHOW. Will you Display It?"));
                                    if(m_yesno.DoModal()==IDOK)
                                    {
                                        Finnished1(1,i+100);
                                        this.Shown=false;
                                        goto end;
                                    }
                                    else {
                                        this.Shown=false;
                                        goto end;
                                    }
                                }
                            }
                        }
                    }
                    if(this.Faza==1&&this.KoiENaRed==1&&online_timer==0) {
                        sit_out_count=sit_out_count+1;
                        if(sit_out_count>=sit_out_drop)
                        {
                            m_pwin->AutoSitOut();
                            goto end;
                        }
                        AutoPlay();
                    }
                }
                if(this.BroiOstavashti==0 && this.Faza<2&&HaveToGet[this.KoiENaRed-1])
                {
                    InitRezDlg(1,1,4);
                }
            }
        }
    }
end:;
}

void COKEY::Timer4()
{
    if(this.Faza==3)
    {
        if(this.SP) {
            InitGame();
        }
        if(this.OP) {
            if(this.Server)
            {
                if(this.P2G==true&&this.P3G==true&&this.P4G==true)
                {
                    if(this.P2OP==true)this.P2G=false;
                    if(this.P3OP==true)this.P3G=false;
                    if(this.P4OP==true)this.P4G=false;
                    InitGame();
                    SendKoiENaRed();
                    SendCards();
                    CClientDC dc(m_pwin);
                    DrawNames(&dc);
                    online_timer=online_timer_time*4;
                }
            }
            else {
            }
        }
    }
}

void COKEY::Timer7()
{
    if(!watch) {
        if(this.OP&&(this.P2OP||this.P3OP||this.P4OP||!this.Server)) {
            if(pause_player!=L"") m_pwin->InvalidateRectScale(CRect(628,475,820, 505),false);
            if(online_timer>0)online_timer=online_timer-1;
            if(online_timer==5) {
                if(this.Faza==1&&this.KoiENaRed==1) {
                    if(Zvuk[0]==1)m_sndSound.Play(1113,L"center", 0,0);
                }
            }
            CClientDC dc(m_pwin);
            m_pwin->InvalidateRectScale(CRect(676,475,732, 505),false);
        }
    }
}

void COKEY::InitGame()
{
    this.Faza=0;
    op_start=false;
    for(int i=0;i<4;i++)
    {
        this.HaveToTake[i]=false;
        HaveToGet[i]=false;
    }
    for(int i=0;i<17;i++)
    {
        this.BoardPlaces[0][i].x=125+31*i;
        this.BoardPlaces[0][i].y=380;
        this.BoardPlaces[1][i].x=125+31*i;
        this.BoardPlaces[1][i].y=440;
    }
    for(int i=0;i<5;i++)
    {
        this.Talon2Pl[i][0].x=220+i*50;
        this.Talon2Pl[i][0].y=150;
        this.Talon2Pl[i+5][0].x=220+i*50;
        this.Talon2Pl[i+5][0].y=220;
    }
    for(int i=0;i<10;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            this.Talon2Pl[i][j].x=this.Talon2Pl[i][0].x-(j);
            this.Talon2Re[i][j].x=this.Talon2Pl[i][j].x;
            this.Talon2Pl[i][j].y=this.Talon2Pl[i][0].y-(j);
            this.Talon2Re[i][j].y=this.Talon2Pl[i][j].y;
        }
    }
    for(int i=0;i<5;i++)
    {
        for(let j: number=0;j<20;j++)
        {
            Boards[i][0][j]=0;
            Boards[i][1][j]=0;
        }
        for(let j: number=0;j<50;j++)
        {
            Talon1[i][j]=0;
        }
        this.Talon1br[i]=0;
    }
    for(int i=0;i<10;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            Talon2[i][j]=0;
        }
        Talon2br[i]=0;
    }
    this.BroiHodove=0;
    this.BroiOstavashti=48;
    this.IsReady=false;
    this.Izlishni[0][0]=20;
    this.FPlayer=0;
    this.Shown=false;
    this.fin1=false;
    this.moveblock=-1;
    this.PosToIn=-1;
	
    OKEY=0;
    if(this.OP&&this.Server||this.SP)
    {
        if(this.KoiENaRed<4)this.KoiENaRed=this.KoiENaRed+1;
        else this.KoiENaRed=1;
        this.HaveToTake[this.KoiENaRed-1]=true;
        Razdavane();
        this.Faza=1;
    }
    if(this.OP) {
        if(!watch)SendGame(L"$|y");
        op_start=false;
    }
    Title=L"Favorite Games: "+GetGameVersion()+L" "+GetLanguageText(L"OKEY")+L" ";
    for(int i=1;i<5;i++) {
        if(last_Name[i]==L"")last_Name[i]=Name[i];
    }
}

void COKEY::InitNewGame()
{
    this.pause=0;
    op_start=false;
    for(int i=0;i<4;i++)
    {
        this.Points[i]=20;
        this.m_rez.frez[i+1]=this.Points[i];
    }
    for(int i=1;i<5;i++)
    {
        for(int a=0;a<50;a++)
        {
            this.rez[i][a]=-100;
        }
    }
    this.pause=0;
    pause_player=L"";
    if(this.SP==true)InitGame();
    for(int i=1;i<5;i++) {
        last_Name[i]=Name[i];
    }
}
void COKEY::LoadPictures()
{
    LoadFile(&TableD,L"Okey\\board.gif");
    LoadFile(&TableU,L"Okey\\up_player_t.gif");
    LoadFile(&TableL,L"Okey\\side_l.gif");
    LoadFile(&TableR,L"Okey\\side_r.gif");
    LoadFile(&EmptyBlock,L"Okey\\tiles\\_.gif");
    for(int i=1;i<14;i++)
    {
        LoadFile(&Blocks[0][i],L"Okey\\tiles\\wood\\black\\"+IntToString(i)+L".gif");
        LoadFile(&Blocks[1][i],L"Okey\\tiles\\wood\\blue\\"+IntToString(i)+L".gif");
        LoadFile(&Blocks[2][i],L"Okey\\tiles\\wood\\green\\"+IntToString(i)+L".gif");
        LoadFile(&Blocks[3][i],L"Okey\\tiles\\wood\\red\\"+IntToString(i)+L".gif");
    }
    LoadFile(&Joker,L"Okey\\tiles\\wood\\0.gif");
    LoadFile(&Back,L"Okey\\tiles\\wood\\00.gif");
    LoadFile(&OKEYBack,L"Okey\\tiles\\wood\\000.gif");
    for(int i=0;i<10;i++)
    {
        LoadFile(&Numbers[0][i],L"Okey\\numbers\\"+IntToString(i+1)+L".gif");
        LoadFile(&Numbers[1][i],L"Okey\\numbers\\"+IntToString(i+1)+L"_.gif");
    }
}

void COKEY::DrawTimer(CDC *dc)
{
    if(!watch&&this.OP) {
        if(this.P2OP||this.P3OP||this.P4OP||!this.Server) {
            if(this.Faza==1&&this.KoiENaRed==1) {
                DrawBorderText(dc,IntToString(online_timer),700,480);
                if(this.pause==0) {
                    p_bmp.Show(dc, CRect(676*scale,478*scale,676*scale+p_bmp.m_Width,478*scale+p_bmp.m_Height));
                }
            }
        }
        if(this.Faza==1&&this.KoiENaRed!=1) {
            if(pause_player!=L""&&(online_timer==(online_timer/2)*2)) {
                DrawBorderText(dc,GetLanguageText(L"Pause")+L": "+pause_player,673,480);
            }
        }
    }
}

void COKEY::DrawView(CDC*dc)//da opravia prioriteta
{
    SetFontSize(dc,-13);
    if(this.Faza>0) {
        int OKEY1;
        if(OKEY%100==13)
        {
            OKEY1=(OKEY-(OKEY%100))+1;
        }
        else
            OKEY1=OKEY+1;
        if(this.KoiENaRed>0)
        {
            DrawKoiENaRed(dc,this.StrelkaP[this.KoiENaRed].x,this.StrelkaP[this.KoiENaRed].y);//3
        }
	
        DrawNames(dc);//risuva imenata
        if(this.OP)DrawTimer(dc);
        if(this.OP)DrawRating(dc);
        for(int i=0;i<4;i++)
        {
            DrawBorderText(dc,L"("+IntToString(this.Points[i])+L")",this.PointsPos[i].x,this.PointsPos[i].y);
        }
        if(this.Faza<2 || this.FPlayer!=1)
        {
            TableD.Show(dc,CRect(113*scale,370*scale,113*scale+TableD.m_Width,370*scale+TableD.m_Height));
        }
        if(this.Faza<2 || this.FPlayer!=3)
        {
            TableU.Show(dc,CRect(113*scale,18*scale,113*scale+TableU.m_Width,18*scale+TableU.m_Height));
        }
        if(this.Faza<2 || this.FPlayer!=4)
        {
            TableL.Show(dc,CRect(25*scale,80*scale,25*scale+TableL.m_Width,80*scale+TableL.m_Height));
        }
        if(this.Faza<2 || this.FPlayer!=2)
        {
            TableR.Show(dc,CRect(740*scale,80*scale,740*scale+TableR.m_Width,80*scale+TableR.m_Height));
        }
        if(this.Faza<2)
        {
            for(int i=0;i<10;i++)//mestata
            {
                EmptyBlock.Show(dc,CRect(this.Talon2Re[i][0].x*scale,this.Talon2Re[i][0].y*scale,this.Talon2Re[i][0].x*scale+EmptyBlock.m_Width,this.Talon2Re[i][0].y*scale+EmptyBlock.m_Height));
            }
            for(int i=0;i<10;i++)//mestata
            {
                if(Talon2br[i]>0)
                {
                    Numbers[0][i].Show(dc,CRect(this.NumbersPl[i].x*scale,this.NumbersPl[i].y*scale, this.NumbersPl[i].x*scale+Numbers[0][i].m_Width,this.NumbersPl[i].y*scale+Numbers[0][i].m_Height));
                }
                if(Talon2br[i]==0)
                {
                    Numbers[1][i].Show(dc,CRect(this.NumbersPl[i].x*scale,this.NumbersPl[i].y*scale, this.NumbersPl[i].x*scale+Numbers[1][i].m_Width,this.NumbersPl[i].y*scale+Numbers[1][i].m_Height));
                }
            }
        }
        for(int i=0;i<4;i++)
        {
            EmptyBlock.Show(dc,CRect(this.Talon1Re[i].x*scale,this.Talon1Re[i].y*scale,this.Talon1Re[i].x*scale+EmptyBlock.m_Width,this.Talon1Re[i].y*scale+EmptyBlock.m_Height));
        }
        if(this.Faza<2)
        {
            for(int i=0;i<10;i++)
            {
                for(let j: number=0;j<Talon2br[i];j++)
                {
                    if(!(this.moveblock>200 && this.moveblock<300 && i==this.moveblock%100 && j==(Talon2br[i]-1)))
                    {
                        Back.Show(dc,CRect(this.Talon2Pl[i][j].x*scale,this.Talon2Pl[i][j].y*scale,this.Talon2Pl[i][j].x*scale+EmptyBlock.m_Width,this.Talon2Pl[i][j].y*scale+EmptyBlock.m_Height));
                    }
                }
            }
        }
        if(this.PosToIn>=0)
        {
            EmptyBlock.Show(dc,CRect(this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x*scale,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y*scale,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].x*scale+EmptyBlock.m_Width,
                this.BoardPlaces[this.PosToIn/100][this.PosToIn%100].y*scale+EmptyBlock.m_Height));
        }
        if(this.Faza<2)
        {
            Blocks[OKEY/100-1][OKEY%100].Show(dc,CRect(this.OKEYPlace.x*scale,this.OKEYPlace.y*scale,this.OKEYPlace.x*scale+Blocks[OKEY/100-1][OKEY%100].m_Width,this.OKEYPlace.y*scale+Blocks[OKEY/100-1][OKEY%100].m_Height));
        }
        for(int i=0;i<4;i++)//risuva 4-te talona
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                if(j==this.Talon1br[i]-1)
                {
                    if(!(this.moveblock==300 && i==3))
                    {
                        if(Talon1[i][j]>0 && Talon1[i][j]<500)
                        {
                            Blocks[Talon1[i][j]/100-1][Talon1[i][j]%100].Show(dc,CRect
                                (this.Talon1Pl[i].x*scale,this.Talon1Pl[i].y*scale,this.Talon1Pl[i].x*scale+
                                Blocks[Talon1[i][j]/100-1][Talon1[i][j]%100].m_Width,
                                this.Talon1Pl[i].y*scale+Blocks[Talon1[i][j]/100-1]
                                [Talon1[i][j]%100].m_Height));
                        }
                        if(Talon1[i][j]==500)
                        {
                            Joker.Show(dc,CRect(this.Talon1Pl[i].x*scale,this.Talon1Pl[i].y*scale,this.Talon1Pl[i].x*scale+Joker.m_Width,this.Talon1Pl[i].y*scale+Joker.m_Height));
                        }
                    }
                }
                if(j<this.Talon1br[i]-1)
                {
                    Blocks[Talon1[i][j]/100-1][Talon1[i][j]%100].Show(dc,CRect
                        (this.Talon1Re[i].x*scale,this.Talon1Re[i].y*scale,this.Talon1Re[i].x*scale+
                        Blocks[Talon1[i][j]/100-1][Talon1[i][j]%100].m_Width,
                        this.Talon1Re[i].y*scale+Blocks[Talon1[i][j]/100-1]
                        [Talon1[i][j]%100].m_Height));
                }
            }
        }
        if(this.Faza<2 || this.FPlayer!=1)
        {
            for(int i=0;i<17;i++)//ploo4kite na tablata na igra4 1
            {
                if(Boards[1][0][i] != 0 && Boards[1][0][i] != 500 && i!=this.moveblock && Boards[1][0][i] < 1000)
                {
                    Blocks[Boards[1][0][i]/100-1][Boards[1][0][i]%100].Show(dc,CRect
                        (this.BoardPlaces[0][i].x*scale,this.BoardPlaces[0][i].y*scale,this.BoardPlaces[0][i].x*scale+
                        Blocks[Boards[1][0][i]/100-1][Boards[1][0][i]%100].m_Width,
                        this.BoardPlaces[0][i].y*scale+Blocks[Boards[1][0][i]/100-1]
                        [Boards[1][0][i]%100].m_Height));
                }
                if(Boards[1][1][i] != 0 && Boards[1][1][i] != 500 && i!=this.moveblock-100 &&  Boards[1][1][i] < 1000)
                {
                    Blocks[Boards[1][1][i]/100-1][Boards[1][1][i]%100].Show(dc,CRect
                        (this.BoardPlaces[1][i].x*scale,this.BoardPlaces[1][i].y*scale,this.BoardPlaces[1][i].x*scale+
                        Blocks[Boards[1][1][i]/100-1][Boards[1][1][i]%100].m_Width,
                        this.BoardPlaces[1][i].y*scale+Blocks[Boards[1][1][i]/100-1]
                        [Boards[1][1][i]%100].m_Height));
                }
                if(Boards[1][0][i] === 500 && i!=this.moveblock)
                {
                    Joker.Show(dc,CRect(this.BoardPlaces[0][i].x*scale,this.BoardPlaces[0][i].y*scale,
                        this.BoardPlaces[0][i].x*scale+Joker.m_Width,this.BoardPlaces[0][i].y*scale+Joker.m_Height));
                }
                if(Boards[1][1][i] === 500 && i!=this.moveblock-100)
                {
                    Joker.Show(dc,CRect(this.BoardPlaces[1][i].x*scale,this.BoardPlaces[1][i].y*scale,
                        this.BoardPlaces[1][i].x*scale+Joker.m_Width,this.BoardPlaces[1][i].y*scale+Joker.m_Height));
                }
                if(Boards[1][0][i] > 1000 && Boards[1][0][i]%1000 !=OKEY1 && i!=this.moveblock)
                {
                    Back.Show(dc,CRect(this.BoardPlaces[0][i].x*scale,this.BoardPlaces[0][i].y*scale,
                        this.BoardPlaces[0][i].x*scale+Joker.m_Width,this.BoardPlaces[0][i].y*scale+Joker.m_Height));
                }
                if(Boards[1][1][i] > 1000 && Boards[1][1][i]%1000 !=OKEY1 && i!=this.moveblock-100)
                {
                    Back.Show(dc,CRect(this.BoardPlaces[1][i].x*scale,this.BoardPlaces[1][i].y*scale,
                        this.BoardPlaces[1][i].x*scale+Joker.m_Width,this.BoardPlaces[1][i].y*scale+Joker.m_Height));
                }
                if(Boards[1][0][i] > 1000 && Boards[1][0][i]%1000 ==OKEY1 && i!=this.moveblock)
                {
                    OKEYBack.Show(dc,CRect(this.BoardPlaces[0][i].x*scale,this.BoardPlaces[0][i].y*scale,
                        this.BoardPlaces[0][i].x*scale+Joker.m_Width,this.BoardPlaces[0][i].y*scale+Joker.m_Height));
                }
                if(Boards[1][1][i] > 1000 && Boards[1][1][i]%1000 ==OKEY1 && i!=this.moveblock-100)
                {
                    OKEYBack.Show(dc,CRect(this.BoardPlaces[1][i].x*scale,this.BoardPlaces[1][i].y*scale,
                        this.BoardPlaces[1][i].x*scale+Joker.m_Width,this.BoardPlaces[1][i].y*scale+Joker.m_Height));
                }
            }
        }
        if(this.Faza<2)
        {
            if(this.moveblock>=0 && this.moveblock<200)
            {
                if(Boards[1][this.moveblock/100][this.moveblock%100] != 0 && Boards[1][this.moveblock/100][this.moveblock%100] != 500 &&
                    Boards[1][this.moveblock/100][this.moveblock%100]<1000)
                {
                    Blocks[Boards[1][this.moveblock/100][this.moveblock%100]/100-1][Boards[1][this.moveblock/100][this.moveblock%100]%100].Show(dc,CRect
                        (this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale,this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale+
                        Blocks[Boards[1][this.moveblock/100][this.moveblock%100]/100-1][Boards[1][this.moveblock/100][this.moveblock%100]%100].m_Width,
                        this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale+Blocks[Boards[1][this.moveblock/100][this.moveblock%100]/100-1]
                        [Boards[1][this.moveblock/100][this.moveblock%100]%100].m_Height));
                }
                if(Boards[1][this.moveblock/100][this.moveblock%100] === 500 )
                {
                    Joker.Show(dc,CRect(this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale,
                        this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale,this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale+
                        Joker.m_Width,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale+Joker.m_Height));
                }
                if(Boards[1][this.moveblock/100][this.moveblock%100] > 1000 && Boards[1][this.moveblock/100][this.moveblock%100]%1000 !=OKEY1 && 
                    Boards[1][this.moveblock/100][this.moveblock%100]%1000!=OKEY1)
                {
                    Back.Show(dc,CRect(this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale,
                        this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale+Joker.m_Width,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale+Joker.m_Height));
                }
                if(Boards[1][this.moveblock/100][this.moveblock%100] > 1000 && Boards[1][this.moveblock/100][this.moveblock%100]%1000 ==OKEY1)
                {
                    OKEYBack.Show(dc,CRect(this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale,
                        this.BoardPlaces[this.moveblock/100][this.moveblock%100].x*scale+Joker.m_Width,this.BoardPlaces[this.moveblock/100][this.moveblock%100].y*scale+Joker.m_Height));
                }
            }
            if(this.moveblock>=200 && this.moveblock<300)
            {
                Back.Show(dc,CRect(this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x*scale
                    ,this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y*scale,
                    this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].x*scale+EmptyBlock.m_Width,
                    this.Talon2Pl[this.moveblock%100][Talon2br[this.moveblock%100]-1].y*scale+EmptyBlock.m_Height));
            }
            if(this.moveblock==300)
            {
                if(Talon1[3][this.Talon1br[3]-1]>0 && Talon1[3][this.Talon1br[3]-1]<500)
                {
                    Blocks[Talon1[3][this.Talon1br[3]-1]/100-1][Talon1[3][this.Talon1br[3]-1]%100].Show(dc,CRect
                        (this.Talon1Pl[3].x*scale,this.Talon1Pl[3].y*scale,this.Talon1Pl[3].x*scale+
                        Blocks[Talon1[3][this.Talon1br[3]-1]/100-1][Talon1[3][this.Talon1br[3]-1]%100].m_Width,
                        this.Talon1Pl[3].y*scale+Blocks[Talon1[3][this.Talon1br[3]-1]/100-1]
                        [Talon1[3][this.Talon1br[3]-1]%100].m_Height));
                }
                if(Talon1[3][this.Talon1br[3]-1]==500)
                {
                    Joker.Show(dc,CRect(this.Talon1Pl[3].x*scale,this.Talon1Pl[3].y*scale,this.Talon1Pl[3].x*scale+
                        Joker.m_Width,this.Talon1Pl[3].y*scale+Joker.m_Height));
                }
            }
        }
        if(this.Faza==2)
        {
            this.BroiOstavashti=0;
            for(int i=0;i<10;i++)
            {
                this.BroiOstavashti+=Talon2br[i];
            }
            Blocks[OKEY/100-1][OKEY%100].Show(dc,CRect(this.OKEYPlace.x*scale,(this.OKEYPlace.y+10)*scale,this.OKEYPlace.x*scale+Blocks[OKEY/100-1][OKEY%100].m_Width,(this.OKEYPlace.y+10)*scale+Blocks[OKEY/100-1][OKEY%100].m_Height));
            if(this.BroiOstavashti!=0 || m_Way!=4)
            {
                TableD.Show(dc,CRect(113*scale,100*scale,113*scale+TableD.m_Width,100*scale+TableD.m_Height));//tablite
                for(int i=0;i<17;i++)
                {
                    Boards[this.FPlayer][0][i]=Boards[this.FPlayer][0][i]%1000;
                    Boards[this.FPlayer][1][i]=Boards[this.FPlayer][1][i]%1000;
                }
                for(int i=0;i<17;i++)//ploo4kite na tablata na igra4 1
                {
                    if(Boards[this.FPlayer][0][i] != 0 && Boards[this.FPlayer][0][i] != 500 && i!=this.FinnishBlock)
                    {
                        Blocks[Boards[this.FPlayer][0][i]/100-1][Boards[this.FPlayer][0][i]%100].Show(dc,CRect
                            (this.FinnishPlaces[0][i].x*scale,this.FinnishPlaces[0][i].y*scale,this.FinnishPlaces[0][i].x*scale+
                            Joker.m_Width,this.FinnishPlaces[0][i].y*scale+Joker.m_Height));
                    }
                    if(Boards[this.FPlayer][1][i] != 0 && Boards[this.FPlayer][1][i] != 500 && i!=this.FinnishBlock-100)
                    {
                        Blocks[Boards[this.FPlayer][1][i]/100-1][Boards[this.FPlayer][1][i]%100].Show(dc,CRect
                            (this.FinnishPlaces[1][i].x*scale,this.FinnishPlaces[1][i].y*scale,this.FinnishPlaces[1][i].x*scale+
                            Joker.m_Width,this.FinnishPlaces[1][i].y*scale+Joker.m_Height));
                    }
                    if(Boards[this.FPlayer][0][i] === 500 && i!=this.FinnishBlock)
                    {
                        Joker.Show(dc,CRect(this.FinnishPlaces[0][i].x*scale,this.FinnishPlaces[0][i].y*scale,
                            this.FinnishPlaces[0][i].x*scale+Joker.m_Width,this.FinnishPlaces[0][i].y*scale+Joker.m_Height));
                    }
                    if(Boards[this.FPlayer][1][i] === 500 && i!=this.FinnishBlock-100)
                    {
                        Joker.Show(dc,CRect(this.FinnishPlaces[1][i].x*scale,this.FinnishPlaces[1][i].y*scale,
                            this.FinnishPlaces[1][i].x*scale+Joker.m_Width,this.FinnishPlaces[1][i].y*scale+Joker.m_Height));
                    }
                }
                if(Boards[this.FPlayer][this.FinnishBlock/100][this.FinnishBlock%100]!=500)
                {
                    Blocks[Boards[this.FPlayer][this.FinnishBlock/100][this.FinnishBlock%100]/100-1][Boards[this.FPlayer][this.FinnishBlock/100][this.FinnishBlock%100]%100].
                        Show(dc,CRect(this.FinnishPlace.x*scale,this.FinnishPlace.y*scale,this.FinnishPlace.x*scale+Joker.m_Width,this.FinnishPlace.y*scale+Joker.m_Height));
                }
                if(Boards[this.FPlayer][this.FinnishBlock/100][this.FinnishBlock%100]==500)
                {
                    Joker.Show(dc,CRect(this.FinnishPlace.x*scale,this.FinnishPlace.y*scale,this.FinnishPlace.x*scale+Joker.m_Width,this.FinnishPlace.y*scale+Joker.m_Height));
                }
                DrawBorderText(dc,GetLanguageText(L"Closing Tile"),320,280);
            }
            DrawBorderText(dc,GetLanguageText(L"Open Tile"),496,280);
        }
        if(this.OP) {
            DrawUsers(dc,m_pwin->game);
            SetFontSize(dc,-13);
            for(int i=1;i<=4;i++) {
                if(chat_message[i]!=L"")DrawChatMessage(dc,i,chat_message[i]);
            }
        }
    }
}
void COKEY::Razdavane()
{
    int First[106],FirstBr=0;
    for(int i=1;i<5;i++)
    {
        for(let j: number=1;j<14;j++)
        {
            First[FirstBr]=(i*100)+j;
            FirstBr++;
            First[FirstBr]=(i*100)+j;
            FirstBr++;
        }
    }
    SYSTEMTIME systime;
    GetSystemTime(&systime);
    int ms=systime.wMilliseconds;
//	srand( ms );
    int l=rand()%FirstBr;
    OKEY=First[l];
    for(int k=l;k<FirstBr;k++)
    {
        First[k]=First[k+1];
    }
    FirstBr--;
    First[FirstBr]=500;
    FirstBr++;
    First[FirstBr]=500;
    FirstBr++;
    for(int i=1;i<5;i++)
    {
        for(let j: number=0;j<14;j++)
        {
            l=rand()%FirstBr;
            Boards[i][0][j]=First[l];
            for(int k=l;k<FirstBr;k++)
            {
                First[k]=First[k+1];
            }
            FirstBr--;
        }
    }
    int OKEY1;
    if(OKEY%100==13)OKEY1=(OKEY-(OKEY%100))+1;
    else OKEY1=OKEY+1;

    for(let j: number=0;j<15;j++)
    {
        if(Boards[1][0][j]==OKEY1)Boards[1][0][j]=Boards[1][0][j]+1000;
    }

    for(int i=1;i<=9;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            l=rand()%FirstBr;
            Talon2[i][j]=First[l];
            for(int k=l;k<FirstBr;k++)
            {
                First[k]=First[k+1];
            }
            FirstBr--;
        }
        Talon2br[i]=5;
    }
    for(let j: number=0;j<3;j++)
    {
        l=rand()%FirstBr;
        Talon2[0][j]=First[l];
        for(int k=l;k<FirstBr;k++)
        {
            First[k]=First[k+1];
        }
        FirstBr--;
    }
    Talon2br[0]=3;
    l=rand()%FirstBr;
    Boards[this.KoiENaRed][0][14]=First[l];
    for(int k=l;k<FirstBr;k++)
    {
        First[k]=First[k+1];
    }
    FirstBr--;
    m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);

}
void COKEY::GetPool(int Place,int Player,int Place2)
{

    int OKEY1;
    if(OKEY%100==13)OKEY1=(OKEY-(OKEY%100))+1;
    else OKEY1=OKEY+1;
    if(this.OP) {
        if(!watch) {
            if(this.Server) {
                if(this.P2OP||this.P3OP||this.P4OP||HaveWatch)
                {
                    if(Player==1) {
                        SendGame(L"$|l|"+IntToString(Place)+L"|"+IntToString(Place2));
                    }
                    else {
                        if((Player==2&&this.P2OP!=true)||(Player==3&&this.P3OP!=true)||(Player==4&&this.P4OP!=true))
                        {
                            SendGame(L"$|l|"+IntToString(Place)+L"|"+IntToString(Place2));
                        }
                    }
                }
            }
            else {
                if(Player==1) {
                    SendGame(L"$|l|"+IntToString(Place)+L"|"+IntToString(Place2));
                }
            }		
            if(this.BroiHodove<4)online_timer=online_timer_time*4;
            else online_timer=online_timer_time*3;
        }
    }
    if(Place>=0 && Place<10)
    {
        Boards[Player][Place2/100][Place2%100]=Talon2[Place][Talon2br[Place]-1];
        Talon2[Place][Talon2br[Place]-1]=0;
        Talon2br[Place]--;
        if(Player==1&&Boards[Player][Place2/100][Place2%100]==OKEY1) {
            Boards[Player][Place2/100][Place2%100]=Boards[Player][Place2/100][Place2%100]+1000;
        }
        int HaveToBeInvalidate=Place;
        m_pwin->InvalidateRectScale(CRect(this.Talon2Pl[HaveToBeInvalidate%100][Talon2br[HaveToBeInvalidate%100]].x-2,
                this.Talon2Pl[HaveToBeInvalidate%100][Talon2br[HaveToBeInvalidate%100]].y-3,
                this.Talon2Pl[HaveToBeInvalidate%100][Talon2br[HaveToBeInvalidate%100]].x+Joker.m_Width+2,
                this.Talon2Pl[HaveToBeInvalidate%100][Talon2br[HaveToBeInvalidate%100]].y+Joker.m_Height+3),false);
            m_pwin->InvalidateRectScale(CRect(this.NumbersPl[HaveToBeInvalidate%100].x-2,
                this.NumbersPl[HaveToBeInvalidate%100].y-3,
                this.NumbersPl[HaveToBeInvalidate%100].x+15,
                this.NumbersPl[HaveToBeInvalidate%100].y+15),false);
        	
    	
    }
    if(Place==10)
    {
        Boards[Player][Place2/100][Place2%100]=Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1];
        Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]=0;
        this.Talon1br[(Player+2)%4]--;
        if(Player==1&&Boards[Player][Place2/100][Place2%100]==OKEY1) {
            Boards[Player][Place2/100][Place2%100]=Boards[Player][Place2/100][Place2%100]+1000;
        }
        int HaveToBeInvalidate=100+((Player+3)%4);
        m_pwin->InvalidateRectScale(CRect(this.Talon1Pl[HaveToBeInvalidate%100-1].x-2,this.Talon1Pl[HaveToBeInvalidate%100-1].y-3,
                this.Talon1Pl[HaveToBeInvalidate%100-1].x+Joker.m_Width+2,this.Talon1Pl[HaveToBeInvalidate%100-1].y+Joker.m_Height+3),false);
            m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%4+1].y*scale,
                (this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale+who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%4+1].y*scale+who.GetHeight()),false);
            m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%100].y*scale,
                (this.StrelkaP[HaveToBeInvalidate%100].x-23) * scale +who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%100].y*scale+ who.GetHeight()),false);
    }
    HaveToGet[Player-1]=false;
    this.HaveToTake[Player-1]=true;
    ToCount=true;
    if(watch) {
        if(Player==1) {
            if(Place==10) {
                int HaveToBeInvalidate1=1000+Place2;
                int c=HaveToBeInvalidate1%1000;
                m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                int HaveToBeInvalidate=104;
                m_pwin->InvalidateRectScale(CRect(this.Talon1Pl[HaveToBeInvalidate%100-1].x-2,this.Talon1Pl[HaveToBeInvalidate%100-1].y-3,
                    this.Talon1Pl[HaveToBeInvalidate%100-1].x+Joker.m_Width+2,this.Talon1Pl[HaveToBeInvalidate%100-1].y+Joker.m_Height+3),false);
                m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%4+1].y*scale,
                    (this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale + who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%4+1].y*scale + who.GetHeight()),false);
                m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%100].y*scale,
                    (this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale + who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%100].y*scale + who.GetHeight()),false);
            }
            else {
                int HaveToBeInvalidate1=1000+Place2;
                int c=HaveToBeInvalidate1%1000;
                m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
            }
        }
    }
    if(watch)Timer3();
    if(Zvuk[0]==1)m_sndSound.Play(1200,L"center", 0,0);
}
void COKEY::TakePool(int Place,int Player)
{
    if(this.OP) {
        if(!watch) {
            if(this.Server) {
                if(this.P2OP||this.P3OP||this.P4OP||HaveWatch)
                {
                    if(Player==1) {
                        SendGame(L"$|k|"+IntToString(Place));
                    }
                    else {
                        if((Player==2&&this.P2OP!=true)||(Player==3&&this.P3OP!=true)||(Player==4&&this.P4OP!=true))
                        {
                            SendGame(L"$|k|"+IntToString(Place));
                        }
                    }
                }
            }
            else {
                if(Player==1) {
                    SendGame(L"$|k|"+IntToString(Place));
                }
            }
            if(this.BroiHodove<4)online_timer=online_timer_time*4;
            else online_timer=online_timer_time*3;
        }
    }
    if(Boards[Player][Place/100][Place%100]>1000)Boards[Player][Place/100][Place%100]=Boards[Player][Place/100][Place%100]-1000;
    Talon1[Player-1][this.Talon1br[Player-1]]=Boards[Player][Place/100][Place%100];
    Boards[Player][Place/100][Place%100]=0;
    this.Talon1br[Player-1]++;
    int HaveToBeInvalidate=100+Player;
    m_pwin->InvalidateRectScale(CRect(this.Talon1Pl[HaveToBeInvalidate%100-1].x-2,this.Talon1Pl[HaveToBeInvalidate%100-1].y-3,
        this.Talon1Pl[HaveToBeInvalidate%100-1].x+Joker.m_Width+2,this.Talon1Pl[HaveToBeInvalidate%100-1].y+Joker.m_Height+3),false);
    m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%4+1].y*scale,
        (this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale + who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%4+1].y*scale + who.GetHeight()),false);
    m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%100].y*scale,
        (this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale+who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%100].y*scale + who.GetHeight()),false);
    this.HaveToTake[Player-1]=false;
    this.KoiENaRed=(this.KoiENaRed%4)+1;
    this.BroiHodove++;
    HaveToGet[this.KoiENaRed-1]=true;
    if(watch) {
        if(Player==1) {
            int HaveToBeInvalidate1=1000+Place;
            int c=HaveToBeInvalidate1%1000;
            m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
            this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
        }
    }
    if(Zvuk[0]==1)m_sndSound.Play(1200,L"center", 0,0);
}

void COKEY::SendInitialization(int player)
{
    CString message=L"$|q|"+IntToString(player)+L"|";
    if(player==2)message=message+Name[3]+L"?"+Name[4]+L"?"+Name[1]+L"?";
    if(player==3)message=message+Name[4]+L"?"+Name[1]+L"?"+Name[2]+L"?";
    if(player==4)message=message+Name[1]+L"?"+Name[2]+L"?"+Name[3]+L"?";
    message=message+IntToString(this.Faza)+L"?";
    if(player==2)
    {
        CString koi=L"4";
        if(this.KoiENaRed>1)koi=IntToString(this.KoiENaRed-1);
        message=message+koi+L"?";
    }
    if(player==3)
    {
        CString koi=IntToString(this.KoiENaRed+2);
        if(this.KoiENaRed>2)koi=IntToString(this.KoiENaRed-2);
        message=message+koi+L"?";
    }
    if(player==4)
    {
        CString koi=IntToString(this.KoiENaRed+1);
        if(this.KoiENaRed==4)koi=IntToString(1);
        message=message+koi+L"?";
    }
    if(player==2)
    {
        for(int i=2;i<5;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
        for(int i=1;i<2;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
    }
    if(player==3)
    {
        for(int i=3;i<5;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
        for(int i=1;i<3;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
    }
    if(player==4)
    {
        for(int i=4;i<5;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
        for(int i=1;i<4;i++)
        {
            for(int a=0;a<=1;a++)
            {
                for(let j: number=0;j<17;j++)
                {
                    message=message+IntToString(Boards[i][a][j])+L"?";
                }
            }
        }
    }
    for(int i=1;i<=9;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            message=message+IntToString(Talon2[i][j])+L"?";
        }
    }
    for(let j: number=0;j<3;j++)
    {
        message=message+IntToString(Talon2[0][j])+L"?";
    }
    message=message+IntToString(OKEY)+L"?";
    for(int i=0;i<=9;i++)
    {
        message=message+IntToString(Talon2br[i])+L"?";
    }
    if(player==2)
    {
        for(int i=1;i<4;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
        for(int i=0;i<1;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
    }
    if(player==3)
    {
        for(int i=2;i<4;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
        for(int i=0;i<2;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
    }
    if(player==4)
    {
        for(int i=3;i<4;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
        for(int i=0;i<3;i++)
        {
            message=message+IntToString(this.Talon1br[i])+L"?";
        }
    }
    if(player==2)
    {
        for(int i=1;i<4;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
        for(int i=0;i<1;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
    }
    if(player==3)
    {
        for(int i=2;i<4;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
        for(int i=0;i<2;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
    }
    if(player==4)
    {
        for(int i=3;i<4;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
        for(int i=0;i<3;i++)
        {
            for(let j: number=0;j<this.Talon1br[i];j++)
            {
                message=message+IntToString(Talon1[i][j])+L"?";
            }
        }
    }
    if(player==2)
    {
        for(int i=1;i<4;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
        for(int i=0;i<1;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
    }
    if(player==3)
    {
        for(int i=2;i<4;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
        for(int i=0;i<2;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
    }
    if(player==4)
    {
        for(int i=3;i<4;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
        for(int i=0;i<3;i++)
        {
            message=message+IntToString(this.Points[i])+L"?";
        }
    }
    message=message+IntToString(this.BroiHodove)+L"?";
    if(HaveToGet[this.KoiENaRed-1])message=message+L"1?";
    else message=message+L"0?";
    if(this.HaveToTake[this.KoiENaRed-1])message=message+L"1?";
    else message=message+L"0?";
    if(this.Faza==2) {
        message=message+IntToString(this.FinnishBlock)+L"?";
        if(player==2)
        {
            if(this.FPlayer==1)message=message+L"4?";
            if(this.FPlayer==2)message=message+L"1?";
            if(this.FPlayer==3)message=message+L"2?";
            if(this.FPlayer==4)message=message+L"3?";
        }
        if(player==3)
        {
            if(this.FPlayer==1)message=message+L"3?";
            if(this.FPlayer==2)message=message+L"4?";
            if(this.FPlayer==3)message=message+L"1?";
            if(this.FPlayer==4)message=message+L"2?";
        }
        if(player==4)
        {
            if(this.FPlayer==1)message=message+L"2?";
            if(this.FPlayer==2)message=message+L"3?";
            if(this.FPlayer==3)message=message+L"4?";
            if(this.FPlayer==4)message=message+L"1?";
        }
    }
    if(message!=L"") {
        SendGame(message);
    }
    SendNames(player);
}

void COKEY::ReceiveInitialization(CString init)
{
    InitGame();
	
    int vpos=init.Find(L"?");
    Name[2]=init.Left(vpos);
    if(Name[2]==L"Computer2"||Name[2]==L"Computer3"||Name[2]==L"Computer4")Name[2]=L"Computer2";
    init.Delete(0,vpos+1);
    vpos=init.Find(L"?");
    Name[3]=init.Left(vpos);
    if(Name[3]==L"Computer2"||Name[3]==L"Computer3"||Name[3]==L"Computer4")Name[3]=L"Computer3";
    init.Delete(0,vpos+1);
    vpos=init.Find(L"?");
    Name[4]=init.Left(vpos);
    if(Name[4]==L"Computer2"||Name[4]==L"Computer3"||Name[4]==L"Computer4")Name[4]=L"Computer4";
    init.Delete(0,vpos+1);
    if(Name[2]!=L"Computer2")last_Name[2]=Name[2];
    if(Name[3]!=L"Computer3")last_Name[3]=Name[3];
    if(Name[4]!=L"Computer4")last_Name[4]=Name[4];
    vpos=init.Find(L"?");
    CString s1=init.Left(vpos);
    this.Faza=_ttoi(s1);
    if(this.Faza<0) {
        goto end;
    }
    init.Delete(0,vpos+1);

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    this.KoiENaRed=_ttoi(s1);
    init.Delete(0,vpos+1);

    for(int i=1;i<5;i++)
    {
        for(int a=0;a<=1;a++)
        {
            for(let j: number=0;j<17;j++)
            {
                int vpos=init.Find(L"?");
                CString s1=init.Left(vpos);
                Boards[i][a][j]=_ttoi(s1);
                init.Delete(0,vpos+1);
            }
        }
    }
    for(int i=1;i<=9;i++)
    {
        for(let j: number=0;j<5;j++)
        {
            int vpos=init.Find(L"?");
            CString s1=init.Left(vpos);
            Talon2[i][j]=_ttoi(s1);
            init.Delete(0,vpos+1);
        }
    }
    for(let j: number=0;j<3;j++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        Talon2[0][j]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    OKEY=_ttoi(s1);
    init.Delete(0,vpos+1);
    for(int i=0;i<=9;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        Talon2br[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    for(int i=0;i<4;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        this.Talon1br[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    for(int i=0;i<4;i++)
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            int vpos=init.Find(L"?");
            CString s1=init.Left(vpos);
            Talon1[i][j]=_ttoi(s1);
            init.Delete(0,vpos+1);
        }
    }
    for(int i=0;i<4;i++)
    {
        int vpos=init.Find(L"?");
        CString s1=init.Left(vpos);
        this.Points[i]=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    this.BroiHodove=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    if(s1==L"1")HaveToGet[this.KoiENaRed-1]=true;
    else HaveToGet[this.KoiENaRed-1]=false;
    init.Delete(0,vpos+1);

    vpos=init.Find(L"?");
    s1=init.Left(vpos);
    if(s1==L"1")this.HaveToTake[this.KoiENaRed-1]=true;
    else this.HaveToTake[this.KoiENaRed-1]=false;
    init.Delete(0,vpos+1);

    if(this.Faza==2) {
        vpos=init.Find(L"?");
        s1=init.Left(vpos);
        this.FinnishBlock=_ttoi(s1);
        init.Delete(0,vpos+1);

        vpos=init.Find(L"?");
        s1=init.Left(vpos);
        this.FPlayer=_ttoi(s1);
        init.Delete(0,vpos+1);
    }
    op_start=true;
end:;
    m_pwin->GetPlayerRating(1);
    m_pwin->GetPlayerRating(2);
    m_pwin->GetPlayerRating(3);
    m_pwin->GetPlayerRating(4);
    m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);
}

void COKEY::RndPlay(int Player)
{
    if(this.Faza<2)
    {
    if(this.BroiHodove<4)
    {
        if(!this.Shown && this.Faza==1&&(HaveToGet[Player-1]||this.BroiHodove==0))
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==OKEY)
                {
                    this.Shown=true;
                    Finnished1(Player,i);
                    this.Shown=false;
                }
                if(Boards[Player][1][i]==OKEY)
                {
                    this.Shown=true;
                    Finnished1(Player,i+100);
                    this.Shown=false;
                }
            }
        }
    }
    if(HaveToGet[Player-1])
    {
        if(this.Talon1br[(Player+2)%4]>0)
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==0)
                {
                    GetPool(10,Player,i);
                    goto end;
                }
            }
        }
        for(int i=0;i<10;i++)
        {
            if(Talon2br[i]>0)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(Boards[Player][i][j]==0)
                    {
                        GetPool(i,Player,j);
                        goto end;
                    }
                }
            }
        }
    }
    if(this.HaveToTake[Player-1])
    {
        for(let j: number=0;j<17;j++)
        {
            if(Boards[Player][0][j]>0)
            {
                TakePool(j,Player);
                goto end;
            }
        }
        for(let j: number=0;j<17;j++)
        {
            if(Boards[Player][1][j]>0)
            {
                TakePool(j+100,Player);
                goto end;
            }
        }
    }
    }
end:;
}
void COKEY::ChangePlaces(int Place1,int Place2,int Player)
{
    if(this.OP) {
        if(!watch) {
            if(this.Server) {
                if(this.P2OP||this.P3OP||this.P4OP||HaveWatch)
                {
                    if(Player==1) {
                        SendGame(L"$|m|"+IntToString(Place1)+L"|"+IntToString(Place2)+L"|"+IntToString(Player));
                    }
                    else {
                        if((Player==2&&this.P2OP!=true)||(Player==3&&this.P3OP!=true)||(Player==4&&this.P4OP!=true))
                        {
                            SendGame(L"$|m|"+IntToString(Place1)+L"|"+IntToString(Place2)+L"|"+IntToString(Player));
                        }
                    }
                }
            }
            else {
                if(Player==1) {
                    SendGame(L"$|m|"+IntToString(Place1)+L"|"+IntToString(Place2)+L"|"+IntToString(Player));
                }
            }
        }
    }
    Boards[Player][Place2/100][Place2%100]=Boards[Player][Place1/100][Place1%100];
    Boards[Player][Place1/100][Place1%100]=0;
    if(Player==1)
    {
        if(watch) {
            m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[Place1/100][Place1%100].x-17,
            this.BoardPlaces[Place1/100][Place1%100].y-17,
            this.BoardPlaces[Place1/100][Place1%100].x+Joker.m_Width+17,
            this.BoardPlaces[Place1/100][Place1%100].y+Joker.m_Height+17),false);
            m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[Place2/100][Place2%100].x-17,
            this.BoardPlaces[Place2/100][Place2%100].y-17,
            this.BoardPlaces[Place2/100][Place2%100].x+Joker.m_Width+17,
            this.BoardPlaces[Place2/100][Place2%100].y+Joker.m_Height+17),false);
        }
        if(Zvuk[0]==1)m_sndSound.Play(1200,L"center", 0,0);
    }
}
int COKEY::Finnished(int Player,int Pool)
{
    int OKEY1;
    if(OKEY%100==13)
    {
        OKEY1=(OKEY-(OKEY%100))+1;
    }
    else
        OKEY1=OKEY+1;
    int RealBoards[2][17];
    for(int i=0;i<17;i++)
    {
        if(i!=Pool)
        {
            if(Boards[Player][0][i]!=OKEY1 && Boards[Player][0][i]!=500 && Boards[Player][0][i]!=1500)
            {
                RealBoards[0][i]=Boards[Player][0][i]%1000;
            }
            if(Boards[Player][0][i]==500 || Boards[Player][0][i]==1500)
            {
                RealBoards[0][i]=OKEY1;
            }
            if(Boards[Player][0][i]==OKEY1 || Boards[Player][0][i]==OKEY1+1000)
            {
                RealBoards[0][i]=500;
            }
        }
        if(i==Pool)
        {
            RealBoards[0][i]=0;
        }
        if(i!=Pool-100)
        {
            if(Boards[Player][1][i]!=OKEY1 && Boards[Player][1][i]!=500 && Boards[Player][1][i]!=1500)
            {
                RealBoards[1][i]=Boards[Player][1][i];
            }
            if(Boards[Player][1][i]==500 || Boards[Player][1][i]==1500)
            {
                RealBoards[1][i]=OKEY1;
            }
            if(Boards[Player][1][i]==OKEY1 || Boards[Player][1][i]==1000+OKEY1)
            {
                RealBoards[1][i]=500;
            }
        }
        if(i==Pool-100)
        {
            RealBoards[1][i]=0;
        }
    }
    for(int k=0;k<=1;k++)
    {
        for(let j: number=0;j<4;j++)
        {
            if(RealBoards[k][j]%100==1)
            {
                bool aranged=true;
                for(int i=0;i<13;i++)
                {
                    if(RealBoards[k][i+j] != RealBoards[k][j]+i && RealBoards[k][i+j] != 500)
                    {
                        aranged=false;
                    }
                }
                if(RealBoards[k][j] != RealBoards[k][j+13] && RealBoards[k][13+j] != 500)
                {
                    aranged=false;
                }
                if(aranged)
                {
                    return 3;
                }
            }
            if(RealBoards[k][j]==500)
            {
                bool aranged=true;
                if(RealBoards[k][j+1]%100==2 || RealBoards[k][j+1]==500)
                {
                    if(RealBoards[k][j+1]%100==2)
                    {
                        RealBoards[k][j]=RealBoards[k][j+1]-1;
                    }
                    if(RealBoards[k][j+1]==500)
                    {
                        if(RealBoards[k][j+2]%100==3)
                        {
                            RealBoards[k][j]=RealBoards[k][j+2]-2;
                        }
                        else
                            aranged=false;
                    }
                }
                else
                    aranged=false;
                for(int i=0;i<13;i++)
                {
                    if(RealBoards[k][i+j] != RealBoards[k][j]+i && RealBoards[k][i+j] != 500)
                    {
                        aranged=false;
                    }
                }
                if(RealBoards[k][j] != RealBoards[k][j+13] && RealBoards[k][13+j] != 500)
                {
                    aranged=false;
                }
                if(aranged)
                {
                    return 3;
                }
            	
                if(!aranged)
                {
                    RealBoards[k][j]=500;
                }
            	
            }
        }
    }
	
    for(int k=0;k<=1;k++)
    {
        for(let j: number=0;j<4;j++)
        {
            if(RealBoards[k][j]%100==1)
            {
                bool aranged=true;
                for(int i=1;i<13;i++)
                {
                    if(RealBoards[k][i+j] != RealBoards[k][j]+(13-i) && RealBoards[k][i+j] != 500)
                    {
                        aranged=false;
                    }
                }
                if(RealBoards[k][j] != RealBoards[k][j+13] && RealBoards[k][13+j] != 500)
                {
                    aranged=false;
                }
                if(aranged)
                {
                    return 3;
                }
            }
            if(RealBoards[k][j]==500)
            {
                bool aranged=true;
                if(RealBoards[k][j+1]%100==2 || RealBoards[k][j+1]==500)
                {
                    if(RealBoards[k][j+1]%100==13)
                    {
                        RealBoards[k][j]=RealBoards[k][j+1]-12;
                    }
                    if(RealBoards[k][j+1]==500)
                    {
                        if(RealBoards[k][j+2]%100==12)
                        {
                            RealBoards[k][j]=RealBoards[k][j+2]-11;
                        }
                        else
                            aranged=false;
                    }
                }
                else
                    aranged=false;
                for(int i=1;i<13;i++)
                {
                    if(RealBoards[k][i+j] != RealBoards[k][j]+(13-i) && RealBoards[k][i+j] != 500)
                    {
                        aranged=false;
                    }
                }
                if(RealBoards[k][j] != RealBoards[k][j+13] && RealBoards[k][13+j] != 500)
                {
                    aranged=false;
                }
                if(aranged)
                {
                    return 3;
                }
            	
                if(!aranged)
                {
                    RealBoards[k][j]=500;
                }
            	
            }
        }
    }
	
    bool doubles=true;
    for(int k=0;k<=1;k++)
    {
        int i=0;
        while(i<17)
        {
            if(RealBoards[k][i]>0)
            {
                if(i!=16)
                {
                    if(RealBoards[k][i]!=500)
                    {
                        if(RealBoards[k][i+1]==500 || RealBoards[k][i+1]==RealBoards[k][i])
                            i+=2;
                        else
                        {
                            i++;
                            doubles=false;
                        }
                    }
                    if(RealBoards[k][i]==500)
                    {
                        if(RealBoards[k][i+1]>0)
                            i+=2;
                        else
                        {
                            i++;
                            doubles=false;
                        }
                    }
                }
                if(i==16)
                {
                    i++;
                    doubles=false;
                }
            }
            else
                i++;
        }
    }
    if(doubles)
        return 2;
    bool chi=true;
    for(int i=0;i<17;i++)
    {
        if(RealBoards[0][i]!=RealBoards[1][i] && RealBoards[0][i]!=500 && RealBoards[1][i]!=500)
            chi=false;
    }
    if(chi)
        return 2;
    if(Group(RealBoards[0],17) && Group(RealBoards[1],17))
        return 1;
    return 0;
}
void COKEY::Finnished1(int Player,int Pool)
{
    if(watch) 
    {
        if(this.Points[0]<=0||this.Points[1]<=0||this.Points[2]<=0||this.Points[3]<=0) {
            for(int i=0;i<4;i++)
            {
                this.Points[i]=20;
            }
        }
    }
    if(Player==1 && !watch)
    {
        if(this.OP&&!watch) {
            if(this.Server) {
                if(Player==1) {
                    SendGame(L"$|j|"+IntToString(Pool));
                }
            }
            else {
                if(Player==1) {
                    SendGame(L"$|j|"+IntToString(Pool));
                }
            }
        }
        for(int i=1;i<4;i++)
        {
            if(i!=(Player-1))
            {
                if(this.Points[i]>=3)this.Points[i]--;
            }
        }
        if(this.OP&&!watch) {
            CString rezult=L"X|"+IntToString(this.Points[0])+L" : "+IntToString(this.Points[1])+L" : "+IntToString(this.Points[2])+L" : "+IntToString(this.Points[3]);
            SendGame(rezult);
        }
        InvalidatePoints();
        CINFO m_info=new CINFO();
        m_info.SetDescription(GetLanguageText(L"You displayed a SHOW \n Points of other players are reduced by 1"));
        m_info.DoModal();
    	
    }
    if(Player !=1 || watch)
    {
        if(this.OP&&!watch) {
            if(this.Server) {
                if(Player!=1) 
                {
                    if((Player==2&&this.P2OP!=true)||(Player==3&&this.P3OP!=true)||(Player==4&&this.P4OP!=true))
                    {
                        SendGame(L"$|j|"+IntToString(Pool));
                    }
                }
            }
        }
        for(int i=0;i<4;i++)
        {
            if(i!=(Player-1))
            {
                if(this.Points[i]>=3)this.Points[i]--;
            }
        }
        InvalidatePoints();
        CINFO m_info=new CINFO();
        CString title=GetLanguageText(L"A SHOW was displayed by (username). Your points are reduced by 1");
        title.Replace(L"(username)",Name[Player]);
        m_info.SetDescription(title);
        m_info.DoModal();
        if(this.OP&&!watch) {
            CString rezult=L"X|"+IntToString(this.Points[0])+L" : "+IntToString(this.Points[1])+L" : "+IntToString(this.Points[2])+L" : "+IntToString(this.Points[3]);
            SendGame(rezult);
        }
    }
    for(int a=0;a<50;a++)
    {
        if(this.rez[1][a]==-100)
        {
            for(int i=1;i<5;i++) {
                this.rez[i][a]=this.Points[i-1];
            }
            goto next;
        }
    }
next:;
}
void COKEY::Finnished2(int Player,int Pool,int Way)
{
    if(watch)
    {
        if(this.Points[0]<=0||this.Points[1]<=0||this.Points[2]<=0||this.Points[3]<=0) {
            for(int i=0;i<4;i++)
            {
                this.Points[i]=20;
            }
            InvalidatePoints();
        }
    }
    if(!watch) {
        if(this.OP) {
            if(this.Server) {
                if(Player==1) {
                    SendGame(L"$|o|"+IntToString(Pool)+L"|"+IntToString(Way));
                }
                else {
                    if((Player==2&&this.P2OP!=true)||(Player==3&&this.P3OP!=true)||(Player==4&&this.P4OP!=true))
                    {
                        SendGame(L"$|o|"+IntToString(Pool)+L"|"+IntToString(Way));
                    }
                }
            }
            else {
                if(Player==1) {
                    SendGame(L"$|o|"+IntToString(Pool)+L"|"+IntToString(Way));
                }
            }
        }
    }
    InitRezDlg(Player,Pool,Way);
}

void COKEY::LoadBitmaps()
{
    LoadPictures();
    LoadFiles();
}
bool COKEY::Group(int red8[17],int broi)
{
    int red[17];
    for(int u=0;u<broi;u++)
    {
        red[u]=red8[u];
    }
    if(broi==0)
        return true;
    if(broi>0)
    {
        if(red[0]==0)
        {
            int red1[17];
            for(int i=1;i<17;i++)
            {
                red1[i-1]=red[i];
            }
            if(Group(red1,broi-1))
                return true;
            else
                return false;
        }
        if(red[0]==500)//ako purvata e OKEY-a
        {
            if(broi<3)//ako ostavat po malko ot 3 karti
                return false;
            if(red[1]==0 || red[2]==0)
                return false;
            if(red[1]==500)//ako i 2-ta e OKEY
            {
                int red1[17];
                for(int i=3;i<17;i++)
                {
                    red1[i-3]=red[i];
                }
                if(Group(red1,broi-3))
                    return true;
                if(broi>3)
                {
                    if(red[2]%100==red[3]%100 && red[2]!=red[3])//za ednakvi
                    {
                        int red2[17];
                        for(int i=4;i<17;i++)
                        {
                            red2[i-4]=red[i];
                        }
                        if(Group(red2,broi-4))
                            return true;
                        else
                            return false;
                    }
                    if(red[2]%100!=2 && red[2]%100!=1)//za poredni
                    {
                        int broi11=3;
                        while(1==1)
                        {
                            if(broi11+1>broi || broi11+red[2]%100+1>15)
                                break;
                            if(red[broi11]==((red[2]%100+broi11-2)%13)+1+(red[2]/100)*100 || red[broi11]==500)
                                broi11++;
                            else
                                break;
                        }
                        if(broi11>=3)
                        {
                            for(let j: number=3;j<=broi11;j++)
                            {
                                int red1[17];
                                for(int i=j;i<17;i++)
                                {
                                    red1[i-j]=red[i];
                                }
                                if(Group(red1,broi-j))
                                    return true;
                            }
                        }
                    }
                    //za poredni na obratno
                	
                    if(red[2]%100!=13 && red[2]%100!=1)
                    {
                        int broi11=3;
                        while(1==1)
                        {
                            if(broi11+1>broi || ((red[1]%100)==12 && broi11>14) || (red[2]%100)<broi11-1)
                                break;
                            if(red[broi11]==500 || red[broi11]==red[2]+2-broi11)
                                broi11++;
                            else
                                break;	
                        }
                        if(broi11>=3)
                        {
                            for(let j: number=3;j<=broi11;j++)
                            {
                                int red1[17];
                                for(int i=j;i<17;i++)
                                {
                                    red1[i-j]=red[i];
                                }
                                if(Group(red1,broi-j))
                                    return true;
                            }
                        }
                    }
                	
                }
            }
            if(red[1]>0 && red[1]!=500)//ako 2-ta e normalna
            {
                if((red[2]%100==red[1]%100 && red[2]!=red[0] && red[2]!=red[1]) || red[2]==500)//za ednakvi
                {
                    int red1[17];
                    for(int i=3;i<17;i++)
                    {
                        red1[i-3]=red[i];
                    }
                    int red2[17];
                    for(int i=4;i<17;i++)
                    {
                        red2[i-4]=red[i];
                    }
                    if(broi>3 &&((red[3]!=red[0] && red[3]!=red[1] && red[3]!=red[2] && red[3]%100==red[1]%100) || red[3]==500))
                    {
                        if(Group(red1,broi-3) || Group(red2,broi-4))
                            return true;
                        else
                            return false;
                    }
                    if(Group(red1,broi-3))
                        return true;
                    else
                        return false;
                }
                if(red[1]%100!=1 && (red[2]==500 || (red[2] === red[1]+1 && red[1]!=13) || (red[1]%100==13 && red[2]%100==1)))//za poredni
                {
                    int broi11=2;
                    while(1==1)
                    {
                        if(broi11+1>broi || broi11+(red[1]%100)>15)
                            break;
                        if(red[broi11]==((red[1]%100+broi11-2)%13)+1+(red[1]/100)*100 || red[broi11]==500)
                            broi11++;
                        else
                            break;
                    }
                    if(broi11<3)
                        return false;
                    if(broi11>=3)
                    {
                        for(let j: number=3;j<=broi11;j++)
                        {
                            int red1[17];
                            for(int i=j;i<17;i++)
                            {
                                red1[i-j]=red[i];
                            }
                            if(Group(red1,broi-j))
                                return true;
                        }
                    }
                }
                //za poredni na obratno
            	
                if(red[1]%100!=1)
                {
                    int broi11=2;
                    while(1==1)
                    {
                        if(broi11+1>broi || ((red[1]%100)==13 && broi11>14) || (red[1]%100)<broi11)
                            break;
                        if(red[broi11]==500 || red[broi11]==red[1]+1-broi11)
                            broi11++;
                        else
                            break;
                    }
                    if(broi11<3)
                        return false;
                    if(broi11>=3)
                    {
                        for(let j: number=3;j<=broi11;j++)
                        {
                            int red1[17];
                            for(int i=j;i<17;i++)
                            {
                                red1[i-j]=red[i];
                            }
                            if(Group(red1,broi-j))
                                return true;
                        }
                    }
                }
            	
            }
        }
        if(red[0]!=500 && red[0]>0)//ako parvata e normalna
        {
            if(broi<3)
            {
                return false;
            }
            if(red[1]==0 || red[2]==0)
            {
                return false;
            }
            if(1==1)
            {
                if(red[1]!=red[0] && red[1]%100==red[0]%100 || red[1]==500)//za ednakvi
                {
                    if((red[2]%100==red[0]%100 && red[2]!=red[1] && red[2]!=red[0]) || red[2]==500)
                    {
                        int red1[17];
                        for(int i=3;i<17;i++)
                        {
                            red1[i-3]=red[i];
                        }
                        int red2[17];
                        for(int i=4;i<17;i++)
                        {
                            red2[i-4]=red[i];
                        }
                        if(broi>3 &&((red[3]!=red[0] && red[3]!=red[1] && red[3]!=red[2] && red[3]%100==red[0]%100) || red[3]==500))
                        {
                            if(Group(red1,broi-3) || Group(red2,broi-4))
                                return true;
                            else
                                return false;
                        }
                        if(Group(red1,broi-3))
                            return true;
                        else
                            return false;
                    }
                }
                if(red[1]==red[0]+1 || (red[1]==500 && ((red[2]==red[0]+2 && red[0]%100 != 12) || (red[2]%100==1 && red[0]%100==12))))//za poredni
                {
                    int broi11=2;
                    while(1==1)
                    {
                        if(broi11+1>broi || broi11+(red[0]%100)>14)
                            break;
                        if(red[broi11]==((red[0]%100+broi11-1)%13)+1+(red[0]/100)*100 || red[broi11]==500)
                            broi11++;
                        else
                            break;
                    }
                    if(broi11<3)
                        return false;
                    if(broi11>=3)
                    {
                        for(let j: number=3;j<=broi11;j++)
                        {
                            int red1[17];
                            for(int i=j;i<17;i++)
                            {
                                red1[i-j]=red[i];
                            }
                            if(Group(red1,broi-j))
                                return true;
                        }
                    }
                }
            	
                if(red[1]==red[0]-1 || red[1]==500 || (red[1]%100==13 && red[0]%100==1))//za poredni na obratno
                {
                    int broi11=2;
                    while(1==1)
                    {
                        if(broi11+1>broi || ((red[0]%100)==1 && broi11>14) || ((red[0]%100)<broi11+1 && red[0]%100>1))
                            break;
                        if(red[broi11]==500 || ((red[0]%100)==1 && red[broi11]==red[0]+13-broi11) || red[broi11]==red[0]-broi11)
                            broi11++;
                        else
                            break;
                    }
                    if(broi11<3)
                        return false;
                    if(broi11>=3)
                    {
                        for(let j: number=3;j<=broi11;j++)
                        {
                            int red1[17];
                            for(int i=j;i<17;i++)
                            {
                                red1[i-j]=red[i];
                            }
                            if(Group(red1,broi-j))
                                return true;
                        }
                    }
                }
            	
                return false;
            }
        }
    }
    if(broi<0)
        return false;
    return false;
}
void COKEY::Intelect(int Player)
{
    if(this.BroiHodove<4&&!this.fin1)
    {
        if(!this.Shown && this.Faza==1&&(HaveToGet[Player-1]||this.BroiHodove==0))
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==OKEY)
                {
                    this.fin1=true;
                    this.Shown=true;
                    if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                    Finnished1(Player,i);
                    this.Shown=false;
                }
                if(Boards[Player][1][i]==OKEY)
                {
                    this.fin1=true;
                    this.Shown=true;
                    if(Zvuk[0]==1)m_sndSound.Play(1201,L"center", 0,0);
                    Finnished1(Player,i+100);
                    this.Shown=false;
                }
            }
        }
    }
    int c=WhatToPlay(Player);
    if(getIsTest())TRACE(L"\nWhatToPlay="+IntToString(c));
        int a=5;
    if(c==1)
        Intelect1(Player);
    if(c==2)
        Intelect2(Player);
    if(c>10)
        Intelect3(Player,c%10);
}
int COKEY::WhatToPlay(int Player)
{
    int OKEY1;
    int left=0;
    int Passed[60],PassedBr=0;
    if(OKEY%100==13)
    {
        OKEY1=(OKEY-(OKEY%100))+1;
    }
    else
        OKEY1=OKEY+1;
    int RealBoards[2][17];
    for(int i=0;i<17;i++)
    {
    	
        if(Boards[Player][0][i]!=OKEY1 && Boards[Player][0][i]!=500)
        {
            RealBoards[0][i]=Boards[Player][0][i];
        }
        if(Boards[Player][0][i]==500)
        {
            RealBoards[0][i]=OKEY1;
        }
        if(Boards[Player][0][i]==OKEY1)
        {
            RealBoards[0][i]=500;
        }
        if(Boards[Player][1][i]!=OKEY1 && Boards[Player][1][i]!=500)
        {
            RealBoards[1][i]=Boards[Player][1][i];
        }
        if(Boards[Player][1][i]==500)
        {
            RealBoards[1][i]=OKEY1;
        }
        if(Boards[Player][1][i]==OKEY1)
        {
            RealBoards[1][i]=500;
        }
    }
    int Blocks1[15]={0,0,0,0,0,0,0,0,0,0,0,0,0,0,0},Blocks1br=0;
    for(int i=0;i<17;i++)
    {
        if(RealBoards[0][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[0][i];
            Blocks1br++;
        }
        if(RealBoards[1][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[1][i];
            Blocks1br++;
        }
    }
    for(int i=0;i<Blocks1br;i++)
    {
        for(let j: number=i+1;j<Blocks1br;j++)
        {
            if(Blocks1[i]>Blocks1[j])
            {
                int a=Blocks1[i];
                Blocks1[i]=Blocks1[j];
                Blocks1[j]=a;
            }
        }
    }
    for(int i=0;i<9;i++)//kolko ima o6te v talona
    {
        left+=Talon2br[i];
    }
    for(int i=0;i<4;i++)//minalite
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            if(!(i==(Player+2)%4 && j==this.Talon1br[i]-1 && HaveToGet[Player-1]))
            {
                if(Talon1[i][j]!=500 && Talon1[i][j]!=OKEY1)
                {
                    Passed[PassedBr]=Talon1[i][j];
                    PassedBr++;
                }
                if(Talon1[i][j]==OKEY1)
                {
                    Passed[PassedBr]=500;
                    PassedBr++;
                }
                if(Talon1[i][j]==500)
                {
                    Passed[PassedBr]=OKEY1;
                    PassedBr++;
                }
            }
        }
    }
    Passed[PassedBr]=OKEY;
    PassedBr++;
    for(int i=0;i<PassedBr;i++)
    {
        for(let j: number=i+1;j<PassedBr;j++)
        {
            if(Passed[i]>Passed[j])
            {
                int a=Passed[i];
                Passed[i]=Passed[j];
                Passed[j]=a;
            }
        }
    }
    int BroiR[5]={0,0,0,0,0},BroiCh=0,BroiOkey=0;
    for(int i=0;i<Blocks1br;i++)
    {
        if(Blocks1[i]==500)
        {
            BroiOkey++;
        }
        if(Blocks1[i]==Blocks1[i+1] && i!=Blocks1br && Blocks1[i]!=500)
        {
            BroiCh++;
        }
    }
    for(let j: number=1;j<5;j++)
    {
        for(int i=0;i<Blocks1br;i++)
        {
            if(Blocks1[i]/100==j)
            {
                if((Blocks1[i]!=Blocks1[i-1] && i!=1) || i==1 || Blocks1[i]%100==1)
                {
                    BroiR[j]++;
                }
            }
        }
    }
    for(int k=1;k<=4;k++)
    {
        if(BroiR[k]>=9)
        {
            int a[14]={0,0,0,0,0,0,0,0,0,0,0,0,0,0};
            int Bad=0,Middle=0,Good=0;
            for(int i=1;i<=13;i++)
            {
                for(let j: number=0;j<Blocks1br;j++)
                {
                    if(Blocks1[j]==i+100*k)
                    {
                        a[i]++;
                    }
                }
            }
            for(int i=2;i<=13;i++)
            {
                if(a[i]<1)
                {
                    int b=0;
                    for(let j: number=0;j<PassedBr;j++)
                    {
                        if(Passed[j]==k*100+i)
                            b++;
                    }
                    if(b==0)
                        Good++;
                    if(b==1)
                        Middle++;
                    if(b==2)
                        Bad++;
                }
            }
            if(a[1]<2)
            {
                int b=0;
                for(let j: number=0;j<PassedBr;j++)
                {
                    if(Passed[j]==k*100+1)
                        b++;
                }
                if(a[1]==0)
                {
                    if(b==0)
                        Middle+=2;
                    if(b==1)
                        Middle+=1;
                        Bad+=1;
                    if(b==2)
                        Bad+=2;
                }
                if(a[1]==1)
                {
                    if(b==0)
                        Middle++;
                    if(b==1)
                        Bad++;
                }
            }
            if(Bad-BroiOkey<=0)
            {
                int c=(Good+2*Middle)+(3*(Bad-BroiOkey));
                if(c<=1)
                    return 10+k;
                if(c==2 && left>=5)
                    return 10+k;
                if(c==3 && left>=15)
                    return 10+k;
                if(c==4 && left>=25)
                    return 10+k;
                if(c==5 && left>=35)
                    return 10+k;
            }
        }
    }
    if(BroiCh>=3)
    {
        int c=BroiCh*2+BroiOkey*3;
        if(c>=13)
            return 2;
        if(c>=12 && left>=5)
            return 2;
        if(c>=11 && left>=20)
            return 2;
        if(c>=10 && left>=30)
            return 2;
        if(c>=9 && left>=39)
            return 2;
    }

    return 1;//ina4e
}
void COKEY::Intelect3(int Player,int Boia)//intelekta za 14 poredni
{
    int OKEY1;
    int left=0;
    int Passed[60],PassedBr=0;
    if(OKEY%100==13)
    {
        OKEY1=(OKEY-(OKEY%100))+1;
    }
    else
        OKEY1=OKEY+1;
    int RealBoards[2][17];
    for(int i=0;i<17;i++)
    {
    	
        if(Boards[Player][0][i]!=OKEY1 && Boards[Player][0][i]!=500)
        {
            RealBoards[0][i]=Boards[Player][0][i];
        }
        if(Boards[Player][0][i]==500)
        {
            RealBoards[0][i]=OKEY1;
        }
        if(Boards[Player][0][i]==OKEY1)
        {
            RealBoards[0][i]=500;
        }
        if(Boards[Player][1][i]!=OKEY1 && Boards[Player][1][i]!=500)
        {
            RealBoards[1][i]=Boards[Player][1][i];
        }
        if(Boards[Player][1][i]==500)
        {
            RealBoards[1][i]=OKEY1;
        }
        if(Boards[Player][1][i]==OKEY1)
        {
            RealBoards[1][i]=500;
        }
    }
    int Blocks1[15]={0,0,0,0,0,0,0,0,0,0,0,0,0,0,0},Blocks1br=0;
    for(int i=0;i<17;i++)
    {
        if(RealBoards[0][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[0][i];
            Blocks1br++;
        }
        if(RealBoards[1][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[1][i];
            Blocks1br++;
        }
    }
    for(int i=0;i<Blocks1br;i++)
    {
        for(let j: number=i+1;j<Blocks1br;j++)
        {
            if(Blocks1[i]>Blocks1[j])
            {
                int a=Blocks1[i];
                Blocks1[i]=Blocks1[j];
                Blocks1[j]=a;
            }
        }
    }
    for(int i=0;i<9;i++)//kolko ima o6te v talona
    {
        left+=Talon2br[i];
    }
    for(int i=0;i<4;i++)//minalite
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            if(!(i==(Player+2)%4 && j==this.Talon1br[i]-1 && HaveToGet[Player-1]))
            {
                if(Talon1[i][j]!=500 && Talon1[i][j]!=OKEY1)
                {
                    Passed[PassedBr]=Talon1[i][j];
                    PassedBr++;
                }
                if(Talon1[i][j]==OKEY1)
                {
                    Passed[PassedBr]=500;
                    PassedBr++;
                }
                if(Talon1[i][j]==500)
                {
                    Passed[PassedBr]=OKEY1;
                    PassedBr++;
                }
            }
        }
    }
    Passed[PassedBr]=OKEY;
    PassedBr++;
    for(int i=0;i<PassedBr;i++)
    {
        for(let j: number=i+1;j<PassedBr;j++)
        {
            if(Passed[i]>Passed[j])
            {
                int a=Passed[i];
                Passed[i]=Passed[j];
                Passed[j]=a;
            }
        }
    }
    int BroiOkey=0;
    for(int i=0;i<Blocks1br;i++)
    {
        if(Blocks1[i]==500)
        {
            BroiOkey++;
        }
    }
    int a[14]={0,0,0,0,0,0,0,0,0,0,0,0,0,0};
    for(int i=1;i<=13;i++)
    {
        for(let j: number=0;j<Blocks1br;j++)
        {
            if(Blocks1[j]==i+100*Boia)
            {
                a[i]++;
            }
        }
    }
    if(HaveToGet[Player-1])
    {
        if(this.Talon1br[(Player+2)%4]>=1)
        {
            int p;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==OKEY1)
                p=500;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==500)
                p=OKEY1;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=OKEY1 && Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=500)
                p=Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1];
            if(p==OKEY1 || (p/100==Boia && (p%100==1 || a[p]==0)))
            {
                for(int i=0;i<17;i++)
                {
                    if(Boards[Player][0][i]==0)
                    {
                        GetPool(10,Player,i);
                        goto end;
                    }
                }	
            }
        }
        for(int i=0;i<10;i++)
        {
            if(Talon2br[i]>0)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(Boards[Player][0][j]==0)
                    {
                        GetPool(i,Player,j);
                        goto end;
                    }
                }
            }
        }
        if(this.Talon1br[(Player+2)%4]>0)
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==0)
                {
                    GetPool(10,Player,i);
                    goto end;
                }
            }
        }
    }
    if(this.HaveToTake[Player-1])
    {
        int BadBr=0;
        BadBr+=2-a[1];
        for(int i=2;i<=13;i++)
        {
            int ci=1-a[i];
            if(ci<0)ci=0;
            BadBr+=ci;
        }
        if(BadBr<=BroiOkey)
        {
            for(int i=0;i<17;i++)
            {
                if(RealBoards[0][i]>0)
                {
                    for(let j: number=0;j<17;j++)
                    {
                        if(RealBoards[1][j]==0)
                        {
                            ChangePlaces(i,j+100,Player);
                            RealBoards[1][j]=RealBoards[0][i];
                            RealBoards[0][i]=0;
                            break;
                        }
                    }
                }
            }
            for(int i=0;i<17;i++)
            {
                if(RealBoards[1][i]==100*Boia+1)
                {
                    if(RealBoards[0][1]==0)
                    {
                        ChangePlaces(i+100,1,Player);
                        RealBoards[0][1]=RealBoards[1][i];
                        RealBoards[1][i]=0;
                    }
                    if(RealBoards[0][14]==0 && RealBoards[0][1]!=0)
                    {
                        ChangePlaces(i+100,14,Player);
                        RealBoards[0][14]=RealBoards[1][i];
                        RealBoards[1][i]=0;
                    }
                }
            }
            for(int i=2;i<=13;i++)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(RealBoards[1][j]==100*Boia+i)
                    {
                        if(RealBoards[0][i]==0)
                        {
                            ChangePlaces(j+100,i,Player);
                            RealBoards[0][i]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                }
            }
    	
            for(int i=1;i<=14;i++)
            {
                if(RealBoards[0][i]==0)
                {
                    for(let j: number=0;j<17;j++)
                    {
                        if(RealBoards[1][j]==500)
                        {
                            ChangePlaces(j+100,i,Player);
                            RealBoards[0][i]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                }
            }
            for(int i=0;i<17;i++)
            {
                if(RealBoards[1][i]>0)
                {
                    Finnished2(Player,100+i,3);
                    goto end;
                }
            }
        }
        for(int i=0;i<17;i++)
        {
            if(RealBoards[0][i]!=0 && RealBoards[0][i]!=500 && (RealBoards[0][i]/100!=Boia || 
                (a[RealBoards[0][i]%100]>1 && RealBoards[0][i]%100!=1)))
            {
                TakePool(i,Player);
                goto end;
            }
            if(RealBoards[1][i]!=0 && RealBoards[1][i]!=500 && (RealBoards[1][i]/100!=Boia || 
                (a[RealBoards[1][i]%100]>1 && RealBoards[1][i]%100!=1)))
            {
                TakePool(i+100,Player);
                goto end;
            }
        }
    }
end:;
}
void COKEY::Intelect2(int Player)//intelekta za 4ift
{
    int OKEY1;
    int left=0;
    int Passed[60],PassedBr=0;
    if(OKEY%100==13)
    {
        OKEY1=(OKEY-(OKEY%100))+1;
    }
    else
        OKEY1=OKEY+1;
    int RealBoards[2][17];
    for(int i=0;i<17;i++)
    {
    	
        if(Boards[Player][0][i]!=OKEY1 && Boards[Player][0][i]!=500)
        {
            RealBoards[0][i]=Boards[Player][0][i];
        }
        if(Boards[Player][0][i]==500)
        {
            RealBoards[0][i]=OKEY1;
        }
        if(Boards[Player][0][i]==OKEY1)
        {
            RealBoards[0][i]=500;
        }
        if(Boards[Player][1][i]!=OKEY1 && Boards[Player][1][i]!=500)
        {
            RealBoards[1][i]=Boards[Player][1][i];
        }
        if(Boards[Player][1][i]==500)
        {
            RealBoards[1][i]=OKEY1;
        }
        if(Boards[Player][1][i]==OKEY1)
        {
            RealBoards[1][i]=500;
        }
    }
    int Blocks1[15]={0,0,0,0,0,0,0,0,0,0,0,0,0,0,0},Blocks1br=0;
    for(int i=0;i<17;i++)
    {
        if(RealBoards[0][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[0][i];
            Blocks1br++;
        }
        if(RealBoards[1][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[1][i];
            Blocks1br++;
        }
    }
    for(int i=0;i<Blocks1br;i++)
    {
        for(let j: number=i+1;j<Blocks1br;j++)
        {
            if(Blocks1[i]>Blocks1[j])
            {
                int a=Blocks1[i];
                Blocks1[i]=Blocks1[j];
                Blocks1[j]=a;
            }
        }
    }
    for(int i=0;i<9;i++)//kolko ima o6te v talona
    {
        left+=Talon2br[i];
    }
    for(int i=0;i<4;i++)//minalite
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            if(!(i==(Player+2)%4 && j==this.Talon1br[i]-1 && HaveToGet[Player-1]))
            {
                if(Talon1[i][j]!=500 && Talon1[i][j]!=OKEY1)
                {
                    Passed[PassedBr]=Talon1[i][j];
                    PassedBr++;
                }
                if(Talon1[i][j]==OKEY1)
                {
                    Passed[PassedBr]=500;
                    PassedBr++;
                }
                if(Talon1[i][j]==500)
                {
                    Passed[PassedBr]=OKEY1;
                    PassedBr++;
                }
            }
        }
    }
    Passed[PassedBr]=OKEY;
    PassedBr++;
    for(int i=0;i<PassedBr;i++)
    {
        for(let j: number=i+1;j<PassedBr;j++)
        {
            if(Passed[i]>Passed[j])
            {
                int a=Passed[i];
                Passed[i]=Passed[j];
                Passed[j]=a;
            }
        }
    }
    int Ch[7],BroiCh=0,BroiOkey=0;
    for(int i=0;i<Blocks1br;i++)
    {
        if(Blocks1[i]==500)
        {
            BroiOkey++;
        }
        if(Blocks1[i]==Blocks1[i+1] && i!=Blocks1br && Blocks1[i]!=500)
        {
            Ch[BroiCh]=Blocks1[i];
            BroiCh++;
        }
    }
    if(HaveToGet[Player-1])
    {
        if(this.Talon1br[(Player+2)%4]>=1)
        {
            int p;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==OKEY1)
                p=500;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==500)
                p=OKEY1;
            if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=OKEY1 && Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=500)
                p=Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1];
            for(let j: number=0;j<Blocks1br;j++)
            {
                if(p==500 || p==Blocks1[j])
                {
                    for(int i=0;i<17;i++)
                    {
                        if(Boards[Player][0][i]==0)
                        {
                            GetPool(10,Player,i);
                            goto end;
                        }
                    }	
                }
            }
        }
        for(int i=0;i<10;i++)
        {
            if(Talon2br[i]>0)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(Boards[Player][0][j]==0)
                    {
                        GetPool(i,Player,j);
                        goto end;
                    }
                }
            }
        }
        if(this.Talon1br[(Player+2)%4]>0)
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==0)
                {
                    GetPool(10,Player,i);
                    goto end;
                }
            }
        }
    }
    if(this.HaveToTake[Player-1])
    {
        if(BroiCh+BroiOkey>=7)
        {
            for(int i=0;i<17;i++)
            {
                if(RealBoards[0][i]>0)
                {
                    for(let j: number=0;j<17;j++)
                    {
                        if(RealBoards[1][j]==0)
                        {
                            ChangePlaces(i,j+100,Player);
                            RealBoards[1][j]=RealBoards[0][i];
                            RealBoards[0][i]=0;
                            break;
                        }
                    }
                }
            }
            for(int i=0;i<=BroiCh;i++)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(RealBoards[1][j]==Ch[i])
                    {
                        if(RealBoards[0][2*i]==0)
                        {
                            ChangePlaces(j+100,2*i,Player);
                            RealBoards[0][2*i]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                        if(RealBoards[0][2*i+1]==0 && RealBoards[0][2*i]>0)
                        {
                            ChangePlaces(j+100,2*i+1,Player);
                            RealBoards[0][2*i+1]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                }
            }
            if(BroiOkey==2)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(RealBoards[1][j]==500)
                    {
                        if(RealBoards[0][10]==0)
                        {
                            ChangePlaces(j+100,10,Player);
                            RealBoards[0][10]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                        if(RealBoards[0][12]==0 && RealBoards[0][10]>0)
                        {
                            ChangePlaces(j+100,12,Player);
                            RealBoards[0][12]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                    if(RealBoards[1][j]!=500 && RealBoards[1][j]>0)
                    {
                        if(RealBoards[0][11]==0)
                        {
                            ChangePlaces(j+100,11,Player);
                            RealBoards[0][11]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                        if(RealBoards[0][13]==0 && RealBoards[0][11]>0)
                        {
                            ChangePlaces(j+100,13,Player);
                            RealBoards[0][13]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                }
            }
            if(BroiOkey==1)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(RealBoards[1][j]==500)
                    {
                        if(RealBoards[0][12]==0)
                        {
                            ChangePlaces(j+100,12,Player);
                            RealBoards[0][12]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                    if(RealBoards[1][j]!=500 && RealBoards[1][j]>0)
                    {
                        if(RealBoards[0][13]==0)
                        {
                            ChangePlaces(j+100,13,Player);
                            RealBoards[0][13]=RealBoards[1][j];
                            RealBoards[1][j]=0;
                        }
                    }
                }
            }
            for(int i=0;i<17;i++)
            {
                if(RealBoards[1][i]>0)
                {
                    Finnished2(Player,100+i,2);
                    goto end;
                }
            }
        }
        for(int i=0;i<17;i++)
        {
            for(let j: number=0;j<PassedBr;j++)
            {
                if(RealBoards[0][i]==Passed[j] && Passed[j]!=500)
                {
                    TakePool(i,Player);
                    goto end;
                }
                if(RealBoards[1][i]==Passed[j] && Passed[j]!=500)
                {
                    TakePool(i+100,Player);
                    goto end;
                }
            }
        }
        for(int i=0;i<17;i++)
        {
            bool ECh=false;
            for(let j: number=0;j<BroiCh;j++)
            {
                if(RealBoards[0][i]==Ch[j])
                {
                    ECh=true;
                	
                }				
            }
            if(!ECh && RealBoards[0][i]!=500)
            {
                TakePool(i,Player);
                goto end;
            }
        }
        for(int i=0;i<17;i++)
        {
            bool ECh=false;
            for(let j: number=0;j<BroiCh;j++)
            {
                if(RealBoards[1][i]==Ch[j])
                {
                    ECh=true;
                	
                }				
            }
            if(!ECh && RealBoards[1][i]!=500)
            {
                TakePool(i+100,Player);
                goto end;
            }
        }
        for(int i=0;i<17;i++)
        {	
            if(RealBoards[0][i]>0)
            {
                TakePool(i,Player);
                goto end;		
            }				
            if(RealBoards[1][i]>0)
            {
                TakePool(i+100,Player);
                goto end;		
            }
        }
    }
end:;
}
void COKEY::FindIzlishni(int Player,int a[15],int aBr)
{
    int c1[15];
    for(int i=0;i<aBr;i++)
    {
        c1[i]=a[i];
    }
    bool HaveFindGroup=false;
    if(aBr==0)
    {
        this.Izlishni[0][0]=0;
        IzlishniBr=1;
    }
    if(aBr==1)
    {
        if(this.Izlishni[0][0]>1)
        {
            this.Izlishni[0][0]=1;
            this.Izlishni[0][1]=a[0];
            IzlishniBr=1;
        }
        if(this.Izlishni[0][0]==1)
        {
            this.Izlishni[IzlishniBr][0]=1;
            this.Izlishni[IzlishniBr][1]=a[0];
            IzlishniBr++;
        }
    }
    if(aBr==2)
    {
        if(this.Izlishni[0][0]>2)
        {
            this.Izlishni[0][0]=2;
            this.Izlishni[0][1]=a[0];
            this.Izlishni[0][2]=a[1];
            IzlishniBr=1;
        }
        if(this.Izlishni[0][0]==2)
        {
            this.Izlishni[IzlishniBr][0]=2;
            this.Izlishni[IzlishniBr][1]=a[0];
            this.Izlishni[IzlishniBr][2]=a[1];
            IzlishniBr++;
        }
    }
//	if(aBr>2)
    {
        int BrOkey=0;
        for(int i=0;i<aBr;i++)
        {
            if(a[i]==500)
                BrOkey++;
        }
        for(int i=0;i<aBr;i++)//maha grupa ot poredni
        {
            for(let j: number=i+1;j<aBr;j++)
            {
                if(a[i]>a[j])
                {
                    int c=a[i];
                    a[i]=a[j];
                    a[i]=c;
                }
            }
        }
        for(int i=0;i<aBr;i++)
        {
            if(a[i]!=500)
            {
                int Gr[15],GrBr=1,OK1br=BrOkey;
                Gr[0]=a[i];
                for(int k=0;k<5;k++)
                {
                    bool get=false;
                    for(let j: number=0;j<aBr;j++)
                    {
                    //	if(i+j<aBr)
                        {
                            if(Gr[0]%100==1)
                            {
                                if(Gr[GrBr-1]+1==a[j])
                                {
                                    Gr[GrBr]=a[j];
                                    GrBr++;
                                    get =true;
                                }
                            }
                            if(Gr[0]%100!=1)
                            {
                                if(Gr[GrBr-1]+1==a[j] && Gr[GrBr-1]%100!=1)
                                {
                                    Gr[GrBr]=a[j];
                                    GrBr++;
                                    get=true;
                                }
                                if(Gr[GrBr-1]%100==13 && a[j]%100==1 && Gr[0]/100==a[j]/100)
                                {
                                    Gr[GrBr]=a[j];
                                    GrBr++;
                                    get=true;
                                }
                            }
                        }
                    }
                    if(!get && OK1br>0 )
                    {
                        if(Gr[0]%100==1 && OK1br > 0)
                        {
                            Gr[GrBr]=Gr[GrBr-1]+1;
                            GrBr++;
                            OK1br--;
                        }
                        if(Gr[0]%100!=1)
                        {
                            if(Gr[GrBr-1]%100!=1 && Gr[GrBr-1]%100!=13 && OK1br > 0)
                            {
                                Gr[GrBr]=Gr[GrBr-1]+1;
                                GrBr++;
                                OK1br--;
                            }
                            if(Gr[GrBr-1]%100==1 && OK1br > 0)
                            {
                                for(int u=GrBr-1;u>=0;u--)
                                {
                                    Gr[u+1]=Gr[u];
                                }
                                Gr[0]=Gr[1]-1;;
                                GrBr++;
                                OK1br--;
                            }
                            if(Gr[GrBr-1]%100==13 && OK1br > 0)
                            {
                                Gr[GrBr]=Gr[GrBr-1]-12;
                                GrBr++;
                                OK1br--;
                            }
                        }
                    }
                }
                for(int t=0;t<GrBr;t++)
                {
                    bool is=false;
                    for(int v=0;v<aBr;v++)
                    {
                        if(Gr[t]==a[v])
                            is=true;
                    }
                    if(!is)
                    {
                        Gr[t]=500;
                    }
                }
                if(GrBr>=3)
                {
                    for(int t=3;t<=GrBr;t++)
                    {
                        int a1[16],a2Br=0,a2[13];
                        for(int u=0;u<aBr;u++)
                        {
                            a1[u]=a[u];
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            for(int v=0;v<t;v++)
                            {
                                if(a[u]==Gr[v])
                                {
                                    a1[u]=0;

                                    if(a1[u+1]==Gr[v])
                                        u++;
                                }
                            }
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            if(a1[u]!=0)
                            {
                                a2[a2Br]=a1[u];
                                a2Br++;
                            }
                        }
                        //
                        if(a2Br==0 || (a2Br==3 && a2[0]==306 && a2[1]==307 && a2[2]==308))
                        {
                            int qqq=0;
                        }
                        //
                        FindIzlishni(Player,a2,a2Br);
                        if(this.Izlishni[0][0]==0 && this.IsReady)
                        {
                            for(int f=0;f<t;f++)
                            {
                                if(GroupedBr>13) {
                                    int brint=0;
                                    goto end;
                                }
                                Grouped[GroupedBr]=Gr[f];
                                GroupedBr++;
                            }
                            goto end;
                        }
                        HaveFindGroup=true;
                    }
                }
                if(Gr[0]==500)
                {
                    for(let j: number=1;j<GrBr;j++)
                    {
                        Gr[j-1]=Gr[j];
                    }
                    GrBr--;
                }
                if(GrBr>=3)
                {
                    for(int t=3;t<=GrBr;t++)
                    {
                        int a1[16],a2Br=0,a2[13];
                        for(int u=0;u<aBr;u++)
                        {
                            a1[u]=a[u];
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            for(int v=0;v<t;v++)
                            {
                                if(a[u]==Gr[v])
                                {
                                    a1[u]=0;

                                    if(a1[u+1]==Gr[v])
                                        u++;
                                }
                            }
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            if(a1[u]!=0)
                            {
                                a2[a2Br]=a1[u];
                                a2Br++;
                            }
                        }
                        //
                        if(a2Br==0 || (a2Br==3 && a2[0]==306 && a2[1]==307 && a2[2]==308))
                        {
                            int qqq=0;
                        }
                        //
                        FindIzlishni(Player,a2,a2Br);
                        if(this.Izlishni[0][0]==0 && this.IsReady)
                        {
                            for(int f=0;f<t;f++)
                            {
                                Grouped[GroupedBr]=Gr[f];
                                GroupedBr++;
                            }
                            goto end;
                        }
                        HaveFindGroup=true;
                    }
                }
                if(Gr[0]==500)
                {
                    for(let j: number=1;j<GrBr;j++)
                    {
                        Gr[j-1]=Gr[j];
                    }
                    GrBr--;
                }
                if(GrBr>=3)
                {
                    for(int t=3;t<=GrBr;t++)
                    {
                        int a1[16],a2Br=0,a2[13];
                        for(int u=0;u<aBr;u++)
                        {
                            a1[u]=a[u];
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            for(int v=0;v<t;v++)
                            {
                                if(a[u]==Gr[v])
                                {
                                    a1[u]=0;

                                    if(a1[u+1]==Gr[v])
                                        u++;
                                }
                            }
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            if(a1[u]!=0)
                            {
                                a2[a2Br]=a1[u];
                                a2Br++;
                            }
                        }
                        //
                        if(a2Br==0 || (a2Br==3 && a2[0]==306 && a2[1]==307 && a2[2]==308))
                        {
                            int qqq=0;
                        }
                        //
                        FindIzlishni(Player,a2,a2Br);
                        if(this.Izlishni[0][0]==0 && this.IsReady)
                        {
                            for(int f=0;f<t;f++)
                            {
                                Grouped[GroupedBr]=Gr[f];
                                GroupedBr++;		
                            }
                            goto end;
                        }
                        HaveFindGroup=true;
                    }
                }
            }
        }
        for(int i=0;i<aBr;i++)
        {
            if(a[i]!=500)
            {
                int Gr[4],GrBr=1,OK1br=BrOkey;
                Gr[0]=a[i];
                for(let j: number=0;j<aBr;j++)
                {
                    if(GrBr==1)
                    {
                        if(Gr[0]%100==a[j]%100 && Gr[0]!=a[j])
                        {
                            Gr[1]=a[j];
                            GrBr++;
                        }
                    }
                    if(GrBr==2)
                    {
                        if(Gr[0]%100==a[j]%100 && Gr[0]!=a[j] && Gr[1]!=a[j])
                        {
                            Gr[2]=a[j];
                            GrBr++;
                        }
                    }
                    if(GrBr==3)
                    {
                        if(Gr[0]%100==a[j]%100 && Gr[0]!=a[j] && Gr[1]!=a[j] && Gr[2]!=a[j])
                        {
                            Gr[3]=a[j];
                            GrBr++;
                        }
                    }
                }
                for(int k=0;k<2;k++)
                {
                    if(OK1br>0 && GrBr<4)
                    {
                        Gr[GrBr]=500;
                        OK1br--;
                        GrBr++;
                    }
                }
                if(GrBr>=3)
                {
                    for(int t=3;t<=GrBr;t++)
                    {
                        int a1[16],a2Br=0,a2[13];
                        for(int u=0;u<aBr;u++)
                        {
                            a1[u]=a[u];
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            for(int v=0;v<t;v++)
                            {
                                if(a[u]==Gr[v])
                                {
                                    a1[u]=0;

                                    if(a1[u+1]==Gr[v])
                                        u++;
                                }
                            }
                        }
                        for(int u=0;u<aBr;u++)
                        {
                            if(a1[u]!=0)
                            {
                                a2[a2Br]=a1[u];
                                a2Br++;
                            }
                        }
                        //
                        if(a2Br==0 || (a2Br==3 && a2[0]==306 && a2[1]==307 && a2[2]==308))
                        {
                            int qqq=0;
                        }
                        //
                        FindIzlishni(Player,a2,a2Br);
                        if(this.Izlishni[0][0]==0 && this.IsReady)
                        {
                            for(int f=0;f<t;f++)///
                            {
                                Grouped[GroupedBr]=Gr[f];
                                GroupedBr++;
                            }
                            goto end;
                        }
                        HaveFindGroup=true;
                    }
                }
            }
        }
    }
    if(!HaveFindGroup)
    {
        if(this.Izlishni[0][0]>aBr)
        {
            this.Izlishni[0][0]=aBr;
            for(int i=1;i<=aBr;i++)
            {
                this.Izlishni[0][i]=a[i-1];
            }
            IzlishniBr=1;
        }
        if(this.Izlishni[0][0]==aBr)
        {
            this.Izlishni[IzlishniBr][0]=aBr;
            for(int i=1;i<=aBr;i++)
            {
                this.Izlishni[IzlishniBr][i]=a[i-1];
            }
            IzlishniBr++;
        }
    }
end:;
}
void COKEY::Intelect1(int Player)//obiknovennia
{
    int OKEY1;
    int left=0;
    int Passed[60],PassedBr=0;
    if(OKEY%100==13)
    {
        OKEY1=(OKEY-(OKEY%100))+1;
    }
    else
        OKEY1=OKEY+1;
    int RealBoards[2][17];
    for(int i=0;i<17;i++)
    {
    	
        if(Boards[Player][0][i]!=OKEY1 && Boards[Player][0][i]!=500)
        {
            RealBoards[0][i]=Boards[Player][0][i];
        }
        if(Boards[Player][0][i]==500)
        {
            RealBoards[0][i]=OKEY1;
        }
        if(Boards[Player][0][i]==OKEY1)
        {
            RealBoards[0][i]=500;
        }
        if(Boards[Player][1][i]!=OKEY1 && Boards[Player][1][i]!=500)
        {
            RealBoards[1][i]=Boards[Player][1][i];
        }
        if(Boards[Player][1][i]==500)
        {
            RealBoards[1][i]=OKEY1;
        }
        if(Boards[Player][1][i]==OKEY1)
        {
            RealBoards[1][i]=500;
        }
    }
    int Blocks1[15]={0,0,0,0,0,0,0,0,0,0,0,0,0,0,0},Blocks1br=0;
    for(int i=0;i<17;i++)
    {
        if(RealBoards[0][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[0][i];
            Blocks1br++;
        }
        if(RealBoards[1][i]>0)
        {
            Blocks1[Blocks1br]=RealBoards[1][i];
            Blocks1br++;
        }
    }
    for(int i=0;i<Blocks1br;i++)
    {
        for(let j: number=i+1;j<Blocks1br;j++)
        {
            if(Blocks1[i]>Blocks1[j])
            {
                int a=Blocks1[i];
                Blocks1[i]=Blocks1[j];
                Blocks1[j]=a;
            }
        }
    }
    if(HaveToGet[Player-1] && Blocks1br!=14)
        int r=5;
    if(this.HaveToTake[Player-1] && Blocks1br!=15)
        int r=5;
    for(int i=0;i<9;i++)//kolko ima o6te v talona
    {
        left+=Talon2br[i];
    }
    for(int i=0;i<4;i++)//minalite
    {
        for(let j: number=0;j<this.Talon1br[i];j++)
        {
            if(!(i==(Player+2)%4 && j==this.Talon1br[i]-1 && HaveToGet[Player-1]))
            {
                if(Talon1[i][j]!=500 && Talon1[i][j]!=OKEY1)
                {
                    Passed[PassedBr]=Talon1[i][j];
                    PassedBr++;
                }
                if(Talon1[i][j]==OKEY1)
                {
                    Passed[PassedBr]=500;
                    PassedBr++;
                }
                if(Talon1[i][j]==500)
                {
                    Passed[PassedBr]=OKEY1;
                    PassedBr++;
                }
            }
        }
    }
    Passed[PassedBr]=OKEY;
    PassedBr++;
    for(int i=0;i<PassedBr;i++)
    {
        for(let j: number=i+1;j<PassedBr;j++)
        {
            if(Passed[i]>Passed[j])
            {
                int a=Passed[i];
                Passed[i]=Passed[j];
                Passed[j]=a;
            }
        }
    }
    if(HaveToGet[Player-1])
    {
        int Izlishni1[5000][16],Izlishni1Br=0;
        int Izlishni2[5000][16],Izlishni2Br=0;
        this.Izlishni[0][0]=20;
        FindIzlishni(Player,Blocks1,14);
        Izlishni1Br=IzlishniBr;
        for(int i=0;i<Izlishni1Br;i++)
        {
            Izlishni1[i][0]=this.Izlishni[i][0];
            for(let j: number=1;j<=this.Izlishni[i][0];j++)
            {
                Izlishni1[i][j]=this.Izlishni[i][j];
            }
        }
        int Blocks2[15];
        for(int i=0;i<14;i++)
        {
            Blocks2[i]=Blocks1[i];
        }
        int p;
        if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==OKEY1)
            p=500;
        if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]==500)
            p=OKEY1;
        if(Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=OKEY1 && Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1]!=500)
            p=Talon1[(Player+2)%4][this.Talon1br[(Player+2)%4]-1];
        Blocks2[14]=p;
        this.Izlishni[0][0]=20;
        FindIzlishni(Player,Blocks2,15);
        Izlishni2Br=IzlishniBr;
        for(int i=0;i<Izlishni2Br;i++)
        {
            Izlishni2[i][0]=this.Izlishni[i][0];
            for(let j: number=1;j<=this.Izlishni[i][0];j++)
            {
                Izlishni2[i][j]=this.Izlishni[i][j];
            }
        }
        if(p==500 || Izlishni2[0][0]<=Izlishni1[0][0])
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==0)
                {
                    GetPool(10,Player,i);
                    goto end;
                }
            }
        }
        else
        {
            for(int i=0;i<Izlishni1Br;i++)
            {
                for(let j: number=1;j<Izlishni1[i][0];j++)
                {
                    int t=0;
                    if(Izlishni1[i][j]%100 === p%100 && 
                        Izlishni1[i][j] != p)
                    {		
                        int br=4;
                        for(int k=0;k<PassedBr;k++)
                        {
                            if(Izlishni1[i][j]%100==Passed[k]%100 && Izlishni1[i][j]!=Passed[k] &&
                                Passed[k] != p)
                                br--;
                        }
                        for(int k=0;k<14;k++)
                        {
                            if(Izlishni1[i][j]%100==Blocks1[k]%100 && Izlishni1[i][j]!=Blocks1[k] &&
                                Blocks1[k] != p)
                                br--;
                        }
                        for(int k=0;k<Izlishni1Br;k++)
                        {
                            if(k!=i && Izlishni1[k]==Izlishni1[i])
                            {
                                br=0;
                            }
                        }
                        t+=br;
                    }
                    if(Izlishni1[i][j] === p+1)
                    {
                        int br=4;
                        for(int k=0;k<PassedBr;k++)
                        {
                            if(Izlishni1[i][j]==Passed[k]-1 || Passed[k]+1 === p)
                                br--;
                        }
                        for(int k=0;k<14;k++)
                        {
                            if(Izlishni1[i][j]==Passed[k]-1 || Passed[k]+1 === p)
                                br--;
                        }
                        for(int k=0;k<Izlishni1Br;k++)
                        {
                            if(k!=i && Izlishni1[k]==Izlishni1[i])
                            {
                                br=0;
                            }
                        }
                        t+=br;
                    }
                    if(Izlishni1[i][j] === p-1)
                    {
                        int br=4;
                        for(int k=0;k<PassedBr;k++)
                        {
                            if(Izlishni1[i][j]==Passed[k]+1 || Passed[k]-1 === p)
                                br--;
                        }
                        for(int k=0;k<14;k++)
                        {
                            if(Izlishni1[i][j]==Passed[k]+1 || Passed[k]-1 === p)
                                br--;
                        }
                        for(int k=0;k<Izlishni1Br;k++)
                        {
                            if(k!=i && Izlishni1[k]==Izlishni1[i])
                            {
                                br=0;
                            }
                        }
                        t+=br;
                    }
                    if(t>=4)
                    {
                        for(int t=0;t<17;t++)
                        {
                            if(Boards[Player][0][t]==0)
                            {
                                GetPool(10,Player,t);
                                goto end;
                            }
                        }
                    }
                }
            }
        }
        for(int i=0;i<10;i++)
        {
            if(Talon2br[i]>0)
            {
                for(let j: number=0;j<17;j++)
                {
                    if(Boards[Player][0][j]==0)
                    {
                        GetPool(i,Player,j);
                        goto end;
                    }
                }
            }
        }
        if(this.Talon1br[(Player+2)%4]>0)
        {
            for(int i=0;i<17;i++)
            {
                if(Boards[Player][0][i]==0)
                {
                    GetPool(10,Player,i);
                    goto end;
                }
            }
        }
    }
    if(this.HaveToTake[Player-1])//do tuk
    {//opredelia dali moje da priklu4i
        for(int i=0;i<15;i++)
        {
            if(Blocks1[i]==500)
            {
                int izl=Blocks1[i];
                int Blocks2[14],Blocks2Br=0;
                for(int k=0;k<15;k++)
                {
                    if(k!=i)
                    {
                        Blocks2[Blocks2Br]=Blocks1[k];
                        Blocks2Br++;
                    }
                }
                this.Izlishni[0][0]=20;
                FindIzlishni(Player,Blocks2,14);
                if(this.Izlishni[0][0]==0)
                {
                    for(int i=0;i<17;i++)
                    {
                        if(RealBoards[0][i]>0)
                        {
                            for(let j: number=0;j<17;j++)
                            {
                                if(RealBoards[1][j]==0)
                                {
                                    ChangePlaces(i,j+100,Player);
                                    RealBoards[1][j]=RealBoards[0][i];
                                    RealBoards[0][i]=0;
                                    break;
                                }
                            }
                        }
                    }
                    for(int i=0;i<17;i++)
                    {
                        for(let j: number=0;j<14;j++)
                        {
                            if(RealBoards[1][i]==Grouped[j])
                            {
                                if(RealBoards[0][j]==0)
                                {
                                    ChangePlaces(i+100,j,Player);
                                    RealBoards[0][j]=RealBoards[1][i];
                                    RealBoards[1][i]=0;
                                }
                            }
                        }
                    }
                    for(int i=0;i<17;i++)
                    {
                        if(RealBoards[1][i]>0 && RealBoards[1][i]==izl)
                        {
                            for(let j: number=0;j<17;j++)//?
                            {
                                if(Boards[Player][1][j]>0 && i!=j)
                                {
                                    int a=5;
                                }
                            }
                            Finnished2(Player,100+i,2);
                            goto end;
                        }
                    }
                }
            }
        }
        for(int i=0;i<15;i++)
        {
            int izl=Blocks1[i];
            int Blocks2[14],Blocks2Br=0;
            for(int k=0;k<15;k++)
            {
                if(k!=i)
                {
                    Blocks2[Blocks2Br]=Blocks1[k];
                    Blocks2Br++;
                }
            }
            GroupedBr=0;
            this.Izlishni[0][0]=20;
            this.IsReady=true;
            FindIzlishni(Player,Blocks2,14);
            this.IsReady=false;
            if(this.Izlishni[0][0]==0)
            {
                for(int i=0;i<17;i++)
                {
                    if(RealBoards[0][i]>0)
                    {
                        for(let j: number=0;j<17;j++)
                        {
                            if(RealBoards[1][j]==0)
                            {
                                ChangePlaces(i,j+100,Player);
                                RealBoards[1][j]=RealBoards[0][i];
                                RealBoards[0][i]=0;
                                break;
                            }
                        }
                    }
                }
                for(int i=0;i<17;i++)
                {
                    for(let j: number=0;j<14;j++)
                    {
                        if(RealBoards[1][i]==Grouped[j])
                        {
                            if(RealBoards[0][j]==0)
                            {
                                ChangePlaces(i+100,j,Player);
                                RealBoards[0][j]=RealBoards[1][i];
                                RealBoards[1][i]=0;
                            }
                        }
                    }
                }
                for(int i=0;i<17;i++)
                {
                    if(RealBoards[1][i]>0 && RealBoards[1][i]==izl)
                    {
                        Finnished2(Player,100+i,1);
                        goto end;
                    }
                }
            }	
        }
///////////opredelia pool-a koito 6te dade
        this.Izlishni[0][0]=20;
        FindIzlishni(Player,Blocks1,15);
        int IzlishniQuef[5000];
        for(int i=0;i<IzlishniBr;i++)
        {
            IzlishniQuef[i]=0;
            for(let j: number=1;j<this.Izlishni[i][0];j++)
            {
                for(int k=j+1;k<this.Izlishni[i][0];k++)
                {
                    if(this.Izlishni[i][j]%100 === this.Izlishni[i][k]%100 && this.Izlishni[i][j]%100 != this.Izlishni[i][k]%100)
                    {
                        int br=4;
                        for(int f=0;f<PassedBr;f++)
                        {
                            if(this.Izlishni[i][j]%100==Passed[f]%100 && this.Izlishni[i][j] != Passed[f] &&
                                Passed[f] != this.Izlishni[i][k])
                                br--;
                        }
                        for(int f=0;f<15;f++)
                        {
                            if(this.Izlishni[i][j]%100==Blocks1[f]%100 && this.Izlishni[i][j]!=Blocks1[f] &&
                                Blocks1[f] != this.Izlishni[i][k])
                                br--;
                        }
                        IzlishniQuef[i]+=br;
                    }
                    if(this.Izlishni[i][j] === this.Izlishni[i][k]+1)
                    {
                        int br;
                        if(this.Izlishni[i][k]%100!=1)
                            br=4;
                        if(this.Izlishni[i][k]%100==1)
                            br=2;
                        for(int f=0;f<PassedBr;f++)
                        {
                            if(this.Izlishni[i][j]==Passed[f]-1 || Passed[f]+1 === this.Izlishni[i][k])
                                br--;
                        }
                        for(int f=0;f<15;f++)
                        {
                            if(this.Izlishni[i][j]==Blocks1[f]-1 || Blocks1[f]+1 === this.Izlishni[i][k])
                                br--;
                        }
                        IzlishniQuef[i]+=br;
                    }
                    if(this.Izlishni[i][j] === this.Izlishni[i][k]-1)
                    {
                        int br;
                        if(this.Izlishni[i][j]%100!=1)
                            br=4;
                        if(this.Izlishni[i][j]%100==1)
                            br=2;
                        for(int f=0;f<PassedBr;f++)
                        {
                            if(this.Izlishni[i][j]==Passed[f]+1 || Passed[f]-1 === this.Izlishni[i][k])
                                br--;
                        }
                        for(int f=0;f<15;f++)
                        {
                            if(this.Izlishni[i][j]==Blocks1[f]+1 || Blocks1[f]-1 === this.Izlishni[i][k])
                                br--;
                        }
                        IzlishniQuef[i]+=br;
                    }
                    if(this.Izlishni[i][j] === this.Izlishni[i][k]+2)
                    {
                        int br=2;
                        for(int f=0;f<PassedBr;f++)
                        {
                            if(this.Izlishni[i][j]==Passed[k]+1)
                                br--;
                        }
                        for(int f=0;f<15;f++)
                        {
                            if(this.Izlishni[i][j]==Blocks1[f]+1)
                                br--;
                        }
                        IzlishniQuef[i]+=br;
                    }
                    if(this.Izlishni[i][j] === this.Izlishni[i][k]-2)
                    {
                        int br=2;
                        for(int f=0;f<PassedBr;f++)
                        {
                            if(this.Izlishni[i][j]==Passed[f]-1)
                                br--;
                        }
                        for(int f=0;f<15;f++)
                        {
                            if(this.Izlishni[i][j]==Blocks1[f]-1)
                                br--;
                        }
                        IzlishniQuef[i]+=br;
                    }
                }
            }
        }
        int que=0,ind=0;
        for(int i=0;i<IzlishniBr;i++)
        {
            if(IzlishniQuef[i]>que)
            {
                que=IzlishniQuef[i];
                ind=i;
            }
        }
        int Izlishni3[15],Izlishni3Br;
        Izlishni3Br=this.Izlishni[ind][0];
        for(int i=1;i<=Izlishni3Br;i++)
        {
            Izlishni3[i-1]=this.Izlishni[ind][i];
        }//
        int que1[15];
        for(let j: number=0;j<Izlishni3Br;j++)
        {
            que1[j]=0;
            bool bad=false;
            for(int k=0;k<Izlishni3Br;k++)
            {
                if(Izlishni3[j]%100 === Izlishni3[k]%100 && Izlishni3[j] != Izlishni3[k])
                {
                    int br=4;
                    for(int u=0;u<PassedBr;u++)
                    {
                        if(Izlishni3[j]%100==Passed[u]%100 && Izlishni3[j] != Passed[u] &&
                            Passed[u] != Izlishni3[k])
                            br--;
                    }
                    for(int u=0;u<15;u++)
                    {
                        if(Izlishni3[j]%100==Blocks1[u]%100 && Izlishni3[j]!=Blocks1[u] &&
                            Blocks1[u] != Izlishni3[k])
                            br--;
                    }
                    que1[j]+=br;
                }
                if(Izlishni3[j] === Izlishni3[k]+1)
                {
                    int br=4;
                    for(int u=0;u<PassedBr;u++)
                    {
                        if(Izlishni3[j]==Passed[u]-1 || Passed[u]+1 === Izlishni3[k])
                            br--;
                    }
                    for(int u=0;u<15;u++)
                    {
                        if(Izlishni3[j]==Blocks1[u]-1 || Blocks1[u]+1 === Izlishni3[k])
                            br--;
                    }
                    que1[j]+=br;
                }
                if(Izlishni3[j] === Izlishni3[k]-1)
                {
                    int br=4;
                    for(int u=0;u<PassedBr;u++)
                    {
                        if(Izlishni3[j]==Passed[u]+1 || Passed[u]-1 === Izlishni3[k])
                            br--;
                    }
                    for(int u=0;u<15;u++)
                    {
                        if(Izlishni3[j]==Blocks1[u]+1 || Blocks1[u]-1 === Izlishni3[k])
                            br--;
                    }
                    que1[j]+=br;
                }
                if(Izlishni3[j] === Izlishni3[k]+2)
                {
                    int br=2;
                    for(int u=0;u<PassedBr;u++)
                    {
                        if(Izlishni3[j]==Passed[u]+1)
                            br--;
                    }
                    for(int u=0;u<15;u++)
                    {
                        if(Izlishni3[j]==Blocks1[u]+1)
                            br--;
                    }
                    que1[j]+=br;
                }
                if(Izlishni3[j] === Izlishni3[k]-2)
                {
                    int br=2;
                    for(int u=0;u<PassedBr;u++)
                    {
                        if(Izlishni3[j]==Passed[u]-1)
                            br--;
                    }
                    for(int u=0;u<15;u++)
                    {
                        if(Izlishni3[j]==Blocks1[u]-1)
                            br--;
                    }
                    que1[j]+=br;
                }
                if(Izlishni3[j] === Izlishni3[k] && k!=j && k<j)
                {
                    bad=true;
                }
            }
            if(bad)
                que1[j]=0;
            if(Izlishni3[j]==500)
                que1[j]=1000;
        }
        //??
        int que3=0,ForGive;
        for(int i=0;i<Izlishni3Br;i++)
        {
            if(que1[que3]>que1[i])
                que3=i;
        }
        ForGive=Izlishni3[que3];
        for(int i=0;i<17;i++)
        {	
            if(RealBoards[0][i]==ForGive)
            {
                TakePool(i,Player);
                goto end;		
            }				
            if(RealBoards[1][i]==ForGive)
            {
                TakePool(i+100,Player);
                goto end;		
            }
        }
        for(int i=0;i<17;i++)
        {	
            if(RealBoards[0][i]>0)
            {
                TakePool(i,Player);
                goto end;		
            }				
            if(RealBoards[1][i]>0)
            {
                TakePool(i+100,Player);
                goto end;		
            }
        }

    }
end:;
}
void COKEY::OnLButtonDblClk(CPoint point)
{
    if(!watch) {
        if(this.HaveToTake[0])
        {
            for(int i=0;i<17;i++)
            {
                if(point.x > this.BoardRect[0][i].x*scale && point.x < this.BoardRect[0][i].x*scale+Joker.m_Width
                    && point.y > this.BoardRect[0][i].y*scale && point.y < this.BoardRect[0][i].y*scale+Joker.m_Height)
                {
                    if(Boards[1][0][i] != 0 && Boards[1][0][i] <1000)
                    {
                        TakePool(i,1);
                        sit_out_count=0;
                        if(this.OP) {
                            if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                if(!op_start) {
                                    SendGame(L"L");
                                    op_start=true;
                                }
                            }
                        }
                        int HaveToBeInvalidate1=1000+i;
                        int c=HaveToBeInvalidate1%1000;
                        m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                        this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                        goto end;
                    }
                }
            }
            for(int i=0;i<17;i++)
            {
                if(point.x > this.BoardRect[1][i].x*scale && point.x < this.BoardRect[1][i].x*scale+Joker.m_Width
                    && point.y > this.BoardRect[1][i].y*scale && point.y < this.BoardRect[1][i].y*scale+Joker.m_Height)
                {
                    if(Boards[1][1][i] != 0 && Boards[1][1][i] <1000)
                    {
                        TakePool(i+100,1);
                        sit_out_count=0;
                        if(this.OP) {
                            if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                if(!op_start) {
                                    SendGame(L"L");
                                    op_start=true;
                                }
                            }
                        }
                        int HaveToBeInvalidate1=1100+i;
                        int c=HaveToBeInvalidate1%1000;
                        m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                        this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                        goto end;
                    }
                }
            }
        }
        if(HaveToGet[0])
        {
            for(int i=0;i<10;i++)
            {
                if(Talon2br[i]>0 && (i==0 || Talon2br[i-1]==0))
                {
                    if(point.x<this.Talon2Pl[i][Talon2br[i]-1].x*scale+Joker.m_Width && point.x>this.Talon2Pl[i][Talon2br[i]-1].x*scale &&
                        point.y<this.Talon2Pl[i][Talon2br[i]-1].y*scale+Joker.m_Height&& point.y>this.Talon2Pl[i][Talon2br[i]-1].y*scale)
                    {
                        for(let j: number=0;j<17;j++)
                        {
                            if(Boards[1][0][j]==0)
                            {
                                GetPool(i,1,j);
                                sit_out_count=0;
                                if(this.OP) {
                                    if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                        if(!op_start) {
                                            SendGame(L"L");
                                            op_start=true;
                                        }
                                    }
                                }
                                int HaveToBeInvalidate1=1000+j;
                                int c=HaveToBeInvalidate1%1000;
                                m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                                this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                                goto end;
                            }
                        }
                    }
                }
            }
            if(this.Talon1br[3]>0)
            {
                if(point.x<this.Talon1Pl[3].x*scale+Joker.m_Width && point.x>this.Talon1Pl[3].x*scale &&
                            point.y<this.Talon1Pl[3].y*scale+Joker.m_Height&& point.y>this.Talon1Pl[3].y*scale)
                {	
                    for(let j: number=0;j<17;j++)
                    {
                        if(Boards[1][0][j]==0)
                        {
                            GetPool(10,1,j);
                            sit_out_count=0;
                            if(this.OP) {
                                if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
                                    if(!op_start) {
                                        SendGame(L"L");
                                        op_start=true;
                                    }
                                }
                            }
                            int HaveToBeInvalidate1=1000+j;
                            int c=HaveToBeInvalidate1%1000;
                            m_pwin->InvalidateRectScale(CRect(this.BoardPlaces[c/100][c%100].x-2,this.BoardPlaces[c/100][c%100].y-3,
                            this.BoardPlaces[c/100][c%100].x+Joker.m_Width+2,this.BoardPlaces[c/100][c%100].y+Joker.m_Height+3),false);
                            int HaveToBeInvalidate=104;
                            m_pwin->InvalidateRectScale(CRect(this.Talon1Pl[HaveToBeInvalidate%100-1].x-2,this.Talon1Pl[HaveToBeInvalidate%100-1].y-3,
                                this.Talon1Pl[HaveToBeInvalidate%100-1].x+Joker.m_Width+2,this.Talon1Pl[HaveToBeInvalidate%100-1].y+Joker.m_Height+3),false);
                            m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%4+1].y*scale,
                                (this.StrelkaP[HaveToBeInvalidate%4+1].x-23)*scale + who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%4+1].y*scale + who.GetHeight()),false);
                            m_pwin->InvalidateRect(CRect((this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale,this.StrelkaP[HaveToBeInvalidate%100].y*scale,
                                (this.StrelkaP[HaveToBeInvalidate%100].x-23)*scale + who.GetWidth(),this.StrelkaP[HaveToBeInvalidate%100].y*scale+who.GetHeight()),false);
                            goto end;
                        }
                    }	
                }
            }
        }
    }
end:;
}
void COKEY::InitRezDlg(int Player,int Pool,int Way)
{
    this.Faza=2;
    m_Way=Way;
    this.FPlayer=Player;
    this.FinnishBlock=Pool;
    m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);
    CINFO m_info=new CINFO();
    online_timer=online_timer_time*4;
    open_orez_dlg=true;
    if(this.OP&&!watch) {
        if(this.Points[0]>=19&&this.Points[1]>=19&&this.Points[2]>=19&&this.Points[3]>=19) {
            if(!op_start) {
                SendGame(L"L");
                op_start=true;
            }
        }
    }
    CString FName[5];
    for (int i = 1; i <= this.Players; i++) {
        FName[i] = Name[i];
    }
    if(Player==1 && !watch)
    {
        if(Way==1)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=2;
                m_info.SetDescription(GetLanguageText(L"You finnished the game normally \n Points of other players are reduced by 2"));	
                if(m_info.DoModal()==IDOK)
                {
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=4;
                m_info.SetDescription(GetLanguageText(L"You finnished the game normally with OKAY \n Points of other players are reduced by 4"));
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
        if(Way==2)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=8;
                m_info.SetDescription(GetLanguageText(L"You finnished the game with doubles \n Points of other players are reduced by 8"));
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=12;
                m_info.SetDescription(GetLanguageText(L"You finnished the game with doubles with OKAY \n Points of other players are reduced by 12"));
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
        if(Way==3)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=16;
                m_info.SetDescription(GetLanguageText(L"You finnished the game with arranged \n Points of other players are reduced by 16"));
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||(Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=1;i<4;i++)this.Points[i]-=20;
                m_info.SetDescription(GetLanguageText(L"You finnished the game with arranged with OKAY \n Points of other players are reduced by 20"));
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
    }
    if(Player!=1 || watch)
    {
        if(Way==1)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=2;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game normally \n Your points are reduced by 2");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=4;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game normally with OKAY \n Your points reduced by 4");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
        if(Way==2)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=8;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game with doubles \n Your points reduced by 8");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=12;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game with doubles with OKAY \n Your points are reduced by 12");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
        if(Way==3)
        {
            if(!((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=16;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game with arranged \n Your points are reduced by 16");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
            if(((Boards[Player][Pool/100][Pool%100]%1000==OKEY+1 && OKEY%100!=13) ||
                (Boards[Player][Pool/100][Pool%100]%1000==1+((OKEY/100)*100) && OKEY%100==13)))
            {
                for(int i=0;i<4;i++)
                {
                    if(i!=Player-1)
                    {
                        this.Points[i]-=20;
                    }
                }
                CString title=GetLanguageText(L"(username) finnished the game with arranged with OKAY \n Your points are reduced by 20");
                title.Replace(L"(username)",FName[Player]);
                m_info.SetDescription(title);
            	
                if(m_info.DoModal()==IDOK)
                {
                //	this.Faza=3;
                }
            }
        }
    }
    if(Way==4)
    {
        for(int i=0;i<4;i++)this.Points[i]-=1;
        CINFO m_info=new CINFO();
        m_info.SetDescription(GetLanguageText(L"The Tiles Finished! \n Your Points are reduced by 1."));
        if(m_info.DoModal()==IDOK)
        {
        //	this.Faza=3;
        	
        }
    }
    InvalidatePoints();
    if(this.OP&&!watch) {
        CString rezult=L"X|"+IntToString(this.Points[0])+L" : "+IntToString(this.Points[1])+L" : "+IntToString(this.Points[2])+L" : "+IntToString(this.Points[3]);
        SendGame(rezult);
    }
    if(this.Points[0]>0&&this.Points[1]>0&&this.Points[2]>0&&this.Points[3]>0) {
        for(int a=0;a<50;a++)
        {
            if(this.rez[1][a]==-100)
            {
                for(int i=1;i<5;i++) {
                    this.rez[i][a]=this.Points[i-1];
                }
                goto next;
            }
        }
next:;
        if(this.SP)InitGame();//??? Da se proveri dali ne e izlishno i da se mahne 
    }
    else {
        if(!watch) {
            CWinApp* pApp = AfxGetApp();
            CString okey_rezult=pApp->GetProfileString(L"Options", L"OKEY",L"");
            CString crezult=L"";
            CTime time=CTime::GetCurrentTime();
            CString ctime=L"<";
            CString day=time.GetDay() < 10 ? L"0" + IntToString(time.GetDay()) : IntToString(time.GetDay());
            CString month=time.GetMonth() < 10 ? L"0" + IntToString(time.GetMonth()) : IntToString(time.GetMonth());
            CString year=IntToString(time.GetYear());
            CString hour=time.GetHour()<10 ? L"0" + IntToString(time.GetHour()) : IntToString(time.GetHour());
            CString minute=time.GetMinute()<10 ? L"0" + IntToString(time.GetMinute()) : IntToString(time.GetMinute());
            ctime=ctime+day+L"."+month+L"."+year+L" "+hour+L":"+minute+L">";
        	
            crezult=ctime+L" "+ FName[1] +L", "+ FName[2] +L", "+ FName[3] +L", "+ FName[4] +L" ";
            crezult=crezult+GetLanguageText(L"scoring")+L" - "+IntToString(this.Points[0])+L" : "+IntToString(this.Points[1])+L" : "+IntToString(this.Points[2])+L" : "+IntToString(this.Points[3]);
            if(GetPlayerPosition(1)==1) {
                crezult=crezult+L"w;";
            }
            else {
                crezult=crezult+L"l;";
            }
            pApp->WriteProfileString(L"Options", L"OKEY", okey_rezult+crezult);
            if(this.OP) {
                CString rezult=L"$|n|"+IntToString(GetPlayerPosition(1))+L"|"+IntToString(this.Points[0])+L" : "+IntToString(this.Points[1])+L" : "+IntToString(this.Points[2])+L" : "+IntToString(this.Points[3]);
                SendGame(rezult);
            }
        }
        COK m_ok=new COK();
        m_ok.SetTitle(GetWinners(FName));
        m_ok.DoModal();
        if(watch) 
        {
            if(this.Points[0]<=0||this.Points[1]<=0||this.Points[2]<=0||this.Points[3]<=0) {
                for(int i=0;i<4;i++)
                {
                    this.Points[i]=20;
                }
            }
        }
        if(this.OP&&!watch) {
            CString rezult=L"X|20 : 20 : 20 : 20";
            SendGame(rezult);
        }
        if(!watch) {
            CYESNO m_yesno=new CYESNO();
            m_yesno.SetTitle(GetLanguageText(L"New Game"));
            if(m_yesno.DoModal()==IDOK) {
                InitNewGame();
            }
            else {
                if(this.OP) {
                    m_pwin->GoToLobby();
                }
                else {
                    m_pwin->OnNewGame();
                }
                goto end;
            }
        }
    }
    if(!watch)this.Faza=3;
    if(this.OP&&!watch) {
        if(!this.Server) {
            SendGame(L"R");
        }
    }
    m_pwin->InvalidateRectScale(CRect(0,0,800,600),false);
end:;
}

CString COKEY::GetWinners(CString FName[5])
{
    CString winners=L"";
    for(int i=1;i<=4;i++) {
        if(GetPlayerPosition(i)==1) {
            if(winners==L"") {
                winners=GetLanguageText(L"The game profit2")+L" "+ FName[i];
            }
            else {
                winners.Replace(GetLanguageText(L"The game profit2"),GetLanguageText(L"The game profit"));
                winners=winners+L" "+GetLanguageText(L"and")+L" " + FName[i];
            }
        }
    }
    return winners;
}

int COKEY::GetPlayerPosition(int player)
{
    int pos=1;
    if(this.Points[player-1]<this.Points[0]&&player!=1)pos=pos+1;
    if(this.Points[player-1]<this.Points[1]&&player!=2)pos=pos+1;
    if(this.Points[player-1]<this.Points[2]&&player!=3)pos=pos+1;
    if(this.Points[player-1]<this.Points[3]&&player!=4)pos=pos+1;
    return pos;
}
    */

    async LoadBitmaps() { }
    OnLButtonDblClk(point: CPoint) {
        console.log(point);
    }



    SendCards() { }
    SendKoiENaRed() { }
    SendNames() { }

    InitGame() { }
    async OnLButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async OnRButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async ReceiveGame(strRecvd: string) {
        console.log(strRecvd);
        return true;
    }
    Timer7() { }
    Timer3() { }
    Timer2() { }
    Timer4() { }

    Timer1() { }

    DrawView() {
        return null;
    }

    render() {
        if (this.props.m_pwin.game === 'OKEY') {
            return <View key={'OKEY'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default COKEY;
