class GlobalVariables {
    static scale: number = 1;
    static WindowWidth: number = 819;
    static WindowHeight: number = 525;
    static scaleWidth: number = 1;
    static scaleHeight: number = 1;
    static positionTop: number = 0;
    static positionLeft: number = 56;
    static folder: string = 'files_1.0';
    static diceWidth: number = 48;
    static diceHeight: number = 48;
    static pionkaWidth: number = 37;
    static pionkaHeight: number = 40;
    static pionkaMoveLeft: number = 0;
    static pionkaMoveTop: number = -9;
    static Zvuk: number = 1;
    static automatic_td: boolean = true;
    static automatic_release: boolean = false;
    static show_rating: boolean = true;
    static show_pictures: boolean = true;
    static player_pools_color: 'Black' | 'White';
    static OtkritiKarti: number = 0;
    static toPercent(size: number): string {
        return size + '%';
    }
}

export default GlobalVariables;
