import React, { Component } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import GlobalVariables from '../../Components/GlobalVariables';
import { RadioButton } from 'react-native-paper';
import { BiddingOptionProps } from '~/Components/props/BiddingOptionProps';

export default class BiddingOption extends Component<BiddingOptionProps> {
    constructor(props: BiddingOptionProps) {
        super(props);
    }

    render() {
        return (
            <Pressable
                onPress={() => this.props.parent.onPressOption(this.props.level, this.props.naddavane)}
                disabled={this.props.isDisableRadio}
                style={[
                    cstyles.cell,
                    cstyles.option,
                    this.props.isDisableRadio ? cstyles.disabled : cstyles.enabled,
                    {
                        width: 40 * GlobalVariables.scale,
                        height: 40 * GlobalVariables.scale,
                        marginHorizontal: 15 * GlobalVariables.scale,
                        marginVertical: 2 * GlobalVariables.scale,
                        borderRadius: 20 * GlobalVariables.scale,
                    },
                ]}
            >
                <RadioButton
                    value={this.props.level.toString()}
                    onPress={() => this.props.parent.onPressOption(this.props.level, this.props.naddavane)}
                    disabled={this.props.isDisableRadio}
                />
            </Pressable>
        );
    }
}

const cstyles = StyleSheet.create({
    cell: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2 * GlobalVariables.scale,
    },
    disabled: { borderColor: 'gray' },
    enabled: { borderColor: 'green' },
    option: {
        borderWidth: 2,
    },
});
