import { StyleSheet } from 'react-native';
import GlobalVariables from '../src/Components/GlobalVariables';
import { appBackground } from './container';

export const dialog = StyleSheet.create({
    dialogView: {
        backgroundColor: appBackground,
        borderRadius: 10 * GlobalVariables.scale,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2 * GlobalVariables.scale,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4 * GlobalVariables.scale,
        elevation: 5,
    },
    dialogBody: {
        flex: 1,
        backgroundColor: appBackground,
        position: 'absolute',
        justifyContent: 'center',
    },
    fullScreen: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});
