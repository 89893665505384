const GlobalConstants = {
    IDOK: 'IDOK',
    IDNO: 'IDNO',
    IDCANCEL: 'IDCANCEL',
    WEBSITE: 'https://favorite-games.com/',
    WS_SERVER: 's1.favorite-games.com:443', //WS_SERVER: 'localhost:443',
    //WS_SERVER: 'localhost:443', //WS_SERVER: 'localhost:443',
    IMAGES_FOLDER: 'https://web.favorite-games.com/fg_react_native/assets',
    SOUND_FOLDER: 'https://web.favorite-games.com/fg_react_native/assets/Sound/',
    CURSOR_HAND: 'pointer',
    CURSOR_ARROW: 'default',
    CURSOR_WATCH: 'not-allowed',
    CURSOR_CLOSE_HAND: 'grabbing',
    CURSOR_NOT_ALLOWED: 'no-drop',
    CURSOR_WAIT: 'wait',
    EXTRA_SMALL_FONT_SIZE: 10,
    SMALL_FONT_SIZE: 12,
    MEDIUM_SMALL_FONT_SIZE: 14,
    MEDIUM_FONT_SIZE: 16,
    MEDIUM_FONT_SIZEXL: 17,
    MEDIUM_FONT_SIZEXXL: 18,
    MEDIUM_FONT_SIZEXXXL: 19,
    LARGE_FONT_SIZE: 20,
    DEFAULT_FONT_SIZE: 22,
    EXTRA_LARGE_FONT_SIZE: 24,
    SUPER_EXTRA_LARGE_FONT_SIZE: 28,
    SHOW_ROOMS_MIN_HEIGHT: 380,
    SHOW_ADD_FRIENDS_TEXT_MIN_WIDTH: 800,
    SHOW_TOURNAMENT_NAME_MIN_WIDTH: 800,
    GAME_VERSION: '5.43.69',
    ONLINE_VERSION: '497',
    GAME_WIDTH: 779,
    GAME_HEIGHT: 512,
    CARD_WIDTH: 57,
    CARD_HEIGHT: 80,
    PLAYER_CARD_WIDTH: 91.2,
    PLAYER_CARD_HEIGHT: 128,
    ON_COLOR: '#225522',
    CHAT_BAR_HEIGHT: 104,
};

export default GlobalConstants;
