import { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import GlobalVariables from '../GlobalVariables';
import { DrawTextProps } from '../props/DrawTextProps';
import CPicture from '../cppComponents/CPicture';

class DrawAnons extends Component<DrawTextProps> {
    constructor(props: DrawTextProps) {
        super(props);
    }

    render() {
        if (this.props.text !== '' && this.props.position !== undefined && this.props.position.x > 0 && this.props.position.y > 0) {
            let fontSize = 12;
            return (
                <View
                    style={[
                        styles.container,
                        {
                            left: this.props.position.x * GlobalVariables.scale,
                            top: this.props.position.y * GlobalVariables.scale,
                        },
                    ]}
                >
                    <CPicture relative={true} image={'announcement/left.png'} m_x={0} m_y={0} m_Height={20} m_Width={22} />
                    <View style={[styles.textBackground, { height: 20 * GlobalVariables.scale }]}>
                        <Text
                            style={[
                                styles.text,
                                {
                                    fontSize: GlobalVariables.scale * fontSize,
                                },
                            ]}
                        >
                            {this.props.text}
                        </Text>
                    </View>
                    <CPicture relative={true} image={'announcement/right.png'} m_x={0} m_y={0} m_Height={20} m_Width={12} />
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DrawAnons;

export const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        flexDirection: 'row',
    },
    textBackground: {
        backgroundColor: 'rgb(255, 255, 255)',
        borderTopColor: 'rgb(97, 97, 97)',
        borderBottomColor: 'rgb(37, 37, 37)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginRight: -1,
        marginLeft: -1,
        justifyContent: 'center',
    },
    text: {
        fontWeight: 'bold',
        paddingHorizontal: 1,
    },
});
