import { TippyProps } from '@tippyjs/react';
import React from 'react';
import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import { View } from 'react-native';

const Tooltip = (props: TippyProps) => {
    return (
        <Tippy content={props.content} delay={1000}>
            <View>{props.children ? props.children : null}</View>
        </Tippy>
    );
};

export default Tooltip;
