class Banner {
    m_x: number;
    m_y: number;
    m_url: string;
    m_language: string;
    m_filename: string;
    m_Width: number;
    m_Height: number;

    constructor() {}
}

export default Banner;
