import React, { Component } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';
import DlgDetails from './DlgDetails';
import t from '../../translation';
import Button from '../Components/core/Button';
import { modal } from '../../css/modal';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';

class DlgRezultatB3P extends Component<ParentDlgProps> {
    m_bel1: string;
    m_bel2: string;
    m_crez1: number;
    m_crez2: number;
    m_crez3: number;
    m_name1: string;
    m_name2: string;
    m_rez1: number;
    m_rez2: number;
    m_rez3: number;
    m_ter1pl1: string;
    m_ter1pl2: string;
    m_ter2pl1: string;
    m_ter2pl2: string;
    m_info: string;

    dis = [];
    vz: number[] = [];
    kd: number[] = [];
    dlg_det_start: boolean;
    dlg_det: DlgDetails = new DlgDetails(null);
    start: number;

    constructor(props: ParentDlgProps) {
        super(props);
        for (let i = 1; i < 5; i++) {
            this.dis[i] = [];
            this.dis[i][0] = true;
            this.dis[i][1] = true;
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    async DoModal() {
        this.props.m_pwin.state.DlgRezultatVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgRezultatVisible) {
            await this.performTimeConsumingTask();
        }
        return GlobalConstants.IDOK;
    }

    OnOK() {
        this.start = 0;
        this.props.m_pwin.setState({ DlgRezultatVisible: false });
    }

    render() {
        return null;
        /*    if (this.props.m_pwin.state.DlgRezultatVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 20 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 0 * GlobalVariables.scale,
                                width: 790 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_name1}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_bel1}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[1][0] === false} noTranslate={true}>
                                    {this.m_ter1pl1}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[1][1] === false} noTranslate={true}>
                                    {this.m_ter2pl1}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{t.t('Total')} </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_name3}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_bel3}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[3][0] === false} noTranslate={true}>
                                    {this.m_ter1pl3}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[3][1] === false} noTranslate={true}>
                                    {this.m_ter2pl3}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_rez13.toString()}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_name2}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_bel2}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[2][0] === false} noTranslate={true}>
                                    {this.m_ter1pl2}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[2][1] === false} noTranslate={true}>
                                    {this.m_ter2pl2}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{t.t('Total')} </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_name4}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_bel4}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[4][0] === false} noTranslate={true}>
                                    {this.m_ter1pl4}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} disabled={this.dis[4][1] === false} noTranslate={true}>
                                    {this.m_ter2pl4}
                                </Text>
                            </View>
                            <View style={cstyle.dialogCell}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} noTranslate={true}>
                                    {this.m_rez24.toString()}
                                </Text>
                            </View>
                        </View>
                        <View style={cstyle.rezult}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>
                                {t.t('Scoring')} {this.m_crez13.toString()} - {this.m_crez24.toString()} {this.m_info}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle3PerRow} onPress={this.OnOK.bind(this)} text="OK" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }*/
    }
}

export default DlgRezultatB3P;

const cstyle = StyleSheet.create({
    dialogCell: {
        width: '18%',
        padding: '1%',
        margin: '1%',
        borderColor: '#000000',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    rezult: {
        width: '100%',
        padding: '1%',
    },
});
