import React, { Component } from 'react';
import { View, Pressable, StyleSheet, Dimensions } from 'react-native';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import Text from '../../Components/core/Text';
import { RadioButton } from 'react-native-paper';
import GlobalConstants from '~/Components/GlobalConstants';
import { styles } from '../../../css/container';

class IgraBlatoDlg extends Component<BaseDlgProps> {
    Game: number = 1;
    m_type: number = 0;
    Type: number = 15;

    constructor(props) {
        super(props);
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraBlatoDlgVisible: true });
    }

    OnSp() {
        this.Game = 1;
        this.props.m_pwin.CloseIgraBlato();
    }

    async OnOp() {
        this.Game = 3;
        await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Sergeant Major (3-5-8)');
        this.props.m_pwin.CloseIgraBlato();
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadFinished();
    }

    async changeGameType(type: number) {
        this.m_type = type;
        if (this.m_type === 0) {
            this.Type = 15;
        }
        if (this.m_type === 1) {
            this.Type = 9;
        }
        if (this.m_type === 2) {
            this.Type = 21;
        }
        await this.props.m_pwin.m_main.WriteProfileInt('Options', 'blato_type', this.Type);

        this.setState({});
    }

    async loadFinished() {
        let save_type: number = await this.props.m_pwin.m_main.GetProfileInt('Options', 'blato_type', 15);
        if (save_type === 9) {
            this.m_type = 1;
        } else if (save_type === 21) {
            this.m_type = 2;
        } else {
            this.m_type = 0;
        }
        if (this.m_type === 0) {
            this.Type = 15;
        }
        if (this.m_type === 1) {
            this.Type = 9;
        }
        if (this.m_type === 2) {
            this.Type = 21;
        }
        this.setState({});
    }

    OnIzhod() {
        this.props.m_pwin.setState({ IgraBlatoDlgVisible: false });
        this.props.m_pwin.OnNewGame();
    }

    render() {
        if (this.props.m_pwin.state.IgraBlatoDlgVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View style={cstyles.optionsContainer}>
                                <View style={cstyles.centeredOptionsContainer}>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(0)}>
                                        <RadioButton
                                            value="0"
                                            status={this.m_type === 0 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(0)}
                                        />
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>15 </Text>
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Round</Text>
                                    </Pressable>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(1)}>
                                        <RadioButton
                                            value="1"
                                            status={this.m_type === 1 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(1)}
                                        />
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>9 </Text>
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Round</Text>
                                    </Pressable>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(2)}>
                                        <RadioButton
                                            value="2"
                                            status={this.m_type === 2 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(2)}
                                        />
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>To 21 Points</Text>
                                    </Pressable>
                                </View>
                            </View>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                {this.props.m_pwin.isEnableOnlinePlayer() ? (
                                    <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                ) : null}
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnIzhod.bind(this)} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraBlatoDlg;

const cstyles = StyleSheet.create({
    optionsContainer: { justifyContent: 'center', alignItems: 'center', width: '100%' },
    centeredOptionsContainer: { justifyContent: 'flex-start' },
    option: { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' },
});
