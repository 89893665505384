import { StyleSheet, Dimensions } from 'react-native';
import GlobalVariables from '../src/Components/GlobalVariables';

export const appBackground = '#306699';

export const styles = StyleSheet.create({
    app: {
        flex: 1,
    },
    h100: { height: '100%' },
    W100: { width: '100%' },
    cpictureResizeMode: { resizeMode: 'stretch' },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
    },
    width50Percent: { width: '50%' },
    boldFont: { fontWeight: 'bold' },
    center: { justifyContent: 'center', alignItems: 'center' },
    padding1: {
        padding: '1%',
    },
    languagePickerStyle: {
        position: 'absolute',
        top: 20,
        left: '3%',
    },
    languagePickerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: '4%',
    },
    doalogTitle: {
        backgroundColor: '#205689',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        borderTopEndRadius: 10 * GlobalVariables.scale,
        borderTopLeftRadius: 10 * GlobalVariables.scale,
        marginBottom: 2 * GlobalVariables.scale,
    },
    appBackground: {
        backgroundColor: appBackground,
    },
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    containerAbsolute: {
        position: 'absolute',
    },
    containerRelative: {
        position: 'relative',
    },
    view: {
        width: '100%',
        height: '100%',
    },
    navigationHeaderSafeAreaView: {
        flex: 1,
        height: '100%',
        width: GlobalVariables.positionLeft,
        backgroundColor: appBackground,
        position: 'absolute',
    },
    navigationHeaderBackgroundColor: {
        backgroundColor: appBackground,
    },
    navigationHeaderImage: {
        width: GlobalVariables.positionLeft * 0.8,
        height: GlobalVariables.positionLeft * 0.8,
        margin: GlobalVariables.positionLeft * 0.1,
    },
    websiteIcon: {
        width: 48,
        height: 48,
        margin: 4,
    },
    logoStyles: {
        width: '50%',
        height: 100 * GlobalVariables.scale,
        resizeMode: 'contain',
        margin: 30 * GlobalVariables.scale,
    },
    logoContainer: {
        alignItems: 'center',
    },
    game: {
        width: Dimensions.get('window').width - GlobalVariables.positionLeft,
        height: Dimensions.get('window').height,
        position: 'absolute',
        top: GlobalVariables.positionTop,
        left: GlobalVariables.positionLeft,
    },
    modal: {
        position: 'absolute',
        top: 0,
        left: -GlobalVariables.positionLeft,
    },
    modalfdialog: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
});
