import DlgNaddavane from '../../../Dialogs/DlgNaddavane';
import DlgORezultat from '../../../Dialogs/DlgORezultat';
import CMain from '../../CMain';
import CPoint from '../../cppComponents/CPoint';
import React from 'react';
import DrawCard from '../../Draw/DrawCard';
import CPicture from '../../cppComponents/CPicture';
import CAnnouncement from '../../../Dialogs/CAnnouncement';
import GlobalVariables from '../../GlobalVariables';
import { GamesProps } from '../../props/GamesProps';
import GlobalConstants from '~/Components/GlobalConstants';
import CRect from '~/Components/cppComponents/CRect';

class CBelot extends CMain {
    m_orez: DlgORezultat = new DlgORezultat(null);
    m_n: DlgNaddavane = new DlgNaddavane(null);
    PTerci = [];
    PosBelot = [];
    PosTerci = [];
    Visiashti: number;
    BrPas: number; //broiach za pasovete
    KoiObiaviava: number; //Koj e na red za obiaviavane
    BrBoiaNach = [];
    op_belot = [];
    terci = [];
    Piska = [];
    Pneiska = [];
    Pniama = [];
    Belot = [];
    m_ann: CAnnouncement = new CAnnouncement(null);

    constructor(props: GamesProps) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        for (let i = 1; i < 5; i++) {
            this.Belot[i] = [];
            this.PosBelot[i] = new CPoint(0, 0);
            this.PosTerci[i] = [];
            this.terci[i] = [];
            this.PTerci[i] = [];
            this.Piska[i] = [];
            this.Pneiska[i] = [];
            this.Pniama[i] = [];
            this.BrBoiaNach[i] = [];
            for (let a = 0; a < 2; a++) {
                this.PosTerci[i][a] = new CPoint(0, 0);
            }
        }

        this.P2OP = false;
        this.P3OP = false;
        this.P4OP = false;
        this.Razdavane1 = false;
        this.Razdavane2 = false;
        this.Server = false;
        this.Faza = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.Naddavane = 0;
        this.hand = 0;
        this.KoiENaRed = 0;
        this.KoiPuskaParvi = 0;
        this.KoiZapochva = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        this.KoiObiaviava = 0;
        this.BrPas = 0;
        this.Mcard = 0;
        this.Kontra = 0;
        this.Rekontra = 0;
        this.Move = false;
        for (let i = 0; i < 33; i++) {
            this.k[i] = 0;
        }
        this.pause_rect = new CRect(676, 478, 700, 498);
    }

    CheckIzbivane(player: number, partner: number) {
        let h: number;
        let h1: number;
        if (this.Players === 4) {
            if (this.Naddavane === 6) {
                if (
                    this.CheckCard3(17, player, true) === true &&
                    this.GetBrojKarti(1, player) > 2 &&
                    this.CheckCard(18) === true &&
                    this.CheckCard3(18, player, true) === false &&
                    this.Pneiska[partner][1] === false
                ) {
                    this.Piska[player][1] = true;
                    if (this.CheckCard3(13, player, true) === true && this.CheckCard3(14, player, true) === true) {
                        return this.PuskCard(13, player);
                    }
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (
                    this.CheckCard3(27, player, true) === true &&
                    this.GetBrojKarti(2, player) > 2 &&
                    this.CheckCard(28) === true &&
                    this.CheckCard3(28, player, true) === false &&
                    this.Pneiska[partner][2] === false
                ) {
                    this.Piska[player][2] = true;
                    if (this.CheckCard3(23, player, true) === true && this.CheckCard3(24, player, true) === true) {
                        return this.PuskCard(23, player);
                    }
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (
                    this.CheckCard3(37, player, true) === true &&
                    this.GetBrojKarti(3, player) > 2 &&
                    this.CheckCard(38) === true &&
                    this.CheckCard3(38, player, true) === false &&
                    this.Pneiska[partner][3] === false
                ) {
                    this.Piska[player][3] = true;
                    if (this.CheckCard3(33, player, true) === true && this.CheckCard3(34, player, true) === true) {
                        return this.PuskCard(33, player);
                    }
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (
                    this.CheckCard3(47, player, true) === true &&
                    this.GetBrojKarti(4, player) > 2 &&
                    this.CheckCard(48) === true &&
                    this.CheckCard3(48, player, true) === false &&
                    this.Pneiska[partner][4] === false
                ) {
                    this.Piska[player][4] = true;
                    if (this.CheckCard3(43, player, true) === true && this.CheckCard3(44, player, true) === true) {
                        return this.PuskCard(43, player);
                    }
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 17 && this.CheckCard(18) === true && this.CheckCard3(18, player, true) === false) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 10 && this.k[h1] < 17 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][1] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 27 && this.CheckCard(28) === true && this.CheckCard3(28, player, true) === false) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 20 && this.k[h1] < 27 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][2] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 37 && this.CheckCard(38) === true && this.CheckCard3(38, player, true) === false) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 30 && this.k[h1] < 37 && this.Pneiska[partner][3] === false) {
                                this.Piska[player][3] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 47 && this.CheckCard(48) === true && this.CheckCard3(48, player, true) === false) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 40 && this.k[h1] < 47 && this.Pneiska[partner][4] === false) {
                                this.Piska[player][4] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 16 &&
                        (this.CheckCard(18) === true || this.CheckCard(17) === true) &&
                        this.CheckCard3(18, player, true) === false &&
                        this.CheckCard3(17, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 10 && this.k[h1] < 16 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][1] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 26 &&
                        (this.CheckCard(28) === true || this.CheckCard(27) === true) &&
                        this.CheckCard3(28, player, true) === false &&
                        this.CheckCard3(27, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 20 && this.k[h1] < 26 && this.Pneiska[partner][2] === false) {
                                this.Piska[player][2] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 36 &&
                        (this.CheckCard(38) === true || this.CheckCard(37) === true) &&
                        this.CheckCard3(38, player, true) === false &&
                        this.CheckCard3(37, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 30 && this.k[h1] < 36 && this.Pneiska[partner][3] === false) {
                                this.Piska[player][3] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 46 &&
                        (this.CheckCard(48) === true || this.CheckCard(47) === true) &&
                        this.CheckCard3(48, player, true) === false &&
                        this.CheckCard3(47, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 40 && this.k[h1] < 46 && this.Pneiska[partner][4] === false) {
                                this.Piska[player][4] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 18 &&
                        this.GetBrojKarti(1, 0) > 6 &&
                        this.GetBrojKarti(1, player) > 2 &&
                        this.GetBrojKarti(1, player) < 5 &&
                        this.CheckCard3(17, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 10 && this.k[h1] < 16 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][1] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 28 &&
                        this.GetBrojKarti(2, 0) > 6 &&
                        this.GetBrojKarti(2, player) > 2 &&
                        this.GetBrojKarti(2, player) < 5 &&
                        this.CheckCard3(27, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 20 && this.k[h1] < 26 && this.Pneiska[partner][2] === false) {
                                this.Piska[player][2] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 38 &&
                        this.GetBrojKarti(3, 0) > 6 &&
                        this.GetBrojKarti(3, player) > 2 &&
                        this.GetBrojKarti(3, player) < 5 &&
                        this.CheckCard3(37, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 30 && this.k[h1] < 36 && this.Pneiska[partner][3] === false) {
                                this.Piska[player][3] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 48 &&
                        this.GetBrojKarti(4, 0) > 6 &&
                        this.GetBrojKarti(4, player) > 2 &&
                        this.GetBrojKarti(4, player) < 5 &&
                        this.CheckCard3(47, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 40 && this.k[h1] < 46 && this.Pneiska[partner][4] === false) {
                                this.Piska[player][4] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 18 &&
                        this.GetBrojKarti(1, 0) > 6 &&
                        this.GetBrojKarti(1, player) === 2 &&
                        this.CheckCard3(17, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 10 && this.k[h1] < 16 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][1] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 28 &&
                        this.GetBrojKarti(2, 0) > 6 &&
                        this.GetBrojKarti(2, player) === 2 &&
                        this.CheckCard3(27, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 20 && this.k[h1] < 26 && this.Pneiska[partner][2] === false) {
                                this.Piska[player][2] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 38 &&
                        this.GetBrojKarti(3, 0) > 6 &&
                        this.GetBrojKarti(3, player) === 2 &&
                        this.CheckCard3(37, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 30 && this.k[h1] < 36 && this.Pneiska[partner][3] === false) {
                                this.Piska[player][3] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 48 &&
                        this.GetBrojKarti(4, 0) > 6 &&
                        this.GetBrojKarti(4, player) === 2 &&
                        this.CheckCard3(47, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 40 && this.k[h1] < 46 && this.Pneiska[partner][4] === false) {
                                this.Piska[player][4] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 15 &&
                        (this.CheckCard(18) === true || this.CheckCard(17) === true || this.CheckCard(16) === true) &&
                        this.CheckCard3(18, player, true) === false &&
                        this.CheckCard3(17, player, true) === false &&
                        this.CheckCard3(16, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 10 && this.k[h1] < 15 && this.Pneiska[partner][1] === false) {
                                this.Piska[player][1] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 25 &&
                        (this.CheckCard(28) === true || this.CheckCard(27) === true || this.CheckCard(26) === true) &&
                        this.CheckCard3(28, player, true) === false &&
                        this.CheckCard3(27, player, true) === false &&
                        this.CheckCard3(26, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 20 && this.k[h1] < 25 && this.Pneiska[partner][2] === false) {
                                this.Piska[player][2] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 35 &&
                        (this.CheckCard(38) === true || this.CheckCard(37) === true || this.CheckCard(36) === true) &&
                        this.CheckCard3(38, player, true) === false &&
                        this.CheckCard3(37, player, true) === false &&
                        this.CheckCard3(36, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 30 && this.k[h1] < 35 && this.Pneiska[partner][3] === false) {
                                this.Piska[player][3] = true;
                                return h1;
                            }
                        }
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 45 &&
                        (this.CheckCard(48) === true || this.CheckCard(47) === true || this.CheckCard(46) === true) &&
                        this.CheckCard3(48, player, true) === false &&
                        this.CheckCard3(47, player, true) === false &&
                        this.CheckCard3(46, player, true) === false
                    ) {
                        for (h1 = 1 + (player - 1) * 8; h1 < 9 - this.hand + (player - 1) * 8; h1++) {
                            if (this.k[h1] > 40 && this.k[h1] < 45 && this.Pneiska[partner][4] === false) {
                                this.Piska[player][4] = true;
                                return h1;
                            }
                        }
                    }
                }
            }
        }
        return 0;
    }

    PointTerci(terca: number) {
        if (terca > 0 && terca < 7) {
            return 20;
        }
        if (terca > 10 && terca < 16) {
            return 50;
        }
        if (terca > 20 && terca < 35) {
            return 100;
        }
        if (terca === 35) {
            return 150;
        }
        if (terca === 36) {
            return 200;
        }
        return 0;
    }

    GetBrojKarti(boia: number, player: number) {
        let i: number;
        let br: number = 0;
        ///za da tarsi vav vsichkite karti player za Belot4players i Belot3players triabva da e 0
        if (boia === 1) {
            if (player === 0) {
                if (this.Naddavane === 1) {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 1) {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 2) {
            if (player === 0) {
                if (this.Naddavane === 2) {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 2) {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 3) {
            if (player === 0) {
                if (this.Naddavane === 3) {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 3) {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        if (boia === 4) {
            if (player === 0) {
                if (this.Naddavane === 4) {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1; i < 33; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) {
                            br = br + 1;
                        }
                    }
                }
            } else {
                if (this.Naddavane === 4) {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 100) {
                            br = br + 1;
                        }
                    }
                } else {
                    for (i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) {
                            br = br + 1;
                        }
                    }
                }
            }
        }
        return br;
    }

    Cakane(player: number) {
        let br = this.GetBrojKarti(this.Naddavane, player);
        if (br === 1) {
            return this.CheckNGCard(player, this.Naddavane);
        }
        if (br === 2) {
            if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === false) {
                return this.CheckNGCard(player, this.Naddavane);
            }
        }
        if (br > 2) {
            if (this.CheckCard3(108, player, true) === false) {
                if (this.CheckCard3(106, player, true) === true && this.CheckCard3(107, player, true) === true) {
                    return 107;
                }
                if (this.CheckCard3(105, player, true) === true && this.CheckCard(106) === false && this.CheckCard3(107, player, true) === true) {
                    return 107;
                }
                if (
                    this.CheckCard3(104, player, true) === true &&
                    this.CheckCard(105) === false &&
                    this.CheckCard(106) === false &&
                    this.CheckCard3(107, player, true) === true
                ) {
                    return 107;
                }
                if (
                    this.CheckCard3(103, player, true) === true &&
                    this.CheckCard(104) === false &&
                    this.CheckCard(105) === false &&
                    this.CheckCard(106) === false &&
                    this.CheckCard3(107, player, true) === true
                ) {
                    return 107;
                }
                if (
                    this.CheckCard3(102, player, true) === true &&
                    this.CheckCard(103) === false &&
                    this.CheckCard(104) === false &&
                    this.CheckCard(105) === false &&
                    this.CheckCard(106) === false &&
                    this.CheckCard3(107, player, true) === true
                ) {
                    return 107;
                }
            }
            if (this.CheckCard3(105, player, true) === true && this.CheckCard3(106, player, true) === true) {
                return 106;
            }
            if (this.CheckCard3(104, player, true) === true && this.CheckCard(105) === false && this.CheckCard3(106, player, true) === true) {
                return 106;
            }
            if (
                this.CheckCard3(103, player, true) === true &&
                this.CheckCard(104) === false &&
                this.CheckCard(105) === false &&
                this.CheckCard3(106, player, true) === true
            ) {
                return 106;
            }
            if (
                this.CheckCard3(102, player, true) === true &&
                this.CheckCard(103) === false &&
                this.CheckCard(104) === false &&
                this.CheckCard(105) === false &&
                this.CheckCard3(106, player, true) === true
            ) {
                return 106;
            }

            if (this.CheckCard3(104, player, true) === true && this.CheckCard3(105, player, true) === true) {
                return 105;
            }
            if (this.CheckCard3(103, player, true) === true && this.CheckCard(104) === false && this.CheckCard3(105, player, true) === true) {
                return 105;
            }
            if (
                this.CheckCard3(102, player, true) === true &&
                this.CheckCard(103) === false &&
                this.CheckCard(104) === false &&
                this.CheckCard3(105, player, true) === true
            ) {
                return 105;
            }

            if (this.CheckCard3(103, player, true) === true && this.CheckCard3(104, player, true) === true) {
                return 104;
            }
            if (this.CheckCard3(102, player, true) === true && this.CheckCard(103) === false && this.CheckCard3(104, player, true) === true) {
                return 104;
            }

            if (this.CheckCard3(102, player, true) === true && this.CheckCard3(104, player, true) === true) {
                return 104;
            }
        }
        return 0;
    }

    PuskDrBMaxCard(player: number) {
        let br: number[] = [];
        let brob: number[] = [];
        br[1] = this.GetBrojKarti(1, player);
        br[2] = this.GetBrojKarti(2, player);
        br[3] = this.GetBrojKarti(3, player);
        br[4] = this.GetBrojKarti(4, player);
        brob[1] = this.GetBrojKarti(1, 0);
        brob[2] = this.GetBrojKarti(2, 0);
        brob[3] = this.GetBrojKarti(3, 0);
        brob[4] = this.GetBrojKarti(4, 0);
        let partner: number[] = [];
        if (player === 1) {
            partner[2] = 2;
            partner[3] = 3;
            partner[4] = 4;
        }
        if (player === 2) {
            partner[2] = 3;
            partner[3] = 4;
            partner[4] = 1;
        }
        if (player === 3) {
            partner[2] = 4;
            partner[3] = 1;
            partner[4] = 2;
        }
        if (player === 4) {
            partner[2] = 1;
            partner[3] = 2;
            partner[4] = 3;
        }
        if (this.Players === 4) {
            if (this.Naddavane < 5) {
                if (br[1] === 1 && this.CheckCard3(17, player, true) === true && this.CheckCard(18) === true) {
                    return this.PuskCard(17, player);
                }
                if (br[2] === 1 && this.CheckCard3(27, player, true) === true && this.CheckCard(28) === true) {
                    return this.PuskCard(27, player);
                }
                if (br[3] === 1 && this.CheckCard3(37, player, true) === true && this.CheckCard(38) === true) {
                    return this.PuskCard(37, player);
                }
                if (br[4] === 1 && this.CheckCard3(47, player, true) === true && this.CheckCard(48) === true) {
                    return this.PuskCard(47, player);
                }
                if (this.Pkazal[partner[2]][this.Naddavane] === true || this.Pkazal[partner[4]][this.Naddavane] === true || this.hand > 5) {
                    if (this.CheckCard3(18, player, true) === true) {
                        if (this.CheckCard3(17, player, true) === true) {
                            return this.PuskCard(18, player);
                        }
                        if (this.CheckCard(17) === false && this.CheckCard3(16, player, true) === true) {
                            return this.PuskCard(18, player);
                        }
                        if (this.CheckCard(17) === false && this.CheckCard(16) === false && this.CheckCard3(15, player, true) === true) {
                            return this.PuskCard(18, player);
                        }
                        if (
                            this.CheckCard(17) === false &&
                            this.CheckCard(16) === false &&
                            this.CheckCard(15) === false &&
                            this.CheckCard3(14, player, true) === true
                        ) {
                            return this.PuskCard(18, player);
                        }
                        if (
                            this.CheckCard(17) === false &&
                            this.CheckCard(16) === false &&
                            this.CheckCard(15) === false &&
                            this.CheckCard(14) === false &&
                            this.CheckCard3(13, player, true) === true
                        ) {
                            return this.PuskCard(18, player);
                        }
                        if (
                            this.CheckCard(17) === false &&
                            this.CheckCard(16) === false &&
                            this.CheckCard(15) === false &&
                            this.CheckCard(14) === false &&
                            this.CheckCard(13) === false &&
                            this.CheckCard3(12, player, true) === true
                        ) {
                            return this.PuskCard(18, player);
                        }
                        if (
                            this.CheckCard(17) === false &&
                            this.CheckCard(16) === false &&
                            this.CheckCard(15) === false &&
                            this.CheckCard(14) === false &&
                            this.CheckCard(13) === false &&
                            this.CheckCard(12) === false &&
                            this.CheckCard3(11, player, true) === true
                        ) {
                            return this.PuskCard(18, player);
                        }
                    }
                    if (this.CheckCard3(28, player, true) === true) {
                        if (this.CheckCard3(27, player, true) === true) {
                            return this.PuskCard(28, player);
                        }
                        if (this.CheckCard(27) === false && this.CheckCard3(26, player, true) === true) {
                            return this.PuskCard(28, player);
                        }
                        if (this.CheckCard(27) === false && this.CheckCard(26) === false && this.CheckCard3(25, player, true) === true) {
                            return this.PuskCard(28, player);
                        }
                        if (
                            this.CheckCard(27) === false &&
                            this.CheckCard(26) === false &&
                            this.CheckCard(25) === false &&
                            this.CheckCard3(24, player, true) === true
                        ) {
                            return this.PuskCard(28, player);
                        }
                        if (
                            this.CheckCard(27) === false &&
                            this.CheckCard(26) === false &&
                            this.CheckCard(25) === false &&
                            this.CheckCard(24) === false &&
                            this.CheckCard3(23, player, true) === true
                        ) {
                            return this.PuskCard(28, player);
                        }
                        if (
                            this.CheckCard(27) === false &&
                            this.CheckCard(26) === false &&
                            this.CheckCard(25) === false &&
                            this.CheckCard(24) === false &&
                            this.CheckCard(23) === false &&
                            this.CheckCard3(22, player, true) === true
                        ) {
                            return this.PuskCard(28, player);
                        }
                        if (
                            this.CheckCard(27) === false &&
                            this.CheckCard(26) === false &&
                            this.CheckCard(25) === false &&
                            this.CheckCard(24) === false &&
                            this.CheckCard(23) === false &&
                            this.CheckCard(22) === false &&
                            this.CheckCard3(21, player, true) === true
                        ) {
                            return this.PuskCard(28, player);
                        }
                    }
                    if (this.CheckCard3(38, player, true) === true) {
                        if (this.CheckCard3(37, player, true) === true) {
                            return this.PuskCard(38, player);
                        }
                        if (this.CheckCard(37) === false && this.CheckCard3(36, player, true) === true) {
                            return this.PuskCard(38, player);
                        }
                        if (this.CheckCard(37) === false && this.CheckCard(36) === false && this.CheckCard3(35, player, true) === true) {
                            return this.PuskCard(28, player);
                        }
                        if (
                            this.CheckCard(37) === false &&
                            this.CheckCard(36) === false &&
                            this.CheckCard(35) === false &&
                            this.CheckCard3(34, player, true) === true
                        ) {
                            return this.PuskCard(38, player);
                        }
                        if (
                            this.CheckCard(37) === false &&
                            this.CheckCard(36) === false &&
                            this.CheckCard(35) === false &&
                            this.CheckCard(34) === false &&
                            this.CheckCard3(33, player, true) === true
                        ) {
                            return this.PuskCard(38, player);
                        }
                        if (
                            this.CheckCard(37) === false &&
                            this.CheckCard(36) === false &&
                            this.CheckCard(35) === false &&
                            this.CheckCard(34) === false &&
                            this.CheckCard(33) === false &&
                            this.CheckCard3(32, player, true) === true
                        ) {
                            return this.PuskCard(38, player);
                        }
                        if (
                            this.CheckCard(37) === false &&
                            this.CheckCard(36) === false &&
                            this.CheckCard(35) === false &&
                            this.CheckCard(34) === false &&
                            this.CheckCard(33) === false &&
                            this.CheckCard(32) === false &&
                            this.CheckCard3(31, player, true) === true
                        ) {
                            return this.PuskCard(38, player);
                        }
                    }
                    if (this.CheckCard3(48, player, true) === true) {
                        if (this.CheckCard3(47, player, true) === true) {
                            return this.PuskCard(48, player);
                        }
                        if (this.CheckCard(47) === false && this.CheckCard3(46, player, true) === true) {
                            return this.PuskCard(48, player);
                        }
                        if (this.CheckCard(47) === false && this.CheckCard(46) === false && this.CheckCard3(45, player, true) === true) {
                            return this.PuskCard(48, player);
                        }
                        if (
                            this.CheckCard(47) === false &&
                            this.CheckCard(46) === false &&
                            this.CheckCard(45) === false &&
                            this.CheckCard3(44, player, true) === true
                        ) {
                            return this.PuskCard(48, player);
                        }
                        if (
                            this.CheckCard(47) === false &&
                            this.CheckCard(46) === false &&
                            this.CheckCard(45) === false &&
                            this.CheckCard(44) === false &&
                            this.CheckCard3(43, player, true) === true
                        ) {
                            return this.PuskCard(48, player);
                        }
                        if (
                            this.CheckCard(47) === false &&
                            this.CheckCard(46) === false &&
                            this.CheckCard(45) === false &&
                            this.CheckCard(44) === false &&
                            this.CheckCard(43) === false &&
                            this.CheckCard3(42, player, true) === true
                        ) {
                            return this.PuskCard(48, player);
                        }
                        if (
                            this.CheckCard(47) === false &&
                            this.CheckCard(46) === false &&
                            this.CheckCard(45) === false &&
                            this.CheckCard(44) === false &&
                            this.CheckCard(43) === false &&
                            this.CheckCard(42) === false &&
                            this.CheckCard3(41, player, true) === true
                        ) {
                            return this.PuskCard(48, player);
                        }
                    }
                }
                if (br[1] === 1 && this.CheckCard3(16, player, true) === true && (this.CheckCard(18) === true || this.CheckCard(17) === true)) {
                    return this.PuskCard(16, player);
                }
                if (br[2] === 1 && this.CheckCard3(26, player, true) === true && (this.CheckCard(28) === true || this.CheckCard(27) === true)) {
                    return this.PuskCard(26, player);
                }
                if (br[3] === 1 && this.CheckCard3(36, player, true) === true && (this.CheckCard(38) === true || this.CheckCard(37) === true)) {
                    return this.PuskCard(36, player);
                }
                if (br[4] === 1 && this.CheckCard3(46, player, true) === true && (this.CheckCard(48) === true || this.CheckCard(47) === true)) {
                    return this.PuskCard(46, player);
                }
                if (
                    br[1] === 1 &&
                    this.CheckCard3(15, player, true) === true &&
                    (this.CheckCard(18) === true || this.CheckCard(17) === true || this.CheckCard(16) === true)
                ) {
                    return this.PuskCard(15, player);
                }
                if (
                    br[2] === 1 &&
                    this.CheckCard3(25, player, true) === true &&
                    (this.CheckCard(28) === true || this.CheckCard(27) === true || this.CheckCard(26) === true)
                ) {
                    return this.PuskCard(25, player);
                }
                if (
                    br[3] === 1 &&
                    this.CheckCard3(35, player, true) === true &&
                    (this.CheckCard(38) === true || this.CheckCard(37) === true || this.CheckCard(36) === true)
                ) {
                    return this.PuskCard(35, player);
                }
                if (
                    br[4] === 1 &&
                    this.CheckCard3(45, player, true) === true &&
                    (this.CheckCard(48) === true || this.CheckCard(47) === true || this.CheckCard(46) === true)
                ) {
                    return this.PuskCard(45, player);
                }
                if (
                    br[1] === 1 &&
                    this.CheckCard3(14, player, true) === true &&
                    (this.CheckCard(18) === true || this.CheckCard(17) === true || this.CheckCard(16) === true || this.CheckCard(15) === true)
                ) {
                    return this.PuskCard(14, player);
                }
                if (
                    br[2] === 1 &&
                    this.CheckCard3(24, player, true) === true &&
                    (this.CheckCard(28) === true || this.CheckCard(27) === true || this.CheckCard(26) === true || this.CheckCard(25) === true)
                ) {
                    return this.PuskCard(24, player);
                }
                if (
                    br[3] === 1 &&
                    this.CheckCard3(34, player, true) === true &&
                    (this.CheckCard(38) === true || this.CheckCard(37) === true || this.CheckCard(36) === true || this.CheckCard(35) === true)
                ) {
                    return this.PuskCard(34, player);
                }
                if (
                    br[4] === 1 &&
                    this.CheckCard3(44, player, true) === true &&
                    (this.CheckCard(48) === true || this.CheckCard(47) === true || this.CheckCard(46) === true || this.CheckCard(45) === true)
                ) {
                    return this.PuskCard(44, player);
                }

                if (br[1] > 1) {
                    if (this.CheckCard3(17, player, true) === true && this.CheckCard3(16, player, true) === true) {
                        return this.PuskCard(17, player);
                    }
                    if (this.CheckCard3(17, player, true) === true && this.CheckCard3(15, player, true) === true && this.CheckCard(16) === false) {
                        return this.PuskCard(17, player);
                    }
                    if (
                        this.CheckCard3(17, player, true) === true &&
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard(16) === false &&
                        this.CheckCard(15) === false
                    ) {
                        return this.PuskCard(17, player);
                    }
                    if (
                        this.CheckCard3(17, player, true) === true &&
                        this.CheckCard3(13, player, true) === true &&
                        this.CheckCard(16) === false &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false
                    ) {
                        return this.PuskCard(17, player);
                    }
                    if (
                        this.CheckCard3(17, player, true) === true &&
                        this.CheckCard3(12, player, true) === true &&
                        this.CheckCard(16) === false &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false
                    ) {
                        return this.PuskCard(17, player);
                    }
                    if (
                        this.CheckCard3(17, player, true) === true &&
                        this.CheckCard3(11, player, true) === true &&
                        this.CheckCard(16) === false &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false
                    ) {
                        return this.PuskCard(17, player);
                    }
                }
                if (br[2] > 1) {
                    if (this.CheckCard3(27, player, true) === true && this.CheckCard3(26, player, true) === true) {
                        return this.PuskCard(27, player);
                    }
                    if (this.CheckCard3(27, player, true) === true && this.CheckCard3(25, player, true) === true && this.CheckCard(26) === false) {
                        return this.PuskCard(27, player);
                    }
                    if (
                        this.CheckCard3(27, player, true) === true &&
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard(26) === false &&
                        this.CheckCard(25) === false
                    ) {
                        return this.PuskCard(27, player);
                    }
                    if (
                        this.CheckCard3(27, player, true) === true &&
                        this.CheckCard3(23, player, true) === true &&
                        this.CheckCard(26) === false &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false
                    ) {
                        return this.PuskCard(27, player);
                    }
                    if (
                        this.CheckCard3(27, player, true) === true &&
                        this.CheckCard3(22, player, true) === true &&
                        this.CheckCard(26) === false &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false
                    ) {
                        return this.PuskCard(27, player);
                    }
                    if (
                        this.CheckCard3(27, player, true) === true &&
                        this.CheckCard3(21, player, true) === true &&
                        this.CheckCard(26) === false &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false
                    ) {
                        return this.PuskCard(27, player);
                    }
                }
                if (br[3] > 1) {
                    if (this.CheckCard3(37, player, true) === true && this.CheckCard3(36, player, true) === true) {
                        return this.PuskCard(37, player);
                    }
                    if (this.CheckCard3(37, player, true) === true && this.CheckCard3(35, player, true) === true && this.CheckCard(36) === false) {
                        return this.PuskCard(37, player);
                    }
                    if (
                        this.CheckCard3(37, player, true) === true &&
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard(36) === false &&
                        this.CheckCard(35) === false
                    ) {
                        return this.PuskCard(37, player);
                    }
                    if (
                        this.CheckCard3(37, player, true) === true &&
                        this.CheckCard3(33, player, true) === true &&
                        this.CheckCard(36) === false &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false
                    ) {
                        return this.PuskCard(37, player);
                    }
                    if (
                        this.CheckCard3(37, player, true) === true &&
                        this.CheckCard3(32, player, true) === true &&
                        this.CheckCard(36) === false &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false
                    ) {
                        return this.PuskCard(37, player);
                    }
                    if (
                        this.CheckCard3(37, player, true) === true &&
                        this.CheckCard3(31, player, true) === true &&
                        this.CheckCard(36) === false &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false
                    ) {
                        return this.PuskCard(37, player);
                    }
                }
                if (br[4] > 1) {
                    if (this.CheckCard3(47, player, true) === true && this.CheckCard3(46, player, true) === true) {
                        return this.PuskCard(47, player);
                    }
                    if (this.CheckCard3(47, player, true) === true && this.CheckCard3(45, player, true) === true && this.CheckCard(46) === false) {
                        return this.PuskCard(47, player);
                    }
                    if (
                        this.CheckCard3(47, player, true) === true &&
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard(46) === false &&
                        this.CheckCard(45) === false
                    ) {
                        return this.PuskCard(47, player);
                    }
                    if (
                        this.CheckCard3(47, player, true) === true &&
                        this.CheckCard3(43, player, true) === true &&
                        this.CheckCard(46) === false &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false
                    ) {
                        return this.PuskCard(47, player);
                    }
                    if (
                        this.CheckCard3(47, player, true) === true &&
                        this.CheckCard3(42, player, true) === true &&
                        this.CheckCard(46) === false &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false
                    ) {
                        return this.PuskCard(47, player);
                    }
                    if (
                        this.CheckCard3(47, player, true) === true &&
                        this.CheckCard3(41, player, true) === true &&
                        this.CheckCard(46) === false &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false
                    ) {
                        return this.PuskCard(47, player);
                    }
                }
                if (br[1] > 1) {
                    if (this.CheckCard3(16, player, true) === true && this.CheckCard3(15, player, true) === true) {
                        return this.PuskCard(16, player);
                    }
                    if (this.CheckCard3(16, player, true) === true && this.CheckCard3(14, player, true) === true && this.CheckCard(15) === false) {
                        return this.PuskCard(16, player);
                    }
                    if (
                        this.CheckCard3(16, player, true) === true &&
                        this.CheckCard3(13, player, true) === true &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false
                    ) {
                        return this.PuskCard(16, player);
                    }
                    if (
                        this.CheckCard3(16, player, true) === true &&
                        this.CheckCard3(12, player, true) === true &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false
                    ) {
                        return this.PuskCard(16, player);
                    }
                    if (
                        this.CheckCard3(16, player, true) === true &&
                        this.CheckCard3(11, player, true) === true &&
                        this.CheckCard(15) === false &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false
                    ) {
                        return this.PuskCard(16, player);
                    }
                }
                if (br[2] > 1) {
                    if (this.CheckCard3(26, player, true) === true && this.CheckCard3(25, player, true) === true) {
                        return this.PuskCard(26, player);
                    }
                    if (this.CheckCard3(26, player, true) === true && this.CheckCard3(24, player, true) === true && this.CheckCard(25) === false) {
                        return this.PuskCard(26, player);
                    }
                    if (
                        this.CheckCard3(26, player, true) === true &&
                        this.CheckCard3(23, player, true) === true &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false
                    ) {
                        return this.PuskCard(26, player);
                    }
                    if (
                        this.CheckCard3(26, player, true) === true &&
                        this.CheckCard3(22, player, true) === true &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false
                    ) {
                        return this.PuskCard(26, player);
                    }
                    if (
                        this.CheckCard3(26, player, true) === true &&
                        this.CheckCard3(21, player, true) === true &&
                        this.CheckCard(25) === false &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false
                    ) {
                        return this.PuskCard(26, player);
                    }
                }
                if (br[3] > 1) {
                    if (this.CheckCard3(36, player, true) === true && this.CheckCard3(35, player, true) === true) {
                        return this.PuskCard(36, player);
                    }
                    if (this.CheckCard3(36, player, true) === true && this.CheckCard3(34, player, true) === true && this.CheckCard(35) === false) {
                        return this.PuskCard(36, player);
                    }
                    if (
                        this.CheckCard3(36, player, true) === true &&
                        this.CheckCard3(33, player, true) === true &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false
                    ) {
                        return this.PuskCard(36, player);
                    }
                    if (
                        this.CheckCard3(36, player, true) === true &&
                        this.CheckCard3(32, player, true) === true &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false
                    ) {
                        return this.PuskCard(36, player);
                    }
                    if (
                        this.CheckCard3(36, player, true) === true &&
                        this.CheckCard3(31, player, true) === true &&
                        this.CheckCard(35) === false &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false
                    ) {
                        return this.PuskCard(36, player);
                    }
                }
                if (br[4] > 1) {
                    if (this.CheckCard3(46, player, true) === true && this.CheckCard3(45, player, true) === true) {
                        return this.PuskCard(46, player);
                    }
                    if (this.CheckCard3(46, player, true) === true && this.CheckCard3(44, player, true) === true && this.CheckCard(45) === false) {
                        return this.PuskCard(46, player);
                    }
                    if (
                        this.CheckCard3(46, player, true) === true &&
                        this.CheckCard3(43, player, true) === true &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false
                    ) {
                        return this.PuskCard(46, player);
                    }
                    if (
                        this.CheckCard3(46, player, true) === true &&
                        this.CheckCard3(42, player, true) === true &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false
                    ) {
                        return this.PuskCard(46, player);
                    }
                    if (
                        this.CheckCard3(46, player, true) === true &&
                        this.CheckCard3(41, player, true) === true &&
                        this.CheckCard(45) === false &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false
                    ) {
                        return this.PuskCard(46, player);
                    }
                }
                if (br[1] > 1) {
                    if (
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false &&
                        this.CheckCard(11) === false
                    ) {
                        return this.PuskCard(15, player);
                    }
                    if (this.CheckCard3(15, player, true) === true && this.CheckCard3(14, player, true) === true) {
                        return this.PuskCard(15, player);
                    }
                    if (this.CheckCard3(15, player, true) === true && this.CheckCard3(13, player, true) === true && this.CheckCard(14) === false) {
                        return this.PuskCard(15, player);
                    }
                    if (
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard3(12, player, true) === true &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false
                    ) {
                        return this.PuskCard(15, player);
                    }
                    if (
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard3(11, player, true) === true &&
                        this.CheckCard(14) === false &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false
                    ) {
                        return this.PuskCard(15, player);
                    }
                }
                if (br[2] > 1) {
                    if (
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false &&
                        this.CheckCard(21) === false
                    ) {
                        return this.PuskCard(25, player);
                    }
                    if (this.CheckCard3(25, player, true) === true && this.CheckCard3(24, player, true) === true) {
                        return this.PuskCard(25, player);
                    }
                    if (this.CheckCard3(25, player, true) === true && this.CheckCard3(23, player, true) === true && this.CheckCard(24) === false) {
                        return this.PuskCard(25, player);
                    }
                    if (
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard3(22, player, true) === true &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false
                    ) {
                        return this.PuskCard(25, player);
                    }
                    if (
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard3(21, player, true) === true &&
                        this.CheckCard(24) === false &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false
                    ) {
                        return this.PuskCard(25, player);
                    }
                }
                if (br[3] > 1) {
                    if (
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false &&
                        this.CheckCard(31) === false
                    ) {
                        return this.PuskCard(35, player);
                    }
                    if (this.CheckCard3(35, player, true) === true && this.CheckCard3(34, player, true) === true) {
                        return this.PuskCard(35, player);
                    }
                    if (this.CheckCard3(35, player, true) === true && this.CheckCard3(33, player, true) === true && this.CheckCard(34) === false) {
                        return this.PuskCard(35, player);
                    }
                    if (
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard3(32, player, true) === true &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false
                    ) {
                        return this.PuskCard(35, player);
                    }
                    if (
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard3(31, player, true) === true &&
                        this.CheckCard(34) === false &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false
                    ) {
                        return this.PuskCard(35, player);
                    }
                }
                if (br[4] > 1) {
                    if (
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false &&
                        this.CheckCard(41) === false
                    ) {
                        return this.PuskCard(45, player);
                    }
                    if (this.CheckCard3(45, player, true) === true && this.CheckCard3(44, player, true) === true) {
                        return this.PuskCard(45, player);
                    }
                    if (this.CheckCard3(45, player, true) === true && this.CheckCard3(43, player, true) === true && this.CheckCard(44) === false) {
                        return this.PuskCard(15, player);
                    }
                    if (
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard3(42, player, true) === true &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false
                    ) {
                        return this.PuskCard(45, player);
                    }
                    if (
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard3(41, player, true) === true &&
                        this.CheckCard(44) === false &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false
                    ) {
                        return this.PuskCard(45, player);
                    }
                }
                if (br[1] > 1) {
                    if (
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false &&
                        this.CheckCard(11) === false
                    ) {
                        return this.PuskCard(14, player);
                    }
                    if (this.CheckCard3(14, player, true) === true && this.CheckCard3(13, player, true) === true) {
                        return this.PuskCard(14, player);
                    }
                    if (this.CheckCard3(14, player, true) === true && this.CheckCard3(12, player, true) === true && this.CheckCard(13) === false) {
                        return this.PuskCard(14, player);
                    }
                    if (
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard3(11, player, true) === true &&
                        this.CheckCard(13) === false &&
                        this.CheckCard(12) === false
                    ) {
                        return this.PuskCard(14, player);
                    }
                }
                if (br[2] > 1) {
                    if (
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false &&
                        this.CheckCard(21) === false
                    ) {
                        return this.PuskCard(24, player);
                    }
                    if (this.CheckCard3(24, player, true) === true && this.CheckCard3(23, player, true) === true) {
                        return this.PuskCard(24, player);
                    }
                    if (this.CheckCard3(24, player, true) === true && this.CheckCard3(22, player, true) === true && this.CheckCard(23) === false) {
                        return this.PuskCard(24, player);
                    }
                    if (
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard3(21, player, true) === true &&
                        this.CheckCard(23) === false &&
                        this.CheckCard(22) === false
                    ) {
                        return this.PuskCard(24, player);
                    }
                }
                if (br[3] > 1) {
                    if (
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false &&
                        this.CheckCard(31) === false
                    ) {
                        return this.PuskCard(34, player);
                    }
                    if (this.CheckCard3(34, player, true) === true && this.CheckCard3(33, player, true) === true) {
                        return this.PuskCard(34, player);
                    }
                    if (this.CheckCard3(34, player, true) === true && this.CheckCard3(32, player, true) === true && this.CheckCard(33) === false) {
                        return this.PuskCard(34, player);
                    }
                    if (
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard3(31, player, true) === true &&
                        this.CheckCard(33) === false &&
                        this.CheckCard(32) === false
                    ) {
                        return this.PuskCard(34, player);
                    }
                }
                if (br[4] > 1) {
                    if (
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false &&
                        this.CheckCard(41) === false
                    ) {
                        return this.PuskCard(44, player);
                    }
                    if (this.CheckCard3(44, player, true) === true && this.CheckCard3(43, player, true) === true) {
                        return this.PuskCard(44, player);
                    }
                    if (this.CheckCard3(44, player, true) === true && this.CheckCard3(42, player, true) === true && this.CheckCard(43) === false) {
                        return this.PuskCard(44, player);
                    }
                    if (
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard3(41, player, true) === true &&
                        this.CheckCard(43) === false &&
                        this.CheckCard(42) === false
                    ) {
                        return this.PuskCard(44, player);
                    }
                }
                if (
                    this.CheckVlastnaKarta4(this.CheckNGCard(player, 1), partner[2], partner[3], partner[4]) === false &&
                    this.Naddavane !== 1 &&
                    this.CheckNGCard(player, 1) > 0
                ) {
                    return this.PuskCard(this.CheckNGCard(player, 1), player);
                }
                if (
                    this.CheckVlastnaKarta4(this.CheckNGCard(player, 2), partner[2], partner[3], partner[4]) === false &&
                    this.Naddavane !== 2 &&
                    this.CheckNGCard(player, 2) > 0
                ) {
                    return this.PuskCard(this.CheckNGCard(player, 2), player);
                }
                if (
                    this.CheckVlastnaKarta4(this.CheckNGCard(player, 3), partner[2], partner[3], partner[4]) === false &&
                    this.Naddavane !== 3 &&
                    this.CheckNGCard(player, 3) > 0
                ) {
                    return this.PuskCard(this.CheckNGCard(player, 3), player);
                }
                if (
                    this.CheckVlastnaKarta4(this.CheckNGCard(player, 4), partner[2], partner[3], partner[4]) === false &&
                    this.Naddavane !== 4 &&
                    this.CheckNGCard(player, 4) > 0
                ) {
                    return this.PuskCard(this.CheckNGCard(player, 4), player);
                }
            }
            if (this.Naddavane === 5 || this.Naddavane === 6) {
                if (br[1] === 1 && this.CheckCard3(17, player, true) === true && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(17, player);
                }
                if (br[2] === 1 && this.CheckCard3(27, player, true) === true && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(27, player);
                }
                if (br[3] === 1 && this.CheckCard3(37, player, true) === true && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(37, player);
                }
                if (br[4] === 1 && this.CheckCard3(47, player, true) === true && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(47, player);
                }

                if (br[1] === 1 && this.CheckCard3(16, player, true) === true && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(16, player);
                }
                if (br[2] === 1 && this.CheckCard3(26, player, true) === true && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(26, player);
                }
                if (br[3] === 1 && this.CheckCard3(36, player, true) === true && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(36, player);
                }
                if (br[4] === 1 && this.CheckCard3(46, player, true) === true && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(46, player);
                }

                if (br[1] === 1 && this.CheckCard3(15, player, true) === true && this.CheckVlastnaKarta(15) === false) {
                    return this.PuskCard(15, player);
                }
                if (br[2] === 1 && this.CheckCard3(25, player, true) === true && this.CheckVlastnaKarta(25) === false) {
                    return this.PuskCard(25, player);
                }
                if (br[3] === 1 && this.CheckCard3(35, player, true) === true && this.CheckVlastnaKarta(35) === false) {
                    return this.PuskCard(35, player);
                }
                if (br[4] === 1 && this.CheckCard3(45, player, true) === true && this.CheckVlastnaKarta(45) === false) {
                    return this.PuskCard(45, player);
                }

                if (br[1] === 1 && this.CheckCard3(14, player, true) === true && this.CheckVlastnaKarta(14) === false) {
                    return this.PuskCard(14, player);
                }
                if (br[2] === 1 && this.CheckCard3(24, player, true) === true && this.CheckVlastnaKarta(24) === false) {
                    return this.PuskCard(24, player);
                }
                if (br[3] === 1 && this.CheckCard3(34, player, true) === true && this.CheckVlastnaKarta(34) === false) {
                    return this.PuskCard(34, player);
                }
                if (br[4] === 1 && this.CheckCard3(44, player, true) === true && this.CheckVlastnaKarta(44) === false) {
                    return this.PuskCard(44, player);
                }

                if (br[1] === 1 && this.CheckCard3(13, player, true) === true && this.CheckVlastnaKarta(13) === false) {
                    return this.PuskCard(13, player);
                }
                if (br[2] === 1 && this.CheckCard3(23, player, true) === true && this.CheckVlastnaKarta(23) === false) {
                    return this.PuskCard(23, player);
                }
                if (br[3] === 1 && this.CheckCard3(33, player, true) === true && this.CheckVlastnaKarta(33) === false) {
                    return this.PuskCard(33, player);
                }
                if (br[4] === 1 && this.CheckCard3(43, player, true) === true && this.CheckVlastnaKarta(43) === false) {
                    return this.PuskCard(43, player);
                }
                ////////////
            }
        }
        return 0;
    }

    CheckVlastnaKartaAfter(card: number) {
        let a;
        if (card > 10 && card < 20) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 20 && this.k[a] > card) {
                    return false;
                }
            }
        }
        if (card > 20 && card < 30) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 30 && this.k[a] > card) {
                    return false;
                }
            }
        }
        if (card > 30 && card < 40) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 40 && this.k[a] > card) {
                    return false;
                }
            }
        }
        if (card > 40 && card < 50) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 50 && this.k[a] > card) {
                    return false;
                }
            }
        }
        if (card > 100) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] > card) {
                    return false;
                }
            }
        }
        return true;
    }

    MinCard(player: number) {
        let h;
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 11) {
                return h;
            }
            if (this.k[h] === 21) {
                return h;
            }
            if (this.k[h] === 31) {
                return h;
            }
            if (this.k[h] === 41) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 12) {
                return h;
            }
            if (this.k[h] === 22) {
                return h;
            }
            if (this.k[h] === 32) {
                return h;
            }
            if (this.k[h] === 42) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 13) {
                return h;
            }
            if (this.k[h] === 23) {
                return h;
            }
            if (this.k[h] === 33) {
                return h;
            }
            if (this.k[h] === 43) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 14) {
                return h;
            }
            if (this.k[h] === 24) {
                return h;
            }
            if (this.k[h] === 34) {
                return h;
            }
            if (this.k[h] === 44) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 15) {
                return h;
            }
            if (this.k[h] === 25) {
                return h;
            }
            if (this.k[h] === 35) {
                return h;
            }
            if (this.k[h] === 45) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 16) {
                return h;
            }
            if (this.k[h] === 26) {
                return h;
            }
            if (this.k[h] === 36) {
                return h;
            }
            if (this.k[h] === 46) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 17) {
                return h;
            }
            if (this.k[h] === 27) {
                return h;
            }
            if (this.k[h] === 37) {
                return h;
            }
            if (this.k[h] === 47) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 18) {
                return h;
            }
            if (this.k[h] === 28) {
                return h;
            }
            if (this.k[h] === 38) {
                return h;
            }
            if (this.k[h] === 48) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 101) {
                return h;
            }
            if (this.k[h] === 102) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 103) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 104) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 105) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 106) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 107) {
                return h;
            }
        }
        for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === 108) {
                return h;
            }
        }
        return 1 + (player - 1) * 8;
    }

    PuskDrBMinCard(player: number) {
        if (this.Players === 4) {
            if (this.Naddavane === 5 || this.Naddavane === 6) {
                if (this.CheckNGCard(player, 1) === 11 && this.CheckVlastnaKarta(11) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 21 && this.CheckVlastnaKarta(21) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 31 && this.CheckVlastnaKarta(31) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 41 && this.CheckVlastnaKarta(41) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 12 && this.CheckVlastnaKarta(12) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 22 && this.CheckVlastnaKarta(22) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 32 && this.CheckVlastnaKarta(32) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 42 && this.CheckVlastnaKarta(42) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 13) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 23) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 33) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 43) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 13 && this.CheckVlastnaKarta(13) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 23 && this.CheckVlastnaKarta(23) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 33 && this.CheckVlastnaKarta(33) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 43 && this.CheckVlastnaKarta(43) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 14) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 24) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 34) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 44) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 14 && this.CheckVlastnaKarta(14) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 24 && this.CheckVlastnaKarta(24) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 34 && this.CheckVlastnaKarta(34) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 44 && this.CheckVlastnaKarta(44) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 15) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 25) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 35) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 45) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 15 && this.CheckVlastnaKarta(15) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 25 && this.CheckVlastnaKarta(25) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 35 && this.CheckVlastnaKarta(35) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 45 && this.CheckVlastnaKarta(45) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 16) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 26) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 36) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 46) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 16 && this.GetBrojKarti(1, player) > 2 && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 26 && this.GetBrojKarti(2, player) > 2 && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 36 && this.GetBrojKarti(3, player) > 2 && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 46 && this.GetBrojKarti(4, player) > 2 && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 16 && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 26 && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 36 && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 46 && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 17) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 27) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 37) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 47) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 17 && this.GetBrojKarti(1, player) > 2 && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 27 && this.GetBrojKarti(2, player) > 2 && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 37 && this.GetBrojKarti(3, player) > 2 && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 47 && this.GetBrojKarti(4, player) > 2 && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 17 && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 27 && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 37 && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 47 && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 18) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 28) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 38) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 48) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
            }
            if (this.Naddavane < 5) {
                if (this.CheckNGCard(player, 1) === 11) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 21) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 31) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 41) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 12) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 22) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 32) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 42) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 13) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 23) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 33) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 43) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 14) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 24) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 34) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 44) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 14) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 24) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 34) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 44) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 15) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 25) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 35) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 45) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 15) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 25) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 35) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 45) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 16) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 26) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 36) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 46) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 16 && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 26 && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 36 && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 46 && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 17) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 27) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 37) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 47) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 17 && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 27 && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 37 && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 47 && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                if (this.CheckNGCard(player, 1) === 18 && this.CheckNMCard(player, 1) < 18) {
                    return this.PuskCard(this.CheckNMCard(player, 1), player);
                }
                if (this.CheckNGCard(player, 2) === 28 && this.CheckNMCard(player, 2) < 28) {
                    return this.PuskCard(this.CheckNMCard(player, 2), player);
                }
                if (this.CheckNGCard(player, 3) === 38 && this.CheckNMCard(player, 3) < 38) {
                    return this.PuskCard(this.CheckNMCard(player, 3), player);
                }
                if (this.CheckNGCard(player, 4) === 48 && this.CheckNMCard(player, 4) < 48) {
                    return this.PuskCard(this.CheckNMCard(player, 4), player);
                }
                //			return this.PuskCard(this.CheckNMCard(player,Naddavane),player);
            }
        }
        return 0;
    }

    CheckVlastnaKarta4(player: number, partner2: number, partner3: number, partner4: number) {
        let h;
        if (
            (this.KoiPuskaParvi === 2 && player === 2) ||
            (this.KoiPuskaParvi === 3 && player === 3) ||
            (this.KoiPuskaParvi === 4 && player === 4) ||
            (this.KoiPuskaParvi === 1 && player === 1)
        ) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 18 &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 28 &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 38 &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 48 &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 17 &&
                    this.CheckVlastnaKarta(17) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 27 &&
                    this.CheckVlastnaKarta(27) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 37 &&
                    this.CheckVlastnaKarta(37) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 47 &&
                    this.CheckVlastnaKarta(47) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 16 &&
                    this.CheckVlastnaKarta(16) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 26 &&
                    this.CheckVlastnaKarta(26) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 36 &&
                    this.CheckVlastnaKarta(36) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 46 &&
                    this.CheckVlastnaKarta(46) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 15 &&
                    this.CheckVlastnaKarta(15) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 25 &&
                    this.CheckVlastnaKarta(25) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 35 &&
                    this.CheckVlastnaKarta(35) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 45 &&
                    this.CheckVlastnaKarta(45) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 14 &&
                    this.CheckVlastnaKarta(14) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 24 &&
                    this.CheckVlastnaKarta(24) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 34 &&
                    this.CheckVlastnaKarta(34) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 44 &&
                    this.CheckVlastnaKarta(44) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 13 &&
                    this.CheckVlastnaKarta(13) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 23 &&
                    this.CheckVlastnaKarta(23) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 33 &&
                    this.CheckVlastnaKarta(33) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 43 &&
                    this.CheckVlastnaKarta(43) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 12 &&
                    this.CheckVlastnaKarta(12) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 22 &&
                    this.CheckVlastnaKarta(22) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 32 &&
                    this.CheckVlastnaKarta(32) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 42 &&
                    this.CheckVlastnaKarta(42) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 11 &&
                    this.CheckVlastnaKarta(11) === true &&
                    this.Naddavane !== 1 &&
                    (this.GetBrojKarti(1, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(1, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 21 &&
                    this.CheckVlastnaKarta(21) === true &&
                    this.Naddavane !== 2 &&
                    (this.GetBrojKarti(2, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(2, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 31 &&
                    this.CheckVlastnaKarta(31) === true &&
                    this.Naddavane !== 3 &&
                    (this.GetBrojKarti(3, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(3, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (
                    this.k[h] === 41 &&
                    this.CheckVlastnaKarta(41) === true &&
                    this.Naddavane !== 4 &&
                    (this.GetBrojKarti(4, partner2) > 0 || this.GetBrojKarti(this.Naddavane, partner2) === 0) &&
                    (this.GetBrojKarti(4, partner4) > 0 || this.GetBrojKarti(this.Naddavane, partner4) === 0)
                ) {
                    return h;
                }
            }
        }
        if (
            (this.KoiPuskaParvi === 2 && player === 3) ||
            (this.KoiPuskaParvi === 3 && player === 4) ||
            (this.KoiPuskaParvi === 4 && player === 1) ||
            (this.KoiPuskaParvi === 1 && player === 2)
        ) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 18 && (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 17 &&
                        this.CheckVlastnaKarta(17) === true &&
                        (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 16 &&
                        this.CheckVlastnaKarta(16) === true &&
                        (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 15 &&
                        this.CheckVlastnaKarta(15) === true &&
                        (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 14 && this.CheckVlastnaKarta(14) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 13 && this.CheckVlastnaKarta(13) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 12 && this.CheckVlastnaKarta(12) === true) {
                        return h;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 28 && (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 27 &&
                        this.CheckVlastnaKarta(27) === true &&
                        (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 26 &&
                        this.CheckVlastnaKarta(26) === true &&
                        (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 25 &&
                        this.CheckVlastnaKarta(25) === true &&
                        (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 24 && this.CheckVlastnaKarta(24) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 23 && this.CheckVlastnaKarta(23) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 22 && this.CheckVlastnaKarta(22) === true) {
                        return h;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 38 && (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 37 &&
                        this.CheckVlastnaKarta(37) === true &&
                        (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 36 &&
                        this.CheckVlastnaKarta(36) === true &&
                        (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 35 &&
                        this.CheckVlastnaKarta(35) === true &&
                        (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 34 && this.CheckVlastnaKarta(34) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 33 && this.CheckVlastnaKarta(33) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 32 && this.CheckVlastnaKarta(32) === true) {
                        return h;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 48 && (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 47 &&
                        this.CheckVlastnaKarta(47) === true &&
                        (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 46 &&
                        this.CheckVlastnaKarta(46) === true &&
                        (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (
                        this.k[h] === 45 &&
                        this.CheckVlastnaKarta(45) === true &&
                        (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                    ) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 44 && this.CheckVlastnaKarta(44) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 43 && this.CheckVlastnaKarta(43) === true) {
                        return h;
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 42 && this.CheckVlastnaKarta(42) === true) {
                        return h;
                    }
                }
            }
        }
        if (
            (this.KoiPuskaParvi === 2 && player === 4) ||
            (this.KoiPuskaParvi === 3 && player === 1) ||
            (this.KoiPuskaParvi === 4 && player === 2) ||
            (this.KoiPuskaParvi === 1 && player === 3)
        ) {
            if (this.VtoriPuska < 100) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 18 && (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 17 &&
                            this.CheckVlastnaKarta(17) === true &&
                            (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 16 &&
                            this.CheckVlastnaKarta(16) === true &&
                            (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 15 &&
                            this.CheckVlastnaKarta(15) === true &&
                            (this.Pniama[partner2][1] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 14 && this.CheckVlastnaKarta(14) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 13 && this.CheckVlastnaKarta(13) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 12 && this.CheckVlastnaKarta(12) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 28 && (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 27 &&
                            this.CheckVlastnaKarta(27) === true &&
                            (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 26 &&
                            this.CheckVlastnaKarta(26) === true &&
                            (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 25 &&
                            this.CheckVlastnaKarta(25) === true &&
                            (this.Pniama[partner2][2] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 24 && this.CheckVlastnaKarta(24) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 23 && this.CheckVlastnaKarta(23) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 22 && this.CheckVlastnaKarta(22) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 38 && (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 37 &&
                            this.CheckVlastnaKarta(37) === true &&
                            (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 36 &&
                            this.CheckVlastnaKarta(36) === true &&
                            (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 35 &&
                            this.CheckVlastnaKarta(35) === true &&
                            (this.Pniama[partner2][3] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 34 && this.CheckVlastnaKarta(34) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 33 && this.CheckVlastnaKarta(33) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 32 && this.CheckVlastnaKarta(32) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 48 && (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 47 &&
                            this.CheckVlastnaKarta(47) === true &&
                            (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 46 &&
                            this.CheckVlastnaKarta(46) === true &&
                            (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (
                            this.k[h] === 45 &&
                            this.CheckVlastnaKarta(45) === true &&
                            (this.Pniama[partner2][4] !== true || this.Pniama[partner2][this.Naddavane] === true)
                        ) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 44 && this.CheckVlastnaKarta(44) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 43 && this.CheckVlastnaKarta(43) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 42 && this.CheckVlastnaKarta(42) === true) {
                            return h;
                        }
                    }
                }
            }
        }
        if (
            (this.KoiPuskaParvi === 2 && player === 1) ||
            (this.KoiPuskaParvi === 3 && player === 2) ||
            (this.KoiPuskaParvi === 4 && player === 3) ||
            (this.KoiPuskaParvi === 1 && player === 4)
        ) {
            if (this.ParviPuska < 100 && this.TretiPuska < 100) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 18) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 17 && this.CheckVlastnaKarta(17) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 16 && this.CheckVlastnaKarta(16) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 15 && this.CheckVlastnaKarta(15) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 14 && this.CheckVlastnaKarta(14) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 13 && this.CheckVlastnaKarta(13) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 12 && this.CheckVlastnaKarta(12) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 28) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 27 && this.CheckVlastnaKarta(27) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 26 && this.CheckVlastnaKarta(26) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 25 && this.CheckVlastnaKarta(25) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 24 && this.CheckVlastnaKarta(24) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 23 && this.CheckVlastnaKarta(23) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 22 && this.CheckVlastnaKarta(22) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 38) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 37 && this.CheckVlastnaKarta(37) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 36 && this.CheckVlastnaKarta(36) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 35 && this.CheckVlastnaKarta(35) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 34 && this.CheckVlastnaKarta(34) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 33 && this.CheckVlastnaKarta(33) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 32 && this.CheckVlastnaKarta(32) === true) {
                            return h;
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 48) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 47 && this.CheckVlastnaKarta(47) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 46 && this.CheckVlastnaKarta(46) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 45 && this.CheckVlastnaKarta(45) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 44 && this.CheckVlastnaKarta(44) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 43 && this.CheckVlastnaKarta(43) === true) {
                            return h;
                        }
                    }
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 42 && this.CheckVlastnaKarta(42) === true) {
                            return h;
                        }
                    }
                }
            }
        }
        return 0;
    }

    CheckNMCard(player: number, boia: number) {
        let h: number;
        if (boia === this.Naddavane) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (this.k[h] > 100) {
                    return this.k[h];
                }
            }
        }
        if (boia === 1) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (this.k[h] > 10 && this.k[h] < 20) {
                    return this.k[h];
                }
            }
        }
        if (boia === 2) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (this.k[h] > 20 && this.k[h] < 30) {
                    return this.k[h];
                }
            }
        }
        if (boia === 3) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (this.k[h] > 30 && this.k[h] < 40) {
                    return this.k[h];
                }
            }
        }
        if (boia === 4) {
            for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                if (this.k[h] > 40 && this.k[h] < 50) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    SendNamesP(player: number) {
        if (this.Players === 4) {
            if (player === 2) {
                let message = '$|t|2|' + this.Name[3] + '|' + this.Name[4] + '|' + this.Name[1];
                this.SendGame(message);
            }
            if (player === 3) {
                let message = '$|t|3|' + this.Name[4] + '|' + this.Name[1] + '|' + this.Name[2];
                this.SendGame(message);
            }
            if (player === 4) {
                let message = '$|t|4|' + this.Name[1] + '|' + this.Name[2] + '|' + this.Name[3];
                this.SendGame(message);
            }
        }
        if (this.Players === 3) {
            if (player === 2) {
                let message = '$|t|2|' + this.Name[3] + '|' + this.Name[1];
                this.SendGame(message);
            }
            if (player === 3) {
                let message = '$|t|3|' + this.Name[1] + '|' + this.Name[2];
                this.SendGame(message);
            }
        }
    }

    PuskCard(card: number, player: number) {
        for (let h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
            if (this.k[h] === card) {
                return h;
            }
        }
        return 1 + (player - 1) * 8;
    }

    SendNames() {
        if (this.Players === 4) {
            if (this.P2OP) {
                let message = '$|a|' + this.Name[3];
                this.SendGame(message);
                message = '$|a|' + this.Name[4];
                this.SendGame(message);
                message = '$|a|' + this.Name[1];
                this.SendGame(message);
            }
            if (this.P3OP) {
                let message = '$|d|' + this.Name[4];
                this.SendGame(message);
                message = '$|d|' + this.Name[1];
                this.SendGame(message);
                message = '$|d|' + this.Name[2];
                this.SendGame(message);
            }
            if (this.P4OP) {
                let message = '$|g|' + this.Name[1];
                this.SendGame(message);
                message = '$|g|' + this.Name[2];
                this.SendGame(message);
                message = '$|g|' + this.Name[3];
                this.SendGame(message);
            }
            if (this.HaveWatch) {
                let message = '$|t|1|' + this.Name[2] + '|' + this.Name[3] + '|' + this.Name[4];
                this.SendGame(message);
            }
        }
        if (this.Players === 3) {
            if (this.P2OP) {
                let message = '$|a|' + this.Name[3];
                this.SendGame(message);
                message = '$|a|' + this.Name[1];
                this.SendGame(message);
            }
            if (this.P3OP) {
                let message = '$|d|' + this.Name[1];
                this.SendGame(message);
                message = '$|d|' + this.Name[2];
                this.SendGame(message);
            }
            if (this.HaveWatch) {
                let message = '$|t|1|' + this.Name[2] + '|' + this.Name[3];
                this.SendGame(message);
            }
        }
        if (this.Players === 2) {
            if (this.P2OP) {
                let message = '$|a|' + this.Name[1];
                this.SendGame(message);
            }
            if (this.HaveWatch) {
                let message = '$|t|1|' + this.Name[2];
                this.SendGame(message);
            }
        }
    }

    CheckVlastnaKarta(card: number) {
        let a;
        if (card > 10 && card < 20) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 20 && this.k[a] > card) {
                    return false;
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.ParviPuska > card) {
                    return false;
                }
            }
            if (this.VtoriPuska > 10 && this.VtoriPuska < 20) {
                if (this.VtoriPuska > card) {
                    return false;
                }
            }
            if (this.TretiPuska > 10 && this.TretiPuska < 20) {
                if (this.TretiPuska > card) {
                    return false;
                }
            }
        }
        if (card > 20 && card < 30) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 30 && this.k[a] > card) {
                    return false;
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.ParviPuska > card) {
                    return false;
                }
            }
            if (this.VtoriPuska > 20 && this.VtoriPuska < 30) {
                if (this.VtoriPuska > card) {
                    return false;
                }
            }
            if (this.TretiPuska > 20 && this.TretiPuska < 30) {
                if (this.TretiPuska > card) {
                    return false;
                }
            }
        }
        if (card > 30 && card < 40) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 40 && this.k[a] > card) {
                    return false;
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.ParviPuska > card) {
                    return false;
                }
            }
            if (this.VtoriPuska > 30 && this.VtoriPuska < 40) {
                if (this.VtoriPuska > card) {
                    return false;
                }
            }
            if (this.TretiPuska > 30 && this.TretiPuska < 40) {
                if (this.TretiPuska > card) {
                    return false;
                }
            }
        }
        if (card > 40 && card < 50) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] < 50 && this.k[a] > card) {
                    return false;
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.ParviPuska > card) {
                    return false;
                }
            }
            if (this.VtoriPuska > 40 && this.VtoriPuska < 50) {
                if (this.VtoriPuska > card) {
                    return false;
                }
            }
            if (this.TretiPuska > 40 && this.TretiPuska < 50) {
                if (this.TretiPuska > card) {
                    return false;
                }
            }
        }
        if (card > 100) {
            for (a = 1; a < 33; a++) {
                if (this.k[a] > card) {
                    return false;
                }
            }
            if (this.ParviPuska > 100) {
                if (this.ParviPuska > card) {
                    return false;
                }
            }
            if (this.VtoriPuska > 100) {
                if (this.VtoriPuska > card) {
                    return false;
                }
            }
            if (this.TretiPuska > 100) {
                if (this.TretiPuska > card) {
                    return false;
                }
            }
        }
        return true;
    }

    CheckNGCard(player: number, boia: number) {
        let h;
        if (boia === this.Naddavane) {
            for (h = 8 - this.hand + (player - 1) * 8; h > (player - 1) * 8; h--) {
                if (this.k[h] > 100) {
                    return this.k[h];
                }
            }
        }
        if (boia === 1) {
            for (h = 8 - this.hand + (player - 1) * 8; h > (player - 1) * 8; h--) {
                if (this.k[h] > 10 && this.k[h] < 20) {
                    return this.k[h];
                }
            }
        }
        if (boia === 2) {
            for (h = 8 - this.hand + (player - 1) * 8; h > (player - 1) * 8; h--) {
                if (this.k[h] > 20 && this.k[h] < 30) {
                    return this.k[h];
                }
            }
        }
        if (boia === 3) {
            for (h = 8 - this.hand + (player - 1) * 8; h > (player - 1) * 8; h--) {
                if (this.k[h] > 30 && this.k[h] < 40) {
                    return this.k[h];
                }
            }
        }
        if (boia === 4) {
            for (h = 8 - this.hand + (player - 1) * 8; h > (player - 1) * 8; h--) {
                if (this.k[h] > 40 && this.k[h] < 50) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    StringTerci(terca: number) {
        if (terca === 1) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' 9';
        }
        if (terca === 2) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' 10';
        }
        if (terca === 3) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' J';
        }
        if (terca === 4) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' D';
        }
        if (terca === 5) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' K';
        }
        if (terca === 6) {
            return this.GetLanguageText('Twenty') + ' ' + this.GetLanguageText('to') + ' A';
        }

        if (terca === 11) {
            return this.GetLanguageText('Fifty') + ' ' + this.GetLanguageText('to') + ' 10';
        }
        if (terca === 12) {
            return this.GetLanguageText('Fifty') + ' ' + this.GetLanguageText('to') + ' J';
        }
        if (terca === 13) {
            return this.GetLanguageText('Fifty') + ' ' + this.GetLanguageText('to') + ' D';
        }
        if (terca === 14) {
            return this.GetLanguageText('Fifty') + ' ' + this.GetLanguageText('to') + ' K';
        }
        if (terca === 15) {
            return this.GetLanguageText('Fifty') + ' ' + this.GetLanguageText('to') + ' A';
        }

        if (terca === 21) {
            return this.GetLanguageText('Hundred') + ' ' + this.GetLanguageText('to') + ' J';
        }
        if (terca === 22) {
            return this.GetLanguageText('Hundred') + ' ' + this.GetLanguageText('to') + ' D';
        }
        if (terca === 23) {
            return this.GetLanguageText('Hundred') + ' ' + this.GetLanguageText('to') + ' K';
        }
        if (terca === 24) {
            return this.GetLanguageText('Hundred') + ' ' + this.GetLanguageText('to') + ' A';
        }

        if (terca === 31) {
            return this.GetLanguageText('Four') + ' D';
        }
        if (terca === 32) {
            return this.GetLanguageText('Four') + ' K';
        }
        if (terca === 33) {
            return this.GetLanguageText('Four') + ' 10';
        }
        if (terca === 34) {
            return this.GetLanguageText('Four') + ' A';
        }
        if (terca === 35) {
            return this.GetLanguageText('Four') + ' 9';
        }
        if (terca === 36) {
            return this.GetLanguageText('Four') + ' J';
        }
        return '';
    }

    DrawChavka() {
        if (this.BrPas !== this.Players && this.Faza !== 6) {
            return this.DrawKoiENaRed(this.NamePos[this.KoiZapochva].x, this.NamePos[this.KoiZapochva].y);
        } else {
            return null;
        }
    }

    async LoadBitmaps() {
        let face = await this.GetProfileString('Options', 'facenew', 'cards_1');
        let cardPicture = 1;
        if (this.Players === 4) {
            for (let i = 100; i <= 400; i = i + 100) {
                for (let a = 7; a <= 14; a++) {
                    this.CardPicture[cardPicture] = 'cards/' + face + '/' + this.IntToString(i + a) + '.gif';
                    cardPicture++;
                }
            }
        } else {
            for (let i = 100; i <= 400; i = i + 100) {
                for (let a = 9; a <= 14; a++) {
                    this.CardPicture[cardPicture] = 'cards/' + face + '/' + this.IntToString(i + a) + '.gif';
                    cardPicture++;
                }
            }
        }
        let back = await this.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        let grab = await this.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.CardPicture[0] = 'cards/' + back + '/' + this.IntToString(grab) + '.gif';
        this.CardP[0].image = this.CardPicture[0];
        this.LoadFiles();
    }

    LoadCards(begin: boolean, sound: boolean) {
        if (this.Players === 4) {
            for (let i = 1; i < 33; i++) {
                if (!begin) {
                    if (this.k[i] === 11) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 12) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 13) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 14) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 15) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 16) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 17) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 18) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 21) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 22) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 23) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 24) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 25) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 26) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 27) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 28) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 31) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 32) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 33) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 34) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 35) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 36) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 37) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 38) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                    if (this.k[i] === 41) {
                        this.CardP[i].image = this.CardPicture[25];
                    }
                    if (this.k[i] === 42) {
                        this.CardP[i].image = this.CardPicture[26];
                    }
                    if (this.k[i] === 43) {
                        this.CardP[i].image = this.CardPicture[27];
                    }
                    if (this.k[i] === 44) {
                        this.CardP[i].image = this.CardPicture[28];
                    }
                    if (this.k[i] === 45) {
                        this.CardP[i].image = this.CardPicture[29];
                    }
                    if (this.k[i] === 46) {
                        this.CardP[i].image = this.CardPicture[30];
                    }
                    if (this.k[i] === 47) {
                        this.CardP[i].image = this.CardPicture[31];
                    }
                    if (this.k[i] === 48) {
                        this.CardP[i].image = this.CardPicture[32];
                    }
                } else {
                    this.ConvertCard(this.k[i], i);
                }
                if (begin) {
                    if (this.P1puska > 0) {
                        this.ConvertCard(this.P1puska, 33);
                    }
                    if (this.P2puska > 0) {
                        this.ConvertCard(this.P2puska, 34);
                    }
                    if (this.P3puska > 0) {
                        this.ConvertCard(this.P3puska, 35);
                    }
                    if (this.P4puska > 0) {
                        this.ConvertCard(this.P4puska, 36);
                    }
                }
            }
        } else {
            for (let i = 1; i < 25; i++) {
                if (!begin) {
                    if (this.k[i] === 13) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 14) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 15) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 16) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 17) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 18) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 23) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 24) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 25) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 26) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 27) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 28) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 33) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 34) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 35) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 36) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 37) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 38) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 43) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 44) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 45) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 46) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 47) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 48) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                } else {
                    this.ConvertCard(this.k[i], i);
                }
                if (begin) {
                    if (this.P1puska > 0) {
                        this.ConvertCard(this.P1puska, 33);
                    }
                    if (this.P2puska > 0) {
                        this.ConvertCard(this.P2puska, 34);
                    }
                    if (this.P3puska > 0) {
                        this.ConvertCard(this.P3puska, 35);
                    }
                    if (this.P4puska > 0) {
                        this.ConvertCard(this.P4puska, 36);
                    }
                }
            }
        }
        if (GlobalVariables.Zvuk === 1 && begin === false && sound === true) {
            //    await this.props.m_pwin.m_sndSound.PlayFile('rand_cards', true);
        }
        if (GlobalVariables.Zvuk === 1 && begin === false && sound === true) {
            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        }
    }

    DrawPause() {
        if (!this.watch && this.OP) {
            if (this.Players === 4) {
                if (
                    this.OP &&
                    (this.P2OP ||
                        this.wait_opplayers[2] ||
                        this.P3OP ||
                        this.wait_opplayers[3] ||
                        this.P4OP ||
                        this.wait_opplayers[4] ||
                        !this.Server)
                ) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        if (this.pause === 0) {
                            return (
                                <CPicture
                                    image={this.p_bmp}
                                    m_x={this.pause_rect.left}
                                    m_y={this.pause_rect.top}
                                    m_Width={20}
                                    m_Height={20}
                                    cursor={this.cursor}
                                    key={'pause'}
                                />
                            );
                        }
                    }
                }
            }
            if (this.Players === 3) {
                if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        if (this.pause === 0) {
                            return (
                                <CPicture
                                    image={this.p_bmp}
                                    m_x={this.pause_rect.left}
                                    m_y={this.pause_rect.top}
                                    m_Width={20}
                                    m_Height={20}
                                    cursor={this.cursor}
                                    key={'pause'}
                                />
                            );
                        }
                    }
                }
            }
            if (this.Players === 2) {
                if (this.P2OP || this.wait_opplayers[2] || !this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        if (this.pause === 0) {
                            return (
                                <CPicture
                                    image={this.p_bmp}
                                    m_x={this.pause_rect.left}
                                    m_y={this.pause_rect.top}
                                    m_Width={20}
                                    m_Height={20}
                                    cursor={this.cursor}
                                    key={'pause'}
                                />
                            );
                        }
                    }
                }
            }
        }
        return null;
    }

    DrawTimer() {
        let res = [];
        if (!this.watch && this.OP) {
            if (this.Players === 4) {
                if (
                    this.OP &&
                    (this.P2OP ||
                        this.wait_opplayers[2] ||
                        this.P3OP ||
                        this.wait_opplayers[3] ||
                        this.P4OP ||
                        this.wait_opplayers[4] ||
                        !this.Server)
                ) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                    if (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                }
            }
            if (this.Players === 3) {
                if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || !this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                    if (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 3 && (this.BrPas !== 2 || this.Naddavane === 0)) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                }
            }
            if (this.Players === 2) {
                if (this.P2OP || this.wait_opplayers[2] || !this.Server) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                    if (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 2 && (this.BrPas !== 1 || this.Naddavane === 0)) {
                        res.push(
                            this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer')
                        );
                    }
                }
            }
            if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed !== 1 && !this.Move) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    res.push(this.DrawBorderText('Pause' + ': ' + this.pause_player, 670, this.pause_rect.top, 'DrawTimer'));
                }
            }
        }
        return res;
    }

    DeleteCard(card: number) {
        if (card > 0 && card < 9) {
            for (let i = card; i < 8; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 8; i > 0; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 8 && card < 17) {
            for (let i = card; i < 16; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 16; i > 8; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 16 && card < 25) {
            for (let i = card; i < 24; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 24; i > 16; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 24 && card < 33) {
            for (let i = card; i < 32; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 32; i > 24; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
    }

    ObiaviavaneTerci(player: number, terca: number, pos: number, zvuk: boolean) {
        let res = this.DrawObiaviavane(player, terca + 100, pos);
        let position = 'center';
        if (this.KoiENaRed === 2) {
            position = 'right';
        }
        if (this.KoiENaRed === 4) {
            position = 'left';
        }
        if (GlobalVariables.Zvuk === 1 && zvuk === true) {
            if (terca > 0 && terca < 7) {
                this.props.m_pwin.m_sndSound.Play(11, position, this.stereo, this.pls[player]);
            }
            if (terca === 7) {
                this.props.m_pwin.m_sndSound.Play(16, position, this.stereo, this.pls[player]);
            }
            if (terca > 10 && terca < 16) {
                this.props.m_pwin.m_sndSound.Play(12, position, this.stereo, this.pls[player]);
            }
            if (terca === 16) {
                this.props.m_pwin.m_sndSound.Play(17, position, this.stereo, this.pls[player]);
            }
            if (terca > 20 && terca < 30) {
                this.props.m_pwin.m_sndSound.Play(13, position, this.stereo, this.pls[player]);
            }
            if (terca > 30 && terca < 37) {
                this.props.m_pwin.m_sndSound.Play(24, position, this.stereo, this.pls[player]);
            }
            if (terca === 37) {
                this.props.m_pwin.m_sndSound.Play(18, position, this.stereo, this.pls[player]);
            }
        }
        return res;
    }

    CheckCard(card: number) {
        let h: number;
        if (this.Players === 4) {
            for (h = 1; h < 33; h++) {
                if (this.k[h] === card) {
                    return true;
                }
            }
        }
        if (this.Players === 3) {
            for (h = 1; h < 25; h++) {
                if (this.k[h] === card) {
                    return true;
                }
            }
        }
        if (this.Players === 2) {
            for (h = 1; h < 17; h++) {
                if (this.k[h] === card) {
                    return true;
                }
            }
        }
        return false;
    }

    DrawObiaviavane(player: number, anons: number, pos: number) {
        let text: string;
        text = this.GameName(anons);
        if (anons === 10) {
            text = this.GetLanguageText('Belote');
        }
        if (anons > 100 && anons < 107) {
            text = this.GetLanguageText('Run of three');
        }
        if (anons === 107) {
            text = '2 ' + this.GetLanguageText('Run of threes');
        }
        if (anons > 110 && anons < 116) {
            text = '50';
        }
        if (anons === 116) {
            text = '2*50';
        }
        if (anons > 120 && anons < 130) {
            text = '100';
        }
        if (anons > 130 && anons < 137) {
            text = this.GetLanguageText('Four of a kind');
        }
        if (anons === 137) {
            text = '2 ' + this.GetLanguageText('Four of a kind');
            if (this.language === 'Bulgarian') {
                text = this.GetLanguageText('2 Four of a kind');
            }
        }
        if (anons >= 0 && anons < 10) {
            return this.DrawObiaviavanePNG(this.PosNaddavane[player].x, this.PosNaddavane[player].y, text);
        }
        if (anons === 10) {
            return this.DrawObiaviavanePNG(this.PosBelot[player].x, this.PosBelot[player].y, text);
        }
        if (anons >= 100) {
            return this.DrawObiaviavanePNG(this.PosTerci[player][pos].x, this.PosTerci[player][pos].y, text);
        }
    }

    ObiaviavaneBelot(player: number, zvuk: boolean) {
        this.Belot[player][1] = 1;
        let res = this.DrawObiaviavane(player, 10, 0);
        let position = 'center';
        if (this.KoiENaRed === 2) {
            position = 'right';
        }
        if (this.KoiENaRed === 4) {
            position = 'left';
        }
        if (GlobalVariables.Zvuk === 1) {
            if (zvuk === true) {
                this.props.m_pwin.m_sndSound.Play(1, position, this.stereo, this.pls[player]);
            }
        }
        return res;
    }

    CheckBelot(card: number, player: number) {
        let h: number;
        if (this.Naddavane < 5) {
            if (card === 103) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 104) {
                        return true;
                    }
                }
            }
            if (card === 104) {
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.k[h] === 103) {
                        return true;
                    }
                }
            }
        }
        if (
            (this.KoiPuskaParvi === 1 && player === 1) ||
            (this.KoiPuskaParvi === 2 && player === 2) ||
            (this.KoiPuskaParvi === 3 && player === 3) ||
            (this.KoiPuskaParvi === 4 && player === 4) ||
            (card > 10 && card < 20 && this.ParviPuska > 10 && this.ParviPuska < 20) ||
            (card > 20 && card < 30 && this.ParviPuska > 20 && this.ParviPuska < 30) ||
            (card > 30 && card < 40 && this.ParviPuska > 30 && this.ParviPuska < 40) ||
            (card > 40 && card < 50 && this.ParviPuska > 40 && this.ParviPuska < 50)
        ) {
            if (this.Naddavane === 6) {
                if (card === 13) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 14) {
                            return true;
                        }
                    }
                }
                if (card === 14) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 13) {
                            return true;
                        }
                    }
                }
                if (card === 23) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 24) {
                            return true;
                        }
                    }
                }
                if (card === 24) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 23) {
                            return true;
                        }
                    }
                }
                if (card === 33) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 34) {
                            return true;
                        }
                    }
                }
                if (card === 34) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 33) {
                            return true;
                        }
                    }
                }
                if (card === 43) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 44) {
                            return true;
                        }
                    }
                }
                if (card === 44) {
                    for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                        if (this.k[h] === 43) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    CheckCard3(card: number, player: number, full: boolean): boolean {
        let h;
        if (full === true) {
            for (h = 1 + (player - 1) * 8; h < 9 + (player - 1) * 8; h++) {
                if (this.k[h] === card) {
                    return true;
                }
            }
        } else {
            for (h = 1 + (player - 1) * 8; h < 6 + (player - 1) * 8; h++) {
                if (this.k[h] === card) {
                    return true;
                }
            }
        }
        return false;
    }

    CheckTerci(full: boolean, player: number) {
        this.PTerci[player][1] = 0;
        this.PTerci[player][2] = 0;
        if (full === true && this.Naddavane !== 5) {
            if (
                this.CheckCard3(18, player, true) === true &&
                this.CheckCard3(28, player, true) === true &&
                this.CheckCard3(38, player, true) === true &&
                this.CheckCard3(48, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 34;
                } else {
                    this.PTerci[player][1] = 34;
                }
            }
            if (
                this.CheckCard3(17, player, true) === true &&
                this.CheckCard3(27, player, true) === true &&
                this.CheckCard3(37, player, true) === true &&
                this.CheckCard3(47, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 32;
                } else {
                    this.PTerci[player][1] = 32;
                }
            }
            if (
                this.CheckCard3(16, player, true) === true &&
                this.CheckCard3(26, player, true) === true &&
                this.CheckCard3(36, player, true) === true &&
                this.CheckCard3(46, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 31;
                } else {
                    this.PTerci[player][1] = 31;
                }
            }
            if (
                this.CheckCard3(15, player, true) === true &&
                this.CheckCard3(25, player, true) === true &&
                this.CheckCard3(35, player, true) === true &&
                this.CheckCard3(45, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 36;
                } else {
                    this.PTerci[player][1] = 36;
                }
            }
            if (
                this.CheckCard3(14, player, true) === true &&
                this.CheckCard3(24, player, true) === true &&
                this.CheckCard3(34, player, true) === true &&
                this.CheckCard3(44, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 33;
                } else {
                    this.PTerci[player][1] = 33;
                }
            }
            if (
                this.CheckCard3(13, player, true) === true &&
                this.CheckCard3(23, player, true) === true &&
                this.CheckCard3(33, player, true) === true &&
                this.CheckCard3(43, player, true) === true
            ) {
                if (this.PTerci[player][0] === 0) {
                    this.PTerci[player][0] = 35;
                } else {
                    this.PTerci[player][1] = 35;
                }
            }
            /////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////new//////////////////////////
            if (this.PTerci[player][0] === 0) {
                if (
                    this.CheckCard3(11, player, true) === true &&
                    this.CheckCard3(12, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(14, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 1;
                    } else {
                        this.PTerci[player][1] = 1;
                    }
                }
                if (
                    this.CheckCard3(21, player, true) === true &&
                    this.CheckCard3(22, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(24, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 1;
                    } else {
                        this.PTerci[player][1] = 1;
                    }
                }
                if (
                    this.CheckCard3(31, player, true) === true &&
                    this.CheckCard3(32, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(34, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 1;
                    } else {
                        this.PTerci[player][1] = 1;
                    }
                }
                if (
                    this.CheckCard3(41, player, true) === true &&
                    this.CheckCard3(42, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(44, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 1;
                    } else {
                        this.PTerci[player][1] = 1;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(12, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(15, player, true) === false &&
                    this.CheckCard3(11, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 2;
                    } else {
                        this.PTerci[player][1] = 2;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(22, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(25, player, true) === false &&
                    this.CheckCard3(21, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 2;
                    } else {
                        this.PTerci[player][1] = 2;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(32, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(35, player, true) === false &&
                    this.CheckCard3(31, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 2;
                    } else {
                        this.PTerci[player][1] = 2;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(42, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(45, player, true) === false &&
                    this.CheckCard3(41, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 2;
                    } else {
                        this.PTerci[player][1] = 2;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(16, player, true) === false &&
                    this.CheckCard3(12, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 3;
                    } else {
                        this.PTerci[player][1] = 3;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(26, player, true) === false &&
                    this.CheckCard3(22, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 3;
                    } else {
                        this.PTerci[player][1] = 3;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(36, player, true) === false &&
                    this.CheckCard3(32, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 3;
                    } else {
                        this.PTerci[player][1] = 3;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(46, player, true) === false &&
                    this.CheckCard3(42, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 3;
                    } else {
                        this.PTerci[player][1] = 3;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(17, player, true) === false &&
                    this.CheckCard3(13, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 4;
                    } else {
                        this.PTerci[player][1] = 4;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(27, player, true) === false &&
                    this.CheckCard3(23, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 4;
                    } else {
                        this.PTerci[player][1] = 4;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(37, player, true) === false &&
                    this.CheckCard3(33, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 4;
                    } else {
                        this.PTerci[player][1] = 4;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(47, player, true) === false &&
                    this.CheckCard3(43, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 4;
                    } else {
                        this.PTerci[player][1] = 4;
                    }
                }
                if (
                    this.CheckCard3(17, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(18, player, true) === false &&
                    this.CheckCard3(14, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 5;
                    } else {
                        this.PTerci[player][1] = 5;
                    }
                }
                if (
                    this.CheckCard3(27, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(28, player, true) === false &&
                    this.CheckCard3(24, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 5;
                    } else {
                        this.PTerci[player][1] = 5;
                    }
                }
                if (
                    this.CheckCard3(37, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(38, player, true) === false &&
                    this.CheckCard3(34, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 5;
                    } else {
                        this.PTerci[player][1] = 5;
                    }
                }
                if (
                    this.CheckCard3(47, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(48, player, true) === false &&
                    this.CheckCard3(44, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 5;
                    } else {
                        this.PTerci[player][1] = 5;
                    }
                }
                if (
                    this.CheckCard3(17, player, true) === true &&
                    this.CheckCard3(18, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(15, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 6;
                    } else {
                        this.PTerci[player][1] = 6;
                    }
                }
                if (
                    this.CheckCard3(27, player, true) === true &&
                    this.CheckCard3(28, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(25, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 6;
                    } else {
                        this.PTerci[player][1] = 6;
                    }
                }
                if (
                    this.CheckCard3(37, player, true) === true &&
                    this.CheckCard3(38, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(35, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 6;
                    } else {
                        this.PTerci[player][1] = 6;
                    }
                }
                if (
                    this.CheckCard3(47, player, true) === true &&
                    this.CheckCard3(48, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(45, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 6;
                    } else {
                        this.PTerci[player][1] = 6;
                    }
                }
                if (
                    this.CheckCard3(11, player, true) === true &&
                    this.CheckCard3(12, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(15, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 11;
                    } else {
                        this.PTerci[player][1] = 11;
                    }
                }
                if (
                    this.CheckCard3(21, player, true) === true &&
                    this.CheckCard3(22, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(25, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 11;
                    } else {
                        this.PTerci[player][1] = 11;
                    }
                }
                if (
                    this.CheckCard3(31, player, true) === true &&
                    this.CheckCard3(32, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(35, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 11;
                    } else {
                        this.PTerci[player][1] = 11;
                    }
                }
                if (
                    this.CheckCard3(41, player, true) === true &&
                    this.CheckCard3(42, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(45, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 11;
                    } else {
                        this.PTerci[player][1] = 11;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(12, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(16, player, true) === false &&
                    this.CheckCard3(11, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 12;
                    } else {
                        this.PTerci[player][1] = 12;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(22, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(26, player, true) === false &&
                    this.CheckCard3(21, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 12;
                    } else {
                        this.PTerci[player][1] = 12;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(32, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(36, player, true) === false &&
                    this.CheckCard3(31, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 12;
                    } else {
                        this.PTerci[player][1] = 12;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(42, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(46, player, true) === false &&
                    this.CheckCard3(41, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 12;
                    } else {
                        this.PTerci[player][1] = 12;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(13, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(17, player, true) === false &&
                    this.CheckCard3(12, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 13;
                    } else {
                        this.PTerci[player][1] = 13;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(23, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(27, player, true) === false &&
                    this.CheckCard3(22, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 13;
                    } else {
                        this.PTerci[player][1] = 13;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(33, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(37, player, true) === false &&
                    this.CheckCard3(32, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 13;
                    } else {
                        this.PTerci[player][1] = 13;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(43, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(47, player, true) === false &&
                    this.CheckCard3(42, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 13;
                    } else {
                        this.PTerci[player][1] = 13;
                    }
                }
                if (
                    this.CheckCard3(14, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(17, player, true) === true &&
                    this.CheckCard3(18, player, true) === false &&
                    this.CheckCard3(13, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 14;
                    } else {
                        this.PTerci[player][1] = 14;
                    }
                }
                if (
                    this.CheckCard3(24, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(27, player, true) === true &&
                    this.CheckCard3(28, player, true) === false &&
                    this.CheckCard3(23, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 14;
                    } else {
                        this.PTerci[player][1] = 14;
                    }
                }
                if (
                    this.CheckCard3(34, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(37, player, true) === true &&
                    this.CheckCard3(38, player, true) === false &&
                    this.CheckCard3(33, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 14;
                    } else {
                        this.PTerci[player][1] = 14;
                    }
                }
                if (
                    this.CheckCard3(44, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(47, player, true) === true &&
                    this.CheckCard3(48, player, true) === false &&
                    this.CheckCard3(43, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 14;
                    } else {
                        this.PTerci[player][1] = 14;
                    }
                }
                if (
                    this.CheckCard3(17, player, true) === true &&
                    this.CheckCard3(15, player, true) === true &&
                    this.CheckCard3(16, player, true) === true &&
                    this.CheckCard3(18, player, true) === true &&
                    this.CheckCard3(14, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 15;
                    } else {
                        this.PTerci[player][1] = 15;
                    }
                }
                if (
                    this.CheckCard3(27, player, true) === true &&
                    this.CheckCard3(25, player, true) === true &&
                    this.CheckCard3(26, player, true) === true &&
                    this.CheckCard3(28, player, true) === true &&
                    this.CheckCard3(24, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 15;
                    } else {
                        this.PTerci[player][1] = 15;
                    }
                }
                if (
                    this.CheckCard3(37, player, true) === true &&
                    this.CheckCard3(35, player, true) === true &&
                    this.CheckCard3(36, player, true) === true &&
                    this.CheckCard3(38, player, true) === true &&
                    this.CheckCard3(34, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 15;
                    } else {
                        this.PTerci[player][1] = 15;
                    }
                }
                if (
                    this.CheckCard3(47, player, true) === true &&
                    this.CheckCard3(45, player, true) === true &&
                    this.CheckCard3(46, player, true) === true &&
                    this.CheckCard3(48, player, true) === true &&
                    this.CheckCard3(44, player, true) === false
                ) {
                    if (this.PTerci[player][0] === 0) {
                        this.PTerci[player][0] = 15;
                    } else {
                        this.PTerci[player][1] = 15;
                    }
                }
                let tpl = 0;
                if (this.PTerci[player][0] !== 0) {
                    tpl = 1;
                }
                if (this.PTerci[player][1] === 0) {
                    if (
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard3(16, player, true) === true &&
                        this.CheckCard3(17, player, true) === true &&
                        this.CheckCard3(18, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 24;
                    }
                    if (
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard3(26, player, true) === true &&
                        this.CheckCard3(27, player, true) === true &&
                        this.CheckCard3(28, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 24;
                    }
                    if (
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard3(36, player, true) === true &&
                        this.CheckCard3(37, player, true) === true &&
                        this.CheckCard3(38, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 24;
                    }
                    if (
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard3(46, player, true) === true &&
                        this.CheckCard3(47, player, true) === true &&
                        this.CheckCard3(48, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 24;
                    }
                    if (
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard3(13, player, true) === true &&
                        this.CheckCard3(16, player, true) === true &&
                        this.CheckCard3(17, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 23;
                    }
                    if (
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard3(23, player, true) === true &&
                        this.CheckCard3(26, player, true) === true &&
                        this.CheckCard3(27, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 23;
                    }
                    if (
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard3(33, player, true) === true &&
                        this.CheckCard3(36, player, true) === true &&
                        this.CheckCard3(37, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 23;
                    }
                    if (
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard3(43, player, true) === true &&
                        this.CheckCard3(46, player, true) === true &&
                        this.CheckCard3(47, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 23;
                    }
                    if (
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard3(12, player, true) === true &&
                        this.CheckCard3(13, player, true) === true &&
                        this.CheckCard3(15, player, true) === true &&
                        this.CheckCard3(16, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 22;
                    }
                    if (
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard3(22, player, true) === true &&
                        this.CheckCard3(23, player, true) === true &&
                        this.CheckCard3(25, player, true) === true &&
                        this.CheckCard3(26, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 22;
                    }
                    if (
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard3(32, player, true) === true &&
                        this.CheckCard3(33, player, true) === true &&
                        this.CheckCard3(35, player, true) === true &&
                        this.CheckCard3(36, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 22;
                    }
                    if (
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard3(42, player, true) === true &&
                        this.CheckCard3(43, player, true) === true &&
                        this.CheckCard3(45, player, true) === true &&
                        this.CheckCard3(46, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 22;
                    }
                    if (
                        this.CheckCard3(11, player, true) === true &&
                        this.CheckCard3(12, player, true) === true &&
                        this.CheckCard3(13, player, true) === true &&
                        this.CheckCard3(14, player, true) === true &&
                        this.CheckCard3(15, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 21;
                    }
                    if (
                        this.CheckCard3(21, player, true) === true &&
                        this.CheckCard3(22, player, true) === true &&
                        this.CheckCard3(23, player, true) === true &&
                        this.CheckCard3(24, player, true) === true &&
                        this.CheckCard3(25, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 21;
                    }
                    if (
                        this.CheckCard3(31, player, true) === true &&
                        this.CheckCard3(32, player, true) === true &&
                        this.CheckCard3(33, player, true) === true &&
                        this.CheckCard3(34, player, true) === true &&
                        this.CheckCard3(35, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 21;
                    }
                    if (
                        this.CheckCard3(41, player, true) === true &&
                        this.CheckCard3(42, player, true) === true &&
                        this.CheckCard3(43, player, true) === true &&
                        this.CheckCard3(44, player, true) === true &&
                        this.CheckCard3(45, player, true) === true
                    ) {
                        this.PTerci[player][tpl] = 21;
                    }
                }
            } else {
                if (this.PTerci[player][1] === 0) {
                    if (this.PTerci[player][0] === 31) {
                        if (this.CheckCard3(11, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(21, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(31, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(41, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (
                            this.CheckCard3(11, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(21, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(31, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(41, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                    }
                    if (this.PTerci[player][0] === 32) {
                        if (this.CheckCard3(11, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(21, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(31, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(41, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (
                            this.CheckCard3(11, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(21, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(31, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(41, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                    }
                    if (this.PTerci[player][0] === 33) {
                        if (this.CheckCard3(11, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(21, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(31, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(41, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true) && this.CheckCard3(17, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true) && this.CheckCard3(27, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true) && this.CheckCard3(37, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true) && this.CheckCard3(47, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(18, player, true) && this.CheckCard3(16, player, true) && this.CheckCard3(17, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(28, player, true) && this.CheckCard3(26, player, true) && this.CheckCard3(27, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(38, player, true) && this.CheckCard3(36, player, true) && this.CheckCard3(37, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(48, player, true) && this.CheckCard3(46, player, true) && this.CheckCard3(47, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(17, player, true) &&
                            this.CheckCard3(18, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(27, player, true) &&
                            this.CheckCard3(28, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(37, player, true) &&
                            this.CheckCard3(38, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(47, player, true) &&
                            this.CheckCard3(48, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                    }
                    if (this.PTerci[player][0] === 34) {
                        if (this.CheckCard3(11, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(21, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(31, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(41, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 3;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(17, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(27, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(37, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(47, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (
                            this.CheckCard3(11, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(21, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(31, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(41, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 12;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 13;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(17, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 14;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(27, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 14;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(37, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 14;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(47, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 14;
                        }
                    }
                    if (this.PTerci[player][0] === 35) {
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true)) {
                            this.PTerci[player][1] = 4;
                        }
                        if (this.CheckCard3(17, player, true) && this.CheckCard3(15, player, true) && this.CheckCard3(16, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(27, player, true) && this.CheckCard3(25, player, true) && this.CheckCard3(26, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(37, player, true) && this.CheckCard3(35, player, true) && this.CheckCard3(36, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(47, player, true) && this.CheckCard3(45, player, true) && this.CheckCard3(46, player, true)) {
                            this.PTerci[player][1] = 5;
                        }
                        if (this.CheckCard3(18, player, true) && this.CheckCard3(16, player, true) && this.CheckCard3(17, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(28, player, true) && this.CheckCard3(26, player, true) && this.CheckCard3(27, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(38, player, true) && this.CheckCard3(36, player, true) && this.CheckCard3(37, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(48, player, true) && this.CheckCard3(46, player, true) && this.CheckCard3(47, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(17, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 15;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(27, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 15;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(37, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 15;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(47, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 15;
                        }
                        if (
                            this.CheckCard3(15, player, true) &&
                            this.CheckCard3(16, player, true) &&
                            this.CheckCard3(17, player, true) &&
                            this.CheckCard3(18, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(25, player, true) &&
                            this.CheckCard3(26, player, true) &&
                            this.CheckCard3(27, player, true) &&
                            this.CheckCard3(28, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(35, player, true) &&
                            this.CheckCard3(36, player, true) &&
                            this.CheckCard3(37, player, true) &&
                            this.CheckCard3(38, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                        if (
                            this.CheckCard3(45, player, true) &&
                            this.CheckCard3(46, player, true) &&
                            this.CheckCard3(47, player, true) &&
                            this.CheckCard3(48, player, true)
                        ) {
                            this.PTerci[player][1] = 16;
                        }
                    }
                    if (this.PTerci[player][0] === 36) {
                        if (this.CheckCard3(11, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(21, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(31, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(41, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 1;
                        }
                        if (this.CheckCard3(14, player, true) && this.CheckCard3(12, player, true) && this.CheckCard3(13, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(24, player, true) && this.CheckCard3(22, player, true) && this.CheckCard3(23, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(34, player, true) && this.CheckCard3(32, player, true) && this.CheckCard3(33, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(44, player, true) && this.CheckCard3(42, player, true) && this.CheckCard3(43, player, true)) {
                            this.PTerci[player][1] = 2;
                        }
                        if (this.CheckCard3(18, player, true) && this.CheckCard3(16, player, true) && this.CheckCard3(17, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(28, player, true) && this.CheckCard3(26, player, true) && this.CheckCard3(27, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(38, player, true) && this.CheckCard3(36, player, true) && this.CheckCard3(37, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (this.CheckCard3(48, player, true) && this.CheckCard3(46, player, true) && this.CheckCard3(47, player, true)) {
                            this.PTerci[player][1] = 6;
                        }
                        if (
                            this.CheckCard3(11, player, true) &&
                            this.CheckCard3(12, player, true) &&
                            this.CheckCard3(13, player, true) &&
                            this.CheckCard3(14, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(21, player, true) &&
                            this.CheckCard3(22, player, true) &&
                            this.CheckCard3(23, player, true) &&
                            this.CheckCard3(24, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(31, player, true) &&
                            this.CheckCard3(32, player, true) &&
                            this.CheckCard3(33, player, true) &&
                            this.CheckCard3(34, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                        if (
                            this.CheckCard3(41, player, true) &&
                            this.CheckCard3(42, player, true) &&
                            this.CheckCard3(43, player, true) &&
                            this.CheckCard3(44, player, true)
                        ) {
                            this.PTerci[player][1] = 11;
                        }
                    }
                }
            }
            ///////////////////////////new end//////////////////////
        } else {
            if (
                this.CheckCard3(18, player, false) === true &&
                this.CheckCard3(28, player, false) === true &&
                this.CheckCard3(38, player, false) === true &&
                this.CheckCard3(48, player, false) === true
            ) {
                return 34;
            }
            if (
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(47, player, false) === true
            ) {
                return 32;
            }
            if (
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(46, player, false) === true
            ) {
                return 31;
            }
            if (
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(45, player, false) === true
            ) {
                return 36;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(44, player, false) === true
            ) {
                return 33;
            }
            if (
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(43, player, false) === true
            ) {
                return 35;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(18, player, false) === true
            ) {
                return 24;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(28, player, false) === true
            ) {
                return 24;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(38, player, false) === true
            ) {
                return 24;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(47, player, false) === true &&
                this.CheckCard3(48, player, false) === true
            ) {
                return 24;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(17, player, false) === true
            ) {
                return 23;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(27, player, false) === true
            ) {
                return 23;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(37, player, false) === true
            ) {
                return 23;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(47, player, false) === true
            ) {
                return 23;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true
            ) {
                return 22;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true
            ) {
                return 22;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true
            ) {
                return 22;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true
            ) {
                return 22;
            }
            if (
                this.CheckCard3(11, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true
            ) {
                return 21;
            }
            if (
                this.CheckCard3(21, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true
            ) {
                return 21;
            }
            if (
                this.CheckCard3(31, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true
            ) {
                return 21;
            }
            if (
                this.CheckCard3(41, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true
            ) {
                return 21;
            }
            if (
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(18, player, false) === true
            ) {
                return 15;
            }
            if (
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(28, player, false) === true
            ) {
                return 15;
            }
            if (
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(38, player, false) === true
            ) {
                return 15;
            }
            if (
                this.CheckCard3(47, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(48, player, false) === true
            ) {
                return 15;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(18, player, false) === false
            ) {
                return 14;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(28, player, false) === false
            ) {
                return 14;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(38, player, false) === false
            ) {
                return 14;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(47, player, false) === true &&
                this.CheckCard3(48, player, false) === false
            ) {
                return 14;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(17, player, false) === false
            ) {
                return 13;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(27, player, false) === false
            ) {
                return 13;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(37, player, false) === false
            ) {
                return 13;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(47, player, false) === false
            ) {
                return 13;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === false
            ) {
                return 12;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === false
            ) {
                return 12;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === false
            ) {
                return 12;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === false
            ) {
                return 12;
            }
            if (
                this.CheckCard3(11, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === false
            ) {
                return 11;
            }
            if (
                this.CheckCard3(21, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === false
            ) {
                return 11;
            }
            if (
                this.CheckCard3(31, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === false
            ) {
                return 11;
            }
            if (
                this.CheckCard3(41, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === false
            ) {
                return 11;
            }
            if (
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(18, player, false) === true &&
                this.CheckCard3(16, player, false) === true
            ) {
                return 6;
            }
            if (
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(28, player, false) === true &&
                this.CheckCard3(26, player, false) === true
            ) {
                return 6;
            }
            if (
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(38, player, false) === true &&
                this.CheckCard3(36, player, false) === true
            ) {
                return 6;
            }
            if (
                this.CheckCard3(47, player, false) === true &&
                this.CheckCard3(48, player, false) === true &&
                this.CheckCard3(46, player, false) === true
            ) {
                return 6;
            }
            if (
                this.CheckCard3(17, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(18, player, false) === false &&
                this.CheckCard3(14, player, false) === false
            ) {
                return 5;
            }
            if (
                this.CheckCard3(27, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(28, player, false) === false &&
                this.CheckCard3(24, player, false) === false
            ) {
                return 5;
            }
            if (
                this.CheckCard3(37, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(38, player, false) === false &&
                this.CheckCard3(34, player, false) === false
            ) {
                return 5;
            }
            if (
                this.CheckCard3(47, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(48, player, false) === false &&
                this.CheckCard3(44, player, false) === false
            ) {
                return 5;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(16, player, false) === true &&
                this.CheckCard3(17, player, false) === false &&
                this.CheckCard3(13, player, false) === false
            ) {
                return 4;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(26, player, false) === true &&
                this.CheckCard3(27, player, false) === false &&
                this.CheckCard3(23, player, false) === false
            ) {
                return 4;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(36, player, false) === true &&
                this.CheckCard3(37, player, false) === false &&
                this.CheckCard3(33, player, false) === false
            ) {
                return 4;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(46, player, false) === true &&
                this.CheckCard3(47, player, false) === false &&
                this.CheckCard3(43, player, false) === false
            ) {
                return 4;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(15, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(16, player, false) === false &&
                this.CheckCard3(12, player, false) === false
            ) {
                return 3;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(25, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(26, player, false) === false &&
                this.CheckCard3(22, player, false) === false
            ) {
                return 3;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(35, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(36, player, false) === false &&
                this.CheckCard3(32, player, false) === false
            ) {
                return 3;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(45, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(46, player, false) === false &&
                this.CheckCard3(42, player, false) === false
            ) {
                return 3;
            }
            if (
                this.CheckCard3(14, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(15, player, false) === false &&
                this.CheckCard3(11, player, false) === false
            ) {
                return 2;
            }
            if (
                this.CheckCard3(24, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(25, player, false) === false &&
                this.CheckCard3(21, player, false) === false
            ) {
                return 2;
            }
            if (
                this.CheckCard3(34, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(35, player, false) === false &&
                this.CheckCard3(31, player, false) === false
            ) {
                return 2;
            }
            if (
                this.CheckCard3(44, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(45, player, false) === false &&
                this.CheckCard3(41, player, false) === false
            ) {
                return 2;
            }
            if (
                this.CheckCard3(11, player, false) === true &&
                this.CheckCard3(12, player, false) === true &&
                this.CheckCard3(13, player, false) === true &&
                this.CheckCard3(14, player, false) === false
            ) {
                return 1;
            }
            if (
                this.CheckCard3(21, player, false) === true &&
                this.CheckCard3(22, player, false) === true &&
                this.CheckCard3(23, player, false) === true &&
                this.CheckCard3(24, player, false) === false
            ) {
                return 1;
            }
            if (
                this.CheckCard3(31, player, false) === true &&
                this.CheckCard3(32, player, false) === true &&
                this.CheckCard3(33, player, false) === true &&
                this.CheckCard3(34, player, false) === false
            ) {
                return 1;
            }
            if (
                this.CheckCard3(41, player, false) === true &&
                this.CheckCard3(42, player, false) === true &&
                this.CheckCard3(43, player, false) === true &&
                this.CheckCard3(44, player, false) === false
            ) {
                return 1;
            }
        }
        return 0;
    }

    SendGame(msg) {
        this.props.m_pwin.SendGame(msg);
    }

    SendKoiENaRed() {
        if (this.Players === 4) {
            if (this.P2OP) {
                let koi = '4';
                if (this.KoiENaRed > 1) {
                    koi = this.IntToString(this.KoiENaRed - 1);
                }
                this.SendGame('$|b|' + koi);
            }
            if (this.P3OP) {
                let koi: string = this.IntToString(this.KoiENaRed + 2);
                if (this.KoiENaRed > 2) {
                    koi = this.IntToString(this.KoiENaRed - 2);
                }
                this.SendGame('$|e|' + koi);
            }
            if (this.P4OP) {
                let koi = this.IntToString(this.KoiENaRed + 1);
                if (this.KoiENaRed === 4) {
                    koi = this.IntToString(1);
                }
                this.SendGame('$|h|' + koi);
            }
        }
        if (this.Players === 3) {
            if (this.P2OP) {
                let koi = '3';
                if (this.KoiENaRed > 1) {
                    koi = this.IntToString(this.KoiENaRed - 1);
                }
                this.SendGame('$|b|' + koi);
            }
            if (this.P3OP) {
                let koi = '2';
                if (this.KoiENaRed === 1) {
                    koi = '2';
                }
                if (this.KoiENaRed === 2) {
                    koi = '3';
                }
                if (this.KoiENaRed === 3) {
                    koi = '1';
                }
                this.SendGame('$|e|' + koi);
            }
        }
        if (this.Players === 2) {
            if (this.P2OP) {
                let koi = '2';
                if (this.KoiZapochva > 1) {
                    koi = '1';
                }
                this.SendGame('$|b|' + koi);
            }
        }
        if (this.HaveWatch) {
            this.SendGame('$|*|a|' + this.IntToString(this.KoiENaRed));
        }
    }

    DrawCards() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        var res = [];
        let a = 25;
        if (this.Faza !== 6 || this.Razdavane1 === true) {
            if (this.Players === 4) {
                a = 33;
            }
            for (let i = 1; i < a; i++) {
                if (this.k[i] > 0) {
                    if (this.CardsPoint[i].x !== this.CardsPos[0].x && this.CardsPoint[i].y !== this.CardsPos[0].y) {
                        if (i > 8) {
                            res.push(
                                <DrawCard
                                    image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                    m_x={this.CardsPoint[i].x}
                                    m_y={this.CardsPoint[i].y}
                                    key={'card' + i}
                                    cursor={this.cursor}
                                />
                            );
                        } else {
                            res.push(
                                <DrawCard
                                    image={this.CardP[i].image}
                                    m_x={this.CardsPoint[i].x - ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8}
                                    m_y={this.CardsPoint[i].y}
                                    cursor={this.cursor}
                                    key={'card' + i}
                                    big={true}
                                />
                            );
                        }
                    }
                }
            }
            if (
                this.Razdavane1 === true ||
                (this.Faza > 0 && this.Faza < 3) ||
                this.Razdavane2 === true ||
                (this.Players === 2 && this.Faza > 0 && this.Faza < 7)
            ) {
                for(let i=0;i<this.showrcard;i++) {
                    res.push(
                        <DrawCard
                            image={this.CardP[0].image}
                            m_x={this.CardsPoint[0].x - (i+1) * 0.25}
                            m_y={this.CardsPoint[0].y - (i+1) * 0.5}
                            cursor={this.cursor}
                            key={'card_'+(i+1).toString()}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 1) {
                if (this.P1puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
                if (this.P2puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
                if (this.P3puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[35].image}
                            m_x={this.CardsPoint[35].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[35].y}
                            cursor={this.cursor}
                            key={'card35'}
                            big={true}
                        />
                    );
                }
                if (this.P4puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[36].image}
                            m_x={this.CardsPoint[36].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[36].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card36'}
                            big={true}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 2) {
                if (this.P2puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
                if (this.P3puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[35].image}
                            m_x={this.CardsPoint[35].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[35].y}
                            cursor={this.cursor}
                            key={'card35'}
                            big={true}
                        />
                    );
                }
                if (this.P4puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[36].image}
                            m_x={this.CardsPoint[36].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[36].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card36'}
                            big={true}
                        />
                    );
                }
                if (this.P1puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 3) {
                if (this.P3puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[35].image}
                            m_x={this.CardsPoint[35].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[35].y}
                            cursor={this.cursor}
                            key={'card35'}
                            big={true}
                        />
                    );
                }
                if (this.P4puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[36].image}
                            m_x={this.CardsPoint[36].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[36].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card36'}
                            big={true}
                        />
                    );
                }
                if (this.P1puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
                if (this.P2puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 4) {
                if (this.P4puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[36].image}
                            m_x={this.CardsPoint[36].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[36].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card36'}
                            big={true}
                        />
                    );
                }
                if (this.P1puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
                if (this.P2puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
                if (this.P3puska > 0) {
                    res.push(
                        <DrawCard
                            image={this.CardP[35].image}
                            m_x={this.CardsPoint[35].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[35].y}
                            cursor={this.cursor}
                            key={'card35'}
                            big={true}
                        />
                    );
                }
            }
            for (let i = 1; i <= this.Players; i++) {
                if (this.Belot[i][1] === 1) {
                    res.push(this.ObiaviavaneBelot(i, false));
                }
            }
            if (this.hand === 0 && this.KoiZapochva > 0) {
                if (this.KoiPuskaParvi > 0 && this.KoiObiaviava >= 0 && this.KoiENaRed > 0) {
                    //FIX https://gitlab.com/alehandros/FavoriteGames/-/issues/60
                    res.push(this.DrawChavka());
                }
                if (this.P1puska > 0) {
                    for (let b = 0; b <= 1; b++) {
                        if (this.PTerci[1][b] > 0) {
                            if (this.terci[1][b]) {
                                if (
                                    !(
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    )
                                ) {
                                    res.push(this.ObiaviavaneTerci(1, this.PTerci[1][b], b, false));
                                }
                                if (b === 0) {
                                    if (
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) {
                                        res.push(this.ObiaviavaneTerci(1, 7, 0, false));
                                    }
                                    if (
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) {
                                        res.push(this.ObiaviavaneTerci(1, 16, 0, false));
                                    }
                                    if (
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    ) {
                                        res.push(this.ObiaviavaneTerci(1, 37, 0, false));
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.P2puska > 0) {
                    for (let b = 0; b <= 1; b++) {
                        if (this.PTerci[2][b] > 0) {
                            if (this.terci[2][b]) {
                                if (
                                    !(
                                        this.PTerci[2][0] > 0 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 7 &&
                                        this.PTerci[2][1] > 0 &&
                                        this.PTerci[2][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[2][0] > 10 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 16 &&
                                        this.PTerci[2][1] > 10 &&
                                        this.PTerci[2][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[2][0] > 30 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 37 &&
                                        this.PTerci[2][1] > 30 &&
                                        this.PTerci[2][1] < 37
                                    )
                                ) {
                                    res.push(this.ObiaviavaneTerci(2, this.PTerci[2][b], b, false));
                                }
                                if (b === 0) {
                                    if (
                                        this.PTerci[2][0] > 0 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 7 &&
                                        this.PTerci[2][1] > 0 &&
                                        this.PTerci[2][1] < 7
                                    ) {
                                        res.push(this.ObiaviavaneTerci(2, 7, 0, false));
                                    }
                                    if (
                                        this.PTerci[2][0] > 10 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 16 &&
                                        this.PTerci[2][1] > 10 &&
                                        this.PTerci[2][1] < 16
                                    ) {
                                        res.push(this.ObiaviavaneTerci(2, 16, 0, false));
                                    }
                                    if (
                                        this.PTerci[2][0] > 30 &&
                                        this.terci[2][0] &&
                                        this.terci[2][1] &&
                                        this.PTerci[2][0] < 37 &&
                                        this.PTerci[2][1] > 30 &&
                                        this.PTerci[2][1] < 37
                                    ) {
                                        res.push(this.ObiaviavaneTerci(2, 37, 0, false));
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.P3puska > 0) {
                    for (let b = 0; b <= 1; b++) {
                        if (this.PTerci[3][b] > 0) {
                            if (this.terci[3][b]) {
                                if (
                                    !(
                                        this.PTerci[3][0] > 0 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 7 &&
                                        this.PTerci[3][1] > 0 &&
                                        this.PTerci[3][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[3][0] > 10 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 16 &&
                                        this.PTerci[3][1] > 10 &&
                                        this.PTerci[3][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[3][0] > 30 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 37 &&
                                        this.PTerci[3][1] > 30 &&
                                        this.PTerci[3][1] < 37
                                    )
                                ) {
                                    res.push(this.ObiaviavaneTerci(3, this.PTerci[3][b], b, false));
                                }
                                if (b === 0) {
                                    if (
                                        this.PTerci[3][0] > 0 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 7 &&
                                        this.PTerci[3][1] > 0 &&
                                        this.PTerci[3][1] < 7
                                    ) {
                                        res.push(this.ObiaviavaneTerci(3, 7, 0, false));
                                    }
                                    if (
                                        this.PTerci[3][0] > 10 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 16 &&
                                        this.PTerci[3][1] > 10 &&
                                        this.PTerci[3][1] < 16
                                    ) {
                                        res.push(this.ObiaviavaneTerci(3, 16, 0, false));
                                    }
                                    if (
                                        this.PTerci[3][0] > 30 &&
                                        this.terci[3][0] &&
                                        this.terci[3][1] &&
                                        this.PTerci[3][0] < 37 &&
                                        this.PTerci[3][1] > 30 &&
                                        this.PTerci[3][1] < 37
                                    ) {
                                        res.push(this.ObiaviavaneTerci(3, 37, 0, false));
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.P4puska > 0) {
                    for (let b = 0; b <= 1; b++) {
                        if (this.PTerci[4][b] > 0) {
                            if (this.terci[4][b]) {
                                if (
                                    !(
                                        this.PTerci[4][0] > 0 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 7 &&
                                        this.PTerci[4][1] > 0 &&
                                        this.PTerci[4][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[4][0] > 10 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 16 &&
                                        this.PTerci[4][1] > 10 &&
                                        this.PTerci[4][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[4][0] > 30 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 37 &&
                                        this.PTerci[4][1] > 30 &&
                                        this.PTerci[4][1] < 37
                                    )
                                ) {
                                    res.push(this.ObiaviavaneTerci(4, this.PTerci[4][b], b, false));
                                }
                                if (b === 0) {
                                    if (
                                        this.PTerci[4][0] > 0 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 7 &&
                                        this.PTerci[4][1] > 0 &&
                                        this.PTerci[4][1] < 7
                                    ) {
                                        res.push(this.ObiaviavaneTerci(4, 7, 0, false));
                                    }
                                    if (
                                        this.PTerci[4][0] > 10 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 16 &&
                                        this.PTerci[4][1] > 10 &&
                                        this.PTerci[4][1] < 16
                                    ) {
                                        res.push(this.ObiaviavaneTerci(4, 16, 0, false));
                                    }
                                    if (
                                        this.PTerci[4][0] > 30 &&
                                        this.terci[4][0] &&
                                        this.terci[4][1] &&
                                        this.PTerci[4][0] < 37 &&
                                        this.PTerci[4][1] > 30 &&
                                        this.PTerci[4][1] < 37
                                    ) {
                                        res.push(this.ObiaviavaneTerci(4, 37, 0, false));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return res;
    }

    ConvertCard(num: number, index: number) {
        if (this.Players === 4) {
            if (this.Naddavane === 1) {
                if (num === 101) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 102) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[5];
                }

                if (num === 21) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 22) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[16];
                }

                if (num === 31) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 32) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[24];
                }

                if (num === 41) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 42) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[29];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[32];
                }
            }
            if (this.Naddavane === 2) {
                if (num === 101) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 102) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[13];
                }

                if (num === 11) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 12) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[8];
                }

                if (num === 31) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 32) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[24];
                }

                if (num === 41) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 42) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[29];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[32];
                }
            }
            if (this.Naddavane === 3) {
                if (num === 101) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 102) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[24];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[21];
                }

                if (num === 11) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 12) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[8];
                }

                if (num === 21) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 22) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[16];
                }

                if (num === 41) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 42) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[29];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[32];
                }
            }
            if (this.Naddavane === 4) {
                if (num === 101) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 102) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[32];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[29];
                }

                if (num === 11) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 12) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[8];
                }

                if (num === 21) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 22) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[16];
                }

                if (num === 31) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 32) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 5) {
                if (num === 11) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 12) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[8];
                }

                if (num === 21) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 22) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[16];
                }

                if (num === 31) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 32) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[24];
                }

                if (num === 41) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 42) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[29];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[32];
                }
            }
            if (this.Naddavane === 6) {
                if (num === 11) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 12) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[5];
                }

                if (num === 21) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 22) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[13];
                }

                if (num === 31) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 32) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[24];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[21];
                }

                if (num === 41) {
                    this.CardP[index].image = this.CardPicture[25];
                }
                if (num === 42) {
                    this.CardP[index].image = this.CardPicture[26];
                }
                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[30];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[31];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[28];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[32];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[27];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[29];
                }
            }
        }
        if (this.Players === 3 || this.Players === 2) {
            if (this.Naddavane === 1) {
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[3];
                }

                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[12];
                }

                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[18];
                }

                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 2) {
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[9];
                }

                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[6];
                }

                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[18];
                }

                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 3) {
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[15];
                }

                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[6];
                }

                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[12];
                }

                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 4) {
                if (num === 103) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 104) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 105) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 106) {
                    this.CardP[index].image = this.CardPicture[24];
                }
                if (num === 107) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 108) {
                    this.CardP[index].image = this.CardPicture[21];
                }

                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[6];
                }

                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[12];
                }

                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[18];
                }
            }
            if (this.Naddavane === 5) {
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[3];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[6];
                }

                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[9];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[12];
                }

                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[15];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[18];
                }

                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[21];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 6) {
                if (num === 13) {
                    this.CardP[index].image = this.CardPicture[4];
                }
                if (num === 14) {
                    this.CardP[index].image = this.CardPicture[5];
                }
                if (num === 15) {
                    this.CardP[index].image = this.CardPicture[2];
                }
                if (num === 16) {
                    this.CardP[index].image = this.CardPicture[6];
                }
                if (num === 17) {
                    this.CardP[index].image = this.CardPicture[1];
                }
                if (num === 18) {
                    this.CardP[index].image = this.CardPicture[3];
                }

                if (num === 23) {
                    this.CardP[index].image = this.CardPicture[10];
                }
                if (num === 24) {
                    this.CardP[index].image = this.CardPicture[11];
                }
                if (num === 25) {
                    this.CardP[index].image = this.CardPicture[8];
                }
                if (num === 26) {
                    this.CardP[index].image = this.CardPicture[12];
                }
                if (num === 27) {
                    this.CardP[index].image = this.CardPicture[7];
                }
                if (num === 28) {
                    this.CardP[index].image = this.CardPicture[9];
                }

                if (num === 33) {
                    this.CardP[index].image = this.CardPicture[16];
                }
                if (num === 34) {
                    this.CardP[index].image = this.CardPicture[17];
                }
                if (num === 35) {
                    this.CardP[index].image = this.CardPicture[14];
                }
                if (num === 36) {
                    this.CardP[index].image = this.CardPicture[18];
                }
                if (num === 37) {
                    this.CardP[index].image = this.CardPicture[13];
                }
                if (num === 38) {
                    this.CardP[index].image = this.CardPicture[15];
                }

                if (num === 43) {
                    this.CardP[index].image = this.CardPicture[22];
                }
                if (num === 44) {
                    this.CardP[index].image = this.CardPicture[23];
                }
                if (num === 45) {
                    this.CardP[index].image = this.CardPicture[20];
                }
                if (num === 46) {
                    this.CardP[index].image = this.CardPicture[24];
                }
                if (num === 47) {
                    this.CardP[index].image = this.CardPicture[19];
                }
                if (num === 48) {
                    this.CardP[index].image = this.CardPicture[21];
                }
            }
        }
    }

    InitGame() {
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        for (let i = 1; i <= 4; i++) {
            this.Belot[i][0] = 0;
        }
        for (let i = 1; i < 5; i++) {
            this.Tactic[i] = 0;
        }
        if ((this.OP === true && this.Server === true) || this.SP === true) {
            this.KoiPuskaParvi = this.KoiENaRed = this.KoiObiaviava = this.KoiZapochva;
        }
        this.Faza = 0;
        this.BrPas = 0;
        this.kapo1 = true;
        this.kapo2 = true;
        this.kapo3 = true;
        this.Naddavane = 0;
        this.Kontra = this.Rekontra = 0;
        this.hand = 0;
        for (let i = 0; i < 8; i++) {
            this.op_belot[i] = false;
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 0; a <= 1; a++) {
                this.terci[i][a] = false;
            }
            for (let a = 1; a < 9; a++) {
                this.Pkazal[i][a] = false;
            }
            for (let a = 1; a < 5; a++) {
                this.Piska[i][a] = false;
                this.Pneiska[i][a] = false;
                this.Pniama[i][a] = false;
            }
            this.PTerci[i][0] = 0;
            this.PTerci[i][1] = 0;
        }
    }

    async Init() {
        for (let i = 0; i < 3; i++) {
            for (let a = 0; a < 100; a++) {
                this.m_orez.orez[i][a] = -10;
            }
        }
        for (let i = 0; i < 5; i++) {
            this.wait_opplayers[i] = false;
        }
        this.Visiashti = 0;
        for (let i = 1; i < 33; i++) {
            this.kd[i] = 0;
        }
        GlobalVariables.OtkritiKarti = 0;
        if (!this.OP) {
            this.Name[1] = await this.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.GetProfileString('Options', 'Name[2]', 'Computer2');
        }
        await this.LoadBitmaps();
        for (let i = 1; i < 5; i++) {
            this.PTerci[i] = [];
            for (let a = 0; a < 2; a++) {
                this.PTerci[i][a] = 0;
            }
        }
        if ((this.OP && this.Server) || this.SP) {
            this.KoiZapochva = this.KoiPuskaParvi = this.KoiENaRed = this.RandKoiZapochva(this.Players);
        }
    }
}

export default CBelot;
