import React, { Component } from 'react';
import { View, Pressable, Switch, StyleSheet, TextInput, Keyboard, ScrollView, Dimensions, Platform } from 'react-native';
import { styles } from '../../css/container';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import Button from '../Components/core/Button';
import GlobalConstants from '../Components/GlobalConstants';
import Text from '../Components/core/Text';
import GlobalVariables from '../Components/GlobalVariables';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import InputField from '~/Components/core/InputField';

class SettingsScreen extends Component<BaseDlgProps> {
    ok: boolean = false;
    start: number;
    Title: string = 'Options';
    passwordRef: TextInput;
    password2Ref: TextInput;
    passwordVisibility: boolean = true;
    passwordConfirmVisibility: boolean = true;
    m_password: string = '';
    m_password2: string = '';

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    setPasswordVisibility(passwordVisibility: boolean) {
        this.passwordVisibility = passwordVisibility;
        this.setState({});
    }
    setPasswordConfirmVisibility(passwordConfirmVisibility) {
        this.passwordConfirmVisibility = passwordConfirmVisibility;
        this.setState({});
    }

    async validatePassword(): Promise<boolean> {
        if (this.m_password.length < 3) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('The password must be greater than 2 chars');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (!this.props.m_pwin.m_main.CheckUsernameAndPassword(this.m_password)) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Unacceptable characters in the password');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (this.m_password !== this.m_password2) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                'The second password that you provided is different from the first one'
            );
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (this.m_password.length === 0) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please enter a Password');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        return true;
    }

    async updatePassword() {
        if (await this.validatePassword()) {
            let message: string = 'S|' + this.props.m_pwin.m_con.m_password + '|' + this.m_password + '|' + this.m_password2;
            this.props.m_pwin.SendGame(message);
        }
    }

    async DoModal() {
        this.props.m_pwin.state.SettingsScreenVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.SettingsScreenVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDCANCEL;
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    changeTD() {
        GlobalVariables.automatic_td = !GlobalVariables.automatic_td;
        this.setState({});
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'automatic_td', GlobalVariables.automatic_td ? 1 : 0);
    }

    changeAR() {
        GlobalVariables.automatic_release = !GlobalVariables.automatic_release;
        this.setState({});
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'automatic_release', GlobalVariables.automatic_release ? 1 : 0);
    }

    changeSR() {
        GlobalVariables.show_rating = !GlobalVariables.show_rating;
        this.setState({});
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'show_rating', GlobalVariables.show_rating ? 1 : 0);
    }

    async changePoolsColor() {
        GlobalVariables.player_pools_color = GlobalVariables.player_pools_color === 'Black' ? 'White' : 'Black';
        this.setState({});
        if (this.props.m_pwin.game === 'Tabla') {
            await this.props.m_pwin.tab.LoadPools(await this.props.m_pwin.m_main.WriteProfileInt('Options', 'poolsnew', 1));
            this.props.m_pwin.tab.setState({});
        }
        this.props.m_pwin.m_main.WriteProfileString('Options', 'player_pools_color', GlobalVariables.player_pools_color);
    }

    changeSP() {
        GlobalVariables.show_pictures = !GlobalVariables.show_pictures;
        this.setState({});
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'show_pictures', GlobalVariables.show_pictures ? 1 : 0);
    }

    OnOk() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ SettingsScreenVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ SettingsScreenVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.SettingsScreenVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 5 * GlobalVariables.scale,
                                width: 780 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.Title}</Text>
                        </View>
                        <ScrollView style={[styles.W100, { height: Dimensions.get('window').height * 0.7 }]}>
                            <Pressable>
                                {this.props.m_pwin.ShowDiceOption() ? (
                                    <View style={styles.row}>
                                        <View style={[styles.row, cstyles.textWithSwith]}>
                                            <View>
                                                <Switch
                                                    style={cstyles.switchStyle}
                                                    onValueChange={this.changeTD.bind(this)}
                                                    value={GlobalVariables.automatic_td}
                                                />
                                            </View>
                                            <View style={cstyles.text}>
                                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Automatic dice throw</Text>
                                            </View>
                                        </View>
                                    </View>
                                ) : null}
                                <View style={styles.row}>
                                    <View style={[styles.row, cstyles.textWithSwith]}>
                                        <View>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={this.changeAR.bind(this)}
                                                value={GlobalVariables.automatic_release}
                                            />
                                        </View>
                                        <View style={cstyles.text}>
                                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Automatic release</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={[styles.row, cstyles.textWithSwith]}>
                                        <View>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={this.changeSR.bind(this)}
                                                value={GlobalVariables.show_rating}
                                            />
                                        </View>
                                        <View style={cstyles.text}>
                                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Show Rating</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={[styles.row, cstyles.textWithSwith]}>
                                        <View>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={this.changeSP.bind(this)}
                                                value={GlobalVariables.show_pictures}
                                            />
                                        </View>
                                        <View style={cstyles.text}>
                                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Show Pictures</Text>
                                        </View>
                                    </View>
                                </View>
                                {this.props.m_pwin.GetGameConfig() === 'Tabla' || this.props.m_pwin.GetGameConfig() === 'All' ? (
                                    <View style={styles.row}>
                                        <View style={[styles.row, cstyles.textWithSwith]}>
                                            <View>
                                                <Switch
                                                    style={cstyles.switchStyle}
                                                    onValueChange={this.changePoolsColor.bind(this)}
                                                    value={GlobalVariables.player_pools_color === 'Black'}
                                                />
                                            </View>
                                            <View style={[cstyles.text, cstyles.pools]}>
                                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Backgammon Pools</Text>
                                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>: </Text>
                                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{GlobalVariables.player_pools_color}</Text>
                                            </View>
                                        </View>
                                    </View>
                                ) : null}
                                {this.props.m_pwin.IsGameOnline() ? (
                                    <View style={styles.row}>
                                        <Button
                                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                onPress={() => this.props.m_pwin.setState({ ChangePasswordVisible: true })}
                                                text="Change Password"
                                                containerStyle={modal.buttonStyle2PerRow}
                                            />
                                    </View>
                                ) : null}
                                <View style={styles.row}>
                                    <Button
                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                            onPress={() => this.props.m_pwin.setState({ PlayersNamesVisible: true })}
                                            text="Players Names"
                                            containerStyle={modal.buttonStyle2PerRow}
                                        />
                                </View>
                            </Pressable>
                        </ScrollView>
                        <View style={styles.row}>
                            <Button
                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                containerStyle={modal.buttonStyle2PerRow}
                                onPress={() => this.OnOk()}
                                text="Close"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default SettingsScreen;

const cstyles = StyleSheet.create({
    changePasswordContainer: {
        padding: 1,
        marginBottom: 1,
    },
    switchStyle: {
        height: 48,
        marginRight: 15,
        transform: Platform.OS === 'android' ? [{ scaleX: 2 }, { scaleY: 2 }] : [{ scaleX: 1 }, { scaleY: 1 }],
    },
    changePasswordInput: { width: '48%' },
    changePassword: { width: '17%' },
    textWithSwith: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        paddingBottom: 3,
        height: 50,
    },
    text: { height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 * GlobalVariables.scale },
    pools: { flexDirection: 'row' },
});
