import { useEffect, useState } from 'react';
import { Dimensions, Image } from 'react-native';
import GlobalVariables from '../GlobalVariables';
import GlobalConstants from '../GlobalConstants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons, AntDesign, Entypo, MaterialCommunityIcons, Foundation, FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';

export const useImages = () => {
    const [loading, setLoading] = useState(true);
    const [initLoading, setInitLoading] = useState(false);

    useEffect(() => {
        if (initLoading) {
            return;
        }
        async function prepare() {
            let urlOfImages = [];
            let preFetchTasks = [];
            CalculateScale();
            setInitLoading(true);
            if (loading) {
                await Font.loadAsync({
                    ...Ionicons.font,
                    ...AntDesign.font,
                    ...Entypo.font,
                    ...MaterialCommunityIcons.font,
                    ...Foundation.font,
                    ...FontAwesome.font,
                    ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
                    anticon: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/AntDesign.ttf'),
                    entypo: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf'),
                    'material-community': require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
                    foundation: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Foundation.ttf'),
                    fontAwesome: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf'),
                })
                    .then(() => console.log('Load Fonts Finished'))
                    .catch(() => {
                        console.log('Load Fonts Finished with error');
                    });

                let face = await GetProfileString('Options', 'facenew', 'cards_1');
                for (let i = 100; i <= 400; i = i + 100) {
                    for (let a = 2; a <= 14; a++) {
                        urlOfImages.push(
                            GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/cards/' + face + '/' + Number(i + a).toString() + '.gif'
                        );
                    }
                }
                if (GlobalVariables.folder !== 'files_2.0') {
                    for (let i = 100; i <= 400; i = i + 100) {
                        for (let a = 2; a <= 14; a++) {
                            urlOfImages.push(GlobalConstants.IMAGES_FOLDER + '/files_2.0/cards/' + face + '/' + Number(i + a).toString() + '.gif');
                        }
                    }
                }
                let dc = await GetProfileInt('Options', 'dicenssc', 1);
                for (let i = 1; i <= 6; i++) {
                    urlOfImages.push(
                        GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/backgammon/dice/' + dc + '/' + Number(i).toString() + '.png'
                    );
                    urlOfImages.push(
                        GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/backgammon/dice/' + dc + '/_' + Number(i).toString() + '.png'
                    );
                }
                let ct = await GetProfileInt('Options', 'dicetabla', 1);
                if (ct !== dc) {
                    for (let i = 1; i <= 6; i++) {
                        urlOfImages.push(
                            GlobalConstants.IMAGES_FOLDER +
                                '/' +
                                GlobalVariables.folder +
                                '/backgammon/dice/' +
                                ct +
                                '/' +
                                Number(i).toString() +
                                '.png'
                        );
                        urlOfImages.push(
                            GlobalConstants.IMAGES_FOLDER +
                                '/' +
                                GlobalVariables.folder +
                                '/backgammon/dice/' +
                                ct +
                                '/_' +
                                Number(i).toString() +
                                '.png'
                        );
                    }
                }
                let back = await GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
                let grab = await GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
                urlOfImages.push(
                    GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/cards/' + back + '/' + Number(grab).toString() + '.gif'
                );
                urlOfImages.push(
                    GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/cards/' + back + '/' + Number(grab + 8).toString() + '.gif'
                );
                if (GlobalVariables.folder !== 'files_2.0') {
                    urlOfImages.push(GlobalConstants.IMAGES_FOLDER + '/files_2.0/cards/' + back + '/' + Number(grab).toString() + '.gif');
                    urlOfImages.push(GlobalConstants.IMAGES_FOLDER + '/files_2.0/cards/' + back + '/' + Number(grab + 8).toString() + '.gif');
                }
                urlOfImages.forEach((p) => {
                    preFetchTasks.push(Image.prefetch(p));
                });

                try {
                    await Promise.all(preFetchTasks)
                        .catch(function (err) {
                            // log that I have an error, return the entire array;
                            setLoading(false);
                            console.log('A promise failed to resolve', err);
                        })
                        .then(async () => {
                            setLoading(false);
                            console.log('Load Images Finished');
                        })
                        .finally(async () => {
                            setLoading(false);
                            console.log('Stop Loading');
                        });
                } catch (e) {
                    setLoading(false);
                    console.log('Load Images Finished with error', e);
                }
            }
        }
        if (loading) {
            prepare();
        }
    }, [initLoading, loading]);

    function CalculateScale() {
        let width = Dimensions.get('window').width;
        let height = Dimensions.get('window').height;
        let scalew = width / (GlobalConstants.GAME_WIDTH + GlobalVariables.positionLeft); //779
        let scaleh = height / GlobalConstants.GAME_HEIGHT; //525
        GlobalVariables.scale = Math.min(scalew, scaleh);
        GlobalVariables.WindowWidth = width;
        GlobalVariables.WindowHeight = height;
        GlobalVariables.scaleWidth = scalew;
        GlobalVariables.scaleHeight = scaleh;
        if (GlobalVariables.scale <= 1) {
            GlobalVariables.folder = 'files_1.0';
        }
        if (GlobalVariables.scale > 1) {
            GlobalVariables.folder = 'files_1.5';
        }
        if (GlobalVariables.scale > 1.5) {
            GlobalVariables.folder = 'files_2.0';
        }
    }

    async function GetProfileString(prefix: string, name: string, defaultValue: string) {
        let val: string = await AsyncStorage.getItem(prefix + '_' + name);
        if (val !== undefined && val !== null) {
            return val;
        }
        return defaultValue;
    }

    async function GetProfileInt(prefix: string, name: string, defaultValue: number) {
        let val: string = await AsyncStorage.getItem(prefix + '_' + name);
        if (val !== undefined && val !== null) {
            return Number(val);
        }
        return defaultValue;
    }

    return loading;
};
