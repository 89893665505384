import CCardGamesView from './CCardGamesView';

export default class ChatSocket {
    ws: WebSocket = null;
    m_strRecv: string = '';
    static connected = false;
    m_pwin: CCardGamesView;

    constructor() {
        ChatSocket.connected = false;
        this.ws = null;
        this.m_strRecv = '';
    }

    RestoreMessage(msg_back: string) {
        this.m_strRecv = msg_back + this.m_strRecv;
    }

    createWebSocketConnection() {
        this.ws = new WebSocket('wss://' + this.m_pwin.m_main.GetServerAddress());
        this.ws.onerror = function () {};
        this.ws.onopen = function () {
            ChatSocket.connected = true;
        };
        this.ws.onmessage = (evt) => {
            // listen to data sent from the websocket server
            let message = evt.data;
            this.m_strRecv += message;
            if (!this.m_pwin.props.isAppActive) {
                this.m_pwin.OnTimer1();
                this.m_pwin.OnTimer2();
                this.m_pwin.OnTimer3();
                this.m_pwin.OnTimer4();
                this.m_pwin.OnTimer5();
                this.m_pwin.OnTimer7();
                if (this.m_pwin.game === 'Belot4') {
                    if (this.m_pwin.b4.Razdavane1 || this.m_pwin.b4.Razdavane2) {
                        while (this.m_pwin.b4.Razdavane1 || this.m_pwin.b4.Razdavane2) {
                            this.m_pwin.OnTimer1();
                        }
                    }
                }
                if (this.m_pwin.game === 'Bridge') {
                    if (this.m_pwin.m_bri.Razdavane1) {
                        while (this.m_pwin.m_bri.Razdavane1) {
                            this.m_pwin.OnTimer1();
                        }
                    }
                }
            }
        };
        this.ws.onclose = function () {
            ChatSocket.connected = false;
        };
    }
    Close() {
        ChatSocket.connected = false;
        if (this.ws) {
            this.ws.close();
        }
        this.ws = null;
    }

    Create(m_pwin: CCardGamesView) {
        this.m_pwin = m_pwin;
        this.createWebSocketConnection();
    }

    SendMessage(message: string): boolean {
        if (this.ws) {
            this.ws.send(message);
            return true;
        } else {
            ChatSocket.connected = false;
            return false;
        }
    }
}
