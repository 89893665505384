import React, { Component } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import Text from '../../Components/core/Text';
import CPicture from '../../Components/cppComponents/CPicture';
import GlobalConstants from '../../Components/GlobalConstants';
import GlobalVariables from '../../Components/GlobalVariables';
import { MessageParts } from './CChatBar';

type Props = {
    parts: MessageParts[];
    spec: string[];
    index: number;
    scale?: boolean;
    fontSize?: number;
};

class ChatMessage extends Component<Props> {
    cstyles = stylesGenerator();
    render() {
        return (
            <Pressable style={this.cstyles.message} onPress={() => { return;}}>
                {this.props.parts.map((part: MessageParts, index) => (
                    <View key={index} style={this.cstyles.messageRow} >
                        {part.type === 'text' ? (
                            <Text
                                color="black"
                                scaleText={false}
                                style={this.cstyles.historyText}
                                textSize={this.props.fontSize ? this.props.fontSize : GlobalConstants.SMALL_FONT_SIZE}
                            >
                                {part.text}
                            </Text>
                        ) : (
                            <View
                                style={[
                                    this.cstyles.emote,
                                    this.props.scale
                                        ? { width: 14 * GlobalVariables.scale, height: 14 * GlobalVariables.scale }
                                        : this.cstyles.noScale,
                                ]}
                            >
                                <CPicture
                                    image={'emote/' + part.emoteIndex + '.png'}
                                    folder="files_2.0"
                                    m_Height={14}
                                    m_Width={14}
                                    m_x={0}
                                    m_y={0}
                                    noScale={this.props.scale ? false : true}
                                    cursor={GlobalConstants.CURSOR_ARROW}
                                />
                            </View>
                        )}
                    </View>
                ))}
            </Pressable>
        );
    }
}

export default ChatMessage;

const stylesGenerator = () =>
    StyleSheet.create({
        emote: { marginHorizontal: 1 },
        noScale: { width: 14, height: 14 },
        messageRow: { maxWidth: '100%' },
        message: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            width: '100%',
        },
        historyText: {
            fontWeight: 'bold',
        },
    });
