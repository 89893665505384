import { Component } from 'react';
import CChatBar from '../DockBars/ChatBar/CChatBar';
import CInfoBar from '../DockBars/CInfoBar';

class CMainFrame extends Component {
    constructor(props) {
        super(props);
    }
    m_chat_bar: CChatBar = new CChatBar(null);
    m_info_bar: CInfoBar = new CInfoBar(null);
}

export default CMainFrame;
