import React, { Component } from 'react';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import { StyleSheet, View } from 'react-native';
import GlobalVariables from '../Components/GlobalVariables';
import { Table, Row } from 'react-native-table-component-2';
import GlobalConstants from '../Components/GlobalConstants';
import t from '../../translation';
import ScrollbarWrapperVertical from '~/Components/core/ScrollbarWrapperVertical';

class CInfoBar extends Component<BaseDlgProps> {
    tableHead = [];
    widthArr = [];
    tableData = [];
    dialogWidth: number = 150;
    constructor(props: BaseDlgProps) {
        super(props);
        this.tableHead = [];
        this.widthArr = [];
    }

    InitTable() {
        console.log('InitTable', this.props.m_pwin.m_main.language);
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Players'));
        this.widthArr.push(75);
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Bidding'));
        this.widthArr.push(75);
    }

    updateLanguage() {
        this.tableHead = [];
        this.widthArr = [];
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Players'));
        this.widthArr.push(75);
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Bidding'));
        this.widthArr.push(75);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.InitTable();
    }

    Clear() {
        this.tableData = [];
    }

    ShowWindow(show: boolean) {
        if (this.props && this.props.m_pwin) {
            this.props.m_pwin.state.CInfoBarVisible = show;
        }
    }

    IsWindowVisible(): boolean {
        if (this.props && this.props.m_pwin) {
            return this.props.m_pwin.state.CInfoBarVisible;
        }
        return false;
    }

    AddNewGame() {
        this.AddToInfoBar('------------', '------------');
    }

    AddToInfoBar(username: string, info: string) {
        let row = [username, info];
        this.tableData.unshift(row);
        this.setState({});
    }

    OnExit() {
        this.props.m_pwin.setState({ CInfoBarVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.CInfoBarVisible) {
            return (
                <View
                    style={[
                        cstyles.infoDockBarContainer,
                        {
                            right: Math.max(
                                -this.dialogWidth,
                                1015 * GlobalVariables.scale + GlobalVariables.positionLeft - GlobalVariables.WindowWidth
                            ),
                        },
                    ]}
                >
                    <Table borderStyle={StyleSheet.flatten(cstyles.borderStyle)}>
                        <Row
                            data={this.tableHead}
                            widthArr={this.widthArr}
                            style={StyleSheet.flatten(cstyles.header)}
                            textStyle={StyleSheet.flatten(cstyles.text)}
                        />
                    </Table>
                    <ScrollbarWrapperVertical
                        style={{
                            height: GlobalConstants.GAME_HEIGHT * GlobalVariables.scale - GlobalVariables.positionTop - 30,
                        }}
                    >
                        <Table borderStyle={StyleSheet.flatten(cstyles.borderStyle)}>
                            {this.tableData.map((rowData, index) => (
                                <Row
                                    key={index}
                                    data={[rowData[0], t.t(rowData[1])]}
                                    widthArr={this.widthArr}
                                    height={30}
                                    style={StyleSheet.flatten(index % 2 === 0 ? cstyles.rowActiveColor : cstyles.rowColor)}
                                    textStyle={StyleSheet.flatten(cstyles.text)}
                                    onPress={() => { return;}}
                                />
                            ))}
                        </Table>
                    </ScrollbarWrapperVertical>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CInfoBar;

const cstyles = StyleSheet.create({
    infoDockBarContainer: {
        top: 0,
        width: 150,
        backgroundColor: 'rgb(240,240,240)',
        position: 'absolute',
    },
    borderStyle: {
        borderWidth: 1,
        borderColor: '#333333',
        borderStyle: 'solid',
        zIndex: 1000
    },
    header: { height: 30, backgroundColor: '#FFFFFF' },
    text: { paddingLeft: 2, fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
    rowActiveColor: { backgroundColor: '#F7F6E7' },
    rowColor: { backgroundColor: '#E7E6E1' },
});
