import React, { Component } from 'react';
import { Alert, Platform, StyleSheet, View, Dimensions } from 'react-native';
import { BaseDlgProps } from '~/Components/props/BaseDlgProps';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import t from '../../translation';
import Loader from '../Components/core/Loader';

class DlgVip extends Component<BaseDlgProps> {
    sms_vip: string = '';
    sms_chips: string = '';
    openWebViewPayment: boolean = false;
    enablePayment = false;
    purchasedProductId: string = '';
    loading: boolean = false;
    constructor(props: BaseDlgProps) {
        super(props);
        if (this.props && this.props.m_pwin.isEnableMobilePayment()) {
            this.enablePayment = true;
        } else {
            this.enablePayment = false;
        }
    }

    initStrings() {
        this.sms_vip = this.props.m_pwin.m_main.sms_vip.replace('(username)', this.props.m_pwin.m_con.getUsername());
        this.sms_chips = this.props.m_pwin.m_main.sms_chips.replace('(username)', this.props.m_pwin.m_con.getUsername());
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    async componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.OnOk();
    }

    async OnOk() {
        if (this.enablePayment) {
            try {
                let InAppPurchases = require('expo-in-app-purchases');
                await InAppPurchases.disconnectAsync()
                    .then(() => {
                        console.log('disconnectAsync Successful');
                    })
                    .catch((e) => {
                        console.log('Error disconnectAsync ', e);
                    });
            } catch (e) {
                console.log(e);
            }
        }
        this.props.m_pwin.state.DlgVipVisible = false;
    }

    async DoModal() {
        this.props.m_pwin.state.DlgVipVisible = true;
        this.initStrings();
        if (this.enablePayment) {
            this.LoadPaymentForm();
        } else {
            this.openWebViewPayment = false;
        }
        this.setState({});
        this.props.m_pwin.TrackEvent('OpenVipDLG', 0.3);
        this.props.m_pwin.setState({});
        while (this.props.m_pwin.state.DlgVipVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgVipVisible: false });
    }

    UnLoadPaymentForm() {
        this.openWebViewPayment = false;
        if (this.enablePayment) {
            try {
                if (this.props.m_pwin.m_main.GetAppStoreCode() === 'hu') {
                } else {
                    let InAppPurchases = require('expo-in-app-purchases');
                    InAppPurchases.disconnectAsync();
                }
            } catch (e) {
                console.log(e);
            }
        }
        this.setState({});
    }

    async LoadPaymentForm() {
        this.openWebViewPayment = true;
        if (this.enablePayment) {
            try {
                if (this.props.m_pwin.m_main.GetAppStoreCode() === 'hu') {
                /*    let HMSIapModule = require('@hmscore/react-native-hms-iap');
                    console.log('this.props.m_pwin.m_main.GetAppStoreCode() === hu');

                    HMSIapModule.isEnvironmentReady(false)
                        .then((isEnvReadyResult) => {
                            console.log(JSON.stringify(isEnvReadyResult));
                        })
                        .catch((err) => {
                            console.log(JSON.stringify(err));
                        });

                    let productInfoReq = {
                        priceType: HMSIapModule.PRICE_TYPE_IN_APP_CONSUMABLE,
                        skuIds: ['vip30', 'vip90', 'vip365', 'chips50000', 'chips150000', 'chips500000'],
                    };
                    HMSIapModule.obtainProductInfo(productInfoReq)
                        .then((productInfoResult) => {
                            console.log(JSON.stringify(productInfoResult));
                            //Alert.alert('productInfoResult', JSON.stringify(productInfoResult))
                        })
                        .catch((err) => {
                            console.log(JSON.stringify(err));
                        });*/
                } else {
                    let InAppPurchases = require('expo-in-app-purchases');
                    await InAppPurchases.connectAsync()
                        .then(() => {})
                        .catch((e) => {
                            Alert.alert('Error connectAsync' + JSON.stringify(e));
                        });
                    InAppPurchases.setPurchaseListener(({ responseCode, results, errorCode }) => {
                        console.log('PurchaseListener Successful');
                        if (responseCode === InAppPurchases.IAPResponseCode.OK) {
                            results.forEach((purchase) => {
                                if (!purchase.acknowledged) {
                                    if (
                                        (purchase.productId === 'vip30' ||
                                            purchase.productId === 'vip30all' ||
                                            purchase.productId === 'vip30blato2' ||
                                            purchase.productId === 'vip30' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip30');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('vip30', 7);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                    if (
                                        (purchase.productId === 'vip90' ||
                                            purchase.productId === 'vip90all' ||
                                            purchase.productId === 'vip90blato2' ||
                                            purchase.productId === 'vip90' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip90');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('vip90', 15);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                    if (
                                        (purchase.productId === 'vip365' ||
                                            purchase.productId === 'vip365all' ||
                                            purchase.productId === 'vip365blato2' ||
                                            purchase.productId === 'vip365' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip365');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('vip365', 51);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                    if (
                                        (purchase.productId === 'chips50000' ||
                                            purchase.productId === 'chips50000all' ||
                                            purchase.productId === 'chips50000blato2' ||
                                            purchase.productId === 'chips50000' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-50000) + '|AddChips');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('chips50000', 9);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                    if (
                                        (purchase.productId === 'chips150000' ||
                                            purchase.productId === 'chips150000all' ||
                                            purchase.productId === 'chips150000blato2' ||
                                            purchase.productId === 'chips150000' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-150000) + '|AddChips');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('chips150000', 21);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                    if (
                                        (purchase.productId === 'chips500000' ||
                                            purchase.productId === 'chips500000all' ||
                                            purchase.productId === 'chips500000blato2' ||
                                            purchase.productId === 'chips500000' + this.props.m_pwin.GetGameConfig().toLowerCase()) &&
                                        this.purchasedProductId === purchase.productId
                                    ) {
                                        this.purchasedProductId = '';
                                        this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-500000) + '|AddChips');
                                        this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                        this.props.m_pwin.TrackEvent('chips500000', 63);
                                        InAppPurchases.finishTransactionAsync(purchase, true);
                                    }
                                }
                                this.loading = false;
                                this.setState({});
                            });
                        } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
                            console.log('User canceled the transaction');
                            this.loading = false;
                            this.setState({});
                        } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
                            console.log('User does not have permissions to buy but requested parental approval (iOS only)');
                            this.loading = false;
                            this.setState({});
                        } else {
                            Alert.alert(`Something went wrong with the purchase. Received errorCode ${errorCode}`);
                        }
                    });
                }
            } catch (e) {
                Alert.alert('Error: ', e);
                this.loading = false;
                this.setState({});
            }
        }
        this.props.m_pwin.TrackEvent('PayWithCard', 1);
        this.setState({});
    }

    async makePayment(item: string) {
        this.loading = true;
        this.setState({});
        if (this.props.m_pwin.m_main.GetAppStoreCode() === 'hu') {
            try {
            /*    let HMSIapModule = require('@hmscore/react-native-hms-iap');
                let type = HMSIapModule.PRICE_TYPE_IN_APP_CONSUMABLE;
                console.log('Init Type');
                const productId = item;
                let reservedInfo = {
                    item: item,
                };
                let purchaseIntentReq = {
                    priceType: type,
                    productId: productId,
                    developerPayload: 'payload',
                    reservedInfor: JSON.stringify(reservedInfo),
                };
                console.log('Init purchaseIntentReq');
                console.log('createPurchaseIntent Begin');
                HMSIapModule.createPurchaseIntent(purchaseIntentReq)
                    .then((purchaseResultInfo) => {
                        console.log(purchaseResultInfo);
                        console.log(JSON.stringify(purchaseResultInfo));
                        //Alert.alert('Success', JSON.stringify(purchaseResultInfo));

                        let purchaseToken = purchaseResultInfo.InAppPurchaseData.PurchaseToken;

                        let consumeOwnedPurchaseReq = {
                            developerChallenge: 'DEVELOPER_CHALLENGE',
                            purchaseToken: purchaseToken,
                        };
                        HMSIapModule.consumeOwnedPurchase(consumeOwnedPurchaseReq)
                            .then((consumeOwnedPurchaseResult) => {
                                //Alert.alert('consumeOwnedPurchaseResult', JSON.stringify(consumeOwnedPurchaseResult));
                                console.log(JSON.stringify(consumeOwnedPurchaseResult));
                                if (item === 'vip30') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip30');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('vip30', 7);
                                }
                                if (item === 'vip90') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip90');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('vip90', 15);
                                }
                                if (item === 'vip365') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(0) + '|AddVip365');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('vip365', 51);
                                }
                                if (item === 'chips50000') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-50000) + '|AddChips');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('chips50000', 9);
                                }
                                if (item === 'chips150000') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-150000) + '|AddChips');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('chips150000', 21);
                                }
                                if (item === 'chips500000') {
                                    this.props.m_pwin.SendGame('*|P|' + this.props.m_pwin.m_main.IntToString(-500000) + '|AddChips');
                                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                                    this.props.m_pwin.TrackEvent('chips500000', 63);
                                }
                            })
                            .catch((err) => {
                                console.log(JSON.stringify(err));
                            });
                        this.loading = false;
                        this.setState({});
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log(JSON.stringify(err));
                        this.loading = false;
                        this.setState({});
                        Alert.alert('Error', JSON.stringify(err));
                    });*/
            } catch (err) {
                this.loading = false;
                this.setState({});
                Alert.alert('error occured while trying to purchase: ' + err);
            }
            console.log('createPurchaseIntent End');
        } else {
            if (this.props.m_pwin.GetGameConfig() === 'Bridge' && item === 'vip30' && Platform.OS === 'android') {
                //Wrong delete product vip30bridge ( this is jhust exception for google play)
            } else if (this.props.m_pwin.GetGameConfig() === 'All' && Platform.OS === 'ios') {
                //Wrong delete product vip30bridge ( this is jhust exception for google play)
                item = item + 'all';
            } else if (this.props.m_pwin.GetGameConfig() === 'Blato' && Platform.OS === 'ios') {
                //Wrong delete product vip30bridge ( this is jhust exception for google play)
                item = item + 'blato2';
            } else if (this.props.m_pwin.GetGameConfig() !== 'All') {
                item = item + this.props.m_pwin.GetGameConfig().toLowerCase();
            }
            console.log('now making payment...' + item);
            try {
                let InAppPurchases = require('expo-in-app-purchases');
                const products = await InAppPurchases.getProductsAsync([item]);
                console.log('products: ' + JSON.stringify(products));
                if (products.results.length > 0) {
                    this.purchasedProductId = item;
                    console.log('found products!');
                    await InAppPurchases.purchaseItemAsync(item)
                        .then(() => {})
                        .catch((e) => {
                            Alert.alert('Error purchaseItemAsync' + JSON.stringify(e));
                            console.log('Error purchaseItemAsync', e);
                            this.purchasedProductId = '';
                            this.loading = false;
                            this.setState({});
                        });
                } else {
                    this.loading = false;
                    this.setState({});
                    Alert.alert('This product is not available.');
                }
            } catch (err) {
                this.loading = false;
                this.setState({});
                Alert.alert('error occured while trying to purchase: ' + err);
            }
        }
    }

    //JSON.stringify(error)
    async Add30DaysVip() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&days=30' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('vip30');
        }
    }

    async Add90DaysVip() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&days=90' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('vip90');
        }
    }

    async Add365DaysVip() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&days=90' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('vip365');
        }
    }

    async Add50000Chips() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&chips=50000' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('chips50000');
        }
    }

    async Add150000Chips() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&chips=150000' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('chips150000');
        }
    }

    async Add500000Chips() {
        if (!this.enablePayment) {
            let ctime: number = new Date().getTime();
            let url =
                'https://favorite-games.com/html/epay_form.php?language=' +
                this.props.m_pwin.m_main.language +
                '&username=' +
                this.props.m_pwin.m_con.getUsername() +
                '&chips=150000' +
                '&payment=card&time=' +
                this.props.m_pwin.m_main.IntToString(ctime);
            this.props.m_pwin.m_main.ShellExecute(url);
        } else {
            await this.makePayment('chips500000');
        }
    }

    render() {
        if (this.props.m_pwin.state.DlgVipVisible) {
            if (!this.openWebViewPayment) {
                return (
                    <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                        <View
                            style={[
                                dialog.dialogView,
                                {
                                    top: GlobalVariables.positionTop + 50 * GlobalVariables.scale,
                                    left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                    width: 750 * GlobalVariables.scale,
                                },
                            ]}
                        >
                            <View style={styles.doalogTitle}>
                                <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Become a VIP user</Text>
                            </View>
                            <View style={cstyle.paymentInfoContainer}>
                                <View style={cstyle.paymentInfo}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.sms_vip}</Text>
                                </View>
                            </View>
                            <View style={cstyle.paymentInfoContainer}>
                                <View style={cstyle.paymentInfo}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.sms_chips}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <Button
                                    containerStyle={modal.buttonStyle2PerRow}
                                    textSize={GlobalConstants.LARGE_FONT_SIZE}
                                    onPress={() => this.LoadPaymentForm()}
                                    text={'Pay with Card'}
                                />
                                <Button
                                    containerStyle={modal.buttonStyle2PerRow}
                                    textSize={GlobalConstants.LARGE_FONT_SIZE}
                                    onPress={this.OnOk.bind(this)}
                                    text="OK"
                                />
                            </View>
                        </View>
                    </View>
                );
            } else {
                return (
                    <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                        <View
                            style={[
                                dialog.dialogView,
                                styles.W100,
                                styles.h100,
                                {
                                    top: 0 * GlobalVariables.scale,
                                    left: 0 * GlobalVariables.scale,
                                },
                            ]}
                        >
                            <View style={styles.doalogTitle}>
                                <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Become a VIP user</Text>
                            </View>
                            <View style={cstyle.buttonsContainer}>
                                <View style={styles.row}>
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add30DaysVip();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (days) days VIP', { days: '30' })}
                                    />
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add50000Chips();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (chips) Chips.', { chips: '50 000' })}
                                    />
                                </View>
                                <View style={styles.row}>
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add90DaysVip();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (days) days VIP', { days: '90' })}
                                    />
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add150000Chips();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (chips) Chips.', { chips: '150 000' })}
                                    />
                                </View>
                                <View style={styles.row}>
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add365DaysVip();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (days) days VIP', { days: '365' })}
                                    />
                                    <Button
                                        disabled={this.loading}
                                        onPress={() => {
                                            this.Add500000Chips();
                                        }}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        text={t.t('Add (chips) Chips.', { chips: '500 000' })}
                                    />
                                </View>
                            </View>
                            <View style={styles.row}>
                                {Platform.OS !== 'ios' ? (
                                    <Button
                                        disabled={this.loading}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        onPress={() => this.UnLoadPaymentForm()}
                                        text="Go Back"
                                    />
                                ) : null}
                                <Button
                                    disabled={this.loading}
                                    containerStyle={modal.buttonStyle2PerRow}
                                    textSize={GlobalConstants.LARGE_FONT_SIZE}
                                    onPress={this.OnOk.bind(this)}
                                    text="Close"
                                />
                            </View>
                            <Loader loading={this.loading} />
                        </View>
                    </View>
                );
            }
        } else {
            return null;
        }
    }
}

export default DlgVip;

const cstyle = StyleSheet.create({
    paymentInfoContainer: {
        padding: '2%',
    },
    paymentInfo: { flexDirection: 'row', width: '100%' },
    buttonsContainer: { justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' },
});
