import React, { Component } from 'react';
import { View, StyleSheet, Pressable, Dimensions } from 'react-native';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import Text from '../../Components/core/Text';
import { RadioButton } from 'react-native-paper';
import GlobalConstants from '~/Components/GlobalConstants';
import { styles } from '../../../css/container';

class IgraTabla extends Component<BaseDlgProps> {
    Game: number = 0;
    m_type: number;

    constructor(props: BaseDlgProps) {
        super(props);
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraTablaVisible: true });
    }

    OnSp() {
        this.props.m_pwin.CloseIgraTabla();
    }

    async OnOp() {
        this.Game = 7;
        if (this.m_type === 0) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Backgammon');
        }
        if (this.m_type === 1) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Backgammon - Tapa');
        }
        if (this.m_type === 2) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Backgammon - Gulbara');
        }
        if (this.m_type === 3) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Backgammon 3 in 1');
        }
        this.props.m_pwin.CloseIgraTabla();
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadFinished();
    }

    async loadFinished() {
        let st = await this.props.m_pwin.m_main.GetProfileString('Options', 'sel_tabla', 'Backgammon');
        if (st === 'Backgammon') {
            this.m_type = 0;
        }
        if (st === 'Backgammon - Tapa') {
            this.m_type = 1;
        }
        if (st === 'Backgammon - Gulbara') {
            this.m_type = 2;
        }
        if (st === 'Backgammon 3 in 1') {
            this.m_type = 3;
        }
        this.Game = this.m_type;
        this.setState({});
    }

    async changeGameType(type: number) {
        this.m_type = type;
        this.Game = this.m_type;
        if (this.m_type === 0) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_tabla', 'Backgammon');
        }
        if (this.m_type === 1) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_tabla', 'Backgammon - Tapa');
        }
        if (this.m_type === 2) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_tabla', 'Backgammon - Gulbara');
        }
        if (this.m_type === 3) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_tabla', 'Backgammon 3 in 1');
        }
        this.setState({});
    }

    OnIzhod() {
        this.props.m_pwin.setState({ IgraTablaVisible: false });
        this.props.m_pwin.OnNewGame();
    }

    render() {
        if (this.props.m_pwin.state.IgraTablaVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View style={cstyles.optionsContainer}>
                                <View style={cstyles.centeredOptionsContainer}>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(0)}>
                                        <RadioButton
                                            value="0"
                                            status={this.m_type === 0 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(0)}
                                        />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Backgammon - Classic</Text>
                                    </Pressable>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(1)}>
                                        <RadioButton
                                            value="1"
                                            status={this.m_type === 1 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(1)}
                                        />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Backgammon - Tapa</Text>
                                    </Pressable>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(2)}>
                                        <RadioButton
                                            value="2"
                                            status={this.m_type === 2 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(2)}
                                        />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Backgammon - Gulbara</Text>
                                    </Pressable>
                                    <Pressable style={cstyles.option} onPress={() => this.changeGameType(3)}>
                                        <RadioButton
                                            value="3"
                                            status={this.m_type === 3 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(3)}
                                        />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Backgammon 3 in 1</Text>
                                    </Pressable>
                                </View>
                            </View>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnIzhod.bind(this)} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraTabla;

const cstyles = StyleSheet.create({
    optionsContainer: { justifyContent: 'center', alignItems: 'center', width: '100%' },
    centeredOptionsContainer: { justifyContent: 'flex-start' },
    option: { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' },
});
