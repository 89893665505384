import React, { Component } from 'react';
import { View, Pressable, StyleSheet, Platform, Dimensions } from 'react-native';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import Text from '../../Components/core/Text';
import { RadioButton } from 'react-native-paper';
import GlobalConstants from '~/Components/GlobalConstants';
import t from '../../../translation';
import { styles } from '../../../css/container';

class IgraBridge extends Component<BaseDlgProps> {
    Game: number = 1;
    m_type: number;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadFinished();
    }

    async loadFinished() {
        let st = await this.props.m_pwin.m_main.GetProfileString('Options', 'sel_bridge', 'Bridge Rubber');
        if (st === 'Bridge Rubber') {
            this.Game = 1;
            this.m_type = 0;
        }
        if (st === 'Duplicate Bridge') {
            this.m_type = 1;
            this.Game = 2;
        }
        this.setState({});
    }

    async MissingGame() {
        if (Platform.OS !== 'ios') {
            let message = t.t('This game is not available for (os) at this moment. Do you want to install another version?', { os: Platform.OS });
            this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
            if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                this.props.m_pwin.openPage(t.t('downloads_online'));
            }
        }
    }

    async changeGameType(type: number) {
        this.m_type = type;
        this.Game = type + 1;
        if (this.Game === 1) {
            this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_bridge', 'Bridge Rubber');
        } else {
            if (this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge')) {
                this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_bridge', 'Duplicate Bridge');
            } else {
                this.m_type = 0;
                this.Game = this.m_type + 1;
                await this.MissingGame();
            }
        }
        this.setState({});
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraBridgeVisible: true });
    }

    OnSp() {
        this.props.m_pwin.CloseIgraBridge();
    }

    async OnOp() {
        this.Game = 3;
        if (this.m_type === 0) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Bridge Rubber');
        }
        if (this.m_type === 1) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', 'Duplicate Bridge');
        }
        this.props.m_pwin.CloseIgraBridge();
    }

    OnIzhod() {
        this.props.m_pwin.setState({ IgraBridgeVisible: false });
        this.props.m_pwin.OnNewGame();
    }

    render() {
        if (this.props.m_pwin.state.IgraBridgeVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View style={cstyles.optionsContainer}>
                                <View style={cstyles.centeredOptionsContainer}>
                                    <Pressable onPress={() => this.changeGameType(0)} style={cstyles.option}>
                                        <RadioButton
                                            value="0"
                                            status={this.m_type === 0 ? 'checked' : 'unchecked'}
                                            onPress={() => this.changeGameType(0)}
                                        />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Bridge Rubber</Text>
                                    </Pressable>
                                    {this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge') ? (
                                        <Pressable onPress={() => this.changeGameType(1)} style={cstyles.option}>
                                            <RadioButton
                                                value="1"
                                                status={this.m_type === 1 ? 'checked' : 'unchecked'}
                                                onPress={() => this.changeGameType(1)}
                                            />
                                            <Text
                                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                                disabled={!this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge')}
                                            >
                                                Duplicate Bridge
                                            </Text>
                                        </Pressable>
                                    ) : null}
                                </View>
                            </View>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                {this.props.m_pwin.isEnableOnlinePlayer() ? (
                                    <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                ) : null}
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnIzhod.bind(this)} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraBridge;

const cstyles = StyleSheet.create({
    optionsContainer: { justifyContent: 'center', alignItems: 'center', width: '100%' },
    centeredOptionsContainer: { justifyContent: 'flex-start' },
    option: { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' },
});
