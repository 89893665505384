import React, { Component } from 'react';
import { StyleSheet, Pressable, View, Platform } from 'react-native';
import CPicture from '../../Components/cppComponents/CPicture';
import GlobalConstants from '../../Components/GlobalConstants';

type Props = {
    onEmote: Function;
    emotePerRow: number;
};

class Emote extends Component<Props> {
    render() {
        let res = [];
        for (let i = 0; i < 46; i++) {
            for (let a = 0; a < 3; a++) {
                res.push(
                    <Pressable
                        onPress={() => this.props.onEmote(i + a * 46)}
                        key={'emote_' + (i + a * 46)}
                        style={(args) => [
                            {
                                opacity: args.pressed ? 0.5 : 1,
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                width: Platform.OS === 'web' ? 23 : 27,
                                height: Platform.OS === 'web' ? 29 : 33,
                                left: i * (Platform.OS === 'web' ? 23 : 27),
                                top: a * (Platform.OS === 'web' ? 29 : 33),
                            },
                        ]}
                    >
                        <CPicture
                            image={'emote/' + (i + a * 46) + '.png'}
                            folder="files_2.0"
                            m_Height={Platform.OS === 'web' ? 19 : 23}
                            m_Width={Platform.OS === 'web' ? 19 : 23}
                            m_x={0}
                            m_y={0}
                            relative={true}
                            noScale={true}
                            cursor={GlobalConstants.CURSOR_HAND}
                        />
                    </Pressable>
                );
            }
        }
        return <View style={[cstyle.emoteContainer, { width: 2 + 46 * (Platform.OS === 'web' ? 23 : 27) }]}>{res}</View>;
    }
}

export default Emote;

const cstyle = StyleSheet.create({
    emoteContainer: {
        borderColor: '#555555',
        borderStyle: 'solid',
        borderWidth: 1,
        height: Platform.OS === 'web' ? 90 : 102,
        margin: 1,
    },
});
