import React from 'react';
import { Pressable, Text, PressableProps, StyleProp, ViewStyle, StyleSheet, View } from 'react-native';
import t from '../../../translation';
import CPicture from '../cppComponents/CPicture';
import GlobalConstants from '../GlobalConstants';
import GlobalVariables from '../GlobalVariables';

type Props = PressableProps & {
    text: string;
    textSize?: number;
    containerStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<ViewStyle>;
    backgroundColor?: { backgroundColor: string };
    image?: string;
    imageWidth?: number;
    imageHeight?: number;
};

const Button = ({
    text,
    textSize = GlobalConstants.DEFAULT_FONT_SIZE,
    containerStyle,
    backgroundColor,
    disabled,
    textStyle,
    image,
    imageWidth,
    imageHeight,
    onPress,
    onLongPress,
    ...rest
}: Props) => {
    return (
        <Pressable
            disabled={disabled}
            onPress={disabled ? null : onPress}
            onLongPress={disabled ? null : onLongPress}
            style={(args) => [
                styles.base,
                {
                    opacity: args.pressed || disabled ? 0.5 : 1,
                    height: textSize * 2.2 * GlobalVariables.scale,
                    borderRadius: textSize * 1.5 * GlobalVariables.scale,
                    marginLeft: textSize * GlobalVariables.scale,
                    marginRight: textSize * GlobalVariables.scale,
                    marginTop: textSize * 0.2 * GlobalVariables.scale,
                    marginBottom: textSize * 0.2 * GlobalVariables.scale,
                },
                containerStyle,
                backgroundColor ? backgroundColor : styles.backgroundColor,
            ]}
            {...rest}
        >
            {image ? (
                <View style={[styles.imageStyle, { paddingVertical: ((textSize * 3) / 2 - imageHeight / 2) * GlobalVariables.scale }]}>
                    <CPicture image={image} folder={'files_2.0'} m_Width={imageWidth} m_Height={imageHeight} m_y={0} m_x={0} relative={true} />
                </View>
            ) : null}
            <Text
                style={[
                    styles.text,
                    textStyle,
                    { paddingVertical: textSize * 0.45 * GlobalVariables.scale, fontSize: (textSize * GlobalVariables.scale) / 1.12 },
                ]}
            >
                {t.t(text)}
            </Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    imageStyle: { paddingRight: 5 * GlobalVariables.scale },
    text: {
        color: '#111133',
        fontWeight: '700',
    },
    base: {
        color: '#FFFFFF',
        borderColor: '#7DE24E',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    backgroundColor: { backgroundColor: 'white' },
});

export default Button;
