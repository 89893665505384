import { Component } from 'react';
import React from 'react';
import CPicture from '../cppComponents/CPicture';
import { DrawDiceProps } from '../props/DrawDiceProps';
import GlobalVariables from '../GlobalVariables';

class DrawDice extends Component<DrawDiceProps> {
    constructor(props: DrawDiceProps) {
        super(props);
    }

    render() {
        if (this.props.dice1 === this.props.mdice1) {
            return (
                <CPicture
                    image={'backgammon/dice/' + this.props.dc + '/' + this.props.dice1 + '.png'}
                    folder={'files_2.0'}
                    m_x={this.props.m_x}
                    m_y={this.props.m_y}
                    m_Width={GlobalVariables.diceWidth}
                    m_Height={GlobalVariables.diceHeight}
                    cursor={this.props.cursor}
                />
            );
        } else {
            return (
                <CPicture
                    image={'backgammon/dice/' + this.props.dc + '/_' + this.props.dice1 + '.png'}
                    folder={'files_2.0'}
                    m_x={this.props.m_x}
                    m_y={this.props.m_y}
                    m_Width={GlobalVariables.diceWidth}
                    m_Height={GlobalVariables.diceHeight}
                    cursor={this.props.cursor}
                />
            );
        }
    }
}

export default DrawDice;
