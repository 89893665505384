import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import { View } from 'react-native';

class CPoker extends CMain {
    async LoadBitmaps() {}
    SitPlayer(i: number) {
        console.log(i);
    }
    SetPlayerURating(username: string, rating: string) {
        console.log(username, rating);
    }
    SetAllMoneyForBet(username: string, arg1: number) {
        console.log(username, arg1);
    }
    InitPokerParameters(sblind: any, money: number) {
        console.log(sblind, money);
    }
    InitOP(server: boolean, pplayers: number) {
        console.log(server, pplayers);
    }
    async OnLButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async OnRButtonDown(point: CPoint) {
        console.log('point', point);
    }
    MaxBuyIn: number;
    MinBuyIn: number;
    async ReceiveGame(strRecvd: string) {
        console.log(strRecvd);
        return true;
    }
    Timer7() {}
    Timer3() {}
    Timer2() {}
    Timer4() {}
    constructor(props) {
        super(props);
    }
    Timer1() {}

    DrawView() {
        return null;
    }

    render() {
        if (this.props.m_pwin.game === 'Poker') {
            return <View key={'Poker'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CPoker;
