import { StyleSheet } from 'react-native';
import GlobalVariables from '../src/Components/GlobalVariables';
import { appBackground } from './container';

export const modal = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    modalView: {
        backgroundColor: appBackground,
        borderRadius: 20 * GlobalVariables.scale,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2 * GlobalVariables.scale,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4 * GlobalVariables.scale,
        elevation: 5,
        width: '100%',
        height: '100%',
    },
    button: {
        borderRadius: 20 * GlobalVariables.scale,
        padding: 10 * GlobalVariables.scale,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15 * GlobalVariables.scale,
        textAlign: 'center',
    },
    mainBody: {
        flex: 1,
        backgroundColor: appBackground,
        width: '100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
    },
    SectionStyle: {
        flexDirection: 'row',
        height: 40 * GlobalVariables.scale,
        marginTop: 10 * GlobalVariables.scale,
        marginLeft: 35 * GlobalVariables.scale,
        marginRight: 35 * GlobalVariables.scale,
    },
    buttonStyle1PerRow: {
        width: '98%',
        marginLeft: '1%',
        marginRight: '1%',
    },
    buttonStyle2PerRow: {
        width: '48%',
        marginLeft: '1%',
        marginRight: '1%',
    },
    buttonStyle3PerRow: {
        width: '31.33%',
        marginLeft: '1%',
        marginRight: '1%',
    },
    buttonStyle4PerRow: {
        width: '23%',
        marginLeft: '1%',
        marginRight: '1%',
    },
    inputStyle: {
        flex: 1,
        color: 'white',
        paddingLeft: 15 * GlobalVariables.scale,
        paddingRight: 15 * GlobalVariables.scale,
        borderWidth: 1 * GlobalVariables.scale,
        borderRadius: 30 * GlobalVariables.scale,
        borderColor: '#dadae8',
    },
    registerTextStyle: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 14 * GlobalVariables.scale,
        alignSelf: 'center',
        padding: 10 * GlobalVariables.scale,
    },
    errorTextStyle: {
        color: 'red',
        textAlign: 'center',
        fontSize: 14 * GlobalVariables.scale,
    },
    modal: {
        backgroundColor: appBackground,
        position: 'absolute',
        top: 0,
    },
});
