import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';
import { Table, Row } from 'react-native-table-component-2';

class DlgORezultat extends Component<ParentDlgProps> {
    DeleteAllItems() {}
    m_zagubi: number = 0;
    m_pobedi: number = 0;
    orez = [];
    Player = [];
    Players: number;
    m_rez1: number = 0;
    m_rez2: number = 0;
    m_rez3: number = 0;
    tableHead = [];
    widthArr = [];
    tableData = [];
    start: number;
    scrollView: any;
    constructor(props: ParentDlgProps) {
        super(props);
        for (let i = 0; i < 3; i++) {
            this.orez[i] = [];
            for (let a = 0; a < 100; a++) {
                this.orez[i][a] = -10;
            }
        }
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    InitTable() {
        this.tableHead = [];
        this.tableData = [];
        this.widthArr = [];
        if (this.Players === 4) {
            this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText(''));
            this.widthArr.push(40 * GlobalVariables.scale);
            this.tableHead.push(this.Player[1] + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ' + this.Player[3]);
            this.widthArr.push(215 * GlobalVariables.scale);
            this.tableHead.push(this.Player[2] + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ' + this.Player[4]);
            this.widthArr.push(215 * GlobalVariables.scale);
        }
        if (this.Players === 3) {
            this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText(''));
            this.widthArr.push(35 * GlobalVariables.scale);
            this.tableHead.push(this.Player[1]);
            this.widthArr.push(145 * GlobalVariables.scale);
            this.tableHead.push(this.Player[2]);
            this.widthArr.push(145 * GlobalVariables.scale);
            this.tableHead.push(this.Player[3]);
            this.widthArr.push(145 * GlobalVariables.scale);
        }
        if (this.Players === 2) {
            this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText(''));
            this.widthArr.push(40 * GlobalVariables.scale);
            this.tableHead.push(this.Player[1]);
            this.widthArr.push(215 * GlobalVariables.scale);
            this.tableHead.push(this.Player[2]);
            this.widthArr.push(215 * GlobalVariables.scale);
        }
    }

    OnInitDialog() {
        this.InitTable();
        for (let a = 0; a < 100; a++) {
            if (this.orez[0][a] !== -10) {
                let row = [];
                row[0] = a + 1;
                row[1] = this.orez[0][a];
                row[2] = this.orez[1][a];
                if (this.Players === 3) {
                    row[3] = this.orez[2][a];
                }
                this.tableData.push(row);
            } else {
                break;
            }
        }
    }

    async DoModal() {
        this.props.m_pwin.state.DlgORezultatVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgORezultatVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgORezultatVisible: false });
        return GlobalConstants.IDOK;
    }

    OnOK() {
        this.start = 0;
    }

    AddRezults() {
        this.InitTable();
        this.m_rez1 = 0;
        this.m_rez2 = 0;
        this.m_rez3 = 0;
        for (let a = 0; a < 100; a++) {
            if (this.orez[0][a] !== -10) {
                let row = [];
                row[0] = a + 1;
                row[1] = this.orez[0][a];
                this.m_rez1 = this.m_rez1 + this.orez[0][a];
                row[2] = this.orez[1][a];
                this.m_rez2 = this.m_rez2 + this.orez[1][a];
                if (this.Players === 3) {
                    this.m_rez3 = this.m_rez3 + this.orez[2][a];
                    row[3] = this.orez[2][a];
                }
                this.tableData.push(row);
            } else {
                break;
            }
        }
    }

    render() {
        if (this.props.m_pwin.state.DlgORezultatVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyles.tableBackground}>
                                <Table borderStyle={StyleSheet.flatten(cstyles.borderStyle)}>
                                    <Row
                                        data={this.tableHead}
                                        widthArr={this.widthArr}
                                        style={StyleSheet.flatten(cstyles.header)}
                                        textStyle={StyleSheet.flatten(cstyles.text)}
                                    />
                                </Table>
                                <ScrollView
                                    style={StyleSheet.flatten({ height: GlobalConstants.GAME_HEIGHT * 0.62 * GlobalVariables.scale })}
                                    ref={(ref) => (this.scrollView = ref)}
                                    onContentSizeChange={(width, height) => (this.scrollView ? this.scrollView.scrollTo({ y: height }) : null)}
                                >
                                    <Table borderStyle={StyleSheet.flatten(cstyles.borderStyle)}>
                                        {this.tableData.map((rowData, index) => (
                                            <Row
                                                key={index}
                                                data={rowData}
                                                widthArr={this.widthArr}
                                                height={30}
                                                style={StyleSheet.flatten(index % 2 === 0 ? cstyles.rowActiveColor : cstyles.rowColor)}
                                                textStyle={StyleSheet.flatten(cstyles.text)}
                                            />
                                        ))}
                                    </Table>
                                </ScrollView>
                            </View>
                            <View style={cstyles.result}>
                                <View style={[cstyles.right]}>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Won Games</Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}> : </Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{this.m_pobedi}</Text>
                                </View>
                                <View style={[cstyles.right]}>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Lost Games</Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}> : </Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{this.m_zagubi}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.row]}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Scoring</Text>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>
                                {': ' +
                                    this.m_rez1.toString() +
                                    ' - ' +
                                    this.m_rez2.toString() +
                                    (this.Players === 3 ? ' - ' + this.m_rez3.toString() : '')}
                            </Text>
                            <Button
                                containerStyle={modal.buttonStyle4PerRow}
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                onPress={this.OnOK.bind(this)}
                                text="OK"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgORezultat;

const cstyles = StyleSheet.create({
    result: {
        paddingLeft: 10 * GlobalVariables.scale,
        justifyContent: 'flex-start',
    },
    borderStyle: {
        borderWidth: 1,
        borderColor: '#333333',
        borderStyle: 'solid',
    },
    right: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    tableBackground: {
        backgroundColor: 'white',
        marginBottom: 10 * GlobalVariables.scale,
    },
    header: { height: 30, backgroundColor: '#FFFFFF' },
    text: { paddingLeft: 2, fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
    rowActiveColor: { backgroundColor: '#F7F6E7' },
    rowColor: { backgroundColor: '#E7E6E1' },
});
