import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import { Platform, View } from 'react-native';
import DlgRezultatSantase from '../../Dialogs/DlgRezultatSantase';
import GlobalVariables from '../GlobalVariables';
import GlobalConstants from '../GlobalConstants';

import CRect from '../cppComponents/CRect';
import CPicture from '../cppComponents/CPicture';
import DrawNames from '../Draw/DrawNames';
import DrawCard from '../Draw/DrawCard';
import CSize from '../cppComponents/CSize';
import DrawBid from '../Draw/DrawBid';

class CSantase extends CMain {
    m_rez: DlgRezultatSantase = new DlgRezultatSantase(null);
    loading: boolean = true;
    postooltip: CPoint = new CPoint(0, 0);
    tooltext: string;
    player_cards: number;
    check_change: boolean;
    check_zatvoreno: boolean;
    Pos2040: CPoint[] = [];
    o2040: number;
    movecard: number;
    close: number;
    movekoz: number;
    anons: number;

    constructor(props) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        this.InitArrays();
        this.Move = false;
        this.P2OP = false;
        this.player_cards = -1;
    }

    InitArrays() {
        for (let i1 = 0; i1 < 3; i1++) {
            this.Pos2040[i1] = new CPoint(0, 0);
        }
    }

    SendGame(msg: string) {
        this.props.m_pwin.SendGame(msg);
    }

    OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);

        if (this.OP) {
            if (this.OP && GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 2; i++) {
                    //da se testva pokazvaneto na snimkata
                    if (this.Name[i] !== 'Computer2') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                if (this.cursor !== GlobalConstants.CURSOR_WATCH) {
                    this.cursor = GlobalConstants.CURSOR_WATCH;
                    this.setState({});
                }
                return;
            }
            if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                if (
                    (this.P2OP || this.wait_opplayers[2] || !this.Server) &&
                    this.pause === 0 &&
                    this.Faza > 1 &&
                    this.strelka < 1 &&
                    this.P1puska === 0 &&
                    this.Move === false &&
                    this.KoiENaRed === 1 &&
                    !this.endgame
                ) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                    this.setState({});
                    return;
                }
            }
        }
        for (let i = 1; i <= this.player_cards; i++) {
            if (
                this.Faza > 1 &&
                this.k[i] !== 0 &&
                point.x >
                    (this.CardsPos[i].x - (4.5 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) * GlobalVariables.scale &&
                point.x <
                    (this.CardsPos[i].x - (4.5 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) * GlobalVariables.scale +
                        GlobalConstants.PLAYER_CARD_WIDTH * GlobalVariables.scale &&
                point.y > this.CardsPos[i].y * GlobalVariables.scale &&
                point.y < this.CardsPos[i].y * GlobalVariables.scale + GlobalConstants.PLAYER_CARD_HEIGHT * GlobalVariables.scale
            ) {
                if (this.ProverkaCursor(i)) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                    this.setState({});
                    return;
                } else {
                    if (this.KoiENaRed !== 1) {
                        this.cursor = GlobalConstants.CURSOR_WAIT;
                    } else {
                        this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                    }
                    this.setState({});
                    return;
                }
            }
        }

        if (!this.watch) {
            let rect: CRect = this.ScaleRect(
                new CRect(
                    this.CardsPoint[24].x,
                    this.CardsPoint[24].y,
                    this.CardsPoint[24].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPoint[24].y + GlobalConstants.CARD_HEIGHT
                )
            );
            if (rect.PtInRect(point)) {
                this.postooltip.x = 150;
                this.postooltip.y = 170;
                if (
                    this.tooltext === '' &&
                    this.CheckChange24(1) &&
                    this.hand > 0 &&
                    this.hand < 5 &&
                    this.KoiPuskaParvi === 1 &&
                    this.KoiENaRed === 1 &&
                    this.Faza === 2
                ) {
                    this.tooltext = this.GetLanguageText('Change');
                    this.obiaviavaneRN = this.DrawObiaviavanePNG(this.postooltip.x, this.postooltip.y, this.tooltext);
                    this.setState({});
                    if (GlobalVariables.Zvuk === 1) {
                        this.props.m_pwin.m_sndSound.Play(19, 'center', 0, 0); //???pls[1]
                    }
                } else if (
                    this.tooltext !== this.GetLanguageText('Change') &&
                    this.tooltext === '' &&
                    this.CheckChange24(1) === false &&
                    this.hand > 0 &&
                    this.hand < 5 &&
                    this.KoiPuskaParvi === 1 &&
                    this.KoiENaRed === 1 &&
                    this.Faza === 2
                ) {
                    this.tooltext = this.GetLanguageText('Close');
                    this.obiaviavaneRN = this.DrawObiaviavanePNG(this.postooltip.x, this.postooltip.y, this.tooltext);
                    this.setState({});
                    if (GlobalVariables.Zvuk === 1) {
                        this.props.m_pwin.m_sndSound.Play(20, 'center', 0, 0); //???pls[1]
                    }
                }
                if (this.hand > 0 && this.hand < 5 && this.k[24] !== 0 && this.KoiPuskaParvi === 1) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                    this.setState({});
                    return;
                } else {
                    this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                    this.setState({});
                    return;
                }
            } else {
                if (this.tooltext !== '') {
                    this.tooltext = '';
                    this.obiaviavaneRN = null;
                    this.setState({});
                }
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    CheckChange24(player: number) {
        for (let h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 101) {
                return true;
            }
        }
        return false;
    }

    SendCards() {
        this.P2G = false;
        for (let a = 7; a < 13; a++) {
            this.SendGame('$|c|' + this.IntToString(this.k[a]));
        }
        for (let a = 1; a < 7; a++) {
            this.SendGame('$|c|' + this.IntToString(this.k[a]));
        }
        for (let a = 13; a < 25; a++) {
            this.SendGame('$|c|' + this.IntToString(this.k[a]));
        }
    }

    async Init() {
        this.Players = 2;
        this.pause = 0;
        this.pause_player = '';
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        this.P1G = false;
        this.check_change = false;
        this.check_zatvoreno = false;
        this.endgame = false;
        GlobalVariables.OtkritiKarti = 0;
        this.m_rez.m_orez1 = 0;
        this.m_rez.m_orez2 = 0;
        if (this.SP) {
            this.Name[1] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[2]', 'Computer2');
        }
        this.wait_opplayers[2] = false;
        await this.LoadBitmaps();
        if ((this.OP && this.Server) || this.SP) {
            this.KoiZapochva = this.KoiPuskaParvi = this.KoiENaRed = this.RandKoiZapochva(this.Players);
        }

        this.StrelkaPos[1].x = 550;
        this.StrelkaPos[1].y = 350;
        this.StrelkaPos[2].x = 220;
        this.StrelkaPos[2].y = 130;
        this.StrelkaPos[3].x = 220;
        this.StrelkaPos[3].y = 130;

        this.NamePos[1].x = 360;
        this.NamePos[1].y = 388;
        this.NamePos[2].x = 360;
        this.NamePos[2].y = 136;

        this.Pos2040[1].x = 330;
        this.Pos2040[1].y = 368;
        this.Pos2040[2].x = 330;
        this.Pos2040[2].y = 112;
        this.PosNaddavane[1] = this.Pos2040[1];
        this.PosNaddavane[2] = this.Pos2040[2];

        this.CardsPos[0].x = 66;
        this.CardsPos[0].y = 200;

        this.CardsPos[1].x = 222;
        this.CardsPos[1].y = 418;
        this.CardsPos[2].x = 280;
        this.CardsPos[2].y = 418;
        this.CardsPos[3].x = 338;
        this.CardsPos[3].y = 418;
        this.CardsPos[4].x = 396;
        this.CardsPos[4].y = 418;
        this.CardsPos[5].x = 454;
        this.CardsPos[5].y = 418;
        this.CardsPos[6].x = 512;
        this.CardsPos[6].y = 418;

        this.CardsPos[7].x = 512;
        this.CardsPos[7].y = 22;
        this.CardsPos[8].x = 454;
        this.CardsPos[8].y = 22;
        this.CardsPos[9].x = 396;
        this.CardsPos[9].y = 22;
        this.CardsPos[10].x = 338;
        this.CardsPos[10].y = 22;
        this.CardsPos[11].x = 280;
        this.CardsPos[11].y = 22;
        this.CardsPos[12].x = 222;
        this.CardsPos[12].y = 22;

        this.CardsPos[33].x = 360;
        this.CardsPos[33].y = 270;
        this.CardsPos[34].x = 360;
        this.CardsPos[34].y = 170;

        this.CardsPos[24].x = 127;
        this.CardsPos[24].y = 200;
    }

    InitGame() {
        for (let i: number = 1; i < 13; i++) {
            this.vz[i] = 0;
        }
        for (let i: number = 1; i < 25; i++) {
            this.kd[i] = 0;
        }
        this.player_cards = 6;
        if (this.KoiZapochva === 1) {
            this.KoiZapochva = 2;
        } else {
            this.KoiZapochva = 1;
        }
        this.KoiPuskaParvi = this.KoiENaRed = this.KoiZapochva;
        this.Faza = 0;
        this.o2040 = 0;
        this.movecard = 0;
        this.close = 0;
        if (this.SP) {
            this.endgame = false;
        }
        this.check_change = false;
        this.check_zatvoreno = false;
        this.movekoz = 0;
        this.kapo1 = true;
        this.kapo2 = true;
        this.m_rez.m_rez1 = 0;
        this.m_rez.m_rez2 = 0;
        this.ReloadPoints();
        this.hand = 0;
        if (this.SP) {
            this.Razdavane1 = true;
        }
        for (let i: number = 0; i <= 24; i++) {
            this.k[i] = 0;
            this.CardsPoint[i].x = this.CardsPos[0].x;
            this.CardsPoint[i].y = this.CardsPos[0].y;
        }
        this.CardsPoint[24].x = this.CardsPos[24].x;
        this.CardsPoint[24].y = this.CardsPos[24].y;
        this.P1puska = 0;
        this.P2puska = 0;
        this.strelka = 0;
        if ((this.OP && this.Server) || this.SP) {
            this.RandCards();
        }
        if ((this.OP && this.Server) || this.SP) {
            this.LoadCards(false, true);
        }
        if (this.OP) {
            if (!this.watch) {
                this.SendGame('$|y');
            }
            this.op_start = false;
        }
        this.obiaviavaneRN = null;
        this.setState({});
    }

    LoadCards(begin: boolean, sound: boolean) {
        for (let i: number = 1; i < 25; i++) {
            if (!begin) {
                if (this.k[i] === 11) {
                    this.CardP[i].image = this.CardPicture[1];
                }
                if (this.k[i] === 12) {
                    this.CardP[i].image = this.CardPicture[2];
                }
                if (this.k[i] === 13) {
                    this.CardP[i].image = this.CardPicture[3];
                }
                if (this.k[i] === 14) {
                    this.CardP[i].image = this.CardPicture[4];
                }
                if (this.k[i] === 15) {
                    this.CardP[i].image = this.CardPicture[5];
                }
                if (this.k[i] === 16) {
                    this.CardP[i].image = this.CardPicture[6];
                }
                if (this.k[i] === 21) {
                    this.CardP[i].image = this.CardPicture[7];
                }
                if (this.k[i] === 22) {
                    this.CardP[i].image = this.CardPicture[8];
                }
                if (this.k[i] === 23) {
                    this.CardP[i].image = this.CardPicture[9];
                }
                if (this.k[i] === 24) {
                    this.CardP[i].image = this.CardPicture[10];
                }
                if (this.k[i] === 25) {
                    this.CardP[i].image = this.CardPicture[11];
                }
                if (this.k[i] === 26) {
                    this.CardP[i].image = this.CardPicture[12];
                }
                if (this.k[i] === 31) {
                    this.CardP[i].image = this.CardPicture[13];
                }
                if (this.k[i] === 32) {
                    this.CardP[i].image = this.CardPicture[14];
                }
                if (this.k[i] === 33) {
                    this.CardP[i].image = this.CardPicture[15];
                }
                if (this.k[i] === 34) {
                    this.CardP[i].image = this.CardPicture[16];
                }
                if (this.k[i] === 35) {
                    this.CardP[i].image = this.CardPicture[17];
                }
                if (this.k[i] === 36) {
                    this.CardP[i].image = this.CardPicture[18];
                }
                if (this.k[i] === 41) {
                    this.CardP[i].image = this.CardPicture[19];
                }
                if (this.k[i] === 42) {
                    this.CardP[i].image = this.CardPicture[20];
                }
                if (this.k[i] === 43) {
                    this.CardP[i].image = this.CardPicture[21];
                }
                if (this.k[i] === 44) {
                    this.CardP[i].image = this.CardPicture[22];
                }
                if (this.k[i] === 45) {
                    this.CardP[i].image = this.CardPicture[23];
                }
                if (this.k[i] === 46) {
                    this.CardP[i].image = this.CardPicture[24];
                }
            } else {
                if (this.Naddavane === 1) {
                    if (this.k[i] === 101) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 105) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 102) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 103) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 104) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 106) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 21) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 25) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 22) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 23) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 24) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 26) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 31) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 35) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 32) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 33) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 34) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 36) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 41) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 45) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 42) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 43) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 44) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 46) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                }
                if (this.Naddavane === 2) {
                    if (this.k[i] === 11) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 15) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 12) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 13) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 14) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 16) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 101) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 105) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 102) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 103) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 104) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 106) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 31) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 35) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 32) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 33) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 34) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 36) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 41) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 45) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 42) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 43) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 44) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 46) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                }
                if (this.Naddavane === 3) {
                    if (this.k[i] === 11) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 15) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 12) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 13) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 14) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 16) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 21) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 25) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 22) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 23) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 24) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 26) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 101) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 105) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 102) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 103) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 104) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 106) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 41) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 45) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 42) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 43) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 44) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 46) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                }
                if (this.Naddavane === 4) {
                    if (this.k[i] === 11) {
                        this.CardP[i].image = this.CardPicture[1];
                    }
                    if (this.k[i] === 15) {
                        this.CardP[i].image = this.CardPicture[2];
                    }
                    if (this.k[i] === 12) {
                        this.CardP[i].image = this.CardPicture[3];
                    }
                    if (this.k[i] === 13) {
                        this.CardP[i].image = this.CardPicture[4];
                    }
                    if (this.k[i] === 14) {
                        this.CardP[i].image = this.CardPicture[5];
                    }
                    if (this.k[i] === 16) {
                        this.CardP[i].image = this.CardPicture[6];
                    }
                    if (this.k[i] === 21) {
                        this.CardP[i].image = this.CardPicture[7];
                    }
                    if (this.k[i] === 25) {
                        this.CardP[i].image = this.CardPicture[8];
                    }
                    if (this.k[i] === 22) {
                        this.CardP[i].image = this.CardPicture[9];
                    }
                    if (this.k[i] === 23) {
                        this.CardP[i].image = this.CardPicture[10];
                    }
                    if (this.k[i] === 24) {
                        this.CardP[i].image = this.CardPicture[11];
                    }
                    if (this.k[i] === 26) {
                        this.CardP[i].image = this.CardPicture[12];
                    }
                    if (this.k[i] === 31) {
                        this.CardP[i].image = this.CardPicture[13];
                    }
                    if (this.k[i] === 35) {
                        this.CardP[i].image = this.CardPicture[14];
                    }
                    if (this.k[i] === 32) {
                        this.CardP[i].image = this.CardPicture[15];
                    }
                    if (this.k[i] === 33) {
                        this.CardP[i].image = this.CardPicture[16];
                    }
                    if (this.k[i] === 34) {
                        this.CardP[i].image = this.CardPicture[17];
                    }
                    if (this.k[i] === 36) {
                        this.CardP[i].image = this.CardPicture[18];
                    }
                    if (this.k[i] === 101) {
                        this.CardP[i].image = this.CardPicture[19];
                    }
                    if (this.k[i] === 105) {
                        this.CardP[i].image = this.CardPicture[20];
                    }
                    if (this.k[i] === 102) {
                        this.CardP[i].image = this.CardPicture[21];
                    }
                    if (this.k[i] === 103) {
                        this.CardP[i].image = this.CardPicture[22];
                    }
                    if (this.k[i] === 104) {
                        this.CardP[i].image = this.CardPicture[23];
                    }
                    if (this.k[i] === 106) {
                        this.CardP[i].image = this.CardPicture[24];
                    }
                }
            }
        }
        //if(GlobalVariables.Zvuk==1 && begin === false && sound === true)m_sndSound.PlayFile("rand_cards", true);
        if (GlobalVariables.Zvuk === 1 && begin === false && sound === true) {
            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        }
    }

    RandCards() {
        let Numbers: number[] = [];
        for (let i: number = 0; i < 24; i++) {
            Numbers[i] = i + 1;
        }
        let randomArray = this.randomShuffle(Numbers);
        for (let i = 1; i < 25; i++) {
            if (randomArray[i - 1] === 1) {
                this.k[i] = 11;
            }
            if (randomArray[i - 1] === 2) {
                this.k[i] = 12;
            }
            if (randomArray[i - 1] === 3) {
                this.k[i] = 13;
            }
            if (randomArray[i - 1] === 4) {
                this.k[i] = 14;
            }
            if (randomArray[i - 1] === 5) {
                this.k[i] = 15;
            }
            if (randomArray[i - 1] === 6) {
                this.k[i] = 16;
            }
            if (randomArray[i - 1] === 7) {
                this.k[i] = 21;
            }
            if (randomArray[i - 1] === 8) {
                this.k[i] = 22;
            }
            if (randomArray[i - 1] === 9) {
                this.k[i] = 23;
            }
            if (randomArray[i - 1] === 10) {
                this.k[i] = 24;
            }
            if (randomArray[i - 1] === 11) {
                this.k[i] = 25;
            }
            if (randomArray[i - 1] === 12) {
                this.k[i] = 26;
            }
            if (randomArray[i - 1] === 13) {
                this.k[i] = 31;
            }
            if (randomArray[i - 1] === 14) {
                this.k[i] = 32;
            }
            if (randomArray[i - 1] === 15) {
                this.k[i] = 33;
            }
            if (randomArray[i - 1] === 16) {
                this.k[i] = 34;
            }
            if (randomArray[i - 1] === 17) {
                this.k[i] = 35;
            }
            if (randomArray[i - 1] === 18) {
                this.k[i] = 36;
            }
            if (randomArray[i - 1] === 19) {
                this.k[i] = 41;
            }
            if (randomArray[i - 1] === 20) {
                this.k[i] = 42;
            }
            if (randomArray[i - 1] === 21) {
                this.k[i] = 43;
            }
            if (randomArray[i - 1] === 22) {
                this.k[i] = 44;
            }
            if (randomArray[i - 1] === 23) {
                this.k[i] = 45;
            }
            if (randomArray[i - 1] === 24) {
                this.k[i] = 46;
            }
        }
        if (this.k[24] > 10 && this.k[24] < 20) {
            this.Naddavane = 1;
        }
        if (this.k[24] > 20 && this.k[24] < 30) {
            this.Naddavane = 2;
        }
        if (this.k[24] > 30 && this.k[24] < 40) {
            this.Naddavane = 3;
        }
        if (this.k[24] > 40 && this.k[24] < 50) {
            this.Naddavane = 4;
        }
    }

    ReloadPoints() {
        this.setState({});
    }

    async InitSP() {
        this.loading = true;
        this.SP = true;
        this.OP = false;
        await this.Init();
        this.InitGame();
        console.log('Names:', this.Name);
        this.loading = false;
    }

    async InitOP(server: boolean) {
        this.loading = true;
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        this.InitGame();
        this.InitTimer(25);
        this.loading = false;
    }

    KartaZaRazdavane() {
        if (this.KoiZapochva === 1) {
            if (this.Razdavane1 === true) {
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        if (this.KoiZapochva === 2) {
            if (this.Razdavane1 === true) {
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        return 1;
    }

    PuskCard(card: number, player: number) {
        for (let h: number = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === card) {
                return h;
            }
        }
        return 1 + (player - 1) * 6;
    }

    DeleteCard(card: number) {
        if (card > 0 && card < 7) {
            for (let i: number = card; i < 6; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i: number = 6; i > 0; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 6 && card < 13) {
            for (let i: number = card; i < 12; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i: number = 12; i > 6; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
    }

    ReturnSortCards(card: number) {
        if (this.Naddavane === 1) {
            if (card === 11) {
                return 101;
            }
            if (card === 12) {
                return 105;
            }
            if (card === 13) {
                return 102;
            }
            if (card === 14) {
                return 103;
            }
            if (card === 15) {
                return 104;
            }
            if (card === 16) {
                return 106;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 25;
            }
            if (card === 23) {
                return 22;
            }
            if (card === 24) {
                return 23;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 26;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 35;
            }
            if (card === 33) {
                return 32;
            }
            if (card === 34) {
                return 33;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 36;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 45;
            }
            if (card === 43) {
                return 42;
            }
            if (card === 44) {
                return 43;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 46;
            }
        }
        if (this.Naddavane === 2) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 15;
            }
            if (card === 13) {
                return 12;
            }
            if (card === 14) {
                return 13;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 16;
            }
            if (card === 21) {
                return 101;
            }
            if (card === 22) {
                return 105;
            }
            if (card === 23) {
                return 102;
            }
            if (card === 24) {
                return 103;
            }
            if (card === 25) {
                return 104;
            }
            if (card === 26) {
                return 106;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 35;
            }
            if (card === 33) {
                return 32;
            }
            if (card === 34) {
                return 33;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 36;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 45;
            }
            if (card === 43) {
                return 42;
            }
            if (card === 44) {
                return 43;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 46;
            }
        }
        if (this.Naddavane === 3) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 15;
            }
            if (card === 13) {
                return 12;
            }
            if (card === 14) {
                return 13;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 16;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 25;
            }
            if (card === 23) {
                return 22;
            }
            if (card === 24) {
                return 23;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 26;
            }
            if (card === 31) {
                return 101;
            }
            if (card === 32) {
                return 105;
            }
            if (card === 33) {
                return 102;
            }
            if (card === 34) {
                return 103;
            }
            if (card === 35) {
                return 104;
            }
            if (card === 36) {
                return 106;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 45;
            }
            if (card === 43) {
                return 42;
            }
            if (card === 44) {
                return 43;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 46;
            }
        }
        if (this.Naddavane === 4) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 15;
            }
            if (card === 13) {
                return 12;
            }
            if (card === 14) {
                return 13;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 16;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 25;
            }
            if (card === 23) {
                return 22;
            }
            if (card === 24) {
                return 23;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 26;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 35;
            }
            if (card === 33) {
                return 32;
            }
            if (card === 34) {
                return 33;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 36;
            }
            if (card === 41) {
                return 101;
            }
            if (card === 42) {
                return 105;
            }
            if (card === 43) {
                return 102;
            }
            if (card === 44) {
                return 103;
            }
            if (card === 45) {
                return 104;
            }
            if (card === 46) {
                return 106;
            }
        }
        return 0;
    }

    SortCards(type: number) {
        let sort: number[] = []; //[13]
        let temps: number;
        let tempk: number;
        let tempc: string;
        if (type === 0) {
            for (let i: number = 1; i < 7; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i: number = 1; i < 7; i++) {
                for (let a: number = 1; a < 6; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i: number = 7; i < 13; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i: number = 7; i < 13; i++) {
                for (let a: number = 7; a < 12; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i: number = 13; i < 25; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
            }
        }
        if (type === 11) {
            for (let i: number = 1; i < 7; i++) {
                for (let a: number = 1; a < 6; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (type === 12) {
            for (let i: number = 7; i < 13; i++) {
                for (let a: number = 7; a < 12; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (type === 2) {
            let ocard: number = 24 - this.hand * 2;
            if (this.movecard === 1 || this.movecard === 2) {
                ocard = ocard + 1;
            }
            for (let i: number = 13; i < ocard; i++) {
                this.k[i] = this.k[i + 1];
                this.CardP[i].image = this.CardP[i + 1].image;
            }
            for (let i: number = ocard; i < 24; i++) {
                this.k[i] = 0;
            }
        }
    }

    Strelka() {
        this.hand = this.hand + 1;
        let addrez: number = 0;
        if (this.P1puska === 12 || this.P2puska === 12) {
            addrez = addrez + 2;
        }
        if (this.P1puska === 13 || this.P2puska === 13) {
            addrez = addrez + 3;
        }
        if (this.P1puska === 14 || this.P2puska === 14) {
            addrez = addrez + 4;
        }
        if (this.P1puska === 15 || this.P2puska === 15) {
            addrez = addrez + 10;
        }
        if (this.P1puska === 16 || this.P2puska === 16) {
            addrez = addrez + 11;
        }
        if (this.P1puska === 22 || this.P2puska === 22) {
            addrez = addrez + 2;
        }
        if (this.P1puska === 23 || this.P2puska === 23) {
            addrez = addrez + 3;
        }
        if (this.P1puska === 24 || this.P2puska === 24) {
            addrez = addrez + 4;
        }
        if (this.P1puska === 25 || this.P2puska === 25) {
            addrez = addrez + 10;
        }
        if (this.P1puska === 26 || this.P2puska === 26) {
            addrez = addrez + 11;
        }
        if (this.P1puska === 32 || this.P2puska === 32) {
            addrez = addrez + 2;
        }
        if (this.P1puska === 33 || this.P2puska === 33) {
            addrez = addrez + 3;
        }
        if (this.P1puska === 34 || this.P2puska === 34) {
            addrez = addrez + 4;
        }
        if (this.P1puska === 35 || this.P2puska === 35) {
            addrez = addrez + 10;
        }
        if (this.P1puska === 36 || this.P2puska === 36) {
            addrez = addrez + 11;
        }
        if (this.P1puska === 42 || this.P2puska === 42) {
            addrez = addrez + 2;
        }
        if (this.P1puska === 43 || this.P2puska === 43) {
            addrez = addrez + 3;
        }
        if (this.P1puska === 44 || this.P2puska === 44) {
            addrez = addrez + 4;
        }
        if (this.P1puska === 45 || this.P2puska === 45) {
            addrez = addrez + 10;
        }
        if (this.P1puska === 46 || this.P2puska === 46) {
            addrez = addrez + 11;
        }
        if (this.P1puska === 102 || this.P2puska === 102) {
            addrez = addrez + 2;
        }
        if (this.P1puska === 103 || this.P2puska === 103) {
            addrez = addrez + 3;
        }
        if (this.P1puska === 104 || this.P2puska === 104) {
            addrez = addrez + 4;
        }
        if (this.P1puska === 105 || this.P2puska === 105) {
            addrez = addrez + 10;
        }
        if (this.P1puska === 106 || this.P2puska === 106) {
            addrez = addrez + 11;
        }
        if (this.hand === 12) {
            addrez = addrez + 11;
        }
        if (this.P1puska > 100 || this.P2puska > 100) {
            if (this.P1puska > this.P2puska) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                return 1;
            }
            if (this.P2puska > this.P1puska) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                return 2;
            }
        }
        //Ako  se iska pika
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            for (let i: number = 46; i > 40; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska kupa
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            for (let i: number = 36; i > 30; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska karo
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            for (let i: number = 26; i > 20; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        //Ako se iska spatia
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            for (let i: number = 16; i > 10; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez1 = this.m_rez.m_rez1 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + addrez;
                    return 2;
                }
            }
        }
        return 1;
    }

    SendKoiENaRed() {
        let koi = '2';
        if (this.KoiENaRed > 1) {
            koi = '1';
        }
        this.SendGame('$|b|' + koi);
    }

    InitNewGame() {
        this.op_start = false;
        this.m_rez.m_orez1 = 0;
        this.m_rez.m_orez2 = 0;
        this.pause = 0;
        this.pause_player = '';
        this.InitGame();
        this.last_Name[2] = this.Name[2];
    }

    Timer1() {
        let cmove: boolean = this.Move;
        if (this.SP === true && this.endgame === false) {
            if ((this.movekoz === 1 || this.movekoz === 2) && this.Move !== true && this.Faza === 2) {
                let tempc: string;
                if (this.movekoz === 1) {
                    this.movekoz = 10;
                    let card9: number = this.PuskCard(101, 1);
                    tempc = this.CardP[card9].image;
                    this.CardsPoint[card9].x = this.CardsPos[card9].x;
                    this.CardsPoint[card9].y = this.CardsPos[card9].y;
                    for (let i: number = card9; i < 7; i++) {
                        if (i < 6) {
                            this.CardP[i].image = this.CardP[i + 1].image;
                            this.k[i] = this.k[i + 1];
                        }
                        if (i === 6) {
                            this.DeleteCard(6);
                        }
                    }
                    this.k[6] = this.k[24];
                    this.k[24] = 101;
                    this.CardP[6].image = this.CardP[24].image;
                    this.CardP[24].image = tempc;
                    this.CardsPoint[6].x = this.CardsPos[24].x;
                    this.CardsPoint[6].y = this.CardsPos[24].y;
                    this.BeginMove(this.CardsPos[24], this.CardsPos[6], 6);
                    this.setState({});
                }
                if (this.movekoz === 2) {
                    this.movekoz = 20;
                    let card9: number = this.PuskCard(101, 2);
                    tempc = this.CardP[card9].image;
                    this.CardsPoint[card9].x = this.CardsPos[card9].x;
                    this.CardsPoint[card9].y = this.CardsPos[card9].y;
                    for (let i: number = card9; i < 13; i++) {
                        if (i < 12) {
                            this.CardP[i].image = this.CardP[i + 1].image;
                            this.k[i] = this.k[i + 1];
                        }
                        if (i === 12) {
                            this.DeleteCard(12);
                        }
                    }
                    this.k[12] = this.k[24];
                    this.k[24] = 101;
                    this.CardP[12].image = this.CardP[24].image;
                    this.CardP[24].image = tempc;
                    this.CardsPoint[12].x = this.CardsPos[24].x;
                    this.CardsPoint[12].y = this.CardsPos[24].y;
                    this.BeginMove(this.CardsPos[24], this.CardsPos[12], 12);
                    this.setState({});
                }
            }
            if ((this.movekoz === 10 || this.movekoz === 20) && this.Move !== true && this.Faza === 2) {
                if (this.movekoz === 10) {
                    this.SortCards(11);
                    this.setState({});
                }
                if (this.movekoz === 20) {
                    this.SortCards(12);
                    this.setState({});
                }
                this.movekoz = 0;
            }
            if ((this.movecard === 1 || this.movecard === 2) && this.Move !== true && this.hand < 7 && this.Faza === 2) {
                if (this.movecard === 1) {
                    this.movecard = 10;
                    this.SortCards(11);
                    if (this.hand < 6) {
                        this.k[12] = this.k[13];
                        this.CardP[12].image = this.CardP[13].image;
                        this.CardsPoint[12].x = this.CardsPoint[13].x;
                        this.CardsPoint[12].y = this.CardsPoint[13].y;
                    }
                    if (this.hand === 6) {
                        this.k[12] = this.k[24];
                        this.k[24] = 0;
                        this.CardP[12].image = this.CardP[24].image;
                        this.CardsPoint[12].x = this.CardsPoint[24].x;
                        this.CardsPoint[12].y = this.CardsPoint[24].y;
                        this.Faza = 3;
                    }
                    this.BeginMove(this.CardsPoint[12], this.CardsPos[12], 12);
                    this.setState({});
                }
                if (this.movecard === 2) {
                    this.movecard = 20;
                    this.SortCards(12);
                    if (this.hand < 6) {
                        this.k[6] = this.k[13];
                        this.CardP[6].image = this.CardP[13].image;
                        this.CardsPoint[6].x = this.CardsPoint[13].x;
                        this.CardsPoint[6].y = this.CardsPoint[13].y;
                    }
                    if (this.hand === 6) {
                        this.k[6] = this.k[24];
                        this.k[24] = 0;
                        this.CardP[6].image = this.CardP[24].image;
                        this.CardsPoint[6].x = this.CardsPoint[24].x;
                        this.CardsPoint[6].y = this.CardsPoint[24].y;
                        this.Faza = 3;
                    }
                    this.BeginMove(this.CardsPoint[6], this.CardsPos[6], 6);
                }
                if (this.hand < 6) {
                    this.SortCards(2);
                }
                this.setState({});
            }
            if ((this.movecard === 10 || this.movecard === 20) && this.Move !== true && this.hand < 7) {
                if (this.movecard === 10) {
                    this.SortCards(12);
                }
                if (this.movecard === 20) {
                    this.SortCards(11);
                }
                this.movecard = 0;
                this.setState({});
            }
            if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0) {
                if (this.o2040 > 0) {
                    this.o2040 = 0;
                    this.anons = 0;
                }
                this.strelka = this.Strelka();
                if (this.Faza === 3 || this.close > 0) {
                    this.player_cards = this.player_cards - 1;
                }
                this.ReloadPoints();
                this.vz[this.hand] = this.strelka;
                if (this.strelka === 1) {
                    this.kapo1 = false;
                }
                if (this.strelka === 2) {
                    this.kapo2 = false;
                }
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                this.setState({});
            }
            if (this.Move === true) {
                this.MoveCard(!this.Razdavane1);
                this.setState({});
            }
            if (this.Razdavane1 === true && this.Move !== true && this.Faza < 2) {
                let karta = this.KartaZaRazdavane();
                //console.log('this.CardsPoint[karta]', karta, this.CardsPoint[karta]);
                this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                this.setState({});
            }
            if (this.Razdavane1 !== true && this.Move !== true && this.Faza < 2) {
                this.Faza = 2;
                this.SortCards(0);
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                this.setState({});
            }
        }
        if (this.OP === true) {
            if (this.Server && this.endgame && (this.P2G || !this.P2OP) && this.P1G && this.Faza > 0) {
                if (this.P2OP === true) {
                    this.P2G = false;
                }
                this.P1G = false;
                this.Razdavane1 = true;
                if (this.m_rez.m_orez1 < 11 && this.m_rez.m_orez2 < 11) {
                    this.InitGame();
                } else {
                    this.InitNewGame();
                }
                if (this.P2OP || this.HaveWatch) {
                    this.SendKoiENaRed();
                    this.SendCards();
                }
                this.endgame = false;
                this.online_timer = this.online_timer_time;
                this.setState({});
            }
            if (this.Server) {
                if (!this.endgame) {
                    if ((this.movekoz === 1 || this.movekoz === 2) && this.Move !== true && this.Faza === 2) {
                        let tempc: string;
                        if (this.movekoz === 1) {
                            this.movekoz = 10;
                            let card9 = this.PuskCard(101, 1);
                            tempc = this.CardP[card9].image;
                            this.CardsPoint[card9].x = this.CardsPos[card9].x;
                            this.CardsPoint[card9].y = this.CardsPos[card9].y;
                            for (let i: number = card9; i < 7; i++) {
                                if (i < 6) {
                                    this.CardP[i].image = this.CardP[i + 1].image;
                                    this.k[i] = this.k[i + 1];
                                }
                                if (i === 6) {
                                    this.DeleteCard(6);
                                }
                            }
                            this.k[6] = this.k[24];
                            this.k[24] = 101;
                            this.CardP[6].image = this.CardP[24].image;
                            this.CardP[24].image = tempc;
                            this.CardsPoint[6].x = this.CardsPos[24].x;
                            this.CardsPoint[6].y = this.CardsPos[24].y;
                            this.BeginMove(this.CardsPos[24], this.CardsPos[6], 6);
                            this.setState({});
                        }
                        if (this.movekoz === 2) {
                            this.movekoz = 20;
                            let card9 = this.PuskCard(101, 2);
                            tempc = this.CardP[card9].image;
                            this.CardsPoint[card9].x = this.CardsPos[card9].x;
                            this.CardsPoint[card9].y = this.CardsPos[card9].y;
                            for (let i: number = card9; i < 13; i++) {
                                if (i < 12) {
                                    this.CardP[i].image = this.CardP[i + 1].image;
                                    this.k[i] = this.k[i + 1];
                                }
                                if (i === 12) {
                                    this.DeleteCard(12);
                                }
                            }
                            this.k[12] = this.k[24];
                            this.k[24] = 101;
                            this.CardP[12].image = this.CardP[24].image;
                            this.CardP[24].image = tempc;
                            this.CardsPoint[12].x = this.CardsPos[24].x;
                            this.CardsPoint[12].y = this.CardsPos[24].y;
                            this.BeginMove(this.CardsPos[24], this.CardsPos[12], 12);
                            this.setState({});
                        }
                    }
                    if ((this.movekoz === 10 || this.movekoz === 20) && this.Move !== true && this.Faza === 2) {
                        if (this.movekoz === 10) {
                            this.SortCards(11);
                        }
                        if (this.movekoz === 20) {
                            this.SortCards(12);
                        }
                        this.movekoz = 0;
                        this.setState({});
                    }
                    if ((this.movecard === 1 || this.movecard === 2) && this.Move !== true && this.hand < 7 && this.Faza === 2) {
                        if (this.movecard === 1) {
                            this.movecard = 10;
                            this.SortCards(11);
                            if (this.hand < 6) {
                                this.k[12] = this.k[13];
                                this.CardP[12].image = this.CardP[13].image;
                                this.CardsPoint[12].x = this.CardsPoint[13].x;
                                this.CardsPoint[12].y = this.CardsPoint[13].y;
                            }
                            if (this.hand === 6) {
                                this.k[12] = this.k[24];
                                this.k[24] = 0;
                                this.CardP[12].image = this.CardP[24].image;
                                this.CardsPoint[12].x = this.CardsPoint[24].x;
                                this.CardsPoint[12].y = this.CardsPoint[24].y;
                                this.Faza = 3;
                            }
                            this.BeginMove(this.CardsPoint[12], this.CardsPos[12], 12);
                            this.setState({});
                        }
                        if (this.movecard === 2) {
                            this.movecard = 20;
                            this.SortCards(12);
                            if (this.hand < 6) {
                                this.k[6] = this.k[13];
                                this.CardP[6].image = this.CardP[13].image;
                                this.CardsPoint[6].x = this.CardsPoint[13].x;
                                this.CardsPoint[6].y = this.CardsPoint[13].y;
                            }
                            if (this.hand === 6) {
                                this.k[6] = this.k[24];
                                this.k[24] = 0;
                                this.CardP[6].image = this.CardP[24].image;
                                this.CardsPoint[6].x = this.CardsPoint[24].x;
                                this.CardsPoint[6].y = this.CardsPoint[24].y;
                                this.Faza = 3;
                            }
                            this.BeginMove(this.CardsPoint[6], this.CardsPos[6], 6);
                        }
                        if (this.hand < 6) {
                            this.SortCards(2);
                        }
                        this.setState({});
                    }
                    if ((this.movecard === 10 || this.movecard === 20) && this.Move !== true && this.hand < 7) {
                        if (this.movecard === 10) {
                            this.SortCards(12);
                        }
                        if (this.movecard === 20) {
                            this.SortCards(11);
                        }
                        this.movecard = 0;
                        this.setState({});
                    }
                    if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0) {
                        if (this.o2040 > 0) {
                            this.o2040 = 0;
                            this.anons = 0;
                        }
                        this.strelka = this.Strelka();
                        if (this.Faza === 3 || this.close > 0) {
                            this.player_cards = this.player_cards - 1;
                        }
                        this.ReloadPoints();
                        this.vz[this.hand] = this.strelka;
                        if (this.strelka === 1) {
                            this.kapo1 = false;
                        }
                        if (this.strelka === 2) {
                            this.kapo2 = false;
                        }
                        this.props.m_pwin.KillTimer(4);
                        this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                        this.setState({});
                    }
                    if (this.Move === true) {
                        this.MoveCard(!this.Razdavane1);
                        this.setState({});
                    }
                    if (this.Razdavane1 === true && this.Move !== true && this.Faza < 2) {
                        let karta = this.KartaZaRazdavane();
                        this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                        this.setState({});
                    }
                    if (this.Razdavane1 !== true && this.Move !== true && this.Faza < 2) {
                        this.Faza = 2;
                        this.SortCards(0);
                        this.online_timer = this.online_timer_time;
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                        this.setState({});
                    }
                }
            } else {
                if ((this.movekoz === 1 || this.movekoz === 2) && this.Move !== true && this.Faza === 2) {
                    let tempc: string;
                    if (this.movekoz === 1) {
                        this.movekoz = 10;
                        let card9 = this.PuskCard(101, 1);
                        tempc = this.CardP[card9].image;
                        this.CardsPoint[card9].x = this.CardsPos[card9].x;
                        this.CardsPoint[card9].y = this.CardsPos[card9].y;
                        for (let i: number = card9; i < 7; i++) {
                            if (i < 6) {
                                this.CardP[i].image = this.CardP[i + 1].image;
                                this.k[i] = this.k[i + 1];
                            }
                            if (i === 6) {
                                this.DeleteCard(6);
                            }
                        }
                        this.k[6] = this.k[24];
                        this.k[24] = 101;
                        this.CardP[6].image = this.CardP[24].image;
                        this.CardP[24].image = tempc;
                        this.CardsPoint[6].x = this.CardsPos[24].x;
                        this.CardsPoint[6].y = this.CardsPos[24].y;
                        this.BeginMove(this.CardsPos[24], this.CardsPos[6], 6);
                        this.setState({});
                    }
                    if (this.movekoz === 2) {
                        this.movekoz = 20;
                        let card9 = this.PuskCard(101, 2);
                        tempc = this.CardP[card9].image;
                        this.CardsPoint[card9].x = this.CardsPos[card9].x;
                        this.CardsPoint[card9].y = this.CardsPos[card9].y;
                        for (let i: number = card9; i < 13; i++) {
                            if (i < 12) {
                                this.CardP[i].image = this.CardP[i + 1].image;
                                this.k[i] = this.k[i + 1];
                            }
                            if (i === 12) {
                                this.DeleteCard(12);
                            }
                        }
                        this.k[12] = this.k[24];
                        this.k[24] = 101;
                        this.CardP[12].image = this.CardP[24].image;
                        this.CardP[24].image = tempc;
                        this.CardsPoint[12].x = this.CardsPos[24].x;
                        this.CardsPoint[12].y = this.CardsPos[24].y;
                        this.BeginMove(this.CardsPos[24], this.CardsPos[12], 12);
                        this.setState({});
                    }
                }
                if ((this.movekoz === 10 || this.movekoz === 20) && this.Move !== true && this.Faza === 2) {
                    if (this.movekoz === 10) {
                        this.SortCards(11);
                    }
                    if (this.movekoz === 20) {
                        this.SortCards(12);
                    }
                    this.movekoz = 0;
                    this.setState({});
                }
                if ((this.movecard === 1 || this.movecard === 2) && this.Move !== true && this.hand < 7 && this.Faza === 2) {
                    if (this.movecard === 1) {
                        this.movecard = 10;
                        this.SortCards(11);
                        if (this.hand < 6) {
                            this.k[12] = this.k[13];
                            this.CardP[12].image = this.CardP[13].image;
                            this.CardsPoint[12].x = this.CardsPoint[13].x;
                            this.CardsPoint[12].y = this.CardsPoint[13].y;
                        }
                        if (this.hand === 6) {
                            this.k[12] = this.k[24];
                            this.k[24] = 0;
                            this.CardP[12].image = this.CardP[24].image;
                            this.CardsPoint[12].x = this.CardsPoint[24].x;
                            this.CardsPoint[12].y = this.CardsPoint[24].y;
                            this.Faza = 3;
                        }
                        this.BeginMove(this.CardsPoint[12], this.CardsPos[12], 12);
                        this.setState({});
                    }
                    if (this.movecard === 2) {
                        this.movecard = 20;
                        this.SortCards(12);
                        if (this.hand < 6) {
                            this.k[6] = this.k[13];
                            this.CardP[6].image = this.CardP[13].image;
                            this.CardsPoint[6].x = this.CardsPoint[13].x;
                            this.CardsPoint[6].y = this.CardsPoint[13].y;
                        }
                        if (this.hand === 6) {
                            this.k[6] = this.k[24];
                            this.k[24] = 0;
                            this.CardP[6].image = this.CardP[24].image;
                            this.CardsPoint[6].x = this.CardsPoint[24].x;
                            this.CardsPoint[6].y = this.CardsPoint[24].y;
                            this.Faza = 3;
                        }
                        this.BeginMove(this.CardsPoint[6], this.CardsPos[6], 6);
                        this.setState({});
                    }
                    if (this.hand === 6) {
                        this.setState({});
                    }
                    if (this.hand < 6) {
                        this.SortCards(2);
                    }
                }
                if ((this.movecard === 10 || this.movecard === 20) && this.Move !== true && this.hand < 7) {
                    if (this.movecard === 10) {
                        this.SortCards(12);
                    }
                    if (this.movecard === 20) {
                        this.SortCards(11);
                    }
                    this.movecard = 0;
                    this.setState({});
                }
                if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0) {
                    if (this.o2040 > 0) {
                        this.o2040 = 0;
                        this.anons = 0;
                    }
                    this.strelka = this.Strelka();
                    if (this.Faza === 3 || this.close > 0) {
                        this.player_cards = this.player_cards - 1;
                    }
                    this.ReloadPoints();
                    this.vz[this.hand] = this.strelka;
                    if (this.strelka === 1) {
                        this.kapo1 = false;
                    }
                    if (this.strelka === 2) {
                        this.kapo2 = false;
                    }
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                    this.setState({});
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1);
                    this.setState({});
                }
                if (this.Razdavane1 === true && this.Move !== true && this.Faza < 2) {
                    let karta = this.KartaZaRazdavane();
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                    this.setState({});
                }
                if (this.Razdavane1 !== true && this.Move !== true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                }
            }
        }
        if (cmove !== this.Move) {
            this.Timer1();
        }
    }
    HaveKoz(player: number) {
        for (let h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] > 100) {
                return true;
            }
        }
        return false;
    }

    GetBrojKarti(boia: number, player: number) {
        let br = 0;
        let h: number;
        if (boia === 1) {
            if (this.Naddavane === 1) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 100) {
                        br = br + 1;
                    }
                }
            } else {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 10 && this.k[h] < 20) {
                        br = br + 1;
                    }
                }
            }
        }
        if (boia === 2) {
            if (this.Naddavane === 2) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 100) {
                        br = br + 1;
                    }
                }
            } else {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 20 && this.k[h] < 30) {
                        br = br + 1;
                    }
                }
            }
        }
        if (boia === 3) {
            if (this.Naddavane === 3) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 100) {
                        br = br + 1;
                    }
                }
            } else {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 30 && this.k[h] < 40) {
                        br = br + 1;
                    }
                }
            }
        }
        if (boia === 4) {
            if (this.Naddavane === 4) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 100) {
                        br = br + 1;
                    }
                }
            } else {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] > 40 && this.k[h] < 50) {
                        br = br + 1;
                    }
                }
            }
        }
        return br;
    }

    AddToDetails(card: number) {
        let kdcard = this.k[card];
        if (this.k[card] > 100) {
            if (this.Naddavane === 1) {
                kdcard = this.k[card] - 90;
            }
            if (this.Naddavane === 2) {
                kdcard = this.k[card] - 80;
            }
            if (this.Naddavane === 3) {
                kdcard = this.k[card] - 70;
            }
            if (this.Naddavane === 4) {
                kdcard = this.k[card] - 60;
            }
        }
        for (let i: number = 1; i < 25; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                break;
            }
        }
    }

    CheckCard(card: number, player: number) {
        for (let h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === card) {
                return true;
            }
        }
        return false;
    }

    Check2040(player: number) {
        if (this.hand > 0) {
            if (this.CheckCard(103, player) === true && this.CheckCard(104, player) === true) {
                return 103;
            }
            if (this.CheckCard(13, player) === true && this.CheckCard(14, player) === true) {
                return 13;
            }
            if (this.CheckCard(23, player) === true && this.CheckCard(24, player) === true) {
                return 23;
            }
            if (this.CheckCard(33, player) === true && this.CheckCard(34, player) === true) {
                return 33;
            }
            if (this.CheckCard(43, player) === true && this.CheckCard(44, player) === true) {
                return 43;
            }
        }
        return 0;
    }

    Check2040_2(card: number, player: number) {
        let h: number;
        if (((this.KoiPuskaParvi === 1 && player === 1) || (this.KoiPuskaParvi === 2 && player === 2)) && this.hand > 0) {
            if (card === 103) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 104) {
                        return 2;
                    }
                }
            }
            if (card === 104) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 103) {
                        return 2;
                    }
                }
            }
            if (card === 13) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 14) {
                        return 1;
                    }
                }
            }
            if (card === 14) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 13) {
                        return 1;
                    }
                }
            }
            if (card === 23) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 24) {
                        return 1;
                    }
                }
            }
            if (card === 24) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 23) {
                        return 1;
                    }
                }
            }
            if (card === 33) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 34) {
                        return 1;
                    }
                }
            }
            if (card === 34) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 33) {
                        return 1;
                    }
                }
            }
            if (card === 43) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 44) {
                        return 1;
                    }
                }
            }
            if (card === 44) {
                for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                    if (this.k[h] === 43) {
                        return 1;
                    }
                }
            }
        }
        return 0;
    }

    PuskaneNaKarta(card: number) {
        this.AddToDetails(card);
        let ck2040 = this.Check2040_2(this.k[card], 1);
        if (ck2040 > 0) {
            if (ck2040 === 1) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 20;
            }
            if (ck2040 === 2) {
                this.m_rez.m_rez1 = this.m_rez.m_rez1 + 40;
            }
            this.o2040 = 1;
            this.anons = ck2040;
            this.DrawObiaviavane2040(true);
        }
        if (this.KoiPuskaParvi === 1) {
            this.ParviPuska = this.k[card];
        }
        if (this.KoiPuskaParvi === 2) {
            this.VtoriPuska = this.k[card];
        }

        this.P1puska = this.k[card];
        this.CardsPoint[33].x = this.CardsPoint[card].x;
        this.CardsPoint[33].y = this.CardsPoint[card].y;
        this.CardP[33].image = this.CardP[card].image;
        this.BeginMove(this.CardsPoint[33], this.CardsPos[33], 33);
        if (this.KoiPuskaParvi !== 2) {
            this.KoiENaRed = 2;
        }
        if (this.SP === true) {
            this.props.m_pwin.KillTimer(3);
            this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
        }
        this.DeleteCard(card);
        this.setState({});
        this.ReloadPoints();
        if (this.OP) {
            if (!this.watch) {
                this.online_timer = this.online_timer_time;
                if (this.Server === true) {
                    if (this.P2OP || this.HaveWatch) {
                        this.SendGame('$|k|' + this.IntToString(card));
                    }
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                } else {
                    this.SendGame('$|k|' + this.IntToString(card));
                }
            }
        }
    }

    CheckNGCard(player: number, boia: number) {
        let h: number;
        if (boia === this.Naddavane) {
            for (h = this.player_cards + (player - 1) * 6; h > (player - 1) * 6; h--) {
                if (this.k[h] > 100) {
                    return this.k[h];
                }
            }
        }
        if (boia === 1) {
            for (h = this.player_cards + (player - 1) * 6; h > (player - 1) * 6; h--) {
                if (this.k[h] > 10 && this.k[h] < 20) {
                    return this.k[h];
                }
            }
        }
        if (boia === 2) {
            for (h = this.player_cards + (player - 1) * 6; h > (player - 1) * 6; h--) {
                if (this.k[h] > 20 && this.k[h] < 30) {
                    return this.k[h];
                }
            }
        }
        if (boia === 3) {
            for (h = this.player_cards + (player - 1) * 6; h > (player - 1) * 6; h--) {
                if (this.k[h] > 30 && this.k[h] < 40) {
                    return this.k[h];
                }
            }
        }
        if (boia === 4) {
            for (h = this.player_cards + (player - 1) * 6; h > (player - 1) * 6; h--) {
                if (this.k[h] > 40 && this.k[h] < 50) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    Pusk1KartaZaPuskanePl1() {
        if (this.Faza === 3) {
            if (this.player_cards === 1) {
                this.PuskaneNaKarta(1);
                return;
            }
            if (this.KoiPuskaParvi !== 1) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.GetBrojKarti(1, 1) === 1) {
                    this.PuskaneNaKarta(this.PuskCard(this.CheckNGCard(1, 1), 1));
                    return;
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && this.GetBrojKarti(2, 1) === 1) {
                    this.PuskaneNaKarta(this.PuskCard(this.CheckNGCard(1, 2), 1));
                    return;
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && this.GetBrojKarti(3, 1) === 1) {
                    this.PuskaneNaKarta(this.PuskCard(this.CheckNGCard(1, 3), 1));
                    return;
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && this.GetBrojKarti(4, 1) === 1) {
                    this.PuskaneNaKarta(this.PuskCard(this.CheckNGCard(1, 4), 1));
                    return;
                }
                if (this.ParviPuska > 100) {
                    if (this.GetBrojKarti(this.Naddavane, 1) === 1) {
                        this.PuskaneNaKarta(this.PuskCard(this.CheckNGCard(1, this.Naddavane), 1));
                        return;
                    }
                }
            }
        }
    }

    async Timer3() {
        if (!this.Move) {
            if (this.SP && this.endgame === false) {
                if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                    this.endgame = true;
                    this.setState({});
                    await this.InitRezDlg();
                    return;
                }
                if (
                    this.Faza > 1 &&
                    this.strelka < 1 &&
                    this.KoiENaRed !== 1 &&
                    this.player_cards > 0 &&
                    (this.P1puska === 0 || this.P2puska === 0)
                ) {
                    if (this.Faza === 2 && this.CheckChange24(2) && this.hand > 0 && this.hand < 5 && this.KoiPuskaParvi === 2) {
                        let card9 = this.PuskCard(101, 2);
                        this.movekoz = 2;
                        this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                        return;
                    }
                    this.Intelect(this.KoiENaRed);
                    this.setState({});
                    return;
                }
                if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed === 1 && this.player_cards > 0 && this.P1puska === 0) {
                    if (GlobalVariables.automatic_release) {
                        this.Pusk1KartaZaPuskanePl1();
                    }
                    return;
                }
            }
            if (this.OP && this.endgame === false) {
                if (this.Server) {
                    if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                        this.endgame = true;
                        this.setState({});
                        await this.InitRezDlg();
                        return;
                    }
                    if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed !== 1 && this.player_cards > 0 && this.P2puska === 0) {
                        if (!this.P2OP) {
                            if (this.Faza === 2 && this.CheckChange24(2) && this.hand > 0 && this.hand < 5 && this.KoiPuskaParvi === 2) {
                                let card9 = this.PuskCard(101, 2);
                                this.movekoz = 2;
                                this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                                if (this.HaveWatch) {
                                    this.SendGame('$|a');
                                }
                                return;
                            }
                            this.Intelect(this.KoiENaRed);
                            this.setState({});
                            return;
                        } else {
                            if (
                                this.Faza === 2 &&
                                !this.CheckChange24(2) &&
                                this.hand > 0 &&
                                this.hand < 5 &&
                                this.KoiPuskaParvi === 2 &&
                                this.check_zatvoreno
                            ) {
                                this.Close(2);
                                return;
                            }
                            if (
                                this.Faza === 2 &&
                                this.CheckChange24(2) &&
                                this.hand > 0 &&
                                this.hand < 5 &&
                                this.KoiPuskaParvi === 2 &&
                                this.check_change
                            ) {
                                let card9 = this.PuskCard(101, 2);
                                this.movekoz = 2;
                                this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                                return;
                            }
                            if (this.CheckMessage()) {
                                let ca = this._ttoi(this.ReadMessage());
                                let card = ca + 6;
                                if (this.k[card] === 0) {
                                    this.props.m_pwin.SendErrorToLog('Santase this.Server - Karta=0 ca=' + this.IntToString(ca));
                                    return;
                                }
                                this.AddToDetails(card);
                                let ck2040 = this.Check2040_2(this.k[card], 2);
                                if (ck2040 > 0) {
                                    if (ck2040 === 1) {
                                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 20;
                                    }
                                    if (ck2040 === 2) {
                                        this.m_rez.m_rez2 = this.m_rez.m_rez2 + 40;
                                    }
                                    this.o2040 = 2;
                                    this.anons = ck2040;
                                    this.DrawObiaviavane2040(true);
                                    this.ReloadPoints();
                                }
                                this.CardsPoint[34].x = this.CardsPoint[card].x;
                                this.CardsPoint[34].y = this.CardsPoint[card].y;
                                this.CardP[34].image = this.CardP[card].image;
                                this.BeginMove(this.CardsPoint[card], this.CardsPos[34], 34);
                                this.P2puska = this.k[card];
                                if (this.KoiPuskaParvi === 1) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiPuskaParvi === 2) {
                                    this.ParviPuska = this.k[card];
                                }
                                this.DeleteCard(card);
                                this.setState({});
                                this.online_timer = this.online_timer_time;
                                this.KoiENaRed = 1;
                            }
                            return;
                        }
                    }
                    if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed === 1 && this.player_cards > 0 && this.P1puska === 0) {
                        if (this.online_timer === 0) {
                            this.sit_out_count = this.sit_out_count + 1;
                            if (this.sit_out_count >= this.sit_out_drop) {
                                this.props.m_pwin.AutoSitOut();
                                return;
                            }
                            this.AutoPuskKard();
                        } else if (GlobalVariables.automatic_release) {
                            this.Pusk1KartaZaPuskanePl1();
                        }
                        return;
                    }
                } else {
                    if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                        this.endgame = true;
                        this.setState({});
                        await this.InitRezDlg();
                        if (this.OP && !this.watch && this.props.m_pwin.game !== '') {
                            if (this.CheckMessage()) {
                                this.ClearMessages();
                            }
                            this.SendGame('R');
                        }
                        return;
                    }
                    if (this.watch) {
                        if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed === 1 && this.player_cards > 0 && this.P1puska === 0) {
                            if (
                                this.Faza === 2 &&
                                !this.CheckChange24(1) &&
                                this.hand > 0 &&
                                this.hand < 5 &&
                                this.KoiPuskaParvi === 1 &&
                                this.check_zatvoreno
                            ) {
                                this.Close(1);
                                return;
                            }
                            if (
                                this.Faza === 2 &&
                                this.CheckChange24(1) &&
                                this.hand > 0 &&
                                this.hand < 5 &&
                                this.KoiPuskaParvi === 1 &&
                                this.check_change
                            ) {
                                let card9 = this.PuskCard(101, 1);
                                this.movekoz = 1;
                                this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                                return;
                            }
                            if (this.CheckMessage()) {
                                let ca = this._ttoi(this.ReadMessage());
                                let card = ca;
                                if (this.k[card] === 0 || ca < 1 || ca > 6) {
                                    this.props.m_pwin.SendErrorToLog('Santase - Karta=0 ca=' + this.IntToString(ca));
                                    return;
                                }
                                this.PuskaneNaKarta(card);
                                return;
                            }
                        }
                    }
                    if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed === 2 && this.player_cards > 0 && this.P2puska === 0) {
                        if (
                            this.Faza === 2 &&
                            !this.CheckChange24(2) &&
                            this.hand > 0 &&
                            this.hand < 5 &&
                            this.KoiPuskaParvi === 2 &&
                            this.check_zatvoreno
                        ) {
                            this.Close(2);
                            return;
                        }
                        if (
                            this.Faza === 2 &&
                            this.CheckChange24(2) &&
                            this.hand > 0 &&
                            this.hand < 5 &&
                            this.KoiPuskaParvi === 2 &&
                            this.check_change
                        ) {
                            let card9 = this.PuskCard(101, 2);
                            this.movekoz = 2;
                            this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                            return;
                        }
                        if (this.CheckMessage()) {
                            let ca = this._ttoi(this.ReadMessage());
                            let card = ca + 6;
                            if (this.k[card] === 0 || ca < 1 || ca > 6) {
                                this.props.m_pwin.SendErrorToLog('Santase - Karta=0 ca=' + this.IntToString(ca));
                                return;
                            }
                            this.AddToDetails(card);
                            let ck2040 = this.Check2040_2(this.k[card], 2);
                            if (ck2040 > 0) {
                                if (ck2040 === 1) {
                                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + 20;
                                }
                                if (ck2040 === 2) {
                                    this.m_rez.m_rez2 = this.m_rez.m_rez2 + 40;
                                }
                                this.o2040 = 2;
                                this.anons = ck2040;
                                this.DrawObiaviavane2040(true);
                                this.setState({});
                                this.ReloadPoints();
                            }
                            this.CardsPoint[34].x = this.CardsPoint[card].x;
                            this.CardsPoint[34].y = this.CardsPoint[card].y;
                            this.CardP[34].image = this.CardP[card].image;
                            this.BeginMove(this.CardsPoint[card], this.CardsPos[34], 34);
                            this.P2puska = this.k[card];
                            if (this.KoiPuskaParvi === 1) {
                                this.VtoriPuska = this.k[card];
                            }
                            if (this.KoiPuskaParvi === 2) {
                                this.ParviPuska = this.k[card];
                            }
                            this.DeleteCard(card);
                            this.KoiENaRed = 1;
                            this.online_timer = this.online_timer_time;
                            this.setState({});
                        }
                        return;
                    }
                    if (!this.watch) {
                        if (this.Faza > 1 && this.strelka < 1 && this.KoiENaRed === 1 && this.player_cards > 0 && this.P1puska === 0) {
                            if (this.online_timer === 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                this.AutoPuskKard();
                            } else if (GlobalVariables.automatic_release) {
                                this.Pusk1KartaZaPuskanePl1();
                            }
                            return;
                        }
                    }
                }
            }
        } else {
        }
    }

    //TODO - TODO

    AutoPuskKard() {
        for (let i: number = 1; i < 7; i++) {
            if (this.ProverkaCursor(i)) {
                if (this.k[i] !== 0) {
                    this.PuskaneNaKarta(i);
                    return;
                }
            }
        }
    }

    Close(player: number) {
        this.Faza = 3;
        this.close = player;
        this.CardP[24].image = this.CardP[0].image;
        this.setState({});
    }

    async InitRezDlg() {
        if (this.watch) {
            if (this.m_rez.m_rez1 < 66 && this.m_rez.m_rez2 < 66) {
                if (this.close === 1) {
                    this.m_rez.m_orez2 = this.m_rez.m_orez2 + 3;
                }
                if (this.close === 2) {
                    this.m_rez.m_orez1 = this.m_rez.m_orez1 + 3;
                }
            }
            if (this.m_rez.m_rez1 > 65) {
                if (this.kapo2 === true || this.close === 2) {
                    this.m_rez.m_orez1 = this.m_rez.m_orez1 + 3;
                } else {
                    if (this.m_rez.m_rez2 < 33) {
                        this.m_rez.m_orez1 = this.m_rez.m_orez1 + 2;
                    }
                    if (this.m_rez.m_rez2 >= 33) {
                        this.m_rez.m_orez1 = this.m_rez.m_orez1 + 1;
                    }
                }
            }
            if (this.m_rez.m_rez2 > 65) {
                if (this.kapo1 === true || this.close === 1) {
                    this.m_rez.m_orez2 = this.m_rez.m_orez2 + 3;
                } else {
                    if (this.m_rez.m_rez1 < 33) {
                        this.m_rez.m_orez2 = this.m_rez.m_orez2 + 2;
                    }
                    if (this.m_rez.m_rez1 >= 33) {
                        this.m_rez.m_orez2 = this.m_rez.m_orez2 + 1;
                    }
                }
            }
            this.m_rez.m_name1 = this.Name[1];
            this.m_rez.m_name2 = this.Name[2];
            if (this.m_rez.m_orez1 >= 11 || this.m_rez.m_orez2 >= 11) {
                this.m_rez.m_orez1 = this.m_rez.m_orez2 = 0;
                this.setState({});
            }
        }
        if (!this.watch) {
            let player1 = this.Name[1];
            let player2 = this.Name[2];
            this.P1G = false;
            this.online_timer = this.online_timer_time;
            for (let i: number = 1; i < 13; i++) {
                this.m_rez.vz[i] = this.vz[i];
            }
            for (let i: number = 1; i < 25; i++) {
                this.m_rez.kd[i] = this.kd[i];
            }
            if (this.OP && this.m_rez.m_orez1 === 0 && this.m_rez.m_orez2 === 0) {
                if (this.OP && !this.watch) {
                    if (!this.op_start) {
                        this.SendGame('L');
                        this.op_start = true;
                    }
                }
            }
            if (this.m_rez.m_rez1 < 66 && this.m_rez.m_rez2 < 66) {
                if (this.close === 1) {
                    this.m_rez.m_orez2 = this.m_rez.m_orez2 + 3;
                }
                if (this.close === 2) {
                    this.m_rez.m_orez1 = this.m_rez.m_orez1 + 3;
                }
            }
            if (this.m_rez.m_rez1 > 65) {
                if (this.kapo2 === true || this.close === 2) {
                    this.m_rez.m_orez1 = this.m_rez.m_orez1 + 3;
                } else {
                    if (this.m_rez.m_rez2 < 33) {
                        this.m_rez.m_orez1 = this.m_rez.m_orez1 + 2;
                    }
                    if (this.m_rez.m_rez2 >= 33) {
                        this.m_rez.m_orez1 = this.m_rez.m_orez1 + 1;
                    }
                }
            }
            if (this.m_rez.m_rez2 > 65) {
                if (this.kapo1 === true || this.close === 1) {
                    this.m_rez.m_orez2 = this.m_rez.m_orez2 + 3;
                } else {
                    if (this.m_rez.m_rez1 < 33) {
                        this.m_rez.m_orez2 = this.m_rez.m_orez2 + 2;
                    }
                    if (this.m_rez.m_rez1 >= 33) {
                        this.m_rez.m_orez2 = this.m_rez.m_orez2 + 1;
                    }
                }
            }
            this.m_rez.m_name1 = player1;
            this.m_rez.m_name2 = player2;
            if (this.OP) {
                let rezult = 'X|' + this.IntToString(this.m_rez.m_orez1) + ' : ' + this.IntToString(this.m_rez.m_orez2);
                this.SendGame(rezult);
            }
            this.ReloadPoints();
            if (this.props.m_pwin.state.DlgRezultatSantaseVisible) {
                this.m_rez.Invalidate();
            } else {
                await this.m_rez.DoModal();
            }
            if (this.m_rez.m_orez1 < 11 && this.m_rez.m_orez2 < 11) {
                if (this.SP) {
                    this.InitGame();
                }
            } else {
                let s_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Santase', '');
                let crezult = '';
                let ctime = this.getCDateTime();

                crezult = ctime + ' ' + player1 + ' ' + this.GetLanguageText('against') + ' ' + player2 + ' ';
                crezult =
                    crezult +
                    this.GetLanguageText('scoring') +
                    ' : ' +
                    this.IntToString(this.m_rez.m_orez1) +
                    ' - ' +
                    this.IntToString(this.m_rez.m_orez2);
                if (this.m_rez.m_orez1 > this.m_rez.m_orez2) {
                    crezult = crezult + 'w;';
                } else {
                    crezult = crezult + 'l;';
                }
                await this.WriteProfileString('Options', 'Santase', s_rezult + crezult);
                if (this.m_rez.m_orez1 > this.m_rez.m_orez2) {
                    if (this.OP) {
                        let rezult = '$|n|1|' + this.IntToString(this.m_rez.m_orez1) + ' : ' + this.IntToString(this.m_rez.m_orez2);
                        this.SendGame(rezult);
                    }
                    this.m_rez.m_rez1 = 0;
                    this.m_rez.m_rez2 = 0;
                    this.ReloadPoints();

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + player1);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else {
                    if (this.OP) {
                        let rezult = '$|n|0|' + this.IntToString(this.m_rez.m_orez1) + ' : ' + this.IntToString(this.m_rez.m_orez2);
                        this.SendGame(rezult);
                    }
                    this.m_rez.m_rez1 = 0;
                    this.m_rez.m_rez2 = 0;
                    this.ReloadPoints();

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + player2);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (this.OP) {
                        let rezult = 'X|0 : 0';
                        this.SendGame(rezult);
                    }
                    if (this.SP) {
                        this.InitNewGame();
                    }
                } else {
                    if (this.OP) {
                        this.props.m_pwin.GoToLobby();
                    } else {
                        this.props.m_pwin.OnNewGame();
                    }
                    this.props.m_pwin.showAd();
                    return;
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.wait_opplayers[2]) {
                        this.Name[2] = this.wait_opnames[2];
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.m_rez.m_name2 = this.Name[2];
                        this.P2OP = true;
                        this.P2G = true;
                        this.wait_opplayers[2] = false;
                        this.wait_opnames[2] = '';
                        this.SendResult();
                        let message = '$|v';
                        this.SendGame(message);
                        this.setState({});
                    }
                    if (this.CheckMessage()) {
                        this.ClearMessages();
                        this.props.m_pwin.SendErrorToLog('Santase this.Server - ima saobshtenie v bufera sled kraia na igrata');
                    }
                }
            }
            this.P1G = true;
            this.online_timer = this.online_timer_time;
        }
    }

    SendResult() {
        let message = '$|u|' + this.IntToString(this.m_rez.m_orez2) + '|' + this.IntToString(this.m_rez.m_orez1);
        this.SendGame(message);
    }

    InvalidateNames(player: number) {
        console.log(player);
        this.setState({});
    }

    DrawTimer() {
        let res = [];
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                if (
                    this.OP &&
                    this.Faza > 1 &&
                    this.strelka < 1 &&
                    this.P1puska === 0 &&
                    this.Move === false &&
                    this.KoiENaRed === 1 &&
                    !this.endgame
                ) {
                    res.push(this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer'));
                }
            }
            if (this.OP && this.Faza > 1 && this.strelka < 1 && this.KoiENaRed !== 1 && this.Move === false) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    res.push(
                        this.DrawBorderText(
                            this.GetLanguageText('Pause') + ': ' + this.pause_player,
                            this.pause_rect.left,
                            this.pause_rect.top,
                            'DrawTimerPause'
                        )
                    );
                }
            }
        }
        return res;
    }

    DrawPause() {
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                if (
                    this.OP &&
                    this.Faza > 1 &&
                    this.strelka < 1 &&
                    this.P1puska === 0 &&
                    this.Move === false &&
                    this.KoiENaRed === 1 &&
                    !this.endgame
                ) {
                    if (this.pause === 0) {
                        return (
                            <CPicture
                                image={this.p_bmp}
                                m_x={this.pause_rect.left}
                                m_y={this.pause_rect.top}
                                m_Width={20}
                                m_Height={20}
                                cursor={this.cursor}
                                key={'pause'}
                            />
                        );
                    }
                }
            }
        }
    }

    DrawPoints() {
        if (this.Faza > 0) {
            if (this.Name[1] !== '') {
                let res = [];
                let p1 = this.IntToString(this.m_rez.m_rez1);
                p1 = p1 + ' ' + this.GetLanguageText('p.') + ' ( ' + this.IntToString(this.m_rez.m_orez1) + ' )';
                res.push(this.DrawBorderText(p1, 660, 430, 'p1'));
                let p2 = this.IntToString(this.m_rez.m_rez2);
                p2 = p2 + ' ' + this.GetLanguageText('p.') + ' ( ' + this.IntToString(this.m_rez.m_orez2) + ' )';
                res.push(this.DrawBorderText(p2, 660, 75, 'p2'));
                return res;
            }
        }
        return null;
    }

    DrawObiaviavaneRN() {
        return this.obiaviavaneRN;
    }

    DrawCards() {
        if (this.KoiENaRed < 0) {
            return null;
        }
        var res = [];
        if (this.NamePos[1].x > 1) {
            let a: number = 13;
            for (let i: number = 1; i < a; i++) {
                if (this.k[i] > 0 && this.endgame === false) {
                    if (this.CardsPoint[i].x !== this.CardsPos[0].x && this.CardsPoint[i].y !== this.CardsPos[0].y) {
                        if (i > 6) {
                            res.push(
                                <DrawCard
                                    image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                    m_x={this.CardsPoint[i].x}
                                    m_y={this.CardsPoint[i].y}
                                    key={'card' + i}
                                    cursor={this.cursor}
                                />
                            );
                        } else {
                            res.push(
                                <DrawCard
                                    image={this.CardP[i].image}
                                    m_x={this.CardsPoint[i].x - (4.5 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)}
                                    m_y={this.CardsPoint[i].y}
                                    cursor={this.cursor}
                                    key={'card' + i}
                                    big={true}
                                />
                            );
                        }
                    }
                }
            }
            if ((((this.hand < 6 || (this.hand === 6 && this.strelka > 0)) || this.close > 0) && this.endgame === false) || this.Razdavane1 === true) {
                res.push(
                    <DrawCard image={this.CardP[0].image} m_x={this.CardsPoint[0].x} m_y={this.CardsPoint[0].y} cursor={this.cursor} key={'card25'} />
                );
            }
            if ((this.hand < 6 || (this.hand === 6 && (this.strelka > 0 || (this.Move && this.Faza === 2)))) && this.endgame === false) {
                if (this.Razdavane1 === false) {
                    res.push(
                        <DrawCard
                            image={this.CardP[24].image}
                            m_x={this.CardsPoint[24].x}
                            m_y={this.CardsPoint[24].y}
                            cursor={this.cursor}
                            key={'card24'}
                        />
                    );
                }
            }
            if (((this.hand < 5 || (this.hand === 5 && this.strelka > 0)) && this.endgame === false) || this.Razdavane1 === true) {
                for(let i=0;i<10-((this.hand-1) * 2);i++) {
                    res.push(
                        <DrawCard
                            image={this.CardP[0].image}
                            m_x={this.CardsPoint[0].x - (i+1)*0.5}
                            m_y={this.CardsPoint[0].y - (i+1)*1}
                            cursor={this.cursor}
                            key={'card_'+(25+i).toString()}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 1) {
                if (this.P1puska > 0 && this.endgame === false) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
                if (this.P2puska > 0 && this.endgame === false) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
            }
            if (this.KoiPuskaParvi === 2) {
                if (this.P2puska > 0 && this.endgame === false) {
                    res.push(
                        <DrawCard
                            image={this.CardP[34].image}
                            m_x={this.CardsPoint[34].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[34].y}
                            cursor={this.cursor}
                            key={'card34'}
                            big={true}
                        />
                    );
                }
                if (this.P1puska > 0 && this.endgame === false) {
                    res.push(
                        <DrawCard
                            image={this.CardP[33].image}
                            m_x={this.CardsPoint[33].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 2}
                            m_y={this.CardsPoint[33].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 2}
                            cursor={this.cursor}
                            key={'card33'}
                            big={true}
                        />
                    );
                }
            }
        }
        return res;
    }

    Timer7() {
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                if (this.online_timer > 0) {
                    this.online_timer = this.online_timer - 1;
                }
                if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                    if (
                        this.OP &&
                        this.Faza > 1 &&
                        this.strelka < 1 &&
                        this.P1puska === 0 &&
                        this.Move === false &&
                        this.KoiENaRed === 1 &&
                        !this.endgame
                    ) {
                        if (this.online_timer === 5) {
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                            }
                        }
                    }
                }
                this.setState({});
                if (this.endgame && this.Faza > 1) {
                    if (this.online_timer === 0) {
                        if (this.props.m_pwin.state.DlgRezultatSantaseVisible) {
                            //	if(this.m_rez.dlg_det_start) {
                            //		this.m_rez.dlg_det.OnOK();//TODO Dlg res details with cards - new issue
                            //	}
                            this.m_rez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                        if (this.props.m_pwin.state.DlgRezultatSantaseVisible) {
                            this.m_rez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                    }
                }
            }
        }
    }

    InitVziatka() {
        this.KoiENaRed = this.KoiPuskaParvi = this.strelka;
        if (this.Faza === 2) {
            if (this.strelka === 1) {
                this.k[6] = this.k[13];
                this.movecard = 1;
                this.CardP[6].image = this.CardP[13].image;
                this.CardsPoint[6].x = this.CardsPoint[13].x;
                this.CardsPoint[6].y = this.CardsPoint[13].y;
                this.BeginMove(this.CardsPoint[6], this.CardsPos[6], 6);
            }
            if (this.strelka === 2) {
                this.k[12] = this.k[13];
                this.movecard = 2;
                this.CardP[12].image = this.CardP[13].image;
                this.CardsPoint[12].x = this.CardsPoint[13].x;
                this.CardsPoint[12].y = this.CardsPoint[13].y;
                this.BeginMove(this.CardsPoint[12], this.CardsPos[12], 12);
            }
            if (this.hand === 5) {
                this.setState({});
            }
            if (this.hand < 6) {
                this.SortCards(2);
            }
        }
        this.strelka = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.online_timer = this.online_timer_time;
        this.obiaviavaneRN = null;
    }

    async Timer4() {
        if (this.SP === true && this.endgame === false) {
            if (this.strelka > 0) {
                this.InitVziatka();
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                this.setState({});
                if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                    this.endgame = true;
                    this.setState({});
                    await this.InitRezDlg();
                }
            }
        }
        if (this.OP === true && this.endgame === false) {
            if (this.Server) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                        this.endgame = true;
                        this.setState({});
                        await this.InitRezDlg();
                    }
                }
            } else {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    if (this.m_rez.m_rez1 > 65 || this.m_rez.m_rez2 > 65 || this.player_cards === 0) {
                        this.endgame = true;
                        this.setState({});
                        await this.InitRezDlg();
                        if (this.OP && this.props.m_pwin.game !== '') {
                            if (this.CheckMessage()) {
                                this.ClearMessages();
                            }
                            if (!this.watch) {
                                this.SendGame('R');
                            }
                        }
                    }
                }
            }
        }
    }

    async ReceiveGame(f: string) {
        let strRecvd: string;
        if (f === 'a') {
            //Smiana na karta
            this.check_change = true;
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            if (this.m_rez.m_orez1 < 11 && this.m_rez.m_orez2 < 11) {
                this.InitGame();
            } else {
                this.InitNewGame();
            }
            this.ReloadPoints();
            let koj = this.props.m_pwin.ReceiveString(1);
            if (this.watch) {
                if (koj === '1') {
                    koj = '2';
                } else {
                    koj = '1';
                }
                this.setState({});
            }
            if (koj === '1' || koj === '2') {
                this.KoiZapochva = this.KoiPuskaParvi = this.KoiENaRed = this._ttoi(koj);
            }
            if (this.watch) {
                this.setState({});
            }
            return true;
        }
        if (f === 'c') {
            //Poluchavane na kartite client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rstr = this.props.m_pwin.ReceiveString(2);
            if (this.watch) {
                for (let a: number = 7; a < 13; a++) {
                    if (this.k[a] < 1) {
                        if (a === 7) {
                            if (this.CheckMessage()) {
                                this.ClearMessages();
                            }
                        }
                        this.k[a] = this._ttoi(rstr);
                        return true;
                    }
                }
                for (let a: number = 1; a < 7; a++) {
                    if (this.k[a] < 1) {
                        this.k[a] = this._ttoi(rstr);
                        return true;
                    }
                }
                for (let a: number = 13; a < 25; a++) {
                    if (this.k[a] < 1) {
                        this.k[a] = this._ttoi(rstr);
                        if (a === 24) {
                            this.Razdavane1 = true;
                            this.endgame = false;
                            this.LoadCards(false, true);
                            if (this.k[24] > 10 && this.k[24] < 20) {
                                this.Naddavane = 1;
                            }
                            if (this.k[24] > 20 && this.k[24] < 30) {
                                this.Naddavane = 2;
                            }
                            if (this.k[24] > 30 && this.k[24] < 40) {
                                this.Naddavane = 3;
                            }
                            if (this.k[24] > 40 && this.k[24] < 50) {
                                this.Naddavane = 4;
                            }
                            this.online_timer = this.online_timer_time;
                            this.setState({});
                        }
                        return true;
                    }
                }
            } else {
                for (let i: number = 1; i < 25; i++) {
                    if (this.k[i] < 1) {
                        if (i === 1) {
                            if (this.CheckMessage()) {
                                this.ClearMessages();
                            }
                        }
                        this.k[i] = this._ttoi(rstr);
                        if (i === 24) {
                            this.Razdavane1 = true;
                            this.endgame = false;
                            this.LoadCards(false, true);
                            if (this.k[24] > 10 && this.k[24] < 20) {
                                this.Naddavane = 1;
                            }
                            if (this.k[24] > 20 && this.k[24] < 30) {
                                this.Naddavane = 2;
                            }
                            if (this.k[24] > 30 && this.k[24] < 40) {
                                this.Naddavane = 3;
                            }
                            if (this.k[24] > 40 && this.k[24] < 50) {
                                this.Naddavane = 4;
                            }
                            this.online_timer = this.online_timer_time;
                            this.setState({});
                        }
                        return true;
                    }
                }
            }
            return true;
        }
        if (f === 'd') {
            //this.check_zatvoreno
            this.check_zatvoreno = true;
            return true;
        }
        if (f === 'e') {
            //Poluchavane na signal za gotovnost ot servera
            return true;
        }
        if (f === 'f') {
            //Puskane na karta
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let card = this.props.m_pwin.ReceiveString(1);
            if (card === '1' || card === '2' || card === '3' || card === '4' || card === '5' || card === '6') {
                this.WriteMessage(card);
                await this.Timer3();
            } else {
                this.props.m_pwin.SendErrorToLog('Poluchavane na Nevalidna Karta' + card);
            }
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                if (this.watch) {
                    if (this.Name[2] === name) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    return true;
                }
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '') {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.wait_opplayers[2] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            await this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'j') {
            //ReceiveWachGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(500);
            this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '5') {
                this.P2OP = true;
            }
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            this.setState({});
            return true;
        }
        if (f === 'm') {
        }
        if (f === 'n') {
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.P1G = false;
            }
            if (strRecvd === '2') {
                this.P2G = false;
            }
            if (strRecvd === '4') {
                this.P1G = true;
            }
            if (strRecvd === '5') {
                this.P2G = true;
            }
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra this.Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let rating = this.props.m_pwin.ReceiveString(30);
            let player_index: number = 2;
            if (this.watch) {
                this.Name[2] = player_name;
                this.show_user[2] = false;
                this.props.m_pwin.GetPlayerRating(2);
                this.setState({});
                return true;
            }
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let title =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[2] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(title);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    let message = '$|s|1';
                    this.SendGame(message);
                    this.wait_opplayers[2] = true;
                    this.wait_opnames[2] = player_name;
                    if (this.CheckMessage()) {
                        this.ClearMessages();
                        this.props.m_pwin.SendErrorToLog('Prisaediniavane na igrach - ima saobshtenie v bufera');
                    }
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    let message = '$|s|1';
                    this.SendGame(message);
                    this.wait_opplayers[2] = true;
                    this.wait_opnames[2] = player_name;
                    if (this.CheckMessage()) {
                        this.ClearMessages();
                        this.props.m_pwin.SendErrorToLog('Prisaediniavane na igrach - ima saobshtenie v bufera');
                    }
                } else {
                    let message = '$|s|0';
                    this.SendGame(message);
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title);
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.props.m_pwin.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane po vreme na igra - otgovor
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please wait for the next deal'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user"));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rez1 = this.props.m_pwin.ReceiveString(5);
            let rez2 = this.props.m_pwin.ReceiveString(5);
            this.m_rez.m_orez1 = this._ttoi(rez1);
            this.m_rez.m_orez2 = this._ttoi(rez2);
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!m_san : ' + f);
        return true;
    }

    ReceiveWachGameInitialization(init: string) {
        this.OnLButtonDown(new CPoint(0, 0));
        this.InitNewGame();
        let vpos = init.indexOf('?');
        this.Name[1] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        let lc = false;
        if (this.Faza <= 0) {
            this.ReceiveWachGameInitializationEnd();
            return;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.close = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Naddavane = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        for (let i: number = 1; i < 25; i++) {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.k[i] = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
            if (this.k[i] > 100) {
                lc = true;
            }
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].x = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].y = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }
        this.LoadCards(lc, false);

        this.Razdavane1 = false;
        this.endgame = false;

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.hand = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P1puska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (this.P1puska > 0) {
            if (this.Naddavane === 1) {
                if (this.P1puska === 101) {
                    this.CardP[33].image = this.CardPicture[1];
                }
                if (this.P1puska === 105) {
                    this.CardP[33].image = this.CardPicture[2];
                }
                if (this.P1puska === 102) {
                    this.CardP[33].image = this.CardPicture[3];
                }
                if (this.P1puska === 103) {
                    this.CardP[33].image = this.CardPicture[4];
                }
                if (this.P1puska === 104) {
                    this.CardP[33].image = this.CardPicture[5];
                }
                if (this.P1puska === 106) {
                    this.CardP[33].image = this.CardPicture[6];
                }
                if (this.P1puska === 21) {
                    this.CardP[33].image = this.CardPicture[7];
                }
                if (this.P1puska === 25) {
                    this.CardP[33].image = this.CardPicture[8];
                }
                if (this.P1puska === 22) {
                    this.CardP[33].image = this.CardPicture[9];
                }
                if (this.P1puska === 23) {
                    this.CardP[33].image = this.CardPicture[10];
                }
                if (this.P1puska === 24) {
                    this.CardP[33].image = this.CardPicture[11];
                }
                if (this.P1puska === 26) {
                    this.CardP[33].image = this.CardPicture[12];
                }
                if (this.P1puska === 31) {
                    this.CardP[33].image = this.CardPicture[13];
                }
                if (this.P1puska === 35) {
                    this.CardP[33].image = this.CardPicture[14];
                }
                if (this.P1puska === 32) {
                    this.CardP[33].image = this.CardPicture[15];
                }
                if (this.P1puska === 33) {
                    this.CardP[33].image = this.CardPicture[16];
                }
                if (this.P1puska === 34) {
                    this.CardP[33].image = this.CardPicture[17];
                }
                if (this.P1puska === 36) {
                    this.CardP[33].image = this.CardPicture[18];
                }
                if (this.P1puska === 41) {
                    this.CardP[33].image = this.CardPicture[19];
                }
                if (this.P1puska === 45) {
                    this.CardP[33].image = this.CardPicture[20];
                }
                if (this.P1puska === 42) {
                    this.CardP[33].image = this.CardPicture[21];
                }
                if (this.P1puska === 43) {
                    this.CardP[33].image = this.CardPicture[22];
                }
                if (this.P1puska === 44) {
                    this.CardP[33].image = this.CardPicture[23];
                }
                if (this.P1puska === 46) {
                    this.CardP[33].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 2) {
                if (this.P1puska === 11) {
                    this.CardP[33].image = this.CardPicture[1];
                }
                if (this.P1puska === 15) {
                    this.CardP[33].image = this.CardPicture[2];
                }
                if (this.P1puska === 12) {
                    this.CardP[33].image = this.CardPicture[3];
                }
                if (this.P1puska === 13) {
                    this.CardP[33].image = this.CardPicture[4];
                }
                if (this.P1puska === 14) {
                    this.CardP[33].image = this.CardPicture[5];
                }
                if (this.P1puska === 16) {
                    this.CardP[33].image = this.CardPicture[6];
                }
                if (this.P1puska === 101) {
                    this.CardP[33].image = this.CardPicture[7];
                }
                if (this.P1puska === 105) {
                    this.CardP[33].image = this.CardPicture[8];
                }
                if (this.P1puska === 102) {
                    this.CardP[33].image = this.CardPicture[9];
                }
                if (this.P1puska === 103) {
                    this.CardP[33].image = this.CardPicture[10];
                }
                if (this.P1puska === 104) {
                    this.CardP[33].image = this.CardPicture[11];
                }
                if (this.P1puska === 106) {
                    this.CardP[33].image = this.CardPicture[12];
                }
                if (this.P1puska === 31) {
                    this.CardP[33].image = this.CardPicture[13];
                }
                if (this.P1puska === 35) {
                    this.CardP[33].image = this.CardPicture[14];
                }
                if (this.P1puska === 32) {
                    this.CardP[33].image = this.CardPicture[15];
                }
                if (this.P1puska === 33) {
                    this.CardP[33].image = this.CardPicture[16];
                }
                if (this.P1puska === 34) {
                    this.CardP[33].image = this.CardPicture[17];
                }
                if (this.P1puska === 36) {
                    this.CardP[33].image = this.CardPicture[18];
                }
                if (this.P1puska === 41) {
                    this.CardP[33].image = this.CardPicture[19];
                }
                if (this.P1puska === 45) {
                    this.CardP[33].image = this.CardPicture[20];
                }
                if (this.P1puska === 42) {
                    this.CardP[33].image = this.CardPicture[21];
                }
                if (this.P1puska === 43) {
                    this.CardP[33].image = this.CardPicture[22];
                }
                if (this.P1puska === 44) {
                    this.CardP[33].image = this.CardPicture[23];
                }
                if (this.P1puska === 46) {
                    this.CardP[33].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 3) {
                if (this.P1puska === 11) {
                    this.CardP[33].image = this.CardPicture[1];
                }
                if (this.P1puska === 15) {
                    this.CardP[33].image = this.CardPicture[2];
                }
                if (this.P1puska === 12) {
                    this.CardP[33].image = this.CardPicture[3];
                }
                if (this.P1puska === 13) {
                    this.CardP[33].image = this.CardPicture[4];
                }
                if (this.P1puska === 14) {
                    this.CardP[33].image = this.CardPicture[5];
                }
                if (this.P1puska === 16) {
                    this.CardP[33].image = this.CardPicture[6];
                }
                if (this.P1puska === 21) {
                    this.CardP[33].image = this.CardPicture[7];
                }
                if (this.P1puska === 25) {
                    this.CardP[33].image = this.CardPicture[8];
                }
                if (this.P1puska === 22) {
                    this.CardP[33].image = this.CardPicture[9];
                }
                if (this.P1puska === 23) {
                    this.CardP[33].image = this.CardPicture[10];
                }
                if (this.P1puska === 24) {
                    this.CardP[33].image = this.CardPicture[11];
                }
                if (this.P1puska === 26) {
                    this.CardP[33].image = this.CardPicture[12];
                }
                if (this.P1puska === 101) {
                    this.CardP[33].image = this.CardPicture[13];
                }
                if (this.P1puska === 105) {
                    this.CardP[33].image = this.CardPicture[14];
                }
                if (this.P1puska === 102) {
                    this.CardP[33].image = this.CardPicture[15];
                }
                if (this.P1puska === 103) {
                    this.CardP[33].image = this.CardPicture[16];
                }
                if (this.P1puska === 104) {
                    this.CardP[33].image = this.CardPicture[17];
                }
                if (this.P1puska === 106) {
                    this.CardP[33].image = this.CardPicture[18];
                }
                if (this.P1puska === 41) {
                    this.CardP[33].image = this.CardPicture[19];
                }
                if (this.P1puska === 45) {
                    this.CardP[33].image = this.CardPicture[20];
                }
                if (this.P1puska === 42) {
                    this.CardP[33].image = this.CardPicture[21];
                }
                if (this.P1puska === 43) {
                    this.CardP[33].image = this.CardPicture[22];
                }
                if (this.P1puska === 44) {
                    this.CardP[33].image = this.CardPicture[23];
                }
                if (this.P1puska === 46) {
                    this.CardP[33].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 4) {
                if (this.P1puska === 11) {
                    this.CardP[33].image = this.CardPicture[1];
                }
                if (this.P1puska === 15) {
                    this.CardP[33].image = this.CardPicture[2];
                }
                if (this.P1puska === 12) {
                    this.CardP[33].image = this.CardPicture[3];
                }
                if (this.P1puska === 13) {
                    this.CardP[33].image = this.CardPicture[4];
                }
                if (this.P1puska === 14) {
                    this.CardP[33].image = this.CardPicture[5];
                }
                if (this.P1puska === 16) {
                    this.CardP[33].image = this.CardPicture[6];
                }
                if (this.P1puska === 21) {
                    this.CardP[33].image = this.CardPicture[7];
                }
                if (this.P1puska === 25) {
                    this.CardP[33].image = this.CardPicture[8];
                }
                if (this.P1puska === 22) {
                    this.CardP[33].image = this.CardPicture[9];
                }
                if (this.P1puska === 23) {
                    this.CardP[33].image = this.CardPicture[10];
                }
                if (this.P1puska === 24) {
                    this.CardP[33].image = this.CardPicture[11];
                }
                if (this.P1puska === 26) {
                    this.CardP[33].image = this.CardPicture[12];
                }
                if (this.P1puska === 31) {
                    this.CardP[33].image = this.CardPicture[13];
                }
                if (this.P1puska === 35) {
                    this.CardP[33].image = this.CardPicture[14];
                }
                if (this.P1puska === 32) {
                    this.CardP[33].image = this.CardPicture[15];
                }
                if (this.P1puska === 33) {
                    this.CardP[33].image = this.CardPicture[16];
                }
                if (this.P1puska === 34) {
                    this.CardP[33].image = this.CardPicture[17];
                }
                if (this.P1puska === 36) {
                    this.CardP[33].image = this.CardPicture[18];
                }
                if (this.P1puska === 101) {
                    this.CardP[33].image = this.CardPicture[19];
                }
                if (this.P1puska === 105) {
                    this.CardP[33].image = this.CardPicture[20];
                }
                if (this.P1puska === 102) {
                    this.CardP[33].image = this.CardPicture[21];
                }
                if (this.P1puska === 103) {
                    this.CardP[33].image = this.CardPicture[22];
                }
                if (this.P1puska === 104) {
                    this.CardP[33].image = this.CardPicture[23];
                }
                if (this.P1puska === 106) {
                    this.CardP[33].image = this.CardPicture[24];
                }
            }
            //	this.CardP[33]=this.CardP[0];

            this.CardsPoint[33].x = this.CardsPos[33].x;
            this.CardsPoint[33].y = this.CardsPos[33].y;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P2puska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (this.P2puska > 0) {
            //	this.CardP[34]=this.CardP[0];
            if (this.Naddavane === 1) {
                if (this.P2puska === 101) {
                    this.CardP[34].image = this.CardPicture[1];
                }
                if (this.P2puska === 105) {
                    this.CardP[34].image = this.CardPicture[2];
                }
                if (this.P2puska === 102) {
                    this.CardP[34].image = this.CardPicture[3];
                }
                if (this.P2puska === 103) {
                    this.CardP[34].image = this.CardPicture[4];
                }
                if (this.P2puska === 104) {
                    this.CardP[34].image = this.CardPicture[5];
                }
                if (this.P2puska === 106) {
                    this.CardP[34].image = this.CardPicture[6];
                }
                if (this.P2puska === 21) {
                    this.CardP[34].image = this.CardPicture[7];
                }
                if (this.P2puska === 25) {
                    this.CardP[34].image = this.CardPicture[8];
                }
                if (this.P2puska === 22) {
                    this.CardP[34].image = this.CardPicture[9];
                }
                if (this.P2puska === 23) {
                    this.CardP[34].image = this.CardPicture[10];
                }
                if (this.P2puska === 24) {
                    this.CardP[34].image = this.CardPicture[11];
                }
                if (this.P2puska === 26) {
                    this.CardP[34].image = this.CardPicture[12];
                }
                if (this.P2puska === 31) {
                    this.CardP[34].image = this.CardPicture[13];
                }
                if (this.P2puska === 35) {
                    this.CardP[34].image = this.CardPicture[14];
                }
                if (this.P2puska === 32) {
                    this.CardP[34].image = this.CardPicture[15];
                }
                if (this.P2puska === 33) {
                    this.CardP[34].image = this.CardPicture[16];
                }
                if (this.P2puska === 34) {
                    this.CardP[34].image = this.CardPicture[17];
                }
                if (this.P2puska === 36) {
                    this.CardP[34].image = this.CardPicture[18];
                }
                if (this.P2puska === 41) {
                    this.CardP[34].image = this.CardPicture[19];
                }
                if (this.P2puska === 45) {
                    this.CardP[34].image = this.CardPicture[20];
                }
                if (this.P2puska === 42) {
                    this.CardP[34].image = this.CardPicture[21];
                }
                if (this.P2puska === 43) {
                    this.CardP[34].image = this.CardPicture[22];
                }
                if (this.P2puska === 44) {
                    this.CardP[34].image = this.CardPicture[23];
                }
                if (this.P2puska === 46) {
                    this.CardP[34].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 2) {
                if (this.P2puska === 11) {
                    this.CardP[34].image = this.CardPicture[1];
                }
                if (this.P2puska === 15) {
                    this.CardP[34].image = this.CardPicture[2];
                }
                if (this.P2puska === 12) {
                    this.CardP[34].image = this.CardPicture[3];
                }
                if (this.P2puska === 13) {
                    this.CardP[34].image = this.CardPicture[4];
                }
                if (this.P2puska === 14) {
                    this.CardP[34].image = this.CardPicture[5];
                }
                if (this.P2puska === 16) {
                    this.CardP[34].image = this.CardPicture[6];
                }
                if (this.P2puska === 101) {
                    this.CardP[34].image = this.CardPicture[7];
                }
                if (this.P2puska === 105) {
                    this.CardP[34].image = this.CardPicture[8];
                }
                if (this.P2puska === 102) {
                    this.CardP[34].image = this.CardPicture[9];
                }
                if (this.P2puska === 103) {
                    this.CardP[34].image = this.CardPicture[10];
                }
                if (this.P2puska === 104) {
                    this.CardP[34].image = this.CardPicture[11];
                }
                if (this.P2puska === 106) {
                    this.CardP[34].image = this.CardPicture[12];
                }
                if (this.P2puska === 31) {
                    this.CardP[34].image = this.CardPicture[13];
                }
                if (this.P2puska === 35) {
                    this.CardP[34].image = this.CardPicture[14];
                }
                if (this.P2puska === 32) {
                    this.CardP[34].image = this.CardPicture[15];
                }
                if (this.P2puska === 33) {
                    this.CardP[34].image = this.CardPicture[16];
                }
                if (this.P2puska === 34) {
                    this.CardP[34].image = this.CardPicture[17];
                }
                if (this.P2puska === 36) {
                    this.CardP[34].image = this.CardPicture[18];
                }
                if (this.P2puska === 41) {
                    this.CardP[34].image = this.CardPicture[19];
                }
                if (this.P2puska === 45) {
                    this.CardP[34].image = this.CardPicture[20];
                }
                if (this.P2puska === 42) {
                    this.CardP[34].image = this.CardPicture[21];
                }
                if (this.P2puska === 43) {
                    this.CardP[34].image = this.CardPicture[22];
                }
                if (this.P2puska === 44) {
                    this.CardP[34].image = this.CardPicture[23];
                }
                if (this.P2puska === 46) {
                    this.CardP[34].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 3) {
                if (this.P2puska === 11) {
                    this.CardP[34].image = this.CardPicture[1];
                }
                if (this.P2puska === 15) {
                    this.CardP[34].image = this.CardPicture[2];
                }
                if (this.P2puska === 12) {
                    this.CardP[34].image = this.CardPicture[3];
                }
                if (this.P2puska === 13) {
                    this.CardP[34].image = this.CardPicture[4];
                }
                if (this.P2puska === 14) {
                    this.CardP[34].image = this.CardPicture[5];
                }
                if (this.P2puska === 16) {
                    this.CardP[34].image = this.CardPicture[6];
                }
                if (this.P2puska === 21) {
                    this.CardP[34].image = this.CardPicture[7];
                }
                if (this.P2puska === 25) {
                    this.CardP[34].image = this.CardPicture[8];
                }
                if (this.P2puska === 22) {
                    this.CardP[34].image = this.CardPicture[9];
                }
                if (this.P2puska === 23) {
                    this.CardP[34].image = this.CardPicture[10];
                }
                if (this.P2puska === 24) {
                    this.CardP[34].image = this.CardPicture[11];
                }
                if (this.P2puska === 26) {
                    this.CardP[34].image = this.CardPicture[12];
                }
                if (this.P2puska === 101) {
                    this.CardP[34].image = this.CardPicture[13];
                }
                if (this.P2puska === 105) {
                    this.CardP[34].image = this.CardPicture[14];
                }
                if (this.P2puska === 102) {
                    this.CardP[34].image = this.CardPicture[15];
                }
                if (this.P2puska === 103) {
                    this.CardP[34].image = this.CardPicture[16];
                }
                if (this.P2puska === 104) {
                    this.CardP[34].image = this.CardPicture[17];
                }
                if (this.P2puska === 106) {
                    this.CardP[34].image = this.CardPicture[18];
                }
                if (this.P2puska === 41) {
                    this.CardP[34].image = this.CardPicture[19];
                }
                if (this.P2puska === 45) {
                    this.CardP[34].image = this.CardPicture[20];
                }
                if (this.P2puska === 42) {
                    this.CardP[34].image = this.CardPicture[21];
                }
                if (this.P2puska === 43) {
                    this.CardP[34].image = this.CardPicture[22];
                }
                if (this.P2puska === 44) {
                    this.CardP[34].image = this.CardPicture[23];
                }
                if (this.P2puska === 46) {
                    this.CardP[34].image = this.CardPicture[24];
                }
            }
            if (this.Naddavane === 4) {
                if (this.P2puska === 11) {
                    this.CardP[34].image = this.CardPicture[1];
                }
                if (this.P2puska === 15) {
                    this.CardP[34].image = this.CardPicture[2];
                }
                if (this.P2puska === 12) {
                    this.CardP[34].image = this.CardPicture[3];
                }
                if (this.P2puska === 13) {
                    this.CardP[34].image = this.CardPicture[4];
                }
                if (this.P2puska === 14) {
                    this.CardP[34].image = this.CardPicture[5];
                }
                if (this.P2puska === 16) {
                    this.CardP[34].image = this.CardPicture[6];
                }
                if (this.P2puska === 21) {
                    this.CardP[34].image = this.CardPicture[7];
                }
                if (this.P2puska === 25) {
                    this.CardP[34].image = this.CardPicture[8];
                }
                if (this.P2puska === 22) {
                    this.CardP[34].image = this.CardPicture[9];
                }
                if (this.P2puska === 23) {
                    this.CardP[34].image = this.CardPicture[10];
                }
                if (this.P2puska === 24) {
                    this.CardP[34].image = this.CardPicture[11];
                }
                if (this.P2puska === 26) {
                    this.CardP[34].image = this.CardPicture[12];
                }
                if (this.P2puska === 31) {
                    this.CardP[34].image = this.CardPicture[13];
                }
                if (this.P2puska === 35) {
                    this.CardP[34].image = this.CardPicture[14];
                }
                if (this.P2puska === 32) {
                    this.CardP[34].image = this.CardPicture[15];
                }
                if (this.P2puska === 33) {
                    this.CardP[34].image = this.CardPicture[16];
                }
                if (this.P2puska === 34) {
                    this.CardP[34].image = this.CardPicture[17];
                }
                if (this.P2puska === 36) {
                    this.CardP[34].image = this.CardPicture[18];
                }
                if (this.P2puska === 101) {
                    this.CardP[34].image = this.CardPicture[19];
                }
                if (this.P2puska === 105) {
                    this.CardP[34].image = this.CardPicture[20];
                }
                if (this.P2puska === 102) {
                    this.CardP[34].image = this.CardPicture[21];
                }
                if (this.P2puska === 103) {
                    this.CardP[34].image = this.CardPicture[22];
                }
                if (this.P2puska === 104) {
                    this.CardP[34].image = this.CardPicture[23];
                }
                if (this.P2puska === 106) {
                    this.CardP[34].image = this.CardPicture[24];
                }
            }
            this.CardsPoint[34].x = this.CardsPos[34].x;
            this.CardsPoint[34].y = this.CardsPos[34].y;
        }

        if (this.P1puska > 0 && this.P2puska <= 0) {
            this.ParviPuska = this.P1puska;
        }
        if (this.P2puska > 0 && this.P1puska <= 0) {
            this.ParviPuska = this.P2puska;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez.m_rez1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez.m_rez2 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez.m_orez1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.m_rez.m_orez2 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.player_cards = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.strelka = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiPuskaParvi = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane1 = true;
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
            }
        } else {
            this.Razdavane1 = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Move = true;
        } else {
            this.Move = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mcard = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.endgame = true;
        } else {
            this.endgame = false;
        }
        init = init.substring(vpos + 1, init.length);

        if (this.Faza === 0 && this.Mcard > 0 && this.Razdavane1 && this.Move) {
            this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
        } else {
            if (this.Move) {
                if (this.Mcard > 0) {
                    this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
                }
            }
        }
        this.ReceiveWachGameInitializationEnd();
    }

    ReceiveWachGameInitializationEnd() {
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.setState({});
    }

    async SendWatchGameInitialization(watch_index: number) {
        await this.Timer3();
        let message = '$|o|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[1] + '?' + this.Name[2] + '?';
        //this.Faza
        message = message + this.IntToString(this.Faza) + '?';
        if (this.Faza <= 0) {
            if (message !== '') {
                this.SendGame(message);
            }
            return;
        }
        //this.close
        message = message + this.IntToString(this.close) + '?';
        message = message + this.IntToString(this.Naddavane) + '?';
        for (let i: number = 1; i < 25; i++) {
            message =
                message +
                this.IntToString(this.k[i]) +
                '?' +
                this.IntToString(this.CardsPoint[i].x) +
                '?' +
                this.IntToString(this.CardsPoint[i].y) +
                '?';
        }
        message = message + this.IntToString(this.hand) + '?';
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.P1puska) + '?';
        message = message + this.IntToString(this.P2puska) + '?';
        message = message + this.IntToString(this.m_rez.m_rez1) + '?';
        message = message + this.IntToString(this.m_rez.m_rez2) + '?';
        message = message + this.IntToString(this.m_rez.m_orez1) + '?';
        message = message + this.IntToString(this.m_rez.m_orez2) + '?';
        message = message + this.IntToString(this.player_cards) + '?';
        message = message + this.IntToString(this.strelka) + '?';
        message = message + this.IntToString(this.KoiPuskaParvi) + '?';
        message = message + this.IntToString(this.KoiZapochva) + '?';

        if (this.Razdavane1) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.Move) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        message = message + this.IntToString(this.Mcard) + '?';

        if (this.endgame) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (message !== '') {
            this.SendGame(message);
        }
    }

    DrawObiaviavane2040(plays: boolean) {
        if (this.endgame === false) {
            let play: number = 99;
            let text;
            if (this.anons === 1) {
                play = 21;
                text = this.GetLanguageText('20');
            }
            if (this.anons === 2) {
                play = 22;
                text = this.GetLanguageText('40');
            }
            this.obiaviavaneRN = this.DrawObiaviavanePNG(this.Pos2040[this.o2040].x, this.Pos2040[this.o2040].y, text);
            let position = 'center';
            if (GlobalVariables.Zvuk === 1 && plays === true) {
                this.props.m_pwin.m_sndSound.Play(play, position, 0, 0);
            }
        }
    }

    Intelect(player: number) {
        let card: number;
        this.online_timer = this.online_timer_time;
        card = this.IntelectBoia(player);
        if (this.OP && this.Server && this.HaveWatch) {
            this.SendGame('$|k|' + this.IntToString(card - 6));
        }
        this.AddToDetails(card);
        let ck2040 = this.Check2040_2(this.k[card], player);
        if (ck2040 > 0) {
            if (ck2040 === 1) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 20;
            }
            if (ck2040 === 2) {
                this.m_rez.m_rez2 = this.m_rez.m_rez2 + 40;
            }
            this.o2040 = 2;
            this.anons = ck2040;
            this.DrawObiaviavane2040(true);
            this.ReloadPoints();
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        this.CardsPoint[player + 32].x = this.CardsPoint[card].x;
        this.CardsPoint[player + 32].y = this.CardsPoint[card].y;
        this.CardP[32 + player].image = this.CardP[card].image;
        this.BeginMove(this.CardsPoint[card], this.CardsPos[player + 32], 32 + this.KoiENaRed);
        if (player === 1) {
            this.P1puska = this.k[card];
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        if (this.KoiPuskaParvi === 1) {
            if (player === 1) {
                this.ParviPuska = this.k[card];
            }
            if (player === 2) {
                this.VtoriPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (player === 2) {
                this.ParviPuska = this.k[card];
            }
            if (player === 1) {
                this.VtoriPuska = this.k[card];
            }
        }
        this.DeleteCard(card);
        if (this.P1puska === 0 || this.P2puska === 0) {
            if (this.KoiENaRed < 2) {
                this.KoiENaRed = this.KoiENaRed + 1;
            } else {
                this.KoiENaRed = 1;
            }
        }
        if (this.KoiPuskaParvi !== 1 && player === 2) {
            this.props.m_pwin.SetCursor();
        }
    }

    IntelectBoia(player: number) {
        /////////////////////////Parvi puska/////////////////////////////
        let ck2040 = this.Check2040(player);
        let nmc = this.CheckNMCard1(player);
        let nmsp = this.CheckNMCard(player, 1);
        let ngsp = this.CheckNGCard(player, 1);
        let nmka = this.CheckNMCard(player, 2);
        let ngka = this.CheckNGCard(player, 2);
        let nmku = this.CheckNMCard(player, 3);
        let ngku = this.CheckNGCard(player, 3);
        let nmpi = this.CheckNMCard(player, 4);
        let ngpi = this.CheckNGCard(player, 4);
        let nmkoz = this.CheckNMCard(player, this.Naddavane);
        let ngkoz = this.CheckNGCard(player, this.Naddavane);
        let brkoz = this.GetBrojKarti(this.Naddavane, player);
        let tbrkoz = brkoz + this.GetBrojKarti(this.Naddavane, 1);
        if (this.KoiPuskaParvi === player) {
            if (this.Faza === 2) {
                let addpoint = 0;
                if (ck2040 > 0 && this.hand > 0) {
                    if (ck2040 > 100) {
                        addpoint = addpoint + 40;
                    }
                    if (ck2040 < 100) {
                        addpoint = addpoint + 20;
                    }
                }
                if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                    addpoint = addpoint + this.GetCardPoint(ngkoz);
                    if (this.CheckCard(ngkoz - 1, player) === true) {
                        addpoint = addpoint + this.GetCardPoint(ngkoz - 1);
                        if (this.CheckCard(ngkoz - 2, player) === true) {
                            addpoint = addpoint + this.GetCardPoint(ngkoz - 2);
                            if (this.CheckCard(ngkoz - 3, player) === true) {
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                } else {
                                    if (this.CheckCard1(ngkoz - 4) === false && this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            } else {
                                if (this.CheckCard1(ngkoz - 3) === false && this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        } else {
                            if (this.CheckCard1(ngkoz - 2) === false && this.CheckCard(ngkoz - 3, player) === true) {
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        }
                    } else {
                        if (this.CheckCard1(ngkoz - 1) === false && this.CheckCard(ngkoz - 2, player) === true) {
                            addpoint = addpoint + this.GetCardPoint(ngkoz - 2);
                            if (this.CheckCard(ngkoz - 3, player) === true) {
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        }
                    }
                    if (this.m_rez.m_rez2 + addpoint > 65) {
                        return this.PuskCard(ngkoz, player);
                    }
                }
                if (ck2040 > 0 && this.hand > 0) {
                    return this.PuskCard(ck2040, player);
                }
                if (nmc > 0) {
                    if (
                        (nmc === 13 && this.CheckCard(14, player) === true) ||
                        (nmc === 23 && this.CheckCard(24, player) === true) ||
                        (nmc === 33 && this.CheckCard(34, player) === true) ||
                        (nmc === 43 && this.CheckCard(44, player) === true)
                    ) {
                        if (nmc === 13 && this.CheckCard(14, player) === true) {
                            if (this.CheckCard(23, player) === true && this.CheckCard1(24) === false) {
                                return this.PuskCard(23, player);
                            }
                            if (this.CheckCard(33, player) === true && this.CheckCard1(34) === false) {
                                return this.PuskCard(33, player);
                            }
                            if (this.CheckCard(43, player) === true && this.CheckCard1(44) === false) {
                                return this.PuskCard(43, player);
                            }
                            if (this.CheckCard(24, player) === true && this.CheckCard1(23) === false) {
                                return this.PuskCard(24, player);
                            }
                            if (this.CheckCard(34, player) === true && this.CheckCard1(33) === false) {
                                return this.PuskCard(34, player);
                            }
                            if (this.CheckCard(44, player) === true && this.CheckCard1(43) === false) {
                                return this.PuskCard(44, player);
                            }
                        }
                        if (nmc === 23 && this.CheckCard(24, player) === true) {
                            if (this.CheckCard(13, player) === true && this.CheckCard1(14) === false) {
                                return this.PuskCard(13, player);
                            }
                            if (this.CheckCard(33, player) === true && this.CheckCard1(34) === false) {
                                return this.PuskCard(33, player);
                            }
                            if (this.CheckCard(43, player) === true && this.CheckCard1(44) === false) {
                                return this.PuskCard(43, player);
                            }
                            if (this.CheckCard(14, player) === true && this.CheckCard1(13) === false) {
                                return this.PuskCard(14, player);
                            }
                            if (this.CheckCard(34, player) === true && this.CheckCard1(33) === false) {
                                return this.PuskCard(34, player);
                            }
                            if (this.CheckCard(44, player) === true && this.CheckCard1(43) === false) {
                                return this.PuskCard(44, player);
                            }
                        }
                        if (nmc === 33 && this.CheckCard(34, player) === true) {
                            if (this.CheckCard(23, player) === true && this.CheckCard1(24) === false) {
                                return this.PuskCard(23, player);
                            }
                            if (this.CheckCard(13, player) === true && this.CheckCard1(14) === false) {
                                return this.PuskCard(13, player);
                            }
                            if (this.CheckCard(43, player) === true && this.CheckCard1(44) === false) {
                                return this.PuskCard(43, player);
                            }
                            if (this.CheckCard(24, player) === true && this.CheckCard1(23) === false) {
                                return this.PuskCard(24, player);
                            }
                            if (this.CheckCard(14, player) === true && this.CheckCard1(13) === false) {
                                return this.PuskCard(14, player);
                            }
                            if (this.CheckCard(44, player) === true && this.CheckCard1(43) === false) {
                                return this.PuskCard(44, player);
                            }
                        }
                        if (nmc === 43 && this.CheckCard(44, player) === true) {
                            if (this.CheckCard(23, player) === true && this.CheckCard1(24) === false) {
                                return this.PuskCard(23, player);
                            }
                            if (this.CheckCard(33, player) === true && this.CheckCard1(34) === false) {
                                return this.PuskCard(33, player);
                            }
                            if (this.CheckCard(13, player) === true && this.CheckCard1(14) === false) {
                                return this.PuskCard(13, player);
                            }
                            if (this.CheckCard(24, player) === true && this.CheckCard1(23) === false) {
                                return this.PuskCard(24, player);
                            }
                            if (this.CheckCard(34, player) === true && this.CheckCard1(33) === false) {
                                return this.PuskCard(34, player);
                            }
                            if (this.CheckCard(14, player) === true && this.CheckCard1(13) === false) {
                                return this.PuskCard(14, player);
                            }
                        }
                        if (this.hand < 2) {
                            if (this.CheckCard(16, player) === true) {
                                return this.PuskCard(16, player);
                            }
                            if (this.CheckCard(26, player) === true) {
                                return this.PuskCard(26, player);
                            }
                            if (this.CheckCard(36, player) === true) {
                                return this.PuskCard(36, player);
                            }
                            if (this.CheckCard(46, player) === true) {
                                return this.PuskCard(46, player);
                            }
                        }
                    }
                    return this.PuskCard(nmc, player);
                }
            }
            if (this.Faza === 3) {
                let addpoint: number = 0;
                let vkoz: number = 0;
                if (ck2040 > 0 && this.hand > 0) {
                    if (ck2040 > 100) {
                        addpoint = addpoint + 40;
                    }
                    if (ck2040 < 100) {
                        addpoint = addpoint + 20;
                    }
                }
                if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                    vkoz = vkoz + 1;
                    addpoint = addpoint + this.GetCardPoint(ngkoz);
                    if (this.CheckCard(ngkoz - 1, player) === true) {
                        vkoz = vkoz + 1;
                        addpoint = addpoint + this.GetCardPoint(ngkoz - 1);
                        if (this.CheckCard(ngkoz - 2, player) === true) {
                            vkoz = vkoz + 1;
                            addpoint = addpoint + this.GetCardPoint(ngkoz - 2);
                            if (this.CheckCard(ngkoz - 3, player) === true) {
                                vkoz = vkoz + 1;
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    vkoz = vkoz + 1;
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        vkoz = vkoz + 1;
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                } else {
                                    if (this.CheckCard1(ngkoz - 4) === false && this.CheckCard(ngkoz - 5, player) === true) {
                                        vkoz = vkoz + 1;
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            } else {
                                if (this.CheckCard1(ngkoz - 3) === false && this.CheckCard(ngkoz - 4, player) === true) {
                                    vkoz = vkoz + 1;
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        } else {
                            if (this.CheckCard1(ngkoz - 2) === false && this.CheckCard(ngkoz - 3, player) === true) {
                                vkoz = vkoz + 1;
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        }
                    } else {
                        if (this.CheckCard1(ngkoz - 1) === false && this.CheckCard(ngkoz - 2, player) === true) {
                            vkoz = vkoz + 1;
                            addpoint = addpoint + this.GetCardPoint(ngkoz - 2);
                            if (this.CheckCard(ngkoz - 3, player) === true) {
                                addpoint = addpoint + this.GetCardPoint(ngkoz - 3);
                                if (this.CheckCard(ngkoz - 4, player) === true) {
                                    addpoint = addpoint + this.GetCardPoint(ngkoz - 4);
                                    if (this.CheckCard(ngkoz - 5, player) === true) {
                                        addpoint = addpoint + this.GetCardPoint(ngkoz - 5);
                                    }
                                }
                            }
                        }
                    }
                    if (this.m_rez.m_rez2 + addpoint > 65) {
                        return this.PuskCard(ngkoz, player);
                    }
                }
                if (ck2040 > 0 && this.hand > 0) {
                    return this.PuskCard(ck2040, player);
                }
                if (
                    tbrkoz - brkoz > 0 &&
                    tbrkoz - brkoz < vkoz + 1 &&
                    ((this.CheckVlastnaKarta(ngsp, player) && this.Naddavane !== 1) ||
                        (this.CheckVlastnaKarta(ngka, player) && this.Naddavane !== 2) ||
                        (this.CheckVlastnaKarta(ngku, player) && this.Naddavane !== 3) ||
                        (this.CheckVlastnaKarta(ngpi, player) && this.Naddavane !== 4))
                ) {
                    if (this.CheckVlastnaKarta(ngkoz, player)) {
                        return this.PuskCard(ngkoz, player);
                    }
                }
                for (let h = 12; h < 17; h++) {
                    let pl;
                    if (player === 2) {
                        pl = 1;
                    } else {
                        pl = 2;
                    }
                    if (this.CheckCard(h, player) === true && this.CheckVlastnaKarta(h, player) === true && this.GetBrojKarti(1, pl) > 0) {
                        return this.PuskCard(ngsp, player);
                    }
                }
                for (let h = 22; h < 27; h++) {
                    let pl;
                    if (player === 2) {
                        pl = 1;
                    } else {
                        pl = 2;
                    }
                    if (this.CheckCard(h, player) === true && this.CheckVlastnaKarta(h, player) === true && this.GetBrojKarti(2, pl) > 0) {
                        return this.PuskCard(ngka, player);
                    }
                }
                for (let h = 32; h < 37; h++) {
                    let pl;
                    if (player === 2) {
                        pl = 1;
                    } else {
                        pl = 2;
                    }
                    if (this.CheckCard(h, player) === true && this.CheckVlastnaKarta(h, player) === true && this.GetBrojKarti(3, pl) > 0) {
                        return this.PuskCard(ngku, player);
                    }
                }
                for (let h = 42; h < 47; h++) {
                    let pl;
                    if (player === 2) {
                        pl = 1;
                    } else {
                        pl = 2;
                    }
                    if (this.CheckCard(h, player) === true && this.CheckVlastnaKarta(h, player) === true && this.GetBrojKarti(4, pl) > 0) {
                        return this.PuskCard(ngpi, player);
                    }
                }
                if (nmc > 0) {
                    return this.PuskCard(nmc, player);
                }
            }
        }
        //////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////Vtori puska//////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////
        if (this.KoiPuskaParvi !== player) {
            if (this.Faza === 2) {
                /////////////////////////Puskane na spatia/////////////////////////////
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    let addpoint = 0;
                    if (ck2040 > 0 && this.hand > 0) {
                        if (ck2040 > 100) {
                            addpoint = addpoint + 40;
                        }
                        if (ck2040 < 100) {
                            addpoint = addpoint + 20;
                        }
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngkoz) + addpoint > 65 && ngkoz > 0) {
                        return this.PuskCard(ngkoz, player);
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngsp) + addpoint > 65 && ngsp > this.ParviPuska) {
                        return this.PuskCard(ngsp, player);
                    }
                    if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                        if (ngsp > this.ParviPuska) {
                            addpoint = addpoint + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngsp);
                            if (this.m_rez.m_rez2 + addpoint + this.GetCardPoint(ngkoz) > 65) {
                                return this.PuskCard(ngkoz, player);
                            }
                        }
                    }
                    if (ngsp > this.ParviPuska) {
                        if (ngsp !== 13 && ngsp !== 14) {
                            return this.PuskCard(ngsp, player);
                        } else if (this.CheckCard(12, player) && this.ParviPuska === 11) {
                            return this.PuskCard(12, player);
                        }
                        if (ngsp === 13 && this.CheckCard1(14) === false) {
                            return this.PuskCard(ngsp, player);
                        }
                        if (ngsp === 14 && this.CheckCard1(13) === false) {
                            return this.PuskCard(ngsp, player);
                        }
                    }
                    if (this.ParviPuska === 16 || this.ParviPuska === 15) {
                        if (this.k[24] === 102) {
                            if (this.CheckCard(101, player) === true) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 65) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(103, player) && this.CheckCard1(104) && this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (
                            this.CheckCard(103, player) &&
                            this.CheckCard1(104) &&
                            this.CheckVlastnaKarta(ngkoz, player) &&
                            this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23 - this.GetCardPoint(ngkoz)
                        ) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(102, player) === true) {
                            return this.PuskCard(102, player);
                        }
                        if (this.CheckCard(106, player) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard(105, player) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard(104, player) === true && this.CheckCard1(103) === false) {
                            return this.PuskCard(104, player);
                        }
                        if (this.CheckCard(103, player) === true && this.CheckCard1(104) === false) {
                            return this.PuskCard(103, player);
                        }
                    }
                    if (ngsp < this.ParviPuska) {
                        if (nmc > 0) {
                            return this.PuskCard(nmc, player);
                        }
                    }
                    if (ngsp > this.ParviPuska) {
                        if (ngsp !== 13 && ngsp !== 14) {
                            return this.PuskCard(ngsp, player);
                        } else {
                            if (this.CheckCard(12, player) && this.ParviPuska === 11) {
                                if (this.CheckCard1(13) && this.CheckCard1(14)) {
                                    return this.PuskCard(12, player);
                                } else {
                                    return this.PuskCard(ngsp, player);
                                }
                            }
                        }
                        if (ngsp === 13 && this.CheckCard1(14)) {
                            return this.PuskCard(ngsp, player);
                        }
                        if (ngsp === 14 && this.CheckCard1(13)) {
                            return this.PuskCard(ngsp, player);
                        }
                        if (ngsp === 13 && this.CheckCard(14, 1)) {
                            return this.PuskCard(ngsp, player);
                        }
                        if (ngsp === 14 && this.CheckCard(13, 1)) {
                            return this.PuskCard(ngsp, player);
                        }
                        if (ngsp === 13 || ngsp === 14) {
                            if (nmc === 23 && (this.CheckTeste(24) || this.CheckCard(24, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                            if (nmc === 33 && (this.CheckTeste(34) || this.CheckCard(34, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                            if (nmc === 43 && (this.CheckTeste(44) || this.CheckCard(44, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                            if (nmc === 24 && (this.CheckTeste(23) || this.CheckCard(23, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                            if (nmc === 34 && (this.CheckTeste(33) || this.CheckCard(33, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                            if (nmc === 44 && (this.CheckTeste(43) || this.CheckCard(43, player))) {
                                return this.PuskCard(ngsp, player);
                            }
                        }
                    }
                }
                /////////////////////////Puskane na karo/////////////////////////////
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    let addpoint = 0;
                    if (ck2040 > 0 && this.hand > 0) {
                        if (ck2040 > 100) {
                            addpoint = addpoint + 40;
                        }
                        if (ck2040 < 100) {
                            addpoint = addpoint + 20;
                        }
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngkoz) + addpoint > 65 && ngkoz > 0) {
                        return this.PuskCard(ngkoz, player);
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngka) + addpoint > 65 && ngka > this.ParviPuska) {
                        return this.PuskCard(ngka, player);
                    }
                    if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                        if (ngka > this.ParviPuska) {
                            addpoint = addpoint + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngka);
                            if (this.m_rez.m_rez2 + addpoint + this.GetCardPoint(ngkoz) > 65) {
                                return this.PuskCard(ngkoz, player);
                            }
                        }
                    }
                    if (this.ParviPuska === 26 || this.ParviPuska === 25) {
                        if (this.k[24] === 102) {
                            if (this.CheckCard(101, player) === true) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 65) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(103, player) && this.CheckCard1(104) && this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (
                            this.CheckCard(103, player) &&
                            this.CheckCard1(104) &&
                            this.CheckVlastnaKarta(ngkoz, player) &&
                            this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23 - this.GetCardPoint(ngkoz)
                        ) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(102, player) === true) {
                            return this.PuskCard(102, player);
                        }
                        if (this.CheckCard(106, player) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard(105, player) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard(104, player) === true && this.CheckCard1(103) === false) {
                            return this.PuskCard(104, player);
                        }
                        if (this.CheckCard(103, player) === true && this.CheckCard1(104) === false) {
                            return this.PuskCard(103, player);
                        }
                    }
                    if (ngka < this.ParviPuska) {
                        if (nmc > 0) {
                            return this.PuskCard(nmc, player);
                        }
                    }
                    if (ngka > this.ParviPuska) {
                        if (ngka !== 23 && ngka !== 24) {
                            return this.PuskCard(ngka, player);
                        } else {
                            if (this.CheckCard(22, player) && this.ParviPuska === 21) {
                                if (this.CheckCard1(23) && this.CheckCard1(24)) {
                                    return this.PuskCard(22, player);
                                } else {
                                    return this.PuskCard(ngka, player);
                                }
                            }
                        }
                        if (ngka === 23 && this.CheckCard1(24) === false) {
                            return this.PuskCard(ngka, player);
                        }
                        if (ngka === 24 && this.CheckCard1(23) === false) {
                            return this.PuskCard(ngka, player);
                        }

                        if (ngka === 23 && this.CheckCard(24, 1)) {
                            return this.PuskCard(ngka, player);
                        }
                        if (ngka === 24 && this.CheckCard(23, 1)) {
                            return this.PuskCard(ngka, player);
                        }
                        if (ngka === 23 || ngsp === 24) {
                            if (nmc === 13 && (this.CheckTeste(14) || this.CheckCard(14, player))) {
                                return this.PuskCard(ngka, player);
                            }
                            if (nmc === 33 && (this.CheckTeste(34) || this.CheckCard(34, player))) {
                                return this.PuskCard(ngka, player);
                            }
                            if (nmc === 43 && (this.CheckTeste(44) || this.CheckCard(44, player))) {
                                return this.PuskCard(ngka, player);
                            }
                            if (nmc === 14 && (this.CheckTeste(13) || this.CheckCard(13, player))) {
                                return this.PuskCard(ngka, player);
                            }
                            if (nmc === 34 && (this.CheckTeste(33) || this.CheckCard(33, player))) {
                                return this.PuskCard(ngka, player);
                            }
                            if (nmc === 44 && (this.CheckTeste(43) || this.CheckCard(43, player))) {
                                return this.PuskCard(ngka, player);
                            }
                        }
                    }
                }
                /////////////////////////Puskane na kupa/////////////////////////////
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    let addpoint = 0;
                    if (ck2040 > 0 && this.hand > 0) {
                        if (ck2040 > 100) {
                            addpoint = addpoint + 40;
                        }
                        if (ck2040 < 100) {
                            addpoint = addpoint + 20;
                        }
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngkoz) + addpoint > 65 && ngkoz > 0) {
                        return this.PuskCard(ngkoz, player);
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngku) + addpoint > 65 && ngku > this.ParviPuska) {
                        return this.PuskCard(ngku, player);
                    }
                    if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                        if (ngku > this.ParviPuska) {
                            addpoint = addpoint + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngku);
                            if (this.m_rez.m_rez2 + addpoint + this.GetCardPoint(ngkoz) > 65) {
                                return this.PuskCard(ngkoz, player);
                            }
                        }
                    }
                    if (this.ParviPuska === 36 || this.ParviPuska === 35) {
                        if (this.k[24] === 102) {
                            if (this.CheckCard(101, player) === true) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 65) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(103, player) && this.CheckCard1(104) && this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (
                            this.CheckCard(103, player) &&
                            this.CheckCard1(104) &&
                            this.CheckVlastnaKarta(ngkoz, player) &&
                            this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23 - this.GetCardPoint(ngkoz)
                        ) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(102, player) === true) {
                            return this.PuskCard(102, player);
                        }
                        if (this.CheckCard(106, player) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard(105, player) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard(104, player) === true && this.CheckCard1(103) === false) {
                            return this.PuskCard(104, player);
                        }
                        if (this.CheckCard(103, player) === true && this.CheckCard1(104) === false) {
                            return this.PuskCard(103, player);
                        }
                    }
                    if (ngku < this.ParviPuska) {
                        if (nmc > 0) {
                            return this.PuskCard(nmc, player);
                        }
                    }
                    if (ngku > this.ParviPuska) {
                        if (ngku !== 33 && ngku !== 34) {
                            return this.PuskCard(ngku, player);
                        } else {
                            if (this.CheckCard(32, player) && this.ParviPuska === 31) {
                                if (this.CheckCard1(33) && this.CheckCard1(34)) {
                                    return this.PuskCard(32, player);
                                } else {
                                    return this.PuskCard(ngku, player);
                                }
                            }
                        }
                        if (ngku === 33 && this.CheckCard1(34)) {
                            return this.PuskCard(ngku, player);
                        }
                        if (ngku === 34 && this.CheckCard1(33)) {
                            return this.PuskCard(ngku, player);
                        }

                        if (ngku === 33 && this.CheckCard(34, 1)) {
                            return this.PuskCard(ngku, player);
                        }
                        if (ngku === 34 && this.CheckCard(33, 1)) {
                            return this.PuskCard(ngku, player);
                        }
                        if (ngku === 33 || ngku === 34) {
                            if (nmc === 13 && (this.CheckTeste(14) || this.CheckCard(14, player))) {
                                return this.PuskCard(ngku, player);
                            }
                            if (nmc === 23 && (this.CheckTeste(24) || this.CheckCard(24, player))) {
                                return this.PuskCard(ngku, player);
                            }
                            if (nmc === 43 && (this.CheckTeste(44) || this.CheckCard(44, player))) {
                                return this.PuskCard(ngku, player);
                            }
                            if (nmc === 14 && (this.CheckTeste(13) || this.CheckCard(13, player))) {
                                return this.PuskCard(ngku, player);
                            }
                            if (nmc === 24 && (this.CheckTeste(23) || this.CheckCard(23, player))) {
                                return this.PuskCard(ngku, player);
                            }
                            if (nmc === 44 && (this.CheckTeste(43) || this.CheckCard(43, player))) {
                                return this.PuskCard(ngku, player);
                            }
                        }
                    }
                }
                /////////////////////////Puskane na pika/////////////////////////////
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    let addpoint = 0;
                    if (ck2040 > 0 && this.hand > 0) {
                        if (ck2040 > 100) {
                            addpoint = addpoint + 40;
                        }
                        if (ck2040 < 100) {
                            addpoint = addpoint + 20;
                        }
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngkoz) + addpoint > 65 && ngkoz > 0) {
                        return this.PuskCard(ngkoz, player);
                    }
                    if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngpi) + addpoint > 65 && ngpi > this.ParviPuska) {
                        return this.PuskCard(ngpi, player);
                    }
                    if (this.CheckVlastnaKarta(ngkoz, player) === true) {
                        if (ngpi > this.ParviPuska) {
                            addpoint = addpoint + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(ngpi);
                            if (this.m_rez.m_rez2 + addpoint + this.GetCardPoint(ngkoz) > 65) {
                                return this.PuskCard(ngkoz, player);
                            }
                        }
                    }
                    if (this.ParviPuska === 46 || this.ParviPuska === 45) {
                        if (this.k[24] === 102) {
                            if (this.CheckCard(101, player) === true) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 65) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(103, player) && this.CheckCard1(104) && this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (
                            this.CheckCard(103, player) &&
                            this.CheckCard1(104) &&
                            this.CheckVlastnaKarta(ngkoz, player) &&
                            this.m_rez.m_rez2 + this.GetCardPoint(this.ParviPuska) > 23 - this.GetCardPoint(ngkoz)
                        ) {
                            if (this.CheckCard(101, player)) {
                                return this.PuskCard(101, player);
                            }
                        }
                        if (this.CheckCard(102, player) === true) {
                            return this.PuskCard(102, player);
                        }
                        if (this.CheckCard(106, player) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard(105, player) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard(104, player) === true && this.CheckCard1(103) === false) {
                            return this.PuskCard(104, player);
                        }
                        if (this.CheckCard(103, player) === true && this.CheckCard1(104) === false) {
                            return this.PuskCard(103, player);
                        }
                    }
                    if (ngpi < this.ParviPuska) {
                        if (nmc > 0) {
                            return this.PuskCard(nmc, player);
                        }
                    }
                    if (ngpi > this.ParviPuska) {
                        if (ngpi !== 43 && ngpi !== 44) {
                            return this.PuskCard(ngpi, player);
                        } else {
                            if (this.CheckCard(42, player) && this.ParviPuska === 41) {
                                if (this.CheckCard1(43) && this.CheckCard1(44)) {
                                    return this.PuskCard(42, player);
                                } else {
                                    return this.PuskCard(ngpi, player);
                                }
                            }
                        }
                        if (ngpi === 43 && this.CheckCard1(44) === false) {
                            return this.PuskCard(ngpi, player);
                        }
                        if (ngpi === 44 && this.CheckCard1(43) === false) {
                            return this.PuskCard(ngpi, player);
                        }

                        if (ngpi === 43 && this.CheckCard(44, 1)) {
                            return this.PuskCard(ngpi, player);
                        }
                        if (ngpi === 44 && this.CheckCard(43, 1)) {
                            return this.PuskCard(ngpi, player);
                        }
                        if (ngpi === 43 || ngpi === 44) {
                            if (nmc === 13 && (this.CheckTeste(14) || this.CheckCard(14, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                            if (nmc === 33 && (this.CheckTeste(34) || this.CheckCard(34, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                            if (nmc === 23 && (this.CheckTeste(24) || this.CheckCard(24, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                            if (nmc === 14 && (this.CheckTeste(13) || this.CheckCard(13, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                            if (nmc === 34 && (this.CheckTeste(33) || this.CheckCard(33, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                            if (nmc === 24 && (this.CheckTeste(23) || this.CheckCard(23, player))) {
                                return this.PuskCard(ngpi, player);
                            }
                        }
                    }
                }
                /////////////////////////Puskane na koz/////////////////////////////
                if (this.ParviPuska > 100) {
                    if (ngkoz < this.ParviPuska) {
                        if (nmc > 0) {
                            return this.PuskCard(nmc, player);
                        }
                    }
                }
            }
            if (this.Faza === 3) {
                /////////////////////////Puskane na spatia/////////////////////////////
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (nmsp === 0) {
                        if (this.m_rez.m_rez2 + this.GetCardPoint(ngkoz) + this.GetCardPoint(this.ParviPuska) > 65 && ngkoz > 0) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (brkoz === 2 && this.CheckVlastnaKarta(ngkoz, player) === false) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (
                            (this.ParviPuska === 15 ||
                                this.ParviPuska === 16 ||
                                this.m_rez.m_rez1 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(nmc) > 65) &&
                            nmkoz > 0
                        ) {
                            return this.PuskCard(nmkoz, player);
                        }
                        return this.PuskCard(nmkoz, player);
                    }
                    if (ngsp > this.ParviPuska) {
                        return this.PuskCard(ngsp, player);
                    }
                    if (nmsp > 0) {
                        return this.PuskCard(nmsp, player);
                    }
                    if (nmsp === 0) {
                        if (brkoz > 0) {
                            if (brkoz !== 2 && nmkoz > 0) {
                                return this.PuskCard(nmkoz, player);
                            }
                            if (brkoz === 2) {
                                if (!this.CheckVlastnaKarta(ngkoz, player) && ngkoz > 0) {
                                    return this.PuskCard(ngkoz, player);
                                }
                                if (nmkoz > 0) {
                                    return this.PuskCard(nmkoz, player);
                                }
                            }
                        }
                    }
                }
                /////////////////////////Puskane na karo/////////////////////////////
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (nmka === 0) {
                        if (this.m_rez.m_rez2 + this.GetCardPoint(ngkoz) + this.GetCardPoint(this.ParviPuska) > 65 && ngkoz > 0) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (brkoz === 2 && this.CheckVlastnaKarta(ngkoz, player) === false) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (
                            (this.ParviPuska === 25 ||
                                this.ParviPuska === 26 ||
                                this.m_rez.m_rez1 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(nmc) > 65) &&
                            nmkoz > 0
                        ) {
                            return this.PuskCard(nmkoz, player);
                        }
                        return this.PuskCard(nmkoz, player);
                    }
                    ////////////////pravila//////////
                    if (ngka > this.ParviPuska) {
                        return this.PuskCard(ngka, player);
                    }
                    if (nmka > 0) {
                        return this.PuskCard(nmka, player);
                    }
                    if (nmka === 0) {
                        if (brkoz > 0) {
                            if (brkoz !== 2 && nmkoz > 0) {
                                return this.PuskCard(nmkoz, player);
                            }
                            if (brkoz === 2) {
                                if (!this.CheckVlastnaKarta(ngkoz, player) && ngkoz > 0) {
                                    return this.PuskCard(ngkoz, player);
                                }
                                if (nmkoz > 0) {
                                    return this.PuskCard(nmkoz, player);
                                }
                            }
                        }
                    }
                }
                /////////////////////////Puskane na kupa/////////////////////////////
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (nmku === 0) {
                        if (this.m_rez.m_rez2 + this.GetCardPoint(ngkoz) + this.GetCardPoint(this.ParviPuska) > 65 && ngkoz > 0) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (brkoz === 2 && this.CheckVlastnaKarta(ngkoz, player) === false) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (
                            (this.ParviPuska === 35 ||
                                this.ParviPuska === 36 ||
                                this.m_rez.m_rez1 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(nmc) > 65) &&
                            nmkoz > 0
                        ) {
                            return this.PuskCard(nmkoz, player);
                        }
                        return this.PuskCard(nmkoz, player);
                    }
                    ////////////////pravila//////////
                    if (ngku > this.ParviPuska) {
                        return this.PuskCard(ngku, player);
                    }
                    if (nmku > 0) {
                        return this.PuskCard(nmku, player);
                    }
                    if (nmku === 0) {
                        if (brkoz > 0) {
                            if (brkoz !== 2 && nmkoz > 0) {
                                return this.PuskCard(nmkoz, player);
                            }
                            if (brkoz === 2) {
                                if (!this.CheckVlastnaKarta(ngkoz, player) && ngkoz > 0) {
                                    return this.PuskCard(ngkoz, player);
                                }
                                if (nmkoz > 0) {
                                    return this.PuskCard(nmkoz, player);
                                }
                            }
                        }
                    }
                }
                /////////////////////////Puskane na pika/////////////////////////////
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (nmpi === 0) {
                        if (this.m_rez.m_rez2 + this.GetCardPoint(ngkoz) + this.GetCardPoint(this.ParviPuska) > 65 && ngkoz > 0) {
                            return this.PuskCard(ngkoz, player);
                        }
                        if (
                            (this.ParviPuska === 45 ||
                                this.ParviPuska === 46 ||
                                this.m_rez.m_rez1 + this.GetCardPoint(this.ParviPuska) + this.GetCardPoint(nmc) > 65) &&
                            nmkoz > 0
                        ) {
                            return this.PuskCard(nmkoz, player);
                        }
                        return this.PuskCard(nmkoz, player);
                    }
                    ////////////////pravila//////////
                    if (ngpi > this.ParviPuska) {
                        return this.PuskCard(ngpi, player);
                    }
                    if (nmpi > 0) {
                        return this.PuskCard(nmpi, player);
                    }
                    if (nmpi === 0) {
                        if (brkoz > 0) {
                            if (brkoz !== 2 && nmkoz > 0) {
                                return this.PuskCard(nmkoz, player);
                            }
                            if (brkoz === 2) {
                                if (!this.CheckVlastnaKarta(ngkoz, player) && ngkoz > 0) {
                                    return this.PuskCard(ngkoz, player);
                                }
                                if (nmkoz > 0) {
                                    return this.PuskCard(nmkoz, player);
                                }
                            }
                        }
                    }
                }
                /////////////////////////Puskane na koz/////////////////////////////
                if (this.ParviPuska > 100) {
                    if (nmkoz === 0) {
                        return this.PuskCard(nmc, player);
                    }
                    if (this.CheckVlastnaKarta(ngkoz, player)) {
                        if (nmkoz > this.ParviPuska) {
                            return this.PuskCard(nmkoz, player);
                        }
                    }
                    if (ngkoz < this.ParviPuska) {
                        if (nmkoz > 0) {
                            return this.PuskCard(nmkoz, player);
                        }
                    }
                    ////////////////pravila//////////
                    if (ngkoz > this.ParviPuska) {
                        return this.PuskCard(ngkoz, player);
                    }
                    if (nmkoz > 0) {
                        return this.PuskCard(nmkoz, player);
                    }
                }
            }
        }
        if (nmc > 0) {
            return this.PuskCard(nmc, player);
        }
        return 1 + (player - 1) * 6;
    }

    CheckNMCard(player: number, boia: number) {
        let h: number;
        if (boia === this.Naddavane) {
            for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                if (this.k[h] > 100) {
                    return this.k[h];
                }
            }
        }
        if (boia === 1) {
            for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                if (this.k[h] > 10 && this.k[h] < 20) {
                    return this.k[h];
                }
            }
        }
        if (boia === 2) {
            for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                if (this.k[h] > 20 && this.k[h] < 30) {
                    return this.k[h];
                }
            }
        }
        if (boia === 3) {
            for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                if (this.k[h] > 30 && this.k[h] < 40) {
                    return this.k[h];
                }
            }
        }
        if (boia === 4) {
            for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
                if (this.k[h] > 40 && this.k[h] < 50) {
                    return this.k[h];
                }
            }
        }
        return 0;
    }

    CheckVlastnaKarta(card: number, player: number) {
        let a: number;
        if (this.Faza === 2) {
            if (player === 1) {
                if (card > 10 && card < 20) {
                    for (a = 7; a < 24; a++) {
                        if (this.k[a] > 10 && this.k[a] < 20 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 20 && card < 30) {
                    for (a = 7; a < 24; a++) {
                        if (this.k[a] > 20 && this.k[a] < 30 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 30 && card < 40) {
                    for (a = 7; a < 24; a++) {
                        if (this.k[a] > 30 && this.k[a] < 40 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 40 && card < 50) {
                    for (a = 7; a < 24; a++) {
                        if (this.k[a] > 40 && this.k[a] < 50 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 100) {
                    for (a = 7; a < 24; a++) {
                        if (this.k[a] > 100 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
            }
            if (player === 2) {
                if (card > 10 && card < 20) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 10 && this.k[a] < 20 && this.k[a] > card) {
                            return false;
                        }
                    }
                    for (a = 13; a < 24; a++) {
                        if (this.k[a] > 10 && this.k[a] < 20 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 20 && card < 30) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 20 && this.k[a] < 30 && this.k[a] > card) {
                            return false;
                        }
                    }
                    for (a = 13; a < 24; a++) {
                        if (this.k[a] > 20 && this.k[a] < 30 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 30 && card < 40) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 30 && this.k[a] < 40 && this.k[a] > card) {
                            return false;
                        }
                    }
                    for (a = 13; a < 24; a++) {
                        if (this.k[a] > 30 && this.k[a] < 40 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 40 && card < 50) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 40 && this.k[a] < 50 && this.k[a] > card) {
                            return false;
                        }
                    }
                    for (a = 13; a < 24; a++) {
                        if (this.k[a] > 40 && this.k[a] < 50 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 100) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 100 && this.k[a] > card) {
                            return false;
                        }
                    }
                    for (a = 13; a < 24; a++) {
                        if (this.k[a] > 100 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
            }
        }
        if (this.Faza === 3) {
            if (player === 1) {
                if (card > 10 && card < 20) {
                    for (a = 7; a < 13; a++) {
                        if (this.k[a] > 10 && this.k[a] < 20 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 20 && card < 30) {
                    for (a = 7; a < 13; a++) {
                        if (this.k[a] > 20 && this.k[a] < 30 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 30 && card < 40) {
                    for (a = 7; a < 13; a++) {
                        if (this.k[a] > 30 && this.k[a] < 40 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 40 && card < 50) {
                    for (a = 7; a < 13; a++) {
                        if (this.k[a] > 40 && this.k[a] < 50 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 100) {
                    for (a = 7; a < 13; a++) {
                        if (this.k[a] > 100 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
            }
            if (player === 2) {
                if (card > 10 && card < 20) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 10 && this.k[a] < 20 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 20 && card < 30) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 20 && this.k[a] < 30 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 30 && card < 40) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 30 && this.k[a] < 40 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 40 && card < 50) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 40 && this.k[a] < 50 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
                if (card > 100) {
                    for (a = 1; a < 7; a++) {
                        if (this.k[a] > 100 && this.k[a] > card) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    CheckNMCard1(player: number) {
        let h: number;
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 41) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 31) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 21) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 11) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 12) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 22) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 32) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 42) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 43) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 33) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 23) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 13) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 14) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 24) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 34) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 44) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 45) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 35) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 25) {
                return this.k[h];
            }
        }
        for (h = 1 + (player - 1) * 6; h <= this.player_cards + (player - 1) * 6; h++) {
            if (this.k[h] === 15) {
                return this.k[h];
            }
        }
        return 0;
    }

    GetCardPoint(card: number) {
        if (card === 12 || card === 22 || card === 32 || card === 42 || card === 102) {
            return 2;
        }
        if (card === 13 || card === 23 || card === 33 || card === 43 || card === 103) {
            return 3;
        }
        if (card === 14 || card === 24 || card === 34 || card === 44 || card === 104) {
            return 4;
        }
        if (card === 15 || card === 25 || card === 35 || card === 45 || card === 105) {
            return 10;
        }
        if (card === 16 || card === 26 || card === 36 || card === 46 || card === 106) {
            return 11;
        }
        return 0;
    }

    CheckCard1(card: number) {
        let h: number;
        for (h = 1; h < 25; h++) {
            if (this.k[h] === card) {
                return true;
            }
        }
        return false;
    }

    CheckTeste(card: number) {
        for (let h = 13; h < 25; h++) {
            if (this.k[h] === card) {
                return true;
            }
        }
        return false;
    }

    ProverkaCursor(card: number) {
        if (this.Faza < 2) {
            return false;
        }
        if (this.Move === true || this.KoiENaRed !== 1) {
            return false;
        }
        if (this.Faza === 2) {
            return true;
        }
        if (this.KoiPuskaParvi === 1) {
            return true;
        }
        if (this.P1puska !== 0) {
            return false;
        }
        /////////////////////////////////////////////////////////////////////////
        if (this.ParviPuska > 100) {
            if (this.k[card] < 100) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > 100) {
                        return false;
                    }
                }
            }
            if (this.k[card] > 100 && this.k[card] < this.ParviPuska) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > this.ParviPuska) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            if (this.k[card] > 20) {
                for (let i: number = 1; i < 7; i++) {
                    if ((this.k[i] > 10 && this.k[i] < 20) || (this.HaveKoz(1) && this.k[card] < 100)) {
                        return false;
                    }
                }
            }
            if (this.k[card] > 10 && this.k[card] < 20 && this.k[card] < this.ParviPuska) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > 10 && this.k[i] < 20 && this.k[i] > this.ParviPuska) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            if (this.k[card] > 30 || this.k[card] < 20) {
                for (let i: number = 1; i < 7; i++) {
                    if ((this.k[i] > 20 && this.k[i] < 30) || (this.HaveKoz(1) && this.k[card] < 100)) {
                        return false;
                    }
                }
            }
            if (this.k[card] > 20 && this.k[card] < 30 && this.k[card] < this.ParviPuska) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            if (this.k[card] > 40 || this.k[card] < 30) {
                for (let i: number = 1; i < 7; i++) {
                    if ((this.k[i] > 30 && this.k[i] < 40) || (this.HaveKoz(1) && this.k[card] < 100)) {
                        return false;
                    }
                }
            }
            if (this.k[card] > 30 && this.k[card] < 40 && this.k[card] < this.ParviPuska) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska) {
                        return false;
                    }
                }
            }
        }
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            if (this.k[card] > 100 || this.k[card] < 40) {
                for (let i: number = 1; i < 7; i++) {
                    if ((this.k[i] > 40 && this.k[i] < 50) || (this.HaveKoz(1) && this.k[card] < 100)) {
                        return false;
                    }
                }
            }
            if (this.k[card] > 40 && this.k[card] < 50 && this.k[card] < this.ParviPuska) {
                for (let i: number = 1; i < 7; i++) {
                    if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    OnRButtonDown(point: CPoint) {
        if (this.watch) {
            if (this.Name[2] === 'Computer2') {
                let s: CSize = this.GetTextExtent(this.Name[2], 13);
                let width = s.cx;
                if (
                    this.ScaleRect(new CRect(this.NamePos[2].x, this.NamePos[2].y, this.NamePos[2].x + width, this.NamePos[2].y + 20)).PtInRect(point)
                ) {
                    this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                    this.props.m_pwin.GoToLobby();
                }
            }
        }
    }

    OnLButtonDown(point: CPoint) {
        console.log('OnLButtonDown', point);
        if (this.OP) {
            for (let i: number = 1; i <= this.Players; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i: number = begin; i <= this.Players; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 2; i++) {
                        if (this.Name[i] !== 'Computer2') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        console.log('this.watch', this.watch);
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.wait_opplayers[2] || !this.Server)) {
                if (
                    this.OP &&
                    this.Faza > 1 &&
                    this.strelka < 1 &&
                    this.P1puska === 0 &&
                    this.Move === false &&
                    this.KoiENaRed === 1 &&
                    !this.endgame
                ) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.props.m_pwin.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }
            console.log('this.endgame', this.endgame);
            console.log('this.m_rez.m_rez1', this.m_rez.m_rez1);
            console.log('this.m_rez.m_rez2', this.m_rez.m_rez2);
            if (!this.endgame && this.strelka < 1 && this.m_rez.m_rez1 < 66 && this.m_rez.m_rez2 < 66) {
                console.log('this.KoiENaRed', this.KoiENaRed);
                if (this.KoiENaRed === 1 && this.P1puska === 0) {
                    if (
                        this.Faza === 2 &&
                        point.x > this.CardsPos[24].x * GlobalVariables.scale &&
                        point.x < this.CardsPos[24].x * GlobalVariables.scale + GlobalConstants.CARD_WIDTH * GlobalVariables.scale &&
                        point.y > this.CardsPos[24].y * GlobalVariables.scale &&
                        point.y < this.CardsPos[24].y * GlobalVariables.scale + GlobalConstants.CARD_HEIGHT * GlobalVariables.scale
                    ) {
                        if (
                            this.CheckChange24(1) === false &&
                            this.hand > 0 &&
                            this.hand < 5 &&
                            this.KoiPuskaParvi === 1 &&
                            this.Faza === 2 &&
                            this.KoiENaRed === 1
                        ) {
                            this.Close(1);
                            if (this.OP) {
                                if (!this.Server || this.P2OP || this.HaveWatch) {
                                    this.SendGame('$|d');
                                }
                            }
                        }
                        if (this.CheckChange24(1) && this.hand > 0 && this.hand < 5 && this.KoiPuskaParvi === 1 && this.KoiENaRed === 1) {
                            this.tooltext = '';
                            this.obiaviavaneRN = null;
                            this.setState({});
                            let card9 = this.PuskCard(101, 1);
                            this.movekoz = 1;
                            this.BeginMove(this.CardsPos[card9], this.CardsPos[24], card9);
                            if (this.OP) {
                                if (!this.Server || this.P2OP || this.HaveWatch) {
                                    this.SendGame('$|a');
                                }
                            }
                        }
                    }
                    console.log('this.strelka', this.strelka);
                    if (this.strelka < 1) {
                        console.log('this.player_cards', this.player_cards);
                        for (let i = 1; i <= this.player_cards; i++) {
                            if (
                                (this.CardsPos[i].x - (4.5 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) *
                                    GlobalVariables.scale <
                                    point.x &&
                                (this.CardsPos[i].x - (4.5 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) *
                                    GlobalVariables.scale +
                                    GlobalConstants.PLAYER_CARD_WIDTH * GlobalVariables.scale >
                                    point.x &&
                                this.CardsPos[i].y * GlobalVariables.scale < point.y &&
                                this.CardsPos[i].y * GlobalVariables.scale + GlobalConstants.PLAYER_CARD_HEIGHT * GlobalVariables.scale > point.y &&
                                this.k[i] !== 0
                            ) {
                                console.log('Click on Card', i);
                                if (this.ProverkaCursor(i)) {
                                    console.log('ProverkaCursor = true');
                                    this.PuskaneNaKarta(i);
                                    if (this.OP && !this.watch) {
                                        this.sit_out_count = 0;
                                        if (this.m_rez.m_orez1 === 0 && this.m_rez.m_orez2 === 0) {
                                            if (!this.op_start) {
                                                this.SendGame('L');
                                                this.op_start = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async LoadBitmaps() {
        let face = await this.GetProfileString('Options', 'facenew', 'cards_1');
        let cardPicture = 1;
        for (let i: number = 100; i <= 400; i = i + 100) {
            for (let a: number = 9; a <= 14; a++) {
                this.CardPicture[cardPicture] = 'cards/' + face + '/' + this.IntToString(i + a) + '.gif';
                cardPicture++;
            }
        }
        let back = await this.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        let grab = await this.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.CardPicture[0] = 'cards/' + back + '/' + this.IntToString(grab) + '.gif';
        this.CardP[0].image = this.CardPicture[0];

        this.LoadFiles();
    }

    DrawView() {
        return (
            <View>
                <DrawNames name={this.Name} position={this.NamePos} cursor={this.cursor} key={'names'} />
                {this.DrawTimer()}
                {this.DrawPause()}
                {this.DrawRating()}
                {this.DrawPoints()}
                {this.DrawCards()}
                {this.Faza === 3 ? <DrawBid Naddavane={this.Naddavane} kojKazal={1} positionBid={this.CardsPos[24]} game={'Santase'} /> : null}
                {this.DrawObiaviavaneRN()}
                {this.strelka > 0 && this.endgame === false ? this.DrawStrelkaPng(this.strelka, this.props.m_pwin.game) : null}
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
                <DlgRezultatSantase
                    onRef={(ref: DlgRezultatSantase) => (this.m_rez = ref)}
                    parent={this}
                    m_pwin={this.props.m_pwin}
                    key={'DlgRezultatSantase'}
                />
            </View>
        );
    }

    render() {
        if (this.props.m_pwin.game === 'Santase' && !this.loading && !this.loadingMain) {
            if (Platform.OS === 'web') {
                return (
                    <View key={'webview'}>
                        <div key={'mousemoveSantase'} onMouseMove={this.OnMouseMove.bind(this)}>
                            {this.DrawView()}
                        </div>
                    </View>
                );
            } else {
                return <View key={'mobileviewSantase'}>{this.DrawView()}</View>;
            }
        } else {
            return null;
        }
    }
}

export default CSantase;
