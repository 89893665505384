/* eslint-disable react-native/no-inline-styles */
import React, { useRef } from 'react';
import { Dimensions, ScrollView } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useIsAppActive } from '~/Components/hooks/useIsAppActive';
import { styles } from './css/container';
import CCardGamesView from './src/Components/CCardGamesView';
import { useImages } from '~/Components/hooks/useImages';
import { useNetInfo } from '@react-native-community/netinfo';
import CustomSplashScreen from '~/Components/core/CustomSplashScreen';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyBogJMaVy8HG10ELM44oV8Ng6Ji2xvSQPo',
    authDomain: 'favorite-games-android.firebaseapp.com',
    projectId: 'favorite-games-android',
    storageBucket: 'favorite-games-android.appspot.com',
    messagingSenderId: '531198883561',
    appId: '1:531198883561:web:674623e1438670a80da278',
    measurementId: 'G-485XQ82XWP',
};

// Initialize Firebase
initializeApp(firebaseConfig);

const App = () => {
    const isConnected = useNetInfo().isConnected;
    const loading = useImages();
    const timer: React.MutableRefObject<NodeJS.Timer>[] = [];
    const isAppActive = useIsAppActive();
    timer[1] = useRef<NodeJS.Timer>();
    timer[2] = useRef<NodeJS.Timer>();
    timer[3] = useRef<NodeJS.Timer>();
    timer[4] = useRef<NodeJS.Timer>();
    timer[5] = useRef<NodeJS.Timer>();
    timer[6] = useRef<NodeJS.Timer>();
    timer[7] = useRef<NodeJS.Timer>();
    timer[8] = useRef<NodeJS.Timer>();

    if (loading) {
        return (
            <SafeAreaProvider style={styles.app} key={'firstLoading'}>
                <CustomSplashScreen loading={loading} />
            </SafeAreaProvider>
        );
    } else {
        return (
            <SafeAreaProvider style={styles.app} key={'ilitialLoading'}>
                <ScrollView
                    style={{ height: Dimensions.get('window').height, overflow: 'hidden' }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    scrollEnabled={false}
                >
                    <CCardGamesView timer={timer} isAppActive={isAppActive} isConnected={isConnected} />
                </ScrollView>
            </SafeAreaProvider>
        );
    }
};

export default App;
