import { Component } from 'react';
import { View } from 'react-native';
import React from 'react';
import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';

type Props = {
    m_rez1: number;
    m_rez2: number;
    m_rez3?: number;
    Players: number;
    m_main: CMain;
    position: CPoint;
};

class DrawPoints extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View>
                {this.props.m_main.DrawBorderText(
                    this.props.m_main.GetLanguageText('Scoring') +
                        ' ' +
                        this.props.m_main.IntToString(this.props.m_rez1) +
                        ' : ' +
                        this.props.m_main.IntToString(this.props.m_rez2) +
                        (this.props.Players === 3 ? ' : ' + this.props.m_main.IntToString(this.props.m_rez3) : ''),
                    this.props.position.x,
                    this.props.position.y,
                    'DrawPoints'
                )}
            </View>
        );
    }
}

export default DrawPoints;
