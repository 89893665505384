import { Platform, Pressable, StyleSheet, View } from 'react-native';
import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import { GamesProps } from '../props/GamesProps';
import GlobalVariables from '../GlobalVariables';
import GlobalConstants from '../GlobalConstants';
import CPicture from '../cppComponents/CPicture';
import DrawNames from '../Draw/DrawNames';
import DrawDice from '../Draw/DrawDice';
import CRect from '../cppComponents/CRect';
import CSize from '../cppComponents/CSize';
import Tooltip from '../../Components/core/Tooltip';
import { styles } from '../../../css/container';
import Text from '../core/Text';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export class avidu {
    //pomo6tna structura
    avi: number[][]; //[26][2];//poziciata
    plays: number[][]; //[24][2];//kak se dostiga
    quef: number; //queficienta i
    dicen: number; //sled koi zar e

    constructor() {
        this.avi = [];
        this.plays = [];
        for (let i1 = 0; i1 < 26; i1++) {
            this.avi[i1] = [];
            this.plays[i1] = [];
        }
    }
}

class CTabla extends CMain {
    wait: boolean;
    type: number = 0;
    tab3: boolean = false;
    rez1: number = 0;
    rez2: number = 0;
    loading: boolean = true;
    broihodove: number;
    boardColor: number;
    arr: string[] = [];
    new_game: string;
    wpool: string;
    bpool: string;
    dice1: number; //Stojnost na zar1
    dice2: number; //Stojnost na zar2
    mdice1: number;
    mdice2: number;
    mdice3: number;
    mdice4: number;
    dice_point1: CPoint = new CPoint(0, 0);
    dice_point2: CPoint = new CPoint(0, 0);
    poolpos: CPoint[] = []; //[30];//Pozicia na daden pul v/u daskata
    poolplace = []; //[28][8][2];//Vsichki vazmojni pozicii v/u koito moje da se ozove daden pool
    table = []; //[26][2];//momentnoto sustoianie na igrata
    pip_pos: CPoint[] = [];
    moverect: CRect;
    tablerect: CRect[] = [];
    zarove: boolean;
    newgame: boolean;
    movepool: number;
    Began: boolean;
    Doigravane: boolean;
    poolpoint: CPoint[] = [];
    Marked1: number[] = [];
    Marked: number[] = [];
    MarkedBr: number;
    Marked1Br: number;
    AllPosMovesP12br: number;
    AllPosMovesP12: number[][][]; //[500][2][2]
    AllPosMovesP111br: number;
    AllPosMovesP111: number[][]; // [15][2];
    AllPosMovesP112: number[][]; //[15][2];
    AllPosMovesP112br: number;
    AllPosPosP111: number[][][]; //[15][26][2];
    AllPosPosP112: number[][][]; //[15][26][2];
    AllPosPosP12: number[][][]; //[500][26][2];
    mdice: number[] = [];
    sc: number[] = [];
    avid: avidu[][]; //avid[26][1015];;
    pipcom: number;
    lastpool: number;
    firstpool: number;
    pippla: number;
    s: number;
    s2: number;
    sall: number;
    avi: number[][][]; //[15][26][2]
    plays1: number[][]; //[15][2]
    avi2: number[][][]; //[15][26][2]
    plays2: number[][]; //[15][2]
    aviall: number[][][]; //[300][26][2]
    plays: number[][][]; //[300][2][2]
    toplay: number[][]; //[4][2];
    sc1: number;
    sc2: number;
    sc3: number;
    sc4: number;
    playsc4: number[][][]; //[10000][4][2];
    playsc3: number[][][]; //[10000][4][2];
    maxc1: number;
    maxc2: number;
    maxc3: number;
    maxc4: number;
    playsc1: number[][]; //[15][2]
    playsc2: number[][][]; //[150][2][2]
    max11: number;
    max12: number;
    max2: number;
    que11: number[] = []; //[15]
    que12: number[] = []; //[15]
    que2: number[] = []; //[300];

    quec1: number[] = []; //[15]
    quec2: number[] = []; //[150];
    quec3: number[] = []; //[1000]
    quec4: number[] = []; //[10000];
    avic1: number[][][]; //[15][26][2]
    avic2: number[][][]; //[150][26][2]
    avic3: number[][][]; //[1000][26][2]
    avic4: number[][][]; //[10000][26][2];
    toplayduChift: number[][]; //[24][2];
    qex: number;
    z11: number;

    AllPosPosChP2: number[][][][]; // [5][8192][26][2];
    AllPosMovesChP2: number[][][][]; // [5][8192][4][2];
    AllPosMovesChP2Br: number[] = []; // [5];
    AllPosMovesP22br: number;
    AllPosMovesP211br: number;
    AllPosMovesP212br: number;
    AllPosPosP211: number[][][]; //[15][26][2]
    AllPosMovesP211: number[][]; //[15][2]
    AllPosPosP212: number[][][]; //[15][26][2]
    AllPosMovesP212: number[][]; //[15][2]
    AllPosPosP22: number[][][]; //[500][26][2]
    AllPosMovesP22: number[][][]; //[500][2][2]

    constructor(props: GamesProps) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        this.P2OP = false;
        this.tab3 = false;
        this.broihodove = 0;
        this.rez1 = 0;
        this.rez2 = 0;
        this.InitArrays();
    }

    InitArrays() {
        for (let i1 = 0; i1 < 3; i1++) {
            this.pip_pos[i1] = new CPoint(0, 0);
        }
        this.toplay = [];
        for (let i1 = 0; i1 < 4; i1++) {
            this.toplay[i1] = [];
        }
        this.AllPosPosChP2 = [];
        this.AllPosMovesChP2 = [];
        for (let i1 = 0; i1 < 5; i1++) {
            this.AllPosPosChP2[i1] = [];
            this.AllPosMovesChP2[i1] = [];
            for (let i2 = 0; i2 < 8192; i2++) {
                this.AllPosPosChP2[i1][i2] = [];
                this.AllPosMovesChP2[i1][i2] = [];
                for (let i3 = 0; i3 < 26; i3++) {
                    this.AllPosPosChP2[i1][i2][i3] = [];
                }
                for (let i3 = 0; i3 < 4; i3++) {
                    this.AllPosMovesChP2[i1][i2][i3] = [];
                }
            }
        }
        this.playsc1 = [];
        this.avi = [];
        this.avi2 = [];
        this.plays1 = [];
        this.plays2 = [];
        this.AllPosMovesP111 = [];
        this.AllPosMovesP112 = [];
        this.AllPosPosP111 = [];
        this.AllPosPosP112 = [];
        this.avic1 = [];
        this.AllPosPosP211 = [];
        this.AllPosMovesP211 = [];
        this.AllPosPosP212 = [];
        this.AllPosMovesP212 = [];
        for (let i1 = 0; i1 < 15; i1++) {
            this.playsc1[i1] = [];
            this.avi[i1] = [];
            this.avi2[i1] = [];
            this.plays1[i1] = [];
            this.plays2[i1] = [];
            this.AllPosMovesP111[i1] = [];
            this.AllPosMovesP112[i1] = [];
            this.AllPosPosP111[i1] = [];
            this.AllPosPosP112[i1] = [];
            this.avic1[i1] = [];
            this.AllPosPosP211[i1] = [];
            this.AllPosMovesP211[i1] = [];
            this.AllPosPosP212[i1] = [];
            this.AllPosMovesP212[i1] = [];
            for (let i2 = 0; i2 < 26; i2++) {
                this.avi[i1][i2] = [];
                this.avi2[i1][i2] = [];
                this.AllPosPosP111[i1][i2] = [];
                this.AllPosPosP112[i1][i2] = [];
                this.avic1[i1][i2] = [];
                this.AllPosPosP211[i1][i2] = [];
                this.AllPosPosP212[i1][i2] = [];
            }
        }
        this.toplayduChift = [];
        for (let i1 = 0; i1 < 24; i1++) {
            this.toplayduChift[i1] = [];
        }
        this.table = [];
        this.avid = [];
        for (let i1 = 0; i1 < 26; i1++) {
            this.table[i1] = [];
            this.avid[i1] = [];
            for (let i2 = 0; i2 < 1015; i2++) {
                this.avid[i1][i2] = new avidu();
            }
        }
        this.poolplace = [];
        for (let i1 = 0; i1 < 28; i1++) {
            this.poolplace[i1] = [];
            for (let i2 = 0; i2 < 8; i2++) {
                this.poolplace[i1][i2] = [];
                this.poolplace[i1][i2][0] = new CPoint(0, 0);
                this.poolplace[i1][i2][1] = new CPoint(0, 0);
            }
        }
        this.poolpos = [];
        this.poolpoint = [];
        for (let i1 = 0; i1 < 30; i1++) {
            this.poolpos[i1] = new CPoint(0, 0);
            this.poolpoint[i1] = new CPoint(0, 0);
        }
        this.playsc2 = [];
        for (let i1 = 0; i1 < 150; i1++) {
            this.playsc2[i1] = [];
            for (let i2 = 0; i2 < 2; i2++) {
                this.playsc2[i1][i2] = [];
            }
        }
        this.avic2 = [];
        for (let i1 = 0; i1 < 300; i1++) {
            this.avic2[i1] = [];
            for (let i2 = 0; i2 < 26; i2++) {
                this.avic2[i1][i2] = [];
            }
        }
        this.aviall = [];
        this.plays = [];
        for (let i1 = 0; i1 < 300; i1++) {
            this.aviall[i1] = [];
            this.plays[i1] = [];
            for (let i2 = 0; i2 < 2; i2++) {
                this.plays[i1][i2] = [];
            }
            for (let i2 = 0; i2 < 26; i2++) {
                this.aviall[i1][i2] = [];
            }
        }
        this.AllPosMovesP12 = [];
        this.AllPosPosP12 = [];
        this.AllPosPosP22 = [];
        this.AllPosMovesP22 = [];
        for (let i1 = 0; i1 < 500; i1++) {
            this.AllPosMovesP12[i1] = [];
            this.AllPosPosP12[i1] = [];
            this.AllPosPosP22[i1] = [];
            this.AllPosMovesP22[i1] = [];
            for (let i2 = 0; i2 < 2; i2++) {
                this.AllPosMovesP12[i1][i2] = [];
                this.AllPosMovesP22[i1][i2] = [];
            }
            for (let i2 = 0; i2 < 26; i2++) {
                this.AllPosPosP12[i1][i2] = [];
                this.AllPosPosP22[i1][i2] = [];
            }
        }
        this.avic3 = [];
        for (let i1 = 0; i1 < 1000; i1++) {
            this.avic3[i1] = [];
            for (let i2 = 0; i2 < 26; i2++) {
                this.avic3[i1][i2] = [];
            }
        }
        this.playsc3 = [];
        this.playsc4 = [];
        this.avic4 = [];
        for (let i1 = 0; i1 < 10000; i1++) {
            this.playsc3[i1] = [];
            this.playsc4[i1] = [];
            this.avic4[i1] = [];
            for (let i2 = 0; i2 < 4; i2++) {
                this.playsc3[i1][i2] = [];
                this.playsc4[i1][i2] = [];
            }
            for (let i2 = 0; i2 < 26; i2++) {
                this.avic4[i1][i2] = [];
            }
        }
    }

    async LoadBitmaps() {
        this.LoadBoard(await this.GetProfileInt('Options', 'board', 1));
        await this.LoadPools(await this.GetProfileInt('Options', 'poolsnew', 1));
        this.LoadDice(await this.GetProfileInt('Options', 'dicetabla', 1));
        this.LoadFiles();
    }

    async LoadPools(po: number) {
        if (GlobalVariables.player_pools_color === 'Black') {
            this.wpool = 'backgammon/pools/' + this.IntToString(po) + '/wpool.png';
            this.bpool = 'backgammon/pools/' + this.IntToString(po) + '/bpool.png';
        } else {
            this.wpool = 'backgammon/pools/' + this.IntToString(po) + '/bpool.png';
            this.bpool = 'backgammon/pools/' + this.IntToString(po) + '/wpool.png';
        }
    }

    LoadBoard(bo: number) {
        this.boardColor = bo;
        for (let i = 0; i < 2; i++) {
            this.arr[i] = 'backgammon/arrow/1.gif';
        }
        this.new_game = 'backgammon/new_game.gif';
    }

    DrawDice() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        let res = [];
        if (this.KoiENaRed >= 1) {
            res.push(
                <DrawDice
                    dc={this.diceColor}
                    dice1={this.dice1}
                    mdice1={
                        this.mdice1 === this.dice1
                            ? this.mdice1
                            : this.mdice2 === this.dice1
                            ? this.mdice2
                            : this.mdice3 === this.dice1
                            ? this.mdice3
                            : this.mdice4
                    }
                    m_x={this.dice_point1.x - GlobalVariables.diceWidth / 2}
                    m_y={this.dice_point1.y - GlobalVariables.diceHeight / 2}
                    cursor={this.cursor}
                    key={'dice1'}
                />
            );
            res.push(
                <DrawDice
                    dc={this.diceColor}
                    dice1={this.dice2}
                    mdice1={
                        this.mdice1 === this.dice2
                            ? this.mdice1
                            : this.mdice2 === this.dice2
                            ? this.mdice2
                            : this.mdice3 === this.dice2
                            ? this.mdice3
                            : this.mdice4
                    }
                    m_x={this.dice_point2.x - GlobalVariables.diceWidth / 2}
                    m_y={this.dice_point2.y - GlobalVariables.diceHeight / 2}
                    cursor={this.cursor}
                    key={'dice2'}
                />
            );
        }
        return res;
    }

    LoadDice(dc: number) {
        this.diceColor = dc;
    }

    async InitOP(server: boolean, type: number) {
        this.loading = true;
        this.type = type;
        if (this.type === 3) {
            this.tab3 = true;
            this.type = 1;
        }
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        if (!server) {
            this.wait = true;
        } else {
            this.InitGame();
            this.wait = false;
        }
        this.InitTimer(20);
        this.loading = false;
    }

    async InitSP(type: number) {
        this.loading = true;
        this.type = type;
        if (this.type === 3) {
            this.tab3 = true;
            this.type = 1;
        }
        this.SP = true;
        this.OP = false;
        this.wait = false;
        await this.Init();
        this.InitGame();
        this.loading = false;
    }

    DrawNewGame() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        if (this.ShowNewGameButton()) {
            return (
                <View
                    key={'DrawNewGame'}
                    style={[
                        styles.containerAbsolute,
                        {
                            left: 657 * GlobalVariables.scale,
                            top: 394 * GlobalVariables.scale,
                            width: 27 * GlobalVariables.scale,
                            height: 27 * GlobalVariables.scale,
                        },
                    ]}
                >
                    <Tooltip content={this.GetLanguageText('I give up')} delay={1000}>
                        <View>
                            <CPicture image={this.new_game} m_x={0} m_y={0} m_Height={27} m_Width={27} cursor={this.cursor} relative={true} />
                        </View>
                    </Tooltip>
                </View>
            );
        }
        return null;
    }

    ShowNewGameButton(): boolean {
        let res = false;
        let pipcount1 = this.GetPipCount(1);
        let pipcount2 = this.GetPipCount(2);
        if (this.KoiZapochva === 0 || this.KoiENaRed === 0 || this.wait || this.endgame) {
            res = false;
            return res;
        }
        if (this.watch) {
            res = false;
            return res;
        }
        if (!this.OP || (this.Server && !this.P2OP)) {
            res = true;
            return res;
        }

        if (this.type === 0 || this.type === 1) {
            let haveEnemyPl2: boolean = false;
            for (let i = 0; i < 24; i++) {
                if (this.GetNumPools(i, 2) > 0 && this.HaveEnemy(2, i)) {
                    haveEnemyPl2 = true;
                }
            }
            if (!haveEnemyPl2) {
                if (this.GetNumPools(26, 1) > 0) {
                    if (pipcount1 > pipcount2 * 8) {
                        res = true;
                        return res;
                    }
                    let numPoolsInTable1 = 15 - this.GetNumPools(26, 1);
                    let numPoolsInTable2 = 15 - this.GetNumPools(27, 2);
                    if (numPoolsInTable1 > numPoolsInTable2 * 2 && pipcount1 > pipcount2) {
                        res = true;
                        return res;
                    }
                }
            }
        }
        if (this.type === 2) {
            if (this.GetNumPools(26, 1) > 0) {
                if (pipcount1 > pipcount2 * 28) {
                    res = true;
                    return res;
                }
            }
        }
        return res;
    }

    HaveEnemy(player: number, rect: number) {
        if (player === 1 && rect < this.GetMaxRectPool(2)) {
            return true;
        }
        if (player === 2 && this.GetMinRectPool(1) < rect) {
            return true;
        }
        return false;
    }

    GetMinRectPool(player: number) {
        let mr = 23;
        for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
            let rp = this.GetRectPool(i1);
            if (rp < mr && rp >= 0) {
                mr = rp;
            }
        }
        if (player === 1 && this.HaveKiledPool(player)) {
            mr = -1;
        }
        return mr;
    }

    HaveKiledPool(player: number) {
        for (let a = (player - 1) * 15; a < 15 + (player - 1) * 15; a++) {
            let rp = this.GetRectPool(a);
            if (rp === 24 || rp === 25) {
                return true;
            }
        }
        return false;
    }

    GetRectPool(pool: number) {
        for (let i = 0; i < 26; i++) {
            for (let q1 = 0; q1 < 2; q1++) {
                for (let q2 = 0; q2 < 8; q2++) {
                    if (this.poolpos[pool].x === this.poolplace[i][q2][q1].x && this.poolpos[pool].y === this.poolplace[i][q2][q1].y) {
                        return i;
                    }
                }
            }
        }
        return -1;
    }

    GetMaxRectPool(player: number) {
        let mr = 0;
        for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
            let rp = this.GetRectPool(i1);
            if (rp > mr && rp < 24) {
                mr = rp;
            }
        }
        if (player === 2 && this.HaveKiledPool(player)) {
            mr = 24;
        }
        return mr;
    }

    GetNumPools(rect: number, player: number) {
        let numpools = 0;
        if (rect < 0 || rect > 27) {
            return 0;
        }
        for (let i = (player - 1) * 15; i < 15 + (player - 1) * 15; i++) {
            for (let q1 = 0; q1 < 8; q1++) {
                for (let q2 = 0; q2 < 2; q2++) {
                    if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                        numpools = numpools + 1;
                    }
                }
            }
        }
        return numpools;
    }

    GetPipCount(player: number) {
        let pip = 0;
        if (player === 1) {
            for (let i = 0; i < 15; i++) {
                let rect = this.GetRectPool(i);
                if (rect !== -1) {
                    if (rect > 23) {
                        if (this.type === 0) {
                            pip = pip + 25;
                        }
                        if (this.type === 1) {
                            pip = pip + 24;
                        }
                        if (this.type === 2) {
                            //Za Teodor++
                            pip = pip + this.GetRectPool(i);
                        } //Za Teodor--
                    } else {
                        pip = pip + (24 - rect);
                    }
                }
            }
        }
        if (player === 2) {
            for (let i = 15; i < 30; i++) {
                let rect = this.GetRectPool(i);
                if (rect > 23) {
                    if (this.type === 0) {
                        pip = pip + 25;
                    }
                    if (this.type === 1) {
                        pip = pip + 24;
                    }
                    if (this.type === 2 && !this.CheckReadyToExit(2)) {
                        //Za Teodor++
                        pip = pip + 24;
                    } //Za Teodor--
                } else {
                    if (this.type === 2) {
                        //Za Teodor++
                        if (rect > 11 && rect < 24) {
                            rect = 35 - rect;
                        }
                        //else
                        if (rect < 12 && rect > -1) {
                            rect = 11 - rect;
                        }
                    } //Za Teodor--
                    pip = pip + rect + 1;
                }
            }
        }
        return pip;
    }

    CheckReadyToExit(player: number): boolean {
        if (this.type === 2) {
            //////////////////tova az sum go pisal ni6to 4e ne si mi slogil komentar
            this.full();
            if (player === 1) {
                for (let i = 0; i < 18; i++) {
                    if (this.table[i][0] > 0) {
                        return false;
                    }
                }
                return true;
            }
            if (player === 2) {
                for (let i = 0; i < 6; i++) {
                    if (this.table[i][1] > 0) {
                        return false;
                    }
                }
                for (let i = 12; i < 24; i++) {
                    if (this.table[i][1] > 0) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }
        if (player === 1) {
            for (let i = 0; i < 18; i++) {
                for (let q = 0; q < 15; q++) {
                    if (this.poolpos[q].x === this.poolplace[i][0][0].x && this.poolpos[q].y === this.poolplace[i][0][0].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][1][0].x && this.poolpos[q].y === this.poolplace[i][1][0].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][0][1].x && this.poolpos[q].y === this.poolplace[i][0][1].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][1][1].x && this.poolpos[q].y === this.poolplace[i][1][1].y) {
                        return false;
                    }
                }
            }
            for (let q = 1; q < 15; q++) {
                if (this.poolpos[q].x === this.poolplace[24][0][0].x && this.poolpos[q].y === this.poolplace[24][0][0].y) {
                    return false;
                }
            }
        } else {
            for (let i = 6; i < 24; i++) {
                for (let q = 15; q < 30; q++) {
                    if (this.poolpos[q].x === this.poolplace[i][0][0].x && this.poolpos[q].y === this.poolplace[i][0][0].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][1][0].x && this.poolpos[q].y === this.poolplace[i][1][0].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][0][1].x && this.poolpos[q].y === this.poolplace[i][0][1].y) {
                        return false;
                    }
                    if (this.poolpos[q].x === this.poolplace[i][1][1].x && this.poolpos[q].y === this.poolplace[i][1][1].y) {
                        return false;
                    }
                }
            }
            for (let q = 15; q < 30; q++) {
                if (this.poolpos[q].x === this.poolplace[25][0][0].x && this.poolpos[q].y === this.poolplace[25][0][0].y) {
                    return false;
                }
            }
        }
        if (this.HaveKiledPool(player)) {
            return false;
        }
        if (this.type === 1) {
            for (let i = (player - 1) * 15; i < 15 + (player - 1) * 15; i++) {
                if (this.IsTapedPool(i)) {
                    return false;
                }
            }
        }
        return true;
    }

    IsTapedPool(
        pool: number //Da pitam teodor dali da niama direktno return false ako ne e tapa
    ) {
        if (this.type !== 1) {
            return false;
        }

        let player: number;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }

        let rect = this.GetRectPool(pool);
        let pool1 = this.GetNumPools(rect, 1);
        let pool2 = this.GetNumPools(rect, 2);
        if (rect >= 0 && this.poolpos[pool].x === this.poolplace[rect][0][0].x && this.poolpos[pool].y === this.poolplace[rect][0][0].y) {
            if ((player === 1 && pool2 > 0) || (player === 2 && pool1 > 0)) {
                return true;
            }
        }
        return false;
    }

    full() {
        for (let i = 0; i < 26; i++) {
            this.table[i][0] = 0;
            this.table[i][1] = 0;
        }
        for (let i = 0; i < 15; i++) {
            let rect = this.GetRectPool(i);
            if (rect >= 0) {
                this.table[rect][0]++;
            }
            if (this.IsTapedPool(i)) {
                rect = this.GetRectPool(i);
                if (rect >= 0) {
                    this.table[rect][0] = -1;
                }
            }
        }
        for (let i = 15; i < 30; i++) {
            let rect = this.GetRectPool(i);
            if (rect >= 0) {
                this.table[rect][1]++;
            }
            if (this.IsTapedPool(i)) {
                rect = this.GetRectPool(i);
                if (rect >= 0) {
                    this.table[rect][1] = -1;
                }
            }
        }
    }

    async Init() {
        await this.LoadBitmaps();
        this.pause = 0;
        this.pause_player = '';
        this.op_start = false;
        this.Players = 2;
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        this.P1G = false;
        this.endgame = false;
        this.NamePos[1].x = 662;
        this.NamePos[1].y = 370;
        this.NamePos[2].x = 662;
        this.NamePos[2].y = 130;
        this.PosNaddavane[1].x = this.NamePos[1].x - 40;
        this.PosNaddavane[1].y = this.NamePos[1].y - 6;
        this.PosNaddavane[2].x = this.NamePos[2].x - 40;
        this.PosNaddavane[2].y = this.NamePos[2].y - 6;
        this.pip_pos[1].x = 660;
        this.pip_pos[1].y = 450;
        this.pip_pos[2].x = 660;
        this.pip_pos[2].y = 50;
        this.dice_point1.x = 261;
        this.dice_point1.y = 258;
        this.dice_point2.x = 523;
        this.dice_point2.y = 258;
        this.StrelkaPos[1].x = 730;
        this.StrelkaPos[1].y = 335;
        this.StrelkaPos[2].x = 730;
        this.StrelkaPos[2].y = 95;
        this.StrelkaPos[3].x = 550;
        this.StrelkaPos[3].y = 230;
        if (this.SP === true) {
            this.Name[1] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[2]', 'Computer2');
        }
        if ((this.OP && this.Server) || this.SP) {
            let rand = this.RandNumber(10);
            //    rand = 1; //TODO Remove Test
            let koi = 1;
            if (rand > 4) {
                koi = 2;
            }
            this.KoiZapochva = this.KoiENaRed = koi;
            if (this.KoiENaRed === 2) {
                this.strelka = 3;
            } else {
                this.strelka = 2;
            }
        }
        this.moverect = new CRect(157, 42, 626, 477);

        this.tablerect[0] = new CRect(144, 30, 183, 255);
        this.tablerect[1] = new CRect(183, 30, 222, 255);
        this.tablerect[2] = new CRect(222, 30, 261, 255);
        this.tablerect[3] = new CRect(261, 30, 300, 255);
        this.tablerect[4] = new CRect(300, 30, 339, 255);
        this.tablerect[5] = new CRect(339, 30, 378, 255);
        this.tablerect[6] = new CRect(144 + 262, 30, 183 + 262, 255);
        this.tablerect[7] = new CRect(183 + 262, 30, 222 + 262, 255);
        this.tablerect[8] = new CRect(222 + 262, 30, 261 + 262, 255);
        this.tablerect[9] = new CRect(261 + 262, 30, 300 + 262, 255);
        this.tablerect[10] = new CRect(300 + 262, 30, 339 + 262, 255);
        this.tablerect[11] = new CRect(339 + 262, 30, 640, 255);

        this.tablerect[12] = new CRect(339 + 262, 260, 640, 488);
        this.tablerect[13] = new CRect(300 + 262, 260, 339 + 262, 488);
        this.tablerect[14] = new CRect(261 + 262, 260, 300 + 262, 488);
        this.tablerect[15] = new CRect(222 + 262, 260, 261 + 262, 488);
        this.tablerect[16] = new CRect(183 + 262, 260, 222 + 262, 488);
        this.tablerect[17] = new CRect(144 + 262, 260, 183 + 262, 488);
        this.tablerect[18] = new CRect(339, 260, 378, 488);
        this.tablerect[19] = new CRect(300, 260, 339, 488);
        this.tablerect[20] = new CRect(261, 260, 300, 488);
        this.tablerect[21] = new CRect(222, 260, 261, 488);
        this.tablerect[22] = new CRect(183, 260, 222, 488);
        this.tablerect[23] = new CRect(144, 260, 183, 488);
        this.tablerect[24] = new CRect(-1000, 260, 130, 1000);
        this.tablerect[25] = new CRect(-1000, -1000, 130, 255);

        let posy00 = 43;
        let posy10 = 70;
        let posy20 = 97;
        let posy30 = 124;
        let posy40 = 151;
        let posy50 = 178;
        let posy60 = 205;
        let posy70 = 232;

        let posy01 = 41;
        let posy11 = 68;
        let posy21 = 95;
        let posy31 = 122;
        let posy41 = 149;
        let posy51 = 176;
        let posy61 = 203;
        let posy71 = 230;

        this.poolplace[0][0][0].x = 164;
        this.poolplace[0][0][0].y = posy00;
        this.poolplace[0][1][0].x = 164;
        this.poolplace[0][1][0].y = posy10;
        this.poolplace[0][2][0].x = 164;
        this.poolplace[0][2][0].y = posy20;
        this.poolplace[0][3][0].x = 164;
        this.poolplace[0][3][0].y = posy30;
        this.poolplace[0][4][0].x = 164;
        this.poolplace[0][4][0].y = posy40;
        this.poolplace[0][5][0].x = 164;
        this.poolplace[0][5][0].y = posy50;
        this.poolplace[0][6][0].x = 164;
        this.poolplace[0][6][0].y = posy60;
        this.poolplace[0][7][0].x = 164;
        this.poolplace[0][7][0].y = posy70;
        this.poolplace[0][0][1].x = 162;
        this.poolplace[0][0][1].y = posy01;
        this.poolplace[0][1][1].x = 162;
        this.poolplace[0][1][1].y = posy11;
        this.poolplace[0][2][1].x = 162;
        this.poolplace[0][2][1].y = posy21;
        this.poolplace[0][3][1].x = 162;
        this.poolplace[0][3][1].y = posy31;
        this.poolplace[0][4][1].x = 162;
        this.poolplace[0][4][1].y = posy41;
        this.poolplace[0][5][1].x = 162;
        this.poolplace[0][5][1].y = posy51;
        this.poolplace[0][6][1].x = 162;
        this.poolplace[0][6][1].y = posy61;
        this.poolplace[0][7][1].x = 162;
        this.poolplace[0][7][1].y = posy71;
        this.poolplace[1][0][0].x = 203;
        this.poolplace[1][0][0].y = posy00;
        this.poolplace[1][1][0].x = 203;
        this.poolplace[1][1][0].y = posy10;
        this.poolplace[1][2][0].x = 203;
        this.poolplace[1][2][0].y = posy20;
        this.poolplace[1][3][0].x = 203;
        this.poolplace[1][3][0].y = posy30;
        this.poolplace[1][4][0].x = 203;
        this.poolplace[1][4][0].y = posy40;
        this.poolplace[1][5][0].x = 203;
        this.poolplace[1][5][0].y = posy50;
        this.poolplace[1][6][0].x = 203;
        this.poolplace[1][6][0].y = posy60;
        this.poolplace[1][7][0].x = 203;
        this.poolplace[1][7][0].y = posy70;
        this.poolplace[1][0][1].x = 201;
        this.poolplace[1][0][1].y = posy01;
        this.poolplace[1][1][1].x = 201;
        this.poolplace[1][1][1].y = posy11;
        this.poolplace[1][2][1].x = 201;
        this.poolplace[1][2][1].y = posy21;
        this.poolplace[1][3][1].x = 201;
        this.poolplace[1][3][1].y = posy31;
        this.poolplace[1][4][1].x = 201;
        this.poolplace[1][4][1].y = posy41;
        this.poolplace[1][5][1].x = 201;
        this.poolplace[1][5][1].y = posy51;
        this.poolplace[1][6][1].x = 201;
        this.poolplace[1][6][1].y = posy61;
        this.poolplace[1][7][1].x = 201;
        this.poolplace[1][7][1].y = posy71;
        this.poolplace[2][0][0].x = 242;
        this.poolplace[2][0][0].y = posy00;
        this.poolplace[2][1][0].x = 242;
        this.poolplace[2][1][0].y = posy10;
        this.poolplace[2][2][0].x = 242;
        this.poolplace[2][2][0].y = posy20;
        this.poolplace[2][3][0].x = 242;
        this.poolplace[2][3][0].y = posy30;
        this.poolplace[2][4][0].x = 242;
        this.poolplace[2][4][0].y = posy40;
        this.poolplace[2][5][0].x = 242;
        this.poolplace[2][5][0].y = posy50;
        this.poolplace[2][6][0].x = 242;
        this.poolplace[2][6][0].y = posy60;
        this.poolplace[2][7][0].x = 242;
        this.poolplace[2][7][0].y = posy70;
        this.poolplace[2][0][1].x = 240;
        this.poolplace[2][0][1].y = posy01;
        this.poolplace[2][1][1].x = 240;
        this.poolplace[2][1][1].y = posy11;
        this.poolplace[2][2][1].x = 240;
        this.poolplace[2][2][1].y = posy21;
        this.poolplace[2][3][1].x = 240;
        this.poolplace[2][3][1].y = posy31;
        this.poolplace[2][4][1].x = 240;
        this.poolplace[2][4][1].y = posy41;
        this.poolplace[2][5][1].x = 240;
        this.poolplace[2][5][1].y = posy51;
        this.poolplace[2][6][1].x = 240;
        this.poolplace[2][6][1].y = posy61;
        this.poolplace[2][7][1].x = 240;
        this.poolplace[2][7][1].y = posy71;
        this.poolplace[3][0][0].x = 281;
        this.poolplace[3][0][0].y = posy00;
        this.poolplace[3][1][0].x = 281;
        this.poolplace[3][1][0].y = posy10;
        this.poolplace[3][2][0].x = 281;
        this.poolplace[3][2][0].y = posy20;
        this.poolplace[3][3][0].x = 281;
        this.poolplace[3][3][0].y = posy30;
        this.poolplace[3][4][0].x = 281;
        this.poolplace[3][4][0].y = posy40;
        this.poolplace[3][5][0].x = 281;
        this.poolplace[3][5][0].y = posy50;
        this.poolplace[3][6][0].x = 281;
        this.poolplace[3][6][0].y = posy60;
        this.poolplace[3][7][0].x = 281;
        this.poolplace[3][7][0].y = posy70;
        this.poolplace[3][0][1].x = 279;
        this.poolplace[3][0][1].y = posy01;
        this.poolplace[3][1][1].x = 279;
        this.poolplace[3][1][1].y = posy11;
        this.poolplace[3][2][1].x = 279;
        this.poolplace[3][2][1].y = posy21;
        this.poolplace[3][3][1].x = 279;
        this.poolplace[3][3][1].y = posy31;
        this.poolplace[3][4][1].x = 279;
        this.poolplace[3][4][1].y = posy41;
        this.poolplace[3][5][1].x = 279;
        this.poolplace[3][5][1].y = posy51;
        this.poolplace[3][6][1].x = 279;
        this.poolplace[3][6][1].y = posy61;
        this.poolplace[3][7][1].x = 279;
        this.poolplace[3][7][1].y = posy71;
        this.poolplace[4][0][0].x = 320;
        this.poolplace[4][0][0].y = posy00;
        this.poolplace[4][1][0].x = 320;
        this.poolplace[4][1][0].y = posy10;
        this.poolplace[4][2][0].x = 320;
        this.poolplace[4][2][0].y = posy20;
        this.poolplace[4][3][0].x = 320;
        this.poolplace[4][3][0].y = posy30;
        this.poolplace[4][4][0].x = 320;
        this.poolplace[4][4][0].y = posy40;
        this.poolplace[4][5][0].x = 320;
        this.poolplace[4][5][0].y = posy50;
        this.poolplace[4][6][0].x = 320;
        this.poolplace[4][6][0].y = posy60;
        this.poolplace[4][7][0].x = 320;
        this.poolplace[4][7][0].y = posy70;
        this.poolplace[4][0][1].x = 318;
        this.poolplace[4][0][1].y = posy01;
        this.poolplace[4][1][1].x = 318;
        this.poolplace[4][1][1].y = posy11;
        this.poolplace[4][2][1].x = 318;
        this.poolplace[4][2][1].y = posy21;
        this.poolplace[4][3][1].x = 318;
        this.poolplace[4][3][1].y = posy31;
        this.poolplace[4][4][1].x = 318;
        this.poolplace[4][4][1].y = posy41;
        this.poolplace[4][5][1].x = 318;
        this.poolplace[4][5][1].y = posy51;
        this.poolplace[4][6][1].x = 318;
        this.poolplace[4][6][1].y = posy61;
        this.poolplace[4][7][1].x = 318;
        this.poolplace[4][7][1].y = posy71;
        this.poolplace[5][0][0].x = 359;
        this.poolplace[5][0][0].y = posy00;
        this.poolplace[5][1][0].x = 359;
        this.poolplace[5][1][0].y = posy10;
        this.poolplace[5][2][0].x = 359;
        this.poolplace[5][2][0].y = posy20;
        this.poolplace[5][3][0].x = 359;
        this.poolplace[5][3][0].y = posy30;
        this.poolplace[5][4][0].x = 359;
        this.poolplace[5][4][0].y = posy40;
        this.poolplace[5][5][0].x = 359;
        this.poolplace[5][5][0].y = posy50;
        this.poolplace[5][6][0].x = 359;
        this.poolplace[5][6][0].y = posy60;
        this.poolplace[5][7][0].x = 359;
        this.poolplace[5][7][0].y = posy70;
        this.poolplace[5][0][1].x = 357;
        this.poolplace[5][0][1].y = posy01;
        this.poolplace[5][1][1].x = 357;
        this.poolplace[5][1][1].y = posy11;
        this.poolplace[5][2][1].x = 357;
        this.poolplace[5][2][1].y = posy21;
        this.poolplace[5][3][1].x = 357;
        this.poolplace[5][3][1].y = posy31;
        this.poolplace[5][4][1].x = 357;
        this.poolplace[5][4][1].y = posy41;
        this.poolplace[5][5][1].x = 357;
        this.poolplace[5][5][1].y = posy51;
        this.poolplace[5][6][1].x = 357;
        this.poolplace[5][6][1].y = posy61;
        this.poolplace[5][7][1].x = 357;
        this.poolplace[5][7][1].y = posy71;

        this.poolplace[6][0][0].x = 164 + 262;
        this.poolplace[6][0][0].y = posy00;
        this.poolplace[6][1][0].x = 164 + 262;
        this.poolplace[6][1][0].y = posy10;
        this.poolplace[6][2][0].x = 164 + 262;
        this.poolplace[6][2][0].y = posy20;
        this.poolplace[6][3][0].x = 164 + 262;
        this.poolplace[6][3][0].y = posy30;
        this.poolplace[6][4][0].x = 164 + 262;
        this.poolplace[6][4][0].y = posy40;
        this.poolplace[6][5][0].x = 164 + 262;
        this.poolplace[6][5][0].y = posy50;
        this.poolplace[6][6][0].x = 164 + 262;
        this.poolplace[6][6][0].y = posy60;
        this.poolplace[6][7][0].x = 164 + 262;
        this.poolplace[6][7][0].y = posy70;
        this.poolplace[6][0][1].x = 162 + 262;
        this.poolplace[6][0][1].y = posy01;
        this.poolplace[6][1][1].x = 162 + 262;
        this.poolplace[6][1][1].y = posy11;
        this.poolplace[6][2][1].x = 162 + 262;
        this.poolplace[6][2][1].y = posy21;
        this.poolplace[6][3][1].x = 162 + 262;
        this.poolplace[6][3][1].y = posy31;
        this.poolplace[6][4][1].x = 162 + 262;
        this.poolplace[6][4][1].y = posy41;
        this.poolplace[6][5][1].x = 162 + 262;
        this.poolplace[6][5][1].y = posy51;
        this.poolplace[6][6][1].x = 162 + 262;
        this.poolplace[6][6][1].y = posy61;
        this.poolplace[6][7][1].x = 162 + 262;
        this.poolplace[6][7][1].y = posy71;
        this.poolplace[7][0][0].x = 203 + 262;
        this.poolplace[7][0][0].y = posy00;
        this.poolplace[7][1][0].x = 203 + 262;
        this.poolplace[7][1][0].y = posy10;
        this.poolplace[7][2][0].x = 203 + 262;
        this.poolplace[7][2][0].y = posy20;
        this.poolplace[7][3][0].x = 203 + 262;
        this.poolplace[7][3][0].y = posy30;
        this.poolplace[7][4][0].x = 203 + 262;
        this.poolplace[7][4][0].y = posy40;
        this.poolplace[7][5][0].x = 203 + 262;
        this.poolplace[7][5][0].y = posy50;
        this.poolplace[7][6][0].x = 203 + 262;
        this.poolplace[7][6][0].y = posy60;
        this.poolplace[7][7][0].x = 203 + 262;
        this.poolplace[7][7][0].y = posy70;
        this.poolplace[7][0][1].x = 201 + 262;
        this.poolplace[7][0][1].y = posy01;
        this.poolplace[7][1][1].x = 201 + 262;
        this.poolplace[7][1][1].y = posy11;
        this.poolplace[7][2][1].x = 201 + 262;
        this.poolplace[7][2][1].y = posy21;
        this.poolplace[7][3][1].x = 201 + 262;
        this.poolplace[7][3][1].y = posy31;
        this.poolplace[7][4][1].x = 201 + 262;
        this.poolplace[7][4][1].y = posy41;
        this.poolplace[7][5][1].x = 201 + 262;
        this.poolplace[7][5][1].y = posy51;
        this.poolplace[7][6][1].x = 201 + 262;
        this.poolplace[7][6][1].y = posy61;
        this.poolplace[7][7][1].x = 201 + 262;
        this.poolplace[7][7][1].y = posy71;
        this.poolplace[8][0][0].x = 242 + 262;
        this.poolplace[8][0][0].y = posy00;
        this.poolplace[8][1][0].x = 242 + 262;
        this.poolplace[8][1][0].y = posy10;
        this.poolplace[8][2][0].x = 242 + 262;
        this.poolplace[8][2][0].y = posy20;
        this.poolplace[8][3][0].x = 242 + 262;
        this.poolplace[8][3][0].y = posy30;
        this.poolplace[8][4][0].x = 242 + 262;
        this.poolplace[8][4][0].y = posy40;
        this.poolplace[8][5][0].x = 242 + 262;
        this.poolplace[8][5][0].y = posy50;
        this.poolplace[8][6][0].x = 242 + 262;
        this.poolplace[8][6][0].y = posy60;
        this.poolplace[8][7][0].x = 242 + 262;
        this.poolplace[8][7][0].y = posy70;
        this.poolplace[8][0][1].x = 240 + 262;
        this.poolplace[8][0][1].y = posy01;
        this.poolplace[8][1][1].x = 240 + 262;
        this.poolplace[8][1][1].y = posy11;
        this.poolplace[8][2][1].x = 240 + 262;
        this.poolplace[8][2][1].y = posy21;
        this.poolplace[8][3][1].x = 240 + 262;
        this.poolplace[8][3][1].y = posy31;
        this.poolplace[8][4][1].x = 240 + 262;
        this.poolplace[8][4][1].y = posy41;
        this.poolplace[8][5][1].x = 240 + 262;
        this.poolplace[8][5][1].y = posy51;
        this.poolplace[8][6][1].x = 240 + 262;
        this.poolplace[8][6][1].y = posy61;
        this.poolplace[8][7][1].x = 240 + 262;
        this.poolplace[8][7][1].y = posy71;
        this.poolplace[9][0][0].x = 281 + 262;
        this.poolplace[9][0][0].y = posy00;
        this.poolplace[9][1][0].x = 281 + 262;
        this.poolplace[9][1][0].y = posy10;
        this.poolplace[9][2][0].x = 281 + 262;
        this.poolplace[9][2][0].y = posy20;
        this.poolplace[9][3][0].x = 281 + 262;
        this.poolplace[9][3][0].y = posy30;
        this.poolplace[9][4][0].x = 281 + 262;
        this.poolplace[9][4][0].y = posy40;
        this.poolplace[9][5][0].x = 281 + 262;
        this.poolplace[9][5][0].y = posy50;
        this.poolplace[9][6][0].x = 281 + 262;
        this.poolplace[9][6][0].y = posy60;
        this.poolplace[9][7][0].x = 281 + 262;
        this.poolplace[9][7][0].y = posy70;
        this.poolplace[9][0][1].x = 279 + 262;
        this.poolplace[9][0][1].y = posy01;
        this.poolplace[9][1][1].x = 279 + 262;
        this.poolplace[9][1][1].y = posy11;
        this.poolplace[9][2][1].x = 279 + 262;
        this.poolplace[9][2][1].y = posy21;
        this.poolplace[9][3][1].x = 279 + 262;
        this.poolplace[9][3][1].y = posy31;
        this.poolplace[9][4][1].x = 279 + 262;
        this.poolplace[9][4][1].y = posy41;
        this.poolplace[9][5][1].x = 279 + 262;
        this.poolplace[9][5][1].y = posy51;
        this.poolplace[9][6][1].x = 279 + 262;
        this.poolplace[9][6][1].y = posy61;
        this.poolplace[9][7][1].x = 279 + 262;
        this.poolplace[9][7][1].y = posy71;
        this.poolplace[10][0][0].x = 320 + 262;
        this.poolplace[10][0][0].y = posy00;
        this.poolplace[10][1][0].x = 320 + 262;
        this.poolplace[10][1][0].y = posy10;
        this.poolplace[10][2][0].x = 320 + 262;
        this.poolplace[10][2][0].y = posy20;
        this.poolplace[10][3][0].x = 320 + 262;
        this.poolplace[10][3][0].y = posy30;
        this.poolplace[10][4][0].x = 320 + 262;
        this.poolplace[10][4][0].y = posy40;
        this.poolplace[10][5][0].x = 320 + 262;
        this.poolplace[10][5][0].y = posy50;
        this.poolplace[10][6][0].x = 320 + 262;
        this.poolplace[10][6][0].y = posy60;
        this.poolplace[10][7][0].x = 320 + 262;
        this.poolplace[10][7][0].y = posy70;
        this.poolplace[10][0][1].x = 318 + 262;
        this.poolplace[10][0][1].y = posy01;
        this.poolplace[10][1][1].x = 318 + 262;
        this.poolplace[10][1][1].y = posy11;
        this.poolplace[10][2][1].x = 318 + 262;
        this.poolplace[10][2][1].y = posy21;
        this.poolplace[10][3][1].x = 318 + 262;
        this.poolplace[10][3][1].y = posy31;
        this.poolplace[10][4][1].x = 318 + 262;
        this.poolplace[10][4][1].y = posy41;
        this.poolplace[10][5][1].x = 318 + 262;
        this.poolplace[10][5][1].y = posy51;
        this.poolplace[10][6][1].x = 318 + 262;
        this.poolplace[10][6][1].y = posy61;
        this.poolplace[10][7][1].x = 318 + 262;
        this.poolplace[10][7][1].y = posy71;
        this.poolplace[11][0][0].x = 359 + 262;
        this.poolplace[11][0][0].y = posy00;
        this.poolplace[11][1][0].x = 359 + 262;
        this.poolplace[11][1][0].y = posy10;
        this.poolplace[11][2][0].x = 359 + 262;
        this.poolplace[11][2][0].y = posy20;
        this.poolplace[11][3][0].x = 359 + 262;
        this.poolplace[11][3][0].y = posy30;
        this.poolplace[11][4][0].x = 359 + 262;
        this.poolplace[11][4][0].y = posy40;
        this.poolplace[11][5][0].x = 359 + 262;
        this.poolplace[11][5][0].y = posy50;
        this.poolplace[11][6][0].x = 359 + 262;
        this.poolplace[11][6][0].y = posy60;
        this.poolplace[11][7][0].x = 359 + 262;
        this.poolplace[11][7][0].y = posy70;
        this.poolplace[11][0][1].x = 357 + 262;
        this.poolplace[11][0][1].y = posy01;
        this.poolplace[11][1][1].x = 357 + 262;
        this.poolplace[11][1][1].y = posy11;
        this.poolplace[11][2][1].x = 357 + 262;
        this.poolplace[11][2][1].y = posy21;
        this.poolplace[11][3][1].x = 357 + 262;
        this.poolplace[11][3][1].y = posy31;
        this.poolplace[11][4][1].x = 357 + 262;
        this.poolplace[11][4][1].y = posy41;
        this.poolplace[11][5][1].x = 357 + 262;
        this.poolplace[11][5][1].y = posy51;
        this.poolplace[11][6][1].x = 357 + 262;
        this.poolplace[11][6][1].y = posy61;
        this.poolplace[11][7][1].x = 357 + 262;
        this.poolplace[11][7][1].y = posy71;

        this.poolplace[24][0][0].x = 164 - 50;
        this.poolplace[24][0][0].y = posy10;
        this.poolplace[24][1][0].x = 164 - 50;
        this.poolplace[24][1][0].y = posy20;
        this.poolplace[24][2][0].x = 164 - 50;
        this.poolplace[24][2][0].y = posy20;
        this.poolplace[24][3][0].x = 164 - 50;
        this.poolplace[24][3][0].y = posy30;
        this.poolplace[24][4][0].x = 164 - 50;
        this.poolplace[24][4][0].y = posy40;
        this.poolplace[24][5][0].x = 164 - 50;
        this.poolplace[24][5][0].y = posy50;
        this.poolplace[24][6][0].x = 164 - 50;
        this.poolplace[24][6][0].y = posy60;
        this.poolplace[24][7][0].x = 164 - 50;
        this.poolplace[24][7][0].y = posy70;
        this.poolplace[24][0][1].x = 162 - 50;
        this.poolplace[24][0][1].y = posy01;
        this.poolplace[24][1][1].x = 162 - 50;
        this.poolplace[24][1][1].y = posy11;
        this.poolplace[24][2][1].x = 162 - 50;
        this.poolplace[24][2][1].y = posy21;
        this.poolplace[24][3][1].x = 162 - 50;
        this.poolplace[24][3][1].y = posy31;
        this.poolplace[24][4][1].x = 162 - 50;
        this.poolplace[24][4][1].y = posy41;
        this.poolplace[24][5][1].x = 162 - 50;
        this.poolplace[24][5][1].y = posy51;
        this.poolplace[24][6][1].x = 162 - 50;
        this.poolplace[24][6][1].y = posy61;
        this.poolplace[24][7][1].x = 162 - 50;
        this.poolplace[24][7][1].y = posy71;

        posy00 = 473;
        posy10 = 446;
        posy20 = 419;
        posy30 = 392;
        posy40 = 365;
        posy50 = 338;
        posy60 = 311;
        posy70 = 284;

        posy01 = 471;
        posy11 = 444;
        posy21 = 417;
        posy31 = 390;
        posy41 = 363;
        posy51 = 336;
        posy61 = 309;
        posy71 = 282;

        this.poolplace[12][0][0].x = 359 + 262;
        this.poolplace[12][0][0].y = posy00;
        this.poolplace[12][1][0].x = 359 + 262;
        this.poolplace[12][1][0].y = posy10;
        this.poolplace[12][2][0].x = 359 + 262;
        this.poolplace[12][2][0].y = posy20;
        this.poolplace[12][3][0].x = 359 + 262;
        this.poolplace[12][3][0].y = posy30;
        this.poolplace[12][4][0].x = 359 + 262;
        this.poolplace[12][4][0].y = posy40;
        this.poolplace[12][5][0].x = 359 + 262;
        this.poolplace[12][5][0].y = posy50;
        this.poolplace[12][6][0].x = 359 + 262;
        this.poolplace[12][6][0].y = posy60;
        this.poolplace[12][7][0].x = 359 + 262;
        this.poolplace[12][7][0].y = posy70;
        this.poolplace[12][0][1].x = 357 + 262;
        this.poolplace[12][0][1].y = posy01;
        this.poolplace[12][1][1].x = 357 + 262;
        this.poolplace[12][1][1].y = posy11;
        this.poolplace[12][2][1].x = 357 + 262;
        this.poolplace[12][2][1].y = posy21;
        this.poolplace[12][3][1].x = 357 + 262;
        this.poolplace[12][3][1].y = posy31;
        this.poolplace[12][4][1].x = 357 + 262;
        this.poolplace[12][4][1].y = posy41;
        this.poolplace[12][5][1].x = 357 + 262;
        this.poolplace[12][5][1].y = posy51;
        this.poolplace[12][6][1].x = 357 + 262;
        this.poolplace[12][6][1].y = posy61;
        this.poolplace[12][7][1].x = 357 + 262;
        this.poolplace[12][7][1].y = posy71;
        this.poolplace[13][0][0].x = 320 + 262;
        this.poolplace[13][0][0].y = posy00;
        this.poolplace[13][1][0].x = 320 + 262;
        this.poolplace[13][1][0].y = posy10;
        this.poolplace[13][2][0].x = 320 + 262;
        this.poolplace[13][2][0].y = posy20;
        this.poolplace[13][3][0].x = 320 + 262;
        this.poolplace[13][3][0].y = posy30;
        this.poolplace[13][4][0].x = 320 + 262;
        this.poolplace[13][4][0].y = posy40;
        this.poolplace[13][5][0].x = 320 + 262;
        this.poolplace[13][5][0].y = posy50;
        this.poolplace[13][6][0].x = 320 + 262;
        this.poolplace[13][6][0].y = posy60;
        this.poolplace[13][7][0].x = 320 + 262;
        this.poolplace[13][7][0].y = posy70;
        this.poolplace[13][0][1].x = 318 + 262;
        this.poolplace[13][0][1].y = posy01;
        this.poolplace[13][1][1].x = 318 + 262;
        this.poolplace[13][1][1].y = posy11;
        this.poolplace[13][2][1].x = 318 + 262;
        this.poolplace[13][2][1].y = posy21;
        this.poolplace[13][3][1].x = 318 + 262;
        this.poolplace[13][3][1].y = posy31;
        this.poolplace[13][4][1].x = 318 + 262;
        this.poolplace[13][4][1].y = posy41;
        this.poolplace[13][5][1].x = 318 + 262;
        this.poolplace[13][5][1].y = posy51;
        this.poolplace[13][6][1].x = 318 + 262;
        this.poolplace[13][6][1].y = posy61;
        this.poolplace[13][7][1].x = 318 + 262;
        this.poolplace[13][7][1].y = posy71;
        this.poolplace[14][0][0].x = 281 + 262;
        this.poolplace[14][0][0].y = posy00;
        this.poolplace[14][1][0].x = 281 + 262;
        this.poolplace[14][1][0].y = posy10;
        this.poolplace[14][2][0].x = 281 + 262;
        this.poolplace[14][2][0].y = posy20;
        this.poolplace[14][3][0].x = 281 + 262;
        this.poolplace[14][3][0].y = posy30;
        this.poolplace[14][4][0].x = 281 + 262;
        this.poolplace[14][4][0].y = posy40;
        this.poolplace[14][5][0].x = 281 + 262;
        this.poolplace[14][5][0].y = posy50;
        this.poolplace[14][6][0].x = 281 + 262;
        this.poolplace[14][6][0].y = posy60;
        this.poolplace[14][7][0].x = 281 + 262;
        this.poolplace[14][7][0].y = posy70;
        this.poolplace[14][0][1].x = 279 + 262;
        this.poolplace[14][0][1].y = posy01;
        this.poolplace[14][1][1].x = 279 + 262;
        this.poolplace[14][1][1].y = posy11;
        this.poolplace[14][2][1].x = 279 + 262;
        this.poolplace[14][2][1].y = posy21;
        this.poolplace[14][3][1].x = 279 + 262;
        this.poolplace[14][3][1].y = posy31;
        this.poolplace[14][4][1].x = 279 + 262;
        this.poolplace[14][4][1].y = posy41;
        this.poolplace[14][5][1].x = 279 + 262;
        this.poolplace[14][5][1].y = posy51;
        this.poolplace[14][6][1].x = 279 + 262;
        this.poolplace[14][6][1].y = posy61;
        this.poolplace[14][7][1].x = 279 + 262;
        this.poolplace[14][7][1].y = posy71;
        this.poolplace[15][0][0].x = 242 + 262;
        this.poolplace[15][0][0].y = posy00;
        this.poolplace[15][1][0].x = 242 + 262;
        this.poolplace[15][1][0].y = posy10;
        this.poolplace[15][2][0].x = 242 + 262;
        this.poolplace[15][2][0].y = posy20;
        this.poolplace[15][3][0].x = 242 + 262;
        this.poolplace[15][3][0].y = posy30;
        this.poolplace[15][4][0].x = 242 + 262;
        this.poolplace[15][4][0].y = posy40;
        this.poolplace[15][5][0].x = 242 + 262;
        this.poolplace[15][5][0].y = posy50;
        this.poolplace[15][6][0].x = 242 + 262;
        this.poolplace[15][6][0].y = posy60;
        this.poolplace[15][7][0].x = 242 + 262;
        this.poolplace[15][7][0].y = posy70;
        this.poolplace[15][0][1].x = 240 + 262;
        this.poolplace[15][0][1].y = posy01;
        this.poolplace[15][1][1].x = 240 + 262;
        this.poolplace[15][1][1].y = posy11;
        this.poolplace[15][2][1].x = 240 + 262;
        this.poolplace[15][2][1].y = posy21;
        this.poolplace[15][3][1].x = 240 + 262;
        this.poolplace[15][3][1].y = posy31;
        this.poolplace[15][4][1].x = 240 + 262;
        this.poolplace[15][4][1].y = posy41;
        this.poolplace[15][5][1].x = 240 + 262;
        this.poolplace[15][5][1].y = posy51;
        this.poolplace[15][6][1].x = 240 + 262;
        this.poolplace[15][6][1].y = posy61;
        this.poolplace[15][7][1].x = 240 + 262;
        this.poolplace[15][7][1].y = posy71;
        this.poolplace[16][0][0].x = 203 + 262;
        this.poolplace[16][0][0].y = posy00;
        this.poolplace[16][1][0].x = 203 + 262;
        this.poolplace[16][1][0].y = posy10;
        this.poolplace[16][2][0].x = 203 + 262;
        this.poolplace[16][2][0].y = posy20;
        this.poolplace[16][3][0].x = 203 + 262;
        this.poolplace[16][3][0].y = posy30;
        this.poolplace[16][4][0].x = 203 + 262;
        this.poolplace[16][4][0].y = posy40;
        this.poolplace[16][5][0].x = 203 + 262;
        this.poolplace[16][5][0].y = posy50;
        this.poolplace[16][6][0].x = 203 + 262;
        this.poolplace[16][6][0].y = posy60;
        this.poolplace[16][7][0].x = 203 + 262;
        this.poolplace[16][7][0].y = posy70;
        this.poolplace[16][0][1].x = 201 + 262;
        this.poolplace[16][0][1].y = posy01;
        this.poolplace[16][1][1].x = 201 + 262;
        this.poolplace[16][1][1].y = posy11;
        this.poolplace[16][2][1].x = 201 + 262;
        this.poolplace[16][2][1].y = posy21;
        this.poolplace[16][3][1].x = 201 + 262;
        this.poolplace[16][3][1].y = posy31;
        this.poolplace[16][4][1].x = 201 + 262;
        this.poolplace[16][4][1].y = posy41;
        this.poolplace[16][5][1].x = 201 + 262;
        this.poolplace[16][5][1].y = posy51;
        this.poolplace[16][6][1].x = 201 + 262;
        this.poolplace[16][6][1].y = posy61;
        this.poolplace[16][7][1].x = 201 + 262;
        this.poolplace[16][7][1].y = posy71;
        this.poolplace[17][0][0].x = 164 + 262;
        this.poolplace[17][0][0].y = posy00;
        this.poolplace[17][1][0].x = 164 + 262;
        this.poolplace[17][1][0].y = posy10;
        this.poolplace[17][2][0].x = 164 + 262;
        this.poolplace[17][2][0].y = posy20;
        this.poolplace[17][3][0].x = 164 + 262;
        this.poolplace[17][3][0].y = posy30;
        this.poolplace[17][4][0].x = 164 + 262;
        this.poolplace[17][4][0].y = posy40;
        this.poolplace[17][5][0].x = 164 + 262;
        this.poolplace[17][5][0].y = posy50;
        this.poolplace[17][6][0].x = 164 + 262;
        this.poolplace[17][6][0].y = posy60;
        this.poolplace[17][7][0].x = 164 + 262;
        this.poolplace[17][7][0].y = posy70;
        this.poolplace[17][0][1].x = 162 + 262;
        this.poolplace[17][0][1].y = posy01;
        this.poolplace[17][1][1].x = 162 + 262;
        this.poolplace[17][1][1].y = posy11;
        this.poolplace[17][2][1].x = 162 + 262;
        this.poolplace[17][2][1].y = posy21;
        this.poolplace[17][3][1].x = 162 + 262;
        this.poolplace[17][3][1].y = posy31;
        this.poolplace[17][4][1].x = 162 + 262;
        this.poolplace[17][4][1].y = posy41;
        this.poolplace[17][5][1].x = 162 + 262;
        this.poolplace[17][5][1].y = posy51;
        this.poolplace[17][6][1].x = 162 + 262;
        this.poolplace[17][6][1].y = posy61;
        this.poolplace[17][7][1].x = 162 + 262;
        this.poolplace[17][7][1].y = posy71;

        this.poolplace[18][0][0].x = 359;
        this.poolplace[18][0][0].y = posy00;
        this.poolplace[18][1][0].x = 359;
        this.poolplace[18][1][0].y = posy10;
        this.poolplace[18][2][0].x = 359;
        this.poolplace[18][2][0].y = posy20;
        this.poolplace[18][3][0].x = 359;
        this.poolplace[18][3][0].y = posy30;
        this.poolplace[18][4][0].x = 359;
        this.poolplace[18][4][0].y = posy40;
        this.poolplace[18][5][0].x = 359;
        this.poolplace[18][5][0].y = posy50;
        this.poolplace[18][6][0].x = 359;
        this.poolplace[18][6][0].y = posy60;
        this.poolplace[18][7][0].x = 359;
        this.poolplace[18][7][0].y = posy70;
        this.poolplace[18][0][1].x = 357;
        this.poolplace[18][0][1].y = posy01;
        this.poolplace[18][1][1].x = 357;
        this.poolplace[18][1][1].y = posy11;
        this.poolplace[18][2][1].x = 357;
        this.poolplace[18][2][1].y = posy21;
        this.poolplace[18][3][1].x = 357;
        this.poolplace[18][3][1].y = posy31;
        this.poolplace[18][4][1].x = 357;
        this.poolplace[18][4][1].y = posy41;
        this.poolplace[18][5][1].x = 357;
        this.poolplace[18][5][1].y = posy51;
        this.poolplace[18][6][1].x = 357;
        this.poolplace[18][6][1].y = posy61;
        this.poolplace[18][7][1].x = 357;
        this.poolplace[18][7][1].y = posy71;
        this.poolplace[19][0][0].x = 320;
        this.poolplace[19][0][0].y = posy00;
        this.poolplace[19][1][0].x = 320;
        this.poolplace[19][1][0].y = posy10;
        this.poolplace[19][2][0].x = 320;
        this.poolplace[19][2][0].y = posy20;
        this.poolplace[19][3][0].x = 320;
        this.poolplace[19][3][0].y = posy30;
        this.poolplace[19][4][0].x = 320;
        this.poolplace[19][4][0].y = posy40;
        this.poolplace[19][5][0].x = 320;
        this.poolplace[19][5][0].y = posy50;
        this.poolplace[19][6][0].x = 320;
        this.poolplace[19][6][0].y = posy60;
        this.poolplace[19][7][0].x = 320;
        this.poolplace[19][7][0].y = posy70;
        this.poolplace[19][0][1].x = 318;
        this.poolplace[19][0][1].y = posy01;
        this.poolplace[19][1][1].x = 318;
        this.poolplace[19][1][1].y = posy11;
        this.poolplace[19][2][1].x = 318;
        this.poolplace[19][2][1].y = posy21;
        this.poolplace[19][3][1].x = 318;
        this.poolplace[19][3][1].y = posy31;
        this.poolplace[19][4][1].x = 318;
        this.poolplace[19][4][1].y = posy41;
        this.poolplace[19][5][1].x = 318;
        this.poolplace[19][5][1].y = posy51;
        this.poolplace[19][6][1].x = 318;
        this.poolplace[19][6][1].y = posy61;
        this.poolplace[19][7][1].x = 318;
        this.poolplace[19][7][1].y = posy71;
        this.poolplace[20][0][0].x = 281;
        this.poolplace[20][0][0].y = posy00;
        this.poolplace[20][1][0].x = 281;
        this.poolplace[20][1][0].y = posy10;
        this.poolplace[20][2][0].x = 281;
        this.poolplace[20][2][0].y = posy20;
        this.poolplace[20][3][0].x = 281;
        this.poolplace[20][3][0].y = posy30;
        this.poolplace[20][4][0].x = 281;
        this.poolplace[20][4][0].y = posy40;
        this.poolplace[20][5][0].x = 281;
        this.poolplace[20][5][0].y = posy50;
        this.poolplace[20][6][0].x = 281;
        this.poolplace[20][6][0].y = posy60;
        this.poolplace[20][7][0].x = 281;
        this.poolplace[20][7][0].y = posy70;
        this.poolplace[20][0][1].x = 279;
        this.poolplace[20][0][1].y = posy01;
        this.poolplace[20][1][1].x = 279;
        this.poolplace[20][1][1].y = posy11;
        this.poolplace[20][2][1].x = 279;
        this.poolplace[20][2][1].y = posy21;
        this.poolplace[20][3][1].x = 279;
        this.poolplace[20][3][1].y = posy31;
        this.poolplace[20][4][1].x = 279;
        this.poolplace[20][4][1].y = posy41;
        this.poolplace[20][5][1].x = 279;
        this.poolplace[20][5][1].y = posy51;
        this.poolplace[20][6][1].x = 279;
        this.poolplace[20][6][1].y = posy61;
        this.poolplace[20][7][1].x = 279;
        this.poolplace[20][7][1].y = posy71;
        this.poolplace[21][0][0].x = 242;
        this.poolplace[21][0][0].y = posy00;
        this.poolplace[21][1][0].x = 242;
        this.poolplace[21][1][0].y = posy10;
        this.poolplace[21][2][0].x = 242;
        this.poolplace[21][2][0].y = posy20;
        this.poolplace[21][3][0].x = 242;
        this.poolplace[21][3][0].y = posy30;
        this.poolplace[21][4][0].x = 242;
        this.poolplace[21][4][0].y = posy40;
        this.poolplace[21][5][0].x = 242;
        this.poolplace[21][5][0].y = posy50;
        this.poolplace[21][6][0].x = 242;
        this.poolplace[21][6][0].y = posy60;
        this.poolplace[21][7][0].x = 242;
        this.poolplace[21][7][0].y = posy70;
        this.poolplace[21][0][1].x = 240;
        this.poolplace[21][0][1].y = posy01;
        this.poolplace[21][1][1].x = 240;
        this.poolplace[21][1][1].y = posy11;
        this.poolplace[21][2][1].x = 240;
        this.poolplace[21][2][1].y = posy21;
        this.poolplace[21][3][1].x = 240;
        this.poolplace[21][3][1].y = posy31;
        this.poolplace[21][4][1].x = 240;
        this.poolplace[21][4][1].y = posy41;
        this.poolplace[21][5][1].x = 240;
        this.poolplace[21][5][1].y = posy51;
        this.poolplace[21][6][1].x = 240;
        this.poolplace[21][6][1].y = posy61;
        this.poolplace[21][7][1].x = 240;
        this.poolplace[21][7][1].y = posy71;
        this.poolplace[22][0][0].x = 203;
        this.poolplace[22][0][0].y = posy00;
        this.poolplace[22][1][0].x = 203;
        this.poolplace[22][1][0].y = posy10;
        this.poolplace[22][2][0].x = 203;
        this.poolplace[22][2][0].y = posy20;
        this.poolplace[22][3][0].x = 203;
        this.poolplace[22][3][0].y = posy30;
        this.poolplace[22][4][0].x = 203;
        this.poolplace[22][4][0].y = posy40;
        this.poolplace[22][5][0].x = 203;
        this.poolplace[22][5][0].y = posy50;
        this.poolplace[22][6][0].x = 203;
        this.poolplace[22][6][0].y = posy60;
        this.poolplace[22][7][0].x = 203;
        this.poolplace[22][7][0].y = posy70;
        this.poolplace[22][0][1].x = 201;
        this.poolplace[22][0][1].y = posy01;
        this.poolplace[22][1][1].x = 201;
        this.poolplace[22][1][1].y = posy11;
        this.poolplace[22][2][1].x = 201;
        this.poolplace[22][2][1].y = posy21;
        this.poolplace[22][3][1].x = 201;
        this.poolplace[22][3][1].y = posy31;
        this.poolplace[22][4][1].x = 201;
        this.poolplace[22][4][1].y = posy41;
        this.poolplace[22][5][1].x = 201;
        this.poolplace[22][5][1].y = posy51;
        this.poolplace[22][6][1].x = 201;
        this.poolplace[22][6][1].y = posy61;
        this.poolplace[22][7][1].x = 201;
        this.poolplace[22][7][1].y = posy71;
        this.poolplace[23][0][0].x = 164;
        this.poolplace[23][0][0].y = posy00;
        this.poolplace[23][1][0].x = 164;
        this.poolplace[23][1][0].y = posy10;
        this.poolplace[23][2][0].x = 164;
        this.poolplace[23][2][0].y = posy20;
        this.poolplace[23][3][0].x = 164;
        this.poolplace[23][3][0].y = posy30;
        this.poolplace[23][4][0].x = 164;
        this.poolplace[23][4][0].y = posy40;
        this.poolplace[23][5][0].x = 164;
        this.poolplace[23][5][0].y = posy50;
        this.poolplace[23][6][0].x = 164;
        this.poolplace[23][6][0].y = posy60;
        this.poolplace[23][7][0].x = 164;
        this.poolplace[23][7][0].y = posy70;
        this.poolplace[23][0][1].x = 162;
        this.poolplace[23][0][1].y = posy01;
        this.poolplace[23][1][1].x = 162;
        this.poolplace[23][1][1].y = posy11;
        this.poolplace[23][2][1].x = 162;
        this.poolplace[23][2][1].y = posy21;
        this.poolplace[23][3][1].x = 162;
        this.poolplace[23][3][1].y = posy31;
        this.poolplace[23][4][1].x = 162;
        this.poolplace[23][4][1].y = posy41;
        this.poolplace[23][5][1].x = 162;
        this.poolplace[23][5][1].y = posy51;
        this.poolplace[23][6][1].x = 162;
        this.poolplace[23][6][1].y = posy61;
        this.poolplace[23][7][1].x = 162;
        this.poolplace[23][7][1].y = posy71;

        this.poolplace[25][0][0].x = 164 - 50;
        this.poolplace[25][0][0].y = posy00;
        this.poolplace[25][1][0].x = 164 - 50;
        this.poolplace[25][1][0].y = posy10;
        this.poolplace[25][2][0].x = 164 - 50;
        this.poolplace[25][2][0].y = posy20;
        this.poolplace[25][3][0].x = 164 - 50;
        this.poolplace[25][3][0].y = posy30;
        this.poolplace[25][4][0].x = 164 - 50;
        this.poolplace[25][4][0].y = posy40;
        this.poolplace[25][5][0].x = 164 - 50;
        this.poolplace[25][5][0].y = posy50;
        this.poolplace[25][6][0].x = 164 - 50;
        this.poolplace[25][6][0].y = posy60;
        this.poolplace[25][7][0].x = 164 - 50;
        this.poolplace[25][7][0].y = posy70;
        this.poolplace[25][0][1].x = 162 - 50;
        this.poolplace[25][0][1].y = posy01;
        this.poolplace[25][1][1].x = 162 - 50;
        this.poolplace[25][1][1].y = posy11;
        this.poolplace[25][2][1].x = 162 - 50;
        this.poolplace[25][2][1].y = posy21;
        this.poolplace[25][3][1].x = 162 - 50;
        this.poolplace[25][3][1].y = posy31;
        this.poolplace[25][4][1].x = 162 - 50;
        this.poolplace[25][4][1].y = posy41;
        this.poolplace[25][5][1].x = 162 - 50;
        this.poolplace[25][5][1].y = posy51;
        this.poolplace[25][6][1].x = 162 - 50;
        this.poolplace[25][6][1].y = posy61;
        this.poolplace[25][7][1].x = 162 - 50;
        this.poolplace[25][7][1].y = posy71;

        this.poolplace[26][0][0].x = -164;
        this.poolplace[26][0][0].y = -476;
        this.poolplace[26][1][0].x = -164;
        this.poolplace[26][1][0].y = -449;
        this.poolplace[26][2][0].x = -164;
        this.poolplace[26][2][0].y = -422;
        this.poolplace[26][3][0].x = -164;
        this.poolplace[26][3][0].y = -395;
        this.poolplace[26][4][0].x = -164;
        this.poolplace[26][4][0].y = -368;
        this.poolplace[26][5][0].x = -164;
        this.poolplace[26][5][0].y = -341;
        this.poolplace[26][6][0].x = -164;
        this.poolplace[26][6][0].y = -314;
        this.poolplace[26][7][0].x = -164;
        this.poolplace[26][7][0].y = -287;
        this.poolplace[26][0][1].x = -162;
        this.poolplace[26][0][1].y = -474;
        this.poolplace[26][1][1].x = -162;
        this.poolplace[26][1][1].y = -447;
        this.poolplace[26][2][1].x = -162;
        this.poolplace[26][2][1].y = -420;
        this.poolplace[26][3][1].x = -162;
        this.poolplace[26][3][1].y = -393;
        this.poolplace[26][4][1].x = -162;
        this.poolplace[26][4][1].y = -366;
        this.poolplace[26][5][1].x = -162;
        this.poolplace[26][5][1].y = -339;
        this.poolplace[26][6][1].x = -162;
        this.poolplace[26][6][1].y = -312;
        this.poolplace[26][7][1].x = -162;
        this.poolplace[26][7][1].y = -285;

        this.poolplace[27][0][0].x = -164;
        this.poolplace[27][0][0].y = -42;
        this.poolplace[27][1][0].x = -164;
        this.poolplace[27][1][0].y = -69;
        this.poolplace[27][2][0].x = -164;
        this.poolplace[27][2][0].y = -96;
        this.poolplace[27][3][0].x = -164;
        this.poolplace[27][3][0].y = -123;
        this.poolplace[27][4][0].x = -164;
        this.poolplace[27][4][0].y = -150;
        this.poolplace[27][5][0].x = -164;
        this.poolplace[27][5][0].y = -177;
        this.poolplace[27][6][0].x = -164;
        this.poolplace[27][6][0].y = -204;
        this.poolplace[27][7][0].x = -164;
        this.poolplace[27][7][0].y = -231;
        this.poolplace[27][0][1].x = -162;
        this.poolplace[27][0][1].y = -40;
        this.poolplace[27][1][1].x = -162;
        this.poolplace[27][1][1].y = -67;
        this.poolplace[27][2][1].x = -162;
        this.poolplace[27][2][1].y = -94;
        this.poolplace[27][3][1].x = -162;
        this.poolplace[27][3][1].y = -121;
        this.poolplace[27][4][1].x = -162;
        this.poolplace[27][4][1].y = -148;
        this.poolplace[27][5][1].x = -162;
        this.poolplace[27][5][1].y = -175;
        this.poolplace[27][6][1].x = -162;
        this.poolplace[27][6][1].y = -202;
        this.poolplace[27][7][1].x = -162;
        this.poolplace[27][7][1].y = -229;
        this.zarove = false;
        this.newgame = false;
        this.movepool = -1;
        this.dice1 = 6;
        this.dice2 = 6;
        this.mdice1 = this.mdice2 = this.mdice3 = this.mdice4 = 0;
    }

    async OnLButtonDown(point: CPoint) {
        if (this.OP) {
            for (let i = 1; i <= 2; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 2; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (this.OP && GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 2; i++) {
                        if (this.Name[i] !== 'Computer2') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!this.watch) {
            if ((this.P2OP || this.wait_opplayers[2] || !this.Server) && this.pause === 0 && this.KoiENaRed === 1) {
                if (!this.wait && !this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        this.pause = 1;
                        this.online_timer = 180;
                        this.setState({});
                        let p_str = '*|A|' + this.Name[1];
                        this.props.m_pwin.SendGame(p_str);
                        this.props.m_pwin.showAd();
                    }
                }
            }
            if (this.movepool >= 0) {
                if (!this.watch) {
                    if (!this.wait) {
                        if (this.KoiENaRed === 1) {
                            if (this.zarove) {
                                await this.AddToTableRect(
                                    this.movepool,
                                    new CPoint(point.x / GlobalVariables.scale, point.y / GlobalVariables.scale)
                                );
                                this.movepool = -1;
                                this.setState({});
                                if (this.CheckWinGame(1)) {
                                    if (!this.wait) {
                                        await this.InitRezDlg(0);
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (!this.wait) {
                    if (this.zarove) {
                        if (this.KoiENaRed === 1) {
                            for (let i = 0; i < 15; i++) {
                                if (
                                    point.x < (this.poolpoint[i].x + 19) * GlobalVariables.scale &&
                                    point.x > (this.poolpoint[i].x - 19) * GlobalVariables.scale &&
                                    point.y < (this.poolpoint[i].y + 25) * GlobalVariables.scale &&
                                    point.y > (this.poolpoint[i].y - 25) * GlobalVariables.scale
                                ) {
                                    if (this.CheckMovePool(i)) {
                                        this.movepool = i;
                                        this.sit_out_count = 0;
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        if (
                            (point.x > (this.dice_point1.x - GlobalVariables.diceWidth / 2) * GlobalVariables.scale &&
                                point.x < (this.dice_point1.x + GlobalVariables.diceWidth / 2) * GlobalVariables.scale &&
                                point.y > (this.dice_point1.y - GlobalVariables.diceWidth / 2) * GlobalVariables.scale &&
                                point.y < (this.dice_point1.y + GlobalVariables.diceHeight / 2) * GlobalVariables.scale) ||
                            (point.x > (this.dice_point2.x - GlobalVariables.diceWidth / 2) * GlobalVariables.scale &&
                                point.x < (this.dice_point2.x + GlobalVariables.diceWidth / 2) * GlobalVariables.scale &&
                                point.y > (this.dice_point2.y - GlobalVariables.diceHeight / 2) * GlobalVariables.scale &&
                                point.y < (this.dice_point2.y + GlobalVariables.diceHeight / 2) * GlobalVariables.scale)
                        ) {
                            if (this.KoiENaRed === 1) {
                                this.ThrowDice(-1);
                                this.sit_out_count = 0;
                                this.strelka = 1;
                                this.setState({});
                            }
                        }
                    }
                }
                if (this.movepool >= 0) {
                    this.FindMarked(this.movepool);
                    this.setState({});
                }
            }
        }
        if (this.ShowNewGameButton()) {
            if (this.ScaleRect(new CRect(657, 394, 657 + 27, 394 + 27)).PtInRect(point)) {
                await this.OnNewGameButton();
            }
        }
    }

    async OnNewGameButton() {
        if (((this.Server && this.P2OP) || (this.OP && !this.Server)) && this.props.m_pwin.m_main.GetUserVersion(this.Name[2]) < 480) {
            let message: string = this.GetLanguageText(
                'You cannot use this button because the (username) is playing with an older version of the game.'
            );
            message = message.replace('(username)', this.Name[2]);
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        for (let i = 0; i < this.props.m_pwin.pFrame.m_chat_bar.watchers.length; i++) {
            let watchUsername: string = this.props.m_pwin.pFrame.m_chat_bar.watchers[i];
            if (this.props.m_pwin.m_main.GetUserVersion(watchUsername) < 480) {
                let message = this.GetLanguageText('You cannot use this button because the (username) is playing with an older version of the game.');
                message = message.replace('(username)', watchUsername);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                return;
            }
        }
        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('Are you sure you want to surrender?'));
        this.wait = true;
        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
            if (this.OP) {
                this.props.m_pwin.SendGame('$|a'); //The Player give up
            }
            await this.InitRezDlg(1);
        } else {
            this.wait = false;
        }
    }

    async InitRezDlg(loss_game_player: number) {
        if (!this.endgame) {
            let player1 = this.Name[1];
            let player2 = this.Name[2];
            if (this.OP) {
                this.P1G = false;
                if (this.Server && loss_game_player !== 2) {
                    this.P2G = false;
                }
            }
            this.endgame = true;
            if (!this.watch) {
                this.op_start = false;
                if (this.OP) {
                    this.wait = true;
                }
                if (this.tab3) {
                    if (this.CheckWinGame(1) || loss_game_player === 2) {
                        this.rez1 = this.rez1 + this.GetGamePoints(loss_game_player);
                        this.KoiZapochva = 2;
                    } else {
                        this.rez2 = this.rez2 + this.GetGamePoints(loss_game_player);
                        this.KoiZapochva = 1;
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('scoring') + ' : ' + this.IntToString(this.rez1) + ' - ' + this.IntToString(this.rez2)
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();

                    if (this.rez1 < 7 && this.rez2 < 7) {
                        if (this.OP) {
                            let rezult = 'X|' + this.IntToString(this.rez1) + ' : ' + this.IntToString(this.rez2);
                            this.props.m_pwin.SendGame(rezult);
                        } else {
                            this.InitGame();
                            this.newgame = false;
                        }
                    } else {
                        let t_rezult = '';
                        t_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon 3 in 1', '');
                        let crezult = '';
                        let ctime = this.getCDateTime();
                        crezult = ctime + ' ' + player1 + ' ' + this.GetLanguageText('against') + ' ' + player2 + ' ';
                        crezult = crezult + this.GetLanguageText('scoring') + ' : ';
                        if (this.CheckWinGame(1) || loss_game_player === 2) {
                            let rez = this.IntToString(this.rez1) + ' : ' + this.IntToString(this.rez2);
                            crezult = crezult + rez + 'w;';
                            if (this.OP) {
                                let rezult = '$|n|1|' + rez;
                                this.props.m_pwin.SendGame(rezult);
                            }
                            if (!this.getIsTest()) {
                                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                    this.GetLanguageText('The game profit2') + ' ' + player1
                                );
                                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                            }
                        } else {
                            let rez = this.IntToString(this.rez1) + ' : ' + this.IntToString(this.rez2);
                            crezult = crezult + rez + 'l;';
                            if (this.OP) {
                                let rezult = '$|n|0|' + rez;
                                this.props.m_pwin.SendGame(rezult);
                            }
                            if (!this.getIsTest()) {
                                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                    this.GetLanguageText('The game profit2') + ' ' + player2
                                );
                                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                            }
                        }
                        await this.WriteProfileString('Options', 'Backgammon 3 in 1', t_rezult + crezult);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            if (this.OP) {
                                let rezult = 'X|0 : 0';
                                this.props.m_pwin.SendGame(rezult);
                            } else {
                                if (!this.OP) {
                                    this.InitNewGame();
                                    this.newgame = false;
                                }
                            }
                        } else {
                            if (this.OP) {
                                this.props.m_pwin.GoToLobby();
                            } else {
                                this.props.m_pwin.OnNewGame();
                            }
                            this.endInitRezDlg();
                            this.props.m_pwin.showAd();
                            return;
                        }
                    }
                } else {
                    let t_rezult = '';
                    if (this.type === 0) {
                        t_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Tabla', '');
                    }
                    if (this.type === 1) {
                        t_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon - Tapa', '');
                    }
                    if (this.type === 2) {
                        t_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon - Gulbara', '');
                    }
                    let crezult = '';
                    let ctime = this.getCDateTime();
                    player1 = this.Name[1];
                    player2 = this.Name[2];
                    crezult = ctime + ' ' + player1 + ' ' + this.GetLanguageText('against') + ' ' + player2 + ' ';
                    crezult = crezult + this.GetLanguageText('scoring') + ' : ';
                    if (this.CheckWinGame(1) || loss_game_player === 2) {
                        let rez = this.GetGameResult(loss_game_player);
                        crezult = crezult + rez + 'w;';
                        if (this.OP) {
                            let rezult = '$|n|1|' + rez;
                            this.props.m_pwin.SendGame(rezult);
                        }
                        if (!this.getIsTest()) {
                            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                this.GetLanguageText('The game profit2') + ' ' + player1
                            );
                            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        }
                    } else {
                        let rez = this.GetGameResult(loss_game_player);
                        crezult = crezult + rez + 'l;';
                        if (this.OP) {
                            let rezult = '$|n|0|' + rez;
                            this.props.m_pwin.SendGame(rezult);
                        }
                        if (!this.getIsTest()) {
                            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                this.GetLanguageText('The game profit2') + ' ' + player2
                            );
                            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        }
                    }
                    if (this.type === 0) {
                        await this.WriteProfileString('Options', 'Tabla', t_rezult + crezult);
                    }
                    if (this.type === 1) {
                        await this.WriteProfileString('Options', 'Backgammon - Tapa', t_rezult + crezult);
                    }
                    if (this.type === 2) {
                        await this.WriteProfileString('Options', 'Backgammon - Gulbara', t_rezult + crezult);
                    }
                    this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                    if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                        if (!this.OP) {
                            this.InitNewGame();
                            this.newgame = false;
                        }
                    } else {
                        if (this.OP) {
                            this.props.m_pwin.GoToLobby();
                        } else {
                            this.props.m_pwin.OnNewGame();
                        }
                        this.endInitRezDlg();
                        this.props.m_pwin.showAd();
                        return;
                    }
                }
                if (this.OP && this.Server) {
                    this.wait = false;
                }
                this.online_timer = this.online_timer_time;
            } else {
                if (this.tab3) {
                    if (this.CheckWinGame(1) || loss_game_player === 2) {
                        this.rez1 = this.rez1 + this.GetGamePoints(loss_game_player);
                    } else {
                        this.rez2 = this.rez2 + this.GetGamePoints(loss_game_player);
                    }
                }
            }
            this.endInitRezDlg();
        }
    }

    GetGameResult(loss_game_player: number) {
        let rez = '1 : 0';
        if (this.CheckWinGame(1) || loss_game_player === 2) {
            rez = '1 : 0';
            if (this.GetNumPools(27, 2) === 0) {
                rez = '2 : 0';
            }
        }
        if (this.CheckWinGame(2) || loss_game_player === 1) {
            rez = '0 : 1';
            if (this.GetNumPools(26, 1) === 0) {
                rez = '0 : 2';
            }
        }
        return rez;
    }

    InitNewGame() {
        if (this.tab3) {
            this.type = 1;
            this.rez1 = this.rez2 = 0;
        }
        this.InitGame();
        this.pause = 0;
        this.pause_player = '';
        for (let i = 1; i <= 2; i++) {
            this.last_Name[i] = this.Name[i];
        }
        if (this.SP === true || !this.Server || (this.OP && !this.P2OP)) {
            //    this.props.m_pwin.showAd();
        }
    }

    endInitRezDlg() {
        if (this.OP && !this.Server && !this.watch && this.props.m_pwin.game !== '') {
            if (this.CheckMessage()) {
                this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie' + this.GetAllMessages());
                this.ClearMessages();
            }
            this.props.m_pwin.SendGame('$|e');
        }
        this.P1G = true;
    }

    GetGamePoints(loss_game_player: number) {
        let rez = 1;
        if (this.CheckWinGame(1) || loss_game_player === 2) {
            if (this.GetNumPools(27, 2) === 0) {
                rez = 2;
            }
        }
        if (this.CheckWinGame(2) || loss_game_player === 1) {
            if (this.GetNumPools(26, 1) === 0) {
                rez = 2;
            }
        }
        return rez;
    }

    CheckWinGame(player: number) {
        if (this.type === 1) {
            //if(HaveTapedPool(0,1)&&HaveTapedPool(23,2))return true;
            if (player === 1 && this.HaveTapedPool(23, 2)) {
                return true;
            }
            if (player === 2 && this.HaveTapedPool(0, 1)) {
                return true;
            }
        }
        for (let i = 0; i < 26; i++) {
            for (let a = (player - 1) * 15; a < 15 + (player - 1) * 15; a++) {
                let table_index = this.GetPoolTableRectIndex(a);
                if (player === 1 && table_index < 24) {
                    return false;
                }
                if (player === 2 && table_index >= 0) {
                    return false;
                }
            }
        }
        return true;
    }

    GetPoolTableRectIndex(pool: number) {
        let player;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }
        let table_rect_index = -1;
        if (player === 2) {
            table_rect_index = 24;
        }
        if (this.type === 1 || this.type === 2) {
            table_rect_index = 0;
            if (player === 2) {
                table_rect_index = 23;
            }
        }
        let rexit = this.CheckReadyToExit(player);
        if (rexit) {
            if (player === 1) {
                table_rect_index = 24;
            } else {
                table_rect_index = -1;
            }
        }
        for (let i = 0; i < 24; i++) {
            if (this.tablerect[i].PtInRect(this.poolpos[pool])) {
                table_rect_index = i;
            }
        }
        return table_rect_index;
    }

    HaveTapedPool(rect: number, player: number) {
        let pool1 = this.GetNumPools(rect, 1);
        let pool2 = this.GetNumPools(rect, 2);
        for (let pool = (player - 1) * 15; pool < 15 + (player - 1) * 15; pool++) {
            if (this.poolpos[pool].x === this.poolplace[rect][0][0].x && this.poolpos[pool].y === this.poolplace[rect][0][0].y) {
                if ((player === 1 && pool2 > 0) || (player === 2 && pool1 > 0)) {
                    return true;
                }
            }
        }
        return false;
    }

    DrawTablaType() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        let text = 'Backgammon';
        if (this.type === 1) {
            text = 'Backgammon - Tapa';
        }
        if (this.type === 2) {
            text = 'Backgammon - Gulbara';
        }
        return (
            <View
                style={[
                    styles.containerAbsolute,
                    {
                        top: (this.pip_pos[2].y - 25) * GlobalVariables.scale,
                        left: this.pip_pos[2].x * GlobalVariables.scale,
                    },
                ]}
            >
                <Text>{text}</Text>
            </View>
        );
    }

    DrawArr() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        if (this.movepool >= 0) {
            this.FindMarked(this.movepool);
            let res = [];
            for (let i = 0; i < this.Marked1Br; i++) {
                let x = 169;
                let y = 17;
                if (this.Marked1[i] < 0 || this.Marked1[i] > 23) {
                    this.Marked1[i] = 25;
                }
                if (this.Marked1[i] < 12) {
                    x = x + this.Marked1[i] * 39;
                    if (this.Marked1[i] > 5) {
                        x = x + 28;
                    } else {
                        x = x - 1;
                    }
                } else {
                    y = 492;
                    x = x + 460;
                    x = x - (this.Marked1[i] - 12) * 39;
                    if (this.Marked1[i] > 17) {
                        x = x - 28;
                    }
                }
                if (i >= 0 && i < 2) {
                    res.push(<CPicture image={this.arr[i]} m_x={x} m_y={y} m_Width={15} m_Height={12} key={'arr' + i} />);
                }
            }
            return res;
        } else {
            return null;
        }
    }

    FindMarked(pool: number) {
        let pos = this.GetRectPool(pool);
        this.MarkedBr = 0;
        this.Marked1Br = 1;
        for (let i = 0; i < 100; i++) {
            this.Marked[i] = -1;
        }
        for (let i = 0; i < 10; i++) {
            this.Marked1[i] = -1;
        }
        if (this.CheckMovePool(pool)) {
            if (this.dice1 !== this.dice2) {
                if (this.mdice1 !== 0 && this.mdice2 !== 0) {
                    if (this.type === 0) {
                        this.FindAllPosMovesObP1();
                    }
                    if (this.type === 1) {
                        this.FindAllPosMovesTapP1();
                    }
                    if (this.type === 2) {
                        this.FindAllPosMovesGulP1();
                    }
                    if (this.AllPosMovesP12br > 0) {
                        for (let i = 0; i < this.AllPosMovesP12br; i++) {
                            if (this.AllPosMovesP12[i][0][0] === pos) {
                                this.Marked[this.MarkedBr] = this.AllPosMovesP12[i][0][1];
                                this.MarkedBr++;
                            }
                        }
                    } else {
                        if (this.AllPosMovesP111br > 0) {
                            for (let i = 0; i < this.AllPosMovesP111br; i++) {
                                if (this.AllPosMovesP111[i][0] === pos) {
                                    this.Marked[this.MarkedBr] = this.AllPosMovesP111[i][1];
                                    this.MarkedBr++;
                                }
                            }
                        }
                        if (this.AllPosMovesP112br > 0) {
                            for (let i = 0; i < this.AllPosMovesP112br; i++) {
                                if (this.AllPosMovesP112[i][0] === pos) {
                                    this.Marked[this.MarkedBr] = this.AllPosMovesP112[i][1];
                                    this.MarkedBr++;
                                }
                            }
                        }
                    }
                } else {
                    if (this.mdice1 > 0) {
                        if (pos === 24) {
                            if (this.type === 0) {
                                this.Marked[this.MarkedBr] = this.mdice1 - 1;
                                this.MarkedBr++;
                            }
                            if (this.type === 1 || this.type === 2) {
                                this.Marked[this.MarkedBr] = this.mdice1;
                                this.MarkedBr++;
                            }
                        } else {
                            if (pos + this.mdice1 < 24) {
                                this.Marked[this.MarkedBr] = this.mdice1 + pos;
                                this.MarkedBr++;
                            }
                            if (pos + this.mdice1 >= 24) {
                                this.Marked[this.MarkedBr] = 25;
                                this.MarkedBr++;
                            }
                        }
                    }
                    if (this.mdice2 > 0) {
                        if (pos === 24) {
                            if (this.type === 0) {
                                this.Marked[this.MarkedBr] = this.mdice2 - 1;
                                this.MarkedBr++;
                            }
                            if (this.type === 1 || this.type === 2) {
                                this.Marked[this.MarkedBr] = this.mdice2;
                                this.MarkedBr++;
                            }
                        } else {
                            if (pos + this.mdice1 < 24) {
                                this.Marked[this.MarkedBr] = this.mdice2 + pos;
                                this.MarkedBr++;
                            }
                            if (pos + this.mdice1 >= 24) {
                                this.Marked[this.MarkedBr] = 25;
                                this.MarkedBr++;
                            }
                        }
                    }
                }
            }
            if (this.dice1 === this.dice2) {
                if (pos === 24) {
                    if (this.type === 0) {
                        this.Marked[this.MarkedBr] = this.dice1 - 1;
                        this.MarkedBr++;
                    }
                    if (this.type === 1 || this.type === 2) {
                        this.Marked[this.MarkedBr] = this.dice1;
                        this.MarkedBr++;
                    }
                } else {
                    if (pos + this.dice1 < 24) {
                        this.Marked[this.MarkedBr] = this.dice1 + pos;
                        this.MarkedBr++;
                    }
                    if (pos + this.mdice1 >= 24) {
                        this.Marked[this.MarkedBr] = 25;
                        this.MarkedBr++;
                    }
                }
            }
        }
        this.Marked1[0] = this.Marked[0];
        for (let i = 0; i < this.MarkedBr; i++) {
            let Different: boolean = true;
            for (let j = 0; j < this.Marked1Br; j++) {
                if (this.Marked1[j] === this.Marked[i]) {
                    Different = false;
                }
            }
            if (Different) {
                this.Marked1[this.Marked1Br] = this.Marked[i];
                this.Marked1Br++;
            }
        }
    }

    FindAllPosMovesGulP1() {
        this.full();
        this.AllPosMovesP12br = 0;
        this.AllPosMovesP111br = 0;
        this.AllPosMovesP112br = 0;
        if (this.mdice1 > 0 && this.mdice2 > 0 && this.mdice1 !== this.mdice2) {
            for (let i = 0; i < 26; i++) {
                if (i + this.mdice1 < 24 && i !== 25 && i !== 24 && i !== 0 && this.table[i][0] > 0 && this.table[(i + this.mdice1) % 24][1] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][i + this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = i + this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (i === 0 && this.table[i][0] > 0 && this.table[i + this.mdice1][1] < 1 && this.table[24][0] === 0) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][i + this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = i + this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (i === 24 && this.table[i][0] > 0 && this.table[this.mdice1][1] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (this.CheckReadyToExitGul(this.table, 1) && this.table[i][0] > 0) {
                    if (i + this.mdice1 === 24) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                        this.AllPosMovesP111br++;
                    }
                    if (
                        i + this.mdice1 > 24 &&
                        this.table[i - 1][0] === 0 &&
                        this.table[i - 2][0] === 0 &&
                        this.table[i - 3][0] === 0 &&
                        this.table[i - 4][0] === 0 &&
                        this.table[i - 5][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                        this.AllPosMovesP111br++;
                    }
                }
            }
            for (let i = 0; i < 26; i++) {
                if (i + this.mdice2 < 24 && i !== 25 && i !== 24 && i !== 0 && this.table[i][0] > 0 && this.table[(i + this.mdice2) % 24][1] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][i + this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = i + this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (i === 0 && this.table[i][0] > 0 && this.table[i + this.mdice2][1] < 1 && this.table[24][0] === 0) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][i + this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = i + this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (i === 24 && this.table[i][0] > 0 && this.table[this.mdice2][1] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (this.CheckReadyToExitGul(this.table, 1) && this.table[i][0] > 0) {
                    if (i + this.mdice2 === 24) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                        this.AllPosMovesP112br++;
                    }
                    if (
                        i + this.mdice2 > 24 &&
                        this.table[i - 1][0] === 0 &&
                        this.table[i - 2][0] === 0 &&
                        this.table[i - 3][0] === 0 &&
                        this.table[i - 4][0] === 0 &&
                        this.table[i - 5][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                        this.AllPosMovesP112br++;
                    }
                }
            }
            /////////////////////////////////////////////////////////////////////////////////////////////
            for (let l = 0; l < this.AllPosMovesP111br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i + this.mdice2 < 24 &&
                        i !== 25 &&
                        i !== 24 &&
                        i !== 0 &&
                        this.AllPosPosP111[l][i][0] > 0 &&
                        this.AllPosPosP111[l][(i + this.mdice2) % 24][1] < 1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 0 &&
                        this.AllPosPosP111[l][i][0] > 0 &&
                        this.AllPosPosP111[l][i + this.mdice2][1] < 1 &&
                        this.AllPosPosP111[l][24][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (i === 24 && this.AllPosPosP111[l][i][0] > 0 && this.AllPosPosP111[l][this.mdice2][1] < 1) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (this.CheckReadyToExitGul(this.AllPosPosP111[l], 1) && this.AllPosPosP111[l][i][0] > 0) {
                        if (i + this.mdice2 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice2 > 24 &&
                            this.AllPosPosP111[l][i - 1][0] === 0 &&
                            this.AllPosPosP111[l][i - 2][0] === 0 &&
                            this.AllPosPosP111[l][i - 3][0] === 0 &&
                            this.AllPosPosP111[l][i - 4][0] === 0 &&
                            this.AllPosPosP111[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
            for (let l = 0; l < this.AllPosMovesP112br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i + this.mdice1 < 24 &&
                        i !== 25 &&
                        i !== 24 &&
                        i !== 0 &&
                        this.AllPosPosP112[l][i][0] > 0 &&
                        this.AllPosPosP112[l][(i + this.mdice1) % 24][1] < 1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 0 &&
                        this.AllPosPosP112[l][i][0] > 0 &&
                        this.AllPosPosP112[l][i + this.mdice1][1] < 1 &&
                        this.AllPosPosP112[l][24][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (i === 24 && this.AllPosPosP112[l][i][0] > 0 && this.AllPosPosP112[l][this.mdice1][1] < 1) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (this.CheckReadyToExitGul(this.AllPosPosP112[l], 1) && this.AllPosPosP112[l][i][0] > 0) {
                        if (i + this.mdice1 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice1 > 24 &&
                            this.AllPosPosP112[l][i - 1][0] === 0 &&
                            this.AllPosPosP112[l][i - 2][0] === 0 &&
                            this.AllPosPosP112[l][i - 3][0] === 0 &&
                            this.AllPosPosP112[l][i - 4][0] === 0 &&
                            this.AllPosPosP112[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
        }
    }

    CheckReadyToExitGul(a: number[][], player: number) {
        if (player === 1) {
            for (let i = 0; i < 18; i++) {
                if (a[i][0] > 0) {
                    return false;
                }
            }
        }
        if (player === 2) {
            for (let i = 0; i < 6; i++) {
                if (a[i][1] > 0) {
                    return false;
                }
            }
            for (let i = 12; i < 24; i++) {
                if (a[i][1] > 0) {
                    return false;
                }
            }
        }
        return true;
    }

    FindAllPosMovesTapP1() {
        this.full();
        this.AllPosMovesP12br = 0;
        this.AllPosMovesP111br = 0;
        this.AllPosMovesP112br = 0;
        if (this.mdice1 > 0 && this.mdice2 > 0 && this.mdice1 !== this.mdice2) {
            for (let i = 0; i < 26; i++) {
                if (
                    i + this.mdice1 < 24 &&
                    i !== 25 &&
                    i !== 24 &&
                    i !== 0 &&
                    this.table[i][0] > 0 &&
                    this.table[(i + this.mdice1) % 24][1] < 2 &&
                    this.table[(i + this.mdice1) % 24][0] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][i + this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = i + this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (
                    i === 0 &&
                    this.table[i][0] > 0 &&
                    this.table[this.mdice1][1] < 2 &&
                    this.table[24][0] === 0 &&
                    this.table[this.mdice1][0] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][i + this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = i + this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (i === 24 && this.table[i][0] > 0 && this.table[this.mdice1][1] < 2 && this.table[this.mdice1][0] !== -1) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                        this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                    this.AllPosPosP111[this.AllPosMovesP111br][this.mdice1][0]++;
                    this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                    this.AllPosMovesP111[this.AllPosMovesP111br][1] = this.mdice1;
                    this.AllPosMovesP111br++;
                }
                if (this.CheckReadyToExitTap(this.table, 1) && this.table[i][0] > 0) {
                    if (i + this.mdice1 === 24) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                        this.AllPosMovesP111br++;
                    }
                    if (
                        i + this.mdice1 > 24 &&
                        this.table[i - 1][0] === 0 &&
                        this.table[i - 2][0] === 0 &&
                        this.table[i - 3][0] === 0 &&
                        this.table[i - 4][0] === 0 &&
                        this.table[i - 5][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                        this.AllPosMovesP111br++;
                    }
                }
            }
            for (let i = 0; i < 26; i++) {
                if (
                    i + this.mdice2 < 24 &&
                    i !== 25 &&
                    i !== 24 &&
                    i !== 0 &&
                    this.table[i][0] > 0 &&
                    this.table[(i + this.mdice2) % 24][1] < 2 &&
                    this.table[(i + this.mdice2) % 24][0] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][i + this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = i + this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (
                    i === 0 &&
                    this.table[i][0] > 0 &&
                    this.table[i + this.mdice2][1] < 2 &&
                    this.table[i + this.mdice2][0] !== -1 &&
                    this.table[24][0] === 0
                ) {
                    //i tuk mai
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][i + this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = i + this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (i === 24 && this.table[i][0] > 0 && this.table[this.mdice2][1] < 2 && this.table[this.mdice2][0] !== -1) {
                    //i tuk
                    for (let j = 0; j < 26; j++) {
                        this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                        this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                    }
                    this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                    this.AllPosPosP112[this.AllPosMovesP112br][this.mdice2][0]++;
                    this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                    this.AllPosMovesP112[this.AllPosMovesP112br][1] = this.mdice2;
                    this.AllPosMovesP112br++;
                }
                if (this.CheckReadyToExitTap(this.table, 1) && this.table[i][0] > 0) {
                    if (i + this.mdice2 === 24) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                        this.AllPosMovesP112br++;
                    }
                    if (
                        i + this.mdice2 > 24 &&
                        this.table[i - 1][0] === 0 &&
                        this.table[i - 2][0] === 0 &&
                        this.table[i - 3][0] === 0 &&
                        this.table[i - 4][0] === 0 &&
                        this.table[i - 5][0] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                        this.AllPosMovesP112br++;
                    }
                }
            }
            /////////////////////////////////////////////////////////////
            for (let l = 0; l < this.AllPosMovesP111br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i + this.mdice2 < 24 &&
                        i !== 25 &&
                        i !== 24 &&
                        i !== 0 &&
                        this.AllPosPosP111[l][i][0] > 0 &&
                        this.AllPosPosP111[l][(i + this.mdice2) % 24][1] < 2 &&
                        this.AllPosPosP111[l][(i + this.mdice2) % 24][0] !== -1
                    ) {
                        //i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 0 &&
                        this.AllPosPosP111[l][i][0] > 0 &&
                        this.AllPosPosP111[l][i + this.mdice2][1] < 2 &&
                        this.AllPosPosP111[l][i + this.mdice2][0] !== -1 &&
                        this.AllPosPosP111[l][24][0] === 0
                    ) {
                        //i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 24 &&
                        this.AllPosPosP111[l][i][0] > 0 &&
                        this.AllPosPosP111[l][this.mdice2][1] < 2 &&
                        this.AllPosPosP111[l][this.mdice2][0] !== -1
                    ) {
                        //i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][this.mdice2][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice2;
                        this.AllPosMovesP12br++;
                    }
                    if (this.CheckReadyToExitTap(this.AllPosPosP111[l], 1) && this.AllPosPosP111[l][i][0] > 0) {
                        if (i + this.mdice2 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice2 > 24 &&
                            this.AllPosPosP111[l][i - 1][0] === 0 &&
                            this.AllPosPosP111[l][i - 2][0] === 0 &&
                            this.AllPosPosP111[l][i - 3][0] === 0 &&
                            this.AllPosPosP111[l][i - 4][0] === 0 &&
                            this.AllPosPosP111[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
            for (let l = 0; l < this.AllPosMovesP112br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i + this.mdice1 < 24 &&
                        i !== 25 &&
                        i !== 24 &&
                        i !== 0 &&
                        this.AllPosPosP112[l][i][0] > 0 &&
                        this.AllPosPosP112[l][(i + this.mdice1) % 24][1] < 2 &&
                        this.AllPosPosP112[l][(i + this.mdice1) % 24][0] !== -1
                    ) {
                        //i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 0 &&
                        this.AllPosPosP112[l][i][0] > 0 &&
                        this.AllPosPosP112[l][i + this.mdice1][1] < 2 &&
                        this.AllPosPosP112[l][i + this.mdice1][0] !== -1 &&
                        this.AllPosPosP112[l][24][0] === 0
                    ) {
                        //i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0]; //!!!
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1]; //!!!
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (
                        i === 24 &&
                        this.AllPosPosP112[l][i][0] > 0 &&
                        this.AllPosPosP112[l][this.mdice1][1] < 2 &&
                        this.AllPosPosP112[l][this.mdice1][0] !== -1
                    ) {
                        //!!!i tuk
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                            this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                        }
                        this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                        this.AllPosPosP12[this.AllPosMovesP12br][this.mdice1][0]++;
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0]; //!!!
                        this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1]; //!!!
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                        this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice1;
                        this.AllPosMovesP12br++;
                    }
                    if (this.CheckReadyToExitTap(this.AllPosPosP112[l], 1) && this.AllPosPosP112[l][i][0] > 0) {
                        if (i + this.mdice1 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice1 > 24 &&
                            this.AllPosPosP112[l][i - 1][0] === 0 &&
                            this.AllPosPosP112[l][i - 2][0] === 0 &&
                            this.AllPosPosP112[l][i - 3][0] === 0 &&
                            this.AllPosPosP112[l][i - 4][0] === 0 &&
                            this.AllPosPosP112[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
        }
    }

    CheckReadyToExitTap(a: number[][], player: number) {
        if (player === 1) {
            for (let i = 0; i < 24; i++) {
                if (a[i][0] === -1) {
                    return false;
                }
            }
            for (let i = 0; i < 18; i++) {
                if (a[i][0] > 0) {
                    return false;
                }
            }
        }
        if (player === 2) {
            for (let i = 0; i < 24; i++) {
                if (a[i][1] === -1) {
                    return false;
                }
            }
            for (let i = 6; i < 24; i++) {
                if (a[i][1] > 0) {
                    return false;
                }
            }
        }
        return true;
    }

    FindAllPosMovesObP1() {
        this.full();
        this.AllPosMovesP12br = 0;
        this.AllPosMovesP111br = 0;
        this.AllPosMovesP112br = 0;
        if (this.dice1 !== this.dice2 && this.mdice1 > 0 && this.mdice2 > 0) {
            for (let i = 0; i < 26; i++) {
                if (i === 24 && this.table[24][0] > 0) {
                    if (this.table[this.mdice1 - 1][1] < 2) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][24][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][this.mdice1 - 1][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = 24;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = this.mdice1 - 1;
                        this.AllPosMovesP111br++;
                    }
                }
                if (this.table[24][0] === 0) {
                    if (i + this.mdice1 < 24 && this.table[i][0] > 0 && this.table[i + this.mdice1][1] < 2) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                            this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                        this.AllPosPosP111[this.AllPosMovesP111br][i + this.mdice1][0]++;
                        this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                        this.AllPosMovesP111[this.AllPosMovesP111br][1] = i + this.mdice1;
                        this.AllPosMovesP111br++;
                    }
                    if (this.CheckReadyToExitOb(this.table, 1) && this.table[i][0] > 0) {
                        if (i + this.mdice1 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                                this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                            this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                            this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                            this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                            this.AllPosMovesP111br++;
                        }
                        if (
                            i + this.mdice1 > 24 &&
                            this.table[i - 1][0] === 0 &&
                            this.table[i - 2][0] === 0 &&
                            this.table[i - 3][0] === 0 &&
                            this.table[i - 4][0] === 0 &&
                            this.table[i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP111[this.AllPosMovesP111br][j][0] = this.table[j][0];
                                this.AllPosPosP111[this.AllPosMovesP111br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP111[this.AllPosMovesP111br][i][0]--;
                            this.AllPosPosP111[this.AllPosMovesP111br][25][0]++;
                            this.AllPosMovesP111[this.AllPosMovesP111br][0] = i;
                            this.AllPosMovesP111[this.AllPosMovesP111br][1] = 25;
                            this.AllPosMovesP111br++;
                        }
                    }
                }
            }
            for (let i = 0; i < 26; i++) {
                if (i === 24 && this.table[24][0] > 0) {
                    if (this.table[this.mdice2 - 1][1] < 2) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][24][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][this.mdice2 - 1][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = 24;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = this.mdice2 - 1;
                        this.AllPosMovesP112br++;
                    }
                }
                if (this.table[24][0] === 0) {
                    if (i + this.mdice2 < 24 && this.table[i][0] > 0 && this.table[i + this.mdice2][1] < 2) {
                        for (let j = 0; j < 26; j++) {
                            this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                            this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                        }
                        this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                        this.AllPosPosP112[this.AllPosMovesP112br][i + this.mdice2][0]++;
                        this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                        this.AllPosMovesP112[this.AllPosMovesP112br][1] = i + this.mdice2;
                        this.AllPosMovesP112br++;
                    }
                    if (this.CheckReadyToExitOb(this.table, 1) && this.table[i][0] > 0) {
                        if (i + this.mdice2 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                                this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                            this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                            this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                            this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                            this.AllPosMovesP112br++;
                        }
                        if (
                            i + this.mdice2 > 24 &&
                            this.table[i - 1][0] === 0 &&
                            this.table[i - 2][0] === 0 &&
                            this.table[i - 3][0] === 0 &&
                            this.table[i - 4][0] === 0 &&
                            this.table[i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP112[this.AllPosMovesP112br][j][0] = this.table[j][0];
                                this.AllPosPosP112[this.AllPosMovesP112br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP112[this.AllPosMovesP112br][i][0]--;
                            this.AllPosPosP112[this.AllPosMovesP112br][25][0]++;
                            this.AllPosMovesP112[this.AllPosMovesP112br][0] = i;
                            this.AllPosMovesP112[this.AllPosMovesP112br][1] = 25;
                            this.AllPosMovesP112br++;
                        }
                    }
                }
            }
            /////////////////////////////////////////////////////////////////////////////////////
            for (let l = 0; l < this.AllPosMovesP111br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (i === 24 && this.AllPosPosP111[l][24][0] > 0) {
                        if (this.AllPosPosP111[l][this.mdice2 - 1][1] < 2) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][24][0]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][this.mdice2 - 1][0]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = 24;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice2 - 1;
                            this.AllPosMovesP12br++;
                        }
                    }
                    if (this.AllPosPosP111[l][24][0] === 0) {
                        if (i + this.mdice2 < 24 && this.AllPosPosP111[l][i][0] > 0 && this.AllPosPosP111[l][i + this.mdice2][1] < 2) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice2][0]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice2;
                            this.AllPosMovesP12br++;
                        }
                    }
                    if (this.CheckReadyToExitOb(this.AllPosPosP111[l], 1) && this.AllPosPosP111[l][i][0] > 0) {
                        if (i + this.mdice2 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice2 > 24 &&
                            this.AllPosPosP111[l][i - 1][0] === 0 &&
                            this.AllPosPosP111[l][i - 2][0] === 0 &&
                            this.AllPosPosP111[l][i - 3][0] === 0 &&
                            this.AllPosPosP111[l][i - 4][0] === 0 &&
                            this.AllPosPosP111[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP111[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP111[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP111[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP111[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
            for (let l = 0; l < this.AllPosMovesP112br; l++) {
                for (let i = 0; i < 26; i++) {
                    if (i === 24 && this.AllPosPosP112[l][24][0] > 0) {
                        if (this.AllPosPosP112[l][this.mdice1 - 1][1] < 2) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][24][0]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][this.mdice1 - 1][0]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = 24;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = this.mdice1 - 1;
                            this.AllPosMovesP12br++;
                        }
                    }
                    if (this.AllPosPosP112[l][24][0] === 0) {
                        if (i + this.mdice1 < 24 && this.AllPosPosP112[l][i][0] > 0 && this.AllPosPosP112[l][i + this.mdice1][1] < 2) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][0]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][i + this.mdice1][0]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = i + this.mdice1;
                            this.AllPosMovesP12br++;
                        }
                    }
                    if (this.CheckReadyToExitOb(this.AllPosPosP112[l], 1) && this.AllPosPosP112[l][i][0] > 0) {
                        if (i + this.mdice1 === 24) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                        if (
                            i + this.mdice1 > 24 &&
                            this.AllPosPosP112[l][i - 1][0] === 0 &&
                            this.AllPosPosP112[l][i - 2][0] === 0 &&
                            this.AllPosPosP112[l][i - 3][0] === 0 &&
                            this.AllPosPosP112[l][i - 4][0] === 0 &&
                            this.AllPosPosP112[l][i - 5][0] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP12[this.AllPosMovesP12br][j][0] = this.AllPosPosP112[l][j][0];
                                this.AllPosPosP12[this.AllPosMovesP12br][j][1] = this.AllPosPosP112[l][j][1];
                            }
                            this.AllPosPosP12[this.AllPosMovesP12br][i][1]--;
                            this.AllPosPosP12[this.AllPosMovesP12br][25][1]++;
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][0] = this.AllPosMovesP112[l][0];
                            this.AllPosMovesP12[this.AllPosMovesP12br][0][1] = this.AllPosMovesP112[l][1];
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][0] = i;
                            this.AllPosMovesP12[this.AllPosMovesP12br][1][1] = 25;
                            this.AllPosMovesP12br++;
                        }
                    }
                }
            }
        }
    }

    CheckReadyToExitOb(a: number[][], player: number) {
        if (player === 1) {
            for (let i = 0; i < 18; i++) {
                if (a[i][0] > 0) {
                    return false;
                }
            }
            if (a[24][0] > 0) {
                return false;
            }
        }
        if (player === 2) {
            for (let i = 6; i < 24; i++) {
                if (a[i][1] > 0) {
                    return false;
                }
            }
            if (a[25][1] > 0) {
                return false;
            }
        }
        return true;
    }

    ThrowDice(d: number) {
        let rnum = this.RandNumber(36);
        //rnum = 0; //TODO Remove Test
        if (d >= 0) {
            rnum = d;
        }
        this.dice1 = this.MyMathfloor(rnum / 6) + 1;
        this.dice2 = (rnum % 6) + 1;
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(1111, 'center', 0, 0);
        }
        if (this.dice1 === this.dice2) {
            this.mdice1 = this.dice1;
            this.mdice2 = this.dice1;
            this.mdice3 = this.dice1;
            this.mdice4 = this.dice1;
        } else {
            this.mdice1 = this.dice1;
            this.mdice2 = this.dice2;
            if (this.dice1 > this.dice2) {
                this.mdice1 = this.dice2;
                this.mdice2 = this.dice1;
            }
            this.mdice3 = 0;
            this.mdice4 = 0;
        }
        this.zarove = true;
        if (this.OP) {
            if (this.watch) {
                if (this.KoiENaRed === 1) {
                    this.strelka = 1;
                }
            }
            if (!this.watch) {
                if (this.KoiENaRed === 1) {
                    if (this.Server) {
                        if (this.P2OP || this.HaveWatch) {
                            let message = '$|o|' + this.IntToString(rnum);
                            this.props.m_pwin.SendGame(message);
                        }
                    } else {
                        let message = '$|p|' + this.IntToString(rnum);
                        this.props.m_pwin.SendGame(message);
                    }
                }
                if (this.KoiENaRed === 2) {
                    if (this.HaveWatch) {
                        if (this.Server && !this.P2OP) {
                            let message = '$|o|' + this.IntToString(rnum);
                            this.props.m_pwin.SendGame(message);
                        }
                    }
                }
            }
        }
        this.online_timer = this.online_timer_time;
        if (this.OP && !this.watch) {
            if (this.KoiENaRed === 1 && (!this.tab3 || (this.rez1 === 0 && this.rez2 === 0))) {
                if (!this.op_start) {
                    this.props.m_pwin.SendGame('L');
                    this.op_start = true;
                }
            }
        }
        this.setState({});
    }

    CheckMovePool(pool: number) {
        let table_rect_index = this.GetPoolTableRectIndex(pool);
        let ready_to_exit: boolean = this.CheckReadyToExit(1);
        let gmp = this.GetMinRectPool(1);
        if (this.poolpoint[pool].x < 0) {
            return false;
        }
        if (this.type === 0) {
            ////////////////////////
            this.full();
            let player;
            if (pool < 15) {
                player = 1;
            } else {
                player = 2;
            }
            if (player === 1) {
                if (this.dice1 !== this.dice2) {
                    if (this.mdice1 !== 0 && this.mdice2 !== 0) {
                        this.FindAllPosMovesObP1();
                        let pos = this.GetRectPool(pool);
                        if (this.AllPosMovesP12br > 0) {
                            for (let i = 0; i < this.AllPosMovesP12br; i++) {
                                if (this.AllPosMovesP12[i][0][0] === pos) {
                                    return true;
                                }
                            }
                        } else {
                            if (this.AllPosMovesP111br > 0) {
                                for (let i = 0; i < this.AllPosMovesP111br; i++) {
                                    if (this.AllPosMovesP111[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                            if (this.AllPosMovesP112br > 0) {
                                for (let i = 0; i < this.AllPosMovesP112br; i++) {
                                    if (this.AllPosMovesP112[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }
                }
            }
            //////////////////////////////////
            for (let move_pool_index = -1; move_pool_index < 25; move_pool_index++) {
                if (this.CheckFreeRect(move_pool_index, 1)) {
                    if (
                        this.mdice1 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) && ready_to_exit))
                    ) {
                        if ((this.HaveKiledPool(1) && table_rect_index === -1) || !this.HaveKiledPool(1)) {
                            return true;
                        }
                    }
                    if (
                        this.mdice2 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) && ready_to_exit))
                    ) {
                        if ((this.HaveKiledPool(1) && table_rect_index === -1) || !this.HaveKiledPool(1)) {
                            return true;
                        }
                    }
                    if (
                        this.mdice3 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) && ready_to_exit))
                    ) {
                        if ((this.HaveKiledPool(1) && table_rect_index === -1) || !this.HaveKiledPool(1)) {
                            return true;
                        }
                    }
                    if (
                        this.mdice4 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) && ready_to_exit))
                    ) {
                        if ((this.HaveKiledPool(1) && table_rect_index === -1) || !this.HaveKiledPool(1)) {
                            return true;
                        }
                    }
                }
            }
        }
        if (this.type === 1) {
            ////////////////////////
            this.full();
            let player;
            if (pool < 15) {
                player = 1;
            } else {
                player = 2;
            }
            if (player === 1) {
                if (this.dice1 !== this.dice2) {
                    if (this.mdice1 !== 0 && this.mdice2 !== 0) {
                        this.FindAllPosMovesTapP1();
                        let pos = this.GetRectPool(pool);
                        if (this.AllPosMovesP12br > 0) {
                            for (let i = 0; i < this.AllPosMovesP12br; i++) {
                                if (this.AllPosMovesP12[i][0][0] === pos) {
                                    return true;
                                }
                            }
                        } else {
                            if (this.AllPosMovesP111br > 0) {
                                for (let i = 0; i < this.AllPosMovesP111br; i++) {
                                    if (this.AllPosMovesP111[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                            if (this.AllPosMovesP112br > 0) {
                                for (let i = 0; i < this.AllPosMovesP112br; i++) {
                                    if (this.AllPosMovesP112[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }
                }
            }
            //////////////////////////////////
            if (!this.IsTapedPool(pool)) {
                let index = this.GetPoolRealTableRectIndex(pool);
                if (this.HaveKiledPool(1) && index === 0) {
                    return false;
                }
                for (let move_pool_index = -1; move_pool_index < 25; move_pool_index++) {
                    if (this.CheckFreeRect(move_pool_index, 1)) {
                        if (
                            this.mdice1 !== 0 &&
                            ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                                ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) && ready_to_exit))
                        ) {
                            //	if(this.HaveKiledPool(1)&&table_rect_index==-1||!this.HaveKiledPool(1)) {
                            return true;
                            //	}
                        }
                        if (
                            this.mdice2 !== 0 &&
                            ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                                ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) && ready_to_exit))
                        ) {
                            //	if(this.HaveKiledPool(1)&&table_rect_index==-1||!this.HaveKiledPool(1)) {
                            return true;
                            //	}
                        }
                        if (
                            this.mdice3 !== 0 &&
                            ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                                ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) && ready_to_exit))
                        ) {
                            //	if(this.HaveKiledPool(1)&&table_rect_index==-1||!this.HaveKiledPool(1)) {
                            return true;
                            //	}
                        }
                        if (
                            this.mdice4 !== 0 &&
                            ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                                ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) && ready_to_exit))
                        ) {
                            //	if(this.HaveKiledPool(1)&&table_rect_index==-1||!this.HaveKiledPool(1)) {
                            return true;
                            //	}
                        }
                    }
                }
            }
        }
        if (this.type === 2) {
            //Za Teodor++
            this.full();
            let player;
            let poolplace = this.GetRectPool(pool);
            if (pool < 15) {
                player = 1;
            } else {
                player = 2;
            }
            if (player === 1) {
                if (this.dice1 !== this.dice2) {
                    if (this.mdice1 !== 0 && this.mdice2 !== 0) {
                        this.FindAllPosMovesGulP1();
                        let pos = this.GetRectPool(pool);
                        if (this.AllPosMovesP12br > 0) {
                            for (let i = 0; i < this.AllPosMovesP12br; i++) {
                                if (this.AllPosMovesP12[i][0][0] === pos) {
                                    return true;
                                }
                            }
                        } else {
                            if (this.AllPosMovesP111br > 0) {
                                for (let i = 0; i < this.AllPosMovesP111br; i++) {
                                    if (this.AllPosMovesP111[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                            if (this.AllPosMovesP112br > 0) {
                                for (let i = 0; i < this.AllPosMovesP112br; i++) {
                                    if (this.AllPosMovesP112[i][0] === pos) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }
                }
                let rexit: boolean = this.CheckReadyToExit(1);
                if (poolplace === 24) {
                    if (this.table[poolplace + this.mdice1 - 24][1] === 0 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice2 - 24][1] === 0 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice3 - 24][1] === 0 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice4 - 24][1] === 0 && this.mdice4 !== 0) {
                        return true;
                    }
                }
                if (poolplace === 0) {
                    //return true;
                    if (this.table[poolplace + this.mdice1][1] === 0 && this.mdice1 !== 0 && this.table[24][0] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice2][1] === 0 && this.mdice2 !== 0 && this.table[24][0] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice3][1] === 0 && this.mdice3 !== 0 && this.table[24][0] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice4][1] === 0 && this.mdice4 !== 0 && this.table[24][0] === 0) {
                        return true;
                    }
                }
                if (poolplace < 24 && poolplace > 0) {
                    if (poolplace + this.mdice1 < 24 && this.mdice1 !== 0 && this.table[poolplace + this.mdice1][1] === 0) {
                        return true;
                    }
                    if (poolplace + this.mdice2 < 24 && this.mdice2 !== 0 && this.table[poolplace + this.mdice2][1] === 0) {
                        return true;
                    }
                    if (poolplace + this.mdice3 < 24 && this.mdice3 !== 0 && this.table[poolplace + this.mdice3][1] === 0) {
                        return true;
                    }
                    if (poolplace + this.mdice4 < 24 && this.mdice4 !== 0 && this.table[poolplace + this.mdice4][1] === 0) {
                        return true;
                    }
                }
                if (rexit) {
                    if (poolplace + this.mdice1 === 24 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice2 === 24 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice3 === 24 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice4 === 24 && this.mdice4 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice1 > 24 && this.mdice1 !== 0) {
                        for (let i = poolplace - 1; i > 17; i--) {
                            if (this.table[i][0] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice2 > 24 && this.mdice2 !== 0) {
                        for (let i = poolplace - 1; i > 17; i--) {
                            if (this.table[i][0] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice3 > 24 && this.mdice3 !== 0) {
                        for (let i = poolplace - 1; i > 17; i--) {
                            if (this.table[i][0] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice4 > 24 && this.mdice4 !== 0) {
                        for (let i = poolplace - 1; i > 17; i--) {
                            if (this.table[i][0] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                }
            } else {
                let rexit: boolean = this.CheckReadyToExit(2);
                if (poolplace === 25) {
                    if (this.table[poolplace + this.mdice1 - 13][0] === 0 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice2 - 13][0] === 0 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice3 - 13][0] === 0 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice4 - 13][0] === 0 && this.mdice4 !== 0) {
                        return true;
                    }
                }
                if (poolplace === 12) {
                    if (this.table[poolplace + this.mdice1][0] === 0 && this.mdice1 !== 0 && this.table[25][1] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice2][0] === 0 && this.mdice2 !== 0 && this.table[25][1] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice3][0] === 0 && this.mdice3 !== 0 && this.table[25][1] === 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice4][0] === 0 && this.mdice4 !== 0 && this.table[25][1] === 0) {
                        return true;
                    }
                }
                if (poolplace < 24 && poolplace > 12) {
                    if (this.table[(poolplace + this.mdice1) % 24][0] === 0 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (this.table[(poolplace + this.mdice2) % 24][0] === 0 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (this.table[(poolplace + this.mdice3) % 24][0] === 0 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (this.table[(poolplace + this.mdice4) % 24][0] === 0 && this.mdice4 !== 0) {
                        return true;
                    }
                }
                if (poolplace < 12 && poolplace >= 0) {
                    if (this.table[poolplace + this.mdice1][0] === 0 && poolplace + this.mdice1 < 12 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice2][0] === 0 && poolplace + this.mdice2 < 12 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice3][0] === 0 && poolplace + this.mdice3 < 12 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (this.table[poolplace + this.mdice4][0] === 0 && poolplace + this.mdice4 < 12 && this.mdice4 !== 0) {
                        return true;
                    }
                }
                if (rexit) {
                    if (poolplace + this.mdice1 === 12 && this.mdice1 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice2 === 12 && this.mdice2 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice3 === 12 && this.mdice3 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice4 === 12 && this.mdice4 !== 0) {
                        return true;
                    }
                    if (poolplace + this.mdice1 > 12 && this.mdice1 !== 0) {
                        for (let i = poolplace - 1; i > 5; i--) {
                            if (this.table[i][1] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice2 > 12 && this.mdice2 !== 0) {
                        for (let i = poolplace - 1; i > 5; i--) {
                            if (this.table[i][1] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice3 > 12 && this.mdice3 !== 0) {
                        for (let i = poolplace - 1; i > 5; i--) {
                            if (this.table[i][1] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                    if (poolplace + this.mdice4 > 12 && this.mdice4 !== 0) {
                        for (let i = poolplace - 1; i > 5; i--) {
                            if (this.table[i][1] > 0) {
                                return false;
                            }
                        }
                        return true;
                    }
                }
            }
        } //Za Teodor--
        return false;
    }

    GetPoolRealTableRectIndex(pool: number) {
        let player;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }
        let table_rect_index = -1;
        if (player === 2) {
            table_rect_index = 24;
        }
        let rexit: boolean = this.CheckReadyToExit(player);
        if (rexit) {
            if (player === 1) {
                table_rect_index = 24;
            } else {
                table_rect_index = -1;
            }
        }
        for (let i = 0; i < 24; i++) {
            if (this.tablerect[i].PtInRect(this.poolpos[pool])) {
                table_rect_index = i;
            }
        }
        return table_rect_index;
    }

    CheckFreeRect(rect: number, player: number) {
        let numpools = 0;
        let pindex: number;
        if (player === 1) {
            pindex = 2;
        } else {
            pindex = 1;
        }
        if (rect === -1) {
            return true;
        }
        for (let i = (pindex - 1) * 15; i < 15 + (pindex - 1) * 15; i++) {
            if (this.poolplace[rect][0][0].x === this.poolpos[i].x && this.poolplace[rect][0][0].y === this.poolpos[i].y) {
                numpools = numpools + 1;
            }
            if (this.poolplace[rect][0][1].x === this.poolpos[i].x && this.poolplace[rect][0][1].y === this.poolpos[i].y) {
                numpools = numpools + 1;
            } //opravil sam go
            if (this.poolplace[rect][1][0].x === this.poolpos[i].x && this.poolplace[rect][1][0].y === this.poolpos[i].y) {
                numpools = numpools + 1;
                if (this.type === 1) {
                    return false;
                }
            }
        }
        if (numpools < 2) {
            return true;
        }
        if (rect === -1 || rect === 24 || rect === 25 || rect === 26) {
            return true;
        }
        return false;
    }

    async OnRButtonDown(point: CPoint) {
        if (this.watch) {
            if (this.Name[2] === 'Computer2') {
                let s: CSize = this.GetTextExtent(this.Name[2], 13);
                let width = s.cx;
                if (
                    this.ScaleRect(new CRect(this.NamePos[2].x, this.NamePos[2].y, this.NamePos[2].x + width, this.NamePos[2].y + 20)).PtInRect(point)
                ) {
                    this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                    this.props.m_pwin.GoToLobby();
                }
            }
        }
        ////////////////////////////
        if (
            this.ScaleRect(
                new CRect(
                    this.dice_point1.x - GlobalVariables.diceWidth / 2,
                    this.dice_point1.y - GlobalVariables.diceWidth / 2,
                    this.dice_point1.x + GlobalVariables.diceWidth / 2,
                    this.dice_point1.y + GlobalVariables.diceHeight / 2
                )
            ).PtInRect(point) ||
            this.ScaleRect(
                new CRect(
                    this.dice_point2.x - GlobalVariables.diceWidth / 2,
                    this.dice_point2.y - GlobalVariables.diceWidth / 2,
                    this.dice_point2.x + GlobalVariables.diceWidth / 2,
                    this.dice_point2.y + GlobalVariables.diceHeight / 2
                )
            ).PtInRect(point)
        ) {
            let ct = await this.GetProfileInt('Options', 'dicetabla', 1);
            let nt = this.GetNextDesign(ct, 'dice');
            if (ct !== nt) {
                this.WriteProfileInt('Options', 'dicetabla', nt);
                this.LoadDice(nt);
                this.ReloadDice();
            }
            return;
        }

        for (let i = 0; i < 15; i++) {
            if (
                point.x < (this.poolpoint[i].x + 19) * GlobalVariables.scale &&
                point.x > (this.poolpoint[i].x - 19) * GlobalVariables.scale &&
                point.y < (this.poolpoint[i].y + 25) * GlobalVariables.scale &&
                point.y > (this.poolpoint[i].y - 25) * GlobalVariables.scale
            ) {
                return;
            }
        }

        for (let i = 15; i < 30; i++) {
            if (
                point.x < (this.poolpoint[i].x + 19) * GlobalVariables.scale &&
                point.x > (this.poolpoint[i].x - 19) * GlobalVariables.scale &&
                point.y < (this.poolpoint[i].y + 25) * GlobalVariables.scale &&
                point.y > (this.poolpoint[i].y - 25) * GlobalVariables.scale
            ) {
                let ct = await this.GetProfileInt('Options', 'poolsnew', 1);
                let nt = this.GetNextDesign(ct, 'pools');
                if (ct !== nt) {
                    await this.WriteProfileInt('Options', 'poolsnew', nt);
                    this.LoadPools(nt);
                    this.setState({});
                }
                return;
            }
        }

        if (
            point.x > 130 * GlobalVariables.scale &&
            point.x < 130 * GlobalVariables.scale + 523 * GlobalVariables.scale &&
            point.y > 16 * GlobalVariables.scale &&
            point.y < 16 * GlobalVariables.scale + 488 * GlobalVariables.scale
        ) {
            let ct = await this.GetProfileInt('Options', 'board', 1);
            let nt = this.GetNextDesign(ct, 'board');
            if (ct !== nt) {
                await this.WriteProfileInt('Options', 'board', nt);
                this.LoadBoard(nt);
                this.setState({});
            }
            return;
        }
    }

    ReceiveInitialization(init: string) {
        if (this.tab3) {
            let vpos = init.indexOf('?');
            this.type = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
            this.rez1 = this.rez2 = -1;
            if (this.type === 2) {
                this.type = 0;
            } else if (this.type === 0) {
                this.type = 1;
            } else if (this.type === 1) {
                this.type = 2;
            }
            this.InitGame();
            vpos = init.indexOf('?');
            this.rez1 = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            this.rez2 = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
        } else {
            this.InitNewGame();
        }

        for (let i = 0; i < 30; i++) {
            this.poolpos[i].x = this.poolpos[i].y = -50;
        }
        for (let i = 0; i < 30; i++) {
            let vpos = init.indexOf('?');
            let s1 = init.substring(0, vpos);
            let si = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            let sc1 = init.substring(0, vpos);
            let c1 = this._ttoi(sc1);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            let sc2 = init.substring(0, vpos);
            let c2 = this._ttoi(sc2);
            if (si === -1) {
                this.poolpos[i].x = this.poolplace[27][0][0].x;
                this.poolpos[i].y = this.poolplace[27][0][0].y;
            } else {
                this.poolpos[i].x = this.poolplace[si][c1][c2].x;
                this.poolpos[i].y = this.poolplace[si][c1][c2].y;
            }
            init = init.substring(vpos + 1, init.length);
        }
        let vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        if (this.KoiENaRed === 2) {
            this.strelka = 2;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.dice1 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.dice2 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice1 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice2 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice3 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice4 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.broihodove = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let bint = this._ttoi(init.substring(0, vpos));
        if (bint === 0) {
            this.Began = false;
        } else {
            this.Began = true;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let dint = this._ttoi(init.substring(0, vpos));
        if (dint === 0) {
            this.Doigravane = false;
        } else {
            this.Doigravane = true;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let zint = this._ttoi(init.substring(0, vpos));
        if (zint === 0) {
            this.zarove = false;
        } else {
            this.zarove = true;
        }
        init = init.substring(vpos + 1, init.length);
        this.wait = false;
        this.op_start = true;
        for (let i = 0; i < 30; i++) {
            this.poolpoint[i].x = this.poolpos[i].x;
            this.poolpoint[i].y = this.poolpos[i].y;
        }
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.setState({});
    }

    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string = '';
        if (f === 'a') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let init = this.props.m_pwin.ReceiveString(250);
            this.ReceiveInitialization(init);
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.wait = false;
            this.endgame = false;
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            this.props.m_pwin.GetPlayerRating(1); //TODO - What is this ???
            this.props.m_pwin.GetPlayerRating(2); //TODO - What is this ???
            if (this.tab3 && this.rez1 < 7 && this.rez2 < 7) {
                this.InitGame();
            } else {
                this.InitNewGame();
            }
            let koj = this.props.m_pwin.ReceiveString(1);
            if (this.watch) {
                if (koj === '1') {
                    koj = '2';
                } else {
                    koj = '1';
                }
            }
            if (koj === '1' || koj === '2') {
                this.KoiZapochva = this.KoiENaRed = this._ttoi(koj);
                if (this.KoiENaRed === 1) {
                    this.strelka = 3;
                } else {
                    this.strelka = 2;
                }
            }
            this.online_timer = this.online_timer_time;
            this.setState({});
            return true;
        }
        if (f === 'c') {
            //Puskane na Pool
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 5) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pool = this.props.m_pwin.ReceiveString(2);
            let rpool = this.props.m_pwin.ReceiveString(2);
            let poolpl = this.props.m_pwin.ReceiveString(2);
            let pos1 = this.props.m_pwin.ReceiveString(2);
            let pos2 = this.props.m_pwin.ReceiveString(2);
            if (this.watch) {
                if (this.KoiENaRed === 1) {
                    let p = this._ttoi(pool);
                    pool = this.IntToString(p - 15);
                    let newpoolpl = this._ttoi(poolpl);
                    if (this.type === 2) {
                        if (newpoolpl === 27) {
                            newpoolpl = 24;
                        } else {
                            newpoolpl = (12 + newpoolpl) % 24;
                        }
                    }
                    if (this.type === 1) {
                        if (newpoolpl === 27) {
                            newpoolpl = 24;
                        } else {
                            newpoolpl = 23 - newpoolpl;
                        }
                    }
                    if (this.type === 0) {
                        if (newpoolpl === 27) {
                            newpoolpl = 24;
                        } else {
                            newpoolpl = 23 - newpoolpl;
                        }
                    }
                    if (
                        await this.AddToTableRect(
                            this._ttoi(pool),
                            new CPoint(this.tablerect[newpoolpl].BottomRight().x - 10, this.tablerect[newpoolpl].BottomRight().y - 10)
                        )
                    ) {
                    }
                } else {
                    await this.PuskPool(2, this._ttoi(pool), this._ttoi(rpool), this._ttoi(poolpl), this._ttoi(pos1), this._ttoi(pos2));
                }
            } else {
                if (this.KoiENaRed === 1 && !this.watch) {
                    await this.Timer3();
                }
                await this.PuskPool(2, this._ttoi(pool), this._ttoi(rpool), this._ttoi(poolpl), this._ttoi(pos1), this._ttoi(pos2));
                this.online_timer = this.online_timer_time;
                this.setState({});
            }
            return true;
        }
        if (f === 'd') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            await this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'e') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(300);
            await this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        if (f === 'f') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let dmess = this.props.m_pwin.ReceiveString(2);
            if (this.watch) {
                await this.Timer3();
                this.ThrowDice(this._ttoi(dmess));
                if (this.KoiENaRed === 2) {
                    this.strelka = 2;
                    this.setState({});
                } else {
                }
            } else {
                if (this.KoiENaRed === 1) {
                    await this.Timer3();
                }
                if (this.KoiENaRed === 2) {
                    this.ThrowDice(this._ttoi(dmess));
                    if (this.pause_player !== '') {
                        this.pause_player = '';
                        this.setState({});
                    }
                    this.strelka = 2;
                    this.setState({});
                } else {
                    this.props.m_pwin.SendErrorToLog('Hvarliane na zarovete predi da e svarshil Player1');
                }
            }
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(20);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                if (this.watch) {
                    if (this.Name[2] === name) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    return true;
                }
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                this.online_timer = this.online_timer_time;
                if (this.Server) {
                    if (!this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                        this.props.m_pwin.SendGame('$|y');
                    }
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        this.wait = false;
                        return true;
                    }
                } else {
                    if (!this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                        if (!this.watch) {
                            this.props.m_pwin.SendGame('$|y');
                        }
                    }
                    this.Server = true;
                    this.props.m_pwin.InitIconsTooltips();
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    this.wait = false;
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            //The Player give up
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (this.watch) {
                if (strRecvd === '1') {
                    await this.InitRezDlg(1);
                } else {
                    await this.InitRezDlg(2);
                }
            } else {
                this.wait = true;
                if (this.Server) {
                    this.P2G = false;
                }

                let message = this.GetLanguageText('User (username) surrendered.');
                message = message.replace('(username)', this.Name[2]);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                await this.InitRezDlg(2);
            }
            return true;
        }
        if (f === 'i') {
            this.P2G = true;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '5') {
                this.P2OP = true;
            }
            this.setState({});
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            return true;
        }
        if (f === 'm') {
        }
        if (f === 'n') {
        }
        if (f === 'o') {
            //Not used
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra this.Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let rating = this.props.m_pwin.ReceiveString(30);
            this.wait_opnames[2] = player_name;
            if (this.watch) {
                this.Name[2] = player_name;
                this.show_user[2] = false;
                this.props.m_pwin.GetPlayerRating(2);
                this.setState({});
                return true;
            }
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[2] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[2] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.wait_opnames[2] !== player_name) {
                        return true;
                    }
                    this.Name[2] = player_name;
                    this.show_user[2] = false;
                    this.props.m_pwin.GetPlayerRating(2);
                    this.P2OP = true;
                    this.P2G = true;
                    this.setState({});
                    if (this.HaveWatch) {
                        message = '$|s|1';
                        this.props.m_pwin.SendGame(message);
                    }
                    this.SendInitialization();
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (this.wait_opnames[2] !== player_name) {
                        return true;
                    }
                    this.Name[2] = player_name;
                    this.show_user[2] = false;
                    this.props.m_pwin.GetPlayerRating(2);
                    this.P2OP = true;
                    this.P2G = true;
                    this.setState({});
                    if (this.HaveWatch) {
                        message = '$|s|1';
                        this.props.m_pwin.SendGame(message);
                    }
                    this.SendInitialization();
                    return true;
                } else {
                    if (this.wait_opnames[2] !== player_name) {
                        return true;
                    }
                    message = '$|s|0';
                    this.props.m_pwin.SendGame(message);
                    this.wait_opnames[2] = '';
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        let title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title + '?');
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.props.m_pwin.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane po vreme na igra - otgovor
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please this.wait for the next game'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user") + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!tab : ' + f);

        return true;
    }

    SendInitialization() {
        this.OnRButtonDown(new CPoint(0, 0));
        this.Timer3();
        let message = '$|c|';
        if (this.tab3) {
            message = message + this.IntToString(this.type) + '?' + this.IntToString(this.rez2) + '?' + this.IntToString(this.rez1) + '?';
        }
        for (let i = 15; i < 30; i++) {
            let rp = this.GetRectPool(i);
            if (this.type === 2) {
                if (rp >= 0 && rp < 24) {
                    rp = (12 + rp) % 24;
                }
                if (rp === 26) {
                    rp = 27;
                } else {
                    if (rp === 27) {
                        rp = 26;
                    }
                }
                if (rp === 24) {
                    rp = 25;
                } else {
                    if (rp === 25) {
                        rp = 24;
                    }
                }
            } else {
                if (rp >= 0 && rp < 24) {
                    rp = 23 - rp;
                }
                if (rp === 26) {
                    rp = 27;
                } else {
                    if (rp === 27) {
                        rp = 26;
                    }
                }
                if (rp === 24) {
                    rp = 25;
                } else {
                    if (rp === 25) {
                        rp = 24;
                    }
                }
            }
            let c1 = 0;
            let c2 = 0;
            let breakC = false;
            for (let rect = 0; rect < 28; rect++) {
                if (breakC) {
                    break;
                }
                for (let q1 = 0; q1 < 8; q1++) {
                    if (breakC) {
                        break;
                    }
                    for (let q2 = 0; q2 < 2; q2++) {
                        if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                            c1 = q1;
                            c2 = q2;
                            breakC = true;
                            break;
                        }
                    }
                }
            }
            message = message + this.IntToString(rp) + '?' + this.IntToString(c1) + '?' + this.IntToString(c2) + '?';
        }
        for (let i = 0; i < 15; i++) {
            let rp = this.GetRectPool(i);
            if (this.type === 2) {
                if (rp >= 0 && rp < 24) {
                    rp = (12 + rp) % 24;
                }
                if (rp === 26) {
                    rp = 27;
                } else {
                    if (rp === 27) {
                        rp = 26;
                    }
                }
                if (rp === 24) {
                    rp = 25;
                } else {
                    if (rp === 25) {
                        rp = 24;
                    }
                }
            } else {
                if (rp >= 0 && rp < 24) {
                    rp = 23 - rp;
                }
                if (rp === 26) {
                    rp = 27;
                } else {
                    if (rp === 27) {
                        rp = 26;
                    }
                }
                if (rp === 24) {
                    rp = 25;
                } else {
                    if (rp === 25) {
                        rp = 24;
                    }
                }
            }
            let c1 = 0;
            let c2 = 0;
            let breakC2 = false;
            for (let rect = 0; rect < 28; rect++) {
                if (breakC2) {
                    break;
                }
                for (let q1 = 0; q1 < 8; q1++) {
                    if (breakC2) {
                        break;
                    }
                    for (let q2 = 0; q2 < 2; q2++) {
                        if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                            c1 = q1;
                            c2 = q2;
                            breakC2 = true;
                            break;
                        }
                    }
                }
            }
            message = message + this.IntToString(rp) + '?' + this.IntToString(c1) + '?' + this.IntToString(c2) + '?';
        }
        //koienared
        let koi = '1';
        if (this.KoiENaRed === 1) {
            koi = '2';
        }
        message = message + koi + '?';
        //this.zarove
        let z1 = this.IntToString(this.dice1);
        let z2 = this.IntToString(this.dice2);
        message = message + z1 + '?' + z2 + '?';
        //izigrani this.zarove
        let zi1 = this.IntToString(this.mdice1);
        let zi2 = this.IntToString(this.mdice2);
        let zi3 = this.IntToString(this.mdice3);
        let zi4 = this.IntToString(this.mdice4);
        message = message + zi1 + '?' + zi2 + '?' + zi3 + '?' + zi4 + '?';
        //broi hodove
        let brh = this.IntToString(this.broihodove);
        message = message + brh + '?';
        //this.Began
        let bstr = '1';
        if (!this.Began) {
            bstr = '0';
        }
        message = message + bstr + '?';
        //this.Doigravane
        let dstr = '1';
        if (!this.Doigravane) {
            dstr = '0';
        }
        message = message + dstr + '?';
        //Zarove
        let zstr = '1';
        if (!this.zarove) {
            zstr = '0';
        }
        message = message + zstr + '?';

        if (message !== '') {
            this.props.m_pwin.SendGame(message);
        }
        if (!this.CheckWinGame(2) && !this.CheckWinGame(1)) {
            this.props.m_pwin.SendGame('$|y');
        }
    }

    async ReceiveWachGameInitialization(init: string) {
        await this.OnLButtonDown(new CPoint(0, 0));
        if (this.tab3) {
            let vpos = init.indexOf('?');
            this.type = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
            this.rez1 = this.rez2 = -1;
            if (this.type === 2) {
                this.type = 0;
            } else if (this.type === 0) {
                this.type = 1;
            } else if (this.type === 1) {
                this.type = 2;
            }
            this.InitGame();
            vpos = init.indexOf('?');
            this.rez1 = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            this.rez2 = this._ttoi(init.substring(0, vpos));
            init = init.substring(vpos + 1, init.length);
        } else {
            this.InitNewGame();
        }
        let vpos = init.indexOf('?');
        this.Name[1] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        for (let i = 0; i < 30; i++) {
            this.poolpos[i].x = -50;
            this.poolpos[i].y = -50;
        }
        for (let i = 0; i < 30; i++) {
            vpos = init.indexOf('?');
            let s1 = init.substring(0, vpos);
            let si = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            let sc1 = init.substring(0, vpos);
            let c1 = this._ttoi(sc1);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
            let sc2 = init.substring(0, vpos);
            let c2 = this._ttoi(sc2);
            if (si === -1) {
                this.poolpos[i].x = this.poolplace[27][0][0].x;
                this.poolpos[i].y = this.poolplace[27][0][0].y;
            } else {
                this.poolpos[i].x = this.poolplace[si][c1][c2].x;
                this.poolpos[i].y = this.poolplace[si][c1][c2].y;
            }
            init = init.substring(vpos + 1, init.length);
        }
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        if (this.KoiENaRed === 2) {
            this.strelka = 2;
            this.setState({});
        }
        //	AfxMessageBox(s1);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.dice1 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.dice2 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice1 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice2 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice3 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.mdice4 = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.broihodove = this._ttoi(init.substring(0, vpos));
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let bint = this._ttoi(init.substring(0, vpos));
        if (bint === 0) {
            this.Began = false;
        } else {
            this.Began = true;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        let dint = this._ttoi(init.substring(0, vpos));
        if (dint === 0) {
            this.Doigravane = false;
        } else {
            this.Doigravane = true;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let zint = this._ttoi(init.substring(0, vpos));
        if (zint === 0) {
            this.zarove = false;
        } else {
            this.zarove = true;
        }
        if (this.KoiENaRed === 1) {
            if (this.zarove) {
                this.strelka = 1;
            } else {
                this.strelka = 3;
            }
        }
        init = init.substring(vpos + 1, init.length);
        this.op_start = true;
        for (let i = 0; i < 30; i++) {
            this.poolpoint[i].x = this.poolpos[i].x;
            this.poolpoint[i].y = this.poolpos[i].y;
        }
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.endgame = false;
        this.wait = false;
        this.setState({});
    }

    async SendWatchGameInitialization(watch_index: number) {
        await this.Timer3();
        let message = '$|d|' + this.IntToString(watch_index) + '|';
        if (this.tab3) {
            message = message + this.IntToString(this.type) + '?' + this.IntToString(this.rez1) + '?' + this.IntToString(this.rez2) + '?';
        }
        message = message + this.Name[1] + '?' + this.Name[2] + '?';
        for (let i = 0; i < 15; i++) {
            let rp = this.GetRectPool(i);
            let c1 = 0;
            let c2 = 0;
            let breakC1 = false;
            for (let rect = 0; rect < 28; rect++) {
                if (breakC1) {
                    break;
                }
                for (let q1 = 0; q1 < 8; q1++) {
                    if (breakC1) {
                        break;
                    }
                    for (let q2 = 0; q2 < 2; q2++) {
                        if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                            c1 = q1;
                            c2 = q2;
                            breakC1 = true;
                            break;
                        }
                    }
                }
            }
            message = message + this.IntToString(rp) + '?' + this.IntToString(c1) + '?' + this.IntToString(c2) + '?';
        }
        for (let i = 15; i < 30; i++) {
            let rp = this.GetRectPool(i);
            let c1 = 0;
            let c2 = 0;
            let breakC2 = false;
            for (let rect = 0; rect < 28; rect++) {
                if (breakC2) {
                    break;
                }
                for (let q1 = 0; q1 < 8; q1++) {
                    if (breakC2) {
                        break;
                    }
                    for (let q2 = 0; q2 < 2; q2++) {
                        if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                            c1 = q1;
                            c2 = q2;
                            breakC2 = true;
                            break;
                        }
                    }
                }
            }
            message = message + this.IntToString(rp) + '?' + this.IntToString(c1) + '?' + this.IntToString(c2) + '?';
        }

        //koienared
        //	let koi="1";
        //	if(this.KoiENaRed==1)koi="2";
        message = message + this.IntToString(this.KoiENaRed) + '?';
        //this.zarove
        let z1 = this.IntToString(this.dice1);
        let z2 = this.IntToString(this.dice2);
        message = message + z1 + '?' + z2 + '?';
        //izigrani this.zarove
        let zi1 = this.IntToString(this.mdice1);
        let zi2 = this.IntToString(this.mdice2);
        let zi3 = this.IntToString(this.mdice3);
        let zi4 = this.IntToString(this.mdice4);
        message = message + zi1 + '?' + zi2 + '?' + zi3 + '?' + zi4 + '?';
        //broi hodove
        let brh = this.IntToString(this.broihodove);
        message = message + brh + '?';
        //this.Began
        let bstr = '1';
        if (!this.Began) {
            bstr = '0';
        }
        message = message + bstr + '?';
        //this.Doigravane
        let dstr = '1';
        if (!this.Doigravane) {
            dstr = '0';
        }
        message = message + dstr + '?';
        //Zarove
        let zstr = '1';
        if (!this.zarove) {
            zstr = '0';
        }
        message = message + zstr + '?';
        if (message !== '') {
            this.props.m_pwin.SendGame(message);
        }
    }

    InitGame() {
        if (this.tab3) {
            if (this.type === 0) {
                this.type = 2;
            } else if (this.type === 1) {
                this.type = 0;
            } else if (this.type === 2) {
                this.type = 1;
            }
        }
        if (this.SP) {
            this.endgame = false;
        }
        this.Began = false; //
        this.Doigravane = false; //
        this.broihodove = 0; //tova az sum go pisal
        //this.broihodove = 6; //TODO Remove Test
        if (this.KoiZapochva === 1) {
            this.KoiZapochva = 2;
        } else {
            this.KoiZapochva = 1;
        }
        this.KoiENaRed = this.KoiZapochva;
        if ((this.OP && this.Server) || this.SP) {
            if (this.KoiENaRed === 2) {
                this.strelka = 2;
            } else {
                this.strelka = 3;
            }
        }
        this.newgame = false;
        this.zarove = false;
        this.movepool = -1;
        this.dice1 = 6;
        this.dice2 = 6;
        this.mdice1 = this.mdice2 = this.mdice3 = this.mdice4 = 0;
        if (this.type === 0) {
            this.poolpos[0].x = this.poolplace[0][0][0].x;
            this.poolpos[1].x = this.poolplace[0][1][0].x;
            this.poolpos[2].x = this.poolplace[11][0][0].x;
            this.poolpos[3].x = this.poolplace[11][1][0].x;
            this.poolpos[4].x = this.poolplace[11][2][0].x;
            this.poolpos[5].x = this.poolplace[11][3][0].x;
            this.poolpos[6].x = this.poolplace[11][4][0].x;
            this.poolpos[7].x = this.poolplace[16][0][0].x;
            this.poolpos[8].x = this.poolplace[16][1][0].x;
            this.poolpos[9].x = this.poolplace[16][2][0].x;
            this.poolpos[10].x = this.poolplace[18][0][0].x;
            this.poolpos[11].x = this.poolplace[18][1][0].x;
            this.poolpos[12].x = this.poolplace[18][2][0].x;
            this.poolpos[13].x = this.poolplace[18][3][0].x;
            this.poolpos[14].x = this.poolplace[18][4][0].x;

            this.poolpos[15].x = this.poolplace[23][1][0].x;
            this.poolpos[16].x = this.poolplace[23][0][0].x;
            this.poolpos[17].x = this.poolplace[12][4][0].x;
            this.poolpos[18].x = this.poolplace[12][3][0].x;
            this.poolpos[19].x = this.poolplace[12][2][0].x;
            this.poolpos[20].x = this.poolplace[12][1][0].x;
            this.poolpos[21].x = this.poolplace[12][0][0].x;
            this.poolpos[22].x = this.poolplace[7][0][0].x;
            this.poolpos[23].x = this.poolplace[7][1][0].x;
            this.poolpos[24].x = this.poolplace[7][2][0].x;
            this.poolpos[25].x = this.poolplace[5][0][0].x;
            this.poolpos[26].x = this.poolplace[5][1][0].x;
            this.poolpos[27].x = this.poolplace[5][2][0].x;
            this.poolpos[28].x = this.poolplace[5][3][0].x;
            this.poolpos[29].x = this.poolplace[5][4][0].x;

            this.poolpos[0].y = this.poolplace[0][0][0].y;
            this.poolpos[1].y = this.poolplace[0][1][0].y;
            this.poolpos[2].y = this.poolplace[11][0][0].y;
            this.poolpos[3].y = this.poolplace[11][1][0].y;
            this.poolpos[4].y = this.poolplace[11][2][0].y;
            this.poolpos[5].y = this.poolplace[11][3][0].y;
            this.poolpos[6].y = this.poolplace[11][4][0].y;
            this.poolpos[7].y = this.poolplace[16][0][0].y;
            this.poolpos[8].y = this.poolplace[16][1][0].y;
            this.poolpos[9].y = this.poolplace[16][2][0].y;
            this.poolpos[10].y = this.poolplace[18][0][0].y;
            this.poolpos[11].y = this.poolplace[18][1][0].y;
            this.poolpos[12].y = this.poolplace[18][2][0].y;
            this.poolpos[13].y = this.poolplace[18][3][0].y;
            this.poolpos[14].y = this.poolplace[18][4][0].y;

            this.poolpos[15].y = this.poolplace[23][1][0].y;
            this.poolpos[16].y = this.poolplace[23][0][0].y;
            this.poolpos[17].y = this.poolplace[12][4][0].y;
            this.poolpos[18].y = this.poolplace[12][3][0].y;
            this.poolpos[19].y = this.poolplace[12][2][0].y;
            this.poolpos[20].y = this.poolplace[12][1][0].y;
            this.poolpos[21].y = this.poolplace[12][0][0].y;
            this.poolpos[22].y = this.poolplace[7][0][0].y;
            this.poolpos[23].y = this.poolplace[7][1][0].y;
            this.poolpos[24].y = this.poolplace[7][2][0].y;
            this.poolpos[25].y = this.poolplace[5][0][0].y;
            this.poolpos[26].y = this.poolplace[5][1][0].y;
            this.poolpos[27].y = this.poolplace[5][2][0].y;
            this.poolpos[28].y = this.poolplace[5][3][0].y;
            this.poolpos[29].y = this.poolplace[5][4][0].y;
        }
        if (this.type === 1) {
            this.poolpos[0].x = this.poolplace[0][0][0].x;
            this.poolpos[1].x = this.poolplace[0][1][0].x;
            this.poolpos[2].x = this.poolplace[24][0][0].x;
            this.poolpos[3].x = this.poolplace[24][1][0].x;
            this.poolpos[4].x = this.poolplace[24][2][0].x;
            this.poolpos[5].x = this.poolplace[24][3][0].x;
            this.poolpos[6].x = this.poolplace[24][4][0].x;
            this.poolpos[7].x = this.poolplace[24][5][0].x;
            this.poolpos[8].x = this.poolplace[24][6][0].x;
            this.poolpos[9].x = this.poolplace[24][5][1].x;
            this.poolpos[10].x = this.poolplace[24][0][1].x;
            this.poolpos[11].x = this.poolplace[24][1][1].x;
            this.poolpos[12].x = this.poolplace[24][2][1].x;
            this.poolpos[13].x = this.poolplace[24][3][1].x;
            this.poolpos[14].x = this.poolplace[24][4][1].x;
            this.poolpos[15].x = this.poolplace[23][1][0].x;
            this.poolpos[16].x = this.poolplace[23][0][0].x;
            this.poolpos[17].x = this.poolplace[25][0][0].x;
            this.poolpos[18].x = this.poolplace[25][1][0].x;
            this.poolpos[19].x = this.poolplace[25][2][0].x;
            this.poolpos[20].x = this.poolplace[25][3][0].x;
            this.poolpos[21].x = this.poolplace[25][4][0].x;
            this.poolpos[22].x = this.poolplace[25][5][0].x;
            this.poolpos[23].x = this.poolplace[25][6][0].x;
            this.poolpos[24].x = this.poolplace[25][0][1].x;
            this.poolpos[25].x = this.poolplace[25][1][1].x;
            this.poolpos[26].x = this.poolplace[25][2][1].x;
            this.poolpos[27].x = this.poolplace[25][3][1].x;
            this.poolpos[28].x = this.poolplace[25][4][1].x;
            this.poolpos[29].x = this.poolplace[25][5][1].x;

            this.poolpos[0].y = this.poolplace[0][0][0].y;
            this.poolpos[1].y = this.poolplace[0][1][0].y;
            this.poolpos[2].y = this.poolplace[24][0][0].y;
            this.poolpos[3].y = this.poolplace[24][1][0].y;
            this.poolpos[4].y = this.poolplace[24][2][0].y;
            this.poolpos[5].y = this.poolplace[24][3][0].y;
            this.poolpos[6].y = this.poolplace[24][4][0].y;
            this.poolpos[7].y = this.poolplace[24][5][0].y;
            this.poolpos[8].y = this.poolplace[24][6][0].y;
            this.poolpos[9].y = this.poolplace[24][5][1].y;
            this.poolpos[10].y = this.poolplace[24][0][1].y;
            this.poolpos[11].y = this.poolplace[24][1][1].y;
            this.poolpos[12].y = this.poolplace[24][2][1].y;
            this.poolpos[13].y = this.poolplace[24][3][1].y;
            this.poolpos[14].y = this.poolplace[24][4][1].y;
            this.poolpos[15].y = this.poolplace[23][1][0].y;
            this.poolpos[16].y = this.poolplace[23][0][0].y;
            this.poolpos[17].y = this.poolplace[25][0][0].y;
            this.poolpos[18].y = this.poolplace[25][1][0].y;
            this.poolpos[19].y = this.poolplace[25][2][0].y;
            this.poolpos[20].y = this.poolplace[25][3][0].y;
            this.poolpos[21].y = this.poolplace[25][4][0].y;
            this.poolpos[22].y = this.poolplace[25][5][0].y;
            this.poolpos[23].y = this.poolplace[25][6][0].y;
            this.poolpos[24].y = this.poolplace[25][0][1].y;
            this.poolpos[25].y = this.poolplace[25][1][1].y;
            this.poolpos[26].y = this.poolplace[25][2][1].y;
            this.poolpos[27].y = this.poolplace[25][3][1].y;
            this.poolpos[28].y = this.poolplace[25][4][1].y;
            this.poolpos[29].y = this.poolplace[25][5][1].y;
        }
        if (this.type === 2) {
            this.poolpos[0].x = this.poolplace[0][0][0].x;
            this.poolpos[1].x = this.poolplace[0][1][0].x;
            this.poolpos[2].x = this.poolplace[24][0][0].x;
            this.poolpos[3].x = this.poolplace[24][1][0].x;
            this.poolpos[4].x = this.poolplace[24][2][0].x;
            this.poolpos[5].x = this.poolplace[24][3][0].x;
            this.poolpos[6].x = this.poolplace[24][4][0].x;
            this.poolpos[7].x = this.poolplace[24][5][0].x;
            this.poolpos[8].x = this.poolplace[24][6][0].x;
            this.poolpos[9].x = this.poolplace[24][7][0].x;
            this.poolpos[10].x = this.poolplace[24][0][1].x;
            this.poolpos[11].x = this.poolplace[24][1][1].x;
            this.poolpos[12].x = this.poolplace[24][2][1].x;
            this.poolpos[13].x = this.poolplace[24][3][1].x;
            this.poolpos[14].x = this.poolplace[24][4][1].x;
            this.poolpos[15].x = this.poolplace[12][0][0].x;
            this.poolpos[16].x = this.poolplace[12][1][0].x;
            this.poolpos[17].x = this.poolplace[25][0][0].x;
            this.poolpos[18].x = this.poolplace[25][1][0].x;
            this.poolpos[19].x = this.poolplace[25][2][0].x;
            this.poolpos[20].x = this.poolplace[25][3][0].x;
            this.poolpos[21].x = this.poolplace[25][4][0].x;
            this.poolpos[22].x = this.poolplace[25][5][0].x;
            this.poolpos[23].x = this.poolplace[25][6][0].x;
            this.poolpos[24].x = this.poolplace[25][7][0].x;
            this.poolpos[25].x = this.poolplace[25][0][1].x;
            this.poolpos[26].x = this.poolplace[25][1][1].x;
            this.poolpos[27].x = this.poolplace[25][2][1].x;
            this.poolpos[28].x = this.poolplace[25][3][1].x;
            this.poolpos[29].x = this.poolplace[25][4][1].x;

            this.poolpos[0].y = this.poolplace[0][0][0].y;
            this.poolpos[1].y = this.poolplace[0][1][0].y;
            this.poolpos[2].y = this.poolplace[24][0][0].y;
            this.poolpos[3].y = this.poolplace[24][1][0].y;
            this.poolpos[4].y = this.poolplace[24][2][0].y;
            this.poolpos[5].y = this.poolplace[24][3][0].y;
            this.poolpos[6].y = this.poolplace[24][4][0].y;
            this.poolpos[7].y = this.poolplace[24][5][0].y;
            this.poolpos[8].y = this.poolplace[24][6][0].y;
            this.poolpos[9].y = this.poolplace[24][7][0].y;
            this.poolpos[10].y = this.poolplace[24][0][1].y;
            this.poolpos[11].y = this.poolplace[24][1][1].y;
            this.poolpos[12].y = this.poolplace[24][2][1].y;
            this.poolpos[13].y = this.poolplace[24][3][1].y;
            this.poolpos[14].y = this.poolplace[24][4][1].y;
            this.poolpos[15].y = this.poolplace[12][0][0].y;
            this.poolpos[16].y = this.poolplace[12][1][0].y;
            this.poolpos[17].y = this.poolplace[25][0][0].y;
            this.poolpos[18].y = this.poolplace[25][1][0].y;
            this.poolpos[19].y = this.poolplace[25][2][0].y;
            this.poolpos[20].y = this.poolplace[25][3][0].y;
            this.poolpos[21].y = this.poolplace[25][4][0].y;
            this.poolpos[22].y = this.poolplace[25][5][0].y;
            this.poolpos[23].y = this.poolplace[25][6][0].y;
            this.poolpos[24].y = this.poolplace[25][7][0].y;
            this.poolpos[25].y = this.poolplace[25][0][1].y;
            this.poolpos[26].y = this.poolplace[25][1][1].y;
            this.poolpos[27].y = this.poolplace[25][2][1].y;
            this.poolpos[28].y = this.poolplace[25][3][1].y;
            this.poolpos[29].y = this.poolplace[25][4][1].y;
        }
        for (let i = 0; i < 30; i++) {
            this.poolpoint[i].x = this.poolpos[i].x;
            this.poolpoint[i].y = this.poolpos[i].y;
        }
        if (this.OP) {
            if (!this.watch) {
                this.props.m_pwin.SendGame('$|y');
            }
            this.op_start = false;
        }
        for (let i = 1; i <= 2; i++) {
            if (this.last_Name[i] === '') {
                this.last_Name[i] = this.Name[i];
            }
        }
    }

    DrawPipcount() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        let res = [];
        for (let i = 1; i <= this.Players; i++) {
            if (this.Name[i] !== '') {
                res.push(
                    this.DrawBorderText(
                        this.GetLanguageText('Pipcount') + ': ' + this.IntToString(this.GetPipCount(i)),
                        this.pip_pos[i].x,
                        this.pip_pos[i].y,
                        'DrawPipcount' + i
                    )
                );
            }
        }
        return res;
    }

    DrawPools() {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        let res = [];
        for (let q1 = 0; q1 < 12; q1++) {
            for (let q2 = 0; q2 < 8; q2++) {
                for (let q3 = 0; q3 < 2; q3++) {
                    for (let i = 15; i < 30; i++) {
                        if (this.poolpoint[i].x === this.poolplace[q1][q2][q3].x && this.poolpoint[i].y === this.poolplace[q1][q2][q3].y) {
                            res.push(
                                <CPicture
                                    image={this.wpool}
                                    m_x={this.poolpoint[i].x - 16}
                                    m_y={this.poolpoint[i].y - 14}
                                    m_Width={30}
                                    m_Height={30}
                                    key={'wpool1' + i + '_' + q3 + '-' + q2 + '_' + q1}
                                    cursor={this.cursor}
                                />
                            );
                        }
                    }
                    for (let i = 0; i < 15; i++) {
                        if (i !== this.movepool) {
                            if (this.poolpoint[i].x === this.poolplace[q1][q2][q3].x && this.poolpoint[i].y === this.poolplace[q1][q2][q3].y) {
                                res.push(
                                    <CPicture
                                        image={this.bpool}
                                        m_x={this.poolpoint[i].x - 16}
                                        m_y={this.poolpoint[i].y - 14}
                                        m_Width={30}
                                        m_Height={30}
                                        key={'bpool1' + i + '_' + q3 + '-' + q2 + '_' + q1}
                                        cursor={this.cursor}
                                    />
                                );
                            }
                        }
                    }
                }
            }
        }
        for (let q1 = 12; q1 < 24; q1++) {
            for (let q2 = 7; q2 >= 0; q2--) {
                for (let q3 = 0; q3 < 2; q3++) {
                    for (let i = 15; i < 30; i++) {
                        if (this.poolpoint[i].x === this.poolplace[q1][q2][q3].x && this.poolpoint[i].y === this.poolplace[q1][q2][q3].y) {
                            res.push(
                                <CPicture
                                    image={this.wpool}
                                    m_x={this.poolpoint[i].x - 16}
                                    m_y={this.poolpoint[i].y - 14}
                                    m_Width={30}
                                    m_Height={30}
                                    key={'wpool2' + i + '_' + q3 + '-' + q2 + '_' + q1}
                                    cursor={this.cursor}
                                />
                            );
                        }
                    }
                    for (let i = 0; i < 15; i++) {
                        if (i !== this.movepool) {
                            if (this.poolpoint[i].x === this.poolplace[q1][q2][q3].x && this.poolpoint[i].y === this.poolplace[q1][q2][q3].y) {
                                res.push(
                                    <CPicture
                                        image={this.bpool}
                                        m_x={this.poolpoint[i].x - 16}
                                        m_y={this.poolpoint[i].y - 14}
                                        m_Width={30}
                                        m_Height={30}
                                        key={'bpool2' + i + '_' + q3 + '-' + q2 + '_' + q1}
                                        cursor={this.cursor}
                                    />
                                );
                            }
                        }
                    }
                }
            }
        }
        for (let q2 = 7; q2 >= 0; q2--) {
            for (let q3 = 0; q3 < 2; q3++) {
                for (let i = 15; i < 30; i++) {
                    if (this.poolpoint[i].x === this.poolplace[25][q2][q3].x && this.poolpoint[i].y === this.poolplace[25][q2][q3].y) {
                        res.push(
                            <CPicture
                                image={this.wpool}
                                m_x={this.poolpoint[i].x - 16}
                                m_y={this.poolpoint[i].y - 14}
                                m_Width={30}
                                m_Height={30}
                                key={'wpool3' + i + '_' + q3 + '-' + q2}
                                cursor={this.cursor}
                            />
                        );
                    }
                }
            }
        }
        for (let q2 = 0; q2 < 8; q2++) {
            for (let q3 = 0; q3 < 2; q3++) {
                for (let i = 0; i < 15; i++) {
                    if (i !== this.movepool) {
                        if (this.poolpoint[i].x === this.poolplace[24][q2][q3].x && this.poolpoint[i].y === this.poolplace[24][q2][q3].y) {
                            res.push(
                                <CPicture
                                    image={this.bpool}
                                    m_x={this.poolpoint[i].x - 16}
                                    m_y={this.poolpoint[i].y - 14}
                                    m_Width={30}
                                    m_Height={30}
                                    key={'bpool3' + i + '_' + q3 + '-' + q2}
                                    cursor={this.cursor}
                                />
                            );
                        }
                    }
                }
            }
        }
        if (this.movepool >= 0) {
            res.push(
                <CPicture
                    image={this.bpool}
                    m_x={this.poolpoint[this.movepool].x - 16}
                    m_y={this.poolpoint[this.movepool].y - 14}
                    m_Width={30}
                    m_Height={30}
                    key={'movepool'}
                    cursor={this.cursor}
                />
            );
        }
        return res;
    }

    InvalidateNewGameButton() {
        this.setState({});
    }

    SendKoiENaRed() {
        let koi: string = '2';
        if (this.KoiZapochva > 1) {
            koi = '1';
        }
        this.props.m_pwin.SendGame('$|b|' + koi);
    }

    GetArrowTableRect() {
        let rect = 0;
        for (let i = 0; i < this.Marked1Br; i++) {
            let x = 169;
            let y = 37;
            if (this.Marked1[i] < 0 || this.Marked1[i] > 23) {
                this.Marked1[i] = 25;
            }
            if (this.Marked1[i] < 12) {
                x = x + this.Marked1[i] * 39;
                if (this.Marked1[i] > 5) {
                    x = x + 28;
                } else {
                    x = x - 1;
                }
            } else {
                y = 472;
                x = x + 460;
                x = x - (this.Marked1[i] - 12) * 39;
                if (this.Marked1[i] > 17) {
                    x = x - 28;
                }
            }
            if (i >= 0 && i < 2) {
                let point: CPoint = new CPoint(x, y);
                for (let a = 0; a < 26; a++) {
                    if (this.tablerect[a].PtInRect(point)) {
                        rect = a;
                    }
                }
            }
        }
        return rect;
    }

    async Timer7() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.OP && !this.watch && !this.endgame) {
            if (this.P2OP || !this.Server) {
                if (this.online_timer > 0) {
                    this.online_timer = this.props.m_pwin.props.isAppActive
                        ? this.online_timer - 1
                        : this.online_timer - Math.floor(this.online_timer_time / 3);
                }
                if (this.pause_player !== '') {
                    this.setState({});
                }
                if (!this.wait && !this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                    if (this.online_timer === 5) {
                        if (this.KoiENaRed === 1 && !this.wait && !this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                            }
                        }
                    }
                    this.setState({});
                    if (this.KoiENaRed === 1) {
                        if (this.online_timer <= 0) {
                            this.sit_out_count = this.sit_out_count + 1;
                            if (this.sit_out_count >= this.sit_out_drop) {
                                this.props.m_pwin.AutoSitOut();
                                return;
                            } else {
                                if (!this.zarove) {
                                    this.ThrowDice(-1);
                                    this.strelka = 1;
                                    this.setState({});
                                    return;
                                }
                                if (this.zarove) {
                                    if (this.movepool > -1) {
                                        await this.OnLButtonUp(this.poolpoint[this.movepool]);
                                        return;
                                    }
                                    for (let i = 0; i < 15; i++) {
                                        if (this.poolpoint[i].x >= 0) {
                                            for (let a = 0; a < 25; a++) {
                                                if (
                                                    await this.AddToTableRect(
                                                        i,
                                                        new CPoint(this.tablerect[a].BottomRight().x - 10, this.tablerect[a].BottomRight().y - 10)
                                                    )
                                                ) {
                                                    if (this.movepool > -1) {
                                                        this.movepool = -1;
                                                        this.setState({});
                                                    }
                                                    this.online_timer = this.online_timer_time;
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            this.online_timer = this.online_timer_time;
                        }
                    }
                }
                return;
            }
        }
    }

    GetMovePoolTableRectIndex(pool: number, pp: CPoint) {
        let player;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }
        let move_pool_index = 100;
        for (let i = 0; i < 24; i++) {
            if (this.tablerect[i].PtInRect(pp) && this.CheckFreeRect(i, player)) {
                move_pool_index = i;
            }
        }
        if (player === 1) {
            if (this.tablerect[24].PtInRect(pp)) {
                move_pool_index = 24;
            }
        }
        if (player === 2) {
            if (this.tablerect[25].PtInRect(pp)) {
                move_pool_index = -1;
            }
        }
        return move_pool_index;
    }

    ReloadDice() {
        this.setState({});
    }

    CheckMovePool2(pool: number, pp: CPoint) {
        if (pool >= 0 && pool < 15) {
            this.FindMarked(pool);
        }
        //	if(!this.CheckMovePool(pool))return false;
        let table_rect_index: number = this.GetPoolTableRectIndex(pool);
        let move_pool_index: number = this.GetMovePoolTableRectIndex(pool, pp);
        let player: number;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }
        let ready_to_exit: boolean = this.CheckReadyToExit(player);
        if (this.poolpoint[pool].x < 0) {
            return false;
        }
        if (this.type === 0) {
            this.FindAllPosMovesObP1(); //
            if (player === 1) {
                //////////////////////////////////////////////////
                let Pos2Moves = false;
                if (this.mdice1 !== this.mdice2 && this.mdice1 !== 0 && this.mdice2 !== 0 && this.AllPosMovesP12br > 0) {
                    for (let k = 0; k < this.AllPosMovesP12br; k++) {
                        let u1 = this.AllPosMovesP12[k][0][1];
                        if (u1 === 25) {
                            u1--;
                        }
                        if (this.GetRectPool(pool) === this.AllPosMovesP12[k][0][0] && this.tablerect[u1].PtInRect(pp)) {
                            Pos2Moves = true;
                            break;
                        }
                    }
                } else {
                    Pos2Moves = true;
                }
                if (!Pos2Moves) {
                    return false;
                }
                //////////////////////////////////////////////////////
                let gmp = this.GetMinRectPool(1);
                if (this.HaveKiledPool(player) && table_rect_index !== -1) {
                    return false;
                }
                if (
                    this.mdice1 !== 0 &&
                    ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                        ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) &&
                            ready_to_exit &&
                            this.tablerect[24].PtInRect(pp)))
                ) {
                    this.mdice1 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice2 !== 0 &&
                    ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                        ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) &&
                            ready_to_exit &&
                            this.tablerect[24].PtInRect(pp)))
                ) {
                    this.mdice2 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice3 !== 0 &&
                    ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                        ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) &&
                            ready_to_exit &&
                            this.tablerect[24].PtInRect(pp)))
                ) {
                    this.mdice3 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice4 !== 0 &&
                    ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                        ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) &&
                            ready_to_exit &&
                            this.tablerect[24].PtInRect(pp)))
                ) {
                    this.mdice4 = 0;
                    this.ReloadDice();
                    return true;
                }
            } else {
                if (this.HaveKiledPool(player) && table_rect_index !== 24) {
                    return false;
                }
                if (
                    this.mdice1 !== 0 &&
                    ((table_rect_index - move_pool_index === this.mdice1 && move_pool_index !== -1) ||
                        (ready_to_exit &&
                            table_rect_index - move_pool_index <= this.mdice1 &&
                            move_pool_index !== table_rect_index &&
                            this.tablerect[25].PtInRect(pp)))
                ) {
                    this.mdice1 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice2 !== 0 &&
                    ((table_rect_index - move_pool_index === this.mdice2 && move_pool_index !== -1) ||
                        (ready_to_exit &&
                            table_rect_index - move_pool_index <= this.mdice2 &&
                            move_pool_index !== table_rect_index &&
                            this.tablerect[25].PtInRect(pp)))
                ) {
                    this.mdice2 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice3 !== 0 &&
                    ((table_rect_index - move_pool_index === this.mdice3 && move_pool_index !== -1) ||
                        (ready_to_exit &&
                            table_rect_index - move_pool_index <= this.mdice3 &&
                            move_pool_index !== table_rect_index &&
                            this.tablerect[25].PtInRect(pp)))
                ) {
                    this.mdice3 = 0;
                    this.ReloadDice();
                    return true;
                }
                if (
                    this.mdice4 !== 0 &&
                    ((table_rect_index - move_pool_index === this.mdice4 && move_pool_index !== -1) ||
                        (ready_to_exit &&
                            table_rect_index - move_pool_index <= this.mdice4 &&
                            move_pool_index !== table_rect_index &&
                            this.tablerect[25].PtInRect(pp)))
                ) {
                    this.mdice4 = 0;
                    this.ReloadDice();
                    return true;
                }
            }
        }
        if (this.type === 1) {
            if (!this.IsTapedPool(pool)) {
                let index = this.GetPoolRealTableRectIndex(pool);
                if (player === 1) {
                    //////////////////////////////////////////////////
                    this.FindAllPosMovesTapP1();
                    let Pos2Moves = false;
                    if (this.mdice1 !== this.mdice2 && this.mdice1 !== 0 && this.mdice2 !== 0 && this.AllPosMovesP12br > 0) {
                        for (let k = 0; k < this.AllPosMovesP12br; k++) {
                            let u1 = this.AllPosMovesP12[k][0][1];
                            if (u1 === 25) {
                                u1--;
                            }
                            if (this.GetRectPool(pool) === this.AllPosMovesP12[k][0][0] && this.tablerect[u1].PtInRect(pp)) {
                                Pos2Moves = true;
                                break;
                            }
                        }
                    } else {
                        Pos2Moves = true;
                    }
                    if (!Pos2Moves) {
                        return false;
                    }
                    //////////////////////////////////////////////////////
                    let gmp = this.GetMinRectPool(1);
                    if (this.HaveKiledPool(player) && index === 0) {
                        return false;
                    }
                    if (
                        this.mdice1 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) &&
                                ready_to_exit &&
                                this.tablerect[24].PtInRect(pp)))
                    ) {
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice2 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) &&
                                ready_to_exit &&
                                this.tablerect[24].PtInRect(pp)))
                    ) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice3 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) &&
                                ready_to_exit &&
                                this.tablerect[24].PtInRect(pp)))
                    ) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice4 !== 0 &&
                        ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                            ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) &&
                                ready_to_exit &&
                                this.tablerect[24].PtInRect(pp)))
                    ) {
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                } else {
                    if (this.HaveKiledPool(player) && index === 23) {
                        return false;
                    }
                    if (
                        this.mdice1 !== 0 &&
                        ((table_rect_index - move_pool_index === this.mdice1 && move_pool_index !== -1) ||
                            (ready_to_exit &&
                                table_rect_index - move_pool_index <= this.mdice1 &&
                                move_pool_index !== table_rect_index &&
                                this.tablerect[25].PtInRect(pp)))
                    ) {
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice2 !== 0 &&
                        ((table_rect_index - move_pool_index === this.mdice2 && move_pool_index !== -1) ||
                            (ready_to_exit &&
                                table_rect_index - move_pool_index <= this.mdice2 &&
                                move_pool_index !== table_rect_index &&
                                this.tablerect[25].PtInRect(pp)))
                    ) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice3 !== 0 &&
                        ((table_rect_index - move_pool_index === this.mdice3 && move_pool_index !== -1) ||
                            (ready_to_exit &&
                                table_rect_index - move_pool_index <= this.mdice3 &&
                                move_pool_index !== table_rect_index &&
                                this.tablerect[25].PtInRect(pp)))
                    ) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        this.mdice4 !== 0 &&
                        ((table_rect_index - move_pool_index === this.mdice4 && move_pool_index !== -1) ||
                            (ready_to_exit &&
                                table_rect_index - move_pool_index <= this.mdice4 &&
                                move_pool_index !== table_rect_index &&
                                this.tablerect[25].PtInRect(pp)))
                    ) {
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                }
            }
        }
        if (this.type === 2) {
            //Za Teodor++
            this.full();
            let rexit = true;
            let pool11 = this.GetRectPool(pool);
            //////////////////////////////////////player1/////////////////////////////////
            if (player === 1) {
                //////////////////////////////////////////////////
                this.FindAllPosMovesGulP1();
                let Pos2Moves = false;
                if (this.mdice1 !== this.mdice2 && this.mdice1 !== 0 && this.mdice2 !== 0 && this.AllPosMovesP12br > 0) {
                    for (let k = 0; k < this.AllPosMovesP12br; k++) {
                        let u1 = this.AllPosMovesP12[k][0][1];
                        if (u1 === 25) {
                            u1--;
                        }
                        if (pool11 === this.AllPosMovesP12[k][0][0] && this.tablerect[u1].PtInRect(pp)) {
                            Pos2Moves = true;
                            break;
                        }
                    }
                } else {
                    Pos2Moves = true;
                }
                if (!Pos2Moves) {
                    return false;
                }
                //////////////////////////////////////////////////////
                rexit = this.CheckReadyToExit(player);
                if (this.mdice1 !== 0) {
                    if (pool11 === 24 && this.table[pool11 + this.mdice1 - 24][1] === 0 && this.tablerect[pool11 + this.mdice1 - 24].PtInRect(pp)) {
                        this.Began = true;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 0 &&
                        this.table[pool11 + this.mdice1][1] === 0 &&
                        this.tablerect[pool11 + this.mdice1].PtInRect(pp) &&
                        this.table[24][0] === 0
                    ) {
                        this.Began = true;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 + this.mdice1 < 24 &&
                        pool11 !== 0 &&
                        pool11 !== 24 &&
                        this.table[pool11 + this.mdice1][1] === 0 &&
                        this.tablerect[pool11 + this.mdice1].PtInRect(pp)
                    ) {
                        this.Began = true;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (rexit) {
                        if (pool11 + this.mdice1 === 24 && this.tablerect[24].PtInRect(pp)) {
                            this.Began = true;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice1 > 24 && this.tablerect[24].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 17; i--) {
                                if (this.table[i][0] > 0) {
                                    return false;
                                }
                            }
                            this.Began = true;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice2 !== 0) {
                    if (pool11 === 24 && this.table[pool11 + this.mdice2 - 24][1] === 0 && this.tablerect[pool11 + this.mdice2 - 24].PtInRect(pp)) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 0 &&
                        this.table[pool11 + this.mdice2][1] === 0 &&
                        this.tablerect[pool11 + this.mdice2].PtInRect(pp) &&
                        this.table[24][0] === 0
                    ) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 + this.mdice2 < 24 &&
                        pool11 !== 0 &&
                        pool11 !== 24 &&
                        this.table[pool11 + this.mdice2][1] === 0 &&
                        this.tablerect[pool11 + this.mdice2].PtInRect(pp)
                    ) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (rexit) {
                        if (pool11 + this.mdice2 === 24 && this.tablerect[24].PtInRect(pp)) {
                            this.mdice2 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice2 > 24 && this.tablerect[24].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 17; i--) {
                                if (this.table[i][0] > 0) {
                                    return false;
                                }
                            }
                            this.mdice2 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice3 !== 0) {
                    if (pool11 === 24 && this.table[pool11 + this.mdice3 - 24][1] === 0 && this.tablerect[pool11 + this.mdice3 - 24].PtInRect(pp)) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 0 &&
                        this.table[pool11 + this.mdice3][1] === 0 &&
                        this.tablerect[pool11 + this.mdice3].PtInRect(pp) &&
                        this.table[24][0] === 0
                    ) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 + this.mdice3 < 24 &&
                        pool11 !== 0 &&
                        pool11 !== 24 &&
                        this.table[pool11 + this.mdice3][1] === 0 &&
                        this.tablerect[pool11 + this.mdice3].PtInRect(pp)
                    ) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (rexit) {
                        if (pool11 + this.mdice3 === 24 && this.tablerect[24].PtInRect(pp)) {
                            this.mdice3 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice3 > 24 && this.tablerect[24].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 17; i--) {
                                if (this.table[i][0] > 0) {
                                    return false;
                                }
                            }
                            this.mdice3 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice4 !== 0 && this.mdice3 === 0 && this.mdice2 === 0 && this.mdice1 === 0) {
                    if (pool11 === 24 && this.table[pool11 + this.mdice4 - 24][1] === 0 && this.tablerect[pool11 + this.mdice4 - 24].PtInRect(pp)) {
                        if (this.mdice4 < 6 && this.broihodove > 5) {
                            this.mdice4++;
                            this.mdice1 = this.mdice4;
                            this.mdice2 = this.mdice4;
                            this.mdice3 = this.mdice4;
                            this.dice1 = this.mdice4;
                            this.dice2 = this.mdice4;
                            this.ReloadDice();
                            return true;
                        }
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 0 &&
                        this.table[pool11 + this.mdice4][1] === 0 &&
                        this.tablerect[pool11 + this.mdice4].PtInRect(pp) &&
                        this.table[24][0] === 0
                    ) {
                        if (this.mdice4 < 6 && this.broihodove > 5) {
                            this.mdice4++;
                            this.mdice1 = this.mdice4;
                            this.mdice2 = this.mdice4;
                            this.mdice3 = this.mdice4;
                            this.dice1 = this.mdice4;
                            this.dice2 = this.mdice4;
                            this.ReloadDice();
                            return true;
                        }
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 + this.mdice4 < 24 &&
                        pool11 !== 0 &&
                        pool11 !== 24 &&
                        this.table[pool11 + this.mdice4][1] === 0 &&
                        this.tablerect[pool11 + this.mdice4].PtInRect(pp)
                    ) {
                        if (this.mdice4 < 6 && this.broihodove > 5) {
                            this.mdice4++;
                            this.mdice1 = this.mdice4;
                            this.mdice2 = this.mdice4;
                            this.mdice3 = this.mdice4;
                            this.dice1 = this.mdice4;
                            this.dice2 = this.mdice4;
                            this.ReloadDice();
                            return true;
                        }
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (rexit) {
                        if (pool11 + this.mdice4 === 24 && this.tablerect[24].PtInRect(pp)) {
                            if (this.mdice4 < 6 && this.broihodove > 5) {
                                this.mdice4++;
                                this.mdice1 = this.mdice4;
                                this.mdice2 = this.mdice4;
                                this.mdice3 = this.mdice4;
                                this.dice1 = this.mdice4;
                                this.dice2 = this.mdice4;
                                this.ReloadDice();
                                return true;
                            }
                            this.mdice4 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice4 > 24 && this.tablerect[24].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 17; i--) {
                                if (this.table[i][0] > 0) {
                                    return false;
                                }
                            }
                            if (this.mdice4 < 6 && this.broihodove > 5) {
                                this.mdice4++;
                                this.mdice1 = this.mdice4;
                                this.mdice2 = this.mdice4;
                                this.mdice3 = this.mdice4;
                                this.dice1 = this.mdice4;
                                this.dice2 = this.mdice4;
                                this.ReloadDice();
                                return true;
                            }
                            this.mdice4 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                /////////////////////////////////////ako e 4ift///////////////////////////////////////
                else {
                }
            }
            //////////////////////////////////player2////////////////////////////////////
            else {
                rexit = this.CheckReadyToExit(player);
                if (this.mdice1 !== 0) {
                    if (
                        pool11 === 25 &&
                        pool11 + this.mdice1 - 13 >= 0 &&
                        this.table[pool11 + this.mdice1 - 13][0] === 0 &&
                        this.tablerect[pool11 + this.mdice1 - 13].PtInRect(pp)
                    ) {
                        this.Began = true;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 12 &&
                        this.table[pool11 + this.mdice1][0] === 0 &&
                        this.tablerect[pool11 + this.mdice1].PtInRect(pp) &&
                        this.table[25][1] === 0
                    ) {
                        this.Began = true;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (pool11 !== 25 && pool11 !== 12) {
                        if (pool11 > 11 && pool11 < 24) {
                            if (
                                pool11 + this.mdice1 < 24 &&
                                this.table[pool11 + this.mdice1][0] === 0 &&
                                this.tablerect[pool11 + this.mdice1].PtInRect(pp)
                            ) {
                                this.Began = true;
                                this.mdice1 = 0;
                                this.ReloadDice();
                                return true;
                            }
                            if (
                                pool11 + this.mdice1 >= 24 &&
                                this.table[pool11 + this.mdice1 - 24][0] === 0 &&
                                this.tablerect[pool11 + this.mdice1 - 24].PtInRect(pp)
                            ) {
                                this.Began = true;
                                this.mdice1 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                        if (pool11 >= 0 && pool11 < 12) {
                            if (
                                pool11 + this.mdice1 < 12 &&
                                this.table[pool11 + this.mdice1][0] === 0 &&
                                this.tablerect[pool11 + this.mdice1].PtInRect(pp)
                            ) {
                                this.Began = true;
                                this.mdice1 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                    }
                    if (rexit) {
                        if (pool11 + this.mdice1 === 12 && this.tablerect[25].PtInRect(pp)) {
                            this.Began = true;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice1 > 12 && this.tablerect[25].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 5; i--) {
                                if (this.table[i][1] > 0) {
                                    return false;
                                }
                            }
                            this.Began = true;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice2 !== 0) {
                    if (pool11 === 25 && this.table[pool11 + this.mdice2 - 13][0] === 0 && this.tablerect[pool11 + this.mdice2 - 13].PtInRect(pp)) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 12 &&
                        this.table[pool11 + this.mdice2][0] === 0 &&
                        this.tablerect[pool11 + this.mdice2].PtInRect(pp) &&
                        this.table[25][1] === 0
                    ) {
                        this.mdice2 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (pool11 !== 25 && pool11 !== 12) {
                        if (pool11 > 11 && pool11 < 24) {
                            if (
                                pool11 + this.mdice2 < 24 &&
                                this.table[pool11 + this.mdice2][0] === 0 &&
                                this.tablerect[pool11 + this.mdice2].PtInRect(pp)
                            ) {
                                this.mdice2 = 0;
                                this.ReloadDice();
                                return true;
                            }
                            if (
                                pool11 + this.mdice2 >= 24 &&
                                this.table[pool11 + this.mdice2 - 24][0] === 0 &&
                                this.tablerect[pool11 + this.mdice2 - 24].PtInRect(pp)
                            ) {
                                this.mdice2 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                        if (pool11 >= 0 && pool11 < 12) {
                            if (
                                pool11 + this.mdice2 < 12 &&
                                this.table[pool11 + this.mdice2][0] === 0 &&
                                this.tablerect[pool11 + this.mdice2].PtInRect(pp)
                            ) {
                                this.mdice2 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                    }
                    if (rexit) {
                        if (pool11 + this.mdice2 === 12 && this.tablerect[25].PtInRect(pp)) {
                            this.mdice2 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice2 > 12 && this.tablerect[25].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 5; i--) {
                                if (this.table[i][1] > 0) {
                                    return false;
                                }
                            }
                            this.mdice2 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice3 !== 0) {
                    if (pool11 === 25 && this.table[pool11 + this.mdice3 - 13][0] === 0 && this.tablerect[pool11 + this.mdice3 - 13].PtInRect(pp)) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 12 &&
                        this.table[pool11 + this.mdice3][0] === 0 &&
                        this.tablerect[pool11 + this.mdice3].PtInRect(pp) &&
                        this.table[25][1] === 0
                    ) {
                        this.mdice3 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (pool11 !== 25 && pool11 !== 12) {
                        if (pool11 > 11 && pool11 < 24) {
                            if (
                                pool11 + this.mdice3 < 24 &&
                                this.table[pool11 + this.mdice3][0] === 0 &&
                                this.tablerect[pool11 + this.mdice3].PtInRect(pp)
                            ) {
                                this.mdice3 = 0;
                                this.ReloadDice();
                                return true;
                            }
                            if (
                                pool11 + this.mdice3 >= 24 &&
                                this.table[pool11 + this.mdice3 - 24][0] === 0 &&
                                this.tablerect[pool11 + this.mdice3 - 24].PtInRect(pp)
                            ) {
                                this.mdice3 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                        if (pool11 >= 0 && pool11 < 12) {
                            if (
                                pool11 + this.mdice3 < 12 &&
                                this.table[pool11 + this.mdice3][0] === 0 &&
                                this.tablerect[pool11 + this.mdice3].PtInRect(pp)
                            ) {
                                this.mdice3 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                    }
                    if (rexit) {
                        if (pool11 + this.mdice3 === 12 && this.tablerect[25].PtInRect(pp)) {
                            this.mdice3 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice3 > 12 && this.tablerect[25].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 5; i--) {
                                if (this.table[i][1] > 0) {
                                    return false;
                                }
                            }
                            this.mdice3 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                if (this.mdice4 !== 0 && this.mdice3 === 0 && this.mdice2 === 0 && this.mdice1 === 0) {
                    if (pool11 === 25 && this.table[pool11 + this.mdice4 - 13][0] === 0 && this.tablerect[pool11 + this.mdice4 - 13].PtInRect(pp)) {
                        if (this.mdice4 < 6 && this.broihodove > 5) {
                            this.mdice4++;
                            this.mdice1 = this.mdice4;
                            this.mdice2 = this.mdice4;
                            this.mdice3 = this.mdice4;
                            this.dice1 = this.mdice4;
                            this.dice2 = this.mdice4;
                            this.ReloadDice();
                            return true;
                        }
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (
                        pool11 === 12 &&
                        this.table[pool11 + this.mdice4][0] === 0 &&
                        this.tablerect[pool11 + this.mdice4].PtInRect(pp) &&
                        this.table[25][1] === 0
                    ) {
                        if (this.mdice4 < 6 && this.broihodove > 5) {
                            this.mdice4++;
                            this.mdice1 = this.mdice4;
                            this.mdice2 = this.mdice4;
                            this.mdice3 = this.mdice4;
                            this.dice1 = this.mdice4;
                            this.dice2 = this.mdice4;
                            this.ReloadDice();
                            return true;
                        }
                        this.mdice4 = 0;
                        this.ReloadDice();
                        return true;
                    }
                    if (pool11 !== 25 && pool11 !== 12) {
                        if (pool11 > 11 && pool11 < 24) {
                            if (
                                pool11 + this.mdice4 < 24 &&
                                this.table[pool11 + this.mdice4][0] === 0 &&
                                this.tablerect[pool11 + this.mdice4].PtInRect(pp)
                            ) {
                                if (this.mdice4 < 6 && this.broihodove > 5) {
                                    this.mdice4++;
                                    this.mdice1 = this.mdice4;
                                    this.mdice2 = this.mdice4;
                                    this.mdice3 = this.mdice4;
                                    this.dice1 = this.mdice4;
                                    this.dice2 = this.mdice4;
                                    this.ReloadDice();
                                    return true;
                                }
                                this.mdice4 = 0;
                                this.ReloadDice();
                                return true;
                            }
                            if (
                                pool11 + this.mdice4 >= 24 &&
                                this.table[pool11 + this.mdice4 - 24][0] === 0 &&
                                this.tablerect[pool11 + this.mdice4 - 24].PtInRect(pp)
                            ) {
                                if (this.mdice4 < 6 && this.broihodove > 5) {
                                    this.mdice4++;
                                    this.mdice1 = this.mdice4;
                                    this.mdice2 = this.mdice4;
                                    this.mdice3 = this.mdice4;
                                    this.dice1 = this.mdice4;
                                    this.dice2 = this.mdice4;
                                    this.ReloadDice();
                                    return true;
                                }
                                this.mdice4 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                        if (pool11 >= 0 && pool11 < 12) {
                            if (
                                pool11 + this.mdice4 < 12 &&
                                this.table[pool11 + this.mdice4][0] === 0 &&
                                this.tablerect[pool11 + this.mdice4].PtInRect(pp)
                            ) {
                                if (this.mdice4 < 6 && this.broihodove > 5) {
                                    this.mdice4++;
                                    this.mdice1 = this.mdice4;
                                    this.mdice2 = this.mdice4;
                                    this.mdice3 = this.mdice4;
                                    this.dice1 = this.mdice4;
                                    this.dice2 = this.mdice4;
                                    this.ReloadDice();
                                    return true;
                                }
                                this.mdice4 = 0;
                                this.ReloadDice();
                                return true;
                            }
                        }
                    }
                    if (rexit) {
                        if (pool11 + this.mdice4 === 12 && this.tablerect[25].PtInRect(pp)) {
                            if (this.mdice4 < 6 && this.broihodove > 5) {
                                this.mdice4++;
                                this.mdice1 = this.mdice4;
                                this.mdice2 = this.mdice4;
                                this.mdice3 = this.mdice4;
                                this.dice1 = this.mdice4;
                                this.dice2 = this.mdice4;
                                this.ReloadDice();
                                return true;
                            }
                            this.mdice4 = 0;
                            this.ReloadDice();
                            return true;
                        }
                        if (pool11 + this.mdice4 > 12 && this.tablerect[25].PtInRect(pp)) {
                            for (let i = pool11 - 1; i > 5; i--) {
                                if (this.table[i][1] > 0) {
                                    return false;
                                }
                            }
                            if (this.mdice4 < 6 && this.broihodove > 5) {
                                this.mdice4++;
                                this.mdice1 = this.mdice4;
                                this.mdice2 = this.mdice4;
                                this.mdice3 = this.mdice4;
                                this.dice1 = this.mdice4;
                                this.dice2 = this.mdice4;
                                this.ReloadDice();
                                return true;
                            }
                            this.mdice4 = 0;
                            this.ReloadDice();
                            return true;
                        }
                    }
                }
                ////////////////////////////////////ako e 4ift////////////////////////////////
                else {
                }
            }
        } //Za Teodor--
        return false;
    }

    CheckKillEnemyPool(rect: number, player: number) {
        let eplayer: number;
        if (player === 1) {
            eplayer = 2;
        } else {
            eplayer = 1;
        }
        if (this.GetNumPools(rect, eplayer) === 1) {
            return true;
        }
        return false;
    }

    KillEnemyPool(rect: number, player: number) {
        if (this.type === 0) {
            if (this.CheckKillEnemyPool(rect, player)) {
                let eplayer;
                if (player === 1) {
                    eplayer = 2;
                } else {
                    eplayer = 1;
                }
                for (let i = (eplayer - 1) * 15; i < 15 + (eplayer - 1) * 15; i++) {
                    for (let q1 = 0; q1 < 8; q1++) {
                        for (let q2 = 0; q2 < 2; q2++) {
                            if (this.poolplace[rect][q1][q2].x === this.poolpos[i].x && this.poolplace[rect][q1][q2].y === this.poolpos[i].y) {
                                for (let qp1 = 0; qp1 < 2; qp1++) {
                                    for (let qp2 = 0; qp2 < 8; qp2++) {
                                        let find_free = true;
                                        for (let i1 = (eplayer - 1) * 15; i1 < 15 + (eplayer - 1) * 15; i1++) {
                                            if (
                                                this.poolpos[i1].x === this.poolplace[23 + eplayer][qp2][qp1].x &&
                                                this.poolpos[i1].y === this.poolplace[23 + eplayer][qp2][qp1].y
                                            ) {
                                                find_free = false;
                                            }
                                        }
                                        if (find_free) {
                                            this.poolpoint[i].x = this.poolplace[23 + eplayer][qp2][qp1].x;
                                            this.poolpoint[i].y = this.poolplace[23 + eplayer][qp2][qp1].y;
                                            this.poolpos[i].x = this.poolpoint[i].x;
                                            this.poolpos[i].y = this.poolpoint[i].y;
                                            this.setState({});
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    CheckReadyPlayer(player: number) {
        if (this.mdice1 === 0 && this.mdice2 === 0 && this.mdice3 === 0 && this.mdice4 === 0) {
            this.Began = false; //tova su6to
            this.broihodove++; //tova az sum go pisal
            if (player === 1) {
                ///////////////////////////////////az sum go pisal
                if (this.Doigravane && this.type === 2) {
                    this.KoiENaRed = 1;
                    this.strelka = 3;
                    this.setState({});
                    this.Doigravane = false;
                }
                ///////////////////////////////////////////////////
                else {
                    this.KoiENaRed = 2;
                    this.strelka = 2;
                    this.setState({});
                }
            } else {
                if (this.Doigravane && this.type === 2) {
                    this.KoiENaRed = 2;
                    this.strelka = 2;
                    this.setState({});
                    this.Doigravane = false;
                } else {
                    this.KoiENaRed = 1;
                    this.strelka = 3;
                    this.setState({});
                }
            }
            this.zarove = false;
        }
    }

    ReOrderPools(rect: number) {
        let rpools = this.GetNumPools(rect, 1) + this.GetNumPools(rect, 2);
        let cpool = 0;
        if (rpools > 0) {
            for (let q1 = 0; q1 < 2; q1++) {
                for (let q2 = 0; q2 < 8; q2++) {
                    let find_free = true;
                    for (let i1 = 0; i1 < 30; i1++) {
                        if (this.poolpos[i1].x === this.poolplace[rect][q2][q1].x && this.poolpos[i1].y === this.poolplace[rect][q2][q1].y) {
                            find_free = false;
                            cpool = cpool + 1;
                        }
                    }
                    let findPos = false;
                    if (find_free && cpool < rpools) {
                        for (let qp1 = q1; qp1 < 2; qp1++) {
                            if (findPos) {
                                break;
                            }
                            for (let qp2 = q2; qp2 < 8; qp2++) {
                                if (findPos) {
                                    break;
                                }
                                for (let i2 = 0; i2 < 30; i2++) {
                                    if (
                                        this.poolpos[i2].x === this.poolplace[rect][qp2][qp1].x &&
                                        this.poolpos[i2].y === this.poolplace[rect][qp2][qp1].y
                                    ) {
                                        cpool = cpool + 1;
                                        this.poolpos[i2].x = this.poolplace[rect][q2][q1].x;
                                        this.poolpos[i2].y = this.poolplace[rect][q2][q1].y;
                                        this.poolpoint[i2].x = this.poolpos[i2].x;
                                        this.poolpoint[i2].y = this.poolpos[i2].y;
                                        findPos = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    //next:;
                }
            }
        }
        this.setState({});
    }

    async PuskPool(player: number, pool: number, rpool: number, poolpl: number, pos1: number, pos2: number) {
        let showBegin: boolean = this.ShowNewGameButton();
        if (poolpl < 26) {
            this.KillEnemyPool(poolpl, player);
        }
        if (this.OP && player === 2 && (this.P2OP || !this.Server)) {
            this.CheckMovePool2(pool, new CPoint(this.poolplace[poolpl][pos1][pos2].x + 1, this.poolplace[poolpl][pos1][pos2].y + 1));
        }
        this.poolpoint[pool].x = this.poolplace[poolpl][pos1][pos2].x;
        this.poolpoint[pool].y = this.poolplace[poolpl][pos1][pos2].y;
        this.poolpos[pool].x = this.poolpoint[pool].x;
        this.poolpos[pool].y = this.poolpoint[pool].y;
        this.setState({});
        this.CheckReadyPlayer(player);
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(0, 'center', 0, 0);
        }
        this.ReOrderPools(rpool);
        if (this.OP) {
            if (player === 1) {
                let newpoolpl = poolpl;
                let newrpool = rpool;
                if (this.type === 2) {
                    if (newpoolpl === 26) {
                        newpoolpl = 27;
                    } else {
                        newpoolpl = (12 + newpoolpl) % 24;
                    }
                    if (newrpool === 24) {
                        newrpool = 25;
                    }
                    if (newrpool !== 25) {
                        newrpool = (12 + newrpool) % 24;
                    }
                } else {
                    if (newpoolpl === 26) {
                        newpoolpl = 27;
                    } else {
                        newpoolpl = 23 - newpoolpl;
                    }
                    if (newrpool === 24) {
                        newrpool = 25;
                    }
                    if (newrpool !== 25) {
                        newrpool = 23 - newrpool;
                    }
                }
                if (!this.watch) {
                    if (this.Server) {
                        if (this.P2OP || this.HaveWatch) {
                            let message =
                                '$|k|' +
                                this.IntToString(pool + 15) +
                                '|' +
                                this.IntToString(newrpool) +
                                '|' +
                                this.IntToString(newpoolpl) +
                                '|' +
                                this.IntToString(pos1) +
                                '|' +
                                this.IntToString(pos2);
                            this.props.m_pwin.SendGame(message);
                        }
                    } else {
                        let message =
                            '$|l|' +
                            this.IntToString(pool + 15) +
                            '|' +
                            this.IntToString(newrpool) +
                            '|' +
                            this.IntToString(newpoolpl) +
                            '|' +
                            this.IntToString(pos1) +
                            '|' +
                            this.IntToString(pos2);
                        this.props.m_pwin.SendGame(message);
                    }
                }
                if (this.CheckWinGame(1)) {
                    if (!this.wait) {
                        await this.InitRezDlg(0);
                    }
                    await this.PuskPoolEnd(showBegin);
                    return;
                }
                if (!this.CheckMove(1)) {
                    /////////////////////////////////////////////////az sum go pisal
                    if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(2)) {
                        if (!this.Doigravane) {
                            this.Doigravane = true;
                        } else {
                            this.Doigravane = false;
                        }
                        this.KoiENaRed = 2;
                        this.strelka = 2;
                        await this.PuskPoolEnd(showBegin);
                        return;
                    }
                    ////////////////////////////////////////////////
                    else {
                        if (
                            this.type === 2 &&
                            !this.Began &&
                            !this.Doigravane &&
                            this.mdice1 === 0 &&
                            this.mdice2 === 0 &&
                            this.mdice3 === 0 &&
                            this.mdice4 === 0 &&
                            !this.zarove
                        ) {
                        } else {
                            this.KoiENaRed = 2;
                            this.strelka = 2;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                        }
                        await this.PuskPoolEnd(showBegin);
                        return;
                    }
                }
            } else {
                if (this.Server && !this.P2OP && this.HaveWatch) {
                    let newpoolpl = poolpl;
                    let newrpool = rpool;
                    if (this.type === 2) {
                        if (newpoolpl === 26) {
                            newpoolpl = 27;
                        } else {
                            newpoolpl = (12 + newpoolpl) % 24;
                        }
                        if (newrpool === 24) {
                            newrpool = 25;
                        }
                        if (newrpool !== 25) {
                            newrpool = (12 + newrpool) % 24;
                        }
                    } else {
                        if (newpoolpl === 26) {
                            newpoolpl = 27;
                        } else {
                            newpoolpl = 23 - newpoolpl;
                        }
                        if (newrpool === 24) {
                            newrpool = 25;
                        }
                        if (newrpool !== 25) {
                            newrpool = 23 - newrpool;
                        }
                    }
                    let message =
                        '$|k|' +
                        this.IntToString(pool) +
                        '|' +
                        this.IntToString(rpool) +
                        '|' +
                        this.IntToString(poolpl) +
                        '|' +
                        this.IntToString(pos1) +
                        '|' +
                        this.IntToString(pos2);
                    this.props.m_pwin.SendGame(message);
                }
                if (this.CheckWinGame(2)) {
                    if (!this.wait) {
                        await this.InitRezDlg(0);
                    }
                    await this.PuskPoolEnd(showBegin);
                    return;
                }
                if (!this.CheckMove(2)) {
                    //////////////////////////////////////////////////az sum go pisal
                    if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(1)) {
                        if (!this.Doigravane) {
                            this.Doigravane = true;
                        } else {
                            this.Doigravane = false;
                        }
                        this.KoiENaRed = 1;
                        this.strelka = 1; //3;
                        await this.PuskPoolEnd(showBegin);
                        return;
                    }
                    /////////////////////////////////////////////////////////////////
                    else {
                        if (
                            this.type === 2 &&
                            !this.Began &&
                            !this.Doigravane &&
                            this.mdice1 === 0 &&
                            this.mdice2 === 0 &&
                            this.mdice3 === 0 &&
                            this.mdice4 === 0 &&
                            !this.zarove
                        ) {
                        } else {
                            this.KoiENaRed = 1;
                            this.strelka = 3;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                        }
                    }
                }
            }
        }
        this.online_timer = this.online_timer_time;
        await this.PuskPoolEnd(showBegin);
    }

    CheckMove(player: number) {
        if (this.type === 0 && player === 2) {
            this.full();
            if (this.table[25][1] > 0) {
                if (this.mdice1 > 0 && this.table[24 - this.mdice1][0] < 2) {
                    return true;
                }
                if (this.mdice2 > 0 && this.table[24 - this.mdice2][0] < 2) {
                    return true;
                }
                if (this.mdice3 > 0 && this.table[24 - this.mdice3][0] < 2) {
                    return true;
                }
                if (this.mdice4 > 0 && this.table[24 - this.mdice4][0] < 2) {
                    return true;
                }
                return false;
            }
        }
        let ready_to_exit: boolean = this.CheckReadyToExit(player);
        if (this.type === 0) {
            let gmp = this.GetMinRectPool(1);
            for (let a = (player - 1) * 15; a < 15 + (player - 1) * 15; a++) {
                if (this.poolpoint[a].x > 0) {
                    let pp: CPoint = new CPoint(this.poolpos[a].x, this.poolpos[a].y);
                    let table_rect_index = this.GetPoolTableRectIndex(a);
                    for (let move_pool_index = -1; move_pool_index < 26; move_pool_index++) {
                        if (this.CheckFreeRect(move_pool_index, player)) {
                            if (move_pool_index >= 0) {
                                pp = new CPoint(this.poolplace[move_pool_index][0][0].x, this.poolplace[move_pool_index][0][0].y);
                            }
                            if (ready_to_exit && move_pool_index === -1 && player === 2) {
                                pp = new CPoint(this.poolplace[25][0][0].x, this.poolplace[25][0][0].y);
                            }
                            if (player === 1) {
                                //	if(
                                if (move_pool_index !== 25) {
                                    if (
                                        this.mdice1 !== 0 &&
                                        ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                                            ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) &&
                                                ready_to_exit))
                                    ) {
                                        if ((this.HaveKiledPool(player) && table_rect_index === -1) || !this.HaveKiledPool(player)) {
                                            return true;
                                        }
                                    }
                                    if (
                                        this.mdice2 !== 0 &&
                                        ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                                            ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) &&
                                                ready_to_exit))
                                    ) {
                                        if ((this.HaveKiledPool(player) && table_rect_index === -1) || !this.HaveKiledPool(player)) {
                                            return true;
                                        }
                                    }
                                    if (
                                        this.mdice3 !== 0 &&
                                        ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                                            ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) &&
                                                ready_to_exit))
                                    ) {
                                        if ((this.HaveKiledPool(player) && table_rect_index === -1) || !this.HaveKiledPool(player)) {
                                            return true;
                                        }
                                    }
                                    if (
                                        this.mdice4 !== 0 &&
                                        ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                                            ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) &&
                                                ready_to_exit))
                                    ) {
                                        if ((this.HaveKiledPool(player) && table_rect_index === -1) || !this.HaveKiledPool(player)) {
                                            return true;
                                        }
                                    }
                                }
                                //next1:;
                            }
                            if (player === 2) {
                                gmp = this.GetMaxRectPool(2);
                                if (
                                    this.mdice1 !== 0 &&
                                    ((table_rect_index - move_pool_index === this.mdice1 && move_pool_index !== -1) ||
                                        (ready_to_exit &&
                                            (table_rect_index + 1 === this.mdice1 ||
                                                (table_rect_index + 1 <= this.mdice1 && table_rect_index === gmp)) &&
                                            move_pool_index !== table_rect_index &&
                                            this.tablerect[25].PtInRect(pp)))
                                ) {
                                    if ((this.HaveKiledPool(player) && table_rect_index === 24) || !this.HaveKiledPool(player)) {
                                        return true;
                                    }
                                }
                                if (
                                    this.mdice2 !== 0 &&
                                    ((table_rect_index - move_pool_index === this.mdice2 && move_pool_index !== -1) ||
                                        (ready_to_exit &&
                                            (table_rect_index + 1 === this.mdice2 ||
                                                (table_rect_index + 1 <= this.mdice2 && table_rect_index === gmp)) &&
                                            move_pool_index !== table_rect_index &&
                                            this.tablerect[25].PtInRect(pp)))
                                ) {
                                    if ((this.HaveKiledPool(player) && table_rect_index === 24) || !this.HaveKiledPool(player)) {
                                        return true;
                                    }
                                }
                                if (
                                    this.mdice3 !== 0 &&
                                    ((table_rect_index - move_pool_index === this.mdice3 && move_pool_index !== -1) ||
                                        (ready_to_exit &&
                                            (table_rect_index + 1 === this.mdice3 ||
                                                (table_rect_index + 1 <= this.mdice3 && table_rect_index === gmp)) &&
                                            move_pool_index !== table_rect_index &&
                                            this.tablerect[25].PtInRect(pp)))
                                ) {
                                    if ((this.HaveKiledPool(player) && table_rect_index === 24) || !this.HaveKiledPool(player)) {
                                        return true;
                                    }
                                }
                                if (
                                    this.mdice4 !== 0 &&
                                    ((table_rect_index - move_pool_index === this.mdice4 && move_pool_index !== -1) ||
                                        (ready_to_exit &&
                                            (table_rect_index + 1 === this.mdice4 ||
                                                (table_rect_index + 1 <= this.mdice4 && table_rect_index === gmp)) &&
                                            move_pool_index !== table_rect_index &&
                                            this.tablerect[25].PtInRect(pp)))
                                ) {
                                    if ((this.HaveKiledPool(player) && table_rect_index === 24) || !this.HaveKiledPool(player)) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.type === 1) {
            let gmp = this.GetMinRectPool(1);
            for (let a = (player - 1) * 15; a < 15 + (player - 1) * 15; a++) {
                if (this.poolpoint[a].x > 0) {
                    if (!this.IsTapedPool(a)) {
                        let pp: CPoint = new CPoint(this.poolpos[a].x, this.poolpos[a].y);
                        let table_rect_index = this.GetPoolTableRectIndex(a);
                        for (let move_pool_index = -1; move_pool_index < 26; move_pool_index++) {
                            if (this.CheckFreeRect(move_pool_index, player)) {
                                if (move_pool_index >= 0) {
                                    pp = new CPoint(this.poolplace[move_pool_index][0][0].x, this.poolplace[move_pool_index][0][0].y);
                                }
                                if (ready_to_exit && move_pool_index === -1 && player === 2) {
                                    pp = new CPoint(this.poolplace[25][0][0].x, this.poolplace[25][0][0].y);
                                }
                                if (player === 1) {
                                    if (move_pool_index !== 25) {
                                        if (
                                            this.mdice1 !== 0 &&
                                            ((move_pool_index - table_rect_index === this.mdice1 && move_pool_index !== 24) ||
                                                ((24 - table_rect_index === this.mdice1 || (24 - gmp < this.mdice1 && table_rect_index === gmp)) &&
                                                    ready_to_exit))
                                        ) {
                                            //	if(this.HaveKiledPool(player)&&table_rect_index==-1||!this.HaveKiledPool(player)) {
                                            return true;
                                            //	}
                                        }
                                        if (
                                            this.mdice2 !== 0 &&
                                            ((move_pool_index - table_rect_index === this.mdice2 && move_pool_index !== 24) ||
                                                ((24 - table_rect_index === this.mdice2 || (24 - gmp < this.mdice2 && table_rect_index === gmp)) &&
                                                    ready_to_exit))
                                        ) {
                                            //	if(this.HaveKiledPool(player)&&table_rect_index==-1||!this.HaveKiledPool(player)) {
                                            return true;
                                            //	}
                                        }
                                        if (
                                            this.mdice3 !== 0 &&
                                            ((move_pool_index - table_rect_index === this.mdice3 && move_pool_index !== 24) ||
                                                ((24 - table_rect_index === this.mdice3 || (24 - gmp < this.mdice3 && table_rect_index === gmp)) &&
                                                    ready_to_exit))
                                        ) {
                                            //	if(this.HaveKiledPool(player)&&table_rect_index==-1||!this.HaveKiledPool(player)) {
                                            return true;
                                            //	}
                                        }
                                        if (
                                            this.mdice4 !== 0 &&
                                            ((move_pool_index - table_rect_index === this.mdice4 && move_pool_index !== 24) ||
                                                ((24 - table_rect_index === this.mdice4 || (24 - gmp < this.mdice4 && table_rect_index === gmp)) &&
                                                    ready_to_exit))
                                        ) {
                                            //	if(this.HaveKiledPool(player)&&table_rect_index==-1||!this.HaveKiledPool(player)) {
                                            return true;
                                            //	}
                                        }
                                    }
                                }
                                if (player === 2) {
                                    gmp = this.GetMaxRectPool(2);
                                    if (
                                        this.mdice1 !== 0 &&
                                        ((table_rect_index - move_pool_index === this.mdice1 && move_pool_index !== -1) ||
                                            (ready_to_exit &&
                                                (table_rect_index + 1 === this.mdice1 ||
                                                    (table_rect_index + 1 <= this.mdice1 && table_rect_index === gmp)) &&
                                                move_pool_index !== table_rect_index &&
                                                this.tablerect[25].PtInRect(pp)))
                                    ) {
                                        //	if(this.HaveKiledPool(player)&&table_rect_index==24||!this.HaveKiledPool(player)) {
                                        return true;
                                        //	}
                                    }
                                    if (
                                        this.mdice2 !== 0 &&
                                        ((table_rect_index - move_pool_index === this.mdice2 && move_pool_index !== -1) ||
                                            (ready_to_exit &&
                                                (table_rect_index + 1 === this.mdice2 ||
                                                    (table_rect_index + 1 <= this.mdice2 && table_rect_index === gmp)) &&
                                                move_pool_index !== table_rect_index &&
                                                this.tablerect[25].PtInRect(pp)))
                                    ) {
                                        //	if(this.HaveKiledPool(player)&&table_rect_index==24||!this.HaveKiledPool(player)) {
                                        return true;
                                        //	}
                                    }
                                    if (
                                        this.mdice3 !== 0 &&
                                        ((table_rect_index - move_pool_index === this.mdice3 && move_pool_index !== -1) ||
                                            (ready_to_exit &&
                                                (table_rect_index + 1 === this.mdice3 ||
                                                    (table_rect_index + 1 <= this.mdice3 && table_rect_index === gmp)) &&
                                                move_pool_index !== table_rect_index &&
                                                this.tablerect[25].PtInRect(pp)))
                                    ) {
                                        //	if(this.HaveKiledPool(player)&&table_rect_index==24||!this.HaveKiledPool(player)) {
                                        return true;
                                        //	}
                                    }
                                    if (
                                        this.mdice4 !== 0 &&
                                        ((table_rect_index - move_pool_index === this.mdice4 && move_pool_index !== -1) ||
                                            (ready_to_exit &&
                                                (table_rect_index + 1 === this.mdice4 ||
                                                    (table_rect_index + 1 <= this.mdice4 && table_rect_index === gmp)) &&
                                                move_pool_index !== table_rect_index &&
                                                this.tablerect[25].PtInRect(pp)))
                                    ) {
                                        //	if(this.HaveKiledPool(player)&&table_rect_index==24||!this.HaveKiledPool(player)) {
                                        return true;
                                        //	}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.type === 2) {
            //Za Teodor++
            this.full();
            if (player === 1) {
                for (let i = 0; i < 15; i++) {
                    if (this.CheckMovePool(i)) {
                        return true;
                    }
                }
            }
            if (player === 2) {
                for (let i = 15; i < 30; i++) {
                    if (this.CheckMovePool(i)) {
                        return true;
                    }
                }
            }
        } //Za Teodor--
        return false;
    }

    async PuskPoolEnd(showBegin: boolean) {
        let showEnd = this.ShowNewGameButton();
        if (showEnd !== showBegin) {
            if (this.getIsTest()) {
                if (showEnd && this.OP && (!this.Server || (this.Server && this.P2OP))) {
                    await this.OnNewGameButton();
                }
            }
        }
        if (this.watch) {
            await this.Timer3();
        }
        this.setState({});
    }

    async AddToTableRect(pool: number, pp: CPoint) {
        let player: number;
        if (pool < 15) {
            player = 1;
        } else {
            player = 2;
        }
        if (this.CheckMovePool2(pool, pp)) {
            let rpool = this.GetRectPool(pool);
            if (this.type === 0) {
                if (player === 1 && this.tablerect[24].PtInRect(pp)) {
                    for (let q1 = 0; q1 < 2; q1++) {
                        for (let q2 = 0; q2 < 8; q2++) {
                            let find_free = true;
                            for (let i1 = 0; i1 < 15; i1++) {
                                if (
                                    i1 !== pool &&
                                    this.poolpos[i1].x === this.poolplace[26][q2][q1].x &&
                                    this.poolpos[i1].y === this.poolplace[26][q2][q1].y
                                ) {
                                    find_free = false;
                                }
                            }
                            if (find_free) {
                                await this.PuskPool(player, pool, rpool, 26, q2, q1);
                                this.setState({});
                                return true;
                            }
                        }
                    }
                    return this.AddToTableRectElseEnd(pool);
                }
                if (player === 2 && this.tablerect[25].PtInRect(pp)) {
                    for (let q1 = 0; q1 < 2; q1++) {
                        for (let q2 = 0; q2 < 8; q2++) {
                            let find_free = true;
                            for (let i1 = 15; i1 < 30; i1++) {
                                if (
                                    i1 !== pool &&
                                    this.poolpos[i1].x === this.poolplace[27][q2][q1].x &&
                                    this.poolpos[i1].y === this.poolplace[27][q2][q1].y
                                ) {
                                    find_free = false;
                                }
                            }
                            if (find_free) {
                                await this.PuskPool(player, pool, rpool, 27, q2, q1);
                                this.setState({});
                                return true;
                            }
                        }
                    }
                    return this.AddToTableRectElseEnd(pool);
                }
                for (let i = 0; i < 24; i++) {
                    if (this.tablerect[i].PtInRect(pp)) {
                        for (let q1 = 0; q1 < 2; q1++) {
                            for (let q2 = 0; q2 < 8; q2++) {
                                let find_free = true;
                                for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
                                    if (
                                        i1 !== pool &&
                                        this.poolpos[i1].x === this.poolplace[i][q2][q1].x &&
                                        this.poolpos[i1].y === this.poolplace[i][q2][q1].y
                                    ) {
                                        find_free = false;
                                    }
                                }
                                if (find_free) {
                                    await this.PuskPool(player, pool, rpool, i, q2, q1);
                                    this.setState({});
                                    return true;
                                }
                            }
                        }
                    }
                }
                return this.AddToTableRectElseEnd(pool);
            }
            if (this.type === 1 || this.type === 2) {
                //tova napravo go prehvurlih 4e niama smisal da se pi6e pak
                if (player === 1 && this.tablerect[24].PtInRect(pp)) {
                    for (let q1 = 0; q1 < 2; q1++) {
                        for (let q2 = 0; q2 < 8; q2++) {
                            let find_free = true;
                            for (let i1 = 0; i1 < 15; i1++) {
                                if (
                                    i1 !== pool &&
                                    this.poolpos[i1].x === this.poolplace[26][q2][q1].x &&
                                    this.poolpos[i1].y === this.poolplace[26][q2][q1].y
                                ) {
                                    find_free = false;
                                }
                            }
                            if (find_free) {
                                await this.PuskPool(player, pool, rpool, 26, q2, q1);
                                this.setState({});
                                return true;
                            }
                        }
                    }
                    return this.AddToTableRectElseEnd(pool);
                }
                if (player === 2 && this.tablerect[25].PtInRect(pp)) {
                    for (let q1 = 0; q1 < 2; q1++) {
                        for (let q2 = 0; q2 < 8; q2++) {
                            let find_free = true;
                            for (let i1 = 15; i1 < 30; i1++) {
                                if (
                                    i1 !== pool &&
                                    this.poolpos[i1].x === this.poolplace[27][q2][q1].x &&
                                    this.poolpos[i1].y === this.poolplace[27][q2][q1].y
                                ) {
                                    find_free = false;
                                }
                            }
                            if (find_free) {
                                await this.PuskPool(player, pool, rpool, 27, q2, q1);
                                this.setState({});
                                return true;
                            }
                        }
                    }
                    return this.AddToTableRectElseEnd(pool);
                }
                for (let i = 0; i < 24; i++) {
                    if (this.tablerect[i].PtInRect(pp)) {
                        for (let q1 = 0; q1 < 2; q1++) {
                            for (let q2 = 0; q2 < 8; q2++) {
                                let find_free = true;
                                for (let i1 = 0; i1 < 30; i1++) {
                                    if (
                                        i1 !== pool &&
                                        this.poolpos[i1].x === this.poolplace[i][q2][q1].x &&
                                        this.poolpos[i1].y === this.poolplace[i][q2][q1].y
                                    ) {
                                        find_free = false;
                                    }
                                }
                                if (find_free) {
                                    await this.PuskPool(player, pool, rpool, i, q2, q1);
                                    this.setState({});
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return this.AddToTableRectElseEnd(pool);
        } else {
            this.poolpoint[pool].x = this.poolpos[pool].x;
            this.poolpoint[pool].y = this.poolpos[pool].y;
            this.setState({});
            return false;
        }
    }

    AddToTableRectElseEnd(pool: number): boolean {
        this.poolpoint[pool].x = this.poolpos[pool].x;
        this.poolpoint[pool].y = this.poolpos[pool].y;
        this.setState({});
        return false;
    }

    async Timer3() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.SP && this.endgame === false) {
            if (this.KoiENaRed === 1) {
                if (!this.zarove && this.KoiENaRed === 1 && GlobalVariables.automatic_td && !this.wait) {
                    this.ThrowDice(-1);
                    this.strelka = 1;
                    this.setState({});
                    return;
                }
                if ((this.mdice1 !== 0 || this.mdice2 !== 0 || this.mdice3 !== 0 || this.mdice4 !== 0) && !this.CheckMove(1) && this.zarove) {
                    /////////////////////i tova az sum go pisal
                    if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(2)) {
                        if (!this.Doigravane) {
                            this.Doigravane = true;
                        } else {
                            this.Doigravane = false;
                        }
                        this.KoiENaRed = 2;
                        this.strelka = 2;
                        this.setState({});
                        return;
                    }
                    /////////////////////////////////////
                    else {
                        this.KoiENaRed = 2;
                        this.strelka = 2;
                        this.mdice1 = 0;
                        this.mdice2 = 0;
                        this.mdice3 = 0;
                        this.mdice4 = 0;
                        this.ReloadDice();
                        this.zarove = false;
                    }
                }
            } else {
                if (!this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                    if (!this.zarove) {
                        this.strelka = 2;
                        this.ThrowDice(-1);
                        return;
                    }
                    if (!this.OP) {
                        await this.Intelect();
                    }
                }
                return;
            }
        }
        if (this.OP) {
            if (this.Server && this.endgame === false) {
                if (this.KoiENaRed === 1) {
                    if (
                        !this.zarove &&
                        this.KoiENaRed === 1 &&
                        GlobalVariables.automatic_td &&
                        (this.op_start || (this.tab3 && (this.rez1 > 0 || this.rez2 > 0))) &&
                        !this.wait
                    ) {
                        this.ThrowDice(-1);
                        this.strelka = 1;
                        this.setState({});
                        return;
                    }
                    if ((this.mdice1 !== 0 || this.mdice2 !== 0 || this.mdice3 !== 0 || this.mdice4 !== 0) && !this.CheckMove(1) && this.zarove) {
                        ///////////////////////////i tova
                        if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(2)) {
                            if (!this.Doigravane) {
                                this.Doigravane = true;
                            } else {
                                this.Doigravane = false;
                            }
                            this.KoiENaRed = 2;
                            this.strelka = 2;
                            this.setState({});
                            return;
                        }
                        ///////////////////////////////////////////
                        else {
                            this.KoiENaRed = 2;
                            this.strelka = 2;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                            return;
                        }
                    }
                } else {
                    if (!this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                        if (!this.zarove && !this.P2OP) {
                            this.ThrowDice(-1);
                            this.strelka = 2;
                            this.setState({});
                            return;
                        }
                        if (!this.P2OP) {
                            await this.Intelect();
                            return;
                        }
                    }
                    if ((this.mdice1 !== 0 || this.mdice2 !== 0 || this.mdice3 !== 0 || this.mdice4 !== 0) && !this.CheckMove(2) && this.zarove) {
                        //////////////////////////i tova
                        if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(1)) {
                            if (!this.Doigravane) {
                                this.Doigravane = true;
                            } else {
                                this.Doigravane = false;
                            }
                            this.KoiENaRed = 1;
                            this.strelka = 1; //3;
                            this.setState({});
                            return;
                        }
                        ///////////////////////////////////////////////////
                        else {
                            this.KoiENaRed = 1;
                            this.strelka = 3;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                            return;
                        }
                    }
                }
            } else {
                if (this.KoiENaRed === 1) {
                    if (
                        !this.zarove &&
                        this.KoiENaRed === 1 &&
                        GlobalVariables.automatic_td &&
                        (this.op_start || (this.tab3 && (this.rez1 > 0 || this.rez2 > 0))) &&
                        !this.wait &&
                        !this.watch
                    ) {
                        this.ThrowDice(-1);
                        this.strelka = 1;
                        this.setState({});
                        return;
                    }
                    if ((this.mdice1 !== 0 || this.mdice2 !== 0 || this.mdice3 !== 0 || this.mdice4 !== 0) && !this.CheckMove(1) && this.zarove) {
                        ///////////////////////////////i tova
                        if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(2)) {
                            if (!this.Doigravane) {
                                this.Doigravane = true;
                            } else {
                                this.Doigravane = false;
                            }
                            this.KoiENaRed = 2;
                            this.strelka = 2;
                            this.setState({});
                            return;
                        }
                        ///////////////////////////////////////
                        else {
                            this.KoiENaRed = 2;
                            this.strelka = 2;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                            return;
                        }
                    }
                } else {
                    if ((this.mdice1 !== 0 || this.mdice2 !== 0 || this.mdice3 !== 0 || this.mdice4 !== 0) && !this.CheckMove(2) && this.zarove) {
                        ///////////////////////////i tova
                        if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(1)) {
                            if (!this.Doigravane) {
                                this.Doigravane = true;
                            } else {
                                this.Doigravane = false;
                            }
                            this.KoiENaRed = 1;
                            this.strelka = 1; //3;
                            this.setState({});
                            return;
                        }
                        //////////////////////////////////////////////////
                        else {
                            this.KoiENaRed = 1;
                            this.strelka = 3;
                            this.mdice1 = 0;
                            this.mdice2 = 0;
                            this.mdice3 = 0;
                            this.mdice4 = 0;
                            this.ReloadDice();
                            this.zarove = false;
                            return;
                        }
                    }
                }
            }
        }
    }

    MyMathfloor(num: number): number {
        return parseInt(num.toString(), 10);
    }

    async Intelect() {
        this.props.m_pwin.KillTimer(3);
        let startTime: number = new Date().getTime();
        if (this.zarove) {
            if (this.type === 0) {
                await this.IntelectTabla();
            }
            if (this.type === 1) {
                await this.IntelectTapa();
            }
            if (this.type === 2) {
                await this.IntelectGulbara();
            }
        }
        let endTime: number = new Date().getTime();
        let nextTimer = this.Speed[3] * 100 - (endTime - startTime);
        this.props.m_pwin.SetTimer(3, nextTimer > 0 ? nextTimer : 0);
    }

    AviableGulbaraChift() {
        //console.log('AviableGulbaraChift Start ' + new Date().getSeconds());
        let um: number;
        let rexit: boolean;
        let NUM: number;
        let nu: number = 0;
        let u: number;
        let same: boolean;
        let same1: boolean;
        if (this.mdice1 !== 0) {
            this.mdice[0] = this.mdice1;
            this.mdice[1] = this.mdice2;
            this.mdice[2] = this.mdice3;
            this.mdice[3] = this.mdice4;
            nu = 4;
        } else {
            if (this.mdice2 !== 0) {
                this.mdice[0] = this.mdice2;
                this.mdice[1] = this.mdice3;
                this.mdice[2] = this.mdice4;
                nu = 3;
            } else {
                if (this.mdice3 !== 0) {
                    this.mdice[0] = this.mdice3;
                    this.mdice[1] = this.mdice4;
                    nu = 2;
                } else {
                    this.mdice[0] = this.mdice4;
                    nu = 1;
                }
            }
        }
        um = this.mdice4 + 1;
        if (this.broihodove > 5) {
            for (let i = um; i < 7; i++) {
                for (let j = 0; j < 4; j++) {
                    this.mdice[nu] = i;
                    nu++;
                }
            }
        }
        for (let i = nu; i < 24; i++) {
            this.mdice[i] = 0;
        }
        this.sc[0] = 1;
        for (let i = 1; i < 26; i++) {
            this.sc[i] = 0;
        }
        ///////////////////////////
        for (let i = 0; i < 26; i++) {
            this.avid[0][0].avi[i][0] = this.table[i][0];
            this.avid[0][0].avi[i][1] = this.table[i][1];
        }
        ///////////////////////////
        for (let k = 0; k < 24; k++) {
            if (this.mdice[k] !== 0) {
                //console.log('this.sc[k]', this.sc[k]);
                for (let l = 0; l < this.sc[k]; l++) {
                    if (this.sc[k + 1] < 1000) {
                        NUM = 0;
                        rexit = this.CheckReadyToExit1(this.avid[k][l].avi);
                        ///////////////////////////////////////////////////////////////
                        for (let i = 0; i < 26; i++) {
                            if (
                                i < 24 &&
                                i !== 12 &&
                                (i > 12 || i + this.mdice[k] < 12) &&
                                this.avid[k][l].avi[i][1] > 0 &&
                                this.avid[k][l].avi[(i + this.mdice[k]) % 24][0] < 1
                            ) {
                                for (let j = 0; j < 26; j++) {
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][0] = this.avid[k][l].avi[j][0];
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][1] = this.avid[k][l].avi[j][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].avi[i][1]--;
                                this.avid[k + 1][this.sc[k + 1]].avi[(i + this.mdice[k]) % 24][1]++;
                                for (u = 0; u < k; u++) {
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][0] = this.avid[k][l].plays[u][0];
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][1] = this.avid[k][l].plays[u][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].plays[k][0] = i;
                                this.avid[k + 1][this.sc[k + 1]].plays[k][1] = (i + this.mdice[k]) % 24;
                                NUM++;
                                same = false;
                                for (u = 0; u < this.sc[k + 1]; u++) {
                                    same1 = true;
                                    for (let j = 0; j < 24; j++) {
                                        if (this.avid[k + 1][this.sc[k + 1]].avi[j][1] !== this.avid[k + 1][u].avi[j][1]) {
                                            same1 = false;
                                            break;
                                        }
                                    }
                                    if (same1 === true) {
                                        same = true;
                                        break;
                                    }
                                }
                                if (same === false) {
                                    this.sc[k + 1]++;
                                }
                            }
                            if (
                                i === 12 &&
                                this.avid[k][l].avi[i][1] > 0 &&
                                this.avid[k][l].avi[(i + this.mdice[k]) % 24][0] < 1 &&
                                this.avid[k][l].avi[25][1] === 0
                            ) {
                                for (let j = 0; j < 26; j++) {
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][0] = this.avid[k][l].avi[j][0];
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][1] = this.avid[k][l].avi[j][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].avi[i][1]--;
                                this.avid[k + 1][this.sc[k + 1]].avi[(i + this.mdice[k]) % 24][1]++;
                                for (u = 0; u < k; u++) {
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][0] = this.avid[k][l].plays[u][0];
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][1] = this.avid[k][l].plays[u][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].plays[k][0] = i;
                                this.avid[k + 1][this.sc[k + 1]].plays[k][1] = (i + this.mdice[k]) % 24;
                                NUM++;
                                same = false;
                                for (u = 0; u < this.sc[k + 1]; u++) {
                                    same1 = true;
                                    for (let j = 0; j < 24; j++) {
                                        if (this.avid[k + 1][this.sc[k + 1]].avi[j][1] !== this.avid[k + 1][u].avi[j][1]) {
                                            same1 = false;
                                            break;
                                        }
                                    }
                                    if (same1 === true) {
                                        same = true;
                                        break;
                                    }
                                }
                                if (same === false) {
                                    this.sc[k + 1]++;
                                }
                            }
                            if (i === 25 && this.avid[k][l].avi[i][1] > 0 && this.avid[k][l].avi[(i + this.mdice[k] - 13) % 24][0] < 1) {
                                for (let j = 0; j < 26; j++) {
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][0] = this.avid[k][l].avi[j][0];
                                    this.avid[k + 1][this.sc[k + 1]].avi[j][1] = this.avid[k][l].avi[j][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].avi[i][1]--;
                                this.avid[k + 1][this.sc[k + 1]].avi[(i + this.mdice[k] - 13) % 24][1]++;
                                for (u = 0; u < k; u++) {
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][0] = this.avid[k][l].plays[u][0];
                                    this.avid[k + 1][this.sc[k + 1]].plays[u][1] = this.avid[k][l].plays[u][1];
                                }
                                this.avid[k + 1][this.sc[k + 1]].plays[k][0] = i;
                                this.avid[k + 1][this.sc[k + 1]].plays[k][1] = (i + this.mdice[k] - 13) % 24;
                                NUM++;
                                same = false;
                                for (u = 0; u < this.sc[k + 1]; u++) {
                                    same1 = true;
                                    for (let j = 0; j < 24; j++) {
                                        if (this.avid[k + 1][this.sc[k + 1]].avi[j][1] !== this.avid[k + 1][u].avi[j][1]) {
                                            same1 = false;
                                            break;
                                        }
                                    }
                                    if (same1 === true) {
                                        same = true;
                                        break;
                                    }
                                }
                                if (same === false) {
                                    this.sc[k + 1]++;
                                }
                            }
                            if (rexit) {
                                if (this.avid[k][l].avi[i][1] > 0 && i + this.mdice[k] === 12) {
                                    for (let j = 0; j < 26; j++) {
                                        this.avid[k + 1][this.sc[k + 1]].avi[j][0] = this.avid[k][l].avi[j][0];
                                        this.avid[k + 1][this.sc[k + 1]].avi[j][1] = this.avid[k][l].avi[j][1];
                                    }
                                    this.avid[k + 1][this.sc[k + 1]].avi[i][1]--;
                                    this.avid[k + 1][this.sc[k + 1]].avi[24][1]++;
                                    for (u = 0; u < k; u++) {
                                        this.avid[k + 1][this.sc[k + 1]].plays[u][0] = this.avid[k][l].plays[u][0];
                                        this.avid[k + 1][this.sc[k + 1]].plays[u][1] = this.avid[k][l].plays[u][1];
                                    }
                                    this.avid[k + 1][this.sc[k + 1]].plays[k][0] = i;
                                    this.avid[k + 1][this.sc[k + 1]].plays[k][1] = 25;
                                    NUM++;
                                    same = false;
                                    for (u = 0; u < this.sc[k + 1]; u++) {
                                        same1 = true;
                                        for (let j = 0; j < 24; j++) {
                                            if (this.avid[k + 1][this.sc[k + 1]].avi[j][1] !== this.avid[k + 1][u].avi[j][1]) {
                                                same1 = false;
                                                break;
                                            }
                                        }
                                        if (same1 === true) {
                                            same = true;
                                            break;
                                        }
                                    }
                                    if (same === false) {
                                        this.sc[k + 1]++;
                                    }
                                }
                                if (
                                    i !== 24 &&
                                    i + this.mdice[k] > 12 &&
                                    this.avid[k][l].avi[i][1] > 0 &&
                                    this.avid[k][l].avi[i - 1][1] === 0 &&
                                    this.avid[k][l].avi[i - 2][1] === 0 &&
                                    this.avid[k][l].avi[i - 3][1] === 0 &&
                                    this.avid[k][l].avi[i - 4][1] === 0 &&
                                    this.avid[k][l].avi[i - 5][1] === 0
                                ) {
                                    for (let j = 0; j < 26; j++) {
                                        this.avid[k + 1][this.sc[k + 1]].avi[j][0] = this.avid[k][l].avi[j][0];
                                        this.avid[k + 1][this.sc[k + 1]].avi[j][1] = this.avid[k][l].avi[j][1];
                                    }
                                    this.avid[k + 1][this.sc[k + 1]].avi[i][1]--;
                                    this.avid[k + 1][this.sc[k + 1]].avi[24][1]++;
                                    for (u = 0; u < k; u++) {
                                        this.avid[k + 1][this.sc[k + 1]].plays[u][0] = this.avid[k][l].plays[u][0];
                                        this.avid[k + 1][this.sc[k + 1]].plays[u][1] = this.avid[k][l].plays[u][1];
                                    }
                                    this.avid[k + 1][this.sc[k + 1]].plays[k][0] = i;
                                    this.avid[k + 1][this.sc[k + 1]].plays[k][1] = 25;
                                    NUM++;
                                    same = false;
                                    for (u = 0; u < this.sc[k + 1]; u++) {
                                        same1 = true;
                                        for (let j = 0; j < 24; j++) {
                                            if (this.avid[k + 1][this.sc[k + 1]].avi[j][1] !== this.avid[k + 1][u].avi[j][1]) {
                                                same1 = false;
                                                break;
                                            }
                                        }
                                        if (same1 === true) {
                                            same = true;
                                            break;
                                        }
                                    }
                                    if (same === false) {
                                        this.sc[k + 1]++;
                                    }
                                }
                            }
                        }
                        if (NUM === 0) {
                            if (this.sc[25] < 1000) {
                                for (let j = 0; j < 26; j++) {
                                    this.avid[25][this.sc[25]].avi[j][0] = this.avid[k][l].avi[j][0];
                                    this.avid[25][this.sc[25]].avi[j][1] = this.avid[k][l].avi[j][1];
                                }
                                for (let j = 0; j < 24; j++) {
                                    this.avid[25][this.sc[25]].plays[j][0] = this.avid[k][l].plays[j][0];
                                    this.avid[25][this.sc[25]].plays[j][1] = this.avid[k][l].plays[j][1];
                                }
                                this.avid[25][this.sc[25]].dicen = k;
                                same = false;
                                for (u = 0; u < this.sc[25]; u++) {
                                    same1 = true;
                                    for (let j = 0; j < 24; j++) {
                                        if (this.avid[25][this.sc[25]].avi[j][1] !== this.avid[25][u].avi[j][1]) {
                                            same1 = false;
                                            break;
                                        }
                                    }
                                    if (same1 === true) {
                                        same = true;
                                        break;
                                    }
                                }
                                if (same === false) {
                                    this.sc[25]++;
                                }
                            } //tova e za filtara ma 6te go pi6a koto opraviam intelekta
                            else {
                                //	this.sc[k+1]=500+zize*100;
                                //	zize++;
                            }
                        }
                        ///////////////////////////////////////////////////////////
                    } //tova e za filtara ma 6te go pi6a koto opraviam intelekta
                    else {
                        let tem: number[] = [];
                        for (let i1 = 0; i1 < this.sc[k + 1]; i1++) {
                            this.avid[k + 1][i1].quef = this.takequefduUnPlayed(this.avid[k + 1][i1]);
                        }
                        for (let i1 = 100; i1 < this.sc[k + 1]; i1++) {
                            for (let j1 = 0; j1 < 100; j1++) {
                                if (this.avid[k + 1][i1].quef > this.avid[k + 1][j1].quef) {
                                    for (let j2 = 0; j2 < 26; j2++) {
                                        tem[0] = this.avid[k + 1][j1].avi[j2][0];
                                        tem[1] = this.avid[k + 1][j1].avi[j2][1];
                                        this.avid[k + 1][j1].avi[j2][0] = this.avid[k + 1][i1].avi[j2][0];
                                        this.avid[k + 1][j1].avi[j2][1] = this.avid[k + 1][i1].avi[j2][1];
                                        this.avid[k + 1][i1].avi[j2][0] = tem[0];
                                        this.avid[k + 1][i1].avi[j2][1] = tem[1];
                                    }
                                    for (let j2 = 0; j2 < 24; j2++) {
                                        tem[0] = this.avid[k + 1][j1].plays[j2][0];
                                        tem[1] = this.avid[k + 1][j1].plays[j2][1];
                                        this.avid[k + 1][j1].plays[j2][0] = this.avid[k + 1][i1].plays[j2][0];
                                        this.avid[k + 1][j1].plays[j2][1] = this.avid[k + 1][i1].plays[j2][1];
                                        this.avid[k + 1][i1].plays[j2][0] = tem[0];
                                        this.avid[k + 1][i1].plays[j2][1] = tem[1];
                                    }
                                    tem[0] = this.avid[k + 1][i1].quef;
                                    this.avid[k + 1][i1].quef = this.avid[k + 1][j1].quef;
                                    this.avid[k + 1][j1].quef = tem[0];
                                }
                            }
                        }
                        this.sc[k + 1] = 100;
                    }
                    ///////////////////////////////
                }
            }
        }
        //console.log('AviableGulbaraChift End ' + new Date().getSeconds());
    }

    takequefdu(av: number[][]) {
        let que = 0;
        ////////////////////////////////////////////////////////
        if (this.pipcom > 280) {
            let que11: number[] = [0, 50, 50, 50, 60, 60, 50, 30, 30, 20, 20, 20, 50, 90, 80, 70, 60, 50, 40, 30, 20, 10, 10, 10, 0, 0];
            let que12: number[] = [0, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 30, 20, 15, 10, 5, 0, 0, 0, 10, 20, 15, 0, 0];
            for (let i = 0; i < 26; i++) {
                if (i > this.lastpool) {
                    if (av[i][1] > 0) {
                        que = que + que11[i];
                    }
                    if (av[i][1] > 1) {
                        que = que + que12[i];
                    }
                }
                if (i < 6 && av[12][1] > 0 && av[13][1] > 0 && av[14][1] > 0 && av[15][1] > 0 && av[16][1] > 0 && av[17][1] > 0) {
                    que = que + 100;
                }
            }
            for (let i = 1; i < 19; i++) {
                if (
                    (i > 11 || av[i - 1][0] > 0) &&
                    av[i][1] > 0 &&
                    av[i + 1][1] > 0 &&
                    av[i + 2][1] > 0 &&
                    av[i + 3][1] > 0 &&
                    av[i + 4][1] > 0 &&
                    av[i + 5][1] > 0
                ) {
                    que = que + 15 * (24 - i);
                    break;
                }
            }
        }
        //////////////////////////////////////////////////////////////
        if (this.pipcom < 281 && this.pipcom > 220) {
            //    let que11: number[] = [30, 80, 80, 80, 80, 80, 50, 50, 50, 30, 30, 30, 90, 90, 80, 70, 60, 50, 40, 30, 20, 30, 25, 25, 0, 0];
            //    let que12: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 10, 40, 30, 30, 40, 40, 30, 20, 10, 20, 20, 15, 0, 0];
            let que21: number[] = [30, 80, 80, 80, 80, 80, 50, 50, 50, 30, 30, 30, 70, 70, 70, 70, 80, 80, 70, 70, 70, 65, 65, 5, 0, 0];
            let que22: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 10, 10, 10, 10, 15, 15, 10, 20, 10, 20, 20, 15, 0, 0];
            if (av[13][0] > 0 || av[14][0] > 0 || av[15][0] > 0 || av[16][0] > 0 || av[17][0] > 0) {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + que21[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + que22[i];
                        }
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 33 * (24 - i);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + this.que11[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + this.que12[i];
                        }
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 40 * (24 - i);
                        break;
                    }
                }
            }
        }
        //////////////////////////////////////////////////////////////////////////////
        if (this.pipcom < 221 && this.pipcom > 155) {
            // = [30, 90, 90, 100, 120, 120, 80, 70, 70, 50, 50, 50, 90, 90, 80, 70, 60, 50, 40, 30, 30, 40, 45, 45, 0, 0];
            //    let que12: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 0, 0, 10, 10, 10, 40, 30, 20, 30, 20, 15, 0, 0];
            let que21: number[] = [30, 60, 70, 90, 100, 100, 60, 60, 60, 60, 60, 60, 50, 50, 50, 60, 60, 80, 70, 70, 70, 65, 65, 5, 0, 0];
            let que22: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 0, 0, 10, 15, 15, 40, 30, 30, 40, 20, 15, 0, 0];
            if (av[13][0] > 0 || av[14][0] > 0 || av[15][0] > 0 || av[16][0] > 0 || av[17][0] > 0) {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + que21[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + que22[i];
                        }
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 20;
                    }
                    if (i < 12 && i > 5) {
                        que = que + av[i][1] * 30;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 50 * (24 - i);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + this.que11[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + this.que12[i];
                        }
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 20;
                    }
                    if (i < 12 && i > 5) {
                        que = av[i][1] * 30;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 33 * (24 - i);
                        break;
                    }
                }
            }
        }
        if (this.pipcom < 156 && this.pipcom > 115) {
            //    let que11: number[] = [30, 100, 100, 100, 120, 120, 70, 70, 70, 50, 50, 50, 40, 45, 50, 55, 60, 65, 70, 70, 70, 77, 75, 75, 0, 0];
            //    let que12: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 0, 0, 0, 0, 0, 20, 10, 20, 30, 20, 15, 0, 0];
            let que21: number[] = [30, 70, 70, 70, 70, 70, 60, 60, 60, 60, 60, 60, 10, 20, 20, 40, 50, 60, 70, 70, 70, 75, 75, 75, 0, 0];
            let que22: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 0, 0, 0, 0, 0, 20, 10, 30, 40, 30, 35, 0, 0];
            if (av[18][0] > 0 || av[19][0] > 0 || av[20][0] > 0 || av[21][0] > 0 || av[22][0] > 0 || av[23][0] > 0) {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + que21[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + que22[i];
                        }
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 25;
                    }
                    if (i < 12 && i > 5) {
                        que = que + av[i][1] * 45;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 150;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + this.que11[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + this.que12[i];
                        }
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 25;
                    }
                    if (i < 12 && i > 5) {
                        que = av[i][1] * 45;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 150;
                        break;
                    }
                }
            }
            que = que + av[24][1] * 80;
        }
        if (this.pipcom < 116 && this.pipcom > 65) {
            //    let que11: number[] = [30, 80, 80, 80, 80, 80, 70, 70, 70, 50, 50, 50, 0, 0, 0, 0, 0, 0, 70, 70, 70, 77, 75, 75, 0, 0];
            //    let que12: number[] = [3, 7, 11, 19, 28, 35, 50, 50, 50, 50, 50, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 5, 0, 0];
            let que21: number[] = [30, 70, 70, 70, 70, 70, 60, 60, 60, 60, 60, 60, 0, 0, 0, 0, 0, 0, 10, 15, 20, 25, 25, 35, 0, 0];
            let que22: number[] = [20, 20, 20, 25, 25, 25, 30, 30, 30, 30, 30, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 5, 0, 0];
            if (this.pippla > this.pipcom) {
                for (let i = 0; i < 26; i++) {
                    if (i > this.lastpool) {
                        if (av[i][1] > 0) {
                            que = que + que21[i];
                        }
                        if (av[i][1] > 1) {
                            que = que + que22[i];
                        }
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 25;
                    }
                    if (i < 12 && i > 5) {
                        que = que + av[i][1] * 80;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 150;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < 26; i++) {
                    //if(i>this.lastpool)
                    if (av[i][1] > 0) {
                        que = que + this.que11[i];
                    }
                    if (av[i][1] > 1) {
                        que = que + this.que12[i];
                    }
                    if (i < 6) {
                        que = que + av[i][1] * 25;
                    }
                    if (i < 12 && i > 5) {
                        que = av[i][1] * 45;
                    }
                }
                for (let i = 1; i < 19; i++) {
                    if (
                        (i > 11 || av[i - 1][0] > 0) &&
                        av[i][1] > 0 &&
                        av[i + 1][1] > 0 &&
                        av[i + 2][1] > 0 &&
                        av[i + 3][1] > 0 &&
                        av[i + 4][1] > 0 &&
                        av[i + 5][1] > 0
                    ) {
                        que = que + 150;
                        break;
                    }
                }
            }
            que = que + av[24][1] * 100;
        }
        if (this.pipcom < 66) {
            let que11: number[] = [1, 2, 3, 4, 5, 6, 10, 20, 30, 40, 50, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let que12: number[] = [0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (let i = 0; i < 26; i++) {
                if (av[i][1] > 0) {
                    que = que + que11[i];
                }
                if (av[i][1] > 1) {
                    que = que + que12[i];
                }
                if (i < 12 && i > 5) {
                    que = que + av[i][1] * 45;
                }
                if (i < 6) {
                    que = que + av[i][1] * 10;
                }
            }
            que = que + av[24][1] * 120;
        }
        return que;
    }

    takequefduUnPlayed(av: avidu) {
        let que = 0;
        que = this.takequefdu(av.avi);
        return que;
    }

    CheckReadyToExit1(a: number[][]) {
        for (let i = 0; i < 6; i++) {
            if (a[i][1] > 0) {
                return false;
            }
        }
        for (let i = 12; i < 24; i++) {
            if (a[i][1] > 0) {
                return false;
            }
        }
        return true;
    }

    AviableGulbara() {
        let rexit: boolean = this.CheckReadyToExit(2);
        this.full();
        this.s = 0;
        this.s2 = 0;
        this.sall = 0;
        /////////////////////////////////////////ako ne e 4ift///////////////////////////////////////
        ///////////////////////////////////////zarejda sled purvi hod////////////////////////////////
        if (this.dice1 !== this.dice2 && ((this.mdice1 > 0 && this.dice1 === this.mdice1) || (this.mdice2 > 0 && this.dice1 === this.mdice2))) {
            for (let i = 0; i < 26; i++) {
                if (
                    i !== 25 &&
                    i !== 24 &&
                    i !== 12 &&
                    this.table[i][1] > 0 &&
                    this.table[(i + this.dice1) % 24][0] < 1 &&
                    (i > 12 || i + this.dice1 < 12)
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][(i + this.dice1) % 24][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = (i + this.dice1) % 24;
                    this.s++;
                }
                if (i === 12 && this.table[i][1] > 0 && this.table[(i + this.dice1) % 24][0] < 1 && this.table[25][1] === 0) {
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][(i + this.dice1) % 24][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = (i + this.dice1) % 24;
                    this.s++;
                }
                if (i === 25 && this.table[i][1] > 0 && this.table[(i + this.dice1 - 13) % 24][0] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][(i + this.dice1 - 13) % 24][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = (i + this.dice1 - 13) % 24;
                    this.s++;
                }
                if (rexit) {
                    if (this.table[i][1] > 0 && i + this.dice1 === 12) {
                        for (let j = 0; j < 26; j++) {
                            this.avi[this.s][j][0] = this.table[j][0];
                            this.avi[this.s][j][1] = this.table[j][1];
                        }
                        this.avi[this.s][i][1]--;
                        this.avi[this.s][24][1]++;
                        this.plays1[this.s][0] = i;
                        this.plays1[this.s][1] = 25;
                        this.s++;
                    }
                    if (
                        i !== 24 &&
                        this.table[i][1] > 0 &&
                        i + this.dice1 > 12 &&
                        this.table[i - 1][1] === 0 &&
                        this.table[i - 2][1] === 0 &&
                        this.table[i - 3][1] === 0 &&
                        this.table[i - 4][1] === 0 &&
                        this.table[i - 5][1] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.avi[this.s][j][0] = this.table[j][0];
                            this.avi[this.s][j][1] = this.table[j][1];
                        }
                        this.avi[this.s][i][1]--;
                        this.avi[this.s][24][1]++;
                        this.plays1[this.s][0] = i;
                        this.plays1[this.s][1] = 25;
                        this.s++;
                    }
                }
            }
        }
        ///////////////////////////////////////////
        if (this.dice1 !== this.dice2 && ((this.mdice1 > 0 && this.dice2 === this.mdice1) || (this.mdice2 > 0 && this.dice2 === this.mdice2))) {
            for (let i = 0; i < 26; i++) {
                if (
                    i !== 25 &&
                    i !== 24 &&
                    i !== 12 &&
                    this.table[i][1] > 0 &&
                    this.table[(i + this.dice2) % 24][0] < 1 &&
                    (i > 12 || i + this.dice2 < 12)
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][(i + this.dice2) % 24][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = (i + this.dice2) % 24;
                    this.s2++;
                }
                if (i === 12 && this.table[i][1] > 0 && this.table[(i + this.dice2) % 24][0] < 1 && this.table[25][1] === 0) {
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][(i + this.dice2) % 24][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = (i + this.dice2) % 24;
                    this.s2++;
                }
                if (i === 25 && this.table[i][1] > 0 && this.table[(i + this.dice2 - 13) % 24][0] < 1) {
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][(i + this.dice2 - 13) % 24][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = (i + this.dice2 - 13) % 24;
                    this.s2++;
                }
                if (rexit) {
                    if (this.table[i][1] > 0 && i + this.dice2 === 12) {
                        for (let j = 0; j < 26; j++) {
                            this.avi2[this.s2][j][0] = this.table[j][0];
                            this.avi2[this.s2][j][1] = this.table[j][1];
                        }
                        this.avi2[this.s2][i][1]--;
                        this.avi2[this.s2][24][1]++;
                        this.plays2[this.s2][0] = i;
                        this.plays2[this.s2][1] = 25;
                        this.s2++;
                    }
                    if (
                        i !== 24 &&
                        i + this.dice2 > 12 &&
                        this.table[i][1] > 0 &&
                        this.table[i - 1][1] === 0 &&
                        this.table[i - 2][1] === 0 &&
                        this.table[i - 3][1] === 0 &&
                        this.table[i - 4][1] === 0 &&
                        this.table[i - 5][1] === 0
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.avi2[this.s2][j][0] = this.table[j][0];
                            this.avi2[this.s2][j][1] = this.table[j][1];
                        }
                        this.avi2[this.s2][i][1]--;
                        this.avi2[this.s2][24][1]++;
                        this.plays2[this.s2][0] = i;
                        this.plays2[this.s2][1] = 25;
                        this.s2++;
                    }
                }
            }
        }
        ///////////////////////////////////zarejda sled vtori hod////////////////////////////////////
        if (this.dice1 !== this.dice2 && this.mdice1 > 0 && this.mdice2 > 0) {
            for (let l = 0; l < this.s; l++) {
                rexit = this.CheckReadyToExit1(this.avi[l]);
                for (let i = 0; i < 26; i++) {
                    if (
                        i !== 25 &&
                        i !== 24 &&
                        i !== 12 &&
                        (i > 12 || i + this.dice2 < 12) &&
                        this.avi[l][i][1] > 0 &&
                        this.avi[l][(i + this.dice2) % 24][0] < 1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice2) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice2) % 24;
                        this.sall++;
                    }
                    if (i === 12 && this.avi[l][i][1] > 0 && this.avi[l][(i + this.dice2) % 24][0] < 1 && this.avi[l][25][1] === 0) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice2) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice2) % 24;
                        this.sall++;
                    }
                    if (i === 25 && this.avi[l][i][1] > 0 && this.avi[l][(i + this.dice2 - 13) % 24][0] < 1) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice2 - 13) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice2 - 13) % 24;
                        this.sall++;
                    }
                    if (rexit) {
                        if (this.avi[l][i][1] > 0 && i + this.dice2 === 12) {
                            for (let j = 0; j < 26; j++) {
                                this.aviall[this.sall][j][0] = this.avi[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi[l][j][1];
                            }
                            this.aviall[this.sall][i][1]--;
                            this.aviall[this.sall][24][1]++;
                            this.plays[this.sall][0][0] = this.plays1[l][0];
                            this.plays[this.sall][0][1] = this.plays1[l][1];
                            this.plays[this.sall][1][0] = i;
                            this.plays[this.sall][1][1] = 25;
                            this.sall++;
                        }
                        if (
                            i !== 24 &&
                            i + this.dice2 > 12 &&
                            this.avi[l][i][1] > 0 &&
                            this.avi[l][i - 1][1] === 0 &&
                            this.avi[l][i - 2][1] === 0 &&
                            this.avi[l][i - 3][1] === 0 &&
                            this.avi[l][i - 4][1] === 0 &&
                            this.avi[l][i - 5][1] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.aviall[this.sall][j][0] = this.avi[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi[l][j][1];
                            }
                            this.aviall[this.sall][i][1]--;
                            this.aviall[this.sall][24][1]++;
                            this.plays[this.sall][0][0] = this.plays1[l][0];
                            this.plays[this.sall][0][1] = this.plays1[l][1];
                            this.plays[this.sall][1][0] = i;
                            this.plays[this.sall][1][1] = 25;
                            this.sall++;
                        }
                    }
                }
            }
            //////////////////////////////////////////////////////////
            for (let l = 0; l < this.s2; l++) {
                rexit = this.CheckReadyToExit1(this.avi2[l]);
                for (let i = 0; i < 26; i++) {
                    if (
                        i !== 25 &&
                        i !== 24 &&
                        i !== 12 &&
                        (i > 12 || i + this.dice1 < 12) &&
                        this.avi2[l][i][1] > 0 &&
                        this.avi2[l][(i + this.dice1) % 24][0] < 1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice1) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice1) % 24;
                        this.sall++;
                    }
                    if (i === 12 && this.avi2[l][i][1] > 0 && this.avi2[l][(i + this.dice1) % 24][0] < 1 && this.avi2[l][25][1] === 0) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice1) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice1) % 24;
                        this.sall++;
                    }
                    if (i === 25 && this.avi2[l][i][1] > 0 && this.avi2[l][(i + this.dice1 - 13) % 24][0] < 1) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][(i + this.dice1 - 13) % 24][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = (i + this.dice1 - 13) % 24;
                        this.sall++;
                    }
                    if (rexit) {
                        if (this.avi2[l][i][1] > 0 && i + this.dice1 === 12) {
                            for (let j = 0; j < 26; j++) {
                                try {
                                    this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                    this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                                } catch (e) {
                                    console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                                }
                            }
                            this.aviall[this.sall][i][1]--;
                            this.aviall[this.sall][24][1]++;
                            this.plays[this.sall][0][0] = this.plays2[l][0];
                            this.plays[this.sall][0][1] = this.plays2[l][1];
                            this.plays[this.sall][1][0] = i;
                            this.plays[this.sall][1][1] = 25;
                            this.sall++;
                        }
                        if (
                            i + this.dice1 > 12 &&
                            i !== 24 &&
                            this.avi2[l][i][1] > 0 &&
                            this.avi2[l][i - 1][1] === 0 &&
                            this.avi2[l][i - 2][1] === 0 &&
                            this.avi2[l][i - 3][1] === 0 &&
                            this.avi2[l][i - 4][1] === 0 &&
                            this.avi2[l][i - 5][1] === 0
                        ) {
                            for (let j = 0; j < 26; j++) {
                                try {
                                    this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                    this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                                } catch (e) {
                                    console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                                }
                            }
                            this.aviall[this.sall][i][1]--;
                            this.aviall[this.sall][24][1]++;
                            this.plays[this.sall][0][0] = this.plays2[l][0];
                            this.plays[this.sall][0][1] = this.plays2[l][1];
                            this.plays[this.sall][1][0] = i;
                            this.plays[this.sall][1][1] = 25;
                            this.sall++;
                        }
                    }
                }
            }
            //////////////////////////////////////////
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////smiata veroiatnostta daden pool da bude zatapen//////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    PosToBeTaped(place: number, av: number[][]) {
        let pos = 0;
        if (place >= 1 && av[place - 1][0] > 0) {
            pos = pos + 6;
        } //(1)
        if (place >= 2 && av[place - 2][0] > 0) {
            pos = pos + 6;
        } //(2)
        if (place >= 3 && av[place - 3][0] > 0) {
            pos = pos + 6;
        } //(3)
        if (place >= 4 && av[place - 4][0] > 0) {
            pos = pos + 6;
        } //(4)
        if (place >= 5 && av[place - 5][0] > 0) {
            pos = pos + 6;
        } //(5)
        if (place >= 6 && av[place - 6][0] > 0) {
            pos = pos + 6;
        } //(6)
        if (place >= 2 && av[place - 2][0] > 0 && av[place - 1][1] < 2 && av[place - 1][0] > -1) {
            pos++;
        } //1:1(2)
        if (
            place >= 3 &&
            av[place - 3][0] > 0 &&
            ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 2][1] < 2 && av[place - 2][0] > -1))
        ) {
            pos = pos + 2;
        } //1:2(3)
        if (place >= 3 && av[place - 3][0] > 0 && av[place - 1][1] < 2 && av[place - 1][0] > -1 && av[place - 2][1] < 2 && av[place - 2][0] > -1) {
            pos++;
        } //1:1(3)
        if (place >= 4 && av[place - 4][0] > 0 && av[place - 2][1] < 2 && av[place - 2][0] > -1) {
            pos++;
        } //2:2(4)
        if (
            place >= 4 &&
            av[place - 4][0] > 0 &&
            ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 3][1] < 2 && av[place - 3][0] > -1))
        ) {
            pos = pos + 2;
        } //1:3(4)
        if (
            place >= 4 &&
            av[place - 4][0] > 0 &&
            av[place - 1][1] < 2 &&
            av[place - 1][0] > -1 &&
            av[place - 2][1] < 2 &&
            av[place - 2][0] > -1 &&
            av[place - 3][1] < 2 &&
            av[place - 3][0] > -1
        ) {
            pos++;
        } //1:1(4)
        if (
            place >= 5 &&
            av[place - 5][0] > 0 &&
            ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
        ) {
            pos = pos + 2;
        } //1:4(5)
        if (
            place >= 5 &&
            av[place - 5][0] > 0 &&
            ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 3][1] < 2 && av[place - 3][0] > -1))
        ) {
            pos = pos + 2;
        } //2:3(5)
        if (
            place >= 6 &&
            av[place - 6][0] > 0 &&
            ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
        ) {
            pos = pos + 2;
        } //1:5(6)
        if (
            place >= 6 &&
            av[place - 6][0] > 0 &&
            ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
        ) {
            pos = pos + 2;
        } //2:4(6)
        if (place >= 6 && av[place - 6][0] > 0 && av[place - 3][1] < 2 && av[place - 3][0] > -1) {
            pos++;
        } //3:3(6)
        if (place >= 6 && av[place - 6][0] > 0 && av[place - 2][1] < 2 && av[place - 2][0] > -1 && av[place - 4][1] < 2 && av[place - 4][0] > -1) {
            pos++;
        } //2:2(6)
        if (
            place >= 7 &&
            av[place - 7][0] > 0 &&
            ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
        ) {
            pos = pos + 2;
        } //1:6(7)
        if (
            place >= 7 &&
            av[place - 7][0] > 0 &&
            ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
        ) {
            pos = pos + 2;
        } //2:5(7)
        if (
            place >= 7 &&
            av[place - 7][0] > 0 &&
            ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
        ) {
            pos = pos + 2;
        } //3:4(7)
        if (place >= 8 && av[place - 8][0] > 0 && av[place - 4][1] < 2 && av[place - 4][0] > -1) {
            pos++;
        } //4:4(8)
        if (
            place >= 8 &&
            av[place - 8][0] > 0 &&
            av[place - 2][1] < 2 &&
            av[place - 2][0] > -1 &&
            av[place - 4][1] < 2 &&
            av[place - 4][0] > -1 &&
            av[place - 6][1] < 2 &&
            av[place - 6][0] > -1
        ) {
            pos++;
        } //2:2(8)
        if (
            place >= 8 &&
            av[place - 8][0] > 0 &&
            ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
        ) {
            pos = pos + 2;
        } //2:6(8)
        if (
            place >= 8 &&
            av[place - 8][0] > 0 &&
            ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
        ) {
            pos = pos + 2;
        } //3:5(8)
        if (place >= 9 && av[place - 9][0] > 0 && av[place - 3][1] < 2 && av[place - 3][0] > -1 && av[place - 6][1] < 2 && av[place - 6][0] > -1) {
            pos++;
        } //3:3(9)
        if (
            place >= 9 &&
            av[place - 9][0] > 0 &&
            ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
        ) {
            pos = pos + 2;
        } //3:6(9)
        if (
            place >= 9 &&
            av[place - 9][0] > 0 &&
            ((av[place - 4][1] < 2 && av[place - 4][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
        ) {
            pos = pos + 2;
        } //4:5(9)
        if (place >= 10 && av[place - 10][0] > 0 && av[place - 5][1] < 2 && av[place - 5][0] > -1) {
            pos++;
        } //5:5(10)
        if (
            place >= 10 &&
            av[place - 10][0] > 0 &&
            ((av[place - 4][1] < 2 && av[place - 4][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
        ) {
            pos = pos + 2;
        } //4:6(10)
        if (
            place >= 11 &&
            av[place - 11][0] > 0 &&
            ((av[place - 5][1] < 2 && av[place - 5][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
        ) {
            pos = pos + 2;
        } //5:6(11)
        if (
            place >= 12 &&
            av[place - 12][0] > 0 &&
            av[place - 3][1] < 2 &&
            av[place - 3][0] > -1 &&
            av[place - 6][1] < 2 &&
            av[place - 6][0] > -1 &&
            av[place - 9][1] < 2 &&
            av[place - 9][0] > -1
        ) {
            pos++;
        } //3:3(12)
        if (place >= 12 && av[place - 12][0] > 0 && av[place - 4][1] < 2 && av[place - 4][0] > -1 && av[place - 8][1] < 2 && av[place - 8][0] > -1) {
            pos++;
        } //4:4(12)
        if (place >= 12 && av[place - 12][0] > 0 && av[place - 6][1] < 2 && av[place - 6][0] > -1) {
            pos++;
        } //6:6(12)
        if (
            place >= 15 &&
            av[place - 15][0] > 0 &&
            av[place - 5][1] < 2 &&
            av[place - 5][0] > -1 &&
            av[place - 10][1] < 2 &&
            av[place - 10][0] > -1
        ) {
            pos++;
        } //5:5(15)
        if (
            place >= 16 &&
            av[place - 16][0] > 0 &&
            av[place - 4][1] < 2 &&
            av[place - 4][0] > -1 &&
            av[place - 8][1] < 2 &&
            av[place - 8][0] > -1 &&
            av[place - 12][1] < 2 &&
            av[place - 12][0] > -1
        ) {
            pos++;
        } //4:4(16)
        if (
            place >= 18 &&
            av[place - 18][0] > 0 &&
            av[place - 6][1] < 2 &&
            av[place - 6][0] > -1 &&
            av[place - 12][1] < 2 &&
            av[place - 12][0] > -1
        ) {
            pos++;
        } //6:6(18)
        if (
            place >= 20 &&
            av[place - 20][0] > 0 &&
            av[place - 5][1] < 2 &&
            av[place - 5][0] > -1 &&
            av[place - 10][1] < 2 &&
            av[place - 10][0] > -1 &&
            av[place - 15][1] < 2 &&
            av[place - 15][0] > -1
        ) {
            pos++;
        } //5:5(20)
        //6:6-ne stava malko e poleto
        return pos;
    }

    takequef(av: number[][]): number {
        let pip1 = this.GetPipCount(1); //pla
        let pip2 = this.GetPipCount(2); //comp
        let t = 0;
        let que = 0;
        let qpla = 0;
        let qcom = 0;
        ////////////////////////////////////pomo6tni//////////////////////////////////////////////
        for (let i = 0; i < 26; i++) {
            if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                if (i > 17) {
                    qcom = 1;
                }
                if (i > 11) {
                    qcom = 2;
                }
                if (i < 12 && i > 8) {
                    qcom = 3;
                }
                if (i < 9 && i > 5) {
                    qcom = 4;
                }
                if (i < 6 && i > 3) {
                    qcom = 5;
                }
                if (i === 3) {
                    qcom = 6;
                }
                if (i === 2) {
                    qcom = 7;
                }
                if (i === 1) {
                    qcom = 8;
                }
            }
            if (av[i][1] === -1) {
                if (i < 5) {
                    qpla = 1;
                }
                if (i < 12) {
                    qpla = 2;
                }
                if (i > 11 && i < 15) {
                    qpla = 3;
                }
                if (i < 18 && i > 14) {
                    qpla = 4;
                }
                if (i < 20 && i > 17) {
                    qpla = 5;
                }
                if (i === 20) {
                    qpla = 6;
                }
                if (i === 21) {
                    qpla = 7;
                }
                if (i === 22) {
                    qpla = 8;
                }
                if (i === 23) {
                    qpla = 9;
                }
            }
        }
        //////////////////////////////////// na4alni //////////////////////////////////////////////
        let quef11: number[] = [1, 3, 3, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 8, 8, 8, 10, 10, 10, 80, 0, 0];
        let quef12: number[] = [0, 0, 6, 8, 10, 12, 14, 30, 40, 50, 60, 70, 80, 90, 90, 80, 80, 80, 70, 60, 40, 30, 20, 20, 10, 0];
        let quef13: number[] = [0, 0, 4, 4, 4, 4, 6, 6, 20, 30, 40, 50, 60, 100, 120, 140, 170, 200, 250, 500, 800, 1200, 2000, 4000, 7000, 15000];
        /////////////////////////////////////// 2-ri  /////////////////////////////////////////////
        let quef21: number[] = [1, 2, 3, 3, 5, 5, 5, 5, 5, 5, 10, 10, 10, 10, 10, 10, 12, 12, 25, 20, 25, 40, 50, 100, 0, 0];
        let quef22: number[] = [-10, 0, 0, 6, 8, 10, 14, 30, 40, 50, 60, 70, 80, 90, 100, 120, 100, 100, 80, 60, 40, 30, 20, 20, 10, 0];
        let quef23: number[] = [0, 0, 4, 4, 4, 4, 6, 6, 40, 50, 60, 70, 80, 100, 120, 140, 170, 200, 250, 500, 1200, 1800, 2500, 4000, 7000, 15000];
        ///////////////////////////////////////3-ti-normalen///////////////////////////////////////////////////
        let quef311: number[] = [1, 5, 5, 5, 5, 5, 10, 10, 10, 10, 10, 10, 15, 15, 15, 20, 20, 20, 50, 50, 60, 70, 80, 120, 0, 0];
        let quef321: number[] = [-15, -10, 0, 0, 0, 10, 14, 30, 40, 50, 60, 70, 80, 90, 100, 120, 100, 100, 80, 60, 40, 30, 20, 20, 10, 0];
        let quef331: number[] = [
            0, 0, 10, 15, 15, 20, 20, 25, 40, 50, 60, 70, 80, 100, 120, 140, 170, 200, 250, 500, 1200, 1800, 2500, 4000, 7000, 15000,
        ];
        ///////////////////////////////////////3-ti-agresiven////////////////////////////////////////////////////
        let quef312: number[] = [1, 1, 1, 2, 2, 2, 5, 5, 5, 5, 5, 5, 10, 10, 10, 10, 12, 12, 20, 20, 30, 30, 40, 80, 0, 0];
        let quef322: number[] = [-15, -10, 0, 0, 2, 5, 14, 60, 70, 100, 100, 120, 120, 140, 180, 150, 130, 120, 100, 60, 40, 30, 30, 20, 10, 0];
        let quef332: number[] = [
            0, 0, 14, 14, 24, 24, 36, 36, 140, 150, 160, 170, 180, 200, 220, 240, 270, 300, 350, 700, 1000, 1500, 2000, 4000, 7000, 15000,
        ];
        ///////////////////////////////////////3-ti-defansiven///////////////////////////////////////////////////
        let quef313: number[] = [1, 10, 10, 10, 10, 10, 15, 15, 15, 15, 15, 15, 30, 30, 30, 35, 40, 45, 70, 80, 90, 100, 120, 180, 0, 0];
        let quef323: number[] = [-15, -10, 0, 6, 8, 10, 14, 30, 40, 50, 60, 70, 80, 90, 100, 120, 100, 100, 80, 60, 40, 30, 20, 20, 10, 0];
        let quef333: number[] = [
            0, 0, 15, 15, 20, 20, 20, 30, 40, 50, 60, 70, 80, 100, 120, 140, 170, 200, 250, 500, 1200, 1800, 2500, 4000, 7000, 15000,
        ];
        //////////////////////////////////////4-ti-normalen //////////////////////////////////////////
        let quef411: number[] = [1, 5, 5, 5, 5, 5, 10, 10, 10, 10, 10, 10, 15, 15, 15, 20, 20, 20, 50, 50, 60, 70, 80, 120, 0, 0];
        let quef421: number[] = [-30, -25, -20, -10, -10, 0, 0, 0, 10, 20, 30, 40, 80, 90, 100, 120, 100, 100, 80, 60, 40, 30, 20, 20, 10, 0];
        let quef431: number[] = [
            0, 0, 5, 5, 5, 10, 10, 10, 20, 20, 20, 20, 30, 80, 100, 120, 150, 180, 250, 500, 1200, 1800, 2500, 4000, 7000, 15000,
        ];
        ///////////////////////////////////////4-ti-agresiven////////////////////////////////////////////////////
        let quef412: number[] = [1, 1, 1, 2, 2, 2, 5, 5, 5, 5, 5, 5, 10, 10, 10, 10, 12, 12, 20, 20, 30, 30, 40, 80, 0, 0];
        let quef422: number[] = [-30, -25, -20, -10, -5, 0, 0, 0, 25, 50, 100, 120, 120, 140, 100, 60, 50, 50, 40, 20, 20, 20, 20, 20, 10, 0];
        let quef432: number[] = [
            0, 0, 14, 14, 24, 24, 66, 66, 140, 150, 160, 170, 180, 200, 220, 240, 270, 300, 350, 500, 1200, 1800, 2000, 4000, 7000, 15000,
        ];
        ///////////////////////////////////////4-ti-defansiven///////////////////////////////////////////////////
        let quef413: number[] = [1, 10, 10, 10, 10, 10, 25, 25, 25, 25, 25, 25, 30, 30, 30, 35, 40, 45, 50, 60, 90, 130, 160, 280, 0, 0];
        let quef423: number[] = [-30, -20, -15, -10, -5, 0, 0, 0, 10, 20, 30, 40, 50, 60, 70, 80, 80, 70, 60, 50, 40, 30, 20, 20, 10, 0];
        let quef433: number[] = [0, 0, 5, 5, 10, 10, 10, 20, 20, 20, 25, 25, 55, 55, 70, 70, 70, 100, 150, 250, 1200, 1800, 2500, 4000, 7000, 15000];
        ///////////////////////////////////////5-ti normalen//////////////////////////////////////////
        let quef511: number[] = [1, 10, 10, 10, 10, 10, 25, 25, 25, 25, 25, 25, 60, 60, 60, 65, 70, 70, 90, 90, 90, 130, 160, 280, 0, 0];
        let quef521: number[] = [-80, -70, -60, -50, -40, -30, -20, -10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let quef531: number[] = [0, 0, 5, 5, 10, 10, 10, 20, 20, 20, 25, 25, 55, 55, 70, 70, 70, 200, 350, 450, 1000, 1500, 3000, 4000, 7000, 15000];
        ///////////////////////////////////////5-ti za iz4akvane//////////////////////////////////////
        let quef512: number[] = [1, 10, 10, 10, 10, 10, 25, 25, 25, 25, 25, 25, 60, 60, 60, 65, 70, 70, 90, 90, 90, 130, 160, 280, 0, 0];
        let quef522: number[] = [-80, -70, -60, -50, -40, -30, -20, -10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let quef532: number[] = [
            0, 20, 35, 35, 50, 50, 50, 120, 120, 120, 125, 125, 155, 155, 270, 270, 270, 400, 450, 550, 2000, 2500, 4000, 6000, 7000, 15000,
        ];
        //////////////////////////////////////5-ti s hvanat izvun poleto////////////////////////////
        //    let quef513: number[] = [1, 10, 10, 10, 10, 10, 25, 25, 25, 25, 25, 25, 60, 60, 60, 65, 70, 70, 90, 90, 90, 130, 160, 280, 0, 0];
        //    let quef523: number[] = [-80, -70, -60, -50, -40, -30, -20, -10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        //    let quef533: number[] = [0, 0, 5, 5, 10, 10, 10, 20, 20, 20, 25, 25, 55, 55, 70, 70, 70, 200, 350, 450, 1000, 1500, 3000, 4000, 7000, 15000];
        //////////////////////////////////////5-ti s hvanat v poleto/////////////////////////////////
        let quef514: number[] = [1, 10, 10, 10, 10, 10, 25, 25, 25, 25, 25, 25, 60, 60, 60, 65, 70, 70, 90, 90, 90, 130, 160, 280, 0, 0];
        let quef524: number[] = [-80, -70, -60, -50, -40, -30, -20, -10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let quef534: number[] = [0, 0, 5, 5, 10, 10, 10, 20, 20, 20, 25, 25, 55, 55, 70, 70, 70, 200, 350, 450, 2000, 2500, 4000, 5000, 7000, 15000];
        ///////////////////////////////////////izchisliavaneto////////////////////////////////////////
        for (let i = 0; i < 26; i++) {
            if (pip2 >= 300) {
                if (av[i][1] > 1 && av[i][0] === 0) {
                    que = que + quef12[25 - i];
                }
                if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                    que = que + quef13[25 - i];
                }
                if (av[i][1] === 1 && av[i][0] === 0) {
                    que = que - quef11[i] * (this.PosToBeTaped(i, av) - 1);
                    t = 1;
                }
                if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                    que = que + this.MyMathfloor(quef12[25 - i] / 3);
                }
                if (av[i][1] > 3) {
                    que = que - this.MyMathfloor(quef12[25 - i] / 2.8) * (av[i][1] - 3);
                }
                if (i === 25) {
                    que = que - av[i][1] * 3;
                }
                if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                    que = que + quef13[25 - i] * this.MyMathfloor((av[i][1] - 1) / 5);
                }
            }
            ///////////////////////////////////////2-ri//////////////////////////////////////////////////
            if (pip2 >= 240 && pip2 < 300) {
                if (av[i][1] > 1 && av[i][0] === 0) {
                    que = que + quef22[25 - i];
                }
                if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                    que = que + quef23[25 - i];
                    t = 1;
                }
                if (av[i][1] === 1 && av[i][0] === 0) {
                    que = que - quef21[i] * this.PosToBeTaped(i, av);
                }
                if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                    que = que + this.MyMathfloor(quef22[25 - i] / 3);
                }
                if (av[i][1] > 3) {
                    que = que - this.MyMathfloor(quef22[25 - i] / 3) * (av[i][1] - 3);
                }
                if (i === 25) {
                    que = que - av[i][1] * 7;
                }
                if (i === 25) {
                    if (pip1 - pip2 > 20 && t === 1) {
                        que = que + 100;
                    }
                }
                if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                    que = que + quef23[25 - i] * this.MyMathfloor((av[i][1] - 1) / 5);
                }
            }
            ////////////////////////////////////////3-ti///////////////////////////////////////////////////
            if (pip2 >= 180 && pip2 < 240) {
                ////////////////////////////////////////3-ti norm///////////////////////////////////////////////
                if (pip1 - pip2 <= 20 && pip2 - pip1 <= 20 && Math.abs(qpla - qcom) < 3) {
                    if (av[i][1] > 1 && av[i][0] === 0) {
                        que = que + quef321[25 - i];
                    }
                    if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef331[25 - i];
                        t = 1;
                    }
                    if (av[i][1] === 1 && av[i][0] === 0) {
                        que = que - quef311[i] * this.PosToBeTaped(i, av);
                    }
                    if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                        que = que + this.MyMathfloor(quef321[25 - i] / 3);
                    }
                    if (av[i][1] > 3) {
                        que = que - this.MyMathfloor(quef321[25 - i] / 3.5) * (av[i][1] - 3);
                    }
                    if (i === 25) {
                        que = que - av[i][1] * 15;
                    }
                    if (i === 25) {
                        if (pip1 - pip2 > 20 && t === 1) {
                            que = que + 100;
                        }
                    }
                    if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef331[25 - i] * this.MyMathfloor((av[i][1] - 1) / 5);
                    }
                    if (i === 25) {
                        for (let j = 0; j < 6; j++) {
                            if (av[j][0] === -1) {
                                for (let i1 = 0; i1 < j; i1++) {
                                    if (av[i1][1] > 0 && av[i1][0] === 0) {
                                        que = que - 10 * av[i1][1];
                                    }
                                }
                            }
                        }
                    }
                    if (i === 25) {
                        if (pip1 - pip2 > 20 && t === 1) {
                            que = que + 100;
                        }
                    }
                }
                ///////////////////////////////////////3-ti agresive/////////////////////////////////////////////
                else {
                    if ((pip2 - pip1 > 20 && qcom === 0) || qpla - qcom > 3) {
                        if (av[i][1] > 1 && av[i][0] === 0) {
                            que = que + quef322[25 - i];
                        }
                        if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef332[25 - i];
                            t = 1;
                        }
                        if (av[i][1] === 1 && av[i][0] === 0) {
                            que = que - quef312[i] * this.PosToBeTaped(i, av);
                        }
                        if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                            que = que + this.MyMathfloor(quef322[25 - i] / 3);
                        }
                        if (av[i][1] > 3) {
                            que = que - this.MyMathfloor(quef322[25 - i] / 3.5) * (av[i][1] - 3);
                        }
                        if (i === 25) {
                            que = que - av[i][1] * 15;
                        }
                        if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef332[25 - i] * this.MyMathfloor((av[i][1] - 1) / 5);
                        }
                        if (i === 25) {
                            for (let j = 0; j < 6; j++) {
                                if (av[j][0] === -1) {
                                    for (let i1 = 0; i1 < j; i1++) {
                                        if (av[i1][1] > 0 && av[i1][0] === 0) {
                                            que = que - 10 * av[i1][1];
                                        }
                                    }
                                }
                            }
                        }
                        if (i === 25) {
                            if (pip1 - pip2 > 20 && t === 1) {
                                que = que + 100;
                            }
                        }
                    }
                    //////////////////////////////////////3-ti defencive///////////////////////////////////////////
                    else {
                        if (av[i][1] > 1 && av[i][0] === 0) {
                            que = que + quef323[25 - i];
                        }
                        if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef333[25 - i];
                            t = 1;
                        }
                        if (av[i][1] === 1 && av[i][0] === 0) {
                            que = que - quef313[i] * this.PosToBeTaped(i, av);
                        }
                        if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                            que = que + this.MyMathfloor(quef323[25 - i] / 3);
                        }
                        if (av[i][1] > 3) {
                            que = que - this.MyMathfloor(quef323[25 - i] / 3.5) * (av[i][1] - 3);
                        }
                        if (i === 25) {
                            que = que - av[i][1] * 15;
                        }
                        if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef333[25 - i] * this.MyMathfloor((av[i][1] - 1) / 5);
                        }
                        if (i === 25) {
                            for (let j = 0; j < 6; j++) {
                                if (av[j][0] === -1) {
                                    for (let i1 = 0; i1 < j; i1++) {
                                        if (av[i1][1] > 0 && av[i1][0] === 0) {
                                            que = que - 10 * av[i1][1];
                                        }
                                    }
                                }
                            }
                        }
                        if (i === 25) {
                            if (pip1 - pip2 > 20 && t === 1) {
                                que = que + 100;
                            }
                        }
                    }
                }
            }
            ///////////////////////////////////////////////4-ti///////////////////////////////////////
            if (pip2 >= 120 && pip2 < 180) {
                ////////////////////////////////////////4-ti norm///////////////////////////////////////////////
                if (pip1 - pip2 <= 20 && pip2 - pip1 <= 20 && Math.abs(qpla - qcom) < 3) {
                    if (av[i][1] > 1 && av[i][0] === 0) {
                        que = que + quef421[25 - i];
                    }
                    if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef431[25 - i];
                        t = 1;
                    }
                    if (av[i][1] === 1 && av[i][0] === 0) {
                        que = que - quef411[i] * this.PosToBeTaped(i, av);
                    }
                    if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                        que = que + this.MyMathfloor(quef421[25 - i] / 3);
                    }
                    if (av[i][1] > 3) {
                        que = que - this.MyMathfloor(quef421[25 - i] / 3.5) * (av[i][1] - 3);
                    }
                    if (i === 25) {
                        que = que - av[i][1] * 15;
                    }
                    if (i === 25) {
                        if (pip1 - pip2 > 20 && t === 1) {
                            que = que + 100;
                        }
                    }
                    if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef431[25 - i] * this.MyMathfloor((av[i][1] - 1) / 3);
                    }
                    if (i === 25) {
                        for (let j = 0; j < 6; j++) {
                            if (av[j][0] === -1) {
                                for (let i1 = 0; i1 < j; i1++) {
                                    if (av[i1][1] > 0 && av[i1][0] === 0) {
                                        que = que - 15 * av[i1][1];
                                    }
                                }
                            }
                        }
                    }
                }
                ///////////////////////////////////////4-ti agresive/////////////////////////////////////////////
                else {
                    if ((pip2 - pip1 > 20 && qcom === 0) || qpla - qcom > 3) {
                        if (av[i][1] > 1 && av[i][0] === 0) {
                            que = que + quef422[25 - i];
                        }
                        if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef432[25 - i];
                            t = 1;
                        }
                        if (av[i][1] === 1 && av[i][0] === 0) {
                            que = que - quef412[i] * this.PosToBeTaped(i, av);
                        }
                        if (i === 25) {
                            if (pip1 - pip2 > 20 && t === 1) {
                                que = que + 100;
                            }
                        }
                        if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                            que = que + this.MyMathfloor(quef422[25 - i] / 3);
                        }
                        if (av[i][1] > 3) {
                            que = que - this.MyMathfloor(quef422[25 - i] / 3.5) * (av[i][1] - 3);
                        }
                        if (i === 25) {
                            que = que - av[i][1] * 15;
                        }
                        if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef432[25 - i] * this.MyMathfloor((av[i][1] - 1) / 3);
                        }
                        if (i === 25) {
                            for (let j = 0; j < 6; j++) {
                                if (av[j][0] === -1) {
                                    for (let i1 = 0; i1 < j; i1++) {
                                        if (av[i1][1] > 0 && av[i1][0] === 0) {
                                            que = que - 20 * av[i1][1];
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //////////////////////////////////////4-ti defencive///////////////////////////////////////////
                    else {
                        if (av[i][1] > 1 && av[i][0] === 0) {
                            que = que + quef423[25 - i];
                        }
                        if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef433[25 - i];
                            t = 1;
                        }
                        if (i === 25) {
                            if (pip1 - pip2 > 20 && t === 1) {
                                que = que + 100;
                            }
                        }
                        if (av[i][1] === 1 && av[i][0] === 0) {
                            que = que - quef413[i] * this.PosToBeTaped(i, av);
                        }
                        if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                            que = que + this.MyMathfloor(quef423[25 - i] / 3);
                        }
                        if (av[i][1] > 3) {
                            que = que - this.MyMathfloor(quef423[25 - i] / 3.5) * (av[i][1] - 3);
                        }
                        if (i === 25) {
                            que = que - av[i][1] * 15;
                        }
                        if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef433[25 - i] * this.MyMathfloor((av[i][1] - 1) / 3);
                        }
                        if (i === 25) {
                            for (let j = 0; j < 6; j++) {
                                if (av[j][0] === -1) {
                                    for (let i1 = 0; i1 < j; i1++) {
                                        if (av[i1][1] > 0 && av[i1][0] === 0) {
                                            que = que - 15 * av[i1][1];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            //////////////////////////////////////////5-ti////////////////////////////////////////////////
            if (pip2 < 120) {
                ////////////////////////////////////////5-ti normalen/////////////////////////////////////////////////
                if (qpla === 0 && (qcom === 0 || (qcom < 4 && pip2 - pip1 > 20))) {
                    if (av[i][1] > 1 && av[i][0] === 0) {
                        que = que + quef521[25 - i];
                    }
                    if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef531[25 - i];
                        t = 1;
                    }
                    if (av[i][1] === 1 && av[i][0] === 0) {
                        que = que - quef511[i] * this.PosToBeTaped(i, av);
                    }
                    if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                        que = que + this.MyMathfloor(quef521[25 - i] / 3);
                    }
                    if (av[i][1] > 3) {
                        que = que - this.MyMathfloor(quef521[25 - i] / 3.5) * (av[i][1] - 3);
                    }
                    if (i === 25) {
                        que = que - av[i][1] * 35;
                    }
                    if (i === 25) {
                        if (pip1 - pip2 > 20 && t === 1) {
                            que = que + 100;
                        }
                    }
                    if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                        que = que + quef531[25 - i] * this.MyMathfloor((av[i][1] - 1) / 3);
                    }
                    if (i === 25) {
                        for (let j = 0; j < 6; j++) {
                            if (av[j][0] === -1) {
                                for (let i1 = 0; i1 < j; i1++) {
                                    if (av[i1][1] > 0 && av[i1][0] === 0) {
                                        que = que - 15 * av[i1][1];
                                    }
                                }
                            }
                        }
                    }

                    if (i < 6) {
                        que = que + av[i][1] * 30;
                    }
                }
                ///////////////////////////////////////5-ti za iz4akvane /////////////////////////////////////////////
                else {
                    if (qcom > 0 && qpla > 0) {
                        if (av[i][1] > 1 && av[i][0] === 0) {
                            que = que + quef522[25 - i];
                        }
                        if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef532[25 - i];
                            t = 1;
                        }
                        if (av[i][1] === 1 && av[i][0] === 0) {
                            que = que - quef512[i] * this.PosToBeTaped(i, av);
                        }
                        if (i === 25) {
                            if (pip1 - pip2 > 20 && t === 1) {
                                que = que + 100;
                            }
                        }
                        if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                            que = que + this.MyMathfloor(quef522[25 - i] / 3);
                        }
                        if (av[i][1] > 3) {
                            que = que - this.MyMathfloor(quef522[25 - i] / 3.5) * (av[i][1] - 3);
                        }
                        if (i === 25) {
                            que = que - av[i][1] * 15;
                        }
                        if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                            que = que + quef532[25 - i] * this.MyMathfloor((av[i][1] - 1) / 2);
                        }
                        if (i === 25) {
                            for (let j = 0; j < 6; j++) {
                                if (av[j][0] === -1) {
                                    for (let i1 = 0; i1 < j; i1++) {
                                        if (av[i1][1] > 0 && av[i1][0] === 0) {
                                            que = que - 40 * av[i1][1];
                                        }
                                    }
                                }
                            }
                        }
                        if (i < 12 && i > 5) {
                            que = que + 10;
                        }
                    }
                    //////////////////////////////////////5-ti s hvanat v poleto///////////////////////////////////////////
                    else {
                        if (qpla === 0 && qcom > 4) {
                            if (av[i][1] > 1 && av[i][0] === 0) {
                                que = que + quef524[25 - i];
                            }
                            if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                                que = que + quef534[25 - i];
                                t = 1;
                            }
                            if (i === 25) {
                                if (pip1 - pip2 > 20 && t === 1) {
                                    que = que + 100;
                                }
                            }
                            if (av[i][1] === 1 && av[i][0] === 0) {
                                que = que - quef514[i] * this.PosToBeTaped(i, av);
                            }
                            if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                                que = que + this.MyMathfloor(quef524[25 - i] / 3);
                            }
                            if (av[i][1] > 3) {
                                que = que - this.MyMathfloor(quef524[25 - i] / 3.5) * (av[i][1] - 3);
                            }
                            if (i === 25) {
                                que = que - av[i][1] * 15;
                            }
                            if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                                que = que + quef534[25 - i] * this.MyMathfloor((av[i][1] - 1) / 2);
                            }
                            if (i === 25) {
                                for (let j = 0; j < 6; j++) {
                                    if (av[j][0] === -1) {
                                        for (let i1 = 0; i1 < j; i1++) {
                                            if (av[i1][1] > 0 && av[i1][0] === 0) {
                                                que = que - 70 * av[i1][1];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //////////////////////////////////5-ti//////////////////////////////////////////////////////
                        else {
                            if (av[i][1] > 1 && av[i][0] === 0) {
                                que = que + quef521[25 - i];
                            }
                            if (av[i][1] > 0 && (av[i][0] === 1 || av[i][0] === -1)) {
                                que = que + quef531[25 - i];
                                t = 1;
                            }
                            if (av[i][1] === 1 && av[i][0] === 0) {
                                que = que - quef511[i] * this.PosToBeTaped(i, av);
                            }
                            if ((av[i][1] > 2 && av[i][0] === 0) || (av[i][1] > 1 && av[i][0] !== 0)) {
                                que = que + this.MyMathfloor(quef521[25 - i] / 3);
                            }
                            if (av[i][1] > 3) {
                                que = que - this.MyMathfloor(quef521[25 - i] / 3.5) * (av[i][1] - 3);
                            }
                            if (i === 25) {
                                que = que - av[i][1] * 35;
                            }
                            if (i === 25) {
                                if (pip1 - pip2 > 20 && t === 1) {
                                    que = que + 100;
                                }
                            }
                            if (av[i][1] > 1 && (av[i][0] === 1 || av[i][0] === -1)) {
                                que = que + quef531[25 - i] * this.MyMathfloor((av[i][1] - 1) / 2);
                            }
                            if (i === 25) {
                                for (let j = 0; j < 6; j++) {
                                    if (av[j][0] === -1) {
                                        for (let i1 = 0; i1 < j; i1++) {
                                            if (av[i1][1] > 0 && av[i1][0] === 0) {
                                                que = que - 15 * av[i1][1];
                                            }
                                        }
                                    }
                                }
                            }
                            if (i < 6) {
                                que = que + av[i][1] * 30;
                            }
                        }
                    }
                }
            }
        }
        que = que + 50 * t;
        return que;
    }

    ChooseBest() {
        this.GetMax();

        ////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////zarejda toplay/////////////////////////////////////////////////
        if (this.dice1 === this.dice2) {
            this.toplay[0][0] = 0;
            this.toplay[0][1] = 0;
            this.toplay[1][0] = 0;
            this.toplay[1][1] = 0;
            this.toplay[2][0] = 0;
            this.toplay[2][1] = 0;
            this.toplay[3][0] = 0;
            this.toplay[3][1] = 0;
            if (this.sc4 > 0) {
                this.toplay[0][0] = this.playsc4[this.maxc4][0][0];
                this.toplay[0][1] = this.playsc4[this.maxc4][0][1];
                this.toplay[1][0] = this.playsc4[this.maxc4][1][0];
                this.toplay[1][1] = this.playsc4[this.maxc4][1][1];
                this.toplay[2][0] = this.playsc4[this.maxc4][2][0];
                this.toplay[2][1] = this.playsc4[this.maxc4][2][1];
                this.toplay[3][0] = this.playsc4[this.maxc4][3][0];
                this.toplay[3][1] = this.playsc4[this.maxc4][3][1];
            } else {
                if (this.sc3 > 0) {
                    this.toplay[0][0] = this.playsc3[this.maxc3][0][0];
                    this.toplay[0][1] = this.playsc3[this.maxc3][0][1];
                    this.toplay[1][0] = this.playsc3[this.maxc3][1][0];
                    this.toplay[1][1] = this.playsc3[this.maxc3][1][1];
                    this.toplay[2][0] = this.playsc3[this.maxc3][2][0];
                    this.toplay[2][1] = this.playsc3[this.maxc3][2][1];
                } else {
                    if (this.sc2 > 0) {
                        this.toplay[0][0] = this.playsc2[this.maxc2][0][0];
                        this.toplay[0][1] = this.playsc2[this.maxc2][0][1];
                        this.toplay[1][0] = this.playsc2[this.maxc2][1][0];
                        this.toplay[1][1] = this.playsc2[this.maxc2][1][1];
                    } else {
                        if (this.sc1 > 0) {
                            this.toplay[0][0] = this.playsc1[this.maxc1][0];
                            this.toplay[0][1] = this.playsc1[this.maxc1][1];
                        }
                    }
                }
            }
        }
        if (this.dice1 !== this.dice2) {
            if (this.sall === 0) {
                this.toplay[0][0] = 0;
                this.toplay[0][1] = 0;
                this.toplay[1][0] = 0;
                this.toplay[1][1] = 0;
                if (this.s > 0) {
                    this.toplay[0][0] = this.plays1[this.max11][0];
                    this.toplay[0][1] = this.plays1[this.max11][1];
                }
                if (this.s2 > 0) {
                    this.toplay[0][0] = this.plays2[this.max12][0];
                    this.toplay[0][1] = this.plays2[this.max12][1];
                }
            }
            if (this.sall > 0) {
                this.toplay[0][0] = this.plays[this.max2][0][0];
                this.toplay[0][1] = this.plays[this.max2][0][1];
                this.toplay[1][0] = this.plays[this.max2][1][0];
                this.toplay[1][1] = this.plays[this.max2][1][1];
            }
            this.toplay[2][0] = 0;
            this.toplay[2][1] = 0;
            this.toplay[3][0] = 0;
            this.toplay[3][1] = 0;
        }
    }
    //////////////////////////////opredelia elementa s maximalen koeficient/////////////////////////
    GetMax() {
        this.maxc1 = 0;
        this.maxc2 = 0;
        this.maxc3 = 0;
        this.maxc4 = 0;
        this.max11 = 0;
        this.max12 = 0;
        this.max2 = 0;
        for (let i = 0; i < this.s; i++) {
            if (this.type === 1) {
                this.que11[i] = this.takequef(this.avi[i]);
            }
            if (this.type === 2) {
                this.que11[i] = this.takequefdu(this.avi[i]);
            }
            if (this.que11[i] > this.que11[this.max11]) {
                this.max11 = i;
            }
        }
        for (let i = 0; i < this.s2; i++) {
            if (this.type === 1) {
                this.que12[i] = this.takequef(this.avi2[i]);
            }
            if (this.type === 2) {
                this.que12[i] = this.takequefdu(this.avi2[i]);
            }
            if (this.que12[i] > this.que12[this.max12]) {
                this.max12 = i;
            }
        }
        for (let i = 0; i < this.sall; i++) {
            if (this.type === 1) {
                this.que2[i] = this.takequef(this.aviall[i]);
            }
            if (this.type === 2) {
                this.que2[i] = this.takequefdu(this.aviall[i]);
            }
            if (this.que2[i] > this.que2[this.max2]) {
                this.max2 = i;
            }
        }
        for (let i = 0; i < this.sc1; i++) {
            this.quec1[i] = this.takequef(this.avic1[i]);
            if (this.quec1[i] > this.quec1[this.maxc1]) {
                this.maxc1 = i;
            }
        }
        for (let i = 0; i < this.sc2; i++) {
            this.quec2[i] = this.takequef(this.avic2[i]);
            if (this.quec2[i] > this.quec2[this.maxc2]) {
                this.maxc2 = i;
            }
        }
        for (let i = 0; i < this.sc3; i++) {
            this.quec3[i] = this.takequef(this.avic3[i]);
            if (this.quec3[i] > this.quec3[this.max2]) {
                this.maxc3 = i;
            }
        }
        for (let i = 0; i < this.sc4; i++) {
            this.quec4[i] = this.takequef(this.avic4[i]);
            if (this.quec4[i] > this.quec4[this.maxc4]) {
                this.maxc4 = i;
            }
        }
    }

    GetIndexPool(rect: number, player: number): number {
        let ip: number;
        if (player === 1) {
            ip = 24;
        } else {
            ip = -1;
        }
        if (rect >= 0) {
            for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
                if (this.poolpos[i1].x === this.poolplace[rect][0][0].x && this.poolpos[i1].y === this.poolplace[rect][0][0].y) {
                    ip = i1;
                }
            }
            for (
                let i1 = (player - 1) * 15;
                i1 < 15 + (player - 1) * 15;
                i1++ ////////////////////////////////////////////
            ) {
                if (this.poolpos[i1].x === this.poolplace[rect][0][1].x && this.poolpos[i1].y === this.poolplace[rect][0][1].y) {
                    ip = i1;
                }
            }
            for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
                if (this.poolpos[i1].x === this.poolplace[rect][1][0].x && this.poolpos[i1].y === this.poolplace[rect][1][0].y) {
                    ip = i1;
                }
            }
        }
        return ip;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////opredelia koeficientite/////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    takequefduPlayed(av: avidu) {
        let que = 0;
        que = this.takequefdu(av.avi);
        return que;
    }

    ChooseBestChift() {
        let last = 0;
        for (let i = 24; i > 0; i--) {
            if (this.sc[i] > 0) {
                last = i;
                break;
            }
        }
        let best1 = 0;
        let best2 = 0;
        for (let i = 0; i < this.sc[last]; i++) {
            //takequefduPlayed(this.avid[last][i]);
            this.avid[last][i].quef = this.takequefduPlayed(this.avid[last][i]);
        }
        for (let i = 1; i < this.sc[last]; i++) {
            if (this.avid[last][i].quef > this.avid[last][best1].quef) {
                best1 = i;
            }
        }
        for (let i = 0; i < 24; i++) {
            this.toplayduChift[i][0] = this.avid[last][best1].plays[i][0];
            this.toplayduChift[i][1] = this.avid[last][best1].plays[i][1];
        }
        for (let i = 0; i < this.sc[25]; i++) {
            this.avid[25][i].quef = this.takequefduPlayed(this.avid[25][i]);
        }
        for (let i = 1; i < this.sc[25]; i++) {
            if (this.avid[25][i].quef > this.avid[25][best2].quef) {
                best2 = i;
            }
        }
        if (this.sc[25] > 0 && this.avid[25][best2].quef > this.avid[last][best1].quef) {
            for (let i = 0; i < 24; i++) {
                this.toplayduChift[i][0] = this.avid[25][best2].plays[i][0];
                this.toplayduChift[i][1] = this.avid[25][best2].plays[i][1];
            }
        }
    }

    async IntelectGulbara() {
        if (this.zarove) {
            if (this.dice1 === this.dice2) {
                this.AviableGulbaraChift();
            }
            this.pipcom = this.GetPipCount(2);
            this.pippla = this.GetPipCount(1);
            this.AviableGulbara();
            this.ChooseBest();
            let player = 2;
            if (this.CheckMove(player)) {
                let rexit: boolean = this.CheckReadyToExit(player);
                for (let i = 0; i < 24; i++) {
                    if (this.table[i][0] > 0) {
                        this.lastpool = i;
                        break;
                    }
                }
                for (let i = 23; i >= 0; i--) {
                    if (this.table[i][0] > 0) {
                        this.firstpool = i;
                        break;
                    }
                }
                if (this.dice1 !== this.dice2) {
                    if (this.toplay[0][0] > 0 || this.toplay[0][1] > 0) {
                        let q1 = this.GetIndexPool(this.toplay[0][0], player);
                        if (
                            await this.AddToTableRect(
                                q1,
                                new CPoint(this.tablerect[this.toplay[0][1]].TopLeft().x + 1, this.tablerect[this.toplay[0][1]].TopLeft().y + 1)
                            )
                        ) {
                            this.toplay[0][0] = 0;
                            this.toplay[0][1] = 0;
                            await this.IntelectGulbaraEnd();
                            return;
                        }
                    } else {
                        if (this.toplay[1][0] > 0 || this.toplay[1][1] > 0) {
                            let q2 = this.GetIndexPool(this.toplay[1][0], player);
                            if (
                                await this.AddToTableRect(
                                    q2,
                                    new CPoint(this.tablerect[this.toplay[1][1]].TopLeft().x + 1, this.tablerect[this.toplay[1][1]].TopLeft().y + 1)
                                )
                            ) {
                                this.toplay[1][0] = 0;
                                this.toplay[1][1] = 0;
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                    for (let i = 15; i < 30; i++) {
                        for (let a = 0; a < 24; a++) {
                            if (await this.AddToTableRect(i, new CPoint(this.tablerect[a].TopLeft().x + 1, this.tablerect[a].TopLeft().y + 1))) {
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                    if (rexit) {
                        for (let i = 15; i < 30; i++) {
                            if (await this.AddToTableRect(i, new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1))) {
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                }
                if (this.dice1 === this.dice2) {
                    this.ChooseBestChift();

                    for (let j = 0; j < 24; j++) {
                        if (this.toplayduChift[j][0] > 0 || this.toplayduChift[j][1] > 0) {
                            let q1 = this.GetIndexPool(this.toplayduChift[j][0], player);
                            if (
                                await this.AddToTableRect(
                                    q1,
                                    new CPoint(
                                        this.tablerect[this.toplayduChift[j][1]].TopLeft().x + 1,
                                        this.tablerect[this.toplayduChift[j][1]].TopLeft().y + 1
                                    )
                                )
                            ) {
                                this.toplay[0][0] = 0;
                                this.toplay[0][1] = 0;
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                    for (let i = 15; i < 30; i++) {
                        for (let a = 0; a < 24; a++) {
                            if (await this.AddToTableRect(i, new CPoint(this.tablerect[a].TopLeft().x + 1, this.tablerect[a].TopLeft().y + 1))) {
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                    if (rexit) {
                        for (let i = 15; i < 30; i++) {
                            if (await this.AddToTableRect(i, new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1))) {
                                await this.IntelectGulbaraEnd();
                                return;
                            }
                        }
                    }
                }
                await this.IntelectGulbaraEnd();
                return;
            } else {
                if (this.type === 2 && this.dice1 === this.dice2 && this.Began && this.CheckMove(1)) {
                    if (!this.Doigravane) {
                        this.Doigravane = true;
                    } else {
                        this.Doigravane = false;
                    }
                    this.KoiENaRed = 1;
                    this.strelka = 1; //3;
                    this.setState({});
                } else {
                    this.KoiENaRed = 1;
                    this.strelka = 3;
                    this.mdice1 = 0;
                    this.mdice2 = 0;
                    this.mdice3 = 0;
                    this.mdice4 = 0;
                    this.ReloadDice();
                    this.zarove = false;
                }
            }
        }
    }

    async IntelectGulbaraEnd() {
        if (this.CheckWinGame(2)) {
            if (!this.wait) {
                await this.InitRezDlg(0);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////izkarvane na vsi4ki vuzmojni hodove///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    aviable() {
        this.s = 0;
        this.s2 = 0;
        this.sall = 0;
        ////////////////////////////////////////ako ne e 4ift//////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////vuzmojnosti sled purvia hod/////////////////////////////
        if (this.dice1 !== this.dice2 && ((this.mdice1 > 0 && this.dice1 === this.mdice1) || (this.mdice2 > 0 && this.dice1 === this.mdice2))) {
            for (let i = 0; i < 26; i++) {
                if (
                    i !== 25 &&
                    i >= this.dice1 &&
                    i !== 23 &&
                    this.table[i][1] > 0 &&
                    this.table[i - this.dice1][0] < 2 &&
                    this.table[i - this.dice1][1] > -1
                ) {
                    //!=-1)
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][i - this.dice1][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = i - this.dice1;
                    this.s++;
                }
                if (
                    i === 23 &&
                    this.table[i][1] > 0 &&
                    this.table[i - this.dice1][0] < 2 &&
                    this.table[25][1] === 0 &&
                    i >= this.dice1 &&
                    this.table[i - this.dice1][1] > -1
                ) {
                    //!=-1)
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][i - this.dice1][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = i - this.dice1;
                    this.s++;
                }
                if (
                    i === 25 &&
                    this.table[i][1] > 0 &&
                    this.table[i - (this.dice1 + 2)][0] < 2 &&
                    i >= this.dice1 &&
                    this.table[i - (this.dice1 + 2)][1] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.avi[this.s][j][0] = this.table[j][0];
                        this.avi[this.s][j][1] = this.table[j][1];
                    }
                    this.avi[this.s][i][1]--;
                    this.avi[this.s][i - (this.dice1 + 2)][1]++;
                    this.plays1[this.s][0] = i;
                    this.plays1[this.s][1] = i - (this.dice1 + 2);
                    this.s++;
                }
            }
        }
        if (this.dice1 !== this.dice2 && ((this.mdice1 > 0 && this.dice2 === this.mdice1) || (this.mdice2 > 0 && this.dice2 === this.mdice2))) {
            for (let i = 0; i < 26; i++) {
                if (
                    i !== 25 &&
                    i >= this.dice2 &&
                    i !== 23 &&
                    this.table[i][1] > 0 &&
                    this.table[i - this.dice2][0] < 2 &&
                    this.table[i - this.dice2][1] > -1
                ) {
                    //!=-1)
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][i - this.dice2][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = i - this.dice2;
                    this.s2++;
                }
                if (
                    i === 23 &&
                    this.table[i][1] > 0 &&
                    this.table[i - this.dice2][0] < 2 &&
                    this.table[25][1] === 0 &&
                    i >= this.dice2 &&
                    this.table[i - this.dice2][1] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][i - this.dice2][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = i - this.dice2;
                    this.s2++;
                }
                if (
                    i === 25 &&
                    this.table[i][1] > 0 &&
                    this.table[i - (this.dice2 + 2)][0] < 2 &&
                    i >= this.dice2 &&
                    this.table[i - (this.dice2 + 2)][1] !== -1
                ) {
                    for (let j = 0; j < 26; j++) {
                        this.avi2[this.s2][j][0] = this.table[j][0];
                        this.avi2[this.s2][j][1] = this.table[j][1];
                    }
                    this.avi2[this.s2][i][1]--;
                    this.avi2[this.s2][i - (this.dice2 + 2)][1]++;
                    this.plays2[this.s2][0] = i;
                    this.plays2[this.s2][1] = i - (this.dice2 + 2);
                    this.s2++;
                }
            }
        }
        //////////////////////////////////////////zarejda vsi4ki vuzmojni/////////////////////////////////
        if (this.dice1 !== this.dice2 && this.mdice1 > 0 && this.mdice2 > 0) {
            for (let l = 0; l < this.s; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i !== 25 &&
                        i >= this.dice2 &&
                        i !== 23 &&
                        this.avi[l][i][1] > 0 &&
                        this.table[i - this.dice2][0] < 2 &&
                        this.table[i - this.dice2][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - this.dice2][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - this.dice2;
                        this.sall++;
                    }
                    if (
                        i === 23 &&
                        this.avi[l][i][1] > 0 &&
                        this.table[i - this.dice2][0] < 2 &&
                        this.avi[l][25][1] === 0 &&
                        i >= this.dice2 &&
                        this.table[i - this.dice2][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - this.dice2][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - this.dice2;
                        this.sall++;
                    }
                    if (
                        i === 25 &&
                        this.avi[l][i][1] > 0 &&
                        this.table[i - (this.dice2 + 2)][0] < 2 &&
                        i >= this.dice2 &&
                        this.table[i - (this.dice2 + 2)][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.aviall[this.sall][j][0] = this.avi[l][j][0];
                            this.aviall[this.sall][j][1] = this.avi[l][j][1];
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - (this.dice2 + 2)][1]++;
                        this.plays[this.sall][0][0] = this.plays1[l][0];
                        this.plays[this.sall][0][1] = this.plays1[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - (this.dice2 + 2);
                        this.sall++;
                    }
                }
            }
            for (let l = 0; l < this.s2; l++) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i !== 25 &&
                        i >= this.dice1 &&
                        i !== 23 &&
                        this.avi2[l][i][1] > 0 &&
                        this.table[i - this.dice1][0] < 2 &&
                        this.table[i - this.dice1][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - this.dice1][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - this.dice1;
                        this.sall++;
                    }
                    if (
                        i === 23 &&
                        this.avi2[l][i][1] > 0 &&
                        this.table[i - this.dice1][0] < 2 &&
                        this.avi2[l][25][1] === 0 &&
                        i >= this.dice1 &&
                        this.table[i - this.dice1][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - this.dice1][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - this.dice1;
                        this.sall++;
                    }
                    if (
                        i === 25 &&
                        this.avi2[l][i][1] > 0 &&
                        this.table[i - (this.dice1 + 2)][0] < 2 &&
                        i >= this.dice1 &&
                        this.table[i - (this.dice1 + 2)][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            try {
                                this.aviall[this.sall][j][0] = this.avi2[l][j][0];
                                this.aviall[this.sall][j][1] = this.avi2[l][j][1];
                            } catch (e) {
                                console.error('this.aviall[' + this.sall + '][' + j + '][0]', e);
                            }
                        }
                        this.aviall[this.sall][i][1]--;
                        this.aviall[this.sall][i - (this.dice1 + 2)][1]++;
                        this.plays[this.sall][0][0] = this.plays2[l][0];
                        this.plays[this.sall][0][1] = this.plays2[l][1];
                        this.plays[this.sall][1][0] = i;
                        this.plays[this.sall][1][1] = i - (this.dice1 + 2);
                        this.sall++;
                    }
                }
            }
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////ako e 4ift////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////za 1-vi hod///////////////////////////////////////
        if (this.dice1 === this.dice2) {
            this.sc1 = 0;
            this.sc2 = 0;
            this.sc3 = 0;
            this.sc4 = 0;
            if (this.mdice1 > 0) {
                for (let i = 0; i < 26; i++) {
                    if (
                        i !== 25 &&
                        i >= this.dice1 &&
                        i !== 23 &&
                        this.table[i][1] > 0 &&
                        this.table[i - this.dice1][0] < 2 &&
                        this.table[i - this.dice1][1] > -1
                    ) {
                        //!=-1)
                        for (let j = 0; j < 26; j++) {
                            this.avic1[this.sc1][j][0] = this.table[j][0];
                            this.avic1[this.sc1][j][1] = this.table[j][1];
                        }
                        this.avic1[this.sc1][i][1]--;
                        this.avic1[this.sc1][i - this.dice1][1]++;
                        this.playsc1[this.sc1][0] = i;
                        this.playsc1[this.sc1][1] = i - this.dice1;
                        this.sc1++;
                    }
                    if (
                        i === 23 &&
                        this.table[i][1] > 0 &&
                        this.table[i - this.dice1][0] < 2 &&
                        this.table[25][1] === 0 &&
                        i >= this.dice1 &&
                        this.table[i - this.dice1][1] > -1
                    ) {
                        //!=-1)
                        for (let j = 0; j < 26; j++) {
                            this.avic1[this.sc1][j][0] = this.table[j][0];
                            this.avic1[this.sc1][j][1] = this.table[j][1];
                        }
                        //FIx Tapa
                        this.avic1[this.sc1][i][1]--;
                        this.avic1[this.sc1][i - this.dice1][1]++;
                        this.playsc1[this.sc1][0] = i;
                        this.playsc1[this.sc1][1] = i - this.dice1;
                        this.sc1++;
                    }
                    if (
                        i === 25 &&
                        this.table[i][1] > 0 &&
                        this.table[i - (this.dice1 + 2)][0] < 2 &&
                        i >= this.dice1 &&
                        this.table[i - (this.dice1 + 2)][1] !== -1
                    ) {
                        for (let j = 0; j < 26; j++) {
                            this.avic1[this.sc1][j][0] = this.table[j][0];
                            this.avic1[this.sc1][j][1] = this.table[j][1];
                        }
                        this.avic1[this.sc1][i][1]--;
                        this.avic1[this.sc1][i - (this.dice1 + 2)][1]++;
                        this.playsc1[this.sc1][0] = i;
                        this.playsc1[this.sc1][1] = i - (this.dice1 + 2);
                        this.sc1++;
                    }
                }
            }
            ////////////////////////////////////za 2hod/////////////////////////////////////////////////
            if (this.mdice2 > 0) {
                for (let l = 0; l < this.sc1; l++) {
                    for (let i = 0; i < 26; i++) {
                        if (
                            i !== 25 &&
                            i >= this.dice1 &&
                            i !== 23 &&
                            this.avic1[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic2[this.sc2][j][0] = this.avic1[l][j][0];
                                this.avic2[this.sc2][j][1] = this.avic1[l][j][1];
                            }
                            this.avic2[this.sc2][i][1]--;
                            this.avic2[this.sc2][i - this.dice1][1]++;
                            this.playsc2[this.sc2][0][0] = this.playsc1[l][0];
                            this.playsc2[this.sc2][0][1] = this.playsc1[l][1];
                            this.playsc2[this.sc2][1][0] = i;
                            this.playsc2[this.sc2][1][1] = i - this.dice1;
                            this.sc2++;
                        }
                        if (
                            i === 23 &&
                            this.avic1[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.avic1[l][25][1] === 0 &&
                            i >= this.dice1 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic2[this.sc2][j][0] = this.avic1[l][j][0];
                                this.avic2[this.sc2][j][1] = this.avic1[l][j][1];
                            }
                            this.avic2[this.sc2][i][1]--;
                            this.avic2[this.sc2][i - this.dice1][1]++;
                            this.playsc2[this.sc2][0][0] = this.playsc1[l][0];
                            this.playsc2[this.sc2][0][1] = this.playsc1[l][1];
                            this.playsc2[this.sc2][1][0] = i;
                            this.playsc2[this.sc2][1][1] = i - this.dice1;
                            this.sc2++;
                        }
                        if (
                            i === 25 &&
                            this.avic1[l][i][1] > 0 &&
                            this.table[i - (this.dice1 + 2)][0] < 2 &&
                            i >= this.dice1 &&
                            this.table[i - (this.dice1 + 2)][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic2[this.sc2][j][0] = this.avic1[l][j][0];
                                this.avic2[this.sc2][j][1] = this.avic1[l][j][1];
                            }
                            this.avic2[this.sc2][i][1]--;
                            this.avic2[this.sc2][i - (this.dice1 + 2)][1]++;
                            this.playsc2[this.sc2][0][0] = this.playsc1[l][0];
                            this.playsc2[this.sc2][0][1] = this.playsc1[l][1];
                            this.playsc2[this.sc2][1][0] = i;
                            this.playsc2[this.sc2][1][1] = i - (this.dice1 + 2);
                            this.sc2++;
                        }
                    }
                }
            }
            //////////////////////////////////za 3-ti hod///////////////////////////////////////////////////
            if (this.mdice3 > 0) {
                for (let l = 0; l < this.sc2; l++) {
                    for (let i = 0; i < 26; i++) {
                        if (
                            i !== 25 &&
                            i >= this.dice1 &&
                            i !== 23 &&
                            this.avic2[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                try {
                                    this.avic3[this.sc3][j][0] = this.avic2[l][j][0];
                                    this.avic3[this.sc3][j][1] = this.avic2[l][j][1];
                                } catch (e) {
                                    console.error('this.avic3[' + this.sc3 + '][' + j + '][0]', e);
                                }
                            }
                            this.avic3[this.sc3][i][1]--;
                            this.avic3[this.sc3][i - this.dice1][1]++;
                            this.playsc3[this.sc3][0][0] = this.playsc2[l][0][0];
                            this.playsc3[this.sc3][0][1] = this.playsc2[l][0][1];
                            this.playsc3[this.sc3][1][0] = this.playsc2[l][1][0];
                            this.playsc3[this.sc3][1][1] = this.playsc2[l][1][1];
                            this.playsc3[this.sc3][2][0] = i;
                            this.playsc3[this.sc3][2][1] = i - this.dice1;
                            this.sc3++;
                        }
                        if (
                            i === 23 &&
                            this.avic2[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.avic2[l][25][1] === 0 &&
                            i >= this.dice1 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic3[this.sc3][j][0] = this.avic2[l][j][0];
                                this.avic3[this.sc3][j][1] = this.avic2[l][j][1];
                            }
                            this.avic3[this.sc3][i][1]--;
                            this.avic3[this.sc3][i - this.dice1][1]++;
                            this.playsc3[this.sc3][0][0] = this.playsc2[l][0][0];
                            this.playsc3[this.sc3][0][1] = this.playsc2[l][0][1];
                            this.playsc3[this.sc3][1][0] = this.playsc2[l][1][0];
                            this.playsc3[this.sc3][1][1] = this.playsc2[l][1][1];
                            this.playsc3[this.sc3][2][0] = i;
                            this.playsc3[this.sc3][2][1] = i - this.dice1;
                            this.sc3++;
                        }
                        if (
                            i === 25 &&
                            this.avic2[l][i][1] > 0 &&
                            this.table[i - (this.dice1 + 2)][0] < 2 &&
                            i >= this.dice1 &&
                            this.table[i - (this.dice1 + 2)][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic3[this.sc3][j][0] = this.avic2[l][j][0];
                                this.avic3[this.sc3][j][1] = this.avic2[l][j][1];
                            }
                            this.avic3[this.sc3][i][1]--;
                            this.avic3[this.sc3][i - (this.dice1 + 2)][1]++;
                            this.playsc3[this.sc3][0][0] = this.playsc2[l][0][0];
                            this.playsc3[this.sc3][0][1] = this.playsc2[l][0][1];
                            this.playsc3[this.sc3][1][0] = this.playsc2[l][1][0];
                            this.playsc3[this.sc3][1][1] = this.playsc2[l][1][1];
                            this.playsc3[this.sc3][2][0] = i;
                            this.playsc3[this.sc3][2][1] = i - (this.dice1 + 2);
                            this.sc3++;
                        }
                    }
                }
            }
            /////////////////////////////////za 4-ti hod////////////////////////////////////////////////////
            if (this.mdice4 > 0) {
                for (let l = 0; l < this.sc3; l++) {
                    for (let i = 0; i < 26; i++) {
                        if (
                            i !== 25 &&
                            i >= this.dice1 &&
                            i !== 23 &&
                            this.avic3[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic4[this.sc4][j][0] = this.avic3[l][j][0];
                                this.avic4[this.sc4][j][1] = this.avic3[l][j][1];
                            }
                            this.avic4[this.sc4][i][1]--;
                            this.avic4[this.sc4][i - this.dice1][1]++;
                            this.playsc4[this.sc4][0][0] = this.playsc3[l][0][0];
                            this.playsc4[this.sc4][0][1] = this.playsc3[l][0][1];
                            this.playsc4[this.sc4][1][0] = this.playsc3[l][1][0];
                            this.playsc4[this.sc4][1][1] = this.playsc3[l][1][1];
                            this.playsc4[this.sc4][2][0] = this.playsc3[l][2][0];
                            this.playsc4[this.sc4][2][1] = this.playsc3[l][2][1];
                            this.playsc4[this.sc4][3][0] = i;
                            this.playsc4[this.sc4][3][1] = i - this.dice1;
                            this.sc4++;
                        }
                        if (
                            i === 23 &&
                            this.avic3[l][i][1] > 0 &&
                            this.table[i - this.dice1][0] < 2 &&
                            this.avic3[l][25][1] === 0 &&
                            i >= this.dice1 &&
                            this.table[i - this.dice1][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic4[this.sc4][j][0] = this.avic3[l][j][0];
                                this.avic4[this.sc4][j][1] = this.avic3[l][j][1];
                            }
                            this.avic4[this.sc4][i][1]--;
                            this.avic4[this.sc4][i - this.dice1][1]++;
                            this.playsc4[this.sc4][0][0] = this.playsc3[l][0][0];
                            this.playsc4[this.sc4][0][1] = this.playsc3[l][0][1];
                            this.playsc4[this.sc4][1][0] = this.playsc3[l][1][0];
                            this.playsc4[this.sc4][1][1] = this.playsc3[l][1][1];
                            this.playsc4[this.sc4][2][0] = this.playsc3[l][2][0];
                            this.playsc4[this.sc4][2][1] = this.playsc3[l][2][1];
                            this.playsc4[this.sc4][3][0] = i;
                            this.playsc4[this.sc4][3][1] = i - this.dice1;
                            this.sc4++;
                        }
                        if (
                            i === 25 &&
                            this.avic3[l][i][1] > 0 &&
                            this.table[i - (this.dice1 + 2)][0] < 2 &&
                            i >= this.dice1 &&
                            this.table[i - (this.dice1 + 2)][1] !== -1
                        ) {
                            for (let j = 0; j < 26; j++) {
                                this.avic4[this.sc4][j][0] = this.avic3[l][j][0];
                                this.avic4[this.sc4][j][1] = this.avic3[l][j][1];
                            }
                            this.avic4[this.sc4][i][1]--;
                            this.avic4[this.sc4][i - (this.dice1 + 2)][1]++;
                            this.playsc4[this.sc4][0][0] = this.playsc3[l][0][0];
                            this.playsc4[this.sc4][0][1] = this.playsc3[l][0][1];
                            this.playsc4[this.sc4][1][0] = this.playsc3[l][1][0];
                            this.playsc4[this.sc4][1][1] = this.playsc3[l][1][1];
                            this.playsc4[this.sc4][2][0] = this.playsc3[l][2][0];
                            this.playsc4[this.sc4][2][1] = this.playsc3[l][2][1];
                            this.playsc4[this.sc4][3][0] = i;
                            this.playsc4[this.sc4][3][1] = i - (this.dice1 + 2);
                            this.sc4++;
                        }
                    }
                }
            }
        }
    }

    ////////////////////////////nova funkcia 4e starata ne vinagi vurve6e///////////////////////
    GetIndexPool1(rect: number, player: number) {
        let ip: number;
        if (player === 1) {
            ip = 24;
        } else {
            ip = -1;
        }
        if (rect >= 0) {
            for (let i1 = (player - 1) * 15; i1 < 15 + (player - 1) * 15; i1++) {
                if (
                    (this.poolpos[i1].x === this.poolplace[rect][1][0].x && this.poolpos[i1].y === this.poolplace[rect][1][0].y) ||
                    (this.poolpos[i1].x === this.poolplace[rect][0][1].x && this.poolpos[i1].y === this.poolplace[rect][0][1].y)
                ) {
                    ip = i1;
                }
            }
        }
        return ip;
    }

    async IntelectTapa() {
        if (this.zarove) {
            while (this.mdice1 === 0 && (this.mdice2 > 0 || this.mdice3 > 0 || this.mdice4 > 0)) {
                //
                this.mdice1 = this.mdice2;
                this.mdice2 = this.mdice3;
                this.mdice3 = this.mdice4;
                this.mdice4 = 0;
            }
            let rexit2 = this.CheckReadyToExit(2);
            let player = 2;
            if (this.CheckMove(player)) {
                this.full();
                if (!rexit2) {
                    this.aviable();
                    this.ChooseBest();
                    if (this.toplay[0][0] !== 0) {
                        let q1 = this.GetIndexPool(this.toplay[0][0], player);
                        if (q1 === -1) {
                            q1 = this.GetIndexPool1(this.toplay[0][0], player);
                        }
                        if (q1 > -1) {
                            if (
                                await this.AddToTableRect(
                                    q1,
                                    new CPoint(this.tablerect[this.toplay[0][1]].TopLeft().x + 1, this.tablerect[this.toplay[0][1]].TopLeft().y + 1)
                                )
                            ) {
                                this.toplay[0][0] = 0;
                                this.toplay[0][1] = 0;
                                await this.IntelectTapaEnd();
                                return;
                            }
                        }
                    }
                    /////////////////////////////////////////////
                    else {
                        if (this.toplay[1][0] !== 0) {
                            let q2 = this.GetIndexPool(this.toplay[1][0], player);
                            if (q2 === -1) {
                                q2 = this.GetIndexPool1(this.toplay[1][0], player);
                            }
                            if (q2 > -1) {
                                if (
                                    await this.AddToTableRect(
                                        q2,
                                        new CPoint(
                                            this.tablerect[this.toplay[1][1]].TopLeft().x + 1,
                                            this.tablerect[this.toplay[1][1]].TopLeft().y + 1
                                        )
                                    )
                                ) {
                                    this.toplay[1][0] = 0;
                                    this.toplay[1][1] = 0;
                                    await this.IntelectTapaEnd();
                                    return;
                                }
                            }
                        }
                        ////////////////////////////////////
                        else {
                            if (this.toplay[2][0] !== 0) {
                                let q3 = this.GetIndexPool(this.toplay[2][0], player);
                                if (q3 === -1) {
                                    q3 = this.GetIndexPool1(this.toplay[2][0], player);
                                }
                                if (q3 > -1) {
                                    if (
                                        await this.AddToTableRect(
                                            q3,
                                            new CPoint(
                                                this.tablerect[this.toplay[2][1]].TopLeft().x + 1,
                                                this.tablerect[this.toplay[2][1]].TopLeft().y + 1
                                            )
                                        )
                                    ) {
                                        this.toplay[2][0] = 0;
                                        this.toplay[2][1] = 0;
                                        await this.IntelectTapaEnd();
                                        return;
                                    }
                                }
                            }
                            ///////////////////////////
                            else {
                                if (this.toplay[3][0] !== 0) {
                                    let q4 = this.GetIndexPool(this.toplay[3][0], player);
                                    if (q4 === -1) {
                                        q4 = this.GetIndexPool1(this.toplay[3][0], player);
                                    }
                                    if (q4 > -1) {
                                        if (
                                            await this.AddToTableRect(
                                                q4,
                                                new CPoint(
                                                    this.tablerect[this.toplay[3][1]].TopLeft().x + 1,
                                                    this.tablerect[this.toplay[3][1]].TopLeft().y + 1
                                                )
                                            )
                                        ) {
                                            this.toplay[3][0] = 0;
                                            this.toplay[3][1] = 0;
                                            await this.IntelectTapaEnd();
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    console.log('Fatal Error: No Intelect');
                    for (let i = 15; i < 30; i++) {
                        for (let a = 0; a < 24; a++) {
                            if (await this.AddToTableRect(i, new CPoint(this.tablerect[a].TopLeft().x + 1, this.tablerect[a].TopLeft().y + 1))) {
                                await this.IntelectTapaEnd();
                                return;
                            }
                        }
                    }
                }
                ////////////////////////////////////ako e gotov da vzima////////////////////////////////////
                if (rexit2) {
                    let htp = 0;
                    //	dice_size=24;
                    this.qex = 0;
                    /////////////////////////////tuk sam pipal

                    while (this.mdice1 === 0 && (this.mdice2 > 0 || this.mdice3 > 0 || this.mdice4 > 0)) {
                        this.mdice1 = this.mdice2;
                        this.mdice2 = this.mdice3;
                        this.mdice3 = this.mdice4;
                        this.mdice4 = 0;
                    }
                    //////////////////////tuk sam pipal
                    let tp = 0;
                    for (let i = 0; i < 6; i++) {
                        if (this.table[i][0] === -1) {
                            htp = 1;
                            tp = i;
                            break;
                        }
                    }
                    if (htp === 0) {
                        if (this.mdice1 < this.mdice2) {
                            let temp1;
                            temp1 = this.mdice1;
                            this.mdice1 = this.mdice2;
                            this.mdice2 = temp1;
                        }
                        if (this.table[this.mdice1 - 1][1] > 0) {
                            //||(this.table[this.mdice2-1][1]>0))
                            this.qex = this.GetIndexPool(this.mdice1 - 1, player);
                            if (
                                await this.AddToTableRect(
                                    this.qex,
                                    new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                )
                            ) {
                                this.mdice1 = this.mdice2;
                                this.mdice2 = this.mdice3;
                                this.mdice3 = this.mdice4;
                                this.mdice4 = 0;
                                await this.IntelectTapaEnd();
                                return;
                            }
                        } else {
                            this.z11 = 0;
                            for (let i = this.mdice1; i < 6; i++) {
                                if (this.table[i][1] > 0) {
                                    this.z11 = 1;
                                }
                            }
                            //////////////////////
                            if (this.z11 === 1) {
                                for (let j = 5; j > this.mdice1 - 1; j--) {
                                    if (this.table[j][1] > 0) {
                                        if (this.table[j - this.mdice1][1] === 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 5; j > this.mdice1 - 1; j--) {
                                    if (this.table[j][1] > 0) {
                                        if (this.table[j - this.mdice1][1] === 1) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 5; j > this.mdice1 - 1; j--) {
                                    if (this.table[j][1] > 0) {
                                        if (this.table[j - this.mdice1][1] < this.table[j][1]) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 5; j > this.mdice1 - 1; j--) {
                                    if (this.table[j][1] > 0) {
                                        this.qex = this.GetIndexPool(j, player);
                                        if (
                                            await this.AddToTableRect(
                                                this.qex,
                                                new CPoint(
                                                    this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                    this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                )
                                            )
                                        ) {
                                            this.mdice1 = this.mdice2;
                                            this.mdice2 = this.mdice3;
                                            this.mdice3 = this.mdice4;
                                            this.mdice4 = 0;
                                            await this.IntelectTapaEnd();
                                            return;
                                        }
                                    }
                                }
                            }
                            ////////////////////////////////////////////////
                            if (this.z11 === 0) {
                                for (let j = this.mdice1 - 2; j >= 0; j--) {
                                    if (this.table[j][1] > 0) {
                                        this.qex = this.GetIndexPool(j, player);
                                        if (
                                            await this.AddToTableRect(
                                                this.qex,
                                                new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                            )
                                        ) {
                                            this.mdice1 = this.mdice2;
                                            this.mdice2 = this.mdice3;
                                            this.mdice3 = this.mdice4;
                                            this.mdice4 = 0;
                                            await this.IntelectTapaEnd();
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        ////////////////////////////////////////////////////////////
                    }
                    ////////////////////////////////ako ima zatapen pull/////////////////////////////
                    if (htp === 1) {
                        //			for(let i=0;i<6;i++)
                        //			{
                        //				if(this.table[i][0]==-1)
                        //			}
                        if (this.mdice1 < this.mdice2) {
                            let temp1;
                            temp1 = this.mdice1;
                            this.mdice1 = this.mdice2;
                            this.mdice2 = temp1;
                        }
                        ///////////////////////////////////////////////////////////////
                        if (this.mdice1 - 1 > tp) {
                            if (this.table[this.mdice1 - 1][1] > 0) {
                                this.qex = this.GetIndexPool(this.mdice1 - 1, player);
                                if (
                                    await this.AddToTableRect(
                                        this.qex,
                                        new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                    )
                                ) {
                                    this.mdice1 = this.mdice2;
                                    this.mdice2 = this.mdice3;
                                    this.mdice3 = this.mdice4;
                                    this.mdice4 = 0;
                                    await this.IntelectTapaEnd();
                                    return;
                                }
                            } else {
                                this.z11 = 0;
                                for (let i = this.mdice1; i < 6; i++) {
                                    if (this.table[i][1] > 0) {
                                        this.z11 = 1;
                                    }
                                }
                                //////////////////////
                                if (this.z11 === 1) {
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            if (j - this.mdice1 === tp) {
                                                this.qex = this.GetIndexPool(j, player);
                                                if (
                                                    await this.AddToTableRect(
                                                        this.qex,
                                                        new CPoint(
                                                            this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                            this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                        )
                                                    )
                                                ) {
                                                    this.mdice1 = this.mdice2;
                                                    this.mdice2 = this.mdice3;
                                                    this.mdice3 = this.mdice4;
                                                    this.mdice4 = 0;
                                                    await this.IntelectTapaEnd();
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                //////////////////////////////
                                if (this.z11 === 0) {
                                    for (let j = this.mdice1 - 2; j >= 0; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (this.qex === -1) {
                                                this.qex = this.GetIndexPool1(j, player);
                                            }
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ///////////////////////////////////
                        if (this.mdice1 - 1 === tp) {
                            if (this.table[this.mdice1 - 1][1] > 1) {
                                this.qex = this.GetIndexPool(this.mdice1 - 1, player);
                                if (this.qex === -1) {
                                    this.qex = this.GetIndexPool1(this.mdice1 - 1, player);
                                }
                                if (
                                    await this.AddToTableRect(
                                        this.qex,
                                        new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                    )
                                ) {
                                    this.mdice1 = this.mdice2;
                                    this.mdice2 = this.mdice3;
                                    this.mdice3 = this.mdice4;
                                    this.mdice4 = 0;
                                    await this.IntelectTapaEnd();
                                    return;
                                }
                            } else {
                                this.z11 = 0;
                                for (let i = this.mdice1; i < 6; i++) {
                                    if (this.table[i][1] > 0) {
                                        this.z11 = 1;
                                    }
                                }
                                //////////////////////
                                if (this.z11 === 1) {
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            if (j - this.mdice1 === tp) {
                                                this.qex = this.GetIndexPool(j, player);
                                                if (this.qex === -1) {
                                                    this.qex = this.GetIndexPool1(j, player);
                                                }
                                                if (
                                                    await this.AddToTableRect(
                                                        this.qex,
                                                        new CPoint(
                                                            this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                            this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                        )
                                                    )
                                                ) {
                                                    this.mdice1 = this.mdice2;
                                                    this.mdice2 = this.mdice3;
                                                    this.mdice3 = this.mdice4;
                                                    this.mdice4 = 0;
                                                    await this.IntelectTapaEnd();
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (this.qex === -1) {
                                                this.qex = this.GetIndexPool1(j, player);
                                            }
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                //////////////////////////////
                                if (this.table[this.mdice1 - 1][1] > 0) {
                                    this.qex = this.GetIndexPool(this.mdice1 - 1, player);
                                    if (this.qex === -1) {
                                        this.qex = this.GetIndexPool1(this.mdice1 - 1, player);
                                    }
                                    if (
                                        await this.AddToTableRect(
                                            this.qex,
                                            new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                        )
                                    ) {
                                        this.mdice1 = this.mdice2;
                                        this.mdice2 = this.mdice3;
                                        this.mdice3 = this.mdice4;
                                        this.mdice4 = 0;
                                        await this.IntelectTapaEnd();
                                        return;
                                    }
                                }
                                if (this.z11 === 0) {
                                    for (let j = this.mdice1 - 2; j >= 0; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (this.qex === -1) {
                                                this.qex = this.GetIndexPool1(j, player);
                                            }
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ////////////////////////////////////////////////////////////////
                        if (this.mdice1 - 1 < tp) {
                            if (this.table[this.mdice1 - 1][1] > 0) {
                                this.qex = this.GetIndexPool(this.mdice1 - 1, player);
                                if (this.qex === -1) {
                                    this.qex = this.GetIndexPool1(this.mdice1 - 1, player);
                                }
                                if (
                                    await this.AddToTableRect(
                                        this.qex,
                                        new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                    )
                                ) {
                                    this.mdice1 = this.mdice2;
                                    this.mdice2 = this.mdice3;
                                    this.mdice3 = this.mdice4;
                                    this.mdice4 = 0;
                                    await this.IntelectTapaEnd();
                                    return;
                                }
                            } else {
                                this.z11 = 0;
                                for (let i = this.mdice1; i < 6; i++) {
                                    if (this.table[i][1] > 0) {
                                        this.z11 = 1;
                                    }
                                }
                                //////////////////////
                                if (this.z11 === 1) {
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            if (j - this.mdice1 === tp) {
                                                this.qex = this.GetIndexPool(j, player);
                                                if (this.qex === -1) {
                                                    this.qex = this.GetIndexPool1(j, player);
                                                }
                                                if (
                                                    await this.AddToTableRect(
                                                        this.qex,
                                                        new CPoint(
                                                            this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                            this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                        )
                                                    )
                                                ) {
                                                    this.mdice1 = this.mdice2;
                                                    this.mdice2 = this.mdice3;
                                                    this.mdice3 = this.mdice4;
                                                    this.mdice4 = 0;
                                                    await this.IntelectTapaEnd();
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            if (j !== tp) {
                                                this.qex = this.GetIndexPool(j, player);
                                                if (this.qex === -1) {
                                                    this.qex = this.GetIndexPool1(j, player);
                                                }
                                                if (
                                                    await this.AddToTableRect(
                                                        this.qex,
                                                        new CPoint(
                                                            this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                            this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                        )
                                                    )
                                                ) {
                                                    this.mdice1 = this.mdice2;
                                                    this.mdice2 = this.mdice3;
                                                    this.mdice3 = this.mdice4;
                                                    this.mdice4 = 0;
                                                    await this.IntelectTapaEnd();
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    for (let j = 5; j > this.mdice1 - 1; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (this.qex === -1) {
                                                this.qex = this.GetIndexPool1(j, player);
                                            }
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(
                                                        this.tablerect[j - this.mdice1].TopLeft().x + 1,
                                                        this.tablerect[j - this.mdice1].TopLeft().y + 1
                                                    )
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                                //////////////////////////////
                                if (this.z11 === 0) {
                                    for (let j = this.mdice1 - 2; j >= 0; j--) {
                                        if (this.table[j][1] > 0) {
                                            this.qex = this.GetIndexPool(j, player);
                                            if (this.qex === -1) {
                                                this.qex = this.GetIndexPool1(j, player);
                                            }
                                            if (
                                                await this.AddToTableRect(
                                                    this.qex,
                                                    new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1)
                                                )
                                            ) {
                                                this.mdice1 = this.mdice2;
                                                this.mdice2 = this.mdice3;
                                                this.mdice3 = this.mdice4;
                                                this.mdice4 = 0;
                                                await this.IntelectTapaEnd();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                //////////////////////////////////////////end////////////////////////////////////////
                await this.IntelectTapaEnd();
            } else {
                this.KoiENaRed = 1;
                this.strelka = 3;
                this.mdice1 = 0;
                this.mdice2 = 0;
                this.mdice3 = 0;
                this.mdice4 = 0;
                this.ReloadDice();
                this.zarove = false;
            }
        }
    }

    async IntelectTapaEnd() {
        if (this.CheckWinGame(2)) {
            if (!this.wait) {
                await this.InitRezDlg(0);
            }
        }
    }

    GetPipAfterMoveOb(player: number, av: number[][]) {
        let pip = 0;
        if (player === 1) {
            for (let i = 0; i < 24; i++) {
                pip += av[i][0] * (24 - i);
            }
            pip += av[24][0] * 25;
        }
        if (player === 2) {
            for (let i = 0; i < 24; i++) {
                pip += av[i][1] * (i + 1);
            }
            pip += av[25][1] * 25;
        }
        return pip;
    }

    PosToBeKilled(place: number, av: number[][]) {
        let pos: number = 0;
        if (av[24][0] === 0) {
            if (place >= 1 && av[place - 1][0] > 0) {
                pos += 11;
            } //(1)
            if (place >= 2 && av[place - 2][0] > 0) {
                pos += 11;
            } //(2)
            if (place >= 3 && av[place - 3][0] > 0) {
                pos += 11;
            } //(3)
            if (place >= 4 && av[place - 4][0] > 0) {
                pos += 11;
            } //(4)
            if (place >= 5 && av[place - 5][0] > 0) {
                pos += 11;
            } //(5)
            if (place >= 6 && av[place - 6][0] > 0) {
                pos += 11;
            } //(6)
            if (place >= 2 && av[place - 2][0] > 0 && av[place - 1][1] < 2 && av[place - 1][0] > -1) {
                pos++;
            } //1:1(2)
            if (
                place >= 3 &&
                av[place - 3][0] > 0 &&
                ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 2][1] < 2 && av[place - 2][0] > -1))
            ) {
                pos = pos + 2;
            } //1:2(3)
            if (
                place >= 3 &&
                av[place - 3][0] > 0 &&
                av[place - 1][1] < 2 &&
                av[place - 1][0] > -1 &&
                av[place - 2][1] < 2 &&
                av[place - 2][0] > -1
            ) {
                pos++;
            } //1:1(3)
            if (place >= 4 && av[place - 4][0] > 0 && av[place - 2][1] < 2 && av[place - 2][0] > -1) {
                pos++;
            } //2:2(4)
            if (
                place >= 4 &&
                av[place - 4][0] > 0 &&
                ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 3][1] < 2 && av[place - 3][0] > -1))
            ) {
                pos = pos + 2;
            } //1:3(4)
            if (
                place >= 4 &&
                av[place - 4][0] > 0 &&
                av[place - 1][1] < 2 &&
                av[place - 1][0] > -1 &&
                av[place - 2][1] < 2 &&
                av[place - 2][0] > -1 &&
                av[place - 3][1] < 2 &&
                av[place - 3][0] > -1
            ) {
                pos++;
            } //1:1(4)
            if (
                place >= 5 &&
                av[place - 5][0] > 0 &&
                ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
            ) {
                pos = pos + 2;
            } //1:4(5)
            if (
                place >= 5 &&
                av[place - 5][0] > 0 &&
                ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 3][1] < 2 && av[place - 3][0] > -1))
            ) {
                pos = pos + 2;
            } //2:3(5)
            if (
                place >= 6 &&
                av[place - 6][0] > 0 &&
                ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
            ) {
                pos = pos + 2;
            } //1:5(6)
            if (
                place >= 6 &&
                av[place - 6][0] > 0 &&
                ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
            ) {
                pos = pos + 2;
            } //2:4(6)
            if (place >= 6 && av[place - 6][0] > 0 && av[place - 3][1] < 2 && av[place - 3][0] > -1) {
                pos++;
            } //3:3(6)
            if (
                place >= 6 &&
                av[place - 6][0] > 0 &&
                av[place - 2][1] < 2 &&
                av[place - 2][0] > -1 &&
                av[place - 4][1] < 2 &&
                av[place - 4][0] > -1
            ) {
                pos++;
            } //2:2(6)
            if (
                place >= 7 &&
                av[place - 7][0] > 0 &&
                ((av[place - 1][1] < 2 && av[place - 1][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
            ) {
                pos = pos + 2;
            } //1:6(7)
            if (
                place >= 7 &&
                av[place - 7][0] > 0 &&
                ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
            ) {
                pos = pos + 2;
            } //2:5(7)
            if (
                place >= 7 &&
                av[place - 7][0] > 0 &&
                ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 4][1] < 2 && av[place - 4][0] > -1))
            ) {
                pos = pos + 2;
            } //3:4(7)
            if (place >= 8 && av[place - 8][0] > 0 && av[place - 4][1] < 2 && av[place - 4][0] > -1) {
                pos++;
            } //4:4(8)
            if (
                place >= 8 &&
                av[place - 8][0] > 0 &&
                av[place - 2][1] < 2 &&
                av[place - 2][0] > -1 &&
                av[place - 4][1] < 2 &&
                av[place - 4][0] > -1 &&
                av[place - 6][1] < 2 &&
                av[place - 6][0] > -1
            ) {
                pos++;
            } //2:2(8)
            if (
                place >= 8 &&
                av[place - 8][0] > 0 &&
                ((av[place - 2][1] < 2 && av[place - 2][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
            ) {
                pos = pos + 2;
            } //2:6(8)
            if (
                place >= 8 &&
                av[place - 8][0] > 0 &&
                ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
            ) {
                pos = pos + 2;
            } //3:5(8)
            if (
                place >= 9 &&
                av[place - 9][0] > 0 &&
                av[place - 3][1] < 2 &&
                av[place - 3][0] > -1 &&
                av[place - 6][1] < 2 &&
                av[place - 6][0] > -1
            ) {
                pos++;
            } //3:3(9)
            if (
                place >= 9 &&
                av[place - 9][0] > 0 &&
                ((av[place - 3][1] < 2 && av[place - 3][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
            ) {
                pos = pos + 2;
            } //3:6(9)
            if (
                place >= 9 &&
                av[place - 9][0] > 0 &&
                ((av[place - 4][1] < 2 && av[place - 4][0] > -1) || (av[place - 5][1] < 2 && av[place - 5][0] > -1))
            ) {
                pos = pos + 2;
            } //4:5(9)
            if (place >= 10 && av[place - 10][0] > 0 && av[place - 5][1] < 2 && av[place - 5][0] > -1) {
                pos++;
            } //5:5(10)
            if (
                place >= 10 &&
                av[place - 10][0] > 0 &&
                ((av[place - 4][1] < 2 && av[place - 4][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
            ) {
                pos = pos + 2;
            } //4:6(10)
            if (
                place >= 11 &&
                av[place - 11][0] > 0 &&
                ((av[place - 5][1] < 2 && av[place - 5][0] > -1) || (av[place - 6][1] < 2 && av[place - 6][0] > -1))
            ) {
                pos = pos + 2;
            } //5:6(11)
            if (
                place >= 12 &&
                av[place - 12][0] > 0 &&
                av[place - 3][1] < 2 &&
                av[place - 3][0] > -1 &&
                av[place - 6][1] < 2 &&
                av[place - 6][0] > -1 &&
                av[place - 9][1] < 2 &&
                av[place - 9][0] > -1
            ) {
                pos++;
            } //3:3(12)
            if (
                place >= 12 &&
                av[place - 12][0] > 0 &&
                av[place - 4][1] < 2 &&
                av[place - 4][0] > -1 &&
                av[place - 8][1] < 2 &&
                av[place - 8][0] > -1
            ) {
                pos++;
            } //4:4(12)
            if (place >= 12 && av[place - 12][0] > 0 && av[place - 6][1] < 2 && av[place - 6][0] > -1) {
                pos++;
            } //6:6(12)
            if (
                place >= 15 &&
                av[place - 15][0] > 0 &&
                av[place - 5][1] < 2 &&
                av[place - 5][0] > -1 &&
                av[place - 10][1] < 2 &&
                av[place - 10][0] > -1
            ) {
                pos++;
            } //5:5(15)
            if (
                place >= 16 &&
                av[place - 16][0] > 0 &&
                av[place - 4][1] < 2 &&
                av[place - 4][0] > -1 &&
                av[place - 8][1] < 2 &&
                av[place - 8][0] > -1 &&
                av[place - 12][1] < 2 &&
                av[place - 12][0] > -1
            ) {
                pos++;
            } //4:4(16)
            if (
                place >= 18 &&
                av[place - 18][0] > 0 &&
                av[place - 6][1] < 2 &&
                av[place - 6][0] > -1 &&
                av[place - 12][1] < 2 &&
                av[place - 12][0] > -1
            ) {
                pos++;
            } //6:6(18)
            if (
                place >= 20 &&
                av[place - 20][0] > 0 &&
                av[place - 5][1] < 2 &&
                av[place - 5][0] > -1 &&
                av[place - 10][1] < 2 &&
                av[place - 10][0] > -1 &&
                av[place - 15][1] < 2 &&
                av[place - 15][0] > -1
            ) {
                pos++;
            } //5:5(20)
            //6:6-ne stava malko e poleto
        }
        if (av[24][0] >= 1) {
            if (place < 6) {
                pos += 11;
            }
            if (place >= 6) {
                if (av[24][0] === 1) {
                    if (place === 6) {
                        pos += 6 * this.PosToIn(av, 1);
                    }
                    if (place === 7) {
                        if (av[1][1] < 2 || av[5][1] < 2) {
                            pos += 2;
                        }
                        if (av[2][1] < 2 || av[4][1] < 2) {
                            pos += 2;
                        }
                        if (av[4][1] < 2) {
                            pos += 1;
                        }
                    }
                    if (place === 8) {
                        if (av[2][1] < 2 || av[5][1] < 2) {
                            pos += 2;
                        }
                        if (av[3][1] < 2 || av[4][1] < 2) {
                            pos += 2;
                        }
                    }
                    if (place === 9) {
                        if (av[3][1] < 2 || av[5][1] < 2) {
                            pos += 2;
                        }
                        if (av[4][1] < 2) {
                            pos += 1;
                        }
                    }
                    if (place === 10) {
                        if (av[4][1] < 2 || av[5][1] < 2) {
                            pos += 2;
                        }
                    }
                    if (place === 11) {
                        if (av[5][1] < 2) {
                            pos += 1;
                        }
                    }
                }
                for (let i = 1; i < 7; i++) {
                    if (av[i - 1][1] < 2) {
                        if (place > 2 * i && av[place - i][1] < 2 && av[place - 2 * i][1] < 2) {
                            pos++;
                        }
                        if (place === 3 * i - 1 && av[2 * i - 1][1] < 2 && av[3 * i - 1][1] < 2) {
                            pos++;
                        }
                        if (place > i && av[place - i][1] < 2) {
                            pos++;
                        }
                        if (place === 2 * i - 1 && av[place - i][1] < 2) {
                            pos++;
                        }
                    }
                }
                for (let i = 1; i < 7; i++) {
                    if (av[24][0] === 2) {
                        if (place > i && av[place - i][1] < 2) {
                            pos++;
                        }
                        if (place > i && av[place - i][1] < 2) {
                            pos++;
                        }
                    }
                }
            }
        }
        return pos / 36;
    }

    PosToIn(a: number[][], player: number) {
        let pos: number = 0;
        if (player === 1) {
            for (let i = 0; i < 6; i++) {
                if (a[i][1] < 2) {
                    pos++;
                }
            }
        }
        if (player === 2) {
            for (let i = 18; i < 24; i++) {
                if (a[i][0] < 2) {
                    pos++;
                }
            }
        }
        return pos / 6;
    }

    TakeQuefOb(a: number[][]): number {
        let que: number = 0;
        let pip1 = this.GetPipAfterMoveOb(1, a);
        let pip2 = this.GetPipAfterMoveOb(2, a);
        let LastPoolP1: number;
        let FirstPoolP2: number;
        for (let i = 0; i < 26; i++) {
            if (a[i][0] > 0) {
                LastPoolP1 = i;
                break;
            }
        }
        if (a[24][0] > 0) {
            LastPoolP1 = 0;
        }
        if (a[25][1] > 0) {
            FirstPoolP2 = 25;
        } else {
            for (let i = 0; i < 24; i++) {
                if (a[i][1] > 0) {
                    FirstPoolP2 = i;
                }
            }
        }
        if (!this.CheckReadyToExitOb(a, 2) && FirstPoolP2 > LastPoolP1) {
            if (pip2 > 120) {
                if (pip2 - pip1 < 20 && pip1 - pip2 < 20) {
                    //gore dolu ravno
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 10, 50, 50, 50, 55, 60, 105, 110, 115, 120, 125, 130, 220, 220, 220, 220, 220, 220];
                        let que112: number[] = [100, 100, 100, 100, 100, 100, 75, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 2, 2, 2, 4, 4, 4, 6, 10, 10, 10, 10, 10, 35, 40, 45, 50, 53, 55];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (30 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 8;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 20 && pip2 - pip1 < 50) {
                    //izostava malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 10, 55, 55, 60, 60, 60, 80, 80, 80, 80, 80, 80, 150, 150, 150, 150, 150, 150];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 80, 50, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 5, 15, 17, 20, 22, 24, 26];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (50 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 13;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 50) {
                    //izostava mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 25, 25, 25, 25, 25, 25, 75, 75, 75, 75, 75, 100, 100, 100, 100, 100, 100];
                        let que112: number[] = [140, 140, 140, 140, 140, 140, 80, 60, 40, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -30, -35, -60, -75, -80];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (80 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 20;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 20 && pip1 - pip2 < 50) {
                    //napred e malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 15, 45, 50, 60, 70, 70, 130, 130, 130, 130, 170, 200, 250, 250, 250, 250, 250, 250];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 4, 4, 4, 6, 6, 6, 10, 15, 15, 15, 15, 15, 40, 45, 50, 55, 60, 65];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (20 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 7;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 50) {
                    //napred e mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 25, 55, 55, 60, 70, 70, 130, 130, 130, 220, 220, 220, 300, 300, 300, 300, 300, 300];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 40, 20, 10, 10, 10, 10, 10, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 10, 10, 10, 15, 15, 15, 15, 40, 40, 40, 40, 40, 65, 70, 75, 80, 85, 90];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (10 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 4;
                    que += a[24][1] * 30;
                }
            }
            if (pip2 <= 120 && pip2 > 90) {
                if (pip2 - pip1 < 20 && pip1 - pip2 < 20) {
                    //gore dolu ravno
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 15, 50, 55, 60, 70, 70, 120, 120, 120, 120, 120, 120, 230, 230, 230, 230, 230, 230];
                        let que112: number[] = [100, 100, 100, 100, 100, 100, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 2, 2, 2, 4, 4, 4, 6, 10, 10, 10, 10, 10, 30, 35, 40, 45, 50, 55];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (20 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 8;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 20 && pip2 - pip1 < 40) {
                    //izostava malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 25, 25, 30, 30, 30, 80, 80, 80, 80, 80, 80, 150, 150, 150, 150, 150, 150];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 80, 50, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 5, 15, 16, 17, 19, 21, 23];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (40 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 10;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 40) {
                    //izostava mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 15, 15, 15, 15, 15, 15, 25, 25, 25, 25, 25, 90, 90, 90, 90, 90, 90];
                        let que112: number[] = [140, 140, 140, 140, 140, 140, 80, 60, 40, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -30, -35, -60, -75, -80];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (60 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 13;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 20 && pip1 - pip2 < 40) {
                    //napred e malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 15, 25, 55, 60, 60, 60, 140, 140, 140, 140, 140, 200, 260, 260, 260, 260, 260, 260];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 70, 40, 30, 20, 20, 20, 20, 1, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 4, 4, 4, 6, 6, 6, 10, 15, 15, 15, 15, 15, 45, 50, 55, 60, 65, 70];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (20 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 7;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 40) {
                    //napred e mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 45, 75, 75, 80, 80, 80, 200, 200, 200, 220, 220, 220, 320, 320, 320, 320, 320, 320];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 40, 20, 10, 10, 10, 10, 10, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 10, 10, 10, 15, 15, 15, 15, 40, 40, 40, 40, 40, 65, 70, 75, 80, 85, 90];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (10 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 4;
                    que += a[24][1] * 30;
                }
            }
            if (pip2 <= 90 && pip2 > 60) {
                if (pip2 - pip1 < 15 && pip1 - pip2 < 15) {
                    //gore dolu ravno
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 20, 45, 55, 60, 60, 60, 130, 130, 130, 130, 130, 130, 240, 240, 240, 240, 240, 240];
                        let que112: number[] = [100, 100, 100, 100, 100, 100, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 2, 2, 2, 4, 4, 4, 6, 10, 10, 10, 10, 10, 30, 35, 40, 45, 50, 55];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (30 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 8;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 15 && pip2 - pip1 < 35) {
                    //izostava malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 25, 25, 30, 30, 30, 60, 60, 60, 60, 60, 60, 200, 200, 200, 200, 200, 200];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 80, 50, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -30, -35, -50, -65, -70];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (50 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 13;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 35) {
                    //izostava mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 15, 15, 15, 15, 15, 15, 25, 25, 25, 25, 25, 100, 100, 100, 100, 100, 100];
                        let que112: number[] = [140, 140, 140, 140, 140, 140, 80, 60, 40, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -30, -65, -70, -85, -100];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (80 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 20;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 15 && pip1 - pip2 < 35) {
                    //napred e malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 35, 75, 75, 80, 80, 80, 190, 190, 190, 190, 190, 200, 280, 280, 280, 280, 280, 280];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 4, 4, 4, 6, 6, 6, 10, 15, 15, 15, 15, 15, 55, 70, 75, 80, 95, 105];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (10 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 7;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 35) {
                    //napred e mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [
                            3, 3, 3, 5, 5, 5, 35, 65, 65, 180, 180, 180, 300, 300, 300, 320, 320, 220, 560, 560, 560, 560, 560, 560,
                        ];
                        let que112: number[] = [130, 130, 130, 130, 130, 130, 40, 20, 10, 10, 10, 10, 10, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 10, 10, 10, 15, 15, 15, 15, 40, 40, 40, 40, 40, 75, 80, 85, 100, 105, 120];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (5 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 4;
                    que += a[24][1] * 30;
                }
            }
            if (pip2 <= 60) {
                if (pip2 - pip1 < 10 && pip1 - pip2 < 10) {
                    //gore dolu ravno
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 25, 55, 55, 60, 60, 60, 170, 170, 170, 220, 220, 220, 300, 300, 300, 300, 300, 300];
                        let que112: number[] = [100, 100, 100, 100, 100, 100, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 2, 2, 2, 4, 4, 4, 6, 10, 10, 10, 10, 10, 35, 40, 45, 50, 65, 80];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (30 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 8;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 10 && pip2 - pip1 < 25) {
                    //izostava malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 25, 25, 30, 30, 30, 40, 40, 40, 40, 40, 40, 120, 120, 120, 120, 120, 120];
                        let que112: number[] = [120, 120, 120, 120, 120, 120, 80, 50, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [-11, -10, -9, -8, -7, -6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -40, -55, -70, -75, -80];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (50 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 13;
                    que += a[24][1] * 30;
                }
                if (pip2 - pip1 >= 25) {
                    //izostava mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 15, 15, 15, 15, 15, 15, 25, 25, 25, 25, 25, 100, 100, 100, 100, 100, 100];
                        let que112: number[] = [140, 140, 140, 140, 140, 140, 80, 60, 40, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 10, 10, 10, 10, 10, 10, 8, 0, 0, -30, -75, -80, -85, -100];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (80 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 20;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 10 && pip1 - pip2 < 25) {
                    //napred e malko
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 40, 65, 65, 80, 80, 80, 190, 190, 190, 190, 190, 200, 330, 330, 330, 330, 330, 330];
                        let que112: number[] = [100, 100, 100, 100, 100, 100, 70, 40, 30, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 4, 4, 4, 6, 6, 6, 10, 15, 15, 15, 15, 15, 45, 70, 75, 80, 85, 100];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (20 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 7;
                    que += a[24][1] * 30;
                }
                if (pip1 - pip2 >= 25) {
                    //napred e mnogo
                    for (let i = 0; i < 24; i++) {
                        let que111: number[] = [3, 3, 3, 5, 5, 5, 55, 85, 85, 90, 90, 90, 200, 200, 200, 220, 220, 220, 460, 460, 460, 460, 460, 460];
                        let que112: number[] = [150, 150, 150, 150, 150, 150, 40, 20, 10, 10, 10, 10, 10, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0];
                        let que113: number[] = [0, 0, 0, 0, 0, 0, 10, 10, 10, 15, 15, 15, 15, 40, 40, 40, 40, 40, 75, 80, 85, 90, 105, 120];
                        if (a[i][1] === 1) {
                            que -= (this.PosToBeKilled(i, a) * que111[23 - i]) / this.PosToIn(a, 2);
                        }
                        if (a[i][1] >= 2) {
                            que += que112[i];
                        }
                        que -= que113[i] * a[i][1];
                    }
                    que += a[24][0] * (10 * this.PosToIn(a, 1));
                    que += (pip1 - pip2) * 4;
                }
            }
        }
        if (!this.CheckReadyToExitOb(a, 2) && FirstPoolP2 < LastPoolP1 + 2) {
            //
            if (pip2 > pip1 + 15) {
                que -= 2000;
            }
            if (pip2 + 15 < pip1) {
                que += 2000;
                if (FirstPoolP2 === LastPoolP1 + 1 && a[FirstPoolP2][1] === 1) {
                    que -= 400;
                }
            }
            if (pip2 < pip1 + 15 && pip1 < pip2 + 15 && a[FirstPoolP2][1] === 1 && FirstPoolP2 === LastPoolP1 + 1) {
                que -= 200;
            }
            let quef1111: number[] = [
                100, 100, 100, 100, 100, 100, 50, 45, 40, 30, 20, 10, -10, -20, -30, -40, -50, -100, -130, -160, -180, -200, -200, -200,
            ];
            for (let i = 0; i < 24; i++) {
                que += a[i][1] * quef1111[i];
            }
        }
        if (this.CheckReadyToExitOb(a, 2)) {
            que += 5000;
            for (let i = 0; i < 24; i++) {
                let que111: number[] = [3, 3, 3, 5, 5, 5, 7, 45, 45, 50, 50, 50, 80, 100, 100, 120, 120, 120, 460, 460, 460, 460, 460, 460];
                let que113: number[] = [15, 13, 11, 9, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (a[i][1] === 1) {
                    que -= this.PosToBeKilled(i, a) * que111[23 - i];
                }
                if (a[i][1] === 0) {
                    que -= que113[i];
                }
            }
            que += a[24][1] * 60;
        }
        return this.MyMathfloor(que);
    }

    FindAllPosMovesObP2ch() {
        if (this.dice1 === this.dice2) {
            this.full();
            for (let j = 0; j < 26; j++) {
                this.AllPosPosChP2[0][0][j][0] = this.table[j][0];
                this.AllPosPosChP2[0][0][j][1] = this.table[j][1];
            }
            this.AllPosMovesChP2Br[0] = 1;
            for (let i = 1; i < 5; i++) {
                this.AllPosMovesChP2Br[i] = 0;
            }
            for (let t = 1; t < 5; t++) {
                if ((t === 1 && this.mdice1 > 0) || (t === 2 && this.mdice2 > 0) || (t === 3 && this.mdice3 > 0) || (t === 4 && this.mdice4 > 0)) {
                    for (let l = 0; l < this.AllPosMovesChP2Br[t - 1]; l++) {
                        for (let i = 0; i < 26; i++) {
                            if (i === 25 && this.AllPosPosChP2[t - 1][l][25][1] > 0) {
                                if (this.AllPosPosChP2[t - 1][l][24 - this.dice1][0] === 0) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                    }
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][25][1]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24 - this.mdice1][1]++;
                                    for (let q = 0; q < 4; q++) {
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                    }
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = 25;
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = 24 - this.mdice1;
                                    this.AllPosMovesChP2Br[t]++;
                                }
                                if (this.AllPosPosChP2[t - 1][l][24 - this.mdice1][0] === 1) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                    }
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24 - this.mdice1][0]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24][0]++;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][25][1]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24 - this.mdice1][1]++;
                                    for (let q = 0; q < 4; q++) {
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                    }
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = 25;
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = 24 - this.mdice1;
                                    this.AllPosMovesChP2Br[t]++;
                                }
                            }
                            if (this.AllPosPosChP2[t - 1][l][25][1] === 0) {
                                if (
                                    i - this.mdice1 >= 0 &&
                                    this.AllPosPosChP2[t - 1][l][i][1] > 0 &&
                                    this.AllPosPosChP2[t - 1][l][i - this.mdice1][0] === 0
                                ) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                    }
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i][1]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i - this.mdice1][1]++;
                                    for (let q = 0; q < t - 1; q++) {
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                    }
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = i;
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = i - this.mdice1;
                                    this.AllPosMovesChP2Br[t]++;
                                }
                                if (
                                    i - this.mdice1 >= 0 &&
                                    this.AllPosPosChP2[t - 1][l][i][1] > 0 &&
                                    this.AllPosPosChP2[t - 1][l][i - this.mdice1][0] === 1
                                ) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                    }
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i - this.mdice1][0]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24][0]++;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i][1]--;
                                    this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i - this.mdice1][1]++;
                                    for (let q = 0; q < 4; q++) {
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                    }
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = i;
                                    this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = i - this.mdice1;
                                    this.AllPosMovesChP2Br[t]++;
                                }
                                if (this.CheckReadyToExitOb(this.AllPosPosChP2[t - 1][l], 2) && this.AllPosPosChP2[t - 1][l][i][1] > 0) {
                                    if (i - this.mdice1 === -1) {
                                        for (let j = 0; j < 26; j++) {
                                            this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                            this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                        }
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i][1]--;
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24][1]++;
                                        for (let q = 0; q < 4; q++) {
                                            this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                            this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                        }
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = i;
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = 24;
                                        this.AllPosMovesChP2Br[t]++;
                                    }
                                    if (
                                        i - this.mdice1 < -1 &&
                                        this.AllPosPosChP2[t - 1][l][i + 1][1] === 0 &&
                                        this.AllPosPosChP2[t - 1][l][i + 2][1] === 0 &&
                                        this.AllPosPosChP2[t - 1][l][i + 3][1] === 0 &&
                                        this.AllPosPosChP2[t - 1][l][i + 4][1] === 0 &&
                                        this.AllPosPosChP2[t - 1][l][i + 5][1] === 0
                                    ) {
                                        for (let j = 0; j < 26; j++) {
                                            this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][0] = this.AllPosPosChP2[t - 1][l][j][0];
                                            this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][j][1] = this.AllPosPosChP2[t - 1][l][j][1];
                                        }
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][i][1]--;
                                        this.AllPosPosChP2[t][this.AllPosMovesChP2Br[t]][24][1]++;
                                        for (let q = 0; q < 4; q++) {
                                            this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][0] = this.AllPosMovesChP2[t - 1][l][q][0];
                                            this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][q][1] = this.AllPosMovesChP2[t - 1][l][q][1];
                                        }
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][0] = i;
                                        this.AllPosMovesChP2[t][this.AllPosMovesChP2Br[t]][t - 1][1] = 24;
                                        this.AllPosMovesChP2Br[t]++;
                                    }
                                }
                            }
                            ////////////////////////
                        }
                    }
                }
            }
        }
    }

    FindAllPosMovesObP2() {
        if (this.dice1 !== this.dice2) {
            this.full();
            this.AllPosMovesP22br = 0;
            this.AllPosMovesP211br = 0;
            this.AllPosMovesP212br = 0;
            if (this.mdice1 > 0) {
                for (let i = 0; i < 26; i++) {
                    if (i === 25 && this.table[25][1] > 0) {
                        if (this.table[24 - this.mdice1][0] === 0) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP211[this.AllPosMovesP211br][25][1]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][24 - this.mdice1][1]++;
                            this.AllPosMovesP211[this.AllPosMovesP211br][0] = 25;
                            this.AllPosMovesP211[this.AllPosMovesP211br][1] = 24 - this.mdice1;
                            this.AllPosMovesP211br++;
                        }
                        if (this.table[24 - this.mdice1][0] === 1) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP211[this.AllPosMovesP211br][24 - this.mdice1][0]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][24][0]++;
                            this.AllPosPosP211[this.AllPosMovesP211br][25][1]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][24 - this.mdice1][1]++;
                            this.AllPosMovesP211[this.AllPosMovesP211br][0] = 25;
                            this.AllPosMovesP211[this.AllPosMovesP211br][1] = 24 - this.mdice1;
                            this.AllPosMovesP211br++;
                        }
                    }
                    if (this.table[25][1] === 0) {
                        if (i - this.mdice1 >= 0 && this.table[i][1] > 0 && this.table[i - this.mdice1][0] === 0) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP211[this.AllPosMovesP211br][i][1]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][i - this.mdice1][1]++;
                            this.AllPosMovesP211[this.AllPosMovesP211br][0] = i;
                            this.AllPosMovesP211[this.AllPosMovesP211br][1] = i - this.mdice1;
                            this.AllPosMovesP211br++;
                        }
                        if (i - this.mdice1 >= 0 && this.table[i][1] > 0 && this.table[i - this.mdice1][0] === 1) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP211[this.AllPosMovesP211br][i - this.mdice1][0]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][24][0]++;
                            this.AllPosPosP211[this.AllPosMovesP211br][i][1]--;
                            this.AllPosPosP211[this.AllPosMovesP211br][i - this.mdice1][1]++;
                            this.AllPosMovesP211[this.AllPosMovesP211br][0] = i;
                            this.AllPosMovesP211[this.AllPosMovesP211br][1] = i - this.mdice1;
                            this.AllPosMovesP211br++;
                        }
                        if (this.CheckReadyToExitOb(this.table, 2) && this.table[i][1] > 0) {
                            if (i - this.mdice1 === -1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                    this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                                }
                                this.AllPosPosP211[this.AllPosMovesP211br][i][1]--;
                                this.AllPosPosP211[this.AllPosMovesP211br][24][1]++;
                                this.AllPosMovesP211[this.AllPosMovesP211br][0] = i;
                                this.AllPosMovesP211[this.AllPosMovesP211br][1] = 24;
                                this.AllPosMovesP211br++;
                            }
                            if (
                                i - this.mdice1 < -1 &&
                                this.table[i + 1][1] === 0 &&
                                this.table[i + 2][1] === 0 &&
                                this.table[i + 3][1] === 0 &&
                                this.table[i + 4][1] === 0 &&
                                this.table[i + 5][1] === 0
                            ) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP211[this.AllPosMovesP211br][j][0] = this.table[j][0];
                                    this.AllPosPosP211[this.AllPosMovesP211br][j][1] = this.table[j][1];
                                }
                                this.AllPosPosP211[this.AllPosMovesP211br][i][1]--;
                                this.AllPosPosP211[this.AllPosMovesP211br][24][1]++;
                                this.AllPosMovesP211[this.AllPosMovesP211br][0] = i;
                                this.AllPosMovesP211[this.AllPosMovesP211br][1] = 24;
                                this.AllPosMovesP211br++;
                            }
                        }
                    }
                }
            }
            if (this.mdice2 > 0) {
                for (let i = 0; i < 26; i++) {
                    if (i === 25 && this.table[25][1] > 0) {
                        if (this.table[24 - this.mdice2][0] === 0) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP212[this.AllPosMovesP212br][25][1]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][24 - this.mdice2][1]++;
                            this.AllPosMovesP212[this.AllPosMovesP212br][0] = 25;
                            this.AllPosMovesP212[this.AllPosMovesP212br][1] = 24 - this.mdice2;
                            this.AllPosMovesP212br++;
                        }
                        if (this.table[24 - this.mdice2][0] === 1) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP212[this.AllPosMovesP212br][24 - this.mdice2][0]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][24][0]++;
                            this.AllPosPosP212[this.AllPosMovesP212br][25][1]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][24 - this.mdice2][1]++;
                            this.AllPosMovesP212[this.AllPosMovesP212br][0] = 25;
                            this.AllPosMovesP212[this.AllPosMovesP212br][1] = 24 - this.mdice2;
                            this.AllPosMovesP212br++;
                        }
                    }
                    if (this.table[25][1] === 0) {
                        if (i - this.mdice2 >= 0 && this.table[i][1] > 0 && this.table[i - this.mdice2][0] === 0) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP212[this.AllPosMovesP212br][i][1]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][i - this.mdice2][1]++;
                            this.AllPosMovesP212[this.AllPosMovesP212br][0] = i;
                            this.AllPosMovesP212[this.AllPosMovesP212br][1] = i - this.mdice2;
                            this.AllPosMovesP212br++;
                        }
                        if (i - this.mdice2 >= 0 && this.table[i][1] > 0 && this.table[i - this.mdice2][0] === 1) {
                            for (let j = 0; j < 26; j++) {
                                this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                            }
                            this.AllPosPosP212[this.AllPosMovesP212br][i - this.mdice2][0]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][24][0]++;
                            this.AllPosPosP212[this.AllPosMovesP212br][i][1]--;
                            this.AllPosPosP212[this.AllPosMovesP212br][i - this.mdice2][1]++;
                            this.AllPosMovesP212[this.AllPosMovesP212br][0] = i;
                            this.AllPosMovesP212[this.AllPosMovesP212br][1] = i - this.mdice2;
                            this.AllPosMovesP212br++;
                        }
                        if (this.CheckReadyToExitOb(this.table, 2) && this.table[i][1] > 0) {
                            if (i - this.mdice2 === -1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                    this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                                }
                                this.AllPosPosP212[this.AllPosMovesP212br][i][1]--;
                                this.AllPosPosP212[this.AllPosMovesP212br][24][1]++;
                                this.AllPosMovesP212[this.AllPosMovesP212br][0] = i;
                                this.AllPosMovesP212[this.AllPosMovesP212br][1] = 24;
                                this.AllPosMovesP212br++;
                            }
                            if (
                                i - this.mdice2 < -1 &&
                                this.table[i + 1][1] === 0 &&
                                this.table[i + 2][1] === 0 &&
                                this.table[i + 3][1] === 0 &&
                                this.table[i + 4][1] === 0 &&
                                this.table[i + 5][1] === 0
                            ) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP212[this.AllPosMovesP212br][j][0] = this.table[j][0];
                                    this.AllPosPosP212[this.AllPosMovesP212br][j][1] = this.table[j][1];
                                }
                                this.AllPosPosP212[this.AllPosMovesP212br][i][1]--;
                                this.AllPosPosP212[this.AllPosMovesP212br][24][1]++;
                                this.AllPosMovesP212[this.AllPosMovesP212br][0] = i;
                                this.AllPosMovesP212[this.AllPosMovesP212br][1] = 24;
                                this.AllPosMovesP212br++;
                            }
                        }
                    }
                }
            }
            ////////////////////////////////////////////////////////////////////////////////
            if (this.mdice1 > 0 && this.mdice2 > 0) {
                for (let l = 0; l < this.AllPosMovesP211br; l++) {
                    for (let i = 0; i < 26; i++) {
                        if (i === 25 && this.AllPosPosP211[l][25][1] > 0) {
                            if (this.AllPosPosP211[l][24 - this.mdice2][0] === 0) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][25][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice2][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = 25;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24 - this.mdice2;
                                this.AllPosMovesP22br++;
                            }
                            if (this.AllPosPosP211[l][24 - this.mdice2][0] === 1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice2][0]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24][0]++;
                                this.AllPosPosP22[this.AllPosMovesP22br][25][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice2][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = 25;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24 - this.mdice2;
                                this.AllPosMovesP22br++;
                            }
                        }
                        ///////////
                        if (this.AllPosPosP211[l][25][1] === 0) {
                            if (i - this.mdice2 >= 0 && this.AllPosPosP211[l][i][1] > 0 && this.AllPosPosP211[l][i - this.mdice2][0] === 0) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice2][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = i - this.mdice2;
                                this.AllPosMovesP22br++;
                            }
                            if (i - this.mdice2 >= 0 && this.AllPosPosP211[l][i][1] > 0 && this.AllPosPosP211[l][i - this.mdice2][0] === 1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice2][0]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24][0]++;
                                this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice2][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = i - this.mdice2;
                                this.AllPosMovesP22br++;
                            }
                            if (this.CheckReadyToExitOb(this.AllPosPosP211[l], 2) && this.AllPosPosP211[l][i][1] > 0) {
                                if (i - this.mdice2 === -1) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                    }
                                    this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                    this.AllPosPosP22[this.AllPosMovesP22br][24][1]++;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24;
                                    this.AllPosMovesP22br++;
                                }
                                if (
                                    i - this.mdice2 < -1 &&
                                    this.AllPosPosP211[l][i + 1][1] === 0 &&
                                    this.AllPosPosP211[l][i + 2][1] === 0 &&
                                    this.AllPosPosP211[l][i + 3][1] === 0 &&
                                    this.AllPosPosP211[l][i + 4][1] === 0 &&
                                    this.AllPosPosP211[l][i + 5][1] === 0
                                ) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP211[l][j][0];
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP211[l][j][1];
                                    }
                                    this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                    this.AllPosPosP22[this.AllPosMovesP22br][24][1]++;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP211[l][0];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP211[l][1];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24;
                                    this.AllPosMovesP22br++;
                                }
                            }
                        }
                    }
                }
                for (let l = 0; l < this.AllPosMovesP212br; l++) {
                    for (let i = 0; i < 26; i++) {
                        if (i === 25 && this.AllPosPosP212[l][25][1] > 0) {
                            if (this.AllPosPosP212[l][24 - this.mdice1][0] === 0) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][25][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice1][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = 25;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24 - this.mdice1;
                                this.AllPosMovesP22br++;
                            }
                            if (this.AllPosPosP212[l][24 - this.mdice1][0] === 1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice1][0]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24][0]++;
                                this.AllPosPosP22[this.AllPosMovesP22br][25][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24 - this.mdice1][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = 25;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24 - this.mdice1;
                                this.AllPosMovesP22br++;
                            }
                        }
                        ///////////
                        if (this.AllPosPosP212[l][25][1] === 0) {
                            if (i - this.mdice1 >= 0 && this.AllPosPosP212[l][i][1] > 0 && this.AllPosPosP212[l][i - this.mdice1][0] === 0) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice1][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = i - this.mdice1;
                                this.AllPosMovesP22br++;
                            }
                            if (i - this.mdice1 >= 0 && this.AllPosPosP212[l][i][1] > 0 && this.AllPosPosP212[l][i - this.mdice1][0] === 1) {
                                for (let j = 0; j < 26; j++) {
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                    this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                }
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice1][0]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][24][0]++;
                                this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                this.AllPosPosP22[this.AllPosMovesP22br][i - this.mdice1][1]++;
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = i - this.mdice1;
                                this.AllPosMovesP22br++;
                            }
                            if (this.CheckReadyToExitOb(this.AllPosPosP212[l], 2) && this.AllPosPosP212[l][i][1] > 0) {
                                if (i - this.mdice1 === -1) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                    }
                                    this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                    this.AllPosPosP22[this.AllPosMovesP22br][24][1]++;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24;
                                    this.AllPosMovesP22br++;
                                }
                                if (
                                    i - this.mdice1 < -1 &&
                                    this.AllPosPosP212[l][i + 1][1] === 0 &&
                                    this.AllPosPosP212[l][i + 2][1] === 0 &&
                                    this.AllPosPosP212[l][i + 3][1] === 0 &&
                                    this.AllPosPosP212[l][i + 4][1] === 0 &&
                                    this.AllPosPosP212[l][i + 5][1] === 0
                                ) {
                                    for (let j = 0; j < 26; j++) {
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][0] = this.AllPosPosP212[l][j][0];
                                        this.AllPosPosP22[this.AllPosMovesP22br][j][1] = this.AllPosPosP212[l][j][1];
                                    }
                                    this.AllPosPosP22[this.AllPosMovesP22br][i][1]--;
                                    this.AllPosPosP22[this.AllPosMovesP22br][24][1]++;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][0] = this.AllPosMovesP212[l][0];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][0][1] = this.AllPosMovesP212[l][1];
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][0] = i;
                                    this.AllPosMovesP22[this.AllPosMovesP22br][1][1] = 24;
                                    this.AllPosMovesP22br++;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ChooseBestOb() {
        if (this.dice1 === this.dice2) {
            for (let i = 0; i < 4; i++) {
                this.toplay[i][0] = -1;
                this.toplay[i][1] = -1;
            }
            this.FindAllPosMovesObP2ch();
            for (let i = 4; i > 0; i--) {
                if (this.AllPosMovesChP2Br[i] > 0) {
                    let Best: number = 0;
                    let BestPos: number = 0;
                    Best = this.TakeQuefOb(this.AllPosPosChP2[i][0]);
                    for (let j = 0; j < this.AllPosMovesChP2Br[i]; j++) {
                        if (this.TakeQuefOb(this.AllPosPosChP2[i][j]) > Best) {
                            Best = this.TakeQuefOb(this.AllPosPosChP2[i][j]);
                            BestPos = j;
                        }
                    }
                    for (let j = 0; j < i; j++) {
                        this.toplay[j][0] = this.AllPosMovesChP2[i][BestPos][j][0];
                        this.toplay[j][1] = this.AllPosMovesChP2[i][BestPos][j][1];
                    }
                    break;
                }
            }
        }
        if (this.dice1 !== this.dice2) {
            for (let i = 0; i < 4; i++) {
                this.toplay[i][0] = -1;
                this.toplay[i][1] = -1;
            }
            this.FindAllPosMovesObP2();
            if (this.AllPosMovesP22br > 0) {
                let Best: number = 0;
                let BestPos: number = 0;
                Best = this.TakeQuefOb(this.AllPosPosP22[0]);
                for (let j = 0; j < this.AllPosMovesP22br; j++) {
                    if (this.TakeQuefOb(this.AllPosPosP22[j]) > Best) {
                        Best = this.TakeQuefOb(this.AllPosPosP22[j]);
                        BestPos = j;
                    }
                }
                this.toplay[0][0] = this.AllPosMovesP22[BestPos][0][0];
                this.toplay[0][1] = this.AllPosMovesP22[BestPos][0][1];
                this.toplay[1][0] = this.AllPosMovesP22[BestPos][1][0];
                this.toplay[1][1] = this.AllPosMovesP22[BestPos][1][1];
            }
            if (this.AllPosMovesP22br === 0) {
                let Best1 = -1,
                    Best2 = -1;
                let BestPos1 = 0,
                    BestPos2 = 0;
                if (this.AllPosMovesP211br > 0) {
                    Best1 = this.TakeQuefOb(this.AllPosPosP211[0]);
                }
                if (this.AllPosMovesP211br > 0) {
                    Best2 = this.TakeQuefOb(this.AllPosPosP212[0]);
                }
                for (let j = 0; j < this.AllPosMovesP211br; j++) {
                    if (this.TakeQuefOb(this.AllPosPosP211[j]) > Best1) {
                        Best1 = this.TakeQuefOb(this.AllPosPosP211[j]);
                        BestPos1 = j;
                    }
                }
                for (let j = 0; j < this.AllPosMovesP212br; j++) {
                    if (this.TakeQuefOb(this.AllPosPosP212[j]) > Best2) {
                        Best2 = this.TakeQuefOb(this.AllPosPosP212[j]);
                        BestPos2 = j;
                    }
                }
                if (this.AllPosMovesP211br > 0 && this.AllPosMovesP212br > 0) {
                    if (Best2 > Best1) {
                        this.toplay[0][0] = this.AllPosMovesP212[BestPos2][0];
                        this.toplay[0][1] = this.AllPosMovesP212[BestPos2][1];
                    } else {
                        this.toplay[0][0] = this.AllPosMovesP211[BestPos1][0];
                        this.toplay[0][1] = this.AllPosMovesP211[BestPos1][1];
                    }
                } else {
                    if (this.AllPosMovesP211br > 0) {
                        this.toplay[0][0] = this.AllPosMovesP211[BestPos1][0];
                        this.toplay[0][1] = this.AllPosMovesP211[BestPos1][1];
                    } else {
                        this.toplay[0][0] = this.AllPosMovesP212[BestPos2][0];
                        this.toplay[0][1] = this.AllPosMovesP212[BestPos2][1];
                    }
                }
            }
        }
        for (let i = 0; i < 4; i++) {
            if (this.toplay[i][1] === 24) {
                this.toplay[i][1] = 25;
            }
        }
    }

    async IntelectTabla() {
        if (this.zarove) {
            while (this.mdice1 === 0 && (this.mdice2 > 0 || this.mdice3 > 0 || this.mdice4 > 0)) {
                //promeniano
                this.mdice1 = this.mdice2;
                this.mdice2 = this.mdice3;
                this.mdice3 = this.mdice4;
                this.mdice4 = 0;
            }
            let player = 2;
            if (this.CheckMove(player)) {
                let rexit: boolean = this.CheckReadyToExit(player);
                this.ChooseBestOb();
                if (this.toplay[0][0] !== -1) {
                    let q1 = this.GetIndexPool(this.toplay[0][0], player);
                    if (q1 === -1) {
                        q1 = this.GetIndexPool1(this.toplay[0][0], player);
                    }
                    if (q1 > -1) {
                        if (
                            await this.AddToTableRect(
                                q1,
                                new CPoint(this.tablerect[this.toplay[0][1]].TopLeft().x + 1, this.tablerect[this.toplay[0][1]].TopLeft().y + 1)
                            )
                        ) {
                            this.toplay[0][0] = -1;
                            this.toplay[0][1] = -1;
                            await this.IntelectTablaEnd();
                            return;
                        }
                    }
                }
                /////////////////////////////////////////////
                else {
                    if (this.toplay[1][0] !== -1) {
                        let q2 = this.GetIndexPool(this.toplay[1][0], player);
                        if (q2 === -1) {
                            q2 = this.GetIndexPool1(this.toplay[1][0], player);
                        }
                        if (q2 > -1) {
                            if (
                                await this.AddToTableRect(
                                    q2,
                                    new CPoint(this.tablerect[this.toplay[1][1]].TopLeft().x + 1, this.tablerect[this.toplay[1][1]].TopLeft().y + 1)
                                )
                            ) {
                                this.toplay[1][0] = -1;
                                this.toplay[1][1] = -1;
                                await this.IntelectTablaEnd();
                                return;
                            }
                        }
                    }

                    ////////////////////////////////////
                    else {
                        if (this.toplay[2][0] !== -1) {
                            let q3 = this.GetIndexPool(this.toplay[2][0], player);
                            if (q3 === -1) {
                                q3 = this.GetIndexPool1(this.toplay[2][0], player);
                            }
                            if (q3 > -1) {
                                if (
                                    await this.AddToTableRect(
                                        q3,
                                        new CPoint(
                                            this.tablerect[this.toplay[2][1]].TopLeft().x + 1,
                                            this.tablerect[this.toplay[2][1]].TopLeft().y + 1
                                        )
                                    )
                                ) {
                                    this.toplay[2][0] = -1;
                                    this.toplay[2][1] = -1;
                                    await this.IntelectTablaEnd();
                                    return;
                                }
                            }
                        }
                        ///////////////////////////
                        else {
                            if (this.toplay[3][0] !== -1) {
                                let q4 = this.GetIndexPool(this.toplay[3][0], player);
                                if (q4 === -1) {
                                    q4 = this.GetIndexPool1(this.toplay[3][0], player);
                                }
                                if (q4 > -1) {
                                    if (
                                        await this.AddToTableRect(
                                            q4,
                                            new CPoint(
                                                this.tablerect[this.toplay[3][1]].TopLeft().x + 1,
                                                this.tablerect[this.toplay[3][1]].TopLeft().y + 1
                                            )
                                        )
                                    ) {
                                        this.toplay[3][0] = -1;
                                        this.toplay[3][1] = -1;
                                        await this.IntelectTablaEnd();
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                //////////////////////////////////////////////////////////
                for (let i = 15; i < 30; i++) {
                    for (let a = 0; a < 24; a++) {
                        if (await this.AddToTableRect(i, new CPoint(this.tablerect[a].TopLeft().x + 1, this.tablerect[a].TopLeft().y + 1))) {
                            await this.IntelectTablaEnd();
                            return;
                        }
                    }
                }
                if (rexit) {
                    for (let i = 15; i < 30; i++) {
                        if (await this.AddToTableRect(i, new CPoint(this.tablerect[25].TopLeft().x + 1, this.tablerect[25].TopLeft().y + 1))) {
                            await this.IntelectTablaEnd();
                            return;
                        }
                    }
                }
                await this.IntelectTablaEnd();
            } else {
                this.KoiENaRed = 1;
                this.strelka = 3;
                this.mdice1 = 0;
                this.mdice2 = 0;
                this.mdice3 = 0;
                this.mdice4 = 0;
                this.ReloadDice();
                this.zarove = false;
            }
        }
    }

    async IntelectTablaEnd() {
        if (this.CheckWinGame(2)) {
            if (!this.wait) {
                await this.InitRezDlg(0);
            }
        }
    }

    async OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);
        if (!this.watch) {
            if (!this.wait) {
                if (this.KoiENaRed === 1) {
                    if (
                        (this.movepool > -1 && this.ScaleRect(this.moverect).PtInRect(point)) ||
                        (this.ScaleRect(this.tablerect[24]).PtInRect(point) && this.CheckReadyToExit(1))
                    ) {
                        this.poolpoint[this.movepool] = new CPoint(point.x / GlobalVariables.scale, point.y / GlobalVariables.scale);
                        this.setState({});
                        if (point.x < 80 * GlobalVariables.scale) {
                            await this.OnLButtonUp(point);
                        }
                    }
                }
            }
        }

        if (this.ShowNewGameButton()) {
            if (this.ScaleRect(new CRect(657, 394, 657 + 27, 394 + 27)).PtInRect(point)) {
                this.cursor = GlobalConstants.CURSOR_HAND;
                this.setState({});
                return;
            }
        }
        if (this.OP) {
            if (GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.Name[i] !== 'Computer2') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                this.cursor = GlobalConstants.CURSOR_WATCH;
                this.setState({});
                return;
            }
            if (this.P2OP || !this.Server) {
                if (this.OP && this.KoiENaRed === 1 && !this.wait && !this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.cursor = GlobalConstants.CURSOR_HAND;
                            this.setState({});
                            return;
                        }
                    }
                }
            }
        }
        if (!this.wait && !this.watch) {
            if (this.KoiENaRed === 1) {
                if (this.zarove) {
                    for (let i = 0; i < 15; i++) {
                        if (
                            point.x / GlobalVariables.scale < this.poolpoint[i].x + 15 * GlobalVariables.scale &&
                            point.x / GlobalVariables.scale > this.poolpoint[i].x - 15 * GlobalVariables.scale &&
                            point.y / GlobalVariables.scale < this.poolpoint[i].y + 15 * GlobalVariables.scale &&
                            point.y / GlobalVariables.scale > this.poolpoint[i].y - 15 * GlobalVariables.scale
                        ) {
                            if (this.movepool < 0) {
                                if (this.CheckMovePool(i)) {
                                    this.cursor = GlobalConstants.CURSOR_HAND;
                                    this.setState({});
                                    return;
                                }
                            } else {
                                this.cursor = GlobalConstants.CURSOR_CLOSE_HAND;
                                this.setState({});
                                return;
                            }
                        }
                    }
                }
                if (!this.zarove) {
                    if (
                        (point.x / GlobalVariables.scale > this.dice_point1.x - (GlobalVariables.diceWidth * GlobalVariables.scale) / 2 &&
                            point.x / GlobalVariables.scale < this.dice_point1.x + (GlobalVariables.diceWidth * GlobalVariables.scale) / 2 &&
                            point.y / GlobalVariables.scale > this.dice_point1.y - (GlobalVariables.diceHeight * GlobalVariables.scale) / 2 &&
                            point.y / GlobalVariables.scale < this.dice_point1.y + (GlobalVariables.diceHeight * GlobalVariables.scale) / 2) ||
                        (point.x / GlobalVariables.scale > this.dice_point2.x - (GlobalVariables.diceWidth * GlobalVariables.scale) / 2 &&
                            point.x / GlobalVariables.scale < this.dice_point2.x + (GlobalVariables.diceWidth * GlobalVariables.scale) / 2 &&
                            point.y / GlobalVariables.scale > this.dice_point2.y - (GlobalVariables.diceHeight * GlobalVariables.scale) / 2 &&
                            point.y / GlobalVariables.scale < this.dice_point2.y + (GlobalVariables.diceHeight * GlobalVariables.scale) / 2)
                    ) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                        this.setState({});
                        return;
                    }
                }
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    async OnLButtonDblClk(point: CPoint) {
        if (!this.watch) {
            if (!this.wait) {
                if (this.KoiENaRed === 1) {
                    if (this.zarove) {
                        for (let i = 0; i < 15; i++) {
                            if (
                                point.x < (this.poolpoint[i].x + 15) * GlobalVariables.scale &&
                                point.x > (this.poolpoint[i].x - 15) * GlobalVariables.scale &&
                                point.y < (this.poolpoint[i].y + 15) * GlobalVariables.scale &&
                                point.y > (this.poolpoint[i].y - 15) * GlobalVariables.scale
                            ) {
                                if (this.CheckMovePool(i)) {
                                    this.movepool = i;
                                    this.sit_out_count = 0;
                                }
                            }
                        }
                        if (this.movepool >= 0) {
                            this.FindMarked(this.movepool);
                            let table_rect = this.GetArrowTableRect();
                            if (
                                await this.AddToTableRect(
                                    this.movepool,
                                    new CPoint(this.tablerect[table_rect].BottomRight().x - 10, this.tablerect[table_rect].BottomRight().y - 10)
                                )
                            ) {
                                this.movepool = -1;
                                this.setState({});
                                if (this.CheckWinGame(1)) {
                                    if (!this.wait) {
                                        await this.InitRezDlg(0);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async OnLButtonUp(point: CPoint) {
        if (this.movepool >= 0) {
            if (!this.watch) {
                if (!this.wait) {
                    if (this.KoiENaRed === 1) {
                        if (this.zarove) {
                            await this.AddToTableRect(this.movepool, new CPoint(point.x / GlobalVariables.scale, point.y / GlobalVariables.scale));
                            this.movepool = -1;
                            this.setState({});
                            if (this.CheckWinGame(1)) {
                                if (!this.wait) {
                                    await this.InitRezDlg(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    DrawStrelka(pos: number) {
        if (this.KoiENaRed <= 0) {
            return null;
        }
        if (this.strelka > 0 && !this.wait) {
            return this.DrawStrelkaPng(pos, this.props.m_pwin.game);
        } else {
            return null;
        }
    }

    Timer1() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.SP === true) {
            if (this.newgame === false) {
                this.newgame = true;
                this.endgame = false;
                this.wait = false;
                this.setState({});
            }
        }
        if (this.OP) {
            if (this.Server && this.endgame && (this.P2G || !this.P2OP) && this.P1G) {
                if (this.P2OP === true) {
                    this.P2G = false;
                }
                this.P1G = false;
                if (this.tab3 && this.rez1 < 7 && this.rez2 < 7) {
                    this.InitGame();
                } else {
                    this.InitNewGame();
                }
                if (this.P2OP || this.HaveWatch) {
                    this.SendKoiENaRed();
                }
                this.endgame = false;
                this.wait = false;
                this.online_timer = this.online_timer_time;
            }
            if (this.newgame === false) {
                this.newgame = true;
                this.setState({});
            }
        }
    }

    DrawTimer() {
        let res = [];
        if (!this.watch) {
            if (this.OP && (this.P2OP || !this.Server)) {
                if (this.OP && this.KoiENaRed === 1 && !this.wait && !this.CheckWinGame(2) && !this.CheckWinGame(1)) {
                    res.push(this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer'));
                    if (this.pause === 0) {
                        res.push(
                            <CPicture
                                image={this.p_bmp}
                                m_x={this.pause_rect.left}
                                m_y={this.pause_rect.top}
                                m_Width={20}
                                m_Height={20}
                                cursor={this.cursor}
                                key={'pause'}
                            />
                        );
                    }
                }
            }
            if (this.OP && this.KoiENaRed !== 1) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    res.push(this.DrawBorderText('Pause' + ': ' + this.pause_player, 660, this.pause_rect.top, 'DrawTimer'));
                }
            }
        }
        return res;
    }

    DrawViewElements() {
        return (
            <View key="DrawViewElements">
                <CPicture
                    image={'backgammon/board/' + this.boardColor + '.gif'}
                    m_x={130}
                    m_y={16}
                    m_Width={523}
                    m_Height={488}
                    cursor={GlobalConstants.CURSOR_ARROW}
                    key={'boardweb'}
                />
                <DrawNames name={this.Name} position={this.NamePos} cursor={this.cursor} key={'names'} />
                {this.DrawTablaType()}
                {this.DrawHelp()}
                {this.DrawTimer()}
                {this.DrawRating()}
                {this.DrawPipcount()}
                {this.DrawNewGame()}
                {this.DrawPools()}
                {this.DrawDice()}
                {this.DrawStrelka(this.strelka)}
                {this.DrawArr()}
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
            </View>
        );
    }

    showHelpMessage() {
        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('backgammon-help');
        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
    }

    DrawHelp() {
        return (
            <Pressable
                onPress={() => this.showHelpMessage()}
                style={[cstyle.help, { top: 240 * GlobalVariables.scale, left: 692 * GlobalVariables.scale }]}
            >
                <MaterialCommunityIcons name={'help-circle'} color={'gray'} size={38 * GlobalVariables.scale} />
            </Pressable>
        );
    }

    DrawView() {
        if (this.props.m_pwin.game === 'Tabla' && !this.loading && !this.loadingMain) {
            if (Platform.OS === 'web') {
                return (
                    <View key={'webviewTabla'}>
                        <div key={'mousemove'} onMouseMove={this.OnMouseMove.bind(this)}>
                            {this.DrawViewElements()}
                        </div>
                    </View>
                );
            } else {
                return <View key={'mobileviewTabla'}>{this.DrawViewElements()}</View>;
            }
        } else {
            return null;
        }
    }

    render() {
        if (this.props.m_pwin.game === 'Tabla' && !this.loading && !this.loadingMain) {
            return <View key={'Tabla'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CTabla;

const cstyle = StyleSheet.create({
    help: {
        position: 'absolute',
    },
});
