import styled from 'styled-components';

export const ScrollbarWrapperVertical = styled.div(() => ({
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
        width: '8px',
    },
    '::-webkit-scrollbar-track': {
        boxShadow: 'nset 0 0 6px grey',
        borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'gray',
        borderRadius: '15px',
        height: '2px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: 'gray',
        maxHeight: '10px',
    },
}));

export default ScrollbarWrapperVertical;
