import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';

const Loader = (props) => {
    const { loading } = props;
    if (loading) {
        return (
            <View style={cstyles.loader}>
                <View style={cstyles.modalBackground}>
                    <View style={cstyles.activityIndicatorWrapper}>
                        <ActivityIndicator animating={true} color="#000000" size="large" style={cstyles.activityIndicator} />
                    </View>
                </View>
            </View>
        );
    } else {
        return null;
    }
};

export default Loader;

const cstyles = StyleSheet.create({
    loader: { width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 },
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        opacity: 0.9,
        width: '100%',
        height: '100%',
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        zIndex: 100,
    },
    activityIndicator: {
        alignItems: 'center',
        height: 80,
    },
});
