import React, { Component } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import CPicture from '../cppComponents/CPicture';
import GlobalConstants from '../GlobalConstants';
import { LanguagePickerProps } from '../props/LanguagePickerProps';

class LanguagePicker extends Component<LanguagePickerProps> {
    language: string = null;
    openLanguageSelector: boolean = false;

    constructor(props: LanguagePickerProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.updateFlag();
    }

    async updateFlag() {
        let language = await this.props.m_pwin.m_main.GetLanguage();
        if (language) {
            this.language = language;
            this.setState({});
        }
    }

    openLanguageSelectorDlg() {
        this.props.m_pwin.setState({ LanguagePickerDlgVisible: true });
    }

    render() {
        if (this.language) {
            return (
                <Pressable
                    onPress={() => {
                        this.openLanguageSelectorDlg();
                    }}
                    style={this.props.big ? cstyles.bigFlagSize : cstyles.flagSize}
                >
                    <CPicture
                        image={'languages2/' + this.language + '.png'}
                        m_Width={this.props.big ? 72 : 48}
                        m_Height={this.props.big ? 48 : 32}
                        m_y={0}
                        m_x={0}
                        noScale={true}
                        folder={'files_1.0'}
                        cursor={GlobalConstants.CURSOR_HAND}
                    />
                </Pressable>
            );
        } else {
            return null;
        }
    }
}

export default LanguagePicker;

const cstyles = StyleSheet.create({
    flagSize: {
        width: 48,
        height: 32,
    },
    bigFlagSize: {
        width: 72,
        height: 48,
    },
});
