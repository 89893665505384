import CPoint from './CPoint';

class CRect {
    left: number = 0;
    right: number = 0;
    top: number = 0;
    bottom: number = 0;

    constructor(left: number, top: number, right: number, bottom: number) {
        this.left = left;
        this.right = right;
        this.top = top;
        this.bottom = bottom;
    }

    Width(): number {
        return this.right - this.left;
    }

    Height(): number {
        return this.bottom - this.top;
    }

    TopLeft(): CPoint {
        return new CPoint(this.left, this.top);
    }

    BottomRight(): CPoint {
        return new CPoint(this.right, this.bottom);
    }

    PtInRect(point: CPoint): boolean {
        if (point.x > this.left && point.x < this.right && point.y > this.top && point.y < this.bottom) {
            return true;
        } else {
            return false;
        }
    }
}

export default CRect;
