import React, { Component } from 'react';
import DlgOnline from '../Screens/DlgOnline/DlgOnline';
import ChatSocket from './ChatSocket';
import CMain from './CMain';
import COK from './Messages/COK';
import CBelot4Players from './games/Belot/CBelot4Players';
import CNSSC from './games/CNSSC';
import CMainFrame from './CMainFrame';
import DlgConnect from '../Dialogs/DlgConnect';
import NavigationDrawerHeader from './NavigationDrawerHeader';
import cSound from './cSound';
import CTabla from './games/CTabla';
import CDomino2 from './games/Domino/CDomino2';
import CDomino4 from './games/Domino/CDomino4';
import CDomino3 from './games/Domino/CDomino3';
import CSantase from './games/CSantase';
import CBelot2Players from './games/Belot/CBelot2Players';
import CBelot3Players from './games/Belot/CBelot3Players';
import OnlineRegistration from '../Screens/OnlineRegistration';
import CBridge from './games/CBridge';
import CSvara from './games/CSvara';
import CPoker from './games/CPoker';
import CPokerOmaha from './games/CPokerOmaha';
import CDrawPoker from './games/CDrawPoker';
import COKEY from './games/COKEY';
import CMonopoly from './games/CMonopoly';
import CBlackJack from './games/CBlackJack';
import CBlato from './games/CBlato';
import CYESNO from './Messages/CYESNO';
import GlobalVariables from './GlobalVariables';
import GlobalConstants from './GlobalConstants';
import { Dimensions, Image, ImageBackground, Platform, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import Dlg432Players from '../Dialogs/Dlg432Players';
import CIgraNSSC from '../Screens/SelectGameScreen/IgraNSSC';
import IgraBelotDlg from '../Screens/SelectGameScreen/IgraBelotDlg';
import IgraBJDlg from '../Screens/SelectGameScreen/IgraBJDlg';
import IgraSantaseDlg from '../Screens/SelectGameScreen/IgraSantaseDlg';
import IgraOKEY from '../Screens/SelectGameScreen/IgraOKEY';
import IgraMonopoly from '../Screens/SelectGameScreen/IgraMonopoly';
import IgraTabla from '../Screens/SelectGameScreen/IgraTabla';
import IgraDominoDlg from '../Screens/SelectGameScreen/IgraDominoDlg';
import IgraBlatoDlg from '../Screens/SelectGameScreen/IgraBlatoDlg';
import IgraBridge from '../Screens/SelectGameScreen/IgraBridge';
import IgraSvara from '../Screens/SelectGameScreen/IgraSvara';
import IgraPoker from '../Screens/SelectGameScreen/IgraPoker';
import { styles } from '../../css/container';
import CPoint from './cppComponents/CPoint';
import CChatBar from '../DockBars/ChatBar/CChatBar';
import PlayersFilter from '../Dialogs/PlayersFilter';
import CBlinds from '../Dialogs/CBlinds';
import SettingsScreen from '../Screens/SettingsScreen';
import CNewTablePassword from '../Dialogs/CNewTablePassword';
import CInfoBar from '../DockBars/CInfoBar';
import DlgStatistika from '../Dialogs/DlgStatistika';
import DlgKarti from '../Dialogs/DlgKarti';
import DlgVip from '~/Dialogs/DlgVip';
import Banner from './cppComponents/Banner';
import CIzborNaIgra from '~/Screens/CIzborNaIgra';
import config from '~/config';
import LanguagePickerDlg from './core/LanguagePickerDlg';
import CYESNOChips from './Messages/CYESNOChips';
import DlgWebView from '~/Dialogs/DlgWebView';
import DlgRezultatBlato from '~/Dialogs/DlgRezultatBlato';
import CBlatoGetCards from '~/Dialogs/CBlatoGetCards/CBlatoGetCards';
import DlgObiaviavaneBla from '~/Dialogs/DlgObiaviavaneBla';
import DlgORezultat from '~/Dialogs/DlgORezultat';
import DlgNaddavane from '~/Dialogs/DlgNaddavane';
import DlgRezultat from '~/Dialogs/DlgRezultat';
import Bidding_Bridge from '~/Dialogs/BiddingBridge/Bidding_Bridge';
import CRubberResult from '~/Dialogs/CRubberResult';
import CImpResult from '~/Dialogs/CImpResult';
import ReactGA from 'react-ga';
import * as Analytics from 'expo-firebase-analytics';
import DeleteProfileScreen from '~/Screens/DlgOnline/DeleteProfileScreen';
import CPicture from './cppComponents/CPicture';
import ChangePasswordScreen from '~/Screens/ChangePasswordScreen';
import PlayersNamesScreen from '~/Screens/PlayersNamesScreen';

type Props = {
    timer: React.MutableRefObject<NodeJS.Timer>[];
    isAppActive: boolean;
    isConnected: boolean;
};

class CCardGamesView extends Component<Props> {
    state = {
        CIzborNaIgraVisible: false,
        DlgOnlineVisible: false,
        DlgConnectVisible: false,
        OnlineRegistrationVisible: false,
        Dlg432PlayersVisible: false,
        CIgraNSSCVisible: false,
        IgraBelotDlgVisible: false,
        IgraBJDlgVisible: false,
        IgraBlatoDlgVisible: false,
        IgraBridgeVisible: false,
        IgraChessVisible: false,
        IgraDominoDlgVisible: false,
        IgraMonopolyVisible: false,
        IgraOKEYVisible: false,
        IgraPokerVisible: false,
        IgraSantaseDlgVisible: false,
        IgraSvaraVisible: false,
        IgraTablaVisible: false,
        DlgNaddavaneVisible: false,
        DlgRezultatVisible: false,
        DlgORezultatVisible: false,
        DlgDetailsVisible: false,
        CAnnouncementVisible: false,
        CChatBarVisible: false,
        PlayersFilterVisible: false,
        DlgRezultatSantaseVisible: false,
        CBlindsVisible: false,
        SettingsScreenVisible: false,
        ChangePasswordVisible: false,
        PlayersNamesVisible: false,
        CNewTablePasswordVisible: false,
        CRubberResultVisible: false,
        CImpResultVisible: false,
        CResultOKEYVisible: false,
        Bidding_BridgeVisible: false,
        CInfoBarVisible: false,
        DlgStatistikaVisible: false,
        DlgKartiVisible: false,
        DlgRezultatBlatoVisible: false,
        CBlatoGetCardsVisible: false,
        DlgObiaviavaneBlaVisible: false,
        LanguagePickerDlgVisible: false,
        DlgVipVisible: false,
        CYESNOChipsVisible: false,
        DlgWebViewVisible: false,
        DeleteProfileScreenVisible: false,
    };
    messageokIndex: number = 0;
    messageyesnoIndex: number = 0;
    m_ok: COK[] = [];
    m_yesno: CYESNO[] = [];
    messageOKVisible: boolean[] = [];
    messageYESNOVisible: boolean[] = [];
    m_yesnochips: CYESNOChips;
    m_webView: DlgWebView;
    init_conn = false;
    connected = false;
    rec = true;
    login = false;
    game:
        | ''
        | 'Belot4'
        | 'Belot3'
        | 'Belot2'
        | 'Santase'
        | 'Bridge'
        | 'Svara'
        | 'Poker'
        | 'PokerOmaha'
        | 'DrawPoker'
        | 'BJ'
        | 'Blato'
        | 'Tabla'
        | 'OKEY'
        | 'Monopoly'
        | 'NSSC'
        | 'Domino2'
        | 'Domino3'
        | 'Domino4'
        | 'Chess' = '';
    m_main: CMain = new CMain(null);
    pFrame: CMainFrame = new CMainFrame(null);
    m_sndSound: cSound = new cSound({ m_pwin: this });
    m_dbo: DlgOnline;
    pf: PlayersFilter;
    m_izbor: CIzborNaIgra;
    oreg: OnlineRegistration = new OnlineRegistration(null);
    m_con: DlgConnect;
    m_nsscdlg: CIgraNSSC;
    m_beldlg: IgraBelotDlg;
    m_bjdlg: IgraBJDlg;
    m_sandlg: IgraSantaseDlg;
    m_okeydlg: IgraOKEY;
    m_mondlg: IgraMonopoly;
    m_tabdlg: IgraTabla;
    m_domdlg: IgraDominoDlg;
    m_bladlg: IgraBlatoDlg;
    m_bridlg: IgraBridge;
    m_svadlg: IgraSvara;
    m_pokdlg: IgraPoker;
    tab: CTabla = new CTabla(null);
    m_nssc: CNSSC = new CNSSC(null);
    b4: CBelot4Players = new CBelot4Players(null);
    m_bri: CBridge = new CBridge(null);
    m_san: CSantase = new CSantase(null);
    m_bla: CBlato = new CBlato(null);
    dom2: CDomino2 = new CDomino2(null);
    dom3: CDomino3 = new CDomino3(null);
    dom4: CDomino4 = new CDomino4(null);
    b2: CBelot2Players = new CBelot2Players(null);
    b3: CBelot3Players = new CBelot3Players(null);
    m_sva: CSvara = new CSvara(null);
    m_pok: CPoker = new CPoker(null);
    m_poko: CPokerOmaha = new CPokerOmaha(null);
    m_dpok: CDrawPoker = new CDrawPoker(null);
    m_okey: COKEY = new COKEY(null);
    mon: CMonopoly = new CMonopoly(null);
    m_bj: CBlackJack = new CBlackJack(null);

    m_pClientSocket: ChatSocket = new ChatSocket();
    send_ping: boolean = false;
    last_ping_dif: number = 0;
    last_send_ping: number;
    infoBarWidth: number = 172;
    chatBarHeight: number = 92;
    close_game_m_filename: string;
    m_d432: Dlg432Players = new Dlg432Players(null);
    on_new_game_start: boolean;
    initmenu: boolean = false;
    nd: NavigationDrawerHeader;
    invite_msg: boolean;
    m_blinds: CBlinds;
    m_settings: SettingsScreen;
    m_cp: ChangePasswordScreen;
    m_pl: PlayersNamesScreen;
    m_delete_profile: DeleteProfileScreen;
    m_ntp: CNewTablePassword;
    m_sta: DlgStatistika;
    clickCount = 0;
    pressTimer: any;
    m_karti: DlgKarti;
    m_vip: DlgVip;
    ipAddress: string = '-';
    show_banners: boolean = true;
    load_banner: boolean[] = [];
    banners: Banner[] = [];
    skipFirstAdd: boolean = true;
    languagePickerDlg: LanguagePickerDlg;
    rewardedBonus: boolean = false;
    trackingId = 'UA-8757914-1';
    lastAdTime: number = 0;

    constructor(props: Props) {
        super(props);
        this.OnCreate();
        this.CalculateScale();
        this.initConstructor();
    }

    async initConstructor() {
        if (this.show_banners) {
            for (let i = 0; i < 20; i++) {
                this.load_banner[i] = false;
                let fname = await this.m_main.GetProfileString('Options', 'banner' + this.m_main.IntToString(i), 'fg');
                if (fname !== 'fg') {
                    this.banners[i] = new Banner();
                    this.load_banner[i] = true;
                    this.banners[i].m_x = this.m_main._ttoi(
                        await this.m_main.GetProfileString('Options', 'bannerx' + this.m_main.IntToString(i), '20')
                    );
                    this.banners[i].m_y = this.m_main._ttoi(
                        await this.m_main.GetProfileString('Options', 'bannery' + this.m_main.IntToString(i), '20')
                    );
                    this.banners[i].m_url = await this.m_main.GetProfileString(
                        'Options',
                        'bannerurl' + this.m_main.IntToString(i),
                        this.m_main.GetLanguageText('domain')
                    );
                    this.banners[i].m_language = await this.m_main.GetProfileString('Options', 'bannerlanguage' + this.m_main.IntToString(i), 'ff');
                    this.banners[i].m_filename = fname;
                    this.banners[i].m_Width = this.m_main._ttoi(
                        await this.m_main.GetProfileString('Options', 'bannerwidth' + this.m_main.IntToString(i), '0')
                    );
                    this.banners[i].m_Height = this.m_main._ttoi(
                        await this.m_main.GetProfileString('Options', 'bannerheight' + this.m_main.IntToString(i), '0')
                    );
                }
            }
        }
        this.loadAdConfig();
        ReactGA.initialize(this.trackingId);
        ReactGA.set({
            username: await this.m_main.GetProfileString('Options', 'username', ''),
        });
    }

    componentDidMount() {
        if (Platform.OS === 'web') {
            window.addEventListener('resize', this.updateDimensions.bind(this));
        }
    }

    updateDimensions() {
        let oldFolder = GlobalVariables.folder;
        this.CalculateScale();
        this.resizeDialogs();
        if (oldFolder !== GlobalVariables.folder) {
            this.ReloadImages();
        }
        if (this.game === 'NSSC') {
            this.m_nssc.loadPionki();
        }
        this.setState({});
    }

    resizeDialogs() {
        if (this.state.DlgStatistikaVisible) {
            this.m_sta.InitTableWidth();
        }
        if (this.state.CRubberResultVisible) {
            this.m_bri.rubber_result.InitTableWidth();
        }
        if (this.state.CImpResultVisible) {
            this.m_bri.imp_result.InitTableWidth();
        }
        if (this.state.DlgOnlineVisible) {
            if (this.m_dbo.m_list) {
                this.m_dbo.m_list.InitTableWidth();
            }
            if (this.m_dbo.m_rooms) {
                this.m_dbo.m_rooms.InitTableWidth();
            }
        }
    }

    CalculateScale() {
        let width = Dimensions.get('window').width;
        let height = Dimensions.get('window').height;
        if (this.state.CChatBarVisible) {
            if (Platform.OS !== 'ios') {
                height = height - GlobalConstants.CHAT_BAR_HEIGHT;
            }
        }
        let scalew = width / (GlobalConstants.GAME_WIDTH + GlobalVariables.positionLeft); //779
        let scaleh = height / GlobalConstants.GAME_HEIGHT; //525
        GlobalVariables.scale = Math.min(scalew, scaleh);
        GlobalVariables.WindowWidth = width;
        GlobalVariables.WindowHeight = height;
        GlobalVariables.scaleWidth = scalew;
        GlobalVariables.scaleHeight = scaleh;
        if (GlobalVariables.scale <= 1) {
            GlobalVariables.folder = 'files_1.0';
        }
        if (GlobalVariables.scale > 1) {
            GlobalVariables.folder = 'files_1.5';
        }
        if (GlobalVariables.scale > 1.5) {
            GlobalVariables.folder = 'files_2.0';
        }
    }

    async OpenSettingsDIalog() {
        await this.m_settings.DoModal();
    }

    async OpenStatisticDialog() {
        if (this.game === 'Tabla' && this.tab.tab3) {
            this.m_sta.game = 'Backgammon 3 in 1';
        } else if (this.game === 'Tabla' && this.tab.type === 1) {
            this.m_sta.game = 'Backgammon - Tapa';
        } else if (this.game === 'Tabla' && this.tab.type === 2) {
            this.m_sta.game = 'Backgammon - Gulbara';
        } else if (this.game === 'NSSC') {
            this.m_sta.game = 'Ludo';
        } else if (this.game === 'Bridge' && this.m_bri.type === 1) {
            this.m_sta.game = 'Bridge Rubber';
        } else if (this.game === 'Bridge' && this.m_bri.type === 2) {
            this.m_sta.game = 'Duplicate Bridge';
        } else {
            this.m_sta.game = this.game;
        }
        await this.m_sta.DoModal();
    }

    FindUsernameInTable(username: string): boolean {
        if (username.indexOf('Computer', 0) >= 0) {
            return false;
        }
        if (this.game === 'OKEY') {
            for (let i = 2; i <= 4; i++) {
                let name: string = this.m_okey.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Monopoly') {
            for (let i = 2; i <= 4; i++) {
                let name: string = this.mon.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'NSSC') {
            for (let i = 2; i <= 4; i++) {
                let name: string = this.m_nssc.Name[i];

                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Tabla') {
            let name: string = this.tab.Name[2];
            if (name === username) {
                return true;
            }
        }
        if (this.game === 'Domino2') {
            let name: string = this.dom2.Name[2];
            if (name === username) {
                return true;
            }
        }
        if (this.game === 'Santase') {
            let name: string = this.m_san.Name[2];

            if (name === username) {
                return true;
            }
        }
        if (this.game === 'Belot4') {
            for (let i = 2; i <= 4; i++) {
                let name: string = this.b4.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Belot3') {
            for (let i = 2; i <= 3; i++) {
                let name: string = this.b3.Name[i];

                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Blato') {
            for (let i = 2; i <= 3; i++) {
                let name: string = this.m_bla.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Belot2') {
            let name: string = this.b2.Name[2];
            if (name === username) {
                return true;
            }
        }
        if (this.game === 'Bridge') {
            for (let i = 2; i <= 4; i++) {
                let name: string = this.m_bri.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'BJ') {
            for (let i = 2; i <= this.m_bj.Players; i++) {
                let name: string = this.m_bj.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Svara') {
            for (let i = 2; i <= this.m_sva.Players; i++) {
                let name: string = this.m_sva.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'Poker') {
            for (let i = 2; i <= this.m_pok.Players; i++) {
                let name: string = this.m_pok.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'PokerOmaha') {
            for (let i = 2; i <= this.m_poko.Players; i++) {
                let name: string = this.m_poko.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        if (this.game === 'DrawPoker') {
            for (let i = 2; i <= this.m_dpok.Players; i++) {
                let name: string = this.m_dpok.Name[i];
                if (name === username) {
                    return true;
                }
            }
        }
        return false;
    }

    FindUsernameInWatchers(username: string): boolean {
        if (this.pFrame.m_chat_bar.watchers.find((item) => item === username)) {
            return true;
        }
        return false;
    }

    isVipPlayer(username: string): boolean {
        if (this.m_main.getVipStatus(username) === 'VIP') {
            return true;
        }
        return false;
    }

    async DoYouWantToBecomeAVIPUser(title?: string) {
        if (this.isEnableOpenVipDlg()) {
            this.m_yesno[this.messageyesnoIndex].SetTitle(
                this.m_main.GetLanguageText(title ? title : 'Do you want to become a VIP user, extend your VIP access or load chips?')
            );
            if ((await this.m_yesno[this.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                if (this.IsGuestUser(this.m_con.getUsername())) {
                    this.m_ok[this.messageokIndex].SetTitle(
                        this.m_main.GetLanguageText('To use this functionality you must register with your own username!')
                    );
                    this.m_ok[this.messageokIndex].DoModal();
                } else {
                    await this.openVipDlg();
                }
            }
        }
    }

    isEnableOnlinePlayer(): boolean {
        //    if (Platform.OS === 'ios' && this.GetGameConfig() === 'Bridge') {
        //        return false;
        //   }
        return true;
    }

    isEnableMobilePayment(): boolean {
        if (
            (Platform.OS === 'android' && (this.m_main.GetAppStoreCode() === 'gp' || this.m_main.GetAppStoreCode() === 'am')) ||
            Platform.OS === 'ios'
        ) {
            return true;
        }
        return false;
    }

    isEnableOpenVipDlg(): boolean {
        let userCountry = this.m_main.getUserCountry(this.m_con.getUsername());
        if (this.m_main.GetAppStoreCode() !== 'hu' && 
            (this.isEnableMobilePayment() ||
            this.m_main.language === 'Bulgarian' ||
            Platform.OS === 'web' ||
            (userCountry !== 'United  States' && userCountry !== 'Ireland' && userCountry !== 'South Korea' && userCountry !== 'China'))
        ) {
            return true;
        }
        return false;
    }

    async openVipDlg() {
        if (this.isEnableOpenVipDlg()) {
            await this.m_vip.DoModal();
        }
    }

    async enableDisableWatch() {
        if (this.m_dbo.user_enable_watch === 'NOVIP') {
            if (this.IsGuestUser(this.m_con.getUsername())) {
                if (!this.haveOpenMessages()) {
                    this.m_ok[this.messageokIndex].SetTitle(
                        this.m_main.GetLanguageText('To disable or enable watching of your table in this room must log in with your username.')
                    );
                    this.m_ok[this.messageokIndex].DoModal();
                }
                return;
            }
        }
        if (this.m_dbo.user_enable_watch === 'VIP') {
            if (this.IsGuestUser(this.m_con.getUsername()) || this.isNoVipPlayer(this.m_con.getUsername())) {
                if (!this.haveOpenMessages()) {
                    this.m_ok[this.messageokIndex].SetTitle(
                        this.m_main.GetLanguageText('To disable or enable watching of your table in this room must be a VIP user.')
                    );
                    await this.m_ok[this.messageokIndex].DoModal();
                    await this.DoYouWantToBecomeAVIPUser();
                }
                return;
            }
        }

        if (this.m_main.enable_watch_game) {
            this.m_main.enable_watch_game = false;
            let par = '*|E|4';
            this.SendGame(par);
        } else {
            this.m_main.enable_watch_game = true;
            let par = '*|E|3';
            this.SendGame(par);
        }
        this.setState({});
    }

    async addRemovePassword() {
        if (this.m_dbo.user_enable_password === 'NOVIP') {
            if (this.IsGuestUser(this.m_con.getUsername())) {
                if (!this.haveOpenMessages()) {
                    this.m_ok[this.messageokIndex].SetTitle(
                        this.m_main.GetLanguageText('In order to set a password in this room you must log in with your username.')
                    );
                    this.m_ok[this.messageokIndex].DoModal();
                }
                return;
            }
        }
        if (this.m_dbo.user_enable_password === 'VIP') {
            if (this.IsGuestUser(this.m_con.getUsername()) || this.isNoVipPlayer(this.m_con.getUsername())) {
                if (!this.haveOpenMessages()) {
                    this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('You must be a VIP user to set a password in this room.'));
                    await this.m_ok[this.messageokIndex].DoModal();
                    await this.DoYouWantToBecomeAVIPUser();
                }
                return;
            }
        }

        if (this.close_game_m_filename.indexOf('close.gif') >= 0 || (this.close_game_m_filename === '' && this.m_dbo.m_table_password !== '')) {
            this.close_game_m_filename = 'files_1.0\\online\\open.gif';
            let par = '*|E|1';
            this.SendGame(par);
            this.setState({});
        } else {
            if (this.m_dbo.m_table_password !== '') {
                this.m_ntp.m_new_table_password = this.m_dbo.m_table_password;
            }
            if ((await this.m_ntp.DoModal()) === GlobalConstants.IDOK) {
                if (this.m_ntp.m_new_table_password !== '') {
                    this.m_dbo.m_table_password = '';
                    this.close_game_m_filename = 'files_1.0\\online\\close.gif';
                    let par = '*|I|' + this.m_ntp.m_new_table_password;
                    this.SendGame(par);
                    this.setState({});
                }
            }
        }
    }

    isNoVipPlayer(username: string) {
        if (this.m_main.getVipStatus(username) === 'NOVIP') {
            return true;
        }
        return false;
    }

    async changeLanguage(language: string) {
        if (language !== null) {
            await this.m_main.WriteProfileString('Options', 'language', language);
            this.m_main.changeLanguage(language);
            if (this.nd && this.nd.lp) {
                this.nd.lp.updateFlag();
            }
            if (this.state.DlgConnectVisible) {
                if (this.m_con && this.m_con.lp) {
                    this.m_con.lp.updateFlag();
                }
            }
            if (this.state.OnlineRegistrationVisible) {
                if (this.oreg && this.oreg.lp) {
                    this.oreg.lp.updateFlag();
                }
            }
            if (this.state.CInfoBarVisible) {
                this.pFrame.m_info_bar.updateLanguage();
            }
            this.setState({});
        }
        this.m_sndSound.loadLanguageSounds();
    }

    IsGuestUser(username: string) {
        if (this.m_main.getVipStatus(username) === 'GUEST') {
            return true;
        }
        return false;
    }

    async AddToIgnoreList(username: string) {
        let ignore = await this.m_main.GetProfileString('Options', 'ignore_list_' + this.m_con.getUsername(), '');
        ignore = ignore + username + ';';
        await this.m_main.WriteProfileString('Options', 'ignore_list_' + this.m_con.getUsername(), ignore);
        this.LoadIgnoreList(this.m_con.getUsername());
        this.SendGame('*|M|+|' + username);
    }

    OnlineGame() {
        this.setState({ CIzborNaIgraVisible: false });
        this.m_con.DoModal();
    }

    async OnCreate() {
        this.props.timer[1].current = setInterval(() => {
            this.OnTimer1();
        }, 10);
        this.props.timer[2].current = setInterval(
            async () => {
                await this.OnTimer2();
            },
            this.m_main.getIsTest() ? 0 : (await this.m_main.GetProfileInt('Options', 'Speed[2]', 10)) * 100
        );
        this.props.timer[3].current = setInterval(
            async () => {
                await this.OnTimer3();
            },
            this.m_main.getIsTest() ? 0 : (await this.m_main.GetProfileInt('Options', 'Speed[3]', 10)) * 100
        );
        this.props.timer[4].current = setInterval(
            async () => {
                await this.OnTimer4();
            },
            this.m_main.getIsTest() ? 0 : (await this.m_main.GetProfileInt('Options', 'Speed[3]', 10)) * 100
        );
        this.props.timer[5].current = setInterval(
            () => {
                this.OnTimer5();
            },
            this.m_main.getIsTest() ? 0 : 330
        );
        this.props.timer[6].current = setInterval(() => {
            this.SendIndikatomer();
        }, 30000);
        this.props.timer[7].current = setInterval(
            async () => {
                await this.OnTimer7();
            },
            this.m_main.getIsTest() ? 0 : 1000
        );
    }

    SetCursor() {}

    KillTimer(timerNum: number) {
        clearInterval(this.props.timer[timerNum].current);
    }

    SetTimer(timerNum: number, value: number) {
        if (this.m_main.getIsTest()) {
            value = 0;
        }
        this.props.timer[timerNum].current = setInterval(async () => {
            if (timerNum === 1) {
                await this.OnTimer1();
            }
            if (timerNum === 2) {
                await this.OnTimer2();
            }
            if (timerNum === 3) {
                await this.OnTimer3();
            }
            if (timerNum === 4) {
                await this.OnTimer4();
            }
            if (timerNum === 5) {
                this.OnTimer5();
            }
            if (timerNum === 6) {
                this.SendIndikatomer();
            }
            if (timerNum === 7) {
                await this.OnTimer7();
            }
        }, value);
    }

    SendErrorToLog(error: string) {
        if (this.m_main.client_log_min_ver > 0 && this.m_main._ttoi(this.m_main.GetOnlineVersion()) >= this.m_main.client_log_min_ver) {
            if (error !== '') {
                // Todo replace all "|" in error!
                let message: string = 'Y|' + error;
                this.SendGame(message);
            }
        }
    }

    AutoSitOut() {
        let mes = '*|H';
        this.SendGame(mes);
        this.game = '';
        this.InitIconsTooltips();
        this.pFrame.m_chat_bar.ClearWatchers();
        this.setState({});
    }

    ExistInFriendsList(username: string) {
        for (let i = 0; i < this.m_main.friends_list.length; i++) {
            let u1 = this.m_main.friends_list[i];
            let u2: string = username;
            u1 = u1.toLocaleLowerCase();
            u2 = u2.toLocaleLowerCase();
            if (u1 === u2) {
                return true;
            }
        }
        return false;
    }

    ExistInIgnoreList(username: string) {
        for (let i = 0; i < 300; i++) {
            let u1: string = this.m_main.ignore_list[i];
            let u2: string = username;
            u1 = u1.toLowerCase();
            u2 = u2.toLowerCase();
            if (u1 === u2) {
                return true;
            }
        }
        return false;
    }

    async LoadIgnoreList(username: string) {
        let ignore: string = await this.m_main.GetProfileString('Options', 'ignore_list_' + username, '');
        for (let i = 0; i < 300; i++) {
            this.m_main.ignore_list[i] = '';
        }
        let br = 0;
        while (ignore.indexOf(';') > -1) {
            if (br >= 300) {
                return;
            }
            let tz_index = ignore.indexOf(';');
            this.m_main.ignore_list[br] = ignore.substring(0, tz_index);
            ignore.substring(0, tz_index + 1);
            br = br + 1;
        }
    }

    ReceiveString(len: number) {
        let empty_br = 0;
        let strRecvd = '';
        let brrek = 0;
        //    begin: {
        let del = 0;
        if (len === 0) {
            len = 40000;
        }
        let str_len = this.m_pClientSocket.m_strRecv.length;
        if (str_len > 0) {
            for (let i = 0; i < len; i++) {
                //    opt1: {
                if (str_len !== this.m_pClientSocket.m_strRecv.length) {
                }
                str_len = this.m_pClientSocket.m_strRecv.length;
                del = i;
                if (i < str_len && this.m_pClientSocket.m_strRecv.charAt(i) !== '|') {
                    strRecvd += this.m_pClientSocket.m_strRecv.charAt(i);
                } else {
                    if (i === str_len && this.m_pClientSocket.m_strRecv.charAt(str_len - 1) !== '|' && len !== 1) {
                        brrek = brrek + 1;
                        str_len = this.m_pClientSocket.m_strRecv.length;
                        //    if (brrek < 200) {
                        //        break opt1;
                        //    }
                    }
                    break;
                }
                //    }
            }
            //    next:;
            if (this.m_pClientSocket.m_strRecv.length > 1) {
                this.m_pClientSocket.m_strRecv = this.m_pClientSocket.m_strRecv.substring(del + 1, this.m_pClientSocket.m_strRecv.length);
            }
            if (this.m_pClientSocket.m_strRecv.length > 0) {
                if (this.m_pClientSocket.m_strRecv.charAt(0) === '|') {
                    this.m_pClientSocket.m_strRecv = this.m_pClientSocket.m_strRecv.substring(1, this.m_pClientSocket.m_strRecv.length);
                }
            }
            if (this.m_pClientSocket.m_strRecv.length > 0) {
                if (this.m_pClientSocket.m_strRecv.charAt(0) === '|') {
                    this.m_pClientSocket.m_strRecv = this.m_pClientSocket.m_strRecv.substring(1, this.m_pClientSocket.m_strRecv.length);
                }
            }
        } else {
            while (this.m_pClientSocket.m_strRecv.length === 0 && empty_br < 100) {
                empty_br = empty_br + 1;
            }
            //  if (this.m_pClientSocket.m_strRecv.length > 0) {
            //       break begin;
            //   }
        }
        if (this.m_pClientSocket.m_strRecv.length === 0) {
            this.rec = false;
        }
        //console.log('ReceiveString: ' + strRecvd + '\n'); //Exception on watch any games!Bridge, monopoly, blato
        return strRecvd;
    }

    async Receive() {
        this.rec = true;
        if (this.m_pClientSocket.m_strRecv.length > 0) {
            let strRecvd = this.ReceiveString(0);
            if (strRecvd === '$') {
                let br_ = 0;
                for (let i = 0; i < this.m_pClientSocket.m_strRecv.length; i++) {
                    if (this.m_pClientSocket.m_strRecv.substring(i) === '|') {
                        br_ = br_ + 1;
                    }
                }
                if (br_ < 1) {
                    this.m_pClientSocket.RestoreMessage('$|');
                    return;
                }
                strRecvd = this.ReceiveString(1);
                if (this.game === 'Belot2') {
                    if (!(await this.b2.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Belot3') {
                    if (!(await this.b3.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Belot4') {
                    if (!(await this.b4.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Blato') {
                    if (!(await this.m_bla.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Bridge') {
                    if (!(await this.m_bri.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Svara') {
                    if (!(await this.m_sva.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Domino2') {
                    if (!(await this.dom2.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Domino3') {
                    if (!(await this.dom3.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Domino4') {
                    if (!(await this.dom4.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Monopoly') {
                    if (!(await this.mon.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'NSSC') {
                    if (!(await this.m_nssc.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'OKEY') {
                    if (!(await this.m_okey.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Poker') {
                    if (!(await this.m_pok.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'PokerOmaha') {
                    if (!(await this.m_poko.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'DrawPoker') {
                    if (!(await this.m_dpok.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'BJ') {
                    if (!(await this.m_bj.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Santase') {
                    if (!(await this.m_san.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
                if (this.game === 'Tabla') {
                    if (!(await this.tab.ReceiveGame(strRecvd))) {
                        return;
                    }
                }
            } else {
                if (!(await this.ReceiveF(strRecvd))) {
                    return;
                }
            }
        }
        if (
            this.m_pClientSocket.m_strRecv.length > 0 &&
            this.m_pClientSocket.m_strRecv.substring(this.m_pClientSocket.m_strRecv.length - 1) === '|'
        ) {
            await this.Receive();
        }
    }

    async LoadFriendsList(username: string) {
        this.m_main.friends_list = [];
        let friends: string = await this.m_main.GetProfileString('Options', 'friends_list_' + username, '');
        while (friends.indexOf(';') > -1) {
            let tz_index = friends.indexOf(';');
            this.m_main.friends_list.push(friends.substring(0, tz_index));
            friends = friends.substring(tz_index + 1, friends.length);
        }
    }

    InitIconsTooltips() {}

    async OnConnectToGame(lgame: string, table_password: string, watch: string, tournament: string, bet: string) {
        if (lgame === 'CLudo') {
            //Ako se prisaediniava kam igra NSSC
            await this.m_nssc.InitMain();
            this.m_nssc.initConstructor();
            this.m_nssc.KoiENaRed = -1;
            this.m_nssc.rating[1] = this.m_dbo.p1_rating;
            this.game = 'NSSC';
            this.setState({});
            this.m_nssc.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.m_nssc.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CB4') {
            //Ako se prisaediniava kam igra Belot s 4 igrachi
            await this.b4.InitMain();
            this.b4.initConstructor();
            this.b4.KoiENaRed = -1;
            this.b4.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot4';
            this.b4.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.b4.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CBr1' || lgame === 'CBr2') {
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            this.m_bri.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Bridge Rubber or Duplicate Bridge
            let type = 2;
            if (lgame === 'CBr1') {
                type = 1;
            }
            this.m_bri.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Bridge';
            this.m_bri.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.m_bri.InitOP(false, type);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CBa1') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Tabla obiknovenna
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.tab.Name[2] = this.m_dbo.Select[0];
            this.tab.last_Name[2] = this.tab.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 0);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CBa2') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Tabla Tapa
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.tab.Name[2] = this.m_dbo.Select[0];
            this.tab.last_Name[2] = this.tab.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 1);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CBa3') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Tabla Gulbara
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.tab.Name[2] = this.m_dbo.Select[0];
            this.tab.last_Name[2] = this.tab.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 2);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CBa31') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.m_bri.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Tabla Gulbara
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.tab.Name[2] = this.m_dbo.Select[0];
            this.tab.last_Name[2] = this.tab.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 3);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'C358') {
            //Ako se prisaediniava kam igra Blato
            await this.m_bla.InitMain();
            this.m_bla.initConstructor();
            this.m_bla.KoiENaRed = -1;
            this.m_bla.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Blato';
            this.m_bla.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.m_bla.InitOP(false, this.m_dbo.GameType);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CS') {
            await this.m_san.InitMain();
            this.m_san.initConstructor();
            this.m_san.KoiENaRed = -1;
            //Ako se prisaediniava kam igra Santase
            this.m_san.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Santase';
            this.m_san.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.m_san.Name[2] = this.m_dbo.Select[0];
            this.m_san.last_Name[2] = this.m_san.Name[2];
            this.GetPlayerRating(2);
            this.m_san.m_rez.m_name1 = this.m_san.Name[1];
            this.m_san.m_rez.m_name2 = this.m_san.Name[2];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.m_san.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CPT') {
            //Ako se prisaediniava kam igra Poker Texas Hold em
            this.game = 'Poker';
            await this.m_pok.InitMain();
            this.m_pok.rating[1] = this.m_dbo.p1_rating;
            this.m_pok.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_pok.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_pok.Speed[1] = 100;
                this.m_pok.Speed[2] = 5;
                this.m_pok.Speed[3] = 5;
                this.m_pok.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_pok.InitOP(false, pplayers);
            this.m_pok.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CPO') {
            //Ako se prisaediniava kam igra Poker Omaha
            await this.m_poko.InitMain();
            this.game = 'PokerOmaha';
            this.m_poko.rating[1] = this.m_dbo.p1_rating;
            this.m_poko.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_poko.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_poko.Speed[1] = 100;
                this.m_poko.Speed[2] = 5;
                this.m_poko.Speed[3] = 5;
                this.m_poko.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_poko.InitOP(false, pplayers);
            this.m_poko.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CDP') {
            await this.m_dpok.InitMain();
            //Ako se prisaediniava kam igra Draw Poker
            this.m_dpok.rating[1] = this.m_dbo.p1_rating;
            this.game = 'DrawPoker';
            this.m_dpok.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_dpok.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_dpok.Speed[1] = 100;
                this.m_dpok.Speed[2] = 5;
                this.m_dpok.Speed[3] = 5;
                this.m_dpok.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_dpok.InitOP(false, pplayers);
            this.m_dpok.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CSV') {
            //Ako se prisaediniava kam igra Svara
            await this.m_sva.InitMain();
            this.m_sva.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Svara';
            this.m_sva.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_sva.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_sva.Speed[1] = 100;
                this.m_sva.Speed[2] = 5;
                this.m_sva.Speed[3] = 5;
                this.m_sva.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_sva.InitOP(false, pplayers);
            this.m_sva.InitSvaraParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'COKEY') {
            //Ako se prisaediniava kam igra OKEY
            await this.m_okey.InitMain();
            this.m_okey.rating[1] = this.m_dbo.p1_rating;
            this.game = 'OKEY';
            this.m_okey.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.m_okey.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CMonopoly') {
            //Ako se prisaediniava kam igra Monopoly
            await this.mon.InitMain();
            this.mon.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Monopoly';
            this.mon.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.mon.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CB3') {
            //Ako se prisaediniava kam igra Belot s 3 igrachi
            await this.b3.InitMain();
            this.b3.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot3';
            this.b3.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.b3.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CB2') {
            await this.b2.InitMain();
            //Ako se prisaediniava kam igra Belot s 2 igrachi
            this.b2.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot2';
            this.b2.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.b2.Name[2] = this.m_dbo.Select[0];
            this.b2.last_Name[2] = this.b2.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.b2.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CDo2') {
            await this.dom2.InitMain();
            //Ako se prisaediniava kam igra Domino 2 igracha
            this.dom2.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Domino2';
            this.dom2.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.dom2.Name[2] = this.m_dbo.Select[0];
            this.dom2.last_Name[2] = this.dom2.Name[2];
            this.GetPlayerRating(2);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.dom2.KoiENaRed = -1;
            this.dom2.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
        if (lgame === 'CDo4') {
            await this.dom4.InitMain();
            //Ako se prisaediniava kam igra Domino 4 igracha
            this.dom4.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Domino4';
            this.dom4.Name[1] = this.m_con.getUsername();
            this.GetPlayerRating(1);
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.dom4.InitOP(false);
            let message = 'O|' + this.m_dbo.Select[0] + '|' + table_password + '|' + tournament + '|' + bet;
            this.SendGame(message);
        }
    }

    async OnOnlinegameIgnorelist(pfDialog: PlayersFilter) {
        if (this.IsGuestUser(this.m_con.getUsername())) {
            this.m_ok[this.messageokIndex].SetTitle('To be able to add users to Ignore List you must log in with your username.');
            await this.m_ok[this.messageokIndex].DoModal();
            return;
        }
        pfDialog.list_type = 'ignore_list';
        let reg_key = 'ignore_list_' + this.m_con.getUsername();
        pfDialog.reg_key = reg_key;
        await pfDialog.DoModal();
        await this.LoadIgnoreList(this.m_con.getUsername());
    }

    async OnOnlinegameFriendslist() {
        if (this.IsGuestUser(this.m_con.getUsername())) {
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('In order to add friends you need to log in with your username.'));
            await this.m_ok[this.messageokIndex].DoModal();
            return;
        }
        this.pf.list_type = 'friends';
        let reg_key = 'friends_list_' + this.m_con.getUsername();
        this.pf.reg_key = reg_key;
        await this.pf.DoModal();
        await this.LoadFriendsList(this.m_con.getUsername());
    }

    CancelDlgOnline() {
        this.showAd();
        this.setState({ DlgOnlineVisible: false });
        this.ShowChatBar(false);
        this.SendGame('D');
        this.connected = false;
        this.m_pClientSocket.Close();
        this.OnNewGame();
    }

    async CloseDlgOnline() {
        if (this.state.DlgOnlineVisible) {
            try {
                this.setState({ DlgOnlineVisible: false });
            } catch (e) {
                console.error('this.setState({ DlgOnlineVisible: false })', e);
            }
        }

        this.SendIndikatomer();
        this.m_main.watch = false;
        this.pFrame.m_chat_bar.watch = false;
        let table_password = this.m_dbo.m_table_password;
        this.close_game_m_filename = '';
        if (table_password === '') {
            table_password = '-';
        }
        let watch = this.m_main.IntToString(this.m_dbo.m_eye);
        if (
            this.m_dbo.Game === 'CPT' ||
            this.m_dbo.Game === 'WPT' ||
            this.m_dbo.Game === 'Poker Texas Hold em' ||
            this.m_dbo.Game === 'CPO' ||
            this.m_dbo.Game === 'WPO' ||
            this.m_dbo.Game === 'Poker Omaha Hi'
        ) {
            watch = '1'; //??? Tuka moje bi triabva da se dobaviat za svara i BJ
        }
        if (watch === '1') {
            this.m_main.enable_watch_game = true;
        } else {
            this.m_main.enable_watch_game = false;
        }
        let tournament = '0';
        if (this.m_dbo.m_tournament) {
            tournament = '1';
        }
        let bet = '0';
        let lgame = this.m_dbo.Game;
        await this.OnCreateGame(lgame, table_password, watch, tournament, bet);
        await this.OnConnectToGame(lgame, table_password, watch, tournament, bet);
        await this.OnWatchGame(lgame, table_password);
        this.setState({});
        this.pFrame.m_chat_bar.watch = this.m_main.watch;
        this.InitIconsTooltips();
    }

    openPage(url: string, tooltip?: string, closeButtonTitle?: string) {
        //    if(Platform.OS === 'ios' && this.GetGameConfig() === 'Santase') {
        //        return;
        //    }
        if (!tooltip) {
            tooltip = 'Favorite-Games.com';
        }
        this.m_webView.title = tooltip;
        if (Platform.OS !== 'web') {
            if (url.indexOf('?') > 0) {
                url = url + '&store=' + this.m_main.GetAppStoreCode() + '&platform=' + Platform.OS;
            } else {
                url = url + '?store=' + this.m_main.GetAppStoreCode() + '&platform=' + Platform.OS;
            }
        }
        if (url.indexOf('?') > 0) {
            url = url + '&lang=' + this.m_main.language;
        } else {
            url = url + '?lang=' + this.m_main.language;
        }
        this.m_webView.url = url;
        this.m_webView.closeButtonTitle = closeButtonTitle ? closeButtonTitle : undefined;
        this.m_webView.DoModal();
    }

    IsGameOnlineServer() {
        let res: boolean = false;
        if (
            (this.game === 'Blato' && this.m_bla.OP && this.m_bla.Server) ||
            (this.game === 'Tabla' && this.tab.OP && this.tab.Server) ||
            (this.game === 'Domino2' && this.dom2.OP && this.dom2.Server) ||
            (this.game === 'Belot4' && this.b4.OP && this.b4.Server) ||
            (this.game === 'Belot3' && this.b3.OP && this.b3.Server) ||
            (this.game === 'Belot2' && this.b2.OP && this.b2.Server) ||
            (this.game === 'NSSC' && this.m_nssc.OP && this.m_nssc.Server) ||
            (this.game === 'Bridge' && this.m_bri.OP && this.m_bri.Server) ||
            (this.game === 'Santase' && this.m_san.OP && this.m_san.Server) ||
            (this.game === 'Monopoly' && this.mon.OP && this.mon.Server) ||
            (this.game === 'OKEY' && this.m_okey.OP && this.m_okey.Server) ||
            (this.game === 'NSSC' && this.m_nssc.OP && this.m_nssc.Server) ||
            (this.game === 'Poker' && this.m_pok.OP && this.m_pok.Server) ||
            (this.game === 'PokerOmaha' && this.m_poko.OP && this.m_poko.Server) ||
            (this.game === 'DrawPoker' && this.m_dpok.OP && this.m_dpok.Server) ||
            (this.game === 'BJ' && this.m_bj.OP && this.m_bj.Server) ||
            (this.game === 'Svara' && this.m_sva.OP && this.m_sva.Server)
        ) {
            res = true;
        }
        return res;
    }

    GetPlayerParametersString(username: string) {
        if (
            username !== '' &&
            username !== 'Computer2' &&
            username !== 'Computer3' &&
            username !== 'Computer4' &&
            username !== 'Computer5' &&
            username !== 'Computer6' &&
            username !== 'Computer7' &&
            username !== 'Computer8' &&
            username !== 'Computer9' &&
            username !== 'Computer10'
        ) {
            let rplayer = '*|B|' + username;
            this.SendGame(rplayer);
        }
    }

    async ReceiveF(f: string) {
        if (f === 'A') {
            //Pravilna parola i Username
            this.m_con.loading = false;
            this.m_con.setState({});
            this.setState({ DlgConnectVisible: false });
            this.setState({ OnlineRegistrationVisible: false });
            this.m_dbo.DoModal();
            this.login = true;
            this.pFrame.m_chat_bar.ClearWatchers();
            this.game = '';
            this.setState({});
            //    begin1:;
            await this.LoadIgnoreList(this.m_con.getUsername());
            await this.LoadFriendsList(this.m_con.getUsername());
            return true;
        }

        if (f === 'B') {
            //Ne Pravilna parola i Username
            if (!this.login) {
                this.connected = false;
                this.m_con.action = 'noinit';
                this.m_con.loading = false;
                this.m_con.setState({});
                this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('Invalid username or password') + '!');
                await this.m_ok[this.messageokIndex].DoModal();
            }
            return true;
        }
        if (f === 'C') {
            //Iztegliane na informacia ot servera za prisaedinenite igrachi
            if (!this.CheckMessages(10, f + '|')) {
                return false;
            }
            let name = this.ReceiveString(12);
            let game = this.ReceiveString(30);
            let pi = this.ReceiveString(5);
            let stat = this.ReceiveString(11);
            let rating = this.ReceiveString(30);
            let watch = this.ReceiveString(1);
            let close_game = this.ReceiveString(2);
            let type = this.ReceiveString(50);
            let ousers = this.ReceiveString(150);
            let gameTypeFromServer = this.ReceiveString(50);
            if (this.state.DlgOnlineVisible) {
                if (
                    name !== '' &&
                    game !== '' &&
                    pi !== '' &&
                    stat !== '' &&
                    rating !== '' &&
                    watch !== '' &&
                    close_game !== '' &&
                    type !== '' &&
                    ousers !== '' &&
                    gameTypeFromServer !== ''
                ) {
                    this.m_dbo.AddRow(name, game, pi, stat, rating, watch, close_game, type, ousers, gameTypeFromServer);
                }
            }
            return true;
        }
        if (f === 'D') {
            if (!this.CheckMessages(10, f + '|')) {
                return false;
            }
            let name = this.ReceiveString(12);
            let game = this.ReceiveString(30);
            let pi = this.ReceiveString(5);
            let stat = this.ReceiveString(11);
            let rating = this.ReceiveString(30);
            let watch = this.ReceiveString(1);
            let close_game = this.ReceiveString(2);
            let type = this.ReceiveString(50);
            let ousers = this.ReceiveString(150);
            let gameTypeFromServer = this.ReceiveString(50);
            if (
                name !== '' &&
                game !== '' &&
                pi !== '' &&
                stat !== '' &&
                rating !== '' &&
                watch !== '' &&
                close_game !== '' &&
                type !== '' &&
                ousers !== '' &&
                gameTypeFromServer !== ''
            ) {
                if (this.state.DlgOnlineVisible) {
                    this.m_dbo.UpdateRow(name, game, pi, stat, rating, watch, close_game, type, ousers, gameTypeFromServer);
                }
            }
            return true;
        }
        if (f === 'E') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let username = this.ReceiveString(20);
            if (this.state.DlgOnlineVisible) {
                if (username !== '') {
                    this.m_dbo.DeleteRow(username);
                }
            }
            return true;
        }
        if (f === 'F') {
            //Priasaediniavane na igrach
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let name = this.ReceiveString(12);
            if (!this.ExistInIgnoreList(name)) {
                if (this.state.Dlg432PlayersVisible) {
                    this.m_d432.ConnectPlayer(name);
                }
            } else {
                let message = '$|x|' + name;
                this.SendGame(message);
                this.pFrame.m_chat_bar.AddMessage(
                    this.m_main.GetLanguageText('user') + ' ' + name + ' ' + this.m_main.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'G') {
            //Poluchavane na Info za Server-ite
            if (!this.CheckMessages(4, f + '|')) {
                return false;
            }
            let sip = this.ReceiveString(40);
            let reg_key = this.ReceiveString(20);
            let server_name = this.ReceiveString(40);
            let server_port = this.ReceiveString(10);
            if (sip === '-' || server_name === '-' || server_port === '-') {
                sip = '';
                server_name = '';
                await this.m_main.WriteProfileString('Options', reg_key, '');
            }
            if (server_name !== '') {
                if (reg_key === 'server') {
                    await this.m_main.WriteProfileString('Options', 'cserver', server_name);
                } else {
                    await this.m_main.WriteProfileString('Options', reg_key, server_name);
                    await this.m_main.WriteProfileString('Options', reg_key + '_ip', sip);
                    await this.m_main.WriteProfileInt('Options', reg_key + '_port', this.m_main._ttoi(server_port));
                }
            }
            return true;
        }
        if (f === 'H') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let ping2 = this.ReceiveString(20);

            let ms = Date.now();
            let ping = ms;
            let dif = ping - this.m_main._ttoi(ping2);
            if (this.last_ping_dif !== dif) {
                this.last_ping_dif = dif;
                this.InvalidateIndikator();
            }
            this.send_ping = false;
            return true;
        }
        if (f === 'I') {
            this.pFrame.m_chat_bar.ClearWatchers();
            this.closeAllDialogs();
            this.connected = false;
            this.game = '';
            this.InitIconsTooltips();
            this.setState({});
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('No connection with server'));
            await this.m_ok[this.messageokIndex].DoModal();
            this.closeAllDialogs();
            return true;
        }
        if (f === 'J') {
            //saobshtenie za izteklo vreme
            if (this.state.DlgOnlineVisible) {
                try {
                    this.setState({ Dlg432PlayersVisible: false });
                } catch (e) {
                    console.error('this.setState({ Dlg432PlayersVisible: false })', e);
                }
            }
            this.m_yesno[this.messageyesnoIndex].SetTitle(this.m_main.GetLanguageText('Your VIP access has expired. Do you want to continue it?'));
            if ((await this.m_yesno[this.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                this.openPage(this.m_main.GetLanguageText('registration_online'));
            }
            this.OnNewGame();
            return true;
        }
        if (f === 'K') {
            // igrata e zapochnala bez teb
            this.game = '';
            this.InitIconsTooltips();
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('This Game Has Started Without You. Please Select Another Game'));
            await this.m_ok[this.messageokIndex].DoModal();
            return true;
        }
        if (f === 'L') {
            // poluchavane na ostavashto vreme
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let time = this.ReceiveString(11);
            if (this.state.DlgOnlineVisible) {
                this.m_dbo.SetTime(time);
            }
            return true;
        }
        if (f === 'M') {
            //poluchavane na rezultat ot registraciata
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let type = this.ReceiveString(2);
            if (type === '0') {
                this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('The registration is successful'));
                await this.m_ok[this.messageokIndex].DoModal();
                this.closeAllDialogs();
                this.m_con.action = 'noinit';
                await this.m_main.WriteProfileInt('Options', 'or', (await this.m_main.GetProfileInt('Options', 'or', 0)) + 1);
                await this.m_main.WriteProfileString('Options', 'save', '1');
                await this.m_main.WriteProfileString('Options', 'username', this.oreg.m_username);
                await this.m_main.WriteProfileString('Options', 'password', this.oreg.m_password);
                this.m_con.m_user = this.oreg.m_username;
                this.m_con.m_password = this.oreg.m_password;
                if ((await this.m_main.GetProfileString('Options', 'GetAuthData_Registration', null)) === null) {
                    this.m_main.WriteProfileString('Options', 'GetAuthData_Registration', this.oreg.m_username);
                    this.TrackEvent('GetAuthData', 0.3);
                }
                await this.m_con.DoModal();
            }
            if (type === '1') {
                this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('This User name is not free'));
                await this.m_ok[this.messageokIndex].DoModal();
            }
            if (type === '2') {
                this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('The registration is not successful'));
                await this.m_ok[this.messageokIndex].DoModal();
            }
            return true;
        }
        if (f === 'N') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let info = this.ReceiveString(11);
            //if (this.state.DlgOnlineVisible) {
            this.m_dbo.SetInfo(info);
            //}
            return true;
        }
        if (f === 'O') {
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText("You can't connect to this game") + '!');
            await this.m_ok[this.messageokIndex].DoModal();
            this.game = '';
            this.InitIconsTooltips();
            return true;
        }
        if (f === 'P') {
            // Receive Guest Username
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let guestUsername = this.ReceiveString(20);
            this.pFrame.m_chat_bar.nick = guestUsername;
            this.m_con.m_hiddenUsername = guestUsername;
            if (this.state.DlgOnlineVisible) {
                this.m_dbo.UpdateNick(guestUsername);
            }
            return true;
        }
        if (f === 'Q') {
            if (!this.CheckMessages(6, f + '|')) {
                return false;
            }
            let banner_file_name = this.ReceiveString(21);
            let banner_num = this.ReceiveString(3);
            let banner_url = this.ReceiveString(101);
            let banner_x_position = this.ReceiveString(4);
            let banner_y_position = this.ReceiveString(4);
            let banner_language = this.ReceiveString(21);
            let banner_width = this.ReceiveString(4);
            let banner_height = this.ReceiveString(4);

            if (this.show_banners) {
                if (banner_url === 'end') {
                    for (let i = this.m_main._ttoi(banner_num); i < 20; i++) {
                        await this.m_main.WriteProfileString('Options', 'banner' + this.m_main.IntToString(i), 'fg');
                    }
                } else {
                    await this.m_main.WriteProfileString(
                        'Options',
                        'banner' + banner_num,
                        this.m_main.GetLanguageText('domain') + '/adv/banners/' + banner_file_name
                    );
                    await this.m_main.WriteProfileString('Options', 'bannerurl' + banner_num, banner_url);
                    await this.m_main.WriteProfileString('Options', 'bannerx' + banner_num, banner_x_position);
                    await this.m_main.WriteProfileString('Options', 'bannery' + banner_num, banner_y_position);
                    await this.m_main.WriteProfileString('Options', 'bannerlanguage' + banner_num, banner_language);
                    await this.m_main.WriteProfileString('Options', 'bannerwidth' + banner_num, banner_width);
                    await this.m_main.WriteProfileString('Options', 'bannerheight' + banner_num, banner_height);
                }
            }
            return true;
        }
        if (f === 'R') {
            //Chat saobshtenie// NOt used from ver 475// From ver 481 receive server configuration
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let server_configuration = this.ReceiveString(4000);
            this.ParseServerConfiguration(server_configuration);
            return true;
        }
        if (f === 'S') {
            //Chat saobshtenie po vreme na igra
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let message: string = this.ReceiveString(300);
            let windex = message.indexOf('<Watch Table', 0);
            let loin = message.indexOf('<Login>', 0);
            let lo = message.indexOf('<Logout>', 0);
            let chatoff = message.indexOf('(chatoff) ', 0);
            let kiu = message.indexOf('(kick) ', 0);
            let chaton = message.indexOf('(chaton) ', 0);
            if (windex >= 0) {
                let player_name = message.substring(0, message.length - 14);
                if (this.ExistInIgnoreList(player_name) && this.IsGameOnlineServer()) {
                    message = '$|x|' + player_name;
                    this.SendGame(message);
                    this.pFrame.m_chat_bar.AddMessage(
                        this.m_main.GetLanguageText('user') + ' ' + player_name + ' ' + this.m_main.GetLanguageText('is in the Ignore List') + '!'
                    );
                    return true;
                } else {
                    this.pFrame.m_chat_bar.logInWatchUser(player_name);
                    this.GetPlayerParametersString(player_name);
                }
            }
            if (lo >= 0) {
                this.pFrame.m_chat_bar.logOutWatchUser(message.substring(0, message.length - 9));
            }
            if (loin >= 0) {
                this.pFrame.m_chat_bar.logOutWatchUser(message.substring(0, message.length - 8));
            }
            if (chatoff >= 0) {
                this.pFrame.m_chat_bar.ChatOff(message.substring(message.length - chatoff - 10, message.length), false, false);
            }
            if (chaton >= 0) {
                this.pFrame.m_chat_bar.ChatOff(message.substring(message.length - chaton - 9, message.length), true, false);
            }
            let user = '';
            let sp_index = message.indexOf(' ', 0);
            if (sp_index > 0) {
                user = message.substring(0, sp_index);
                message = this.m_main.FixLanguageMessagesFromServer(message, this.m_main.GetUserLanguage(user));
            } else {
                message = this.m_main.FixLanguageMessagesFromServer(message);
            }

            this.pFrame.m_chat_bar.AddMessage(message);
            if (chatoff < 0 && chaton < 0 && kiu < 0) {
                if (message.length > 100) {
                    message = message.substring(0, 100) + '...';
                }
                if (GlobalVariables.Zvuk === 1) {
                    this.m_sndSound.Play(1112, 'center', 0, 0);
                }
                let player_index = 0;
                if (this.game === 'NSSC') {
                    if (this.m_nssc.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.m_nssc.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.m_nssc.Name[4] === user) {
                        player_index = 4;
                    }
                    if (this.m_nssc.watch && this.m_nssc.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_nssc.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Belot4') {
                    if (this.b4.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.b4.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.b4.Name[4] === user) {
                        player_index = 4;
                    }
                    if (this.b4.watch && this.b4.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.b4.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'OKEY') {
                    if (this.m_okey.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.m_okey.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.m_okey.Name[4] === user) {
                        player_index = 4;
                    }
                    if (this.m_okey.watch && this.m_okey.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_okey.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Monopoly') {
                    if (this.mon.watch && this.mon.Name[1] === user) {
                        player_index = 1;
                    } else {
                        for (let i = 1; i <= 4; i++) {
                            if (this.mon.Name[i] === user) {
                                player_index = i;
                            }
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.mon.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Blato') {
                    if (this.m_bla.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.m_bla.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.m_bla.watch && this.m_bla.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_bla.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Belot3') {
                    if (this.b3.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.b3.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.b3.watch && this.b3.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.b3.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Belot2') {
                    if (this.b2.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.b2.watch && this.b2.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.b2.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Svara') {
                    for (let i = 1; i <= this.m_sva.Players; i++) {
                        if (this.m_sva.Name[i] === user) {
                            player_index = i;
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_sva.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'BJ') {
                    for (let i = 1; i <= this.m_bj.Players; i++) {
                        if (this.m_bj.Name[i] === user) {
                            player_index = i;
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_bj.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Poker') {
                    for (let i = 1; i <= this.m_pok.Players; i++) {
                        if (this.m_pok.Name[i] === user) {
                            player_index = i;
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_pok.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'PokerOmaha') {
                    for (let i = 1; i <= this.m_poko.Players; i++) {
                        if (this.m_poko.Name[i] === user) {
                            player_index = i;
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_poko.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'DrawPoker') {
                    for (let i = 1; i <= this.m_dpok.Players; i++) {
                        if (this.m_dpok.Name[i] === user) {
                            player_index = i;
                        }
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_dpok.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Bridge') {
                    if (this.m_bri.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.m_bri.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.m_bri.Name[4] === user) {
                        player_index = 4;
                    }
                    if (this.m_bri.watch && this.m_bri.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_bri.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Santase') {
                    if (this.m_san.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.m_san.watch && this.m_san.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.m_san.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Tabla') {
                    if (this.tab.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.tab.watch && this.tab.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.tab.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Domino2') {
                    if (this.dom2.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.dom2.watch && this.dom2.Name[1] === user) {
                        player_index = 1;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.dom2.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
                if (this.game === 'Domino4') {
                    if (this.dom4.Name[2] === user) {
                        player_index = 2;
                    }
                    if (this.dom4.Name[3] === user) {
                        player_index = 3;
                    }
                    if (this.dom4.Name[4] === user) {
                        player_index = 4;
                    }
                    if (player_index > 0) {
                        message = message.substring(sp_index + 3, message.length);
                        this.dom4.chat_message[player_index] = message;
                        this.setState({});
                    }
                }
            }
            return true;
        }
        if (f === 'T') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let message = this.m_main.FixLanguageMessagesFromServer(this.ReceiveString(500), this.m_main.language);
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText(message));
            await this.m_ok[this.messageokIndex].DoModal();
            return true;
        }
        if (f === 'U') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let title = this.ReceiveString(500);
            this.m_main.Title = title;
            if (!this.isEnableOpenVipDlg()) {
                title = '';
            }
            this.m_main.Title2 = 'Favorite Games   ' + GlobalConstants.GAME_VERSION + '   << ' + title + '   >>';
            return true;
        }
        if (f === 'V') {
            return true;
        }
        if (f === 'W') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let desc = this.ReceiveString(200);
            if (this.state.DlgOnlineVisible) {
                this.m_dbo.SetTableDescription(desc);
            }
            return true;
        }
        if (f === 'X') {
            if (!this.CheckMessages(4, f + '|')) {
                return false;
            }
            let rating = this.ReceiveString(30);
            let username = this.ReceiveString(20);
            let tournament_chips = this.ReceiveString(30);
            let db_chips = this.ReceiveString(30);
            if (this.state.DlgOnlineVisible) {
                this.m_dbo.SetRating(rating);
                this.m_dbo.SetMoney(tournament_chips, db_chips);
            } else {
                if (this.game === 'Tabla') {
                    this.tab.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Domino2') {
                    this.dom2.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Domino3') {
                    this.dom3.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Domino4') {
                    this.dom4.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Santase') {
                    this.m_san.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Belot4') {
                    this.b4.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Belot3') {
                    this.b3.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Belot2') {
                    this.b2.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Blato') {
                    this.m_bla.SetPlayerRating(username, this.m_main._ttoi(rating));
                    this.m_bla.DrawRating();
                }
                if (this.game === 'OKEY') {
                    this.m_okey.SetPlayerRating(username, this.m_main._ttoi(rating));
                    this.m_okey.DrawRating();
                }
                if (this.game === 'Monopoly') {
                    this.mon.SetPlayerRating(username, this.m_main._ttoi(rating));
                    this.mon.DrawRating();
                }
                if (this.game === 'NSSC') {
                    this.m_nssc.SetPlayerRating(username, this.m_main._ttoi(rating));
                }
                if (this.game === 'Bridge') {
                    this.m_bri.SetPlayerRating(username, this.m_main._ttoi(rating));
                    this.m_bri.DrawRating();
                }
                if (this.game === 'Poker') {
                    this.m_pok.SetAllMoneyForBet(username, this.m_main._ttof(tournament_chips));
                    this.m_pok.SetPlayerURating(username, rating);
                }
                if (this.game === 'PokerOmaha') {
                    this.m_poko.SetAllMoneyForBet(username, this.m_main._ttof(tournament_chips));
                    this.m_poko.SetPlayerURating(username, rating);
                }
                if (this.game === 'DrawPoker') {
                    this.m_dpok.SetAllMoneyForBet(username, this.m_main._ttof(tournament_chips));
                    this.m_dpok.SetPlayerURating(username, rating);
                }
                if (this.game === 'Svara') {
                    this.m_sva.SetAllMoneyForBet(username, this.m_main._ttof(tournament_chips));
                    this.m_sva.SetPlayerURating(username, rating);
                }
                if (this.game === 'BJ') {
                    this.m_bj.SetAllMoneyForBet(username, this.m_main._ttof(tournament_chips));
                    this.m_bj.SetPlayerURating(username, rating);
                }
            }
            return true;
        }
        if (f === 'Y') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let reg = this.ReceiveString(10);
            //if (this.state.DlgOnlineVisible) {
            this.m_dbo.SetRegistrations(reg);
            //}
            return true;
        }
        if (f === 'Z') {
            return true;
        }
        if (f === '*') {
            if (!this.CheckMessages(1, f + '|')) {
                return false;
            }
            let zstr = this.ReceiveString(1);
            if (zstr === 'A') {
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let message = this.ReceiveString(300);
                this.pFrame.m_chat_bar.AddMessage(message);
                return true;
            }
            if (zstr === 'B') {
                return true;
            }
            if (zstr === 'C') {
                //Poluchavane na Pausa
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let username = this.ReceiveString(20);
                if (this.game === 'Santase') {
                    this.m_san.online_timer = 180;
                    this.m_san.pause_player = username;
                }
                if (this.game === 'Belot4') {
                    this.b4.online_timer = 180;
                    this.b4.pause_player = username;
                }
                if (this.game === 'Blato') {
                    this.m_bla.online_timer = 180;
                    this.m_bla.pause_player = username;
                }
                if (this.game === 'Belot3') {
                    this.b3.online_timer = 180;
                    this.b3.pause_player = username;
                }
                if (this.game === 'Belot2') {
                    this.b2.online_timer = 180;
                    this.b2.pause_player = username;
                }
                if (this.game === 'OKEY') {
                    this.m_okey.online_timer = 180;
                    this.m_okey.pause_player = username;
                }
                if (this.game === 'Monopoly') {
                    this.mon.online_timer = 180;
                    this.mon.pause_player = username;
                }
                if (this.game === 'NSSC') {
                    this.m_nssc.online_timer = 180;
                    this.m_nssc.pause_player = username;
                }
                if (this.game === 'Tabla') {
                    this.tab.online_timer = 180;
                    this.tab.pause_player = username;
                }
                if (this.game === 'Domino2') {
                    this.dom2.online_timer = 180;
                    this.dom2.pause_player = username;
                }
                if (this.game === 'Domino3') {
                    this.dom3.online_timer = 180;
                    this.dom3.pause_player = username;
                }
                if (this.game === 'Domino4') {
                    this.dom4.online_timer = 180;
                    this.dom4.pause_player = username;
                }
                if (this.game === 'Poker') {
                    this.m_pok.online_timer = 180;
                    this.m_pok.pause_player = username;
                }
                if (this.game === 'PokerOmaha') {
                    this.m_poko.online_timer = 180;
                    this.m_poko.pause_player = username;
                }
                if (this.game === 'DrawPoker') {
                    this.m_dpok.online_timer = 180;
                    this.m_dpok.pause_player = username;
                }
                if (this.game === 'Svara') {
                    this.m_sva.online_timer = 180;
                    this.m_sva.pause_player = username;
                }
                if (this.game === 'BJ') {
                    this.m_bj.online_timer = 180;
                    this.m_bj.pause_player = username;
                }
                if (this.game === 'Bridge') {
                    this.m_bri.online_timer = 180;
                    this.m_bri.pause_player = username;
                }
                return true;
            }
            if (zstr === 'D') {
                //Poluchavane na Detaili na User-a
                if (!this.CheckMessages(16, f + '|' + zstr + '|')) {
                    return false;
                }

                let username = this.ReceiveString(20);
                let name = this.m_main.FixLanguageMessagesFromServer(this.ReceiveString(100), this.m_main.GetUserLanguage(username));
                let country = this.ReceiveString(100);
                let city = this.m_main.FixLanguageMessagesFromServer(this.ReceiveString(100), this.m_main.GetUserLanguage(username));
                let language = this.ReceiveString(100);
                let sex = this.ReceiveString(1);
                let age = this.ReceiveString(5);
                let description = this.m_main.FixLanguageMessagesFromServer(this.ReceiveString(200), this.m_main.GetUserLanguage(username));
                let ver = this.ReceiveString(10);
                let image = this.ReceiveString(512);
                let rating = this.ReceiveString(30);
                let os = this.ReceiveString(30);
                let vip = this.ReceiveString(30);
                let time = this.ReceiveString(30);
                let fcash = this.ReceiveString(30);
                let dbcash = this.ReceiveString(30);

                this.m_main.AddUserDetails(
                    username,
                    name,
                    country,
                    city,
                    language,
                    sex,
                    age,
                    description,
                    ver,
                    image,
                    rating,
                    os,
                    vip,
                    time,
                    fcash,
                    dbcash
                );
                if (image !== '' && image !== '-') {
                    this.m_main.AddToCustomImage(username, image);
                    if (username === this.m_con.getUsername()) {
                        if (this.state.DlgOnlineVisible) {
                            this.m_dbo.user_pic.image = image;
                            this.m_dbo.setState({});
                        }
                    }
                }
                if (this.game !== '') {
                    //Refactor Use AddUserDetails instead SetPlayerParameters for all games. Use pointer to CCardGamesView
                    if (this.game === 'Bridge') {
                        this.m_bri.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Poker') {
                        this.m_pok.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'PokerOmaha') {
                        this.m_poko.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'DrawPoker') {
                        this.m_dpok.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Svara') {
                        this.m_sva.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'BJ') {
                        this.m_bj.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Tabla') {
                        this.tab.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                        this.tab.InvalidateNewGameButton(); //Check game version
                    }
                    if (this.game === 'Domino2') {
                        this.dom2.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Domino3') {
                        this.dom3.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Domino4') {
                        this.dom4.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Santase') {
                        this.m_san.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Belot4') {
                        this.b4.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Belot3') {
                        this.b3.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Blato') {
                        this.m_bla.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Belot2') {
                        this.b2.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'OKEY') {
                        this.m_okey.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'Monopoly') {
                        this.mon.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    if (this.game === 'NSSC') {
                        this.m_nssc.SetPlayerParameters(username, name, country, city, language, sex, age, description, ver, image, rating, vip, os);
                    }
                    this.InvalidateStolove(-1); //??? Da se optimizira
                } else {
                    if (this.state.DlgOnlineVisible) {
                        this.m_dbo.SetPlayerInfo(username, name, city, rating, country, language, age, sex, description, image, os, vip, time, fcash); //Check if selected users is the same.
                    }
                }
                return true;
            }
            if (zstr === 'E') {
                //Poluchavane na Info za Staite
                if (!this.CheckMessages(14, f + '|' + zstr + '|')) {
                    return false;
                }
                //0 - name
                let room_name = this.ReceiveString(30);
                //1 - tournament (NONE, OPTION, REQUIRED) // Override if have tournamen, else NONE
                let tournament = this.ReceiveString(30);
                //2 - bet (NONE, OPTION, REQUIRED)
                let bet = this.ReceiveString(30);
                //3 - access //GUEST, NOVIP, VIP //Ovverridge if have free_connections or the game is free
                let access = this.ReceiveString(30);
                //4 - description
                let description = this.ReceiveString(512);
                //5 - chat_messages //NONE, GUEST, NOVIP, VIP ( NONE: No chat, GUEST: GUEST, NOVIP, VIP, NOVIP: NOVIP,VIP, VIP: VIP)
                let chat_messages = this.ReceiveString(30);
                //6 - emote_chat //NONE, GUEST, NOVIP, VIP ( NONE: No chat, GUEST: GUEST, NOVIP, VIP, NOVIP: NOVIP,VIP, VIP: VIP) //IF CHat with messegaes is disabled, check chat only with emote.
                let emote_chat = this.ReceiveString(30);
                //7 - enable_password //0,1
                let enable_password = this.ReceiveString(30);
                //8 - enable_watch //0,1
                let enable_watch = this.ReceiveString(30);
                let tooltip = this.ReceiveString(1024); //9
                let tables = this.ReceiveString(5); //10
                let players = this.ReceiveString(5); //11
                let game = this.ReceiveString(30); //12
                let bet_sum = this.ReceiveString(30); //13

                if (this.state.DlgOnlineVisible) {
                    this.m_dbo.AddRoom(
                        room_name,
                        tournament,
                        bet,
                        access,
                        this.m_main.FixLanguageMessagesFromServer(description),
                        chat_messages,
                        emote_chat,
                        enable_password,
                        enable_watch,
                        tables,
                        players,
                        game,
                        this.m_main.FixLanguageMessagesFromServer(tooltip),
                        bet_sum
                    );
                }
                return true;
            }

            if (zstr === 'F') {
                //Poluchavane na info za turnirite
                if (!this.CheckMessages(5, f + '|' + zstr + '|')) {
                    return false;
                }
                let tournament_name = this.m_main.FixLanguageMessagesFromServer(this.ReceiveString(255));
                let restart_price = this.ReceiveString(20);
                let free = this.ReceiveString(1);
                let players = this.ReceiveString(10);
                let rank = this.ReceiveString(10);
                if (this.state.DlgOnlineVisible) {
                    this.m_dbo.SetTournamentParameters(tournament_name, restart_price, free, players, rank);
                }
                return true;
            }
            if (zstr === 'G') {
                if (!this.CheckMessages(5, f + '|' + zstr + '|')) {
                    return false;
                }
                let room_name = this.ReceiveString(30);
                let tables = this.ReceiveString(5);
                let players = this.ReceiveString(5);
                let tournament = this.ReceiveString(50);
                let game = this.ReceiveString(30);
                if (this.state.DlgOnlineVisible) {
                    this.m_dbo.UpdateRoom(room_name, tables, players, tournament, game);
                }
                return true;
            }
            if (zstr === 'H') {
                //Opcii za igrata
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let par = this.ReceiveString(1);
                if (par === '0') {
                    //Close Table
                    this.close_game_m_filename = 'files_1.0\\online\\close.gif';
                    this.setState({});
                }
                if (par === '1') {
                    //Open Table
                    this.close_game_m_filename = 'files_1.0\\online\\open.gif';
                    this.setState({});
                }
                if (par === '2') {
                    //Close With New Password
                }
                if (par === '3') {
                    //Enable Watch Table
                    this.m_main.enable_watch_game = true;
                    this.setState({});
                }
                if (par === '4') {
                    //Disable Watch Table
                    this.m_main.enable_watch_game = false;
                    this.setState({});
                }
                return true;
            }
            if (zstr === 'I') {
                //Kick User
                this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('You have been kicked of this table!'));
                this.m_ok[this.messageokIndex].DoModal();
                this.GoToLobby();
                return true;
            }
            if (zstr === 'J') {
                //receive Watchers
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let wusername = this.ReceiveString(12);
                this.pFrame.m_chat_bar.logInWatchUser(wusername);
                return true;
            }
            if (zstr === 'K') {
                //GetInviteToGame
                if (!this.CheckMessages(2, f + '|' + zstr + '|')) {
                    return false;
                }
                let iusername = this.ReceiveString(50);
                let susername = this.ReceiveString(50);
                if (this.game !== '' && !this.invite_msg) {
                    this.invite_msg = true;
                    let title: string = this.m_main.GetLanguageText('(username) invites you. Do you want to enter into this game?');
                    title = title.replace('(username)', iusername);
                    this.m_yesno[this.messageyesnoIndex].SetTitle(title);
                    if ((await this.m_yesno[this.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                        if (this.game === 'Poker' || this.game === 'PokerOmaha') {
                            //???Da se dobavi info za svara i BJ, omaha i draw
                            if (this.game === 'Poker') {
                                for (let i = 2; i <= this.m_pok.Players; i++) {
                                    if (this.m_pok.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_pok.wait_opplayers[i]) {
                                        this.m_pok.SitPlayer(i);
                                        this.invite_msg = false;
                                        return true;
                                    }
                                }
                            }
                            if (this.game === 'PokerOmaha') {
                                for (let i = 2; i <= this.m_poko.Players; i++) {
                                    if (this.m_poko.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_poko.wait_opplayers[i]) {
                                        this.m_poko.SitPlayer(i);
                                        this.invite_msg = false;
                                        return true;
                                    }
                                }
                            }
                        } else {
                            this.GoToLobby();
                            this.m_dbo.auto_connect = susername;
                        }
                    }
                    this.invite_msg = false;
                }
                return true;
            }
            if (zstr === 'L') {
                let message = this.m_main.GetLanguageText('Download the last version of the game') + '!';
                if (Platform.OS === 'web') {
                    message = 'To play online, clear your browser cache!';
                }

                this.m_ok[this.messageokIndex].SetTitle(message);
                await this.m_ok[this.messageokIndex].DoModal();
                if (Platform.OS !== 'web') {
                    this.openPage(this.m_main.GetLanguageText('downloads_online'));
                }
                this.OnNewGame();
                return true;
            }
            if (zstr === 'M') {
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let enable = this.ReceiveString(1);
                if (enable === '0') {
                    this.pFrame.m_chat_bar.EnableChat(false);
                }
                if (enable === '1') {
                    this.pFrame.m_chat_bar.EnableChat(true);
                }
                return true;
            }
            if (zstr === 'N') {
                return true;
            }
            if (zstr === 'P') {
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let ignore_list = this.ReceiveString(33000); //33000 for 300 usernames from version 477, old value was 22 000 only for 200 users
                if (ignore_list === '-') {
                    ignore_list = '';
                }
                await this.m_main.WriteProfileString('Options', 'ignore_list_' + this.m_con.getUsername(), ignore_list);
                this.LoadIgnoreList(this.m_con.getUsername());
                return true;
            }
            if (zstr === 'Q') {
                if (!this.CheckMessages(1, f + '|' + zstr + '|')) {
                    return false;
                }
                let friend_list = this.ReceiveString(33000);
                if (friend_list === '-') {
                    friend_list = '';
                }
                await this.m_main.WriteProfileString('Options', 'friends_list_' + this.m_con.getUsername(), friend_list);
                this.LoadFriendsList(this.m_con.getUsername());
                return true;
            }
            if (zstr === 'R') {
                if (!this.CheckMessages(2, f + '|' + zstr + '|')) {
                    return false;
                }
                let ok = this.ReceiveString(1);
                let username = this.ReceiveString(20);
                if (ok === '1') {
                    await this.AddToPlayerList(username);
                } else {
                    this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('This username not exist') + '!');
                    await this.m_ok[this.messageokIndex].DoModal();
                }
                return true;
            }
        }
        this.SendErrorToLog('Izpusnato e saobshtenie!View : ' + f);
        return true;
    }

    async AddToPlayerList(username: string) {
        if (this.state.PlayersFilterVisible) {
            await this.pf.InsertUsername(username);
        } else {
            if (this.state.Dlg432PlayersVisible) {
                await this.m_d432.pf.InsertUsername(username);
            }
        }
    }

    async OnWatchGame(lgame: string, table_password: string) {
        if (lgame === 'WLudo') {
            //Ako se nabliudava igra NSSC
            await this.m_nssc.InitMain();
            this.m_nssc.initConstructor();
            this.m_main.enable_watch_game = true;
            this.m_nssc.rating[1] = this.m_dbo.p1_rating;
            this.game = 'NSSC';
            this.m_nssc.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_nssc.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_nssc.Speed[1] = 100;
            }
            await this.m_nssc.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WB4') {
            //Ako se nabliudava igra Belot s 4 igrachi
            await this.b4.InitMain();
            this.b4.initConstructor();
            this.m_main.enable_watch_game = true;
            this.b4.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot4';
            this.b4.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.b4.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.b4.Speed[1] = 100;
            }
            await this.b4.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WBa1') {
            //Ako Nabliudava Tabla obiknovenna
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.tab.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.tab.Speed[1] = 100;
            }
            await this.tab.InitOP(false, 0);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WBa2') {
            //Ako Nabliudava Tabla Tapa
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 1);
            this.tab.watch = true;
            this.m_main.watch = true;
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }

        if (lgame === 'WBa3') {
            //Ako Nabliudava Tabla Gulbara
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 2);
            this.tab.watch = true;
            this.m_main.watch = true;
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WBa31') {
            //Ako Nabliudava Tabla 3 in 1
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.tab.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            await this.tab.InitOP(false, 3);
            this.tab.watch = true;
            this.m_main.watch = true;
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WBr1') {
            //Ako se nabliudava igra Bridge Rubber
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            this.m_main.enable_watch_game = true;
            this.m_bri.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Bridge';
            this.m_bri.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_bri.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_bri.Speed[1] = 100;
            }
            await this.m_bri.InitOP(false, 1);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WBr2') {
            //Ako se nabliudava igra Duplicate Bridge
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            this.m_main.enable_watch_game = true;
            this.m_bri.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Bridge';
            this.m_bri.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_bri.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_bri.Speed[1] = 100;
            }
            await this.m_bri.InitOP(false, 2);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'W358') {
            //Ako se nabliudava igra Blato
            await this.m_bla.InitMain();
            this.m_bla.initConstructor();
            this.m_bla.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.m_bla.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Blato';
            this.m_bla.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_bla.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_bla.Speed[1] = 100;
            }
            await this.m_bla.InitOP(false, this.m_dbo.GameType);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WS') {
            //Ako nabliudava igra Santase
            await this.m_san.InitMain();
            this.m_san.initConstructor();
            this.m_san.KoiENaRed = -1;
            this.m_main.enable_watch_game = true;
            this.m_san.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Santase';
            this.m_san.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_san.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_san.Speed[1] = 100;
            }
            await this.m_san.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WOKEY') {
            //Ako se nabliudava igra OKEY
            await this.m_okey.InitMain();
            this.m_main.enable_watch_game = true;
            this.m_okey.rating[1] = this.m_dbo.p1_rating;
            this.game = 'OKEY';
            this.m_okey.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.m_okey.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_okey.Speed[1] = 100;
            }
            await this.m_okey.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WMonopoly') {
            //Ako se nabliudava igra WMonopoly
            await this.mon.InitMain();
            this.m_main.enable_watch_game = true;
            this.mon.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Monopoly';
            this.mon.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.mon.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.mon.Speed[2] = 5;
            }
            this.mon.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }

        if (lgame === 'WB3') {
            //Ako se nabliudava igra Belot s 3 igrachi
            await this.b3.InitMain();
            this.m_main.enable_watch_game = true;
            this.b3.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot3';
            this.b3.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.b3.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.b3.Speed[1] = 100;
            }
            this.b3.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WB2') {
            //Ako se nabliudava igra Belot s 2 igrachi
            await this.b2.InitMain();
            this.m_main.enable_watch_game = true;
            this.b2.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot2';
            this.b2.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.b2.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.b2.Speed[1] = 100;
            }
            await this.b2.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WPT') {
            //Ako se nabliudava igra Poker Texas
            await this.m_pok.InitMain();
            this.m_main.enable_watch_game = true;
            this.game = 'Poker';
            this.m_pok.rating[1] = this.m_dbo.p1_rating;
            this.m_pok.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_pok.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_pok.Speed[1] = 100;
                this.m_pok.Speed[2] = 5;
                this.m_pok.Speed[3] = 5;
                this.m_pok.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_pok.InitOP(false, pplayers);
            this.m_pok.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WPO') {
            //Ako se nabliudava igra Poker Texas
            await this.m_poko.InitMain();
            this.m_main.enable_watch_game = true;
            this.game = 'PokerOmaha';
            this.m_poko.rating[1] = this.m_dbo.p1_rating;
            this.m_poko.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_poko.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_poko.Speed[1] = 100;
                this.m_poko.Speed[2] = 5;
                this.m_poko.Speed[3] = 5;
                this.m_poko.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_poko.InitOP(false, pplayers);
            this.m_poko.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WDP') {
            //Ako se nabliudava igra Poker Texas
            await this.m_dpok.InitMain();
            this.m_main.enable_watch_game = true;
            this.m_dpok.rating[1] = this.m_dbo.p1_rating;
            this.game = 'DrawPoker';
            this.m_dpok.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_dpok.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_dpok.Speed[1] = 100;
                this.m_dpok.Speed[2] = 5;
                this.m_dpok.Speed[3] = 5;
                this.m_dpok.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_dpok.InitOP(false, pplayers);
            this.m_dpok.InitPokerParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WSV') {
            //Ako se nabliudava igra Svara
            await this.m_sva.InitMain();
            this.m_main.enable_watch_game = true;
            this.m_sva.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Svara';
            this.m_sva.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            let pplayers = this.m_dbo.Players;
            this.m_sva.watch = true;
            this.m_main.watch = true;
            if (!this.m_main.getIsTest()) {
                this.m_sva.Speed[1] = 100;
                this.m_sva.Speed[2] = 5;
                this.m_sva.Speed[3] = 5;
                this.m_sva.Speed[4] = 5;
            }
            this.KillTimer(2);
            this.KillTimer(3);
            this.KillTimer(4);
            this.SetTimer(2, 5);
            this.SetTimer(3, 5);
            this.SetTimer(4, 5);
            this.m_sva.InitOP(false, pplayers);
            this.m_sva.InitSvaraParameters(this.m_dbo.sblind, this.m_dbo.money);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
        if (lgame === 'WDo2') {
            //Ako Nabliudava Domino 2 Players
            await this.dom2.InitMain();
            this.m_main.enable_watch_game = true;
            this.dom2.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Domino2';
            this.dom2.Name[1] = this.m_dbo.Select[0];
            this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
            this.dom2.watch = true;
            this.m_main.watch = true;
            this.dom2.InitOP(false);
            let message = 'P|' + this.m_dbo.Select[0] + '|' + table_password;
            this.GetPlayerParametersString(this.m_dbo.Select[0]);
            this.SendGame(message);
        }
    }

    GetServerConfigValue(parameter: string, config_row: string): number {
        let res: number = 0;
        let tz_index = config_row.indexOf(parameter + '=');
        if (tz_index === 0) {
            res = this.m_main._ttoi(config_row.substring(tz_index + parameter.length + 1, config_row.length));
        }
        return res;
    }

    GetServerConfigValueString(parameter: string, config_row: string): string {
        let res: string = '';
        let tz_index = config_row.indexOf(parameter + '=');
        if (tz_index === 0) {
            res = config_row.substring(tz_index + parameter.length + 1, config_row.length);
        }
        return res;
    }

    ParseServerConfiguration(server_configuration: string) {
        while (server_configuration.indexOf(';') > -1) {
            let tz_index = server_configuration.indexOf(';');
            let config_row = server_configuration.substring(0, tz_index);
            if (config_row.indexOf('client_log_min_ver=') > -1) {
                this.m_main.client_log_min_ver = this.GetServerConfigValue('client_log_min_ver', config_row);
            }
            if (config_row.indexOf('kick_price=') > -1) {
                this.m_main.kick_price = this.GetServerConfigValue('kick_price', config_row);
            }
            if (config_row.indexOf('show_ad_price=') > -1) {
                this.m_main.show_ad_price = this.GetServerConfigValue('show_ad_price', config_row);
            }
            if (config_row.indexOf('vip_price=') > -1) {
                this.m_main.vip_price = this.GetServerConfigValue('vip_price', config_row);
            }
            if (config_row.indexOf('delete_statistic_price=') > -1) {
                this.m_main.delete_statistic_price = this.GetServerConfigValue('delete_statistic_price', config_row);
            }
            if (config_row.indexOf('poker_commission_bet_100=') > -1) {
                let coef: number = this.GetServerConfigValue('poker_commission_bet_100', config_row);
                this.m_main.poker_commission_bet_100 = coef / 10;
            }
            if (config_row.indexOf('poker_commission_bet_10=') > -1) {
                let coef: number = this.GetServerConfigValue('poker_commission_bet_10', config_row);
                this.m_main.poker_commission_bet_10 = coef / 10;
            }
            if (config_row.indexOf('sms_vip=') > -1) {
                this.m_main.sms_vip = this.GetServerConfigValueString('sms_vip', config_row);
            }
            if (config_row.indexOf('sms_chips=') > -1) {
                this.m_main.sms_chips = this.GetServerConfigValueString('sms_chips', config_row);
            }
            server_configuration = server_configuration.substring(tz_index + 1, server_configuration.length);
        }
    }

    CheckMessages(num: number, restore: string) {
        let br_ = 0;
        for (let i = 0; i < this.m_pClientSocket.m_strRecv.length; i++) {
            if (this.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                br_ = br_ + 1;
            }
        }
        if (br_ < num) {
            this.m_pClientSocket.RestoreMessage(restore);
            return false;
        }
        return true;
    }

    isOpenVerAlert() {
        return false;
    }

    ChangeMenuText() {}

    async OnTimer1() {
        try {
            if (
                this.m_pClientSocket.m_strRecv.length > 0 &&
                this.m_pClientSocket.m_strRecv.charAt(this.m_pClientSocket.m_strRecv.length - 1) === '|'
            ) {
                this.Receive();
            }
            if (this.initmenu !== true) {
                this.initmenu = true;
                this.m_main.changeLanguage(await this.m_main.GetProfileString('Options', 'language', this.m_main.GetDefaultLanguage()));
                this.ChangeMenuText();
                this.OnNewGame();
            } else {
                if (this.isOpenVerAlert() && this.on_new_game_start) {
                    this.m_con.OnCancel();
                }
            }
            if (this.game === 'Belot4') {
                await this.b4.Timer1();
            }
            if (this.game === 'Belot3') {
                this.b3.Timer1();
            }
            if (this.game === 'Belot2') {
                await this.b2.Timer1();
            }
            if (this.game === 'Santase') {
                this.m_san.Timer1();
            }
            if (this.game === 'Bridge') {
                this.m_bri.Timer1();
            }
            if (this.game === 'Svara') {
                this.m_sva.Timer1();
            }
            if (this.game === 'Poker') {
                this.m_pok.Timer1();
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.Timer1();
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.Timer1();
            }
            if (this.game === 'BJ') {
                this.m_bj.Timer1();
            }
            if (this.game === 'Blato') {
                this.m_bla.Timer1();
            }
            if (this.game === 'Tabla') {
                this.tab.Timer1();
            }
            if (this.game === 'OKEY') {
                this.m_okey.Timer1();
            }
            if (this.game === 'Monopoly') {
                this.mon.Timer1();
            }
            if (this.game === 'NSSC') {
                this.m_nssc.Timer1();
            }
        } catch (e) {
            console.error('Catch Error Timer1', e);
        }
    }

    GetGameConfig(): string {
        return `${config.GAME}`;
    }

    ShowDiceOption() {
        let gameConfig: string = `${config.GAME}`;
        if (gameConfig === 'All' || gameConfig === 'Tabla' || gameConfig === 'NSSC' || gameConfig === 'Monopoly') {
            return true;
        }
    }

    IsCardApp(): boolean {
        let gameConfig: string = `${config.GAME}`;
        if (
            gameConfig === 'All' ||
            gameConfig === 'Belote' ||
            gameConfig === 'Bridge' ||
            gameConfig === 'BJ' ||
            gameConfig === 'Poker' ||
            gameConfig === 'PokerOmaha' ||
            gameConfig === 'DrawPoker' ||
            gameConfig === 'Svara' ||
            gameConfig === 'Santase' ||
            gameConfig === 'Blato'
        ) {
            return true;
        }
        return false;
    }

    IsCardGame(): boolean {
        if (
            this.game === 'Belot4' ||
            this.game === 'Belot3' ||
            this.game === 'Belot2' ||
            this.game === 'Domino4' ||
            this.game === 'Domino3' ||
            this.game === 'Domino2' ||
            this.game === 'BJ' ||
            this.game === 'Poker' ||
            this.game === 'PokerOmaha' ||
            this.game === 'DrawPoker' ||
            this.game === 'Bridge' ||
            this.game === 'Svara' ||
            this.game === 'Santase' ||
            this.game === 'Blato'
        ) {
            return true;
        }
        return false;
    }

    IsGameOnline(): boolean {
        if (
            (this.game === 'Belot4' && this.b4.OP) ||
            (this.game === 'Blato' && this.m_bla.OP) ||
            (this.game === 'Belot3' && this.b3.OP) ||
            (this.game === 'Belot2' && this.b2.OP) ||
            (this.game === 'OKEY' && this.m_okey.OP) ||
            (this.game === 'Monopoly' && this.mon.OP) ||
            (this.game === 'NSSC' && this.m_nssc.OP) ||
            (this.game === 'Santase' && this.m_san.OP) ||
            (this.game === 'Tabla' && this.tab.OP) ||
            (this.game === 'Domino2' && this.dom2.OP) ||
            (this.game === 'Domino3' && this.dom3.OP) ||
            (this.game === 'Domino4' && this.dom4.OP) ||
            (this.game === 'Bridge' && this.m_bri.OP) ||
            (this.game === 'Poker' && this.m_pok.OP) ||
            (this.game === 'PokerOmaha' && this.m_poko.OP) ||
            (this.game === 'DrawPoker' && this.m_dpok.OP) ||
            (this.game === 'BJ' && this.m_bj.OP) ||
            (this.game === 'Svara' && this.m_sva.OP)
        ) {
            return true;
        }
        return false;
    }

    async OnTimer2() {
        try {
            if (this.game === 'Blato') {
                await this.m_bla.Timer2();
            }
            if (this.game === 'Belot4') {
                await this.b4.Timer2();
            }
            if (this.game === 'OKEY') {
                this.m_okey.Timer2();
            }
            if (this.game === 'Monopoly') {
                this.mon.Timer2();
            }
            if (this.game === 'NSSC') {
                this.m_nssc.Timer2();
            }
            if (this.game === 'Belot3') {
                this.b3.Timer2();
            }
            if (this.game === 'Belot2') {
                await this.b2.Timer2();
            }
            if (this.game === 'Bridge') {
                await this.m_bri.Timer2();
            }
            if (this.game === 'Svara') {
                this.m_sva.Timer2();
            }
            if (this.game === 'Poker') {
                this.m_pok.Timer2();
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.Timer2();
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.Timer2();
            }
            if (this.game === 'BJ') {
                this.m_bj.Timer2();
            }
            if (this.send_ping && this.IsGameOnline() && this.last_ping_dif > 0) {
                let ms = Date.now();
                let ping = ms;
                let dif = ping - this.last_send_ping;
                if (dif > this.last_ping_dif + 10) {
                    this.last_ping_dif = dif;
                    if (this.last_ping_dif < 10001) {
                        this.InvalidateIndikator();
                    }
                }
            }
        } catch (e) {
            console.error('Catch Error Timer2', e);
        }
    }

    InvalidateIndikator() {
        this.setState({});
    }
    async OnTimer3() {
        try {
            if (this.game === 'Bridge') {
                this.m_bri.Timer3();
            }
            if (this.game === 'Svara') {
                this.m_sva.Timer3();
            }
            if (this.game === 'Poker') {
                this.m_pok.Timer3();
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.Timer3();
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.Timer3();
            }
            if (this.game === 'BJ') {
                this.m_bj.Timer3();
            }
            if (this.game === 'Santase') {
                await this.m_san.Timer3();
            }
            if (this.game === 'Tabla') {
                await this.tab.Timer3();
            }
            if (this.game === 'OKEY') {
                this.m_okey.Timer3();
            }
            if (this.game === 'Monopoly') {
                this.mon.Timer3();
            }
            if (this.game === 'NSSC') {
                await this.m_nssc.Timer3();
            }
            if (this.game === 'Blato') {
                this.m_bla.Timer3();
            }
            if (this.game === 'Belot4') {
                await this.b4.Timer3();
            }
            if (this.game === 'Belot3') {
                this.b3.Timer3();
            }
            if (this.game === 'Belot2') {
                await this.b2.Timer3();
            }
            if (this.game === 'Domino2') {
                this.dom2.Timer3();
            }
            if (this.game === 'Domino3') {
                this.dom3.Timer3();
            }
            if (this.game === 'Domino4') {
                this.dom4.Timer3();
            }
        } catch (e) {
            console.error('Catch Error Timer3', e);
        }
    }
    async OnTimer4() {
        try {
            if (this.game === 'Blato') {
                await this.m_bla.Timer4();
            }
            if (this.game === 'Belot4') {
                await this.b4.Timer4();
            }
            if (this.game === 'Belot3') {
                this.b3.Timer4();
            }
            if (this.game === 'Belot2') {
                await this.b2.Timer4();
            }
            if (this.game === 'Santase') {
                await this.m_san.Timer4();
            }
            if (this.game === 'Bridge') {
                this.m_bri.Timer4();
            }
            if (this.game === 'Svara') {
                this.m_sva.Timer4();
            }
            if (this.game === 'OKEY') {
                this.m_okey.Timer4();
            }
            if (this.game === 'NSSC') {
                this.m_nssc.Timer4();
            }
            if (this.game === 'Poker') {
                this.m_pok.Timer4();
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.Timer4();
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.Timer4();
            }
            if (this.game === 'BJ') {
                this.m_bj.Timer4();
            }
        } catch (e) {
            console.error('Catch Error Timer4', e);
        }
    }
    OnTimer5() {
        /*    try {
            
        } catch (e) {
            console.error('Catch Error Timer5', e);
        }*/
    }
    SendIndikatomer() {
        if (this.IsGameOnline()) {
            if (this.send_ping === false) {
                let ms = Date.now();
                let ping = ms;
                this.last_send_ping = ping;
                this.SendGame('J|' + this.m_main.IntToString(ping));
                this.send_ping = true;
            }
        }
    }
    async OnTimer7() {
        try {
            this.checkConnection();
            if (this.game === 'Belot4') {
                if (this.b4.OP) {
                    await this.b4.Timer7();
                }
            }
            if (this.game === 'Blato') {
                if (this.m_bla.OP) {
                    this.m_bla.Timer7();
                }
            }
            if (this.game === 'Belot3') {
                if (this.b3.OP) {
                    this.b3.Timer7();
                }
            }
            if (this.game === 'Belot2') {
                if (this.b2.OP) {
                    await this.b2.Timer7();
                }
            }
            if (this.game === 'Poker') {
                if (this.m_pok.OP || this.m_pok.Faza > 49) {
                    this.m_pok.Timer7();
                }
            }
            if (this.game === 'PokerOmaha') {
                if (this.m_poko.OP || this.m_poko.Faza > 49) {
                    this.m_poko.Timer7();
                }
            }
            if (this.game === 'DrawPoker') {
                if (this.m_dpok.OP || this.m_dpok.Faza > 49) {
                    this.m_dpok.Timer7();
                }
            }
            if (this.game === 'BJ') {
                if (this.m_bj.OP) {
                    this.m_bj.Timer7();
                }
            }
            if (this.game === 'Bridge') {
                if (this.m_bri.OP) {
                    this.m_bri.Timer7();
                }
            }
            if (this.game === 'Svara') {
                if (this.m_sva.OP) {
                    this.m_sva.Timer7();
                }
            }
            if (this.game === 'Santase') {
                if (this.m_san.OP) {
                    this.m_san.Timer7();
                }
            }
            if (this.game === 'Tabla') {
                if (this.tab.OP) {
                    await this.tab.Timer7();
                }
            }
            if (this.game === 'Domino2') {
                if (this.dom2.OP) {
                    this.dom2.Timer7();
                }
            }
            if (this.game === 'Domino3') {
                if (this.dom3.OP) {
                    this.dom3.Timer7();
                }
            }
            if (this.game === 'Domino4') {
                if (this.dom4.OP) {
                    this.dom4.Timer7();
                }
            }
            if (this.game === 'OKEY') {
                if (this.m_okey.OP) {
                    this.m_okey.Timer7();
                }
            }
            if (this.game === 'Monopoly') {
                if (this.mon.OP) {
                    this.mon.Timer7();
                }
            }
            if (this.game === 'NSSC') {
                if (this.m_nssc.OP) {
                    this.m_nssc.Timer7();
                }
            }
            if (this.isEnableOpenVipDlg()) {
                if (this.state.DlgOnlineVisible) {
                    this.m_dbo.onChangeTitle();
                }
            }
        } catch (e) {
            console.error('Catch Error Timer7', e);
        }
    }

    async Login() {
        let reg = '0'; //Unused parameter
        let l_string =
            'A|' +
            this.m_con.getUsername() +
            '|' +
            this.m_con.m_password +
            '|' +
            this.m_main.GetOnlineVersion() +
            '|' +
            this.m_main.language +
            '|' +
            (await this.m_main.GetGameNumber()) +
            '|' +
            reg +
            '|' +
            this.ipAddress;
        if (this.SendGame(l_string)) {
            this.connected = true;
        } else {
            await this.noConnection();
        }
        this.m_main.WriteProfileString('Options', 'last_password', this.m_con.m_password);
    }

    async LoginAsGuest() {
        let l_string =
            'N|' + this.m_main.GetOnlineVersion() + '|' + this.m_main.language + '|' + (await this.m_main.GetGameNumber()) + '|0|' + this.ipAddress;
        if (this.SendGame(l_string)) {
            this.connected = true;
            this.m_con.loading = false;
            this.m_con.setState({});
        } else {
            await this.noConnection();
        }
    }

    async ShowInfoBar(show: boolean) {
        if (show && (this.game === 'Belot4' || this.game === 'Belot3' || this.game === 'Belot2' || this.game === 'Bridge' || this.game === 'Blato')) {
            this.pFrame.m_info_bar.ShowWindow((await this.m_main.GetProfileInt('Options', 'bidd' + this.game, 0)) === 1);
        } else {
            if (this.state.CInfoBarVisible) {
                this.pFrame.m_info_bar.ShowWindow(false);
            }
        }
    }

    async CloseIgraNSSC() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igrinssc', (await this.m_main.GetProfileInt('Options', 'igrinssc', 0)) + 1);
        switch (this.m_nsscdlg.Game) {
            case 1:
                await this.m_nssc.InitMain();
                this.m_nssc.initConstructor();
                this.game = 'NSSC';
                await this.m_nssc.InitSP();
                break;
            case 3:
                this.OnlineGame();
                break;
        }
        this.setState({});
    }

    async CloseIgraSantase() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igrisantase', (await this.m_main.GetProfileInt('Options', 'igrisantase', 0)) + 1);
        switch (this.m_sandlg.Game) {
            case 1:
                await this.m_san.InitMain();
                this.m_san.initConstructor();
                this.game = 'Santase';
                await this.m_san.InitSP();
                break;
            case 3:
                this.OnlineGame();
                break;
        }
        this.setState({});
    }

    async CloseIgraTabla() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igritabla', (await this.m_main.GetProfileInt('Options', 'igritabla', 0)) + 1);
        if (this.m_tabdlg.Game === 7) {
            this.OnlineGame();
        } else {
            await this.tab.InitMain();
            this.tab.initConstructor();
            this.game = 'Tabla';
            await this.tab.InitSP(this.m_tabdlg.Game);
        }
        this.setState({});
    }

    async CloseIgraBridge() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igribridge', (await this.m_main.GetProfileInt('Options', 'igribridge', 0)) + 1);
        if (this.m_bridlg.Game === 3) {
            this.OnlineGame();
        } else {
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            this.game = 'Bridge';
            await this.m_bri.InitSP(this.m_bridlg.Game);
        }
        this.setState({});
    }

    async CloseIgraBlato() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igriblato', (await this.m_main.GetProfileInt('Options', 'igriblato', 0)) + 1);
        switch (this.m_bladlg.Game) {
            case 1:
                await this.m_bla.InitMain();
                this.m_bla.initConstructor();
                this.game = 'Blato';
                await this.m_bla.InitSP(this.m_bladlg.Type);
                break;
            case 3:
                this.OnlineGame();
                break;
        }
        this.setState({});
    }

    async CloseIgraBelot4() {
        this.closeAllDialogs();
        this.m_main.WriteProfileInt('Options', 'igribelot', (await this.m_main.GetProfileInt('Options', 'igribelot', 0)) + 1);
        switch (this.m_beldlg.Game) {
            case 1:
                await this.b2.InitMain();
                this.game = 'Belot2';
                await this.b2.InitSP();
                break;
            case 2:
                await this.b3.InitMain();
                this.game = 'Belot3';
                await this.b3.InitSP();
                break;
            case 3:
                await this.b4.InitMain();
                this.b4.initConstructor();
                this.game = 'Belot4';
                await this.b4.InitSP();
                break;
            case 9:
                this.OnlineGame();
                break;
        }
        this.setState({});
    }

    CloseCIzborNaIgra() {
        this.closeAllDialogs();
        switch (this.m_izbor.Game) {
            case 11:
                this.setState({ CIgraNSSCVisible: true });
                break;
            case 1:
                this.setState({ IgraBelotDlgVisible: true });
                break;
            case 2:
                this.setState({ IgraSantaseDlgVisible: true });
                break;
            case 3:
                this.setState({ IgraBlatoDlgVisible: true });
                break;
            case 5:
                this.setState({ IgraTablaVisible: true });
                break;
            case 6:
                this.setState({ IgraBridgeVisible: true });
                break;
            case 7:
                this.setState({ IgraPokerVisible: true });
                break;
            case 8:
                this.OnlineGame();
                break;
            case 9:
                this.setState({ IgraDominoDlgVisible: true });
                break;
            case 10:
                this.setState({ IgraOKEYVisible: true });
                break;

            case 13:
                this.setState({ IgraMonopolyVisible: true });
                break;
            case 14:
                this.setState({ IgraSvaraVisible: true });
                break;
            case 15:
                this.setState({ IgraBJDlgVisible: true });
                break;
            case 20:
                this.setState({ OnlineRegistrationVisible: true });
                break;
        }
        this.DownloadVerAlert();
        this.on_new_game_start = false;
        this.InitIconsTooltips();
        this.setState({});
    }

    DownloadVerAlert() {}

    OnOfflineGame() {
        this.closeAllDialogs();
        this.on_new_game_start = true;
        if (!this.m_main.loadingMain) {
            this.pFrame.m_info_bar.Clear();
        }
        this.game = '';
        if (this.state.CChatBarVisible) {
            this.ShowChatBar(false);
        }
        if (this.state.CInfoBarVisible) {
            this.ShowInfoBar(false);
        }
        this.pFrame.m_chat_bar.ClearWatchers();

        if (this.GetGameConfig() === 'All') {
            this.setState({ CIzborNaIgraVisible: true });
        } else {
            if (this.GetGameConfig() === 'Bridge') {
                this.setState({ IgraBridgeVisible: true });
            }
            if (this.GetGameConfig() === 'Belote') {
                this.setState({ IgraBelotDlgVisible: true });
            }
            if (this.GetGameConfig() === 'Tabla') {
                this.setState({ IgraTablaVisible: true });
            }
            if (this.GetGameConfig() === 'NSSC') {
                this.setState({ CIgraNSSCVisible: true });
            }
            if (this.GetGameConfig() === 'Blato') {
                this.setState({ IgraBlatoDlgVisible: true });
            }
            if (this.GetGameConfig() === 'Santase') {
                this.setState({ IgraSantaseDlgVisible: true });
            }
        }
    }

    OnNewGame() {
        this.closeAllDialogs();
        this.on_new_game_start = true;
        if (!this.m_main.loadingMain) {
            this.pFrame.m_info_bar.Clear();
        }
        if (this.IsGameOnline()) {
            this.GoToLobby();
            return;
        }
        this.game = '';
        if (this.state.CChatBarVisible) {
            this.ShowChatBar(false);
        }
        if (this.state.CInfoBarVisible) {
            this.ShowInfoBar(false);
        }
        this.pFrame.m_chat_bar.ClearWatchers();

        if (!this.state.DlgConnectVisible) {
            try {
                this.setState({ DlgConnectVisible: true });
            } catch (e) {
                console.error('this.setState({ DlgConnectVisible: true })', e);
            }
            this.showAd();
        }
    }

    HaveFreePositionsToTable() {
        if (this.game === 'OKEY') {
            for (let i = 2; i <= 4; i++) {
                if (this.m_okey.Name[i] === 'Computer2' || this.m_okey.Name[i] === 'Computer3' || this.m_okey.Name[i] === 'Computer4') {
                    return true;
                }
            }
        }
        if (this.game === 'Monopoly') {
            for (let i = 2; i <= 4; i++) {
                if (this.mon.Name[i] === 'Computer' + this.m_main.IntToString(i)) {
                    return true;
                }
            }
        }
        if (this.game === 'NSSC') {
            for (let i = 2; i <= 4; i++) {
                if (this.m_nssc.Name[i] === 'Computer2' || this.m_nssc.Name[i] === 'Computer3' || this.m_nssc.Name[i] === 'Computer4') {
                    return true;
                }
            }
        }
        if (this.game === 'Tabla') {
            if (this.tab.Name[2] === 'Computer2') {
                return true;
            }
        }
        if (this.game === 'Domino2') {
            if (this.dom2.Name[2] === 'Computer2') {
                return true;
            }
        }
        if (this.game === 'Domino3') {
        }
        if (this.game === 'Santase') {
            if (this.m_san.Name[2] === 'Computer2' && !this.m_san.wait_opplayers[2]) {
                return true;
            }
        }
        if (this.game === 'Belot4') {
            for (let i = 2; i <= 4; i++) {
                if (
                    (this.b4.Name[i] === 'Computer2' && !this.b4.wait_opplayers[i]) ||
                    (this.b4.Name[i] === 'Computer3' && !this.b4.wait_opplayers[i]) ||
                    (this.b4.Name[i] === 'Computer4' && !this.b4.wait_opplayers[i])
                ) {
                    return true;
                }
            }
        }
        if (this.game === 'Belot3') {
            for (let i = 2; i <= 3; i++) {
                if (
                    (this.b3.Name[i] === 'Computer2' && !this.b3.wait_opplayers[i]) ||
                    (this.b3.Name[i] === 'Computer3' && !this.b3.wait_opplayers[i])
                ) {
                    return true;
                }
            }
        }
        if (this.game === 'Blato') {
            for (let i = 2; i <= 3; i++) {
                if (
                    (this.m_bla.Name[i] === 'Computer2' && !this.m_bla.wait_opplayers[i]) ||
                    (this.m_bla.Name[i] === 'Computer3' && !this.m_bla.wait_opplayers[i])
                ) {
                    return true;
                }
            }
        }
        if (this.game === 'Belot2') {
            if (this.b2.Name[2] === 'Computer2' && !this.b2.wait_opplayers[2]) {
                return true;
            }
        }
        if (this.game === 'Bridge') {
            for (let i = 2; i <= 4; i++) {
                if (
                    (this.m_bri.Name[i] === 'Computer2' && !this.m_bri.wait_opplayers[i]) ||
                    (this.m_bri.Name[i] === 'Computer3' && !this.m_bri.wait_opplayers[i]) ||
                    (this.m_bri.Name[i] === 'Computer4' && !this.m_bri.wait_opplayers[i])
                ) {
                    return true;
                }
            }
        }
        if (this.game === 'BJ') {
            for (let i = 2; i <= this.m_bj.Players; i++) {
                if (this.m_bj.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_bj.wait_opplayers[i]) {
                    return true;
                }
            }
        }
        if (this.game === 'Svara') {
            for (let i = 2; i <= this.m_sva.Players; i++) {
                if (this.m_sva.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_sva.wait_opplayers[i]) {
                    return true;
                }
            }
        }
        if (this.game === 'Poker') {
            for (let i = 2; i <= this.m_pok.Players; i++) {
                if (this.m_pok.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_pok.wait_opplayers[i]) {
                    return true;
                }
            }
        }
        if (this.game === 'PokerOmaha') {
            for (let i = 2; i <= this.m_poko.Players; i++) {
                if (this.m_poko.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_poko.wait_opplayers[i]) {
                    return true;
                }
            }
        }
        if (this.game === 'DrawPoker') {
            for (let i = 2; i <= this.m_dpok.Players; i++) {
                if (this.m_dpok.Name[i] === 'Computer' + this.m_main.IntToString(i) && !this.m_dpok.wait_opplayers[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    DownLoadServerInfo() {}

    ShowChatBar(show: boolean) {
        if (!show) {
            this.state.CChatBarVisible = false;
            this.CalculateScale();
        }
        if (this.game === 'NSSC') {
            this.m_nssc.loadPionki();
        }
        if (!show || this.game === 'NSSC') {
            this.setState({});
        }
    }

    async Registration(registrationString: string) {
        this.initConnection();
        let brcon = 0;
        while (!ChatSocket.connected && brcon < 30) {
            brcon = brcon + 1;
            await this.performTimeConsumingTask();
        }
        if (!ChatSocket.connected) {
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('No connection with server'));
            await this.m_ok[this.messageokIndex].DoModal();
            return;
        }
        this.SendGame(registrationString);
    }

    async ConnectToServerOnly() {
        if (this.m_con.action !== 'noinit') {
            this.initConnection();
            let brcon = 0;
            while (!ChatSocket.connected && brcon < 30) {
                brcon = brcon + 1;
                await this.performTimeConsumingTask();
            }
            if (!ChatSocket.connected) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    async ConnectToServer() {
        if (this.m_con.action !== 'noinit') {
            this.initConnection();
            let brcon = 0;
            while (!ChatSocket.connected && brcon < 30) {
                brcon = brcon + 1;
                await this.performTimeConsumingTask();
            }
            if (!ChatSocket.connected) {
                await this.noConnection();
                return;
            }
        }
        if (this.m_con.m_guest) {
            this.LoginAsGuest();
        } else {
            this.Login();
        }
    }

    async noConnection() {
        ChatSocket.connected = false;
        this.connected = false;
        this.m_con.loading = false;
        this.m_con.setState({});
        this.m_main.OnGetFile(this.m_main.GetLanguageText('domain') + '/server.inp', 'server.inp', false);
        this.m_yesno[this.messageyesnoIndex].SetTitle(
            this.m_main.GetLanguageText('No connection with server') + '! ' + this.m_main.GetLanguageText('Please try again'),
            this.m_main.GetLanguageText('Connect to Server') + '...'
        );
        if ((await this.m_yesno[this.messageyesnoIndex].DoModal()) === GlobalConstants.IDNO) {
            this.DownLoadServerInfo();
            this.OnNewGame();
        } else {
            this.DownLoadServerInfo();
            this.m_con.loading = true;
            this.m_con.setState({});
            this.ConnectToServer();
        }
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve('result');
            }, 500)
        );
    };

    initConnection() {
        if (this.init_conn === true) {
            this.connected = false;
            this.m_pClientSocket.Close();
        }
        this.m_pClientSocket.Create(this);
        this.init_conn = true;
    }

    CreateNewGame(game) {
        this.game = game;
        this.setState({ CIzborNaIgraVisible: false });
    }

    GetPlayerParameters(player: number) {
        let name: string = '';
        if (this.game === 'OKEY') {
            name = this.m_okey.Name[player];
        }
        if (this.game === 'Monopoly') {
            name = this.mon.Name[player];
        }
        if (this.game === 'NSSC') {
            name = this.m_nssc.Name[player];
        }
        if (this.game === 'Tabla') {
            name = this.tab.Name[player];
        }
        if (this.game === 'Domino2') {
            name = this.dom2.Name[player];
        }
        if (this.game === 'Domino3') {
            name = this.dom3.Name[player];
        }
        if (this.game === 'Domino4') {
            name = this.dom4.Name[player];
        }
        if (this.game === 'Santase') {
            name = this.m_san.Name[player];
        }
        if (this.game === 'Belot4') {
            name = this.b4.Name[player];
        }
        if (this.game === 'Belot3') {
            name = this.b3.Name[player];
        }
        if (this.game === 'Blato') {
            name = this.m_bla.Name[player];
        }
        if (this.game === 'Belot2') {
            name = this.b2.Name[player];
        }
        if (this.game === 'Bridge') {
            name = this.m_bri.Name[player];
        }
        if (this.game === 'BJ') {
            name = this.m_bj.Name[player];
        }
        if (this.game === 'Svara') {
            name = this.m_sva.Name[player];
        }
        if (this.game === 'Poker') {
            name = this.m_pok.Name[player];
        }
        if (this.game === 'PokerOmaha') {
            name = this.m_poko.Name[player];
        }
        if (this.game === 'DrawPoker') {
            name = this.m_dpok.Name[player];
        }
        if (
            name !== '' &&
            name !== 'Computer2' &&
            name !== 'Computer3' &&
            name !== 'Computer4' &&
            name !== 'Computer5' &&
            name !== 'Computer6' &&
            name !== 'Computer7' &&
            name !== 'Computer8' &&
            name !== 'Computer9' &&
            name !== 'Computer10'
        ) {
            let rplayer = '*|B|' + name;
            this.SendGame(rplayer);
        } else {
            if (this.game === 'Domino2') {
                this.dom2.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Domino3') {
                this.dom3.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Domino4') {
                this.dom4.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'OKEY') {
                this.m_okey.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Monopoly') {
                this.mon.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'NSSC') {
                this.m_nssc.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Tabla') {
                this.tab.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Santase') {
                this.m_san.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Belot4') {
                this.b4.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Belot3') {
                this.b3.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Blato') {
                this.m_bla.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Belot2') {
                this.b2.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Bridge') {
                this.m_bri.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'BJ') {
                this.m_bj.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Svara') {
                this.m_sva.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'Poker') {
                this.m_pok.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.uversion[player] = this.m_main._ttoi(this.m_main.GetOnlineVersion());
            }
        }
    }

    GetPlayerRating(player: number) {
        let name = '';
        if (this.game === 'OKEY') {
            name = this.m_okey.Name[player];
        }
        if (this.game === 'Monopoly') {
            name = this.mon.Name[player];
        }
        if (this.game === 'NSSC') {
            name = this.m_nssc.Name[player];
        }
        if (this.game === 'Tabla') {
            name = this.tab.Name[player];
        }
        if (this.game === 'Domino2') {
            name = this.dom2.Name[player];
        }
        if (this.game === 'Domino3') {
            name = this.dom3.Name[player];
        }
        if (this.game === 'Domino4') {
            name = this.dom4.Name[player];
        }
        if (this.game === 'Santase') {
            name = this.m_san.Name[player];
        }
        if (this.game === 'Belot4') {
            name = this.b4.Name[player];
        }
        if (this.game === 'Belot3') {
            name = this.b3.Name[player];
        }
        if (this.game === 'Blato') {
            name = this.m_bla.Name[player];
        }
        if (this.game === 'Belot2') {
            name = this.b2.Name[player];
        }
        if (this.game === 'Bridge') {
            name = this.m_bri.Name[player];
        }
        if (this.game === 'BJ') {
            name = this.m_bj.Name[player];
        }
        if (this.game === 'Svara') {
            name = this.m_sva.Name[player];
        }
        if (this.game === 'Poker') {
            name = this.m_pok.Name[player];
        }
        if (this.game === 'PokerOmaha') {
            name = this.m_poko.Name[player];
        }
        if (this.game === 'DrawPoker') {
            name = this.m_dpok.Name[player];
        }
        if (
            name !== '' &&
            name !== 'Computer2' &&
            name !== 'Computer3' &&
            name !== 'Computer4' &&
            name !== 'Computer5' &&
            name !== 'Computer6' &&
            name !== 'Computer7' &&
            name !== 'Computer8' &&
            name !== 'Computer9' &&
            name !== 'Computer10'
        ) {
            let rplayer = 'Z|' + name;
            this.SendGame(rplayer);
        } else {
            if (this.game === 'Domino2') {
                this.dom2.rating[player] = 40;
            }
            if (this.game === 'Domino3') {
                this.dom3.rating[player] = 40;
            }
            if (this.game === 'Domino4') {
                this.dom4.rating[player] = 40;
            }
            if (this.game === 'Tabla') {
                this.tab.rating[player] = 34;
            }
            if (this.game === 'Santase') {
                this.m_san.rating[player] = 21;
            }
            if (this.game === 'Belot4') {
                this.b4.rating[player] = 33;
            }
            if (this.game === 'Belot3') {
                this.b3.rating[player] = 29;
            }
            if (this.game === 'Blato') {
                this.m_bla.rating[player] = 19;
            }
            if (this.game === 'Belot2') {
                this.b2.rating[player] = 24;
            }
            if (this.game === 'Bridge') {
                this.m_bri.rating[player] = 20;
            }
            if (this.game === 'BJ') {
                this.m_bj.rating[player] = 40;
            }
            if (this.game === 'Svara') {
                this.m_sva.rating[player] = 40;
            }
            if (this.game === 'OKEY') {
                this.m_okey.rating[player] = 40;
            }
            if (this.game === 'Monopoly') {
                this.mon.rating[player] = 25;
            }
            if (this.game === 'NSSC') {
                this.m_nssc.rating[player] = 40;
            }
            if (this.game === 'Poker') {
                this.m_pok.cash[player] = (this.m_pok.MaxBuyIn + this.m_pok.MinBuyIn) / 2;
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.cash[player] = (this.m_poko.MaxBuyIn + this.m_poko.MinBuyIn) / 2;
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.cash[player] = (this.m_dpok.MaxBuyIn + this.m_dpok.MinBuyIn) / 2;
            }
        }
        this.GetPlayerParameters(player);
    }

    async OnCreateGame(lgame: string, table_password: string, watch: string, tournament: string, bet: string) {
        this.closeAllDialogs();
        this.m_d432.Title = this.m_main.GetLanguageText('Please wait for another player to join');
        if (lgame === 'Ludo') {
            //Ako igrata e NSSC
            await this.m_nssc.InitMain();
            this.m_nssc.initConstructor();
            this.m_nssc.KoiENaRed = -1;
            this.m_nssc.loading = true;
            this.game = 'NSSC';
            this.setState({});
            this.m_nssc.rating[1] = this.m_dbo.p1_rating;
            this.m_nssc.Players = 4;
            this.m_nssc.OP = true;
            let cgame = 'B|Ludo|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_nssc.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_con.getUsername();
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_nssc.Name[2] = this.m_d432.Name[2];
                    this.m_nssc.last_Name[2] = this.m_nssc.Name[2];
                    this.m_nssc.P2OP = true;
                    let message = 'C|2|' + this.m_nssc.Name[2];
                    this.SendGame(message);
                } else {
                    this.m_nssc.P2OP = false;
                    this.m_nssc.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.m_nssc.P3OP = true;
                    this.m_nssc.Name[3] = this.m_d432.Name[3];
                    this.m_nssc.last_Name[3] = this.m_nssc.Name[3];
                    let message = 'C|3|' + this.m_nssc.Name[3];
                    this.SendGame(message);
                } else {
                    this.m_nssc.P3OP = false;
                    this.m_nssc.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.m_nssc.P4OP = true;
                    this.m_nssc.Name[4] = this.m_d432.Name[4];
                    this.m_nssc.last_Name[4] = this.m_nssc.Name[4];
                    let message = 'C|4|' + this.m_nssc.Name[4];
                    this.SendGame(message);
                } else {
                    this.m_nssc.P4OP = false;
                    this.m_nssc.Name[4] = 'Computer4';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.GetPlayerRating(3);
                this.GetPlayerRating(4);
                this.SendGame('G');
                await this.m_nssc.InitOP(true);
                this.m_nssc.SendNames();
                this.m_nssc.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'B4') {
            await this.b4.InitMain();
            this.b4.initConstructor();
            //Ako igrata e Belot s 4 igrachi
            this.b4.KoiENaRed = -1;
            this.b4.loading = true;
            this.game = 'Belot4';
            this.setState({});
            this.b4.rating[1] = this.m_dbo.p1_rating;
            this.b4.Players = 4;
            this.b4.OP = true;
            let cgame = 'B|Belote 4 Players|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.b4.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.b4.Name[2] = this.m_d432.Name[2];
                    this.b4.last_Name[2] = this.b4.Name[2];
                    this.b4.P2OP = true;
                    let message = 'C|2|' + this.b4.Name[2];
                    this.SendGame(message);
                } else {
                    this.b4.P2OP = false;
                    this.b4.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.b4.P3OP = true;
                    this.b4.Name[3] = this.m_d432.Name[3];
                    this.b4.last_Name[3] = this.b4.Name[3];
                    let message = 'C|3|' + this.b4.Name[3];
                    this.SendGame(message);
                } else {
                    this.b4.P3OP = false;
                    this.b4.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.b4.P4OP = true;
                    this.b4.Name[4] = this.m_d432.Name[4];
                    this.b4.last_Name[4] = this.b4.Name[4];
                    let message = 'C|4|' + this.b4.Name[4];
                    this.SendGame(message);
                } else {
                    this.b4.P4OP = false;
                    this.b4.Name[4] = 'Computer4';
                }
                for (let i = 1; i < 5; i++) {
                    this.b4.m_orez.Player[i] = this.b4.Name[i];
                    this.GetPlayerRating(i);
                }
                this.SendGame('G');
                await this.b4.InitOP(true);
                this.b4.SendNames();
                this.b4.SendKoiENaRed();
                this.b4.SendCards();
                this.b4.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Br1') {
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            //Ako igrata e Bridge Rubber
            this.m_bri.KoiENaRed = -1;
            this.m_bri.loading = true;
            this.m_bri.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Bridge';
            this.setState({});
            this.m_bri.Players = 4;
            this.m_bri.OP = true;
            let cgame = 'B|Bridge Rubber|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_bri.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_bri.Name[2] = this.m_d432.Name[2];
                    this.m_bri.last_Name[2] = this.m_bri.Name[2];
                    this.m_bri.P2OP = true;
                    let message = 'C|2|' + this.m_bri.Name[2];
                    this.SendGame(message);
                } else {
                    this.m_bri.P2OP = false;
                    this.m_bri.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.m_bri.P3OP = true;
                    this.m_bri.Name[3] = this.m_d432.Name[3];
                    this.m_bri.last_Name[3] = this.m_bri.Name[3];
                    let message = 'C|3|' + this.m_bri.Name[3];
                    this.SendGame(message);
                } else {
                    this.m_bri.P3OP = false;
                    this.m_bri.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.m_bri.P4OP = true;
                    this.m_bri.Name[4] = this.m_d432.Name[4];
                    this.m_bri.last_Name[4] = this.m_bri.Name[4];
                    let message = 'C|4|' + this.m_bri.Name[4];
                    this.SendGame(message);
                } else {
                    this.m_bri.P4OP = false;
                    this.m_bri.Name[4] = 'Computer4';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.GetPlayerRating(3);
                this.GetPlayerRating(4);
                this.SendGame('G');

                await this.m_bri.InitOP(true, 1);
                this.m_bri.SendNames();
                this.m_bri.SendKoiObiaviava();
                this.m_bri.SendCards();
                this.m_bri.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Br2') {
            await this.m_bri.InitMain();
            this.m_bri.initConstructor();
            //Ako igrata e Duplicate Bridge
            this.m_bri.KoiENaRed = -1;
            this.m_bri.loading = true;
            this.m_bri.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Bridge';
            this.m_bri.Players = 4;
            this.m_bri.OP = true;
            let cgame = 'B|Duplicate Bridge|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_bri.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_bri.Name[2] = this.m_d432.Name[2];
                    this.m_bri.last_Name[2] = this.m_bri.Name[2];
                    this.m_bri.P2OP = true;
                    let message = 'C|2|' + this.m_bri.Name[2];
                    this.SendGame(message);
                } else {
                    this.m_bri.P2OP = false;
                    this.m_bri.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.m_bri.P3OP = true;
                    this.m_bri.Name[3] = this.m_d432.Name[3];
                    this.m_bri.last_Name[3] = this.m_bri.Name[3];
                    let message = 'C|3|' + this.m_bri.Name[3];
                    this.SendGame(message);
                } else {
                    this.m_bri.P3OP = false;
                    this.m_bri.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.m_bri.P4OP = true;
                    this.m_bri.Name[4] = this.m_d432.Name[4];
                    this.m_bri.last_Name[4] = this.m_bri.Name[4];
                    let message = 'C|4|' + this.m_bri.Name[4];
                    this.SendGame(message);
                } else {
                    this.m_bri.P4OP = false;
                    this.m_bri.Name[4] = 'Computer4';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.GetPlayerRating(3);
                this.GetPlayerRating(4);
                this.SendGame('G');
                await this.m_bri.InitOP(true, 2);
                this.m_bri.SendNames();
                this.m_bri.SendKoiObiaviava();
                this.m_bri.SendCards();
                this.m_bri.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Ba1') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            //Ako igrata e Tabla - Obiknovenna
            this.tab.KoiENaRed = -1;
            this.tab.loading = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Players = 2;
            this.tab.OP = true;
            let cgame = 'B|Backgammon|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.tab.Name[1] = this.m_con.getUsername();
            this.tab.Name[2] = 'Computer2';
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.tab.Name[2] = this.m_d432.Name[2];
                    this.tab.last_Name[2] = this.tab.Name[2];
                    this.tab.P2OP = true;
                } else {
                    this.tab.P2OP = false;
                    this.tab.Name[2] = 'Computer2';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                await this.tab.InitOP(true, 0);
                this.tab.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Ba2') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            //Ako igrata e Tabla - Tapa
            this.tab.KoiENaRed = -1;
            this.tab.loading = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Players = 2;
            this.tab.OP = true;
            let cgame = 'B|Backgammon - Tapa|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.tab.Name[1] = this.m_con.getUsername();
            this.tab.Name[2] = 'Computer2';
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.tab.Name[2] = this.m_d432.Name[2];
                    this.tab.last_Name[2] = this.tab.Name[2];
                    this.tab.P2OP = true;
                } else {
                    this.tab.Name[2] = 'Computer2';
                    this.tab.P2OP = false;
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);

                await this.tab.InitOP(true, 1);
                this.tab.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Ba3') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            //Ako igrata e Tabla - Gulbara
            this.tab.KoiENaRed = -1;
            this.tab.loading = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Players = 2;
            this.tab.OP = true;
            let cgame = 'B|Backgammon - Gulbara|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.tab.Name[1] = this.m_con.getUsername();
            this.tab.Name[2] = 'Computer2';
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.tab.Name[2] = this.m_d432.Name[2];
                    this.tab.last_Name[2] = this.tab.Name[2];
                    this.tab.P2OP = true;
                } else {
                    this.tab.Name[2] = 'Computer2';
                    this.tab.P2OP = false;
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);

                await this.tab.InitOP(true, 2);
                this.tab.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Ba31') {
            await this.tab.InitMain();
            this.tab.initConstructor();
            //Ako igrata e Tabla - 3 in 1
            this.tab.KoiENaRed = -1;
            this.tab.loading = true;
            this.tab.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Tabla';
            this.tab.Players = 2;
            this.tab.OP = true;
            let cgame = 'B|Backgammon 3 in 1|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.tab.Name[1] = this.m_con.getUsername();
            this.tab.Name[2] = 'Computer2';
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.tab.Name[2] = this.m_d432.Name[2];
                    this.tab.last_Name[2] = this.tab.Name[2];
                    this.tab.P2OP = true;
                } else {
                    this.tab.Name[2] = 'Computer2';
                    this.tab.P2OP = false;
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                await this.tab.InitOP(true, 3);
                this.tab.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === '358') {
            await this.m_bla.InitMain();
            this.m_bla.initConstructor();
            this.m_bla.KoiENaRed = -1;
            this.m_bla.loading = true;
            //Ako igrata e Blato
            this.m_bla.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Blato';
            this.m_bla.Players = 3;
            this.m_bla.OP = true;
            let cgame =
                'B|Sergeant Major (3-5-8)|' +
                table_password +
                '|' +
                watch +
                '|' +
                this.m_main.IntToString(this.m_dbo.GameType) +
                '|' +
                tournament +
                '|' +
                bet;
            this.SendGame(cgame);
            this.m_d432.Players = 3;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_bla.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_bla.Name[2] = this.m_d432.Name[2];
                    this.m_bla.last_Name[2] = this.m_bla.Name[2];
                    this.m_bla.P2OP = true;
                    let message = 'C|2|' + this.m_bla.Name[2];
                    this.SendGame(message);
                } else {
                    this.m_bla.P2OP = false;
                    this.m_bla.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.m_bla.P3OP = true;
                    this.m_bla.Name[3] = this.m_d432.Name[3];
                    this.m_bla.last_Name[3] = this.m_bla.Name[3];
                    let message = 'C|3|' + this.m_bla.Name[3];
                    this.SendGame(message);
                } else {
                    this.m_bla.P3OP = false;
                    this.m_bla.Name[3] = 'Computer3';
                }
                for (let i = 1; i <= 3; i++) {
                    this.m_bla.m_orez.Player[i] = this.m_bla.Name[i];
                    this.GetPlayerRating(i);
                }
                this.SendGame('G');

                await this.m_bla.InitOP(true, this.m_dbo.GameType);
                this.m_bla.SendNames();
                this.m_bla.SendKoiZapochva();
                this.m_bla.SendCards();
                this.m_bla.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'S') {
            await this.m_san.InitMain();
            this.m_san.initConstructor();
            this.m_san.KoiENaRed = -1;
            this.m_san.loading = true;
            //Ako igrata e Santase
            this.m_san.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Santase';

            this.m_san.Players = 2;
            this.m_san.OP = true;
            let cgame = 'B|Schnapsen (66)|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_san.Name[1] = this.m_con.getUsername();
            this.m_san.Name[2] = 'Computer2';
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_san.Name[2] = this.m_d432.Name[2];
                    this.m_san.last_Name[2] = this.m_san.Name[2];
                    this.m_san.P2OP = true;
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.m_san.m_rez.m_name1 = this.m_san.Name[1];
                this.m_san.m_rez.m_name2 = this.m_san.Name[2];

                await this.m_san.InitOP(true);
                this.m_san.SendKoiENaRed();
                this.m_san.SendCards();
                this.m_san.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Do2') {
            await this.dom2.InitMain();
            //Ako igrata e Domino 2 igrachi
            this.dom2.rating[1] = this.m_dbo.p1_rating;
            this.dom2.KoiENaRed = -1;
            this.game = 'Domino2';
            this.dom2.Players = 2;
            this.dom2.OP = true;
            let cgame = 'B|Domino 2 Players|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.dom2.Name[1] = this.m_con.getUsername();
            this.dom2.Name[2] = 'Computer2';
            this.dom2.KoiENaRed = -1;
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.dom2.Name[2] = this.m_d432.Name[2];
                    this.dom2.last_Name[2] = this.dom2.Name[2];
                    this.dom2.P2OP = true;
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);

                this.dom2.InitOP(true);
                this.dom2.SendPlochki();
                this.dom2.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'OKEY') {
            await this.m_okey.InitMain();
            //Ako igrata e OKEY
            this.m_okey.rating[1] = this.m_dbo.p1_rating;
            this.game = 'OKEY';

            this.m_okey.Players = 4;
            this.m_okey.OP = true;
            let cgame = 'B|OKEY|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.m_okey.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.m_okey.Name[2] = this.m_d432.Name[2];
                    this.m_okey.last_Name[2] = this.m_okey.Name[2];
                    this.m_okey.P2OP = true;
                    let message = 'C|2|' + this.m_okey.Name[2];
                    this.SendGame(message);
                } else {
                    this.m_okey.P2OP = false;
                    this.m_okey.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.m_okey.P3OP = true;
                    this.m_okey.Name[3] = this.m_d432.Name[3];
                    this.m_okey.last_Name[3] = this.m_okey.Name[3];
                    let message = 'C|3|' + this.m_okey.Name[3];
                    this.SendGame(message);
                } else {
                    this.m_okey.P3OP = false;
                    this.m_okey.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.m_okey.P4OP = true;
                    this.m_okey.Name[4] = this.m_d432.Name[4];
                    this.m_okey.last_Name[4] = this.m_okey.Name[4];
                    let message = 'C|4|' + this.m_okey.Name[4];
                    this.SendGame(message);
                } else {
                    this.m_okey.P4OP = false;
                    this.m_okey.Name[4] = 'Computer4';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.GetPlayerRating(3);
                this.GetPlayerRating(4);
                this.SendGame('G');

                await this.m_okey.InitOP(true);
                this.m_okey.SendNames();
                this.m_okey.SendKoiENaRed();
                this.m_okey.SendCards();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Monopoly') {
            await this.mon.InitMain();
            //Ako igrata e Monopoly
            this.mon.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Monopoly';
            this.mon.Players = 4;
            this.mon.OP = true;
            let cgame = 'B|Monopoly|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 4;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.mon.Name[1] = this.m_d432.m_pl1;
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.mon.Name[2] = this.m_d432.Name[2];
                    this.mon.last_Name[2] = this.mon.Name[2];
                    this.mon.OPL[2] = true;
                    let message = 'C|2|' + this.mon.Name[2];
                    this.SendGame(message);
                } else {
                    this.mon.OPL[2] = false;
                    this.mon.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.mon.OPL[3] = true;
                    this.mon.Name[3] = this.m_d432.Name[3];
                    this.mon.last_Name[3] = this.mon.Name[3];
                    let message = 'C|3|' + this.mon.Name[3];
                    this.SendGame(message);
                } else {
                    this.mon.OPL[3] = false;
                    this.mon.Name[3] = 'Computer3';
                }
                if (this.m_d432.Name[4] !== 'Computer') {
                    this.mon.OPL[4] = true;
                    this.mon.Name[4] = this.m_d432.Name[4];
                    this.mon.last_Name[4] = this.mon.Name[4];
                    let message = 'C|4|' + this.mon.Name[4];
                    this.SendGame(message);
                } else {
                    this.mon.OPL[4] = false;
                    this.mon.Name[4] = 'Computer4';
                }
                this.GetPlayerRating(1);
                this.GetPlayerRating(2);
                this.GetPlayerRating(3);
                this.GetPlayerRating(4);
                this.SendGame('G');

                this.mon.InitOP(true);
                this.mon.SendNames();
                this.mon.SendKoiENaRed();
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Svara') {
            await this.m_sva.InitMain();
            //Ako igrata e Svara
            let pplayers = this.m_dbo.Players;
            this.m_blinds.player_sum = this.m_dbo.money;
            this.m_blinds.game = this.m_dbo.Game;
            if ((await this.m_blinds.DoModal()) === GlobalConstants.IDOK) {
                this.m_sva.rating[1] = this.m_dbo.p1_rating;
                this.game = 'Svara';
                this.m_sva.Name[1] = this.m_con.getUsername();
                this.GetPlayerRating(1);
                let cgame =
                    'B|Svara|' +
                    table_password +
                    '|' +
                    watch +
                    '|' +
                    this.m_main.IntToString(pplayers) +
                    '|' +
                    this.m_main.FloatToString(this.m_blinds.big_blind) +
                    '|' +
                    tournament;
                this.SendGame(cgame);
                this.m_sva.InitOP(true, pplayers);
                this.m_sva.InitSvaraParameters(this.m_blinds.big_blind, this.m_dbo.money);
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Poker Texas Hold em') {
            await this.m_pok.InitMain();
            //Ako igrata e Poker Texas Hold em
            let pplayers = this.m_dbo.Players;
            this.m_blinds.player_sum = this.m_dbo.money;
            this.m_blinds.game = this.m_dbo.Game;
            if ((await this.m_blinds.DoModal()) === GlobalConstants.IDOK) {
                if (this.m_blinds.big_blind === 100 && this.m_main.poker_commission_bet_100 > 0) {
                    let message = this.m_main.GetLanguageText(
                        'Bet for advanced. Playing with this bet has a (percent)% commission on the casino winnings.'
                    );
                    message = message.replace('(percent)', this.m_main.FloatToString(this.m_main.poker_commission_bet_100));
                    this.m_ok[this.messageokIndex].SetTitle(message);
                    this.m_ok[this.messageokIndex].DoModal();
                }
                if (this.m_blinds.big_blind === 10 && this.m_main.poker_commission_bet_10 > 0) {
                    let message = this.m_main.GetLanguageText(
                        'Bet for advanced. Playing with this bet has a (percent)% commission on the casino winnings.'
                    );
                    message = message.replace('(percent)', this.m_main.FloatToString(this.m_main.poker_commission_bet_10));
                    this.m_ok[this.messageokIndex].SetTitle(message);
                    this.m_ok[this.messageokIndex].DoModal();
                }
                this.game = 'Poker';
                this.m_pok.rating[1] = this.m_dbo.p1_rating;

                this.m_pok.Name[1] = this.m_con.getUsername();
                this.GetPlayerRating(1);
                let cgame =
                    'B|Poker Texas Hold em|' +
                    table_password +
                    '|' +
                    watch +
                    '|' +
                    this.m_main.IntToString(pplayers) +
                    '|' +
                    this.m_main.FloatToString(this.m_blinds.big_blind) +
                    '|' +
                    tournament;
                this.SendGame(cgame);
                this.m_pok.InitOP(true, pplayers);
                this.m_pok.InitPokerParameters(this.m_blinds.big_blind, this.m_dbo.money);
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Poker Omaha Hi') {
            await this.m_poko.InitMain();
            //Ako igrata e Poker Texas Hold em
            let pplayers = this.m_dbo.Players;
            this.m_blinds.player_sum = this.m_dbo.money;
            this.m_blinds.game = this.m_dbo.Game;
            if ((await this.m_blinds.DoModal()) === GlobalConstants.IDOK) {
                if (this.m_blinds.big_blind === 100) {
                    this.m_ok[this.messageokIndex].SetTitle(
                        this.m_main.GetLanguageText('Bet for advanced. Playing with this bet has a 0.2% commission on the casino winnings.')
                    );
                    this.m_ok[this.messageokIndex].DoModal();
                }
                this.game = 'PokerOmaha';
                this.m_poko.rating[1] = this.m_dbo.p1_rating;
                this.m_poko.Name[1] = this.m_con.getUsername();
                this.GetPlayerRating(1);
                let cgame =
                    'B|Poker Omaha Hi|' +
                    table_password +
                    '|' +
                    watch +
                    '|' +
                    this.m_main.IntToString(pplayers) +
                    '|' +
                    this.m_main.FloatToString(this.m_blinds.big_blind) +
                    '|' +
                    tournament;
                this.SendGame(cgame);
                this.m_poko.InitOP(true, pplayers);
                this.m_poko.InitPokerParameters(this.m_blinds.big_blind, this.m_dbo.money);
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'Draw Poker') {
            await this.m_dpok.InitMain();
            //Ako igrata e Draw Poker
            let pplayers = this.m_dbo.Players;
            this.m_blinds.player_sum = this.m_dbo.money;
            this.m_blinds.game = this.m_dbo.Game;
            if ((await this.m_blinds.DoModal()) === GlobalConstants.IDOK) {
                this.m_dpok.rating[1] = this.m_dbo.p1_rating;
                this.game = 'DrawPoker';
                this.m_dpok.Name[1] = this.m_con.getUsername();
                this.GetPlayerRating(1);
                let cgame =
                    'B|Draw Poker|' +
                    table_password +
                    '|' +
                    watch +
                    '|' +
                    this.m_main.IntToString(pplayers) +
                    '|' +
                    this.m_main.FloatToString(this.m_blinds.big_blind) +
                    '|' +
                    tournament;
                this.SendGame(cgame);
                this.m_dpok.InitOP(true, pplayers);
                this.m_dpok.InitPokerParameters(this.m_blinds.big_blind, this.m_dbo.money);
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'B3') {
            await this.b3.InitMain();
            //Ako igrata e Belot s 3 igrachi
            this.b3.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot3';

            this.b3.Players = 3;
            this.b3.OP = true;
            let cgame = 'B|Belote 3 Players|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 3;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.b3.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.b3.Name[2] = this.m_d432.Name[2];
                    this.b3.last_Name[2] = this.b3.Name[2];
                    this.b3.P2OP = true;
                    let message = 'C|2|' + this.b3.Name[2];
                    this.SendGame(message);
                } else {
                    this.b3.P2OP = false;
                    this.b3.Name[2] = 'Computer2';
                }
                if (this.m_d432.Name[3] !== 'Computer') {
                    this.b3.P3OP = true;
                    this.b3.Name[3] = this.m_d432.Name[3];
                    this.b3.last_Name[3] = this.b3.Name[3];
                    let message = 'C|3|' + this.b3.Name[3];
                    this.SendGame(message);
                } else {
                    this.b3.P3OP = false;
                    this.b3.Name[3] = 'Computer3';
                }
                for (let i = 1; i <= 3; i++) {
                    this.b3.m_orez.Player[i] = this.b3.Name[i];
                    this.GetPlayerRating(i);
                }
                this.SendGame('G');

                this.b3.InitOP(true);
                this.b3.SendNames();
                this.b3.SendKoiENaRed();
                this.b3.SendCards();
                this.b3.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
        if (lgame === 'B2') {
            await this.b2.InitMain();
            //Ako igrata e Belot s 2 igrachi
            this.b2.rating[1] = this.m_dbo.p1_rating;
            this.game = 'Belot2';
            this.b2.Players = 2;
            this.b2.OP = true;
            let cgame = 'B|Belote 2 Players|' + table_password + '|' + watch + '|' + tournament + '|' + bet;
            this.SendGame(cgame);
            this.m_d432.Players = 2;
            this.m_d432.m_pl1 = this.m_con.getUsername();
            this.b2.Name[1] = this.m_con.getUsername();
            if ((await this.m_d432.DoModal()) === GlobalConstants.IDOK) {
                this.SendGame('F');
                this.pFrame.m_chat_bar.nick = this.m_d432.m_pl1;
                if (this.m_d432.Name[2] !== 'Computer') {
                    this.b2.Name[2] = this.m_d432.Name[2];
                    this.b2.last_Name[2] = this.b2.Name[2];
                    this.b2.P2OP = true;
                    let message = 'C|2|' + this.b2.Name[2];
                    this.SendGame(message);
                } else {
                    this.b2.P2OP = false;
                    this.b2.Name[2] = 'Computer2';
                }
                for (let i = 1; i <= 2; i++) {
                    this.b2.m_orez.Player[i] = this.b2.Name[i];
                    this.GetPlayerRating(i);
                }
                this.SendGame('G');

                await this.b2.InitOP(true);
                this.b2.SendNames();
                this.b2.SendKoiENaRed();
                this.b2.SendCards();
                this.b2.Razdavane1 = true;
            } else {
                this.GoToLobby();
            }
        }
    }

    GoToLobby() {
        this.game = '';
        if (this.state.CInfoBarVisible) {
            this.ShowInfoBar(false);
        }
        let mes = '*|F';
        this.SendGame(mes);
        this.ShowChatBar(false);
        if (this.state.DlgOnlineVisible) {
            try {
                this.setState({ Dlg432PlayersVisible: false });
            } catch (e) {
                console.error('this.setState({ Dlg432PlayersVisible: false })', e);
            }
        }
        if (!this.state.DlgOnlineVisible) {
            this.m_dbo.DoModal();
        }
        this.InitIconsTooltips();
        this.pFrame.m_chat_bar.ClearWatchers();
        this.setState({});
    }

    async checkConnection() {
        if ((!ChatSocket.connected || !this.props.isConnected) && this.connected && !this.state.DlgConnectVisible) {
            this.connected = false;
            ChatSocket.connected = false;
            this.game = '';
            this.m_con.loading = false;
            this.m_con.setState({});
            this.m_ok[this.messageokIndex].SetTitle(this.m_main.GetLanguageText('No connection with server'));
            await this.m_ok[this.messageokIndex].DoModal();
            this.closeAllDialogs();
            this.OnNewGame();
        }
    }

    SendGame(message: string) {
        message = message + '|';
        //console.log('SendGame', message);
        let send: boolean = this.m_pClientSocket.SendMessage(message);
        return send;
    }

    async OnRButtonDown(e) {
        try {
            let point = new CPoint(e.nativeEvent.pageX - GlobalVariables.positionLeft, e.nativeEvent.pageY - GlobalVariables.positionTop);

            if (this.game === 'NSSC') {
                await this.m_nssc.OnRButtonDown(point);
            }
            if (this.game === 'Belot4') {
                this.b4.OnRButtonDown(point);
            }
            if (this.game === 'Belot3') {
                this.b3.OnRButtonDown(point);
            }
            if (this.game === 'Belot2') {
                this.b2.OnRButtonDown(point);
            }
            if (this.game === 'Blato') {
                this.m_bla.OnRButtonDown(point);
            }
            if (this.game === 'Santase') {
                this.m_san.OnRButtonDown(point);
            }
            if (this.game === 'Bridge') {
                this.m_bri.OnRButtonDown(point);
            }
            if (this.game === 'BJ') {
                this.m_bj.OnRButtonDown(point);
            }
            if (this.game === 'Svara') {
                this.m_sva.OnRButtonDown(point);
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.OnRButtonDown(point);
            }
            if (this.game === 'Tabla') {
                await this.tab.OnRButtonDown(point);
            }
            if (this.game === 'OKEY') {
                this.m_okey.OnRButtonDown(point);
            }
            if (this.game === 'Monopoly') {
                this.mon.OnRButtonDown(point);
            }
            if (this.game === 'Domino2') {
                this.dom2.OnRButtonDown(point);
            }
            if (this.game === 'Domino3') {
                this.dom3.OnRButtonDown(point);
            }
            if (this.game === 'Domino4') {
                this.dom4.OnRButtonDown(point);
            }
            if (this.game === 'Poker') {
                this.m_pok.OnRButtonDown(point);
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.OnRButtonDown(point);
            }
        } catch (error) {
            console.error('Catch Error OnRButtonDown', error);
        }
    }

    OnRezult() {
        if (this.game === 'Santase') {
            this.m_san.m_rez.m_name1 = this.m_san.Name[1];
            this.m_san.m_rez.m_name2 = this.m_san.Name[2];
            this.m_san.m_rez.DoModal();
        }
        if (this.game === 'Bridge') {
            if (this.m_bri.type === 1) {
                this.m_bri.rubber_result.DoModal();
            }
            if (this.m_bri.type === 2) {
                this.m_bri.imp_result.DoModal();
            }
        }
        if (this.game === 'Blato') {
            for (let i = 1; i < 4; i++) {
                this.m_bla.m_orez.Player[i] = this.m_bla.Name[i];
            }
            this.m_bla.m_orez.Players = 3;
            this.m_bla.m_orez.DoModal();
        }
        if (this.game === 'Belot4') {
            for (let i = 1; i < 5; i++) {
                this.b4.m_orez.Player[i] = this.b4.Name[i];
            }
            this.b4.m_orez.Players = 4;
            this.b4.m_orez.DoModal();
        }
        if (this.game === 'Belot3') {
            for (let i = 1; i < 4; i++) {
                this.b3.m_orez.Player[i] = this.b3.Name[i];
            }
            this.b3.m_orez.Players = 3;
            this.b3.m_orez.DoModal();
        }
        if (this.game === 'Belot2') {
            for (let i = 1; i < 3; i++) {
                this.b2.m_orez.Player[i] = this.b2.Name[i];
            }
            this.b2.m_orez.Players = 2;
            this.b2.m_orez.DoModal();
        }
        if (this.game === 'Domino2') {
            this.m_ok[this.messageokIndex].SetTitle(
                this.m_main.GetLanguageText('scoring') +
                    ' : ' +
                    this.m_main.IntToString(this.dom2.m_rez[0]) +
                    ' - ' +
                    this.m_main.IntToString(this.dom2.m_rez[1])
            );
            this.m_ok[this.messageokIndex].DoModal();
        }
        if (this.game === 'Tabla' && this.tab.tab3) {
            this.m_ok[this.messageokIndex].SetTitle(
                this.m_main.GetLanguageText('scoring') +
                    ' : ' +
                    this.m_main.IntToString(this.tab.rez1) +
                    ' - ' +
                    this.m_main.IntToString(this.tab.rez2)
            );
            this.m_ok[this.messageokIndex].DoModal();
        }
        if (this.game === 'OKEY') {
            for (let i = 1; i < 5; i++) {
                this.m_okey.m_rez.Player[i] = this.m_okey.Name[i];
                this.m_okey.m_rez.lrez[i] = this.m_okey.Points[i - 1];
            }
            for (let a = 0; a < 50; a++) {
                for (let i = 1; i < 5; i++) {
                    this.m_okey.m_rez.rez[i][a] = this.m_okey.rez[i][a];
                }
            }
            this.m_okey.m_rez.DoModal();
        }
    }

    closeAllDialogs() {
        this.state.CIzborNaIgraVisible =
            this.state.DlgOnlineVisible =
            this.state.DlgConnectVisible =
            this.state.OnlineRegistrationVisible =
            this.state.Dlg432PlayersVisible =
            this.state.CIgraNSSCVisible =
            this.state.IgraBelotDlgVisible =
            this.state.IgraBJDlgVisible =
            this.state.IgraBlatoDlgVisible =
            this.state.IgraBridgeVisible =
            this.state.IgraChessVisible =
            this.state.IgraDominoDlgVisible =
            this.state.IgraMonopolyVisible =
            this.state.IgraOKEYVisible =
            this.state.IgraPokerVisible =
            this.state.IgraSantaseDlgVisible =
            this.state.IgraSvaraVisible =
            this.state.IgraTablaVisible =
            this.state.DlgNaddavaneVisible =
            this.state.DlgRezultatVisible =
            this.state.DlgORezultatVisible =
            this.state.DlgDetailsVisible =
            this.state.CAnnouncementVisible =
            this.state.PlayersFilterVisible =
            this.state.DlgRezultatSantaseVisible =
            this.state.CBlindsVisible =
            this.state.SettingsScreenVisible =
            this.state.ChangePasswordVisible =
            this.state.PlayersNamesVisible =
            this.state.DeleteProfileScreenVisible =
            this.state.CNewTablePasswordVisible =
            this.state.CRubberResultVisible =
            this.state.CImpResultVisible =
            this.state.CResultOKEYVisible =
            this.state.Bidding_BridgeVisible =
            this.state.DlgStatistikaVisible =
            this.state.DlgKartiVisible =
            this.state.DlgRezultatBlatoVisible =
            this.state.CBlatoGetCardsVisible =
            this.state.DlgObiaviavaneBlaVisible =
            this.state.LanguagePickerDlgVisible =
            this.state.DlgVipVisible =
            this.state.CYESNOChipsVisible =
            this.state.DlgWebViewVisible =
                false;

        for (let i = 0; i <= this.messageokIndex + 10; i++) {
            this.messageOKVisible[i] = false;
        }
        for (let i = 0; i <= this.messageyesnoIndex + 10; i++) {
            this.messageYESNOVisible[i] = false;
        }
        this.setState({});
    }

    haveOpenDialog(): boolean {
        //Not Used
        if (
            this.state.CIzborNaIgraVisible === true ||
            this.state.DlgOnlineVisible === true ||
            this.state.DlgConnectVisible === true ||
            this.state.OnlineRegistrationVisible === true ||
            this.state.Dlg432PlayersVisible === true ||
            this.state.CIgraNSSCVisible === true ||
            this.state.IgraBelotDlgVisible === true ||
            this.state.IgraBJDlgVisible === true ||
            this.state.IgraBlatoDlgVisible === true ||
            this.state.IgraBridgeVisible === true ||
            this.state.IgraChessVisible === true ||
            this.state.IgraDominoDlgVisible === true ||
            this.state.IgraMonopolyVisible === true ||
            this.state.IgraOKEYVisible === true ||
            this.state.IgraPokerVisible === true ||
            this.state.IgraSantaseDlgVisible === true ||
            this.state.IgraSvaraVisible === true ||
            this.state.IgraTablaVisible === true ||
            this.state.DlgNaddavaneVisible === true ||
            this.state.DlgRezultatVisible === true ||
            this.state.DlgORezultatVisible === true ||
            this.state.DlgDetailsVisible === true ||
            this.state.CAnnouncementVisible === true ||
            this.state.PlayersFilterVisible === true ||
            this.state.DlgRezultatSantaseVisible === true ||
            this.state.CBlindsVisible === true ||
            this.state.SettingsScreenVisible === true ||
            this.state.ChangePasswordVisible === true ||
            this.state.PlayersNamesVisible === true ||
            this.state.DeleteProfileScreenVisible === true ||
            this.state.CNewTablePasswordVisible === true ||
            this.state.CRubberResultVisible === true ||
            this.state.CImpResultVisible === true ||
            this.state.CResultOKEYVisible === true ||
            this.state.Bidding_BridgeVisible === true ||
            this.state.DlgStatistikaVisible === true ||
            this.state.DlgKartiVisible === true ||
            this.state.DlgRezultatBlatoVisible === true ||
            this.state.CBlatoGetCardsVisible === true ||
            this.state.DlgObiaviavaneBlaVisible === true ||
            this.state.LanguagePickerDlgVisible === true ||
            this.state.DlgVipVisible === true ||
            this.state.CYESNOChipsVisible === true ||
            this.state.DlgWebViewVisible === true
        ) {
            return true;
        }
        for (let i = 0; i <= this.messageokIndex + 10; i++) {
            if (this.messageOKVisible[i] === true) {
                return true;
            }
        }
        for (let i = 0; i <= this.messageyesnoIndex + 10; i++) {
            if (this.messageYESNOVisible[i] === true) {
                return true;
            }
        }
        return false;
    }

    async OnCards() {
        this.m_karti.grab = await this.m_main.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.m_karti.backdll = await this.m_main.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        this.m_karti.facedll = await this.m_main.GetProfileString('Options', 'facenew', 'cards_1');

        if ((await this.m_karti.DoModal()) === GlobalConstants.IDOK) {
            if (
                this.m_karti.backdll === 'back_1' ||
                this.m_karti.backdll === 'back_5' ||
                this.m_karti.backdll === 'back_6' ||
                this.m_karti.backdll === 'back_8'
            ) {
                this.m_main.WriteProfileString('Options', 'backnewfiles_1.0', this.m_karti.backdll);
                this.m_main.WriteProfileInt('Options', 'grabnewfiles_1.0', this.m_karti.grab);
                this.m_main.WriteProfileString('Options', 'backnewfiles_1.5', this.m_karti.backdll);
                this.m_main.WriteProfileInt('Options', 'grabnewfiles_1.5', this.m_karti.grab);
                this.m_main.WriteProfileString('Options', 'backnewfiles_2.0', this.m_karti.backdll);
                this.m_main.WriteProfileInt('Options', 'grabnewfiles_2.0', this.m_karti.grab);
            } else {
                this.m_main.WriteProfileString('Options', 'backnew' + this.m_karti.cardsFolder, this.m_karti.backdll);
                this.m_main.WriteProfileInt('Options', 'grabnew' + this.m_karti.cardsFolder, this.m_karti.grab);
            }
            this.m_main.WriteProfileString('Options', 'facenew', this.m_karti.facedll);
            this.ReloadImages();
        }
    }

    async ReloadImages() {
        if (this.game === 'Santase') {
            await this.m_san.LoadBitmaps();
        }
        if (this.game === 'Bridge') {
            await this.m_bri.LoadBitmaps();
            if (this.m_bri.Faza >= 4) {
                this.m_bri.LoadCards(true, false);
            } else {
                this.m_bri.LoadCards(false, false);
            }
        }
        if (this.game === 'Poker') {
            await this.m_pok.LoadBitmaps();
        }
        if (this.game === 'PokerOmaha') {
            await this.m_poko.LoadBitmaps();
        }
        if (this.game === 'DrawPoker') {
            await this.m_dpok.LoadBitmaps();
        }
        if (this.game === 'Svara') {
            await this.m_sva.LoadBitmaps();
        }
        if (this.game === 'BJ') {
            await this.m_bj.LoadBitmaps();
        }
        if (this.game === 'Blato') {
            await this.m_bla.LoadBitmaps();
        }
        if (this.game === 'Belot4') {
            await this.b4.LoadBitmaps();
            if (this.b4.Faza > 4) {
                this.b4.LoadCards(true, false);
            } else {
                this.b4.LoadCards(false, false);
            }
        }
        if (this.game === 'Belot3') {
            await this.b3.LoadBitmaps();
        }
        if (this.game === 'Belot2') {
            await this.b2.LoadBitmaps();
        }
        if (this.game === 'OKEY') {
            await this.m_okey.LoadBitmaps();
        }
        if (this.game === 'Monopoly') {
            await this.mon.LoadBitmaps();
        }
        if (this.game === 'NSSC') {
            await this.m_nssc.LoadBitmaps();
        }
        if (this.game === 'Tabla') {
            await this.tab.LoadBitmaps();
        }
        if (this.game === 'Domino2') {
            await this.dom2.LoadBitmaps();
        }
        if (this.game === 'Domino3') {
            await this.dom3.LoadBitmaps();
        }
        if (this.game === 'Domino4') {
            await this.dom4.LoadBitmaps();
        }
        await this.nd.loadCardsSettings();
        if (this.state.DlgKartiVisible) {
            this.m_karti.grab = await this.m_main.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
            this.m_karti.backdll = await this.m_main.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
            this.m_karti.facedll = await this.m_main.GetProfileString('Options', 'facenew', 'cards_1');
            this.m_karti.OnInitDialog();
        }
        this.setState({});
    }

    async OnLButtonDown(e: any) {
        try {
            let point =
                Platform.OS === 'web'
                    ? new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop)
                    : new CPoint(e.nativeEvent.pageX - GlobalVariables.positionLeft, e.nativeEvent.pageY - GlobalVariables.positionTop);
            if (this.haveOpenDialog()) {
                return;
            }
            if (this.game === 'Belot4') {
                await this.b4.OnLButtonDown(point);
            }
            if (this.game === 'NSSC') {
                await this.m_nssc.OnLButtonDown(point);
            }
            if (this.game === 'Belot3') {
                this.b3.OnLButtonDown(point);
            }
            if (this.game === 'Belot2') {
                await this.b2.OnLButtonDown(point);
            }
            if (this.game === 'Blato') {
                this.m_bla.OnLButtonDown(point);
            }
            if (this.game === 'Santase') {
                this.m_san.OnLButtonDown(point);
            }
            if (this.game === 'Bridge') {
                await this.m_bri.OnLButtonDown(point);
            }
            if (this.game === 'BJ') {
                this.m_bj.OnLButtonDown(point);
            }
            if (this.game === 'Svara') {
                this.m_sva.OnLButtonDown(point);
            }
            if (this.game === 'Poker') {
                this.m_pok.OnLButtonDown(point);
            }
            if (this.game === 'PokerOmaha') {
                this.m_poko.OnLButtonDown(point);
            }
            if (this.game === 'DrawPoker') {
                this.m_dpok.OnLButtonDown(point);
            }
            if (this.game === 'Tabla') {
                await this.tab.OnLButtonDown(point);
            }
            if (this.game === 'OKEY') {
                this.m_okey.OnLButtonDown(point);
            }
            if (this.game === 'Monopoly') {
                this.mon.OnLButtonDown(point);
            }
            if (this.game === 'Domino2') {
                this.dom2.OnLButtonDown(point);
            }
            if (this.game === 'Domino3') {
                this.dom3.OnLButtonDown(point);
            }
            if (this.game === 'Domino4') {
                this.dom4.OnLButtonDown(point);
            }
        } catch (error) {
            console.error('Catch Error OnLButtonDown', error);
        }
    }

    haveOpenMessages(): boolean {
        for (let i = 0; i <= this.messageokIndex; i++) {
            if (this.messageOKVisible[i] === true) {
                return true;
            }
        }
        for (let i = 0; i <= this.messageyesnoIndex; i++) {
            if (this.messageYESNOVisible[i] === true) {
                return true;
            }
        }
    }

    async OnLButtonDblClk(e: any) {
        try {
            let point =
                Platform.OS === 'web'
                    ? new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop)
                    : new CPoint(e.nativeEvent.pageX - GlobalVariables.positionLeft, e.nativeEvent.pageY - GlobalVariables.positionTop);
            if (this.game === 'OKEY') {
                this.m_okey.OnLButtonDblClk(point);
            }
            if (this.game === 'Monopoly') {
                this.mon.OnLButtonDblClk(point);
            }
            if (this.game === 'Tabla') {
                await this.tab.OnLButtonDblClk(point);
            }
        } catch (error) {
            console.error('Catch Error OnLButtonDblClk', error);
        }
    }

    DrawBanners() {
        var res = [];
        if (this.game === '' && this.show_banners) {
            for (let i = 0; i < 20; i++) {
                if (this.load_banner[i]) {
                    if (this.banners[i].m_language === this.m_main.language || this.banners[i].m_language === 'all') {
                        if (
                            this.banners[i].m_Width &&
                            this.banners[i].m_Height &&
                            this.banners[i].m_x &&
                            this.banners[i].m_filename &&
                            this.banners[i].m_url
                        ) {
                            res.push(
                                <Pressable
                                    style={[
                                        cstyles.banner,
                                        {
                                            left: this.banners[i].m_x * GlobalVariables.scale,
                                            top: this.banners[i].m_y * GlobalVariables.scale,
                                            width: this.banners[i].m_Width * GlobalVariables.scale,
                                            height: this.banners[i].m_Height * GlobalVariables.scale,
                                        },
                                    ]}
                                    onPress={() => this.openPage(this.banners[i].m_url)}
                                    key={i}
                                >
                                    <Image
                                        source={{
                                            uri: this.banners[i].m_filename,
                                        }}
                                        style={{
                                            width: this.banners[i].m_Width * GlobalVariables.scale,
                                            height: this.banners[i].m_Height * GlobalVariables.scale,
                                        }}
                                    />
                                </Pressable>
                            );
                        }
                    }
                }
            }
        }

        return res;
    }

    async TrackEvent(event: string, amount: number) {
        console.log(event, amount);
        ReactGA.event({
            category: 'New' + event,
            action: 'New' + event,
            label: 'New' + event,
        });
        await Analytics.logEvent('New' + event, {
            currency: 'EUR',
            value: amount,
            username: this.m_con.getUsername(),
            gameNumber: await this.m_main.GetGameNumber(),
            version: this.m_main.GetGameVersion(),
        })
            .then(() => {
                console.log('TrackEvent', 'New' + event, amount);
            })
            .catch((e) => {
                console.log('Not TrackEvent', 'New' + event, amount, e);
            });
    }

    async showInterstitialAd() {
        if (Platform.OS === 'web') {
            let timeVip: Date = new Date(await this.m_main.GetProfileInt('Options', 'time_left', 0));
            let now: Date = new Date();
            if (now.getTime() > timeVip.getTime()) {
                console.log('No Vip Player', timeVip);
                let url = 'https://favorite-games.com/ads.php';
                this.openPage(url, 'Favorite-Games.com', 'Start the game');
                this.lastAdTime = now.getTime();
                /*   if (Platform.OS === 'android') {
                    await AdMobInterstitial.setAdUnitID(`${config.ADMOB_ANDROID_INTERSTITIAL}`).catch(() => {}); // Real Android ID
                } else {
                    await AdMobInterstitial.setAdUnitID(`${config.ADMOB_IOS_INTERSTITIAL}`).catch(() => {}); // Real IOS ID
                }
                        await AdMobInterstitial.showAdAsync()
                            .then(() => {
                                this.TrackEvent('showAd', 0.01);
                                if (this.connected) {
                                    this.SendGame('*|P|' + this.m_main.IntToString(-20) + '|show ad Interstitial');
                                    console.log('show ad Interstitial');
                                }
                            })
                            .catch((e) => console.log('Not Show Ad', e));  */
            } else {
                console.log('Vip Player to ', timeVip);
            }
        }
    }

    async loadAdConfig() {}

    async loadAd() {}

    async showRewardedAd() {
        if (Platform.OS !== 'web') {
            await this.loadAd();
        }
    }

    async showAd() {
        let now = new Date().getTime();
        let adInterval = 300;
        let affiliate = await this.m_main.GetAffiliate();
        if (adInterval - (now - this.lastAdTime) / 1000 < 0) {
            if (affiliate === '364') {
                if (Platform.OS === 'web') {
                    let width = Dimensions.get('window').width;
                    let height = Math.floor(Dimensions.get('window').height * 0.88);
                    let url = 'https://ads-v96c.singularads.cloud/ad/36002?w=' + width + '&h=' + height + '&url=web.favorite-games.com';
                    this.openPage(url, 'Favorite-Games.com', 'Start the game');
                    this.lastAdTime = now;
                }
            }
            /*    else {
                if(affiliate === '365') {
                    if (Platform.OS === 'web') {
                        if (!this.skipFirstAdd) {
                            if (this.m_main.getPlayerStatus(this.m_con.getUsername()) !== 'VIP') {
                                this.showInterstitialAd();
                            }
                        }
                        this.skipFirstAdd = false;
                    }
                }
            }*/
        }

        /*    if (Platform.OS !== 'web') {
            if (!this.skipFirstAdd) {
                if (this.m_main.getPlayerStatus(this.m_con.getUsername()) !== 'VIP') {
                    this.showInterstitialAd();
                }
            }
            this.skipFirstAdd = false;
        }*/
    }

    InvalidateStolove(arg0: number) {
        this.setState({});
    }

    SetStolParameters() {
        if (
            (this.game === 'Poker' && this.m_pok.Players === 10) ||
            (this.game === 'PokerOmaha' && this.m_poko.Players === 10) ||
            (this.game === 'DrawPoker' && this.m_dpok.Players === 10) ||
            (this.game === 'Svara' && this.m_sva.Players === 10)
        ) {
            this.m_main.show_stol[0] = 0;
            this.m_main.show_stol[1] = 0;
        } else {
            this.m_main.show_stol[0] = -1;
            this.m_main.show_stol[1] = -1;
        }
        if (
            (this.game === 'Poker' && this.m_pok.Players >= 6) ||
            (this.game === 'PokerOmaha' && this.m_poko.Players >= 6) ||
            (this.game === 'DrawPoker' && this.m_dpok.Players >= 6) ||
            (this.game === 'Svara' && this.m_sva.Players >= 6)
        ) {
            this.m_main.show_stol[4] = 0;
        } else {
            this.m_main.show_stol[4] = -1;
        }
        if (
            this.game === 'Santase' ||
            this.game === 'Domino2' ||
            this.game === 'Tabla' ||
            this.game === 'Chess' ||
            this.game === 'Belot2' ||
            this.game === '' ||
            (this.game === 'Poker' && this.m_pok.Players === 2) ||
            (this.game === 'PokerOmaha' && this.m_poko.Players === 2) ||
            (this.game === 'DrawPoker' && this.m_dpok.Players === 2)
        ) {
            this.m_main.show_stol[3] = -1;
        } else {
            this.m_main.show_stol[3] = 0;
        }

        if (this.game === '') {
            this.m_main.show_stol[2] = 0;
        } else {
            if (this.IsGameOnline()) {
                if (this.game === 'Belot4') {
                    if (this.b4.usex[1] > 0) this.m_main.show_stol[2] = this.b4.usex[1];
                    if (this.b4.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.b4.Name[2] !== 'Computer2') {
                        if (this.b4.usex[2] > 0) this.m_main.show_stol[3] = this.b4.usex[2];
                        if (this.b4.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Belot3') {
                    if (this.b3.usex[1] > 0) this.m_main.show_stol[2] = this.b3.usex[1];
                    if (this.b3.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.b3.Name[2] !== 'Computer2') {
                        if (this.b3.usex[2] > 0) this.m_main.show_stol[3] = this.b3.usex[2];
                        if (this.b3.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Belot2') {
                    if (this.b2.usex[1] > 0) this.m_main.show_stol[2] = this.b2.usex[1];
                    if (this.b2.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                }
                if (this.game === 'Santase') {
                    if (this.m_san.usex[1] > 0) this.m_main.show_stol[2] = this.m_san.usex[1];
                    if (this.m_san.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                }
                if (this.game === 'Bridge') {
                    if (this.m_bri.usex[1] > 0) this.m_main.show_stol[2] = this.m_bri.usex[1];
                    if (this.m_bri.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_bri.Name[4] !== 'Computer4') {
                        if (this.m_bri.usex[4] > 0) this.m_main.show_stol[3] = this.m_bri.usex[4];
                        if (this.m_bri.usex[4] === 0) {
                            if (this.m_main.pls[4] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Svara') {
                    if (this.m_sva.usex[1] > 0) this.m_main.show_stol[2] = this.m_sva.usex[1];
                    if (this.m_sva.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_sva.Players > 2) {
                        if (this.m_sva.Name[this.m_sva.Players] !== 'Computer' + this.m_main.IntToString(this.m_sva.Players)) {
                            if (this.m_sva.usex[this.m_sva.Players] > 0) this.m_main.show_stol[3] = this.m_sva.usex[this.m_sva.Players];
                            if (this.m_sva.usex[this.m_sva.Players] === 0) {
                                if (this.m_main.pls[this.m_sva.Players] === 0) this.m_main.show_stol[3] = 1;
                                else this.m_main.show_stol[3] = 2;
                            }
                        }
                        if (this.m_sva.Name[this.m_sva.Players - 1] !== 'Computer' + this.m_main.IntToString(this.m_sva.Players - 1)) {
                            if (this.m_sva.usex[this.m_sva.Players - 1] > 0) this.m_main.show_stol[4] = this.m_sva.usex[this.m_sva.Players - 1];
                            if (this.m_sva.usex[this.m_sva.Players - 1] === 0) {
                                if (this.m_main.pls[this.m_sva.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                                else this.m_main.show_stol[4] = 2;
                            }
                        }
                    }
                    if (this.m_sva.Players === 10) {
                        if (this.m_sva.Name[2] !== 'Computer2') {
                            if (this.m_sva.usex[2] > 0) this.m_main.show_stol[1] = this.m_sva.usex[2];
                            if (this.m_sva.usex[2] === 0) {
                                if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                                else this.m_main.show_stol[1] = 2;
                            }
                        }
                        if (this.m_sva.Name[3] !== 'Computer3') {
                            if (this.m_sva.usex[3] > 0) this.m_main.show_stol[0] = this.m_sva.usex[3];
                            if (this.m_sva.usex[3] === 0) {
                                if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                                else this.m_main.show_stol[0] = 2;
                            }
                        }
                    }
                }
                if (this.game === 'BJ') {
                    if (this.m_bj.usex[1] > 0) this.m_main.show_stol[2] = this.m_bj.usex[1];
                    if (this.m_bj.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_bj.Players > 2) {
                        if (this.m_bj.Name[this.m_bj.Players] !== 'Computer' + this.m_main.IntToString(this.m_bj.Players)) {
                            if (this.m_bj.usex[this.m_bj.Players] > 0) this.m_main.show_stol[3] = this.m_bj.usex[this.m_bj.Players];
                            if (this.m_bj.usex[this.m_bj.Players] === 0) {
                                if (this.m_main.pls[this.m_bj.Players] === 0) this.m_main.show_stol[3] = 1;
                                else this.m_main.show_stol[3] = 2;
                            }
                        }
                        if (this.m_bj.Name[this.m_bj.Players - 1] !== 'Computer' + this.m_main.IntToString(this.m_bj.Players - 1)) {
                            if (this.m_bj.usex[this.m_bj.Players - 1] > 0) this.m_main.show_stol[4] = this.m_bj.usex[this.m_bj.Players - 1];
                            if (this.m_bj.usex[this.m_bj.Players - 1] === 0) {
                                if (this.m_main.pls[this.m_bj.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                                else this.m_main.show_stol[4] = 2;
                            }
                        }
                    }
                    if (this.m_bj.Players === 10) {
                        if (this.m_bj.Name[2] !== 'Computer2') {
                            if (this.m_bj.usex[2] > 0) this.m_main.show_stol[1] = this.m_bj.usex[2];
                            if (this.m_bj.usex[2] === 0) {
                                if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                                else this.m_main.show_stol[1] = 2;
                            }
                        }
                        if (this.m_bj.Name[3] !== 'Computer3') {
                            if (this.m_bj.usex[3] > 0) this.m_main.show_stol[0] = this.m_bj.usex[3];
                            if (this.m_bj.usex[3] === 0) {
                                if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                                else this.m_main.show_stol[0] = 2;
                            }
                        }
                    }
                }
                if (this.game === 'Poker') {
                    if (this.m_pok.usex[1] > 0) this.m_main.show_stol[2] = this.m_pok.usex[1];
                    if (this.m_pok.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_pok.Players > 2) {
                        if (this.m_pok.Name[this.m_pok.Players] !== 'Computer' + this.m_main.IntToString(this.m_pok.Players)) {
                            if (this.m_pok.usex[this.m_pok.Players] > 0) this.m_main.show_stol[3] = this.m_pok.usex[this.m_pok.Players];
                            if (this.m_pok.usex[this.m_pok.Players] === 0) {
                                if (this.m_main.pls[this.m_pok.Players] === 0) this.m_main.show_stol[3] = 1;
                                else this.m_main.show_stol[3] = 2;
                            }
                        }
                        if (this.m_pok.Name[this.m_pok.Players - 1] !== 'Computer' + this.m_main.IntToString(this.m_pok.Players - 1)) {
                            if (this.m_pok.usex[this.m_pok.Players - 1] > 0) this.m_main.show_stol[4] = this.m_pok.usex[this.m_pok.Players - 1];
                            if (this.m_pok.usex[this.m_pok.Players - 1] === 0) {
                                if (this.m_main.pls[this.m_pok.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                                else this.m_main.show_stol[4] = 2;
                            }
                        }
                    }
                    if (this.m_pok.Players === 10) {
                        if (this.m_pok.Name[2] !== 'Computer2') {
                            if (this.m_pok.usex[2] > 0) this.m_main.show_stol[1] = this.m_pok.usex[2];
                            if (this.m_pok.usex[2] === 0) {
                                if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                                else this.m_main.show_stol[1] = 2;
                            }
                        }
                        if (this.m_pok.Name[3] !== 'Computer3') {
                            if (this.m_pok.usex[3] > 0) this.m_main.show_stol[0] = this.m_pok.usex[3];
                            if (this.m_pok.usex[3] === 0) {
                                if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                                else this.m_main.show_stol[0] = 2;
                            }
                        }
                    }
                }
                if (this.game === 'PokerOmaha') {
                    if (this.m_poko.usex[1] > 0) this.m_main.show_stol[2] = this.m_poko.usex[1];
                    if (this.m_poko.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_poko.Players > 2) {
                        if (this.m_poko.Name[this.m_poko.Players] !== 'Computer' + this.m_main.IntToString(this.m_poko.Players)) {
                            if (this.m_poko.usex[this.m_poko.Players] > 0) this.m_main.show_stol[3] = this.m_poko.usex[this.m_poko.Players];
                            if (this.m_poko.usex[this.m_poko.Players] === 0) {
                                if (this.m_main.pls[this.m_poko.Players] === 0) this.m_main.show_stol[3] = 1;
                                else this.m_main.show_stol[3] = 2;
                            }
                        }
                        if (this.m_poko.Name[this.m_poko.Players - 1] !== 'Computer' + this.m_main.IntToString(this.m_poko.Players - 1)) {
                            if (this.m_poko.usex[this.m_poko.Players - 1] > 0) this.m_main.show_stol[4] = this.m_poko.usex[this.m_poko.Players - 1];
                            if (this.m_poko.usex[this.m_poko.Players - 1] === 0) {
                                if (this.m_main.pls[this.m_poko.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                                else this.m_main.show_stol[4] = 2;
                            }
                        }
                    }
                    if (this.m_poko.Players === 10) {
                        if (this.m_poko.Name[2] !== 'Computer2') {
                            if (this.m_poko.usex[2] > 0) this.m_main.show_stol[1] = this.m_poko.usex[2];
                            if (this.m_poko.usex[2] === 0) {
                                if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                                else this.m_main.show_stol[1] = 2;
                            }
                        }
                        if (this.m_poko.Name[3] !== 'Computer3') {
                            if (this.m_poko.usex[3] > 0) this.m_main.show_stol[0] = this.m_poko.usex[3];
                            if (this.m_poko.usex[3] === 0) {
                                if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                                else this.m_main.show_stol[0] = 2;
                            }
                        }
                    }
                }
                if (this.game === 'DrawPoker') {
                    if (this.m_dpok.usex[1] > 0) this.m_main.show_stol[2] = this.m_dpok.usex[1];
                    if (this.m_dpok.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_dpok.Players > 2) {
                        if (this.m_dpok.Name[this.m_dpok.Players] !== 'Computer' + this.m_main.IntToString(this.m_dpok.Players)) {
                            if (this.m_dpok.usex[this.m_dpok.Players] > 0) this.m_main.show_stol[3] = this.m_dpok.usex[this.m_dpok.Players];
                            if (this.m_dpok.usex[this.m_dpok.Players] === 0) {
                                if (this.m_main.pls[this.m_dpok.Players] === 0) this.m_main.show_stol[3] = 1;
                                else this.m_main.show_stol[3] = 2;
                            }
                        }
                        if (this.m_dpok.Name[this.m_dpok.Players - 1] !== 'Computer' + this.m_main.IntToString(this.m_dpok.Players - 1)) {
                            if (this.m_dpok.usex[this.m_dpok.Players - 1] > 0) this.m_main.show_stol[4] = this.m_dpok.usex[this.m_dpok.Players - 1];
                            if (this.m_dpok.usex[this.m_dpok.Players - 1] === 0) {
                                if (this.m_main.pls[this.m_dpok.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                                else this.m_main.show_stol[4] = 2;
                            }
                        }
                    }
                    if (this.m_dpok.Players === 10) {
                        if (this.m_dpok.Name[2] !== 'Computer2') {
                            if (this.m_dpok.usex[2] > 0) this.m_main.show_stol[1] = this.m_dpok.usex[2];
                            if (this.m_dpok.usex[2] === 0) {
                                if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                                else this.m_main.show_stol[1] = 2;
                            }
                        }
                        if (this.m_dpok.Name[3] !== 'Computer3') {
                            if (this.m_dpok.usex[3] > 0) this.m_main.show_stol[0] = this.m_dpok.usex[3];
                            if (this.m_dpok.usex[3] === 0) {
                                if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                                else this.m_main.show_stol[0] = 2;
                            }
                        }
                    }
                }
                if (this.game === 'Blato') {
                    if (this.m_bla.usex[1] > 0) this.m_main.show_stol[2] = this.m_bla.usex[1];
                    if (this.m_bla.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_bla.Name[2] !== 'Computer2') {
                        if (this.m_bla.usex[2] > 0) this.m_main.show_stol[3] = this.m_bla.usex[2];
                        if (this.m_bla.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Tabla') {
                    if (this.tab.usex[1] > 0) this.m_main.show_stol[2] = this.tab.usex[1];
                    if (this.tab.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                }
                if (this.game === 'OKEY') {
                    if (this.m_okey.usex[1] > 0) this.m_main.show_stol[2] = this.m_okey.usex[1];
                    if (this.m_okey.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_okey.Name[2] !== 'Computer2') {
                        if (this.m_okey.usex[2] > 0) this.m_main.show_stol[3] = this.m_okey.usex[2];
                        if (this.m_okey.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Monopoly') {
                    if (this.mon.usex[1] > 0) this.m_main.show_stol[2] = this.mon.usex[1];
                    if (this.mon.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.mon.Name[2] !== 'Computer2') {
                        if (this.mon.usex[2] > 0) this.m_main.show_stol[3] = this.mon.usex[2];
                        if (this.mon.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'NSSC') {
                    if (this.m_nssc.usex[1] > 0) this.m_main.show_stol[2] = this.m_nssc.usex[1];
                    if (this.m_nssc.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.m_nssc.Name[4] !== 'Computer4') {
                        if (this.m_nssc.usex[4] > 0) this.m_main.show_stol[3] = this.m_nssc.usex[4];
                        if (this.m_nssc.usex[4] === 0) {
                            if (this.m_main.pls[4] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
                if (this.game === 'Domino2') {
                    if (this.dom2.usex[1] > 0) this.m_main.show_stol[2] = this.dom2.usex[1];
                    if (this.dom2.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                }
                if (this.game === 'Domino4') {
                    if (this.dom4.usex[1] > 0) this.m_main.show_stol[2] = this.dom4.usex[1];
                    if (this.dom4.usex[1] === 0) {
                        if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                        else this.m_main.show_stol[2] = 2;
                    }
                    if (this.dom4.Name[2] !== 'Computer2') {
                        if (this.dom4.usex[2] > 0) this.m_main.show_stol[3] = this.dom4.usex[2];
                        if (this.dom4.usex[2] === 0) {
                            if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                            else this.m_main.show_stol[3] = 2;
                        }
                    }
                }
            } else {
                if (this.m_main.pls[1] === 0) this.m_main.show_stol[2] = 1;
                else this.m_main.show_stol[2] = 2;

                if (this.game === 'Svara') {
                    if (this.m_sva.Players === 10) {
                        if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                        else this.m_main.show_stol[1] = 2;

                        if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                        else this.m_main.show_stol[0] = 2;
                    }
                    if (this.m_main.pls[this.m_sva.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                    else this.m_main.show_stol[4] = 2;

                    if (this.m_main.pls[this.m_sva.Players] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (this.game === 'BJ') {
                    if (this.m_bj.Players === 10) {
                        if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                        else this.m_main.show_stol[1] = 2;

                        if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                        else this.m_main.show_stol[0] = 2;
                    }

                    if (this.m_main.pls[this.m_bj.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                    else this.m_main.show_stol[4] = 2;

                    if (this.m_main.pls[this.m_bj.Players] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (this.game === 'Poker') {
                    if (this.m_pok.Players === 10) {
                        if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                        else this.m_main.show_stol[1] = 2;

                        if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                        else this.m_main.show_stol[0] = 2;
                    }
                    if (this.m_main.pls[this.m_pok.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                    else this.m_main.show_stol[4] = 2;

                    if (this.m_main.pls[this.m_pok.Players] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (this.game === 'PokerOmaha') {
                    if (this.m_poko.Players === 10) {
                        if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                        else this.m_main.show_stol[1] = 2;

                        if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                        else this.m_main.show_stol[0] = 2;
                    }
                    if (this.m_main.pls[this.m_poko.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                    else this.m_main.show_stol[4] = 2;

                    if (this.m_main.pls[this.m_poko.Players] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (this.game === 'DrawPoker') {
                    if (this.m_dpok.Players === 10) {
                        if (this.m_main.pls[2] === 0) this.m_main.show_stol[1] = 1;
                        else this.m_main.show_stol[1] = 2;

                        if (this.m_main.pls[3] === 0) this.m_main.show_stol[0] = 1;
                        else this.m_main.show_stol[0] = 2;
                    }
                    if (this.m_main.pls[this.m_dpok.Players - 1] === 0) this.m_main.show_stol[4] = 1;
                    else this.m_main.show_stol[4] = 2;

                    if (this.m_main.pls[this.m_dpok.Players] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (
                    this.game === 'Belot4' ||
                    this.game === 'Belot3' ||
                    this.game === 'Blato' ||
                    this.game === 'OKEY' ||
                    this.game === 'Domino4' ||
                    this.game === 'Monopoly' ||
                    this.game === 'NSSC'
                ) {
                    if (this.m_main.pls[2] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
                if (this.game === 'Bridge') {
                    if (this.m_main.pls[4] === 0) this.m_main.show_stol[3] = 1;
                    else this.m_main.show_stol[3] = 2;
                }
            }
        }
    }

    DrawStBack() {
        var res = [];
        if (this.IsGameOnline()) {
            res.push(
                <CPicture
                    noScale={true}
                    key={'st_back_online'}
                    folder="files_1.0"
                    image={'online/st_back.gif'}
                    m_Width={59}
                    m_Height={34}
                    m_y={0}
                    m_x={0}
                />
            );
            let to: string = this.m_dbo.sel_type;
            if (this.m_dbo.m_tournament) {
                to = 'Tournaments';
            }
            res.push(
                <CPicture
                    noScale={true}
                    key={'room_icon'}
                    folder="files_1.0"
                    image={'online/Rooms/' + to + '.gif'}
                    m_Width={20}
                    m_Height={20}
                    m_y={4}
                    m_x={4}
                />
            );
            let watch: string = 'eyec.gif';
            if (this.m_main.enable_watch_game) {
                watch = 'eyeo.gif';
            }
            res.push(
                <Pressable onPress={() => this.enableDisableWatch()}>
                    <CPicture
                        cursor={GlobalConstants.CURSOR_HAND}
                        noScale={true}
                        key={'eye_icon'}
                        folder="files_1.0"
                        image={'online/' + watch}
                        m_Width={15}
                        m_Height={8}
                        m_y={10}
                        m_x={40}
                    />
                </Pressable>
            );
            let close: string = 'open.gif';
            if (this.close_game_m_filename.indexOf('close.gif') >= 0 || (this.close_game_m_filename === '' && this.m_dbo.m_table_password !== '')) {
                close = 'close.gif';
            }
            res.push(
                <Pressable onPress={() => this.addRemovePassword()}>
                    <CPicture
                        noScale={true}
                        key={'close_icon'}
                        folder="files_1.0"
                        image={'online/' + close}
                        m_Width={13}
                        m_Height={13}
                        m_y={8}
                        m_x={25}
                        cursor={GlobalConstants.CURSOR_HAND}
                    />
                </Pressable>
            );
        } else {
            if (this.game !== '') {
                res.push(
                    <CPicture
                        noScale={true}
                        key={'st_back_pc'}
                        folder="files_1.0"
                        image={'pc/st_back_pc.gif'}
                        m_Width={28}
                        m_Height={28}
                        m_y={0}
                        m_x={0}
                    />
                );
            }
        }
        return res;
    }

    DrawIndikator() {
        var res = [];
        if (this.IsGameOnline()) {
            let wi: number;
            let hi: number;
            let x: number;
            let y: number;
            let val: number;
            x = 6;
            y = 24;
            wi = 40;
            val = this.GetIndikatorVal(wi);
            hi = 4;
            res.push(
                <CPicture
                    noScale={true}
                    key={'indicator'}
                    folder="files_1.0"
                    image={'online/sp_pointer.gif'}
                    m_Width={8}
                    m_Height={5}
                    m_y={y}
                    m_x={x + val}
                />
            );
        }
        return res;
    }

    GetIndikatorVal(max: number): number {
        let val: number = max;
        if (this.last_ping_dif == 0) val = max;
        if (this.last_ping_dif > 0 && this.last_ping_dif <= 5) val = max - 1;
        if (this.last_ping_dif > 5 && this.last_ping_dif <= 10) val = max - 2;
        if (this.last_ping_dif > 10 && this.last_ping_dif <= 15) val = max - 3;
        if (this.last_ping_dif > 15 && this.last_ping_dif <= 20) val = max - 4;
        if (this.last_ping_dif > 20 && this.last_ping_dif <= 25) val = max - 5;
        if (this.last_ping_dif > 25 && this.last_ping_dif <= 30) val = max - 6;
        if (this.last_ping_dif > 30 && this.last_ping_dif <= 35) val = max - 7;
        if (this.last_ping_dif > 35 && this.last_ping_dif <= 40) val = max - 8;
        if (this.last_ping_dif > 40 && this.last_ping_dif <= 45) val = max - 9;
        if (this.last_ping_dif > 45 && this.last_ping_dif <= 50) val = max - 10;
        if (this.last_ping_dif > 50 && this.last_ping_dif <= 60) val = max - 11;
        if (this.last_ping_dif > 60 && this.last_ping_dif <= 70) val = max - 12;
        if (this.last_ping_dif > 70 && this.last_ping_dif <= 80) val = max - 13;
        if (this.last_ping_dif > 80 && this.last_ping_dif <= 90) val = max - 14;
        if (this.last_ping_dif > 90 && this.last_ping_dif <= 100) val = max - 15;
        if (this.last_ping_dif > 100 && this.last_ping_dif <= 125) val = max - 17;
        if (this.last_ping_dif > 125 && this.last_ping_dif <= 150) val = 21;
        if (this.last_ping_dif > 150 && this.last_ping_dif <= 200) val = 19;
        if (this.last_ping_dif > 200 && this.last_ping_dif <= 300) val = 17;
        if (this.last_ping_dif > 300 && this.last_ping_dif <= 400) val = 15;
        if (this.last_ping_dif > 400 && this.last_ping_dif <= 500) val = 13;
        if (this.last_ping_dif > 500 && this.last_ping_dif <= 600) val = 11;
        if (this.last_ping_dif > 600 && this.last_ping_dif <= 700) val = 9;
        if (this.last_ping_dif > 700 && this.last_ping_dif <= 800) val = 7;
        if (this.last_ping_dif > 800 && this.last_ping_dif <= 900) val = 5;
        if (this.last_ping_dif > 900 && this.last_ping_dif <= 1000) val = 3;
        if (this.last_ping_dif > 1000) val = 2;
        if (this.last_ping_dif > 5000) val = 1;
        if (this.last_ping_dif > 10000) val = 0;
        //m_ping_text.AddRectTool(this,L"Ping: "+IntToString(this.last_ping_dif/2)+ L" ms.",CRect(6,24,54,34),NULL);
        return val;
    }

    DrawSkin() {
        this.SetStolParameters();
        var res = [];
        if (!this.m_main.loadingMain) {
            for (let i: number = 0; i < 5; i++) {
                for (let a = 0; a < 3; a++) {
                    if (this.m_main.show_stol[i] === a) {
                        res.push(
                            <CPicture
                                key={'stol_' + i + '_' + a}
                                image={this.m_main.stol[i][a].image}
                                m_Width={this.m_main.stol[i][a].m_Width}
                                m_Height={this.m_main.stol[i][a].m_Height}
                                m_y={this.m_main.stol[i][a].m_y}
                                m_x={this.m_main.stol[i][a].m_x}
                            />
                        );
                    }
                }
            }
        }
        return res;
    }

    drawView() {
        var res = [];

        res.push(this.DrawBanners());
        res.push(this.DrawSkin());
        res.push(this.DrawStBack());
        res.push(this.DrawIndikator());
        res.push(<DlgConnect onRef={(ref: DlgConnect) => (this.m_con = ref)} m_pwin={this} key={'DlgConnect'} />);
        res.push(<CBelot4Players onRef={(ref: CBelot4Players) => (this.b4 = ref)} m_pwin={this} key={'Belot4'} />);
        res.push(<CBridge onRef={(ref: CBridge) => (this.m_bri = ref)} m_pwin={this} key={'Bridge'} />);
        res.push(<CTabla onRef={(ref: CTabla) => (this.tab = ref)} m_pwin={this} key={'Tabla'} />);
        res.push(<CNSSC onRef={(ref: CNSSC) => (this.m_nssc = ref)} m_pwin={this} key={'CNSSC'} />);
        res.push(<CBlato onRef={(ref: CBlato) => (this.m_bla = ref)} m_pwin={this} key={'CBlato'} />);
        res.push(<CSantase onRef={(ref: CSantase) => (this.m_san = ref)} m_pwin={this} key={'CSantase'} />);
        res.push(<CDomino2 onRef={(ref: CDomino2) => (this.dom2 = ref)} m_pwin={this} key={'CDomino2'} />);
        res.push(<CDomino3 onRef={(ref: CDomino3) => (this.dom3 = ref)} m_pwin={this} key={'CDomino3'} />);
        res.push(<CDomino4 onRef={(ref: CDomino4) => (this.dom4 = ref)} m_pwin={this} key={'CDomino4'} />);
        //res.push(<CBelot2Players onRef={(ref: CBelot2Players) => (this.b2 = ref)} m_pwin={this} key={'CBelot2Players'} />);
        res.push(<CBelot3Players onRef={(ref: CBelot3Players) => (this.b3 = ref)} m_pwin={this} key={'CBelot3Players'} />);
        res.push(<CSvara onRef={(ref: CSvara) => (this.m_sva = ref)} m_pwin={this} key={'CSvara'} />);
        res.push(<CPokerOmaha onRef={(ref: CPokerOmaha) => (this.m_poko = ref)} m_pwin={this} key={'CPokerOmaha'} />);
        res.push(<CDrawPoker onRef={(ref: CDrawPoker) => (this.m_dpok = ref)} m_pwin={this} key={'CDrawPoker'} />);
        //res.push(<COKEY onRef={(ref: COKEY) => (this.m_okey = ref)} m_pwin={this} key={'COKEY'} />);
        res.push(<CMonopoly onRef={(ref: CMonopoly) => (this.mon = ref)} m_pwin={this} key={'CMonopoly'} />);
        res.push(<CBlackJack onRef={(ref: CBlackJack) => (this.m_bj = ref)} m_pwin={this} key={'CBlackJack'} />);

        res.push(<CChatBar onRef={(ref: CChatBar) => (this.pFrame.m_chat_bar = ref)} m_pwin={this} key={'CChatBar'} />);
        if (this.m_main.language !== undefined) {
            res.push(<CInfoBar onRef={(ref: CInfoBar) => (this.pFrame.m_info_bar = ref)} m_pwin={this} key={'CInfoBar'} />);
        }

        if (this.game === 'Blato') {
            res.push(
                <DlgRezultatBlato
                    onRef={(ref: DlgRezultatBlato) => (this.m_bla.m_rez = ref)}
                    parent={this.m_bla}
                    m_pwin={this}
                    key={'DlgRezultatBlato'}
                />
            );
            res.push(
                <CBlatoGetCards
                    onRef={(ref: CBlatoGetCards) => (this.m_bla.get_cardsdlg = ref)}
                    parent={this.m_bla}
                    m_pwin={this}
                    key={'CBlatoGetCards'}
                />
            );
            res.push(
                <DlgObiaviavaneBla
                    onRef={(ref: DlgObiaviavaneBla) => (this.m_bla.m_obbla = ref)}
                    parent={this.m_bla}
                    m_pwin={this}
                    key={'DlgObiaviavaneBla'}
                />
            );
            res.push(
                <DlgORezultat onRef={(ref: DlgORezultat) => (this.m_bla.m_orez = ref)} parent={this.m_bla} m_pwin={this} key={'DlgORezultatBlato'} />
            );
        }
        if (this.game === 'Belot4') {
            res.push(<DlgNaddavane onRef={(ref: DlgNaddavane) => (this.b4.m_n = ref)} parent={this.b4} m_pwin={this} key={'DlgNaddavaneBelot'} />);
            res.push(<DlgRezultat onRef={(ref: DlgRezultat) => (this.b4.m_rez = ref)} parent={this.b4} m_pwin={this} key={'DlgRezultat'} />);
            res.push(<DlgORezultat onRef={(ref: DlgORezultat) => (this.b4.m_orez = ref)} parent={this} m_pwin={this} key={'DlgORezultatBelot4'} />);
        }
        if (this.game === 'Bridge') {
            res.push(<Bidding_Bridge onRef={(ref: Bidding_Bridge) => (this.m_bri.bidd = ref)} parent={this} m_pwin={this} key={'Bidding_Bridge'} />);
            res.push(
                <CRubberResult
                    onRef={(ref: CRubberResult) => (this.m_bri.rubber_result = ref)}
                    parent={this.m_bri}
                    m_pwin={this}
                    key={'CRubberResult'}
                />
            );
            res.push(<CImpResult onRef={(ref: CImpResult) => (this.m_bri.imp_result = ref)} parent={this.m_bri} m_pwin={this} key={'CImpResult'} />);
        }
        res.push(<SettingsScreen onRef={(ref: SettingsScreen) => (this.m_settings = ref)} m_pwin={this} key={'SettingsScreen'} />);
        res.push(<ChangePasswordScreen onRef={(ref: ChangePasswordScreen) => (this.m_cp = ref)} m_pwin={this} key={'ChangePasswordScreen'} />);
        res.push(<PlayersNamesScreen onRef={(ref: PlayersNamesScreen) => (this.m_pl = ref)} m_pwin={this} key={'PlayersNamesScreen'} />);
        res.push(<DlgStatistika onRef={(ref: DlgStatistika) => (this.m_sta = ref)} m_pwin={this} key={'DlgStatistika'} />);
        res.push(<DlgOnline onRef={(ref: DlgOnline) => (this.m_dbo = ref)} m_pwin={this} key={'DlgOnline'} />);
        res.push(<CYESNOChips onRef={(ref: CYESNOChips) => (this.m_yesnochips = ref)} m_pwin={this} key={'CYESNOChips'} />);
        res.push(<DlgWebView onRef={(ref: DlgWebView) => (this.m_webView = ref)} m_pwin={this} key={'DlgWebView'} />);

        res.push(<DlgKarti onRef={(ref: DlgKarti) => (this.m_karti = ref)} m_pwin={this} key={'DlgKarti'} />);
        res.push(<DlgVip onRef={(ref: DlgVip) => (this.m_vip = ref)} m_pwin={this} key={'DlgVip'} />);
        res.push(
            <DeleteProfileScreen onRef={(ref: DeleteProfileScreen) => (this.m_delete_profile = ref)} m_pwin={this} key={'DeleteProfileScreen'} />
        );

        res.push(<CNewTablePassword onRef={(ref: CNewTablePassword) => (this.m_ntp = ref)} m_pwin={this} key={'CNewTablePassword'} />);
        res.push(<CIzborNaIgra onRef={(ref: CIzborNaIgra) => (this.m_izbor = ref)} m_pwin={this} key={'CIzborNaIgra'} />);
        res.push(<CIgraNSSC onRef={(ref: CIgraNSSC) => (this.m_nsscdlg = ref)} m_pwin={this} key={'CIgraNSSC'} />);
        res.push(<IgraBelotDlg onRef={(ref: IgraBelotDlg) => (this.m_beldlg = ref)} m_pwin={this} key={'IgraBelotDlg'} />);
        res.push(<IgraBJDlg onRef={(ref: IgraBJDlg) => (this.m_bjdlg = ref)} m_pwin={this} key={'IgraBJDlg'} />);
        res.push(<IgraSantaseDlg onRef={(ref: IgraSantaseDlg) => (this.m_sandlg = ref)} m_pwin={this} key={'IgraSantaseDlg'} />);
        res.push(<IgraTabla onRef={(ref: IgraTabla) => (this.m_tabdlg = ref)} m_pwin={this} key={'IgraTabla'} />);
        res.push(<IgraOKEY onRef={(ref: IgraOKEY) => (this.m_okeydlg = ref)} m_pwin={this} key={'IgraOKEY'} />);
        res.push(<IgraMonopoly onRef={(ref: IgraMonopoly) => (this.m_mondlg = ref)} m_pwin={this} key={'IgraMonopoly'} />);
        res.push(<IgraDominoDlg onRef={(ref: IgraDominoDlg) => (this.m_domdlg = ref)} m_pwin={this} key={'IgraDominoDlg'} />);
        res.push(<IgraBlatoDlg onRef={(ref: IgraBlatoDlg) => (this.m_bladlg = ref)} m_pwin={this} key={'IgraBlatoDlg'} />);
        res.push(<IgraBridge onRef={(ref: IgraBridge) => (this.m_bridlg = ref)} m_pwin={this} key={'IgraBridge'} />);
        res.push(<IgraSvara onRef={(ref: IgraSvara) => (this.m_svadlg = ref)} m_pwin={this} key={'IgraSvara'} />);
        res.push(<IgraPoker onRef={(ref: IgraPoker) => (this.m_pokdlg = ref)} m_pwin={this} key={'IgraPoker'} />);

        res.push(<Dlg432Players onRef={(ref: Dlg432Players) => (this.m_d432 = ref)} m_pwin={this} key={'Dlg432Players'} />);
        res.push(<PlayersFilter onRef={(ref: PlayersFilter) => (this.pf = ref)} m_pwin={this} parent={this} key={'PlayersFilter'} />);
        res.push(<OnlineRegistration onRef={(ref: OnlineRegistration) => (this.oreg = ref)} m_pwin={this} key={'OnlineRegistration'} />);

        if (!this.haveOpenDialog()) {
            let visibleOkAlerts = 0;
            for (let i = 0; i <= this.messageokIndex; i++) {
                if (this.messageOKVisible[i] === true) {
                    visibleOkAlerts = visibleOkAlerts + 1;
                }
            }
            this.messageokIndex = visibleOkAlerts;
        }
        if (!this.haveOpenDialog()) {
            let visibleYesnoAlerts = 0;
            for (let i = 0; i <= this.messageyesnoIndex; i++) {
                if (this.messageYESNOVisible[i] === true) {
                    visibleYesnoAlerts = visibleYesnoAlerts + 1;
                }
            }
            this.messageyesnoIndex = visibleYesnoAlerts;
        }
        for (let i = this.messageokIndex + 5; i >= 0; i--) {
            res.push(<COK onRef={(ref: COK) => (this.m_ok[i] = ref)} m_pwin={this} key={'COK' + i} />);
        }
        for (let i = this.messageyesnoIndex + 5; i >= 0; i--) {
            res.push(<CYESNO onRef={(ref: CYESNO) => (this.m_yesno[i] = ref)} m_pwin={this} key={'CYESNO' + i} />);
        }
        res.push(<LanguagePickerDlg onRef={(ref: LanguagePickerDlg) => (this.languagePickerDlg = ref)} m_pwin={this} key={'LanguagePickerDlg'} />);

        return res;
    }

    render() {
        return (
            <View style={styles.appBackground}>
                <TouchableOpacity
                    activeOpacity={1}
                    onPress={(evt) => {
                        this.clickCount++;
                        if (this.clickCount >= 2) {
                            clearTimeout(this.pressTimer);
                            this.OnLButtonDblClk(evt);
                            this.clickCount = 0;
                        } else {
                            this.pressTimer = setTimeout(() => {
                                this.clickCount = 0;
                            }, 500);
                            this.OnLButtonDown(evt);
                        }
                    }}
                    onLongPress={(evt) => this.OnRButtonDown(evt)}
                    hitSlop={{
                        top: GlobalVariables.positionTop,
                        bottom: GlobalVariables.positionLeft,
                        left: 1015 * GlobalVariables.scale,
                        right: 693 * GlobalVariables.scale,
                    }}
                >
                    {Platform.OS === 'web' ? (
                        <div
                            style={{
                                cursor: GlobalConstants.CURSOR_ARROW,
                            }}
                        >
                            <NavigationDrawerHeader
                                m_pwin={this}
                                onRef={(ref: NavigationDrawerHeader) => (this.nd = ref)}
                                key={'NavigationDrawerHeader'}
                            />
                            <ImageBackground
                                source={require('../../assets/files_2.0/skins/interface_1.gif')}
                                style={{
                                    width: 1015 * GlobalVariables.scale,
                                    height: 693 * GlobalVariables.scale,
                                    top: GlobalVariables.positionTop,
                                    left: GlobalVariables.positionLeft,
                                }}
                            >
                                {this.drawView()}
                            </ImageBackground>
                        </div>
                    ) : (
                        <>
                            <NavigationDrawerHeader
                                m_pwin={this}
                                onRef={(ref: NavigationDrawerHeader) => (this.nd = ref)}
                                key={'NavigationDrawerHeader'}
                            />
                            <ImageBackground
                                source={require('../../assets/files_2.0/skins/interface_1.gif')}
                                style={{
                                    width: 1015 * GlobalVariables.scale,
                                    height: 693 * GlobalVariables.scale,
                                    top: GlobalVariables.positionTop,
                                    left: GlobalVariables.positionLeft,
                                }}
                            >
                                {this.drawView()}
                            </ImageBackground>
                        </>
                    )}
                </TouchableOpacity>
            </View>
        );
    }
}

export default CCardGamesView;

const cstyles = StyleSheet.create({
    banner: { position: 'absolute' },
});
