import React from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, TextInputProps, TouchableOpacityProps, StyleProp, ViewStyle } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import type { MaterialIcon } from 'app/common-types';
import t from '../../../translation';

type Props = TextInputProps & {
    leftIcon?: MaterialIcon;
    rightIcon?: MaterialIcon;
    containerStyle?: StyleProp<ViewStyle>;
    onRightIconPress?: TouchableOpacityProps['onPress'];
    height?: number;
    required?: boolean;
    refinput?: any;
};

const lightTint = '#2f2f2a';

const InputField = ({
    leftIcon,
    rightIcon,
    containerStyle,
    onRightIconPress,
    height = 22,
    required = false,
    refinput,
    placeholder,
    ...rest
}: Props) => {
    return (
        <View
            style={[
                {
                    backgroundColor: lightTint,
                    borderRadius: height,
                    padding: height / 8,
                    height: height * 1.2,
                },
                styles.container,
                containerStyle,
            ]}
        >
            {leftIcon ? (
                <MaterialCommunityIcons
                    name={leftIcon}
                    size={height * 0.8}
                    style={{
                        marginLeft: height / 8,
                        marginRight: height / 8,
                        paddingTop: height * 0.05,
                    }}
                />
            ) : null}
            <TextInput
                ref={refinput}
                autoCompleteType="off"
                placeholder={placeholder ? (required ? '* ' : '') + t.t(placeholder) : ''}
                style={[styles.input, rest.style, { fontSize: height / 3, marginRight: height / 4 }]}
                autoCorrect={false}
                autoFocus={true}
                multiline={false}
                {...rest}
            />
            {rightIcon ? (
                <TouchableOpacity onPress={onRightIconPress}>
                    <MaterialCommunityIcons
                        name={rightIcon}
                        size={height * 0.8}
                        style={
                            (styles.rightIcon,
                            {
                                marginRight: height / 4,
                                paddingTop: height * 0.05,
                            })
                        }
                    />
                </TouchableOpacity>
            ) : null}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderWidth: 1,
        backgroundColor: 'white',
    },
    input: {
        flex: 1,
        width: '100%',
    },
    rightIcon: {
        alignSelf: 'center',
    },
});

export default InputField;
