import GlobalConstants from '~/Components/GlobalConstants';
import CPoint from '../../cppComponents/CPoint';
import CBelot from './CBelot';
import React from 'react';
import { View } from 'react-native';
import DlgRezultatB3P from '~/Dialogs/DlgRezultatB3P';

class CBelot3Players extends CBelot {

    m_rez = new DlgRezultatB3P(null);
    lKontra: number;

    ProverkaCursor(card: number): boolean {
        if (this.Faza < 5) return false;
        if (this.Move === true || this.KoiENaRed !== 1) return false;
        if (this.KoiPuskaParvi === 1) return true;
        //////////////////////////////////////////////////////////
        if (this.Naddavane === 5) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.k[card] > 20) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) return false;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.k[card] < 20 || this.k[card] > 30) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) return false;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.k[card] < 30 || this.k[card] > 40) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) return false;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.k[card] < 40 || this.k[card] > 50) {
                    for (let i: number = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) return false;
                    }
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////
        if (this.KoiPuskaParvi === 3) {
            if (this.Naddavane < 5) {
                if (this.ParviPuska > 100) {
                    if (this.k[card] < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                    if (this.k[card] > 100 && this.k[card] < this.ParviPuska) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.ParviPuska) return false;
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) return false;
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100 || this.k[i] > 10 && this.k[i] < 20) return false;
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) return false;
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 100 || this.k[card] < 20) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100 || this.k[i] > 20 && this.k[i] < 30) return false;
                        }
                    }

                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) return false;
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 100 || this.k[card] < 30) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100 || this.k[i] > 30 && this.k[i] < 40) return false;
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) return false;
                        }
                    }
                    if (this.k[card] < 40) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100 || this.k[i] > 40 && this.k[i] < 50) return false;
                        }
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) return false;
                        }
                    }
                    if (this.k[card] > 10 && this.k[card] < 20 && this.k[card] < this.ParviPuska) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] < 20 && this.k[i] > this.ParviPuska) return false;
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) return false;
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 30 && this.k[card] < this.ParviPuska) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska) return false;
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] < 30 || this.k[card] > 40) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) return false;
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 40 && this.k[card] < this.ParviPuska) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska) return false;
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] < 40 || this.k[card] > 50) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) return false;
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 50 && this.k[card] < this.ParviPuska) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska) return false;
                        }
                    }
                }
            }
        }
        ////////
        if (this.KoiPuskaParvi === 2) {
            if (this.Naddavane < 5) {
                if (this.ParviPuska > 100) {
                    if (this.k[card] < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                    if (this.k[card] > 100 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska) return false;
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) return false;
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 100 && this.VtoriPuska > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.VtoriPuska) return false;
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 100 && this.VtoriPuska < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) return false;
                        }
                    }
                    if ((this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100 && this.VtoriPuska > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.VtoriPuska) return false;
                        }
                    }
                    if ((this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100 && this.VtoriPuska < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] > 40 || this.k[card] < 30) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) return false;
                        }
                    }
                    if ((this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100 && this.VtoriPuska > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.VtoriPuska) return false;
                        }
                    }
                    if ((this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100 && this.VtoriPuska < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] > 50 || this.k[card] < 40) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) return false;
                        }
                    }
                    if ((this.k[card] < 40 || this.k[card] > 50) && this.k[card] < 100 && this.VtoriPuska > 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > this.VtoriPuska) return false;
                        }
                    }
                    if ((this.k[card] < 40 || this.k[card] > 50) && this.k[card] < 100 && this.VtoriPuska < 100) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 100) return false;
                        }
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) return false;
                        }
                    }
                    if (this.k[card] > 10 && this.k[card] < 20) {
                        if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 10 && this.k[i] < 20) return false;
                            }
                        }
                        if (this.VtoriPuska > 20 && this.k[card] < this.ParviPuska) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 10 && this.k[i] < 20) return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) return false;
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 30) {
                        if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 20 && this.k[i] < 30) return false;
                            }
                        }
                        if ((this.VtoriPuska < 20 || this.VtoriPuska > 30) && this.k[card] < this.ParviPuska) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 20 && this.k[i] < 30) return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] < 30 || this.k[card] > 40) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) return false;
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 40) {
                        if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 30 && this.k[i] < 40) return false;
                            }
                        }
                        if ((this.VtoriPuska < 30 || this.VtoriPuska > 40) && this.k[card] < this.ParviPuska) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 30 && this.k[i] < 40) return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] < 40 || this.k[card] > 50) {
                        for (let i: number = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) return false;
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 50) {
                        if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 40 && this.k[i] < 50) return false;
                            }
                        }
                        if (this.VtoriPuska < 40 && this.k[card] < this.ParviPuska) {
                            for (let i: number = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 40 && this.k[i] < 50) return false;
                            }
                        }
                    }
                }
            }
        }
        return true;
    }

    AddToDetails(card: number) {
        let kdcard = this.k[card];
        if (this.Naddavane === 6) {
            kdcard = this.k[card];
            if (this.k[card] === 18) kdcard = 14;
            if (this.k[card] === 28) kdcard = 24;
            if (this.k[card] === 38) kdcard = 34;
            if (this.k[card] === 48) kdcard = 44;
            if (this.k[card] === 17) kdcard = 13;
            if (this.k[card] === 27) kdcard = 23;
            if (this.k[card] === 37) kdcard = 33;
            if (this.k[card] === 47) kdcard = 43;
            if (this.k[card] === 16) kdcard = 18;
            if (this.k[card] === 26) kdcard = 28;
            if (this.k[card] === 36) kdcard = 38;
            if (this.k[card] === 46) kdcard = 48;
            if (this.k[card] === 15) kdcard = 17;
            if (this.k[card] === 25) kdcard = 27;
            if (this.k[card] === 35) kdcard = 37;
            if (this.k[card] === 45) kdcard = 47;
            if (this.k[card] === 14) kdcard = 16;
            if (this.k[card] === 24) kdcard = 26;
            if (this.k[card] === 34) kdcard = 36;
            if (this.k[card] === 44) kdcard = 46;
            if (this.k[card] === 13) kdcard = 15;
            if (this.k[card] === 23) kdcard = 25;
            if (this.k[card] === 33) kdcard = 35;
            if (this.k[card] === 43) kdcard = 45;
        }
        if (this.Naddavane < 5) {
            kdcard = this.k[card];
            if (this.k[card] === 108) kdcard = this.Naddavane * 10 + 4;
            if (this.k[card] === 107) kdcard = this.Naddavane * 10 + 3;
            if (this.k[card] === 106) kdcard = this.Naddavane * 10 + 8;
            if (this.k[card] === 105) kdcard = this.Naddavane * 10 + 7;
            if (this.k[card] === 104) kdcard = this.Naddavane * 10 + 6;
            if (this.k[card] === 103) kdcard = this.Naddavane * 10 + 5;
            if (this.k[card] === 102) kdcard = this.Naddavane * 10 + 2;
            if (this.k[card] === 101) kdcard = this.Naddavane * 10 + 1;
        }
        for (let i: number = 1; i < 25; i++) {
            if (this.kd[i] === 0) {

                this.kd[i] = kdcard;
                return;

            }
        }
    }

    async PuskaneNaKarta(card: number) {
        this.AddToDetails(card);


        if (this.CheckBelot(this.k[card], 1)) {
            this.m_ann.InitWindow(true, '', '');
            if (!this.watch) {
                if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                    if (this.m_ann.m_belote) {
                        this.ObiaviavaneBelot(1, true);
                        this.Belot[1][0] = this.Belot[1][0] + 1;
                        if (this.OP) {
                            if (this.Server === true) {
                                this.SendGame("$|o|" + this.IntToString(this.hand));
                            }
                            else {
                                this.SendGame("$|p|" + this.IntToString(this.hand));
                            }
                        }
                    }
                }
            }
            else {
                if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                    this.ObiaviavaneBelot(this.KoiENaRed, true);
                    this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                }
            }
            this.m_ann.start = 0;
        }
        if (this.hand === 0) {
            this.setState({});
            if (this.watch) {
                for (let b: number = 0; b <= 1; b++) {
                    if (this.PTerci[this.KoiENaRed][b] > 0) {
                        if (this.terci[this.KoiENaRed][b]) {
                            if (!(this.PTerci[this.KoiENaRed][0] > 0 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 7 && this.PTerci[this.KoiENaRed][1] > 0 && this.PTerci[this.KoiENaRed][1] < 7)
                                && !(this.PTerci[this.KoiENaRed][0] > 10 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 16 && this.PTerci[this.KoiENaRed][1] > 10 && this.PTerci[this.KoiENaRed][1] < 16)
                                && !(this.PTerci[this.KoiENaRed][0] > 30 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 37 && this.PTerci[this.KoiENaRed][1] > 30 && this.PTerci[this.KoiENaRed][1] < 37)) {
                                this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                            }
                            if (b === 0) {
                                if (this.PTerci[this.KoiENaRed][0] > 0 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 7 && this.PTerci[this.KoiENaRed][1] > 0 && this.PTerci[this.KoiENaRed][1] < 7) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                }
                                if (this.PTerci[this.KoiENaRed][0] > 10 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 16 && this.PTerci[this.KoiENaRed][1] > 10 && this.PTerci[this.KoiENaRed][1] < 16) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                }
                                if (this.PTerci[this.KoiENaRed][0] > 30 && this.terci[this.KoiENaRed][0] && this.terci[this.KoiENaRed][1] && this.PTerci[this.KoiENaRed][0] < 37 && this.PTerci[this.KoiENaRed][1] > 30 && this.PTerci[this.KoiENaRed][1] < 37) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                }
                            }
                        }
                        else this.PTerci[this.KoiENaRed][b] = 0;
                    }
                }
            }
            else {
                if (this.PTerci[1][0] > 0 || this.PTerci[1][1] > 0) {
                    this.m_ann.InitWindow(false, this.StringTerci(this.PTerci[1][0]), this.StringTerci(this.PTerci[1][1]));
                    if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                        this.terci[1][0] = this.m_ann.m_announce1;
                        this.terci[1][1] = this.m_ann.m_announce2;
                        for (let b: number = 0; b <= 1; b++) {
                            if (this.PTerci[1][b] > 0 && this.terci[1][b]) {
                                if (!(this.PTerci[1][0] > 0 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 7 && this.PTerci[1][1] > 0 && this.PTerci[1][1] < 7)
                                    && !(this.PTerci[1][0] > 10 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 16 && this.PTerci[1][1] > 10 && this.PTerci[1][1] < 16)
                                    && !(this.PTerci[1][0] > 30 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 37 && this.PTerci[1][1] > 30 && this.PTerci[1][1] < 37)) {
                                    this.ObiaviavaneTerci(1, this.PTerci[1][b], b, true);
                                }
                                if (b === 0) {
                                    if (this.PTerci[1][0] > 0 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 7 && this.PTerci[1][1] > 0 && this.PTerci[1][1] < 7) {
                                        this.ObiaviavaneTerci(1, 7, 0, true);
                                    }
                                    if (this.PTerci[1][0] > 10 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 16 && this.PTerci[1][1] > 10 && this.PTerci[1][1] < 16) {
                                        this.ObiaviavaneTerci(1, 16, 0, true);
                                    }
                                    if (this.PTerci[1][0] > 30 && this.terci[1][0] && this.terci[1][1] && this.PTerci[1][0] < 37 && this.PTerci[1][1] > 30 && this.PTerci[1][1] < 37) {
                                        this.ObiaviavaneTerci(1, 37, 0, true);
                                    }
                                }
                                if (this.OP && !this.watch) {
                                    if (this.Server) {
                                        this.SendGame("$|q|1|" + this.IntToString(b));
                                    }
                                    else {
                                        this.SendGame("$|r|" + this.IntToString(b));
                                    }
                                }
                            }
                            else {
                                this.PTerci[1][b] = 0;
                                this.terci[1][b] = false;
                            }
                        }
                    }
                    else {
                        this.PTerci[1][0] = 0;
                        this.terci[1][0] = false;
                        this.PTerci[1][1] = 0;
                        this.terci[1][1] = false;
                    }
                    this.m_ann.start = 0;
                }
            }
            //	for(let b: number=0;b<=1;b++)if(this.PTerci[1][b]>0)this.ObiaviavaneTerci(1,this.PTerci[1][b],b, true);
        }
        if (this.KoiPuskaParvi === 1) this.ParviPuska = this.k[card];
        if (this.KoiPuskaParvi === 2) this.TretiPuska = this.k[card];
        if (this.KoiPuskaParvi === 3) this.VtoriPuska = this.k[card];
        this.P1puska = this.k[card];
        this.CardsPoint[33] = this.CardsPoint[card];
        this.CardP[33] = this.CardP[card];
        this.BeginMove(this.CardsPoint[33], this.CardsPos[33], 33);
        if (this.KoiPuskaParvi !== 2) this.KoiENaRed = 2;
        if (this.SP === true) {
            this.props.m_pwin.KillTimer(3);
            this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
        }
        if (this.KoiPuskaParvi === 1) {
            if (this.Naddavane > 4) {
                if (this.k[card] > 10 && this.k[card] < 17 && this.CheckVlastnaKarta(this.CheckNGCard(1, 1)) === true && (this.k[card] !== this.CheckNGCard(1, 1) || this.k[card] === 17)) this.Piska[1][1] = true;
                if (this.k[card] > 20 && this.k[card] < 27 && this.CheckVlastnaKarta(this.CheckNGCard(1, 2)) === true && (this.k[card] !== this.CheckNGCard(1, 2) || this.k[card] === 27)) this.Piska[1][2] = true;
                if (this.k[card] > 30 && this.k[card] < 37 && this.CheckVlastnaKarta(this.CheckNGCard(1, 3)) === true && (this.k[card] !== this.CheckNGCard(1, 3) || this.k[card] === 37)) this.Piska[1][3] = true;
                if (this.k[card] > 40 && this.k[card] < 47 && this.CheckVlastnaKarta(this.CheckNGCard(1, 4)) === true && (this.k[card] !== this.CheckNGCard(1, 4) || this.k[card] === 47)) this.Piska[1][4] = true;
            }
        }
        if (this.KoiPuskaParvi > 1) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) this.Pniama[1][1] = true;
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) this.Pniama[1][2] = true;
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) this.Pniama[1][3] = true;
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) this.Pniama[1][4] = true;
            if (this.ParviPuska > 100 && this.k[card] < 100) this.Pniama[1][this.Naddavane] = true;
            if (this.Naddavane === 6 && this.KoiPuskaParvi === 3) {
                if (this.ParviPuska === 12 && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 22 && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 32 && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 42 && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) this.Pniama[1][1] = true;
                if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) this.Pniama[1][2] = true;
                if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) this.Pniama[1][3] = true;
                if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12) this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22) this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32) this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42) this.Pniama[1][4] = true;

                if (this.ParviPuska === 14 && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13) this.Pniama[1][1] = true;
                if (this.ParviPuska === 24 && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23) this.Pniama[1][2] = true;
                if (this.ParviPuska === 34 && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33) this.Pniama[1][3] = true;
                if (this.ParviPuska === 44 && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43) this.Pniama[1][4] = true;

                if (this.ParviPuska === 15 && this.CheckCard(14) === false && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11) this.Pniama[1][1] = true;
                if (this.ParviPuska === 25 && this.CheckCard(24) === false && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21) this.Pniama[1][2] = true;
                if (this.ParviPuska === 35 && this.CheckCard(34) === false && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31) this.Pniama[1][3] = true;
                if (this.ParviPuska === 45 && this.CheckCard(44) === false && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41) this.Pniama[1][4] = true;

                if (this.ParviPuska === 15 && this.CheckCard(14) === false && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12) this.Pniama[1][1] = true;
                if (this.ParviPuska === 25 && this.CheckCard(24) === false && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22) this.Pniama[1][2] = true;
                if (this.ParviPuska === 35 && this.CheckCard(34) === false && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32) this.Pniama[1][3] = true;
                if (this.ParviPuska === 45 && this.CheckCard(44) === false && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42) this.Pniama[1][4] = true;

                if (this.ParviPuska === 15 && this.CheckCard(14) === false && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13) this.Pniama[1][1] = true;
                if (this.ParviPuska === 25 && this.CheckCard(24) === false && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23) this.Pniama[1][2] = true;
                if (this.ParviPuska === 35 && this.CheckCard(34) === false && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33) this.Pniama[1][3] = true;
                if (this.ParviPuska === 45 && this.CheckCard(44) === false && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43) this.Pniama[1][4] = true;

                if (this.ParviPuska === 15 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 14) this.Pniama[1][1] = true;
                if (this.ParviPuska === 25 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 24) this.Pniama[1][2] = true;
                if (this.ParviPuska === 35 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 34) this.Pniama[1][3] = true;
                if (this.ParviPuska === 45 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 44) this.Pniama[1][4] = true;
            }
            if (this.Naddavane < 5 && this.KoiPuskaParvi === 3) {
                if (this.ParviPuska === 102 && this.k[card] === 101) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(102) === false && this.k[card] === 101) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(101) === false && this.k[card] === 102) this.Pniama[1][this.Naddavane] = true;
                if (this.ParviPuska === 104 && this.CheckCard(102) === false && this.CheckCard(101) === false && this.k[card] === 103) this.Pniama[1][this.Naddavane] = true;
            }
        }
        this.setState({});
        this.props.m_pwin.SetCursor();
    }

    PuskaneNaKartaOP(card: number) {
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            if (this.KoiENaRed === 1) {
                this.PuskaneNaKarta(card);
                if (this.Server === true) {
                    if (this.P2OP !== true) {
                        this.props.m_pwin.KillTimer(3);

                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);

                    }
                    this.SendGame("$|k|" + this.IntToString(card));
                }
                else {
                    this.SendGame("$|l|" + this.IntToString(card));
                }
            }
        }
    }

    AutoPuskKard() {
        for (let i: number = 1; i <= 8; i++) {
            if (this.ProverkaCursor(i)) {
                if (this.k[1] !== 0) {
                    if (this.m_ann.start === 1) {
                        this.m_ann.Exit();
                        this.online_timer = this.online_timer_time;
                        return;
                    }
                    this.PuskaneNaKartaOP(i);
                    return;
                }
            }
        }
    }

    Anonsi(Player: number): number {
        //
        let OtherPlay: number;
        if (Player == 2) OtherPlay = 3;
        if (Player == 3) OtherPlay = 2;
        let BrBoia: number[] = [0, 0, 0, 0, 0];
        let BoiaPP: number[] = [0, 0, 0, 0, 0, 0, 0];
        let BoiaVale: boolean[] = [false, false, false, false, false];
        let BoiaDev: boolean[] = [false, false, false, false, false];
        let BoiaAso: boolean[] = [false, false, false, false, false];
        let BoiaDes: boolean[] = [false, false, false, false, false];
        for (let i: number = ((Player - 1) * 8 + 1); i < ((Player - 1) * 8 + 7); i++) {
            BrBoia[this.k[i] / 10]++;
            if (this.k[i] % 10 == 5)
                BoiaVale[this.k[i] / 10] = true;
            if (this.k[i] % 10 == 3)
                BoiaDev[this.k[i] / 10] = true;
            if (this.k[i] % 10 == 8)
                BoiaAso[this.k[i] / 10] = true;
            if (this.k[i] % 10 == 4)
                BoiaDes[this.k[i] / 10] = true;
        }
        //dobavia koef pri dulga boia
        for (let i: number = 1; i < 5; i++) {
            if (BrBoia[i] == 1) {
                BoiaPP[i] -= 50;
            }
            if (BrBoia[i] == 3)
                BoiaPP[i] += 40;
            if (BrBoia[i] == 4)
                BoiaPP[i] += 80;
            if (BrBoia[i] == 5)
                BoiaPP[i] += 150;
            if (BrBoia[i] == 6)
                BoiaPP[i] += 300;
            if (BoiaVale[i] && BoiaDev[i])
                BoiaPP[i] += 50;
        }
        ///
        for (let i: number = 1; i < 5; i++) {
            if (BoiaVale[i] && BoiaDev[i]) {
                BoiaPP[6] += 30;
            }
            if (BrBoia[i] == 1) {
                if (BoiaDev[i]) {
                    BoiaPP[6] -= 12;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] -= 13;
                }
            }
            if (BrBoia[i] == 3) {
                if (BoiaVale[i] && BoiaDev[i])
                    BoiaPP[6] += 30;
                if (BoiaVale[i] && BoiaAso[i]) {
                    BoiaPP[6] += 15;
                }
                if (BoiaVale[i]) {
                    BoiaPP[6] += 20;
                }
                if (BoiaDev[i]) {
                    BoiaPP[6] += 15;
                }
                if (BoiaAso[i] && BoiaDes[i])
                    BoiaPP[5] += 30;
                if (BoiaAso[i]) {
                    BoiaPP[5] += 20;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] += 15;
                }
            }
            if (BrBoia[i] == 4) {
                if (BoiaVale[i] && BoiaDev[i])
                    BoiaPP[6] += 50;
                if (BoiaVale[i] && BoiaAso[i]) {
                    BoiaPP[6] += 25;
                }
                if (BoiaVale[i]) {
                    BoiaPP[6] += 30;
                }
                if (BoiaDev[i]) {
                    BoiaPP[6] += 45;
                }
                if (BoiaAso[i] && BoiaDes[i])
                    BoiaPP[5] += 50;
                if (BoiaAso[i]) {
                    BoiaPP[5] += 30;
                }
                if (BoiaDes[i]) {
                    BoiaPP[5] += 25;
                }
            }
            if (BrBoia[i] == 5) {
                if (BoiaVale[i]) {
                    if (this.KoiPuskaParvi == Player) {
                        BoiaPP[6] += 220;
                    }
                    else {
                        BoiaPP[6] += 80;
                    }
                }
                else {
                    if (this.KoiPuskaParvi == Player) {
                        BoiaPP[6] += 140;
                    }
                    else {
                        BoiaPP[6] += 50;
                    }
                }
                if (BoiaAso[i]) {
                    if (this.KoiPuskaParvi == Player) {
                        BoiaPP[5] += 220;
                    }
                    else {
                        BoiaPP[5] += 80;
                    }
                }
                else {
                    if (this.KoiPuskaParvi == Player) {
                        BoiaPP[5] += 140;
                    }
                    else {
                        BoiaPP[5] += 50;
                    }
                }
            }
            if (BrBoia[i] == 6) {
                if (this.KoiPuskaParvi == Player) {
                    BoiaPP[6] += 400;
                    BoiaPP[5] += 390;
                }
                else {
                    BoiaPP[6] += 80;
                    BoiaPP[5] += 60;
                }
            }
        }
        //
        let spatia: number;
        let karo: number;
        let kupa: number;
        let pika: number;
        let bezkoz: number;
        let vsichkokoz: number;
        spatia = karo = kupa = pika = bezkoz = vsichkokoz = 0;
        if (this.Naddavane < 2) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 13) spatia = spatia + 120;
                if (this.k[a] == 11) spatia = spatia + 90;
                if (this.k[a] == 16) spatia = spatia + 70;
                if (this.k[a] == 12) spatia = spatia + 60;
                if (this.k[a] == 15) spatia = spatia + 30;
                if (this.k[a] == 14) spatia = spatia + 20;
                if (this.k[a] == 26 || this.k[a] == 36 || this.k[a] == 46) spatia = spatia + 60;
                if (this.k[a] == 22 || this.k[a] == 32 || this.k[a] == 42) spatia = spatia + 37;
                this.k[a] += 2;
            }

            spatia += BoiaPP[1];
        }
        //////////////KARO///////////////////////////////////////////
        if (this.Naddavane < 3) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 23) karo = karo + 120;
                if (this.k[a] == 21) karo = karo + 90;
                if (this.k[a] == 26) karo = karo + 70;
                if (this.k[a] == 22) karo = karo + 60;
                if (this.k[a] == 25) karo = karo + 30;
                if (this.k[a] == 24) karo = karo + 20;
                if (this.k[a] == 26 || this.k[a] == 36 || this.k[a] == 46) karo = karo + 60;
                if (this.k[a] == 22 || this.k[a] == 32 || this.k[a] == 42) karo = karo + 37;
                this.k[a] += 2;
            }
            karo += BoiaPP[2];
        }
        //////////////KUPA////////////////////////////////////////////
        if (this.Naddavane < 4) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 33) kupa = kupa + 120;
                if (this.k[a] == 31) kupa = kupa + 90;
                if (this.k[a] == 36) kupa = kupa + 70;
                if (this.k[a] == 32) kupa = kupa + 60;
                if (this.k[a] == 35) kupa = kupa + 30;
                if (this.k[a] == 34) kupa = kupa + 20;
                if (this.k[a] == 26 || this.k[a] == 16 || this.k[a] == 46) kupa = kupa + 60;
                if (this.k[a] == 22 || this.k[a] == 12 || this.k[a] == 42) kupa = kupa + 37;
                this.k[a] += 2;
            }
            kupa += BoiaPP[3];
        }
        //////////////////////////PIKA//////////////////////////////
        if (this.Naddavane < 5) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 43) pika = pika + 120;
                if (this.k[a] == 41) pika = pika + 90;
                if (this.k[a] == 46) pika = pika + 70;
                if (this.k[a] == 42) pika = pika + 60;
                if (this.k[a] == 45) pika = pika + 30;
                if (this.k[a] == 44) pika = pika + 20;
                if (this.k[a] == 26 || this.k[a] == 36 || this.k[a] == 16) pika = pika + 60;
                if (this.k[a] == 22 || this.k[a] == 32 || this.k[a] == 12) pika = pika + 37;
                this.k[a] += 2;
            }
            pika += BoiaPP[4];
        }
        ////////////BEZKOZ/////////////////////////////////////////
        if (this.Naddavane < 6) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 16 || this.k[a] == 26 || this.k[a] == 36 || this.k[a] == 46) bezkoz = bezkoz + 95;
                if (this.k[a] == 12 || this.k[a] == 22 || this.k[a] == 32 || this.k[a] == 42) bezkoz = bezkoz + 65;
                if (this.k[a] == 15 || this.k[a] == 25 || this.k[a] == 35 || this.k[a] == 45) bezkoz = bezkoz + 20;
                this.k[a] += 2;
            }
            bezkoz += BoiaPP[5];
        }
        if (this.Naddavane < 7) {
            for (let a: number = ((Player - 1) * 8 + 1); a < ((Player - 1) * 8 + 7); a++) {
                this.k[a] -= 2;
                if (this.k[a] == 13 || this.k[a] == 23 || this.k[a] == 33 || this.k[a] == 43) vsichkokoz = vsichkokoz + 90;
                if (this.k[a] == 11 || this.k[a] == 21 || this.k[a] == 31 || this.k[a] == 41) vsichkokoz = vsichkokoz + 60;
                if (this.k[a] == 16 || this.k[a] == 26 || this.k[a] == 36 || this.k[a] == 46) vsichkokoz = vsichkokoz + 20;
                this.k[a] += 2;
            }
            vsichkokoz += BoiaPP[6];
        }
        //////////////////////////////////////////////////////////////////////////////////////////
        if ((this.m_rez.m_crez1 < 145 && ((Player == 2 && this.m_rez.m_crez3 < 145) || (Player == 3 && this.m_rez.m_crez2 < 145)))
            || (150 - this.m_rez.m_crez2) < 16) {
            let tercaf = this.CheckTerci(false, Player);
            if (tercaf == 36) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 200;
            }
            if (tercaf == 35) {
                spatia = spatia + 150;
                karo = karo + 170;
                kupa = kupa + 160;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf == 34) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                bezkoz = bezkoz + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf == 33) {
                spatia = spatia + 120;
                karo = karo + 120;
                kupa = kupa + 120;
                pika = pika + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf == 32 || tercaf == 31) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                bezkoz = bezkoz + 40;
            }
            if (tercaf > 20 && tercaf < 30) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf > 10 && tercaf < 20) {
                spatia = spatia + 70;
                karo = karo + 70;
                kupa = kupa + 70;
                pika = pika + 70;
                vsichkokoz = vsichkokoz + 40;
            }
            ////////////////////////////////////////////////////
            if (tercaf > 0 && tercaf < 6) {
                spatia = spatia + 30;
                karo = karo + 30;
                kupa = kupa + 30;
                pika = pika + 30;
                vsichkokoz = vsichkokoz + 30;
            }
        }
        if (this.m_rez.m_rez2 > 135 && this.m_rez.m_rez1 < 120 && this.m_rez.m_rez1 > 60) {
            spatia = spatia - 20;
            karo = karo - 20;
            kupa = kupa - 20;
            pika = pika - 20;
            bezkoz = bezkoz - 30;
            vsichkokoz = vsichkokoz - 30;
        }
        let MaxOtherRes: number;
        let MyRez: number;
        if (Player == 2) {
            MyRez = this.m_rez.m_rez2;
            if (this.m_rez.m_rez1 > this.m_rez.m_rez3)
                MaxOtherRes = this.m_rez.m_rez1;
            else
                MaxOtherRes = this.m_rez.m_rez3;
        }
        if (Player == 3) {
            MyRez = this.m_rez.m_rez3;
            if (this.m_rez.m_rez1 > this.m_rez.m_rez2)
                MaxOtherRes = this.m_rez.m_rez1;
            else
                MaxOtherRes = this.m_rez.m_rez2;
        }
        if (MaxOtherRes > 140 && MyRez < 132) {
            spatia = spatia - (MaxOtherRes - 140) * 8; karo = karo - (MaxOtherRes - 140) * 8; kupa = kupa - (MaxOtherRes - 140) * 8; pika = pika - (MaxOtherRes - 140) * 8; bezkoz = bezkoz - (MaxOtherRes - 140) * 12; vsichkokoz = vsichkokoz - (MaxOtherRes - 140) * 12;
        }
        if (MaxOtherRes > 140 && MyRez > 140 && MyRez < 149 && ((this.Naddavane == 1 && (this.Pkazal[OtherPlay][1] == true)) || (this.Naddavane == 2 && (this.Pkazal[OtherPlay][2] == true)) || (this.Naddavane == 3 && (this.Pkazal[OtherPlay][3] == true)) || (this.Naddavane == 4 && (this.Pkazal[OtherPlay][4] == true)) || (this.Naddavane == 5 && (this.Pkazal[OtherPlay][5] == true)))) {
            karo = karo + 20; kupa = kupa + 20; pika = pika + 20; bezkoz = bezkoz + 20; vsichkokoz = vsichkokoz + 20;
        }
        if (this.KoiPuskaParvi == Player) {
            spatia = spatia + 25;
            karo = karo + 25;
            kupa = kupa + 25;
            pika = pika + 25;
            bezkoz = bezkoz + 50;
            vsichkokoz = vsichkokoz + 50;
        }
        if ((this.Pkazal[1][1] == true || this.Pkazal[OtherPlay][1] == true) && this.Naddavane == 1) {
            vsichkokoz = vsichkokoz - 15;
            if (spatia < 80) {
                karo = karo + 15;
                kupa = kupa + 15;
                pika = pika + 15;
                bezkoz = bezkoz + 15;
            }
            if (spatia > 120) {
                karo = karo - 15;
                kupa = kupa - 15;
                pika = pika - 15;
                bezkoz = bezkoz - 15;
            }
        }
        if ((this.Pkazal[1][2] == true || this.Pkazal[OtherPlay][2] == true) && this.Naddavane == 2) {
            vsichkokoz = vsichkokoz - 15;
            if (karo < 80) {
                kupa = kupa + 15;
                pika = pika + 15;
                bezkoz = bezkoz + 15;
            }
            if (karo > 120) {
                kupa = kupa - 15;
                pika = pika - 15;
                bezkoz = bezkoz - 15;

            }
        }
        if ((this.Pkazal[1][3] == true || this.Pkazal[OtherPlay][3] == true) && this.Naddavane == 3) {
            vsichkokoz = vsichkokoz - 15;
            if (kupa < 80) {
                pika = pika + 15;
                bezkoz = bezkoz + 15;
            }
            if (kupa > 120) {
                pika = pika - 15;
                bezkoz = bezkoz - 15;
            }

        }
        if ((this.Pkazal[1][4] == true || this.Pkazal[OtherPlay][4] == true) && this.Naddavane == 4) {
            vsichkokoz = vsichkokoz - 15;
            if (pika < 80) bezkoz = bezkoz + 15;
            if (pika > 120) bezkoz = bezkoz - 15;
        }
        if ((this.Pkazal[1][5] == true || this.Pkazal[OtherPlay][5] == true) && this.Naddavane == 1) {
            if (bezkoz < 100) vsichkokoz = vsichkokoz + 20;
            if (bezkoz > 150) vsichkokoz = vsichkokoz - 20;
        }
        //////////////////////////////////////////////////////////////////////////////////////////
        let Nadd: number = 0;
        if (spatia > 220 && this.Naddavane < 1) Nadd = 1;
        if (karo > spatia && karo > 225 && this.Naddavane < 2) Nadd = 2;
        if (kupa > karo && karo > spatia && kupa > 230 && this.Naddavane < 3) Nadd = 3;
        if (pika > kupa && pika > karo && pika > spatia && pika > 235 && this.Naddavane < 4) Nadd = 4;
        if (bezkoz > pika && bezkoz > kupa && bezkoz > karo && bezkoz > spatia && bezkoz > 255 && this.Naddavane < 5) Nadd = 5;
        if (vsichkokoz > bezkoz && vsichkokoz > pika && vsichkokoz > kupa && vsichkokoz > karo && vsichkokoz > spatia && vsichkokoz > 245 && this.Naddavane < 6) Nadd = 6;
        //	Nadd=0;//?
        ///////////////////////
        if (this.Kontra == 0 && this.Rekontra == 0) {
            if (this.Pkazal[1][this.Naddavane] == true || this.Pkazal[OtherPlay][this.Naddavane] == true) {
                if (this.Naddavane < 5) {
                    if (MaxOtherRes > 140 && MyRez < 135 && this.BrPas > 0) {
                        Nadd = 7;
                    }
                    else {
                        if (this.Naddavane == 1 && spatia > 260) Nadd = 7;
                        if (this.Naddavane == 2 && karo > 265) Nadd = 7;
                        if (this.Naddavane == 3 && kupa > 270) Nadd = 7;
                        if (this.Naddavane == 4 && pika > 275) Nadd = 7;
                    }
                }
                if (this.Naddavane == 5) {
                    if (MaxOtherRes > 137 && MyRez < 125 && this.BrPas > 0) {
                        Nadd = 7;
                    }
                    else if (bezkoz > 300) Nadd = 7;
                }
                if (this.Naddavane == 6) {
                    if (MaxOtherRes > 135 && MyRez < 121 && this.BrPas > 0) {
                        Nadd = 7;
                    }
                    else if (vsichkokoz > 285) Nadd = 7;
                }
            }
        }
        if (this.Kontra == 1 || this.Kontra == OtherPlay) {
            if (this.Naddavane < 5) {
                if (MaxOtherRes > 118 && MyRez < 118 && this.BrPas > 0) {
                    Nadd = 8;
                }
                else {
                    if (this.Naddavane == 1 && spatia > 285) Nadd = 8;
                    if (this.Naddavane == 2 && karo > 290) Nadd = 8;
                    if (this.Naddavane == 3 && kupa > 295) Nadd = 8;
                    if (this.Naddavane == 4 && pika > 300) Nadd = 8;
                }
            }
            if (this.Naddavane == 5) {
                if (MaxOtherRes > 98 && MyRez < 98 && this.BrPas > 0) {
                    Nadd = 8;
                }
                else if (bezkoz > 330) Nadd = 8;
            }
            if (this.Naddavane == 6) {
                if (MaxOtherRes > 94 && MyRez < 94 && this.BrPas > 0) {
                    Nadd = 8;
                }
                else if (vsichkokoz > 320) Nadd = 8;
            }
        }
        //////////////////////////////////
        if (Nadd > 0 && Nadd < 7) {
            //CString kon;////?
            //Kontrata i rekontrata;
            this.Kontra = 0;
            this.Rekontra = 0;
            this.Naddavane = Nadd;
            this.BrPas = 0;
            this.Pkazal[Player][Nadd] = true;
        }
        if (Nadd > 6) {
            if (Nadd == 7) {
                this.Kontra = Player;
            }
            if (Nadd == 8) {
                this.lKontra = this.Kontra;
                this.Kontra = 0;
                this.Rekontra = Player;
            }
            this.BrPas = 0;
        }
        if (Nadd == 0) this.BrPas = this.BrPas + 1;
        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[Player], this.GameName(Nadd));
        return Nadd;
    }


    /*




void CBelot3Players::Init3()
{
	
    if(pApp->GetProfileInt("Options","bidd"+m_pwin->game,1)==1)m_pwin->ShowInfoBar(true, false);
    this.lKontra=0;
    if(this.P2OP==true)	
    {
        P2G=false;
    }
    else P2G=true;
    if(P3OP==true)
    {
        P3G=false;
    }
    else P3G=true;
    m_orez.m_zagubi=0;
    m_orez.m_pobedi=0;
    Players=3;
    Init();
    pause=0;
    if(this.SP)
    {
        this.Name[3]=pApp->GetProfileString("Options", "this.Name[3]","Computer3");
    }
    for(let i: number =1;i<4;i++)m_orez.Player[i]=this.Name[i];
    m_orez.Players=3;
	
    StrelkaPos[1].x=550;StrelkaPos[1].y=350;
    StrelkaPos[2].x=650;StrelkaPos[2].y=280;
    StrelkaPos[3].x=320;StrelkaPos[3].y=130;
	
    NamePos[1].x=360;NamePos[1].y=381;
    NamePos[2].x=610;NamePos[2].y=260;
    NamePos[3].x=360;NamePos[3].y=138;
	
    PosNaddavane[1].x=340;PosNaddavane[1].y=350;
    PosNaddavane[2].x=540;PosNaddavane[2].y=230;
    PosNaddavane[3].x=340;PosNaddavane[3].y=112;
	
    PosBelot[1].x=330;PosBelot[1].y=363;
    PosBelot[2].x=540;PosBelot[2].y=230;
    PosBelot[3].x=330;PosBelot[3].y=112;
	
    PosTerci[1][0].x=389;PosTerci[1][0].y=363;
    PosTerci[1][1].x=446;PosTerci[1][1].y=363;
    PosTerci[2][0].x=598;PosTerci[2][0].y=230;
    PosTerci[2][1].x=656;PosTerci[2][1].y=230;
    PosTerci[3][0].x=389;PosTerci[3][0].y=112;
    PosTerci[3][1].x=446;PosTerci[3][1].y=112;

    this.CardsPos[0].x=100;this.CardsPos[0].y=180;

    this.CardsPos[1].x=167;this.CardsPos[1].y=418;
    this.CardsPos[2].x=224;this.CardsPos[2].y=418;
    this.CardsPos[3].x=281;this.CardsPos[3].y=418;
    this.CardsPos[4].x=338;this.CardsPos[4].y=418;
    this.CardsPos[5].x=395;this.CardsPos[5].y=418;
    this.CardsPos[6].x=452;this.CardsPos[6].y=418;
    this.CardsPos[7].x=509;this.CardsPos[7].y=418;
    this.CardsPos[8].x=566;this.CardsPos[8].y=418;
	
    this.CardsPos[9].x=710;this.CardsPos[9].y=275;
    this.CardsPos[10].x=710;this.CardsPos[10].y=250;
    this.CardsPos[11].x=710;this.CardsPos[11].y=225;
    this.CardsPos[12].x=710;this.CardsPos[12].y=200;
    this.CardsPos[13].x=710;this.CardsPos[13].y=175;
    this.CardsPos[14].x=710;this.CardsPos[14].y=150;
    this.CardsPos[15].x=710;this.CardsPos[15].y=125;
    this.CardsPos[16].x=710;this.CardsPos[16].y=100;
	
    this.CardsPos[17].x=505;this.CardsPos[17].y=22;
    this.CardsPos[18].x=465;this.CardsPos[18].y=22;
    this.CardsPos[19].x=425;this.CardsPos[19].y=22;
    this.CardsPos[20].x=385;this.CardsPos[20].y=22;
    this.CardsPos[21].x=345;this.CardsPos[21].y=22;
    this.CardsPos[22].x=305;this.CardsPos[22].y=22;
    this.CardsPos[23].x=265;this.CardsPos[23].y=22;
    this.CardsPos[24].x=225;this.CardsPos[24].y=22;

    this.CardsPos[33].x=360;this.CardsPos[33].y=270;
    this.CardsPos[34].x=435;this.CardsPos[34].y=225;
    this.CardsPos[35].x=360;this.CardsPos[35].y=180;
}

void CBelot3Players::InitGame3()
{
    this.lKontra=0;
    op_start=false;
    for(let i: number =1;i<9;i++)vz[i]=0;
    for(let i: number =1;i<25;i++)this.kd[i]=0;
    this.m_rez.m_rez1=0;this.m_rez.m_rez2=0;this.m_rez.m_rez3=0;
    this.m_rez.m_bel1="";
    this.m_rez.m_bel2="";
    this.m_rez.m_bel3="";
    this.m_rez.m_ter1pl1="";
    this.m_rez.m_ter2pl1="";
    this.m_rez.m_ter1pl2="";
    this.m_rez.m_ter2pl2="";
    this.m_rez.m_ter1pl3="";
    this.m_rez.m_ter2pl3="";
    for(let i: number =0;i<5;i++)
    {
        Niama[0][i]=false;
        Niama[1][i]=false;
        Niama[2][i]=false;
        Niama[3][i]=false;
        LIzchistena[0][i]=0;
        LIzchistena[1][i]=0;
        LIzchistena[2][i]=0;
        LIzchistena[3][i]=0;
        NeKa4ena[0][i]=0;
        NeKa4ena[1][i]=0;
        NeKa4ena[2][i]=0;
        NeKa4ena[3][i]=0;
    }
    if(KoiZapochva<3)KoiZapochva=KoiZapochva+1;
    else KoiZapochva=1;
    InitGame();
    for(let i: number =0;i<=24;i++)
    {
        this.k[i]=0;
        this.CardsPoint[i]=this.CardsPos[0];
    }
    this.P1puska=0;P2puska=0;P3puska=0;
    strelka=0;this.Naddavane=0;
    if((this.OP&&this.Server)||this.SP)RandCards();
    if((this.OP&&this.Server)||this.SP) {
        LoadCards(false, true);
    }
    open_rez_dlg=false;
    open_orez_dlg=false;
    if(this.OP) {
        if(!this.watch)this.SendGame("$|y");
    }
    for(let i: number =1;i<=3;i++) {
        if(last_Name[i]=="")last_Name[i]=this.Name[i];
    }

}

void CBelot3Players::RandCards()
{
    int i;
    const int VECTOR_SIZE = 24 ;
    typedef vector<int> IntVector ;
    typedef IntVector::iterator IntVectorIt ;
    IntVector Numbers(VECTOR_SIZE) ;
    IntVectorIt start, end, it ;
    for(let i: number =0;i<24;i++)
    {
        Numbers[i]=i+1;
    }
    start = Numbers.begin() ;
    end = Numbers.end();
    random_shuffle(start, end) ;
    i=0;
    for (it = start; it !== end; it++)
    {
        i = i + 1;
        if (*it === 1)this.k[i] = 13;
        if (*it === 2)this.k[i] = 14;
        if (*it === 3)this.k[i] = 15;
        if (*it === 4)this.k[i] = 16;
        if (*it === 5)this.k[i] = 17;
        if (*it === 6)this.k[i] = 18;
        if (*it === 7)this.k[i] = 23;
        if (*it === 8)this.k[i] = 24;
        if (*it === 9)this.k[i] = 25;
        if (*it === 10)this.k[i] = 26;
        if (*it === 11)this.k[i] = 27;
        if (*it === 12)this.k[i] = 28;
        if (*it === 13)this.k[i] = 33;
        if (*it === 14)this.k[i] = 34;
        if (*it === 15)this.k[i] = 35;
        if (*it === 16)this.k[i] = 36;
        if (*it === 17)this.k[i] = 37;
        if (*it === 18)this.k[i] = 38;
        if (*it === 19)this.k[i] = 43;
        if (*it === 20)this.k[i] = 44;
        if (*it === 21)this.k[i] = 45;
        if (*it === 22)this.k[i] = 46;
        if (*it === 23)this.k[i] = 47;
        if (*it === 24)this.k[i] = 48;
    }
}

void CBelot3Players::InitRezDlg()
{
    if(this.watch) {
        this.Faza=6;
    }
    if(!this.watch) {
        this.online_timer=this.online_timer_time;
        this.m_rez.m_name1=this.Name[1];
        this.m_rez.m_name2=this.Name[2];
        this.m_rez.m_name3=this.Name[3];
        if(this.Belot[1][0]==1)
        {
            this.m_rez.m_rez1=this.m_rez.m_rez1+20;
            this.m_rez.m_bel1="1 "+GetLanguageText("Belote");
        }
        if(this.Belot[1][0]==2)
        {
            this.m_rez.m_rez1=this.m_rez.m_rez1+40;
            this.m_rez.m_bel1="2 "+GetLanguageText("Belotes");
        }
        if(this.Belot[1][0]==3)
        {
            this.m_rez.m_rez1=this.m_rez.m_rez1+60;
            this.m_rez.m_bel1="3 "+GetLanguageText("Belotes");
        }
        if(this.Belot[1][0]==4)
        {
            this.m_rez.m_rez1=this.m_rez.m_rez1+80;
            this.m_rez.m_bel1="4 "+GetLanguageText("Belotes");
        }
        if(this.Belot[2][0]==1)
        {
            this.m_rez.m_rez2=this.m_rez.m_rez2+20;
            this.m_rez.m_bel2="1 "+GetLanguageText("Belote");
        }
        if(this.Belot[2][0]==2)
        {
            this.m_rez.m_rez2=this.m_rez.m_rez2+40;
            this.m_rez.m_bel2="2 "+GetLanguageText("Belotes");
        }
        if(this.Belot[2][0]==3)
        {
            this.m_rez.m_rez2=this.m_rez.m_rez2+60;
            this.m_rez.m_bel2="3 "+GetLanguageText("Belotes");
        }
        if(this.Belot[2][0]==4)
        {
            this.m_rez.m_rez2=this.m_rez.m_rez2+80;
            this.m_rez.m_bel2="4 "+GetLanguageText("Belotes");
        }
        if(this.Belot[3][0]==1)
        {
            this.m_rez.m_rez3=this.m_rez.m_rez3+20;
            this.m_rez.m_bel3="1 "+GetLanguageText("Belote");
        }
        if(this.Belot[3][0]==2)
        {
            this.m_rez.m_rez3=this.m_rez.m_rez3+40;
            this.m_rez.m_bel3="2 "+GetLanguageText("Belotes");
        }
        if(this.Belot[3][0]==3)
        {
            this.m_rez.m_rez3=this.m_rez.m_rez3+60;
            this.m_rez.m_bel3="3 "+GetLanguageText("Belotes");
        }
        if(this.Belot[3][0]==4)
        {
            this.m_rez.m_rez3=this.m_rez.m_rez3+80;
            this.m_rez.m_bel3="4 "+GetLanguageText("Belotes");
        }
        if(this.Naddavane!=5)
        {
            this.m_rez.m_ter1pl1=this.StringTerci(this.PTerci[1][0]);
            this.m_rez.m_ter2pl1=this.StringTerci(this.PTerci[1][1]);
            this.m_rez.m_ter1pl2=this.StringTerci(this.PTerci[2][0]);
            this.m_rez.m_ter2pl2=this.StringTerci(this.PTerci[2][1]);
            this.m_rez.m_ter1pl3=this.StringTerci(this.PTerci[3][0]);
            this.m_rez.m_ter2pl3=this.StringTerci(this.PTerci[3][1]);
            if((this.PTerci[1][0]>30&&this.PTerci[1][0]>this.PTerci[2][0]&&this.PTerci[1][0]>this.PTerci[2][1]&&this.PTerci[1][0]>this.PTerci[3][0]&&this.PTerci[1][0]>this.PTerci[3][1])||(this.PTerci[1][1]>30&&this.PTerci[1][1]>this.PTerci[2][0]&&this.PTerci[1][1]>this.PTerci[2][1]&&this.PTerci[1][1]>this.PTerci[3][0]&&this.PTerci[1][1]>this.PTerci[3][1]))
            {
                if(this.PTerci[1][0]>30) {
                    this.m_rez.m_rez1=this.m_rez.m_rez1+PointTerci(this.PTerci[1][0]);
                    this.m_rez.dis[1][0]=true;
                }
                if(this.PTerci[1][1]>30) {
                    this.m_rez.m_rez1=this.m_rez.m_rez1+PointTerci(this.PTerci[1][1]);
                    this.m_rez.dis[1][1]=true;
                }
                if(this.PTerci[2][0]>30)this.m_rez.dis[2][0]=false;
                if(this.PTerci[2][1]>30)this.m_rez.dis[2][1]=false;
                if(this.PTerci[3][0]>30)this.m_rez.dis[3][0]=false;
                if(this.PTerci[3][1]>30)this.m_rez.dis[3][1]=false;
            }
            if((this.PTerci[1][0]>0&&this.PTerci[1][0]<30&&(this.PTerci[1][0]>=this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[1][0]>=this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[1][0]>=this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[1][0]>=this.PTerci[3][1]||this.PTerci[3][1]>30))||(this.PTerci[1][1]>0&&this.PTerci[1][1]<30&&(this.PTerci[1][1]>=this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[1][1]>=this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[1][1]>=this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[1][1]>=this.PTerci[3][1]||this.PTerci[3][1]>30)))
            {
                if((this.PTerci[1][0]>0&&this.PTerci[1][0]<30&&(this.PTerci[1][0]>this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[1][0]>this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[1][0]>this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[1][0]>this.PTerci[3][1]||this.PTerci[3][1]>30))||(this.PTerci[1][1]>0&&this.PTerci[1][1]<30&&(this.PTerci[1][1]>this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[1][1]>this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[1][1]>this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[1][1]>this.PTerci[3][1]||this.PTerci[3][1]>30)))
                {
                    if(this.PTerci[1][0]>0&&this.PTerci[1][0]<30) {
                        this.m_rez.m_rez1=this.m_rez.m_rez1+PointTerci(this.PTerci[1][0]);
                        this.m_rez.dis[1][0]=true;
                    }
                    if(this.PTerci[1][1]>0&&this.PTerci[1][1]<30) {
                        this.m_rez.m_rez1=this.m_rez.m_rez1+PointTerci(this.PTerci[1][1]);
                        this.m_rez.dis[1][1]=true;
                    }
                }
                if(this.PTerci[2][0]>0&&this.PTerci[2][0]<30)this.m_rez.dis[2][0]=false;
                if(this.PTerci[2][1]>0&&this.PTerci[2][1]<30)this.m_rez.dis[2][1]=false;
                if(this.PTerci[3][0]>0&&this.PTerci[3][0]<30)this.m_rez.dis[3][0]=false;
                if(this.PTerci[3][1]>0&&this.PTerci[3][1]<30)this.m_rez.dis[3][1]=false;
            }
            if((this.PTerci[2][0]>30&&this.PTerci[2][0]>=this.PTerci[1][0]&&this.PTerci[2][0]>=this.PTerci[1][1]&&this.PTerci[2][0]>=this.PTerci[3][0]&&this.PTerci[2][0]>=this.PTerci[3][1])||(this.PTerci[2][1]>30&&this.PTerci[2][1]>=this.PTerci[1][0]&&this.PTerci[2][1]>=this.PTerci[1][1]&&this.PTerci[2][1]>=this.PTerci[3][0]&&this.PTerci[2][1]>=this.PTerci[3][1]))
            {
                if(this.PTerci[2][0]>30) {
                    this.m_rez.m_rez2=this.m_rez.m_rez2+PointTerci(this.PTerci[2][0]);
                    this.m_rez.dis[2][0]=true;
                }
                if(this.PTerci[2][1]>30) {
                    this.m_rez.m_rez2=this.m_rez.m_rez2+PointTerci(this.PTerci[2][1]);
                    this.m_rez.dis[2][1]=true;
                }
                if(this.PTerci[1][0]>30)this.m_rez.dis[1][0]=false;
                if(this.PTerci[1][1]>30)this.m_rez.dis[1][1]=false;
                if(this.PTerci[3][0]>30)this.m_rez.dis[3][0]=false;
                if(this.PTerci[3][1]>30)this.m_rez.dis[3][1]=false;
            }
            if((this.PTerci[2][0]>0&&this.PTerci[2][0]<30&&(this.PTerci[2][0]>=this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[2][0]>=this.PTerci[1][1]||this.PTerci[1][1]>30)&&(this.PTerci[2][0]>=this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[2][0]>=this.PTerci[3][1]||this.PTerci[3][1]>30))||(this.PTerci[2][1]>0&&this.PTerci[2][1]<30&&(this.PTerci[2][1]>=this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[2][1]>=this.PTerci[1][1]||this.PTerci[1][1]>30)&&(this.PTerci[2][1]>=this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[2][1]>=this.PTerci[3][1]||this.PTerci[3][1]>30)))
            {
                if((this.PTerci[2][0]>0&&this.PTerci[2][0]<30&&(this.PTerci[2][0]>this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[2][0]>this.PTerci[1][1]||this.PTerci[1][1]>30)&&(this.PTerci[2][0]>this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[2][0]>this.PTerci[3][1]||this.PTerci[3][1]>30))||(this.PTerci[2][1]>0&&this.PTerci[2][1]<30&&(this.PTerci[2][1]>this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[2][1]>this.PTerci[1][1]||this.PTerci[1][1]>30)&&(this.PTerci[2][1]>this.PTerci[3][0]||this.PTerci[3][0]>30)&&(this.PTerci[2][1]>this.PTerci[3][1]||this.PTerci[3][1]>30)))
                {
                    if(this.PTerci[2][0]>0&&this.PTerci[2][0]<30) {
                        this.m_rez.m_rez2=this.m_rez.m_rez2+PointTerci(this.PTerci[2][0]);
                        this.m_rez.dis[2][0]=true;
                    }
                    if(this.PTerci[2][1]>0&&this.PTerci[2][1]<30) {
                        this.m_rez.m_rez2=this.m_rez.m_rez2+PointTerci(this.PTerci[2][1]);
                        this.m_rez.dis[2][1]=true;
                    }
                }
                if(this.PTerci[1][0]>0&&this.PTerci[1][0]<30)this.m_rez.dis[1][0]=false;
                if(this.PTerci[1][1]>0&&this.PTerci[1][1]<30)this.m_rez.dis[1][1]=false;
                if(this.PTerci[3][0]>0&&this.PTerci[3][0]<30)this.m_rez.dis[3][0]=false;
                if(this.PTerci[3][1]>0&&this.PTerci[3][1]<30)this.m_rez.dis[3][1]=false;
            }
            if((this.PTerci[3][0]>30&&this.PTerci[3][0]>=this.PTerci[1][0]&&this.PTerci[3][0]>=this.PTerci[1][1]&&this.PTerci[3][0]>=this.PTerci[2][0]&&this.PTerci[3][0]>=this.PTerci[2][1])||(this.PTerci[3][1]>30&&this.PTerci[3][1]>=this.PTerci[1][0]&&this.PTerci[3][1]>=this.PTerci[1][1]&&this.PTerci[3][1]>=this.PTerci[2][0]&&this.PTerci[3][1]>=this.PTerci[2][1]))
            {
                if(this.PTerci[3][0]>30) {
                    this.m_rez.m_rez3=this.m_rez.m_rez3+PointTerci(this.PTerci[3][0]);
                    this.m_rez.dis[3][0]=true;
                }
                if(this.PTerci[3][1]>30) {
                    this.m_rez.m_rez3=this.m_rez.m_rez3+PointTerci(this.PTerci[3][1]);
                    this.m_rez.dis[3][1]=true;
                }
                if(this.PTerci[1][0]>30)this.m_rez.dis[1][0]=false;
                if(this.PTerci[1][1]>30)this.m_rez.dis[1][1]=false;
                if(this.PTerci[2][0]>30)this.m_rez.dis[2][0]=false;
                if(this.PTerci[2][1]>30)this.m_rez.dis[2][1]=false;
            }
            if((this.PTerci[3][0]>0&&this.PTerci[3][0]<30&&(this.PTerci[3][0]>=this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[3][0]>=this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[3][0]>=this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[3][0]>=this.PTerci[1][1]||this.PTerci[1][1]>30))||(this.PTerci[3][1]>0&&this.PTerci[3][1]<30&&(this.PTerci[3][1]>=this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[3][1]>=this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[3][1]>=this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[3][1]>=this.PTerci[1][1]||this.PTerci[1][1]>30)))
            {
                if((this.PTerci[3][0]>0&&this.PTerci[3][0]<30&&(this.PTerci[3][0]>this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[3][0]>this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[3][0]>this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[3][0]>this.PTerci[1][1]||this.PTerci[1][1]>30))||(this.PTerci[3][1]>0&&this.PTerci[3][1]<30&&(this.PTerci[3][1]>this.PTerci[2][0]||this.PTerci[2][0]>30)&&(this.PTerci[3][1]>this.PTerci[2][1]||this.PTerci[2][1]>30)&&(this.PTerci[3][1]>this.PTerci[1][0]||this.PTerci[1][0]>30)&&(this.PTerci[3][1]>this.PTerci[1][1]||this.PTerci[1][1]>30)))
                {
                    if(this.PTerci[3][0]>0&&this.PTerci[3][0]<30) {
                        this.m_rez.m_rez3=this.m_rez.m_rez3+PointTerci(this.PTerci[3][0]);
                        this.m_rez.dis[3][0]=true;
                    }
                    if(this.PTerci[3][1]>0&&this.PTerci[3][1]<30) {
                        this.m_rez.m_rez3=this.m_rez.m_rez3+PointTerci(this.PTerci[3][1]);
                        this.m_rez.dis[3][1]=true;
                    }
                }
                if(this.PTerci[2][0]>0&&this.PTerci[2][0]<30)this.m_rez.dis[2][0]=false;
                if(this.PTerci[2][1]>0&&this.PTerci[2][1]<30)this.m_rez.dis[2][1]=false;
                if(this.PTerci[1][0]>0&&this.PTerci[1][0]<30)this.m_rez.dis[1][0]=false;
                if(this.PTerci[1][1]>0&&this.PTerci[1][1]<30)this.m_rez.dis[1][1]=false;
            }
        }
        this.m_rez.m_info="";
        if(this.Kontra==0&&this.Rekontra==0)
        {
            if(kapo1==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo2==true)
            {
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo3==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
            }
            if(this.Pkazal[1][this.Naddavane]==true)
            {
                if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                {
                    this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                {
                    this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez2+this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez1=0;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                {
                    this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez3+this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez1=0;
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    Visiashti=0;
                }
                if((this.m_rez.m_rez1==this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)||(this.m_rez.m_rez1==this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2))
                {
                	
                    Visiashti=Visiashti+(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_info+=this.IntToString(Visiashti);
                    this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_crez1=0;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                }
                if(this.m_rez.m_rez2==this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                {
                    this.m_rez.m_crez3=Visiashti/2+(this.m_rez.m_rez3+this.m_rez.m_rez1/2+5)/10;
                    this.m_rez.m_crez2=Visiashti/2+(this.m_rez.m_rez2+this.m_rez.m_rez1/2+5)/10;
                    this.m_rez.m_crez1=0;
                    Visiashti=0;
                }
            }
            if(this.Pkazal[2][this.Naddavane]==true)
            {
                if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                {
                    this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                {
                    this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_crez2=0;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                {
                    this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez3+this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_crez2=0;
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    Visiashti=0;
                }
                if((this.m_rez.m_rez2==this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)||(this.m_rez.m_rez2==this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1))
                {
                	
                    Visiashti=Visiashti+(this.m_rez.m_rez2+5)/10;
                    this.m_rez.m_info+=this.IntToString(Visiashti);
                    this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez2=0;
                    this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                }
                if(this.m_rez.m_rez1==this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                {
                    this.m_rez.m_crez3=Visiashti/2+(this.m_rez.m_rez3+this.m_rez.m_rez2/2+5)/10;
                    this.m_rez.m_crez1=Visiashti/2+(this.m_rez.m_rez1+this.m_rez.m_rez2/2+5)/10;
                    this.m_rez.m_crez2=0;
                    Visiashti=0;
                }
            }
            if(this.Pkazal[3][this.Naddavane]==true)
            {
                if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                {
                    this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez3+5)/10;
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez1>this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                {
                    this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10;
                    this.m_rez.m_crez3=0;
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    Visiashti=0;
                }
                if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                {
                    this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                    this.m_rez.m_crez3=0;
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    Visiashti=0;
                }
                if((this.m_rez.m_rez3==this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)||(this.m_rez.m_rez3==this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1))
                {
                	
                    Visiashti=Visiashti+(this.m_rez.m_rez3+5)/10;
                    this.m_rez.m_info+=this.IntToString(Visiashti);
                    this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                    this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                    this.m_rez.m_crez3=0;
                    this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                }
                if(this.m_rez.m_rez1==this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                {
                    this.m_rez.m_crez2=Visiashti/2+(this.m_rez.m_rez2+this.m_rez.m_rez3/2+5)/10;
                    this.m_rez.m_crez1=Visiashti/2+(this.m_rez.m_rez1+this.m_rez.m_rez3/2+5)/10;
                    this.m_rez.m_crez3=0;
                    Visiashti=0;
                }
            }
        }
        if(this.Kontra>0)
        {
            if(kapo1==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo2==true)
            {
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo3==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
            }
            if(this.Kontra==1)
            {
                if(this.Pkazal[2][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2==this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=2*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                }
                if(this.Pkazal[3][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez1=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        this.m_rez.m_crez3=2*((this.m_rez.m_rez3+5)/10);
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
            if(this.Kontra==2)
            {
                if(this.Pkazal[1][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                	
                    if(this.m_rez.m_rez1==this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=2*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                }
                if(this.Pkazal[3][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+2*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+2*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez3=2*((this.m_rez.m_rez3+5)/10);
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
            if(this.Kontra==3)
            {
                if(this.Pkazal[1][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez1>this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez1=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+2*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1==this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=2*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
                if(this.Pkazal[2][this.Naddavane]==true)
                {
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+2*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+2*((this.m_rez.m_rez3+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        Visiashti=Visiashti+2*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez2=2*((this.m_rez.m_rez2+5)/10);
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
        }
        if(this.Rekontra>0)
        {
            if(kapo1==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo2==true)
            {
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
                if(!kapo3)this.m_rez.m_rez3=this.m_rez.m_rez3+50;
            }
            if(kapo3==true)
            {
                if(!kapo2)this.m_rez.m_rez2=this.m_rez.m_rez2+50;
                if(!kapo1)this.m_rez.m_rez1=this.m_rez.m_rez1+50;
            }
            if(this.Rekontra==1)
            {
                if(this.lKontra==2)
                {
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2==this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=4*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                }
                if(this.lKontra==3)
                {
                    if(this.m_rez.m_rez1>this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez1=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        this.m_rez.m_crez3=4*((this.m_rez.m_rez3+5)/10);
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
            if(this.Rekontra==2)
            {
                if(this.lKontra==1)
                {
                    if(this.m_rez.m_rez2>this.m_rez.m_rez1&&this.m_rez.m_rez2>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez2=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1==this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=4*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_crez3=(this.m_rez.m_rez3+5)/10;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                }
                if(this.lKontra==3)
                {
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+4*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+4*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez3=4*((this.m_rez.m_rez3+5)/10);
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
            if(this.Rekontra==3)
            {
                if(this.lKontra==1)
                {
                    if(this.m_rez.m_rez3>this.m_rez.m_rez1&&this.m_rez.m_rez3>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez3=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        this.m_rez.m_crez1=Visiashti+4*((this.m_rez.m_rez1+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez1==this.m_rez.m_rez3&&this.m_rez.m_rez1>this.m_rez.m_rez2)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=4*((this.m_rez.m_rez1+5)/10);
                        this.m_rez.m_crez2=(this.m_rez.m_rez2+5)/10;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez1=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
                if(this.lKontra==2)
                {
                    if(this.m_rez.m_rez3>this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez3=Visiashti+4*((this.m_rez.m_rez3+this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez2=0;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez2>this.m_rez.m_rez3&&this.m_rez.m_rez2>this.m_rez.m_rez1)
                    {
                        this.m_rez.m_crez2=Visiashti+4*((this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10);
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        Visiashti=0;
                    }
                    if(this.m_rez.m_rez3==this.m_rez.m_rez2&&this.m_rez.m_rez3>this.m_rez.m_rez1)
                    {
                        Visiashti=Visiashti+4*((this.m_rez.m_rez2+5)/10);
                        this.m_rez.m_info+=this.IntToString(Visiashti);
                        this.m_rez.m_info+=" "+GetLanguageText("Drawn game");
                        this.m_rez.m_crez3=0;
                        this.m_rez.m_crez1=(this.m_rez.m_rez1+5)/10;
                        this.m_rez.m_crez2=4*((this.m_rez.m_rez2+5)/10);
                    }
                    if(this.m_rez.m_rez1>this.m_rez.m_rez2&&this.m_rez.m_rez1>this.m_rez.m_rez3)
                    {
                        this.m_rez.m_crez1=Visiashti+(this.m_rez.m_rez1+this.m_rez.m_rez2+this.m_rez.m_rez3+5)/10;
                        this.m_rez.m_crez2=0;
                        this.m_rez.m_crez3=0;
                        Visiashti=0;
                    }
                }
            }
        }
        for(let i: number =1;i<9;i++)this.m_rez.vz[i]=vz[i];
        for(let i: number =1;i<25;i++)this.m_rez.this.kd[i]=this.kd[i];
        open_rez_dlg=true;
        this.m_rez.DoModal();
        open_rez_dlg=false;
        this.online_timer=this.online_timer_time;
    }
}

void CBelot3Players::InitSP()
{
    this.SP=true;
    this.OP=false;
    Init3();
    InitGame3();
}

void CBelot3Players::InitOP(bool server)
{
    this.Server=server;
    this.OP=true;
    this.SP=false;
    Init3();
    InitGame3();
    InitTimer(20);
}




int CBelot3Players::Strelka()
{
    this.hand=this.hand+1;
    int addrez=0;
    if(this.Naddavane<5)
    {
        if(this.P1puska==14||P2puska==14||P3puska==14)addrez=addrez+2;
        if(this.P1puska==15||P2puska==15||P3puska==15)addrez=addrez+3;
        if(this.P1puska==16||P2puska==16||P3puska==16)addrez=addrez+4;
        if(this.P1puska==17||P2puska==17||P3puska==17)addrez=addrez+10;
        if(this.P1puska==18||P2puska==18||P3puska==18)addrez=addrez+11;
        if(this.P1puska==24||P2puska==24||P3puska==24)addrez=addrez+2;
        if(this.P1puska==25||P2puska==25||P3puska==25)addrez=addrez+3;
        if(this.P1puska==26||P2puska==26||P3puska==26)addrez=addrez+4;
        if(this.P1puska==27||P2puska==27||P3puska==27)addrez=addrez+10;
        if(this.P1puska==28||P2puska==28||P3puska==28)addrez=addrez+11;
        if(this.P1puska==34||P2puska==34||P3puska==34)addrez=addrez+2;
        if(this.P1puska==35||P2puska==35||P3puska==35)addrez=addrez+3;
        if(this.P1puska==36||P2puska==36||P3puska==36)addrez=addrez+4;
        if(this.P1puska==37||P2puska==37||P3puska==37)addrez=addrez+10;
        if(this.P1puska==38||P2puska==38||P3puska==38)addrez=addrez+11;
        if(this.P1puska==44||P2puska==44||P3puska==44)addrez=addrez+2;
        if(this.P1puska==45||P2puska==45||P3puska==45)addrez=addrez+3;
        if(this.P1puska==46||P2puska==46||P3puska==46)addrez=addrez+4;
        if(this.P1puska==47||P2puska==47||P3puska==47)addrez=addrez+10;
        if(this.P1puska==48||P2puska==48||P3puska==48)addrez=addrez+11;
        if(this.P1puska==103||P2puska==103||P3puska==103)addrez=addrez+3;
        if(this.P1puska==104||P2puska==104||P3puska==104)addrez=addrez+4;
        if(this.P1puska==105||P2puska==105||P3puska==105)addrez=addrez+10;
        if(this.P1puska==106||P2puska==106||P3puska==106)addrez=addrez+11;
        if(this.P1puska==107||P2puska==107||P3puska==107)addrez=addrez+14;
        if(this.P1puska==108||P2puska==108||P3puska==108)addrez=addrez+20;
        if(this.hand==8)addrez=addrez+10;
    }
    if(this.Naddavane==5)
    {
    	
        if(this.P1puska==14||P2puska==14||P3puska==14)addrez=addrez+4;
        if(this.P1puska==15||P2puska==15||P3puska==15)addrez=addrez+6;
        if(this.P1puska==16||P2puska==16||P3puska==16)addrez=addrez+8;
        if(this.P1puska==17||P2puska==17||P3puska==17)addrez=addrez+20;
        if(this.P1puska==18||P2puska==18||P3puska==18)addrez=addrez+22;
        if(this.P1puska==24||P2puska==24||P3puska==24)addrez=addrez+4;
        if(this.P1puska==25||P2puska==25||P3puska==25)addrez=addrez+6;
        if(this.P1puska==26||P2puska==26||P3puska==26)addrez=addrez+8;
        if(this.P1puska==27||P2puska==27||P3puska==27)addrez=addrez+20;
        if(this.P1puska==28||P2puska==28||P3puska==28)addrez=addrez+22;
        if(this.P1puska==34||P2puska==34||P3puska==34)addrez=addrez+4;
        if(this.P1puska==35||P2puska==35||P3puska==35)addrez=addrez+6;
        if(this.P1puska==36||P2puska==36||P3puska==36)addrez=addrez+8;
        if(this.P1puska==37||P2puska==37||P3puska==37)addrez=addrez+20;
        if(this.P1puska==38||P2puska==38||P3puska==38)addrez=addrez+22;
        if(this.P1puska==44||P2puska==44||P3puska==44)addrez=addrez+4;
        if(this.P1puska==45||P2puska==45||P3puska==45)addrez=addrez+6;
        if(this.P1puska==46||P2puska==46||P3puska==46)addrez=addrez+8;
        if(this.P1puska==47||P2puska==47||P3puska==47)addrez=addrez+20;
        if(this.P1puska==48||P2puska==48||P3puska==48)addrez=addrez+22;
        if(this.hand==8)addrez=addrez+20;
    }
    if(this.Naddavane==6)
    {
        if(this.P1puska==13||P2puska==13||P3puska==13)addrez=addrez+3;
        if(this.P1puska==14||P2puska==14||P3puska==14)addrez=addrez+4;
        if(this.P1puska==15||P2puska==15||P3puska==15)addrez=addrez+10;
        if(this.P1puska==16||P2puska==16||P3puska==16)addrez=addrez+11;
        if(this.P1puska==17||P2puska==17||P3puska==17)addrez=addrez+14;
        if(this.P1puska==18||P2puska==18||P3puska==18)addrez=addrez+20;
        if(this.P1puska==23||P2puska==23||P3puska==23)addrez=addrez+3;
        if(this.P1puska==24||P2puska==24||P3puska==24)addrez=addrez+4;
        if(this.P1puska==25||P2puska==25||P3puska==25)addrez=addrez+10;
        if(this.P1puska==26||P2puska==26||P3puska==26)addrez=addrez+11;
        if(this.P1puska==27||P2puska==27||P3puska==27)addrez=addrez+14;
        if(this.P1puska==28||P2puska==28||P3puska==28)addrez=addrez+20;
        if(this.P1puska==33||P2puska==33||P3puska==33)addrez=addrez+3;
        if(this.P1puska==34||P2puska==34||P3puska==34)addrez=addrez+4;
        if(this.P1puska==35||P2puska==35||P3puska==35)addrez=addrez+10;
        if(this.P1puska==36||P2puska==36||P3puska==36)addrez=addrez+11;
        if(this.P1puska==37||P2puska==37||P3puska==37)addrez=addrez+14;
        if(this.P1puska==38||P2puska==38||P3puska==38)addrez=addrez+20;
        if(this.P1puska==43||P2puska==43||P3puska==43)addrez=addrez+3;
        if(this.P1puska==44||P2puska==44||P3puska==44)addrez=addrez+4;
        if(this.P1puska==45||P2puska==45||P3puska==45)addrez=addrez+10;
        if(this.P1puska==46||P2puska==46||P3puska==46)addrez=addrez+11;
        if(this.P1puska==47||P2puska==47||P3puska==47)addrez=addrez+14;
        if(this.P1puska==48||P2puska==48||P3puska==48)addrez=addrez+20;
        if(this.hand==8)addrez=addrez+10;
    }
    if(this.P1puska>100||P2puska>100||P3puska>100)
    {
        if(this.P1puska>P2puska&&this.P1puska>P3puska)
        {
            this.m_rez.m_rez1=this.m_rez.m_rez1+addrez;
            return 1;
        }
        if(P2puska>this.P1puska&&P2puska>P3puska)
        {
            this.m_rez.m_rez2=this.m_rez.m_rez2+addrez;
            return 2;
        }
        if(P3puska>P2puska&&P3puska>this.P1puska)
        {
            this.m_rez.m_rez3=this.m_rez.m_rez3+addrez;
            return 3;
        }
    }
    //Ako  se iska pika
    if(this.ParviPuska>40&&this.ParviPuska<50)
    {
        for(let i: number =48;i>40;i--)
        {
            if(this.P1puska==i)
            {
                this.m_rez.m_rez1=this.m_rez.m_rez1+addrez;
                return 1;
            }
            if(P2puska==i)
            {
                this.m_rez.m_rez2=this.m_rez.m_rez2+addrez;
                return 2;
            }
            if(P3puska==i)
            {
                this.m_rez.m_rez3=this.m_rez.m_rez3+addrez;
                return 3;
            }
        }
    }
    //Ako se iska kupa
    if(this.ParviPuska>30&&this.ParviPuska<40)
    {
        for(let i: number =38;i>30;i--)
        {
            if(this.P1puska==i)
            {
                this.m_rez.m_rez1=this.m_rez.m_rez1+addrez;
                return 1;
            }
            if(P2puska==i)
            {
                this.m_rez.m_rez2=this.m_rez.m_rez2+addrez;
                return 2;
            }
            if(P3puska==i)
            {
                this.m_rez.m_rez3=this.m_rez.m_rez3+addrez;
                return 3;
            }
        }
    }
    //Ako se iska karo
    if(this.ParviPuska>20&&this.ParviPuska<30)
    {
        for(let i: number =28;i>20;i--)
        {
            if(this.P1puska==i)
            {
                this.m_rez.m_rez1=this.m_rez.m_rez1+addrez;
                return 1;
            }
            if(P2puska==i)
            {
                this.m_rez.m_rez2=this.m_rez.m_rez2+addrez;
                return 2;
            }
            if(P3puska==i)
            {
                this.m_rez.m_rez3=this.m_rez.m_rez3+addrez;
                return 3;
            }
        }
    }
    //Ako se iska spatia
    if(this.ParviPuska>10&&this.ParviPuska<20)
    {
        for(let i: number =18;i>10;i--)
        {
            if(this.P1puska==i)
            {
                this.m_rez.m_rez1=this.m_rez.m_rez1+addrez;
                return 1;
            }
            if(P2puska==i)
            {
                this.m_rez.m_rez2=this.m_rez.m_rez2+addrez;
                return 2;
            }
            if(P3puska==i)
            {
                this.m_rez.m_rez3=this.m_rez.m_rez3+addrez;
                return 3;
            }
        }
    }
    return 1;
}

void CBelot3Players::SortCards(int type)
{
    int sort[25],temps,tempk;
    CPicture*tempc;
    if(type==0)
    {
        for(let i: number =1;i<6;i++)
        {
            for(let a: number =1;a<5;a++)
            {
                if(this.k[a]>this.k[a+1])
                {
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
        for(let i: number =9;i<14;i++)
        {
            for(let a: number =9;a<13;a++)
            {
                if(this.k[a]>this.k[a+1])
                {
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
        for(let i: number =17;i<22;i++)
        {
            for(let a: number =17;a<21;a++)
            {
                if(this.k[a]>this.k[a+1])
                {
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
    }
    if(type==1)
    {
        for(let i: number =1;i<9;i++)
        {
            this.k[i]=ReturnSortCards(this.k[i]);
            sort[i]=this.k[i];
        }
        for(let i: number =1;i<9;i++)
        {
            for(let a: number =1;a<8;a++)
            {
                if(sort[a]>sort[a+1])
                {
                    temps=sort[a+1];
                    sort[a+1]=sort[a];
                    sort[a]=temps;
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
        for(let i: number =9;i<17;i++)
        {
            this.k[i]=ReturnSortCards(this.k[i]);
            sort[i]=this.k[i];
        }
        for(let i: number =9;i<17;i++)
        {
            for(let a: number =9;a<16;a++)
            {
                if(sort[a]>sort[a+1])
                {
                    temps=sort[a+1];
                    sort[a+1]=sort[a];
                    sort[a]=temps;
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
        for(let i: number =17;i<25;i++)
        {
            this.k[i]=ReturnSortCards(this.k[i]);
            sort[i]=this.k[i];
        }
        for(let i: number =17;i<25;i++)
        {
            for(let a: number =17;a<24;a++)
            {
                if(sort[a]>sort[a+1])
                {
                    temps=sort[a+1];
                    sort[a+1]=sort[a];
                    sort[a]=temps;
                    tempk=this.k[a+1];
                    this.k[a+1]=this.k[a];
                    this.k[a]=tempk;
                    tempc=this.CardP[a+1];
                    this.CardP[a+1]=this.CardP[a];
                    this.CardP[a]=tempc;
                }
            }
        }
    }
//	tempc->DeleteObject();
}

void CBelot3Players::SendResult(int player)
{
    if(player==1)
    {
        for(let a: number =0;a<29;a++)
        {
            if(m_orez.orez[0][a+1]==-10)
            {
                if(m_orez.orez[0][a]>-10)
                {
                    CString message="$|u|"+this.IntToString(player)+"|"+this.IntToString(m_orez.orez[0][a])+"|"+this.IntToString(m_orez.orez[1][a])+"|"+this.IntToString(m_orez.orez[2][a]);
                    this.SendGame(message);
                }
                else {
                    CString message="$|u|"+this.IntToString(player)+"|0|0|0";
                    this.SendGame(message);
                }
                goto end;
            }
        }
    }
    else {
        if(player==2)
        {
            for(let a: number =0;a<30;a++)
            {
                if(m_orez.orez[0][a]>-10)
                {
                    CString message="$|u|"+this.IntToString(player)+"|"+this.IntToString(m_orez.orez[1][a])+"|"+this.IntToString(m_orez.orez[2][a])+"|"+this.IntToString(m_orez.orez[0][a]);
                    this.SendGame(message);
                }
            }
        }
        if(player==3)
        {
            for(let a: number =0;a<30;a++)
            {
                if(m_orez.orez[0][a]>-10)
                {
                    CString message="$|u|"+this.IntToString(player)+"|"+this.IntToString(m_orez.orez[2][a])+"|"+this.IntToString(m_orez.orez[0][a])+"|"+this.IntToString(m_orez.orez[1][a]);
                    this.SendGame(message);
                }
            }
        }
        this.SendGame("$|*|b|"+this.IntToString(Visiashti));
    }
end:;
}

void CBelot3Players::SendCards()
{
    CString p2;
    int a;
    if(this.P2OP)
    {
        P2G=false;
        for(a=9;a<25;a++)
        {
            this.SendGame("$|c|"+this.IntToString(this.k[a]));
        }
        for(a=1;a<9;a++)
        {	
            this.SendGame("$|c|"+this.IntToString(this.k[a]));
        }
    }
    if(P3OP)
    {
        P3G=false;
        for(a=17;a<25;a++)
        {
            this.SendGame("$|f|"+this.IntToString(this.k[a]));
        }
        for(a=1;a<17;a++)
        {
            this.SendGame("$|f|"+this.IntToString(this.k[a]));
        }
    }
    if(HaveWatch) {
        for(a=1;a<25;a++)
        {
            this.SendGame("$|j|"+this.IntToString(this.k[a]));
        }
    }
}

bool CBelot3Players::ReceiveGame(CString f)
{
	
    CString strRecvd;
	
    if(f=="a")//Poluchavane na imenata client
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString name=m_pwin->ReceiveString(12);
        for(let i: number =2;i<4;i++)
        {
            if(this.Name[i]=="")
            {
                if(name!="Computer2"&&name!="Computer3")this.Name[i]=name;
                else {
                    if(i==2)
                    {
                        this.Name[2]="Computer2";
                        m_pwin->InvalidateStolove(3);
                    }
                    if(i==3)this.Name[3]="Computer3";
                }
                m_orez.Player[i]=this.Name[i];
                m_pwin->GetPlayerRating(i);
                goto end;
            }
        }
        goto end;
    }
    if(f=="b")//Poluchavane na koi zapochva client
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        if(Zvuk[0]==1)m_sndSound.Play(1112,"center", 0,0);
        InitGame3();
        if(this.watch) {
            m_pwin->Invalidate(true);
        }
        CString koj=m_pwin->ReceiveString(1);
        if(koj=="1"||koj=="2"||koj=="3") {
            KoiZapochva=this.KoiPuskaParvi=KoiObiaviava=this.KoiENaRed=_ttoi(koj);
        }
        goto end;
    }
    if(f=="c")//Poluchavane na kartite client
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString rstr=m_pwin->ReceiveString(2);
        for(let i: number =1;i<25;i++)
        {
            if(this.k[i]<1)
            {
                if(i==1) {
                    if(CheckMessage()) {
                        ClearMessages();
                    }
                    if(CheckAMessage()) {
                        ClearAMessages();
                    }
                }
                this.k[i]=_ttoi(rstr);
                if(i==24)
                {
                    DrawNames(&dc);
                    LoadCards(false, true);
                    Razdavane1=true;
                    Razdavane2=false;
                    this.online_timer=this.online_timer_time;
                }
                goto end;
            }
        }
        goto end;
    }
    if(f=="d")//Poluchavane na anons
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        if(KoiObiaviava==1) {
            m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
        }
        if(KoiObiaviava==2) {
            m_pwin->InvalidateRectScale(CRect(PosNaddavane[1].x,PosNaddavane[1].y,PosNaddavane[1].x+150,PosNaddavane[3].y+50),false);
        }
        if(KoiObiaviava==3) {
            m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
        }
        CString annons=m_pwin->ReceiveString(1);
        if(annons=="0"||annons=="1"||annons=="2"||annons=="3"||annons=="4"||annons=="5"||annons=="6"||annons=="7"||annons=="8") {
            WriteAMessage(annons);
            Timer2();
        }
        else {
            m_pwin->SendErrorToLog("Nevaliden Anons: "+annons);
        }
        goto end;
    }
    if(f=="e")//Poluchavane na Visiashti tochki
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString vs=m_pwin->ReceiveString(4);
        Visiashti=_ttoi(vs);
        goto end;
    }
    if(f=="f")//Poluchavane na karta
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        if(pause_player!="") {
            pause_player="";
            m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        }
        CString card=m_pwin->ReceiveString(1);
        if(card=="1"||card=="2"||card=="3"||card=="4"||card=="5"||card=="6"||card=="7"||card=="8") {
            WriteMessage(card);
            Timer3();
        }
        else {
            m_pwin->SendErrorToLog("Poluchavane na greshna karta: "+card);
        }
        goto end;
    }
    if(f=="g")//Razpadane na vrazkata s niakoi ot igrachite
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(12);
        if(m_pwin->m_d432.Dlg==true)m_pwin->m_d432.DisconnectPlayer(strRecvd);
        else
        {
        	
            CString name=strRecvd;
            this.online_timer=this.online_timer_time;
            if(pause_player!=""&&pause_player==name) {
                pause_player="";
                m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
            }
            if(this.Server) {
                if(name==this.Name[2]) {
                    this.P2OP=false;
                    P2G=true;
                    this.Name[2]="Computer2";
                    m_pwin->InvalidateStolove(3);
                    bool ss=show_user[2];
                    show_user[2]=false;
                    m_pwin->GetPlayerRating(2);
                    InvalidateNames(2);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[2].m_x-6,users[2].m_y-6,users[2].m_x+106,users[2].m_y+126), true);
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);				
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+" "+GetLanguageText("has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==this.Name[3]) {
                    P3OP=false;
                    P3G=true;
                    this.Name[3]="Computer3";
                    bool ss=show_user[3];
                    show_user[3]=false;
                    m_pwin->GetPlayerRating(3);
                    InvalidateNames(3);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[3].m_x-6,users[3].m_y-6,users[3].m_x+106,users[3].m_y+126), true);
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+" "+GetLanguageText("has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==wait_opnames[2]) {
                    wait_opnames[2]="";
                    wait_opplayers[2]=false;
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+" "+GetLanguageText("has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
                if(name==wait_opnames[3]) {
                    wait_opnames[3]="";
                    wait_opplayers[3]=false;
                    m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
                    COK m_ok=new COK();
                    m_ok.SetTitle(name+" "+GetLanguageText("has left the game"));
                    m_ok.DoModal();
                    goto end;
                }
            }
            else {
                if(name==this.Name[2]) {
                    this.Name[2]="Computer2";
                    m_pwin->InvalidateStolove(3);
                    bool ss=show_user[2];
                    show_user[2]=false;
                    m_pwin->GetPlayerRating(2);
                    InvalidateNames(2);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[2].m_x-6,users[2].m_y-6,users[2].m_x+106,users[2].m_y+126), true);
                }
                if(name==this.Name[3]) {
                    this.Name[3]="Computer3";
                    bool ss=show_user[3];
                    show_user[3]=false;
                    m_pwin->GetPlayerRating(3);
                    InvalidateNames(3);
                    if(ss)m_pwin->InvalidateRectScale(CRect(users[3].m_x-6,users[3].m_y-6,users[3].m_x+106,users[3].m_y+126), true);
                }
                COK m_ok=new COK();
                m_ok.SetTitle(name+" "+GetLanguageText("has left the game"));
                m_ok.DoModal();
                goto end;
            }
        }
        goto end;
    }
    if(f=="h")
    {
        P2G=true;
        goto end;
    }
    if(f=="i")
    {
        P3G=true;
        goto end;
    }
    if(f=="j")
    {
        goto end;
    }
    if(f=="k")
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd=="2")this.P2OP=false;
        if(strRecvd=="3")P3OP=false;

        if(strRecvd=="5")this.P2OP=true;
        if(strRecvd=="6")P3OP=true;
        goto end;
    }
    if(f=="l")
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd=="1") {
            this.Server=true;
            m_pwin->InitIconsTooltips();
        }
        if(strRecvd=="0")this.Server=false;
        m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
        goto end;
    }
    if(f=="m")
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        this.op_belot[_ttoi(strRecvd)]=true;
        goto end;
    }
    if(f=="n")
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<2) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        CString player=m_pwin->ReceiveString(1);
        this.terci[_ttoi(player)][_ttoi(strRecvd)]=true;
        goto end;
    }
    if(f=="o")
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        strRecvd=m_pwin->ReceiveString(1);
        if(strRecvd=="2") {
            P2G=false;
            if(this.Faza==6)P2G=true;
        }
        if(strRecvd=="3") {
            P3G=false;
            if(this.Faza==6)P3G=true;
        }
        if(strRecvd=="5")P2G=true;
        if(strRecvd=="6")P3G=true;
        goto end;
    }
    if(f=="p")//Prisaediniavane na Igrach po vreme na igra this.Server
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<3) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        this.online_timer=this.online_timer_time;
        CString player_name=m_pwin->ReceiveString(12);
        CString pistr=m_pwin->ReceiveString(1);
        CString rating = m_pwin->ReceiveString(30);
        if(!m_pwin->m_main->ExistInIgnoreList(player_name)) {	
            int player_index=_ttoi(pistr);
            if(this.watch) {
                this.Name[player_index]=player_name;
                show_user[player_index]=false;
                m_pwin->GetPlayerRating(player_index);
                InvalidateNames(player_index);
                goto end;
            }
            CString message=GetLanguageText("Would you like to replace the computer")+" ( "+this.Name[player_index]+" )"+" "+GetLanguageText("with")+" "+GetLanguageText("user")+" "+player_name+" / "+GetLanguageText("Rating")+": " + rating + "?";
            if(last_Name[player_index]==player_name || m_pwin->isNoVipPlayer(this.Name[1]) || m_pwin->IsGuestUser(this.Name[1])) {
                COK m_ok=new COK();
                m_ok.SetTitle(message);
                m_ok.DoModal();
                if(player_index==2)this.P2OP=false;
                if(player_index==3)P3OP=false;
                CString message="$|s|"+pistr+"|1";
                this.SendGame(message);
                wait_opplayers[player_index]=true;
                wait_opnames[player_index]=player_name;
                m_pwin->GetPlayerParameters(player_name);
                SendNames(player_index);
                goto end;
            }
            CYESNO m_yesno=new CYESNO();
            m_yesno.SetTitle(message);
            if(m_yesno.DoModal()==IDOK) {
                if(player_index==2)this.P2OP=false;
                if(player_index==3)P3OP=false;
                CString message="$|s|"+pistr+"|1";
                this.SendGame(message);
                wait_opplayers[player_index]=true;
                wait_opnames[player_index]=player_name;
                m_pwin->GetPlayerParameters(player_name);
                SendNames(player_index);
            }
            else {
                CString message="$|s|"+pistr+"|0";
                this.SendGame(message);
                if(m_pwin->m_main->last_refused_user==player_name)
                {
                    CYESNO m_yesno2=new CYESNO();
                    CString title=GetLanguageText("Do you want to add (username) to your ignore list");
                    title.Replace("(username)",player_name);
                    m_yesno2.SetTitle(title+"?");
                    if(m_yesno2.DoModal()==IDOK) {
                        m_pwin->AddToIgnoreList(player_name);
                    }
                }
                m_pwin->m_main->last_refused_user=player_name;
            }
        }
        else {
            CString message="$|x|"+player_name;
            this.SendGame(message);
            m_pwin->pFrame->m_chat_bar.AddMessage(GetLanguageText("user")+" "+player_name+" "+GetLanguageText("is in the Ignore List")+"!");
        }
        goto end;
    }
    if(f=="q")//Prisaediniavane na Igrach po vreme na igra
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString ok=m_pwin->ReceiveString(1);
        if(ok=="1") {
            COK m_ok=new COK();
            m_ok.SetTitle(GetLanguageText("Please wait for the next deal"));
            m_ok.DoModal();
            InvalidateNames(0);
        }
        if(ok=="0") {
            m_pwin->game="";
            m_pwin->ShowChatBar(false, false);
            m_pwin->MessageBox(GetLanguageText("This user refused to join this play")+"!");
            m_pwin->GoToLobby();
        }
        if(ok=="2") {
            m_pwin->game="";
            m_pwin->ShowChatBar(false, false);
            m_pwin->MessageBox(GetLanguageText("You can't connect to this user"));
            m_pwin->GoToLobby();
        }
        goto end;
    }
    if(f=="r")//Poluchavane na rezultat ot WaitConnecting
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<3) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString rez1=m_pwin->ReceiveString(5);
        CString rez2=m_pwin->ReceiveString(5);
        CString rez3=m_pwin->ReceiveString(5);
        if(this.watch) {
            if(rez1=="0"&&rez2=="0"&&rez3=="0") {
                m_orez.m_rez1=0;m_orez.m_rez2=0;m_orez.m_rez3=0;
                Visiashti=0;
                for(let i: number =0;i<3;i++)
                {
                    for(let a: number =0;a<100;a++)
                    {
                        m_orez.orez[i][a]=-10;
                    }
                }
                InvalidatePoints();
                goto end;
            }
            else {
                for(let a: number =0;a<30;a++)
                {
                    if(m_orez.orez[0][a]==-10)
                    {
                        m_orez.orez[0][a]=_ttoi(rez1);
                        m_orez.orez[1][a]=_ttoi(rez2);
                        m_orez.orez[2][a]=_ttoi(rez3);
                        m_orez.m_rez1=m_orez.m_rez1+m_orez.orez[0][a];
                        m_orez.m_rez2=m_orez.m_rez2+m_orez.orez[1][a];
                        m_orez.m_rez3=m_orez.m_rez3+m_orez.orez[2][a];
                        InvalidatePoints();
                        goto end;
                    }
                }
            }
        }
        else {
            for(let a: number =0;a<30;a++)
            {
                if(m_orez.orez[0][a]==-10)
                {
                    m_orez.orez[0][a]=_ttoi(rez1);
                    m_orez.orez[1][a]=_ttoi(rez2);
                    m_orez.orez[2][a]=_ttoi(rez3);
                    m_orez.m_rez1=m_orez.m_rez1+m_orez.orez[0][a];
                    m_orez.m_rez2=m_orez.m_rez2+m_orez.orez[1][a];
                    m_orez.m_rez3=m_orez.m_rez3+m_orez.orez[2][a];
                    InvalidatePoints();
                    goto end;
                }
            }
        }
        goto end;
    }
    if(f=="s")//Promiana na Imeto na igrach
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<2) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString pistr=m_pwin->ReceiveString(1);
        int player_index=_ttoi(pistr);
        CString player_name=m_pwin->ReceiveString(12);
        this.Name[player_index]=player_name;
        m_pwin->GetPlayerRating(player_index);
        InvalidateNames(player_index);
        goto end;
    }
    if(f=="t")//Ignoriran igrach
    {
        m_pwin->game="";
        m_pwin->ShowChatBar(false, false);
        goto end;
    }
    if(f=="u")//SendWatchGameInitialization
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        HaveWatch=true;
        CString windex=m_pwin->ReceiveString(3);
        SendWatchGameInitialization(_ttoi(windex));
        goto end;
    }
    if(f=="v")//ReceiveWachGameInitialization
    {
        int br_=0;
        for(let i: number =0;i<m_pwin->m_pClientSocket.m_strRecv.GetLength();i++)
        {
            if(m_pwin->m_main->Decrypt(m_pwin->m_pClientSocket.m_strRecv,m_pwin->m_pClientSocket.encryptKey).GetAt(i)=='|')br_=br_+1;
        }
        if(br_<1) {
            m_pwin->m_pClientSocket.RestoreMessage("$|"+f+"|");
            return false;
        }
        CString initstr=m_pwin->ReceiveString(500);
        ReceiveWachGameInitialization(initstr);
        goto end;
    }
    m_pwin->SendErrorToLog("Izpusnato e saobshtenie!b3 : "+f);
    end:;
    return true;
}

void CBelot3Players::ReceiveWachGameInitialization(CString init)
{
    OnLButtonDown(CPoint(0,0));
    InitGame3();
    int brrez=0;
    int kojkazal=1;
    int vpos=init.Find("?");
    CString n1=init.Left(vpos);
    if(n1!="")this.Name[1]=n1;
    init.Delete(0,vpos+1);
    vpos=init.Find("?");
    this.Name[2]=init.Left(vpos);
    init.Delete(0,vpos+1);
    vpos=init.Find("?");
    this.Name[3]=init.Left(vpos);
    init.Delete(0,vpos+1);
    vpos=init.Find("?");
    CString s1=init.Left(vpos);
    this.Faza=_ttoi(s1);
    if(this.Faza<0) {
        goto end;
    }
    init.Delete(0,vpos+1);
    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.Naddavane=_ttoi(s1);
    init.Delete(0,vpos+1);
	
    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.hand=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.KoiENaRed=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.KoiPuskaParvi=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    KoiZapochva=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.P1puska=_ttoi(s1);
    if(this.P1puska>0) {
        ConvertCard(this.P1puska,33);
        this.CardsPoint[33]=this.CardsPos[33];
    }
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    P2puska=_ttoi(s1);
    if(P2puska>0) {
        ConvertCard(P2puska,34);
        this.CardsPoint[34]=this.CardsPos[34];
    }
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    P3puska=_ttoi(s1);
    if(P3puska>0) {
        ConvertCard(P3puska,35);
        this.CardsPoint[35]=this.CardsPos[35];
    }
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.ParviPuska=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.VtoriPuska=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.TretiPuska=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    strelka=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    KoiObiaviava=_ttoi(s1);
    init.Delete(0,vpos+1);
    if(KoiObiaviava==0)KoiObiaviava=1;

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.BrPas=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    Mcard=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.Kontra=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    this.Rekontra=_ttoi(s1);
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    kojkazal=_ttoi(s1);
    init.Delete(0,vpos+1);

    if(this.Naddavane>0&&this.Naddavane<7)
    {
    	
        if(GetLanguage()=="Turkish") 
        {	
            SetTitle(this.Name[kojkazal]+" "+GetLanguageText("declared by")+" "+this.GameName(this.Naddavane),Title2);
        }
        else {
            SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("declared by")+" "+this.Name[kojkazal],Title2);
        }
    }
    if(this.Kontra>0)
    {
        if(GetLanguage()=="Turkish") 
        {
            SetTitle(this.Name[kojkazal]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
        }
        else 
        {
            SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[kojkazal],Title2);
        }
    }
    if(this.Rekontra>0)
    {
        if(GetLanguage()=="Turkish") 
        {
            SetTitle(this.Name[kojkazal]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
        }
        else 
        {
            SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[kojkazal],Title2);
        }
    }
    vpos=init.Find("?");
    s1=init.Left(vpos);
    if(s1=="1") {
        Razdavane1=true;
        if(Zvuk[0]==1 && this.Faza<=4)m_sndSound.PlayFile("deal",false);
    }
    else Razdavane1=false;
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    if(s1=="1") {
        Razdavane2=true;
        if(Zvuk[0]==1 && this.Faza<=4)m_sndSound.PlayFile("deal",false);
    }
    else Razdavane2=false;
    init.Delete(0,vpos+1);

    vpos=init.Find("?");
    s1=init.Left(vpos);
    if(s1=="1")this.Move=true;
    else this.Move=false;
    init.Delete(0,vpos+1);

    for(let i: number =1;i<25;i++) {
        int vpos=init.Find("?");
        CString s1=init.Left(vpos);
        this.k[i]=_ttoi(s1);
        init.Delete(0,vpos+1);

        vpos=init.Find("?");
        s1=init.Left(vpos);
        this.CardsPoint[i].x=_ttoi(s1);
        init.Delete(0,vpos+1);

        vpos=init.Find("?");
        s1=init.Left(vpos);
        this.CardsPoint[i].y=_ttoi(s1);
        init.Delete(0,vpos+1);
    }

    vpos=init.Find("?");
    s1=init.Left(vpos);
    brrez=_ttoi(s1);
    init.Delete(0,vpos+1);

    if(brrez>0) 
    {
        for(let a: number =0;a<brrez;a++)
        {

            vpos=init.Find("?");
            s1=init.Left(vpos);
            int rez1=_ttoi(s1);
            init.Delete(0,vpos+1);

            vpos=init.Find("?");
            s1=init.Left(vpos);
            int rez2=_ttoi(s1);
            init.Delete(0,vpos+1);

            vpos=init.Find("?");
            s1=init.Left(vpos);
            int rez3=_ttoi(s1);
            init.Delete(0,vpos+1);

            m_orez.orez[0][a]=rez1;
            m_orez.orez[1][a]=rez2;
            m_orez.orez[2][a]=rez3;
        }
    }
    m_orez.m_rez1=0;
    m_orez.m_rez2=0;
    m_orez.m_rez3=0;
    for(let a: number =0;a<brrez;a++)
    {
        m_orez.m_rez1=m_orez.m_rez1+m_orez.orez[0][a];
        m_orez.m_rez2=m_orez.m_rez2+m_orez.orez[1][a];
        m_orez.m_rez3=m_orez.m_rez3+m_orez.orez[2][a];
    }
    if(this.Faza>4)LoadCards(true, false);
    else LoadCards(false, false);
    if((Razdavane1||Razdavane2)&&Mcard>0&&this.Move) {
        this.BeginMove(this.CardsPoint[Mcard],this.CardsPos[Mcard],Mcard);
    }
    else {
        if(this.Move) {
            if(Mcard>0) {
                this.BeginMove(this.CardsPoint[Mcard],this.CardsPos[Mcard],Mcard);
            }
        }
    }

end:;
    m_pwin->GetPlayerRating(1);
    m_pwin->GetPlayerRating(2);
    m_pwin->GetPlayerRating(3);
    m_pwin->Invalidate(true);
}

void CBelot3Players::SendWatchGameInitialization(int watch_index)
{
    CString message="$|z|"+this.IntToString(watch_index)+"|";
    message=message+this.Name[1]+"?"+this.Name[2]+"?"+this.Name[3]+"?";
    //this.Faza
    message=message+this.IntToString(this.Faza)+"?";
    message=message+this.IntToString(this.Naddavane)+"?";
    message=message+this.IntToString(this.hand)+"?";
    message=message+this.IntToString(this.KoiENaRed)+"?";
    message=message+this.IntToString(this.KoiPuskaParvi)+"?";
    message=message+this.IntToString(KoiZapochva)+"?";
    message=message+this.IntToString(this.P1puska)+"?";
    message=message+this.IntToString(P2puska)+"?";
    message=message+this.IntToString(P3puska)+"?";

    message=message+this.IntToString(this.ParviPuska)+"?";
    message=message+this.IntToString(this.VtoriPuska)+"?";
    message=message+this.IntToString(this.TretiPuska)+"?";
    message=message+this.IntToString(strelka)+"?";
    message=message+this.IntToString(KoiObiaviava)+"?";
    message=message+this.IntToString(this.BrPas)+"?";
    message=message+this.IntToString(Mcard)+"?";	
    message=message+this.IntToString(this.Kontra)+"?";
    message=message+this.IntToString(this.Rekontra)+"?";
    CString kojkazal="1";
    if(this.Naddavane>0&&this.Naddavane<7)
    {
        if(this.Pkazal[2][this.Naddavane])kojkazal="2";
        if(this.Pkazal[3][this.Naddavane])kojkazal="3";
    }
    if(this.Naddavane==7)
    {
        kojkazal=this.IntToString(this.Kontra);
    }
    if(this.Naddavane==8)
    {
        kojkazal=this.IntToString(this.Rekontra);
    }
    message=message+kojkazal+"?";

    if(Razdavane1) {
        message=message+"1?";
    }
    else {
        message=message+"0?";
    }

    if(Razdavane2) {
        message=message+"1?";
    }
    else {
        message=message+"0?";
    }
    if(this.Move) {
        message=message+"1?";
    }
    else {
        message=message+"0?";
    }
    for(let i: number =1;i<25;i++)
    {
        message=message+this.IntToString(this.k[i])+"?"+this.IntToString(this.CardsPoint[i].x)+"?"+this.IntToString(this.CardsPoint[i].y)+"?";
    }
    CString brrez="0";
    CString mrez="";
    for(let a: number =0;a<30;a++)
    {
        if(m_orez.orez[0][a]>-10)
        {
            brrez=this.IntToString(a+1);
            mrez=mrez+this.IntToString(m_orez.orez[0][a])+"?";
            mrez=mrez+this.IntToString(m_orez.orez[1][a])+"?";
            mrez=mrez+this.IntToString(m_orez.orez[2][a])+"?";
        	
        }
        else goto next;
    }
next:;
    message=message+brrez+"?"+mrez+"?";
    if(message!="") {
        this.SendGame(message);
    }
}

int CBelot3Players::ReturnSortCards(int card)
{
    if(this.Naddavane==1)
    {
        if(card==13)return 107;if(card==14)return 105;if(card==15)return 108;if(card==16)return 103;if(card==17)return 104;if(card==18)return 106;
        if(card==23)return 23;if(card==24)return 27;if(card==25)return 24;if(card==26)return 25;if(card==27)return 26;if(card==28)return 28;
        if(card==33)return 33;if(card==34)return 37;if(card==35)return 34;if(card==36)return 35;if(card==37)return 36;if(card==38)return 38;
        if(card==43)return 43;if(card==44)return 47;if(card==45)return 44;if(card==46)return 45;if(card==47)return 46;if(card==48)return 48;
    }
    if(this.Naddavane==2)
    {
        if(card==13)return 13;if(card==14)return 17;if(card==15)return 14;if(card==16)return 15;if(card==17)return 16;if(card==18)return 18;
        if(card==23)return 107;if(card==24)return 105;if(card==25)return 108;if(card==26)return 103;if(card==27)return 104;if(card==28)return 106;
        if(card==33)return 33;if(card==34)return 37;if(card==35)return 34;if(card==36)return 35;if(card==37)return 36;if(card==38)return 38;
        if(card==43)return 43;if(card==44)return 47;if(card==45)return 44;if(card==46)return 45;if(card==47)return 46;if(card==48)return 48;
    }
    if(this.Naddavane==3)
    {
        if(card==13)return 13;if(card==14)return 17;if(card==15)return 14;if(card==16)return 15;if(card==17)return 16;if(card==18)return 18;
        if(card==23)return 23;if(card==24)return 27;if(card==25)return 24;if(card==26)return 25;if(card==27)return 26;if(card==28)return 28;
        if(card==33)return 107;if(card==34)return 105;if(card==35)return 108;if(card==36)return 103;if(card==37)return 104;if(card==38)return 106;
        if(card==43)return 43;if(card==44)return 47;if(card==45)return 44;if(card==46)return 45;if(card==47)return 46;if(card==48)return 48;
    }
    if(this.Naddavane==4)
    {
        if(card==13)return 13;if(card==14)return 17;if(card==15)return 14;if(card==16)return 15;if(card==17)return 16;if(card==18)return 18;
        if(card==23)return 23;if(card==24)return 27;if(card==25)return 24;if(card==26)return 25;if(card==27)return 26;if(card==28)return 28;
        if(card==33)return 33;if(card==34)return 37;if(card==35)return 34;if(card==36)return 35;if(card==37)return 36;if(card==38)return 38;
        if(card==43)return 107;if(card==44)return 105;if(card==45)return 108;if(card==46)return 103;if(card==47)return 104;if(card==48)return 106;
    }
    if(this.Naddavane==5)
    {
        if(card==13)return 13;if(card==14)return 17;if(card==15)return 14;if(card==16)return 15;if(card==17)return 16;if(card==18)return 18;
        if(card==23)return 23;if(card==24)return 27;if(card==25)return 24;if(card==26)return 25;if(card==27)return 26;if(card==28)return 28;
        if(card==33)return 33;if(card==34)return 37;if(card==35)return 34;if(card==36)return 35;if(card==37)return 36;if(card==38)return 38;
        if(card==43)return 43;if(card==44)return 47;if(card==45)return 44;if(card==46)return 45;if(card==47)return 46;if(card==48)return 48;
    }
    if(this.Naddavane==6)
    {
        if(card==13)return 17;if(card==14)return 15;if(card==15)return 18;if(card==16)return 13;if(card==17)return 14;if(card==18)return 16;
        if(card==23)return 27;if(card==24)return 25;if(card==25)return 28;if(card==26)return 23;if(card==27)return 24;if(card==28)return 26;
        if(card==33)return 37;if(card==34)return 35;if(card==35)return 38;if(card==36)return 33;if(card==37)return 34;if(card==38)return 36;
        if(card==43)return 47;if(card==44)return 45;if(card==45)return 48;if(card==46)return 43;if(card==47)return 44;if(card==48)return 46;
    }
    return 0;
}

int CBelot3Players::IntelectBoia(int player)
{
    int nmc=CheckNMCard(player);
    int nmsp=CheckNMCard(player,1);
    int ngsp=this.CheckNGCard(player,1);
    int nmka=CheckNMCard(player,2);
    int ngka=this.CheckNGCard(player,2);
    int nmku=CheckNMCard(player,3);
    int ngku=this.CheckNGCard(player,3);
    int nmpi=CheckNMCard(player,4);
    int ngpi=this.CheckNGCard(player,4);
    int nmkoz=CheckNMCard(player,this.Naddavane);
    int ngkoz=this.CheckNGCard(player,this.Naddavane);
    int brsp=GetBrojKarti(1,player);
    int brka=GetBrojKarti(2,player);
    int brku=GetBrojKarti(3,player);
    int brpi=GetBrojKarti(4,player);
    int obrsp=GetBrojKarti(1,0);
    int obrka=GetBrojKarti(2,0);
    int obrku=GetBrojKarti(3,0);
    int obrpi=GetBrojKarti(4,0);
    int brkoz=GetBrojKarti(this.Naddavane,player);
    int obrkoz=GetBrojKarti(this.Naddavane,0);
    int vcard=GetVlastnaKarta(player);
////////////////////////parvi Puska////////////////////////////////////////
    if(this.KoiPuskaParvi==player)
    {
        if(vcard>0)
        {
            if(vcard>10&&vcard<20)
            {
                if((player==2&&(this.Pniama[1][1]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[3][1]!=true||this.Pniama[3][this.Naddavane]==true))||(player==3&&(this.Pniama[1][1]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[2][1]!=true||this.Pniama[2][this.Naddavane]==true)))
                {
                    return PuskCard(vcard, player);
                }
            }
            if(vcard>20&&vcard<30)
            {
                if((player==2&&(this.Pniama[1][2]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[3][2]!=true||this.Pniama[3][this.Naddavane]==true))||(player==3&&(this.Pniama[1][2]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[2][2]!=true||this.Pniama[2][this.Naddavane]==true)))
                {
                    return PuskCard(vcard, player);
                }
            }
            if(vcard>30&&vcard<40)
            {
                if((player==2&&(this.Pniama[1][3]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[3][3]!=true||this.Pniama[3][this.Naddavane]==true))||(player==3&&(this.Pniama[1][3]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[2][3]!=true||this.Pniama[2][this.Naddavane]==true)))
                {
                    return PuskCard(vcard, player);
                }
            }
            if(vcard>40&&vcard<50)
            {
                if((player==2&&(this.Pniama[1][4]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[3][4]!=true||this.Pniama[3][this.Naddavane]==true))||(player==3&&(this.Pniama[1][4]!=true||this.Pniama[1][this.Naddavane]==true)&&(this.Pniama[2][4]!=true||this.Pniama[2][this.Naddavane]==true)))
                {
                    return PuskCard(vcard, player);
                }
            }
        }
        if(this.CheckVlastnaKarta(ngsp,player)==false&&(ngsp==114||ngsp==113||ngsp==112)&&brsp>2&&obrsp-brsp>1&&(player!=1||this.Pniama[1][1]!=true||this.Pniama[1][this.Naddavane]==true)&&(player!=2||this.Pniama[2][1]!=true||this.Pniama[2][this.Naddavane]==true)&&(player!=3||this.Pniama[3][1]!=true||this.Pniama[3][this.Naddavane]==true))
        {
            int sc=GetSmallerCard(player,110);
            if(sc>0)return PuskCard(sc, player);
        }
        if(this.CheckVlastnaKarta(ngka,player)==false&&(ngka==214||ngka==213||ngka==212)&&brka>2&&obrka-brka>1&&(player!=1||this.Pniama[1][2]!=true||this.Pniama[1][this.Naddavane]==true)&&(player!=2||this.Pniama[2][2]!=true||this.Pniama[2][this.Naddavane]==true)&&(player!=3||this.Pniama[3][2]!=true||this.Pniama[3][this.Naddavane]==true))
        {
            int sc=GetSmallerCard(player,210);
            if(sc>0)return PuskCard(sc, player);
        }
        if(this.CheckVlastnaKarta(ngku,player)==false&&(ngku==314||ngku==313||ngku==312)&&brku>2&&obrku-brku>1&&(player!=1||this.Pniama[1][3]!=true||this.Pniama[1][this.Naddavane]==true)&&(player!=2||this.Pniama[2][3]!=true||this.Pniama[2][this.Naddavane]==true)&&(player!=3||this.Pniama[3][3]!=true||this.Pniama[3][this.Naddavane]==true))
        {
            int sc=GetSmallerCard(player,310);
            if(sc>0)return PuskCard(sc, player);
        }
        if(this.CheckVlastnaKarta(ngpi,player)==false&&(ngpi==414||ngpi==413||ngpi==412)&&brpi>2&&obrpi-brpi>1&&(player!=1||this.Pniama[1][4]!=true||this.Pniama[1][this.Naddavane]==true)&&(player!=2||this.Pniama[2][4]!=true||this.Pniama[2][this.Naddavane]==true)&&(player!=3||this.Pniama[3][4]!=true||this.Pniama[3][this.Naddavane]==true))
        {
            int sc=GetSmallerCard(player,410);
            if(sc>0)return PuskCard(sc, player);
        }
        if(((player==2&&this.Naddavane!=1&&(this.Pniama[1][1]==true||this.Pniama[3][1]==true))||(player==3&&this.Naddavane!=1&&(this.Pniama[1][1]==true||this.Pniama[2][1]==true)))&&nmsp<111&&nmsp>0)return PuskCard(nmsp, player);
        if(((player==2&&this.Naddavane!=2&&(this.Pniama[1][2]==true||this.Pniama[3][2]==true))||(player==3&&this.Naddavane!=2&&(this.Pniama[1][2]==true||this.Pniama[2][2]==true)))&&nmka<211&&nmka>0)return PuskCard(nmka, player);
        if(((player==2&&this.Naddavane!=3&&(this.Pniama[1][3]==true||this.Pniama[3][3]==true))||(player==3&&this.Naddavane!=3&&(this.Pniama[1][3]==true||this.Pniama[2][3]==true)))&&nmku<311&&nmku>0)return PuskCard(nmku, player);
        if(((player==2&&this.Naddavane!=4&&(this.Pniama[1][4]==true||this.Pniama[3][4]==true))||(player==3&&this.Naddavane!=4&&(this.Pniama[1][4]==true||this.Pniama[2][4]==true)))&&nmpi<411&&nmpi>0)return PuskCard(nmpi, player);
        if(brkoz==(16-this.hand))
        {
            if(this.CheckVlastnaKarta(ngkoz,player)&&ngkoz>0)return PuskCard(ngkoz, player);
        }
        if(nmc>0)return PuskCard(nmc, player);
    }
////////////////////////Vtori Puska////////////////////////////////////////
    if(this.KoiPuskaParvi==2&&player==3||this.KoiPuskaParvi==1&&player==2)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {

            if(this.CheckVlastnaKarta(ngsp,player)&&ngsp>this.ParviPuska)return PuskCard(ngsp, player);
            if(nmsp>0)return PuskCard(nmsp, player);
            if((brkoz>1||this.CheckVlastnaKarta(ngkoz,player)!=true)&&nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            if(this.CheckVlastnaKarta(ngka,player)&&ngka>this.ParviPuska)return PuskCard(ngka, player);
            if(nmka>0)return PuskCard(nmka, player);
            if((brkoz>1||this.CheckVlastnaKarta(ngkoz,player)!=true)&&nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            if(this.CheckVlastnaKarta(ngku,player)&&ngku>this.ParviPuska)return PuskCard(ngku, player);
            if(nmku>0)return PuskCard(nmku, player);
            if((brkoz>1||this.CheckVlastnaKarta(ngkoz,player)!=true)&&nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            if(this.CheckVlastnaKarta(ngpi,player)&&ngpi>this.ParviPuska)return PuskCard(ngpi, player);
            if(nmpi>0)return PuskCard(nmpi, player);
            if((brkoz>1||this.CheckVlastnaKarta(ngkoz,player)!=true)&&nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>100)
        {
            if(this.CheckVlastnaKarta(ngkoz,player)&&ngkoz>this.ParviPuska)return PuskCard(ngkoz, player);
            if(nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
    }
////////////////////////treti Puska////////////////////////////////////////
    if(this.KoiPuskaParvi==1&&player==3||this.KoiPuskaParvi==3&&player==2)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {
            int bk=GetBigestCard(player,1,this.ParviPuska,this.VtoriPuska);
            if(bk>0)return PuskCard(bk, player);
            if(nmsp>0)return PuskCard(nmsp, player);
            if(this.VtoriPuska<100)
            {
                if(nmkoz>0)return PuskCard(nmkoz, player);
            	
            }
            else
            {
                int brk=GetBigerCard(player,this.VtoriPuska);
                if(brk>0)return PuskCard(brk, player);
            }
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            int bk=GetBigestCard(player,2,this.ParviPuska,this.VtoriPuska);
            if(bk>0)return PuskCard(bk, player);
            if(nmka>0)return PuskCard(nmka, player);
            if(this.VtoriPuska<100)
            {
                if(nmkoz>0)return PuskCard(nmkoz, player);
            	
            }
            else
            {
                int brk=GetBigerCard(player,this.VtoriPuska);
                if(brk>0)return PuskCard(brk, player);
            }
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            int bk=GetBigestCard(player,3,this.ParviPuska,this.VtoriPuska);
            if(bk>0)return PuskCard(bk, player);
            if(nmku>0)return PuskCard(nmku, player);
            if(this.VtoriPuska<100)
            {
                if(nmkoz>0)return PuskCard(nmkoz, player);
            	
            }
            else
            {
                int brk=GetBigerCard(player,this.VtoriPuska);
                if(brk>0)return PuskCard(brk, player);
            }
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            int bk=GetBigestCard(player,4,this.ParviPuska,this.VtoriPuska);
            if(bk>0)return PuskCard(bk, player);
            if(nmpi>0)return PuskCard(nmpi, player);
            if(this.VtoriPuska<100)
            {
                if(nmkoz>0)return PuskCard(nmkoz, player);
            	
            }
            else
            {
                int brk=GetBigerCard(player,this.VtoriPuska);
                if(brk>0)return PuskCard(brk, player);
            }
            if(nmc>0)return PuskCard(nmc, player);
        }
        if(this.ParviPuska>100)
        {
            int bk=GetBigestCard(player,this.Naddavane,this.ParviPuska,this.VtoriPuska);
            if(bk>0)return PuskCard(bk, player);
            if(nmkoz>0)return PuskCard(nmkoz, player);
            if(nmc>0)return PuskCard(nmc, player);
        }
    }
    if(nmc>0)return PuskCard(nmc, player);
    return MinCard(player);
}

int CBelot3Players::IntelectBe(int player)
{
    int brspa=GetBrojKarti(1,this.KoiENaRed);
    int brkar=GetBrojKarti(2,this.KoiENaRed);
    int brkup=GetBrojKarti(3,this.KoiENaRed);
    int brpik=GetBrojKarti(4,this.KoiENaRed);
    int brspaob=GetBrojKarti(1,0);
    int brkarob=GetBrojKarti(2,0);
    int brkupob=GetBrojKarti(3,0);
    int brpikob=GetBrojKarti(4,0);
    if(this.KoiPuskaParvi==2&&this.KoiENaRed==2||this.KoiPuskaParvi==3&&this.KoiENaRed==3)
    {
        if(this.Pkazal[this.KoiPuskaParvi][1]==true)
        {
        }
        for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)
        {
            if(this.CheckVlastnaKarta(this.k[i])==true)return i;
        }
        return MinCard(player);
    }
//label2////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
    if(this.KoiPuskaParvi==2&&this.KoiENaRed==3||this.KoiPuskaParvi==1&&this.KoiENaRed==2)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>10&&this.k[i]<20)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>20&&this.k[i]<30)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>30&&this.k[i]<40)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>40&&this.k[i]<50)return i;
            }
            return MinCard(player);
        }
    }
//label3////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
    if(this.KoiPuskaParvi==3&&this.KoiENaRed==2||this.KoiPuskaParvi==1&&this.KoiENaRed==3)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>10&&this.k[i]<20)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>20&&this.k[i]<30)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>30&&this.k[i]<40)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>40&&this.k[i]<50)return i;
            }
            return MinCard(player);
        }
    }
    return MinCard(player);
}
int CBelot3Players::IntelectVs(int player)
{
    int brspa=GetBrojKarti(1,this.KoiENaRed);
    int brkar=GetBrojKarti(2,this.KoiENaRed);
    int brkup=GetBrojKarti(3,this.KoiENaRed);
    int brpik=GetBrojKarti(4,this.KoiENaRed);
    int brspaob=GetBrojKarti(1,0);
    int brkarob=GetBrojKarti(2,0);
    int brkupob=GetBrojKarti(3,0);
    int brpikob=GetBrojKarti(4,0);
    if(this.KoiPuskaParvi==2&&this.KoiENaRed==2||this.KoiPuskaParvi==3&&this.KoiENaRed==3)
    {
        if(this.Pkazal[this.KoiPuskaParvi][1]==true)
        {
        }
        for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
        {
            if(this.CheckVlastnaKarta(this.k[i])==true)return i;
        }
    	
        return MinCard(player);
    }
//label2////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
    if(this.KoiPuskaParvi==2&&this.KoiENaRed==3||this.KoiPuskaParvi==1&&this.KoiENaRed==2)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>this.ParviPuska&&this.k[i]<20)return i;
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>10&&this.k[i]<20)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>this.ParviPuska&&this.k[i]<30)return i;
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>20&&this.k[i]<30)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>this.ParviPuska&&this.k[i]<40)return i;
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>30&&this.k[i]<40)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>this.ParviPuska&&this.k[i]<50)return i;
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>40&&this.k[i]<50)return i;
            }
            return MinCard(player);
        }
    }
//label3////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
    if(this.KoiPuskaParvi==3&&this.KoiENaRed==2||this.KoiPuskaParvi==1&&this.KoiENaRed==3)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20)
        {
            if(this.VtoriPuska>10&&this.VtoriPuska<20)
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]>this.VtoriPuska&&this.k[i]<20)return i;
                }
            }
            else
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]<20)return i;
                }
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>10&&this.k[i]<20)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>20&&this.ParviPuska<30)
        {
            if(this.VtoriPuska>20&&this.VtoriPuska<30)
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]>this.VtoriPuska&&this.k[i]<30)return i;
                }
            }
            else
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]<30)return i;
                }
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>20&&this.k[i]<30)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>30&&this.ParviPuska<40)
        {
            if(this.VtoriPuska>30&&this.VtoriPuska<40)
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]>this.VtoriPuska&&this.k[i]<40)return i;
                }
            }
            else
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]<40)return i;
                }
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>30&&this.k[i]<40)return i;
            }
            return MinCard(player);
        }
        if(this.ParviPuska>40&&this.ParviPuska<50)
        {
            if(this.VtoriPuska>40&&this.VtoriPuska<50)
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]>this.VtoriPuska&&this.k[i]<50)return i;
                }
            }
            else
            {
                for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
                {
                    if(this.k[i]>this.ParviPuska&&this.k[i]<50)return i;
                }
            }
            for(let i: number =(1+(player-1)*8);i<(9-this.hand+(player-1)*8);i++)//pravila
            {
                if(this.k[i]>40&&this.k[i]<50)return i;
            }
            return MinCard(player);
        }
    }
    return MinCard(player);
}

void CBelot3Players::OnRButtonDown(CPoint point)
{
    if(this.watch) {
    	
        for(let i: number =2;i<=3;i++) {
            if(this.Name[i]=="Computer2"||this.Name[i]=="Computer3") {
                CSize s=dc.GetTextExtent(this.Name[i]);
                int width=s.cx;
                if(ScaleRect(CRect(NamePos[i].x,NamePos[i].y,NamePos[i].x+width,NamePos[i].y+20)).PtInRect(point)) {					
                    m_pwin->GoToLobby();
                    m_pwin->m_dbo.auto_connect=this.Name[1];
                }
            }
        }
    }
}

void CBelot3Players::OnLButtonDown(CPoint point)
{
    if(this.OP) {
        for(let i: number =1;i<=3;i++) {
            if(chat_message[i]!="") {
                chat_message[i]="";
                m_pwin->InvalidateRectScale(CRect(PosNaddavane[i].x-10,PosNaddavane[i].y-80,PosNaddavane[i].x+600,PosNaddavane[i].y+20),true);
            }
        }
        int begin=2;
        if(this.watch)begin=1;
        for(let i: number =begin;i<=3;i++) {
            if(show_user[i]) {
                show_user[i]=false;
                ShellExecute(m_pwin->m_hWnd,"open",GetLanguageText("domain")+"/server/public/show_user.php?username="+this.Name[i]+"&lang="+GetLanguage(), NULL,NULL,SW_SHOWNORMAL);
            }
        }
    }
    if(!this.watch) {
        if(this.OP&&(this.P2OP||wait_opplayers[2]||P3OP||wait_opplayers[3]||!this.Server)) {
            if(this.OP&&this.Faza>4&&strelka<1&&this.P1puska==0&&this.Move==false&&this.KoiENaRed==1) {
                if(ScaleRect(pause_rect).PtInRect(point)) {
                    if(pause==0) {
                        pause=1;
                        this.online_timer=180;
                        m_pwin->InvalidateRectScale(CRect(676,475,732, 505),false);
                        CString p_str="*|A|"+this.Name[1];
                        this.SendGame(p_str);
                    }
                }
            }
        }
        if(strelka<1&&this.P1puska==0)
        {
            for(let i: number =1;i<=8;i++) 
            {
                if(this.CardsPos[i].x*scale<point.x&&(this.CardsPos[i].x+Width)*scale>point.x&&this.CardsPos[i].y*scale<point.y&&(this.CardsPos[i].y+Height)*scale>point.y&&this.k[i]!=0)
                {
                    if(this.ProverkaCursor(i))
                    {
                        if(this.SP)this.PuskaneNaKarta(i);
                        if(this.OP)this.PuskaneNaKartaOP(i);
                        if(this.OP&&!this.watch) {
                            sit_out_count=0;
                            if(m_orez.m_rez1==0&&m_orez.m_rez2==0&&m_orez.m_rez3==0) {
                                if(!op_start) {
                                    this.SendGame("");
                                    op_start=true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

void CBelot3Players::Intelect(int player)
{
    int card;
    this.online_timer=this.online_timer_time;
	
    if(this.Naddavane<5)card=IntelectColor(player);
    if(this.Naddavane==5)card=IntelectNT(player);
    if(this.Naddavane==6)card=IntelectTrumps(player);
    bool send_card=true;
    if(CheckMessage()) {
        int ca=_ttoi(ReadMessage());
        if(player==2)card=8+ca;
        if(player==3)card=16+ca;
        send_card=false;
        m_pwin->SendErrorToLog("B3Puskane na Karta ot PL "+this.IntToString(player));
    }
    int ccard=card;
    if(player==2)ccard=ccard-8;
    if(player==3)ccard=ccard-16;
    if(ccard<=0||(ccard>8)) {
        card=(1+(player-1)*8);
        if(this.OP)m_pwin->SendErrorToLog("Greshka pri puskane na karta ot comp-this.Naddavane="+this.IntToString(this.Naddavane)+" player="+this.IntToString(player)+" Karta: "+this.IntToString(ccard));
    }
    this.AddToDetails(card);
    if(this.CheckBelot(this.k[card],player)==true)
    {
        this.ObiaviavaneBelot(player,true);
        this.Belot[player][0]=this.Belot[player][0]+1;
        if(this.OP)
        {
            this.SendGame("$|o|"+this.IntToString(this.hand));
        }
    }
    if(player!=this.KoiPuskaParvi)
    {
        if(this.ParviPuska>10&&this.ParviPuska<20&&this.k[card]>20)this.Pniama[player][1]=true;
        if(this.ParviPuska>20&&this.ParviPuska<30&&(this.k[card]<20||this.k[card]>30))this.Pniama[player][2]=true;
        if(this.ParviPuska>30&&this.ParviPuska<40&&(this.k[card]<30||this.k[card]>40))this.Pniama[player][3]=true;
        if(this.ParviPuska>40&&this.ParviPuska<50&&(this.k[card]<40||this.k[card]>50))this.Pniama[player][4]=true;
        if(this.ParviPuska>100&&this.k[card]<100)this.Pniama[player][this.Naddavane]=true;
        if(this.Naddavane==6&&this.KoiPuskaParvi==3)
        {
            if(this.ParviPuska==12&&this.k[card]==11)this.Pniama[player][1]=true;
            if(this.ParviPuska==22&&this.k[card]==21)this.Pniama[player][2]=true;
            if(this.ParviPuska==32&&this.k[card]==31)this.Pniama[player][3]=true;
            if(this.ParviPuska==42&&this.k[card]==41)this.Pniama[player][4]=true;

            if(this.ParviPuska==13&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
            if(this.ParviPuska==23&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
            if(this.ParviPuska==33&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
            if(this.ParviPuska==43&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

            if(this.ParviPuska==13&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
            if(this.ParviPuska==23&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
            if(this.ParviPuska==33&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
            if(this.ParviPuska==43&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

            if(this.ParviPuska==14&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
            if(this.ParviPuska==24&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
            if(this.ParviPuska==34&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
            if(this.ParviPuska==44&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

            if(this.ParviPuska==14&&this.CheckCard(13)==false&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
            if(this.ParviPuska==24&&this.CheckCard(23)==false&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
            if(this.ParviPuska==34&&this.CheckCard(33)==false&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
            if(this.ParviPuska==44&&this.CheckCard(43)==false&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

            if(this.ParviPuska==14&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==13)this.Pniama[player][1]=true;
            if(this.ParviPuska==24&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==23)this.Pniama[player][2]=true;
            if(this.ParviPuska==34&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==33)this.Pniama[player][3]=true;
            if(this.ParviPuska==44&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==43)this.Pniama[player][4]=true;

            if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
            if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
            if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
            if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

            if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(13)==false&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
            if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(23)==false&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
            if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(33)==false&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
            if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(43)==false&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

            if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==13)this.Pniama[player][1]=true;
            if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==23)this.Pniama[player][2]=true;
            if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==33)this.Pniama[player][3]=true;
            if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==43)this.Pniama[player][4]=true;

            if(this.ParviPuska==15&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==14)this.Pniama[player][1]=true;
            if(this.ParviPuska==25&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==24)this.Pniama[player][2]=true;
            if(this.ParviPuska==35&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==34)this.Pniama[player][3]=true;
            if(this.ParviPuska==45&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==44)this.Pniama[player][4]=true;
        }
        if(this.Naddavane<5&&this.KoiPuskaParvi==3)
        {
            if(this.ParviPuska==102&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
            if(this.ParviPuska==103&&this.CheckCard(102)==false&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
            if(this.ParviPuska==103&&this.CheckCard(101)==false&&this.k[card]==102)this.Pniama[player][this.Naddavane]=true;
            if(this.ParviPuska==104&&this.CheckCard(103)==false&&this.CheckCard(102)==false&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
            if(this.ParviPuska==104&&this.CheckCard(103)==false&&this.CheckCard(101)==false&&this.k[card]==102)this.Pniama[player][this.Naddavane]=true;
            if(this.ParviPuska==104&&this.CheckCard(102)==false&&this.CheckCard(101)==false&&this.k[card]==103)this.Pniama[player][this.Naddavane]=true;
        }
    }
    if(this.hand==0)
    {

        this.terci[player][0] = true;
        this.terci[player][1] = true;
        for (int b = 0; b <= 1; b++) {
            if (this.PTerci[player][b] > 0) {
                if (!(this.PTerci[player][0] > 0 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 7 && this.PTerci[player][1]>0 && this.PTerci[player][1] < 7)
                    && !(this.PTerci[player][0] > 10 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 16 && this.PTerci[player][1]>10 && this.PTerci[player][1] < 16)
                    && !(this.PTerci[player][0] > 30 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 37 && this.PTerci[player][1]>30 && this.PTerci[player][1] < 37)) {
                    this.ObiaviavaneTerci(player, this.PTerci[player][b], b, true);
                }
                if (b === 0) {
                    if (this.PTerci[player][0] > 0 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 7 && this.PTerci[player][1]>0 && this.PTerci[player][1] < 7) {
                        this.ObiaviavaneTerci(player, 7, 0, true);
                    }
                    if (this.PTerci[player][0] > 10 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 16 && this.PTerci[player][1]>10 && this.PTerci[player][1] < 16) {
                        this.ObiaviavaneTerci(player, 16, 0, true);
                    }
                    if (this.PTerci[player][0] > 30 && this.terci[player][0] && this.terci[player][1] && this.PTerci[player][0] < 37 && this.PTerci[player][1]>30 && this.PTerci[player][1] < 37) {
                        this.ObiaviavaneTerci(player, 37, 0, true);
                    }
                }
                if (this.OP) {
                    CString message = "$|q|" + this.IntToString(player) + "|" + this.IntToString(b);
                    this.SendGame(message);
                }
            }
        }
    }
    if(this.OP==true)
    {
        int ca=card;
        while(ca>8)ca=ca-8;
        CString car=this.IntToString(ca);
        if(this.Server==true)
        {
            this.SendGame("$|k|"+car);
        }
    }
    if(player==2)P2puska=this.k[card];
    if(player==3)P3puska=this.k[card];
    this.CardsPoint[player+32]=this.CardsPoint[card];
    this.CardP[32+this.KoiENaRed]=this.CardP[card];
    this.BeginMove(this.CardsPoint[card],this.CardsPos[this.KoiENaRed+32],this.KoiENaRed+32);
    if(player==1)this.P1puska=this.k[card];
    if(player==2)P2puska=this.k[card];
    if(player==3)P3puska=this.k[card];

    if(this.KoiPuskaParvi==1)
    {
        if(player==1)this.ParviPuska=this.k[card];
        if(player==2)
        {
            if(this.Naddavane==6&&this.ParviPuska==12&&this.k[card]==11)this.Pniama[player][1]=true;
            if(this.Naddavane==6&&this.ParviPuska==22&&this.k[card]==21)this.Pniama[player][2]=true;
            if(this.Naddavane==6&&this.ParviPuska==32&&this.k[card]==31)this.Pniama[player][3]=true;
            if(this.Naddavane==6&&this.ParviPuska==42&&this.k[card]==41)this.Pniama[player][4]=true;
            if(this.Naddavane<5&&this.ParviPuska==102&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
            this.VtoriPuska=this.k[card];
        }
        if(player==3)
        {
            P1G=false;
            if(this.P2OP==true)P2G=false;	
            if(P3OP==true)P3G=false;
            this.TretiPuska=this.k[card];
        }
    }
    if(this.KoiPuskaParvi==2)
    {
        if(player==2)this.ParviPuska=this.k[card];
        if(player==3)this.VtoriPuska=this.k[card];
        if(player==1)this.TretiPuska=this.k[card];
    }
    if(this.KoiPuskaParvi==3)
    {
        if(player==3)this.ParviPuska=this.k[card];
        if(player==1)this.VtoriPuska=this.k[card];
        if(this.KoiENaRed==2)
        {
            P1G=false;
            if(this.P2OP==true)P2G=false;	
            if(P3OP==true)P3G=false;
            this.TretiPuska=this.k[card];
        }
    }
    this.DeleteCard(card);
    if(this.P1puska==0||P2puska==0||P3puska==0)
    {
        if(this.KoiENaRed<=2)this.KoiENaRed=this.KoiENaRed+1;
        else this.KoiENaRed=1;
    }
}

int CBelot3Players::KartaZaRazdavane()
{
    if(KoiZapochva==1)
    {
        if(Razdavane1==true)
        {
            if(this.CardsPoint[1].x==this.CardsPos[0].x&&this.CardsPoint[1].y==this.CardsPos[0].y)return 1;
            if(this.CardsPoint[2].x==this.CardsPos[0].x&&this.CardsPoint[2].y==this.CardsPos[0].y)return 2;
            if(this.CardsPoint[3].x==this.CardsPos[0].x&&this.CardsPoint[3].y==this.CardsPos[0].y)return 3;
            if(this.CardsPoint[9].x==this.CardsPos[0].x&&this.CardsPoint[9].y==this.CardsPos[0].y)return 9;
            if(this.CardsPoint[10].x==this.CardsPos[0].x&&this.CardsPoint[10].y==this.CardsPos[0].y)return 10;
            if(this.CardsPoint[11].x==this.CardsPos[0].x&&this.CardsPoint[11].y==this.CardsPos[0].y)return 11;
            if(this.CardsPoint[17].x==this.CardsPos[0].x&&this.CardsPoint[17].y==this.CardsPos[0].y)return 17;
            if(this.CardsPoint[18].x==this.CardsPos[0].x&&this.CardsPoint[18].y==this.CardsPos[0].y)return 18;
            if(this.CardsPoint[19].x==this.CardsPos[0].x&&this.CardsPoint[19].y==this.CardsPos[0].y)return 19;
            if(this.CardsPoint[4].x==this.CardsPos[0].x&&this.CardsPoint[4].y==this.CardsPos[0].y)return 4;
            if(this.CardsPoint[5].x==this.CardsPos[0].x&&this.CardsPoint[5].y==this.CardsPos[0].y)return 5;
            if(this.CardsPoint[12].x==this.CardsPos[0].x&&this.CardsPoint[12].y==this.CardsPos[0].y)return 12;
            showrcard=2;
            if(this.CardsPoint[13].x==this.CardsPos[0].x&&this.CardsPoint[13].y==this.CardsPos[0].y)return 13;
            if(this.CardsPoint[20].x==this.CardsPos[0].x&&this.CardsPoint[20].y==this.CardsPos[0].y)return 20;
            Razdavane1=false;this.Faza=1;
            if(this.CardsPoint[21].x==this.CardsPos[0].x&&this.CardsPoint[21].y==this.CardsPos[0].y)return 21;
            this.online_timer=this.online_timer_time;
        }
        if(Razdavane2==true)
        {
            if(this.CardsPoint[6].x==this.CardsPos[0].x&&this.CardsPoint[6].y==this.CardsPos[0].y)return 6;
            if(this.CardsPoint[7].x==this.CardsPos[0].x&&this.CardsPoint[7].y==this.CardsPos[0].y)return 7;
            if(this.CardsPoint[8].x==this.CardsPos[0].x&&this.CardsPoint[8].y==this.CardsPos[0].y)return 8;
            if(this.CardsPoint[14].x==this.CardsPos[0].x&&this.CardsPoint[14].y==this.CardsPos[0].y)return 14;
            if(this.CardsPoint[15].x==this.CardsPos[0].x&&this.CardsPoint[15].y==this.CardsPos[0].y)return 15;
            if(this.CardsPoint[16].x==this.CardsPos[0].x&&this.CardsPoint[16].y==this.CardsPos[0].y)return 16;
            showrcard=1;
            if(this.CardsPoint[22].x==this.CardsPos[0].x&&this.CardsPoint[22].y==this.CardsPos[0].y)return 22;
            if(this.CardsPoint[23].x==this.CardsPos[0].x&&this.CardsPoint[23].y==this.CardsPos[0].y)return 23;
            Razdavane2=false;this.Faza=4;
            if(this.CardsPoint[24].x==this.CardsPos[0].x&&this.CardsPoint[24].y==this.CardsPos[0].y)return 24;
            this.online_timer=this.online_timer_time;
        }
    }
    if(KoiZapochva==2)
    {
        if(Razdavane1==true)
        {
            if(this.CardsPoint[9].x==this.CardsPos[0].x&&this.CardsPoint[9].y==this.CardsPos[0].y)return 9;
            if(this.CardsPoint[10].x==this.CardsPos[0].x&&this.CardsPoint[10].y==this.CardsPos[0].y)return 10;
            if(this.CardsPoint[11].x==this.CardsPos[0].x&&this.CardsPoint[11].y==this.CardsPos[0].y)return 11;
            if(this.CardsPoint[17].x==this.CardsPos[0].x&&this.CardsPoint[17].y==this.CardsPos[0].y)return 17;
            if(this.CardsPoint[18].x==this.CardsPos[0].x&&this.CardsPoint[18].y==this.CardsPos[0].y)return 18;
            if(this.CardsPoint[19].x==this.CardsPos[0].x&&this.CardsPoint[19].y==this.CardsPos[0].y)return 19;
            if(this.CardsPoint[1].x==this.CardsPos[0].x&&this.CardsPoint[1].y==this.CardsPos[0].y)return 1;
            if(this.CardsPoint[2].x==this.CardsPos[0].x&&this.CardsPoint[2].y==this.CardsPos[0].y)return 2;
            if(this.CardsPoint[3].x==this.CardsPos[0].x&&this.CardsPoint[3].y==this.CardsPos[0].y)return 3;
            if(this.CardsPoint[12].x==this.CardsPos[0].x&&this.CardsPoint[12].y==this.CardsPos[0].y)return 12;
            if(this.CardsPoint[13].x==this.CardsPos[0].x&&this.CardsPoint[13].y==this.CardsPos[0].y)return 13;
            if(this.CardsPoint[20].x==this.CardsPos[0].x&&this.CardsPoint[20].y==this.CardsPos[0].y)return 20;
            showrcard=2;
            if(this.CardsPoint[21].x==this.CardsPos[0].x&&this.CardsPoint[21].y==this.CardsPos[0].y)return 21;
            if(this.CardsPoint[4].x==this.CardsPos[0].x&&this.CardsPoint[4].y==this.CardsPos[0].y)return 4;
            Razdavane1=false;this.Faza=1;
            if(this.CardsPoint[5].x==this.CardsPos[0].x&&this.CardsPoint[5].y==this.CardsPos[0].y)return 5;
            this.online_timer=this.online_timer_time;
        }
        if(Razdavane2==true)
        {
            if(this.CardsPoint[14].x==this.CardsPos[0].x&&this.CardsPoint[14].y==this.CardsPos[0].y)return 14;
            if(this.CardsPoint[15].x==this.CardsPos[0].x&&this.CardsPoint[15].y==this.CardsPos[0].y)return 15;
            if(this.CardsPoint[16].x==this.CardsPos[0].x&&this.CardsPoint[16].y==this.CardsPos[0].y)return 16;
            if(this.CardsPoint[22].x==this.CardsPos[0].x&&this.CardsPoint[22].y==this.CardsPos[0].y)return 22;
            if(this.CardsPoint[23].x==this.CardsPos[0].x&&this.CardsPoint[23].y==this.CardsPos[0].y)return 23;
            if(this.CardsPoint[24].x==this.CardsPos[0].x&&this.CardsPoint[24].y==this.CardsPos[0].y)return 24;
            showrcard=1;
            if(this.CardsPoint[6].x==this.CardsPos[0].x&&this.CardsPoint[6].y==this.CardsPos[0].y)return 6;
            if(this.CardsPoint[7].x==this.CardsPos[0].x&&this.CardsPoint[7].y==this.CardsPos[0].y)return 7;
            Razdavane2=false;this.Faza=4;
            if(this.CardsPoint[8].x==this.CardsPos[0].x&&this.CardsPoint[8].y==this.CardsPos[0].y)return 8;
            this.online_timer=this.online_timer_time;
        }
    }
    if(KoiZapochva==3)
    {
        if(Razdavane1==true)
        {
            if(this.CardsPoint[17].x==this.CardsPos[0].x&&this.CardsPoint[17].y==this.CardsPos[0].y)return 17;
            if(this.CardsPoint[18].x==this.CardsPos[0].x&&this.CardsPoint[18].y==this.CardsPos[0].y)return 18;
            if(this.CardsPoint[19].x==this.CardsPos[0].x&&this.CardsPoint[19].y==this.CardsPos[0].y)return 19;
            if(this.CardsPoint[1].x==this.CardsPos[0].x&&this.CardsPoint[1].y==this.CardsPos[0].y)return 1;
            if(this.CardsPoint[2].x==this.CardsPos[0].x&&this.CardsPoint[2].y==this.CardsPos[0].y)return 2;
            if(this.CardsPoint[3].x==this.CardsPos[0].x&&this.CardsPoint[3].y==this.CardsPos[0].y)return 3;
            if(this.CardsPoint[9].x==this.CardsPos[0].x&&this.CardsPoint[9].y==this.CardsPos[0].y)return 9;
            if(this.CardsPoint[10].x==this.CardsPos[0].x&&this.CardsPoint[10].y==this.CardsPos[0].y)return 10;
            if(this.CardsPoint[11].x==this.CardsPos[0].x&&this.CardsPoint[11].y==this.CardsPos[0].y)return 11;
            if(this.CardsPoint[20].x==this.CardsPos[0].x&&this.CardsPoint[20].y==this.CardsPos[0].y)return 20;
            if(this.CardsPoint[21].x==this.CardsPos[0].x&&this.CardsPoint[21].y==this.CardsPos[0].y)return 21;
            if(this.CardsPoint[4].x==this.CardsPos[0].x&&this.CardsPoint[4].y==this.CardsPos[0].y)return 4;
            showrcard=2;
            if(this.CardsPoint[5].x==this.CardsPos[0].x&&this.CardsPoint[5].y==this.CardsPos[0].y)return 5;
            if(this.CardsPoint[12].x==this.CardsPos[0].x&&this.CardsPoint[12].y==this.CardsPos[0].y)return 12;
            Razdavane1=false;this.Faza=1;
            if(this.CardsPoint[13].x==this.CardsPos[0].x&&this.CardsPoint[13].y==this.CardsPos[0].y)return 13;
            this.online_timer=this.online_timer_time;
        }
        if(Razdavane2==true)
        {
            if(this.CardsPoint[22].x==this.CardsPos[0].x&&this.CardsPoint[22].y==this.CardsPos[0].y)return 22;
            if(this.CardsPoint[23].x==this.CardsPos[0].x&&this.CardsPoint[23].y==this.CardsPos[0].y)return 23;
            if(this.CardsPoint[24].x==this.CardsPos[0].x&&this.CardsPoint[24].y==this.CardsPos[0].y)return 24;
            if(this.CardsPoint[6].x==this.CardsPos[0].x&&this.CardsPoint[6].y==this.CardsPos[0].y)return 6;
            if(this.CardsPoint[7].x==this.CardsPos[0].x&&this.CardsPoint[7].y==this.CardsPos[0].y)return 7;
            if(this.CardsPoint[8].x==this.CardsPos[0].x&&this.CardsPoint[8].y==this.CardsPos[0].y)return 8;
            showrcard=1;
            if(this.CardsPoint[14].x==this.CardsPos[0].x&&this.CardsPoint[14].y==this.CardsPos[0].y)return 14;
            if(this.CardsPoint[15].x==this.CardsPos[0].x&&this.CardsPoint[15].y==this.CardsPos[0].y)return 15;
            Razdavane2=false;this.Faza=4;
            if(this.CardsPoint[16].x==this.CardsPos[0].x&&this.CardsPoint[16].y==this.CardsPos[0].y)return 16;
            this.online_timer=this.online_timer_time;
        }
    }
    return 0;
}

void CBelot3Players::InitVziatka()
{
    for(let i: number =1;i<=3;i++)this.Belot[i][1]=0;
    this.KoiENaRed=this.KoiPuskaParvi=strelka;
    int ParviPuska11,VtoriPuska11,TretiPuska11;
    if(this.ParviPuska>100)
    {
        ParviPuska11=this.ParviPuska%10+this.Naddavane*10;
    }
    if(this.ParviPuska>=0 && this.ParviPuska<100)
    {
        ParviPuska11=this.ParviPuska;
    }
    if(this.VtoriPuska>100)
    {
        VtoriPuska11=this.VtoriPuska%10+this.Naddavane*10;
    }
    if(this.VtoriPuska>=0 && this.VtoriPuska<100)
    {
        VtoriPuska11=this.VtoriPuska;
    }
    if(this.TretiPuska>100)
    {
        TretiPuska11=this.TretiPuska%10+this.Naddavane*10;
    }
    if(this.TretiPuska>=0 && this.TretiPuska<100)
    {
        TretiPuska11=this.TretiPuska;
    }

    if(this.KoiPuskaParvi==1)
    {
        if(VtoriPuska11/10 !== ParviPuska11/10)
        {
            Niama[2][ParviPuska11/10]=true;
            LIzchistena[2][VtoriPuska11/10]=VtoriPuska11;
            if(this.Naddavane<5 && VtoriPuska11/10!=this.Naddavane)
                Niama[2][this.Naddavane]=true;
        }
        if(TretiPuska11/10 !== ParviPuska11/10)
        {
            Niama[3][ParviPuska11/10]=true;
            LIzchistena[3][TretiPuska11/10]=TretiPuska11;
            if(this.Naddavane<5 && TretiPuska11/10!=this.Naddavane)
                Niama[3][this.Naddavane]=true;
        }
        if(VtoriPuska11/10 === ParviPuska11/10)
        {
            if(VtoriPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[2][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[2][ParviPuska11/10] = VtoriPuska11;
            }
        }
        if(TretiPuska11/10 === ParviPuska11/10)
        {
            if(TretiPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[3][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[3][ParviPuska11/10] = TretiPuska11;
            }
        }
    }
    if(this.KoiPuskaParvi==2)
    {
        if(VtoriPuska11/10 !== ParviPuska11/10)
        {
            Niama[3][ParviPuska11/10]=true;
            LIzchistena[3][VtoriPuska11/10]=VtoriPuska11;
            if(this.Naddavane<5 && VtoriPuska11/10!=this.Naddavane)
                Niama[3][this.Naddavane]=true;
        }
        if(TretiPuska11/10 !== ParviPuska11/10)
        {
            Niama[1][ParviPuska11/10]=true;
            LIzchistena[1][TretiPuska11/10]=TretiPuska11;
            if(this.Naddavane<5 && TretiPuska11/10!=this.Naddavane)
                Niama[1][this.Naddavane]=true;
        }
        if(VtoriPuska11/10 === ParviPuska11/10)
        {
            if(VtoriPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[3][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[3][ParviPuska11/10] = VtoriPuska11;
            }
        }
        if(TretiPuska11/10 === ParviPuska11/10)
        {
            if(TretiPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[1][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[1][ParviPuska11/10] = TretiPuska11;
            }
        }
    }
    if(this.KoiPuskaParvi==3)
    {
        if(VtoriPuska11/10 !== ParviPuska11/10)
        {
            Niama[1][ParviPuska11/10]=true;
            LIzchistena[1][VtoriPuska11/10]=VtoriPuska11;
        }
        if(TretiPuska11/10 !== ParviPuska11/10)
        {
            Niama[2][ParviPuska11/10]=true;
            LIzchistena[2][TretiPuska11/10]=TretiPuska11;
        }
        if(VtoriPuska11/10 === ParviPuska11/10)
        {
            if(VtoriPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[1][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[1][ParviPuska11/10] = VtoriPuska11;
            }
        }
        if(TretiPuska11/10 === ParviPuska11/10)
        {
            if(TretiPuska11 < ParviPuska11)
            {
                if(this.Naddavane === 6 || ParviPuska11/10 === this.Naddavane)
                {
                    NeKa4ena[2][ParviPuska11/10] = ParviPuska11;
                }
                LIzchistena[2][ParviPuska11/10] = TretiPuska11;
            }
        }
    }
    strelka=0;
    this.P1puska=0;P2puska=0;P3puska=0;
    this.ParviPuska=0;this.VtoriPuska=0;this.TretiPuska=0;
    this.online_timer=this.online_timer_time;
}

void CBelot3Players::Obiaviavane(int player,int anons)
{
    this.online_timer=this.online_timer_time;
	
    if(this.OP==true)
    {
        if(anons>0)this.BrPas=0;
    }
    DrawObiaviavane(&dc,player,anons,0);	
    if(KoiObiaviava<3)KoiObiaviava=KoiObiaviava+1;
    else KoiObiaviava=1;
    CString position="center";
    if(Zvuk[0]==1)
    {
        m_sndSound.Play(anons+2, position, stereo,pls[player]);
    }
}

void CBelot3Players::Obiaviavane(int player)
{
	
    int anons=Anonsi(player);
    DrawObiaviavane(&dc,player,anons,0);
    if(KoiObiaviava<3)KoiObiaviava=KoiObiaviava+1;
    else KoiObiaviava=1;
    CString position="center";
    if(Zvuk[0]==1)
    {
        m_sndSound.Play(anons+2, position, stereo,pls[player]);
    }
}

void CBelot3Players::Timer1()
{
    if(this.SP)
    {
        if(strelka==0&&this.Move!=true&&this.P1puska>0&&P2puska>0&&P3puska>0)
        {
        	
            strelka=Strelka();
            if(strelka==1)kapo1=false;
            if(strelka==2)kapo2=false;
            if(strelka==3)kapo3=false;
            vz[this.hand]=strelka;
            m_pwin->InvalidateRectScale(CRect(StrelkaPos[strelka].x,StrelkaPos[strelka].y,StrelkaPos[strelka].x+34,StrelkaPos[strelka].y+34),false);
            this.props.m_pwin.KillTimer(4);
            this.props.m_pwin.SetTimer(4,this.Speed[4]",15)*100);
        }
        if(this.Move==true)
        {
            int x1=this.CardsPoint[Mcard].x;
            int y1=this.CardsPoint[Mcard].y;
            MoveCard(!Razdavane1 && !Razdavane2);
            m_pwin->InvalidateRectScale(CRect(x1,y1,x1+Width,y1+Height),false);
            m_pwin->InvalidateRectScale(CRect(this.CardsPoint[Mcard].x,this.CardsPoint[Mcard].y,this.CardsPoint[Mcard].x+Width,this.CardsPoint[Mcard].y+Height),false);
        }
        if((Razdavane1==true||Razdavane2==true)&&this.Move!=true&&this.Faza<4)
        {
            int sc1=showrcard;
            int karta=KartaZaRazdavane();
            int sc2=showrcard;
            if(sc1!=sc2)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[0].x-2,this.CardsPoint[0].y-4,this.CardsPoint[0].x+Width,this.CardsPoint[0].y+Height),false);
            this.BeginMove(this.CardsPoint[karta],this.CardsPos[karta],karta);
        }
        if(Razdavane1!=true&&Razdavane2!=true&&this.Move!=true&&this.Faza==1)
        {
        	
        	
            this.Faza=2;
            SortCards(0);
            DrawCards(&dc);
            this.props.m_pwin.KillTimer(2);
            this.props.m_pwin.SetTimer(2,this.Speed[2] * 100);
        }
        if(this.Faza==4&&Razdavane2!=true&&this.Move!=true)
        {
        	
        	
            this.Faza=5;
            for(int f=1;f<=3;f++)this.CheckTerci(true,f);
            SortCards(1);
            DrawCards(&dc);
            this.props.m_pwin.KillTimer(3);
            this.props.m_pwin.SetTimer(3,this.Speed[2] * 100);
        }
    }
    if(this.OP)
    {
        if(this.Server) {
            if(this.Faza==6&&P2G&&P3G)
            {
                if(this.P2OP==true)P2G=false;
                if(P3OP==true)P3G=false;
                InitGame3();
                SendKoiENaRed();
                SendCards();
            	
                DrawNames(&dc);
                Razdavane1=true;
                this.online_timer=this.online_timer_time;
            }
            if(strelka==0&&this.Move!=true&&this.P1puska>0&&P2puska>0&&P3puska>0)
            {
            	
                strelka=Strelka();
                if(strelka==1)kapo1=false;
                if(strelka==2)kapo2=false;
                if(strelka==3)kapo3=false;
                vz[this.hand]=strelka;
                m_pwin->InvalidateRectScale(CRect(StrelkaPos[strelka].x,StrelkaPos[strelka].y,StrelkaPos[strelka].x+34,StrelkaPos[strelka].y+34),false);
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4,this.Speed[4]",15)*100);
            }
            if(this.Move==true)
            {
                int x1=this.CardsPoint[Mcard].x;
                int y1=this.CardsPoint[Mcard].y;
                MoveCard(!Razdavane1 && !Razdavane2);
                m_pwin->InvalidateRectScale(CRect(x1,y1,x1+Width,y1+Height),false);
                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[Mcard].x,this.CardsPoint[Mcard].y,this.CardsPoint[Mcard].x+Width,this.CardsPoint[Mcard].y+Height),false);
            }
            if((Razdavane1==true||Razdavane2==true)&&this.Move!=true&&this.Faza<4)
            {
                int sc1=showrcard;
                int karta=KartaZaRazdavane();
                int sc2=showrcard;
                if(sc1!=sc2)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[0].x-2,this.CardsPoint[0].y-4,this.CardsPoint[0].x+Width,this.CardsPoint[0].y+Height),false);
                this.BeginMove(this.CardsPoint[karta],this.CardsPos[karta],karta);
            }
            if(Razdavane1!=true&&Razdavane2!=true&&this.Move!=true&&this.Faza==1)
            {
            	
            	
                this.Faza=2;
                SortCards(0);
                DrawCards(&dc);
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2,this.Speed[2] * 100);
            }
            if(this.Faza==4&&Razdavane2!=true&&this.Move!=true)
            {
            	
            	
                this.Faza=5;
                for(int f=1;f<=3;f++)this.CheckTerci(true,f);
                SortCards(1);
                DrawCards(&dc);
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3,this.Speed[2] * 100);
            }
        }
        else {
            if(strelka==0&&this.Move!=true&&this.P1puska>0&&P2puska>0&&P3puska>0)
            {
            	
                strelka=Strelka();
                if(strelka==1)kapo1=false;
                if(strelka==2)kapo2=false;
                if(strelka==3)kapo3=false;
                vz[this.hand]=strelka;
                m_pwin->InvalidateRectScale(CRect(StrelkaPos[strelka].x,StrelkaPos[strelka].y,StrelkaPos[strelka].x+34,StrelkaPos[strelka].y+34),false);
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4,this.Speed[4]",15)*100);
            }
            if(this.Move==true)
            {
                int x1=this.CardsPoint[Mcard].x;
                int y1=this.CardsPoint[Mcard].y;
                MoveCard(!Razdavane1 && !Razdavane2);
                m_pwin->InvalidateRectScale(CRect(x1,y1,x1+Width,y1+Height),false);
                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[Mcard].x,this.CardsPoint[Mcard].y,this.CardsPoint[Mcard].x+Width,this.CardsPoint[Mcard].y+Height),false);
            }
            if((Razdavane1==true||Razdavane2==true)&&this.Move!=true&&this.Faza<4)
            {
                int sc1=showrcard;
                int karta=KartaZaRazdavane();
                int sc2=showrcard;
                if(sc1!=sc2)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[0].x-2,this.CardsPoint[0].y-4,this.CardsPoint[0].x+Width,this.CardsPoint[0].y+Height),false);
                this.BeginMove(this.CardsPoint[karta],this.CardsPos[karta],karta);
            }
            if(Razdavane1!=true&&Razdavane2!=true&&this.Move!=true&&this.Faza==1)
            {
            	
            	
                this.Faza=2;
                SortCards(0);
                DrawCards(&dc);
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2,this.Speed[2] * 100);
            }
            if(this.Faza==4&&Razdavane2!=true&&this.Move!=true)
            {
            	
            	
                this.Faza=5;
                for(int f=1;f<=3;f++)this.CheckTerci(true,f);
                SortCards(1);
                DrawCards(&dc);
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3,this.Speed[2] * 100);
            }
        }
    }
}

void CBelot3Players::Timer3()
{
    if(!this.Move)
    {
        if(this.SP)
        {	
            if(this.Faza>4&&strelka<1&&this.KoiENaRed!=1&&this.hand<8&&(this.P1puska==0||P2puska==0||P3puska==0))
            {
                Intelect(this.KoiENaRed);
                for(let i: number =9;i<25;i++)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                goto end;
            }
            if(this.Faza>4&&strelka<1&&this.KoiENaRed==1&&this.hand<8&&this.P1puska==0)
            {
                if(automatic_release&&this.m_ann.start!=1)Pusk1KartaZaPuskanePl1();
                goto end;
            }
        }
        if(this.OP)
        {
            if(this.Server)
            {
                if(this.Faza>4&&strelka<1&&this.KoiENaRed!=1&&this.hand<8&&(P2puska==0||P3puska==0))
                {
                    if(((this.KoiENaRed==2&&this.P2OP&&P2puska==0)||(this.KoiENaRed==3&&P3OP&&P3puska==0))&&CheckMessage())
                    {
                    	
                        int ca=_ttoi(ReadMessage());
                        int card;
                    	
                        if(this.KoiENaRed==2)card=8+ca;
                        if(this.KoiENaRed==3)card=16+ca;
                        if(this.k[card]==0||ca==0) {
                            m_pwin->SendErrorToLog("Nevalidna Karta this.Server: "+this.IntToString(card)+" Hand="+this.IntToString(this.hand)+"Koi e na red="+this.IntToString(this.KoiENaRed));
                            goto end;
                        }
                        this.online_timer=this.online_timer_time;
                        this.AddToDetails(card);
                        if(this.KoiENaRed==2)P2puska=this.k[card];
                        if(this.KoiENaRed==3)P3puska=this.k[card];
                        this.CardsPoint[this.KoiENaRed+32]=this.CardsPoint[card];
                        this.CardP[32+this.KoiENaRed]=this.CardP[card];
                        if(this.CheckBelot(this.k[card],this.KoiENaRed)==true&&this.op_belot[this.hand])
                        {
                            this.ObiaviavaneBelot(this.KoiENaRed, true);
                            this.Belot[this.KoiENaRed][0]=this.Belot[this.KoiENaRed][0]+1;
                        }

                        int player=this.KoiENaRed;
                        if(player!=this.KoiPuskaParvi)
                        {
                            if(this.ParviPuska>10&&this.ParviPuska<20&&this.k[card]>20)this.Pniama[player][1]=true;
                            if(this.ParviPuska>20&&this.ParviPuska<30&&(this.k[card]<20||this.k[card]>30))this.Pniama[player][2]=true;
                            if(this.ParviPuska>30&&this.ParviPuska<40&&(this.k[card]<30||this.k[card]>40))this.Pniama[player][3]=true;
                            if(this.ParviPuska>40&&this.ParviPuska<50&&(this.k[card]<40||this.k[card]>50))this.Pniama[player][4]=true;
                            if(this.ParviPuska>100&&this.k[card]<100)this.Pniama[player][this.Naddavane]=true;
                            if(this.Naddavane==6&&this.KoiPuskaParvi==3)
                            {
                                if(this.ParviPuska==12&&this.k[card]==11)this.Pniama[player][1]=true;
                                if(this.ParviPuska==22&&this.k[card]==21)this.Pniama[player][2]=true;
                                if(this.ParviPuska==32&&this.k[card]==31)this.Pniama[player][3]=true;
                                if(this.ParviPuska==42&&this.k[card]==41)this.Pniama[player][4]=true;

                                if(this.ParviPuska==13&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
                                if(this.ParviPuska==23&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
                                if(this.ParviPuska==33&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
                                if(this.ParviPuska==43&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

                                if(this.ParviPuska==13&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
                                if(this.ParviPuska==23&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
                                if(this.ParviPuska==33&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
                                if(this.ParviPuska==43&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

                                if(this.ParviPuska==14&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
                                if(this.ParviPuska==24&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
                                if(this.ParviPuska==34&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
                                if(this.ParviPuska==44&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

                                if(this.ParviPuska==14&&this.CheckCard(13)==false&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
                                if(this.ParviPuska==24&&this.CheckCard(23)==false&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
                                if(this.ParviPuska==34&&this.CheckCard(33)==false&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
                                if(this.ParviPuska==44&&this.CheckCard(43)==false&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

                                if(this.ParviPuska==14&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==13)this.Pniama[player][1]=true;
                                if(this.ParviPuska==24&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==23)this.Pniama[player][2]=true;
                                if(this.ParviPuska==34&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==33)this.Pniama[player][3]=true;
                                if(this.ParviPuska==44&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==43)this.Pniama[player][4]=true;

                                if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.k[card]==11)this.Pniama[player][1]=true;
                                if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.k[card]==21)this.Pniama[player][2]=true;
                                if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.k[card]==31)this.Pniama[player][3]=true;
                                if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.k[card]==41)this.Pniama[player][4]=true;

                                if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(13)==false&&this.CheckCard(11)==false&&this.k[card]==12)this.Pniama[player][1]=true;
                                if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(23)==false&&this.CheckCard(21)==false&&this.k[card]==22)this.Pniama[player][2]=true;
                                if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(33)==false&&this.CheckCard(31)==false&&this.k[card]==32)this.Pniama[player][3]=true;
                                if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(43)==false&&this.CheckCard(41)==false&&this.k[card]==42)this.Pniama[player][4]=true;

                                if(this.ParviPuska==15&&this.CheckCard(14)==false&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==13)this.Pniama[player][1]=true;
                                if(this.ParviPuska==25&&this.CheckCard(24)==false&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==23)this.Pniama[player][2]=true;
                                if(this.ParviPuska==35&&this.CheckCard(34)==false&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==33)this.Pniama[player][3]=true;
                                if(this.ParviPuska==45&&this.CheckCard(44)==false&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==43)this.Pniama[player][4]=true;

                                if(this.ParviPuska==15&&this.CheckCard(13)==false&&this.CheckCard(12)==false&&this.CheckCard(11)==false&&this.k[card]==14)this.Pniama[player][1]=true;
                                if(this.ParviPuska==25&&this.CheckCard(23)==false&&this.CheckCard(22)==false&&this.CheckCard(21)==false&&this.k[card]==24)this.Pniama[player][2]=true;
                                if(this.ParviPuska==35&&this.CheckCard(33)==false&&this.CheckCard(32)==false&&this.CheckCard(31)==false&&this.k[card]==34)this.Pniama[player][3]=true;
                                if(this.ParviPuska==45&&this.CheckCard(43)==false&&this.CheckCard(42)==false&&this.CheckCard(41)==false&&this.k[card]==44)this.Pniama[player][4]=true;
                            }
                            if(this.Naddavane<5&&this.KoiPuskaParvi==3)
                            {
                                if(this.ParviPuska==102&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
                                if(this.ParviPuska==103&&this.CheckCard(102)==false&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
                                if(this.ParviPuska==103&&this.CheckCard(101)==false&&this.k[card]==102)this.Pniama[player][this.Naddavane]=true;
                                if(this.ParviPuska==104&&this.CheckCard(103)==false&&this.CheckCard(102)==false&&this.k[card]==101)this.Pniama[player][this.Naddavane]=true;
                                if(this.ParviPuska==104&&this.CheckCard(103)==false&&this.CheckCard(101)==false&&this.k[card]==102)this.Pniama[player][this.Naddavane]=true;
                                if(this.ParviPuska==104&&this.CheckCard(102)==false&&this.CheckCard(101)==false&&this.k[card]==103)this.Pniama[player][this.Naddavane]=true;
                            }
                        }
                        if(this.hand==0)
                        {
                            if(this.KoiENaRed==1) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                            if(this.KoiENaRed==2) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                            if(this.KoiENaRed==3) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                        }
                        this.BeginMove(this.CardsPoint[card],this.CardsPos[this.KoiENaRed+32],32+this.KoiENaRed);
                        if(this.KoiENaRed==2)
                        {
                            if(P3OP!=true)
                            {
                                this.props.m_pwin.KillTimer(3);
                                this.props.m_pwin.SetTimer(3,this.Speed[3] * 100);
                            }
                            for(let i: number =9;i<17;i++) {
                                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                            }
                        }
                        if(this.KoiENaRed==3)
                        {
                            this.props.m_pwin.KillTimer(3);
                            this.props.m_pwin.SetTimer(3,this.Speed[3] * 100);
                            for(let i: number =17;i<25;i++) {
                                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                            }
                        }
                        if(this.KoiPuskaParvi==1)
                        {
                            if(this.KoiENaRed==1)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==2)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==3)this.TretiPuska=this.k[card];
                        }
                        if(this.KoiPuskaParvi==2)
                        {
                            if(this.KoiENaRed==2)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==3)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==1)this.TretiPuska=this.k[card];
                        }
                        if(this.KoiPuskaParvi==3)
                        {
                            if(this.KoiENaRed==3)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==1)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==2)this.TretiPuska=this.k[card];
                        }
                        this.DeleteCard(card);
                        if(this.P1puska==0||P2puska==0||P3puska==0)
                        {
                            if(this.KoiENaRed<3)this.KoiENaRed=this.KoiENaRed+1;
                            else this.KoiENaRed=1;
                        }
                        goto end;
                    }
                    if(this.KoiENaRed==2&&this.P2OP!=true)
                    {
                        Intelect(2);
                        for(let i: number =9;i<17;i++) {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                        }
                        goto end;
                    }
                    if(this.KoiENaRed==3&&P3OP!=true)
                    {
                        Intelect(3);
                        for(let i: number =17;i<25;i++) {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                        }
                        goto end;
                    }
                }
                if(this.Faza>4&&strelka<1&&this.hand<8&&this.P1puska==0&&this.KoiENaRed==1&&this.Move!=true&&this.Faza<6)
                {
                    if(this.online_timer==0) {
                        sit_out_count=sit_out_count+1;
                        if(sit_out_count>=sit_out_drop)
                        {
                            m_pwin->AutoSitOut();
                            goto end;
                        }
                        AutoPuskKard();
                    }
                    else if(automatic_release==true&&this.KoiENaRed==1&&this.m_ann.start!=1)Pusk1KartaZaPuskanePl1();
                    goto end;
                }
            }
            else {
                if(this.watch) {
                    if(this.Faza>4&&strelka<1&&this.KoiENaRed==1&&this.hand<8&&this.Move!=true&&this.P1puska==0)
                    {
                        if(CheckMessage())
                        {
                        	
                            int ca=_ttoi(ReadMessage());
                            int card=ca;
                        	
                            if(this.k[card]==0||ca==0) {
                                m_pwin->SendErrorToLog("Nevalidna Karta Client: "+this.IntToString(ca)+" Hand="+this.IntToString(this.hand)+"Koi e na red="+this.IntToString(this.KoiENaRed));
                                goto end;
                            }
                            this.online_timer=this.online_timer_time;
                            this.AddToDetails(card);
                            this.P1puska=this.k[card];
                            this.CardsPoint[this.KoiENaRed+32]=this.CardsPoint[card];
                            this.CardP[32+this.KoiENaRed]=this.CardP[card];
                            this.PuskaneNaKarta(card);
                        }
                        goto end;
                    }
                }
                if(this.Faza>4&&strelka<1&&this.KoiENaRed!=1&&this.hand<8&&this.Move!=true&&((this.KoiENaRed==2&&P2puska==0)||(this.KoiENaRed==3&&P3puska==0)))
                {
                    if(CheckMessage())
                    {
                    	
                        int ca=_ttoi(ReadMessage());
                        int card;
                    	
                        if(this.KoiENaRed==2)card=8+ca;
                        if(this.KoiENaRed==3)card=16+ca;
                        if(this.k[card]==0||ca==0) {
                            m_pwin->SendErrorToLog("Nevalidna Karta Client: "+this.IntToString(ca)+" Hand="+this.IntToString(this.hand)+"Koi e na red="+this.IntToString(this.KoiENaRed));
                            goto end;
                        }
                        this.online_timer=this.online_timer_time;
                        this.AddToDetails(card);
                        if(this.KoiENaRed==2)P2puska=this.k[card];
                        if(this.KoiENaRed==3)P3puska=this.k[card];
                        this.CardsPoint[this.KoiENaRed+32]=this.CardsPoint[card];
                        this.CardP[32+this.KoiENaRed]=this.CardP[card];
                        if(this.CheckBelot(this.k[card],this.KoiENaRed)==true&&this.op_belot[this.hand])
                        {
                            this.ObiaviavaneBelot(this.KoiENaRed, true);
                            this.Belot[this.KoiENaRed][0]=this.Belot[this.KoiENaRed][0]+1;
                        }
                        if(this.hand==0)
                        {
                            if(this.KoiENaRed==1) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                            if(this.KoiENaRed==2) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                            if(this.KoiENaRed==3) {
                                for(let b: number=0;b<=1;b++) {
                                    if(this.PTerci[this.KoiENaRed][b]>0) {
                                        if(this.terci[this.KoiENaRed][b]) {
                                            if(!(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7)&&!(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16)&&!(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37))this.ObiaviavaneTerci(this.KoiENaRed,this.PTerci[this.KoiENaRed][b],b, true);
                                            if(b==0) {
                                                if(this.PTerci[this.KoiENaRed][0]>0&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<7&&this.PTerci[this.KoiENaRed][1]>0&&this.PTerci[this.KoiENaRed][1]<7) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,7,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>10&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<16&&this.PTerci[this.KoiENaRed][1]>10&&this.PTerci[this.KoiENaRed][1]<16) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,16,0, true);
                                                }
                                                if(this.PTerci[this.KoiENaRed][0]>30&&this.terci[this.KoiENaRed][0]&&this.terci[this.KoiENaRed][1]&&this.PTerci[this.KoiENaRed][0]<37&&this.PTerci[this.KoiENaRed][1]>30&&this.PTerci[this.KoiENaRed][1]<37) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed,37,0, true);
                                                }
                                            }
                                        }
                                        else this.PTerci[this.KoiENaRed][b]=0;
                                    }
                                }
                            }
                        }
                        this.BeginMove(this.CardsPoint[card],this.CardsPos[this.KoiENaRed+32],32+this.KoiENaRed);
                        if(this.KoiENaRed==2) {
                            for(let i: number =9;i<17;i++) {
                                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                            }
                        }
                        if(this.KoiENaRed==3) {
                            for(let i: number =17;i<25;i++) {
                                m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x,this.CardsPoint[i].y,this.CardsPoint[i].x+Width,this.CardsPoint[i].y+Height),false);
                            }
                        }
                        if(this.KoiPuskaParvi==1)
                        {
                            if(this.KoiENaRed==1)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==2)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==3)this.TretiPuska=this.k[card];
                        }
                        if(this.KoiPuskaParvi==2)
                        {
                            if(this.KoiENaRed==2)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==3)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==1)this.TretiPuska=this.k[card];
                        }
                        if(this.KoiPuskaParvi==3)
                        {
                            if(this.KoiENaRed==3)this.ParviPuska=this.k[card];
                            if(this.KoiENaRed==1)this.VtoriPuska=this.k[card];
                            if(this.KoiENaRed==2)this.TretiPuska=this.k[card];
                        }
                        this.DeleteCard(card);
                        if(this.P1puska==0||P2puska==0||P3puska==0)
                        {
                            if(this.KoiENaRed<3)this.KoiENaRed=this.KoiENaRed+1;
                            else this.KoiENaRed=1;
                        }
                    }
                    goto end;
                }
                if(!this.watch) {
                    if(this.Faza>4&&strelka<1&&this.hand<8&&this.P1puska==0&&this.KoiENaRed==1&&this.Move!=true&&this.Faza<6)
                    {
                        if(this.online_timer==0) {
                            sit_out_count=sit_out_count+1;
                            if(sit_out_count>=sit_out_drop)
                            {
                                m_pwin->AutoSitOut();
                                goto end;
                            }
                            AutoPuskKard();
                        }
                        else if(automatic_release==true&&this.KoiENaRed==1&&this.m_ann.start!=1)Pusk1KartaZaPuskanePl1();
                        goto end;
                    }
                }
            }
        }
    }
end:;
}

void CBelot3Players::Timer4()
{
    if(this.Move!=true)
    {
    	
        if (strelka > 0)
        {
            if (this.hand === 1)
            {
                m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x - 23) * scale, NamePos[KoiZapochva].y * scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(), NamePos[KoiZapochva].y * scale + who.GetHeight()));
                for (int i = 1; i <= Players; i++) {
                    for (int b = 0; b <= 1; b++) {
                        if (this.PTerci[i][b] > 0) {
                            m_pwin->InvalidateRectScale(CRect(PosTerci[i][b].x, PosTerci[i][b].y, PosTerci[i][b].x + 120, PosTerci[i][b].y + 25), false);
                        }
                    }
                }
            }
            for (int i = 1; i <= Players; i++) {
                if (this.Belot[i][1] === 1) {
                    m_pwin->InvalidateRectScale(CRect(PosBelot[i].x, PosBelot[i].y, PosBelot[i].x + 75, PosBelot[i].y + 25), false);
                }
            }
        	
        }
        if(this.SP)
        {
            if(strelka>0)
            {
                InitVziatka();
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, pApp->GetProfileInt("Options", "Speed[3]", 10) * 100, NULL);
                for (int i = 1; i <= Players; i++)m_pwin->InvalidateRectScale(CRect(StrelkaPos[i].x, StrelkaPos[i].y, StrelkaPos[i].x + 34, StrelkaPos[i].y + 34), false);
                for (int i = 33; i < 36; i++)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x, this.CardsPoint[i].y, this.CardsPoint[i].x + Width, this.CardsPoint[i].y + Height), false);
                for(let i: number =1;i<25;i++)
                {
                    if(this.k[i]!=0)goto end;
                }
                CString FName[4];
                for (int i = 1; i <= Players; i++) {
                    FName[i] = this.Name[i];
                }
                InitRezDlg();
                InitORezDlg(FName);
                m_pwin->InvalidateRectScale(CRect(this.CardsPos[0].x-2,this.CardsPos[0].y-4,this.CardsPos[0].x+Width,this.CardsPos[0].y+Height),false);
            }
        }
        if(this.OP)
        {
            if(strelka>0) {
                InitVziatka();
                for (int i = 1; i <= Players; i++)m_pwin->InvalidateRectScale(CRect(StrelkaPos[i].x, StrelkaPos[i].y, StrelkaPos[i].x + 34, StrelkaPos[i].y + 34), false);
                for (int i = 33; i < 36; i++)m_pwin->InvalidateRectScale(CRect(this.CardsPoint[i].x, this.CardsPoint[i].y, this.CardsPoint[i].x + Width, this.CardsPoint[i].y + Height), false);
                for(let i: number =1;i<9;i++)
                {
                    if(this.k[i]!=0)goto end;
                }
                this.Faza=7;
                CString FName[4];
                for (int i = 1; i <= Players; i++) {
                    FName[i] = this.Name[i];
                }
                InitRezDlg();
                InitORezDlg(FName);
                if (m_pwin->game !== "") {
                    if (CheckMessage()) {
                        if (!this.watch)m_pwin->SendErrorToLog("Ostanalo saobshtenie Karti:" + GetAllMessages());
                        ClearMessages();
                    }
                    if (CheckAMessage()) {
                        if (!this.watch)m_pwin->SendErrorToLog("Ostanalo saobshtenie Annonsi" + GetAllAMessages());
                        ClearAMessages();
                    }
                    if (!this.Server && !this.watch)this.SendGame("R");
                }
                m_pwin->InvalidateRectScale(CRect(this.CardsPos[0].x-2,this.CardsPos[0].y-4,this.CardsPos[0].x+Width,this.CardsPos[0].y+Height),true);
            }
        }
    }
end:;
}

void CBelot3Players::Timer7()
{
    if(!this.watch&&this.OP) {
        if(this.P2OP||wait_opplayers[2]||P3OP||wait_opplayers[3]||!this.Server) {
            if(pause_player!="") m_pwin->InvalidateRectScale(CRect(628,475,780, 505),false);
            if(this.online_timer>0)this.online_timer=this.online_timer-1;
            if(this.online_timer==5) {
                if(this.P2OP||wait_opplayers[2]||P3OP||wait_opplayers[3]||!this.Server) {
                    if(this.Faza>4&&strelka<1&&this.P1puska==0&&this.Move==false&&this.KoiENaRed==1) {
                        if(Zvuk[0]==1)m_sndSound.Play(1113,"center", 0,0);
                    }
                }
            }
        	
            m_pwin->InvalidateRectScale(CRect(676,475,732, 505),false);
            if(KoiObiaviava==0&&this.Faza==2&&this.BrPas!=3&&(this.BrPas!=2||this.Naddavane==0)) {
                if(this.online_timer==0) {
                    this.online_timer=this.online_timer_time;
                    m_n.klik_o=true;
                    m_n.OnOK();
                }
            }
            if(this.Faza==7) {
                if(this.online_timer==0) {
                    if(open_rez_dlg) {
                        if(this.m_rez.dlg_det_start) {
                            this.m_rez.dlg_det.OnOK();
                        }
                        this.m_rez.OnOK();
                        this.online_timer=this.online_timer_time;
                        goto end;
                    }
                    if(open_orez_dlg&&!open_rez_dlg) {
                        m_orez.OnOK();
                        this.online_timer=this.online_timer_time;
                        goto end;
                    }
                }
            }
        }
    }
end:;
}

void CBelot3Players::Timer2()
{
    if(this.Move!=true)
    {
        if(this.SP)
        {
            if(this.Faza==2&&Razdavane1!=true&&Razdavane2!=true&&this.Move!=true)
            {
                if(this.BrPas==3)
                {
                    if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                    if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                    m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x-23)*scale,NamePos[KoiZapochva].y*scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(),NamePos[KoiZapochva].y * scale + who.GetHeight()));
                    InitGame3();
                    for(let i: number =1;i<6;i++)
                    {
                        m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                    }
                    for(let i: number =9;i<14;i++)
                    {
                        m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                    }
                    for(let i: number =17;i<22;i++)
                    {
                        m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                    }
                    goto end;
                }
                if(this.BrPas==2&&this.Naddavane>0)
                {
                    if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                    if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                    this.Faza=3;
                    Razdavane2=true;
                    if(Zvuk[0]==1)m_sndSound.PlayFile("deal",false);
                    goto end;
                }
                if(KoiObiaviava==1&&this.Faza==2&&this.BrPas!=3&&(this.BrPas!=2||this.Naddavane==0))
                {
                	
                    m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                    KoiObiaviava=0;
                    m_n.naddavane=this.Naddavane;
                    if(this.Naddavane==0)
                    {
                        m_n.kontra=false;
                        m_n.rekontra=false;
                    }
                    if(this.Kontra==0&&this.Rekontra==0&&(this.Pkazal[2][this.Naddavane]==true||this.Pkazal[3][this.Naddavane]==true))
                    {
                        m_n.kontra=true;
                        m_n.rekontra=false;
                    }
                    if(this.Kontra==2||this.Kontra==3)
                    {
                        m_n.rekontra=true;
                        m_n.kontra=false;
                    }
                    if(this.Rekontra>0)
                    {
                        m_n.rekontra=false;
                        m_n.kontra=false;
                    }
                    ///////////////////////////////////////////////
                    AfxGetMainWnd ()->ShowWindow(SW_SHOWNORMAL);
                    m_n.DoModal();
                    ///////////////////////////////////////////////
                    if(m_n.m_pas==-1)
                    {
                        this.BrPas=this.BrPas+1;
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                    }
                    if(m_n.m_pas==0)
                    {
                        this.Pkazal[1][1]=true;
                        this.Naddavane=1;
                    }
                    if(m_n.m_pas==1)
                    {
                        this.Pkazal[1][2]=true;
                        this.Naddavane=2;
                    }
                    if(m_n.m_pas==2)
                    {
                        this.Pkazal[1][3]=true;
                        this.Naddavane=3;
                    }
                    if(m_n.m_pas==3)
                    {
                        this.Pkazal[1][4]=true;
                        this.Naddavane=4;
                    }
                    if(m_n.m_pas==4)
                    {
                        this.Pkazal[1][5]=true;
                        this.Naddavane=5;
                    }
                    if(m_n.m_pas==5)
                    {
                        this.Pkazal[1][6]=true;
                        this.Naddavane=6;
                    }
                    if(m_n.m_pas==6)
                    {
                        this.BrPas=0;
                        this.Kontra=1;
                        SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                    }
                    if(m_n.m_pas==7)
                    {
                        this.Rekontra=1;
                        this.lKontra=this.Kontra;
                        this.Kontra=0;
                        this.BrPas=0;
                        SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                    }
                    if(m_n.m_pas>-1&&m_n.m_pas<6)
                    {
                        SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                        this.Kontra=this.Rekontra=0;
                        this.BrPas=0;
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                    }
                    KoiObiaviava=2;
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2,this.Speed[2] * 100);
                    goto end;
                }
                if(KoiObiaviava>1)
                {
                    if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                    Obiaviavane(KoiObiaviava);
                }
            }
        }
        if(this.OP)
        {
            if(this.Server)
            {
                if(this.Faza==2&&Razdavane1!=true&&Razdavane2!=true&&this.Move!=true)
                {
                    if(this.BrPas==3)
                    {
                        if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                        if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                        m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x-23)*scale,NamePos[KoiZapochva].y*scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(),NamePos[KoiZapochva].y * scale + who.GetHeight()));
                        this.Faza=6;
                        for(let i: number =1;i<6;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        for(let i: number =9;i<14;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        for(let i: number =17;i<22;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x-23)*scale,NamePos[KoiZapochva].y*scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(),NamePos[KoiZapochva].y * scale + who.GetHeight()));
                        m_pwin->InvalidateRectScale(CRect(this.CardsPos[0].x-2,this.CardsPos[0].y-4,this.CardsPos[0].x+Width,this.CardsPos[0].y+Height),true);
                        goto end;
                    }
                    if(this.BrPas==2&&this.Naddavane>0)
                    {
                        if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                        if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                        this.Faza=3;
                        Razdavane2=true;
                        if(Zvuk[0]==1)m_sndSound.PlayFile("deal",false);
                        goto end;
                    }
                    if(KoiObiaviava==1&&this.Faza==2&&this.BrPas!=3&&(this.BrPas!=2||this.Naddavane==0))
                    {
                    	
                        m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                        KoiObiaviava=0;
                        m_n.naddavane=this.Naddavane;
                        if(this.Naddavane==0)
                        {
                            m_n.kontra=false;
                            m_n.rekontra=false;
                        }
                        if(this.Kontra==0&&this.Rekontra==0&&(this.Pkazal[2][this.Naddavane]==true||this.Pkazal[3][this.Naddavane]==true))
                        {
                            m_n.kontra=true;
                            m_n.rekontra=false;
                        }
                        if(this.Kontra==2||this.Kontra==3)
                        {
                            m_n.rekontra=true;
                            m_n.kontra=false;
                        }
                        if(this.Rekontra>0)
                        {
                            m_n.rekontra=false;
                            m_n.kontra=false;
                        }
                        this.online_timer=this.online_timer_time;
                        ///////////////////////////////////////////////
                        AfxGetMainWnd ()->ShowWindow(SW_SHOWNORMAL);
                        m_n.DoModal();
                        ///////////////////////////////////////////////
                        if(m_n.m_pas==-1)
                        {
                            this.SendGame("$|m|0");
                            this.BrPas=this.BrPas+1;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                        }
                        if(m_n.m_pas>=0) {
                            if(this.OP) {
                                if(m_orez.m_rez1==0&&m_orez.m_rez2==0&&m_orez.m_rez3==0) {
                                    if(!op_start) {
                                        this.SendGame("");
                                        op_start=true;
                                    }
                                }
                            }
                        }
                        if(m_n.m_pas==0)
                        {
                            this.SendGame("$|m|1");
                            this.Pkazal[1][1]=true;
                            this.Naddavane=1;
                        }
                        if(m_n.m_pas==1)
                        {
                            this.SendGame("$|m|2");
                            this.Pkazal[1][2]=true;
                            this.Naddavane=2;
                        }
                        if(m_n.m_pas==2)
                        {
                            this.SendGame("$|m|3");
                            this.Pkazal[1][3]=true;
                            this.Naddavane=3;
                        }
                        if(m_n.m_pas==3)
                        {
                            this.SendGame("$|m|4");
                            this.Pkazal[1][4]=true;
                            this.Naddavane=4;
                        }
                        if(m_n.m_pas==4)
                        {
                            this.SendGame("$|m|5");
                            this.Pkazal[1][5]=true;
                            this.Naddavane=5;
                        }
                        if(m_n.m_pas==5)
                        {
                            this.SendGame("$|m|6");
                            this.Pkazal[1][6]=true;
                            this.Naddavane=6;
                        }
                        if(m_n.m_pas==6)
                        {
                            this.Kontra=1;
                            this.BrPas=0;
                            if(GetLanguage()=="Turkish") 
                            {
                                SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
                            }
                            else 
                            {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                            this.SendGame("$|m|7");
                        }
                        if(m_n.m_pas==7)
                        {
                            this.Rekontra=1;
                            this.lKontra=this.Kontra;
                            this.Kontra=0;
                            this.BrPas=0;
                            if(GetLanguage()=="Turkish") 
                            {
                                SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
                            }
                            else 
                            {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                            this.SendGame("$|m|8");
                        }
                        if(m_n.m_pas>-1&&m_n.m_pas<6)
                        {
                            if(GetLanguage()=="Turkish") 
                            {	
                                SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(this.Naddavane),Title2);
                            }
                            else {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                            this.Kontra=0;
                            this.Rekontra=0;
                            this.BrPas=0;
                        }
                        KoiObiaviava=2;
                        if(this.P2OP!=true)
                        {
                            this.props.m_pwin.KillTimer(2);
                            this.props.m_pwin.SetTimer(2,this.Speed[2] * 100);
                        }
                        goto end;
                    }
                    if(KoiObiaviava>1)
                    {
                        if(((KoiObiaviava==2&&this.P2OP)||(KoiObiaviava==3&&P3OP))&&CheckAMessage())
                        {
                            int Nadd=_ttoi(ReadAMessage());
                            if(((Nadd==7||Nadd==8)||this.Kontra==0&&Nadd==8)&&this.Naddavane==0) {
                                m_pwin->SendErrorToLog("Obiaviavane na annons (this.Server):"+this.IntToString(Nadd)+" sled pas");
                                goto end;
                            }
                            if(this.Naddavane>0&&Nadd>0&&Nadd<this.Naddavane) {
                                m_pwin->SendErrorToLog("Obiaviavane na greshen annons (this.Server):"+this.IntToString(Nadd)+" sled "+this.IntToString(this.Naddavane));
                            }
                            if(Nadd==0)
                            {
                                this.BrPas=this.BrPas+1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(0));
                            }
                            if(Nadd>0&&Nadd<7)
                            {
                                this.BrPas=0;
                                this.Naddavane=Nadd;
                                this.Kontra=0;
                                this.Rekontra=0;
                                this.Pkazal[KoiObiaviava][Nadd]=true;
                                if(GetLanguage()=="Turkish") 
                                {	
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(Nadd),Title2);
                                }
                                else {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(Nadd)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(Nadd));
                            }
                            if(Nadd==7)
                            {
                                this.BrPas=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(7));
                                this.Kontra=KoiObiaviava;
                            }
                            if(Nadd==8)
                            {
                                this.BrPas=0;
                                this.lKontra=this.Kontra;
                                this.Kontra=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(8));
                                this.Rekontra=KoiObiaviava;
                            }
                            Obiaviavane(KoiObiaviava,Nadd);
                            goto end;
                        }
                        if(KoiObiaviava==2&&this.P2OP!=true)
                        {
                        //	m_pwin->InvalidateRectScale(CRect(PosNaddavane[1].x,PosNaddavane[1].y,PosNaddavane[1].x+150,PosNaddavane[1].y+25),false);
                            if(CheckAMessage()) {
                                int anons=_ttoi(ReadAMessage());
                                Obiaviavane(KoiObiaviava,anons);
                                m_pwin->SendErrorToLog("Obiaviavane ot izliazal Client PL2 LU:"+last_Name[2]);
                            }
                            else {
                                int anons=Anonsi(KoiObiaviava);
                                CString ano=this.IntToString(anons);
                                this.SendGame("$|m|"+ano);
                                Obiaviavane(KoiObiaviava,anons);
                            }
                            goto end;
                        }
                        if(KoiObiaviava==3&&P3OP!=true)
                        {
                            m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+25),false);
                            if(CheckAMessage()) {
                                int anons=_ttoi(ReadAMessage());
                                Obiaviavane(KoiObiaviava,anons);
                                m_pwin->SendErrorToLog("Obiaviavane ot izliazal Client PL3 LU:"+last_Name[3]);
                            }
                            else {
                                int anons=Anonsi(KoiObiaviava);
                                CString ano=this.IntToString(anons);
                                this.SendGame("$|m|"+ano);
                                Obiaviavane(KoiObiaviava,anons);
                            }
                            goto end;
                        }
                //		if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                //		Obiaviavane(KoiObiaviava);
                    }
                }
            }
            else
            {
                if(this.Faza==2&&Razdavane1!=true&&Razdavane2!=true)
                {
                    if(this.BrPas==3)
                    {
                        if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                        if(KoiObiaviava==2)m_pwin->InvalidateRectScale(CRect(PosNaddavane[1].x,PosNaddavane[1].y,PosNaddavane[1].x+150,PosNaddavane[1].y+50),false);
                        if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                        m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x-23)*scale,NamePos[KoiZapochva].y*scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(),NamePos[KoiZapochva].y * scale + who.GetHeight()));
                        this.Faza=6;
                        for(let i: number =1;i<6;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        for(let i: number =9;i<14;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        for(let i: number =17;i<22;i++)
                        {
                            m_pwin->InvalidateRectScale(CRect(this.CardsPos[i].x,this.CardsPos[i].y,this.CardsPos[i].x+Width,this.CardsPos[i].y+Height),false);
                        }
                        m_pwin->InvalidateRect(CRect((NamePos[KoiZapochva].x-23)*scale,NamePos[KoiZapochva].y*scale, (NamePos[KoiZapochva].x - 23) * scale + who.GetWidth(),NamePos[KoiZapochva].y * scale + who.GetHeight()));
                        m_pwin->InvalidateRectScale(CRect(this.CardsPos[0].x-2,this.CardsPos[0].y-4,this.CardsPos[0].x+Width,this.CardsPos[0].y+Height),true);
                        if(!this.watch)this.SendGame("R");
                        goto end;
                    }
                    if(this.BrPas==2&&this.Naddavane>0)
                    {
                        if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                        if(KoiObiaviava==2)m_pwin->InvalidateRectScale(CRect(PosNaddavane[1].x,PosNaddavane[1].y,PosNaddavane[1].x+150,PosNaddavane[1].y+50),false);
                        if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                        this.Faza=3;
                        Razdavane2=true;
                        if(Zvuk[0]==1)m_sndSound.PlayFile("deal",false);
                        goto end;
                    }
                    if(this.watch&&KoiObiaviava==1&&this.Faza==2&&this.BrPas!=3&&(this.BrPas!=2||this.Naddavane==0))
                    {
                        if(CheckAMessage()) {
                            int Nadd=_ttoi(ReadAMessage());
                            if((Nadd==7||Nadd==8)&&this.Naddavane==0) {
                                m_pwin->SendErrorToLog("Obiaviavane na annons (Client):"+this.IntToString(Nadd)+" sled pas");
                                goto end;
                            }
                            if(this.Naddavane>0&&Nadd>0&&Nadd<this.Naddavane) {
                                m_pwin->SendErrorToLog("Obiaviavane na greshen annons (Client):"+this.IntToString(Nadd)+" sled "+this.IntToString(this.Naddavane));
                                goto end;
                            }
                            if(Nadd==0)
                            {
                                this.BrPas=this.BrPas+1;
                            }
                            if(Nadd>0&&Nadd<7)
                            {
                                this.BrPas=0;
                                this.Naddavane=Nadd;
                                this.Kontra=0;
                                this.Rekontra=0;
                                this.Pkazal[KoiObiaviava][Nadd]=true;
                                if(GetLanguage()=="Turkish") 
                                {	
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(Nadd),Title2);
                                }
                                else {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(Nadd)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                            }
                            if(Nadd==7)
                            {
                                this.BrPas=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                                this.Kontra=KoiObiaviava;
                            }
                            if(Nadd==8)
                            {
                                this.BrPas=0;
                                this.lKontra=this.Kontra;
                                this.Kontra=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                                }
                                this.Rekontra=KoiObiaviava;
                            }
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(Nadd));
                            Obiaviavane(KoiObiaviava,Nadd);
                            m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+25),false);
                            goto end;
                        }
                    }
                    if(!this.watch) {
                        if(KoiObiaviava==1&&this.Faza==2&&this.BrPas!=3&&(this.BrPas!=2||this.Naddavane==0))
                        {
                        	
                            m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+50),false);
                            KoiObiaviava=0;
                            m_n.naddavane=this.Naddavane;
                            if(this.Naddavane==0)
                            {
                                m_n.kontra=false;
                                m_n.rekontra=false;
                            }
                            if(this.Kontra==0&&this.Rekontra==0&&(this.Pkazal[2][this.Naddavane]==true||this.Pkazal[3][this.Naddavane]==true))
                            {
                                m_n.kontra=true;
                                m_n.rekontra=false;
                            }
                            if(this.Kontra==2||this.Kontra==3)
                            {
                                m_n.rekontra=true;
                                m_n.kontra=false;
                            }
                            if(this.Rekontra>0)
                            {
                                m_n.rekontra=false;
                                m_n.kontra=false;
                            }
                            ///////////////////////////////////////////////
                            this.online_timer=this.online_timer_time;
                            AfxGetMainWnd ()->ShowWindow(SW_SHOWNORMAL);
                            m_n.DoModal();
                            ///////////////////////////////////////////////
                            CString message="$|m|";
                            if(m_n.m_pas==-1)
                            {
                                this.SendGame(message+"0");
                                this.BrPas=this.BrPas+1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                            }
                            if(m_n.m_pas>=0) {
                                if(this.OP) {
                                    if(m_orez.m_rez1==0&&m_orez.m_rez2==0&&m_orez.m_rez3==0) {
                                        if(!op_start) {
                                            this.SendGame("");
                                            op_start=true;
                                        }
                                    }
                                }
                            }
                            if(m_n.m_pas==0)
                            {
                                this.SendGame(message+"1");
                                this.Pkazal[1][1]=true;
                                this.Naddavane=1;
                            }
                            if(m_n.m_pas==1)
                            {
                                this.SendGame(message+"2");
                                this.Pkazal[1][2]=true;
                                this.Naddavane=2;
                            }
                            if(m_n.m_pas==2)
                            {
                                this.SendGame(message+"3");
                                this.Pkazal[1][3]=true;
                                this.Naddavane=3;
                            }
                            if(m_n.m_pas==3)
                            {
                                this.SendGame(message+"4");
                                this.Pkazal[1][4]=true;
                                this.Naddavane=4;
                            }
                            if(m_n.m_pas==4)
                            {
                                this.SendGame(message+"5");
                                this.Pkazal[1][5]=true;
                                this.Naddavane=5;
                            }
                            if(m_n.m_pas==5)
                            {
                                this.SendGame(message+"6");
                                this.Pkazal[1][6]=true;
                                this.Naddavane=6;
                            }
                            if(m_n.m_pas==6)
                            {
                                this.Kontra=1;
                                this.BrPas=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                                this.SendGame(message+"7");
                            }
                            if(m_n.m_pas==7)
                            {
                                this.Rekontra=1;
                                this.lKontra=this.Kontra;
                                this.Kontra=0;
                                this.BrPas=0;
                                if(GetLanguage()=="Turkish") 
                                {
                                    SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else 
                                {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                                }
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                                this.SendGame(message+"8");
                            }
                            if(m_n.m_pas>-1&&m_n.m_pas<6)
                            {
                                if(GetLanguage()=="Turkish") 
                                {	
                                    SetTitle(this.Name[1]+" "+GetLanguageText("declared by")+" "+this.GameName(this.Naddavane),Title2);
                                }
                                else {
                                    SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("declared by")+" "+this.Name[1],Title2);
                                }
                                this.Kontra=0;
                                this.Rekontra=0;
                                this.BrPas=0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane));
                            }
                            KoiObiaviava=2;
                            goto end;
                        }
                    }
                    if(KoiObiaviava>1&&CheckAMessage())
                    {
                        if(KoiObiaviava==2)m_pwin->InvalidateRectScale(CRect(PosNaddavane[1].x,PosNaddavane[1].y,PosNaddavane[1].x+150,PosNaddavane[1].y+25),false);
                        if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+25),false);
                        if(KoiObiaviava==1)m_pwin->InvalidateRectScale(CRect(PosNaddavane[3].x,PosNaddavane[3].y,PosNaddavane[3].x+150,PosNaddavane[3].y+25),false);
                        int Nadd=_ttoi(ReadAMessage());
                        if((Nadd==7||Nadd==8)&&this.Naddavane==0) {
                            m_pwin->SendErrorToLog("Obiaviavane na annons (Client):"+this.IntToString(Nadd)+" sled pas");
                            goto end;
                        }
                        if(this.Naddavane>0&&Nadd>0&&Nadd<this.Naddavane) {
                            m_pwin->SendErrorToLog("Obiaviavane na greshen annons (Client):"+this.IntToString(Nadd)+" sled "+this.IntToString(this.Naddavane));
                            goto end;
                        }
                        if(Nadd==0)
                        {
                            this.BrPas=this.BrPas+1;
                        }
                        if(Nadd>0&&Nadd<7)
                        {
                            this.BrPas=0;
                            this.Naddavane=Nadd;
                            this.Kontra=0;
                            this.Rekontra=0;
                            this.Pkazal[KoiObiaviava][Nadd]=true;
                            if(GetLanguage()=="Turkish") 
                            {	
                                SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(Nadd),Title2);
                            }
                            else {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(Nadd)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                            }
                        }
                        if(Nadd==7)
                        {
                            this.BrPas=0;
                            if(GetLanguage()=="Turkish") 
                            {
                                SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(7)+" "+this.GameName(this.Naddavane),Title2);
                            }
                            else 
                            {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(7)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                            }
                            this.Kontra=KoiObiaviava;
                        }
                        if(Nadd==8)
                        {
                            this.BrPas=0;
                            this.lKontra=this.Kontra;
                            this.Kontra=0;
                            if(GetLanguage()=="Turkish") 
                            {
                                SetTitle(this.Name[KoiObiaviava]+" "+GetLanguageText("declared by")+" "+this.GameName(8)+" "+this.GameName(this.Naddavane),Title2);
                            }
                            else 
                            {
                                SetTitle(GetLanguageText("A game of")+" "+this.GameName(this.Naddavane)+" "+GetLanguageText("with")+" "+this.GameName(8)+" "+GetLanguageText("declared by")+" "+this.Name[KoiObiaviava],Title2);
                            }
                            this.Rekontra=KoiObiaviava;
                        }
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[KoiObiaviava], this.GameName(Nadd));
                        Obiaviavane(KoiObiaviava,Nadd);
                        goto end;
                    //	if(KoiObiaviava==3)m_pwin->InvalidateRectScale(CRect(PosNaddavane[2].x,PosNaddavane[2].y,PosNaddavane[2].x+150,PosNaddavane[2].y+50),false);
                    //	Obiaviavane(KoiObiaviava);
                    }
                }
            }
        }
    }
end:;
}

void CBelot3Players::InitNewGame()
{
    op_start=false;
    m_orez.m_rez1=0;m_orez.m_rez2=0;m_orez.m_rez3=0;
    Visiashti=0;
    for(let i: number =0;i<3;i++)
    {
        for(let a: number =0;a<100;a++)
        {
            m_orez.orez[i][a]=-10;
        }
    }
    pause=0;
    pause_player="";
    if(this.SP==true)InitGame3();
    for(let i: number =1;i<=3;i++) {
        for(let a: number =0;a<2;a++) {
            this.PTerci[i][a]=0;
        }
        last_Name[i]=this.Name[i];
    }
    m_pwin->pFrame->m_info_bar.AddNewGame();
}

int CBelot3Players::GetPlayerPosition()
{
    int pos=1;
    if(m_orez.m_rez1<m_orez.m_rez2)pos=pos+1;
    if(m_orez.m_rez1<m_orez.m_rez3)pos=pos+1;
    return pos;
}

void CBelot3Players::InitORezDlg(CString FName[4])
{
    if(!this.watch) {
        if(this.OP) {
            if(m_orez.m_rez1==0&&m_orez.m_rez2==0&&m_orez.m_rez3==0) {
                if(!op_start) {
                    this.SendGame("");
                    op_start=true;
                }
            }
        }
        for(let a: number =0;a<30;a++)
        {
            if(m_orez.orez[0][a]==-10)
            {
                m_orez.orez[0][a]=this.m_rez.m_crez1;
                m_orez.orez[1][a]=this.m_rez.m_crez2;
                m_orez.orez[2][a]=this.m_rez.m_crez3;
                goto next;
            }
        }
next:;
        m_orez.m_rez1=m_orez.m_rez2=m_orez.m_rez3=0;
        if (this.OP && !this.watch) {
            if (this.Server) {
                if (HaveWatch) {
                    SendResult(1);
                }
            }
        }
        for(let i: number =0;i<3;i++)
        {
            for(let a: number =0;a<30;a++)
            {
                if(m_orez.orez[i][a]>-10)
                {
                    if(i==0)
                    {
                        m_orez.m_rez1=m_orez.m_rez1+m_orez.orez[i][a];
                    }
                    if(i==1)
                    {
                        m_orez.m_rez2=m_orez.m_rez2+m_orez.orez[i][a];
                    }
                    if(i==2)
                    {
                        m_orez.m_rez3=m_orez.m_rez3+m_orez.orez[i][a];
                    }
                }
            }
        }
        open_orez_dlg=true;
        for(let i: number =1;i<4;i++)m_orez.Player[i]=FName[i];
        if(this.OP) {
            CString rezult="X|"+this.IntToString(m_orez.m_rez1)+" : "+this.IntToString(m_orez.m_rez2)+" : "+this.IntToString(m_orez.m_rez3);
            this.SendGame(rezult);
        }
        if(m_orez.dlg) {
            m_orez.UpdateData(false);
            m_orez.UpdateData(true);
            m_orez.m_list.DeleteAllItems();
            m_orez.AddRezults();
            m_orez.Invalidate();
        }
        else {
            m_orez.DoModal();
        }
        open_orez_dlg=false;
        if(m_orez.m_rez1<151&&m_orez.m_rez2<151&&m_orez.m_rez3<151)
        {
            if(this.SP)InitGame3();
        }
        else
        {
            if((kapo1&&kapo2)||(kapo1&&kapo3)||(kapo2&&kapo3))
            {
                COK m_ok=new COK();
                m_ok.SetTitle(GetLanguageText("You can't end the game with capot."));
                m_ok.DoModal();
                if(this.SP==true)InitGame3();
                goto end2;
            }
            if((m_orez.m_rez1==m_orez.m_rez2&&m_orez.m_rez1>151)||(m_orez.m_rez1==m_orez.m_rez3&&m_orez.m_rez1>151)||(m_orez.m_rez3==m_orez.m_rez2&&m_orez.m_rez3>151))
            {
                COK m_ok=new COK();
                m_ok.SetTitle(GetLanguageText("The score is even. There will be one more deal."));
                m_ok.DoModal();
                if(this.SP==true)InitGame3();
                goto end2;
            }
        	
            CString b3_rezult=pApp->GetProfileString("Options", "Belot3","");
            CString crezult="";
            CTime time=CTime::GetCurrentTime();
            CString ctime="<";
            CString day=time.GetDay() < 10 ? "0" + this.IntToString(time.GetDay()) : this.IntToString(time.GetDay());
            CString month=time.GetMonth() < 10 ? "0" + this.IntToString(time.GetMonth()) : this.IntToString(time.GetMonth());
            CString year=this.IntToString(time.GetYear());
            CString hour=time.GetHour()<10 ? "0" + this.IntToString(time.GetHour()) : this.IntToString(time.GetHour());
            CString minute=time.GetMinute()<10 ? "0" + this.IntToString(time.GetMinute()) : this.IntToString(time.GetMinute());
            ctime=ctime+day+"."+month+"."+year+" "+hour+":"+minute+">";
            CString player1=FName[1];
            CString player2=FName[2];
            CString player3=FName[3];
            crezult=ctime+" "+player1+" "+GetLanguageText("against")+" "+player2+" "+GetLanguageText("against")+" "+player3+" ";
            crezult=crezult+GetLanguageText("scoring")+" : "+this.IntToString(m_orez.m_rez1)+" - "+this.IntToString(m_orez.m_rez2)+" - "+this.IntToString(m_orez.m_rez3);
            if(m_orez.m_rez1>=m_orez.m_rez2&&m_orez.m_rez1>=m_orez.m_rez3) {
                crezult=crezult+"w;";
            }
            else {
                crezult=crezult+"l;";
            }
            pApp->WriteProfileString("Options", "Belot3", b3_rezult+crezult);
            if(m_orez.m_rez1>m_orez.m_rez2&&m_orez.m_rez1>m_orez.m_rez3)
            {
                m_orez.m_pobedi=m_orez.m_pobedi+1;
                if(this.OP) {
                    CString rezult="$|n|"+this.IntToString(GetPlayerPosition())+"|"+this.IntToString(m_orez.m_rez1)+" : "+this.IntToString(m_orez.m_rez2)+" : "+this.IntToString(m_orez.m_rez3);
                    this.SendGame(rezult);
                }
                COK m_ok=new COK();
                m_ok.SetTitle(GetLanguageText("The game profit2")+" "+ FName[1]);
                m_ok.DoModal();
            }
            if(m_orez.m_rez2>m_orez.m_rez1&&m_orez.m_rez2>m_orez.m_rez3)
            {
                m_orez.m_zagubi=m_orez.m_zagubi+1;
                if(this.OP) {
                    CString rezult="$|n|"+this.IntToString(GetPlayerPosition())+"|"+this.IntToString(m_orez.m_rez1)+" : "+this.IntToString(m_orez.m_rez2)+" : "+this.IntToString(m_orez.m_rez3);
                    this.SendGame(rezult);
                }
                COK m_ok=new COK();
                m_ok.SetTitle(GetLanguageText("The game profit2")+" "+ FName[2]);
                m_ok.DoModal();
            }
            if(m_orez.m_rez3>m_orez.m_rez1&&m_orez.m_rez3>m_orez.m_rez2)
            {
                m_orez.m_zagubi=m_orez.m_zagubi+1;
                if(this.OP) {
                    CString rezult="$|n|"+this.IntToString(GetPlayerPosition())+"|"+this.IntToString(m_orez.m_rez1)+" : "+this.IntToString(m_orez.m_rez2)+" : "+this.IntToString(m_orez.m_rez3);
                    this.SendGame(rezult);
                }
                COK m_ok=new COK();
                m_ok.SetTitle(GetLanguageText("The game profit2")+" "+ FName[3]);
                m_ok.DoModal();
            }
            if(this.OP) {
                CString rezult="X|0 : 0 : 0";
                this.SendGame(rezult);
            }
            CYESNO m_yesno=new CYESNO();
            m_yesno.SetTitle(GetLanguageText("New Game"));
            if(m_yesno.DoModal()==IDOK) {
                InitNewGame();
                if (this.OP && !this.watch) {
                    if (this.Server) {
                        if (HaveWatch) {
                            SendResult(1);
                        }
                    }
                }
            }
            else {
                if(this.OP) {
                    m_pwin->GoToLobby();
                }
                else {
                    m_pwin->OnNewGame();
                }
                goto end;
            }
        }
end2:;
        if(this.OP&&!this.watch) {
            this.Faza=6;
            if(this.Server) {
                for(let i: number =2;i<=3;i++) {
                    if(wait_opplayers[i]) {
                        this.Name[i]=wait_opnames[i];
                        show_user[i]=false;
                        m_pwin->GetPlayerRating(i);
                        if(i==2) {
                            this.P2OP=true;
                            P2G=true;
                        }
                        if(i==3) {
                            P3OP=true;
                            P3G=true;
                        }
                        SendResult(i);
                        CString message="$|v|"+this.IntToString(i);
                        this.SendGame(message);
                        InvalidateNames(i);
                    }
                }
                for(let i: number =2;i<=3;i++) {
                    if(wait_opplayers[i]) {
                        CString new_name="$|w|"+this.IntToString(i)+"|"+wait_opnames[i];
                        this.SendGame(new_name);
                        wait_opplayers[i]=false;
                        wait_opnames[i]="";
                    }				
                }
            }
        }
        InvalidatePoints();
    }
end:;
}

void CBelot3Players::Pusk1KartaZaPuskanePl1()
{
    if(this.m_ann.start==1)goto end;
    int vc = 0;
    int pc = 0;
    if ((this.KoiENaRed === 1 && this.P1puska === 0))
    {
        for (int i = 1; i <= 8; i++) {
            if (this.ProverkaCursor(i))
            {
                if (this.k[1] !== 0 && this.k[i] !== 0) {
                    vc = vc + 1;
                    pc = i;
                }
            }
        }
    }
    if (vc === 1 && pc > 0) {
        if (this.ProverkaCursor(pc))
        {
            if (this.k[pc] !== 0) {
                if (this.SP === true)this.PuskaneNaKarta(pc);
                if (this.OP === true)this.PuskaneNaKartaOP(pc);
                goto end;
            }
        }
    }
end:;
}
int CBelot3Players::IntelectTrumps(int Player)
{
//	SYSTEMTIME systime;
//	GetSystemTime(&systime);
//	int ms=systime.wMilliseconds;
//	srand( ms );
    int k1[25];//kopie na kartite koeto moaga da obrabotvam
    int Card11=-1;
    int Broi=0;//broia na ostava6tite karti
    int BroiOP[5]={0,0,0,0,0};//broia po boi na kartite na 2-mata dr igra4i
    int BroiBoia[5]={0,0,0,0,0};//broia po boi na kartite na igra4a
    int MyCards[5][8][2];//vsi4ki karti sortirani i info kolko po golemi karti ot dadena karta ima
    for(let i: number =0;i<5;i++)
    {
        for(int j=0;j<8;j++)
        {
            MyCards[i][j][0]=0;
            MyCards[i][j][1]=0;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        k1[i]=this.k[i]-2;
    }
    for(let i: number =((Player-1)*8)+1;i<=Player*8;i++)
    {
        if(this.k[i]>0)
        {
            MyCards[k1[i]/10][BroiBoia[k1[i]/10]][0]=k1[i];
            BroiBoia[k1[i]/10]++;
            Broi++;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            if(this.k[i]>0)
            {
                BroiOP[k1[i]/10]++;
            }
        }
    }
    //////
    for(let i: number =1;i<5;i++)
    {
        for(int j=0;j<6;j++)
        {
            for(int k11=j+1;k11<6;k11++)
            {
                if(MyCards[i][j][0]<MyCards[i][k11][0])
                {
                    int temp=MyCards[i][j][0];
                    MyCards[i][j][0]=MyCards[i][k11][0];
                    MyCards[i][k11][0]=temp;
                }
            }
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            for(int j=0;j<6;j++)
            {
                if(k1[i]>MyCards[k1[i]/10][j][0] && MyCards[k1[i]/10][j][0]!=0)
                {
                    if(!Niama[Player][k1[i]/10])
                    {
                        MyCards[k1[i]/10][j][1]++;
                    }
                }
            }
        }
    }
    int ParviPuska1,VtoriPuska1;
    if(this.ParviPuska>0) ParviPuska1=this.ParviPuska-2;
    else ParviPuska1=this.ParviPuska;
    if(this.VtoriPuska>0) VtoriPuska1=this.VtoriPuska-2;
    else VtoriPuska1=this.VtoriPuska;
    ////
    if(ParviPuska1==0)//igra4ut igrae parvi
    {
        //igrae si dulgite boi
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]<=2 && MyCards[i][0][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=3 && MyCards[i][0][1]==0 && MyCards[i][1][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=3 && MyCards[i][0][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=2 && MyCards[i][0][1]>0)
                {
                    Card11=MyCards[i][BroiBoia[i]-1][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(MyCards[i][0][1]==0 && BroiBoia[i]>=1)
            {
                Card11=MyCards[i][0][0];
                goto end;	
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(MyCards[i][0][1]==1 && BroiBoia[i]>=2)
            {
                Card11=MyCards[i][BroiBoia[i]-1][0];
                goto end;	
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=1)
            {
                Card11=MyCards[i][BroiBoia[i]-1][0];
                goto end;	
            }
        }
    }
    if(ParviPuska1>0 && VtoriPuska1==0)//igra4ut igrae vtori
    {
        if(BroiBoia[ParviPuska1/10]>0)
        {
            if(MyCards[ParviPuska1/10][0][0]>ParviPuska1)
            {
                if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[this.ParviPuska/10]>=2)
                {	
                    if(Player==2)//ako sledva6ti niama ot boiata
                    {
                        if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==3)
                    {
                        if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==2)//ako ne se e ka4il na po malka karta
                    {
                        if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][0]>NeKa4ena[3][ParviPuska1 / 10] && NeKa4ena[3][ParviPuska1 / 10]>0 &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==3)
                    {
                        if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][0]>NeKa4ena[1][ParviPuska1 / 10] && NeKa4ena[1][ParviPuska1 / 10]>0 &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==2)//ako e iz4istil po goliama karta ot nujnata
                    {
                        if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==3)
                    {
                        if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(MyCards[ParviPuska1/10][1][1]==0)
                    {
                        Card11=MyCards[ParviPuska1/10][0][0];
                        goto end;
                    }
                    if(MyCards[ParviPuska1/10][1][1]==1)
                    {
                        int ccc=rand()%2;
                        if(ccc==0)
                        {
                            Card11=MyCards[ParviPuska1/10][1][0];
                            goto end;
                        }
                    }
                }
                Card11=MyCards[ParviPuska1/10][0][0];
                goto end;
            }
            else
            {
                Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                goto end;
            }
        }
        else
        {
            Card11=Chistene(Player,k1);
            goto end;
        }
    }
    if(VtoriPuska1>0)//igra4ut igrae treti
    {
        if(BroiBoia[ParviPuska1/10]>0)
        {

            if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && 
                (MyCards[ParviPuska1/10][0][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
            {
                if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[ParviPuska1/10]>=2 &&
                    (MyCards[ParviPuska1/10][1][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                {
                    if(MyCards[ParviPuska1/10][0][1]==0)
                    {
                        for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                        {
                            if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                            {
                                Card11=MyCards[ParviPuska1/10][w][0];
                                goto end;
                            }
                        }
                    }
                    if(MyCards[ParviPuska1/10][0][1]!=0)
                    {
                        if(BroiBoia[ParviPuska1/10]>=3)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    Card11=MyCards[ParviPuska1/10][0][0];
                    goto end;
                }
                Card11=MyCards[ParviPuska1/10][0][0];
                goto end;
            }
            else
            {
                Card11=MyCards[this.ParviPuska/10][BroiBoia[this.ParviPuska/10]-1][0];
                goto end;
            }
            Card11=MyCards[this.ParviPuska/10][BroiBoia[this.ParviPuska/10]-1][0];
            goto end;
        }
        else
        {
            Card11=Chistene(Player,k1);
            goto end;
        }
    }
end:;
    for(let i: number =1;i<25;i++)
    {
        if(k1[i] === Card11)
        {
            return i;
        }
    }
    return 0;
}
int CBelot3Players::Chistene(int Player,int k1[25])
{
    /////////////////////////
    int Broi=0;//broia na ostava6tite karti
    int BroiOP[5]={0,0,0,0,0};//broia po boi na kartite na 2-mata dr igra4i
    int BroiBoia[5]={0,0,0,0,0};//broia po boi na kartite na igra4a
    int MyCards[5][8][2];//vsi4ki karti sortirani i info kolko po golemi karti ot dadena karta ima
    for(let i: number =0;i<5;i++)
    {
        for(int j=0;j<8;j++)
        {
            MyCards[i][j][0]=0;
            MyCards[i][j][1]=0;
        }
    }
    for(let i: number =((Player-1)*8)+1;i<=Player*8;i++)
    {
        if(k1[i]>0)
        {
            MyCards[k1[i]/10][BroiBoia[k1[i]/10]][0]=k1[i];
            BroiBoia[k1[i]/10]++;
            Broi++;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            if(k1[i]>0)
            {
                BroiOP[k1[i]/10]++;
            }
        }
    }
    //////
    for(let i: number =1;i<5;i++)
    {
        for(int j=0;j<6;j++)
        {
            for(int k11=j+1;k11<6;k11++)
            {
                if(MyCards[i][j][0]<MyCards[i][k11][0])
                {
                    int temp=MyCards[i][j][0];
                    MyCards[i][j][0]=MyCards[i][k11][0];
                    MyCards[i][k11][0]=temp;
                }
            }
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            for(int j=0;j<6;j++)
            {
                if(k1[i]>MyCards[k1[i]/10][j][0] && MyCards[k1[i]/10][j][0]!=0)
                {
                    if(!Niama[k1[i]/10])
                    {
                        MyCards[k1[i]/10][j][1]++;
                    }
                }
            }
        }
    }
    ///////////////////////////////////////
    int ques[5]={0,0,0,0,0};
    for(let i: number =1;i<5;i++)
    {
        if(BroiBoia[i]==0)
        {
            ques[i]+=20000;
        }
        if(BroiBoia[i]>0)
        {
            //dava bonus za to4kite
            if(i==this.Naddavane)
            {
                ques[i]+=10000;
            }
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 6)
                ques[i]+=1000;
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 5)
                ques[i]+=800;
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 4)
            {
                if(i==this.Naddavane || this.Naddavane === 6)
                    ques[i]+=600;
                else
                {
                    if(this.Naddavane==5)
                    {
                        ques[i]+=250;
                    }
                    else
                    {
                        ques[i]+=100;
                    }
                }
            }
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 3)
            {
                if(i==this.Naddavane || this.Naddavane === 6)
                    ques[i]+=500;
                else
                {
                    if(this.Naddavane==5)
                    {
                        ques[i]+=150;
                    }
                    else
                    {
                        ques[i]+=50;
                    }
                }
            }
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 2)
            {
                ques[i]+=25;
            }
            if(MyCards[i][BroiBoia[i]-1][0]%10 === 1)
            {
                if((i==this.Naddavane || this.Naddavane === 6) && BroiBoia[i]>=2 && MyCards[i][BroiBoia[i]-2][0]%10 <= 2)
                    ques[i]+=300;
            }
            //dava bonus za miastoto
            if(Niama[i] || MyCards[i][BroiBoia[i]-1][1] === 0)
            {
                ques[i] +=500;
            }
            //dava bonus za prikrivane
            if(BroiBoia[i]==3)
            {
                if(MyCards[i][0][1]==1 || MyCards[i][0][1]==2)
                {
                    ques[i]+=150;
                }
            }
            if(BroiBoia[i]==2)
            {
                if(MyCards[i][0][1]==1)
                {
                    if(BroiOP[i]>=3)
                        ques[i]+=600;
                    if(BroiOP[i]==2)
                        ques[i]+=300;
                    if(BroiOP[i]==1 && Broi>=3)
                        ques[i]+=200;
                    ques[i]+=100;
                }
            }
            //dava binus za duljina
            if(BroiBoia[i]-BroiOP[i] >= 0 && Broi>=4)
            {
                ques[i]+=250;
            }
            if(BroiBoia[i]-BroiOP[i] >= 0 && Broi<=3)
            {
                ques[i]+=150;
            }
            if(BroiOP[i] <=1 && BroiBoia[i] <=3)
            {
                ques[i]-=100;
            }
            //maha bonus ako ne moje da se izpolzva

        }
    }
    int Ca=1,qq=ques[1];
    for(let i: number =1;i<5;i++)
    {
        if(ques[i]<qq)
        {
            Ca=i;
            qq=ques[i];
        }
    }
    return MyCards[Ca][BroiBoia[Ca]-1][0];
}
int CBelot3Players::IntelectNT(int Player)
{
//	SYSTEMTIME systime;
//	GetSystemTime(&systime);
//	int ms=systime.wMilliseconds;
//	srand( ms );
    int k1[25];//kopie na kartite koeto moaga da obrabotvam
    int Card11=-1;
    int Broi=0;//broia na ostava6tite karti
    int BroiOP[5]={0,0,0,0,0};//broia po boi na kartite na 2-mata dr igra4i
    int BroiBoia[5]={0,0,0,0,0};//broia po boi na kartite na igra4a
    int MyCards[5][8][2];//vsi4ki karti sortirani i info kolko po golemi karti ot dadena karta ima
    for(let i: number =0;i<5;i++)
    {
        for(int j=0;j<8;j++)
        {
            MyCards[i][j][0]=0;
            MyCards[i][j][1]=0;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        k1[i]=this.k[i]-2;
    }
    for(let i: number =((Player-1)*8)+1;i<=Player*8;i++)
    {
        if(this.k[i]>0)
        {
            MyCards[k1[i]/10][BroiBoia[k1[i]/10]][0]=k1[i];
            BroiBoia[k1[i]/10]++;
            Broi++;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            if(this.k[i]>0)
            {
                BroiOP[k1[i]/10]++;
            }
        }
    }
    //////
    for(let i: number =1;i<5;i++)
    {
        for(int j=0;j<6;j++)
        {
            for(int k11=j+1;k11<6;k11++)
            {
                if(MyCards[i][j][0]<MyCards[i][k11][0])
                {
                    int temp=MyCards[i][j][0];
                    MyCards[i][j][0]=MyCards[i][k11][0];
                    MyCards[i][k11][0]=temp;
                }
            }
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            for(int j=0;j<6;j++)
            {
                if(k1[i]>MyCards[k1[i]/10][j][0] && MyCards[k1[i]/10][j][0]!=0)
                {
                    if(!Niama[Player][k1[i]/10])
                    {
                        MyCards[k1[i]/10][j][1]++;
                    }
                }
            }
        }
    }
    int ParviPuska1,VtoriPuska1;
    if(this.ParviPuska>0) ParviPuska1=this.ParviPuska-2;
    else ParviPuska1=this.ParviPuska;
    if(this.VtoriPuska>0) VtoriPuska1=this.VtoriPuska-2;
    else VtoriPuska1=this.VtoriPuska;
    ////
    if(ParviPuska1==0)//igra4ut igrae parvi
    {
        //igrae si dulgite boi
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1 && Broi>=5)
            {
                if(BroiBoia[i]>=2 && MyCards[i][0][1]>0)
                {
                    Card11=MyCards[i][BroiBoia[i]-1][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=3 && MyCards[i][0][1]==0 && MyCards[i][1][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]<=2 && MyCards[i][0][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=3 && MyCards[i][0][1]==0)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1)
            {
                if(BroiBoia[i]>=2 && MyCards[i][0][1]>0)
                {
                    Card11=MyCards[i][BroiBoia[i]-1][0];
                    goto end;	
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(MyCards[i][0][1]==0 && BroiBoia[i]>=1)
            {
                Card11=MyCards[i][0][0];
                goto end;	
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(MyCards[i][0][1]==1 && BroiBoia[i]>=2)
            {
                Card11=MyCards[i][BroiBoia[i]-1][0];
                goto end;	
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=1)
            {
                Card11=MyCards[i][BroiBoia[i]-1][0];
                goto end;	
            }
        }
    }
    if(ParviPuska1>0 && VtoriPuska1==0)
    {
        if(BroiBoia[ParviPuska1/10]>0)
        {
            if(MyCards[ParviPuska1/10][0][0]>ParviPuska1)
            {
                if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[this.ParviPuska/10]>=2)
                {	
                    if(Player==2)//ako sledva6ti niama ot boiata
                    {
                        if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==3)
                    {
                        if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==2)//ako e iz4istil po goliama karta ot nujnata
                    {
                        if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(Player==3)
                    {
                        if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    if(MyCards[ParviPuska1/10][1][1]==0)
                    {
                        Card11=MyCards[ParviPuska1/10][0][0];
                        goto end;
                    }
                    if(MyCards[ParviPuska1/10][1][1]==1)
                    {
                        int ccc=rand()%2;
                        if(ccc==0)
                        {
                            Card11=MyCards[ParviPuska1/10][1][0];
                            goto end;
                        }
                    }
                }
                if(MyCards[ParviPuska1/10][0][1] === 0 && (ParviPuska1%10>=5 || BroiBoia[ParviPuska1 / 10]<=3 || Broi<=4))
                {
                    Card11=MyCards[ParviPuska1/10][0][0];
                    goto end;
                }
                Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                goto end;
            }
            else
            {
                Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                goto end;
            }
        }
        else
        {
            Card11=Chistene(Player,k1);
            goto end;
        }	
    }
    if(VtoriPuska1>0 && this.TretiPuska==0)
    {
        if(BroiBoia[ParviPuska1/10]>0)
        {
            if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && 
                (MyCards[ParviPuska1/10][0][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
            {
                if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[ParviPuska1/10]>=2 &&
                    (MyCards[ParviPuska1/10][1][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                {
                    if(MyCards[ParviPuska1/10][0][1]==0)
                    {
                        for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                        {
                            if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                            {
                                Card11=MyCards[ParviPuska1/10][w][0];
                                goto end;
                            }
                        }
                    }
                    if(MyCards[ParviPuska1/10][0][1]!=0)
                    {
                        if(BroiBoia[ParviPuska1/10]>=3)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                    }
                    Card11=MyCards[ParviPuska1/10][0][0];
                    goto end;
                }
                if(MyCards[ParviPuska1/10][0][1] !== 0 || ParviPuska1%10>=5 || VtoriPuska1%10>=5)
                {
                    Card11=MyCards[ParviPuska1/10][0][0];
                    goto end;
                }
                Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                goto end;
            }
            else
            {
                Card11=MyCards[this.ParviPuska/10][BroiBoia[this.ParviPuska/10]-1][0];
                goto end;
            }
            Card11=MyCards[this.ParviPuska/10][BroiBoia[this.ParviPuska/10]-1][0];
            goto end;
        }
        else
        {
            Card11=Chistene(Player,k1);
            goto end;
        }
    }
end:;
    for(let i: number =1;i<25;i++)
    {
        if(k1[i] === Card11)
        {
            return i;
        }
    }
    return 0;
}

int CBelot3Players::IntelectColor(int Player)
{
//	SYSTEMTIME systime;
//	GetSystemTime(&systime);
//	int ms=systime.wMilliseconds;
//	srand( ms );
    int nmc=CheckNMCard(Player);
    int k1[25];//kopie na kartite koeto moaga da obrabotvam
    int Card11=-1;
    int Broi=0;//broia na ostava6tite karti
    int BroiOP[5]={0,0,0,0,0};//broia po boi na kartite na 2-mata dr igra4i
    int BroiBoia[5]={0,0,0,0,0};//broia po boi na kartite na igra4a
    int MyCards[5][8][2];//vsi4ki karti sortirani i info kolko po golemi karti ot dadena karta ima
    for(let i: number =0;i<5;i++)
    {
        for(int j=0;j<8;j++)
        {
            MyCards[i][j][0]=0;
            MyCards[i][j][1]=0;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(this.k[i]<100 && this.k[i]>0)
        {
            k1[i]=this.k[i]-2;
        }
        else
        {
            if(this.k[i]>0)
            {
                k1[i]=(this.k[i]%10-2)+this.Naddavane*10;
            }
            if(this.k[i]==0) k1[i]=0;
        }
    }
    for(let i: number =((Player-1)*8)+1;i<=Player*8;i++)
    {
        if(this.k[i]>0)
        {
            MyCards[k1[i]/10][BroiBoia[k1[i]/10]][0]=k1[i];
            BroiBoia[k1[i]/10]++;
            Broi++;
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            if(this.k[i]>0)
            {
                BroiOP[k1[i]/10]++;
            }
        }
    }
    //////
    for(let i: number =1;i<5;i++)
    {
        for(int j=0;j<6;j++)
        {
            for(int k11=j+1;k11<6;k11++)
            {
                if(MyCards[i][j][0]<MyCards[i][k11][0])
                {
                    int temp=MyCards[i][j][0];
                    MyCards[i][j][0]=MyCards[i][k11][0];
                    MyCards[i][k11][0]=temp;
                }
            }
        }
    }
    for(let i: number =1;i<25;i++)
    {
        if(i<=(Player-1)*8 || i>Player*8)
        {
            for(int j=0;j<6;j++)
            {
                if(k1[i]>MyCards[k1[i]/10][j][0] && MyCards[k1[i]/10][j][0]!=0)
                {
                    if(!Niama[Player][k1[i]/10])
                    {
                        MyCards[k1[i]/10][j][1]++;
                    }
                }
            }
        }
    }
    int ParviPuska1,VtoriPuska1;
    if(this.ParviPuska>0 && this.ParviPuska<100) ParviPuska1=this.ParviPuska-2;
    if(this.ParviPuska>100) ParviPuska1=this.ParviPuska%10-2+this.Naddavane*10;
    if(this.ParviPuska==0) ParviPuska1=this.ParviPuska;
    if(this.VtoriPuska>0 && this.VtoriPuska<100) VtoriPuska1=this.VtoriPuska-2;
    if(this.VtoriPuska>100) VtoriPuska1=this.VtoriPuska%10-2+this.Naddavane*10;
    if(this.VtoriPuska==0) VtoriPuska1=this.VtoriPuska;

    if(ParviPuska1==0)//igrae parvi
    {
        int this.OP;
        if(Player === 2) this.OP=3;
        if(Player === 3) this.OP=4;
        if(BroiBoia[this.Naddavane]>=BroiOP[this.Naddavane] && BroiOP[this.Naddavane]>0)//tegli kozovete
        {
            if(MyCards[this.Naddavane][0][1]==0)
            {
                Card11=MyCards[this.Naddavane][0][0];
                goto end;
            }
            if(MyCards[this.Naddavane][0][1]>0 && BroiBoia[this.Naddavane]>1)
            {
                Card11=MyCards[this.Naddavane][BroiBoia[this.Naddavane]-1][0];
                goto end;
            }
        }
        if(BroiOP[this.Naddavane] === 0)//ako ne sa ostanali kozove v drugite
        {
            for(let i: number =1;i<5;i++)
            {
                if(BroiBoia[i]>=BroiOP[i] && BroiBoia[i]>=1 && i!=this.Naddavane && Broi>=5)
                {
                    if(BroiBoia[i]>=2 && MyCards[i][0][1]>0)
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;	
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(BroiBoia[i]>=BroiOP[i] && i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(BroiBoia[i]>=3 && MyCards[i][0][1]==0 && MyCards[i][1][1]==0)
                    {
                        Card11=MyCards[i][0][0];
                        goto end;	
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(BroiBoia[i]>=BroiOP[i] && i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(BroiBoia[i]<=2 && MyCards[i][0][1]==0)
                    {
                        Card11=MyCards[i][0][0];
                        goto end;	
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(BroiBoia[i]>=BroiOP[i] && i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(BroiBoia[i]>=3 && MyCards[i][0][1]==0)
                    {
                        Card11=MyCards[i][0][0];
                        goto end;	
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(BroiBoia[i]>=BroiOP[i] && i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(BroiBoia[i]>=2 && MyCards[i][0][1]>0)
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;	
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(MyCards[i][0][1]==0 && i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    Card11=MyCards[i][0][0];
                    goto end;	
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(MyCards[i][0][1]==1 && i!=this.Naddavane && BroiBoia[i]>=2)
                {
                    Card11=MyCards[i][BroiBoia[i]-1][0];
                    goto end;	
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    Card11=MyCards[i][BroiBoia[i]-1][0];
                    goto end;	
                }
            }

        }
        if(BroiOP[this.Naddavane] > 0)//AKO DRUGITE VSE O6TE IMAT KOZOVE
        {
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(MyCards[i][0][1] === 0 && MyCards[i][0][0]%10 <=4)
                    {
                        Card11=MyCards[i][0][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(MyCards[i][0][1] === 0 && (!Niama[1][i] || Niama[1][this.Naddavane]) && (!Niama[this.OP][i] || Niama[this.OP][this.Naddavane]))
                    {
                        Card11=MyCards[i][0][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if((Niama[1][i] || !Niama[1][this.Naddavane]) || (Niama[this.OP][i] || !Niama[this.OP][this.Naddavane]))
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    if(MyCards[i][0][1] === 0)
                    {
                        Card11=MyCards[i][0][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane)
                {
                    if(BroiBoia[i]>=3)
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane)
                {
                    if(BroiBoia[i]==2)
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;
                    }
                }
            }
            for(let i: number =1;i<5;i++)
            {
                if(i!=this.Naddavane && BroiBoia[i]>=1)
                {
                    {
                        Card11=MyCards[i][BroiBoia[i]-1][0];
                        goto end;
                    }
                }
            }
        }
        for(let i: number =1;i<5;i++)
        {
            if(BroiBoia[i]>=1)
            {
                Card11=MyCards[i][BroiBoia[i]-1][0];
                goto end;	
            }
        }
        if(Card11==0)
            int aaaaa=0;
        //vzima si rucete

    }
    if(ParviPuska1>0 && VtoriPuska1==0)//igrae vtori
    {
        int NP;
        if(Player==2) NP=3;
        if(Player==3) NP=1;
        if(BroiBoia[ParviPuska1/10]>0)
        {
            if(ParviPuska1/10==this.Naddavane)
            {
                if(BroiBoia[ParviPuska1/10]>0)
                {
                    if(MyCards[ParviPuska1/10][0][0]>ParviPuska1)
                    {
                        if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[this.ParviPuska/10]>=2)
                        {	
                            if(Player==2)//ako sledva6ti niama ot boiata
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==2)//ako ne se e ka4il na po malka karta
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][0]>NeKa4ena[3][ParviPuska1 / 10] && NeKa4ena[3][ParviPuska1 / 10]>0 &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][0]>NeKa4ena[1][ParviPuska1 / 10] && NeKa4ena[1][ParviPuska1 / 10]>0 &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==2)//ako e iz4istil po goliama karta ot nujnata
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(MyCards[ParviPuska1/10][1][1]==0)
                            {
                                Card11=MyCards[ParviPuska1/10][0][0];
                                goto end;
                            }
                            if(MyCards[ParviPuska1/10][1][1]==1)
                            {
                                int ccc=rand()%2;
                                if(ccc==0)
                                {
                                    Card11=MyCards[ParviPuska1/10][1][0];
                                    goto end;
                                }
                            }
                        }
                        Card11=MyCards[ParviPuska1/10][0][0];
                        goto end;
                    }
                    else
                    {
                        Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                        goto end;
                    }
                }
            }
            if(ParviPuska1/10!=this.Naddavane)
            {
                if(BroiOP[this.Naddavane]>0)
                {
                    if(!Niama[NP][ParviPuska1/10] || Niama[NP][this.Naddavane])
                    {
                        if(MyCards[ParviPuska1/10][0][1]==0 && MyCards[ParviPuska1/10][0][0]>ParviPuska1)
                        {
                            Card11=MyCards[ParviPuska1/10][0][0];
                            goto end;
                        }
                        Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                        goto end;
                    }
                    else
                    {
                        Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                        goto end;
                    }
                }
                else
                {
                    if(MyCards[ParviPuska1/10][0][0]>ParviPuska1)
                    {
                        if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[this.ParviPuska/10]>=2)
                        {	
                            if(Player==2)//ako sledva6ti niama ot boiata
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==2)//ako ne se e ka4il na po malka karta
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][0]>NeKa4ena[3][ParviPuska1 / 10] && NeKa4ena[3][ParviPuska1 / 10]>0 &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][0]>NeKa4ena[1][ParviPuska1 / 10] && NeKa4ena[1][ParviPuska1 / 10]>0 &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==2)//ako e iz4istil po goliama karta ot nujnata
                            {
                                if(Niama[3][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(Player==3)
                            {
                                if(Niama[1][ParviPuska1 / 10] || BroiOP[ParviPuska1 / 10]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            MyCards[ParviPuska1/10][w][1]==1 && LIzchistena[3][ParviPuska1 / 10]>MyCards[ParviPuska1/10][w][0] &&
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                            }
                            if(MyCards[ParviPuska1/10][1][1]==0)
                            {
                                Card11=MyCards[ParviPuska1/10][0][0];
                                goto end;
                            }
                            if(MyCards[ParviPuska1/10][1][1]==1)
                            {
                                int ccc=rand()%2;
                                if(ccc==0)
                                {
                                    Card11=MyCards[ParviPuska1/10][1][0];
                                    goto end;
                                }
                            }
                        }
                        Card11=MyCards[ParviPuska1/10][0][0];
                        goto end;
                    }
                    else
                    {
                        Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                        goto end;
                    }
                }
                Card11=MyCards[ParviPuska1/10][BroiBoia[this.ParviPuska/10]-1][0];
                goto end;
            }
        }
        if(BroiBoia[ParviPuska1/10]==0)
        {
            if(ParviPuska1/10==this.Naddavane)
            {
                Card11=Chistene(Player,k1);
                goto end;
            }
            if(ParviPuska1/10!=this.Naddavane)
            {
                if(BroiBoia[this.Naddavane]==0)
                {
                    Card11=Chistene(Player,k1);
                    goto end;
                }
                if(BroiBoia[this.Naddavane]>0)
                {
                    if((!Niama[NP][ParviPuska1/10] && BroiOP[ParviPuska1/10]>0) || Niama[NP][ParviPuska1/10])
                    {
                        if(MyCards[this.Naddavane][0][1]==0 || (MyCards[this.Naddavane][0][1]==0 && BroiBoia[this.Naddavane]>=3))
                        {
                            for(int w=BroiBoia[this.Naddavane]-1;w>=0;w--)
                            {
                                if( ( (MyCards[this.Naddavane][w-1][1]<MyCards[this.Naddavane][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[this.Naddavane][w][0];
                                    goto end;
                                }
                            }
                        }
                        Card11=MyCards[this.Naddavane][0][0];
                        goto end;
                    }
                    Card11=MyCards[this.Naddavane][BroiBoia[this.Naddavane]-1][0];
                    goto end;
                }
            }
        }

    }
    if(VtoriPuska1>0 && this.TretiPuska==0)//igrae treti
    {
        if(BroiBoia[ParviPuska1/10]>0)
        {
            if(ParviPuska1/10==this.Naddavane)//ako purvia e igral koz
            {
                if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && 
                (MyCards[ParviPuska1/10][0][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                {
                    if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[ParviPuska1/10]>=2 &&
                        (MyCards[ParviPuska1/10][1][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                    {
                        if(MyCards[ParviPuska1/10][0][1]==0)
                        {
                            for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                            {
                                if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                    (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                    ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[ParviPuska1/10][w][0];
                                    goto end;
                                }
                            }
                        }
                        if(MyCards[ParviPuska1/10][0][1]!=0)
                        {
                            if(BroiBoia[ParviPuska1/10]>=3)
                            {
                                for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                {
                                    if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                        (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                        ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                    {
                                        Card11=MyCards[ParviPuska1/10][w][0];
                                        goto end;
                                    }
                                }
                            }
                        }
                        Card11=MyCards[ParviPuska1/10][0][0];
                        goto end;
                    }
                    Card11=MyCards[ParviPuska1/10][0][0];
                    goto end;
                }
                else
                {
                    Card11=MyCards[ParviPuska1/10][BroiBoia[this.Naddavane]-1][0];
                    goto end;
                }
            }
            else//ako ne e igral koz
            {
                if(VtoriPuska1/10==this.Naddavane)//ako vtoria e cakal
                {
                    Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                    goto end;
                }
                if(VtoriPuska1/10!=ParviPuska1/10 && VtoriPuska1/10!=this.Naddavane)//ako ne e otgovoril
                {
                    if(BroiOP[this.Naddavane]>0)
                    {
                        if(MyCards[ParviPuska1/10][0][0]>ParviPuska1)
                        {
                            Card11=MyCards[ParviPuska1/10][0][0];
                            goto end;
                        }
                        Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                        goto end;
                    }
                    if(BroiOP[this.Naddavane]==0)
                    {
                        if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && 
                            (MyCards[ParviPuska1/10][0][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                        {
                            if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[ParviPuska1/10]>=2 &&
                                (MyCards[ParviPuska1/10][1][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                            {
                                if(MyCards[ParviPuska1/10][0][1]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                                if(MyCards[ParviPuska1/10][0][1]!=0)
                                {
                                    if(BroiBoia[ParviPuska1/10]>=3)
                                    {
                                        for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                        {
                                            if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                                (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                                ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                            {
                                                Card11=MyCards[ParviPuska1/10][w][0];
                                                goto end;
                                            }
                                        }
                                    }
                                }
                                Card11=MyCards[ParviPuska1/10][0][0];
                                goto end;
                            }
                            Card11=MyCards[ParviPuska1/10][0][0];
                            goto end;
                        }
                    }
                    Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                    goto end;
                }
                //ako si e otgovoril
                if(VtoriPuska1/10==ParviPuska1/10)
                {
                    if(BroiOP[this.Naddavane]>0)
                    {
                        if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && MyCards[ParviPuska1/10][0][0]>VtoriPuska1)
                        {
                            Card11=MyCards[ParviPuska1/10][0][0];
                            goto end;
                        }
                        Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                        goto end;
                    }
                    if(BroiOP[this.Naddavane]==0)
                    {
                        if(MyCards[ParviPuska1/10][0][0]>ParviPuska1 && 
                            (MyCards[ParviPuska1/10][0][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                        {
                            if(MyCards[ParviPuska1/10][1][0]>ParviPuska1 && BroiBoia[ParviPuska1/10]>=2 &&
                                (MyCards[ParviPuska1/10][1][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10))
                            {
                                if(MyCards[ParviPuska1/10][0][1]==0)
                                {
                                    for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                    {
                                        if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                            (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                            ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                        {
                                            Card11=MyCards[ParviPuska1/10][w][0];
                                            goto end;
                                        }
                                    }
                                }
                                if(MyCards[ParviPuska1/10][0][1]!=0)
                                {
                                    if(BroiBoia[ParviPuska1/10]>=3)
                                    {
                                        for(int w=BroiBoia[ParviPuska1/10]-1;w>=0;w--)
                                        {
                                            if(MyCards[ParviPuska1/10][w][0]>ParviPuska1 && 
                                                (MyCards[ParviPuska1/10][w][0]>VtoriPuska1 || ParviPuska1/10!=VtoriPuska1/10) &&
                                                ( (MyCards[ParviPuska1/10][w-1][1]<MyCards[ParviPuska1/10][w][1]) || w==0 ))
                                            {
                                                Card11=MyCards[ParviPuska1/10][w][0];
                                                goto end;
                                            }
                                        }
                                    }
                                }
                                Card11=MyCards[ParviPuska1/10][0][0];
                                goto end;
                            }
                            Card11=MyCards[ParviPuska1/10][0][0];
                            goto end;
                        }
                    }
                    Card11=MyCards[ParviPuska1/10][BroiBoia[ParviPuska1/10]-1][0];
                    goto end;
                }
            }
        }
        if(BroiBoia[ParviPuska1/10]==0)
        {
            if(ParviPuska1/10==this.Naddavane)
            {
                Card11=Chistene(Player,k1);
                goto end;
            }
            else
            {
                if(BroiBoia[this.Naddavane]==0)
                {
                    Card11=Chistene(Player,k1);
                    goto end;
                }
                if(BroiBoia[this.Naddavane]>0)
                {
                    if(MyCards[this.Naddavane][0][0]<VtoriPuska1 && VtoriPuska1/10==this.Naddavane)
                    {
                        Card11=Chistene(Player,k1);
                        goto end;
                    }
                    if(VtoriPuska1/10==this.Naddavane && MyCards[this.Naddavane][0][0]>VtoriPuska1)//triabva da se ka4i
                    {
                        if(MyCards[this.Naddavane][0][1]==0 || (MyCards[this.Naddavane][0][1]==0 && BroiBoia[this.Naddavane]>=3))
                        {
                            for(int w=BroiBoia[this.Naddavane]-1;w>=0;w--)
                            {
                                if(MyCards[this.Naddavane][w][0]>VtoriPuska1 && 
                                    ( (MyCards[this.Naddavane][w-1][1]<MyCards[this.Naddavane][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[this.Naddavane][w][0];
                                    goto end;
                                }
                            }
                        }
                        Card11=MyCards[this.Naddavane][0][0];
                        goto end;
                    }
                    else
                    {
                        if(MyCards[this.Naddavane][0][1]==0 || (MyCards[this.Naddavane][0][1]==0 && BroiBoia[this.Naddavane]>=3))
                        {
                            for(int w=BroiBoia[this.Naddavane]-1;w>=0;w--)
                            {
                                if( ( (MyCards[this.Naddavane][w-1][1]<MyCards[this.Naddavane][w][1]) || w==0 ))
                                {
                                    Card11=MyCards[this.Naddavane][w][0];
                                    goto end;
                                }
                            }
                        }
                        Card11=MyCards[this.Naddavane][0][0];
                        goto end;
                    }
                }
            }
        }
    }
end:;
    for(let i: number =1;i<25;i++)
    {
        if(k1[i] === Card11)
        {
            return i;
        }
    }
    if(this.OP)m_pwin->SendErrorToLog("Greshka pri puskane na karta ot comp-this.Naddavane="+this.IntToString(this.Naddavane)+" player="+this.IntToString(Player)+" Karta: "+this.IntToString(Card11)+" Koipuskaparvi: "+this.IntToString(this.KoiPuskaParvi));
    if(nmc>0)return PuskCard(nmc, Player);
    return MinCard(Player);
}
    */

    SendCards() {
        throw new Error('Method not implemented.');
    }
    InitOP(server: boolean) {
        console.log(server);
    }
    async InitSP() { }
    async OnLButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async OnRButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async ReceiveGame(strRecvd: string) {
        console.log(strRecvd);
        return true;
    }
    Timer7() { }
    Timer3() { }
    Timer2() { }
    Timer4() { }
    constructor(props) {
        super(props);
    }
    Timer1() { }

    DrawView() {
        return null;
    }

    render() {
        if (this.props.m_pwin.game === 'Belot3') {
            return <View key={'Belot3'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CBelot3Players;
