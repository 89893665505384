import React from 'react';
import { StyleSheet, View, ImageBackground, ActivityIndicator, Pressable, Dimensions } from 'react-native';
import { styles } from '../../../css/container';
import config from '~/config';

const CustomSplashScreen = (props) => {
    const { loading } = props;

    if (loading) {
        return (
            <Pressable
                style={[styles.modalfdialog, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}
                onPress={() => {
                    return;
                }}
            >
                <View style={cstyles.modalBackground}>
                    <ImageBackground
                        source={config.GAME === 'Bridge' ? require('../../../assets/Images/Bridge/SplashScreen.png') :  require('../../../assets/Images/SplashScreen.png')}
                        resizeMode="cover"
                        style={[styles.W100, styles.h100]}
                    >
                        <View style={cstyles.loader}>
                            <View style={cstyles.activityIndicatorWrapper}>
                                <ActivityIndicator animating={true} color="#000000" size="large" style={cstyles.activityIndicator} />
                            </View>
                        </View>
                    </ImageBackground>
                </View>
            </Pressable>
        );
    } else {
        return null;
    }
};

export default CustomSplashScreen;

const cstyles = StyleSheet.create({
    loader: { justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' },
    modalBackground: {
        width: '100%',
        height: '100%',
        backgroundColor: '#A6E4BF',
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        opacity: 0.8,
        zIndex: 100,
    },
    activityIndicator: {
        alignItems: 'center',
        height: 80,
    },
});
