import * as React from 'react';
import * as AppleAuthentication from 'expo-apple-authentication';
import CCardGamesView from '../CCardGamesView';
import { AuthData } from '~/Dialogs/DlgConnect';
import GlobalVariables from '../GlobalVariables';
import jwt_decode from 'jwt-decode';
import { Pressable, Image } from 'react-native';

type Props = {
    cCardGamesViewRef: CCardGamesView;
};

export type FullName = {
    givenName: string;
    familyName: string;
};

export type AppleData = {
    email: string;
    fullName: FullName;
};

export default function AppleLogin({ cCardGamesViewRef }: Props) {
    const [uid, setUid] = React.useState('null');

    React.useEffect(() => {
        async function fetchData() {
            let suid = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_apple_id', null);
            setUid(suid);
        }
        fetchData();
    }, [cCardGamesViewRef.m_main]);

    async function signinwithApple() {
        if (uid !== null && uid !== 'null') {
            let email: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_apple_email', null);
            let fname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_apple_fname', null);
            let lname: string = await cCardGamesViewRef.m_main.GetProfileString('Options', 'authData_apple_lname', null);
            let data: AuthData = {
                email: email,
                fname: fname,
                lname: lname,
                uid: uid,
            };
            cCardGamesViewRef.m_con.loginWithAuthData(data);
            if (!cCardGamesViewRef.m_con.m_save) {
                deleteAuthData();
            }
        } else {
            try {
                let credential = await AppleAuthentication.signInAsync({
                    requestedScopes: [AppleAuthentication.AppleAuthenticationScope.FULL_NAME, AppleAuthentication.AppleAuthenticationScope.EMAIL],
                });
                let data: AuthData = {
                    email: credential.email,
                    fname: credential.fullName ? credential.fullName.givenName : '-',
                    lname: credential.fullName ? credential.fullName.familyName : '-',
                    uid: credential.user,
                };
                if (data.uid) {
                    cCardGamesViewRef.m_con.loginWithAuthData(data);
                    if (cCardGamesViewRef.m_con.m_save) {
                        setUid(data.uid);
                        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_email', data.email);
                        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_fname', data.fname);
                        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_lname', data.lname);
                        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_id', data.uid);
                    } else {
                        deleteAuthData();
                    }
                } else {
                    let user = credential.user;
                    let identityToken = credential.identityToken;
                    var decoded = jwt_decode<AppleData>(identityToken);
                    data = {
                        email: decoded.email,
                        fname: credential.fullName ? credential.fullName.givenName : '-',
                        lname: credential.fullName ? credential.fullName.familyName : '-',
                        uid: user,
                    };
                    if (data.uid) {
                        cCardGamesViewRef.m_con.loginWithAuthData(data);
                        if (cCardGamesViewRef.m_con.m_save) {
                            setUid(data.uid);
                            cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_email', data.email);
                            cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_fname', data.fname);
                            cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_lname', data.lname);
                            cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_id', data.uid);
                        } else {
                            deleteAuthData();
                        }
                    }
                }
            } catch (e) {
                if (e.code === 'ERR_CANCELED') {
                    console.log('e', e);
                } else {
                    console.log('e', e);
                }
            }
        }
    }

    async function deleteAuthData() {
        setUid('null');
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_email', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_fname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_lname', null);
        cCardGamesViewRef.m_main.WriteProfileString('Options', 'authData_apple_id', null);
    }

    return (
        <Pressable
            onPress={() => {
                signinwithApple();
            }}
        >
            <Image
                style={{
                    width: 44 * GlobalVariables.scale,
                    height: 44 * GlobalVariables.scale,
                    marginHorizontal: 10 * GlobalVariables.scale,
                }}
                source={require('../../../assets/Images/HomeScreen/Apple.png')}
            />
        </Pressable>
    );
}
