import React, { Component } from 'react';
import { View, KeyboardAvoidingView, Keyboard, Platform, StyleSheet, TextInput, Pressable, Image, Dimensions, ImageBackground } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import LanguagePicker from '../Components/core/LanguagePicker';
import Loader from '../Components/core/Loader';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import FacebookLogin from '~/Components/AuthButtons/FacebookLogin';
import GoogleLogin from '~/Components/AuthButtons/GoogleLogin';
import AppleLogin from '~/Components/AuthButtons/AppleLogin';
import { Ionicons } from '@expo/vector-icons';
import Checkbox from '~/Components/core/Checkbox';
import t from '../../translation';
import config from '~/config';

export type AuthData = {
    email: string;
    fname: string;
    lname: string;
    image?: string;
    uid: string;
    friends?: string;
    username?: string;
};

class DlgConnect extends Component<BaseDlgProps> {
    action: string = '';
    loading: boolean = false;
    m_user: string = '';
    m_hiddenUsername: string = '';
    m_password: string = '';
    m_guest: boolean = false;
    m_save: boolean = false;
    passwordVisibility: boolean = true;
    lp: LanguagePicker;
    start: number;
    passwordRef: TextInput;

    constructor(props: BaseDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    getUsername(): string {
        if (this.m_hiddenUsername !== '') {
            return this.m_hiddenUsername;
        }
        return this.m_user;
    }

    async DoModal() {
        this.props.m_pwin.state.DlgConnectVisible = true;
        this.loadFinished();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgConnectVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgConnectVisible: false });
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadFinished();
    }

    async loadFinished() {
        this.m_user = '';
        this.m_password = '';
        if ((await this.props.m_pwin.m_main.GetProfileString('Options', 'save', '1')) === '1') {
            this.m_save = true;
        }
        if (this.m_save) {
            this.m_user = await this.props.m_pwin.m_main.GetProfileString('Options', 'username', '');
            this.m_password = await this.props.m_pwin.m_main.GetProfileString('Options', 'password', '');
        }
        this.m_guest = false;
        this.setState({});
    }

    OnRegistration() {
        this.props.m_pwin.setState({ DlgConnectVisible: false, OnlineRegistrationVisible: true });
    }

    async loginWithAuthData(data: AuthData) {
        this.loading = true;
        this.setState({});
        this.m_guest = false;
        this.props.m_pwin.login = false;
        this.action = '';
        if (await this.props.m_pwin.ConnectToServerOnly()) {
            this.loading = false;
            let email = data.email;
            let fname = data.fname;
            let lname = data.lname;
            let image = data.image;
            let uid = data.uid;
            let displayName = fname + ' ' + lname;
            if (!fname || fname === '' || fname === 'null' || fname === 'undefined' || fname === undefined) {
                fname = '-';
            }
            if (!lname || lname === '' || lname === 'null' || lname === 'undefined' || lname === undefined) {
                lname = '-';
            }
            if (!image || image === '' || image === 'null' || image === 'undefined' || image === undefined) {
                image = '-';
            }
            if (!email || email === '' || email === 'null' || email === 'undefined' || email === undefined) {
                email = '-';
            }

            let message =
                '*|S|' +
                email +
                '|' +
                fname +
                '|' +
                lname +
                '|' +
                image +
                '|' +
                uid +
                '|' +
                displayName +
                '|' +
                this.props.m_pwin.ipAddress +
                '|' +
                this.props.m_pwin.m_main.GetOnlineVersion() +
                '|' +
                this.props.m_pwin.m_main.language +
                '|' +
                (await this.props.m_pwin.m_main.GetGameNumber()) +
                '|' +
                (await this.props.m_pwin.m_main.GetAffiliate()) +
                '|' +
                this.props.m_pwin.m_main.GetOSVersion() +
                '|' +
                this.props.m_pwin.m_main.GetOnlineVersion() +
                '|' +
                this.props.m_pwin.m_main.language +
                '|' +
                (await this.props.m_pwin.m_main.GetGameNumber()) +
                '|0|' +
                this.props.m_pwin.ipAddress;
            if (this.props.m_pwin.SendGame(message)) {
                this.props.m_pwin.connected = true;
                this.setState({});
                if ((await this.props.m_pwin.m_main.GetProfileString('Options', 'GetAuthData_' + uid, null)) === null) {
                    this.props.m_pwin.m_main.WriteProfileString('Options', 'GetAuthData_' + uid, uid);
                    this.props.m_pwin.TrackEvent('GetAuthData', email !== '-' ? 0.5 : 0.3);
                }
                this.props.m_pwin.m_main.WriteProfileString('Options', 'last_password', uid);
            } else {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('No connection with server')
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
        } else {
            this.loading = false;
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('No connection with server'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
        }
        this.setState({});
    }

    async loginAsGuest() {
        this.m_hiddenUsername = '';
        this.loading = true;
        this.setState({});
        this.m_guest = true;
        this.props.m_pwin.login = false;
        this.action = '';
        await this.props.m_pwin.ConnectToServer();
        this.setState({});
    }

    async login() {
        this.m_hiddenUsername = '';
        if (!this.m_user) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('Please enter a Username'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        if (!this.m_password) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.props.m_pwin.m_main.GetLanguageText('Please enter a Password'));
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        if (!this.props.m_pwin.m_main.CheckUsernameAndPassword(this.m_user)) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the username')
            );
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        if (!this.props.m_pwin.m_main.CheckUsernameAndPassword(this.m_password)) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the password')
            );
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return;
        }
        this.m_guest = false;
        this.loading = true;
        if (this.m_save) {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'username', this.m_user);
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'password', this.m_password);
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'save', '1');
        } else {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'save', '0');
        }
        this.setState({});
        this.props.m_pwin.login = false;
        this.action = '';
        await this.props.m_pwin.ConnectToServer();
        this.setState({});
    }
    toggleSave() {
        this.m_save = !this.m_save;
        this.setState({});
    }
    setPasswordVisibility(passwordVisibility: boolean) {
        this.passwordVisibility = passwordVisibility;
        this.setState({});
    }

    OnCancel() {
        this.props.m_pwin.setState({ DlgConnectVisible: false });
    }

    OnFPassword() {
        this.props.m_pwin.openPage('https://favorite-games.com/server/public/forgot_password.php', 'Favorite-Games.com');
    }

    render() {
        if (this.props.m_pwin.state.DlgConnectVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={[dialog.dialogView, cstyle.screenSize]}>
                        <View style={cstyle.content}>
                            <View style={cstyle.leftSide}>
                                <View style={cstyle.logoRow}>
                                    <Image
                                        source={
                                            config.GAME === 'Belote'
                                                ? require('../../assets/Images/Belote/favicon.png')
                                                : config.GAME === 'Bridge'
                                                ? require('../../assets/Images/Bridge/favicon.png')
                                                : config.GAME === 'NSSC'
                                                ? require('../../assets/Images/NSSC/favicon.png')
                                                : config.GAME === 'Blato'
                                                ? require('../../assets/Images/Blato/favicon.png')
                                                : config.GAME === 'Santase'
                                                ? require('../../assets/Images/Santase/favicon.png')
                                                : config.GAME === 'Tabla'
                                                ? require('../../assets/Images/Tabla/favicon.png')
                                                : require('../../assets/Images/favicon.png')
                                        }
                                        style={{
                                            width: 66 * GlobalVariables.scale,
                                            height: 66 * GlobalVariables.scale,
                                        }}
                                    />
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                        FAVORITE
                                    </Text>
                                    <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                        GAMES
                                    </Text>
                                </View>
                                <View style={cstyle.leftSideCenterContent}>
                                    <View style={[cstyle.loginTitleContainer, { paddingTop: 24 * GlobalVariables.scale }]}>
                                        <Text textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                            LOG IN
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            cstyle.loginDescriptionContainer,
                                            { paddingTop: 7 * GlobalVariables.scale, paddingBottom: 21 * GlobalVariables.scale },
                                        ]}
                                    >
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            Play online
                                        </Text>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            with real Players
                                        </Text>
                                    </View>
                                    <KeyboardAvoidingView behavior={'position'} style={[{ paddingBottom: 5 * GlobalVariables.scale }]}>
                                        <View style={cstyle.fieldsContainer}>
                                            <View
                                                style={[
                                                    cstyle.homeInputStyle,
                                                    { height: 40 * GlobalVariables.scale, margin: 10 * GlobalVariables.scale },
                                                ]}
                                            >
                                                <TextInput
                                                    onSubmitEditing={() => {
                                                        Keyboard.dismiss;
                                                        this.passwordRef.focus();
                                                    }}
                                                    defaultValue={this.m_user}
                                                    onChangeText={(username) => (this.m_user = username)}
                                                    placeholder={t.t('Username')}
                                                    placeholderTextColor="#BCC1CB"
                                                    autoCapitalize="none"
                                                    keyboardType="default"
                                                    returnKeyType="next"
                                                    underlineColorAndroid="#f000"
                                                    maxLength={10}
                                                    testID={'usernameTestId'}
                                                    autoCorrect={false}
                                                    autoFocus={false}
                                                    multiline={false}
                                                    style={{
                                                        height: 40 * GlobalVariables.scale,
                                                        fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale,
                                                    }}
                                                />
                                            </View>
                                            <View
                                                style={[
                                                    cstyle.homeInputStyle,
                                                    { height: 40 * GlobalVariables.scale, margin: 10 * GlobalVariables.scale },
                                                ]}
                                            >
                                                <TextInput
                                                    ref={(ref: TextInput) => {
                                                        this.passwordRef = ref;
                                                    }}
                                                    defaultValue={this.m_password}
                                                    onChangeText={(password) => (this.m_password = password)}
                                                    placeholder={t.t('Password')}
                                                    placeholderTextColor="#BCC1CB"
                                                    keyboardType="default"
                                                    onSubmitEditing={() => {
                                                        Keyboard.dismiss;
                                                        this.login();
                                                    }}
                                                    maxLength={12}
                                                    underlineColorAndroid="#f000"
                                                    returnKeyType="done"
                                                    secureTextEntry={this.passwordVisibility}
                                                    testID={'passwordTestId'}
                                                    autoCorrect={false}
                                                    autoFocus={false}
                                                    multiline={false}
                                                    style={{
                                                        height: 40 * GlobalVariables.scale,
                                                        fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale,
                                                    }}
                                                />
                                            </View>
                                            <View style={cstyle.saveContainer}>
                                                <View style={cstyle.rememberContainer}>
                                                    <Checkbox checked={this.m_save} setChecked={this.toggleSave.bind(this)} />
                                                    <View style={{ paddingLeft: 10 * GlobalVariables.scale }}>
                                                        <Text textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE} color={'black'}>
                                                            Remember me
                                                        </Text>
                                                    </View>
                                                </View>
                                                <Pressable onPress={() => this.OnFPassword()} style={cstyle.rememberContainer}>
                                                    <Text textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE} color={'#48C37A'}>
                                                        Forgot password?
                                                    </Text>
                                                </Pressable>
                                            </View>
                                        </View>
                                    </KeyboardAvoidingView>
                                    <Pressable onPress={() => this.login()} style={[cstyle.loginButton, { height: 40 * GlobalVariables.scale }]}>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>LOG IN</Text>
                                    </Pressable>
                                    <View style={[cstyle.orLoginWithContainer, { paddingTop: 15 * GlobalVariables.scale }]}>
                                        <View style={cstyle.grayBorder} />
                                        <Text textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE} color={'#BCC1CB'}>
                                            or login with
                                        </Text>
                                        <View style={cstyle.grayBorder} />
                                    </View>
                                    <View style={[cstyle.loginWithContainer, { paddingTop: 15 * GlobalVariables.scale }]}>
                                        {Platform.OS === 'ios' ? <AppleLogin cCardGamesViewRef={this.props.m_pwin} /> : null}
                                        <FacebookLogin cCardGamesViewRef={this.props.m_pwin} />
                                        {Platform.OS !== 'android' ? <GoogleLogin cCardGamesViewRef={this.props.m_pwin} /> : null}
                                    </View>
                                    <Pressable style={cstyle.registerContainer} onPress={this.OnRegistration.bind(this)}>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'#BCC1CB'}>
                                            Don't have account?
                                        </Text>
                                        <View style={{ paddingLeft: 6 * GlobalVariables.scale }}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} color={'#48C37A'}>
                                                Register
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                                <View style={cstyle.leftSideRightContent} />
                            </View>
                            <View style={cstyle.rightSide}>
                                <ImageBackground
                                    source={config.GAME === 'Bridge' ? require('../../assets/Images/Bridge/home_screen_background.jpg') :  require('../../assets/Images/HomeScreen/home_screen_background.jpg')}
                                    resizeMode="cover"
                                    style={[styles.W100, styles.h100]}
                                >
                                    <View style={cstyle.closeContainer}>
                                        <Pressable
                                            onPress={() => this.OnCancel()}
                                            style={{ paddingTop: 24 * GlobalVariables.scale, paddingRight: 20 * GlobalVariables.scale }}
                                        >
                                            <Ionicons name={'close-circle-outline'} color={'black'} size={48 * GlobalVariables.scale} />
                                        </Pressable>
                                    </View>
                                    <View style={cstyle.titleContainer}>
                                        <Text textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE} color={'black'} style={cstyle.playNowText}>
                                            PLAY NOW
                                        </Text>
                                    </View>
                                    <View style={cstyle.descriptionContainer}>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            Play online as guest
                                        </Text>
                                        <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL} color={'black'} style={cstyle.descriptionText}>
                                            or against computer
                                        </Text>
                                    </View>
                                    <View style={cstyle.buttonsContainer}>
                                        <Pressable
                                            onPress={() => {
                                                this.loginAsGuest();
                                                this.props.m_pwin.showAd();
                                            }}
                                            style={[cstyle.playButton, { height: 40 * GlobalVariables.scale }]}
                                        >
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>Login as guest</Text>
                                        </Pressable>
                                        <Pressable
                                            onPress={async () => {
                                                this.props.m_pwin.OnOfflineGame();
                                                this.props.m_pwin.showAd();
                                            }}
                                            style={[cstyle.playButton, { height: 40 * GlobalVariables.scale }]}
                                        >
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZEXXL}>Local Game</Text>
                                        </Pressable>
                                    </View>
                                    <View style={cstyle.languageContainer}>
                                        <View style={cstyle.closeContainer}>
                                            <View style={[cstyle.languagePicker, { paddingRight: 20 * GlobalVariables.scale }]}>
                                                <LanguagePicker onRef={(ref: LanguagePicker) => (this.lp = ref)} m_pwin={this.props.m_pwin} />
                                            </View>
                                        </View>
                                    </View>
                                </ImageBackground>
                            </View>
                        </View>
                        <Loader loading={this.loading} />
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgConnect;

const cstyle = StyleSheet.create({
    registerContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '12%' },
    loginWithContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
    orLoginWithContainer: { flexDirection: 'row', width: '96%', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: '2%' },
    grayBorder: { width: '20%', height: 1, backgroundColor: '#BCC1CB' },
    loginDescriptionContainer: {
        alignItems: 'center',
        height: '16%',
    },
    loginTitleContainer: { justifyContent: 'center', alignItems: 'center', height: '10.5%' },
    loginButton: { width: '100%', backgroundColor: '#FF0D0D', justifyContent: 'center', alignItems: 'center' },
    rememberContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
    homeInputStyle: {
        backgroundColor: '#F2F4F8',
        width: '100%',
    },
    saveContainer: { flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingVertical: 12 * GlobalVariables.scale },
    form: {
        flexDirection: 'row',
        width: '100%',
        height: '34%',
    },
    fieldsContainer: {
        alignItems: 'center',
        width: '100%',
    },
    leftSideCenterContent: { width: '60%' },
    leftSideRightContent: { width: '20%' },
    languagePicker: {},
    descriptionText: { fontWeight: '600' },
    playNowText: { fontWeight: '700' },
    textStyle: { color: 'black' },
    playButton: {
        width: '46%',
        backgroundColor: 'black',
        marginHorizontal: '2%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeContainer: { justifyContent: 'flex-start', alignItems: 'flex-end', height: '25%' },
    titleContainer: { justifyContent: 'center', alignItems: 'center', height: '15%' },
    descriptionContainer: { justifyContent: 'center', alignItems: 'center', height: '20%' },
    buttonsContainer: { flexDirection: 'row', height: '25%' },
    languageContainer: { height: '15%' },
    logoRow: { width: '20%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 20 * GlobalVariables.scale },
    leftSide: { width: '50%', height: Platform.OS === 'web' ? '100vh' : '100%', flexDirection: 'row', backgroundColor: 'white' },
    rightSide: { width: '50%', height: Platform.OS === 'web' ? '100vh' : '100%' },
    content: { flexDirection: 'row', width: '100%' },
    screenSize: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});
