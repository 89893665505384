class CSize {
    cx: number = 0;
    cy: number = 0;

    constructor(cx: number, cy: number) {
        this.cx = cx;
        this.cy = cy;
    }
}

export default CSize;
