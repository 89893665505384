import React, { Component } from 'react';
import { StyleSheet, View, KeyboardAvoidingView, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import InputField from '../Components/core/InputField';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { Table, Row } from 'react-native-table-component-2';
import { ParentDlgProps } from '~/Components/props/ParentDlgProps';
import ScrollbarWrapperVertical from '~/Components/core/ScrollbarWrapperVertical';

class PlayersFilter extends Component<ParentDlgProps> {
    list_type: string;
    reg_key: string;
    start: number;

    tableHead = [];
    widthArr = [];
    tableData = [];
    m_newusername: string = '';
    sel_username: string = '';

    cstyles = stylesGenerator();

    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    async InsertUsername(username: string) {
        let row = [];
        row[0] = username;
        this.tableData.push(row);
        await this.OnSave();
        this.setState({});
    }

    InitTable() {
        this.tableHead = [];
        this.tableData = [];
        this.widthArr = [];
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Username'));
        this.widthArr.push(300 * GlobalVariables.scale);
    }

    async OnInitDialog() {
        this.InitTable();
        let ignore = await this.props.m_pwin.m_main.GetProfileString('Options', this.reg_key, '');
        while (ignore.indexOf(';') > -1) {
            let tz_index = ignore.indexOf(';');
            let ins_str = ignore.substring(0, tz_index);
            let row = [];
            row[0] = ins_str;
            this.tableData.push(row);
            ignore = ignore.substring(tz_index + 1, ignore.length);
        }
        this.m_newusername = '';
    }

    async DoModal() {
        this.props.parent.state.PlayersFilterVisible = true;
        await this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.parent.state.PlayersFilterVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.parent.setState({ PlayersFilterVisible: false });
    }

    OnOK() {
        this.start = 0;
    }

    async OnSave() {
        let ignore: string = '';
        for (let i = 0; i < this.tableData.length; i++) {
            ignore = ignore + this.tableData[i][0] + ';';
        }
        await this.props.m_pwin.m_main.WriteProfileString('Options', this.reg_key, ignore);
    }

    existInList(username: string): boolean {
        for (let i = 0; i < this.tableData.length; i++) {
            if (username.toLowerCase() === this.tableData[i][0]) {
                return true;
            }
        }
        return false;
    }

    OnAdd() {
        if (this.tableData.length < 300) {
            if (this.m_newusername !== '') {
                if (!this.existInList(this.m_newusername)) {
                    let command = 'N';
                    if (this.list_type !== 'friends') {
                        command = 'M';
                    }
                    this.props.m_pwin.SendGame('*|' + command + '|+|' + this.m_newusername);
                    this.m_newusername = '';
                } else {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('This user has already been added.')
                    );
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
            }
        } else {
            this.m_newusername = '';
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                this.props.m_pwin.m_main.GetLanguageText('You can add up to 300 users.')
            );
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
        }
        this.setState({});
    }

    DeleteUsername(username: string) {
        for (let i = 0; i < this.tableData.length; i++) {
            if (username.toLowerCase() === this.tableData[i][0].toLocaleLowerCase()) {
                this.tableData.splice(i, 1);
                break;
            }
        }
        this.setState({});
    }

    async OnDelete() {
        if (this.sel_username !== '') {
            let command = 'N';
            if (this.list_type !== 'friends') {
                command = 'M';
            }
            this.props.m_pwin.SendGame('*|' + command + '|-|' + this.sel_username);
            this.DeleteUsername(this.sel_username);
            this.sel_username = '';
            this.m_newusername = '';
            await this.OnSave();
            this.setState({});
        }
    }

    OnSelchangeList1(row: any) {
        this.sel_username = row[0];
        this.setState({});
    }

    ChangeUsernameInput(username: string) {
        this.m_newusername = username;
        this.setState({});
    }

    render() {
        if (this.props.parent.state.PlayersFilterVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop,
                                left: GlobalVariables.positionLeft + 160 * GlobalVariables.scale,
                                width: 470 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>
                                {this.list_type === 'friends' ? 'Friends list' : 'Ignore Players'}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <View style={[this.cstyles.tableBackground, { marginBottom: 10 * GlobalVariables.scale }]}>
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    <Row
                                        data={this.tableHead}
                                        widthArr={this.widthArr}
                                        style={StyleSheet.flatten(this.cstyles.header)}
                                        textStyle={StyleSheet.flatten(this.cstyles.text)}
                                    />
                                </Table>
                                <ScrollbarWrapperVertical style={StyleSheet.flatten({ height: Dimensions.get('window').height * 0.38 })}>
                                    <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                        {this.tableData.map((rowData, index) => (
                                            <Row
                                                key={index}
                                                data={rowData}
                                                widthArr={this.widthArr}
                                                height={30}
                                                style={StyleSheet.flatten(
                                                    this.props.m_pwin.m_main.GetLanguageText(this.sel_username) === rowData[0]
                                                        ? this.cstyles.rowActiveColor
                                                        : this.cstyles.rowColor
                                                )}
                                                textStyle={StyleSheet.flatten(this.cstyles.text)}
                                                onPress={() => this.OnSelchangeList1(rowData)}
                                            />
                                        ))}
                                    </Table>
                                </ScrollbarWrapperVertical>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <KeyboardAvoidingView behavior={'position'}>
                                <View style={{ width: 300 * GlobalVariables.scale }}>
                                    <InputField
                                        height={32 * GlobalVariables.scale}
                                        onChangeText={(username) => this.ChangeUsernameInput(username)}
                                        placeholder="Username"
                                        placeholderTextColor="#8b9cb5"
                                        autoCapitalize="none"
                                        keyboardType="default"
                                        returnKeyType="done"
                                        maxLength={10}
                                        underlineColorAndroid="#f000"
                                        leftIcon="human"
                                        value={this.m_newusername}
                                        autoFocus={false}
                                    />
                                </View>
                            </KeyboardAvoidingView>
                        </View>
                        <View style={styles.row}>
                            <Button
                                textSize={15}
                                containerStyle={modal.buttonStyle2PerRow}
                                onPress={this.OnAdd.bind(this)}
                                text="Add"
                                disabled={this.m_newusername === ''}
                            />
                            <Button
                                textSize={15}
                                containerStyle={modal.buttonStyle2PerRow}
                                onPress={this.OnDelete.bind(this)}
                                text="Delete"
                                disabled={this.sel_username === ''}
                            />
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle1PerRow} textSize={15} onPress={this.OnOK.bind(this)} text="Close" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default PlayersFilter;

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            width: '100%',
        },
        borderStyle: {
            borderWidth: 1,
            borderColor: '#333333',
            borderStyle: 'solid',
        },
        tableBackground: {
            backgroundColor: 'white',
        },
        header: { height: 30, backgroundColor: '#FFFFFF' },
        text: { fontWeight: '100', paddingLeft: 2 },
        rowActiveColor: { backgroundColor: 'rgb(0,120,215)' },
        rowColor: { backgroundColor: 'rgb(105,123,130)' },
    });
