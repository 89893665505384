import CBelot from './CBelot';
import React from 'react';
import { Platform, View } from 'react-native';
import DlgRezultat from '../../../Dialogs/DlgRezultat';
import GlobalConstants from '../../GlobalConstants';
import CPoint from '../../cppComponents/CPoint';
import GlobalVariables from '../../GlobalVariables';
import CRect from '../../cppComponents/CRect';
import CSize from '../../cppComponents/CSize';
import CAnnouncement from '../../../Dialogs/CAnnouncement';
import DrawPoints from '../../Draw/DrawPoints';
import DrawBid from '../../Draw/DrawBid';
import DrawNames from '../../Draw/DrawNames';
import { GamesProps } from '../../props/GamesProps';

class CBelot4Players extends CBelot {
    m_rez = new DlgRezultat(null);
    loading: boolean = true;

    constructor(props: GamesProps) {
        super(props);
    }

    SendWatchGameInitialization(watch_index: number) {
        let message = '$|z|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[2] + '?' + this.Name[3] + '?' + this.Name[4] + '?';
        //Faza
        message = message + this.IntToString(this.Faza) + '?';
        message = message + this.IntToString(this.Naddavane) + '?';
        message = message + this.IntToString(this.hand) + '?';
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.KoiPuskaParvi) + '?';
        message = message + this.IntToString(this.KoiZapochva) + '?';
        message = message + this.IntToString(this.P1puska) + '?';
        message = message + this.IntToString(this.P2puska) + '?';
        message = message + this.IntToString(this.P3puska) + '?';
        message = message + this.IntToString(this.P4puska) + '?';

        message = message + this.IntToString(this.ParviPuska) + '?';
        message = message + this.IntToString(this.VtoriPuska) + '?';
        message = message + this.IntToString(this.TretiPuska) + '?';
        message = message + this.IntToString(this.ChetvartiPuska) + '?';
        message = message + this.IntToString(this.strelka) + '?';
        message = message + this.IntToString(this.KoiObiaviava) + '?';
        message = message + this.IntToString(this.BrPas) + '?';
        message = message + this.IntToString(this.Mcard) + '?';
        message = message + this.IntToString(this.Kontra) + '?';
        message = message + this.IntToString(this.Rekontra) + '?';
        let kojkazal: string = '1';
        if (this.Naddavane > 0 && this.Naddavane < 7) {
            if (this.Pkazal[2][this.Naddavane]) {
                kojkazal = '2';
            }
            if (this.Pkazal[3][this.Naddavane]) {
                kojkazal = '3';
            }
            if (this.Pkazal[4][this.Naddavane]) {
                kojkazal = '4';
            }
        }
        if (this.Naddavane === 7) {
            kojkazal = this.IntToString(this.Kontra);
        }
        if (this.Naddavane === 8) {
            kojkazal = this.IntToString(this.Rekontra);
        }
        message = message + kojkazal + '?';

        if (this.Razdavane1) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }

        if (this.Razdavane2) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.Move) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        for (let i = 1; i < 33; i++) {
            message =
                message +
                this.IntToString(this.k[i]) +
                '?' +
                this.IntToString(this.CardsPoint[i].x) +
                '?' +
                this.IntToString(this.CardsPoint[i].y) +
                '?';
        }
        let brrez = '0';
        let mrez = '';
        for (let a = 0; a < 30; a++) {
            if (this.m_orez.orez[0][a] > 0 || this.m_orez.orez[1][a] > 0) {
                brrez = this.IntToString(a + 1);
                mrez = mrez + this.IntToString(this.m_orez.orez[0][a]) + '?';
                mrez = mrez + this.IntToString(this.m_orez.orez[1][a]) + '?';
            } else {
                break;
            }
        }
        message = message + brrez + '?' + mrez;
        message = message + this.Name[1] + '?';
        if (message !== '') {
            this.SendGame(message);
        }
    }

    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string;
        if (f === 'a') {
            //Poluchavane na imenata client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let name = this.props.m_pwin.ReceiveString(12);
            for (let i = 2; i < 5; i++) {
                if (this.Name[i] === '') {
                    if (name !== 'Computer2' && name !== 'Computer3' && name !== 'Computer4') {
                        this.Name[i] = name;
                    } else {
                        if (i === 2) {
                            this.Name[2] = 'Computer2';
                            this.props.m_pwin.InvalidateStolove(3);
                        }
                        if (i === 3) {
                            this.Name[3] = 'Computer3';
                        }
                        if (i === 4) {
                            this.Name[4] = 'Computer4';
                        }
                    }
                    this.m_orez.Player[i] = this.Name[i];
                    this.props.m_pwin.GetPlayerRating(i);
                    return true;
                }
            }
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            await this.InitGame4();
            if (this.watch) {
                this.setState({});
            }
            let koj = this.props.m_pwin.ReceiveString(1);
            if (koj === '1' || koj === '2' || koj === '3' || koj === '4') {
                this.KoiZapochva = this.KoiPuskaParvi = this.KoiObiaviava = this.KoiENaRed = this._ttoi(koj);
            }
            return true;
        }
        if (f === 'c') {
            //Poluchavane na kartite client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rstr = this.props.m_pwin.ReceiveString(2);
            for (let i = 1; i < 33; i++) {
                if (this.k[i] < 1) {
                    if (i === 1) {
                        if (this.CheckMessage()) {
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.ClearAMessages();
                        }
                    }
                    this.k[i] = this._ttoi(rstr);
                    if (i === 32) {
                        this.Razdavane1 = true;
                        this.LoadCards(false, true);
                        this.Razdavane2 = false;
                        this.online_timer = this.online_timer_time;
                        this.setState({});
                    }
                    return true;
                }
            }
            return true;
        }
        if (f === 'd') {
            //Poluchavane na anons
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.setState({});
            let annons = this.props.m_pwin.ReceiveString(1);
            if (
                annons === '0' ||
                annons === '1' ||
                annons === '2' ||
                annons === '3' ||
                annons === '4' ||
                annons === '5' ||
                annons === '6' ||
                annons === '7' ||
                annons === '8'
            ) {
                this.WriteAMessage(annons);
                await this.Timer2();
            } else {
                this.props.m_pwin.SendErrorToLog('Nevaliden Anons: ' + annons);
            }
            return true;
        }
        if (f === 'e') {
            //Poluchavane na Visiashti tochki
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let vs = this.props.m_pwin.ReceiveString(4);
            this.Visiashti = this._ttoi(vs);
            return true;
        }
        if (f === 'f') {
            //Poluchavane na karta
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let card = this.props.m_pwin.ReceiveString(1);
            if (card === '1' || card === '2' || card === '3' || card === '4' || card === '5' || card === '6' || card === '7' || card === '8') {
                this.WriteMessage(card);
                await this.Timer3();
            } else {
                this.props.m_pwin.SendErrorToLog('Poluchavane na greshna karta: ' + card);
            }
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.setState({});
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[3]) {
                        this.P3OP = false;
                        this.P3G = true;
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[4]) {
                        this.P4OP = false;
                        this.P4G = true;
                        this.Name[4] = 'Computer4';
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.wait_opplayers[2] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[3]) {
                        this.wait_opnames[3] = '';
                        this.wait_opplayers[3] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[4]) {
                        this.wait_opnames[4] = '';
                        this.wait_opplayers[4] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.props.m_pwin.InvalidateStolove(3);
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    if (name === this.Name[3]) {
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                    }
                    if (name === this.Name[4]) {
                        this.Name[4] = 'Computer4';
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            this.P3G = true;
            return true;
        }
        if (f === 'j') {
            this.P4G = true;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '3') {
                this.P3OP = false;
            }
            if (strRecvd === '4') {
                this.P4OP = false;
            }
            if (strRecvd === '5') {
                this.P2OP = true;
            }
            if (strRecvd === '6') {
                this.P3OP = true;
            }
            if (strRecvd === '7') {
                this.P4OP = true;
            }
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            this.setState({});
            return true;
        }
        if (f === 'm') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            this.op_belot[this._ttoi(strRecvd)] = true;
            return true;
        }
        if (f === 'n') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            let player = this.props.m_pwin.ReceiveString(1);
            this.terci[this._ttoi(player)][this._ttoi(strRecvd)] = true;
            return true;
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2G = false;
                if (this.Faza === 6) {
                    this.P2G = true;
                }
            }
            if (strRecvd === '3') {
                this.P3G = false;
                if (this.Faza === 6) {
                    this.P3G = true;
                }
            }
            if (strRecvd === '4') {
                this.P4G = false;
                if (this.Faza === 6) {
                    this.P4G = true;
                }
            }
            if (strRecvd === '5') {
                this.P2G = true;
            }
            if (strRecvd === '6') {
                this.P3G = true;
            }
            if (strRecvd === '7') {
                this.P4G = true;
            }
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index: number = this._ttoi(pistr);
            let rating = this.props.m_pwin.ReceiveString(30);
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                if (this.watch) {
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    this.setState({});
                    return true;
                }
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[player_index] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    if (player_index === 4) {
                        this.P4OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    if (player_index === 4) {
                        this.P4OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                } else {
                    message = '$|s|' + pistr + '|0';
                    this.SendGame(message);
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        let title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title + '?');
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane na Igrach po vreme na igra
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please wait for the next deal'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user"));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rez1 = this.props.m_pwin.ReceiveString(5);
            let rez2 = this.props.m_pwin.ReceiveString(5);
            if (this.watch) {
                if (rez1 === '0' && rez2 === '0') {
                    this.m_orez.m_rez1 = 0;
                    this.m_orez.m_rez2 = 0;
                    this.Visiashti = 0;
                    for (let i = 0; i < 2; i++) {
                        for (let a = 0; a < 100; a++) {
                            this.m_orez.orez[i][a] = -10;
                        }
                    }
                    this.setState({});
                    return true;
                } else {
                    for (let a = 0; a < 30; a++) {
                        if (this.m_orez.orez[0][a] === -10) {
                            this.m_orez.orez[0][a] = this._ttoi(rez1);
                            this.m_orez.orez[1][a] = this._ttoi(rez2);
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                            this.setState({});
                            return true;
                        }
                    }
                }
            } else {
                for (let a = 0; a < 30; a++) {
                    if (this.m_orez.orez[0][a] === -10) {
                        this.m_orez.orez[0][a] = this._ttoi(rez1);
                        this.m_orez.orez[1][a] = this._ttoi(rez2);
                        this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
                        this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
                        this.setState({});
                        return true;
                    }
                }
            }
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index = this._ttoi(pistr);
            let player_name = this.props.m_pwin.ReceiveString(12);
            this.Name[player_index] = player_name;
            this.props.m_pwin.GetPlayerRating(player_index);
            this.setState({});
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        if (f === 'u') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'v') {
            //ReceiveWachGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(500);
            await this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!b4 : ' + f);

        return true;
    }

    async ReceiveWachGameInitialization(init: string) {
        await this.OnLButtonDown(new CPoint(0, 0));
        await this.InitGame4();
        let a: number;
        let brrez: number;
        let kojkazal = 1;
        let vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[3] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[4] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        if (this.Faza < 0) {
            this.ReceiveWachGameInitializationEnd();
            return;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Naddavane = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.hand = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiPuskaParvi = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P1puska = this._ttoi(s1);
        if (this.P1puska > 0) {
            //	CardP[33]=CardP[0];
            this.ConvertCard(this.P1puska, 33);
            this.CardsPoint[33].x = this.CardsPos[33].x;
            this.CardsPoint[33].y = this.CardsPos[33].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P2puska = this._ttoi(s1);
        if (this.P2puska > 0) {
            //	CardP[34]=CardP[0];
            this.ConvertCard(this.P2puska, 34);
            this.CardsPoint[34].x = this.CardsPos[34].x;
            this.CardsPoint[34].y = this.CardsPos[34].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P3puska = this._ttoi(s1);
        if (this.P3puska > 0) {
            //	CardP[35]=CardP[0];
            this.ConvertCard(this.P3puska, 35);
            this.CardsPoint[35].x = this.CardsPos[35].x;
            this.CardsPoint[35].y = this.CardsPos[35].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P4puska = this._ttoi(s1);
        if (this.P4puska > 0) {
            //	CardP[36]=CardP[0];
            this.ConvertCard(this.P4puska, 36);
            this.CardsPoint[36].x = this.CardsPos[36].x;
            this.CardsPoint[36].y = this.CardsPos[36].y;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ParviPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.VtoriPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.TretiPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ChetvartiPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.strelka = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiObiaviava = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        if (this.KoiObiaviava === 0) {
            this.KoiObiaviava = 1;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.BrPas = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mcard = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Kontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Rekontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        kojkazal = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        if (this.Naddavane > 0 && this.Naddavane < 7) {
            this.Pkazal[kojkazal][this.Naddavane] = true;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane1 = true;
            if (GlobalVariables.Zvuk === 1 && this.Faza <= 4) {
                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
            }
        } else {
            this.Razdavane1 = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane2 = true;
            if (GlobalVariables.Zvuk === 1 && this.Faza <= 4) {
                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
            }
        } else {
            this.Razdavane2 = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Move = true;
        } else {
            this.Move = false;
        }
        init = init.substring(vpos + 1, init.length);

        for (let i = 1; i < 33; i++) {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.k[i] = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].x = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].y = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        brrez = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        if (brrez > 0) {
            for (a = 0; a < brrez; a++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez1 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                let rez2 = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
                if (rez1 > 0 || rez2 > 0) {
                    this.m_orez.orez[0][a] = rez1;
                    this.m_orez.orez[1][a] = rez2;
                }
            }
        }
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        for (a = 0; a < brrez; a++) {
            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[0][a];
            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[1][a];
        }

        if (init !== '' && init !== '?') {
            vpos = init.indexOf('?');
            this.Name[1] = init.substring(0, vpos);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
        }

        if (this.Faza > 4) {
            this.LoadCards(true, false);
        } else {
            this.LoadCards(false, false);
        }
        if ((this.Razdavane1 || this.Razdavane2) && this.Mcard > 0 && this.Move) {
            this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
        } else {
            if (this.Move) {
                if (this.Mcard > 0) {
                    this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
                }
            }
        }
        this.ReceiveWachGameInitializationEnd();
    }

    ReceiveWachGameInitializationEnd() {
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.props.m_pwin.GetPlayerRating(3);
        this.props.m_pwin.GetPlayerRating(4);
        this.setState({});
    }

    async InitOP(server: boolean) {
        this.loading = true;
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init4();
        await this.InitGame4();
        this.InitTimer(20);
        this.loading = false;
    }

    ReturnSortCards(card: number) {
        if (this.Naddavane === 1) {
            if (card === 11) {
                return 101;
            }
            if (card === 12) {
                return 102;
            }
            if (card === 13) {
                return 107;
            }
            if (card === 14) {
                return 105;
            }
            if (card === 15) {
                return 108;
            }
            if (card === 16) {
                return 103;
            }
            if (card === 17) {
                return 104;
            }
            if (card === 18) {
                return 106;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 22;
            }
            if (card === 23) {
                return 23;
            }
            if (card === 24) {
                return 27;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 25;
            }
            if (card === 27) {
                return 26;
            }
            if (card === 28) {
                return 28;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 32;
            }
            if (card === 33) {
                return 33;
            }
            if (card === 34) {
                return 37;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 35;
            }
            if (card === 37) {
                return 36;
            }
            if (card === 38) {
                return 38;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 42;
            }
            if (card === 43) {
                return 43;
            }
            if (card === 44) {
                return 47;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 45;
            }
            if (card === 47) {
                return 46;
            }
            if (card === 48) {
                return 48;
            }
        }
        if (this.Naddavane === 2) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 12;
            }
            if (card === 13) {
                return 13;
            }
            if (card === 14) {
                return 17;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 15;
            }
            if (card === 17) {
                return 16;
            }
            if (card === 18) {
                return 18;
            }
            if (card === 21) {
                return 101;
            }
            if (card === 22) {
                return 102;
            }
            if (card === 23) {
                return 107;
            }
            if (card === 24) {
                return 105;
            }
            if (card === 25) {
                return 108;
            }
            if (card === 26) {
                return 103;
            }
            if (card === 27) {
                return 104;
            }
            if (card === 28) {
                return 106;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 32;
            }
            if (card === 33) {
                return 33;
            }
            if (card === 34) {
                return 37;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 35;
            }
            if (card === 37) {
                return 36;
            }
            if (card === 38) {
                return 38;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 42;
            }
            if (card === 43) {
                return 43;
            }
            if (card === 44) {
                return 47;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 45;
            }
            if (card === 47) {
                return 46;
            }
            if (card === 48) {
                return 48;
            }
        }
        if (this.Naddavane === 3) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 12;
            }
            if (card === 13) {
                return 13;
            }
            if (card === 14) {
                return 17;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 15;
            }
            if (card === 17) {
                return 16;
            }
            if (card === 18) {
                return 18;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 22;
            }
            if (card === 23) {
                return 23;
            }
            if (card === 24) {
                return 27;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 25;
            }
            if (card === 27) {
                return 26;
            }
            if (card === 28) {
                return 28;
            }
            if (card === 31) {
                return 101;
            }
            if (card === 32) {
                return 102;
            }
            if (card === 33) {
                return 107;
            }
            if (card === 34) {
                return 105;
            }
            if (card === 35) {
                return 108;
            }
            if (card === 36) {
                return 103;
            }
            if (card === 37) {
                return 104;
            }
            if (card === 38) {
                return 106;
            }
            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 42;
            }
            if (card === 43) {
                return 43;
            }
            if (card === 44) {
                return 47;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 45;
            }
            if (card === 47) {
                return 46;
            }
            if (card === 48) {
                return 48;
            }
        }
        if (this.Naddavane === 4) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 12;
            }
            if (card === 13) {
                return 13;
            }
            if (card === 14) {
                return 17;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 15;
            }
            if (card === 17) {
                return 16;
            }
            if (card === 18) {
                return 18;
            }
            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 22;
            }
            if (card === 23) {
                return 23;
            }
            if (card === 24) {
                return 27;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 25;
            }
            if (card === 27) {
                return 26;
            }
            if (card === 28) {
                return 28;
            }
            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 32;
            }
            if (card === 33) {
                return 33;
            }
            if (card === 34) {
                return 37;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 35;
            }
            if (card === 37) {
                return 36;
            }
            if (card === 38) {
                return 38;
            }
            if (card === 41) {
                return 101;
            }
            if (card === 42) {
                return 102;
            }
            if (card === 43) {
                return 107;
            }
            if (card === 44) {
                return 105;
            }
            if (card === 45) {
                return 108;
            }
            if (card === 46) {
                return 103;
            }
            if (card === 47) {
                return 104;
            }
            if (card === 48) {
                return 106;
            }
        }
        if (this.Naddavane === 5) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 12;
            }
            if (card === 13) {
                return 13;
            }
            if (card === 14) {
                return 17;
            }
            if (card === 15) {
                return 14;
            }
            if (card === 16) {
                return 15;
            }
            if (card === 17) {
                return 16;
            }
            if (card === 18) {
                return 18;
            }

            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 22;
            }
            if (card === 23) {
                return 23;
            }
            if (card === 24) {
                return 27;
            }
            if (card === 25) {
                return 24;
            }
            if (card === 26) {
                return 25;
            }
            if (card === 27) {
                return 26;
            }
            if (card === 28) {
                return 28;
            }

            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 32;
            }
            if (card === 33) {
                return 33;
            }
            if (card === 34) {
                return 37;
            }
            if (card === 35) {
                return 34;
            }
            if (card === 36) {
                return 35;
            }
            if (card === 37) {
                return 36;
            }
            if (card === 38) {
                return 38;
            }

            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 42;
            }
            if (card === 43) {
                return 43;
            }
            if (card === 44) {
                return 47;
            }
            if (card === 45) {
                return 44;
            }
            if (card === 46) {
                return 45;
            }
            if (card === 47) {
                return 46;
            }
            if (card === 48) {
                return 48;
            }
        }
        if (this.Naddavane === 6) {
            if (card === 11) {
                return 11;
            }
            if (card === 12) {
                return 12;
            }
            if (card === 13) {
                return 17;
            }
            if (card === 14) {
                return 15;
            }
            if (card === 15) {
                return 18;
            }
            if (card === 16) {
                return 13;
            }
            if (card === 17) {
                return 14;
            }
            if (card === 18) {
                return 16;
            }

            if (card === 21) {
                return 21;
            }
            if (card === 22) {
                return 22;
            }
            if (card === 23) {
                return 27;
            }
            if (card === 24) {
                return 25;
            }
            if (card === 25) {
                return 28;
            }
            if (card === 26) {
                return 23;
            }
            if (card === 27) {
                return 24;
            }
            if (card === 28) {
                return 26;
            }

            if (card === 31) {
                return 31;
            }
            if (card === 32) {
                return 32;
            }
            if (card === 33) {
                return 37;
            }
            if (card === 34) {
                return 35;
            }
            if (card === 35) {
                return 38;
            }
            if (card === 36) {
                return 33;
            }
            if (card === 37) {
                return 34;
            }
            if (card === 38) {
                return 36;
            }

            if (card === 41) {
                return 41;
            }
            if (card === 42) {
                return 42;
            }
            if (card === 43) {
                return 47;
            }
            if (card === 44) {
                return 45;
            }
            if (card === 45) {
                return 48;
            }
            if (card === 46) {
                return 43;
            }
            if (card === 47) {
                return 44;
            }
            if (card === 48) {
                return 46;
            }
        }
        return 0;
    }

    SortCards(type: number) {
        let a: number;
        let sort: number[] = [];
        let temps: number;
        let tempk: number;
        let tempc: string;
        if (type === 0) {
            for (let i = 1; i < 6; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 9; i < 14; i++) {
                for (a = 9; a < 13; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 17; i < 22; i++) {
                for (a = 17; a < 21; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 25; i < 30; i++) {
                for (a = 25; a < 29; a++) {
                    if (this.k[a] > this.k[a + 1]) {
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
        if (type === 1) {
            for (let i = 1; i < 9; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 1; i < 9; i++) {
                for (a = 1; a < 8; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 9; i < 17; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 9; i < 17; i++) {
                for (a = 9; a < 16; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 17; i < 25; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 17; i < 25; i++) {
                for (a = 17; a < 24; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
            for (let i = 25; i < 33; i++) {
                this.k[i] = this.ReturnSortCards(this.k[i]);
                sort[i] = this.k[i];
            }
            for (let i = 25; i < 33; i++) {
                for (a = 25; a < 32; a++) {
                    if (sort[a] > sort[a + 1]) {
                        temps = sort[a + 1];
                        sort[a + 1] = sort[a];
                        sort[a] = temps;
                        tempk = this.k[a + 1];
                        this.k[a + 1] = this.k[a];
                        this.k[a] = tempk;
                        tempc = this.CardP[a + 1].image;
                        this.CardP[a + 1].image = this.CardP[a].image;
                        this.CardP[a].image = tempc;
                    }
                }
            }
        }
    }

    Strelka() {
        this.hand = this.hand + 1;
        let addrez = 0;
        if (this.Naddavane < 5) {
            if (this.P1puska === 14 || this.P2puska === 14 || this.P3puska === 14 || this.P4puska === 14) {
                addrez = addrez + 2;
            }
            if (this.P1puska === 15 || this.P2puska === 15 || this.P3puska === 15 || this.P4puska === 15) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 16 || this.P2puska === 16 || this.P3puska === 16 || this.P4puska === 16) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 17 || this.P2puska === 17 || this.P3puska === 17 || this.P4puska === 17) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 18 || this.P2puska === 18 || this.P3puska === 18 || this.P4puska === 18) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 24 || this.P2puska === 24 || this.P3puska === 24 || this.P4puska === 24) {
                addrez = addrez + 2;
            }
            if (this.P1puska === 25 || this.P2puska === 25 || this.P3puska === 25 || this.P4puska === 25) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 26 || this.P2puska === 26 || this.P3puska === 26 || this.P4puska === 26) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 27 || this.P2puska === 27 || this.P3puska === 27 || this.P4puska === 27) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 28 || this.P2puska === 28 || this.P3puska === 28 || this.P4puska === 28) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 34 || this.P2puska === 34 || this.P3puska === 34 || this.P4puska === 34) {
                addrez = addrez + 2;
            }
            if (this.P1puska === 35 || this.P2puska === 35 || this.P3puska === 35 || this.P4puska === 35) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 36 || this.P2puska === 36 || this.P3puska === 36 || this.P4puska === 36) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 37 || this.P2puska === 37 || this.P3puska === 37 || this.P4puska === 37) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 38 || this.P2puska === 38 || this.P3puska === 38 || this.P4puska === 38) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 44 || this.P2puska === 44 || this.P3puska === 44 || this.P4puska === 44) {
                addrez = addrez + 2;
            }
            if (this.P1puska === 45 || this.P2puska === 45 || this.P3puska === 45 || this.P4puska === 45) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 46 || this.P2puska === 46 || this.P3puska === 46 || this.P4puska === 46) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 47 || this.P2puska === 47 || this.P3puska === 47 || this.P4puska === 47) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 48 || this.P2puska === 48 || this.P3puska === 48 || this.P4puska === 48) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 103 || this.P2puska === 103 || this.P3puska === 103 || this.P4puska === 103) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 104 || this.P2puska === 104 || this.P3puska === 104 || this.P4puska === 104) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 105 || this.P2puska === 105 || this.P3puska === 105 || this.P4puska === 105) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 106 || this.P2puska === 106 || this.P3puska === 106 || this.P4puska === 106) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 107 || this.P2puska === 107 || this.P3puska === 107 || this.P4puska === 107) {
                addrez = addrez + 14;
            }
            if (this.P1puska === 108 || this.P2puska === 108 || this.P3puska === 108 || this.P4puska === 108) {
                addrez = addrez + 20;
            }
            if (this.hand === 8) {
                addrez = addrez + 10;
            }
        }
        if (this.Naddavane === 5) {
            if (this.P1puska === 14 || this.P2puska === 14 || this.P3puska === 14 || this.P4puska === 14) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 15 || this.P2puska === 15 || this.P3puska === 15 || this.P4puska === 15) {
                addrez = addrez + 6;
            }
            if (this.P1puska === 16 || this.P2puska === 16 || this.P3puska === 16 || this.P4puska === 16) {
                addrez = addrez + 8;
            }
            if (this.P1puska === 17 || this.P2puska === 17 || this.P3puska === 17 || this.P4puska === 17) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 18 || this.P2puska === 18 || this.P3puska === 18 || this.P4puska === 18) {
                addrez = addrez + 22;
            }
            if (this.P1puska === 24 || this.P2puska === 24 || this.P3puska === 24 || this.P4puska === 24) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 25 || this.P2puska === 25 || this.P3puska === 25 || this.P4puska === 25) {
                addrez = addrez + 6;
            }
            if (this.P1puska === 26 || this.P2puska === 26 || this.P3puska === 26 || this.P4puska === 26) {
                addrez = addrez + 8;
            }
            if (this.P1puska === 27 || this.P2puska === 27 || this.P3puska === 27 || this.P4puska === 27) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 28 || this.P2puska === 28 || this.P3puska === 28 || this.P4puska === 28) {
                addrez = addrez + 22;
            }
            if (this.P1puska === 34 || this.P2puska === 34 || this.P3puska === 34 || this.P4puska === 34) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 35 || this.P2puska === 35 || this.P3puska === 35 || this.P4puska === 35) {
                addrez = addrez + 6;
            }
            if (this.P1puska === 36 || this.P2puska === 36 || this.P3puska === 36 || this.P4puska === 36) {
                addrez = addrez + 8;
            }
            if (this.P1puska === 37 || this.P2puska === 37 || this.P3puska === 37 || this.P4puska === 37) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 38 || this.P2puska === 38 || this.P3puska === 38 || this.P4puska === 38) {
                addrez = addrez + 22;
            }
            if (this.P1puska === 44 || this.P2puska === 44 || this.P3puska === 44 || this.P4puska === 44) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 45 || this.P2puska === 45 || this.P3puska === 45 || this.P4puska === 45) {
                addrez = addrez + 6;
            }
            if (this.P1puska === 46 || this.P2puska === 46 || this.P3puska === 46 || this.P4puska === 46) {
                addrez = addrez + 8;
            }
            if (this.P1puska === 47 || this.P2puska === 47 || this.P3puska === 47 || this.P4puska === 47) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 48 || this.P2puska === 48 || this.P3puska === 48 || this.P4puska === 48) {
                addrez = addrez + 22;
            }
            if (this.hand === 8) {
                addrez = addrez + 20;
            }
        }
        if (this.Naddavane === 6) {
            if (this.P1puska === 13 || this.P2puska === 13 || this.P3puska === 13 || this.P4puska === 13) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 14 || this.P2puska === 14 || this.P3puska === 14 || this.P4puska === 14) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 15 || this.P2puska === 15 || this.P3puska === 15 || this.P4puska === 15) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 16 || this.P2puska === 16 || this.P3puska === 16 || this.P4puska === 16) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 17 || this.P2puska === 17 || this.P3puska === 17 || this.P4puska === 17) {
                addrez = addrez + 14;
            }
            if (this.P1puska === 18 || this.P2puska === 18 || this.P3puska === 18 || this.P4puska === 18) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 23 || this.P2puska === 23 || this.P3puska === 23 || this.P4puska === 23) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 24 || this.P2puska === 24 || this.P3puska === 24 || this.P4puska === 24) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 25 || this.P2puska === 25 || this.P3puska === 25 || this.P4puska === 25) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 26 || this.P2puska === 26 || this.P3puska === 26 || this.P4puska === 26) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 27 || this.P2puska === 27 || this.P3puska === 27 || this.P4puska === 27) {
                addrez = addrez + 14;
            }
            if (this.P1puska === 28 || this.P2puska === 28 || this.P3puska === 28 || this.P4puska === 28) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 33 || this.P2puska === 33 || this.P3puska === 33 || this.P4puska === 33) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 34 || this.P2puska === 34 || this.P3puska === 34 || this.P4puska === 34) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 35 || this.P2puska === 35 || this.P3puska === 35 || this.P4puska === 35) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 36 || this.P2puska === 36 || this.P3puska === 36 || this.P4puska === 36) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 37 || this.P2puska === 37 || this.P3puska === 37 || this.P4puska === 37) {
                addrez = addrez + 14;
            }
            if (this.P1puska === 38 || this.P2puska === 38 || this.P3puska === 38 || this.P4puska === 38) {
                addrez = addrez + 20;
            }
            if (this.P1puska === 43 || this.P2puska === 43 || this.P3puska === 43 || this.P4puska === 43) {
                addrez = addrez + 3;
            }
            if (this.P1puska === 44 || this.P2puska === 44 || this.P3puska === 44 || this.P4puska === 44) {
                addrez = addrez + 4;
            }
            if (this.P1puska === 45 || this.P2puska === 45 || this.P3puska === 45 || this.P4puska === 45) {
                addrez = addrez + 10;
            }
            if (this.P1puska === 46 || this.P2puska === 46 || this.P3puska === 46 || this.P4puska === 46) {
                addrez = addrez + 11;
            }
            if (this.P1puska === 47 || this.P2puska === 47 || this.P3puska === 47 || this.P4puska === 47) {
                addrez = addrez + 14;
            }
            if (this.P1puska === 48 || this.P2puska === 48 || this.P3puska === 48 || this.P4puska === 48) {
                addrez = addrez + 20;
            }
            if (this.hand === 8) {
                addrez = addrez + 10;
            }
        }
        if (this.P1puska > 100 || this.P2puska > 100 || this.P3puska > 100 || this.P4puska > 100) {
            if (this.P1puska > this.P2puska && this.P1puska > this.P3puska && this.P1puska > this.P4puska) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                return 1;
            }
            if (this.P2puska > this.P1puska && this.P2puska > this.P3puska && this.P2puska > this.P4puska) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                return 2;
            }
            if (this.P3puska > this.P2puska && this.P3puska > this.P1puska && this.P3puska > this.P4puska) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                return 3;
            }
            if (this.P4puska > this.P2puska && this.P4puska > this.P3puska && this.P4puska > this.P1puska) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                return 4;
            }
        }
        //Ako  se iska pika
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            for (let i = 48; i > 40; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 2;
                }
                if (this.P3puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 3;
                }
                if (this.P4puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 4;
                }
            }
        }
        //Ako se iska kupa
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            for (let i = 38; i > 30; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 2;
                }
                if (this.P3puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 3;
                }
                if (this.P4puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 4;
                }
            }
        }
        //Ako se iska karo
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            for (let i = 28; i > 20; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 2;
                }
                if (this.P3puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 3;
                }
                if (this.P4puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 4;
                }
            }
        }
        //Ako se iska spatia
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            for (let i = 18; i > 10; i--) {
                if (this.P1puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 1;
                }
                if (this.P2puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 2;
                }
                if (this.P3puska === i) {
                    this.m_rez.m_rez13 = this.m_rez.m_rez13 + addrez;
                    return 3;
                }
                if (this.P4puska === i) {
                    this.m_rez.m_rez24 = this.m_rez.m_rez24 + addrez;
                    return 4;
                }
            }
        }
        this.props.m_pwin.SendErrorToLog('Nevalidna Strelka');
        return 1;
    }

    SendCards() {
        let a: number;
        if (this.P2OP) {
            this.P2G = false;
            for (a = 9; a < 33; a++) {
                this.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (a = 1; a < 9; a++) {
                this.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
        }
        if (this.P3OP) {
            this.P3G = false;
            for (a = 17; a < 33; a++) {
                this.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
            for (a = 1; a < 17; a++) {
                this.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
        }
        if (this.P4OP) {
            this.P4G = false;
            for (a = 25; a < 33; a++) {
                this.SendGame('$|i|' + this.IntToString(this.k[a]));
            }
            for (a = 1; a < 25; a++) {
                this.SendGame('$|i|' + this.IntToString(this.k[a]));
            }
        }
        if (this.HaveWatch) {
            for (a = 1; a < 33; a++) {
                this.SendGame('$|j|' + this.IntToString(this.k[a]));
            }
        }
    }

    async Timer1() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.SP) {
            if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0) {
                this.strelka = this.Strelka();
                if (this.strelka === 1 || this.strelka === 3) {
                    this.kapo1 = false;
                } else {
                    this.kapo2 = false;
                }
                this.vz[this.hand] = this.strelka;
                this.setState({});
                this.props.m_pwin.KillTimer(4);
                this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
            }
            if (this.Move === true) {
                this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                this.setState({});
            }
            if ((this.Razdavane1 === true || this.Razdavane2 === true) && this.Move !== true && this.Faza < 4) {
                let karta = this.KartaZaRazdavane();
                this.setState({});
                
                this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
            }
            if (this.Razdavane1 !== true && this.Razdavane2 !== true && this.Move !== true && this.Faza === 1) {
                this.Faza = 2;
                this.SortCards(0);
                this.setState({});
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            }
            if (this.Faza === 4 && this.Razdavane2 !== true && this.Move !== true) {
                this.Faza = 5;
                for (let f = 1; f <= 4; f++) {
                    this.CheckTerci(true, f);
                }
                this.SortCards(1);
                this.setState({});
                this.props.m_pwin.KillTimer(3);
                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
            }
        }
        if (this.OP) {
            if (this.Server) {
                if (this.Faza === 6 && this.P2G === true && this.P3G === true && this.P4G === true) {
                    if (this.P2OP === true) {
                        this.P2G = false;
                    }
                    if (this.P3OP === true) {
                        this.P3G = false;
                    }
                    if (this.P4OP === true) {
                        this.P4G = false;
                    }
                    this.Razdavane1 = true;
                    await this.InitGame4();
                    this.SendKoiENaRed();
                    this.SendCards();
                    this.setState({});
                    this.online_timer = this.online_timer_time;
                }
                if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0) {
                    this.strelka = this.Strelka();
                    if (this.strelka === 1 || this.strelka === 3) {
                        this.kapo1 = false;
                    } else {
                        this.kapo2 = false;
                    }
                    this.vz[this.hand] = this.strelka;
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                    this.setState({});
                }
                if ((this.Razdavane1 === true || this.Razdavane2 === true) && this.Move !== true && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.setState({});
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (this.Razdavane1 !== true && this.Razdavane2 !== true && this.Move !== true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
                if (this.Faza === 4 && this.Razdavane2 !== true && this.Move !== true) {
                    this.Faza = 5;
                    for (let f = 1; f <= 4; f++) {
                        this.CheckTerci(true, f);
                    }
                    this.SortCards(1);
                    this.setState({});
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                }
            } else {
                if (this.strelka === 0 && this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0) {
                    this.strelka = this.Strelka();
                    if (this.strelka === 1 || this.strelka === 3) {
                        this.kapo1 = false;
                    } else {
                        this.kapo2 = false;
                    }
                    this.vz[this.hand] = this.strelka;
                    this.setState({});
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1 && !this.Razdavane2);
                    this.setState({});
                }
                if ((this.Razdavane1 === true || this.Razdavane2 === true) && this.Move !== true && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.setState({});
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (this.Razdavane1 !== true && this.Razdavane2 !== true && this.Move !== true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                }
                if (this.Faza === 4 && this.Razdavane2 !== true && this.Move !== true) {
                    this.Faza = 5;
                    for (let f = 1; f <= 4; f++) {
                        this.CheckTerci(true, f);
                    }
                    this.SortCards(1);
                    this.setState({});
                }
            }
        }
    }

    Anonsi(Player: number) {
        if (this.Kontra > 0) {
        }
        if (this.Rekontra > 0) {
        }
        let spatia: number;
        let karo: number;
        let kupa: number;
        let pika: number;
        let bezkoz: number;
        let vsichkokoz: number;
        spatia = karo = kupa = pika = bezkoz = vsichkokoz = 0;
        if (this.Naddavane < 2) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 15) {
                    spatia = spatia + 120;
                }
                if (this.k[a] === 13) {
                    spatia = spatia + 80;
                }
                if (this.k[a] === 18) {
                    spatia = spatia + 70;
                }
                if (this.k[a] === 14) {
                    spatia = spatia + 50;
                }
                if (this.k[a] === 17) {
                    spatia = spatia + 40;
                }
                if (this.k[a] === 16) {
                    spatia = spatia + 30;
                }
                if (this.k[a] === 12) {
                    spatia = spatia + 25;
                }
                if (this.k[a] === 11) {
                    spatia = spatia + 20;
                }
                if (this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) {
                    spatia = spatia + 60;
                }
                if (this.k[a] === 24 || this.k[a] === 34 || this.k[a] === 44) {
                    spatia = spatia + 30;
                }
            }
        }
        //////////////KARO///////////////////////////////////////////
        if (this.Naddavane < 3) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 25) {
                    karo = karo + 120;
                }
                if (this.k[a] === 23) {
                    karo = karo + 80;
                }
                if (this.k[a] === 28) {
                    karo = karo + 70;
                }
                if (this.k[a] === 24) {
                    karo = karo + 50;
                }
                if (this.k[a] === 27) {
                    karo = karo + 40;
                }
                if (this.k[a] === 26) {
                    karo = karo + 30;
                }
                if (this.k[a] === 22) {
                    karo = karo + 25;
                }
                if (this.k[a] === 21) {
                    karo = karo + 20;
                }
                if (this.k[a] === 18 || this.k[a] === 38 || this.k[a] === 48) {
                    karo = karo + 60;
                }
                if (this.k[a] === 14 || this.k[a] === 34 || this.k[a] === 44) {
                    karo = karo + 30;
                }
            }
        }
        //////////////KUPA////////////////////////////////////////////
        if (this.Naddavane < 4) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 35) {
                    kupa = kupa + 120;
                }
                if (this.k[a] === 33) {
                    kupa = kupa + 80;
                }
                if (this.k[a] === 38) {
                    kupa = kupa + 70;
                }
                if (this.k[a] === 34) {
                    kupa = kupa + 50;
                }
                if (this.k[a] === 37) {
                    kupa = kupa + 40;
                }
                if (this.k[a] === 36) {
                    kupa = kupa + 30;
                }
                if (this.k[a] === 32) {
                    kupa = kupa + 25;
                }
                if (this.k[a] === 31) {
                    kupa = kupa + 20;
                }
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 48) {
                    kupa = kupa + 60;
                }
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 44) {
                    kupa = kupa + 30;
                }
            }
        }
        //////////////////////////PIKA//////////////////////////////
        if (this.Naddavane < 5) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 45) {
                    pika = pika + 120;
                }
                if (this.k[a] === 43) {
                    pika = pika + 80;
                }
                if (this.k[a] === 48) {
                    pika = pika + 70;
                }
                if (this.k[a] === 44) {
                    pika = pika + 50;
                }
                if (this.k[a] === 47) {
                    pika = pika + 40;
                }
                if (this.k[a] === 46) {
                    pika = pika + 30;
                }
                if (this.k[a] === 42) {
                    pika = pika + 25;
                }
                if (this.k[a] === 41) {
                    pika = pika + 20;
                }
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38) {
                    pika = pika + 60;
                }
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 34) {
                    pika = pika + 30;
                }
            }
        }
        ////////////BEZKOZ/////////////////////////////////////////
        if (this.Naddavane < 6) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) {
                    bezkoz = bezkoz + 90;
                }
                if (this.k[a] === 14 || this.k[a] === 24 || this.k[a] === 34 || this.k[a] === 44) {
                    bezkoz = bezkoz + 60;
                }
                if (this.k[a] === 17 || this.k[a] === 27 || this.k[a] === 37 || this.k[a] === 47) {
                    bezkoz = bezkoz + 20;
                }
            }
        }
        if (this.Naddavane < 7) {
            for (let a = (Player - 1) * 8 + 1; a < (Player - 1) * 8 + 7; a++) {
                if (this.k[a] === 15 || this.k[a] === 25 || this.k[a] === 35 || this.k[a] === 45) {
                    vsichkokoz = vsichkokoz + 90;
                }
                if (this.k[a] === 13 || this.k[a] === 23 || this.k[a] === 33 || this.k[a] === 43) {
                    vsichkokoz = vsichkokoz + 60;
                }
                if (this.k[a] === 18 || this.k[a] === 28 || this.k[a] === 38 || this.k[a] === 48) {
                    vsichkokoz = vsichkokoz + 20;
                }
            }
        }
        if (
            ((Player === 3 || Player === 1) && (this.m_orez.m_rez2 < 145 || this.m_orez.m_rez2 - this.m_orez.m_rez1 < 12)) ||
            ((Player === 2 || Player === 4) && (this.m_orez.m_rez1 < 145 || this.m_orez.m_rez1 - this.m_orez.m_rez2 < 12))
        ) {
            let tercaf = this.CheckTerci(false, Player);
            if (tercaf === 36) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 200;
            }
            if (tercaf === 35) {
                spatia = spatia + 150;
                karo = karo + 170;
                kupa = kupa + 160;
                pika = pika + 150;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf === 34) {
                spatia = spatia + 150;
                karo = karo + 150;
                kupa = kupa + 150;
                pika = pika + 150;
                bezkoz = bezkoz + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf === 33) {
                spatia = spatia + 120;
                karo = karo + 120;
                kupa = kupa + 120;
                pika = pika + 120;
                vsichkokoz = vsichkokoz + 50;
            }
            if (tercaf === 32 || tercaf === 31) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                bezkoz = bezkoz + 40;
            }
            if (tercaf > 20 && tercaf < 30) {
                spatia = spatia + 100;
                karo = karo + 100;
                kupa = kupa + 100;
                pika = pika + 100;
                vsichkokoz = vsichkokoz + 100;
            }
            if (tercaf > 10 && tercaf < 20) {
                spatia = spatia + 70;
                karo = karo + 70;
                kupa = kupa + 70;
                pika = pika + 70;
                vsichkokoz = vsichkokoz + 40;
            }
            ////////////////////////////////////////////////////
            if (tercaf > 0 && tercaf < 6) {
                spatia = spatia + 30;
                karo = karo + 30;
                kupa = kupa + 30;
                pika = pika + 30;
                vsichkokoz = vsichkokoz + 30;
            }
        }
        if (Player === 3) {
            if (this.m_orez.m_rez1 > 135 && this.m_orez.m_rez2 < 120 && this.m_orez.m_rez2 > 60) {
                spatia = spatia - 20;
                karo = karo - 20;
                kupa = kupa - 20;
                pika = pika - 20;
                bezkoz = bezkoz - 30;
                vsichkokoz = vsichkokoz - 30;
            }
            if (this.m_orez.m_rez2 > 140 && this.m_orez.m_rez1 < 132 && this.m_orez.m_rez2 > 60) {
                spatia = spatia - (this.m_orez.m_rez2 - 140) * 8;
                karo = karo - (this.m_orez.m_rez2 - 140) * 8;
                kupa = kupa - (this.m_orez.m_rez2 - 140) * 8;
                pika = pika - (this.m_orez.m_rez2 - 140) * 8;
                bezkoz = bezkoz - (this.m_orez.m_rez2 - 140) * 12;
                vsichkokoz = vsichkokoz - (this.m_orez.m_rez2 - 140) * 12;
            }
            if (
                this.m_orez.m_rez2 > 140 &&
                this.m_orez.m_rez1 > 140 &&
                this.m_orez.m_rez1 < 149 &&
                ((this.Naddavane === 1 && (this.Pkazal[2][1] === true || this.Pkazal[4][1] === true)) ||
                    (this.Naddavane === 2 && (this.Pkazal[2][2] === true || this.Pkazal[4][2] === true)) ||
                    (this.Naddavane === 3 && (this.Pkazal[2][3] === true || this.Pkazal[4][3] === true)) ||
                    (this.Naddavane === 4 && (this.Pkazal[2][4] === true || this.Pkazal[4][4] === true)) ||
                    (this.Naddavane === 5 && (this.Pkazal[2][5] === true || this.Pkazal[4][5] === true)))
            ) {
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 20;
                vsichkokoz = vsichkokoz + 20;
            }
        } else {
            if (this.m_orez.m_rez2 > 135 && this.m_orez.m_rez1 < 120 && this.m_orez.m_rez1 > 60) {
                spatia = spatia - 20;
                karo = karo - 20;
                kupa = kupa - 20;
                pika = pika - 20;
                bezkoz = bezkoz - 30;
                vsichkokoz = vsichkokoz - 30;
            }
            if (this.m_orez.m_rez1 > 140 && this.m_orez.m_rez2 < 132 && this.m_orez.m_rez1 > 60) {
                spatia = spatia - (this.m_orez.m_rez1 - 140) * 8;
                karo = karo - (this.m_orez.m_rez1 - 140) * 8;
                kupa = kupa - (this.m_orez.m_rez1 - 140) * 8;
                pika = pika - (this.m_orez.m_rez1 - 140) * 8;
                bezkoz = bezkoz - (this.m_orez.m_rez1 - 140) * 12;
                vsichkokoz = vsichkokoz - (this.m_orez.m_rez1 - 140) * 12;
            }
            if (
                this.m_orez.m_rez1 > 140 &&
                this.m_orez.m_rez2 > 140 &&
                this.m_orez.m_rez2 < 149 &&
                ((this.Naddavane === 1 && (this.Pkazal[1][1] === true || this.Pkazal[3][1] === true)) ||
                    (this.Naddavane === 2 && (this.Pkazal[1][2] === true || this.Pkazal[3][2] === true)) ||
                    (this.Naddavane === 3 && (this.Pkazal[1][3] === true || this.Pkazal[3][3] === true)) ||
                    (this.Naddavane === 4 && (this.Pkazal[1][4] === true || this.Pkazal[3][4] === true)) ||
                    (this.Naddavane === 5 && (this.Pkazal[1][5] === true || this.Pkazal[3][5] === true)))
            ) {
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 20;
                vsichkokoz = vsichkokoz + 20;
            }
        }
        if (Player === 2) {
            if (this.KoiPuskaParvi === 2) {
                spatia = spatia + 20;
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 40;
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.KoiPuskaParvi === 4) {
                spatia = spatia + 10;
                karo = karo + 10;
                kupa = kupa + 10;
                pika = pika + 10;
                bezkoz = bezkoz + 30;
                vsichkokoz = vsichkokoz + 30;
            }
            if (this.KoiPuskaParvi === 3 || this.KoiPuskaParvi === 1) {
                spatia = spatia - 10;
                karo = karo - 10;
                kupa = kupa - 10;
                pika = pika - 10;
                bezkoz = bezkoz - 30;
                vsichkokoz = vsichkokoz - 30;
            }
            if ((this.Pkazal[3][1] === true || this.Pkazal[1][1] === true) && this.Naddavane === 1) {
                vsichkokoz = vsichkokoz - 20;
                if (spatia < 80) {
                    karo = karo + 20;
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (spatia > 120) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
            }
            if ((this.Pkazal[3][2] === true || this.Pkazal[1][2] === true) && this.Naddavane === 2) {
                vsichkokoz = vsichkokoz - 20;
                if (karo < 80) {
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (karo > 120) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
            }
            if ((this.Pkazal[3][3] === true || this.Pkazal[1][3] === true) && this.Naddavane === 3) {
                vsichkokoz = vsichkokoz - 20;
                if (kupa < 80) {
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (kupa > 120) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
            }
            if ((this.Pkazal[3][4] === true || this.Pkazal[1][4] === true) && this.Naddavane === 4) {
                vsichkokoz = vsichkokoz - 20;
                if (pika < 80) {
                    bezkoz = bezkoz + 20;
                }
                if (pika > 120) {
                    bezkoz = bezkoz - 20;
                }
            }
            if ((this.Pkazal[3][5] === true || this.Pkazal[1][5] === true) && this.Naddavane === 1) {
                if (bezkoz < 100) {
                    vsichkokoz = vsichkokoz + 30;
                }
                if (bezkoz > 150) {
                    vsichkokoz = vsichkokoz - 30;
                }
            }
            if (this.Pkazal[4][1] === true && this.Naddavane === 1) {
                if (spatia > 50) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[4][2] === true && this.Naddavane === 2) {
                if (karo > 50) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[4][3] === true && this.Naddavane === 3) {
                if (kupa > 50) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[4][4] === true && this.Naddavane === 4) {
                if (pika > 50) {
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[4][5] === true && this.Naddavane === 5) {
                vsichkokoz = vsichkokoz - 30;
                if (bezkoz > 50) {
                    vsichkokoz = vsichkokoz - 20;
                }
            }
        }
        if (Player === 3) {
            if (this.KoiPuskaParvi === 3) {
                spatia = spatia + 20;
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 40;
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.KoiPuskaParvi === 1) {
                spatia = spatia + 10;
                karo = karo + 10;
                kupa = kupa + 10;
                pika = pika + 10;
                bezkoz = bezkoz + 30;
                vsichkokoz = vsichkokoz + 30;
            }
            if (this.KoiPuskaParvi === 2 || this.KoiPuskaParvi === 4) {
                spatia = spatia - 10;
                karo = karo - 10;
                kupa = kupa - 10;
                pika = pika - 10;
                bezkoz = bezkoz - 30;
                vsichkokoz = vsichkokoz - 30;
            }
            if ((this.Pkazal[2][1] === true || this.Pkazal[4][1] === true) && this.Naddavane === 1) {
                if (spatia < 80) {
                    karo = karo + 20;
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (spatia > 120) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[2][2] === true || this.Pkazal[4][2] === true) && this.Naddavane === 2) {
                if (karo < 80) {
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (karo > 120) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[2][3] === true || this.Pkazal[4][3] === true) && this.Naddavane === 3) {
                if (kupa < 80) {
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (kupa > 120) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[2][4] === true || this.Pkazal[4][4] === true) && this.Naddavane === 4) {
                if (pika < 80) {
                    bezkoz = bezkoz + 20;
                }
                if (pika > 120) {
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[2][5] === true || this.Pkazal[4][5] === true) && this.Naddavane === 1) {
                if (bezkoz < 100) {
                    vsichkokoz = vsichkokoz + 30;
                }
                if (bezkoz > 150) {
                    vsichkokoz = vsichkokoz - 30;
                }
            }
            if (this.Pkazal[1][1] === true && this.Naddavane === 1) {
                if (spatia > 50) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[1][2] === true && this.Naddavane === 2) {
                if (karo > 50) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[1][3] === true && this.Naddavane === 3) {
                if (kupa > 50) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[1][4] === true && this.Naddavane === 4) {
                if (pika > 50) {
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 50;
            }
            if (this.Pkazal[1][5] === true && this.Naddavane === 5) {
                vsichkokoz = vsichkokoz - 30;
                if (bezkoz > 50) {
                    vsichkokoz = vsichkokoz - 20;
                }
            }
        }
        if (Player === 4) {
            if (this.KoiPuskaParvi === 4) {
                spatia = spatia + 20;
                karo = karo + 20;
                kupa = kupa + 20;
                pika = pika + 20;
                bezkoz = bezkoz + 40;
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.KoiPuskaParvi === 2) {
                spatia = spatia + 10;
                karo = karo + 10;
                kupa = kupa + 10;
                pika = pika + 10;
                bezkoz = bezkoz + 30;
                vsichkokoz = vsichkokoz + 30;
            }
            if (this.KoiPuskaParvi === 3 || this.KoiPuskaParvi === 1) {
                spatia = spatia - 10;
                karo = karo - 10;
                kupa = kupa - 10;
                pika = pika - 10;
                bezkoz = bezkoz - 30;
                vsichkokoz = vsichkokoz - 30;
            }
            if ((this.Pkazal[3][1] === true || this.Pkazal[1][1] === true) && this.Naddavane === 1) {
                if (spatia < 80) {
                    karo = karo + 20;
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (spatia > 120) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[3][2] === true || this.Pkazal[1][2] === true) && this.Naddavane === 2) {
                if (karo < 80) {
                    kupa = kupa + 20;
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (karo > 120) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[3][3] === true || this.Pkazal[1][3] === true) && this.Naddavane === 3) {
                if (kupa < 80) {
                    pika = pika + 20;
                    bezkoz = bezkoz + 20;
                }
                if (kupa > 120) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[3][4] === true || this.Pkazal[1][4] === true) && this.Naddavane === 4) {
                if (pika < 80) {
                    bezkoz = bezkoz + 20;
                }
                if (pika > 120) {
                    bezkoz = bezkoz - 20;
                }
                vsichkokoz = vsichkokoz - 20;
            }
            if ((this.Pkazal[3][5] === true || this.Pkazal[1][5] === true) && this.Naddavane === 1) {
                if (bezkoz < 100) {
                    vsichkokoz = vsichkokoz + 30;
                }
                if (bezkoz > 150) {
                    vsichkokoz = vsichkokoz - 30;
                }
            }
            if (this.Pkazal[2][1] === true && this.Naddavane === 1) {
                if (spatia > 50) {
                    karo = karo - 20;
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.Pkazal[2][2] === true && this.Naddavane === 2) {
                if (karo > 50) {
                    kupa = kupa - 20;
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.Pkazal[2][3] === true && this.Naddavane === 3) {
                if (kupa > 50) {
                    pika = pika - 20;
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.Pkazal[2][4] === true && this.Naddavane === 4) {
                if (pika > 50) {
                    bezkoz = bezkoz - 50;
                }
                vsichkokoz = vsichkokoz + 40;
            }
            if (this.Pkazal[2][5] === true && this.Naddavane === 5) {
                vsichkokoz = vsichkokoz - 30;
                if (bezkoz > 50) {
                    vsichkokoz = vsichkokoz - 20;
                }
            }
        }
        //	if(this.Kontra==true)
        //	{
        //	}
        //	if(Rekontra==true)
        //	{
        //	}
        //////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////
        let Nadd = 0;
        if (spatia > 225 && this.Naddavane < 1) {
            Nadd = 1;
        }
        if (karo > spatia && karo > 230 && this.Naddavane < 2) {
            Nadd = 2;
        }
        if (kupa > karo && kupa > spatia && kupa > 235 && this.Naddavane < 3) {
            Nadd = 3;
        }
        if (pika > kupa && pika > karo && pika > spatia && pika > 240 && this.Naddavane < 4) {
            Nadd = 4;
        }
        if (bezkoz > pika && bezkoz > kupa && bezkoz > karo && bezkoz > spatia && bezkoz > 255 && this.Naddavane < 5) {
            Nadd = 5;
        }
        if (
            vsichkokoz > bezkoz &&
            vsichkokoz > pika &&
            vsichkokoz > kupa &&
            vsichkokoz > karo &&
            vsichkokoz > spatia &&
            vsichkokoz > 245 &&
            this.Naddavane < 6
        ) {
            Nadd = 6;
        }
        if ((Player === 1 || Player === 3) && this.Kontra === 0 && this.Rekontra === 0) {
            if (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true) {
                if (this.Naddavane < 5 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez2 > 140 && this.m_orez.m_rez1 < 136 && this.BrPas > 0) {
                        Nadd = 7;
                    } else {
                        if (this.m_orez.m_rez1 < 136) {
                            if (this.Naddavane === 1 && spatia > 240) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 2 && karo > 245) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 3 && kupa > 250) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 4 && pika > 255) {
                                Nadd = 7;
                            }
                        }
                    }
                }
                if (this.Naddavane === 5 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez2 > 137 && this.m_orez.m_rez1 < 126 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (bezkoz > 275) {
                        if (this.m_orez.m_rez1 < 126) {
                            Nadd = 7;
                        }
                    }
                }
                if (this.Naddavane === 6 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez2 > 135 && this.m_orez.m_rez1 < 126 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (vsichkokoz > 265) {
                        if (this.m_orez.m_rez1 < 126) {
                            Nadd = 7;
                        }
                    }
                }
            }
        }
        if (Player === 1 || Player === 3) {
            if (this.Kontra === 2 || this.Kontra === 4) {
                if (this.Naddavane < 5) {
                    if (this.m_orez.m_rez2 > 118 && this.m_orez.m_rez1 < 120 && this.BrPas > 0) {
                        Nadd = 8;
                    } else {
                        if (this.m_orez.m_rez1 < 120) {
                            if (this.Naddavane === 1 && spatia > 260) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 2 && karo > 265) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 3 && kupa > 270) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 4 && pika > 275) {
                                Nadd = 8;
                            }
                        }
                    }
                }
                if (this.Naddavane === 5) {
                    if (this.m_orez.m_rez2 > 98 && this.m_orez.m_rez1 < 100 && this.BrPas > 0) {
                        Nadd = 8;
                    } else if (bezkoz > 290) {
                        if (this.m_orez.m_rez1 < 100) {
                            Nadd = 8;
                        }
                    }
                }
                if (this.Naddavane === 6) {
                    if (this.m_orez.m_rez2 > 98 && this.m_orez.m_rez1 < 100 && this.BrPas > 0) {
                        Nadd = 8;
                    } else if (vsichkokoz > 285) {
                        if (this.m_orez.m_rez1 < 100) {
                            Nadd = 8;
                        }
                    }
                }
            }
        }
        if ((Player === 2 || Player === 4) && this.Kontra === 0 && this.Rekontra === 0) {
            if (this.Pkazal[1][this.Naddavane] === true || this.Pkazal[3][this.Naddavane] === true) {
                if (this.Naddavane < 5 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez1 > 140 && this.m_orez.m_rez2 < 136 && this.BrPas > 0) {
                        Nadd = 7;
                    } else {
                        if (this.m_orez.m_rez2 < 136) {
                            if (this.Naddavane === 1 && spatia > 240) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 2 && karo > 245) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 3 && kupa > 250) {
                                Nadd = 7;
                            }
                            if (this.Naddavane === 4 && pika > 255) {
                                Nadd = 7;
                            }
                        }
                    }
                }
                if (this.Naddavane === 5 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez1 > 137 && this.m_orez.m_rez2 < 126 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (bezkoz > 275) {
                        if (this.m_orez.m_rez2 < 126) {
                            Nadd = 7;
                        }
                    }
                }
                if (this.Naddavane === 6 && this.Naddavane > 0) {
                    if (this.m_orez.m_rez1 > 135 && this.m_orez.m_rez2 < 126 && this.BrPas > 0) {
                        Nadd = 7;
                    } else if (vsichkokoz > 265) {
                        if (this.m_orez.m_rez2 < 126) {
                            Nadd = 7;
                        }
                    }
                }
            }
        }
        if (Player === 2 || Player === 4) {
            if (this.Kontra === 1 || this.Kontra === 3) {
                if (this.Naddavane < 5) {
                    if (this.m_orez.m_rez1 > 118 && this.m_orez.m_rez2 < 119 && this.BrPas > 0) {
                        Nadd = 8;
                    } else {
                        if (this.m_orez.m_rez2 < 119) {
                            if (this.Naddavane === 1 && spatia > 260) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 2 && karo > 265) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 3 && kupa > 270) {
                                Nadd = 8;
                            }
                            if (this.Naddavane === 4 && pika > 275) {
                                Nadd = 8;
                            }
                        }
                    }
                }
                if (this.Naddavane === 5) {
                    if (this.m_orez.m_rez1 > 98 && this.m_orez.m_rez2 < 99 && this.BrPas > 0) {
                        Nadd = 8;
                    } else if (bezkoz > 275) {
                        if (this.m_orez.m_rez2 < 99) {
                            Nadd = 8;
                        }
                    }
                }
                if (this.Naddavane === 6) {
                    if (this.m_orez.m_rez1 > 94 && this.m_orez.m_rez2 < 99 && this.BrPas > 0) {
                        Nadd = 8;
                    } else if (vsichkokoz > 265) {
                        if (this.m_orez.m_rez2 < 99) {
                            Nadd = 8;
                        }
                    }
                }
            }
        }
        if (Nadd > 0 && Nadd < 7) {
            if (Nadd < 5) {
                this.Piska[Player][Nadd] = true;
            }
            this.Kontra = 0;
            this.Rekontra = 0;
            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[Player], this.GameName(Nadd, false));
            this.Naddavane = Nadd;
            this.BrPas = 0;
            this.Pkazal[Player][Nadd] = true;
        }
        if (Nadd > 6) {
            if (Nadd === 7) {
                this.Kontra = Player;
            }
            if (Nadd === 8) {
                this.Kontra = 0;
                this.Rekontra = Player;
            }
            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[Player], this.GameName(Nadd, false));
            this.BrPas = 0;
        }
        if (Nadd === 0) {
            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[Player], this.GameName(0, false));
            this.BrPas = this.BrPas + 1;
        }
        return Nadd;
    }

    Obiaviavane(player: number, anons: number) {
        if (anons === undefined) {
            anons = this.Anonsi(player);
        }
        this.online_timer = this.online_timer_time;
        if (this.OP === true) {
            if (anons > 0) {
                this.BrPas = 0;
            }
        }
        this.obiaviavaneRN = this.DrawObiaviavane(player, anons, 0);
        let position = 'center';
        if (this.KoiObiaviava === 2) {
            position = 'right';
        }
        if (this.KoiObiaviava === 4) {
            position = 'left';
        }
        if (this.KoiObiaviava < 4) {
            this.KoiObiaviava = this.KoiObiaviava + 1;
        } else {
            this.KoiObiaviava = 1;
        }
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(anons + 2, position, this.stereo, this.pls[player]);
        }
        this.setState({});
    }

    async Timer2() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.Move !== true) {
            if (this.SP) {
                if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true && !this.Move) {
                    if (this.BrPas === 4) {
                        await this.InitGame4();
                        this.setState({});
                        return;
                    }
                    if (this.BrPas === 3 && this.Naddavane > 0) {
                        this.Faza = 3;
                        this.Razdavane2 = true;
                        this.obiaviavaneRN = null;
                        this.setState({});
                        if (GlobalVariables.Zvuk === 1) {
                            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                        }
                        return;
                    }
                    if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                        this.KoiObiaviava = 0;
                        this.m_n.naddavane = this.Naddavane;
                        if (this.Naddavane === 0) {
                            this.m_n.kontra = false;
                            this.m_n.rekontra = false;
                        }
                        if (
                            this.Kontra === 0 &&
                            this.Rekontra === 0 &&
                            (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true)
                        ) {
                            this.m_n.kontra = true;
                            this.m_n.rekontra = false;
                        }
                        if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[3][this.Naddavane] === true) {
                            this.m_n.kontra = false;
                            this.m_n.rekontra = false;
                        }
                        if (this.Kontra === 2 || this.Kontra === 4) {
                            this.m_n.rekontra = true;
                            this.m_n.kontra = false;
                        }
                        if (this.Kontra === 3 || this.Kontra === 1 || this.Rekontra > 0) {
                            this.m_n.rekontra = false;
                            this.m_n.kontra = false;
                        }
                        await this.m_n.DoModal();
                        if (this.m_n.m_pas === -1) {
                            this.BrPas = this.BrPas + 1;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0, false));
                        }
                        if (this.m_n.m_pas === 0) {
                            this.Pkazal[1][1] = true;
                            this.Naddavane = 1;
                        }
                        if (this.m_n.m_pas === 1) {
                            this.Pkazal[1][2] = true;
                            this.Naddavane = 2;
                        }
                        if (this.m_n.m_pas === 2) {
                            this.Pkazal[1][3] = true;
                            this.Naddavane = 3;
                        }
                        if (this.m_n.m_pas === 3) {
                            this.Pkazal[1][4] = true;
                            this.Naddavane = 4;
                        }
                        if (this.m_n.m_pas === 4) {
                            this.Pkazal[1][5] = true;
                            this.Naddavane = 5;
                        }
                        if (this.m_n.m_pas === 5) {
                            this.Pkazal[1][6] = true;
                            this.Naddavane = 6;
                        }
                        if (this.m_n.m_pas === 6) {
                            this.Kontra = 1;
                            this.BrPas = 0;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7, false));
                        }
                        if (this.m_n.m_pas === 7) {
                            this.Rekontra = 1;
                            this.Kontra = 0;
                            this.BrPas = 0;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8, false));
                        }
                        if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane, false));
                            this.Kontra = 0;
                            this.Rekontra = 0;
                            this.BrPas = 0;
                        }
                        this.setState({});
                        this.KoiObiaviava = 2;
                        this.props.m_pwin.KillTimer(2);
                        this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                        return;
                    }
                    if (this.KoiObiaviava > 1) {
                        this.Obiaviavane(this.KoiObiaviava, undefined);
                        this.setState({});
                    }
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true && !this.Move) {
                        if (this.BrPas === 4) {
                            this.Faza = 6;
                            this.SendBeginGameToWaitPlayers();
                            this.setState({});
                            return;
                        }
                        if (this.BrPas === 3 && this.Naddavane > 0) {
                            this.Faza = 3;
                            this.Razdavane2 = true;
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                            }
                            this.obiaviavaneRN = null;
                            this.setState({});
                            return;
                        }
                        if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                            this.KoiObiaviava = 0;
                            this.m_n.naddavane = this.Naddavane;
                            if (this.Naddavane === 0) {
                                this.m_n.kontra = false;
                                this.m_n.rekontra = false;
                            }
                            if (
                                this.Kontra === 0 &&
                                this.Rekontra === 0 &&
                                (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true)
                            ) {
                                this.m_n.kontra = true;
                                this.m_n.rekontra = false;
                            }
                            if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[3][this.Naddavane] === true) {
                                this.m_n.kontra = false;
                                this.m_n.rekontra = false;
                            }
                            if (this.Kontra === 2 || this.Kontra === 4) {
                                this.m_n.rekontra = true;
                                this.m_n.kontra = false;
                            }
                            if (this.Kontra === 3 || this.Kontra === 1 || this.Rekontra > 0) {
                                this.m_n.rekontra = false;
                                this.m_n.kontra = false;
                            }
                            this.online_timer = this.online_timer_time;
                            ///////////////////////////////////////////////
                            await this.m_n.DoModal();

                            if (this.m_n.m_pas === -1) {
                                this.SendGame('$|m|0');
                                this.BrPas = this.BrPas + 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0, false));
                            }
                            if (this.m_n.m_pas >= 0) {
                                if (this.OP) {
                                    if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                                        if (!this.op_start) {
                                            this.SendGame('L');
                                            this.op_start = true;
                                        }
                                    }
                                }
                            }
                            if (this.m_n.m_pas === 0) {
                                this.SendGame('$|m|1');
                                this.Pkazal[1][1] = true;
                                this.Naddavane = 1;
                            }
                            if (this.m_n.m_pas === 1) {
                                this.SendGame('$|m|2');
                                this.Pkazal[1][2] = true;
                                this.Naddavane = 2;
                            }
                            if (this.m_n.m_pas === 2) {
                                this.SendGame('$|m|3');
                                this.Pkazal[1][3] = true;
                                this.Naddavane = 3;
                            }
                            if (this.m_n.m_pas === 3) {
                                this.SendGame('$|m|4');
                                this.Pkazal[1][4] = true;
                                this.Naddavane = 4;
                            }
                            if (this.m_n.m_pas === 4) {
                                this.SendGame('$|m|5');
                                this.Pkazal[1][5] = true;
                                this.Naddavane = 5;
                            }
                            if (this.m_n.m_pas === 5) {
                                this.SendGame('$|m|6');
                                this.Pkazal[1][6] = true;
                                this.Naddavane = 6;
                            }
                            if (this.m_n.m_pas === 6) {
                                this.Kontra = 1;
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7, false));
                                this.SendGame('$|m|7');
                            }
                            if (this.m_n.m_pas === 7) {
                                this.Rekontra = 1;
                                this.Kontra = 0;
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8, false));
                                this.SendGame('$|m|8');
                            }
                            if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane, false));
                                this.Kontra = 0;
                                this.Rekontra = 0;
                                this.BrPas = 0;
                            }
                            this.setState({});
                            this.KoiObiaviava = 2;
                            if (this.P2OP !== true) {
                                this.props.m_pwin.KillTimer(2);
                                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                            }
                            return;
                        }
                        if (this.KoiObiaviava > 1) {
                            if (
                                ((this.KoiObiaviava === 2 && this.P2OP === true) ||
                                    (this.KoiObiaviava === 3 && this.P3OP === true) ||
                                    (this.KoiObiaviava === 4 && this.P4OP === true)) &&
                                this.CheckAMessage()
                            ) {
                                let Nadd = this._ttoi(this.ReadAMessage());
                                if ((Nadd === 7 || Nadd === 8 || (this.Kontra === 0 && Nadd === 8)) && this.Naddavane === 0) {
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (Server):' + this.IntToString(Nadd) + ' sled pas');
                                    return;
                                }
                                if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Obiaviavane na greshen annons (Server):' +
                                            this.IntToString(Nadd) +
                                            ' sled ' +
                                            this.IntToString(this.Naddavane)
                                    );
                                }
                                if (Nadd === 0) {
                                    this.BrPas = this.BrPas + 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0, false));
                                }
                                if (Nadd > 0 && Nadd < 7) {
                                    this.BrPas = 0;
                                    this.Naddavane = Nadd;
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.Pkazal[this.KoiObiaviava][Nadd] = true;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd, false));
                                }
                                if (Nadd === 7) {
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7, false));
                                    this.Kontra = this.KoiObiaviava;
                                }
                                if (Nadd === 8) {
                                    this.BrPas = 0;
                                    this.Kontra = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8, false));
                                    this.Rekontra = this.KoiObiaviava;
                                }
                                this.Obiaviavane(this.KoiObiaviava, Nadd);
                                return;
                            }
                            if (this.KoiObiaviava === 2 && this.P2OP !== true) {
                                if (this.CheckAMessage()) {
                                    let anons = this._ttoi(this.ReadAMessage());
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2 LU:' + this.last_Name[2]);
                                } else {
                                    let anons = this.Anonsi(this.KoiObiaviava);
                                    let ano = this.IntToString(anons);
                                    this.SendGame('$|m|' + ano);
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                }
                                return;
                            }
                            if (this.KoiObiaviava === 3 && this.P3OP !== true) {
                                if (this.CheckAMessage()) {
                                    let anons = this._ttoi(this.ReadAMessage());
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL3 LU:' + this.last_Name[3]);
                                } else {
                                    let anons = this.Anonsi(this.KoiObiaviava);
                                    let ano = this.IntToString(anons);
                                    this.SendGame('$|m|' + ano);
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                }
                                this.setState({});
                                return;
                            }
                            if (this.KoiObiaviava === 4 && this.P4OP !== true) {
                                if (this.CheckAMessage()) {
                                    let anons = this._ttoi(this.ReadAMessage());
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL4 LU:' + this.last_Name[4]);
                                } else {
                                    let anons = this.Anonsi(this.KoiObiaviava);
                                    let ano = this.IntToString(anons);
                                    this.SendGame('$|m|' + ano);
                                    this.Obiaviavane(this.KoiObiaviava, anons);
                                }
                                this.setState({});
                                return;
                            }
                        }
                    }
                } else {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Razdavane2 !== true) {
                        if (this.BrPas === 4) {
                            this.Faza = 6;
                            if (!this.watch) {
                                this.SendGame('R');
                            }
                            this.setState({});
                            return;
                        }
                        if (this.BrPas === 3 && this.Naddavane > 0) {
                            this.Faza = 3;
                            this.Razdavane2 = true;
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
                            }
                            this.obiaviavaneRN = null;
                            this.setState({});
                            return;
                        }
                        if (
                            this.watch &&
                            this.KoiObiaviava === 1 &&
                            this.Faza === 2 &&
                            this.BrPas !== 4 &&
                            (this.BrPas !== 3 || this.Naddavane === 0)
                        ) {
                            if (this.CheckAMessage()) {
                                let Nadd = this._ttoi(this.ReadAMessage());
                                if ((Nadd === 7 || Nadd === 8) && this.Naddavane === 0) {
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (Client):' + this.IntToString(Nadd) + ' sled pas');
                                    return;
                                }
                                if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Obiaviavane na greshen annons (Client):' +
                                            this.IntToString(Nadd) +
                                            ' sled ' +
                                            this.IntToString(this.Naddavane)
                                    );
                                    return;
                                }
                                if (Nadd === 0) {
                                    this.BrPas = this.BrPas + 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0, false));
                                }
                                if (Nadd > 0 && Nadd < 7) {
                                    this.BrPas = 0;
                                    this.Naddavane = Nadd;
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.Pkazal[this.KoiObiaviava][Nadd] = true;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd, false));
                                }
                                if (Nadd === 7) {
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7, false));
                                    this.Kontra = this.KoiObiaviava;
                                }
                                if (Nadd === 8) {
                                    this.BrPas = 0;
                                    this.Kontra = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8, false));
                                    this.Rekontra = this.KoiObiaviava;
                                }
                                this.Obiaviavane(this.KoiObiaviava, Nadd);
                                this.setState({});
                                return;
                            }
                        }
                        if (!this.watch) {
                            if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                                this.KoiObiaviava = 0;
                                this.m_n.naddavane = this.Naddavane;
                                if (this.Naddavane === 0) {
                                    this.m_n.kontra = false;
                                    this.m_n.rekontra = false;
                                }
                                if (
                                    this.Kontra === 0 &&
                                    this.Rekontra === 0 &&
                                    (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true)
                                ) {
                                    this.m_n.kontra = true;
                                    this.m_n.rekontra = false;
                                }
                                if (this.Kontra === 0 && this.Rekontra === 0 && this.Pkazal[3][this.Naddavane] === true) {
                                    this.m_n.kontra = false;
                                    this.m_n.rekontra = false;
                                }
                                if (this.Kontra === 2 || this.Kontra === 4) {
                                    this.m_n.rekontra = true;
                                    this.m_n.kontra = false;
                                }
                                if (this.Kontra === 3 || this.Kontra === 1 || this.Rekontra > 0) {
                                    this.m_n.rekontra = false;
                                    this.m_n.kontra = false;
                                }
                                ///////////////////////////////////////////////
                                this.online_timer = this.online_timer_time;
                                await this.m_n.DoModal();
                                let message = '$|m|';
                                if (this.m_n.m_pas === -1) {
                                    this.SendGame(message + '0');
                                    this.BrPas = this.BrPas + 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0, false));
                                }
                                if (this.m_n.m_pas >= 0) {
                                    if (this.OP) {
                                        if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                                            if (!this.op_start) {
                                                this.SendGame('L');
                                                this.op_start = true;
                                            }
                                        }
                                    }
                                }
                                if (this.m_n.m_pas === 0) {
                                    this.SendGame(message + '1');
                                    this.Pkazal[1][1] = true;
                                    this.Naddavane = 1;
                                }
                                if (this.m_n.m_pas === 1) {
                                    this.SendGame(message + '2');
                                    this.Pkazal[1][2] = true;
                                    this.Naddavane = 2;
                                }
                                if (this.m_n.m_pas === 2) {
                                    this.SendGame(message + '3');
                                    this.Pkazal[1][3] = true;
                                    this.Naddavane = 3;
                                }
                                if (this.m_n.m_pas === 3) {
                                    this.SendGame(message + '4');
                                    this.Pkazal[1][4] = true;
                                    this.Naddavane = 4;
                                }
                                if (this.m_n.m_pas === 4) {
                                    this.SendGame(message + '5');
                                    this.Pkazal[1][5] = true;
                                    this.Naddavane = 5;
                                }
                                if (this.m_n.m_pas === 5) {
                                    this.SendGame(message + '6');
                                    this.Pkazal[1][6] = true;
                                    this.Naddavane = 6;
                                }
                                if (this.m_n.m_pas === 6) {
                                    this.Kontra = 1;
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7, false));
                                    this.SendGame(message + '7');
                                }
                                if (this.m_n.m_pas === 7) {
                                    this.Rekontra = 1;
                                    this.Kontra = 0;
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8, false));
                                    this.SendGame(message + '8');
                                }
                                if (this.m_n.m_pas > -1 && this.m_n.m_pas < 6) {
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane, false));
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.BrPas = 0;
                                }
                                this.setState({});
                                this.KoiObiaviava = 2;
                                return;
                            }
                        }
                        if (this.KoiObiaviava > 1 && this.CheckAMessage()) {
                            this.setState({});
                            let Nadd = this._ttoi(this.ReadAMessage());
                            if ((Nadd === 7 || Nadd === 8) && this.Naddavane === 0) {
                                this.props.m_pwin.SendErrorToLog('Obiaviavane na annons (Client):' + this.IntToString(Nadd) + ' sled pas');
                                return;
                            }
                            if (this.Naddavane > 0 && Nadd > 0 && Nadd < this.Naddavane) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Obiaviavane na greshen annons (Client):' + this.IntToString(Nadd) + ' sled ' + this.IntToString(this.Naddavane)
                                );
                                return;
                            }
                            if (Nadd === 0) {
                                this.BrPas = this.BrPas + 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0, false));
                            }
                            if (Nadd > 0 && Nadd < 7) {
                                this.BrPas = 0;
                                this.Naddavane = Nadd;
                                this.Kontra = 0;
                                this.Rekontra = 0;
                                this.Pkazal[this.KoiObiaviava][Nadd] = true;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(Nadd, false));
                            }
                            if (Nadd === 7) {
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7, false));
                                this.Kontra = this.KoiObiaviava;
                            }
                            if (Nadd === 8) {
                                this.BrPas = 0;
                                this.Kontra = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8, false));
                                this.Rekontra = this.KoiObiaviava;
                            }
                            this.Obiaviavane(this.KoiObiaviava, Nadd);
                            return;
                        }
                    }
                }
            }
        }
    }

    PuskIskanaKarta(player: number, partner2: number, partner3: number, partner4: number) {
        if (this.Pneiska[partner2][1] === true && this.Pneiska[partner4][1] === true) {
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 10 && this.k[i] < 20) {
                    return i;
                }
            }
        }
        if (this.Pneiska[partner2][2] === true && this.Pneiska[partner4][2] === true) {
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 20 && this.k[i] < 30) {
                    return i;
                }
            }
        }
        if (this.Pneiska[partner2][3] === true && this.Pneiska[partner4][3] === true) {
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 30 && this.k[i] < 40) {
                    return i;
                }
            }
        }
        if (this.Pneiska[partner2][4] === true && this.Pneiska[partner4][4] === true) {
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 40 && this.k[i] < 50) {
                    return i;
                }
            }
        }
        if (this.Piska[partner3][1] === true) {
            this.Piska[partner3][1] = false;
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 10 && this.k[i] < 20) {
                    return i;
                }
            }
        }
        if (this.Piska[partner3][2] === true) {
            this.Piska[partner3][2] = false;
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 20 && this.k[i] < 30) {
                    return i;
                }
            }
        }
        if (this.Piska[partner3][3] === true) {
            this.Piska[partner3][3] = false;
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 30 && this.k[i] < 40) {
                    return i;
                }
            }
        }
        if (this.Piska[partner3][4] === true) {
            this.Piska[partner3][4] = false;
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.k[i] > 40 && this.k[i] < 50) {
                    return i;
                }
            }
        }
        return 0;
    }

    IntelectBoia(player: number) {
        let vla;
        let pusk = 0;
        let partner: number[] = [];
        if (player === 1) {
            partner[2] = 2;
            partner[3] = 3;
            partner[4] = 4;
        }
        if (player === 2) {
            partner[2] = 3;
            partner[3] = 4;
            partner[4] = 1;
        }
        if (player === 3) {
            partner[2] = 4;
            partner[3] = 1;
            partner[4] = 2;
        }
        if (player === 4) {
            partner[2] = 1;
            partner[3] = 2;
            partner[4] = 3;
        }
        let br: number[] = [];
        let brob: number[] = [];
        br[1] = this.GetBrojKarti(1, player);
        br[2] = this.GetBrojKarti(2, player);
        br[3] = this.GetBrojKarti(3, player);
        br[4] = this.GetBrojKarti(4, player);
        brob[1] = this.GetBrojKarti(1, 0);
        brob[2] = this.GetBrojKarti(2, 0);
        brob[3] = this.GetBrojKarti(3, 0);
        brob[4] = this.GetBrojKarti(4, 0);
        let vziatka: boolean = this.Vziatka();
        if (
            (this.KoiPuskaParvi === 2 && player === 2) ||
            (this.KoiPuskaParvi === 3 && player === 3) ||
            (this.KoiPuskaParvi === 4 && player === 4) ||
            (this.KoiPuskaParvi === 1 && player === 1)
        ) {
            pusk = this.PuskIskanaKarta(player, partner[2], partner[3], partner[4]);
            if (pusk > 0) {
                return pusk;
            }
            if (this.Pkazal[player][this.Naddavane] === true) {
                if (
                    this.hand < 6 &&
                    br[this.Naddavane] > 1 &&
                    (this.GetBrojKarti(this.Naddavane, partner[2]) > 0 || this.GetBrojKarti(this.Naddavane, partner[4]) > 0) &&
                    (this.CheckCard3(18, player, true) === true ||
                        this.CheckCard3(28, player, true) === true ||
                        this.CheckCard3(38, player, true) === true ||
                        this.CheckCard3(48, player, true) === true ||
                        this.CheckCard3(17, player, true) === true ||
                        this.CheckCard3(27, player, true) === true ||
                        this.CheckCard3(37, player, true) === true ||
                        this.CheckCard3(47, player, true) === true)
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                    } else {
                        if (
                            (br[this.Naddavane] > 2 && this.hand === 0) ||
                            (br[this.Naddavane] === 2 && this.hand > 0) ||
                            brob[this.Naddavane] - br[this.Naddavane] < 3
                        ) {
                            return this.PuskCard(this.CheckNMCard(player, this.Naddavane), player);
                        }
                    }
                }
            }
            if (this.Pkazal[partner[3]][this.Naddavane] === true) {
                if (
                    this.hand < 6 &&
                    br[this.Naddavane] > 0 &&
                    (this.GetBrojKarti(this.Naddavane, partner[2]) > 0 || this.GetBrojKarti(this.Naddavane, partner[4]) > 0) &&
                    (this.CheckCard3(18, player, true) === true ||
                        this.CheckCard3(28, player, true) === true ||
                        this.CheckCard3(38, player, true) === true ||
                        this.CheckCard3(48, player, true) === true ||
                        this.CheckCard3(17, player, true) === true ||
                        this.CheckCard3(27, player, true) === true ||
                        this.CheckCard3(37, player, true) === true ||
                        this.CheckCard3(47, player, true) === true)
                ) {
                    if (this.CheckCard3(105, player, true) === true) {
                        return this.PuskCard(105, player);
                    }
                    if (this.CheckCard3(106, player, true) === true) {
                        return this.PuskCard(106, player);
                    }
                    if (this.CheckCard3(104, player, true) === true) {
                        return this.PuskCard(104, player);
                    }
                    if (this.CheckCard3(107, player, true) === true) {
                        return this.PuskCard(107, player);
                    }
                    if (this.CheckCard3(103, player, true) === true) {
                        return this.PuskCard(103, player);
                    }
                    if (this.CheckCard3(102, player, true) === true) {
                        return this.PuskCard(102, player);
                    }
                    if (this.CheckCard3(101, player, true) === true) {
                        return this.PuskCard(101, player);
                    }
                    if (this.CheckCard3(108, player, true) === true) {
                        return this.PuskCard(108, player);
                    }
                }
            }
            if (
                br[this.Naddavane] === 8 - this.hand &&
                this.Pniama[partner[2]][this.Naddavane] !== true &&
                this.Pniama[partner[4]][this.Naddavane] !== true &&
                this.Pniama[partner[3]][this.Naddavane] !== true &&
                brob[this.Naddavane] - br[this.Naddavane] < 4
            ) {
                if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === true) {
                    return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                }
            }
            vla = this.CheckVlastnaKarta4(player, partner[2], partner[3], partner[4]);
            if (vla > 0) {
                return vla;
            }

            if (this.Pniama[partner[2]][1] === true && br[1] > 0) {
                return this.PuskCard(this.CheckNMCard(player, 1), player);
            }
            if (this.Pniama[partner[2]][2] === true && br[2] > 0) {
                return this.PuskCard(this.CheckNMCard(player, 2), player);
            }
            if (this.Pniama[partner[2]][3] === true && br[3] > 0) {
                return this.PuskCard(this.CheckNMCard(player, 3), player);
            }
            if (this.Pniama[partner[2]][4] === true && br[4] > 0) {
                return this.PuskCard(this.CheckNMCard(player, 4), player);
            }

            pusk = this.PuskDrBMinCard(player);
            if (pusk > 0) {
                return pusk;
            }
            return this.MinCard(player);
        }
        //label2////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 2)
        ) {
            vla = this.CheckVlastnaKarta4(player, partner[2], partner[3], partner[4]);
            if (vla > 0) {
                return vla;
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                if (br[this.Naddavane] === 2) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                    }
                }
                if (br[this.Naddavane] === 0) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.Cakane(player);
                    if (pusk > 0) {
                        return this.PuskCard(pusk, player);
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                if (br[this.Naddavane] === 2) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                    }
                }
                if (br[this.Naddavane] === 0) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.Cakane(player);
                    if (pusk > 0) {
                        return this.PuskCard(pusk, player);
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                if (br[this.Naddavane] === 2) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                    }
                }
                if (br[this.Naddavane] === 0) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.Cakane(player);
                    if (pusk > 0) {
                        return this.PuskCard(pusk, player);
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                if (br[this.Naddavane] === 2) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, this.Naddavane)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                    }
                }
                if (br[this.Naddavane] === 0) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.Cakane(player);
                    if (pusk > 0) {
                        return this.PuskCard(pusk, player);
                    }
                }
            }
            if (this.ParviPuska > 100) {
                if (br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    if (this.Pkazal[partner[4]][this.Naddavane] === true && br[this.Naddavane] === 2) {
                        if (this.CheckCard3(107, player, true) === true && this.CheckCard3(108, partner[4], true) === true) {
                            return this.PuskCard(107, player);
                        }
                    }
                }
            }
            //pravila2:;
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                pusk = this.Cakane(player);
                if (pusk > 0) {
                    return this.PuskCard(pusk, player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                pusk = this.Cakane(player);
                if (pusk > 0) {
                    return this.PuskCard(pusk, player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                pusk = this.Cakane(player);
                if (pusk > 0) {
                    return this.PuskCard(pusk, player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                pusk = this.Cakane(player);
                if (pusk > 0) {
                    return this.PuskCard(pusk, player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 100) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > this.ParviPuska) {
                        return i;
                    }
                }
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
        }
        //label3////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 3)
        ) {
            vla = this.CheckVlastnaKarta4(player, partner[2], partner[3], partner[4]);
            if (vla > 0) {
                return vla;
            }
            if (vziatka === true) {
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                        if (this.Pniama[partner[2]][this.Naddavane] === true || this.Pniama[partner[2]][1] !== true) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 1), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                    if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                        if (this.Pniama[partner[2]][this.Naddavane] === true || this.Pniama[partner[2]][2] !== true) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 2), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                    if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                        if (this.Pniama[partner[2]][this.Naddavane] === true || this.Pniama[partner[2]][3] !== true) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 3), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                    if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                        if (this.Pniama[partner[2]][this.Naddavane] === true || this.Pniama[partner[2]][4] !== true) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 4), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                    if (this.ParviPuska > 100 && br[this.Naddavane] === 2) {
                        if (this.ParviPuska === 108) {
                            if (this.CheckCard3(107, player, true) === true) {
                                return this.PuskCard(this.CheckNMCard(player, this.Naddavane), player);
                            }
                            if (this.CheckCard3(107, player, true) === false && this.CheckCard(107) === true) {
                                return this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                            }
                        }
                        if (this.CheckVlastnaKartaAfter(this.CheckNGCard(player, this.Naddavane)) === false) {
                            pusk = this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                if (vziatka === false && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (vziatka === false && br[this.Naddavane] > 0) {
                    pusk = this.Cakane(player);
                    if (pusk > 0 && pusk > this.VtoriPuska) {
                        return this.PuskCard(pusk, player);
                    }
                }
                if (vziatka === true) {
                    if ((this.Pniama[partner[2]][this.Naddavane] === true || this.Pniama[partner[2]][1] === false) && this.CheckCard(17) === true) {
                        //?
                    }
                    if (
                        this.CheckVlastnaKarta(this.ParviPuska) === true &&
                        (this.Pniama[partner[2]][this.Naddavane] === true || this.GetBrojKarti(this.Naddavane, partner[2]) > 0)
                    ) {
                        pusk = this.PuskDrBMaxCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                if (vziatka === false && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (vziatka === false && br[this.Naddavane] > 0) {
                    pusk = this.Cakane(player);
                    if (pusk > 0 && pusk > this.VtoriPuska) {
                        return this.PuskCard(pusk, player);
                    }
                }
                if (vziatka === true) {
                    if (
                        this.CheckVlastnaKarta(this.ParviPuska) === true &&
                        (this.Pniama[partner[2]][this.Naddavane] === true || this.GetBrojKarti(this.Naddavane, partner[2]) > 0)
                    ) {
                        pusk = this.PuskDrBMaxCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                if (vziatka === false && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (vziatka === false && br[this.Naddavane] > 0) {
                    pusk = this.Cakane(player);
                    if (pusk > 0 && pusk > this.VtoriPuska) {
                        return this.PuskCard(pusk, player);
                    }
                }
                if (vziatka === true) {
                    if (
                        this.CheckVlastnaKarta(this.ParviPuska) === true &&
                        (this.Pniama[partner[2]][this.Naddavane] === true || this.GetBrojKarti(this.Naddavane, partner[2]) > 0)
                    ) {
                        pusk = this.PuskDrBMaxCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                if (vziatka === false && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (vziatka === false && br[this.Naddavane] > 0) {
                    pusk = this.Cakane(player);
                    if (pusk > 0 && pusk > this.VtoriPuska) {
                        return this.PuskCard(pusk, player);
                    }
                }
                if (vziatka === true) {
                    if (
                        this.CheckVlastnaKarta(this.ParviPuska) === true &&
                        (this.Pniama[partner[2]][this.Naddavane] === true || this.GetBrojKarti(this.Naddavane, partner[2]) > 0)
                    ) {
                        pusk = this.PuskDrBMaxCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    }
                }
            }
            if (this.ParviPuska > 100 && br[this.Naddavane] === 0) {
                this.Pniama[player][this.Naddavane] = true;
                if (vziatka === false) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (vziatka === true) {
                    if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                        pusk = this.PuskDrBMaxCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    }
                }
            }
            //pravila3:;
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.VtoriPuska && vziatka === false) {
                    return this.PuskCard(pusk, player);
                }
                if (this.VtoriPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.VtoriPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                if (vziatka === false) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > 100) {
                            return i;
                        }
                    }
                    this.Pniama[player][this.Naddavane] = true;
                }
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.VtoriPuska && vziatka === false) {
                    return this.PuskCard(pusk, player);
                }
                if (this.VtoriPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.VtoriPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                if (vziatka === false) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > 100) {
                            return i;
                        }
                    }
                    this.Pniama[player][this.Naddavane] = true;
                }
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.VtoriPuska && vziatka === false) {
                    return this.PuskCard(pusk, player);
                }
                if (this.VtoriPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.VtoriPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                if (vziatka === false) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > 100) {
                            return i;
                        }
                    }
                    this.Pniama[player][this.Naddavane] = true;
                }
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.VtoriPuska && vziatka === false) {
                    return this.PuskCard(pusk, player);
                }
                if (this.VtoriPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.VtoriPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                if (vziatka === false) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > 100) {
                            return i;
                        }
                    }
                    this.Pniama[player][this.Naddavane] = true;
                }
                return this.MinCard(player);
            }
            if (this.ParviPuska > 100) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
                return this.MinCard(player);
            }
        }
        /////////////////////////////////////////////label4/////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 3)
        ) {
            if (vziatka === true) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 0) {
                    pusk = this.PuskCard(this.CheckNGCard(player, 1), player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 0) {
                    pusk = this.PuskCard(this.CheckNGCard(player, 2), player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 0) {
                    pusk = this.PuskCard(this.CheckNGCard(player, 3), player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 0) {
                    pusk = this.PuskCard(this.CheckNGCard(player, 4), player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 100 && br[this.Naddavane] === 2) {
                    if (this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                        if (this.CheckVlastnaKartaAfter(this.CheckNGCard(player, this.Naddavane)) === false) {
                            pusk = this.PuskCard(this.CheckNGCard(player, this.Naddavane), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                    this.Pniama[player][1] = true;
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                    this.Pniama[player][2] = true;
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                    this.Pniama[player][3] = true;
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                    this.Pniama[player][4] = true;
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 100 && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 18) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 17) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 16) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 15) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 14) {
                            return i;
                        }
                    }
                    return this.Pravila4Boia(player, vziatka);
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 28) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 27) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 26) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 25) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 24) {
                            return i;
                        }
                    }
                    return this.Pravila4Boia(player, vziatka);
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 38) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 37) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 36) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 35) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 34) {
                            return i;
                        }
                    }
                    return this.Pravila4Boia(player, vziatka);
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 48) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 47) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 46) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 45) {
                            return i;
                        }
                    }
                    for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                        if (this.k[i] === 44) {
                            return i;
                        }
                    }
                    return this.Pravila4Boia(player, vziatka);
                }
                if (this.ParviPuska > 100 && br[this.Naddavane] === 2) {
                    if (this.VtoriPuska === 108) {
                        if (this.CheckCard3(107, player, true) === true) {
                            if (this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (this.CheckCard(106) === false && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (this.CheckCard(106) === false && this.CheckCard(105) === false && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard(106) === false &&
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard3(103, player, true) === true
                            ) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard(106) === false &&
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard3(102, player, true) === true
                            ) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard(106) === false &&
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(107, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(106, player, true) === true) {
                            if (this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard(105) === false && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard(105) === false && this.CheckCard(104) === false && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard3(102, player, true) === true
                            ) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(106, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(105, player, true) === true) {
                            if (this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(3104, player, true) === true) {
                            if (this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(103, player, true) === true) {
                            if (this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                    if (this.VtoriPuska === 107) {
                        if (this.CheckCard3(108, player, true) === true) {
                            return this.PuskCard(108, player);
                        }
                        if (this.CheckCard3(106, player, true) === true) {
                            if (this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard(105) === false && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard(105) === false && this.CheckCard(104) === false && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard3(102, player, true) === true
                            ) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard(105) === false &&
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(106, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(105, player, true) === true) {
                            if (this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(104, player, true) === true) {
                            if (this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(103, player, true) === true) {
                            if (this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                    if (this.VtoriPuska === 106) {
                        if (this.CheckCard3(108, player, true) === true) {
                            return this.PuskCard(108, player);
                        }
                        if (this.CheckCard3(107, player, true) === true) {
                            return this.PuskCard(107, player);
                        }
                        if (this.CheckCard3(105, player, true) === true) {
                            if (this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard(104) === false && this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard(104) === false &&
                                this.CheckCard(103) === false &&
                                this.CheckCard(102) === false &&
                                this.CheckCard3(101, player, true) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(104, player, true) === true) {
                            if (this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(103, player, true) === true) {
                            if (this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                    if (this.VtoriPuska === 105) {
                        if (this.CheckCard3(108, player, true) === true) {
                            if (this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            return this.PuskCard(108, player);
                        }
                        if (this.CheckCard3(107, player, true) === true) {
                            return this.PuskCard(107, player);
                        }
                        if (this.CheckCard3(106, player, true) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard3(104, player, true) === true) {
                            if (this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard(103) === false && this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.CheckCard3(103, player, true) === true) {
                            if (this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                    if (this.VtoriPuska === 104) {
                        if (this.CheckCard3(108, player, true) === true) {
                            if (this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            return this.PuskCard(108, player);
                        }
                        if (this.CheckCard3(107, player, true) === true) {
                            if (this.CheckCard(108) === false) {
                                if (this.CheckCard3(105, player, true) === true) {
                                    return this.PuskCard(105, player);
                                }
                                if (this.CheckCard3(106, player, true) === true) {
                                    return this.PuskCard(106, player);
                                }
                            }
                            return this.PuskCard(107, player);
                        }
                        if (this.CheckCard3(106, player, true) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard3(105, player, true) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard3(103, player, true) === true) {
                            if (this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard(102) === false && this.CheckCard3(101, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                    if (this.VtoriPuska === 103) {
                        if (this.CheckCard3(108, player, true) === true) {
                            if (this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            return this.PuskCard(108, player);
                        }
                        if (this.CheckCard3(107, player, true) === true) {
                            if (this.CheckCard(108) === false) {
                                if (this.CheckCard3(106, player, true) === true) {
                                    return this.PuskCard(106, player);
                                }
                                if (this.CheckCard3(105, player, true) === true) {
                                    return this.PuskCard(105, player);
                                }
                            }
                            return this.PuskCard(107, player);
                        }
                        if (this.CheckCard3(106, player, true) === true) {
                            return this.PuskCard(106, player);
                        }
                        if (this.CheckCard3(105, player, true) === true) {
                            return this.PuskCard(105, player);
                        }
                        if (this.CheckCard3(104, player, true) === true) {
                            return this.PuskCard(104, player);
                        }
                    }
                }
            } else {
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                    this.Pniama[player][1] = true;
                    if (br[this.Naddavane] === 0) {
                        pusk = this.PuskDrBMinCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    } else {
                        pusk = this.Cakane(player);
                        if (pusk > 0 && pusk > this.TretiPuska) {
                            return this.PuskCard(pusk, player);
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                    this.Pniama[player][2] = true;
                    if (br[this.Naddavane] === 0) {
                        pusk = this.PuskDrBMinCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    } else {
                        pusk = this.Cakane(player);
                        if (pusk > 0 && pusk > this.TretiPuska) {
                            return this.PuskCard(pusk, player);
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                    this.Pniama[player][3] = true;
                    if (br[this.Naddavane] === 0) {
                        pusk = this.PuskDrBMinCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    } else {
                        pusk = this.Cakane(player);
                        if (pusk > 0 && pusk > this.TretiPuska) {
                            return this.PuskCard(pusk, player);
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                    this.Pniama[player][4] = true;
                    if (br[this.Naddavane] === 0) {
                        pusk = this.PuskDrBMinCard(player);
                        if (pusk > 0) {
                            return pusk;
                        }
                    } else {
                        pusk = this.Cakane(player);
                        if (pusk > 0 && pusk > this.TretiPuska) {
                            return this.PuskCard(pusk, player);
                        }
                    }
                }
                if (this.ParviPuska > 100 && br[this.Naddavane] === 0) {
                    this.Pniama[player][this.Naddavane] = true;
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                    if (this.TretiPuska < 100) {
                        if (this.TretiPuska > 10 && this.TretiPuska < 20) {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 10 && this.k[i] < 20 && this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska) {
                                    return i;
                                }
                            }
                        } else {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 10 && this.k[i] < 20 && this.k[i] > this.ParviPuska) {
                                    return i;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                    if (this.TretiPuska < 100) {
                        if (this.TretiPuska > 20 && this.TretiPuska < 30) {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska) {
                                    return i;
                                }
                            }
                        } else {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska) {
                                    return i;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                    if (this.TretiPuska < 100) {
                        if (this.TretiPuska > 30 && this.TretiPuska < 40) {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska) {
                                    return i;
                                }
                            }
                        } else {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska) {
                                    return i;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                    if (this.TretiPuska < 100) {
                        if (this.TretiPuska > 40 && this.TretiPuska < 50) {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska) {
                                    return i;
                                }
                            }
                        } else {
                            for (let i = 8 - this.hand + (player - 1) * 8; i > (player - 1) * 8; i--) {
                                if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska) {
                                    return i;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 100) {
                    if (br[this.Naddavane] === 2) {
                        if (this.ParviPuska === 108 || this.TretiPuska === 108) {
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 107 || this.TretiPuska === 107) {
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 106 || this.TretiPuska === 106) {
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 105 || this.TretiPuska === 105) {
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard3(107, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 104 || this.TretiPuska === 104) {
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(107, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === false
                            ) {
                                return this.PuskCard(107, player);
                            }
                            if (this.CheckCard3(106, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 103 || this.TretiPuska === 103) {
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard3(107, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === false
                            ) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(107) === false
                            ) {
                                return this.PuskCard(107, player);
                            }
                            if (this.CheckCard3(106, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 102 || this.TretiPuska === 102) {
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard3(107, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard3(106, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard3(105, player, true) === true && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(105) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard3(104, player, true) === true && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                        if (this.ParviPuska === 101 || this.TretiPuska === 101) {
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            if (this.CheckCard3(108, player, true) === true && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(102, player);
                            }
                            if (this.CheckCard3(107, player, true) === true && this.CheckCard3(106, player, true) === true) {
                                return this.PuskCard(107, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (
                                this.CheckCard3(107, player, true) === true &&
                                this.CheckCard3(102, player, true) === true &&
                                this.CheckVlastnaKarta(107) === true
                            ) {
                                return this.PuskCard(102, player);
                            }
                            if (this.CheckCard3(106, player, true) === true && this.CheckCard3(105, player, true) === true) {
                                return this.PuskCard(106, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (
                                this.CheckCard3(106, player, true) === true &&
                                this.CheckCard3(102, player, true) === true &&
                                this.CheckVlastnaKarta(106) === true
                            ) {
                                return this.PuskCard(102, player);
                            }
                            if (this.CheckCard3(105, player, true) === true && this.CheckCard3(104, player, true) === true) {
                                return this.PuskCard(105, player);
                            }
                            if (
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckCard3(103, player, true) === true &&
                                this.CheckVlastnaKarta(105) === true
                            ) {
                                return this.PuskCard(103, player);
                            }
                            if (
                                this.CheckCard3(105, player, true) === true &&
                                this.CheckCard3(102, player, true) === true &&
                                this.CheckVlastnaKarta(105) === true
                            ) {
                                return this.PuskCard(102, player);
                            }
                            if (this.CheckCard3(104, player, true) === true && this.CheckCard3(103, player, true) === true) {
                                return this.PuskCard(104, player);
                            }
                            if (
                                this.CheckCard3(104, player, true) === true &&
                                this.CheckCard3(102, player, true) === true &&
                                this.CheckVlastnaKarta(104) === true
                            ) {
                                return this.PuskCard(102, player);
                            }
                            if (this.CheckCard3(103, player, true) === true && this.CheckCard3(102, player, true) === true) {
                                return this.PuskCard(103, player);
                            }
                            return this.Pravila4Boia(player, vziatka);
                        }
                    }
                }
            }
            vla = this.CheckVlastnaKarta4(player, partner[2], partner[3], partner[4]);
            if (vla > 0) {
                return vla;
            }
            return this.Pravila4Boia(player, vziatka);
        }
        return this.MinCard(player);
    }

    Pravila4Boia(player: number, vziatka: boolean) {
        let pusk = 0;
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 10 && this.k[i] < 20) {
                    return i;
                }
            }
            this.Pniama[player][1] = true;
            if (vziatka === false) {
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.TretiPuska) {
                    return this.PuskCard(pusk, player);
                }
                if (this.TretiPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.TretiPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
            }
            return this.MinCard(player);
        }
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 20 && this.k[i] < 30) {
                    return i;
                }
            }
            this.Pniama[player][2] = true;
            if (vziatka === false) {
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.TretiPuska) {
                    return this.PuskCard(pusk, player);
                }
                if (this.TretiPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.TretiPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
            }
            return this.MinCard(player);
        }
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 30 && this.k[i] < 40) {
                    return i;
                }
            }
            this.Pniama[player][3] = true;
            if (vziatka === false) {
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.TretiPuska) {
                    return this.PuskCard(pusk, player);
                }
                if (this.TretiPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.TretiPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
            }
            return this.MinCard(player);
        }
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 40 && this.k[i] < 50) {
                    return i;
                }
            }
            this.Pniama[player][4] = true;
            if (vziatka === false) {
                pusk = this.Cakane(player);
                if (pusk > 0 && pusk > this.TretiPuska) {
                    return this.PuskCard(pusk, player);
                }
                if (this.TretiPuska > 100) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.TretiPuska) {
                            return i;
                        }
                    }
                    return this.MinCard(player);
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 100) {
                        return i;
                    }
                }
                this.Pniama[player][this.Naddavane] = true;
            }
            return this.MinCard(player);
        }
        if (this.ParviPuska > 100) {
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska) {
                    return i;
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 100) {
                    return i;
                }
            }
            this.Pniama[player][this.Naddavane] = true;
            return this.MinCard(player);
        }
        return this.MinCard(player);
    }

    IntelectBe(player: number) {
        let h;
        let pusk;
        let boia;
        let tac = 0;
        let partner: number[] = [];

        if (this.KoiENaRed !== this.KoiPuskaParvi) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                boia = 1;
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                boia = 2;
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                boia = 3;
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                boia = 4;
            }
        }

        if (this.KoiENaRed === 2) {
            partner[2] = 3;
            partner[3] = 4;
            partner[4] = 1;
        }
        if (this.KoiENaRed === 3) {
            partner[2] = 4;
            partner[3] = 1;
            partner[4] = 2;
        }
        if (this.KoiENaRed === 4) {
            partner[2] = 1;
            partner[3] = 2;
            partner[4] = 3;
        }
        let br: number[] = [];
        let brob: number[] = [];
        br[1] = this.GetBrojKarti(1, player);
        br[2] = this.GetBrojKarti(2, player);
        br[3] = this.GetBrojKarti(3, player);
        br[4] = this.GetBrojKarti(4, player);
        brob[1] = this.GetBrojKarti(1, 0);
        brob[2] = this.GetBrojKarti(2, 0);
        brob[3] = this.GetBrojKarti(3, 0);
        brob[4] = this.GetBrojKarti(4, 0);
        if (this.hand === 0) {
            this.BrBoiaNach[player][1] = br[1];
            this.BrBoiaNach[player][2] = br[2];
            this.BrBoiaNach[player][3] = br[3];
            this.BrBoiaNach[player][4] = br[4];
        }
        let vziatka: boolean = this.Vziatka();
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 4)
        ) {
            if (this.Pkazal[partner[3]][5] === true && this.hand > 3 && this.RandNumber(100) > this.hand * 18) {
                this.Tactic[player] = 1;
            }
            if (this.Pkazal[partner[3]][5] !== true && this.hand > 3) {
                this.Tactic[player] = 1;
            }
            if (this.Tactic[player] === 0 && this.hand < 3) {
                tac = tac + this.hand * 25;
                if (this.hand < 3) {
                    if (this.CheckCard3(18, player, true) === true && this.CheckCard3(17, player, true) === true) {
                        tac = tac + br[1] * 16;
                    }
                    if (this.CheckCard(18) === false && this.CheckCard3(17, player, true) === true) {
                        tac = tac + br[1] * 16;
                    }
                    if (this.CheckCard3(18, player, true) === true && this.CheckCard3(16, player, true) === true) {
                        tac = tac + br[1] * 10;
                    }
                    if (this.CheckCard3(28, player, true) === true && this.CheckCard3(27, player, true) === true) {
                        tac = tac + br[2] * 16;
                    }
                    if (this.CheckCard(28) === false && this.CheckCard3(27, player, true) === true) {
                        tac = tac + br[2] * 16;
                    }
                    if (this.CheckCard3(28, player, true) === true && this.CheckCard3(26, player, true) === true) {
                        tac = tac + br[2] * 10;
                    }
                    if (this.CheckCard3(38, player, true) === true && this.CheckCard3(37, player, true) === true) {
                        tac = tac + br[3] * 16;
                    }
                    if (this.CheckCard(38) === false && this.CheckCard3(37, player, true) === true) {
                        tac = tac + br[3] * 16;
                    }
                    if (this.CheckCard3(38, player, true) === true && this.CheckCard3(36, player, true) === true) {
                        tac = tac + br[3] * 10;
                    }
                    if (this.CheckCard3(48, player, true) === true && this.CheckCard3(47, player, true) === true) {
                        tac = tac + br[4] * 16;
                    }
                    if (this.CheckCard(48) === false && this.CheckCard3(47, player, true) === true) {
                        tac = tac + br[4] * 16;
                    }
                    if (this.CheckCard3(48, player, true) === true && this.CheckCard3(46, player, true) === true) {
                        tac = tac + br[4] * 10;
                    }
                    if (this.Pkazal[partner[3]][6] === true) {
                        tac = tac - 20;
                    }
                    if (this.Pkazal[partner[2]][6] === true) {
                        tac = tac + 20;
                    }
                    if (this.Pkazal[partner[4]][6] === true) {
                        tac = tac + 20;
                    }
                    if (this.CheckCard3(18, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(17, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(16, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(15, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(14, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(13, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(12, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(11, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(28, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(27, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(26, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(25, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(24, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(23, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(22, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(21, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(38, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(37, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(36, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(35, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(34, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(33, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(32, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(31, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(48, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(47, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(46, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(45, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(44, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(43, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(42, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(41, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.RandNumber(100) < tac) {
                    this.Tactic[player] = 1;
                } else {
                    this.Tactic[player] = 2;
                }
            }
            /////////
            if (this.Tactic[player] === 1) {
                if (
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][4] &&
                    br[1] > 0 &&
                    this.Pkazal[partner[2]][1] !== true &&
                    this.Pkazal[partner[4]][1] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 1)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][1] &&
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][4] &&
                    br[2] > 0 &&
                    this.Pkazal[partner[2]][2] !== true &&
                    this.Pkazal[partner[4]][2] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 2)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][1] &&
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][4] &&
                    br[3] > 0 &&
                    this.Pkazal[partner[2]][3] !== true &&
                    this.Pkazal[partner[4]][3] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 3)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][1] &&
                    br[4] > 0 &&
                    this.Pkazal[partner[2]][4] !== true &&
                    this.Pkazal[partner[4]][4] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 4)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.CheckVlastnaKarta(this.k[h]) === true) {
                        return h;
                    }
                }
                this.Tactic[player] = 2;
            }
            if (this.Tactic[player] === 2) {
                if (this.hand > 2) {
                    this.Tactic[player] = 1;
                }
                let izb = this.CheckIzbivane(player, partner[3]);
                if (izb > 0) {
                    return izb;
                }
                this.Tactic[player] = 1;
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.CheckVlastnaKarta(this.k[h]) === true) {
                        return h;
                    }
                }
            }
            /////////

            /////////
            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                if (this.CheckVlastnaKarta(this.k[i]) === true) {
                    return i;
                }
            }
            /////////
            pusk = this.PuskIskanaKarta(player, partner[2], partner[3], partner[4]);
            if (pusk > 0) {
                return pusk;
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.CheckVlastnaKarta(this.k[i]) === true) {
                    return i;
                }
            }
            return this.MinCard(player);
        }
        //label2////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 2)
        ) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 10 && this.k[i] < 20 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 20 && this.k[i] < 30 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 30 && this.k[i] < 40 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 40 && this.k[i] < 50 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.Pkazal[partner[4]][this.Naddavane] === true && br[boia] === 2) {
                if (boia === 1 && this.CheckCard3(17, player, true) === true && this.CheckCard3(18, partner[4], true) === true) {
                    return this.PuskCard(17, player);
                }
                if (boia === 2 && this.CheckCard3(27, player, true) === true && this.CheckCard3(28, partner[4], true) === true) {
                    return this.PuskCard(27, player);
                }
                if (boia === 3 && this.CheckCard3(37, player, true) === true && this.CheckCard3(38, partner[4], true) === true) {
                    return this.PuskCard(37, player);
                }
                if (boia === 4 && this.CheckCard3(47, player, true) === true && this.CheckCard3(48, partner[4], true) === true) {
                    return this.PuskCard(47, player);
                }
            }
            //pravila2:;//////////////////////////
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                return this.MinCard(player);
            }
        }
        //label3////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 3)
        ) {
            if (vziatka === false) {
                if (br[boia] > 1) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, boia)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                }
            }
            if (vziatka === true) {
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    if (br[boia] === 0) {
                        if (br[1] === 8 - this.hand) {
                            if (br[1] === brob[1] || (this.VtoriPuska > 10 && this.VtoriPuska < 20 && br[1] === brob[1] - 1)) {
                                if (br[1] > 0) {
                                    if (this.CheckNGCard(player, 1) > 0) {
                                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                                    }
                                }
                            }
                        }
                        if (br[2] === 8 - this.hand) {
                            if (br[2] === brob[2] || (this.VtoriPuska > 20 && this.VtoriPuska < 30 && br[1] === brob[2] - 1)) {
                                if (br[2] > 0) {
                                    if (this.CheckNGCard(player, 2) > 0) {
                                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                                    }
                                }
                            }
                        }
                        if (br[3] === 8 - this.hand) {
                            if (br[3] === brob[3] || (this.VtoriPuska > 30 && this.VtoriPuska < 40 && br[1] === brob[3] - 1)) {
                                if (br[3] > 0) {
                                    if (this.CheckNGCard(player, 3) > 0) {
                                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                                    }
                                }
                            }
                        }
                        if (br[1] === 8 - this.hand) {
                            if (br[4] === brob[4] || (this.VtoriPuska > 40 && this.VtoriPuska < 50 && br[1] === brob[4] - 1)) {
                                if (br[4] > 0) {
                                    if (this.CheckNGCard(player, 4) > 0) {
                                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                                    }
                                }
                            }
                        }
                    }
                    if (br[boia] === 2) {
                        if (this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 1) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (
                            this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 2 &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                        ) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (
                            this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 3 &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                        ) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (
                            this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 4 &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                        ) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (
                            this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 5 &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 4) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                        ) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (
                            this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 6 &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 5) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 4) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                            this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                        ) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        }
                        if (this.CheckVlastnaKartaAfter(this.CheckNGCard(player, boia)) === false) {
                            return this.PuskCard(this.CheckNGCard(player, boia), player);
                        } else {
                            return this.PuskCard(this.CheckNMCard(player, boia), player);
                        }
                    }
                    if (this.hand === 6 && br[boia] === 0) {
                        if (br[1] === 2 && brob[1] < 4) {
                            return this.PuskCard(this.CheckNGCard(player, 1), player);
                        }
                        if (br[2] === 2 && brob[2] < 4) {
                            return this.PuskCard(this.CheckNGCard(player, 2), player);
                        }
                        if (br[3] === 2 && brob[3] < 4) {
                            return this.PuskCard(this.CheckNGCard(player, 3), player);
                        }
                        if (br[4] === 2 && brob[4] < 4) {
                            return this.PuskCard(this.CheckNGCard(player, 4), player);
                        }
                    }
                    if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                        if (
                            this.CheckVlastnaKartaAfter(this.CheckNGCard(player, 1)) === false ||
                            this.Pkazal[partner[3]][5] === true ||
                            this.Pkazal[partner[3]][1] === true ||
                            this.hand > 5
                        ) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 1), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        } else {
                            if (this.CheckCard3(17, player, true) === true) {
                                if (this.CheckCard3(16, player, true) === true) {
                                    return this.PuskCard(17, player);
                                }
                                if (this.CheckCard(16) === false && this.CheckCard3(15, player, true) === true) {
                                    return this.PuskCard(17, player);
                                }
                                if (this.CheckCard(16) === false && this.CheckCard(15) === false && this.CheckCard3(14, player, true) === true) {
                                    return this.PuskCard(17, player);
                                }
                                if (
                                    this.CheckCard(16) === false &&
                                    this.CheckCard(15) === false &&
                                    this.CheckCard(14) === false &&
                                    this.CheckCard3(13, player, true) === true
                                ) {
                                    return this.PuskCard(17, player);
                                }
                                if (
                                    this.CheckCard(16) === false &&
                                    this.CheckCard(15) === false &&
                                    this.CheckCard(14) === false &&
                                    this.CheckCard(13) === false &&
                                    this.CheckCard3(12, player, true) === true
                                ) {
                                    return this.PuskCard(17, player);
                                }
                                if (
                                    this.CheckCard(16) === false &&
                                    this.CheckCard(15) === false &&
                                    this.CheckCard(14) === false &&
                                    this.CheckCard(13) === false &&
                                    this.CheckCard(12) === false &&
                                    this.CheckCard3(11, player, true) === true
                                ) {
                                    return this.PuskCard(17, player);
                                }
                            }
                            if (this.CheckCard3(16, player, true) === true) {
                                if (this.CheckCard3(15, player, true) === true) {
                                    return this.PuskCard(16, player);
                                }
                                if (this.CheckCard(15) === false && this.CheckCard3(14, player, true) === true) {
                                    return this.PuskCard(16, player);
                                }
                                if (this.CheckCard(15) === false && this.CheckCard(14) === false && this.CheckCard3(13, player, true) === true) {
                                    return this.PuskCard(16, player);
                                }
                                if (
                                    this.CheckCard(15) === false &&
                                    this.CheckCard(14) === false &&
                                    this.CheckCard(13) === false &&
                                    this.CheckCard3(12, player, true) === true
                                ) {
                                    return this.PuskCard(16, player);
                                }
                                if (
                                    this.CheckCard(15) === false &&
                                    this.CheckCard(14) === false &&
                                    this.CheckCard(13) === false &&
                                    this.CheckCard(12) === false &&
                                    this.CheckCard3(11, player, true) === true
                                ) {
                                    return this.PuskCard(16, player);
                                }
                            }
                            if (this.CheckCard3(15, player, true) === true) {
                                if (this.CheckCard3(14, player, true) === true) {
                                    return this.PuskCard(15, player);
                                }
                                if (this.CheckCard(14) === false && this.CheckCard3(13, player, true) === true) {
                                    return this.PuskCard(15, player);
                                }
                                if (this.CheckCard(14) === false && this.CheckCard(13) === false && this.CheckCard3(12, player, true) === true) {
                                    return this.PuskCard(15, player);
                                }
                                if (
                                    this.CheckCard(14) === false &&
                                    this.CheckCard(13) === false &&
                                    this.CheckCard(12) === false &&
                                    this.CheckCard3(11, player, true) === true
                                ) {
                                    return this.PuskCard(15, player);
                                }
                            }
                            if (this.CheckCard3(14, player, true) === true) {
                                if (this.CheckCard3(12, player, true) === true) {
                                    return this.PuskCard(14, player);
                                }
                                if (this.CheckCard(13) === false && this.CheckCard3(12, player, true) === true) {
                                    return this.PuskCard(14, player);
                                }
                                if (this.CheckCard(13) === false && this.CheckCard(12) === false && this.CheckCard3(11, player, true) === true) {
                                    return this.PuskCard(14, player);
                                }
                            }
                        }
                    }

                    if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                        if (
                            this.CheckVlastnaKartaAfter(this.CheckNGCard(player, 2)) === false ||
                            this.Pkazal[partner[3]][5] === true ||
                            this.Pkazal[partner[3]][2] === true ||
                            this.hand > 5
                        ) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 2), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        } else {
                            if (this.CheckCard3(27, player, true) === true) {
                                if (this.CheckCard3(26, player, true) === true) {
                                    return this.PuskCard(27, player);
                                }
                                if (this.CheckCard(26) === false && this.CheckCard3(25, player, true) === true) {
                                    return this.PuskCard(27, player);
                                }
                                if (this.CheckCard(26) === false && this.CheckCard(25) === false && this.CheckCard3(24, player, true) === true) {
                                    return this.PuskCard(27, player);
                                }
                                if (
                                    this.CheckCard(26) === false &&
                                    this.CheckCard(25) === false &&
                                    this.CheckCard(24) === false &&
                                    this.CheckCard3(23, player, true) === true
                                ) {
                                    return this.PuskCard(27, player);
                                }
                                if (
                                    this.CheckCard(26) === false &&
                                    this.CheckCard(25) === false &&
                                    this.CheckCard(24) === false &&
                                    this.CheckCard(23) === false &&
                                    this.CheckCard3(22, player, true) === true
                                ) {
                                    return this.PuskCard(27, player);
                                }
                                if (
                                    this.CheckCard(26) === false &&
                                    this.CheckCard(25) === false &&
                                    this.CheckCard(24) === false &&
                                    this.CheckCard(23) === false &&
                                    this.CheckCard(22) === false &&
                                    this.CheckCard3(21, player, true) === true
                                ) {
                                    return this.PuskCard(27, player);
                                }
                            }
                            if (this.CheckCard3(26, player, true) === true) {
                                if (this.CheckCard3(25, player, true) === true) {
                                    return this.PuskCard(26, player);
                                }
                                if (this.CheckCard(25) === false && this.CheckCard3(24, player, true) === true) {
                                    return this.PuskCard(26, player);
                                }
                                if (this.CheckCard(25) === false && this.CheckCard(24) === false && this.CheckCard3(23, player, true) === true) {
                                    return this.PuskCard(26, player);
                                }
                                if (
                                    this.CheckCard(25) === false &&
                                    this.CheckCard(24) === false &&
                                    this.CheckCard(23) === false &&
                                    this.CheckCard3(22, player, true) === true
                                ) {
                                    return this.PuskCard(26, player);
                                }
                                if (
                                    this.CheckCard(25) === false &&
                                    this.CheckCard(24) === false &&
                                    this.CheckCard(23) === false &&
                                    this.CheckCard(22) === false &&
                                    this.CheckCard3(21, player, true) === true
                                ) {
                                    return this.PuskCard(26, player);
                                }
                            }
                            if (this.CheckCard3(25, player, true) === true) {
                                if (this.CheckCard3(24, player, true) === true) {
                                    return this.PuskCard(25, player);
                                }
                                if (this.CheckCard(24) === false && this.CheckCard3(23, player, true) === true) {
                                    return this.PuskCard(25, player);
                                }
                                if (this.CheckCard(24) === false && this.CheckCard(23) === false && this.CheckCard3(22, player, true) === true) {
                                    return this.PuskCard(25, player);
                                }
                                if (
                                    this.CheckCard(24) === false &&
                                    this.CheckCard(23) === false &&
                                    this.CheckCard(22) === false &&
                                    this.CheckCard3(21, player, true) === true
                                ) {
                                    return this.PuskCard(25, player);
                                }
                            }
                            if (this.CheckCard3(24, player, true) === true) {
                                if (this.CheckCard3(23, player, true) === true) {
                                    return this.PuskCard(24, player);
                                }
                                if (this.CheckCard(23) === false && this.CheckCard3(22, player, true) === true) {
                                    return this.PuskCard(24, player);
                                }
                                if (this.CheckCard(23) === false && this.CheckCard(22) === false && this.CheckCard3(21, player, true) === true) {
                                    return this.PuskCard(24, player);
                                }
                            }
                        }
                    }
                    if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                        if (
                            this.CheckVlastnaKartaAfter(this.CheckNGCard(player, 3)) === false ||
                            this.Pkazal[partner[3]][5] === true ||
                            this.Pkazal[partner[3]][3] === true ||
                            this.hand > 5
                        ) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 3), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        } else {
                            if (this.CheckCard3(37, player, true) === true) {
                                if (this.CheckCard3(36, player, true) === true) {
                                    return this.PuskCard(37, player);
                                }
                                if (this.CheckCard(36) === false && this.CheckCard3(35, player, true) === true) {
                                    return this.PuskCard(37, player);
                                }
                                if (this.CheckCard(36) === false && this.CheckCard(35) === false && this.CheckCard3(34, player, true) === true) {
                                    return this.PuskCard(37, player);
                                }
                                if (
                                    this.CheckCard(36) === false &&
                                    this.CheckCard(35) === false &&
                                    this.CheckCard(34) === false &&
                                    this.CheckCard3(33, player, true) === true
                                ) {
                                    return this.PuskCard(37, player);
                                }
                                if (
                                    this.CheckCard(36) === false &&
                                    this.CheckCard(35) === false &&
                                    this.CheckCard(34) === false &&
                                    this.CheckCard(33) === false &&
                                    this.CheckCard3(32, player, true) === true
                                ) {
                                    return this.PuskCard(37, player);
                                }
                                if (
                                    this.CheckCard(36) === false &&
                                    this.CheckCard(35) === false &&
                                    this.CheckCard(34) === false &&
                                    this.CheckCard(33) === false &&
                                    this.CheckCard(32) === false &&
                                    this.CheckCard3(31, player, true) === true
                                ) {
                                    return this.PuskCard(37, player);
                                }
                            }
                            if (this.CheckCard3(36, player, true) === true) {
                                if (this.CheckCard3(35, player, true) === true) {
                                    return this.PuskCard(36, player);
                                }
                                if (this.CheckCard(35) === false && this.CheckCard3(34, player, true) === true) {
                                    return this.PuskCard(36, player);
                                }
                                if (this.CheckCard(35) === false && this.CheckCard(34) === false && this.CheckCard3(33, player, true) === true) {
                                    return this.PuskCard(36, player);
                                }
                                if (
                                    this.CheckCard(35) === false &&
                                    this.CheckCard(34) === false &&
                                    this.CheckCard(33) === false &&
                                    this.CheckCard3(32, player, true) === true
                                ) {
                                    return this.PuskCard(36, player);
                                }
                                if (
                                    this.CheckCard(35) === false &&
                                    this.CheckCard(34) === false &&
                                    this.CheckCard(33) === false &&
                                    this.CheckCard(32) === false &&
                                    this.CheckCard3(31, player, true) === true
                                ) {
                                    return this.PuskCard(36, player);
                                }
                            }
                            if (this.CheckCard3(35, player, true) === true) {
                                if (this.CheckCard3(34, player, true) === true) {
                                    return this.PuskCard(35, player);
                                }
                                if (this.CheckCard(34) === false && this.CheckCard3(33, player, true) === true) {
                                    return this.PuskCard(35, player);
                                }
                                if (this.CheckCard(34) === false && this.CheckCard(33) === false && this.CheckCard3(32, player, true) === true) {
                                    return this.PuskCard(35, player);
                                }
                                if (
                                    this.CheckCard(34) === false &&
                                    this.CheckCard(33) === false &&
                                    this.CheckCard(32) === false &&
                                    this.CheckCard3(31, player, true) === true
                                ) {
                                    return this.PuskCard(35, player);
                                }
                            }
                            if (this.CheckCard3(34, player, true) === true) {
                                if (this.CheckCard3(33, player, true) === true) {
                                    return this.PuskCard(34, player);
                                }
                                if (this.CheckCard(33) === false && this.CheckCard3(32, player, true) === true) {
                                    return this.PuskCard(34, player);
                                }
                                if (this.CheckCard(33) === false && this.CheckCard(32) === false && this.CheckCard3(31, player, true) === true) {
                                    return this.PuskCard(34, player);
                                }
                            }
                        }
                    }
                    if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                        if (
                            this.CheckVlastnaKartaAfter(this.CheckNGCard(player, 4)) === false ||
                            this.Pkazal[partner[3]][5] === true ||
                            this.Pkazal[partner[3]][4] === true ||
                            this.hand > 5
                        ) {
                            pusk = this.PuskCard(this.CheckNGCard(player, 4), player);
                            if (pusk > 0) {
                                return pusk;
                            }
                        } else {
                            if (this.CheckCard3(47, player, true) === true) {
                                if (this.CheckCard3(46, player, true) === true) {
                                    return this.PuskCard(47, player);
                                }
                                if (this.CheckCard(46) === false && this.CheckCard3(45, player, true) === true) {
                                    return this.PuskCard(47, player);
                                }
                                if (this.CheckCard(46) === false && this.CheckCard(45) === false && this.CheckCard3(44, player, true) === true) {
                                    return this.PuskCard(47, player);
                                }
                                if (
                                    this.CheckCard(46) === false &&
                                    this.CheckCard(45) === false &&
                                    this.CheckCard(44) === false &&
                                    this.CheckCard3(43, player, true) === true
                                ) {
                                    return this.PuskCard(47, player);
                                }
                                if (
                                    this.CheckCard(46) === false &&
                                    this.CheckCard(45) === false &&
                                    this.CheckCard(44) === false &&
                                    this.CheckCard(43) === false &&
                                    this.CheckCard3(42, player, true) === true
                                ) {
                                    return this.PuskCard(47, player);
                                }
                                if (
                                    this.CheckCard(46) === false &&
                                    this.CheckCard(45) === false &&
                                    this.CheckCard(44) === false &&
                                    this.CheckCard(43) === false &&
                                    this.CheckCard(42) === false &&
                                    this.CheckCard3(41, player, true) === true
                                ) {
                                    return this.PuskCard(47, player);
                                }
                            }
                            if (this.CheckCard3(46, player, true) === true) {
                                if (this.CheckCard3(45, player, true) === true) {
                                    return this.PuskCard(46, player);
                                }
                                if (this.CheckCard(45) === false && this.CheckCard3(44, player, true) === true) {
                                    return this.PuskCard(46, player);
                                }
                                if (this.CheckCard(45) === false && this.CheckCard(44) === false && this.CheckCard3(43, player, true) === true) {
                                    return this.PuskCard(46, player);
                                }
                                if (
                                    this.CheckCard(45) === false &&
                                    this.CheckCard(44) === false &&
                                    this.CheckCard(43) === false &&
                                    this.CheckCard3(42, player, true) === true
                                ) {
                                    return this.PuskCard(46, player);
                                }
                                if (
                                    this.CheckCard(45) === false &&
                                    this.CheckCard(44) === false &&
                                    this.CheckCard(43) === false &&
                                    this.CheckCard(42) === false &&
                                    this.CheckCard3(41, player, true) === true
                                ) {
                                    return this.PuskCard(46, player);
                                }
                            }
                            if (this.CheckCard3(45, player, true) === true) {
                                if (this.CheckCard3(44, player, true) === true) {
                                    return this.PuskCard(45, player);
                                }
                                if (this.CheckCard(44) === false && this.CheckCard3(43, player, true) === true) {
                                    return this.PuskCard(45, player);
                                }
                                if (this.CheckCard(44) === false && this.CheckCard(43) === false && this.CheckCard3(42, player, true) === true) {
                                    return this.PuskCard(45, player);
                                }
                                if (
                                    this.CheckCard(44) === false &&
                                    this.CheckCard(43) === false &&
                                    this.CheckCard(42) === false &&
                                    this.CheckCard3(41, player, true) === true
                                ) {
                                    return this.PuskCard(45, player);
                                }
                            }
                            if (this.CheckCard3(44, player, true) === true) {
                                if (this.CheckCard3(43, player, true) === true) {
                                    return this.PuskCard(44, player);
                                }
                                if (this.CheckCard(43) === false && this.CheckCard3(42, player, true) === true) {
                                    return this.PuskCard(44, player);
                                }
                                if (this.CheckCard(43) === false && this.CheckCard(42) === false && this.CheckCard3(41, player, true) === true) {
                                    return this.PuskCard(44, player);
                                }
                            }
                        }
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            //pravila3:;
            ////////////////////////////////////////////pravila//////////////////////////////////////////////////
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                return this.MinCard(player);
            }
        }
        //label4////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 3)
        ) {
            if (vziatka === true) {
                if (br[boia] === 2) {
                    if (this.CheckVlastnaKartaAfter(this.CheckNGCard(player, boia)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (this.CheckNMCard(player, boia) > this.VtoriPuska && this.CheckVlastnaKartaAfter(this.CheckNGCard(player, boia)) === true) {
                        return this.PuskCard(this.CheckNMCard(player, boia), player);
                    }
                }
                if (this.hand === 6 && br[boia] === 0) {
                    if (br[1] === 2 && brob[1] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                    }
                    if (br[2] === 2 && brob[2] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                    }
                    if (br[3] === 2 && brob[3] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                    }
                    if (br[4] === 2 && brob[4] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                    }
                }
            }
            if (vziatka === false) {
                if (br[boia] > 1) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, boia)) === true) {
                        if (this.TretiPuska > boia * 10 && this.TretiPuska < (boia + 1) * 10) {
                            if (
                                this.k[this.PuskCard(this.CheckNGCard(player, boia), player) - 1] > this.ParviPuska &&
                                this.k[this.PuskCard(this.CheckNGCard(player, boia), player) - 1] > this.TretiPuska &&
                                this.k[this.PuskCard(this.CheckNGCard(player, boia), player) - 1] < this.CheckNGCard(player, boia) - 1
                            ) {
                                return this.PuskCard(this.CheckNGCard(player, boia), player) - 1;
                            }
                        } else {
                            if (
                                this.k[this.PuskCard(this.CheckNGCard(player, boia), player) - 1] > this.ParviPuska &&
                                this.k[this.PuskCard(this.CheckNGCard(player, boia), player) - 1] < this.CheckNGCard(player, boia) - 1
                            ) {
                                return this.PuskCard(this.CheckNGCard(player, boia), player) - 1;
                            }
                        }
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                if (vziatka === false) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                if (vziatka === false) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                if (vziatka === false) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                if (vziatka === false) {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            //pravila4:;
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                return this.MinCard(player);
            }
        }
        return this.MinCard(player);
    }

    IntelectVs(player: number) {
        let h: number;
        let pusk: number;
        let tac: number = 0;
        let boia: number;
        let partner: number[] = [];
        if (this.KoiENaRed !== this.KoiPuskaParvi) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                boia = 1;
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                boia = 2;
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                boia = 3;
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                boia = 4;
            }
        }
        if (this.KoiENaRed === 2) {
            partner[2] = 3;
            partner[3] = 4;
            partner[4] = 1;
        }
        if (this.KoiENaRed === 3) {
            partner[2] = 4;
            partner[3] = 1;
            partner[4] = 2;
        }
        if (this.KoiENaRed === 4) {
            partner[2] = 1;
            partner[3] = 2;
            partner[4] = 3;
        }
        let br: number[] = [];
        let brob: number[] = [];
        br[1] = this.GetBrojKarti(1, player);
        br[2] = this.GetBrojKarti(2, player);
        br[3] = this.GetBrojKarti(3, player);
        br[4] = this.GetBrojKarti(4, player);
        brob[1] = this.GetBrojKarti(1, 0);
        brob[2] = this.GetBrojKarti(2, 0);
        brob[3] = this.GetBrojKarti(3, 0);
        brob[4] = this.GetBrojKarti(4, 0);
        if (this.hand === 0) {
            this.BrBoiaNach[player][1] = br[1];
            this.BrBoiaNach[player][2] = br[2];
            this.BrBoiaNach[player][3] = br[3];
            this.BrBoiaNach[player][4] = br[4];
        }
        let vziatka: boolean = this.Vziatka();
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 4)
        ) {
            /////////
            if (this.Pkazal[partner[3]][6] === true && this.hand > 3 && this.RandNumber(100) > this.hand * 18) {
                this.Tactic[player] = 1;
            }
            if (this.Pkazal[partner[3]][6] !== true && this.hand > 3) {
                this.Tactic[player] = 1;
            }
            if (this.Tactic[player] === 0 && this.hand < 3) {
                tac = tac + this.hand * 25;
                if (this.hand < 3) {
                    if (this.CheckCard3(18, player, true) === true && this.CheckCard3(17, player, true) === true) {
                        tac = tac + br[1] * 16;
                    }
                    if (this.CheckCard(18) === false && this.CheckCard3(17, player, true) === true) {
                        tac = tac + br[1] * 16;
                    }
                    if (this.CheckCard3(18, player, true) === true && this.CheckCard3(16, player, true) === true) {
                        tac = tac + br[1] * 10;
                    }
                    if (this.CheckCard3(28, player, true) === true && this.CheckCard3(27, player, true) === true) {
                        tac = tac + br[2] * 16;
                    }
                    if (this.CheckCard(28) === false && this.CheckCard3(27, player, true) === true) {
                        tac = tac + br[2] * 16;
                    }
                    if (this.CheckCard3(28, player, true) === true && this.CheckCard3(26, player, true) === true) {
                        tac = tac + br[2] * 10;
                    }
                    if (this.CheckCard3(38, player, true) === true && this.CheckCard3(37, player, true) === true) {
                        tac = tac + br[3] * 16;
                    }
                    if (this.CheckCard(38) === false && this.CheckCard3(37, player, true) === true) {
                        tac = tac + br[3] * 16;
                    }
                    if (this.CheckCard3(38, player, true) === true && this.CheckCard3(36, player, true) === true) {
                        tac = tac + br[3] * 10;
                    }
                    if (this.CheckCard3(48, player, true) === true && this.CheckCard3(47, player, true) === true) {
                        tac = tac + br[4] * 16;
                    }
                    if (this.CheckCard(48) === false && this.CheckCard3(47, player, true) === true) {
                        tac = tac + br[4] * 16;
                    }
                    if (this.CheckCard3(48, player, true) === true && this.CheckCard3(46, player, true) === true) {
                        tac = tac + br[4] * 10;
                    }
                    if (this.Pkazal[partner[3]][6] === true) {
                        tac = tac - 20;
                    }
                    if (this.Pkazal[partner[2]][6] === true) {
                        tac = tac + 20;
                    }
                    if (this.Pkazal[partner[4]][6] === true) {
                        tac = tac + 20;
                    }
                    if (this.CheckCard3(18, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(17, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(16, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(15, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(14, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(13, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(12, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(11, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(28, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(27, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(26, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(25, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(24, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(23, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(22, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(21, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(38, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(37, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(36, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(35, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(34, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(33, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(32, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(31, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (this.CheckCard3(48, player, true) === true) {
                        tac = tac + 15;
                        if (this.CheckCard3(47, player, true) === true) {
                            tac = tac + 15;
                            if (this.CheckCard3(46, player, true) === true) {
                                tac = tac + 15;
                                if (this.CheckCard3(45, player, true) === true) {
                                    tac = tac + 15;
                                    if (this.CheckCard3(44, player, true) === true) {
                                        tac = tac + 15;
                                        if (this.CheckCard3(43, player, true) === true) {
                                            tac = tac + 15;
                                            if (this.CheckCard3(42, player, true) === true) {
                                                tac = tac + 15;
                                                if (this.CheckCard3(41, player, true) === true) {
                                                    tac = tac + 15;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.RandNumber(100) < tac) {
                    this.Tactic[player] = 1;
                } else {
                    this.Tactic[player] = 2;
                }
            }
            /////////
            if (this.Tactic[player] === 1) {
                if (
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][1] >= this.BrBoiaNach[player][4] &&
                    br[1] > 0 &&
                    this.Pkazal[partner[2]][1] !== true &&
                    this.Pkazal[partner[4]][1] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 1)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][1] &&
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][2] >= this.BrBoiaNach[player][4] &&
                    br[2] > 0 &&
                    this.Pkazal[partner[2]][2] !== true &&
                    this.Pkazal[partner[4]][2] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 2)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][1] &&
                    this.BrBoiaNach[player][3] >= this.BrBoiaNach[player][4] &&
                    br[3] > 0 &&
                    this.Pkazal[partner[2]][3] !== true &&
                    this.Pkazal[partner[4]][3] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 3)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                    }
                }
                if (
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][2] &&
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][3] &&
                    this.BrBoiaNach[player][4] >= this.BrBoiaNach[player][1] &&
                    br[4] > 0 &&
                    this.Pkazal[partner[2]][4] !== true &&
                    this.Pkazal[partner[4]][4] !== true
                ) {
                    if (this.CheckVlastnaKarta(this.CheckNGCard(player, 4)) === true) {
                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                    }
                }
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.CheckVlastnaKarta(this.k[h]) === true) {
                        return h;
                    }
                }
                this.Tactic[player] = 2;
            }
            if (this.Tactic[player] === 2) {
                if (this.Piska[partner[3]][1] === true && br[1] > 0 && (this.CheckNGCard(player, 1) !== 17 || this.Pkazal[player][1] === true)) {
                    return this.PuskCard(this.CheckNGCard(player, 1), player);
                }
                if (this.Piska[partner[3]][2] === true && br[2] > 0 && (this.CheckNGCard(player, 2) !== 27 || this.Pkazal[player][2] === true)) {
                    return this.PuskCard(this.CheckNGCard(player, 2), player);
                }
                if (this.Piska[partner[3]][3] === true && br[3] > 0 && (this.CheckNGCard(player, 3) !== 37 || this.Pkazal[player][3] === true)) {
                    return this.PuskCard(this.CheckNGCard(player, 3), player);
                }
                if (this.Piska[partner[3]][4] === true && br[4] > 0 && (this.CheckNGCard(player, 4) !== 47 || this.Pkazal[player][4] === true)) {
                    return this.PuskCard(this.CheckNGCard(player, 4), player);
                }

                if (this.hand > 2) {
                    this.Tactic[player] = 1;
                }
                let izb = this.CheckIzbivane(player, partner[3]);
                if (izb > 0) {
                    return izb;
                }
                this.Tactic[player] = 1;
                for (h = 1 + (player - 1) * 8; h < 9 - this.hand + (player - 1) * 8; h++) {
                    if (this.CheckVlastnaKarta(this.k[h]) === true) {
                        return h;
                    }
                }
            }
            /////////

            pusk = this.PuskIskanaKarta(player, partner[2], partner[3], partner[4]);
            if (pusk > 0) {
                return pusk;
            }
            pusk = this.PuskDrBMinCard(player);
            if (pusk > 0) {
                return pusk;
            }
            return this.MinCard(player);
        }
        /////////////////////////////////////////label2/////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 2)
        ) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 10 && this.k[i] < 20 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 20 && this.k[i] < 30 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 30 && this.k[i] < 40 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 40 && this.k[i] < 50 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            }
            if (this.Pkazal[partner[4]][this.Naddavane] === true && br[boia] === 2 && this.RandNumber(100) > 40) {
                if (boia === 1 && this.CheckCard3(17, player, true) === true && this.CheckCard3(18, partner[4], true) === true) {
                    return this.PuskCard(17, player);
                }
                if (boia === 2 && this.CheckCard3(27, player, true) === true && this.CheckCard3(28, partner[4], true) === true) {
                    return this.PuskCard(27, player);
                }
                if (boia === 3 && this.CheckCard3(37, player, true) === true && this.CheckCard3(38, partner[4], true) === true) {
                    return this.PuskCard(37, player);
                }
                if (boia === 4 && this.CheckCard3(47, player, true) === true && this.CheckCard3(48, partner[4], true) === true) {
                    return this.PuskCard(47, player);
                }
            }
            ////////////////////////////////////pravila2//////////////////////////////////////////////////////////
            //pravila2:;
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] < 20) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] < 30) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] < 40) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] < 50) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                return this.MinCard(player);
            }
        }
        //label3////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 3)
        ) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 10 && this.k[i] < 20 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 20 && this.k[i] < 30 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 30 && this.k[i] < 40 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                    if (this.k[i] > 40 && this.k[i] < 50 && this.CheckVlastnaKarta(this.k[i]) === true) {
                        return i;
                    }
                }
            }
            if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                if (br[boia] === 0) {
                    if (br[1] === 8 - this.hand) {
                        if (br[1] === brob[1] || (this.VtoriPuska > 10 && this.VtoriPuska < 20 && br[1] === brob[1] - 1)) {
                            if (br[1] > 0) {
                                return this.PuskCard(this.CheckNGCard(player, 1), player);
                            }
                        }
                    }
                    if (br[2] === 8 - this.hand) {
                        if (br[2] === brob[2] || (this.VtoriPuska > 20 && this.VtoriPuska < 30 && br[1] === brob[2] - 1)) {
                            if (br[2] > 0) {
                                return this.PuskCard(this.CheckNGCard(player, 2), player);
                            }
                        }
                    }
                    if (br[3] === 8 - this.hand) {
                        if (br[3] === brob[3] || (this.VtoriPuska > 30 && this.VtoriPuska < 40 && br[1] === brob[3] - 1)) {
                            if (br[3] > 0) {
                                return this.PuskCard(this.CheckNGCard(player, 3), player);
                            }
                        }
                    }
                    if (br[1] === 8 - this.hand) {
                        if (br[4] === brob[4] || (this.VtoriPuska > 40 && this.VtoriPuska < 50 && br[1] === brob[4] - 1)) {
                            if (br[4] > 0) {
                                return this.PuskCard(this.CheckNGCard(player, 4), player);
                            }
                        }
                    }
                }
                if (br[boia] === 2) {
                    if (this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 1) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (
                        this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 2 &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                    ) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (
                        this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 3 &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                    ) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (
                        this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 4 &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                    ) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (
                        this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 5 &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 4) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                    ) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (
                        this.CheckNMCard(player, boia) === this.CheckNGCard(player, boia) - 6 &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 5) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 4) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 3) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 2) === false &&
                        this.CheckCard(this.CheckNGCard(player, boia) - 1) === false
                    ) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    }
                    if (this.CheckVlastnaKartaAfter(this.CheckNGCard(player, boia)) === false) {
                        return this.PuskCard(this.CheckNGCard(player, boia), player);
                    } else {
                        return this.PuskCard(this.CheckNMCard(player, boia), player);
                    }
                }
                if (this.hand === 6 && br[boia] === 0) {
                    if (br[1] === 2 && brob[1] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                    }
                    if (br[2] === 2 && brob[2] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                    }
                    if (br[3] === 2 && brob[3] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                    }
                    if (br[4] === 2 && brob[4] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                    }
                }
                if (br[boia] > 1) {
                    if (boia === 1 && this.CheckCard3(13, player, true) === true && this.CheckCard3(14, player, true) === true) {
                        return this.PuskCard(14, player);
                    }
                    if (boia === 2 && this.CheckCard3(23, player, true) === true && this.CheckCard3(24, player, true) === true) {
                        return this.PuskCard(24, player);
                    }
                    if (boia === 3 && this.CheckCard3(33, player, true) === true && this.CheckCard3(34, player, true) === true) {
                        return this.PuskCard(34, player);
                    }
                    if (boia === 4 && this.CheckCard3(43, player, true) === true && this.CheckCard3(44, player, true) === true) {
                        return this.PuskCard(44, player);
                    }
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
                this.Pniama[player][1] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
                this.Pniama[player][2] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
                this.Pniama[player][3] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
                this.Pniama[player][4] = true;
                if (this.CheckVlastnaKarta(this.ParviPuska) === true) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                } else {
                    pusk = this.PuskDrBMinCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
            }
            /////////////////////////////////////////pravila3/////////////////////////////////////////////////////
            //pravila3:;
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.VtoriPuska > 10 && this.VtoriPuska < 20) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 20) {
                            return i;
                        }
                    }
                } else {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] < 20) {
                            return i;
                        }
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
                this.Pniama[player][1] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.VtoriPuska > 20 && this.VtoriPuska < 30) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 30) {
                            return i;
                        }
                    }
                } else {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] < 30) {
                            return i;
                        }
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
                this.Pniama[player][2] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.VtoriPuska > 30 && this.VtoriPuska < 40) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 40) {
                            return i;
                        }
                    }
                } else {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] < 40) {
                            return i;
                        }
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
                this.Pniama[player][3] = true;
                return this.MinCard(player);
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.VtoriPuska > 40 && this.VtoriPuska < 50) {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 50) {
                            return i;
                        }
                    }
                } else {
                    for (
                        let i = 1 + (player - 1) * 8;
                        i < 9 - this.hand + (player - 1) * 8;
                        i++ //pravila
                    ) {
                        if (this.k[i] > this.ParviPuska && this.k[i] < 50) {
                            return i;
                        }
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
                this.Pniama[player][4] = true;
                return this.MinCard(player);
            }
        }
        //label4////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 3)
        ) {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (vziatka === true) {
                if (this.hand === 6 && br[boia] === 0) {
                    if (br[1] === 2 && brob[1] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 1), player);
                    }
                    if (br[2] === 2 && brob[2] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 2), player);
                    }
                    if (br[3] === 2 && brob[3] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 3), player);
                    }
                    if (br[4] === 2 && brob[4] < 4) {
                        return this.PuskCard(this.CheckNGCard(player, 4), player);
                    }
                }
                if (br[boia] === 0) {
                    pusk = this.PuskDrBMaxCard(player);
                    if (pusk > 0) {
                        return pusk;
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                    if (br[1] === 2) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (
                                this.CheckVlastnaKartaAfter(this.k[i]) === true &&
                                this.k[i] > 10 &&
                                this.k[i] < 20 &&
                                this.Pkazal[partner[3]][1] === false
                            ) {
                                return this.Pravila4Vs(player, vziatka, br);
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.VtoriPuska === 18) {
                        if (this.Pkazal[partner[3]][1] === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 15) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 14) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 13) {
                                    return i;
                                }
                            }
                        }
                        if (this.Pkazal[partner[2]][1] === true || this.Pkazal[partner[4]][1] === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.CheckVlastnaKartaAfter(this.k[i]) === true && this.k[i] > 10 && this.k[i] < 20) {
                                    return this.Pravila4Vs(player, vziatka, br);
                                }
                            }
                        }
                        if (
                            ((this.CheckCard3(17, player, true) === true && this.CheckCard3(16, player, true) === true) || brob[1] - br[1] < 2) &&
                            this.Pkazal[partner[2]][1] === false &&
                            this.Pkazal[partner[4]][1] === false
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 17) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 16) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 15) {
                        if (this.CheckCard3(18, player, true) === true && this.CheckCard3(17, player, true) === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 18) {
                                    return i;
                                }
                            }
                        }
                        if (
                            this.CheckCard3(18, player, true) === true &&
                            this.CheckCard3(17, player, true) === false &&
                            this.CheckCard(17) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 18) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 14) {
                        if (this.CheckCard3(18, player, true) === true && this.CheckCard3(17, player, true) === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 18) {
                                    return i;
                                }
                            }
                        }
                        if (
                            this.CheckCard3(18, player, true) === true &&
                            this.CheckCard(17) === false &&
                            this.CheckCard3(16, player, true) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 18) {
                                    return i;
                                }
                            }
                        }
                        if (
                            this.CheckCard(18) === false &&
                            this.CheckCard3(17, player, true) === true &&
                            this.CheckCard3(16, player, true) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                        }
                        if (
                            this.CheckCard3(18, player, true) === true &&
                            this.CheckCard3(17, player, true) === false &&
                            this.CheckCard(17) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 18) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 13) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 13) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 12) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 11) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 16) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 15) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 14) {
                                return i;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                    if (br[2] === 2) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (
                                this.CheckVlastnaKartaAfter(this.k[i]) === true &&
                                this.k[i] > 20 &&
                                this.k[i] < 30 &&
                                this.Pkazal[partner[3]][2] === false
                            ) {
                                return this.Pravila4Vs(player, vziatka, br);
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.VtoriPuska === 28) {
                        if (this.Pkazal[partner[3]][2] === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                        }
                        if (
                            ((this.CheckCard3(27, player, true) === true && this.CheckCard3(26, player, true) === true) || brob[2] - br[2] < 2) &&
                            this.Pkazal[partner[2]][2] === false &&
                            this.Pkazal[partner[4]][2] === false
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 27) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 26) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 25) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 24) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 23) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 23) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 22) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 21) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 26) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 25) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 24) {
                                return i;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                    if (br[3] === 2) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (
                                this.CheckVlastnaKartaAfter(this.k[i]) === true &&
                                this.k[i] > 30 &&
                                this.k[i] < 40 &&
                                this.Pkazal[partner[3]][3] === false
                            ) {
                                return this.Pravila4Vs(player, vziatka, br);
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.VtoriPuska === 38) {
                        if (this.Pkazal[partner[3]][3] === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                        }
                        if (
                            ((this.CheckCard3(37, player, true) === true && this.CheckCard3(36, player, true) === true) || brob[3] - br[3] < 2) &&
                            this.Pkazal[partner[2]][3] === false &&
                            this.Pkazal[partner[4]][3] === false
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 37) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 36) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 35) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 34) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 33) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 33) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 32) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 31) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 36) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 35) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 34) {
                                return i;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                    if (br[4] === 2) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (
                                this.CheckVlastnaKartaAfter(this.k[i]) === true &&
                                this.k[i] > 40 &&
                                this.k[i] < 50 &&
                                this.Pkazal[partner[3]][4] === false
                            ) {
                                return this.Pravila4Vs(player, vziatka, br);
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.VtoriPuska === 48) {
                        if (this.Pkazal[partner[3]][4] === true) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                        }
                        if (
                            ((this.CheckCard3(47, player, true) === true && this.CheckCard3(46, player, true) === true) || brob[4] - br[4] < 2) &&
                            this.Pkazal[partner[2]][4] === false &&
                            this.Pkazal[partner[4]][4] === false
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 47) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 46) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 45) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 44) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 43) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 43) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 42) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                    }
                    if (this.VtoriPuska === 41) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 46) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 45) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 44) {
                                return i;
                            }
                        }
                    }
                }
            } else {
                if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] > 1) {
                    if (this.ParviPuska === 18 || this.TretiPuska === 18) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 17 || this.TretiPuska === 17) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 16 || this.TretiPuska === 16) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 18) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 17) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 15 || this.TretiPuska === 15) {
                        if (
                            this.CheckCard3(18, player, true) === false &&
                            this.CheckCard3(16, player, true) === true &&
                            this.CheckCard3(17, player, true) === true &&
                            this.CheckCard(18) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 14 || this.TretiPuska === 14) {
                        if (this.CheckCard3(18, player, true) === false && this.CheckCard(18) === true && (br[1] === 2 || brob[1] < 5)) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 13 || this.TretiPuska === 13) {
                        if (
                            ((this.CheckCard3(18, player, true) === false && this.CheckCard(18) === true) ||
                                (this.CheckCard3(17, player, true) === false && this.CheckVlastnaKarta(17) === true)) &&
                            (br[1] === 2 || brob[1] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 15) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 12 || this.TretiPuska === 12) {
                        if (
                            ((this.CheckCard3(18, player, true) === false && this.CheckCard(18) === true) ||
                                (this.CheckCard3(17, player, true) === false && this.CheckVlastnaKarta(17) === true) ||
                                (this.CheckCard3(16, player, true) === false && this.CheckVlastnaKarta(16) === true)) &&
                            (br[1] === 2 || brob[1] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 15) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 14) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 11 || this.TretiPuska === 11) {
                        if (
                            ((this.CheckCard3(18, player, true) === false && this.CheckCard(18) === true) ||
                                (this.CheckCard3(17, player, true) === false && this.CheckVlastnaKarta(17) === true) ||
                                (this.CheckCard3(16, player, true) === false && this.CheckVlastnaKarta(16) === true) ||
                                (this.CheckCard3(15, player, true) === false && this.CheckVlastnaKarta(15) === true)) &&
                            (br[1] === 2 || brob[1] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 17) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 16) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 15) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 14) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 13) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] > 1) {
                    if (this.ParviPuska === 28 || this.TretiPuska === 28) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 27 || this.TretiPuska === 27) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 26 || this.TretiPuska === 26) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 28) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 27) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 25 || this.TretiPuska === 25) {
                        if (
                            this.CheckCard3(28, player, true) === false &&
                            this.CheckCard3(26, player, true) === true &&
                            this.CheckCard3(27, player, true) === true &&
                            this.CheckCard(28) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 24 || this.TretiPuska === 24) {
                        if (this.CheckCard3(28, player, true) === false && this.CheckCard(28) === true && (br[2] === 2 || brob[2] < 5)) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 26) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 23 || this.TretiPuska === 23) {
                        if (
                            ((this.CheckCard3(28, player, true) === false && this.CheckCard(28) === true) ||
                                (this.CheckCard3(27, player, true) === false && this.CheckVlastnaKarta(27) === true)) &&
                            (br[2] === 2 || brob[2] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 26) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 25) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 22 || this.TretiPuska === 22) {
                        if (
                            ((this.CheckCard3(28, player, true) === false && this.CheckCard(28) === true) ||
                                (this.CheckCard3(27, player, true) === false && this.CheckVlastnaKarta(27) === true) ||
                                (this.CheckCard3(26, player, true) === false && this.CheckVlastnaKarta(26) === true)) &&
                            (br[2] === 2 || brob[2] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 26) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 25) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 24) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 21 || this.TretiPuska === 21) {
                        if (
                            ((this.CheckCard3(28, player, true) === false && this.CheckCard(28) === true) ||
                                (this.CheckCard3(27, player, true) === false && this.CheckVlastnaKarta(27) === true) ||
                                (this.CheckCard3(26, player, true) === false && this.CheckVlastnaKarta(26) === true) ||
                                (this.CheckCard3(25, player, true) === false && this.CheckVlastnaKarta(25) === true)) &&
                            (br[2] === 2 || brob[2] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 27) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 26) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 25) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 24) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 23) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] > 1) {
                    if (this.ParviPuska === 38 || this.TretiPuska === 38) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 37 || this.TretiPuska === 37) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 36 || this.TretiPuska === 36) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 38) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 37) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 35 || this.TretiPuska === 35) {
                        if (
                            this.CheckCard3(38, player, true) === false &&
                            this.CheckCard3(36, player, true) === true &&
                            this.CheckCard3(37, player, true) === true &&
                            this.CheckCard(38) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 34 || this.TretiPuska === 34) {
                        if (this.CheckCard3(38, player, true) === false && this.CheckCard(38) === true && (br[3] === 2 || brob[3] < 5)) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 36) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 33 || this.TretiPuska === 33) {
                        if (
                            ((this.CheckCard3(38, player, true) === false && this.CheckCard(38) === true) ||
                                (this.CheckCard3(37, player, true) === false && this.CheckVlastnaKarta(37) === true)) &&
                            (br[3] === 2 || brob[3] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 36) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 35) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 32 || this.TretiPuska === 32) {
                        if (
                            ((this.CheckCard3(38, player, true) === false && this.CheckCard(38) === true) ||
                                (this.CheckCard3(37, player, true) === false && this.CheckVlastnaKarta(37) === true) ||
                                (this.CheckCard3(36, player, true) === false && this.CheckVlastnaKarta(36) === true)) &&
                            (br[3] === 2 || brob[3] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 36) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 35) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 34) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 31 || this.TretiPuska === 31) {
                        if (
                            ((this.CheckCard3(38, player, true) === false && this.CheckCard(38) === true) ||
                                (this.CheckCard3(37, player, true) === false && this.CheckVlastnaKarta(37) === true) ||
                                (this.CheckCard3(36, player, true) === false && this.CheckVlastnaKarta(36) === true) ||
                                (this.CheckCard3(35, player, true) === false && this.CheckVlastnaKarta(35) === true)) &&
                            (br[3] === 2 || brob[3] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 37) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 36) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 35) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 34) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 33) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] > 1) {
                    if (this.ParviPuska === 48 || this.TretiPuska === 48) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 47 || this.TretiPuska === 47) {
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 46 || this.TretiPuska === 46) {
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 48) {
                                return i;
                            }
                        }
                        for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                            if (this.k[i] === 47) {
                                return i;
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 45 || this.TretiPuska === 45) {
                        if (
                            this.CheckCard3(48, player, true) === false &&
                            this.CheckCard3(46, player, true) === true &&
                            this.CheckCard3(47, player, true) === true &&
                            this.CheckCard(48) === true
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 44 || this.TretiPuska === 44) {
                        if (this.CheckCard3(48, player, true) === false && this.CheckCard(48) === true && (br[4] === 2 || brob[4] < 5)) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 46) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 43 || this.TretiPuska === 43) {
                        if (
                            ((this.CheckCard3(48, player, true) === false && this.CheckCard(48) === true) ||
                                (this.CheckCard3(47, player, true) === false && this.CheckVlastnaKarta(47) === true)) &&
                            (br[4] === 2 || brob[4] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 46) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 45) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 42 || this.TretiPuska === 42) {
                        if (
                            ((this.CheckCard3(48, player, true) === false && this.CheckCard(48) === true) ||
                                (this.CheckCard3(47, player, true) === false && this.CheckVlastnaKarta(47) === true) ||
                                (this.CheckCard3(46, player, true) === false && this.CheckVlastnaKarta(46) === true)) &&
                            (br[4] === 2 || brob[4] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 46) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 45) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 44) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                    if (this.ParviPuska === 41 || this.TretiPuska === 41) {
                        if (
                            ((this.CheckCard3(48, player, true) === false && this.CheckCard(48) === true) ||
                                (this.CheckCard3(47, player, true) === false && this.CheckVlastnaKarta(47) === true) ||
                                (this.CheckCard3(46, player, true) === false && this.CheckVlastnaKarta(46) === true) ||
                                (this.CheckCard3(45, player, true) === false && this.CheckVlastnaKarta(45) === true)) &&
                            (br[4] === 2 || brob[4] < 5)
                        ) {
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 47) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 46) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 45) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 44) {
                                    return i;
                                }
                            }
                            for (let i = 1 + (player - 1) * 8; i < 9 - this.hand + (player - 1) * 8; i++) {
                                if (this.k[i] === 43) {
                                    return i;
                                }
                            }
                        }
                        return this.Pravila4Vs(player, vziatka, br);
                    }
                }
            }
            //////////////////////////////////pravila////////////////////////////////////////////////////////////////////////////
            return this.Pravila4Vs(player, vziatka, br);
        }

        return this.MinCard(player);
    }

    Pravila4Vs(player: number, vziatka: boolean, br: number[]) {
        let pusk: number;
        if (this.ParviPuska > 10 && this.ParviPuska < 20 && br[1] === 0) {
            this.Pniama[player][1] = true;
            if (vziatka === false) {
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            } else {
                if (br[2] === 1 && this.CheckCard3(27, player, true) === true && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(27, player);
                }
                if (br[3] === 1 && this.CheckCard3(37, player, true) === true && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(37, player);
                }
                if (br[4] === 1 && this.CheckCard3(47, player, true) === true && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(47, player);
                }

                if (br[2] === 1 && this.CheckCard3(26, player, true) === true && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(26, player);
                }
                if (br[3] === 1 && this.CheckCard3(36, player, true) === true && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(36, player);
                }
                if (br[4] === 1 && this.CheckCard3(46, player, true) === true && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(46, player);
                }

                if (br[2] === 1 && this.CheckCard3(25, player, true) === true && this.CheckVlastnaKarta(25) === false) {
                    return this.PuskCard(25, player);
                }
                if (br[3] === 1 && this.CheckCard3(35, player, true) === true && this.CheckVlastnaKarta(35) === false) {
                    return this.PuskCard(35, player);
                }
                if (br[4] === 1 && this.CheckCard3(45, player, true) === true && this.CheckVlastnaKarta(45) === false) {
                    return this.PuskCard(45, player);
                }

                if (br[2] === 1 && this.CheckCard3(24, player, true) === true && this.CheckVlastnaKarta(24) === false) {
                    return this.PuskCard(24, player);
                }
                if (br[3] === 1 && this.CheckCard3(34, player, true) === true && this.CheckVlastnaKarta(34) === false) {
                    return this.PuskCard(34, player);
                }
                if (br[4] === 1 && this.CheckCard3(44, player, true) === true && this.CheckVlastnaKarta(44) === false) {
                    return this.PuskCard(44, player);
                }

                if (br[2] === 1 && this.CheckCard3(23, player, true) === true && this.CheckVlastnaKarta(23) === false) {
                    return this.PuskCard(23, player);
                }
                if (br[3] === 1 && this.CheckCard3(33, player, true) === true && this.CheckVlastnaKarta(33) === false) {
                    return this.PuskCard(33, player);
                }
                if (br[4] === 1 && this.CheckCard3(43, player, true) === true && this.CheckVlastnaKarta(43) === false) {
                    return this.PuskCard(43, player);
                }
            }
        }
        if (this.ParviPuska > 20 && this.ParviPuska < 30 && br[2] === 0) {
            this.Pniama[player][2] = true;
            if (vziatka === false) {
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            } else {
                if (br[1] === 1 && this.CheckCard3(17, player, true) === true && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(17, player);
                }
                if (br[3] === 1 && this.CheckCard3(37, player, true) === true && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(37, player);
                }
                if (br[4] === 1 && this.CheckCard3(47, player, true) === true && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(47, player);
                }

                if (br[1] === 1 && this.CheckCard3(16, player, true) === true && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(16, player);
                }
                if (br[3] === 1 && this.CheckCard3(36, player, true) === true && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(36, player);
                }
                if (br[4] === 1 && this.CheckCard3(46, player, true) === true && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(46, player);
                }

                if (br[1] === 1 && this.CheckCard3(15, player, true) === true && this.CheckVlastnaKarta(15) === false) {
                    return this.PuskCard(15, player);
                }
                if (br[3] === 1 && this.CheckCard3(35, player, true) === true && this.CheckVlastnaKarta(35) === false) {
                    return this.PuskCard(35, player);
                }
                if (br[4] === 1 && this.CheckCard3(45, player, true) === true && this.CheckVlastnaKarta(45) === false) {
                    return this.PuskCard(45, player);
                }

                if (br[1] === 1 && this.CheckCard3(14, player, true) === true && this.CheckVlastnaKarta(14) === false) {
                    return this.PuskCard(14, player);
                }
                if (br[3] === 1 && this.CheckCard3(34, player, true) === true && this.CheckVlastnaKarta(34) === false) {
                    return this.PuskCard(34, player);
                }
                if (br[4] === 1 && this.CheckCard3(44, player, true) === true && this.CheckVlastnaKarta(44) === false) {
                    return this.PuskCard(44, player);
                }

                if (br[1] === 1 && this.CheckCard3(13, player, true) === true && this.CheckVlastnaKarta(13) === false) {
                    return this.PuskCard(13, player);
                }
                if (br[3] === 1 && this.CheckCard3(33, player, true) === true && this.CheckVlastnaKarta(33) === false) {
                    return this.PuskCard(33, player);
                }
                if (br[4] === 1 && this.CheckCard3(43, player, true) === true && this.CheckVlastnaKarta(43) === false) {
                    return this.PuskCard(43, player);
                }
            }
        }
        if (this.ParviPuska > 30 && this.ParviPuska < 40 && br[3] === 0) {
            this.Pniama[player][3] = true;
            if (vziatka === false) {
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            } else {
                if (br[1] === 1 && this.CheckCard3(17, player, true) === true && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(17, player);
                }
                if (br[2] === 1 && this.CheckCard3(27, player, true) === true && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(27, player);
                }
                if (br[4] === 1 && this.CheckCard3(47, player, true) === true && this.CheckVlastnaKarta(47) === false) {
                    return this.PuskCard(47, player);
                }

                if (br[1] === 1 && this.CheckCard3(16, player, true) === true && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(16, player);
                }
                if (br[2] === 1 && this.CheckCard3(26, player, true) === true && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(26, player);
                }
                if (br[4] === 1 && this.CheckCard3(46, player, true) === true && this.CheckVlastnaKarta(46) === false) {
                    return this.PuskCard(46, player);
                }

                if (br[1] === 1 && this.CheckCard3(15, player, true) === true && this.CheckVlastnaKarta(15) === false) {
                    return this.PuskCard(15, player);
                }
                if (br[2] === 1 && this.CheckCard3(25, player, true) === true && this.CheckVlastnaKarta(25) === false) {
                    return this.PuskCard(25, player);
                }
                if (br[4] === 1 && this.CheckCard3(45, player, true) === true && this.CheckVlastnaKarta(45) === false) {
                    return this.PuskCard(45, player);
                }

                if (br[1] === 1 && this.CheckCard3(14, player, true) === true && this.CheckVlastnaKarta(14) === false) {
                    return this.PuskCard(14, player);
                }
                if (br[2] === 1 && this.CheckCard3(24, player, true) === true && this.CheckVlastnaKarta(24) === false) {
                    return this.PuskCard(24, player);
                }
                if (br[4] === 1 && this.CheckCard3(44, player, true) === true && this.CheckVlastnaKarta(44) === false) {
                    return this.PuskCard(44, player);
                }

                if (br[1] === 1 && this.CheckCard3(13, player, true) === true && this.CheckVlastnaKarta(13) === false) {
                    return this.PuskCard(13, player);
                }
                if (br[2] === 1 && this.CheckCard3(23, player, true) === true && this.CheckVlastnaKarta(23) === false) {
                    return this.PuskCard(23, player);
                }
                if (br[4] === 1 && this.CheckCard3(43, player, true) === true && this.CheckVlastnaKarta(43) === false) {
                    return this.PuskCard(43, player);
                }
            }
        }
        if (this.ParviPuska > 40 && this.ParviPuska < 50 && br[4] === 0) {
            this.Pniama[player][4] = true;
            if (vziatka === false) {
                pusk = this.PuskDrBMinCard(player);
                if (pusk > 0) {
                    return pusk;
                }
            } else {
                if (br[1] === 1 && this.CheckCard3(17, player, true) === true && this.CheckVlastnaKarta(17) === false) {
                    return this.PuskCard(17, player);
                }
                if (br[2] === 1 && this.CheckCard3(27, player, true) === true && this.CheckVlastnaKarta(27) === false) {
                    return this.PuskCard(27, player);
                }
                if (br[3] === 1 && this.CheckCard3(37, player, true) === true && this.CheckVlastnaKarta(37) === false) {
                    return this.PuskCard(37, player);
                }
                if (br[1] === 1 && this.CheckCard3(16, player, true) === true && this.CheckVlastnaKarta(16) === false) {
                    return this.PuskCard(16, player);
                }
                if (br[2] === 1 && this.CheckCard3(26, player, true) === true && this.CheckVlastnaKarta(26) === false) {
                    return this.PuskCard(26, player);
                }
                if (br[3] === 1 && this.CheckCard3(36, player, true) === true && this.CheckVlastnaKarta(36) === false) {
                    return this.PuskCard(36, player);
                }
                if (br[1] === 1 && this.CheckCard3(15, player, true) === true && this.CheckVlastnaKarta(15) === false) {
                    return this.PuskCard(15, player);
                }
                if (br[2] === 1 && this.CheckCard3(25, player, true) === true && this.CheckVlastnaKarta(25) === false) {
                    return this.PuskCard(25, player);
                }
                if (br[3] === 1 && this.CheckCard3(35, player, true) === true && this.CheckVlastnaKarta(35) === false) {
                    return this.PuskCard(35, player);
                }
                if (br[1] === 1 && this.CheckCard3(14, player, true) === true && this.CheckVlastnaKarta(14) === false) {
                    return this.PuskCard(14, player);
                }
                if (br[2] === 1 && this.CheckCard3(24, player, true) === true && this.CheckVlastnaKarta(24) === false) {
                    return this.PuskCard(24, player);
                }
                if (br[3] === 1 && this.CheckCard3(34, player, true) === true && this.CheckVlastnaKarta(34) === false) {
                    return this.PuskCard(34, player);
                }
                if (br[1] === 1 && this.CheckCard3(13, player, true) === true && this.CheckVlastnaKarta(13) === false) {
                    return this.PuskCard(13, player);
                }
                if (br[2] === 1 && this.CheckCard3(23, player, true) === true && this.CheckVlastnaKarta(23) === false) {
                    return this.PuskCard(23, player);
                }
                if (br[3] === 1 && this.CheckCard3(33, player, true) === true && this.CheckVlastnaKarta(33) === false) {
                    return this.PuskCard(33, player);
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.ParviPuska > 10 && this.ParviPuska < 20) {
            if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && this.TretiPuska > 10 && this.TretiPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska && this.k[i] < 20) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
            }
            if (this.VtoriPuska > 10 && this.VtoriPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 20) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
            }
            if (this.TretiPuska > 10 && this.TretiPuska < 20) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] < 20) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 10 && this.k[i] < 20) {
                        return i;
                    }
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > this.ParviPuska && this.k[i] < 20) {
                    return i;
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 10 && this.k[i] < 20) {
                    return i;
                }
            }
            this.Pniama[player][1] = true;
            return this.MinCard(player);
        }
        if (this.ParviPuska > 20 && this.ParviPuska < 30) {
            if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && this.TretiPuska > 20 && this.TretiPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska && this.k[i] < 30) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
            }
            if (this.VtoriPuska > 20 && this.VtoriPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 30) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
            }
            if (this.TretiPuska > 20 && this.TretiPuska < 30) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] < 30) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 20 && this.k[i] < 30) {
                        return i;
                    }
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > this.ParviPuska && this.k[i] < 30) {
                    return i;
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 20 && this.k[i] < 30) {
                    return i;
                }
            }
            this.Pniama[player][2] = true;
            return this.MinCard(player);
        }
        if (this.ParviPuska > 30 && this.ParviPuska < 40) {
            if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && this.TretiPuska > 30 && this.TretiPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska && this.k[i] < 40) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
            }
            if (this.VtoriPuska > 30 && this.VtoriPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 40) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
            }
            if (this.TretiPuska > 30 && this.TretiPuska < 40) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] < 40) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 30 && this.k[i] < 40) {
                        return i;
                    }
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > this.ParviPuska && this.k[i] < 40) {
                    return i;
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 30 && this.k[i] < 40) {
                    return i;
                }
            }
            this.Pniama[player][3] = true;
            return this.MinCard(player);
        }
        if (this.ParviPuska > 40 && this.ParviPuska < 50) {
            if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && this.TretiPuska > 40 && this.TretiPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska && this.k[i] < 50) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
            }
            if (this.VtoriPuska > 40 && this.VtoriPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] < 50) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
            }
            if (this.TretiPuska > 40 && this.TretiPuska < 50) {
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] < 50) {
                        return i;
                    }
                }
                for (
                    let i = 1 + (player - 1) * 8;
                    i < 9 - this.hand + (player - 1) * 8;
                    i++ //pravila
                ) {
                    if (this.k[i] > 40 && this.k[i] < 50) {
                        return i;
                    }
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > this.ParviPuska && this.k[i] < 50) {
                    return i;
                }
            }
            for (
                let i = 1 + (player - 1) * 8;
                i < 9 - this.hand + (player - 1) * 8;
                i++ //pravila
            ) {
                if (this.k[i] > 40 && this.k[i] < 50) {
                    return i;
                }
            }
            this.Pniama[player][4] = true;
            return this.MinCard(player);
        }
        return this.MinCard(player);
    }

    AddToDetails(card: number) {
        let kdcard = this.k[card];
        if (this.Naddavane === 6) {
            kdcard = this.k[card];
            if (this.k[card] === 18) {
                kdcard = 14;
            }
            if (this.k[card] === 28) {
                kdcard = 24;
            }
            if (this.k[card] === 38) {
                kdcard = 34;
            }
            if (this.k[card] === 48) {
                kdcard = 44;
            }
            if (this.k[card] === 17) {
                kdcard = 13;
            }
            if (this.k[card] === 27) {
                kdcard = 23;
            }
            if (this.k[card] === 37) {
                kdcard = 33;
            }
            if (this.k[card] === 47) {
                kdcard = 43;
            }
            if (this.k[card] === 16) {
                kdcard = 18;
            }
            if (this.k[card] === 26) {
                kdcard = 28;
            }
            if (this.k[card] === 36) {
                kdcard = 38;
            }
            if (this.k[card] === 46) {
                kdcard = 48;
            }
            if (this.k[card] === 15) {
                kdcard = 17;
            }
            if (this.k[card] === 25) {
                kdcard = 27;
            }
            if (this.k[card] === 35) {
                kdcard = 37;
            }
            if (this.k[card] === 45) {
                kdcard = 47;
            }
            if (this.k[card] === 14) {
                kdcard = 16;
            }
            if (this.k[card] === 24) {
                kdcard = 26;
            }
            if (this.k[card] === 34) {
                kdcard = 36;
            }
            if (this.k[card] === 44) {
                kdcard = 46;
            }
            if (this.k[card] === 13) {
                kdcard = 15;
            }
            if (this.k[card] === 23) {
                kdcard = 25;
            }
            if (this.k[card] === 33) {
                kdcard = 35;
            }
            if (this.k[card] === 43) {
                kdcard = 45;
            }
        }
        if (this.Naddavane < 5) {
            kdcard = this.k[card];
            if (this.k[card] === 108) {
                kdcard = this.Naddavane * 10 + 4;
            }
            if (this.k[card] === 107) {
                kdcard = this.Naddavane * 10 + 3;
            }
            if (this.k[card] === 106) {
                kdcard = this.Naddavane * 10 + 8;
            }
            if (this.k[card] === 105) {
                kdcard = this.Naddavane * 10 + 7;
            }
            if (this.k[card] === 104) {
                kdcard = this.Naddavane * 10 + 6;
            }
            if (this.k[card] === 103) {
                kdcard = this.Naddavane * 10 + 5;
            }
            if (this.k[card] === 102) {
                kdcard = this.Naddavane * 10 + 2;
            }
            if (this.k[card] === 101) {
                kdcard = this.Naddavane * 10 + 1;
            }
        }
        for (let i = 1; i < 33; i++) {
            if (this.kd[i] === 0) {
                this.kd[i] = kdcard;
                return;
            }
        }
    }

    Intelect(player: number) {
        let card: number;
        this.online_timer = this.online_timer_time;
        if (this.Naddavane < 5) {
            card = this.IntelectBoia(player);
        }
        if (this.Naddavane === 5) {
            card = this.IntelectBe(player);
        }
        if (this.Naddavane === 6) {
            card = this.IntelectVs(player);
        }
        let send_card = true;
        if (this.OP && this.CheckMessage()) {
            let ca = this._ttoi(this.ReadMessage());
            if (player === 2) {
                card = 8 + ca;
            }
            if (player === 3) {
                card = 16 + ca;
            }
            if (player === 4) {
                card = 24 + ca;
            }
            send_card = false;
            this.props.m_pwin.SendErrorToLog('B4Puskane na Karta ot PL ' + this.IntToString(player));
        }
        let ccard = card;

        if (player === 2) {
            ccard = ccard - 8;
        }
        if (player === 3) {
            ccard = ccard - 16;
        }
        if (player === 4) {
            ccard = ccard - 24;
        }
        if (ccard <= 0 || ccard > 8) {
            card = 1 + (player - 1) * 8;
            if (this.OP) {
                this.props.m_pwin.SendErrorToLog(
                    'Greshka pri puskane na karta ot comp-Naddavane=' +
                        this.IntToString(this.Naddavane) +
                        ' player=' +
                        this.IntToString(player) +
                        ' Karta: ' +
                        this.IntToString(card)
                );
            }
        }
        this.AddToDetails(card);
        if (this.CheckBelot(this.k[card], player) === true) {
            this.ObiaviavaneBelot(player, true);
            this.Belot[player][0] = this.Belot[player][0] + 1;
            if (this.OP) {
                this.SendGame('$|o|' + this.IntToString(this.hand));
            }
        }
        if (player !== this.KoiPuskaParvi) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) {
                this.Pniama[player][1] = true;
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) {
                this.Pniama[player][2] = true;
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) {
                this.Pniama[player][3] = true;
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) {
                this.Pniama[player][4] = true;
            }
            if (this.ParviPuska > 100 && this.k[card] < 100) {
                this.Pniama[player][this.Naddavane] = true;
            }
            if (this.Naddavane === 6 && this.KoiPuskaParvi === 4) {
                if (this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }

                if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }

                if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) {
                    this.Pniama[player][4] = true;
                }

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }
                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42) {
                    this.Pniama[player][4] = true;
                }
                if (this.ParviPuska === 14 && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13) {
                    this.Pniama[player][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23) {
                    this.Pniama[player][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33) {
                    this.Pniama[player][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43) {
                    this.Pniama[player][4] = true;
                }
                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.k[card] === 11
                ) {
                    this.Pniama[player][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.k[card] === 21
                ) {
                    this.Pniama[player][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.k[card] === 31
                ) {
                    this.Pniama[player][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.k[card] === 41
                ) {
                    this.Pniama[player][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 12
                ) {
                    this.Pniama[player][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 22
                ) {
                    this.Pniama[player][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 32
                ) {
                    this.Pniama[player][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 42
                ) {
                    this.Pniama[player][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 13
                ) {
                    this.Pniama[player][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 23
                ) {
                    this.Pniama[player][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 33
                ) {
                    this.Pniama[player][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 43
                ) {
                    this.Pniama[player][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 14
                ) {
                    this.Pniama[player][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 24
                ) {
                    this.Pniama[player][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 34
                ) {
                    this.Pniama[player][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 44
                ) {
                    this.Pniama[player][4] = true;
                }
            }
            if (this.Naddavane < 5 && this.KoiPuskaParvi === 4) {
                if (this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(102) === false && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(101) === false && this.k[card] === 102) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(102) === false && this.CheckCard(101) === false && this.k[card] === 103) {
                    this.Pniama[player][this.Naddavane] = true;
                }
            }
        }
        if (this.hand === 0) {
            this.terci[player][0] = true;
            this.terci[player][1] = true;
            for (let b = 0; b <= 1; b++) {
                if (this.PTerci[player][b] > 0) {
                    if (
                        !(
                            this.PTerci[player][0] > 0 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 7 &&
                            this.PTerci[player][1] > 0 &&
                            this.PTerci[player][1] < 7
                        ) &&
                        !(
                            this.PTerci[player][0] > 10 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 16 &&
                            this.PTerci[player][1] > 10 &&
                            this.PTerci[player][1] < 16
                        ) &&
                        !(
                            this.PTerci[player][0] > 30 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 37 &&
                            this.PTerci[player][1] > 30 &&
                            this.PTerci[player][1] < 37
                        )
                    ) {
                        this.ObiaviavaneTerci(player, this.PTerci[player][b], b, true);
                    }
                    if (b === 0) {
                        if (
                            this.PTerci[player][0] > 0 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 7 &&
                            this.PTerci[player][1] > 0 &&
                            this.PTerci[player][1] < 7
                        ) {
                            this.ObiaviavaneTerci(player, 7, 0, true);
                        }
                        if (
                            this.PTerci[player][0] > 10 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 16 &&
                            this.PTerci[player][1] > 10 &&
                            this.PTerci[player][1] < 16
                        ) {
                            this.ObiaviavaneTerci(player, 16, 0, true);
                        }
                        if (
                            this.PTerci[player][0] > 30 &&
                            this.terci[player][0] &&
                            this.terci[player][1] &&
                            this.PTerci[player][0] < 37 &&
                            this.PTerci[player][1] > 30 &&
                            this.PTerci[player][1] < 37
                        ) {
                            this.ObiaviavaneTerci(player, 37, 0, true);
                        }
                    }
                    if (this.OP) {
                        let message = '$|q|' + this.IntToString(player) + '|' + this.IntToString(b);
                        this.SendGame(message);
                    }
                }
            }
        }
        if (this.OP) {
            let ca = card;
            while (ca > 8) {
                ca = ca - 8;
            }
            let car = this.IntToString(ca);
            if (this.Server) {
                if (send_card) {
                    this.SendGame('$|k|' + car);
                }
            }
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        if (player === 3) {
            this.P3puska = this.k[card];
        }
        if (player === 4) {
            this.P4puska = this.k[card];
        }
        this.CardsPoint[player + 32].x = this.CardsPoint[card].x;
        this.CardsPoint[player + 32].y = this.CardsPoint[card].y;
        this.CardP[32 + player].image = this.CardP[card].image;
        this.BeginMove(this.CardsPoint[card], this.CardsPos[player + 32], 32 + this.KoiENaRed);
        if (player === 1) {
            this.P1puska = this.k[card];
        }
        if (player === 2) {
            this.P2puska = this.k[card];
        }
        if (player === 3) {
            this.P3puska = this.k[card];
        }
        if (player === 4) {
            this.P4puska = this.k[card];
        }

        if (this.KoiPuskaParvi === 1) {
            if (player === 1) {
                this.ParviPuska = this.k[card];
            }
            if (player === 2) {
                if (this.Naddavane === 6 && this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }
                if (this.Naddavane < 5 && this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                this.VtoriPuska = this.k[card];
            }
            if (player === 3) {
                this.TretiPuska = this.k[card];
            }
            if (player === 4) {
                this.ChetvartiPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (player === 2) {
                this.ParviPuska = this.k[card];
            }
            if (player === 3) {
                if (this.Naddavane === 6 && this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }
                if (this.Naddavane < 5 && this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                this.VtoriPuska = this.k[card];
            }
            if (player === 4) {
                this.TretiPuska = this.k[card];
            }
            if (player === 1) {
                this.ChetvartiPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 3) {
            if (player === 3) {
                this.ParviPuska = this.k[card];
            }
            if (player === 4) {
                if (this.Naddavane === 6 && this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }
                if (this.Naddavane < 5 && this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                this.VtoriPuska = this.k[card];
            }
            if (player === 1) {
                this.TretiPuska = this.k[card];
            }
            if (player === 2) {
                this.ChetvartiPuska = this.k[card];
            }
        }
        if (this.KoiPuskaParvi === 4) {
            if (player === 4) {
                this.ParviPuska = this.k[card];
            }
            if (player === 1) {
                if (this.Naddavane === 6 && this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[player][1] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[player][2] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[player][3] = true;
                }
                if (this.Naddavane === 6 && this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[player][4] = true;
                }
                if (this.Naddavane < 5 && this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[player][this.Naddavane] = true;
                }
                this.VtoriPuska = this.k[card];
            }
            if (player === 2) {
                this.TretiPuska = this.k[card];
            }
            if (player === 3) {
                this.ChetvartiPuska = this.k[card];
            }
        }
        this.DeleteCard(card);
        if (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0 || this.P4puska === 0) {
            if (this.KoiENaRed < 4) {
                this.KoiENaRed = this.KoiENaRed + 1;
            } else {
                this.KoiENaRed = 1;
            }
        }

        if (this.KoiPuskaParvi !== 1 && player === 4) {
            this.props.m_pwin.SetCursor();
        }
    }

    Vziatka() {
        if (
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 1) ||
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 4)
        ) {
            return true;
        }
        //label2////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 1) ||
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 3) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 2)
        ) {
            return false;
        }
        //label3////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 1) ||
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 3)
        ) {
            if (this.ParviPuska > 100) {
                if (this.VtoriPuska > this.ParviPuska) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if ((this.VtoriPuska > 10 && this.VtoriPuska < 20 && this.VtoriPuska > this.ParviPuska) || this.VtoriPuska > 100) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if ((this.VtoriPuska > 20 && this.VtoriPuska < 30 && this.VtoriPuska > this.ParviPuska) || this.VtoriPuska > 100) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if ((this.VtoriPuska > 30 && this.VtoriPuska < 40 && this.VtoriPuska > this.ParviPuska) || this.VtoriPuska > 100) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if ((this.VtoriPuska > 40 && this.VtoriPuska < 50 && this.VtoriPuska > this.ParviPuska) || this.VtoriPuska > 100) {
                    return false;
                } else {
                    return true;
                }
            }
        }
        //label4////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (
            (this.KoiPuskaParvi === 2 && this.KoiENaRed === 1) ||
            (this.KoiPuskaParvi === 1 && this.KoiENaRed === 4) ||
            (this.KoiPuskaParvi === 3 && this.KoiENaRed === 2) ||
            (this.KoiPuskaParvi === 4 && this.KoiENaRed === 3)
        ) {
            if (this.ParviPuska > 100) {
                if (this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                    return true;
                } else {
                    return false;
                }
            }
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.VtoriPuska > 100 && this.TretiPuska < 100) {
                    return true;
                }
                if (this.VtoriPuska < 100 && this.TretiPuska > 100) {
                    return false;
                }
                if (this.VtoriPuska > 100 && this.TretiPuska > 100) {
                    if (this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska > 10 && this.TretiPuska < 20) {
                    if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska > 20 && this.TretiPuska < 100) {
                    if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && this.VtoriPuska > this.ParviPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.VtoriPuska > 100 && this.TretiPuska < 100) {
                    return true;
                }
                if (this.VtoriPuska < 100 && this.TretiPuska > 100) {
                    return false;
                }
                if (this.VtoriPuska > 100 && this.TretiPuska > 100) {
                    if (this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska > 20 && this.TretiPuska < 30) {
                    if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if ((this.TretiPuska < 20 || this.TretiPuska > 30) && this.TretiPuska < 100) {
                    if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && this.VtoriPuska > this.ParviPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.VtoriPuska > 100 && this.TretiPuska < 100) {
                    return true;
                }
                if (this.VtoriPuska < 100 && this.TretiPuska > 100) {
                    return false;
                }
                if (this.VtoriPuska > 100 && this.TretiPuska > 100) {
                    if (this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska > 30 && this.TretiPuska < 40) {
                    if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if ((this.TretiPuska < 30 || this.TretiPuska > 40) && this.TretiPuska < 100) {
                    if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && this.VtoriPuska > this.ParviPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.VtoriPuska > 100 && this.TretiPuska < 100) {
                    return true;
                }
                if (this.VtoriPuska < 100 && this.TretiPuska > 100) {
                    return false;
                }
                if (this.VtoriPuska > 100 && this.TretiPuska > 100) {
                    if (this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska > 40 && this.TretiPuska < 50) {
                    if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.TretiPuska < 40) {
                    if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && this.VtoriPuska > this.ParviPuska) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    ProverkaCursor(card: number) {
        if (this.Faza < 5) {
            return false;
        }
        if (this.Move === true || this.KoiENaRed !== 1) {
            return false;
        }
        if (this.KoiPuskaParvi === 1) {
            return true;
        }
        let vziatka;
        if (this.KoiPuskaParvi === 2 || this.KoiPuskaParvi === 3) {
            vziatka = this.Vziatka();
        }
        //////////////////////////////////////////////////////////
        if (this.Naddavane === 5) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                if (this.k[card] > 20) {
                    for (let i = 1; i < 9; i++) {
                        if (this.k[i] > 10 && this.k[i] < 20) {
                            return false;
                        }
                    }
                }
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                if (this.k[card] < 20 || this.k[card] > 30) {
                    for (let i = 1; i < 9; i++) {
                        if (this.k[i] > 20 && this.k[i] < 30) {
                            return false;
                        }
                    }
                }
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                if (this.k[card] < 30 || this.k[card] > 40) {
                    for (let i = 1; i < 9; i++) {
                        if (this.k[i] > 30 && this.k[i] < 40) {
                            return false;
                        }
                    }
                }
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                if (this.k[card] < 40 || this.k[card] > 50) {
                    for (let i = 1; i < 9; i++) {
                        if (this.k[i] > 40 && this.k[i] < 50) {
                            return false;
                        }
                    }
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////
        if (this.KoiPuskaParvi === 4) {
            if (this.Naddavane < 5) {
                if (this.ParviPuska > 100) {
                    if (this.k[card] < 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 100 && this.k[card] < this.ParviPuska) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > this.ParviPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100 || (this.k[i] > 10 && this.k[i] < 20)) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] > 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if ((this.k[card] > 30 && this.k[card] < 100) || this.k[card] < 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100 || (this.k[i] > 20 && this.k[i] < 30)) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] > 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if ((this.k[card] > 40 && this.k[card] < 100) || this.k[card] < 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100 || (this.k[i] > 30 && this.k[i] < 40)) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] > 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] < 100 && this.k[card] < 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100 || (this.k[i] > 40 && this.k[i] < 50)) {
                                return false;
                            }
                        }
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 10 && this.k[card] < 20 && this.k[card] < this.ParviPuska) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] < 20 && this.k[i] > this.ParviPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 30 && this.k[card] < this.ParviPuska) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30 && this.k[i] > this.ParviPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] < 30 || this.k[card] > 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 40 && this.k[card] < this.ParviPuska) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40 && this.k[i] > this.ParviPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] < 40 || this.k[card] > 50) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 50 && this.k[card] < this.ParviPuska) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50 && this.k[i] > this.ParviPuska) {
                                return false;
                            }
                        }
                    }
                }
            }
        }
        ////////
        if (this.KoiPuskaParvi === 3) {
            if (this.Naddavane < 5) {
                if (this.ParviPuska > 100) {
                    if (this.k[card] < 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 100 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if (this.k[card] > 20 && this.k[card] < 100 && this.VtoriPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && this.k[card] > 20 && this.k[card] < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.VtoriPuska > 100 && this.k[card] < this.VtoriPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100 && this.VtoriPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && (this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.VtoriPuska > 100 && this.k[card] < this.VtoriPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] > 40 || this.k[card] < 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100 && this.VtoriPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && (this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.VtoriPuska > 100 && this.k[card] < this.VtoriPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] > 50 || this.k[card] < 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 40 || this.k[card] > 50) && this.k[card] < 100 && this.VtoriPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && this.k[card] < 40) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.VtoriPuska > 100 && this.k[card] < this.VtoriPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.VtoriPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 10 && this.k[card] < 20) {
                        if (this.VtoriPuska > 10 && this.VtoriPuska < 20 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 10 && this.k[i] < 20) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska > 20 && this.k[card] < this.ParviPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 10 && this.k[i] < 20) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 30) {
                        if (this.VtoriPuska > 20 && this.VtoriPuska < 30 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 20 && this.k[i] < 30) {
                                    return false;
                                }
                            }
                        }
                        if ((this.VtoriPuska < 20 || this.VtoriPuska > 30) && this.k[card] < this.ParviPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 20 && this.k[i] < 30) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] < 30 || this.k[card] > 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 40) {
                        if (this.VtoriPuska > 30 && this.VtoriPuska < 40 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 30 && this.k[i] < 40) {
                                    return false;
                                }
                            }
                        }
                        if ((this.VtoriPuska < 30 || this.VtoriPuska > 40) && this.k[card] < this.ParviPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 30 && this.k[i] < 40) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] < 40 || this.k[card] > 50) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 50) {
                        if (this.VtoriPuska > 40 && this.VtoriPuska < 50 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 40 && this.k[i] < 50) {
                                    return false;
                                }
                            }
                        }
                        if (this.VtoriPuska < 40 && this.k[card] < this.ParviPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 40 && this.k[i] < 50) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (this.Naddavane < 5) {
                if (this.ParviPuska > 100) {
                    if (this.k[card] < 100) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 100) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 100 && (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska || this.k[card] < this.TretiPuska)) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > this.TretiPuska) {
                                return false;
                            }
                        }
                    }
                }
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if (this.k[card] > 20 && this.k[card] < 100 && this.TretiPuska < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 20 && this.k[card] < 100 && this.TretiPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.TretiPuska > 100 && this.k[card] < this.TretiPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] > 30 || this.k[card] < 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100 && this.TretiPuska < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if ((this.k[card] < 20 || this.k[card] > 30) && this.k[card] < 100 && this.TretiPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.TretiPuska > 100 && this.k[card] < this.TretiPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] > 40 || this.k[card] < 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100 && this.TretiPuska < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if ((this.k[card] < 30 || this.k[card] > 40) && this.k[card] < 100 && this.TretiPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.TretiPuska > 100 && this.k[card] < this.TretiPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] > 50 || this.k[card] < 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (vziatka === false) {
                        if ((this.k[card] < 40 || this.k[card] > 50) && this.k[card] < 100 && this.TretiPuska < 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > 100) {
                                    return false;
                                }
                            }
                        }
                        if ((this.k[card] < 40 || this.k[card] > 50) && this.k[card] < 100 && this.TretiPuska > 100) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                        if (this.k[card] > 100 && this.TretiPuska > 100 && this.k[card] < this.TretiPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.TretiPuska) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.ParviPuska > 10 && this.ParviPuska < 20) {
                    if (this.k[card] > 20) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 10 && this.k[i] < 20) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 10 && this.k[card] < 20) {
                        if (
                            this.VtoriPuska > 10 &&
                            this.VtoriPuska < 20 &&
                            this.TretiPuska > 10 &&
                            this.TretiPuska < 20 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (
                                    this.k[i] > this.ParviPuska &&
                                    this.k[i] > this.VtoriPuska &&
                                    this.k[i] > this.TretiPuska &&
                                    this.k[i] > 10 &&
                                    this.k[i] < 20
                                ) {
                                    return false;
                                }
                            }
                        }
                        if (
                            this.VtoriPuska > 20 &&
                            this.TretiPuska > 10 &&
                            this.TretiPuska < 20 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] > 10 && this.k[i] < 20) {
                                    return false;
                                }
                            }
                        }
                        if (
                            this.TretiPuska > 20 &&
                            this.VtoriPuska > 10 &&
                            this.VtoriPuska < 20 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 10 && this.k[i] < 20) {
                                    return false;
                                }
                            }
                        }
                        if (this.TretiPuska > 20 && this.VtoriPuska > 20 && this.k[card] < this.ParviPuska) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 10 && this.k[i] < 20) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 20 && this.ParviPuska < 30) {
                    if (this.k[card] < 20 || this.k[card] > 30) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 20 && this.k[i] < 30) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 20 && this.k[card] < 30) {
                        if (
                            this.VtoriPuska > 20 &&
                            this.VtoriPuska < 30 &&
                            this.TretiPuska > 20 &&
                            this.TretiPuska < 30 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (
                                    this.k[i] > this.ParviPuska &&
                                    this.k[i] > this.VtoriPuska &&
                                    this.k[i] > this.TretiPuska &&
                                    this.k[i] > 20 &&
                                    this.k[i] < 30
                                ) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.VtoriPuska < 20 || this.VtoriPuska > 30) &&
                            this.TretiPuska > 20 &&
                            this.TretiPuska < 30 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] > 20 && this.k[i] < 30) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 20 || this.TretiPuska > 30) &&
                            this.VtoriPuska > 20 &&
                            this.VtoriPuska < 30 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 20 && this.k[i] < 30) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 20 || this.TretiPuska > 30) &&
                            (this.VtoriPuska < 20 || this.VtoriPuska > 30) &&
                            this.k[card] < this.ParviPuska
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 20 && this.k[i] < 30) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 30 && this.ParviPuska < 40) {
                    if (this.k[card] < 30 || this.k[card] > 40) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 30 && this.k[i] < 40) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 30 && this.k[card] < 40) {
                        if (
                            this.VtoriPuska > 30 &&
                            this.VtoriPuska < 40 &&
                            this.TretiPuska > 30 &&
                            this.TretiPuska < 40 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (
                                    this.k[i] > this.ParviPuska &&
                                    this.k[i] > this.VtoriPuska &&
                                    this.k[i] > this.TretiPuska &&
                                    this.k[i] > 30 &&
                                    this.k[i] < 40
                                ) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.VtoriPuska < 30 || this.VtoriPuska > 40) &&
                            this.TretiPuska > 30 &&
                            this.TretiPuska < 40 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] > 30 && this.k[i] < 40) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 30 || this.TretiPuska > 40) &&
                            this.VtoriPuska > 30 &&
                            this.VtoriPuska < 40 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 30 && this.k[i] < 40) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 30 || this.TretiPuska > 40) &&
                            (this.VtoriPuska < 30 || this.VtoriPuska > 40) &&
                            this.k[card] < this.ParviPuska
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 30 && this.k[i] < 40) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (this.ParviPuska > 40 && this.ParviPuska < 50) {
                    if (this.k[card] < 40 || this.k[card] > 50) {
                        for (let i = 1; i < 9; i++) {
                            if (this.k[i] > 40 && this.k[i] < 50) {
                                return false;
                            }
                        }
                    }
                    if (this.k[card] > 40 && this.k[card] < 50) {
                        if (
                            this.VtoriPuska > 40 &&
                            this.VtoriPuska < 50 &&
                            this.TretiPuska > 40 &&
                            this.TretiPuska < 50 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (
                                    this.k[i] > this.ParviPuska &&
                                    this.k[i] > this.VtoriPuska &&
                                    this.k[i] > this.TretiPuska &&
                                    this.k[i] > 40 &&
                                    this.k[i] < 50
                                ) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.VtoriPuska < 40 || this.VtoriPuska > 50) &&
                            this.TretiPuska > 40 &&
                            this.TretiPuska < 50 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.TretiPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.TretiPuska && this.k[i] > 40 && this.k[i] < 50) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 40 || this.TretiPuska > 50) &&
                            this.VtoriPuska > 40 &&
                            this.VtoriPuska < 50 &&
                            (this.k[card] < this.ParviPuska || this.k[card] < this.VtoriPuska)
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > this.VtoriPuska && this.k[i] > 40 && this.k[i] < 50) {
                                    return false;
                                }
                            }
                        }
                        if (
                            (this.TretiPuska < 40 || this.TretiPuska > 50) &&
                            (this.VtoriPuska < 40 || this.VtoriPuska > 50) &&
                            this.k[card] < this.ParviPuska
                        ) {
                            for (let i = 1; i < 9; i++) {
                                if (this.k[i] > this.ParviPuska && this.k[i] > 40 && this.k[i] < 50) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }

    async PuskaneNaKarta(card: number) {
        this.AddToDetails(card);
        if (this.CheckBelot(this.k[card], 1) === true) {
            this.m_ann.InitWindow(true, '', '');
            if (!this.watch) {
                if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                    if (this.m_ann.m_belote) {
                        this.ObiaviavaneBelot(1, true);
                        this.Belot[1][0] = this.Belot[1][0] + 1;
                        if (this.OP) {
                            if (this.Server === true) {
                                this.SendGame('$|o|' + this.IntToString(this.hand));
                            } else {
                                this.SendGame('$|p|' + this.IntToString(this.hand));
                            }
                        }
                    }
                }
            } else {
                if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                    this.ObiaviavaneBelot(this.KoiENaRed, true);
                    this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                }
            }
        }
        if (this.hand === 0) {
            this.setState({});
            if (this.watch) {
                for (let b = 0; b <= 1; b++) {
                    if (this.PTerci[this.KoiENaRed][b] > 0) {
                        if (this.terci[this.KoiENaRed][b]) {
                            if (
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                    this.PTerci[this.KoiENaRed][1] < 7
                                ) &&
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                    this.PTerci[this.KoiENaRed][1] < 16
                                ) &&
                                !(
                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                    this.PTerci[this.KoiENaRed][1] < 37
                                )
                            ) {
                                this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                            }
                            if (b === 0) {
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 0 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 7 &&
                                    this.PTerci[this.KoiENaRed][1] > 0 &&
                                    this.PTerci[this.KoiENaRed][1] < 7
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                }
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 10 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 16 &&
                                    this.PTerci[this.KoiENaRed][1] > 10 &&
                                    this.PTerci[this.KoiENaRed][1] < 16
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                }
                                if (
                                    this.PTerci[this.KoiENaRed][0] > 30 &&
                                    this.terci[this.KoiENaRed][0] &&
                                    this.terci[this.KoiENaRed][1] &&
                                    this.PTerci[this.KoiENaRed][0] < 37 &&
                                    this.PTerci[this.KoiENaRed][1] > 30 &&
                                    this.PTerci[this.KoiENaRed][1] < 37
                                ) {
                                    this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                }
                            }
                        } else {
                            this.PTerci[this.KoiENaRed][b] = 0;
                        }
                    }
                }
            } else {
                if (this.PTerci[1][0] > 0 || this.PTerci[1][1] > 0) {
                    this.m_ann.InitWindow(false, this.StringTerci(this.PTerci[1][0]), this.StringTerci(this.PTerci[1][1]));
                    if ((await this.m_ann.DoModal()) === GlobalConstants.IDOK) {
                        this.terci[1][0] = this.m_ann.m_announce1;
                        this.terci[1][1] = this.m_ann.m_announce2;
                        for (let b = 0; b <= 1; b++) {
                            if (this.PTerci[1][b] > 0 && this.terci[1][b]) {
                                if (
                                    !(
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) &&
                                    !(
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    )
                                ) {
                                    this.ObiaviavaneTerci(1, this.PTerci[1][b], b, true);
                                }
                                if (b === 0) {
                                    if (
                                        this.PTerci[1][0] > 0 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 7 &&
                                        this.PTerci[1][1] > 0 &&
                                        this.PTerci[1][1] < 7
                                    ) {
                                        this.ObiaviavaneTerci(1, 7, 0, true);
                                    }
                                    if (
                                        this.PTerci[1][0] > 10 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 16 &&
                                        this.PTerci[1][1] > 10 &&
                                        this.PTerci[1][1] < 16
                                    ) {
                                        this.ObiaviavaneTerci(1, 16, 0, true);
                                    }
                                    if (
                                        this.PTerci[1][0] > 30 &&
                                        this.terci[1][0] &&
                                        this.terci[1][1] &&
                                        this.PTerci[1][0] < 37 &&
                                        this.PTerci[1][1] > 30 &&
                                        this.PTerci[1][1] < 37
                                    ) {
                                        this.ObiaviavaneTerci(1, 37, 0, true);
                                    }
                                }
                                if (this.OP && !this.watch) {
                                    if (this.Server) {
                                        this.SendGame('$|q|1|' + this.IntToString(b));
                                    } else {
                                        this.SendGame('$|r|' + this.IntToString(b));
                                    }
                                }
                            } else {
                                this.PTerci[1][b] = 0;
                                this.terci[1][b] = false;
                            }
                        }
                    } else {
                        this.PTerci[1][0] = 0;
                        this.terci[1][0] = false;
                        this.PTerci[1][1] = 0;
                        this.terci[1][1] = false;
                    }
                }
            }
        }
        if (this.KoiPuskaParvi === 1) {
            this.ParviPuska = this.k[card];
        }
        if (this.KoiPuskaParvi === 2) {
            this.ChetvartiPuska = this.k[card];
        }
        if (this.KoiPuskaParvi === 3) {
            this.TretiPuska = this.k[card];
        }
        if (this.KoiPuskaParvi === 4) {
            this.VtoriPuska = this.k[card];
        }

        this.P1puska = this.k[card];
        this.CardsPoint[33].x = this.CardsPoint[card].x;
        this.CardsPoint[33].y = this.CardsPoint[card].y;
        this.CardP[33].image = this.CardP[card].image;
        this.BeginMove(this.CardsPoint[33], this.CardsPos[33], 33);
        if (this.KoiPuskaParvi !== 2) {
            this.KoiENaRed = 2;
        }
        if (this.SP === true) {
            this.props.m_pwin.KillTimer(3);
            this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
        }
        if (this.KoiPuskaParvi === 1) {
            if (this.Naddavane > 4) {
                if (
                    this.k[card] > 10 &&
                    this.k[card] < 17 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 1)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 1) || this.k[card] === 17)
                ) {
                    this.Piska[1][1] = true;
                }
                if (
                    this.k[card] > 20 &&
                    this.k[card] < 27 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 2)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 2) || this.k[card] === 27)
                ) {
                    this.Piska[1][2] = true;
                }
                if (
                    this.k[card] > 30 &&
                    this.k[card] < 37 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 3)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 3) || this.k[card] === 37)
                ) {
                    this.Piska[1][3] = true;
                }
                if (
                    this.k[card] > 40 &&
                    this.k[card] < 47 &&
                    this.CheckVlastnaKarta(this.CheckNGCard(1, 4)) === true &&
                    (this.k[card] !== this.CheckNGCard(1, 4) || this.k[card] === 47)
                ) {
                    this.Piska[1][4] = true;
                }
            }
        }
        if (this.KoiPuskaParvi > 1) {
            if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) {
                this.Pniama[1][1] = true;
            }
            if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) {
                this.Pniama[1][2] = true;
            }
            if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) {
                this.Pniama[1][3] = true;
            }
            if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) {
                this.Pniama[1][4] = true;
            }
            if (this.ParviPuska > 100 && this.k[card] < 100) {
                this.Pniama[1][this.Naddavane] = true;
            }
            if (this.Naddavane === 6 && this.KoiPuskaParvi === 4) {
                if (this.ParviPuska === 12 && this.k[card] === 11) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 22 && this.k[card] === 21) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 32 && this.k[card] === 31) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 42 && this.k[card] === 41) {
                    this.Pniama[1][4] = true;
                }

                if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) {
                    this.Pniama[1][4] = true;
                }

                if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) {
                    this.Pniama[1][4] = true;
                }

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(12) === false && this.k[card] === 11) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(22) === false && this.k[card] === 21) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(32) === false && this.k[card] === 31) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(42) === false && this.k[card] === 41) {
                    this.Pniama[1][4] = true;
                }

                if (this.ParviPuska === 14 && this.CheckCard(13) === false && this.CheckCard(11) === false && this.k[card] === 12) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(23) === false && this.CheckCard(21) === false && this.k[card] === 22) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(33) === false && this.CheckCard(31) === false && this.k[card] === 32) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(43) === false && this.CheckCard(41) === false && this.k[card] === 42) {
                    this.Pniama[1][4] = true;
                }

                if (this.ParviPuska === 14 && this.CheckCard(12) === false && this.CheckCard(11) === false && this.k[card] === 13) {
                    this.Pniama[1][1] = true;
                }
                if (this.ParviPuska === 24 && this.CheckCard(22) === false && this.CheckCard(21) === false && this.k[card] === 23) {
                    this.Pniama[1][2] = true;
                }
                if (this.ParviPuska === 34 && this.CheckCard(32) === false && this.CheckCard(31) === false && this.k[card] === 33) {
                    this.Pniama[1][3] = true;
                }
                if (this.ParviPuska === 44 && this.CheckCard(42) === false && this.CheckCard(41) === false && this.k[card] === 43) {
                    this.Pniama[1][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.k[card] === 11
                ) {
                    this.Pniama[1][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.k[card] === 21
                ) {
                    this.Pniama[1][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.k[card] === 31
                ) {
                    this.Pniama[1][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.k[card] === 41
                ) {
                    this.Pniama[1][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 12
                ) {
                    this.Pniama[1][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 22
                ) {
                    this.Pniama[1][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 32
                ) {
                    this.Pniama[1][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 42
                ) {
                    this.Pniama[1][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(14) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 13
                ) {
                    this.Pniama[1][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(24) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 23
                ) {
                    this.Pniama[1][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(34) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 33
                ) {
                    this.Pniama[1][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(44) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 43
                ) {
                    this.Pniama[1][4] = true;
                }

                if (
                    this.ParviPuska === 15 &&
                    this.CheckCard(13) === false &&
                    this.CheckCard(12) === false &&
                    this.CheckCard(11) === false &&
                    this.k[card] === 14
                ) {
                    this.Pniama[1][1] = true;
                }
                if (
                    this.ParviPuska === 25 &&
                    this.CheckCard(23) === false &&
                    this.CheckCard(22) === false &&
                    this.CheckCard(21) === false &&
                    this.k[card] === 24
                ) {
                    this.Pniama[1][2] = true;
                }
                if (
                    this.ParviPuska === 35 &&
                    this.CheckCard(33) === false &&
                    this.CheckCard(32) === false &&
                    this.CheckCard(31) === false &&
                    this.k[card] === 34
                ) {
                    this.Pniama[1][3] = true;
                }
                if (
                    this.ParviPuska === 45 &&
                    this.CheckCard(43) === false &&
                    this.CheckCard(42) === false &&
                    this.CheckCard(41) === false &&
                    this.k[card] === 44
                ) {
                    this.Pniama[1][4] = true;
                }
            }
            if (this.Naddavane < 5 && this.KoiPuskaParvi === 4) {
                if (this.ParviPuska === 102 && this.k[card] === 101) {
                    this.Pniama[1][this.Naddavane] = true;
                }
                if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) {
                    this.Pniama[1][this.Naddavane] = true;
                }
                if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) {
                    this.Pniama[1][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(102) === false && this.k[card] === 101) {
                    this.Pniama[1][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(103) === false && this.CheckCard(101) === false && this.k[card] === 102) {
                    this.Pniama[1][this.Naddavane] = true;
                }
                if (this.ParviPuska === 104 && this.CheckCard(102) === false && this.CheckCard(101) === false && this.k[card] === 103) {
                    this.Pniama[1][this.Naddavane] = true;
                }
            }
        }
        this.DeleteCard(card);
        this.setState({});
        this.props.m_pwin.SetCursor();
    }

    async PuskaneNaKartaOP(card: number) {
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            if (this.KoiENaRed === 1) {
                await this.PuskaneNaKarta(card);
                if (this.Server === true) {
                    if (this.P2OP !== true) {
                        this.props.m_pwin.KillTimer(3);
                        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    }
                    this.SendGame('$|k|' + this.IntToString(card));
                } else {
                    this.SendGame('$|l|' + this.IntToString(card));
                }
            }
        }
    }

    async Pusk1KartaZaPuskanePl1() {
        if (this.props.m_pwin.state.CAnnouncementVisible) {
            return;
        }
        let vc = 0;
        let pc = 0;
        if (this.KoiENaRed === 1 && this.P1puska === 0) {
            for (let i = 1; i <= 8; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[1] !== 0 && this.k[i] !== 0) {
                        vc = vc + 1;
                        pc = i;
                    }
                }
            }
        }
        if (vc === 1 && pc > 0) {
            if (this.ProverkaCursor(pc)) {
                if (this.k[pc] !== 0) {
                    if (this.SP === true) {
                        await this.PuskaneNaKarta(pc);
                        return;
                    }
                    if (this.OP === true) {
                        await this.PuskaneNaKartaOP(pc);
                        return;
                    }
                }
            }
        }
    }

    CloseAllDialogs() {
        if (this.props.m_pwin.state.CAnnouncementVisible) {
            this.m_ann.Exit();
        }
    }

    async AutoPuskKard() {
        for (let i = 1; i <= 8; i++) {
            if (this.ProverkaCursor(i)) {
                if (this.k[1] !== 0) {
                    if (this.props.m_pwin.state.CAnnouncementVisible) {
                        this.m_ann.Exit();
                        this.online_timer = this.online_timer_time;
                        return;
                    }
                    await this.PuskaneNaKartaOP(i);
                    return;
                }
            }
        }
    }

    AutoSitOut() {
        this.CloseAllDialogs();
        this.props.m_pwin.AutoSitOut();
    }

    async Timer3() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.Move !== true) {
            if (this.SP === true) {
                if (
                    this.Faza > 4 &&
                    this.strelka < 1 &&
                    this.KoiENaRed !== 1 &&
                    this.hand < 8 &&
                    !this.Move &&
                    (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0 || this.P4puska === 0)
                ) {
                    this.Intelect(this.KoiENaRed);
                    this.setState({});
                    return;
                }
                if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 1 && this.hand < 8 && this.P1puska === 0) {
                    if (GlobalVariables.automatic_release && !this.props.m_pwin.state.CAnnouncementVisible) {
                        await this.Pusk1KartaZaPuskanePl1();
                    }
                    return;
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.KoiENaRed !== 1 &&
                        this.hand < 8 &&
                        !this.Move &&
                        (this.P2puska === 0 || this.P3puska === 0 || this.P4puska === 0)
                    ) {
                        if (
                            ((this.KoiENaRed === 2 && this.P2OP && this.P2puska === 0) ||
                                (this.KoiENaRed === 3 && this.P3OP && this.P3puska === 0) ||
                                (this.KoiENaRed === 4 && this.P4OP && this.P4puska === 0)) &&
                            this.CheckMessage()
                        ) {
                            //!!!New
                            let ca = this._ttoi(this.ReadMessage());
                            let card;
                            if (this.KoiENaRed === 2) {
                                card = 8 + ca;
                            }
                            if (this.KoiENaRed === 3) {
                                card = 16 + ca;
                            }
                            if (this.KoiENaRed === 4) {
                                card = 24 + ca;
                            }
                            if (this.k[card] === 0 || ca === 0) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Nevalidna Karta Server: ' +
                                        this.IntToString(card) +
                                        ' this.hand=' +
                                        this.IntToString(this.hand) +
                                        'Koi e na red=' +
                                        this.IntToString(this.KoiENaRed)
                                );
                                return;
                            }
                            this.online_timer = this.online_timer_time;
                            this.AddToDetails(card);
                            if (this.KoiENaRed === 2) {
                                this.P2puska = this.k[card];
                            }
                            if (this.KoiENaRed === 3) {
                                this.P3puska = this.k[card];
                            }
                            if (this.KoiENaRed === 4) {
                                this.P4puska = this.k[card];
                            }
                            this.CardsPoint[this.KoiENaRed + 32].x = this.CardsPoint[card].x;
                            this.CardsPoint[this.KoiENaRed + 32].y = this.CardsPoint[card].y;
                            this.CardP[32 + this.KoiENaRed].image = this.CardP[card].image;
                            if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                                this.ObiaviavaneBelot(this.KoiENaRed, true);
                                this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                            }

                            let player = this.KoiENaRed;
                            if (player !== this.KoiPuskaParvi) {
                                if (this.ParviPuska > 10 && this.ParviPuska < 20 && this.k[card] > 20) {
                                    this.Pniama[player][1] = true;
                                }
                                if (this.ParviPuska > 20 && this.ParviPuska < 30 && (this.k[card] < 20 || this.k[card] > 30)) {
                                    this.Pniama[player][2] = true;
                                }
                                if (this.ParviPuska > 30 && this.ParviPuska < 40 && (this.k[card] < 30 || this.k[card] > 40)) {
                                    this.Pniama[player][3] = true;
                                }
                                if (this.ParviPuska > 40 && this.ParviPuska < 50 && (this.k[card] < 40 || this.k[card] > 50)) {
                                    this.Pniama[player][4] = true;
                                }
                                if (this.ParviPuska > 100 && this.k[card] < 100) {
                                    this.Pniama[player][this.Naddavane] = true;
                                }
                                if (this.Naddavane === 6 && this.KoiPuskaParvi === 4) {
                                    if (this.ParviPuska === 12 && this.k[card] === 11) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (this.ParviPuska === 22 && this.k[card] === 21) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (this.ParviPuska === 32 && this.k[card] === 31) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (this.ParviPuska === 42 && this.k[card] === 41) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (this.ParviPuska === 13 && this.CheckCard(12) === false && this.k[card] === 11) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (this.ParviPuska === 23 && this.CheckCard(22) === false && this.k[card] === 21) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (this.ParviPuska === 33 && this.CheckCard(32) === false && this.k[card] === 31) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (this.ParviPuska === 43 && this.CheckCard(42) === false && this.k[card] === 41) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (this.ParviPuska === 13 && this.CheckCard(11) === false && this.k[card] === 12) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (this.ParviPuska === 23 && this.CheckCard(21) === false && this.k[card] === 22) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (this.ParviPuska === 33 && this.CheckCard(31) === false && this.k[card] === 32) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (this.ParviPuska === 43 && this.CheckCard(41) === false && this.k[card] === 42) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 14 &&
                                        this.CheckCard(13) === false &&
                                        this.CheckCard(12) === false &&
                                        this.k[card] === 11
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 24 &&
                                        this.CheckCard(23) === false &&
                                        this.CheckCard(22) === false &&
                                        this.k[card] === 21
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 34 &&
                                        this.CheckCard(33) === false &&
                                        this.CheckCard(32) === false &&
                                        this.k[card] === 31
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 44 &&
                                        this.CheckCard(43) === false &&
                                        this.CheckCard(42) === false &&
                                        this.k[card] === 41
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 14 &&
                                        this.CheckCard(13) === false &&
                                        this.CheckCard(11) === false &&
                                        this.k[card] === 12
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 24 &&
                                        this.CheckCard(23) === false &&
                                        this.CheckCard(21) === false &&
                                        this.k[card] === 22
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 34 &&
                                        this.CheckCard(33) === false &&
                                        this.CheckCard(31) === false &&
                                        this.k[card] === 32
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 44 &&
                                        this.CheckCard(43) === false &&
                                        this.CheckCard(41) === false &&
                                        this.k[card] === 42
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 14 &&
                                        this.CheckCard(12) === false &&
                                        this.CheckCard(11) === false &&
                                        this.k[card] === 13
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 24 &&
                                        this.CheckCard(22) === false &&
                                        this.CheckCard(21) === false &&
                                        this.k[card] === 23
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 34 &&
                                        this.CheckCard(32) === false &&
                                        this.CheckCard(31) === false &&
                                        this.k[card] === 33
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 44 &&
                                        this.CheckCard(42) === false &&
                                        this.CheckCard(41) === false &&
                                        this.k[card] === 43
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 15 &&
                                        this.CheckCard(14) === false &&
                                        this.CheckCard(13) === false &&
                                        this.CheckCard(12) === false &&
                                        this.k[card] === 11
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 25 &&
                                        this.CheckCard(24) === false &&
                                        this.CheckCard(23) === false &&
                                        this.CheckCard(22) === false &&
                                        this.k[card] === 21
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 35 &&
                                        this.CheckCard(34) === false &&
                                        this.CheckCard(33) === false &&
                                        this.CheckCard(32) === false &&
                                        this.k[card] === 31
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 45 &&
                                        this.CheckCard(44) === false &&
                                        this.CheckCard(43) === false &&
                                        this.CheckCard(42) === false &&
                                        this.k[card] === 41
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 15 &&
                                        this.CheckCard(14) === false &&
                                        this.CheckCard(13) === false &&
                                        this.CheckCard(11) === false &&
                                        this.k[card] === 12
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 25 &&
                                        this.CheckCard(24) === false &&
                                        this.CheckCard(23) === false &&
                                        this.CheckCard(21) === false &&
                                        this.k[card] === 22
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 35 &&
                                        this.CheckCard(34) === false &&
                                        this.CheckCard(33) === false &&
                                        this.CheckCard(31) === false &&
                                        this.k[card] === 32
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 45 &&
                                        this.CheckCard(44) === false &&
                                        this.CheckCard(43) === false &&
                                        this.CheckCard(41) === false &&
                                        this.k[card] === 42
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 15 &&
                                        this.CheckCard(14) === false &&
                                        this.CheckCard(12) === false &&
                                        this.CheckCard(11) === false &&
                                        this.k[card] === 13
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 25 &&
                                        this.CheckCard(24) === false &&
                                        this.CheckCard(22) === false &&
                                        this.CheckCard(21) === false &&
                                        this.k[card] === 23
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 35 &&
                                        this.CheckCard(34) === false &&
                                        this.CheckCard(32) === false &&
                                        this.CheckCard(31) === false &&
                                        this.k[card] === 33
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 45 &&
                                        this.CheckCard(44) === false &&
                                        this.CheckCard(42) === false &&
                                        this.CheckCard(41) === false &&
                                        this.k[card] === 43
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }

                                    if (
                                        this.ParviPuska === 15 &&
                                        this.CheckCard(13) === false &&
                                        this.CheckCard(12) === false &&
                                        this.CheckCard(11) === false &&
                                        this.k[card] === 14
                                    ) {
                                        this.Pniama[player][1] = true;
                                    }
                                    if (
                                        this.ParviPuska === 25 &&
                                        this.CheckCard(23) === false &&
                                        this.CheckCard(22) === false &&
                                        this.CheckCard(21) === false &&
                                        this.k[card] === 24
                                    ) {
                                        this.Pniama[player][2] = true;
                                    }
                                    if (
                                        this.ParviPuska === 35 &&
                                        this.CheckCard(33) === false &&
                                        this.CheckCard(32) === false &&
                                        this.CheckCard(31) === false &&
                                        this.k[card] === 34
                                    ) {
                                        this.Pniama[player][3] = true;
                                    }
                                    if (
                                        this.ParviPuska === 45 &&
                                        this.CheckCard(43) === false &&
                                        this.CheckCard(42) === false &&
                                        this.CheckCard(41) === false &&
                                        this.k[card] === 44
                                    ) {
                                        this.Pniama[player][4] = true;
                                    }
                                }
                                if (this.Naddavane < 5 && this.KoiPuskaParvi === 4) {
                                    if (this.ParviPuska === 102 && this.k[card] === 101) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                    if (this.ParviPuska === 103 && this.CheckCard(102) === false && this.k[card] === 101) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                    if (this.ParviPuska === 103 && this.CheckCard(101) === false && this.k[card] === 102) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                    if (
                                        this.ParviPuska === 104 &&
                                        this.CheckCard(103) === false &&
                                        this.CheckCard(102) === false &&
                                        this.k[card] === 101
                                    ) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                    if (
                                        this.ParviPuska === 104 &&
                                        this.CheckCard(103) === false &&
                                        this.CheckCard(101) === false &&
                                        this.k[card] === 102
                                    ) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                    if (
                                        this.ParviPuska === 104 &&
                                        this.CheckCard(102) === false &&
                                        this.CheckCard(101) === false &&
                                        this.k[card] === 103
                                    ) {
                                        this.Pniama[player][this.Naddavane] = true;
                                    }
                                }
                            }

                            if (this.hand === 0) {
                                if (this.KoiENaRed === 2) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                                if (this.KoiENaRed === 3) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                                if (this.KoiENaRed === 4) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                            }
                            this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 32], 32 + this.KoiENaRed);
                            if (this.KoiENaRed === 2) {
                                if (this.P3OP !== true) {
                                    this.props.m_pwin.KillTimer(3);
                                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                }
                                this.setState({});
                            }
                            if (this.KoiENaRed === 3) {
                                if (this.P4OP !== true) {
                                    this.props.m_pwin.KillTimer(3);
                                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                }
                                this.setState({});
                            }
                            if (this.KoiENaRed === 4) {
                                this.props.m_pwin.KillTimer(3);
                                this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                                this.setState({});
                            }
                            if (this.KoiPuskaParvi === 1) {
                                if (this.KoiENaRed === 2) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.TretiPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 2) {
                                if (this.KoiENaRed === 2) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.TretiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 3) {
                                if (this.KoiENaRed === 3) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 2) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 4) {
                                if (this.KoiENaRed === 4) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 2) {
                                    this.TretiPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            this.DeleteCard(card);
                            if (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0 || this.P4puska === 0) {
                                if (this.KoiENaRed < 4) {
                                    this.KoiENaRed = this.KoiENaRed + 1;
                                } else {
                                    this.KoiENaRed = 1;
                                }
                            }
                            return;
                        }
                        if (this.KoiENaRed === 2 && this.P2OP !== true) {
                            this.Intelect(2);
                            this.setState({});
                            return;
                        }
                        if (this.KoiENaRed === 3 && this.P3OP !== true) {
                            this.Intelect(3);
                            this.setState({});
                            return;
                        }
                        if (this.KoiENaRed === 4 && this.P4OP !== true) {
                            this.Intelect(4);
                            this.setState({});
                            return;
                        }
                    }
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.hand < 8 &&
                        this.P1puska === 0 &&
                        this.KoiENaRed === 1 &&
                        !this.Move &&
                        this.Faza < 6
                    ) {
                        if (this.online_timer <= 0) {
                            this.sit_out_count = this.sit_out_count + 1;
                            if (this.sit_out_count >= this.sit_out_drop) {
                                this.AutoSitOut();
                                return;
                            }
                            await this.AutoPuskKard();
                        } else if (
                            GlobalVariables.automatic_release === true &&
                            this.KoiENaRed === 1 &&
                            !this.props.m_pwin.state.CAnnouncementVisible
                        ) {
                            await this.Pusk1KartaZaPuskanePl1();
                        }
                        return;
                    }
                } else {
                    if (this.watch) {
                        if (this.Faza > 4 && this.strelka < 1 && this.KoiENaRed === 1 && this.hand < 8 && !this.Move && this.P1puska === 0) {
                            if (this.CheckMessage()) {
                                let ca = this._ttoi(this.ReadMessage());
                                let card = ca;
                                if (this.k[card] === 0 || ca === 0) {
                                    this.props.m_pwin.SendErrorToLog(
                                        'Nevalidna Karta Client: ' +
                                            this.IntToString(ca) +
                                            ' this.hand=' +
                                            this.IntToString(this.hand) +
                                            'Koi e na red=' +
                                            this.IntToString(this.KoiENaRed)
                                    );
                                    return;
                                }
                                this.online_timer = this.online_timer_time;
                                this.AddToDetails(card);
                                this.P1puska = this.k[card];
                                this.CardsPoint[this.KoiENaRed + 32].x = this.CardsPoint[card].x;
                                this.CardsPoint[this.KoiENaRed + 32].y = this.CardsPoint[card].y;
                                this.CardP[32 + this.KoiENaRed].image = this.CardP[card].image;
                                await this.PuskaneNaKarta(card);
                            }
                            return;
                        }
                    }
                    if (
                        this.Faza > 4 &&
                        this.strelka < 1 &&
                        this.KoiENaRed !== 1 &&
                        this.hand < 8 &&
                        !this.Move &&
                        ((this.KoiENaRed === 2 && this.P2puska === 0) ||
                            (this.KoiENaRed === 3 && this.P3puska === 0) ||
                            (this.KoiENaRed === 4 && this.P4puska === 0))
                    ) {
                        //!!!New
                        if (this.CheckMessage()) {
                            let ca = this._ttoi(this.ReadMessage());
                            let card;
                            if (this.KoiENaRed === 2) {
                                card = 8 + ca;
                            }
                            if (this.KoiENaRed === 3) {
                                card = 16 + ca;
                            }
                            if (this.KoiENaRed === 4) {
                                card = 24 + ca;
                            }
                            if (this.k[card] === 0 || ca === 0) {
                                this.props.m_pwin.SendErrorToLog(
                                    'Nevalidna Karta Client: ' +
                                        this.IntToString(ca) +
                                        ' this.hand=' +
                                        this.IntToString(this.hand) +
                                        'Koi e na red=' +
                                        this.IntToString(this.KoiENaRed)
                                );
                                return;
                            }
                            this.online_timer = this.online_timer_time;
                            this.AddToDetails(card);
                            if (this.KoiENaRed === 2) {
                                this.P2puska = this.k[card];
                            }
                            if (this.KoiENaRed === 3) {
                                this.P3puska = this.k[card];
                            }
                            if (this.KoiENaRed === 4) {
                                this.P4puska = this.k[card];
                            }
                            this.CardsPoint[this.KoiENaRed + 32].x = this.CardsPoint[card].x;
                            this.CardsPoint[this.KoiENaRed + 32].y = this.CardsPoint[card].y;
                            this.CardP[32 + this.KoiENaRed].image = this.CardP[card].image;
                            if (this.CheckBelot(this.k[card], this.KoiENaRed) === true && this.op_belot[this.hand]) {
                                this.ObiaviavaneBelot(this.KoiENaRed, true);
                                this.Belot[this.KoiENaRed][0] = this.Belot[this.KoiENaRed][0] + 1;
                            }
                            if (this.hand === 0) {
                                if (this.KoiENaRed === 2) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                                if (this.KoiENaRed === 3) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                                if (this.KoiENaRed === 4) {
                                    for (let b = 0; b <= 1; b++) {
                                        if (this.PTerci[this.KoiENaRed][b] > 0) {
                                            if (this.terci[this.KoiENaRed][b]) {
                                                if (
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) &&
                                                    !(
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    )
                                                ) {
                                                    this.ObiaviavaneTerci(this.KoiENaRed, this.PTerci[this.KoiENaRed][b], b, true);
                                                }
                                                if (b === 0) {
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 0 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 7 &&
                                                        this.PTerci[this.KoiENaRed][1] > 0 &&
                                                        this.PTerci[this.KoiENaRed][1] < 7
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 7, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 10 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 16 &&
                                                        this.PTerci[this.KoiENaRed][1] > 10 &&
                                                        this.PTerci[this.KoiENaRed][1] < 16
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 16, 0, true);
                                                    }
                                                    if (
                                                        this.PTerci[this.KoiENaRed][0] > 30 &&
                                                        this.terci[this.KoiENaRed][0] &&
                                                        this.terci[this.KoiENaRed][1] &&
                                                        this.PTerci[this.KoiENaRed][0] < 37 &&
                                                        this.PTerci[this.KoiENaRed][1] > 30 &&
                                                        this.PTerci[this.KoiENaRed][1] < 37
                                                    ) {
                                                        this.ObiaviavaneTerci(this.KoiENaRed, 37, 0, true);
                                                    }
                                                }
                                            } else {
                                                this.PTerci[this.KoiENaRed][b] = 0;
                                            }
                                        }
                                    }
                                }
                            }
                            this.BeginMove(this.CardsPoint[card], this.CardsPos[this.KoiENaRed + 32], 32 + this.KoiENaRed);
                            if (this.KoiENaRed === 2) {
                                this.setState({});
                            }
                            if (this.KoiENaRed === 3) {
                                this.setState({});
                            }
                            if (this.KoiENaRed === 4) {
                                this.setState({});
                            }
                            if (this.KoiPuskaParvi === 1) {
                                if (this.KoiENaRed === 2) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.TretiPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 2) {
                                if (this.KoiENaRed === 2) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.TretiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 3) {
                                if (this.KoiENaRed === 3) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 4) {
                                    this.VtoriPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 2) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            if (this.KoiPuskaParvi === 4) {
                                if (this.KoiENaRed === 4) {
                                    this.ParviPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 2) {
                                    this.TretiPuska = this.k[card];
                                }
                                if (this.KoiENaRed === 3) {
                                    this.ChetvartiPuska = this.k[card];
                                }
                            }
                            this.DeleteCard(card);
                            if (this.P1puska === 0 || this.P2puska === 0 || this.P3puska === 0 || this.P4puska === 0) {
                                if (this.KoiENaRed < 4) {
                                    this.KoiENaRed = this.KoiENaRed + 1;
                                } else {
                                    this.KoiENaRed = 1;
                                }
                            }
                        }
                        return;
                    }
                    if (!this.watch) {
                        if (
                            this.Faza > 4 &&
                            this.strelka < 1 &&
                            this.hand < 8 &&
                            this.P1puska === 0 &&
                            this.KoiENaRed === 1 &&
                            !this.Move &&
                            this.Faza < 6
                        ) {
                            if (this.online_timer <= 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.AutoSitOut();
                                    return;
                                }
                                await this.AutoPuskKard();
                            } else if (
                                GlobalVariables.automatic_release === true &&
                                this.KoiENaRed === 1 &&
                                !this.props.m_pwin.state.CAnnouncementVisible
                            ) {
                                await this.Pusk1KartaZaPuskanePl1();
                            }
                            return;
                        }
                    }
                }
            }
        }
    }

    InitVziatka() {
        for (let i = 1; i <= 4; i++) {
            this.Belot[i][1] = 0;
        }
        this.KoiENaRed = this.KoiPuskaParvi = this.strelka;
        this.strelka = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        this.online_timer = this.online_timer_time;
    }

    async InitRezDlg() {
        if (this.watch) {
            this.Faza = 6;
        }
        if (!this.watch) {
            this.online_timer = this.online_timer_time;
            this.m_rez.m_name1 = this.Name[1];
            this.m_rez.m_name2 = this.Name[2];
            this.m_rez.m_name3 = this.Name[3];
            this.m_rez.m_name4 = this.Name[4];
            if (this.Belot[1][0] === 1) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 20;
                this.m_rez.m_bel1 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[1][0] === 2) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 40;
                this.m_rez.m_bel1 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[1][0] === 3) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 60;
                this.m_rez.m_bel1 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[1][0] === 4) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 80;
                this.m_rez.m_bel1 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 1) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 20;
                this.m_rez.m_bel2 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[2][0] === 2) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 40;
                this.m_rez.m_bel2 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 3) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 60;
                this.m_rez.m_bel2 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[2][0] === 4) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 80;
                this.m_rez.m_bel2 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[3][0] === 1) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 20;
                this.m_rez.m_bel3 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[3][0] === 2) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 40;
                this.m_rez.m_bel3 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[3][0] === 3) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 60;
                this.m_rez.m_bel3 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[3][0] === 4) {
                this.m_rez.m_rez13 = this.m_rez.m_rez13 + 80;
                this.m_rez.m_bel3 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[4][0] === 1) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 20;
                this.m_rez.m_bel4 = '1 ' + this.GetLanguageText('Belote');
            }
            if (this.Belot[4][0] === 2) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 40;
                this.m_rez.m_bel4 = '2 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[4][0] === 3) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 60;
                this.m_rez.m_bel4 = '3 ' + this.GetLanguageText('Belotes');
            }
            if (this.Belot[4][0] === 4) {
                this.m_rez.m_rez24 = this.m_rez.m_rez24 + 80;
                this.m_rez.m_bel4 = '4 ' + this.GetLanguageText('Belotes');
            }
            if (this.Naddavane !== 5) {
                this.m_rez.m_ter1pl1 = this.StringTerci(this.PTerci[1][0]);
                this.m_rez.m_ter2pl1 = this.StringTerci(this.PTerci[1][1]);
                this.m_rez.m_ter1pl2 = this.StringTerci(this.PTerci[2][0]);
                this.m_rez.m_ter2pl2 = this.StringTerci(this.PTerci[2][1]);
                this.m_rez.m_ter1pl3 = this.StringTerci(this.PTerci[3][0]);
                this.m_rez.m_ter2pl3 = this.StringTerci(this.PTerci[3][1]);
                this.m_rez.m_ter1pl4 = this.StringTerci(this.PTerci[4][0]);
                this.m_rez.m_ter2pl4 = this.StringTerci(this.PTerci[4][1]);
                if (
                    (this.PTerci[1][0] > 30 &&
                        this.PTerci[1][0] > this.PTerci[2][0] &&
                        this.PTerci[1][0] > this.PTerci[2][1] &&
                        this.PTerci[1][0] > this.PTerci[4][0] &&
                        this.PTerci[1][0] > this.PTerci[4][1]) ||
                    (this.PTerci[3][0] > 30 &&
                        this.PTerci[3][0] > this.PTerci[2][0] &&
                        this.PTerci[3][0] > this.PTerci[2][1] &&
                        this.PTerci[3][0] > this.PTerci[4][0] &&
                        this.PTerci[3][0] > this.PTerci[4][1]) ||
                    (this.PTerci[1][1] > 30 &&
                        this.PTerci[1][1] > this.PTerci[2][0] &&
                        this.PTerci[1][1] > this.PTerci[2][1] &&
                        this.PTerci[1][1] > this.PTerci[4][0] &&
                        this.PTerci[1][1] > this.PTerci[4][1]) ||
                    (this.PTerci[3][1] > 30 &&
                        this.PTerci[3][1] > this.PTerci[2][0] &&
                        this.PTerci[3][1] > this.PTerci[2][1] &&
                        this.PTerci[3][1] > this.PTerci[4][0] &&
                        this.PTerci[3][1] > this.PTerci[4][1])
                ) {
                    if (this.PTerci[1][0] > 30) {
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[1][0]);
                        this.m_rez.dis[1][0] = true;
                    }
                    if (this.PTerci[1][1] > 30) {
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[1][1]);
                        this.m_rez.dis[1][1] = true;
                    }
                    if (this.PTerci[3][0] > 30) {
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[3][0]);
                        this.m_rez.dis[3][0] = true;
                    }
                    if (this.PTerci[3][1] > 30) {
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[3][1]);
                        this.m_rez.dis[3][1] = true;
                    }
                    if (this.PTerci[2][0] > 30) {
                        this.m_rez.dis[2][0] = false;
                    }
                    if (this.PTerci[2][1] > 30) {
                        this.m_rez.dis[2][1] = false;
                    }
                    if (this.PTerci[4][0] > 30) {
                        this.m_rez.dis[4][0] = false;
                    }
                    if (this.PTerci[4][1] > 30) {
                        this.m_rez.dis[4][1] = false;
                    }
                }
                if (
                    (this.PTerci[1][0] > 0 &&
                        this.PTerci[1][0] < 30 &&
                        (this.PTerci[1][0] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[1][0] >= this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                        (this.PTerci[1][0] >= this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                        (this.PTerci[1][0] >= this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                    (this.PTerci[3][0] > 0 &&
                        this.PTerci[3][0] < 30 &&
                        (this.PTerci[3][0] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[3][0] >= this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                        (this.PTerci[3][0] >= this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                        (this.PTerci[3][0] >= this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                    (this.PTerci[1][1] > 0 &&
                        this.PTerci[1][1] < 30 &&
                        (this.PTerci[1][1] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[1][1] >= this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                        (this.PTerci[1][1] >= this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                        (this.PTerci[1][1] >= this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                    (this.PTerci[3][1] > 0 &&
                        this.PTerci[3][1] < 30 &&
                        (this.PTerci[3][1] >= this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                        (this.PTerci[3][1] >= this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                        (this.PTerci[3][1] >= this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                        (this.PTerci[3][1] >= this.PTerci[4][1] || this.PTerci[4][1] > 30))
                ) {
                    if (
                        (this.PTerci[1][0] > 0 &&
                            this.PTerci[1][0] < 30 &&
                            (this.PTerci[1][0] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[1][0] > this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                            (this.PTerci[1][0] > this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                            (this.PTerci[1][0] > this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                        (this.PTerci[3][0] > 0 &&
                            this.PTerci[3][0] < 30 &&
                            (this.PTerci[3][0] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[3][0] > this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                            (this.PTerci[3][0] > this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                            (this.PTerci[3][0] > this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                        (this.PTerci[1][1] > 0 &&
                            this.PTerci[1][1] < 30 &&
                            (this.PTerci[1][1] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[1][1] > this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                            (this.PTerci[1][1] > this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                            (this.PTerci[1][1] > this.PTerci[4][1] || this.PTerci[4][1] > 30)) ||
                        (this.PTerci[3][1] > 0 &&
                            this.PTerci[3][1] < 30 &&
                            (this.PTerci[3][1] > this.PTerci[2][0] || this.PTerci[2][0] > 30) &&
                            (this.PTerci[3][1] > this.PTerci[2][1] || this.PTerci[2][1] > 30) &&
                            (this.PTerci[3][1] > this.PTerci[4][0] || this.PTerci[4][0] > 30) &&
                            (this.PTerci[3][1] > this.PTerci[4][1] || this.PTerci[4][1] > 30))
                    ) {
                        if (this.PTerci[1][0] > 0 && this.PTerci[1][0] < 30) {
                            this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[1][0]);
                            this.m_rez.dis[1][0] = true;
                        }
                        if (this.PTerci[1][1] > 0 && this.PTerci[1][1] < 30) {
                            this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[1][1]);
                            this.m_rez.dis[1][1] = true;
                        }
                        if (this.PTerci[3][0] > 0 && this.PTerci[3][0] < 30) {
                            this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[3][0]);
                            this.m_rez.dis[3][0] = true;
                        }
                        if (this.PTerci[3][1] > 0 && this.PTerci[3][1] < 30) {
                            this.m_rez.m_rez13 = this.m_rez.m_rez13 + this.PointTerci(this.PTerci[3][1]);
                            this.m_rez.dis[3][1] = true;
                        }
                    }
                    if (this.PTerci[2][0] > 0 && this.PTerci[2][0] < 30) {
                        this.m_rez.dis[2][0] = false;
                    }
                    if (this.PTerci[2][1] > 0 && this.PTerci[2][1] < 30) {
                        this.m_rez.dis[2][1] = false;
                    }
                    if (this.PTerci[4][0] > 0 && this.PTerci[4][0] < 30) {
                        this.m_rez.dis[4][0] = false;
                    }
                    if (this.PTerci[4][1] > 0 && this.PTerci[4][1] < 30) {
                        this.m_rez.dis[4][1] = false;
                    }
                }
                if (
                    (this.PTerci[2][0] > 30 &&
                        this.PTerci[2][0] > this.PTerci[1][0] &&
                        this.PTerci[2][0] > this.PTerci[1][1] &&
                        this.PTerci[2][0] > this.PTerci[3][0] &&
                        this.PTerci[2][0] > this.PTerci[3][1]) ||
                    (this.PTerci[4][0] > 30 &&
                        this.PTerci[4][0] > this.PTerci[1][0] &&
                        this.PTerci[4][0] > this.PTerci[1][1] &&
                        this.PTerci[4][0] > this.PTerci[3][0] &&
                        this.PTerci[4][0] > this.PTerci[3][1]) ||
                    (this.PTerci[2][1] > 30 &&
                        this.PTerci[2][1] > this.PTerci[1][0] &&
                        this.PTerci[2][1] > this.PTerci[1][1] &&
                        this.PTerci[2][1] > this.PTerci[3][0] &&
                        this.PTerci[2][1] > this.PTerci[3][1]) ||
                    (this.PTerci[4][1] > 30 &&
                        this.PTerci[4][1] > this.PTerci[1][0] &&
                        this.PTerci[4][1] > this.PTerci[1][1] &&
                        this.PTerci[4][1] > this.PTerci[3][0] &&
                        this.PTerci[4][1] > this.PTerci[3][1])
                ) {
                    if (this.PTerci[2][0] > 30) {
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[2][0]);
                        this.m_rez.dis[2][0] = true;
                    }
                    if (this.PTerci[2][1] > 30) {
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[2][1]);
                        this.m_rez.dis[2][1] = true;
                    }
                    if (this.PTerci[4][0] > 30) {
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[4][0]);
                        this.m_rez.dis[4][0] = true;
                    }
                    if (this.PTerci[4][1] > 30) {
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[4][1]);
                        this.m_rez.dis[4][1] = true;
                    }
                    if (this.PTerci[1][0] > 30) {
                        this.m_rez.dis[1][0] = false;
                    }
                    if (this.PTerci[1][1] > 30) {
                        this.m_rez.dis[1][1] = false;
                    }
                    if (this.PTerci[3][0] > 30) {
                        this.m_rez.dis[3][0] = false;
                    }
                    if (this.PTerci[3][1] > 30) {
                        this.m_rez.dis[3][1] = false;
                    }
                }
                if (
                    (this.PTerci[2][0] > 0 &&
                        this.PTerci[2][0] < 30 &&
                        (this.PTerci[2][0] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[2][0] >= this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                        (this.PTerci[2][0] >= this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                        (this.PTerci[2][0] >= this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                    (this.PTerci[4][0] > 0 &&
                        this.PTerci[4][0] < 30 &&
                        (this.PTerci[4][0] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[4][0] >= this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                        (this.PTerci[4][0] >= this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                        (this.PTerci[4][0] >= this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                    (this.PTerci[2][1] > 0 &&
                        this.PTerci[2][1] < 30 &&
                        (this.PTerci[2][1] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[2][1] >= this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                        (this.PTerci[2][1] >= this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                        (this.PTerci[2][1] >= this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                    (this.PTerci[4][1] > 0 &&
                        this.PTerci[4][1] < 30 &&
                        (this.PTerci[4][1] >= this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                        (this.PTerci[4][1] >= this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                        (this.PTerci[4][1] >= this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                        (this.PTerci[4][1] >= this.PTerci[3][1] || this.PTerci[3][1] > 30))
                ) {
                    if (
                        (this.PTerci[2][0] > 0 &&
                            this.PTerci[2][0] < 30 &&
                            (this.PTerci[2][0] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[2][0] > this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                            (this.PTerci[2][0] > this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                            (this.PTerci[2][0] > this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                        (this.PTerci[4][0] > 0 &&
                            this.PTerci[4][0] < 30 &&
                            (this.PTerci[4][0] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[4][0] > this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                            (this.PTerci[4][0] > this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                            (this.PTerci[4][0] > this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                        (this.PTerci[2][1] > 0 &&
                            this.PTerci[2][1] < 30 &&
                            (this.PTerci[2][1] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[2][1] > this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                            (this.PTerci[2][1] > this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                            (this.PTerci[2][1] > this.PTerci[3][1] || this.PTerci[3][1] > 30)) ||
                        (this.PTerci[4][1] > 0 &&
                            this.PTerci[4][1] < 30 &&
                            (this.PTerci[4][1] > this.PTerci[1][0] || this.PTerci[1][0] > 30) &&
                            (this.PTerci[4][1] > this.PTerci[1][1] || this.PTerci[1][1] > 30) &&
                            (this.PTerci[4][1] > this.PTerci[3][0] || this.PTerci[3][0] > 30) &&
                            (this.PTerci[4][1] > this.PTerci[3][1] || this.PTerci[3][1] > 30))
                    ) {
                        if (this.PTerci[2][0] > 0 && this.PTerci[2][0] < 30) {
                            this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[2][0]);
                            this.m_rez.dis[2][0] = true;
                        }
                        if (this.PTerci[2][1] > 0 && this.PTerci[2][1] < 30) {
                            this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[2][1]);
                            this.m_rez.dis[2][1] = true;
                        }
                        if (this.PTerci[4][0] > 0 && this.PTerci[4][0] < 30) {
                            this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[4][0]);
                            this.m_rez.dis[4][0] = true;
                        }
                        if (this.PTerci[4][1] > 0 && this.PTerci[4][1] < 30) {
                            this.m_rez.m_rez24 = this.m_rez.m_rez24 + this.PointTerci(this.PTerci[4][1]);
                            this.m_rez.dis[4][1] = true;
                        }
                    }
                    if (this.PTerci[1][0] > 0 && this.PTerci[1][0] < 30) {
                        this.m_rez.dis[1][0] = false;
                    }
                    if (this.PTerci[1][1] > 0 && this.PTerci[1][1] < 30) {
                        this.m_rez.dis[1][1] = false;
                    }
                    if (this.PTerci[3][0] > 0 && this.PTerci[3][0] < 30) {
                        this.m_rez.dis[3][0] = false;
                    }
                    if (this.PTerci[3][1] > 0 && this.PTerci[3][1] < 30) {
                        this.m_rez.dis[3][1] = false;
                    }
                }
            }
            this.m_rez.m_info = '';
            if (this.Naddavane < 5) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true || this.Pkazal[3][this.Naddavane] === true) {
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + 3) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 4) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 - 2) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + 4) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 4) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true) {
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + 3) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 4) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2) / 10;
                            this.m_rez.m_crez24 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + 4) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 4) / 10;
                            this.m_rez.m_crez24 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Kontra === 1 || this.Kontra === 3) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (2 * this.m_rez.m_rez24) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + this.m_rez.m_rez24 / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez24 = (2 * this.m_rez.m_rez24) / 10;
                            } else {
                                this.m_rez.m_crez24 = this.m_rez.m_rez13 / 10;
                            }
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Kontra === 2 || this.Kontra === 4) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (2 * this.m_rez.m_rez13) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + this.m_rez.m_rez13 / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez13 = (2 * this.m_rez.m_rez13) / 10;
                            } else {
                                this.m_rez.m_crez13 = this.m_rez.m_rez13 / 10;
                            }
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (4 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (4 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 - 2) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Rekontra === 1 || this.Rekontra === 3) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (4 * this.m_rez.m_rez24) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + this.m_rez.m_rez24 / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez24 = (4 * this.m_rez.m_rez24) / 10;
                            } else {
                                this.m_rez.m_crez24 = this.m_rez.m_rez24 / 10;
                            }
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Rekontra === 2 || this.Rekontra === 4) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (4 * this.m_rez.m_rez13) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + this.m_rez.m_rez13 / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez13 = (4 * this.m_rez.m_rez13) / 10;
                            } else {
                                this.m_rez.m_crez13 = this.m_rez.m_rez24 / 10;
                            }
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            if (this.Naddavane === 5) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true || this.Pkazal[3][this.Naddavane] === true) {
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + 4) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + 5) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 5) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true) {
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + 4) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 5) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            this.m_rez.m_crez24 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez13 === this.m_rez.m_rez24) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + 5) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 5) / 10;
                            this.m_rez.m_crez24 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Kontra === 1 || this.Kontra === 3) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Kontra === 2 || this.Kontra === 4) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez24 + this.m_rez.m_rez13) / 10;
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot');
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (4 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (4 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Rekontra === 1 || this.Rekontra === 3) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez24 = (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                            } else {
                                this.m_rez.m_crez24 = (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            }
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Rekontra === 2 || this.Rekontra === 4) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24)) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24) / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez13 = (2 * (this.m_rez.m_rez24 + this.m_rez.m_rez13)) / 10;
                            } else {
                                this.m_rez.m_crez13 = (this.m_rez.m_rez24 + this.m_rez.m_rez13) / 10;
                            }
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            if (this.Naddavane === 6) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.Pkazal[1][this.Naddavane] === true || this.Pkazal[3][this.Naddavane] === true) {
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + 5) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 6) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + 6) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + 6) / 10;
                            this.m_rez.m_crez13 = 0;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                    if (this.Pkazal[2][this.Naddavane] === true || this.Pkazal[4][this.Naddavane] === true) {
                        if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + 5) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 6) / 10;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                            if (this.Visiashti > 0) {
                                this.m_rez.m_info += '+ ';
                                this.m_rez.m_info += this.IntToString(this.Visiashti);
                                this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                            }
                            this.m_rez.m_info += ' - ';
                            this.m_rez.m_info += this.GetLanguageText('Inside');
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 + 2) / 10;
                            this.m_rez.m_crez24 = 0;
                            this.Visiashti = 0;
                        }
                        if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                            this.m_rez.m_crez24 = 0;
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez13 + 6) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez13 + 6) / 10;
                            this.m_rez.m_info += ' , ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                    }
                }
                if (this.Kontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (2 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 + 2)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 + 2) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (2 * (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Kontra === 1 || this.Kontra === 3) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            this.m_rez.m_crez24 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Kontra === 2 || this.Kontra === 4) {
                            this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            this.m_rez.m_crez13 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
                if (this.Rekontra > 0) {
                    if (this.kapo2 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez13 = this.m_rez.m_rez13 + 90;
                    }
                    if (this.kapo1 === true) {
                        this.m_rez.m_info = this.GetLanguageText('Capot'); //language
                        this.m_rez.m_rez24 = this.m_rez.m_rez24 + 90;
                    }
                    if (this.m_rez.m_rez13 > this.m_rez.m_rez24) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez13 = this.Visiashti + (4 * (this.m_rez.m_rez13 + this.m_rez.m_rez24 + 2)) / 10;
                        } else {
                            this.m_rez.m_crez13 = this.Visiashti + (this.m_rez.m_rez13 + this.m_rez.m_rez24 + 2) / 10;
                        }
                        this.m_rez.m_crez24 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 > this.m_rez.m_rez13) {
                        if (this.Visiashti > 0) {
                            this.m_rez.m_info += '+ ';
                            this.m_rez.m_info += this.IntToString(this.Visiashti);
                            this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                        }
                        if (!this.kapo1 && !this.kapo2) {
                            this.m_rez.m_crez24 = this.Visiashti + (4 * (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2)) / 10;
                        } else {
                            this.m_rez.m_crez24 = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                        }
                        this.m_rez.m_crez13 = 0;
                        this.Visiashti = 0;
                    }
                    if (this.m_rez.m_rez24 === this.m_rez.m_rez13) {
                        if (this.Rekontra === 1 || this.Rekontra === 3) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez24 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            } else {
                                this.m_rez.m_crez24 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            }
                            this.m_rez.m_crez13 = 0;
                        }
                        if (this.Rekontra === 2 || this.Rekontra === 4) {
                            if (!this.kapo1 && !this.kapo2) {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            } else {
                                this.Visiashti = this.Visiashti + (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            }
                            if (!this.kapo1 && !this.kapo2) {
                                this.m_rez.m_crez13 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            } else {
                                this.m_rez.m_crez13 = (this.m_rez.m_rez24 + this.m_rez.m_rez13 + 2) / 10;
                            }
                            this.m_rez.m_crez24 = 0;
                        }
                        this.m_rez.m_info += ' , ';
                        this.m_rez.m_info += this.IntToString(this.Visiashti);
                        this.m_rez.m_info += ' ' + this.GetLanguageText('Drawn game');
                    }
                }
            }
            for (let i = 1; i < 9; i++) {
                this.m_rez.vz[i] = this.vz[i];
            }
            for (let i = 1; i < 33; i++) {
                this.m_rez.kd[i] = this.kd[i];
            }
            this.open_rez_dlg = true;
            this.m_rez.m_crez13 = Math.floor(this.m_rez.m_crez13);
            this.m_rez.m_crez24 = Math.floor(this.m_rez.m_crez24);
            await this.m_rez.DoModal();
            this.open_rez_dlg = false;
            this.online_timer = this.online_timer_time;
        }
    }

    async InitORezDlg(FName: string[]) {
        if (!this.watch) {
            if (this.OP) {
                if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                    if (!this.op_start) {
                        this.SendGame('L');
                        this.op_start = true;
                    }
                }
            }
            for (let a = 0; a < 30; a++) {
                if (this.m_orez.orez[0][a] === -10) {
                    this.m_orez.orez[0][a] = this.m_rez.m_crez13;
                    this.m_orez.orez[1][a] = this.m_rez.m_crez24;
                    break;
                }
            }
            this.m_orez.m_rez1 = this.m_orez.m_rez2 = 0;

            if (this.OP) {
                if (this.Server) {
                    if (this.HaveWatch) {
                        this.SendResult(1);
                    }
                }
            }

            for (let i = 0; i < 2; i++) {
                for (let a = 0; a < 30; a++) {
                    if (this.m_orez.orez[i][a] > -10) {
                        if (i === 0) {
                            this.m_orez.m_rez1 = this.m_orez.m_rez1 + this.m_orez.orez[i][a];
                        } else {
                            this.m_orez.m_rez2 = this.m_orez.m_rez2 + this.m_orez.orez[i][a];
                        }
                    }
                }
            }
            this.open_orez_dlg = true;
            this.m_orez.Players = 4;
            for (let i = 1; i < 5; i++) {
                this.m_orez.Player[i] = FName[i];
            }
            if (this.OP) {
                let rezult = 'X|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                this.SendGame(rezult);
            }
            if (this.props.m_pwin.state.DlgORezultatVisible) {
                this.m_orez.AddRezults();
                this.m_orez.setState({});
            } else {
                await this.m_orez.DoModal();
            }
            this.open_orez_dlg = false;
            if (this.m_orez.m_rez1 < 151 && this.m_orez.m_rez2 < 151) {
                if (this.SP) {
                    await this.InitGame4();
                }
            } else {
                if (this.kapo1 === true || this.kapo2 === true) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't end the game with capot."));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.SP === true) {
                        await this.InitGame4();
                    }
                    this.EndOrezDlg();
                    return;
                }
                if (this.m_orez.m_rez1 === this.m_orez.m_rez2) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The score is even. There will be one more deal.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.SP === true) {
                        await this.InitGame4();
                    }
                    this.EndOrezDlg();
                    return;
                }
                let b4_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Belot4', '');
                let crezult = '';
                let ctime = this.getCDateTime();

                let player1 = FName[1];
                let player2 = FName[2];
                let player3 = FName[3];
                let player4 = FName[4];
                crezult =
                    ctime +
                    ' ' +
                    player1 +
                    ' ' +
                    this.GetLanguageText('and') +
                    ' ' +
                    player3 +
                    ' ' +
                    this.GetLanguageText('against') +
                    ' ' +
                    player2 +
                    ' ' +
                    this.GetLanguageText('and') +
                    ' ' +
                    player4 +
                    ' ';
                crezult =
                    crezult +
                    this.GetLanguageText('scoring') +
                    ' : ' +
                    this.IntToString(this.m_orez.m_rez1) +
                    ' - ' +
                    this.IntToString(this.m_orez.m_rez2);
                if (this.m_orez.m_rez1 > this.m_orez.m_rez2) {
                    crezult = crezult + 'w;';
                } else {
                    crezult = crezult + 'l;';
                }
                this.WriteProfileString('Options', 'Belot4', b4_rezult + crezult);
                if (this.m_orez.m_rez1 > this.m_orez.m_rez2) {
                    this.m_orez.m_pobedi = this.m_orez.m_pobedi + 1;
                    if (this.OP) {
                        let rezult = '$|n|1|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                        this.SendGame(rezult);
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                } else {
                    this.m_orez.m_zagubi = this.m_orez.m_zagubi + 1;
                    if (this.OP) {
                        let rezult = '$|n|0|' + this.IntToString(this.m_orez.m_rez1) + ' : ' + this.IntToString(this.m_orez.m_rez2);
                        this.SendGame(rezult);
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.GetLanguageText('The game profit') + ' ' + FName[2] + ' ' + this.GetLanguageText('and') + ' ' + FName[4]
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                if (this.OP) {
                    let rezult = 'X|0 : 0';
                    this.SendGame(rezult);
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    await this.InitNewGame();
                    if (this.OP) {
                        if (this.Server) {
                            if (this.HaveWatch) {
                                this.SendResult(1);
                            }
                        }
                    }
                } else {
                    if (this.OP) {
                        this.props.m_pwin.GoToLobby();
                    } else {
                        this.props.m_pwin.OnNewGame();
                    }
                    this.props.m_pwin.showAd();
                    return;
                }
            }
            this.EndOrezDlg();
        }
    }

    async InitNewGame() {
        this.op_start = false;
        this.m_orez.m_rez1 = 0;
        this.m_orez.m_rez2 = 0;
        this.Visiashti = 0;
        for (let i = 0; i < 2; i++) {
            for (let a = 0; a < 100; a++) {
                this.m_orez.orez[i][a] = -10;
            }
        }
        this.pause = 0;
        this.pause_player = '';
        if (this.SP === true) {
            await this.InitGame4();
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 0; a < 2; a++) {
                this.PTerci[i][a] = 0;
            }
            this.last_Name[i] = this.Name[i];
        }
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        if (this.SP === true || !this.Server || (this.OP && !this.P2OP && !this.P3OP && !this.P4OP)) {
            //    this.props.m_pwin.showAd();
        }
    }

    EndOrezDlg() {
        if (this.OP) {
            this.Faza = 6;
            if (this.Server) {
                this.SendBeginGameToWaitPlayers();
            }
        }
        this.setState({});
    }

    SendBeginGameToWaitPlayers() {
        //https://gitlab.com/alehandros/FavoriteGames/-/issues/63
        for (let i = 2; i <= 4; i++) {
            if (this.wait_opplayers[i]) {
                this.Name[i] = this.wait_opnames[i];
                this.show_user[i] = false;
                this.props.m_pwin.GetPlayerRating(i);
                if (i === 2) {
                    this.P2OP = true;
                    this.P2G = true;
                }
                if (i === 3) {
                    this.P3OP = true;
                    this.P3G = true;
                }
                if (i === 4) {
                    this.P4OP = true;
                    this.P4G = true;
                }
                this.SendResult(i);
                let message = '$|v|' + this.IntToString(i);
                this.SendGame(message);
                this.setState({});
            }
        }
        for (let i = 2; i <= 4; i++) {
            if (this.wait_opplayers[i]) {
                let new_name = '$|w|' + this.IntToString(i) + '|' + this.wait_opnames[i];
                this.SendGame(new_name);
                this.wait_opplayers[i] = false;
                this.wait_opnames[i] = '';
            }
        }
    }

    SendResult(player: number) {
        if (player === 1) {
            for (let a = 0; a < 29; a++) {
                if (this.m_orez.orez[0][a + 1] === -10) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]);
                        this.SendGame(message);
                    } else {
                        let message = '$|u|' + this.IntToString(player) + '|0|0';
                        this.SendGame(message);
                    }
                    return;
                }
            }
        } else {
            if (player === 2 || player === 4) {
                for (let a = 0; a < 30; a++) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]);
                        this.SendGame(message);
                    }
                }
            }
            if (player === 3) {
                for (let a = 0; a < 30; a++) {
                    if (this.m_orez.orez[0][a] > -10) {
                        let message =
                            '$|u|' +
                            this.IntToString(player) +
                            '|' +
                            this.IntToString(this.m_orez.orez[0][a]) +
                            '|' +
                            this.IntToString(this.m_orez.orez[1][a]);
                        this.SendGame(message);
                    }
                }
            }
            this.SendGame('$|*|b|' + this.IntToString(this.Visiashti));
        }
    }

    async Timer4() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.Move !== true) {
            if (this.strelka > 0) {
                if (this.hand === 1) {
                    this.obiaviavaneRN = null;
                    this.setState({});
                }
                for (let i = 1; i <= 4; i++) {
                    if (this.Belot[i][1] === 1) {
                        this.setState({});
                    }
                }
            }
            if (this.SP === true) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.props.m_pwin.KillTimer(3);
                    this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
                    this.setState({});
                    for (let i = 1; i < 9; i++) {
                        if (this.k[i] !== 0) {
                            return;
                        }
                    }
                    let FName: string[] = [];
                    for (let i = 1; i <= this.Players; i++) {
                        FName[i] = this.Name[i];
                    }
                    await this.InitRezDlg();
                    await this.InitORezDlg(FName);
                    this.setState({});
                }
            }
            if (this.OP) {
                if (this.strelka > 0) {
                    this.InitVziatka();
                    this.setState({});
                    for (let i = 1; i < 33; i++) {
                        if (this.k[i] !== 0) {
                            return;
                        }
                    }
                    this.Faza = 7;
                    let FName: string[] = [];
                    for (let i = 1; i <= this.Players; i++) {
                        FName[i] = this.Name[i];
                    }
                    await this.InitRezDlg();
                    await this.InitORezDlg(FName);
                    if (this.props.m_pwin.game !== '') {
                        if (this.CheckMessage()) {
                            this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Karti:' + this.GetAllMessages());
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.props.m_pwin.SendErrorToLog('Ostanalo saobshtenie Annonsi' + this.GetAllAMessages());
                            this.ClearAMessages();
                        }
                        if (!this.Server && !this.watch) {
                            this.SendGame('R');
                        }
                    }
                    this.setState({});
                }
            }
        }
    }

    async Timer7() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (!this.watch) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (this.online_timer > 0) {
                    this.online_timer = this.props.m_pwin.props.isAppActive
                        ? this.online_timer - 1
                        : this.online_timer - Math.floor(this.online_timer_time / 3);
                }
                if (this.online_timer === 5) {
                    if (
                        this.OP &&
                        (this.P2OP ||
                            this.wait_opplayers[2] ||
                            this.P3OP ||
                            this.wait_opplayers[3] ||
                            this.P4OP ||
                            this.wait_opplayers[4] ||
                            !this.Server)
                    ) {
                        if (this.OP && this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                            if (GlobalVariables.Zvuk === 1) {
                                this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                            }
                        }
                    }
                }
                this.setState({});
                if (this.OP && this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                    if (this.online_timer <= 0) {
                        this.online_timer = this.online_timer_time;
                        this.m_n.klik_o = true;
                        this.m_n.OnOK();
                    }
                }
                if (this.Faza === 7) {
                    if (this.online_timer <= 0) {
                        if (this.open_rez_dlg) {
                            if (this.m_rez.dlg_det_start) {
                                this.m_rez.dlg_det.OnOK();
                            }
                            this.m_rez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                        if (this.open_orez_dlg && !this.open_rez_dlg) {
                            this.m_orez.OnOK();
                            this.online_timer = this.online_timer_time;
                            return;
                        }
                    }
                }
            }
        }
    }

    async Init4() {
        this.props.m_pwin.ShowInfoBar(true);
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        if (this.P3OP === true) {
            this.P3G = false;
        } else {
            this.P3G = true;
        }
        if (this.P4OP === true) {
            this.P4G = false;
        } else {
            this.P4G = true;
        }
        this.m_orez.m_zagubi = 0;
        this.m_orez.m_pobedi = 0;
        this.Players = 4;
        await this.Init();
        this.pause = 0;
        this.pause_player = '';
        if (this.SP) {
            this.Name[3] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[3]', 'Computer3');
            this.Name[4] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[4]', 'Computer4');
        }
        for (let i = 1; i < 5; i++) {
            this.m_orez.Player[i] = this.Name[i];
        }
        this.m_orez.Players = 4;
        this.StrelkaPos[1].x = 550;
        this.StrelkaPos[1].y = 350;
        this.StrelkaPos[2].x = 650;
        this.StrelkaPos[2].y = 280;
        this.StrelkaPos[3].x = 220;
        this.StrelkaPos[3].y = 130;
        this.StrelkaPos[4].x = 110;
        this.StrelkaPos[4].y = 150;
        this.NamePos[1].x = 360;
        this.NamePos[1].y = 388;
        this.NamePos[2].x = 608;
        this.NamePos[2].y = 259;
        this.NamePos[3].x = 360;
        this.NamePos[3].y = 138;
        this.NamePos[4].x = 110;
        this.NamePos[4].y = 259;

        this.PosNaddavane[1].x = 340;
        this.PosNaddavane[1].y = 350;
        this.PosNaddavane[2].x = 590;
        this.PosNaddavane[2].y = 230;
        this.PosNaddavane[3].x = 340;
        this.PosNaddavane[3].y = 110;
        this.PosNaddavane[4].x = 130;
        this.PosNaddavane[4].y = 230;

        this.PosBelot[1].x = 330;
        this.PosBelot[1].y = 360;
        this.PosBelot[2].x = 540;
        this.PosBelot[2].y = 230;
        this.PosBelot[3].x = 330;
        this.PosBelot[3].y = 110;
        this.PosBelot[4].x = 90;
        this.PosBelot[4].y = 230;

        this.PosTerci[1][0].x = 389;
        this.PosTerci[1][0].y = 363;
        this.PosTerci[1][1].x = 446;
        this.PosTerci[1][1].y = 363;
        this.PosTerci[2][0].x = 598;
        this.PosTerci[2][0].y = 230;
        this.PosTerci[2][1].x = 656;
        this.PosTerci[2][1].y = 230;
        this.PosTerci[3][0].x = 389;
        this.PosTerci[3][0].y = 110;
        this.PosTerci[3][1].x = 446;
        this.PosTerci[3][1].y = 110;
        this.PosTerci[4][0].x = 148;
        this.PosTerci[4][0].y = 230;
        this.PosTerci[4][1].x = 206;
        this.PosTerci[4][1].y = 230;

        this.CardsPos[0].x = 230;
        this.CardsPos[0].y = 180;

        this.CardsPosDeal[1].x = 417;
        this.CardsPosDeal[1].y = 320;
        this.CardsPosDeal[2].x = 635;
        this.CardsPosDeal[2].y = 285;
        this.CardsPosDeal[3].x = 470;
        this.CardsPosDeal[3].y = 110;
        this.CardsPosDeal[4].x = 110;
        this.CardsPosDeal[4].y = 140;

        //TODO
        this.CardsPosDeal[1].x = this.CardsPos[0].x;
        this.CardsPosDeal[2].x = this.CardsPos[0].x;
        this.CardsPosDeal[3].x = this.CardsPos[0].x;
        this.CardsPosDeal[4].x = this.CardsPos[0].x;
        this.CardsPosDeal[1].y = this.CardsPos[0].y;
        this.CardsPosDeal[2].y = this.CardsPos[0].y;
        this.CardsPosDeal[3].y = this.CardsPos[0].y;
        this.CardsPosDeal[4].y = this.CardsPos[0].y;
        //TODO CHange refresh deal cards//https://gitlab.com/alehandros/FavoriteGames/-/issues/367

        this.CardsPos[0] = this.CardsPosDeal[4];

        this.CardsPos[1].x = 167;
        this.CardsPos[1].y = 418;
        this.CardsPos[2].x = 224;
        this.CardsPos[2].y = 418;
        this.CardsPos[3].x = 281;
        this.CardsPos[3].y = 418;
        this.CardsPos[4].x = 338;
        this.CardsPos[4].y = 418;
        this.CardsPos[5].x = 395;
        this.CardsPos[5].y = 418;
        this.CardsPos[6].x = 452;
        this.CardsPos[6].y = 418;
        this.CardsPos[7].x = 509;
        this.CardsPos[7].y = 418;
        this.CardsPos[8].x = 566;
        this.CardsPos[8].y = 418;

        this.CardsPos[9].x = 710;
        this.CardsPos[9].y = 275;
        this.CardsPos[10].x = 710;
        this.CardsPos[10].y = 250;
        this.CardsPos[11].x = 710;
        this.CardsPos[11].y = 225;
        this.CardsPos[12].x = 710;
        this.CardsPos[12].y = 200;
        this.CardsPos[13].x = 710;
        this.CardsPos[13].y = 175;
        this.CardsPos[14].x = 710;
        this.CardsPos[14].y = 150;
        this.CardsPos[15].x = 710;
        this.CardsPos[15].y = 125;
        this.CardsPos[16].x = 710;
        this.CardsPos[16].y = 100;

        this.CardsPos[17].x = 505;
        this.CardsPos[17].y = 22;
        this.CardsPos[18].x = 465;
        this.CardsPos[18].y = 22;
        this.CardsPos[19].x = 425;
        this.CardsPos[19].y = 22;
        this.CardsPos[20].x = 385;
        this.CardsPos[20].y = 22;
        this.CardsPos[21].x = 345;
        this.CardsPos[21].y = 22;
        this.CardsPos[22].x = 305;
        this.CardsPos[22].y = 22;
        this.CardsPos[23].x = 265;
        this.CardsPos[23].y = 22;
        this.CardsPos[24].x = 225;
        this.CardsPos[24].y = 22;

        this.CardsPos[25].x = 22;
        this.CardsPos[25].y = 100;
        this.CardsPos[26].x = 22;
        this.CardsPos[26].y = 125;
        this.CardsPos[27].x = 22;
        this.CardsPos[27].y = 150;
        this.CardsPos[28].x = 22;
        this.CardsPos[28].y = 175;
        this.CardsPos[29].x = 22;
        this.CardsPos[29].y = 200;
        this.CardsPos[30].x = 22;
        this.CardsPos[30].y = 225;
        this.CardsPos[31].x = 22;
        this.CardsPos[31].y = 250;
        this.CardsPos[32].x = 22;
        this.CardsPos[32].y = 275;

        this.CardsPos[33].x = 360;
        this.CardsPos[33].y = 270;
        this.CardsPos[34].x = 435;
        this.CardsPos[34].y = 220;
        this.CardsPos[35].x = 360;
        this.CardsPos[35].y = 170;
        this.CardsPos[36].x = 285;
        this.CardsPos[36].y = 220;
    }

    async InitGame4() {
        this.op_start = false;
        for (let i = 1; i < 9; i++) {
            this.vz[i] = 0;
        }
        for (let i = 1; i < 33; i++) {
            this.kd[i] = 0;
        }
        this.m_rez.m_rez13 = 0;
        this.m_rez.m_rez24 = 0;
        this.m_rez.m_bel1 = '';
        this.m_rez.m_bel2 = '';
        this.m_rez.m_bel3 = '';
        this.m_rez.m_bel4 = '';
        this.m_rez.m_ter1pl1 = '';
        this.m_rez.m_ter2pl1 = '';
        this.m_rez.m_ter1pl2 = '';
        this.m_rez.m_ter2pl2 = '';
        this.m_rez.m_ter1pl3 = '';
        this.m_rez.m_ter2pl3 = '';
        this.m_rez.m_ter1pl4 = '';
        this.m_rez.m_ter2pl4 = '';
        if (this.KoiZapochva < 4) {
            this.KoiZapochva = this.KoiZapochva + 1;
        } else {
            this.KoiZapochva = 1;
        }
        this.CardsPos[0].x = this.CardsPosDeal[this.KoiZapochva].x;
        this.CardsPos[0].y = this.CardsPosDeal[this.KoiZapochva].y;
        this.showrcard = 32;
        this.InitGame();
        for (let i = 0; i < 33; i++) {
            this.k[i] = 0;
            this.CardsPoint[i].x = this.CardsPosDeal[this.KoiZapochva].x;
            this.CardsPoint[i].y = this.CardsPosDeal[this.KoiZapochva].y;
        }
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.strelka = 0;
        this.Naddavane = 0;
        if ((this.OP && this.Server) || this.SP) {
            this.RandCards();
        }
        /*    this.k[1] = 11;
        this.k[2] = 16;
        this.k[3] = 17;
        this.k[4] = 32;
        this.k[5] = 33;
        this.k[6] = 34;
        this.k[7] = 47;
        this.k[8] = 45;*/
        if ((this.OP && this.Server) || this.SP) {
            this.LoadCards(false, true);
        }
        if (this.SP || (this.OP && this.Server)) {
            this.Razdavane1 = true;
        }
        this.open_rez_dlg = false;
        this.open_orez_dlg = false;
        if (this.OP) {
            if (!this.watch) {
                this.SendGame('$|y');
            }
        }
        for (let i = 1; i < 5; i++) {
            if (this.last_Name[i] === '') {
                this.last_Name[i] = this.Name[i];
            }
        }
        this.obiaviavaneRN = null;
    }

    RandCards() {
        let Numbers: number[] = [];
        for (let i = 0; i < 32; i++) {
            Numbers[i] = i + 1;
        }
        let randomArray = this.randomShuffle(Numbers);
        for (let i = 1; i < 33; i++) {
            if (randomArray[i - 1] === 1) {
                this.k[i] = 11;
            }
            if (randomArray[i - 1] === 2) {
                this.k[i] = 12;
            }
            if (randomArray[i - 1] === 3) {
                this.k[i] = 13;
            }
            if (randomArray[i - 1] === 4) {
                this.k[i] = 14;
            }
            if (randomArray[i - 1] === 5) {
                this.k[i] = 15;
            }
            if (randomArray[i - 1] === 6) {
                this.k[i] = 16;
            }
            if (randomArray[i - 1] === 7) {
                this.k[i] = 17;
            }
            if (randomArray[i - 1] === 8) {
                this.k[i] = 18;
            }
            if (randomArray[i - 1] === 9) {
                this.k[i] = 21;
            }
            if (randomArray[i - 1] === 10) {
                this.k[i] = 22;
            }
            if (randomArray[i - 1] === 11) {
                this.k[i] = 23;
            }
            if (randomArray[i - 1] === 12) {
                this.k[i] = 24;
            }
            if (randomArray[i - 1] === 13) {
                this.k[i] = 25;
            }
            if (randomArray[i - 1] === 14) {
                this.k[i] = 26;
            }
            if (randomArray[i - 1] === 15) {
                this.k[i] = 27;
            }
            if (randomArray[i - 1] === 16) {
                this.k[i] = 28;
            }
            if (randomArray[i - 1] === 17) {
                this.k[i] = 31;
            }
            if (randomArray[i - 1] === 18) {
                this.k[i] = 32;
            }
            if (randomArray[i - 1] === 19) {
                this.k[i] = 33;
            }
            if (randomArray[i - 1] === 20) {
                this.k[i] = 34;
            }
            if (randomArray[i - 1] === 21) {
                this.k[i] = 35;
            }
            if (randomArray[i - 1] === 22) {
                this.k[i] = 36;
            }
            if (randomArray[i - 1] === 23) {
                this.k[i] = 37;
            }
            if (randomArray[i - 1] === 24) {
                this.k[i] = 38;
            }
            if (randomArray[i - 1] === 25) {
                this.k[i] = 41;
            }
            if (randomArray[i - 1] === 26) {
                this.k[i] = 42;
            }
            if (randomArray[i - 1] === 27) {
                this.k[i] = 43;
            }
            if (randomArray[i - 1] === 28) {
                this.k[i] = 44;
            }
            if (randomArray[i - 1] === 29) {
                this.k[i] = 45;
            }
            if (randomArray[i - 1] === 30) {
                this.k[i] = 46;
            }
            if (randomArray[i - 1] === 31) {
                this.k[i] = 47;
            }
            if (randomArray[i - 1] === 32) {
                this.k[i] = 48;
            }
        }
    }

    async InitSP() {
        this.loading = true;
        this.SP = true;
        this.OP = false;
        await this.Init4();
        await this.InitGame4();
        this.loading = false;
    }

    KartaZaRazdavane() {
        if (this.KoiZapochva === 1) {
            if (this.Razdavane1 === true) {
                this.showrcard = 31;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 30;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 29;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 28;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 27;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 26;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 25;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 24;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 23;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 22;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 21;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 20;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 19;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 18;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 17;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 16;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 15;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 14;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 13;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 12;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                this.showrcard = 11;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 10;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 9;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 8;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 7;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 6;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 5;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 4;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 3;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 2;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 1;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 0;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        if (this.KoiZapochva === 2) {
            if (this.Razdavane1 === true) {
                this.showrcard = 31;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 30;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 29;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 28;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 27;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 26;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 25;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 24;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 23;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 22;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 21;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 20;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 19;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 18;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 17;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 16;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 15;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 14;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 13;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 12;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                this.showrcard = 11;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 10;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 9;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 8;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 7;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 6;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 5;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 4;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 3;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 2;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 1;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 0;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        if (this.KoiZapochva === 3) {
            if (this.Razdavane1 === true) {
                this.showrcard = 31;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 30;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 29;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 28;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 27;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 26;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 25;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 24;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 23;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 22;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 21;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 20;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 19;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 18;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 17;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 16;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 15;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 14;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 13;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 12;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                this.showrcard = 11;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 10;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 9;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 8;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 7;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 6;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 5;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 4;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 3;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 2;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 1;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 0;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        if (this.KoiZapochva === 4) {
            if (this.Razdavane1 === true) {
                this.showrcard = 31;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 30;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 29;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 28;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 27;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 26;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 25;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 24;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 23;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 22;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 21;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 20;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 19;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 18;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 17;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 16;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 15;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 14;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 13;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 12;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.online_timer = this.online_timer_time;
            }
            if (this.Razdavane2 === true) {
                this.showrcard = 11;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 10;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 9;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 8;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 7;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 6;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 5;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 4;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 3;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 2;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 1;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 0;
                this.Razdavane2 = false;
                this.Faza = 4;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.online_timer = this.online_timer_time;
            }
        }
        return 0;
    }

    OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);
        if (this.OP) {
            if (GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                this.cursor = GlobalConstants.CURSOR_WATCH;
                this.setState({});
                return;
            }
            if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                if (
                    this.P2OP ||
                    this.wait_opplayers[2] ||
                    this.P3OP ||
                    this.wait_opplayers[3] ||
                    this.P4OP ||
                    this.wait_opplayers[4] ||
                    !this.Server
                ) {
                    if (this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1 && this.pause === 0) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                        this.setState({});
                        return;
                    }
                }
            }
        }
        for (let i = 1; i < 9; i++) {
            if (
                this.Faza > 4 &&
                this.k[i] !== 0 &&
                point.x >
                    (this.CardsPos[i].x - ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8) * GlobalVariables.scale &&
                (point.x <
                    (this.CardsPos[i].x - ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8) * GlobalVariables.scale +
                        GlobalConstants.CARD_WIDTH * GlobalVariables.scale +
                        (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 8 ||
                    (i === 8 - this.hand &&
                        point.x <
                            (this.CardsPos[i].x - ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8) *
                                GlobalVariables.scale +
                                GlobalConstants.PLAYER_CARD_WIDTH * GlobalVariables.scale)) &&
                point.y > this.CardsPos[i].y * GlobalVariables.scale &&
                point.y < this.CardsPos[i].y * GlobalVariables.scale + GlobalConstants.PLAYER_CARD_HEIGHT * GlobalVariables.scale
            ) {
                if (this.ProverkaCursor(i)) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                } else {
                    if (this.KoiENaRed !== 1) {
                        this.cursor = GlobalConstants.CURSOR_WAIT;
                    } else {
                        this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                    }
                }
                this.setState({});
                return;
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    OnRButtonDown(point: CPoint) {
        if (this.watch) {
            for (let i = 2; i <= 4; i++) {
                if (this.Name[i] === 'Computer2' || this.Name[i] === 'Computer3' || this.Name[i] === 'Computer4') {
                    let s: CSize = this.GetTextExtent(this.Name[i], 13);
                    let width = s.cx;
                    if (
                        this.ScaleRect(new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)).PtInRect(
                            point
                        )
                    ) {
                        this.props.m_pwin.GoToLobby();
                        this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                    }
                }
            }
        }
    }

    async OnLButtonDown(point: CPoint) {
        if (this.OP) {
            for (let i = 1; i <= 4; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 4; i++) {
                        if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!this.watch) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (this.OP && this.Faza > 4 && this.strelka < 1 && this.P1puska === 0 && this.Move === false && this.KoiENaRed === 1) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }
            if (this.strelka < 1 && this.P1puska === 0) {
                for (let i = 1; i <= 8; i++) {
                    if (
                        (this.CardsPos[i].x - ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8) *
                            GlobalVariables.scale <
                            point.x &&
                        ((this.CardsPos[i].x -
                            ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8 +
                            GlobalConstants.CARD_WIDTH) *
                            GlobalVariables.scale >
                            point.x ||
                            ((this.CardsPos[i].x -
                                ((8 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 8 +
                                GlobalConstants.PLAYER_CARD_WIDTH) *
                                GlobalVariables.scale >
                                point.x &&
                                i === 8 - this.hand)) &&
                        this.CardsPos[i].y * GlobalVariables.scale < point.y &&
                        (this.CardsPos[i].y + GlobalConstants.PLAYER_CARD_HEIGHT) * GlobalVariables.scale > point.y &&
                        this.k[i] !== 0
                    ) {
                        if (this.ProverkaCursor(i) === true) {
                            if (this.SP === true) {
                                await this.PuskaneNaKarta(i);
                            }
                            if (this.OP === true) {
                                await this.PuskaneNaKartaOP(i);
                            }
                            if (this.OP && !this.watch) {
                                this.sit_out_count = 0;
                                if (this.m_orez.m_rez1 === 0 && this.m_orez.m_rez2 === 0) {
                                    if (!this.op_start) {
                                        this.SendGame('L');
                                        this.op_start = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    DrawObiaviavaneRN() {
        return this.obiaviavaneRN;
    }

    DrawView() {
        return (
            <View>
                <DrawNames name={this.Name} position={this.NamePos} cursor={this.cursor} key={'names'} />
                {this.DrawTimer()}
                {this.DrawPause()}
                {this.DrawRating()}
                <DrawPoints
                    m_rez1={this.m_orez.m_rez1}
                    m_rez2={this.m_orez.m_rez2}
                    m_main={this}
                    Players={this.Players}
                    position={new CPoint(590, 35)}
                />
                {this.DrawCards()}
                {this.DrawObiaviavaneRN()}
                {this.DrawStrelkaPng(this.strelka, this.props.m_pwin.game)}
                <DrawBid
                    Naddavane={this.Naddavane}
                    kojKazal={
                        this.Pkazal[1][this.Naddavane]
                            ? 1
                            : this.Pkazal[2][this.Naddavane]
                            ? 2
                            : this.Pkazal[3][this.Naddavane]
                            ? 3
                            : this.Pkazal[4][this.Naddavane]
                            ? 4
                            : -1
                    }
                    Kontra={this.Kontra}
                    Rekontra={this.Rekontra}
                    positionBid={
                        this.CardsPos[
                            this.Pkazal[1][this.Naddavane] ? 1 : this.Pkazal[2][this.Naddavane] ? 9 : this.Pkazal[3][this.Naddavane] ? 17 : 25
                        ]
                    }
                    positionKontra={this.CardsPos[1 + (this.Kontra - 1) * 8]}
                    positionRekontra={this.CardsPos[1 + (this.Rekontra - 1) * 8]}
                    game="Belote"
                />
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
                <CAnnouncement onRef={(ref: CAnnouncement) => (this.m_ann = ref)} parent={this} m_pwin={this.props.m_pwin} key={'CAnnouncement'} />
            </View>
        );
    }

    render() {
        if (this.props.m_pwin.game === 'Belot4' && !this.loading && !this.loadingMain) {
            if (Platform.OS === 'web') {
                return (
                    <View key={'webview'}>
                        <div key={'mousemoveBelot4'} onMouseMove={this.OnMouseMove.bind(this)}>
                            {this.DrawView()}
                        </div>
                    </View>
                );
            } else {
                return <View key={'mobileviewBelot4'}>{this.DrawView()}</View>;
            }
        } else {
            return null;
        }
    }
}

export default CBelot4Players;
