import React, { Component } from 'react';
import { View, Pressable, Switch, StyleSheet, TextInput, Keyboard, ScrollView, Dimensions, Platform } from 'react-native';
import { styles } from '../../css/container';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import Button from '../Components/core/Button';
import GlobalConstants from '../Components/GlobalConstants';
import Text from '../Components/core/Text';
import GlobalVariables from '../Components/GlobalVariables';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import InputField from '~/Components/core/InputField';

class ChangePasswordScreen extends Component<BaseDlgProps> {
    ok: boolean = false;
    start: number;
    oldPasswordRef: TextInput;
    passwordRef: TextInput;
    password2Ref: TextInput;
    passwordVisibility: boolean = true;
    passwordConfirmVisibility: boolean = true;
    oldPasswordVisibility: boolean = true;
    old_password: string = '';
    m_password: string = '';
    m_password2: string = '';

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    setOldPasswordVisibility(oldPasswordVisibility: boolean) {
        this.oldPasswordVisibility = oldPasswordVisibility;
        this.setState({});
    }
    setPasswordVisibility(passwordVisibility: boolean) {
        this.passwordVisibility = passwordVisibility;
        this.setState({});
    }
    setPasswordConfirmVisibility(passwordConfirmVisibility) {
        this.passwordConfirmVisibility = passwordConfirmVisibility;
        this.setState({});
    }

    async validatePassword(): Promise<boolean> {
        if (this.m_password.length < 3) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('The password must be greater than 2 chars');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (!this.props.m_pwin.m_main.CheckUsernameAndPassword(this.m_password)) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Unacceptable characters in the password');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (this.m_password !== this.m_password2) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                'The second password that you provided is different from the first one'
            );
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        if (this.m_password.length === 0) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please enter a Password');
            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            return false;
        }
        return true;
    }

    async updatePassword() {
        if (await this.validatePassword()) {
            let message: string = 'S|' + this.old_password + '|' + this.m_password + '|' + this.m_password2;
            this.props.m_pwin.SendGame(message);
        }
    }

    async DoModal() {
        this.props.m_pwin.state.ChangePasswordVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.ChangePasswordVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDCANCEL;
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    OnOk() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ ChangePasswordVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ ChangePasswordVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.ChangePasswordVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 5 * GlobalVariables.scale,
                                width: 780 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Change Password</Text>
                        </View>
                        <ScrollView style={[styles.W100, { height: Dimensions.get('window').height * 0.7 }]}>
                            <View style={cstyles.changePasswordContainer}>
                                <View style={styles.row}>
                                    <View style={cstyles.changePasswordInput}>
                                    <InputField
                                            blurOnSubmit={false}
                                            refinput={(ref: TextInput) => {
                                                this.oldPasswordRef = ref;
                                            }}
                                            onSubmitEditing={() => {
                                                this.passwordRef.focus();
                                            }}
                                            autoFocus={false}
                                            textContentType="password"
                                            onChangeText={(old_password) => (this.old_password = old_password)}
                                            placeholder={'Old Password'}
                                            placeholderTextColor="#8b9cb5"
                                            keyboardType="default"
                                            maxLength={12}
                                            height={40 * GlobalVariables.scale}
                                            underlineColorAndroid="#f000"
                                            returnKeyType="next"
                                            leftIcon="lock"
                                            secureTextEntry={this.oldPasswordVisibility}
                                            rightIcon={this.oldPasswordVisibility ? 'eye' : 'eye-off'}
                                            onRightIconPress={() => this.setOldPasswordVisibility(!this.oldPasswordVisibility)}
                                            defaultValue=""
                                            required={true}
                                        />
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={cstyles.changePasswordInput}>
                                        <InputField
                                            blurOnSubmit={false}
                                            refinput={(ref: TextInput) => {
                                                this.passwordRef = ref;
                                            }}
                                            onSubmitEditing={() => {
                                                this.password2Ref.focus();
                                            }}
                                            autoFocus={false}
                                            textContentType="password"
                                            onChangeText={(password) => (this.m_password = password)}
                                            placeholder={'Password'}
                                            placeholderTextColor="#8b9cb5"
                                            keyboardType="default"
                                            maxLength={12}
                                            height={40 * GlobalVariables.scale}
                                            underlineColorAndroid="#f000"
                                            returnKeyType="next"
                                            leftIcon="lock"
                                            secureTextEntry={this.passwordVisibility}
                                            rightIcon={this.passwordVisibility ? 'eye' : 'eye-off'}
                                            onRightIconPress={() => this.setPasswordVisibility(!this.passwordVisibility)}
                                            defaultValue=""
                                            required={true}
                                        />
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={cstyles.changePasswordInput}>
                                        <InputField
                                            refinput={(ref: TextInput) => {
                                                this.password2Ref = ref;
                                            }}
                                            onSubmitEditing={() => {
                                                Keyboard.dismiss;
                                            }}
                                            autoFocus={false}
                                            onChangeText={(password2) => (this.m_password2 = password2)}
                                            placeholder={'Retype password'}
                                            placeholderTextColor="#8b9cb5"
                                            keyboardType="default"
                                            maxLength={12}
                                            height={40 * GlobalVariables.scale}
                                            underlineColorAndroid="#f000"
                                            returnKeyType="done"
                                            leftIcon="lock"
                                            secureTextEntry={this.passwordConfirmVisibility}
                                            textContentType="password"
                                            rightIcon={this.passwordConfirmVisibility ? 'eye' : 'eye-off'}
                                            onRightIconPress={() => this.setPasswordConfirmVisibility(!this.passwordConfirmVisibility)}
                                            defaultValue=""
                                            required={true}
                                        />
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <Button
                                        textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                        onPress={() => this.updatePassword()}
                                        text="Save"
                                        containerStyle={modal.buttonStyle2PerRow}
                                    />
                                </View>
                                <View style={styles.row}>
                                    <Button
                                        textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                        containerStyle={modal.buttonStyle2PerRow}
                                        onPress={() => this.OnOk()}
                                        text="Close"
                                    />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default ChangePasswordScreen;

const cstyles = StyleSheet.create({
    changePasswordContainer: {
        borderWidth: 1,
        borderColor: 'gray',
        padding: 1,
        marginBottom: 1,
    },
    switchStyle: {
        height: 48,
        marginRight: 15,
        transform: Platform.OS === 'android' ? [{ scaleX: 2 }, { scaleY: 2 }] : [{ scaleX: 1 }, { scaleY: 1 }],
    },
    changePasswordInput: { width: '48%' },
    changePassword: { width: '17%' },
    textWithSwith: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        paddingBottom: 3,
        height: 50,
    },
    text: { height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 * GlobalVariables.scale },
    pools: { flexDirection: 'row' },
});
