import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';
import { Table, Row } from 'react-native-table-component-2';

class CRubberResult extends Component<ParentDlgProps> {
    tableHeadUp = [];
    widthArrUp = [];
    tableDataUp = [];
    scrollViewUp: any;
    tableHeadUnder = [];
    widthArrUnder = [];
    tableDataUnder = [];
    scrollViewUnder: any;

    m_final_score: string;
    GetHands: number;
    level: number;
    Whose: number;
    zone: number;

    start: number;
    BroiUnderResult: number = 0;
    BroiUpResult: number = 0;
    UnderResult = []; //[50][3]
    UpResult = []; //[100][3]
    cstyles = stylesGenerator();

    constructor(props: ParentDlgProps) {
        super(props);
        for (let i = 0; i < 50; i++) {
            this.UnderResult[i] = [];
            for (let a = 0; a < 3; a++) {
                this.UnderResult[i][a] = 0;
            }
        }
        for (let i = 0; i < 100; i++) {
            this.UpResult[i] = [];
            for (let a = 0; a < 3; a++) {
                this.UpResult[i][a] = 0;
            }
        }
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    DeleteAllItems() {
        this.tableDataUp = [];
        this.tableDataUnder = [];
    }

    FinalResult() {
        let s1 = 0,
            s2 = 0;
        for (let i = 0; i < this.BroiUnderResult; i++) {
            s1 += this.UnderResult[i][0];
            s2 += this.UnderResult[i][1];
        }
        for (let i = 0; i < this.BroiUpResult; i++) {
            s1 += this.UpResult[i][0];
            s2 += this.UpResult[i][1];
        }
        this.m_final_score =
            this.props.m_pwin.m_main.IntToString(s1) +
            '   :   ' +
            this.props.m_pwin.m_main.IntToString(s2) +
            '     ' +
            this.props.m_pwin.m_main.GetLanguageText('Final Result');
        this.setState({});
    }
    AddRezults() {
        for (let i = 0; i < 50; i++) {
            if (this.UnderResult[i][0] !== 0 || this.UnderResult[i][1] !== 0 || this.UnderResult[i][2] !== 0) {
                let row = [];
                let col0 = '';
                let col1 = '';
                let col2 = '';
                if (this.UnderResult[i][0] !== 0) {
                    col0 = this.props.m_pwin.m_main.IntToString(this.UnderResult[i][0]);
                }
                if (this.UnderResult[i][1] !== 0) {
                    col1 = this.props.m_pwin.m_main.IntToString(this.UnderResult[i][1]);
                }
                if (this.UnderResult[i][2] === -1) {
                    col0 = col1 = '-------';
                    col2 = '-------------------------------------------------------------------';
                }
                if (this.UnderResult[i][2] > 0) {
                    let b1: number, b2: number, b3: number;
                    let a1: string, a2: string, a3: string;
                    b3 = Math.floor(this.UnderResult[i][2] / 100);
                    b2 = Math.floor(this.UnderResult[i][2] / 10) % 10;
                    b1 = this.UnderResult[i][2] % 10;
                    if (b1 === 1) {
                        if (b2 === 1) {
                            a2 = 'Club';
                        }
                        if (b2 === 2) {
                            a2 = 'Diamond';
                        }
                        if (b2 === 3) {
                            a2 = 'Heart';
                        }
                        if (b2 === 4) {
                            a2 = 'Spade';
                        }
                        if (b2 === 5) {
                            a2 = 'No Trump';
                        }
                    }
                    if (b1 > 1) {
                        if (b2 === 1) {
                            a2 = 'Clubs';
                        }
                        if (b2 === 2) {
                            a2 = 'Diamonds';
                        }
                        if (b2 === 3) {
                            a2 = 'Hearts';
                        }
                        if (b2 === 4) {
                            a2 = 'Spades';
                        }
                        if (b2 === 5) {
                            a2 = 'No Trump';
                        }
                    }
                    a1 = this.props.m_pwin.m_main.IntToString(b1);
                    a3 = '';
                    if (b3 === 1) {
                        a3 = ' (' + this.props.m_pwin.m_main.GetLanguageText('doubled') + ')';
                    }
                    if (b3 === 2) {
                        a3 = ' (' + this.props.m_pwin.m_main.GetLanguageText('redoubled') + ')';
                    }
                    a2 = this.props.m_pwin.m_main.GetLanguageText(a2);
                    a3 = this.props.m_pwin.m_main.GetLanguageText(a3);
                    col2 = a1 + ' ' + a2 + a3 + ' ' + this.props.m_pwin.m_main.GetLanguageText('Made');
                }
                row[0] = col0;
                row[1] = col1;
                row[2] = col2;
                this.tableDataUnder.push(row);
            }
        }

        for (let i = 0; i < 100; i++) {
            let row = [];
            let col0 = '';
            let col1 = '';
            let col2 = '';
            if (this.UpResult[i][0] !== 0 || this.UpResult[i][1] !== 0 || this.UpResult[i][2] !== 0) {
                col0 = '';
                if (this.UpResult[i][0] !== 0) {
                    col0 = this.props.m_pwin.m_main.IntToString(this.UpResult[i][0]);
                }
                if (this.UpResult[i][1] !== 0) {
                    col1 = this.props.m_pwin.m_main.IntToString(this.UpResult[i][1]);
                }
                if (this.UpResult[i][2] !== 0) {
                    let s: string;
                    if (this.UpResult[i][2] === 10) {
                        s = this.props.m_pwin.m_main.GetLanguageText('Rubber Bonus');
                    }
                    if (this.UpResult[i][2] === 20) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Small slam') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract');
                    }
                    if (this.UpResult[i][2] === 21) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Small slam') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract') +
                            ',' +
                            this.props.m_pwin.m_main.GetLanguageText('vulnerable');
                    }
                    if (this.UpResult[i][2] === 30) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Giant slam') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract');
                    }
                    if (this.UpResult[i][2] === 31) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Giant slam') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract') +
                            ',' +
                            this.props.m_pwin.m_main.GetLanguageText('vulnerable');
                    }
                    if (this.UpResult[i][2] === 40) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('doubled') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract');
                    }
                    if (this.UpResult[i][2] === 50) {
                        s =
                            this.props.m_pwin.m_main.GetLanguageText('Bonus for made') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('redoubled') +
                            ' ' +
                            this.props.m_pwin.m_main.GetLanguageText('Contract');
                    }
                    if (this.UpResult[i][2] === 60) {
                        s = this.props.m_pwin.m_main.GetLanguageText('Honour bonus');
                    }
                    if (this.UpResult[i][2] > 100 && this.UpResult[i][2] < 1000) {
                        //2 overtricks,doubled
                        //1 overtrick, doubled,vulnerable
                        let a1: number, a2: number, a3: number;
                        let s1 = '',
                            s2 = '',
                            s3 = '';
                        a1 = this.UpResult[i][2] % 10;
                        a2 = Math.floor(this.UpResult[i][2] / 10) % 10;
                        a3 = Math.floor(this.UpResult[i][2] / 100);
                        s1 = this.props.m_pwin.m_main.IntToString(a1);
                        if (a2 === 1) {
                            s2 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('vulnerable');
                        }
                        if (a3 === 2) {
                            s3 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('doubled');
                        }
                        if (a3 === 3) {
                            s3 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('redoubled');
                        }
                        if (a1 === 1) {
                            s =
                                s1 +
                                ' ' +
                                this.props.m_pwin.m_main.GetLanguageText('overtrick') +
                                this.props.m_pwin.m_main.GetLanguageText(s2) +
                                this.props.m_pwin.m_main.GetLanguageText(s3);
                        }
                        if (a1 > 1) {
                            s =
                                s1 +
                                ' ' +
                                this.props.m_pwin.m_main.GetLanguageText('overtricks') +
                                this.props.m_pwin.m_main.GetLanguageText(s2) +
                                this.props.m_pwin.m_main.GetLanguageText(s3);
                        }
                    }
                    if (this.UpResult[i][2] > 1000) {
                        //bonus for defeating contract by 6 tricks
                        let a1: number, a2: number, a3: number;
                        let s1 = '',
                            s2 = '',
                            s3 = '';
                        a1 = this.UpResult[i][2] % 10;
                        a2 = Math.floor(this.UpResult[i][2] / 10) % 10;
                        if (a2 > 1) {
                            if (a2 === 2) {
                                a1 = 10 + a1;
                                a2 = 0;
                            }
                            if (a2 === 3) {
                                a1 = 10 + a1;
                                a2 = 1;
                            }
                        }
                        a3 = Math.floor(this.UpResult[i][2] / 100) % 10;
                        s1 = this.props.m_pwin.m_main.IntToString(a1);
                        if (a2 === 1) {
                            s2 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('vulnerable');
                        }
                        if (a3 === 2) {
                            s3 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('doubled');
                        }
                        if (a3 === 3) {
                            s3 = ' ,' + this.props.m_pwin.m_main.GetLanguageText('redoubled');
                        }
                        if (a1 === 1) {
                            s =
                                this.props.m_pwin.m_main.GetLanguageText('Bonus for defeating contract by') +
                                ' ' +
                                s1 +
                                ' ' +
                                this.props.m_pwin.m_main.GetLanguageText('trick') +
                                this.props.m_pwin.m_main.GetLanguageText(s2) +
                                this.props.m_pwin.m_main.GetLanguageText(s3);
                        }
                        if (a1 > 1) {
                            s =
                                this.props.m_pwin.m_main.GetLanguageText('Bonus for defeating contract by') +
                                ' ' +
                                s1 +
                                ' ' +
                                this.props.m_pwin.m_main.GetLanguageText('tricks') +
                                this.props.m_pwin.m_main.GetLanguageText(s2) +
                                this.props.m_pwin.m_main.GetLanguageText(s3);
                        }
                    }
                    col2 = s;
                }
                row[0] = col0;
                row[1] = col1;
                row[2] = col2;
                this.tableDataUp.push(row);
            }
        }
    }
    Clean() {
        for (let i = 0; i < 50; i++) {
            this.UnderResult[i][0] = 0;
            this.UnderResult[i][1] = 0;
            this.UnderResult[i][2] = 0;
        }
        for (let i = 0; i < 100; i++) {
            this.UpResult[i][0] = 0;
            this.UpResult[i][1] = 0;
            this.UpResult[i][2] = 0;
        }
        this.BroiUnderResult = 0;
        this.BroiUpResult = 0;
        this.m_final_score = '';
    }

    OnOK() {
        this.start = 0;
        this.props.m_pwin.setState({ CRubberResultVisible: false });
    }

    InitTableWidth() {
        this.widthArrUp = [];
        this.widthArrUp.push(48 * GlobalVariables.scale);
        this.widthArrUp.push(48 * GlobalVariables.scale);
        this.widthArrUp.push(390 * GlobalVariables.scale);
        this.widthArrUnder = [];
        this.widthArrUnder.push(48 * GlobalVariables.scale);
        this.widthArrUnder.push(48 * GlobalVariables.scale);
        this.widthArrUnder.push(390 * GlobalVariables.scale);
    }

    InitTables() {
        this.InitTableWidth();
        this.tableHeadUp = [];
        this.tableDataUp = [];
        this.tableHeadUnder = [];
        this.tableDataUnder = [];

        this.tableHeadUp.push('S/N');
        this.tableHeadUp.push('E/W');
        this.tableHeadUp.push('');

        this.tableHeadUnder.push('S/N');
        this.tableHeadUnder.push('E/W');
        this.tableHeadUnder.push('');
    }

    OnInitDialog() {
        this.InitTables();
        this.AddRezults();
    }

    async DoModal() {
        this.props.m_pwin.state.CRubberResultVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CRubberResultVisible) {
            await this.performTimeConsumingTask();
        }
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.state.CRubberResultVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 70 * GlobalVariables.scale,
                                width: 650 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View
                            style={[
                                this.cstyles.tableBackground,
                                { height: Dimensions.get('window').height * 0.32, marginBottom: 10 * GlobalVariables.scale },
                            ]}
                        >
                            <ScrollView
                                style={StyleSheet.flatten({ maxHeight: Dimensions.get('window').height * 0.32 })}
                                ref={(ref) => (this.scrollViewUp = ref)}
                                onContentSizeChange={(width, height) => (this.scrollViewUp ? this.scrollViewUp.scrollTo({ y: height }) : null)}
                            >
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    <Row
                                        data={this.tableHeadUp}
                                        widthArr={this.widthArrUp}
                                        style={StyleSheet.flatten(this.cstyles.header)}
                                        textStyle={StyleSheet.flatten([
                                            this.cstyles.text,
                                            { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                        ])}
                                        onPress={() => { return;}}
                                    />
                                </Table>
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    {this.tableDataUp.map((rowData, index) => (
                                        <Row
                                            key={index}
                                            data={rowData}
                                            widthArr={this.widthArrUp}
                                            height={30}
                                            style={StyleSheet.flatten(index % 2 === 0 ? this.cstyles.rowActiveColor : this.cstyles.rowColor)}
                                            textStyle={StyleSheet.flatten([
                                                this.cstyles.text,
                                                { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                            ])}
                                            onPress={() => { return;}}
                                        />
                                    ))}
                                </Table>
                            </ScrollView>
                        </View>
                        <View
                            style={[
                                this.cstyles.tableBackground,
                                { height: Dimensions.get('window').height * 0.32, marginBottom: 10 * GlobalVariables.scale },
                            ]}
                        >
                            <ScrollView
                                style={StyleSheet.flatten({ maxHeight: Dimensions.get('window').height * 0.32 })}
                                ref={(ref) => (this.scrollViewUnder = ref)}
                                onContentSizeChange={(width, height) => (this.scrollViewUnder ? this.scrollViewUnder.scrollTo({ y: height }) : null)}
                            >
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    <Row
                                        data={this.tableHeadUnder}
                                        widthArr={this.widthArrUnder}
                                        style={StyleSheet.flatten(this.cstyles.header)}
                                        textStyle={StyleSheet.flatten([
                                            this.cstyles.text,
                                            { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                        ])}
                                        onPress={() => { return;}}
                                    />
                                </Table>
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    {this.tableDataUnder.map((rowData, index) => (
                                        <Row
                                            key={index}
                                            data={rowData}
                                            widthArr={this.widthArrUnder}
                                            height={30}
                                            style={StyleSheet.flatten(index % 2 === 0 ? this.cstyles.rowActiveColor : this.cstyles.rowColor)}
                                            textStyle={StyleSheet.flatten([
                                                this.cstyles.text,
                                                { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                            ])}
                                            onPress={() => { return;}}
                                        />
                                    ))}
                                </Table>
                            </ScrollView>
                        </View>
                        <View style={styles.row}>
                            <Text>{this.m_final_score}</Text>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle4PerRow} onPress={this.OnOK.bind(this)} text="OK" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CRubberResult;

const stylesGenerator = () =>
    StyleSheet.create({
        borderStyle: {
            borderWidth: 1,
            borderColor: '#333333',
            borderStyle: 'solid',
        },
        tableBackground: {
            backgroundColor: 'white',
        },
        header: { height: 30, backgroundColor: '#FFFFFF' },
        text: { paddingLeft: 2 },
        rowActiveColor: { backgroundColor: '#F7F6E7' },
        rowColor: { backgroundColor: '#E7E6E1' },
    });
