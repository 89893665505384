import { Platform, View } from 'react-native';
import CMain from '../CMain';
import CPoint from '../cppComponents/CPoint';
import React from 'react';
import CRubberResult from '../../Dialogs/CRubberResult';
import CImpResult from '../../Dialogs/CImpResult';
import { GamesProps } from '../props/GamesProps';
import GlobalVariables from '../GlobalVariables';
import DrawNames from '../Draw/DrawNames';
import CRect from '../cppComponents/CRect';
import GlobalConstants from '../GlobalConstants';
import Bidding_Bridge from '../../Dialogs/BiddingBridge/Bidding_Bridge';
import CPicture from '../cppComponents/CPicture';
import DrawCard from '../Draw/DrawCard';
import CSize from '../cppComponents/CSize';
import DrawBid from '../Draw/DrawBid';

export class PlayerInfo {
    //Pomo6tna Struktura za obiaviavaneto
    MinPoints: number = 0;
    MaxPoints: number = 0;
    MinBroiKupi: number = 0;
    MinBroiPiki: number = 0;
    MinBroiKari: number = 0;
    MinBroiSpatii: number = 0;
    AskingAsa: boolean = false;
    BroiAsa: number = 0;
    CloseAnons: boolean = false;
    GestPoints: number = 0;
}

class CBridge extends CMain {
    type: number = 1;
    rubber_result = new CRubberResult(null);
    imp_result = new CImpResult(null);
    obiaviavaneRN: any = null;
    AnonBr: number;
    kojkazal: number;
    KoiObiaviava: number;
    BrPas: number;
    level: number;
    Anon: number[] = [];
    zone: number;
    Mor: number;
    BroiIgri: number;
    TimeVzemane: boolean;
    wait_new_game: boolean;
    impove: number;
    WhoSaysKontra: number;
    Rubber: boolean;
    PlayerI: PlayerInfo[] = [];
    rotate_card: string;
    Vzeti = [];
    TimeVzemane1: number;
    CardRect: CRect[] = [];
    Izkarani: number;
    Pribirane: boolean;
    LastSays: number[] = [];
    br: number[] = [];
    bidd: Bidding_Bridge = new Bidding_Bridge(null);
    PlayerFCards = [];
    NachalniKarti: number[] = [];
    RefreshMor: boolean;
    hand1: number[] = [];
    PlayerAnouns = [];
    WhoPlay: number;
    GameResult: string;
    Zaduljenie1: number;
    Zaduljenie2: number;
    fits1: number;
    fits2: number;
    points1: number;
    points2: number;
    points3: number;
    points4: number;
    loading: boolean = true;

    constructor(props: GamesProps) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        for (let i1 = 0; i1 < 4; i1++) {
            this.PlayerAnouns[i1] = [];
            for (let i2 = 0; i2 < 15; i2++) {
                this.PlayerAnouns[i1][i2] = [];
                for (let i3 = 0; i3 < 3; i3++) {
                    this.PlayerAnouns[i1][i2][i3] = 0;
                }
            }
        }
        this.AnonBr = 0;
        this.kojkazal = 0;
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.Naddavane = 0;
        this.hand = 0;
        this.KoiENaRed = 0;
        this.KoiPuskaParvi = 0;
        this.KoiZapochva = 0;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        this.KoiObiaviava = 0;
        this.BrPas = 0;
        this.Mcard = 0;
        this.Kontra = 0;
        this.Rekontra = 0;
        this.level = 0;
        for (let i = 0; i < 100; i++) {
            this.Anon[i] = 0;
        }
        this.zone = 0;
        this.Mor = 0;
        this.BroiIgri = 0;
        this.Move = false;
        this.TimeVzemane = false;
        this.Server = false;
        this.op_start = false;
        this.wait_new_game = false;
        for (let i = 0; i < 5; i++) {
            this.wait_opplayers[i] = false;
        }
        this.open_rez_dlg = false;
        this.pause = 0;
        this.impove = 0;
        this.Razdavane1 = false;
        this.WhoSaysKontra = 0;
        this.imp_result.fzone = -1;
        this.Rubber = false;
        this.pause_rect = new CRect(676, 484, 700, 504);
    }

    async InitOP(server: boolean, type: number) {
        this.loading = true;
        this.type = type;
        this.Server = server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        if (this.Server) {
            this.InitGame();
        }
        this.InitTimer(20);
        this.loading = false;
    }
    async OnLButtonDown(point: CPoint) {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.OP) {
            for (let i = 1; i <= 4; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (this.OP && GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 4; i++) {
                        if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!this.Move && !this.watch) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (
                    this.OP &&
                    this.Faza >= 4 &&
                    this.P1puska === 0 &&
                    !this.Move &&
                    ((this.KoiENaRed === 1 && this.Mor !== 1) || (this.KoiENaRed === 3 && this.Mor === 3))
                ) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.props.m_pwin.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }
            if (
                new CRect(
                    this.CardsPos[13 - this.hand].x * GlobalVariables.scale -
                        ((this.hand * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13) * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].y * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].x * GlobalVariables.scale -
                        ((this.hand * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13) * GlobalVariables.scale +
                        GlobalConstants.PLAYER_CARD_WIDTH * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].y * GlobalVariables.scale + GlobalConstants.PLAYER_CARD_HEIGHT * GlobalVariables.scale
                ).PtInRect(point) &&
                this.k[13 - this.hand] !== 0
            ) {
                if (this.ProverkaCursor(13 - this.hand)) {
                    this.PuskaneNaKarta(13 - this.hand);
                    this.sit_out_count = 0;
                    return;
                }
            }
            ///////////////////////////////////////
            if (this.Mor !== 2) {
                if (
                    new CRect(
                        this.CardsPos[26 - this.hand].x * GlobalVariables.scale,
                        this.CardsPos[26 - this.hand].y * GlobalVariables.scale,
                        (this.CardsPos[26 - this.hand].x + GlobalConstants.CARD_WIDTH) * GlobalVariables.scale,
                        (this.CardsPos[26 - this.hand].y + GlobalConstants.CARD_HEIGHT) * GlobalVariables.scale
                    ).PtInRect(point) &&
                    this.k[26 - this.hand] !== 0
                ) {
                    if (this.ProverkaCursor(26 - this.hand)) {
                        this.PuskaneNaKarta(26 - this.hand);
                        this.sit_out_count = 0;
                        return;
                    }
                }
            }
            if (this.Mor !== 3) {
                if (
                    new CRect(
                        this.CardsPos[39 - this.hand].x * GlobalVariables.scale,
                        this.CardsPos[39 - this.hand].y * GlobalVariables.scale,
                        (this.CardsPos[39 - this.hand].x + GlobalConstants.CARD_WIDTH) * GlobalVariables.scale,
                        (this.CardsPos[39 - this.hand].y + GlobalConstants.CARD_HEIGHT) * GlobalVariables.scale
                    ).PtInRect(point) &&
                    this.k[39 - this.hand] !== 0
                ) {
                    if (this.ProverkaCursor(39 - this.hand)) {
                        this.PuskaneNaKarta(39 - this.hand);
                        this.sit_out_count = 0;
                        return;
                    }
                }
            }
            if (this.Mor !== 4) {
                if (
                    new CRect(
                        this.CardsPos[52 - this.hand].x * GlobalVariables.scale,
                        this.CardsPos[52 - this.hand].y * GlobalVariables.scale,
                        (this.CardsPos[52 - this.hand].x + GlobalConstants.CARD_WIDTH) * GlobalVariables.scale,
                        (this.CardsPos[52 - this.hand].y + GlobalConstants.CARD_HEIGHT) * GlobalVariables.scale
                    ).PtInRect(point) &&
                    this.k[52 - this.hand] !== 0
                ) {
                    if (this.ProverkaCursor(52 - this.hand)) {
                        this.PuskaneNaKarta(52 - this.hand);
                        return;
                    }
                }
            }
            for (let i = 1; i < 40 - this.hand; i++) {
                let cardRect = this.CardRect[i];
                if (i < 13 - this.hand) {
                    cardRect = new CRect(
                        this.CardRect[i].left - ((13 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13,
                        this.CardRect[i].top,
                        this.CardRect[i].right -
                            ((13 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13 +
                            (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 13,
                        this.CardRect[i].bottom + GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH
                    );
                } else {
                    if (i < 27) {
                        i = 27;
                        cardRect = this.CardRect[i];
                    }
                }
                if (this.ScaleRect(cardRect).PtInRect(point) && this.k[i] !== 0) {
                    if (this.ProverkaCursor(i)) {
                        this.PuskaneNaKarta(i);
                        this.sit_out_count = 0;
                        return;
                    }
                }
            }
        }
    }
    OnRButtonDown(point: CPoint) {
        if (this.watch) {
            for (let i = 2; i <= 4; i++) {
                if (this.Name[i] === 'Computer2' || this.Name[i] === 'Computer3' || this.Name[i] === 'Computer4') {
                    let s: CSize = this.GetTextExtent(this.Name[i], 13);
                    let width = s.cx;
                    if (
                        this.ScaleRect(new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)).PtInRect(
                            point
                        )
                    ) {
                        this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                        this.props.m_pwin.GoToLobby();
                    }
                }
            }
        }
    }
    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string;
        if (f === 'a') {
            //Poluchavane na imenata client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let name = this.props.m_pwin.ReceiveString(12);
            for (let i = 2; i < 5; i++) {
                if (this.Name[i] === '') {
                    if (name !== 'Computer2' && name !== 'Computer3' && name !== 'Computer4') {
                        this.Name[i] = name;
                    } else {
                        if (i === 2) {
                            this.Name[2] = 'Computer2';
                        }
                        if (i === 3) {
                            this.Name[3] = 'Computer3';
                        }
                        if (i === 4) {
                            this.Name[4] = 'Computer4';
                            this.props.m_pwin.InvalidateStolove(3);
                        }
                    }
                    this.props.m_pwin.GetPlayerRating(i);
                    return true;
                }
            }
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi obiaviava client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            let koj = this.props.m_pwin.ReceiveString(1);
            let zonestr = this.props.m_pwin.ReceiveString(1);
            let fzonestr = this.props.m_pwin.ReceiveString(1);
            if (koj === '1' || koj === '2' || koj === '3' || koj === '4') {
                this.KoiZapochva = this.KoiPuskaParvi = this.KoiObiaviava = this.KoiENaRed = this._ttoi(koj);
                this.zone = this._ttoi(zonestr);
                this.imp_result.fzone = this._ttoi(fzonestr);
                this.InitGame();
            }
            if (this.watch) {
                this.setState({});
            }
            return true;
        }
        if (f === 'c') {
            //Poluchavane na kartite client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let rstr = this.props.m_pwin.ReceiveString(3);
            if (this.watch) {
                if (this.Faza >= 2) {
                    for (let i = 1; i <= 52; i++) {
                        this.k[i] = 0;
                    }
                    this.Faza = 0;
                }
            }
            for (let i = 1; i < 53; i++) {
                if (this.k[i] < 1) {
                    if (i === 1) {
                        if (this.CheckMessage()) {
                            this.ClearMessages();
                        }
                        if (this.CheckAMessage()) {
                            this.ClearAMessages();
                        }
                    }
                    this.k[i] = this._ttoi(rstr);
                    if (i === 52) {
                        this.Razdavane1 = true;
                        this.LoadCards(false, true);
                        this.online_timer = this.online_timer_time;
                    }
                    return true;
                }
            }
            return true;
        }
        if (f === 'd') {
            //Poluchavane na anons
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.setState({});
            let annons = this.props.m_pwin.ReceiveString(4);
            this.WriteAMessage(annons);
            await this.Timer2();
            return true;
        }
        if (f === 'e') {
            //Poluchavane na signal za gotovnost ot this.Servera
            return true;
        }
        if (f === 'f') {
            //Poluchavane na karta
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let card = this.props.m_pwin.ReceiveString(3);
            this.WriteMessage(card);
            this.Timer3();
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[3]) {
                        this.P3OP = false;
                        this.P3G = true;
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[4]) {
                        this.P4OP = false;
                        this.P4G = true;
                        this.Name[4] = 'Computer4';
                        this.props.m_pwin.InvalidateStolove(3);
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.wait_opplayers[2] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[3]) {
                        this.wait_opnames[3] = '';
                        this.wait_opplayers[3] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[4]) {
                        this.wait_opnames[4] = '';
                        this.wait_opplayers[4] = false;
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    if (name === this.Name[3]) {
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                    }
                    if (name === this.Name[4]) {
                        this.Name[4] = 'Computer4';
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                    }

                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            this.P3G = true;
            return true;
        }
        if (f === 'j') {
            this.P4G = true;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '3') {
                this.P3OP = false;
            }
            if (strRecvd === '4') {
                this.P4OP = false;
            }
            if (strRecvd === '5') {
                this.P2OP = true;
            }
            if (strRecvd === '6') {
                this.P3OP = true;
            }
            if (strRecvd === '7') {
                this.P4OP = true;
            }
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                //if(this.HaveWatch) { }
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            this.setState({});
            return true;
        }
        if (f === 'm') {
            return true;
        }
        if (f === 'n') {
            return true;
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2G = false;
                if (this.Faza === 4 || this.Faza === 6) {
                    this.P2G = true;
                }
            }
            if (strRecvd === '3') {
                this.P3G = false;
                if (this.Faza === 4 || this.Faza === 6) {
                    this.P3G = true;
                }
            }
            if (strRecvd === '4') {
                this.P4G = false;
                if (this.Faza === 4 || this.Faza === 6) {
                    this.P4G = true;
                }
            }
            if (strRecvd === '5') {
                this.P2G = true;
            }
            if (strRecvd === '6') {
                this.P3G = true;
            }
            if (strRecvd === '7') {
                this.P4G = true;
            }
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra this.Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let player_name = this.props.m_pwin.ReceiveString(12);
            let pistr = this.props.m_pwin.ReceiveString(1);
            let rating = this.props.m_pwin.ReceiveString(30);
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let player_index = this._ttoi(pistr);
                if (this.watch) {
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    this.setState({});
                    return true;
                }
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[player_index] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    if (player_index === 4) {
                        this.P4OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.props.m_pwin.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (player_index === 2) {
                        this.P2OP = false;
                    }
                    if (player_index === 3) {
                        this.P3OP = false;
                    }
                    if (player_index === 4) {
                        this.P4OP = false;
                    }
                    message = '$|s|' + pistr + '|1';
                    this.props.m_pwin.SendGame(message);
                    this.wait_opplayers[player_index] = true;
                    this.wait_opnames[player_index] = player_name;
                    this.SendNamesP(player_index);
                } else {
                    message = '$|s|' + pistr + '|0';
                    this.props.m_pwin.SendGame(message);
                    if (this.props.m_pwin.m_main.last_refused_user === player_name) {
                        let title = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title);
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.props.m_pwin.m_main.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.props.m_pwin.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane na Igrach po vreme na igra
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('Please wait for the next deal'));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('This user refused to join this play') + '!');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText("You can't connect to this user"));
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 4) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let uu = this.props.m_pwin.ReceiveString(1);
            let rez1 = this.props.m_pwin.ReceiveString(6);
            let rez2 = this.props.m_pwin.ReceiveString(6);
            let desc1 = this.props.m_pwin.ReceiveString(6);
            if (this.type === 1) {
                if (uu === '1') {
                    this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][0] = this._ttoi(rez1);
                    this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][1] = this._ttoi(rez2);
                    this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] = this._ttoi(desc1);
                    this.rubber_result.BroiUnderResult++;
                }
                if (uu === '2') {
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][0] = this._ttoi(rez1);
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][1] = this._ttoi(rez2);
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = this._ttoi(desc1);
                    this.rubber_result.BroiUpResult++;
                }
            }
            if (this.type === 2) {
                for (let i = 0; i < 30; i++) {
                    if (this.imp_result.result1[2][i] === '' && this.imp_result.result1[1][i] === '' && this.imp_result.result1[0][i] === '') {
                        this.imp_result.result1[0][i] = rez1;
                        this.imp_result.result1[1][i] = rez2;
                        this.imp_result.result1[2][i] = desc1;
                        this.BroiIgri = i + 1;
                        if (rez1 !== '' && rez2 !== '') {
                            if (rez1 !== '0') {
                                this.impove = this._ttoi(rez1);
                            } else if (rez2 !== '0') {
                                this.impove = -this._ttoi(rez2);
                            }
                            if (rez1 === '0' && rez2 === '0') {
                                this.impove = 0;
                            }
                        }
                        break;
                    }
                }
            }
            this.op_start = true;
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index = this._ttoi(pistr);
            let player_name = this.props.m_pwin.ReceiveString(12);
            this.Name[player_index] = player_name;
            this.props.m_pwin.GetPlayerRating(player_index);
            this.setState({});
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        if (f === 'u') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'v') {
            //ReceiveWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(1500);
            this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!bridge : ' + f);
        return true;
    }

    ReceiveWachGameInitialization(init: string) {
        this.OnLButtonDown(new CPoint(0, 0));
        this.InitGame();
        let vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[3] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[4] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        if (this.Faza < 0) {
            this.ReceiveWachGameInitializationEnd();
            return;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Naddavane = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.level = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.hand = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        if (this.hand > 0) {
            for (let i = 1; i <= this.hand; i++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.hand1[i - 1] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
            }
        }
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiPuskaParvi = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P1puska = this._ttoi(s1);
        if (this.P1puska > 0) {
            this.ConvertCard(this.P1puska, 53);
            if (this.Move && this.Mcard > 0) {
                this.CardsPoint[53].x = this.CardsPos[1].x;
                this.CardsPoint[53].y = this.CardsPos[1].y;
            } else {
                this.CardsPoint[53].x = this.CardsPos[53].x;
                this.CardsPoint[53].y = this.CardsPos[53].y;
            }
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P2puska = this._ttoi(s1);
        if (this.P2puska > 0) {
            this.ConvertCard(this.P2puska, 54);
            if (this.Move && this.Mcard > 0) {
                this.CardsPoint[54].x = this.CardsPos[14].x;
                this.CardsPoint[54].y = this.CardsPos[14].y;
            } else {
                this.CardsPoint[54].x = this.CardsPos[54].x;
                this.CardsPoint[54].y = this.CardsPos[54].y;
            }
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P3puska = this._ttoi(s1);
        if (this.P3puska > 0) {
            this.ConvertCard(this.P3puska, 55);
            if (this.Move && this.Mcard > 0) {
                this.CardsPoint[55].x = this.CardsPos[27].x;
                this.CardsPoint[55].y = this.CardsPos[27].y;
            } else {
                this.CardsPoint[55].x = this.CardsPos[55].x;
                this.CardsPoint[55].y = this.CardsPos[55].y;
            }
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.P4puska = this._ttoi(s1);
        if (this.P4puska > 0) {
            this.ConvertCard(this.P4puska, 56);
            if (this.Move && this.Mcard > 0) {
                this.CardsPoint[56].x = this.CardsPos[40].x;
                this.CardsPoint[56].y = this.CardsPos[40].y;
            } else {
                this.CardsPoint[56].x = this.CardsPos[56].x;
                this.CardsPoint[56].y = this.CardsPos[56].y;
            }
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ParviPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.VtoriPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.TretiPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.ChetvartiPuska = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.strelka = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiObiaviava = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        if (this.KoiObiaviava === 0) {
            this.KoiObiaviava = 1;
        }

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.BrPas = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mcard = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Kontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Rekontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.WhoSaysKontra = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.AnonBr = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Anon[this.AnonBr] = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.zone = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.Mor = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        this.RecalculateCardsPos();
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.BroiIgri = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Razdavane1 = true;
            if (GlobalVariables.Zvuk === 1 && this.Faza <= 4) {
                this.props.m_pwin.m_sndSound.PlayFile('deal', false);
            }
        } else {
            this.Razdavane1 = false;
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Move = true;
        } else {
            this.Move = false;
        }
        init = init.substring(vpos + 1, init.length);

        for (let i = 1; i < 53; i++) {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.k[i] = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].x = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.CardsPoint[i].y = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }
        if (this.type === 1) {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.rubber_result.BroiUnderResult = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            for (let ii = 0; ii < this.rubber_result.BroiUnderResult; ii++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UnderResult[ii][0] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UnderResult[ii][1] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UnderResult[ii][2] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
            }

            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.rubber_result.BroiUpResult = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);

            for (let ii = 0; ii < this.rubber_result.BroiUpResult; ii++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UpResult[ii][0] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UpResult[ii][1] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.rubber_result.UpResult[ii][2] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
            }
        }
        if (this.type === 2) {
            for (let ii = 0; ii < this.BroiIgri; ii++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.imp_result.result1[0][ii] = s1;
                init = init.substring(vpos + 1, init.length);

                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.imp_result.result1[1][ii] = s1;
                init = init.substring(vpos + 1, init.length);
                if (this.imp_result.result1[0][ii] !== '' && this.imp_result.result1[1][ii] !== '') {
                    if (this.imp_result.result1[0][ii] !== '0') {
                        this.impove = this._ttoi(this.imp_result.result1[0][ii]);
                    } else if (this.imp_result.result1[1][ii] !== '0') {
                        this.impove = -this._ttoi(this.imp_result.result1[1][ii]);
                    }
                    if (this.imp_result.result1[0][ii] === '0' && this.imp_result.result1[1][ii] === '0') {
                        this.impove = 0;
                    }
                }
            }
        }

        if (this.Faza >= 4) {
            this.LoadCards(true, false);
            for (let i = 1; i < 53; i++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.NachalniKarti[i] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
            }
        } else {
            this.LoadCards(false, false);
        }
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.Rubber = true;
        } else {
            this.Rubber = false;
        }
        init = init.substring(vpos + 1, init.length);

        if (this.Faza === 0 && this.Mcard > 0 && this.Razdavane1 && this.Move) {
            this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
        } else {
            if (this.Move) {
                if (this.Mcard > 0) {
                    this.BeginMove(this.CardsPoint[this.Mcard], this.CardsPos[this.Mcard], this.Mcard);
                }
            }
        }
        if (init !== '') {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.kojkazal = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
        }

        if (init !== '' && init !== '?') {
            vpos = init.indexOf('?');
            this.Name[1] = init.substring(0, vpos);
            init = init.substring(vpos + 1, init.length);
            vpos = init.indexOf('?');
        }

        this.ReceiveWachGameInitializationEnd();
    }

    ReceiveWachGameInitializationEnd() {
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.props.m_pwin.GetPlayerRating(3);
        this.props.m_pwin.GetPlayerRating(4);
        this.setState({});
    }

    SendNames() {
        if (this.P2OP) {
            let message = '$|a|' + this.Name[3];
            this.props.m_pwin.SendGame(message);
            message = '$|a|' + this.Name[4];
            this.props.m_pwin.SendGame(message);
            message = '$|a|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
        }
        if (this.P3OP) {
            let message = '$|d|' + this.Name[4];
            this.props.m_pwin.SendGame(message);
            message = '$|d|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
            message = '$|d|' + this.Name[2];
            this.props.m_pwin.SendGame(message);
        }
        if (this.P4OP) {
            let message = '$|g|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
            message = '$|g|' + this.Name[2];
            this.props.m_pwin.SendGame(message);
            message = '$|g|' + this.Name[3];
            this.props.m_pwin.SendGame(message);
        }
        if (this.HaveWatch) {
            let message = '$|t|1|' + this.Name[2] + '|' + this.Name[3] + '|' + this.Name[4];
            this.props.m_pwin.SendGame(message);
        }
    }

    SendWatchGameInitialization(watch_index: number) {
        let message = '$|z|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[2] + '?' + this.Name[3] + '?' + this.Name[4] + '?';
        //this.Faza
        message = message + this.IntToString(this.Faza) + '?';
        message = message + this.IntToString(this.Naddavane) + '?';
        message = message + this.IntToString(this.level) + '?';
        message = message + this.IntToString(this.hand) + '?';
        if (this.hand > 0) {
            for (let i = 1; i <= this.hand; i++) {
                message = message + this.IntToString(this.hand1[i - 1]) + '?';
            }
        }
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.KoiPuskaParvi) + '?';
        message = message + this.IntToString(this.KoiZapochva) + '?';
        message = message + this.IntToString(this.P1puska) + '?';
        message = message + this.IntToString(this.P2puska) + '?';
        message = message + this.IntToString(this.P3puska) + '?';
        message = message + this.IntToString(this.P4puska) + '?';

        message = message + this.IntToString(this.ParviPuska) + '?';
        message = message + this.IntToString(this.VtoriPuska) + '?';
        message = message + this.IntToString(this.TretiPuska) + '?';
        message = message + this.IntToString(this.ChetvartiPuska) + '?';
        message = message + this.IntToString(this.strelka) + '?';
        message = message + this.IntToString(this.KoiObiaviava) + '?';
        message = message + this.IntToString(this.BrPas) + '?';
        message = message + this.IntToString(this.Mcard) + '?';
        message = message + this.IntToString(this.Kontra) + '?';
        message = message + this.IntToString(this.Rekontra) + '?';
        message = message + this.IntToString(this.WhoSaysKontra) + '?';
        message = message + this.IntToString(this.AnonBr) + '?';
        message = message + this.IntToString(this.Anon[this.AnonBr]) + '?';
        message = message + this.IntToString(this.zone) + '?';
        message = message + this.IntToString(this.Mor) + '?';
        message = message + this.IntToString(this.BroiIgri) + '?';
        if (this.Razdavane1) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        if (this.Move) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        for (let i = 1; i < 53; i++) {
            message =
                message +
                this.IntToString(this.k[i]) +
                '?' +
                this.IntToString(this.CardsPoint[i].x) +
                '?' +
                this.IntToString(this.CardsPoint[i].y) +
                '?';
        }
        if (this.type === 1) {
            message = message + this.IntToString(this.rubber_result.BroiUnderResult) + '?';
            for (let ii = 0; ii < this.rubber_result.BroiUnderResult; ii++) {
                message =
                    message +
                    this.IntToString(this.rubber_result.UnderResult[ii][0]) +
                    '?' +
                    this.IntToString(this.rubber_result.UnderResult[ii][1]) +
                    '?' +
                    this.IntToString(this.rubber_result.UnderResult[ii][2]) +
                    '?';
            }
            message = message + this.IntToString(this.rubber_result.BroiUpResult) + '?';
            for (let ii = 0; ii < this.rubber_result.BroiUpResult; ii++) {
                message =
                    message +
                    this.IntToString(this.rubber_result.UpResult[ii][0]) +
                    '?' +
                    this.IntToString(this.rubber_result.UpResult[ii][1]) +
                    '?' +
                    this.IntToString(this.rubber_result.UpResult[ii][2]) +
                    '?';
            }
        }
        if (this.type === 2) {
            for (let ii = 0; ii < this.BroiIgri; ii++) {
                message = message + this.imp_result.result1[0][ii] + '?' + this.imp_result.result1[1][ii] + '?';
            }
        }
        if (this.Faza >= 4) {
            for (let i = 1; i < 53; i++) {
                message = message + this.IntToString(this.NachalniKarti[i]) + '?';
            }
        }
        if (this.Rubber) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        message = message + this.IntToString(this.kojkazal) + '?';
        message = message + this.Name[1] + '?';
        if (message !== '') {
            this.props.m_pwin.SendGame(message);
        }
    }

    async InitSP(type: number) {
        this.type = type;
        this.SP = true;
        this.OP = false;
        await this.Init();
        this.InitGame();
        this.loading = false;
    }
    async Init() {
        this.props.m_pwin.ShowInfoBar(true);
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        if (this.P3OP === true) {
            this.P3G = false;
        } else {
            this.P3G = true;
        }
        if (this.P4OP === true) {
            this.P4G = false;
        } else {
            this.P4G = true;
        }
        for (let i = 1; i < 5; i++) {
            this.PlayerI[i] = new PlayerInfo();
            this.PlayerI[i].AskingAsa = false;
            this.PlayerI[i].BroiAsa = 0;
            this.PlayerI[i].CloseAnons = false;
            this.PlayerI[i].MinBroiKari = 0;
            this.PlayerI[i].MinBroiKupi = 0;
            this.PlayerI[i].MinBroiPiki = 0;
            this.PlayerI[i].MinBroiSpatii = 0;
            this.PlayerI[i].MinPoints = 0;
            this.PlayerI[i].MaxPoints = 40;
            this.PlayerI[i].GestPoints = 0;
        }
        if (this.type === 1) {
            this.Rubber = false;
            this.zone = 0;
        }
        this.BroiIgri = 0;
        this.Players = 4;
        GlobalVariables.OtkritiKarti = 0;
        if (!this.OP) {
            this.Name[1] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[2]', 'Computer2');
            this.Name[3] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[3]', 'Computer3');
            this.Name[4] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[4]', 'Computer4');
        }
        await this.LoadBitmaps();
        if ((this.OP && this.Server) || this.SP) {
            this.KoiZapochva = this.KoiPuskaParvi = this.KoiENaRed = this.RandKoiZapochva(this.Players);
        }
        if (this.type === 2) {
            this.impove = 0;
            this.zone = 2 - (this.KoiZapochva % 2);
        }
        this.StrelkaPos[1].x = 370;
        this.StrelkaPos[1].y = 377;
        this.StrelkaPos[2].x = 620;
        this.StrelkaPos[2].y = 240;
        this.StrelkaPos[3].x = 370;
        this.StrelkaPos[3].y = 160;
        this.StrelkaPos[4].x = 120;
        this.StrelkaPos[4].y = 240;
        this.NamePos[1].x = 52;
        this.NamePos[1].y = 470;
        this.NamePos[4].x = 684;
        this.NamePos[4].y = 60;
        this.NamePos[3].x = 585;
        this.NamePos[3].y = 25;
        this.NamePos[2].x = 25;
        this.NamePos[2].y = 412;
        this.PosNaddavane[4].x = 590;
        this.PosNaddavane[4].y = 235;
        this.PosNaddavane[3].x = 340;
        this.PosNaddavane[3].y = 112;
        this.PosNaddavane[2].x = 130;
        this.PosNaddavane[2].y = 235;
        this.PosNaddavane[1].x = 100;
        this.PosNaddavane[1].y = 442;
        this.CardsPos[0].x = 181;
        this.CardsPos[0].y = 179;

        this.CardsPos[1].x = 154;
        this.CardsPos[1].y = 424;
        this.CardsPos[2].x = 189;
        this.CardsPos[2].y = 424;
        this.CardsPos[3].x = 224;
        this.CardsPos[3].y = 424;
        this.CardsPos[4].x = 259;
        this.CardsPos[4].y = 424;
        this.CardsPos[5].x = 294;
        this.CardsPos[5].y = 424;
        this.CardsPos[6].x = 329;
        this.CardsPos[6].y = 424;
        this.CardsPos[7].x = 364;
        this.CardsPos[7].y = 424;
        this.CardsPos[8].x = 399;
        this.CardsPos[8].y = 424;
        this.CardsPos[9].x = 434;
        this.CardsPos[9].y = 424;
        this.CardsPos[10].x = 469;
        this.CardsPos[10].y = 424;
        this.CardsPos[11].x = 504;
        this.CardsPos[11].y = 424;
        this.CardsPos[12].x = 539;
        this.CardsPos[12].y = 424;
        this.CardsPos[13].x = 574;
        this.CardsPos[13].y = 424;

        this.CardsPos[14].x = 30;
        this.CardsPos[14].y = 118;
        this.CardsPos[15].x = 30;
        this.CardsPos[15].y = 135;
        this.CardsPos[16].x = 30;
        this.CardsPos[16].y = 152;
        this.CardsPos[17].x = 30;
        this.CardsPos[17].y = 169;
        this.CardsPos[18].x = 30;
        this.CardsPos[18].y = 186;
        this.CardsPos[19].x = 30;
        this.CardsPos[19].y = 203;
        this.CardsPos[20].x = 30;
        this.CardsPos[20].y = 220;
        this.CardsPos[21].x = 30;
        this.CardsPos[21].y = 237;
        this.CardsPos[22].x = 30;
        this.CardsPos[22].y = 254;
        this.CardsPos[23].x = 30;
        this.CardsPos[23].y = 271;
        this.CardsPos[24].x = 30;
        this.CardsPos[24].y = 288;
        this.CardsPos[25].x = 30;
        this.CardsPos[25].y = 305;
        this.CardsPos[26].x = 30;
        this.CardsPos[26].y = 322;

        this.CardsPos[27].x = 484;
        this.CardsPos[27].y = 25;
        this.CardsPos[28].x = 462;
        this.CardsPos[28].y = 25;
        this.CardsPos[29].x = 440;
        this.CardsPos[29].y = 25;
        this.CardsPos[30].x = 418;
        this.CardsPos[30].y = 25;
        this.CardsPos[31].x = 396;
        this.CardsPos[31].y = 25;
        this.CardsPos[32].x = 374;
        this.CardsPos[32].y = 25;
        this.CardsPos[33].x = 352;
        this.CardsPos[33].y = 25;
        this.CardsPos[34].x = 330;
        this.CardsPos[34].y = 25;
        this.CardsPos[35].x = 308;
        this.CardsPos[35].y = 25;
        this.CardsPos[36].x = 286;
        this.CardsPos[36].y = 25;
        this.CardsPos[37].x = 264;
        this.CardsPos[37].y = 25;
        this.CardsPos[38].x = 242;
        this.CardsPos[38].y = 25;
        this.CardsPos[39].x = 220;
        this.CardsPos[39].y = 25;

        this.CardsPos[40].x = 700;
        this.CardsPos[40].y = 322;
        this.CardsPos[41].x = 700;
        this.CardsPos[41].y = 305;
        this.CardsPos[42].x = 700;
        this.CardsPos[42].y = 288;
        this.CardsPos[43].x = 700;
        this.CardsPos[43].y = 271;
        this.CardsPos[44].x = 700;
        this.CardsPos[44].y = 254;
        this.CardsPos[45].x = 700;
        this.CardsPos[45].y = 237;
        this.CardsPos[46].x = 700;
        this.CardsPos[46].y = 220;
        this.CardsPos[47].x = 700;
        this.CardsPos[47].y = 203;
        this.CardsPos[48].x = 700;
        this.CardsPos[48].y = 186;
        this.CardsPos[49].x = 700;
        this.CardsPos[49].y = 169;
        this.CardsPos[50].x = 700;
        this.CardsPos[50].y = 152;
        this.CardsPos[51].x = 700;
        this.CardsPos[51].y = 135;
        this.CardsPos[52].x = 700;
        this.CardsPos[52].y = 118;

        this.CardsPos[53].x = 360;
        this.CardsPos[53].y = 297;
        this.CardsPos[56].x = 435;
        this.CardsPos[56].y = 260;
        this.CardsPos[55].x = 360;
        this.CardsPos[55].y = 215;
        this.CardsPos[54].x = 285;
        this.CardsPos[54].y = 260;
        for (let i = 0; i < 3; i++) {
            this.Vzeti[i] = [];
        }
        for (let i = 1; i < 14; i++) {
            for (let a = 0; a < 3; a++) {
                this.Vzeti[a][i] = new CPoint(0, 0);
            }
        }
        this.Vzeti[0][1].x = 540;
        this.Vzeti[0][1].y = 402;
        for (let i = 1; i < 14; i++) {
            this.Vzeti[1][i].x = 611 + 6 * i;
            this.Vzeti[1][i].y = 402;
            this.Vzeti[2][i].x = 611 + 6 * i;
            this.Vzeti[2][i].y = 425;
        }
        this.TimeVzemane = false;
        this.TimeVzemane1 = 1;
        this.CardRect[1] = new CRect(this.CardsPos[1].x, this.CardsPos[1].y, this.CardsPos[2].x, this.CardsPos[1].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[2] = new CRect(this.CardsPos[2].x, this.CardsPos[2].y, this.CardsPos[3].x, this.CardsPos[2].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[3] = new CRect(this.CardsPos[3].x, this.CardsPos[3].y, this.CardsPos[4].x, this.CardsPos[3].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[4] = new CRect(this.CardsPos[4].x, this.CardsPos[4].y, this.CardsPos[5].x, this.CardsPos[4].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[5] = new CRect(this.CardsPos[5].x, this.CardsPos[5].y, this.CardsPos[6].x, this.CardsPos[5].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[6] = new CRect(this.CardsPos[6].x, this.CardsPos[6].y, this.CardsPos[7].x, this.CardsPos[6].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[7] = new CRect(this.CardsPos[7].x, this.CardsPos[7].y, this.CardsPos[8].x, this.CardsPos[7].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[8] = new CRect(this.CardsPos[8].x, this.CardsPos[8].y, this.CardsPos[9].x, this.CardsPos[8].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[9] = new CRect(this.CardsPos[9].x, this.CardsPos[9].y, this.CardsPos[10].x, this.CardsPos[9].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[10] = new CRect(
            this.CardsPos[10].x,
            this.CardsPos[10].y,
            this.CardsPos[11].x,
            this.CardsPos[10].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[11] = new CRect(
            this.CardsPos[11].x,
            this.CardsPos[11].y,
            this.CardsPos[12].x,
            this.CardsPos[11].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[12] = new CRect(
            this.CardsPos[12].x,
            this.CardsPos[12].y,
            this.CardsPos[13].x,
            this.CardsPos[12].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[13] = new CRect(
            this.CardsPos[13].x,
            this.CardsPos[13].y,
            this.CardsPos[13].x + GlobalConstants.CARD_WIDTH,
            this.CardsPos[13].y + GlobalConstants.CARD_HEIGHT
        );
        for (let i = 1; i < 53; i++) {
            if (i < 13) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i].y,
                    this.CardsPos[i + 1].x,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 13) {
                this.CardRect[13] = new CRect(
                    this.CardsPos[13].x,
                    this.CardsPos[13].y,
                    this.CardsPos[13].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[13].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i > 13 && i < 27) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i].y,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i + 1].y
                );
            }
            if (i === 27) {
                this.CardRect[27] = new CRect(
                    this.CardsPos[27].x,
                    this.CardsPos[27].y,
                    this.CardsPos[27].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[27].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i > 27 && i < 39) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i + 1].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 39) {
                this.CardRect[39] = new CRect(
                    this.CardsPos[39].x,
                    this.CardsPos[39].y,
                    this.CardsPos[39].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[39].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i < 52 && i >= 40) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i + 1].y + GlobalConstants.CARD_HEIGHT,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 52) {
                this.CardRect[52] = new CRect(
                    this.CardsPos[52].x,
                    this.CardsPos[52].y,
                    this.CardsPos[52].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[52].y + GlobalConstants.CARD_HEIGHT
                );
            }
        }
    }

    async LoadBitmaps() {
        let face = await this.GetProfileString('Options', 'facenew', 'cards_1');
        let cardPicture = 1;
        for (let i = 100; i <= 400; i = i + 100) {
            for (let a = 2; a <= 14; a++) {
                this.CardPicture[cardPicture] = 'cards/' + face + '/' + this.IntToString(i + a) + '.gif';
                cardPicture++;
            }
        }
        let back = await this.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        let grab = await this.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.CardPicture[0] = 'cards/' + back + '/' + this.IntToString(grab) + '.gif';
        this.CardP[0].image = this.CardPicture[0];
        this.rotate_card = 'cards/' + back + '/' + this.IntToString(grab + 8) + '.gif';
        this.LoadFiles();
    }

    InitGame() {
        this.Kontra = 0;
        this.Rekontra = 0;
        this.Izkarani = 0;
        this.wait_new_game = false;
        this.Pribirane = false;
        for (let i = 1; i < 5; i++) {
            this.PlayerI[i].AskingAsa = false;
            this.PlayerI[i].BroiAsa = 0;
            this.PlayerI[i].CloseAnons = false;
            this.PlayerI[i].MinBroiKari = 0;
            this.PlayerI[i].MinBroiKupi = 0;
            this.PlayerI[i].MinBroiPiki = 0;
            this.PlayerI[i].MinBroiSpatii = 0;
            this.PlayerI[i].MinPoints = 0;
            this.PlayerI[i].MaxPoints = 40;
            this.PlayerI[i].GestPoints = 0;
        }
        this.AnonBr = 0;
        for (let i = 0; i < 100; i++) {
            this.Anon[i] = 0;
        }
        if (this.type === 2) {
            if ((this.OP && this.Server) || this.SP) {
                if (this.zone === 1 && this.BroiIgri % 4 === 1) {
                    this.zone = 2;
                } else if (this.zone === 2 && this.BroiIgri % 4 === 1) {
                    this.zone = 1;
                }
                if (this.BroiIgri % 4 === 2) {
                    this.zone = 3;
                }
                if (this.BroiIgri % 4 === 3) {
                    this.zone = 0;
                }
                if (this.BroiIgri > 0 && this.BroiIgri % 4 === 0) {
                    this.zone = this.imp_result.fzone;
                }
            }
            if (this.BroiIgri === 0) {
                this.imp_result.fzone = this.zone;
            }
            this.BroiIgri++;
        }
        ///////////////////////////////////////
        this.LastSays[0] = 0;
        this.LastSays[1] = 0;
        this.LastSays[2] = 0;
        this.br[0] = 0;
        this.br[1] = 0;
        this.br[2] = 0;
        this.br[3] = 0;
        this.Mor = 0;
        ///////////////////////////////////////
        this.showrcard = 52;
        if ((this.OP && this.Server) || this.SP) {
            if (this.KoiZapochva < 4) {
                this.KoiZapochva = this.KoiZapochva + 1;
            } else {
                this.KoiZapochva = 1;
            }
        }
        if ((this.OP && this.Server) || this.SP) {
            this.KoiPuskaParvi = this.KoiENaRed = this.KoiObiaviava = this.KoiZapochva;
        }
        this.Faza = 0;
        this.Naddavane = 0;
        this.BrPas = 0;
        this.level = 0;
        this.hand = 0;
        if (this.SP || (this.OP && this.Server)) {
            this.Razdavane1 = true;
        }
        for (let i = 0; i < 53; i++) {
            this.k[i] = 0;
            this.CardsPoint[i].x = this.CardsPos[0].x;
            this.CardsPoint[i].y = this.CardsPos[0].y;
        }
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.strelka = 0;
        if ((this.OP && this.Server) || this.SP) {
            this.RandCards();
        }
        if ((this.OP && this.Server) || this.SP) {
            this.LoadCards(false, true);
        }
        /////////////////////////////////////////
        GlobalVariables.OtkritiKarti = 0;
        this.bidd.m_spa = -1;
        this.bidd.m_kar = -1;
        this.bidd.m_kup = -1;
        this.bidd.m_bk = -1;
        this.bidd.m_pik = -1;
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        //////////////////////////////////////////
        this.CardRect[1] = new CRect(this.CardsPos[1].x, this.CardsPos[1].y, this.CardsPos[2].x, this.CardsPos[1].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[2] = new CRect(this.CardsPos[2].x, this.CardsPos[2].y, this.CardsPos[3].x, this.CardsPos[2].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[3] = new CRect(this.CardsPos[3].x, this.CardsPos[3].y, this.CardsPos[4].x, this.CardsPos[3].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[4] = new CRect(this.CardsPos[4].x, this.CardsPos[4].y, this.CardsPos[5].x, this.CardsPos[4].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[5] = new CRect(this.CardsPos[5].x, this.CardsPos[5].y, this.CardsPos[6].x, this.CardsPos[5].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[6] = new CRect(this.CardsPos[6].x, this.CardsPos[6].y, this.CardsPos[7].x, this.CardsPos[6].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[7] = new CRect(this.CardsPos[7].x, this.CardsPos[7].y, this.CardsPos[8].x, this.CardsPos[7].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[8] = new CRect(this.CardsPos[8].x, this.CardsPos[8].y, this.CardsPos[9].x, this.CardsPos[8].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[9] = new CRect(this.CardsPos[9].x, this.CardsPos[9].y, this.CardsPos[10].x, this.CardsPos[9].y + GlobalConstants.CARD_HEIGHT);
        this.CardRect[10] = new CRect(
            this.CardsPos[10].x,
            this.CardsPos[10].y,
            this.CardsPos[11].x,
            this.CardsPos[10].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[11] = new CRect(
            this.CardsPos[11].x,
            this.CardsPos[11].y,
            this.CardsPos[12].x,
            this.CardsPos[11].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[12] = new CRect(
            this.CardsPos[12].x,
            this.CardsPos[12].y,
            this.CardsPos[13].x,
            this.CardsPos[12].y + GlobalConstants.CARD_HEIGHT
        );
        this.CardRect[13] = new CRect(
            this.CardsPos[13].x,
            this.CardsPos[13].y,
            this.CardsPos[13].x + GlobalConstants.CARD_WIDTH,
            this.CardsPos[13].y + GlobalConstants.CARD_HEIGHT
        );
        /////////////////
        for (let i = 1; i < 53; i++) {
            if (i < 13) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i].y,
                    this.CardsPos[i + 1].x,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 13) {
                this.CardRect[13] = new CRect(
                    this.CardsPos[13].x,
                    this.CardsPos[13].y,
                    this.CardsPos[13].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[13].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i > 13 && i < 26) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i].y,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i + 1].y
                );
            }
            if (i === 27) {
                this.CardRect[27] = new CRect(
                    this.CardsPos[27].x,
                    this.CardsPos[27].y,
                    this.CardsPos[27].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[27].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i > 27 && i < 39) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i + 1].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 39) {
                this.CardRect[39] = new CRect(
                    this.CardsPos[39].x,
                    this.CardsPos[39].y,
                    this.CardsPos[39].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[39].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i < 52 && i >= 40) {
                this.CardRect[i] = new CRect(
                    this.CardsPos[i].x,
                    this.CardsPos[i + 1].y + GlobalConstants.CARD_HEIGHT,
                    this.CardsPos[i].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[i].y + GlobalConstants.CARD_HEIGHT
                );
            }
            if (i === 52) {
                this.CardRect[52] = new CRect(
                    this.CardsPos[52].x,
                    this.CardsPos[52].y,
                    this.CardsPos[52].x + GlobalConstants.CARD_WIDTH,
                    this.CardsPos[52].y + GlobalConstants.CARD_HEIGHT
                );
            }
        }
        if (this.OP) {
            if (!this.watch) {
                this.props.m_pwin.SendGame('$|y');
            }
        }
        for (let i = 1; i < 5; i++) {
            if (this.last_Name[i] === '') {
                this.last_Name[i] = this.Name[i];
            }
        }
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        this.obiaviavaneRN = null;
    }

    LoadCards(begin: boolean, sound: boolean) {
        if (begin) {
            let pictureIndex = 1;
            for (let i = 100; i <= 400; i = i + 100) {
                for (let a = 2; a <= 14; a++) {
                    if (this.P1puska === i + a || this.P1puska === 500 + a) {
                        this.CardP[53].image = this.CardPicture[pictureIndex];
                    }
                    if (this.P2puska === i + a || this.P2puska === 500 + a) {
                        this.CardP[54].image = this.CardPicture[pictureIndex];
                    }
                    if (this.P3puska === i + a || this.P3puska === 500 + a) {
                        this.CardP[55].image = this.CardPicture[pictureIndex];
                    }
                    if (this.P4puska === i + a || this.P4puska === 500 + a) {
                        this.CardP[56].image = this.CardPicture[pictureIndex];
                    }
                    pictureIndex = pictureIndex + 1;
                }
            }
        }

        for (let i = 1; i < 53; i++) {
            if (!begin) {
                let pictureIndex = 1;
                for (let i1 = 100; i1 <= 400; i1 = i1 + 100) {
                    for (let a = 2; a <= 14; a++) {
                        if (this.k[i] === i1 + a) {
                            this.CardP[i].image = this.CardPicture[pictureIndex];
                        }
                        pictureIndex = pictureIndex + 1;
                    }
                }
            } else {
                this.ConvertCard(this.k[i], i);
            }
        }
        if (!begin) {
            this.FullFCard();
        }
        //if(GlobalVariables.Zvuk==1 && begin === false && sound === true)m_sndSound.PlayFile("rand_cards", true);
        if (GlobalVariables.Zvuk === 1 && begin === false && sound === true) {
            this.props.m_pwin.m_sndSound.PlayFile('deal', false);
        }
    }

    FullFCard() {
        for (let i1 = 0; i1 < 4; i1++) {
            this.PlayerFCards[i1] = [];
            for (let i2 = 0; i2 < 13; i2++) {
                this.PlayerFCards[i1][i2] = [];
                this.PlayerFCards[i1][i2][0] = 0;
                this.PlayerFCards[i1][i2][1] = 0;
            }
        }
        for (let i = 1; i < 53; i++) {
            this.PlayerFCards[Math.floor((i - 1 - ((i - 1) % 13)) / 13)][(i - 1) % 13][0] = (this.k[i] % 100) - 1;
            this.PlayerFCards[Math.floor((i - 1 - ((i - 1) % 13)) / 13)][(i - 1) % 13][1] = Math.floor((this.k[i] - (this.k[i] % 100)) / 100);
            this.NachalniKarti[i] = this.k[i];
        }
    }

    ConvertCard(num: number, index: number) {
        if (this.Naddavane === 1) {
            if (num === 502) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 503) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 504) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 505) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 506) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 507) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 508) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 509) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 510) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 511) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 512) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 513) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 514) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }
            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 2) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 502) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 503) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 504) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 505) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 506) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 507) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 508) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 509) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 510) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 511) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 512) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 513) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 514) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 3) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 502) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 503) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 504) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 505) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 506) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 507) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 508) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 509) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 510) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 511) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 512) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 513) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 514) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 4) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 502) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 503) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 504) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 505) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 506) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 507) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 508) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 509) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 510) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 511) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 512) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 513) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 514) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
        if (this.Naddavane === 5) {
            if (num === 102) {
                this.CardP[index].image = this.CardPicture[1];
            }
            if (num === 103) {
                this.CardP[index].image = this.CardPicture[2];
            }
            if (num === 104) {
                this.CardP[index].image = this.CardPicture[3];
            }
            if (num === 105) {
                this.CardP[index].image = this.CardPicture[4];
            }
            if (num === 106) {
                this.CardP[index].image = this.CardPicture[5];
            }
            if (num === 107) {
                this.CardP[index].image = this.CardPicture[6];
            }
            if (num === 108) {
                this.CardP[index].image = this.CardPicture[7];
            }
            if (num === 109) {
                this.CardP[index].image = this.CardPicture[8];
            }
            if (num === 110) {
                this.CardP[index].image = this.CardPicture[9];
            }
            if (num === 111) {
                this.CardP[index].image = this.CardPicture[10];
            }
            if (num === 112) {
                this.CardP[index].image = this.CardPicture[11];
            }
            if (num === 113) {
                this.CardP[index].image = this.CardPicture[12];
            }
            if (num === 114) {
                this.CardP[index].image = this.CardPicture[13];
            }

            if (num === 202) {
                this.CardP[index].image = this.CardPicture[14];
            }
            if (num === 203) {
                this.CardP[index].image = this.CardPicture[15];
            }
            if (num === 204) {
                this.CardP[index].image = this.CardPicture[16];
            }
            if (num === 205) {
                this.CardP[index].image = this.CardPicture[17];
            }
            if (num === 206) {
                this.CardP[index].image = this.CardPicture[18];
            }
            if (num === 207) {
                this.CardP[index].image = this.CardPicture[19];
            }
            if (num === 208) {
                this.CardP[index].image = this.CardPicture[20];
            }
            if (num === 209) {
                this.CardP[index].image = this.CardPicture[21];
            }
            if (num === 210) {
                this.CardP[index].image = this.CardPicture[22];
            }
            if (num === 211) {
                this.CardP[index].image = this.CardPicture[23];
            }
            if (num === 212) {
                this.CardP[index].image = this.CardPicture[24];
            }
            if (num === 213) {
                this.CardP[index].image = this.CardPicture[25];
            }
            if (num === 214) {
                this.CardP[index].image = this.CardPicture[26];
            }

            if (num === 302) {
                this.CardP[index].image = this.CardPicture[27];
            }
            if (num === 303) {
                this.CardP[index].image = this.CardPicture[28];
            }
            if (num === 304) {
                this.CardP[index].image = this.CardPicture[29];
            }
            if (num === 305) {
                this.CardP[index].image = this.CardPicture[30];
            }
            if (num === 306) {
                this.CardP[index].image = this.CardPicture[31];
            }
            if (num === 307) {
                this.CardP[index].image = this.CardPicture[32];
            }
            if (num === 308) {
                this.CardP[index].image = this.CardPicture[33];
            }
            if (num === 309) {
                this.CardP[index].image = this.CardPicture[34];
            }
            if (num === 310) {
                this.CardP[index].image = this.CardPicture[35];
            }
            if (num === 311) {
                this.CardP[index].image = this.CardPicture[36];
            }
            if (num === 312) {
                this.CardP[index].image = this.CardPicture[37];
            }
            if (num === 313) {
                this.CardP[index].image = this.CardPicture[38];
            }
            if (num === 314) {
                this.CardP[index].image = this.CardPicture[39];
            }

            if (num === 402) {
                this.CardP[index].image = this.CardPicture[40];
            }
            if (num === 403) {
                this.CardP[index].image = this.CardPicture[41];
            }
            if (num === 404) {
                this.CardP[index].image = this.CardPicture[42];
            }
            if (num === 405) {
                this.CardP[index].image = this.CardPicture[43];
            }
            if (num === 406) {
                this.CardP[index].image = this.CardPicture[44];
            }
            if (num === 407) {
                this.CardP[index].image = this.CardPicture[45];
            }
            if (num === 408) {
                this.CardP[index].image = this.CardPicture[46];
            }
            if (num === 409) {
                this.CardP[index].image = this.CardPicture[47];
            }
            if (num === 410) {
                this.CardP[index].image = this.CardPicture[48];
            }
            if (num === 411) {
                this.CardP[index].image = this.CardPicture[49];
            }
            if (num === 412) {
                this.CardP[index].image = this.CardPicture[50];
            }
            if (num === 413) {
                this.CardP[index].image = this.CardPicture[51];
            }
            if (num === 414) {
                this.CardP[index].image = this.CardPicture[52];
            }
        }
    }

    RandCards() {
        let Numbers: number[] = [];
        for (let i = 0; i < 52; i++) {
            Numbers[i] = i + 1;
        }
        let randomArray = this.randomShuffle(Numbers);
        for (let i = 1; i < 53; i++) {
            if (randomArray[i - 1] === 1) {
                this.k[i] = 102;
            }
            if (randomArray[i - 1] === 2) {
                this.k[i] = 103;
            }
            if (randomArray[i - 1] === 3) {
                this.k[i] = 104;
            }
            if (randomArray[i - 1] === 4) {
                this.k[i] = 105;
            }
            if (randomArray[i - 1] === 5) {
                this.k[i] = 106;
            }
            if (randomArray[i - 1] === 6) {
                this.k[i] = 107;
            }
            if (randomArray[i - 1] === 7) {
                this.k[i] = 108;
            }
            if (randomArray[i - 1] === 8) {
                this.k[i] = 109;
            }
            if (randomArray[i - 1] === 9) {
                this.k[i] = 110;
            }
            if (randomArray[i - 1] === 10) {
                this.k[i] = 111;
            }
            if (randomArray[i - 1] === 11) {
                this.k[i] = 112;
            }
            if (randomArray[i - 1] === 12) {
                this.k[i] = 113;
            }
            if (randomArray[i - 1] === 13) {
                this.k[i] = 114;
            }
            if (randomArray[i - 1] === 14) {
                this.k[i] = 202;
            }
            if (randomArray[i - 1] === 15) {
                this.k[i] = 203;
            }
            if (randomArray[i - 1] === 16) {
                this.k[i] = 204;
            }
            if (randomArray[i - 1] === 17) {
                this.k[i] = 205;
            }
            if (randomArray[i - 1] === 18) {
                this.k[i] = 206;
            }
            if (randomArray[i - 1] === 19) {
                this.k[i] = 207;
            }
            if (randomArray[i - 1] === 20) {
                this.k[i] = 208;
            }
            if (randomArray[i - 1] === 21) {
                this.k[i] = 209;
            }
            if (randomArray[i - 1] === 22) {
                this.k[i] = 210;
            }
            if (randomArray[i - 1] === 23) {
                this.k[i] = 211;
            }
            if (randomArray[i - 1] === 24) {
                this.k[i] = 212;
            }
            if (randomArray[i - 1] === 25) {
                this.k[i] = 213;
            }
            if (randomArray[i - 1] === 26) {
                this.k[i] = 214;
            }
            if (randomArray[i - 1] === 27) {
                this.k[i] = 302;
            }
            if (randomArray[i - 1] === 28) {
                this.k[i] = 303;
            }
            if (randomArray[i - 1] === 29) {
                this.k[i] = 304;
            }
            if (randomArray[i - 1] === 30) {
                this.k[i] = 305;
            }
            if (randomArray[i - 1] === 31) {
                this.k[i] = 306;
            }
            if (randomArray[i - 1] === 32) {
                this.k[i] = 307;
            }
            if (randomArray[i - 1] === 33) {
                this.k[i] = 308;
            }
            if (randomArray[i - 1] === 34) {
                this.k[i] = 309;
            }
            if (randomArray[i - 1] === 35) {
                this.k[i] = 310;
            }
            if (randomArray[i - 1] === 36) {
                this.k[i] = 311;
            }
            if (randomArray[i - 1] === 37) {
                this.k[i] = 312;
            }
            if (randomArray[i - 1] === 38) {
                this.k[i] = 313;
            }
            if (randomArray[i - 1] === 39) {
                this.k[i] = 314;
            }
            if (randomArray[i - 1] === 40) {
                this.k[i] = 402;
            }
            if (randomArray[i - 1] === 41) {
                this.k[i] = 403;
            }
            if (randomArray[i - 1] === 42) {
                this.k[i] = 404;
            }
            if (randomArray[i - 1] === 43) {
                this.k[i] = 405;
            }
            if (randomArray[i - 1] === 44) {
                this.k[i] = 406;
            }
            if (randomArray[i - 1] === 45) {
                this.k[i] = 407;
            }
            if (randomArray[i - 1] === 46) {
                this.k[i] = 408;
            }
            if (randomArray[i - 1] === 47) {
                this.k[i] = 409;
            }
            if (randomArray[i - 1] === 48) {
                this.k[i] = 410;
            }
            if (randomArray[i - 1] === 49) {
                this.k[i] = 411;
            }
            if (randomArray[i - 1] === 50) {
                this.k[i] = 412;
            }
            if (randomArray[i - 1] === 51) {
                this.k[i] = 413;
            }
            if (randomArray[i - 1] === 52) {
                this.k[i] = 414;
            }
        }
    }

    Viziatka() {
        this.ParviPuska = 0;
        this.VtoriPuska = 0;
        this.TretiPuska = 0;
        this.ChetvartiPuska = 0;
        if (this.hand > 0) {
            this.TimeVzemane = true;
        }
        this.props.m_pwin.KillTimer(4);
        this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
    }

    SendCards() {
        if (this.P2OP) {
            this.P2G = false;
            for (let a = 14; a < 53; a++) {
                this.props.m_pwin.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
            for (let a = 1; a < 14; a++) {
                this.props.m_pwin.SendGame('$|c|' + this.IntToString(this.k[a]));
            }
        }
        if (this.P3OP) {
            this.P3G = false;
            for (let a = 27; a < 53; a++) {
                this.props.m_pwin.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
            for (let a = 1; a < 27; a++) {
                this.props.m_pwin.SendGame('$|f|' + this.IntToString(this.k[a]));
            }
        }
        if (this.P4OP) {
            this.P4G = false;
            for (let a = 40; a < 53; a++) {
                this.props.m_pwin.SendGame('$|i|' + this.IntToString(this.k[a]));
            }
            for (let a = 1; a < 40; a++) {
                this.props.m_pwin.SendGame('$|i|' + this.IntToString(this.k[a]));
            }
        }
        if (this.HaveWatch) {
            for (let a = 1; a < 53; a++) {
                this.props.m_pwin.SendGame('$|j|' + this.IntToString(this.k[a]));
            }
        }
    }

    async Timer1() {
        if (this.loading || this.loadingMain) {
            return;
        }
        if (this.Faza > 4 && this.RefreshMor && this.Faza < 9) {
            this.GetMor();
        }
        /////////////////////////
        if (this.Faza === 4) {
            if (this.ParviPuska > 0) {
                this.Faza = 5;
                return;
            }
        }
        if (this.Faza === 5) {
            this.GetMor();
            this.Faza = 6;
            return;
        }
        if (this.Faza === 6 && this.hand === 13) {
            this.Faza = 7;
            return;
        }
        /////////////////////////////
        if (this.SP === true) {
            if (this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0 && !this.Pribirane) {
                this.Viziatka();
                this.Pribirane = true;
            }
            if (this.Move === true) {
                this.MoveCard(!this.Razdavane1);
                this.setState({});
            }
            if (this.Razdavane1 === true && this.Move !== true && this.Faza < 4) {
                let karta = this.KartaZaRazdavane();
                this.setState({});
                this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
            }
            if (this.Razdavane1 !== true && this.Move !== true && this.Faza === 1) {
                this.Faza = 2;
                this.SortCards(0);
                this.setState({});
                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            }
        }
        if (this.OP) {
            if (this.Server) {
                if (this.Faza === 10 && this.P2G === true && this.P3G === true && this.P4G === true) {
                    if (this.P2OP === true) {
                        this.P2G = false;
                    }
                    if (this.P3OP === true) {
                        this.P3G = false;
                    }
                    if (this.P4OP === true) {
                        this.P4G = false;
                    }
                    this.InitGame();
                    this.SendKoiObiaviava();
                    this.SendCards();
                    this.setState({});
                    this.Razdavane1 = true;
                    this.online_timer = this.online_timer_time * 1.5;
                }
                if (this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0 && !this.Pribirane) {
                    this.Viziatka();
                    this.Pribirane = true;
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1);
                    this.setState({});
                }
                if (this.Razdavane1 === true && this.Move !== true && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.setState({});
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (this.Razdavane1 !== true && this.Move !== true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
            } else {
                if (this.Move !== true && this.P1puska > 0 && this.P2puska > 0 && this.P3puska > 0 && this.P4puska > 0 && !this.Pribirane) {
                    this.Viziatka();
                    this.Pribirane = true;
                }
                if (this.Move === true) {
                    this.MoveCard(!this.Razdavane1);
                    this.setState({});
                }
                if (this.Razdavane1 === true && this.Move !== true && this.Faza < 4) {
                    let karta = this.KartaZaRazdavane();
                    this.setState({});
                    this.BeginMove(this.CardsPoint[karta], this.CardsPos[karta], karta);
                }
                if (this.Razdavane1 !== true && this.Move !== true && this.Faza === 1) {
                    this.Faza = 2;
                    this.SortCards(0);
                    this.setState({});
                    this.props.m_pwin.KillTimer(2);
                    this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                }
            }
        }
    }

    SortCards(type: number) {
        if (this.Faza < 4) {
            let a: number;
            let sort: number[] = [];
            let temps;
            let tempk;
            let tempc: string;
            if (type === 0) {
                for (let i = 1; i < 14; i++) {
                    for (a = 1; a < 13; a++) {
                        if (this.k[a] > this.k[a + 1]) {
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 14; i < 27; i++) {
                    for (a = 14; a < 26; a++) {
                        if (this.k[a] > this.k[a + 1]) {
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 27; i < 40; i++) {
                    for (a = 27; a < 39; a++) {
                        if (this.k[a] > this.k[a + 1]) {
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 40; i < 53; i++) {
                    for (a = 40; a < 52; a++) {
                        if (this.k[a] > this.k[a + 1]) {
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
            }
            if (type === 1) {
                for (let i = 1; i < 14; i++) {
                    this.k[i] = this.ReturnSortCards(this.k[i]);
                    sort[i] = this.k[i];
                }
                for (let i = 1; i < 14; i++) {
                    for (a = 1; a < 13; a++) {
                        if (sort[a] > sort[a + 1]) {
                            temps = sort[a + 1];
                            sort[a + 1] = sort[a];
                            sort[a] = temps;
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 14; i < 27; i++) {
                    this.k[i] = this.ReturnSortCards(this.k[i]);
                    sort[i] = this.k[i];
                }
                for (let i = 14; i < 27; i++) {
                    for (a = 14; a < 26; a++) {
                        if (sort[a] > sort[a + 1]) {
                            temps = sort[a + 1];
                            sort[a + 1] = sort[a];
                            sort[a] = temps;
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 27; i < 40; i++) {
                    this.k[i] = this.ReturnSortCards(this.k[i]);
                    sort[i] = this.k[i];
                }
                for (let i = 27; i < 40; i++) {
                    for (a = 27; a < 39; a++) {
                        if (sort[a] > sort[a + 1]) {
                            temps = sort[a + 1];
                            sort[a + 1] = sort[a];
                            sort[a] = temps;
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
                for (let i = 40; i < 53; i++) {
                    this.k[i] = this.ReturnSortCards(this.k[i]);
                    sort[i] = this.k[i];
                }
                for (let i = 40; i < 53; i++) {
                    for (a = 40; a < 52; a++) {
                        if (sort[a] > sort[a + 1]) {
                            temps = sort[a + 1];
                            sort[a + 1] = sort[a];
                            sort[a] = temps;
                            tempk = this.k[a + 1];
                            this.k[a + 1] = this.k[a];
                            this.k[a] = tempk;
                            tempc = this.CardP[a + 1].image;
                            this.CardP[a + 1].image = this.CardP[a].image;
                            this.CardP[a].image = tempc;
                        }
                    }
                }
            }
        }
    }

    ReturnSortCards(card: number) {
        if (this.Naddavane === 1) {
            if (card > 100 && card < 200) {
                return card + 400;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 2) {
            if (card > 200 && card < 300) {
                return card + 300;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 3) {
            if (card > 300 && card < 400) {
                return card + 200;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 4) {
            if (card > 400 && card < 500) {
                return card + 100;
            } else {
                return card;
            }
        }
        if (this.Naddavane === 5) {
            return card;
        }
        return 0;
    }

    KartaZaRazdavane() {
        if (this.KoiZapochva === 1) {
            if (this.Razdavane1 === true) {
                this.showrcard = 51;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 50;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 49;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 48;
                if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                    return 40;
                }
                this.showrcard = 47;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 46;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 45;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 44;
                if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                    return 41;
                }
                this.showrcard = 43;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 42;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 41;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 40;
                if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                    return 42;
                }
                this.showrcard = 39;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 38;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 37;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 36;
                if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                    return 43;
                }
                this.showrcard = 35;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 34;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 33;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 32;
                if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                    return 44;
                }
                this.showrcard = 31;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 30;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 29;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 28;
                if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                    return 45;
                }
                this.showrcard = 27;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 26;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 25;
                if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                    return 33;
                }
                this.showrcard = 24;
                if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                    return 46;
                }
                this.showrcard = 23;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 22;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 21;
                if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                    return 34;
                }
                this.showrcard = 20;
                if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                    return 47;
                }
                this.showrcard = 19;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 18;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 17;
                if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                    return 35;
                }
                this.showrcard = 16;
                if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                    return 48;
                }
                this.showrcard = 15;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 14;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 13;
                if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                    return 36;
                }
                this.showrcard = 12;
                if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                    return 49;
                }
                this.showrcard = 11;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 10;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 9;
                if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                    return 37;
                }
                this.showrcard = 8;
                if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                    return 50;
                }
                this.showrcard = 7;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 6;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 5;
                if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                    return 38;
                }
                this.showrcard = 4;
                if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                    return 51;
                }
                this.showrcard = 3;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 2;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 1;
                if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                    return 39;
                }
                this.showrcard = 0;
                this.Razdavane1 = false;
                this.Faza = 1;
                this.online_timer = this.online_timer_time * 1.5;
                if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                    return 52;
                }
            }
        }
        if (this.KoiZapochva === 2) {
            if (this.Razdavane1 === true) {
                this.showrcard = 51;
                if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                    return 40;
                }
                this.showrcard = 50;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 49;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 48;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 47;
                if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                    return 41;
                }
                this.showrcard = 46;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 45;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 44;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 43;
                if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                    return 42;
                }
                this.showrcard = 42;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 41;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 40;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 39;
                if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                    return 43;
                }
                this.showrcard = 38;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 37;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 36;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 35;
                if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                    return 44;
                }
                this.showrcard = 34;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 33;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 32;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 31;
                if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                    return 45;
                }
                this.showrcard = 30;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 29;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 28;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 27;
                if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                    return 46;
                }
                this.showrcard = 26;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 25;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 24;
                if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                    return 33;
                }
                this.showrcard = 23;
                if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                    return 47;
                }
                this.showrcard = 22;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 21;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 20;
                if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                    return 34;
                }
                this.showrcard = 19;
                if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                    return 48;
                }
                this.showrcard = 18;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 17;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 16;
                if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                    return 35;
                }
                this.showrcard = 15;
                if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                    return 49;
                }
                this.showrcard = 14;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 13;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 12;
                if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                    return 36;
                }
                this.showrcard = 11;
                if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                    return 50;
                }
                this.showrcard = 10;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 9;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 8;
                if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                    return 37;
                }
                this.showrcard = 7;
                if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                    return 51;
                }
                this.showrcard = 6;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 5;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 4;
                if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                    return 38;
                }
                this.showrcard = 3;
                if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                    return 52;
                }
                this.showrcard = 2;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 1;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 0;
                this.Razdavane1 = false;
                this.Faza = 1;
                if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                    return 39;
                }
                this.online_timer = this.online_timer_time * 1.5;
            }
        }
        if (this.KoiZapochva === 3) {
            if (this.Razdavane1 === true) {
                this.showrcard = 51;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 50;
                if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                    return 40;
                }
                this.showrcard = 49;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 48;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 47;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 46;
                if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                    return 41;
                }
                this.showrcard = 45;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 44;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 43;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 42;
                if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                    return 42;
                }
                this.showrcard = 41;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 40;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 39;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 38;
                if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                    return 43;
                }
                this.showrcard = 37;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 36;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 35;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 34;
                if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                    return 44;
                }
                this.showrcard = 33;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 32;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 31;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 30;
                if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                    return 45;
                }
                this.showrcard = 29;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 28;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 27;
                if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                    return 33;
                }
                this.showrcard = 26;
                if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                    return 46;
                }
                this.showrcard = 25;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 24;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 23;
                if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                    return 34;
                }
                this.showrcard = 22;
                if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                    return 47;
                }
                this.showrcard = 21;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 20;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 19;
                if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                    return 35;
                }
                this.showrcard = 18;
                if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                    return 48;
                }
                this.showrcard = 17;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 16;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 15;
                if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                    return 36;
                }
                this.showrcard = 14;
                if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                    return 49;
                }
                this.showrcard = 13;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 12;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 11;
                if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                    return 37;
                }
                this.showrcard = 10;
                if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                    return 50;
                }
                this.showrcard = 9;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 8;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 7;
                if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                    return 38;
                }
                this.showrcard = 6;
                if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                    return 51;
                }
                this.showrcard = 5;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 4;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 3;
                if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                    return 39;
                }
                this.showrcard = 2;
                if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                    return 52;
                }
                this.showrcard = 1;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
                this.showrcard = 0;
                this.Razdavane1 = false;
                this.Faza = 1;
                this.online_timer = this.online_timer_time * 1.5;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
            }
        }
        if (this.KoiZapochva === 4) {
            if (this.Razdavane1 === true) {
                this.showrcard = 51;
                if (this.CardsPoint[14].x === this.CardsPos[0].x && this.CardsPoint[14].y === this.CardsPos[0].y) {
                    return 14;
                }
                this.showrcard = 50;
                if (this.CardsPoint[27].x === this.CardsPos[0].x && this.CardsPoint[27].y === this.CardsPos[0].y) {
                    return 27;
                }
                this.showrcard = 49;
                if (this.CardsPoint[40].x === this.CardsPos[0].x && this.CardsPoint[40].y === this.CardsPos[0].y) {
                    return 40;
                }
                this.showrcard = 48;
                if (this.CardsPoint[1].x === this.CardsPos[0].x && this.CardsPoint[1].y === this.CardsPos[0].y) {
                    return 1;
                }
                this.showrcard = 47;
                if (this.CardsPoint[15].x === this.CardsPos[0].x && this.CardsPoint[15].y === this.CardsPos[0].y) {
                    return 15;
                }
                this.showrcard = 46;
                if (this.CardsPoint[28].x === this.CardsPos[0].x && this.CardsPoint[28].y === this.CardsPos[0].y) {
                    return 28;
                }
                this.showrcard = 45;
                if (this.CardsPoint[41].x === this.CardsPos[0].x && this.CardsPoint[41].y === this.CardsPos[0].y) {
                    return 41;
                }
                this.showrcard = 44;
                if (this.CardsPoint[2].x === this.CardsPos[0].x && this.CardsPoint[2].y === this.CardsPos[0].y) {
                    return 2;
                }
                this.showrcard = 43;
                if (this.CardsPoint[16].x === this.CardsPos[0].x && this.CardsPoint[16].y === this.CardsPos[0].y) {
                    return 16;
                }
                this.showrcard = 42;
                if (this.CardsPoint[29].x === this.CardsPos[0].x && this.CardsPoint[29].y === this.CardsPos[0].y) {
                    return 29;
                }
                this.showrcard = 41;
                if (this.CardsPoint[42].x === this.CardsPos[0].x && this.CardsPoint[42].y === this.CardsPos[0].y) {
                    return 42;
                }
                this.showrcard = 40;
                if (this.CardsPoint[3].x === this.CardsPos[0].x && this.CardsPoint[3].y === this.CardsPos[0].y) {
                    return 3;
                }
                this.showrcard = 39;
                if (this.CardsPoint[17].x === this.CardsPos[0].x && this.CardsPoint[17].y === this.CardsPos[0].y) {
                    return 17;
                }
                this.showrcard = 38;
                if (this.CardsPoint[30].x === this.CardsPos[0].x && this.CardsPoint[30].y === this.CardsPos[0].y) {
                    return 30;
                }
                this.showrcard = 37;
                if (this.CardsPoint[43].x === this.CardsPos[0].x && this.CardsPoint[43].y === this.CardsPos[0].y) {
                    return 43;
                }
                this.showrcard = 36;
                if (this.CardsPoint[4].x === this.CardsPos[0].x && this.CardsPoint[4].y === this.CardsPos[0].y) {
                    return 4;
                }
                this.showrcard = 35;
                if (this.CardsPoint[18].x === this.CardsPos[0].x && this.CardsPoint[18].y === this.CardsPos[0].y) {
                    return 18;
                }
                this.showrcard = 34;
                if (this.CardsPoint[31].x === this.CardsPos[0].x && this.CardsPoint[31].y === this.CardsPos[0].y) {
                    return 31;
                }
                this.showrcard = 33;
                if (this.CardsPoint[44].x === this.CardsPos[0].x && this.CardsPoint[44].y === this.CardsPos[0].y) {
                    return 44;
                }
                this.showrcard = 32;
                if (this.CardsPoint[5].x === this.CardsPos[0].x && this.CardsPoint[5].y === this.CardsPos[0].y) {
                    return 5;
                }
                this.showrcard = 31;
                if (this.CardsPoint[19].x === this.CardsPos[0].x && this.CardsPoint[19].y === this.CardsPos[0].y) {
                    return 19;
                }
                this.showrcard = 30;
                if (this.CardsPoint[32].x === this.CardsPos[0].x && this.CardsPoint[32].y === this.CardsPos[0].y) {
                    return 32;
                }
                this.showrcard = 29;
                if (this.CardsPoint[45].x === this.CardsPos[0].x && this.CardsPoint[45].y === this.CardsPos[0].y) {
                    return 45;
                }
                this.showrcard = 28;
                if (this.CardsPoint[6].x === this.CardsPos[0].x && this.CardsPoint[6].y === this.CardsPos[0].y) {
                    return 6;
                }
                this.showrcard = 27;
                if (this.CardsPoint[20].x === this.CardsPos[0].x && this.CardsPoint[20].y === this.CardsPos[0].y) {
                    return 20;
                }
                this.showrcard = 26;
                if (this.CardsPoint[33].x === this.CardsPos[0].x && this.CardsPoint[33].y === this.CardsPos[0].y) {
                    return 33;
                }
                this.showrcard = 25;
                if (this.CardsPoint[46].x === this.CardsPos[0].x && this.CardsPoint[46].y === this.CardsPos[0].y) {
                    return 46;
                }
                this.showrcard = 24;
                if (this.CardsPoint[7].x === this.CardsPos[0].x && this.CardsPoint[7].y === this.CardsPos[0].y) {
                    return 7;
                }
                this.showrcard = 23;
                if (this.CardsPoint[21].x === this.CardsPos[0].x && this.CardsPoint[21].y === this.CardsPos[0].y) {
                    return 21;
                }
                this.showrcard = 22;
                if (this.CardsPoint[34].x === this.CardsPos[0].x && this.CardsPoint[34].y === this.CardsPos[0].y) {
                    return 34;
                }
                this.showrcard = 21;
                if (this.CardsPoint[47].x === this.CardsPos[0].x && this.CardsPoint[47].y === this.CardsPos[0].y) {
                    return 47;
                }
                this.showrcard = 20;
                if (this.CardsPoint[8].x === this.CardsPos[0].x && this.CardsPoint[8].y === this.CardsPos[0].y) {
                    return 8;
                }
                this.showrcard = 19;
                if (this.CardsPoint[22].x === this.CardsPos[0].x && this.CardsPoint[22].y === this.CardsPos[0].y) {
                    return 22;
                }
                this.showrcard = 18;
                if (this.CardsPoint[35].x === this.CardsPos[0].x && this.CardsPoint[35].y === this.CardsPos[0].y) {
                    return 35;
                }
                this.showrcard = 17;
                if (this.CardsPoint[48].x === this.CardsPos[0].x && this.CardsPoint[48].y === this.CardsPos[0].y) {
                    return 48;
                }
                this.showrcard = 16;
                if (this.CardsPoint[9].x === this.CardsPos[0].x && this.CardsPoint[9].y === this.CardsPos[0].y) {
                    return 9;
                }
                this.showrcard = 15;
                if (this.CardsPoint[23].x === this.CardsPos[0].x && this.CardsPoint[23].y === this.CardsPos[0].y) {
                    return 23;
                }
                this.showrcard = 14;
                if (this.CardsPoint[36].x === this.CardsPos[0].x && this.CardsPoint[36].y === this.CardsPos[0].y) {
                    return 36;
                }
                this.showrcard = 13;
                if (this.CardsPoint[49].x === this.CardsPos[0].x && this.CardsPoint[49].y === this.CardsPos[0].y) {
                    return 49;
                }
                this.showrcard = 12;
                if (this.CardsPoint[10].x === this.CardsPos[0].x && this.CardsPoint[10].y === this.CardsPos[0].y) {
                    return 10;
                }
                this.showrcard = 11;
                if (this.CardsPoint[24].x === this.CardsPos[0].x && this.CardsPoint[24].y === this.CardsPos[0].y) {
                    return 24;
                }
                this.showrcard = 10;
                if (this.CardsPoint[37].x === this.CardsPos[0].x && this.CardsPoint[37].y === this.CardsPos[0].y) {
                    return 37;
                }
                this.showrcard = 9;
                if (this.CardsPoint[50].x === this.CardsPos[0].x && this.CardsPoint[50].y === this.CardsPos[0].y) {
                    return 50;
                }
                this.showrcard = 8;
                if (this.CardsPoint[11].x === this.CardsPos[0].x && this.CardsPoint[11].y === this.CardsPos[0].y) {
                    return 11;
                }
                this.showrcard = 7;
                if (this.CardsPoint[25].x === this.CardsPos[0].x && this.CardsPoint[25].y === this.CardsPos[0].y) {
                    return 25;
                }
                this.showrcard = 6;
                if (this.CardsPoint[38].x === this.CardsPos[0].x && this.CardsPoint[38].y === this.CardsPos[0].y) {
                    return 38;
                }
                this.showrcard = 5;
                if (this.CardsPoint[51].x === this.CardsPos[0].x && this.CardsPoint[51].y === this.CardsPos[0].y) {
                    return 51;
                }
                this.showrcard = 4;
                if (this.CardsPoint[12].x === this.CardsPos[0].x && this.CardsPoint[12].y === this.CardsPos[0].y) {
                    return 12;
                }
                this.showrcard = 3;
                if (this.CardsPoint[26].x === this.CardsPos[0].x && this.CardsPoint[26].y === this.CardsPos[0].y) {
                    return 26;
                }
                this.showrcard = 2;
                if (this.CardsPoint[39].x === this.CardsPos[0].x && this.CardsPoint[39].y === this.CardsPos[0].y) {
                    return 39;
                }
                this.showrcard = 1;
                if (this.CardsPoint[52].x === this.CardsPos[0].x && this.CardsPoint[52].y === this.CardsPos[0].y) {
                    return 52;
                }
                this.showrcard = 0;
                this.Razdavane1 = false;
                this.Faza = 1;
                this.online_timer = this.online_timer_time * 1.5;
                if (this.CardsPoint[13].x === this.CardsPos[0].x && this.CardsPoint[13].y === this.CardsPos[0].y) {
                    return 13;
                }
            }
        }
        return 0;
    }

    GetMor() {
        let k1: number[] = [];
        for (let i = 0; i < 53; i++) {
            if (this.k[i] > 500) {
                k1[i] = this.LastSays[2] * 100 + (this.k[i] % 100);
            } else {
                k1[i] = this.k[i];
            }
        }
        let step = 25;
        let cvet: number[] = [0, 0, 0, 0];
        let broi: number[] = [];
        if (this.Mor === 3) {
            broi[0] = 0;
            broi[1] = 0;
            broi[2] = 0;
            broi[3] = 0;
            for (let i = 27; i < 40; i++) {
                if (k1[i] > 100 && k1[i] < 200) {
                    broi[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    broi[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    broi[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    broi[3]++;
                }
            }
            for (let i = 39; i > 26; i--) {
                if (k1[i] > 100 && k1[i] < 200) {
                    if (broi[0] > 5) {
                        step = 189 / broi[0];
                    } else {
                        step = 41;
                    }
                    this.CardsPoint[i].x = 510;
                    this.CardsPoint[i].y = 15 + step * cvet[0];
                    if (cvet[0] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i - 1].y + step
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    if (broi[1] > 5) {
                        step = 189 / broi[1];
                    } else {
                        step = 41;
                    }
                    this.CardsPoint[i].x = 420;
                    this.CardsPoint[i].y = 15 + step * cvet[1];
                    if (cvet[1] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i - 1].y + step
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    if (broi[2] > 5) {
                        step = 189 / broi[2];
                    } else {
                        step = 41;
                    }
                    this.CardsPoint[i].x = 300;
                    this.CardsPoint[i].y = 15 + step * cvet[2];
                    if (cvet[2] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i - 1].y + step
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    if (broi[3] > 5) {
                        step = 189 / broi[3];
                    } else {
                        step = 41;
                    }
                    this.CardsPoint[i].x = 210;
                    this.CardsPoint[i].y = 15 + step * cvet[3];
                    if (cvet[3] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i - 1].y + step
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[3]++;
                }
            }
            if ((this.hand === 0 && this.KoiENaRed === this.Mor) || this.RefreshMor) {
                this.setState({});
            }
        }
        if (this.Mor === 1) {
            broi[0] = 0;
            broi[1] = 0;
            broi[2] = 0;
            broi[3] = 0;
            for (let i = 0; i < 14; i++) {
                if (k1[i] > 100 && k1[i] < 200) {
                    broi[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    broi[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    broi[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    broi[3]++;
                }
            }
            for (let i = 13; i > 0; i--) {
                if (k1[i] > 100 && k1[i] < 200) {
                    if (broi[0] > 4) {
                        step = 90 / (broi[0] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 210;
                    this.CardsPoint[i].y = 417 - step * cvet[0];
                    if (cvet[0] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + step
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    if (broi[1] > 4) {
                        step = 90 / (broi[1] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 300;
                    this.CardsPoint[i].y = 417 - step * cvet[1];
                    if (cvet[1] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + step
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    if (broi[2] > 4) {
                        step = 90 / (broi[2] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 420;
                    this.CardsPoint[i].y = 417 - step * cvet[2];
                    if (cvet[2] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + step
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    if (broi[3] > 4) {
                        step = 90 / (broi[3] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 510;
                    this.CardsPoint[i].y = 417 - step * cvet[3];
                    if (cvet[3] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + step
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[3]++;
                }
            }
            if ((this.hand === 0 && this.KoiENaRed === this.Mor) || this.RefreshMor) {
                this.setState({});
            }
        }
        if (this.Mor === 2) {
            broi[0] = 0;
            broi[1] = 0;
            broi[2] = 0;
            broi[3] = 0;
            for (let i = 14; i < 27; i++) {
                if (k1[i] > 100 && k1[i] < 200) {
                    broi[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    broi[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    broi[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    broi[3]++;
                }
            }
            for (let i = 26; i > 13; i--) {
                if (k1[i] > 100 && k1[i] < 200) {
                    if (broi[0] > 4) {
                        step = 90 / (broi[0] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 15 + step * cvet[0];
                    this.CardsPoint[i].y = 82;
                    if (cvet[0] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + step,
                            this.CardsPoint[i - 1].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    if (broi[1] > 4) {
                        step = 90 / (broi[1] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 15 + step * cvet[1];
                    this.CardsPoint[i].y = 162;
                    if (cvet[1] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + step,
                            this.CardsPoint[i - 1].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    if (broi[2] > 4) {
                        step = 90 / (broi[2] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 15 + step * cvet[2];
                    this.CardsPoint[i].y = 242;
                    if (cvet[2] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + step,
                            this.CardsPoint[i - 1].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    if (broi[3] > 4) {
                        step = 90 / (broi[3] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 15 + step * cvet[3];
                    this.CardsPoint[i].y = 322;
                    if (cvet[3] > 0) {
                        this.CardRect[i - 1] = new CRect(
                            this.CardsPoint[i - 1].x,
                            this.CardsPoint[i - 1].y,
                            this.CardsPoint[i - 1].x + step,
                            this.CardsPoint[i - 1].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    this.CardRect[i] = new CRect(
                        this.CardsPoint[i].x,
                        this.CardsPoint[i].y,
                        this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                        this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                    );
                    cvet[3]++;
                }
            }
            if ((this.hand === 0 && this.KoiENaRed === this.Mor) || this.RefreshMor) {
                this.setState({});
            }
        }
        if (this.Mor === 4) {
            broi[0] = 0;
            broi[1] = 0;
            broi[2] = 0;
            broi[3] = 0;
            for (let i = 40; i < 53; i++) {
                if (k1[i] > 100 && k1[i] < 200) {
                    broi[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    broi[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    broi[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    broi[3]++;
                }
            }
            for (let i = 52; i > 39; i--) {
                if (k1[i] > 100 && k1[i] < 200) {
                    if (broi[0] > 4) {
                        step = 90 / (broi[0] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 700 - step * cvet[0];
                    this.CardsPoint[i].y = 82;
                    if (cvet[0] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + step,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[0]++;
                }
                if (k1[i] > 200 && k1[i] < 300) {
                    if (broi[1] > 4) {
                        step = 90 / (broi[1] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 700 - step * cvet[1];
                    this.CardsPoint[i].y = 162;
                    if (cvet[1] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + step,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[1]++;
                }
                if (k1[i] > 300 && k1[i] < 400) {
                    if (broi[2] > 4) {
                        step = 90 / (broi[2] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 700 - step * cvet[2];
                    this.CardsPoint[i].y = 242;
                    if (cvet[2] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + step,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[2]++;
                }
                if (k1[i] > 400 && k1[i] < 500) {
                    if (broi[3] > 4) {
                        step = 90 / (broi[3] - 1) + 1;
                    } else {
                        step = 25;
                    }
                    this.CardsPoint[i].x = 700 - step * cvet[3];
                    this.CardsPoint[i].y = 322;
                    if (cvet[3] > 0) {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + step,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    } else {
                        this.CardRect[i] = new CRect(
                            this.CardsPoint[i].x,
                            this.CardsPoint[i].y,
                            this.CardsPoint[i].x + GlobalConstants.CARD_WIDTH,
                            this.CardsPoint[i].y + GlobalConstants.CARD_HEIGHT
                        );
                    }
                    cvet[3]++;
                }
            }
            if ((this.hand === 0 && this.KoiENaRed === this.Mor) || this.RefreshMor) {
                this.setState({});
            }
        }
        this.RefreshMor = false;
    }

    DrawNames() {
        let res = [];
        let OName: string[] = [];

        for (let i = 1; i <= 4; i++) {
            OName[i] = this.Name[i];
        }
        if (this.zone === 1) {
            OName[1] = OName[1] + '*';
            OName[3] = OName[3] + '*';
        }
        if (this.zone === 2) {
            OName[2] = OName[2] + '*';
            OName[4] = OName[4] + '*';
        }
        if (this.zone === 3) {
            OName[1] = OName[1] + '*';
            OName[3] = OName[3] + '*';
            OName[2] = OName[2] + '*';
            OName[4] = OName[4] + '*';
        }
        res.push(<DrawNames name={OName} position={this.NamePos} cursor={this.cursor} key={'names'} />);
        return res;
    }

    DrawCards() {
        if (this.KoiENaRed < 0) {
            return null;
        }
        var res = [];
        if (this.Faza === 9 && this.watch) {
            return null;
        }
        for (let i = 1; i < 53; i++) {
            if (this.k[i] > 0 && !(i > (this.Mor - 1) * 13 && i <= this.Mor * 13 && this.Faza > 4)) {
                if (
                    this.CardsPoint[i] &&
                    this.CardsPoint[i].x !== 0 &&
                    this.CardsPoint[i].x !== this.CardsPos[0].x &&
                    this.CardsPoint[i].y !== this.CardsPos[0].y
                ) {
                    if (i > 13) {
                        res.push(
                            <DrawCard
                                image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                m_x={this.CardsPoint[i].x}
                                m_y={this.CardsPoint[i].y}
                                key={'card' + i}
                                cursor={this.cursor}
                            />
                        );
                    } else {
                        res.push(
                            <DrawCard
                                image={this.CardP[i].image}
                                m_x={this.CardsPoint[i].x - ((13 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13}
                                m_y={this.CardsPoint[i].y}
                                key={'card' + i}
                                cursor={this.cursor}
                                big={true}
                            />
                        );
                    }
                }
            }
        }

        ////////////////////////////////
        if (this.Faza > 4) {
            if (this.Mor > 1 && this.Mor < 5) {
                for (let i = this.Mor * 13; i > (this.Mor - 1) * 13; i--) {
                    if (this.k[i] > 0) {
                        if (
                            this.CardsPoint[i] &&
                            this.CardsPoint[i].x !== 0 &&
                            (this.CardsPoint[i].x !== this.CardsPos[0].x || this.CardsPoint[i].y !== this.CardsPos[0].y)
                        ) {
                            if (this.Faza > 4 && i > (this.Mor - 1) * 13 && i <= this.Mor * 13) {
                                res.push(
                                    <DrawCard
                                        image={this.CardP[i].image}
                                        m_x={this.CardsPoint[i].x}
                                        m_y={this.CardsPoint[i].y}
                                        key={'card' + i}
                                        cursor={this.cursor}
                                    />
                                );
                            } else {
                                if (i > 13) {
                                    res.push(
                                        <DrawCard
                                            image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                            m_x={this.CardsPoint[i].x}
                                            m_y={this.CardsPoint[i].y}
                                            key={'card' + i}
                                            cursor={this.cursor}
                                        />
                                    );
                                } else {
                                    res.push(
                                        <DrawCard
                                            image={this.CardP[i].image}
                                            m_x={this.CardsPoint[i].x}
                                            m_y={this.CardsPoint[i].y}
                                            key={'card' + i}
                                            cursor={this.cursor}
                                        />
                                    );
                                }
                            }
                        }
                    }
                }
            }
            if (this.Mor === 1) {
                for (let i = 1; i <= 13; i++) {
                    if (this.k[i] > 0) {
                        if (
                            this.CardsPoint[i] &&
                            this.CardsPoint[i].x !== 0 &&
                            this.CardsPoint[i].x !== this.CardsPos[0].x &&
                            this.CardsPoint[i].y !== this.CardsPos[0].y
                        ) {
                            if (this.Faza > 4 && i > (this.Mor - 1) * 13 && i <= this.Mor * 13) {
                                res.push(
                                    <DrawCard
                                        image={this.CardP[i].image}
                                        m_x={this.CardsPoint[i].x}
                                        m_y={this.CardsPoint[i].y}
                                        key={'card' + i}
                                        cursor={this.cursor}
                                    />
                                );
                            } else {
                                if (i > 13) {
                                    res.push(
                                        <DrawCard
                                            image={this.CardP[i * GlobalVariables.OtkritiKarti].image}
                                            m_x={this.CardsPoint[i].x}
                                            m_y={this.CardsPoint[i].y}
                                            key={'card' + i}
                                            cursor={this.cursor}
                                        />
                                    );
                                } else {
                                    res.push(
                                        <DrawCard
                                            image={this.CardP[i].image}
                                            m_x={this.CardsPoint[i].x}
                                            m_y={this.CardsPoint[i].y}
                                            key={'card' + i}
                                            cursor={this.cursor}
                                        />
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
        /////////////////////////////////
        if (this.Razdavane1 === true) {
            for(let i=0;i<this.showrcard;i++) {
                res.push(
                    <DrawCard
                        image={this.CardP[0].image}
                        m_x={this.CardsPoint[0].x - (i+1) * 0.2}
                        m_y={this.CardsPoint[0].y - (i+1) * 0.4}
                        cursor={this.cursor}
                        key={'card_'+(i+1).toString()}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 1) {
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card53'}
                        middle={true}
                    />
                );
            }
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card54'}
                        middle={true}
                    />
                );
            }
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[55].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 3}
                        cursor={this.cursor}
                        key={'card55'}
                        middle={true}
                    />
                );
            }
            if (this.P4puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[56].image}
                        m_x={this.CardsPoint[56].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[56].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card56'}
                        middle={true}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 2) {
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card54'}
                        middle={true}
                    />
                );
            }
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[55].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 3}
                        cursor={this.cursor}
                        key={'card55'}
                        middle={true}
                    />
                );
            }
            if (this.P4puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[56].image}
                        m_x={this.CardsPoint[56].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[56].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card56'}
                        middle={true}
                    />
                );
            }
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card53'}
                        middle={true}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 3) {
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[55].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 3}
                        cursor={this.cursor}
                        key={'card55'}
                        middle={true}
                    />
                );
            }
            if (this.P4puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[56].image}
                        m_x={this.CardsPoint[56].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[56].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card56'}
                        middle={true}
                    />
                );
            }
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card53'}
                        middle={true}
                    />
                );
            }
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card54'}
                        middle={true}
                    />
                );
            }
        }
        if (this.KoiPuskaParvi === 4) {
            if (this.P4puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[56].image}
                        m_x={this.CardsPoint[56].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[56].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card56'}
                        middle={true}
                    />
                );
            }
            if (this.P1puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[53].image}
                        m_x={this.CardsPoint[53].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[53].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card53'}
                        middle={true}
                    />
                );
            }
            if (this.P2puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[54].image}
                        m_x={this.CardsPoint[54].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[54].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 4}
                        cursor={this.cursor}
                        key={'card54'}
                        middle={true}
                    />
                );
            }
            if (this.P3puska > 0) {
                res.push(
                    <DrawCard
                        image={this.CardP[55].image}
                        m_x={this.CardsPoint[55].x - (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 4}
                        m_y={this.CardsPoint[55].y - (GlobalConstants.PLAYER_CARD_HEIGHT - GlobalConstants.CARD_HEIGHT) / 3}
                        cursor={this.cursor}
                        key={'card55'}
                        middle={true}
                    />
                );
            }
        }
        for (let i = 1; i <= this.hand; i++) {
            if (this.hand1[i - 1] === 1) {
                res.push(
                    <DrawCard
                        image={this.CardP[0].image}
                        m_x={this.Vzeti[this.hand1[i - 1]][i].x}
                        m_y={this.Vzeti[this.hand1[i - 1]][i].y}
                        cursor={this.cursor}
                        key={'cardr' + i}
                    />
                );
            }
            if (this.hand1[i - 1] === 2) {
                res.push(
                    <DrawCard
                        image={this.rotate_card}
                        m_x={this.Vzeti[this.hand1[i - 1]][i].x}
                        m_y={this.Vzeti[this.hand1[i - 1]][i].y}
                        cursor={this.cursor}
                        rotate={true}
                        key={'cardr' + i}
                    />
                );
            }
        }
        return res;
    }
    DrawPause() {
        if (this.OP && !this.watch) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (
                    this.OP &&
                    this.Faza >= 4 &&
                    !this.Move &&
                    ((this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) || (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0))
                ) {
                    if (this.pause === 0) {
                        return (
                            <CPicture
                                image={this.p_bmp}
                                m_x={this.pause_rect.left}
                                m_y={this.pause_rect.top}
                                m_Width={20}
                                m_Height={20}
                                cursor={this.cursor}
                                key={'pause'}
                            />
                        );
                    }
                }
            }
        }
    }
    DrawTimer() {
        let res = [];
        if (this.OP && !this.watch) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (
                    this.OP &&
                    this.Faza >= 4 &&
                    !this.Move &&
                    ((this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) || (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0))
                ) {
                    res.push(this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer'));
                    this.show_timer = true;
                }
                if (this.OP && this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                    res.push(this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer'));
                    this.show_timer = true;
                }
            }
            if (this.OP && this.Faza >= 4 && !((this.KoiENaRed === 1 && this.Mor !== 1) || (this.KoiENaRed === 3 && this.Mor === 3)) && !this.Move) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    res.push(this.DrawBorderText('Pause' + ': ' + this.pause_player, 632, this.pause_rect.top, 'DrawTimerPause'));
                    this.show_timer = true;
                }
            }
        }
        return res;
    }

    SendKoiObiaviava() {
        let zonestr = this.IntToString(this.zone);
        let fzonestr = this.IntToString(this.imp_result.fzone);
        if (fzonestr !== '0' && fzonestr !== '1' && fzonestr !== '2' && fzonestr !== '3') {
            fzonestr = '0';
        }
        if (this.P2OP) {
            let koi = '4';
            if (this.KoiObiaviava > 1) {
                koi = this.IntToString(this.KoiObiaviava - 1);
            }
            if (this.zone === 1) {
                zonestr = '2';
            }
            if (this.zone === 2) {
                zonestr = '1';
            }
            if (this.imp_result.fzone === 1) {
                fzonestr = '2';
            }
            if (this.imp_result.fzone === 2) {
                fzonestr = '1';
            }
            this.props.m_pwin.SendGame('$|b|' + koi + zonestr + fzonestr);
        }
        if (this.P3OP) {
            zonestr = this.IntToString(this.zone);
            fzonestr = this.IntToString(this.imp_result.fzone);
            if (fzonestr !== '0' && fzonestr !== '1' && fzonestr !== '2' && fzonestr !== '3') {
                fzonestr = '0';
            }
            let koi = this.IntToString(this.KoiObiaviava + 2);
            if (this.KoiENaRed > 2) {
                koi = this.IntToString(this.KoiObiaviava - 2);
            }
            this.props.m_pwin.SendGame('$|e|' + koi + zonestr + fzonestr);
        }
        if (this.P4OP) {
            zonestr = this.IntToString(this.zone);
            fzonestr = this.IntToString(this.imp_result.fzone);
            if (fzonestr !== '0' && fzonestr !== '1' && fzonestr !== '2' && fzonestr !== '3') {
                fzonestr = '0';
            }
            let koi = this.IntToString(this.KoiObiaviava + 1);
            if (this.KoiObiaviava === 4) {
                koi = this.IntToString(1);
            }
            if (this.zone === 1) {
                zonestr = '2';
            }
            if (this.zone === 2) {
                zonestr = '1';
            }
            if (this.imp_result.fzone === 1) {
                fzonestr = '2';
            }
            if (this.imp_result.fzone === 2) {
                fzonestr = '1';
            }
            this.props.m_pwin.SendGame('$|h|' + koi + zonestr + fzonestr);
        }
        if (this.HaveWatch) {
            zonestr = this.IntToString(this.zone);
            fzonestr = this.IntToString(this.imp_result.fzone);
            if (fzonestr !== '0' && fzonestr !== '1' && fzonestr !== '2' && fzonestr !== '3') {
                fzonestr = '0';
            }
            this.props.m_pwin.SendGame('$|*|a|' + this.IntToString(this.KoiObiaviava) + zonestr + fzonestr);
        }
    }

    async Timer2() {
        if (!this.Move) {
            if (this.SP === true) {
                if (this.Faza === 2 && this.Razdavane1 !== true && this.Move !== true) {
                    if (this.BrPas === 4) {
                        this.obiaviavaneRN = null;
                        if (this.type === 1) {
                            this.InitGame();
                            this.setState({});
                        }
                        if (this.type === 2) {
                            this.Faza = 7;
                        }
                        return;
                    }
                    if (this.BrPas === 3 && this.Naddavane > 0) {
                        this.obiaviavaneRN = null;
                        this.setState({});
                        this.Faza = 3;
                        return;
                    }
                    if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                        this.obiaviavaneRN = null;
                        this.setState({});
                        this.KoiObiaviava = 0;
                        ////////////////////////
                        if ((this.LastSays[0] === 2 || this.LastSays[0] === 4) && this.Kontra === 0 && this.Rekontra === 0) {
                            this.bidd.diskontra = true;
                        } else {
                            this.bidd.diskontra = false;
                        }
                        if ((this.WhoSaysKontra === 2 || this.WhoSaysKontra === 4) && this.Kontra > 0 && this.Rekontra === 0) {
                            this.bidd.disrekontra = true;
                        } else {
                            this.bidd.disrekontra = false;
                        }
                        ////////////////////////////
                        this.bidd.naddavane = this.Naddavane;
                        this.bidd.level = this.level;
                        ///////////////////////////////////////////////
                        await this.bidd.DoModal();
                        if (this.bidd.pas) {
                            this.BrPas = this.BrPas + 1;
                            this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                        } else {
                            this.Naddavane = this.bidd.naddavane;
                            this.level = this.bidd.level;
                            if (this.bidd.kontra) {
                                this.WhoSaysKontra = 1;
                                this.Kontra = 1;
                                this.BrPas = 0;
                                this.kojkazal = 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                            }
                            if (this.bidd.rekontra) {
                                this.Rekontra = 1;
                                this.Kontra = 0;
                                this.BrPas = 0;
                                this.kojkazal = 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                            }
                            if (!this.bidd.rekontra && !this.bidd.kontra) {
                                this.kojkazal = 1;
                                this.Kontra = 0;
                                this.Rekontra = 0;
                                this.BrPas = 0;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane * 10 + this.level));
                            }
                        }
                        this.KoiObiaviava = 2;
                        this.props.m_pwin.KillTimer(2);
                        this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                        ////////////////////////
                        if (!this.bidd.pas && !this.bidd.rekontra && !this.bidd.kontra) {
                            this.PlayerAnouns[0][this.br[0]][0] = this.level;
                            this.PlayerAnouns[0][this.br[0]][1] = this.Naddavane;
                            this.br[0]++;
                            if (!this.bidd.kontra && !this.bidd.rekontra) {
                                this.LastSays[0] = 1;
                                this.LastSays[1] = this.level;
                                this.LastSays[2] = this.Naddavane;
                            }
                        }
                        if (this.bidd.pas) {
                            this.Anon[this.AnonBr] = 1000;
                        } else {
                            this.Anon[this.AnonBr] = 1000;
                            this.Anon[this.AnonBr] += this.Naddavane;
                            this.Anon[this.AnonBr] += this.level * 10;
                            if (this.Kontra > 0) {
                                this.Anon[this.AnonBr] = 1100;
                            }
                            if (this.Rekontra > 0) {
                                this.Anon[this.AnonBr] = 1200;
                            }
                        }
                        this.AnonBr++;
                        /////////////////
                        this.WhatMyPartnerKnowAboutMe(1);
                        this.WhoPlay = this.FindWP();
                        this.setState({});
                        return;
                    }
                    //////////////////////////////////////tova e za triene(pomo6tno)///////////////////////////////////////////////////////
                    ////////////////////////////////////////do tuk/////////////////////////////////////////////////////
                    if (this.KoiObiaviava > 1) {
                        this.IntelectAnons(this.KoiObiaviava);
                        if (this.KoiObiaviava !== 1) {
                            this.WhatMyPartnerKnowAboutMe(this.KoiObiaviava - 1);
                        } else {
                            this.WhatMyPartnerKnowAboutMe(4);
                        }
                        this.props.m_pwin.KillTimer(2);
                        this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                        return;
                    }
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Move !== true) {
                        if (this.BrPas === 4) {
                            if (this.type === 1) {
                                if (this.P2G && this.P3G && this.P4G) {
                                    this.obiaviavaneRN = null;
                                    this.InitGame();
                                    this.setState({});
                                    if (this.P2OP === true) {
                                        this.P2G = false;
                                    }
                                    if (this.P3OP === true) {
                                        this.P3G = false;
                                    }
                                    if (this.P4OP === true) {
                                        this.P4G = false;
                                    }
                                    this.InitGame();
                                    this.SendKoiObiaviava();
                                    this.SendCards();
                                    this.Razdavane1 = true;
                                    this.online_timer = this.online_timer_time * 1.5;
                                }
                            }
                            if (this.type === 2) {
                                if (this.P2OP === true) {
                                    this.P2G = false;
                                }
                                if (this.P3OP === true) {
                                    this.P3G = false;
                                }
                                if (this.P4OP === true) {
                                    this.P4G = false;
                                }
                                this.Faza = 7;
                            }
                            return;
                        }
                        if (this.BrPas === 3 && this.Naddavane > 0) {
                            this.obiaviavaneRN = null;
                            this.setState({});
                            this.online_timer = this.online_timer_time;
                            this.Faza = 3;
                            return;
                        }
                        if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                            this.obiaviavaneRN = null;
                            this.setState({});
                            this.KoiObiaviava = 0;
                            ////////////////////////
                            if ((this.LastSays[0] === 2 || this.LastSays[0] === 4) && this.Kontra === 0 && this.Rekontra === 0) {
                                this.bidd.diskontra = true;
                            } else {
                                this.bidd.diskontra = false;
                            }
                            if ((this.WhoSaysKontra === 2 || this.WhoSaysKontra === 4) && this.Kontra > 0 && this.Rekontra === 0) {
                                this.bidd.disrekontra = true;
                            } else {
                                this.bidd.disrekontra = false;
                            }
                            ////////////////////////////
                            this.bidd.naddavane = this.Naddavane;
                            this.bidd.level = this.level;
                            ///////////////////////////////////////////////
                            await this.bidd.DoModal();
                            if (this.bidd.pas) {
                                this.BrPas = this.BrPas + 1;
                                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                            } else {
                                if (this.OP) {
                                    if (
                                        (this.type === 2 && this.BroiIgri === 1) ||
                                        (this.type === 1 && (this.rubber_result.BroiUnderResult === 0 || this.rubber_result.BroiUpResult === 0))
                                    ) {
                                        if (!this.op_start) {
                                            this.props.m_pwin.SendGame('L');
                                            this.op_start = true;
                                        }
                                    }
                                }
                                this.Naddavane = this.bidd.naddavane;
                                this.level = this.bidd.level;
                                if (this.bidd.kontra) {
                                    this.WhoSaysKontra = 1;
                                    this.Kontra = 1;
                                    this.BrPas = 0;
                                    this.kojkazal = 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                                }
                                if (this.bidd.rekontra) {
                                    this.Rekontra = 1;
                                    this.Kontra = 0;
                                    this.BrPas = 0;
                                    this.kojkazal = 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                                }
                                if (!this.bidd.rekontra && !this.bidd.kontra) {
                                    this.kojkazal = 1;
                                    this.Kontra = 0;
                                    this.Rekontra = 0;
                                    this.BrPas = 0;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(this.Naddavane * 10 + this.level));
                                }
                            }
                            this.KoiObiaviava = 2;
                            this.props.m_pwin.KillTimer(2);
                            this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                            ////////////////////////
                            if (!this.bidd.pas && !this.bidd.rekontra && !this.bidd.kontra) {
                                this.PlayerAnouns[0][this.br[0]][0] = this.level;
                                this.PlayerAnouns[0][this.br[0]][1] = this.Naddavane;
                                this.br[0]++;
                                if (!this.bidd.kontra && !this.bidd.rekontra) {
                                    this.LastSays[0] = 1;
                                    this.LastSays[1] = this.level;
                                    this.LastSays[2] = this.Naddavane;
                                }
                            }
                            if (this.bidd.pas) {
                                this.Anon[this.AnonBr] = 1000;
                            } else {
                                this.Anon[this.AnonBr] = 1000;
                                this.Anon[this.AnonBr] += this.Naddavane;
                                this.Anon[this.AnonBr] += this.level * 10;
                                if (this.Kontra > 0) {
                                    this.Anon[this.AnonBr] = 1100;
                                }
                                if (this.Rekontra > 0) {
                                    this.Anon[this.AnonBr] = 1200;
                                }
                            }
                            this.props.m_pwin.SendGame('$|m|' + this.IntToString(this.Anon[this.AnonBr]));
                            this.AnonBr++;
                            /////////////////
                            this.WhatMyPartnerKnowAboutMe(1);
                            this.WhoPlay = this.FindWP();
                            this.setState({});
                            return;
                        }
                        ////////////////////////////////////////do tuk/////////////////////////////////////////////////////
                        if (this.KoiObiaviava > 1) {
                            if (
                                ((this.KoiObiaviava === 2 && this.P2OP === true) ||
                                    (this.KoiObiaviava === 3 && this.P3OP === true) ||
                                    (this.KoiObiaviava === 4 && this.P4OP === true)) &&
                                this.CheckAMessage()
                            ) {
                                let Nadd = this._ttoi(this.ReadAMessage());
                                let kontra: number;
                                let level: number;
                                let boia: number;
                                kontra = Math.floor((Nadd % 1000) / 100);
                                level = Math.floor((Nadd % 100) / 10);
                                boia = Nadd % 10;
                                let oldKoj = this.KoiObiaviava;
                                this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                                this.WhatMyPartnerKnowAboutMe(oldKoj);
                                return;
                            }
                            if (this.KoiObiaviava === 2 && this.P2OP !== true) {
                                if (this.CheckAMessage()) {
                                    let Nadd = this._ttoi(this.ReadAMessage());
                                    let kontra: number;
                                    let level: number;
                                    let boia: number;
                                    kontra = Math.floor((Nadd % 1000) / 100);
                                    level = Math.floor((Nadd % 100) / 10);
                                    boia = Nadd % 10;
                                    this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2');
                                } else {
                                    this.IntelectAnons(this.KoiObiaviava);
                                    this.WhatMyPartnerKnowAboutMe(this.KoiObiaviava - 1);
                                }
                                return;
                            }
                            if (this.KoiObiaviava === 3 && this.P3OP !== true) {
                                this.setState({});
                                if (this.CheckAMessage()) {
                                    let Nadd = this._ttoi(this.ReadAMessage());
                                    let kontra: number;
                                    let level: number;
                                    let boia: number;
                                    kontra = Math.floor((Nadd % 1000) / 100);
                                    level = Math.floor((Nadd % 100) / 10);
                                    boia = Nadd % 10;
                                    this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2');
                                } else {
                                    this.IntelectAnons(this.KoiObiaviava);
                                    this.WhatMyPartnerKnowAboutMe(this.KoiObiaviava - 1);
                                }
                                return;
                            }

                            if (this.KoiObiaviava === 4 && this.P4OP !== true) {
                                this.setState({});
                                if (this.CheckAMessage()) {
                                    let Nadd = this._ttoi(this.ReadAMessage());
                                    let kontra: number;
                                    let level: number;
                                    let boia: number;
                                    kontra = Math.floor((Nadd % 1000) / 100);
                                    level = Math.floor((Nadd % 100) / 10);
                                    boia = Nadd % 10;
                                    this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                                    this.props.m_pwin.SendErrorToLog('Obiaviavane ot izliazal Client PL2');
                                } else {
                                    this.IntelectAnons(this.KoiObiaviava);
                                    this.WhatMyPartnerKnowAboutMe(4);
                                }
                                return;
                            }
                        }
                    }
                } else {
                    if (this.Faza === 2 && this.Razdavane1 !== true && this.Move !== true) {
                        if (this.BrPas === 4) {
                            if (this.type === 1) {
                                if (!this.wait_new_game) {
                                    this.obiaviavaneRN = null;
                                    this.setState({});
                                    if (!this.watch) {
                                        this.props.m_pwin.SendGame('R');
                                    }
                                }
                                this.wait_new_game = true;
                            }
                            if (this.type === 2) {
                                this.Faza = 7;
                            }
                            return;
                        }
                        if (this.BrPas === 3 && this.Naddavane > 0) {
                            this.obiaviavaneRN = null;
                            this.setState({});
                            this.online_timer = this.online_timer_time;
                            this.Faza = 3;
                            return;
                        }
                        if (
                            this.watch &&
                            this.KoiObiaviava === 1 &&
                            this.Faza === 2 &&
                            this.BrPas !== 4 &&
                            (this.BrPas !== 3 || this.Naddavane === 0) &&
                            this.CheckAMessage()
                        ) {
                            this.setState({});
                            let Nadd = this._ttoi(this.ReadAMessage());
                            let kontra: number;
                            let level: number;
                            let boia: number;
                            kontra = Math.floor((Nadd % 1000) / 100);
                            level = Math.floor((Nadd % 100) / 10);
                            boia = Nadd % 10;
                            this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                            this.WhatMyPartnerKnowAboutMe(this.KoiObiaviava - 1);
                        }
                        if (!this.watch) {
                            if (this.KoiObiaviava === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                                this.setState({});
                                this.KoiObiaviava = 0;
                                ////////////////////////
                                if ((this.LastSays[0] === 2 || this.LastSays[0] === 4) && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.bidd.diskontra = true;
                                } else {
                                    this.bidd.diskontra = false;
                                }
                                if ((this.WhoSaysKontra === 2 || this.WhoSaysKontra === 4) && this.Kontra > 0 && this.Rekontra === 0) {
                                    this.bidd.disrekontra = true;
                                } else {
                                    this.bidd.disrekontra = false;
                                }
                                ////////////////////////////
                                this.bidd.naddavane = this.Naddavane;
                                this.bidd.level = this.level;
                                ///////////////////////////////////////////////
                                await this.bidd.DoModal();
                                if (this.bidd.pas) {
                                    this.BrPas = this.BrPas + 1;
                                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(0));
                                } else {
                                    if (this.OP && !this.watch) {
                                        if (
                                            (this.type === 2 && this.BroiIgri === 1) ||
                                            (this.type === 1 && (this.rubber_result.BroiUnderResult === 0 || this.rubber_result.BroiUpResult === 0))
                                        ) {
                                            if (!this.op_start) {
                                                this.props.m_pwin.SendGame('L');
                                                this.op_start = true;
                                            }
                                        }
                                    }
                                    this.Naddavane = this.bidd.naddavane;
                                    this.level = this.bidd.level;
                                    if (this.bidd.kontra) {
                                        this.WhoSaysKontra = 1;
                                        this.Kontra = 1;
                                        this.BrPas = 0;
                                        this.kojkazal = 1;
                                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(7));
                                    }
                                    if (this.bidd.rekontra) {
                                        this.Rekontra = 1;
                                        this.Kontra = 0;
                                        this.BrPas = 0;
                                        this.kojkazal = 1;
                                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[1], this.GameName(8));
                                    }
                                    if (!this.bidd.rekontra && !this.bidd.kontra) {
                                        this.kojkazal = 1;
                                        this.Kontra = 0;
                                        this.Rekontra = 0;
                                        this.BrPas = 0;
                                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(
                                            this.Name[1],
                                            this.GameName(this.Naddavane * 10 + this.level)
                                        );
                                    }
                                }
                                this.KoiObiaviava = 2;
                                this.props.m_pwin.KillTimer(2);
                                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                                ////////////////////////
                                if (!this.bidd.pas && !this.bidd.rekontra && !this.bidd.kontra) {
                                    this.PlayerAnouns[0][this.br[0]][0] = this.level;
                                    this.PlayerAnouns[0][this.br[0]][1] = this.Naddavane;
                                    this.br[0]++;
                                    if (!this.bidd.kontra && !this.bidd.rekontra) {
                                        this.LastSays[0] = 1;
                                        this.LastSays[1] = this.level;
                                        this.LastSays[2] = this.Naddavane;
                                    }
                                }
                                if (this.bidd.pas) {
                                    this.Anon[this.AnonBr] = 1000;
                                } else {
                                    this.Anon[this.AnonBr] = 1000;
                                    this.Anon[this.AnonBr] += this.Naddavane;
                                    this.Anon[this.AnonBr] += this.level * 10;
                                    if (this.Kontra > 0) {
                                        this.Anon[this.AnonBr] = 1100;
                                    }
                                    if (this.Rekontra > 0) {
                                        this.Anon[this.AnonBr] = 1200;
                                    }
                                }
                                this.props.m_pwin.SendGame('$|m|' + this.IntToString(this.Anon[this.AnonBr]));
                                this.AnonBr++;
                                /////////////////
                                this.WhatMyPartnerKnowAboutMe(1);
                                this.WhoPlay = this.FindWP();
                                this.setState({});
                                return;
                            }
                        }
                        ////////////////////////////////////////do tuk/////////////////////////////////////////////////////
                        if (this.KoiObiaviava > 1 && this.CheckAMessage()) {
                            this.setState({});
                            let Nadd = this._ttoi(this.ReadAMessage());
                            let kontra: number;
                            let level: number;
                            let boia: number;
                            kontra = Math.floor((Nadd % 1000) / 100);
                            level = Math.floor((Nadd % 100) / 10);
                            boia = Nadd % 10;
                            let oldKoj = this.KoiObiaviava;
                            this.ObiaviavaneC(level, boia, kontra, this.KoiObiaviava);
                            this.WhatMyPartnerKnowAboutMe(oldKoj);
                            return;
                        }
                    }
                }
            }
        }
    }

    ObiaviavaneC(level1: number, Naddavane1: number, Kontra1: number, player: number) {
        //////////////////////////proverka za pravilnost
        let FA = level1 * 10 + Naddavane1 + Kontra1 * 100 + player * 1000;
        for (
            let k = 0;
            k < this.AnonBr;
            k++ //na obiaviavanet0
        ) {
            if (FA % 100 > 0 && this.Anon[k] % 100 > FA % 100) {
                level1 = 0;
                Naddavane1 = 0;
                Kontra1 = 0;
                break;
            }
        }
        //na kontrata
        if (Kontra1 === 1) {
            let OK111: boolean = false;
            if (this.AnonBr >= 1) {
                if (this.Anon[this.AnonBr - 1] % 100 > 0) {
                    OK111 = true;
                }
            }
            if (this.AnonBr >= 3) {
                if (this.Anon[this.AnonBr - 3] % 100 > 0 && this.Anon[this.AnonBr - 2] % 1000 === 0 && this.Anon[this.AnonBr - 1] % 1000 === 0) {
                    OK111 = true;
                }
            }
            if (!OK111) {
                level1 = 0;
                Naddavane1 = 0;
                Kontra1 = 0;
            }
        }
        //na this.Rekontrata
        if (Kontra1 === 2) {
            let OK111: boolean = false;
            if (this.AnonBr >= 1) {
                if (Math.floor(this.Anon[this.AnonBr - 1] / 100) % 10 === 1) {
                    OK111 = true;
                }
            }
            if (this.AnonBr >= 3) {
                if (
                    Math.floor(this.Anon[this.AnonBr - 3] / 100) % 10 === 1 &&
                    this.Anon[this.AnonBr - 2] % 1000 === 0 &&
                    this.Anon[this.AnonBr - 1] % 1000 === 0
                ) {
                    OK111 = true;
                }
            }
            if (!OK111) {
                level1 = 0;
                Naddavane1 = 0;
                Kontra1 = 0;
            }
        }
        //////////////////////////
        if (Kontra1 > 0) {
            level1 = 0;
            Naddavane1 = 0;
        }
        if (player === 1 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
            if (this.watch) {
                this.setState({});
                ////////////////////////
                if ((this.LastSays[0] === 2 || this.LastSays[0] === 4) && this.Kontra === 0 && this.Rekontra === 0) {
                    this.bidd.diskontra = true;
                } else {
                    this.bidd.diskontra = false;
                }
                if ((this.WhoSaysKontra === 2 || this.WhoSaysKontra === 4) && this.Kontra > 0 && this.Rekontra === 0) {
                    this.bidd.disrekontra = true;
                } else {
                    this.bidd.disrekontra = false;
                }
                ////////////////////////////
                if (level1 > 0) {
                    this.bidd.naddavane = Naddavane1;
                    this.bidd.level = level1;
                }
                ///////////////////////////////////////////////
                if (level1 === 0 && Kontra1 === 0) {
                    this.BrPas = this.BrPas + 1;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0));
                } else {
                    this.Naddavane = this.bidd.naddavane;
                    this.level = this.bidd.level;
                    if (Kontra1 === 1) {
                        this.WhoSaysKontra = player;
                        this.Kontra = player;
                        this.BrPas = 0;
                        this.kojkazal = 1;
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7));
                    }
                    if (Kontra1 === 2) {
                        this.Rekontra = player;
                        this.Kontra = 0;
                        this.BrPas = 0;
                        this.kojkazal = 1;
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8));
                    }
                    if (Kontra1 === 0) {
                        this.kojkazal = 1;
                        this.Kontra = 0;
                        this.Rekontra = 0;
                        this.BrPas = 0;
                        this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(
                            this.Name[this.KoiObiaviava],
                            this.GameName(this.Naddavane * 10 + this.level)
                        );
                    }
                }

                this.props.m_pwin.KillTimer(2);
                this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
                ////////////////////////
                if (Kontra1 === 0 && level1 > 0) {
                    this.PlayerAnouns[0][this.br[0]][0] = this.level;
                    this.PlayerAnouns[0][this.br[0]][1] = this.Naddavane;
                    this.br[0]++;
                }
                if (level1 > 0) {
                    this.LastSays[0] = 1;
                    this.LastSays[1] = level1;
                    this.LastSays[2] = Naddavane1;
                }
                this.WhoPlay = this.FindWP();
                if (level1 === 0 && Kontra1 === 0) {
                    this.Anon[this.AnonBr] = 1000;
                } else {
                    this.Anon[this.AnonBr] = 1000;
                    this.Anon[this.AnonBr] += Naddavane1;
                    this.Anon[this.AnonBr] += level1 * 10;
                    if (Kontra1 === 1) {
                        this.Anon[this.AnonBr] = 1100;
                    }
                    if (Kontra1 === 2) {
                        this.Anon[this.AnonBr] = 1200;
                    }
                }
                this.AnonBr++;
                if (Kontra1 === 0) {
                    this.obiaviavaneRN = this.DrawObiaviavane(player, Naddavane1, level1);
                } else {
                    this.obiaviavaneRN = this.DrawObiaviavane(player, 6 + Kontra1, 0);
                }
                /////////////////
                ///
                let bidd = level1 + Naddavane1 * 10;
                if (Kontra1 === 1) {
                    bidd = -11;
                }
                if (Kontra1 === 2) {
                    bidd = -10;
                }
                if (GlobalVariables.Zvuk === 1) {
                    if (bidd === 0) {
                        bidd = -18;
                    }
                    this.props.m_pwin.m_sndSound.Play(20 + bidd, 'center', this.stereo, this.pls[player]);
                }
                this.KoiObiaviava = 2;
            } else {
            }
            ///
        }
        if (player === 2 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
            this.setState({});
            ////////////////////////
            if ((this.LastSays[0] === 1 || this.LastSays[0] === 3) && this.Kontra === 0 && this.Rekontra === 0) {
                this.bidd.diskontra = true;
            } else {
                this.bidd.diskontra = false;
            }
            if ((this.WhoSaysKontra === 1 || this.WhoSaysKontra === 3) && this.Kontra > 0 && this.Rekontra === 0) {
                this.bidd.disrekontra = true;
            } else {
                this.bidd.disrekontra = false;
            }
            ////////////////////////////
            if (level1 > 0) {
                this.bidd.naddavane = Naddavane1;
                this.bidd.level = level1;
            }
            ///////////////////////////////////////////////
            if (level1 === 0 && Kontra1 === 0) {
                this.BrPas = this.BrPas + 1;
                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0));
            } else {
                this.Naddavane = this.bidd.naddavane;
                this.level = this.bidd.level;
                if (Kontra1 === 1) {
                    this.WhoSaysKontra = player;
                    this.Kontra = player;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7));
                }
                if (Kontra1 === 2) {
                    this.Rekontra = player;
                    this.Kontra = 0;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8));
                }
                if (Kontra1 === 0) {
                    this.kojkazal = player;
                    this.Kontra = 0;
                    this.Rekontra = 0;
                    this.BrPas = 0;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(this.Naddavane * 10 + this.level));
                }
            }
            this.props.m_pwin.KillTimer(2);
            this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            ////////////////////////
            if (level1 > 0 && Kontra1 === 0) {
                this.PlayerAnouns[1][this.br[1]][0] = level1;
                this.PlayerAnouns[1][this.br[1]][1] = Naddavane1;
                this.br[1]++;
            }
            if (level1 > 0) {
                this.LastSays[0] = 2;
                this.LastSays[1] = level1;
                this.LastSays[2] = Naddavane1;
            }
            this.WhoPlay = this.FindWP();
            if (level1 === 0 && Kontra1 === 0) {
                this.Anon[this.AnonBr] = 2000;
            } else {
                this.Anon[this.AnonBr] = 2000;
                this.Anon[this.AnonBr] += Naddavane1;
                this.Anon[this.AnonBr] += level1 * 10;
                if (Kontra1 === 1) {
                    this.Anon[this.AnonBr] = 2100;
                }
                if (Kontra1 === 2) {
                    this.Anon[this.AnonBr] = 2200;
                }
            }
            if (this.OP) {
                if (this.Server && !this.P2OP) {
                    this.props.m_pwin.SendGame('$|m|' + this.IntToString(this.Anon[this.AnonBr]));
                }
            }
            this.AnonBr++;
            if (Kontra1 === 0) {
                this.obiaviavaneRN = this.DrawObiaviavane(player, Naddavane1, level1);
            } else {
                this.obiaviavaneRN = this.DrawObiaviavane(player, 6 + Kontra1, 0);
            }
            /////////////////
            ///
            let bidd = level1 + Naddavane1 * 10;
            if (Kontra1 === 1) {
                bidd = -11;
            }
            if (Kontra1 === 2) {
                bidd = -10;
            }
            if (GlobalVariables.Zvuk === 1) {
                if (bidd === 0) {
                    bidd = -18;
                }
                this.props.m_pwin.m_sndSound.Play(20 + bidd, 'center', this.stereo, this.pls[player]);
            }
            this.KoiObiaviava = 3;
            ///
        }
        ///////////////////////////////////////////////////////////////////////////////////////////////
        if (player === 3 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
            this.setState({});
            ////////////////////////
            if ((this.LastSays[0] === 2 || this.LastSays[0] === 4) && this.Kontra === 0 && this.Rekontra === 0) {
                this.bidd.diskontra = true;
            } else {
                this.bidd.diskontra = false;
            }
            if ((this.WhoSaysKontra === 2 || this.WhoSaysKontra === 4) && this.Kontra > 0 && this.Rekontra === 0) {
                this.bidd.disrekontra = true;
            } else {
                this.bidd.disrekontra = false;
            }
            ////////////////////////////
            if (level1 > 0) {
                this.bidd.naddavane = Naddavane1;
                this.bidd.level = level1;
            }
            ///////////////////////////////////////////////
            if (level1 === 0 && Kontra1 === 0) {
                this.BrPas = this.BrPas + 1;
                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0));
            } else {
                this.Naddavane = this.bidd.naddavane;
                this.level = this.bidd.level;
                if (Kontra1 === 1) {
                    this.WhoSaysKontra = player;
                    this.Kontra = player;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7));
                }
                if (Kontra1 === 2) {
                    this.Rekontra = player;
                    this.Kontra = 0;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8));
                }
                if (Kontra1 === 0) {
                    this.kojkazal = player;
                    this.Kontra = 0;
                    this.Rekontra = 0;
                    this.BrPas = 0;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(this.Naddavane * 10 + this.level));
                }
            }
            this.props.m_pwin.KillTimer(2);
            this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            ////////////////////////
            if (Kontra1 === 0 && level1 > 0) {
                this.PlayerAnouns[2][this.br[2]][0] = this.level;
                this.PlayerAnouns[2][this.br[2]][1] = this.Naddavane;
                this.br[2]++;
            }
            if (level1 > 0) {
                this.LastSays[0] = 3;
                this.LastSays[1] = level1;
                this.LastSays[2] = Naddavane1;
            }
            this.WhoPlay = this.FindWP();
            if (level1 === 0 && Kontra1 === 0) {
                this.Anon[this.AnonBr] = 3000;
            } else {
                this.Anon[this.AnonBr] = 3000;
                this.Anon[this.AnonBr] += Naddavane1;
                this.Anon[this.AnonBr] += level1 * 10;
                if (Kontra1 === 1) {
                    this.Anon[this.AnonBr] = 3100;
                }
                if (Kontra1 === 2) {
                    this.Anon[this.AnonBr] = 3200;
                }
            }
            if (this.Server && !this.P3OP) {
                this.props.m_pwin.SendGame('$|m|' + this.IntToString(this.Anon[this.AnonBr]));
            }
            this.AnonBr++;
            if (Kontra1 === 0) {
                this.obiaviavaneRN = this.DrawObiaviavane(player, Naddavane1, level1);
            } else {
                this.obiaviavaneRN = this.DrawObiaviavane(player, 6 + Kontra1, 0);
            }
            /////////////////
            ///
            let bidd = level1 + Naddavane1 * 10;
            if (Kontra1 === 1) {
                bidd = -11;
            }
            if (Kontra1 === 2) {
                bidd = -10;
            }
            if (GlobalVariables.Zvuk === 1) {
                if (bidd === 0) {
                    bidd = -18;
                }
                this.props.m_pwin.m_sndSound.Play(20 + bidd, 'center', this.stereo, this.pls[player]);
            }
            this.KoiObiaviava = 4;
            ///
        }
        /////////////////////////////////////////////////////////////////
        if (player === 4 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
            this.setState({});
            ////////////////////////
            if ((this.LastSays[0] === 1 || this.LastSays[0] === 3) && this.Kontra === 0 && this.Rekontra === 0) {
                this.bidd.diskontra = true;
            } else {
                this.bidd.diskontra = false;
            }
            if ((this.WhoSaysKontra === 1 || this.WhoSaysKontra === 3) && this.Kontra > 0 && this.Rekontra === 0) {
                this.bidd.disrekontra = true;
            } else {
                this.bidd.disrekontra = false;
            }
            ////////////////////////////
            if (level1 > 0) {
                this.bidd.naddavane = Naddavane1;
                this.bidd.level = level1;
            }
            ///////////////////////////////////////////////
            if (level1 === 0 && Kontra1 === 0) {
                this.BrPas = this.BrPas + 1;
                this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(0));
            } else {
                this.Naddavane = this.bidd.naddavane;
                this.level = this.bidd.level;
                if (Kontra1 === 1) {
                    this.WhoSaysKontra = player;
                    this.Kontra = player;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(7));
                }
                if (Kontra1 === 2) {
                    this.Rekontra = player;
                    this.Kontra = 0;
                    this.BrPas = 0;
                    this.kojkazal = player;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(8));
                }
                if (Kontra1 === 0) {
                    this.kojkazal = player;
                    this.Kontra = 0;
                    this.Rekontra = 0;
                    this.BrPas = 0;
                    this.props.m_pwin.pFrame.m_info_bar.AddToInfoBar(this.Name[this.KoiObiaviava], this.GameName(this.Naddavane * 10 + this.level));
                }
            }
            this.props.m_pwin.KillTimer(2);
            this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
            ////////////////////////
            if (level1 > 0 && Kontra1 === 0) {
                this.PlayerAnouns[3][this.br[3]][0] = level1;
                this.PlayerAnouns[3][this.br[3]][1] = Naddavane1;
                this.br[3]++;
            }
            if (level1 > 0) {
                this.LastSays[0] = 4;
                this.LastSays[1] = level1;
                this.LastSays[2] = Naddavane1;
            }
            this.WhoPlay = this.FindWP();
            if (level1 === 0 && Kontra1 === 0) {
                this.Anon[this.AnonBr] = 4000;
            } else {
                this.Anon[this.AnonBr] = 4000;
                this.Anon[this.AnonBr] += Naddavane1;
                this.Anon[this.AnonBr] += level1 * 10;
                if (Kontra1 === 1) {
                    this.Anon[this.AnonBr] = 4100;
                }
                if (Kontra1 === 2) {
                    this.Anon[this.AnonBr] = 4200;
                }
            }
            if (this.Server && !this.P4OP) {
                this.props.m_pwin.SendGame('$|m|' + this.IntToString(this.Anon[this.AnonBr]));
            }
            this.AnonBr++;
            if (Kontra1 === 0) {
                this.obiaviavaneRN = this.DrawObiaviavane(player, Naddavane1, level1);
            } else {
                this.obiaviavaneRN = this.DrawObiaviavane(player, 6 + Kontra1, 0);
            }
            /////////////////
            ///
            let bidd = level1 + Naddavane1 * 10;
            if (Kontra1 === 1) {
                bidd = -11;
            }
            if (Kontra1 === 2) {
                bidd = -10;
            }
            if (GlobalVariables.Zvuk === 1) {
                if (bidd === 0) {
                    bidd = -18;
                }
                this.props.m_pwin.m_sndSound.Play(20 + bidd, 'center', this.stereo, this.pls[player]);
            }
            this.KoiObiaviava = 1;
            ///
        }
        this.online_timer = this.online_timer_time * 1.5;
        this.setState({});
    }

    DrawObiaviavane(player: number, anons: number, level: number) {
        let text: string;
        if (level > 0) {
            text = this.GameName(anons * 10 + level);
        } else {
            text = this.GameName(anons);
        }

        if (anons >= 0 && anons < 10) {
            return this.DrawObiaviavanePNG(this.PosNaddavane[player].x, this.PosNaddavane[player].y, text);
        }
    }

    IntelectAnons(player: number) {
        let PlayerCards: number[] = [];
        let Points: number = 0;
        for (let i = 0; i < 13; i++) {
            PlayerCards[i] = this.k[i + 13 * (player - 1) + 1];
        }
        for (let i = 0; i < 13; i++) {
            if (PlayerCards[i] % 100 > 10) {
                Points += (PlayerCards[i] % 100) - 10;
            }
        }
        let BroiSpatii = 0,
            BroiKari = 0,
            BroiKupi = 0,
            BroiPiki = 0;
        let BroiTo4kiSpatii = 0,
            BroiTo4kiKari = 0,
            BroiTo4kiKupi = 0,
            BroiTo4kiPiki = 0;
        let BroiAsa = 0;
        for (let i = 0; i < 13; i++) {
            if (PlayerCards[i] % 100 === 14) {
                BroiAsa++;
            }
        }
        for (let i = 0; i < 13; i++) {
            if (PlayerCards[i] > 100 && PlayerCards[i] < 200) {
                if (PlayerCards[i] % 100 > 10) {
                    BroiTo4kiSpatii += (PlayerCards[i] % 100) - 10;
                }
                BroiSpatii++;
            }
            if (PlayerCards[i] > 200 && PlayerCards[i] < 300) {
                if (PlayerCards[i] % 100 > 10) {
                    BroiTo4kiKari += (PlayerCards[i] % 100) - 10;
                }
                BroiKari++;
            }
            if (PlayerCards[i] > 300 && PlayerCards[i] < 400) {
                if (PlayerCards[i] % 100 > 10) {
                    BroiTo4kiKupi += (PlayerCards[i] % 100) - 10;
                }
                BroiKupi++;
            }
            if (PlayerCards[i] > 400 && PlayerCards[i] < 500) {
                if (PlayerCards[i] % 100 > 10) {
                    BroiTo4kiPiki += (PlayerCards[i] % 100) - 10;
                }
                BroiPiki++;
            }
        }
        ///////////////////////////////////////////otkrivane///////////////////////////////////////////
        let Otkrivane = true;
        for (let i = 0; i < this.AnonBr; i++) {
            if (player === 2) {
                if (this.Anon[i] > 4000 && this.Anon[i] < 5000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    Otkrivane = false;
                }
            }
            if (player === 3) {
                if (this.Anon[i] > 1000 && this.Anon[i] < 2000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 3000 && this.Anon[i] < 4000) {
                    Otkrivane = false;
                }
            }
            if (player === 4) {
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 4000 && this.Anon[i] < 5000) {
                    Otkrivane = false;
                }
            }
        }
        if (this.AnonBr >= 4) {
            Otkrivane = false;
        }
        let BestAnons = 0;
        for (let i = 0; i < this.AnonBr; i++) {
            if (this.Anon[i] % 100 > BestAnons) {
                BestAnons = this.Anon[i] % 100;
            }
        }
        if (Otkrivane) {
            //	if(Points<14)

            ////////Baraj Spatii
            if (Points < 15 && BroiSpatii === 6 && BroiTo4kiSpatii >= 3 && Points > 5) {
                if (BestAnons < 31) {
                    this.ObiaviavaneC(3, 1, 0, player);
                    return;
                }
                if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 31 && BestAnons < 41) {
                    this.ObiaviavaneC(4, 1, 0, player);
                    return;
                }
            }
            if (Points < 15 && BroiSpatii >= 7 && BroiSpatii < 9) {
                if (BestAnons < 31) {
                    this.ObiaviavaneC(3, 1, 0, player);
                    return;
                }
                if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 31 && BestAnons < 41) {
                    this.ObiaviavaneC(4, 1, 0, player);
                    return;
                }
            }
            if (BroiSpatii >= 9 && BroiSpatii < 11) {
                if (BestAnons < 41) {
                    this.ObiaviavaneC(4, 1, 0, player);
                    return;
                }
                if (BestAnons === 41 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            if (BroiSpatii >= 11) {
                if (BestAnons < 51) {
                    this.ObiaviavaneC(5, 1, 0, player);
                    return;
                }
                if (BestAnons === 51 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            /////////Baraj Kari
            if (Points < 15 && BroiKari === 6 && BroiTo4kiKari >= 3 && Points > 5) {
                if (BestAnons < 32) {
                    this.ObiaviavaneC(3, 2, 0, player);
                    return;
                }
                if (Points < 15 && BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 32 && BestAnons < 42) {
                    this.ObiaviavaneC(4, 2, 0, player);
                    return;
                }
            }
            if (BroiKari >= 7 && Points < 17 && BroiKari <= 8 && Points > 5) {
                if (BestAnons < 32) {
                    this.ObiaviavaneC(3, 2, 0, player);
                    return;
                }
                if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 32 && BestAnons < 42) {
                    this.ObiaviavaneC(4, 2, 0, player);
                    return;
                }
            }
            if (BroiKari >= 9 && BroiKari <= 10) {
                if (BestAnons < 42) {
                    this.ObiaviavaneC(4, 2, 0, player);
                    return;
                }
                if (BestAnons === 42 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            if (BroiKari >= 11) {
                if (BestAnons < 52) {
                    this.ObiaviavaneC(5, 2, 0, player);
                    return;
                }
                if (BestAnons === 52 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////////Baraj Kupi
            if (Points < 15 && BroiKupi === 6 && BroiTo4kiKupi >= 3 && Points > 5) {
                if (BestAnons < 33) {
                    this.ObiaviavaneC(3, 3, 0, player);
                    return;
                }
                if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 33 && BestAnons < 43) {
                    this.ObiaviavaneC(4, 3, 0, player);
                    return;
                }
            }
            if (Points < 15 && BroiKupi >= 7 && BroiKupi <= 8 && Points > 5) {
                if (BestAnons < 33) {
                    this.ObiaviavaneC(3, 3, 0, player);
                    return;
                }
                if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 33 && BestAnons < 43) {
                    this.ObiaviavaneC(4, 3, 0, player);
                    return;
                }
            }
            if (BroiKupi >= 9) {
                if (BestAnons < 43) {
                    this.ObiaviavaneC(4, 3, 0, player);
                    if (BestAnons > 33) {
                        return;
                    }
                }
                if (BestAnons === 43 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    this.PlayerI[player].MinBroiKupi = 9;
                    return;
                }
            }
            ///////////Baraj Piki
            if (Points < 15 && BroiPiki === 6 && BroiTo4kiPiki >= 3 && Points > 5) {
                if (BestAnons < 34) {
                    this.ObiaviavaneC(3, 4, 0, player);
                    return;
                }
                if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 34 && BestAnons < 44) {
                    this.ObiaviavaneC(4, 4, 0, player);
                    return;
                }
            }
            if (Points < 15 && BroiPiki >= 7 && BroiPiki <= 8 && Points > 5) {
                if (BestAnons < 34) {
                    this.ObiaviavaneC(3, 4, 0, player);
                    return;
                }
                if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 34 && BestAnons < 44) {
                    this.ObiaviavaneC(4, 4, 0, player);
                    return;
                }
            }
            if (BroiPiki >= 9) {
                if (BestAnons < 44) {
                    this.ObiaviavaneC(4, 4, 0, player);
                    return;
                }
                if (BestAnons === 44 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }

            ///////////otkrivane 1 boia
            if (Points === 11) {
                //uslovna spatia
                if (BestAnons < 11) {
                    this.ObiaviavaneC(1, 1, 0, player);
                    return;
                }
                if (BestAnons === 11 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            if (Points >= 12 && Points <= 14) {
                //obiaviavania na edno boia
                if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiSpatii > BroiKari) {
                    //1 spat
                    if (BestAnons < 11) {
                        this.ObiaviavaneC(1, 1, 0, player);
                        return;
                    }
                    if (BestAnons === 11 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 11 && BestAnons < 21 && (Points > 12 || BroiSpatii > 4)) {
                        this.ObiaviavaneC(2, 1, 0, player);
                        return;
                    }
                }
                if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                    //1 kara
                    if (BestAnons < 12) {
                        this.ObiaviavaneC(1, 2, 0, player);
                        return;
                    }
                    if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 12 && BestAnons < 22 && (Points > 12 || BroiKari > 4)) {
                        this.ObiaviavaneC(2, 2, 0, player);
                        return;
                    }
                }
                if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                    if (
                        !(
                            (BroiKupi === BroiKari && BroiTo4kiKupi - BroiTo4kiKari >= 4) ||
                            (BroiPiki === BroiKari && BroiTo4kiPiki - BroiTo4kiKari >= 4)
                        )
                    ) {
                        if (BestAnons < 12) {
                            this.ObiaviavaneC(1, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 12 && BestAnons < 22 && (Points > 12 || BroiKari > 4)) {
                            if (BestAnons === 13) {
                                if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BroiKari === BroiPiki) {
                                    this.ObiaviavaneC(1, 4, 0, player);
                                    return;
                                }
                            }
                            if (BestAnons === 14) {
                                if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            this.ObiaviavaneC(2, 2, 0, player);
                            return;
                        }
                    }
                }
                if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                    //1 kupa
                    if (BestAnons < 13) {
                        this.ObiaviavaneC(1, 3, 0, player);
                        return;
                    }
                    if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 13 && BestAnons < 23 && (Points > 12 || BroiKupi > 4)) {
                        this.ObiaviavaneC(2, 3, 0, player);
                        return;
                    }
                }
                if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                    if (
                        (BroiKupi === BroiKari && BroiTo4kiKupi - BroiTo4kiKari >= 4) ||
                        !(BroiPiki === BroiKupi && BroiTo4kiPiki - BroiTo4kiKupi >= 4)
                    ) {
                        if (BestAnons < 13) {
                            this.ObiaviavaneC(1, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 13 && BestAnons < 23 && (Points > 12 || BroiKupi > 4)) {
                            if (BestAnons === 14) {
                                if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            this.ObiaviavaneC(2, 3, 0, player);
                            return;
                        }
                    }
                }
                if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                    //1 pika
                    if (BestAnons < 14) {
                        this.ObiaviavaneC(1, 4, 0, player);
                        return;
                    }
                    if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                        this.ObiaviavaneC(2, 4, 0, player);
                        return;
                    }
                }
                if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                    if (
                        (BroiPiki === BroiKari && BroiTo4kiKupi - BroiTo4kiKari >= 4) ||
                        (BroiPiki === BroiKupi && BroiTo4kiPiki - BroiTo4kiKupi >= 4)
                    ) {
                        if (BestAnons < 14) {
                            this.ObiaviavaneC(1, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                            this.ObiaviavaneC(2, 4, 0, player);
                            return;
                        }
                    }
                }
            }
            ////////1 bez koz
            if (Points >= 15 && Points <= 16) {
                if (BestAnons < 15) {
                    this.ObiaviavaneC(1, 5, 0, player);
                    return;
                }
                if (BestAnons === 15) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 15 && BestAnons < 25) {
                    this.ObiaviavaneC(2, 5, 0, player);
                    return;
                }
                if (BestAnons === 25) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 25 && BestAnons < 35) {
                    this.ObiaviavaneC(3, 5, 0, player);
                    return;
                }
            }
            //////2 boia???
            if (Points >= 17 && Points <= 19) {
                let bk2 = false;
                if (Points === 19) {
                    if (BroiSpatii === 4 && BroiTo4kiSpatii < 4 && BroiKupi === 3 && BroiPiki === 3 && BroiKari === 3) {
                        bk2 = true;
                    }
                    if (BroiKari === 4 && BroiTo4kiKari < 4 && BroiKupi === 3 && BroiPiki === 3 && BroiSpatii === 3) {
                        bk2 = true;
                    }
                    if (BroiKupi === 4 && BroiTo4kiKupi < 4 && BroiKari === 3 && BroiPiki === 3 && BroiSpatii === 3) {
                        bk2 = true;
                    }
                    if (BroiPiki === 4 && BroiTo4kiPiki < 4 && BroiKari === 3 && BroiKupi === 3 && BroiSpatii === 3) {
                        bk2 = true;
                    }
                }
                if (bk2) {
                    if (BestAnons < 25) {
                        this.ObiaviavaneC(2, 5, 0, player);
                        return;
                    }
                    if (BestAnons === 25) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 25 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                    if (BestAnons >= 35) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                }
                if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                    //2 spatii
                    if (BestAnons < 21) {
                        if (BestAnons > 11) {
                            if (Points < 19) {
                                if (BestAnons < 15) {
                                    this.ObiaviavaneC(1, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 15) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            if (Points >= 19) {
                                if (BestAnons < 25) {
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                            }
                        }
                        if (BestAnons <= 11) {
                            this.ObiaviavaneC(2, 1, 0, player);
                            return;
                        }
                    }
                    if (BestAnons === 21 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 21 && BestAnons < 31) {
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        } else {
                            this.ObiaviavaneC(3, 1, 0, player);
                            return;
                        }
                    }
                    if (BestAnons >= 31 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
                if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                    //2 kari
                    if (BestAnons < 22) {
                        if (BestAnons > 12) {
                            if (Points < 19) {
                                if (BestAnons < 15) {
                                    this.ObiaviavaneC(1, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 15) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 20 && BestAnons < 25) {
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                            }
                            if (Points >= 19) {
                                if (BestAnons < 25) {
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                            }
                        }
                        if (BestAnons <= 12) {
                            this.ObiaviavaneC(2, 2, 0, player);
                            return;
                        }
                    }
                    if (BestAnons === 22 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 22 && BestAnons < 32) {
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        } else {
                            this.ObiaviavaneC(3, 2, 0, player);
                            return;
                        }
                    }
                    if (BestAnons >= 32 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
                if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                    //2 kupa
                    if (BestAnons < 23) {
                        if (BestAnons > 13) {
                            if (Points < 19) {
                                if (BestAnons < 15) {
                                    this.ObiaviavaneC(1, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 15) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 20 && BestAnons < 25) {
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                            }
                            if (Points >= 19) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                        if (BestAnons <= 13) {
                            this.ObiaviavaneC(2, 3, 0, player);
                            return;
                        }
                    }
                    if (BestAnons === 23 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 23 && BestAnons < 33) {
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        } else {
                            this.ObiaviavaneC(3, 3, 0, player);
                            return;
                        }
                    }
                    if (BestAnons >= 33 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
                if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                    //2 pika
                    if (BestAnons < 24) {
                        if (BestAnons > 14) {
                            if (Points < 19) {
                                if (BestAnons < 15) {
                                    this.ObiaviavaneC(1, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 15) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 20) {
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                            }
                            if (Points >= 19) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                        if (BestAnons <= 14) {
                            this.ObiaviavaneC(2, 4, 0, player);
                            return;
                        }
                    }
                    if (BestAnons === 24 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 24 && BestAnons < 34) {
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        } else {
                            this.ObiaviavaneC(3, 4, 0, player);
                            return;
                        }
                    }
                    if (BestAnons >= 34 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
            }
            ///////2 bezkoz
            if (Points >= 20 && Points <= 24) {
                if (BestAnons < 25) {
                    if (BestAnons <= 15) {
                        this.ObiaviavaneC(2, 5, 0, player);
                        return;
                    } else {
                        this.ObiaviavaneC(2, 5, 0, player);
                        return;
                    }
                }
                if (BestAnons === 25) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (BestAnons > 25 && BestAnons < 35) {
                    this.ObiaviavaneC(3, 5, 0, player);
                    return;
                }
                if (BestAnons >= 35) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////3 bezkoz+
            if (Points >= 25 && Points <= 32) {
                if (BestAnons < 35) {
                    if (BestAnons <= 25) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    } else {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
                if (BestAnons >= 35) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            if (Points >= 32 && Points < 35) {
                if (BestAnons < 65) {
                    this.ObiaviavaneC(6, 5, 0, player);
                    return;
                }
                if (BestAnons >= 65) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            if (Points >= 35) {
                if (BestAnons < 75) {
                    this.ObiaviavaneC(7, 5, 0, player);
                    return;
                }
                if (BestAnons === 75) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
        }
        ///////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////1-vi otgovor/////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////
        let otgovor1 = true;
        let broianon = 0;
        for (let i = 0; i < this.AnonBr; i++) {
            if (player === 2) {
                if (this.Anon[i] < 3000 && this.Anon[i] > 2000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] < 5000 && this.Anon[i] > 4000) {
                    broianon++;
                }
            }
            if (player === 3) {
                if (this.Anon[i] < 4000 && this.Anon[i] > 3000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] < 2000 && this.Anon[i] > 1000) {
                    broianon++;
                }
            }
            if (player === 4) {
                if (this.Anon[i] < 5000 && this.Anon[i] > 4000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    broianon++;
                }
            }
        }
        if (broianon !== 1) {
            otgovor1 = false;
        }
        if (this.AnonBr > 6) {
            otgovor1 = false;
        }
        let BestAnons1 = 0;
        //    let StrongC = false;
        for (let i = 0; i < this.AnonBr - 2; i++) {
            if (this.Anon[i] % 100 > BestAnons1) {
                BestAnons1 = this.Anon[i] % 100;
            }
        }
        /*    let FirstAnons = this.Anon[this.AnonBr - 2];
        let RealAnons;
        if ((FirstAnons % 100) - BestAnons1 >= 10) {
            RealAnons = FirstAnons % 100;
        }
        if ((FirstAnons % 1000 === 100 || FirstAnons % 1000 === 200) && BestAnons1 < 30) {
            RealAnons = BestAnons1;
        }
        if ((FirstAnons % 100) - BestAnons1 < 10) {
            if (FirstAnons % 100 < 20) {
                RealAnons = FirstAnons % 100;
            } else {
                RealAnons = (FirstAnons % 100) - 10;
                if (FirstAnons < 25) {
                    StrongC = true;
                }
            }
        }*/
        let Partner;
        if (player === 2) {
            Partner = 4;
        }
        if (player === 4) {
            Partner = 2;
        }
        if (player === 3) {
            Partner = 1;
        }
        if (player === 1) {
            Partner = 3;
        }
        if (otgovor1) {
            /////////////az imam baraj
            if (Points <= 10 && BestAnons < 15) {
                if ((BroiSpatii === 6 && Points > 3) || BroiSpatii === 7) {
                    if (BestAnons < 31) {
                        this.ObiaviavaneC(3, 1, 0, player);
                        return;
                    }
                }
                if (BroiSpatii >= 8 && BroiSpatii <= 9) {
                    if (BestAnons < 41) {
                        this.ObiaviavaneC(4, 1, 0, player);
                        return;
                    }
                }
                if (BroiSpatii >= 10) {
                    if (BestAnons < 51) {
                        this.ObiaviavaneC(5, 1, 0, player);
                        return;
                    }
                }
                if ((BroiKari === 6 && Points > 3) || BroiKari === 7) {
                    if (BestAnons < 32) {
                        this.ObiaviavaneC(3, 2, 0, player);
                        return;
                    }
                }
                if (BroiKari >= 8 && BroiKari <= 9) {
                    if (BestAnons < 42) {
                        this.ObiaviavaneC(4, 2, 0, player);
                        return;
                    }
                }
                if (BroiKari >= 10) {
                    if (BestAnons < 52) {
                        this.ObiaviavaneC(5, 2, 0, player);
                        return;
                    }
                }
                if ((BroiKupi === 6 && Points > 3) || BroiKupi === 7) {
                    if (BestAnons < 33) {
                        this.ObiaviavaneC(3, 3, 0, player);
                        return;
                    }
                }
                if (BroiKupi >= 8) {
                    if (BestAnons < 43) {
                        this.ObiaviavaneC(4, 3, 0, player);
                        return;
                    }
                }
                if ((BroiPiki === 6 && Points > 3) || BroiPiki === 7) {
                    if (BestAnons < 34) {
                        this.ObiaviavaneC(3, 4, 0, player);
                        return;
                    }
                }
                if (BroiPiki >= 8) {
                    if (BestAnons < 44) {
                        this.ObiaviavaneC(4, 4, 0, player);
                        return;
                    }
                }
            }
            ////////////////otgovor na baraj
            if (this.PlayerI[Partner].GestPoints === 8) {
                if (this.PlayerI[Partner].MinBroiSpatii === 8) {
                    //na spat
                    if (Points >= 13 && Points <= 14) {
                        if (BroiSpatii >= 2) {
                            if (BestAnons < 41 && BestAnons > 31) {
                                this.ObiaviavaneC(4, 1, 0, player);
                                return;
                            }
                        }
                    }
                    if (Points >= 15 && Points <= 18) {
                        if (BestAnons < 51) {
                            this.ObiaviavaneC(5, 1, 0, player);
                            return;
                        }
                    }
                    if (Points >= 19) {
                        if (BestAnons < 61) {
                            this.ObiaviavaneC(6, 1, 0, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKari === 8) {
                    //na kari
                    if (Points >= 13 && Points <= 14) {
                        if (BroiKari >= 2) {
                            if (BestAnons < 42 && BestAnons > 32) {
                                this.ObiaviavaneC(4, 2, 0, player);
                                return;
                            }
                        }
                    }
                    if (Points >= 15 && Points <= 18) {
                        if (BestAnons < 52) {
                            this.ObiaviavaneC(5, 2, 0, player);
                            return;
                        }
                    }
                    if (Points >= 19) {
                        if (BestAnons < 62) {
                            this.ObiaviavaneC(6, 2, 0, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKupi === 8) {
                    //na kupi
                    if (Points >= 13 && Points <= 14) {
                        if (BroiKupi >= 2) {
                            if (BestAnons < 43 && BestAnons > 33) {
                                this.ObiaviavaneC(4, 3, 0, player);
                                return;
                            }
                        }
                    }
                    if (Points >= 15 && Points <= 18) {
                        if (BestAnons < 43) {
                            this.ObiaviavaneC(4, 3, 0, player);
                            return;
                        }
                    }
                    if (Points >= 19) {
                        if (BestAnons < 63) {
                            this.ObiaviavaneC(6, 3, 0, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiPiki === 8) {
                    //na piki
                    if (Points >= 13 && Points <= 14) {
                        if (BroiPiki >= 2) {
                            if (BestAnons < 44 && BestAnons > 34) {
                                this.ObiaviavaneC(4, 4, 0, player);
                                return;
                            }
                        }
                    }
                    if (Points >= 15 && Points <= 18) {
                        if (BestAnons < 44) {
                            this.ObiaviavaneC(4, 4, 0, player);
                            return;
                        }
                    }
                    if (Points >= 19) {
                        if (BestAnons < 64) {
                            this.ObiaviavaneC(6, 4, 0, player);
                            return;
                        }
                    }
                }
            }
            ////////////////otgovor na 1 spatia
            if (this.PlayerI[Partner].GestPoints === 12) {
                if (Points >= 8 && Points < 11) {
                    if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                        //1 spatia
                        if (BestAnons < 11) {
                            this.ObiaviavaneC(1, 1, 0, player);
                            return;
                        }
                        if (BestAnons >= 11 && BestAnons < 21) {
                            this.ObiaviavaneC(2, 1, 0, player);
                            return;
                        }
                    }
                    if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                        if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                            if (BestAnons < 11) {
                                this.ObiaviavaneC(1, 1, 0, player);
                                return;
                            }
                            if (BestAnons >= 11 && BestAnons < 21) {
                                if (BestAnons === 12) {
                                    if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiKupi) {
                                        this.ObiaviavaneC(1, 3, 0, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 13) {
                                    if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 14) {
                                    if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 1, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                        //1 kara
                        if (BestAnons < 12) {
                            this.ObiaviavaneC(1, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 12 && BestAnons < 22) {
                            this.ObiaviavaneC(2, 2, 0, player);
                            return;
                        }
                    }
                    if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                            if (BestAnons < 12) {
                                this.ObiaviavaneC(1, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 12 && BestAnons < 22) {
                                if (BestAnons === 13) {
                                    if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiKari === BroiPiki) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 14) {
                                    if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 2, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                        //kupa
                        if (BestAnons < 13) {
                            this.ObiaviavaneC(1, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 13 && BestAnons < 23) {
                            this.ObiaviavaneC(2, 3, 0, player);
                            return;
                        }
                    }
                    if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 13) {
                                this.ObiaviavaneC(1, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 13 && BestAnons < 23) {
                                if (BestAnons === 14) {
                                    if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 3, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki > BroiSpatii) {
                        //pika
                        if (BestAnons < 14) {
                            this.ObiaviavaneC(1, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 14 && BestAnons < 24) {
                            this.ObiaviavaneC(2, 4, 0, player);
                            return;
                        }
                    }
                    if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                        if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 14) {
                                this.ObiaviavaneC(1, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                                this.ObiaviavaneC(2, 4, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points >= 11 && Points < 13) {
                    if (BestAnons < 15) {
                        this.ObiaviavaneC(1, 5, 0, player);
                        return;
                    }
                    if (BestAnons === 15) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 15 && BestAnons < 25) {
                        this.ObiaviavaneC(2, 5, 0, player);
                        return;
                    }
                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                        this.ObiaviavaneC(0, 0, 1, player);
                        return;
                    }
                    if (BestAnons > 25 && BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                }
                if (Points >= 13 && Points < 19) {
                    //forcing
                    if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                        //spatii
                        if (BestAnons < 21) {
                            if (BestAnons <= 11) {
                                this.ObiaviavaneC(2, 1, 0, player);
                                return;
                            }
                            if (BestAnons <= 12) {
                                if (BroiKari === BroiSpatii) {
                                    this.ObiaviavaneC(2, 2, 0, player);
                                    return;
                                }
                            }
                            if (BestAnons <= 13) {
                                if (BroiKupi === BroiSpatii) {
                                    this.ObiaviavaneC(2, 3, 0, player);
                                    return;
                                }
                            }
                            if (BestAnons <= 14) {
                                if (BroiPiki === BroiSpatii) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                        if (BestAnons >= 21) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons <= 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                        //Kari
                        if (BestAnons < 22) {
                            if (BestAnons <= 12) {
                                this.ObiaviavaneC(2, 2, 0, player);
                                return;
                            }
                            if (BestAnons <= 13) {
                                if (BroiKupi === BroiKari) {
                                    this.ObiaviavaneC(2, 3, 0, player);
                                    return;
                                }
                            }
                            if (BestAnons <= 14) {
                                if (BroiPiki === BroiKari) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                        if (BestAnons >= 22) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons <= 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                        //Kupi
                        if (BestAnons < 23) {
                            if (BestAnons <= 13) {
                                this.ObiaviavaneC(2, 2, 0, player);
                                return;
                            }
                            if (BestAnons <= 14) {
                                if (BroiPiki === BroiKupi) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                        if (BestAnons >= 23) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons <= 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                        //Piki
                        if (BestAnons < 24) {
                            if (BestAnons <= 14) {
                                this.ObiaviavaneC(2, 2, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                        if (BestAnons >= 24) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons <= 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points > 19) {
                    //pitane za asa
                    if (BestAnons <= 11) {
                        this.ObiaviavaneC(2, 5, 0, player);
                        return;
                    }
                    if (BestAnons <= 25) {
                        this.ObiaviavaneC(3, 5, 0, player);
                        return;
                    }
                    if (BestAnons > 25 && BestAnons < 45) {
                        this.ObiaviavaneC(4, 5, 0, player);
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ////////////////otgovor na 1 boia
            if (this.PlayerI[Partner].GestPoints === 13) {
                ////////////////1 spatia
                if (this.PlayerI[Partner].MinBroiSpatii === 4) {
                    //spa
                    if (BroiSpatii >= 4) {
                        if (Points >= 6 && Points <= 10) {
                            if (BestAnons < 31) {
                                this.ObiaviavaneC(3, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 31) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 41) {
                                this.ObiaviavaneC(4, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 41) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 11 && Points <= 18) {
                            //man6
                            if (BroiSpatii === 4) {
                                if (BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 35) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            if (BroiSpatii >= 5) {
                                if (BestAnons < 51) {
                                    this.ObiaviavaneC(5, 1, 0, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 19) {
                            //pitane za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiSpatii <= 3) {
                        //niama spatii
                        if (Points >= 6 && Points <= 9) {
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 12) {
                                    this.ObiaviavaneC(1, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 12 && BestAnons < 22) {
                                    this.ObiaviavaneC(2, 2, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 12) {
                                        this.ObiaviavaneC(1, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 12 && BestAnons < 22) {
                                        if (BestAnons === 13) {
                                            if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                            if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 13) {
                                    this.ObiaviavaneC(1, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 13 && BestAnons < 23) {
                                    this.ObiaviavaneC(2, 3, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 13) {
                                        this.ObiaviavaneC(1, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 13 && BestAnons < 23) {
                                        if (BestAnons === 14) {
                                            if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 14) {
                                    this.ObiaviavaneC(1, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 14 && BestAnons < 24) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 14) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 10 && Points <= 12) {
                            if (BestAnons < 15) {
                                this.ObiaviavaneC(1, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 15) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 15 && BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                        if (Points >= 13 && Points <= 17) {
                            //forcing za man6
                            if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                                //spatii
                                if (BestAnons < 21) {
                                    if (BestAnons <= 11) {
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 12) {
                                        if (BroiKari === BroiSpatii) {
                                            this.ObiaviavaneC(2, 2, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiSpatii) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiSpatii) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 21) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                                //Kari
                                if (BestAnons < 22) {
                                    if (BestAnons <= 12) {
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiKari) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKari) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 22) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                                //Kupi
                                if (BestAnons < 23) {
                                    if (BestAnons <= 13) {
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKupi) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 23) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                                //Piki
                                if (BestAnons < 24) {
                                    if (BestAnons <= 14) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 24) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 18) {
                            //Asking za asa
                            if (BestAnons <= 11) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                //////////////////////////1 kara
                if (this.PlayerI[Partner].MinBroiKari === 4) {
                    //kar
                    if (BroiKari >= 4) {
                        if (Points >= 6 && Points <= 10) {
                            if (BestAnons < 32) {
                                this.ObiaviavaneC(3, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 32) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 42) {
                                this.ObiaviavaneC(4, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 42) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 11 && Points <= 18) {
                            if (BroiKari === 4) {
                                if (BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 35) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            if (BroiKari >= 5) {
                                if (BestAnons < 52) {
                                    this.ObiaviavaneC(5, 2, 0, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 19) {
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari <= 3) {
                        //niama kari
                        if (Points >= 7 && Points <= 9) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons < 11) {
                                    this.ObiaviavaneC(1, 1, 0, player);
                                    return;
                                }
                                if (BestAnons > 11 && BestAnons < 21) {
                                    this.ObiaviavaneC(2, 1, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons < 11) {
                                        this.ObiaviavaneC(1, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons > 11 && BestAnons < 21) {
                                        if (BestAnons === 12) {
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(1, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 13) {
                                            if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                            if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 13) {
                                    this.ObiaviavaneC(1, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 13 && BestAnons < 23) {
                                    this.ObiaviavaneC(2, 3, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 13) {
                                        this.ObiaviavaneC(1, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 13 && BestAnons < 23) {
                                        if (BestAnons === 14) {
                                            if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 14) {
                                    this.ObiaviavaneC(1, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 14 && BestAnons < 24) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 14) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 10 && Points <= 12) {
                            if (BestAnons < 15) {
                                this.ObiaviavaneC(1, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 15) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 15 && BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                        if (Points >= 13 && Points <= 17) {
                            //forcing za man6
                            if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                                //spatii
                                if (BestAnons < 21) {
                                    if (BestAnons <= 11) {
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 12) {
                                        if (BroiKari === BroiSpatii) {
                                            this.ObiaviavaneC(2, 2, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiSpatii) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiSpatii) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 21) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                                //Kari
                                if (BestAnons < 22) {
                                    if (BestAnons <= 12) {
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiKari) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKari) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 22) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                                //Kupi
                                if (BestAnons < 23) {
                                    if (BestAnons <= 13) {
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKupi) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 23) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                                //Piki
                                if (BestAnons < 24) {
                                    if (BestAnons <= 14) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 24) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 18) {
                            //Asking za asa
                            if (BestAnons <= 11) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                //////////////1 kupa
                if (this.PlayerI[Partner].MinBroiKupi === 4) {
                    //kup
                    if (BroiKupi >= 4) {
                        if (Points >= 6 && Points <= 10) {
                            if (BestAnons < 33) {
                                this.ObiaviavaneC(3, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 33) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 43) {
                                this.ObiaviavaneC(4, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 43) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 11 && Points <= 18) {
                            if (BroiKupi >= 4) {
                                if (BestAnons < 43) {
                                    this.ObiaviavaneC(4, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 43) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 19) {
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi <= 3) {
                        //niama kupi
                        if (Points >= 7 && Points <= 9) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons < 11) {
                                    this.ObiaviavaneC(1, 1, 0, player);
                                    return;
                                }
                                if (BestAnons > 11 && BestAnons < 21) {
                                    this.ObiaviavaneC(2, 1, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons < 11) {
                                        this.ObiaviavaneC(1, 1, 0, player);
                                        return;
                                    }

                                    if (BestAnons > 11 && BestAnons < 21) {
                                        if (BestAnons === 12) {
                                            if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(1, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 13) {
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                            if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 12) {
                                    this.ObiaviavaneC(1, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 12 && BestAnons < 22) {
                                    this.ObiaviavaneC(2, 2, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 12) {
                                        this.ObiaviavaneC(1, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 12 && BestAnons < 22) {
                                        if (BestAnons === 13) {
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                            if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 14) {
                                    this.ObiaviavaneC(1, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 14 && BestAnons < 24) {
                                    this.ObiaviavaneC(2, 4, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 14) {
                                        this.ObiaviavaneC(1, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 14 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 14 && BestAnons < 24 && (Points > 12 || BroiPiki > 4)) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 10 && Points <= 12) {
                            if (BestAnons < 15) {
                                this.ObiaviavaneC(1, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 15) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 15 && BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                        if (Points >= 13 && Points <= 17) {
                            //forcing za man6
                            if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                                //spatii
                                if (BestAnons < 21) {
                                    if (BestAnons <= 11) {
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 12) {
                                        if (BroiKari === BroiSpatii) {
                                            this.ObiaviavaneC(2, 2, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiSpatii) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiSpatii) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 21) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                                //Kari
                                if (BestAnons < 22) {
                                    if (BestAnons <= 12) {
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiKari) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKari) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 22) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                                //Kupi
                                if (BestAnons < 23) {
                                    if (BestAnons <= 13) {
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKupi) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 23) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                                //Piki
                                if (BestAnons < 24) {
                                    if (BestAnons <= 14) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 24) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 18) {
                            //Asking za asa
                            if (BestAnons <= 11) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons <= 25) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                ///////////////na 1 pika
                if (this.PlayerI[Partner].MinBroiPiki === 4) {
                    //pik
                    if (BroiPiki >= 4) {
                        if (Points >= 6 && Points <= 10) {
                            if (BestAnons < 34) {
                                this.ObiaviavaneC(3, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 34) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 44) {
                                this.ObiaviavaneC(4, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 44) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 11 && Points <= 18) {
                            if (BroiPiki >= 4) {
                                if (BestAnons < 44) {
                                    this.ObiaviavaneC(4, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 44) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 19) {
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki <= 3) {
                        if (Points >= 7 && Points <= 9) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons > 11 && BestAnons < 21) {
                                    this.ObiaviavaneC(2, 1, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons > 11 && BestAnons < 21) {
                                        if (BestAnons === 12) {
                                            if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(1, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 13) {
                                            if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                        }
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 12) {
                                    this.ObiaviavaneC(1, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 12 && BestAnons < 22) {
                                    this.ObiaviavaneC(2, 2, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 12) {
                                        this.ObiaviavaneC(1, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 12 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 12 && BestAnons < 22) {
                                        if (BestAnons === 13) {
                                            if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(1, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 14) {
                                        }
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 13) {
                                    this.ObiaviavaneC(1, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 13 && BestAnons < 23) {
                                    this.ObiaviavaneC(2, 3, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 13) {
                                        this.ObiaviavaneC(1, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 13 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 13 && BestAnons < 23) {
                                        if (BestAnons === 14) {
                                        }
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 10 && Points <= 12) {
                            if (BestAnons < 15) {
                                this.ObiaviavaneC(1, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 15) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 15 && BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                        if (Points >= 13 && Points <= 17) {
                            //forcing za man6
                            if (BroiSpatii >= BroiKupi && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKari) {
                                //spatii
                                if (BestAnons < 21) {
                                    if (BestAnons <= 11) {
                                        this.ObiaviavaneC(2, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 12) {
                                        if (BroiKari === BroiSpatii) {
                                            this.ObiaviavaneC(2, 2, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiSpatii) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiSpatii) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 21) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari >= BroiKupi && BroiKari >= BroiPiki && BroiKari >= BroiSpatii) {
                                //Kari
                                if (BestAnons < 22) {
                                    if (BestAnons <= 12) {
                                        this.ObiaviavaneC(2, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 13) {
                                        if (BroiKupi === BroiKari) {
                                            this.ObiaviavaneC(2, 3, 0, player);
                                            return;
                                        }
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKari) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 22) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi >= BroiKari && BroiKupi >= BroiPiki && BroiKupi >= BroiSpatii) {
                                //Kupi
                                if (BestAnons < 23) {
                                    if (BestAnons <= 13) {
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons <= 14) {
                                        if (BroiPiki === BroiKupi) {
                                            this.ObiaviavaneC(2, 4, 0, player);
                                            return;
                                        }
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 23) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki >= BroiKari && BroiPiki >= BroiKupi && BroiPiki >= BroiSpatii) {
                                //Piki
                                if (BestAnons < 24) {
                                    if (BestAnons <= 14) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                    this.ObiaviavaneC(2, 5, 0, player);
                                    return;
                                }
                                if (BestAnons >= 24) {
                                    if (BestAnons < 25) {
                                        this.ObiaviavaneC(2, 5, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 25 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons <= 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 18) {
                            //Asking za asa
                            if (BestAnons <= 11) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons <= 25) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                            if (BestAnons > 25 && BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
            }
            ///////////////otgovor na 1 bk
            if (this.PlayerI[Partner].GestPoints === 16) {
                if (Points >= 6 && Points <= 9) {
                    if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                        //1 spatia
                        if (BestAnons < 21) {
                            this.ObiaviavaneC(2, 1, 0, player);
                            return;
                        }
                        if (BestAnons === 21 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 21 && BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                        if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                            if (BestAnons < 21) {
                                this.ObiaviavaneC(2, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 21 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 21 && BestAnons < 25) {
                                if (BestAnons === 22) {
                                    if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiKupi) {
                                        this.ObiaviavaneC(2, 3, 0, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 23) {
                                    if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 24) {
                                    if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                        //1 kara
                        if (BestAnons < 22) {
                            this.ObiaviavaneC(2, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 22 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 22 && BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                            if (BestAnons < 22) {
                                this.ObiaviavaneC(2, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 22 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 22 && BestAnons < 25) {
                                if (BestAnons === 23) {
                                    if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiKari === BroiPiki) {
                                        this.ObiaviavaneC(2, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 24) {
                                    if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                        //kupa
                        if (BestAnons < 23) {
                            this.ObiaviavaneC(2, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 23 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 23 && BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 23) {
                                this.ObiaviavaneC(2, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 23 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 23 && BestAnons < 25) {
                                if (BestAnons === 24) {
                                    if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki > BroiSpatii) {
                        //pika
                        if (BestAnons < 24) {
                            this.ObiaviavaneC(2, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 24 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 24 && BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                        if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 24) {
                                this.ObiaviavaneC(2, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 24 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 24 && BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points >= 10 && Points <= 15) {
                    //forcing
                    if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                        //1 spatia
                        if (BestAnons < 31) {
                            this.ObiaviavaneC(3, 1, 0, player);
                            return;
                        }
                        if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 31 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                        if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                            if (BestAnons < 31) {
                                this.ObiaviavaneC(3, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 31 && BestAnons < 35) {
                                if (BestAnons === 32) {
                                    if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiKupi) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 33) {
                                    if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                        //1 kara
                        if (BestAnons < 32) {
                            this.ObiaviavaneC(3, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 32 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                            if (BestAnons < 32) {
                                this.ObiaviavaneC(3, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 32 && BestAnons < 35) {
                                if (BestAnons === 33) {
                                    if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiKari === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                        //kupa
                        if (BestAnons < 33) {
                            this.ObiaviavaneC(3, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 33 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 33) {
                                this.ObiaviavaneC(3, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 33 && BestAnons < 35) {
                                if (BestAnons === 34) {
                                    if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki > BroiSpatii) {
                        //pika
                        if (BestAnons < 34) {
                            this.ObiaviavaneC(3, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 34 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                        if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 34) {
                                this.ObiaviavaneC(3, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 34 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points >= 16) {
                    //pitane za asa
                    if (BestAnons < 45) {
                        this.ObiaviavaneC(4, 5, 0, player);
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////////////otgovor na 1 bk+(kazano na Pone 2 nivo)
            if (this.PlayerI[Partner].GestPoints === 17) {
                if (Points >= 7 && Points <= 15) {
                    if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                        //1 spatia
                        if (BestAnons < 21) {
                            this.ObiaviavaneC(2, 1, 0, player);
                            return;
                        }
                        if (BestAnons === 21 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 21 && BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                        if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                            if (BestAnons < 31) {
                                this.ObiaviavaneC(3, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 31 && BestAnons < 35) {
                                if (BestAnons === 32) {
                                    if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiKupi) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 33) {
                                    if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                        //1 kara
                        if (BestAnons < 32) {
                            this.ObiaviavaneC(3, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 32 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                            if (BestAnons < 32) {
                                this.ObiaviavaneC(3, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 32 && BestAnons < 35) {
                                if (BestAnons === 33) {
                                    if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiKari === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                        //kupa
                        if (BestAnons < 33) {
                            this.ObiaviavaneC(3, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 33 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 33) {
                                this.ObiaviavaneC(3, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 33 && BestAnons < 35) {
                                if (BestAnons === 34) {
                                    if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki > BroiSpatii) {
                        //pika
                        if (BestAnons < 34) {
                            this.ObiaviavaneC(3, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 34 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                        if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 34) {
                                this.ObiaviavaneC(3, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 34 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points >= 15) {
                    //pitane za asa
                    if (BestAnons < 45) {
                        this.ObiaviavaneC(4, 5, 0, player);
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////////////otgovor na 2 boia
            if (this.PlayerI[Partner].GestPoints === 18) {
                if (this.PlayerI[Partner].MinBroiSpatii === 4) {
                    if (BroiSpatii >= 4) {
                        if (Points >= 4 && Points <= 6) {
                            if (BestAnons < 31) {
                                this.ObiaviavaneC(3, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 31 && this.Anon[this.AnonBr - 2] !== 3031) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 41) {
                                this.ObiaviavaneC(4, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 41 && this.Anon[this.AnonBr - 2] !== 3041) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 7 && Points <= 12) {
                            //zatvaria6t na man6
                            if (BroiSpatii === 4) {
                                if (BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 35) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            if (BroiSpatii >= 5) {
                                if (BestAnons < 51) {
                                    this.ObiaviavaneC(5, 1, 0, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    if (BroiSpatii <= 3) {
                        //niama spatii
                        if (Points >= 5 && Points <= 7) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 8 && Points <= 12) {
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 32) {
                                    this.ObiaviavaneC(3, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 32 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 32) {
                                        this.ObiaviavaneC(3, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 32 && BestAnons < 35) {
                                        if (BestAnons === 33) {
                                            if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 33) {
                                    this.ObiaviavaneC(3, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 33 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 33) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 33 && BestAnons < 35) {
                                        if (BestAnons === 34) {
                                            if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 34) {
                                    this.ObiaviavaneC(3, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 34 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 34) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 34 && BestAnons < 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKari === 4) {
                    //kari
                    if (BroiKari >= 4) {
                        if (Points >= 4 && Points <= 6) {
                            if (BestAnons < 32) {
                                this.ObiaviavaneC(3, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 32 && this.Anon[this.AnonBr - 2] !== 3032) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 42) {
                                this.ObiaviavaneC(4, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 42 && this.Anon[this.AnonBr - 2] !== 3042) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 7 && Points <= 12) {
                            //zatvaria6t na man6
                            if (BroiKari === 4) {
                                if (BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                                if (BestAnons === 35) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                            if (BroiKari >= 5) {
                                if (BestAnons < 52) {
                                    this.ObiaviavaneC(5, 2, 0, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    if (BroiKari <= 3) {
                        //niama kari
                        if (Points >= 5 && Points <= 7) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 8 && Points <= 12) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons < 31) {
                                    this.ObiaviavaneC(3, 1, 0, player);
                                    return;
                                }
                                if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 31 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons < 31) {
                                        this.ObiaviavaneC(3, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 31 && BestAnons < 35) {
                                        if (BestAnons === 32) {
                                            if (
                                                BroiSpatii === BroiKari &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3032
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(3, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 33) {
                                            if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 33) {
                                    this.ObiaviavaneC(3, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 33 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 33) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 33 && BestAnons < 35) {
                                        if (BestAnons === 34) {
                                            if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 34) {
                                    this.ObiaviavaneC(3, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 34 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 34) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 34 && BestAnons < 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKupi === 4) {
                    if (BroiKupi >= 4) {
                        if (Points >= 4 && Points <= 6) {
                            if (BestAnons < 33) {
                                this.ObiaviavaneC(3, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 33 && this.Anon[this.AnonBr - 2] !== 3033) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 43) {
                                this.ObiaviavaneC(4, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 43 && this.Anon[this.AnonBr - 2] !== 3043) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 7 && Points <= 12) {
                            //man6 na kupi
                            if (BroiKupi === 4) {
                                if (BestAnons < 43) {
                                    this.ObiaviavaneC(4, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 43 && this.Anon[this.AnonBr - 2] !== 3043) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    if (BroiKupi <= 3) {
                        //niama kupi
                        if (Points >= 5 && Points <= 7) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 8 && Points <= 12) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons < 31) {
                                    this.ObiaviavaneC(3, 1, 0, player);
                                    return;
                                }
                                if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 31 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons < 31) {
                                        this.ObiaviavaneC(3, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 31 && BestAnons < 35) {
                                        if (BestAnons === 32) {
                                            if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(3, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 33) {
                                            if (
                                                BroiSpatii === BroiKupi &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3033
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 32) {
                                    this.ObiaviavaneC(3, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 32 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 32) {
                                        this.ObiaviavaneC(3, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 32 && BestAnons < 35) {
                                        if (BestAnons === 33) {
                                            if (
                                                BroiKari === BroiKupi &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3033
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                                //pika
                                if (BestAnons < 34) {
                                    this.ObiaviavaneC(3, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 34 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                                if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 34) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 34 && BestAnons < 35) {
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiPiki === 4) {
                    if (BroiPiki >= 4) {
                        if (Points >= 4 && Points <= 6) {
                            if (BestAnons < 34) {
                                this.ObiaviavaneC(3, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 34 && this.Anon[this.AnonBr - 2] !== 3034) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons < 44) {
                                this.ObiaviavaneC(4, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 44 && this.Anon[this.AnonBr - 2] !== 3044) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 7 && Points <= 12) {
                            //man6 na piki
                            if (BroiPiki === 4) {
                                if (BestAnons < 44 && this.Anon[this.AnonBr - 2] !== 3044) {
                                    this.ObiaviavaneC(4, 4, 0, player);
                                    return;
                                }
                                if (BestAnons === 44) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    if (BroiPiki <= 3) {
                        //niama piki
                        if (Points >= 5 && Points <= 7) {
                            if (BestAnons < 25) {
                                this.ObiaviavaneC(2, 5, 0, player);
                                return;
                            }
                            if (BestAnons === 25) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (Points >= 8 && Points <= 12) {
                            if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                                //1 spatia
                                if (BestAnons < 31) {
                                    this.ObiaviavaneC(3, 1, 0, player);
                                    return;
                                }
                                if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 31 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                                if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                                    if (BestAnons < 31) {
                                        this.ObiaviavaneC(3, 1, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 31 && BestAnons < 35) {
                                        if (BestAnons === 32) {
                                            if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiKupi) {
                                                this.ObiaviavaneC(3, 3, 0, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 33) {
                                            if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiSpatii === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (
                                                BroiSpatii === BroiPiki &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3034
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                                //1 kara
                                if (BestAnons < 32) {
                                    this.ObiaviavaneC(3, 2, 0, player);
                                    return;
                                }
                                if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 32 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                                    if (BestAnons < 32) {
                                        this.ObiaviavaneC(3, 2, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 32 && BestAnons < 35) {
                                        if (BestAnons === 33) {
                                            if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                            if (BroiKari === BroiPiki) {
                                                this.ObiaviavaneC(3, 4, 0, player);
                                                return;
                                            }
                                        }
                                        if (BestAnons === 34) {
                                            if (
                                                BroiKari === BroiPiki &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3034
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                            if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                                //kupa
                                if (BestAnons < 33) {
                                    this.ObiaviavaneC(3, 3, 0, player);
                                    return;
                                }
                                if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                    this.ObiaviavaneC(0, 0, 1, player);
                                    return;
                                }
                                if (BestAnons > 33 && BestAnons < 35) {
                                    this.ObiaviavaneC(3, 5, 0, player);
                                    return;
                                }
                            }
                            if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                                if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                                    if (BestAnons < 33) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BestAnons > 33 && BestAnons < 35) {
                                        if (BestAnons === 34) {
                                            if (
                                                BroiKupi === BroiPiki &&
                                                this.Kontra === 0 &&
                                                this.Rekontra === 0 &&
                                                this.Anon[this.AnonBr - 2] !== 3034
                                            ) {
                                                this.ObiaviavaneC(0, 0, 1, player);
                                                return;
                                            }
                                        }
                                        this.ObiaviavaneC(3, 5, 0, player);
                                        return;
                                    }
                                }
                            }
                        }
                        if (Points >= 13) {
                            //asking za asa
                            if (BestAnons < 45) {
                                this.ObiaviavaneC(4, 5, 0, player);
                                return;
                            }
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
            }
            ///////////////otgovor na 2 bk
            if (this.PlayerI[Partner].GestPoints === 20) {
                if (Points >= 5 && Points <= 10) {
                    if (BroiSpatii > BroiKupi && BroiSpatii > BroiPiki && BroiKari < BroiSpatii) {
                        //1 spatia
                        if (BestAnons < 31) {
                            this.ObiaviavaneC(3, 1, 0, player);
                            return;
                        }
                        if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 31 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari <= BroiSpatii && BroiSpatii >= BroiPiki && BroiSpatii >= BroiKupi) {
                        if (BroiKupi === BroiSpatii || BroiPiki === BroiSpatii || BroiKari === BroiSpatii) {
                            if (BestAnons < 31) {
                                this.ObiaviavaneC(3, 1, 0, player);
                                return;
                            }
                            if (BestAnons === 31 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 31 && BestAnons < 35) {
                                if (BestAnons === 32) {
                                    if (BroiSpatii === BroiKari && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiKupi) {
                                        this.ObiaviavaneC(3, 3, 0, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 33) {
                                    if (BroiSpatii === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiSpatii === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiSpatii === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKari > BroiKupi && BroiKari > BroiPiki && BroiKari >= BroiSpatii) {
                        //1 kara
                        if (BestAnons < 32) {
                            this.ObiaviavaneC(3, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 32 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKari >= BroiSpatii && BroiKari >= BroiPiki && BroiKari >= BroiKupi) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKari) {
                            if (BestAnons < 32) {
                                this.ObiaviavaneC(3, 2, 0, player);
                                return;
                            }
                            if (BestAnons === 32 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 32 && BestAnons < 35) {
                                if (BestAnons === 33) {
                                    if (BroiKari === BroiKupi && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                    if (BroiKari === BroiPiki) {
                                        this.ObiaviavaneC(3, 4, 0, player);
                                        return;
                                    }
                                }
                                if (BestAnons === 34) {
                                    if (BroiKari === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiKupi > BroiKari && BroiKupi > BroiPiki && BroiKupi >= BroiSpatii) {
                        //kupa
                        if (BestAnons < 33) {
                            this.ObiaviavaneC(3, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 33 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiKupi >= BroiSpatii && BroiKupi >= BroiPiki && BroiKupi >= BroiKari) {
                        if (BroiKupi === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 33) {
                                this.ObiaviavaneC(3, 3, 0, player);
                                return;
                            }
                            if (BestAnons === 33 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 33 && BestAnons < 35) {
                                if (BestAnons === 34) {
                                    if (BroiKupi === BroiPiki && this.Kontra === 0 && this.Rekontra === 0) {
                                        this.ObiaviavaneC(0, 0, 1, player);
                                        return;
                                    }
                                }
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                    if (BroiPiki > BroiKari && BroiPiki > BroiKupi && BroiPiki >= BroiSpatii) {
                        //pika
                        if (BestAnons < 34) {
                            this.ObiaviavaneC(3, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons > 34 && BestAnons < 35) {
                            this.ObiaviavaneC(3, 5, 0, player);
                            return;
                        }
                    }
                    if (BroiPiki >= BroiSpatii && BroiPiki >= BroiKupi && BroiPiki >= BroiKari) {
                        if (BroiPiki === BroiKari || BroiPiki === BroiKupi) {
                            if (BestAnons < 34) {
                                this.ObiaviavaneC(3, 4, 0, player);
                                return;
                            }
                            if (BestAnons === 34 && this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                            if (BestAnons > 34 && BestAnons < 35) {
                                this.ObiaviavaneC(3, 5, 0, player);
                                return;
                            }
                        }
                    }
                }
                if (Points >= 11) {
                    //pitane za asa
                    if (BestAnons < 45) {
                        this.ObiaviavaneC(4, 5, 0, player);
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////////////otgovor na 3 bk
            if (this.PlayerI[Partner].GestPoints === 25) {
                if (Points >= 5) {
                    //pitane za asa
                    if (BestAnons < 45) {
                        this.ObiaviavaneC(4, 5, 0, player);
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
            }
            ///////////////otgovor na 6 bk
            if (this.PlayerI[Partner].GestPoints === 32) {
                if (Points >= 3) {
                    if (BestAnons < 75) {
                        this.ObiaviavaneC(7, 5, 0, player);
                        return;
                    }
                }
            }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////produljenia na anonsite//////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////
        if (!Otkrivane && !otgovor1) {
            let LastAnons;
            for (let i = this.AnonBr - 1; i >= 0; i--) {
                if (this.Anon[i] % 100 !== 0) {
                    LastAnons = this.Anon[i];
                    break;
                }
            }
            let IPlay = true;
            if (player === 1 || player === 3) {
                if ((LastAnons < 3000 && LastAnons > 2000) || (LastAnons < 5000 && LastAnons > 4000)) {
                    IPlay = false;
                }
            }
            if (player === 2 || player === 4) {
                if ((LastAnons < 2000 && LastAnons > 1000) || (LastAnons < 4000 && LastAnons > 3000)) {
                    IPlay = false;
                }
            }
            /*    let Ob6tBroiSpatii = this.PlayerI[Partner].MinBroiSpatii + BroiSpatii;
            let Ob6tBroiKari = this.PlayerI[Partner].MinBroiKari + BroiKari;
            let Ob6tBroiKupi = this.PlayerI[Partner].MinBroiKupi + BroiKupi;
            let Ob6tBroiPiki = this.PlayerI[Partner].MinBroiPiki + BroiPiki;*/
            if (this.PlayerI[Partner].CloseAnons && IPlay) {
                this.ObiaviavaneC(0, 0, 0, player);
                return;
            }
            if (this.PlayerI[Partner].AskingAsa && !IPlay && this.Kontra === 0 && this.Rekontra === 0) {
                this.ObiaviavaneC(0, 0, 1, player);
                return;
            }
            if (this.PlayerI[Partner].AskingAsa) {
                let nivo = Math.floor(BestAnons / 10);
                let toAnons: number;
                if (BroiAsa === 0 || BroiAsa === 3) {
                    toAnons = 1;
                }
                if (BroiAsa === 1 || BroiAsa === 4) {
                    toAnons = 2;
                }
                if (BroiAsa === 2) {
                    toAnons = 3;
                }
                this.ObiaviavaneC(nivo + 1, toAnons, 0, player);
                return;
            }
            if (this.PlayerI[Partner].BroiAsa > 0) {
                //sled kato sa obqveni asata
                if (!IPlay && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }

                let ob6to;
                if (this.PlayerI[Partner].BroiAsa === 0 || this.PlayerI[Partner].BroiAsa === 3) {
                    if (BroiAsa <= 1) {
                        ob6to = BroiAsa + 3;
                    } else {
                        ob6to = BroiAsa;
                    }
                }
                if (this.PlayerI[Partner].BroiAsa === 1 || this.PlayerI[Partner].BroiAsa === 4) {
                    if (BroiAsa) {
                        ob6to = 4;
                    } else {
                        ob6to = BroiAsa + 1;
                    }
                }
                if (this.PlayerI[Partner].BroiAsa === 2) {
                    ob6to = BroiAsa + 2;
                }
                let ob6toPts = this.PlayerI[Partner].GestPoints + Points;
                let fit = 0;
                if (BroiSpatii + this.PlayerI[Partner].MinBroiSpatii >= 8) {
                    fit = 1;
                }
                if (BroiKari + this.PlayerI[Partner].MinBroiKari >= 8) {
                    fit = 2;
                }
                if (BroiKupi + this.PlayerI[Partner].MinBroiKupi >= 8) {
                    fit = 3;
                }
                if (BroiPiki + this.PlayerI[Partner].MinBroiPiki >= 8) {
                    fit = 4;
                }
                if (ob6toPts < 32 && !fit && ob6to < 4 && this.Kontra === 0 && this.Rekontra === 0) {
                    this.ObiaviavaneC(0, 0, 1, player);
                    return;
                }
                if (ob6toPts < 36 || ob6to < 4) {
                    if (fit) {
                        this.ObiaviavaneC(6, fit, 0, player);
                        return;
                    } else {
                        this.ObiaviavaneC(6, 5, 0, player);
                        return;
                    }
                }
                if (fit) {
                    this.ObiaviavaneC(7, fit, 0, player);
                    return;
                }
                this.ObiaviavaneC(7, 5, 0, player);
            }
            if (this.Anon[this.AnonBr - 4] === player * 1000 + 11) {
                if (Points === 11) {
                    let dylgaBoq = false;
                    //    let dyljina = 3;
                    if (BroiKari > 3 || BroiKupi > 3 || BroiPiki > 3) {
                        dylgaBoq = true;
                    }
                    if (!dylgaBoq) {
                        if (BestAnons > 25) {
                            this.ObiaviavaneC(0, 0, 0, player);
                            return;
                        }
                        if (BestAnons === 25) {
                            if (this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player); //informativna kontra
                                return;
                            }
                        }
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player); //ne zatvarq6t
                            return;
                        }
                    } else {
                        if (BestAnons < 12 && BroiKari >= 4) {
                            this.ObiaviavaneC(1, 2, 0, player);
                            return;
                        }
                        if (BestAnons === 12 && BroiKari >= 4) {
                            if (this.Kontra === 0 && this.Rekontra === 0) {
                                this.ObiaviavaneC(0, 0, 1, player);
                                return;
                            }
                        }
                        if (BestAnons < 13 && BroiKupi >= 4) {
                            this.ObiaviavaneC(1, 3, 0, player);
                            return;
                        }
                        if (BestAnons === 13 && BroiKupi >= 4 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (BestAnons < 14 && BroiPiki >= 4) {
                            this.ObiaviavaneC(1, 4, 0, player);
                            return;
                        }
                        if (BestAnons === 14 && BroiPiki >= 4 && this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    this.ObiaviavaneC(0, 0, 0, player);
                    return;
                }
                if (Points > 11) {
                    if (BestAnons < 21) {
                        this.ObiaviavaneC(2, 1, 0, player);
                        return;
                    }
                    if (BestAnons === 21) {
                        if (this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                    if (BestAnons < 31) {
                        this.ObiaviavaneC(3, 1, 0, player);
                        return;
                    }
                    if (BestAnons === 31) {
                        if (this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
            }
            let Ob6toPts = Points + this.PlayerI[Partner].GestPoints;
            let fit = 0;
            if (BroiSpatii + this.PlayerI[Partner].MinBroiSpatii >= 8) {
                fit = 1;
            }
            if (BroiKari + this.PlayerI[Partner].MinBroiKari >= 8) {
                fit = 2;
            }
            if (BroiKupi + this.PlayerI[Partner].MinBroiKupi >= 8) {
                fit = 3;
            }
            if (BroiPiki + this.PlayerI[Partner].MinBroiPiki >= 8) {
                fit = 4;
            }
            if (IPlay) {
                if (Ob6toPts < 22) {
                    this.ObiaviavaneC(0, 0, 0, player);
                    return;
                }
                let nivo = Math.floor(BestAnons / 10);
                let boq = BestAnons % 10;
                if (Ob6toPts < 25 || (Ob6toPts === 25 && !fit)) {
                    if (!fit) {
                        //udyljavane handler
                        let zaUdaljavan = 0;
                        if (BroiSpatii > this.PlayerI[player].MinBroiSpatii && this.PlayerI[player].MinBroiSpatii) {
                            zaUdaljavan = 1;
                        }
                        if (BroiKari > this.PlayerI[player].MinBroiKari && this.PlayerI[player].MinBroiKari) {
                            zaUdaljavan = 2;
                        }
                        if (BroiKupi > this.PlayerI[player].MinBroiKupi && this.PlayerI[player].MinBroiKupi) {
                            zaUdaljavan = 3;
                        }
                        if (BroiPiki > this.PlayerI[player].MinBroiPiki && this.PlayerI[player].MinBroiPiki) {
                            zaUdaljavan = 4;
                        }
                        if (zaUdaljavan && boq < zaUdaljavan && nivo <= 3) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo, zaUdaljavan, 0, player);
                            return;
                        }
                        if (zaUdaljavan && boq >= zaUdaljavan && nivo <= 2) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo + 1, zaUdaljavan, 0, player);
                            return;
                        }
                        //neobqvena handler
                        let neobqvena = 0;
                        if (BroiSpatii >= 4 && !this.PlayerI[player].MinBroiSpatii) {
                            neobqvena = 1;
                        }
                        if (BroiKari >= 4 && !this.PlayerI[player].MinBroiKari) {
                            neobqvena = 2;
                        }
                        if (BroiKupi >= 4 && !this.PlayerI[player].MinBroiKupi) {
                            neobqvena = 3;
                        }
                        if (BroiPiki >= 4 && !this.PlayerI[player].MinBroiPiki) {
                            neobqvena = 4;
                        }
                        if (neobqvena && boq < neobqvena && nivo <= 3) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo, neobqvena, 0, player);
                            return;
                        }
                        if (neobqvena && boq >= neobqvena && nivo <= 2) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo + 1, neobqvena, 0, player);
                            return;
                        }
                        if (BestAnons < 25) {
                            this.ObiaviavaneC(2, 5, 0, player);
                            return;
                        }
                        this.ObiaviavaneC(0, 0, 0, player);
                        return;
                    }
                    if ((nivo < 3 || boq < fit) && fit) {
                        this.ObiaviavaneC(3, fit, 0, player); //zatvarq6t anons
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 0, player);
                    return;
                }
                if (Ob6toPts > 25 || (Ob6toPts === 25 && fit)) {
                    if (!fit) {
                        //udyljavane handler
                        let zaUdaljavan = 0;
                        if (BroiSpatii > this.PlayerI[player].MinBroiSpatii && this.PlayerI[player].MinBroiSpatii) {
                            zaUdaljavan = 1;
                        }
                        if (BroiKari > this.PlayerI[player].MinBroiKari && this.PlayerI[player].MinBroiKari) {
                            zaUdaljavan = 2;
                        }
                        if (BroiKupi > this.PlayerI[player].MinBroiKupi && this.PlayerI[player].MinBroiKupi) {
                            zaUdaljavan = 3;
                        }
                        if (BroiPiki > this.PlayerI[player].MinBroiPiki && this.PlayerI[player].MinBroiPiki) {
                            zaUdaljavan = 4;
                        }
                        if (zaUdaljavan && boq < zaUdaljavan && nivo <= 3) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo, zaUdaljavan, 0, player);
                            return;
                        }
                        if (zaUdaljavan && boq >= zaUdaljavan && nivo <= 2) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo + 1, zaUdaljavan, 0, player);
                            return;
                        }
                        //neobqvena handler
                        let neobqvena = 0;
                        if (BroiSpatii >= 4 && !this.PlayerI[player].MinBroiSpatii) {
                            neobqvena = 1;
                        }
                        if (BroiKari >= 4 && !this.PlayerI[player].MinBroiKari) {
                            neobqvena = 2;
                        }
                        if (BroiKupi >= 4 && !this.PlayerI[player].MinBroiKupi) {
                            neobqvena = 3;
                        }
                        if (BroiPiki >= 4 && !this.PlayerI[player].MinBroiPiki) {
                            neobqvena = 4;
                        }
                        if (neobqvena && boq < neobqvena && nivo <= 3) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo, neobqvena, 0, player);
                            return;
                        }
                        if (neobqvena && boq >= neobqvena && nivo <= 2) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo + 1, neobqvena, 0, player);
                            return;
                        }
                    }
                    if (fit >= 3 && BestAnons < 4 * 10 + fit) {
                        this.ObiaviavaneC(4, fit, 0, player); //zatvarq6t anons
                        return;
                    }
                    if (BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player);
                    }
                    //zatvarq6t anons
                    else {
                        this.ObiaviavaneC(0, 0, 0, player);
                    }
                    return;
                }
            } else {
                if (Ob6toPts < 22) {
                    this.ObiaviavaneC(0, 0, 0, player);
                    return;
                }
                let nivo = Math.floor(BestAnons / 10);
                let boq = BestAnons % 10;
                if (Ob6toPts < 25 || (Ob6toPts === 25 && !fit)) {
                    if (!fit) {
                        //udyljavane handler
                        let zaUdaljavan = 0;
                        if (BroiSpatii > this.PlayerI[player].MinBroiSpatii && this.PlayerI[player].MinBroiSpatii) {
                            zaUdaljavan = 1;
                        }
                        if (BroiKari > this.PlayerI[player].MinBroiKari && this.PlayerI[player].MinBroiKari) {
                            zaUdaljavan = 2;
                        }
                        if (BroiKupi > this.PlayerI[player].MinBroiKupi && this.PlayerI[player].MinBroiKupi) {
                            zaUdaljavan = 3;
                        }
                        if (BroiPiki > this.PlayerI[player].MinBroiPiki && this.PlayerI[player].MinBroiPiki) {
                            zaUdaljavan = 4;
                        }
                        if (zaUdaljavan && boq < zaUdaljavan && nivo <= 3) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo, zaUdaljavan, 0, player);
                            return;
                        }
                        if (zaUdaljavan && boq === zaUdaljavan && nivo <= 2 && this.Kontra === 0 && this.Rekontra === 0) {
                            //informativna kontra udaljavame v boqta
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (zaUdaljavan && boq > zaUdaljavan && nivo <= 2) {
                            //informativna kontra udaljavame v boqta
                            this.ObiaviavaneC(nivo + 1, zaUdaljavan, 0, player);
                            return;
                        }
                        //neobqvena handler
                        let neobqvena = 0;
                        if (BroiSpatii >= 4 && !this.PlayerI[player].MinBroiSpatii) {
                            neobqvena = 1;
                        }
                        if (BroiKari >= 4 && !this.PlayerI[player].MinBroiKari) {
                            neobqvena = 2;
                        }
                        if (BroiKupi >= 4 && !this.PlayerI[player].MinBroiKupi) {
                            neobqvena = 3;
                        }
                        if (BroiPiki >= 4 && !this.PlayerI[player].MinBroiPiki) {
                            neobqvena = 4;
                        }
                        if (neobqvena && boq < neobqvena && nivo <= 3) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo, neobqvena, 0, player);
                            return;
                        }
                        if (neobqvena && boq === neobqvena && nivo <= 2 && this.Kontra === 0 && this.Rekontra === 0) {
                            //informativna kontra obqvqvane nova boq
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (neobqvena && boq > neobqvena && nivo <= 2) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo + 1, neobqvena, 0, player);
                            return;
                        }
                    }
                    if ((nivo < 3 || boq < fit) && fit) {
                        this.ObiaviavaneC(3, fit, 0, player); //zatvarq6t anons
                        return;
                    }
                    this.ObiaviavaneC(0, 0, 0, player);
                    return;
                }
                if (Ob6toPts > 25 || (Ob6toPts === 25 && fit)) {
                    if (!fit) {
                        //udyljavane handler
                        let zaUdaljavan = 0;
                        if (BroiSpatii > this.PlayerI[player].MinBroiSpatii && this.PlayerI[player].MinBroiSpatii) {
                            zaUdaljavan = 1;
                        }
                        if (BroiKari > this.PlayerI[player].MinBroiKari && this.PlayerI[player].MinBroiKari) {
                            zaUdaljavan = 2;
                        }
                        if (BroiKupi > this.PlayerI[player].MinBroiKupi && this.PlayerI[player].MinBroiKupi) {
                            zaUdaljavan = 3;
                        }
                        if (BroiPiki > this.PlayerI[player].MinBroiPiki && this.PlayerI[player].MinBroiPiki) {
                            zaUdaljavan = 4;
                        }
                        if (zaUdaljavan && boq < zaUdaljavan && nivo <= 3) {
                            //udaljavame v boqta
                            this.ObiaviavaneC(nivo, zaUdaljavan, 0, player);
                            return;
                        }
                        if (zaUdaljavan && boq === zaUdaljavan && nivo <= 3 && this.Kontra === 0 && this.Rekontra === 0) {
                            //informativna kontra udaljavame v boqta
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (zaUdaljavan && boq > zaUdaljavan && nivo <= 2) {
                            //informativna kontra udaljavame v boqta
                            this.ObiaviavaneC(nivo + 1, zaUdaljavan, 0, player);
                            return;
                        }
                        //neobqvena handler
                        let neobqvena = 0;
                        if (BroiSpatii >= 4 && !this.PlayerI[player].MinBroiSpatii) {
                            neobqvena = 1;
                        }
                        if (BroiKari >= 4 && !this.PlayerI[player].MinBroiKari) {
                            neobqvena = 2;
                        }
                        if (BroiKupi >= 4 && !this.PlayerI[player].MinBroiKupi) {
                            neobqvena = 3;
                        }
                        if (BroiPiki >= 4 && !this.PlayerI[player].MinBroiPiki) {
                            neobqvena = 4;
                        }
                        if (neobqvena && boq < neobqvena && nivo <= 3) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo, neobqvena, 0, player);
                            return;
                        }
                        if (neobqvena && boq === neobqvena && nivo <= 2 && this.Kontra === 0 && this.Rekontra === 0) {
                            //informativna kontra obqvqvane nova boq
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                        if (neobqvena && boq === neobqvena && nivo <= 2) {
                            //obqvqvane nova boq
                            this.ObiaviavaneC(nivo + 1, neobqvena, 0, player);
                            return;
                        }
                    }
                    if (fit >= 3 && BestAnons < 4 * 10 + fit) {
                        this.ObiaviavaneC(4, fit, 0, player); //zatvarq6t anons
                        return;
                    }
                    if (BestAnons < 35) {
                        this.ObiaviavaneC(3, 5, 0, player); //zatvarq6t anons
                        return;
                    } else {
                        if (this.Kontra === 0 && this.Rekontra === 0) {
                            this.ObiaviavaneC(0, 0, 1, player);
                            return;
                        }
                    }
                }
            }
        }
        this.ObiaviavaneC(0, 0, 0, player);
        return;
    }

    RecalculateCardsPos() {
        if (this.Mor === 3) {
            this.StrelkaPos[1].x = 370;
            this.StrelkaPos[1].y = 377;
            this.StrelkaPos[2].x = 620;
            this.StrelkaPos[2].y = 240;
            this.StrelkaPos[3].x = 370;
            this.StrelkaPos[3].y = 160;
            this.StrelkaPos[4].x = 120;
            this.StrelkaPos[4].y = 240;
            this.CardsPos[53].x = 360;
            this.CardsPos[53].y = 297;
            this.CardsPos[56].x = 435;
            this.CardsPos[56].y = 260;
            this.CardsPos[55].x = 360;
            this.CardsPos[55].y = 215;
            this.CardsPos[54].x = 285;
            this.CardsPos[54].y = 260;
        } else {
            this.StrelkaPos[1].x = 370;
            this.StrelkaPos[1].y = 345;
            this.StrelkaPos[2].x = 540;
            this.StrelkaPos[2].y = 230;
            this.StrelkaPos[3].x = 360;
            this.StrelkaPos[3].y = 120;
            this.StrelkaPos[4].x = 180;
            this.StrelkaPos[4].y = 230;
            this.CardsPos[53].x = 350;
            this.CardsPos[53].y = 260;
            this.CardsPos[56].x = 425;
            this.CardsPos[56].y = 210;
            this.CardsPos[55].x = 350;
            this.CardsPos[55].y = 160;
            this.CardsPos[54].x = 275;
            this.CardsPos[54].y = 210;
        }
    }

    FindWP() {
        let WP: number = 0;
        let FirstSays: number = 8;

        if (this.LastSays[0] === 1 || this.LastSays[0] === 3) {
            for (let i = 0; i < this.br[0]; i++) {
                if (this.PlayerAnouns[0][i][1] === this.LastSays[2]) {
                    FirstSays = this.PlayerAnouns[0][i][0];
                    WP = 1;
                    this.Mor = 3;
                    this.RecalculateCardsPos();
                    break;
                }
            }
            for (let i = 0; i < this.br[2]; i++) {
                if (this.PlayerAnouns[2][i][1] === this.LastSays[2] && FirstSays > this.PlayerAnouns[2][i][0]) {
                    WP = 3;
                    this.Mor = 1;
                    this.RecalculateCardsPos();
                    break;
                }
            }
        }
        if (this.LastSays[0] === 2 || this.LastSays[0] === 4) {
            for (let i = 0; i < this.br[1]; i++) {
                if (this.PlayerAnouns[1][i][1] === this.LastSays[2]) {
                    FirstSays = this.PlayerAnouns[1][i][0];
                    WP = 2;
                    this.Mor = 4;
                    this.RecalculateCardsPos();
                    break;
                }
            }
            for (let i = 0; i < this.br[3]; i++) {
                if (this.PlayerAnouns[3][i][1] === this.LastSays[2] && FirstSays > this.PlayerAnouns[3][i][0]) {
                    WP = 4;
                    this.Mor = 2;
                    this.RecalculateCardsPos();
                    break;
                }
            }
        }
        return WP;
    }

    WhatMyPartnerKnowAboutMe(player: number) {
        let MyLastAnons: number;
        let Otkrivane: boolean = true;
        for (let i = 0; i < this.AnonBr - 1; i++) {
            if (player === 1) {
                if (this.Anon[i] > 1000 && this.Anon[i] < 2000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 3000 && this.Anon[i] < 4000) {
                    Otkrivane = false;
                }
            }
            if (player === 2) {
                if (this.Anon[i] > 4000 && this.Anon[i] < 5000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    Otkrivane = false;
                }
            }
            if (player === 3) {
                if (this.Anon[i] > 1000 && this.Anon[i] < 2000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 3000 && this.Anon[i] < 4000) {
                    Otkrivane = false;
                }
            }
            if (player === 4) {
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    Otkrivane = false;
                }
                if (this.Anon[i] > 4000 && this.Anon[i] < 5000) {
                    Otkrivane = false;
                }
            }
        }
        if (this.AnonBr >= 5) {
            Otkrivane = false;
        }
        let BestAnons: number = 0;
        for (let i = 0; i < this.AnonBr - 1; i++) {
            if (this.Anon[i] % 100 > BestAnons) {
                BestAnons = this.Anon[i] % 100;
            }
        }
        MyLastAnons = this.Anon[this.AnonBr - 1] % 1000;
        //////////////////////////kakvo zna4i otkrivaneto
        if (Otkrivane) {
            if (MyLastAnons === 0) {
                if (BestAnons <= 11) {
                    this.PlayerI[player].MaxPoints = 10;
                    return;
                }
                if (BestAnons <= 21) {
                    this.PlayerI[player].MaxPoints = 11;
                    return;
                }
                if (BestAnons < 35) {
                    this.PlayerI[player].MaxPoints = 14;
                    return;
                }
            }
            if (MyLastAnons === 11 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 11)) {
                this.PlayerI[player].MinPoints = 11;
                this.PlayerI[player].GestPoints = 12;
                this.PlayerI[player].MaxPoints = 14;
                return;
            }
            if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                this.PlayerI[player].MinPoints = 12;
                this.PlayerI[player].GestPoints = 13;
                this.PlayerI[player].MaxPoints = 14;
                this.PlayerI[player].MinBroiKari = 4;
                return;
            }
            if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                this.PlayerI[player].MinPoints = 12;
                this.PlayerI[player].GestPoints = 13;
                this.PlayerI[player].MaxPoints = 14;
                this.PlayerI[player].MinBroiKupi = 4;
                return;
            }
            if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                this.PlayerI[player].MinPoints = 12;
                this.PlayerI[player].GestPoints = 13;
                this.PlayerI[player].MaxPoints = 14;
                this.PlayerI[player].MinBroiPiki = 4;
                return;
            }
            if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                if (BestAnons <= 11) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 16;
                    this.PlayerI[player].MaxPoints = 16;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 17;
                    this.PlayerI[player].MaxPoints = 18;
                    return;
                }
            }
            if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                if (BestAnons <= 11) {
                    this.PlayerI[player].MinPoints = 17;
                    this.PlayerI[player].GestPoints = 18;
                    this.PlayerI[player].MaxPoints = 19;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 12;
                    this.PlayerI[player].GestPoints = 13;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
            }
            if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                if (BestAnons <= 12) {
                    this.PlayerI[player].MinPoints = 17;
                    this.PlayerI[player].GestPoints = 18;
                    this.PlayerI[player].MaxPoints = 19;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 12;
                    this.PlayerI[player].GestPoints = 13;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
            }
            if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                if (BestAnons <= 13) {
                    this.PlayerI[player].MinPoints = 17;
                    this.PlayerI[player].GestPoints = 18;
                    this.PlayerI[player].MaxPoints = 19;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 12;
                    this.PlayerI[player].GestPoints = 13;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
            }
            if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                if (BestAnons <= 14) {
                    this.PlayerI[player].MinPoints = 17;
                    this.PlayerI[player].GestPoints = 18;
                    this.PlayerI[player].MaxPoints = 19;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 12;
                    this.PlayerI[player].GestPoints = 13;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
            }
            if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                if (BestAnons <= 15) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 20;
                    this.PlayerI[player].MaxPoints = 24;
                    return;
                } else {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 17;
                    this.PlayerI[player].MaxPoints = 24;
                    return;
                }
            }
            if (MyLastAnons === 31) {
                this.PlayerI[player].MinBroiSpatii = 6;
                this.PlayerI[player].MinPoints = 5;
                this.PlayerI[player].MaxPoints = 12;
                this.PlayerI[player].GestPoints = 8;
                return;
            }
            if (MyLastAnons === 32) {
                this.PlayerI[player].MinBroiKari = 6;
                this.PlayerI[player].MinPoints = 5;
                this.PlayerI[player].MaxPoints = 12;
                this.PlayerI[player].GestPoints = 8;
                return;
            }
            if (MyLastAnons === 33) {
                this.PlayerI[player].MinBroiKupi = 6;
                this.PlayerI[player].MinPoints = 5;
                this.PlayerI[player].MaxPoints = 12;
                this.PlayerI[player].GestPoints = 8;
                return;
            }
            if (MyLastAnons === 34) {
                this.PlayerI[player].MinBroiPiki = 6;
                this.PlayerI[player].MinPoints = 5;
                this.PlayerI[player].MaxPoints = 12;
                this.PlayerI[player].GestPoints = 8;
                return;
            }
            if (MyLastAnons === 35) {
                if (BestAnons <= 25) {
                    this.PlayerI[player].MinPoints = 25;
                    this.PlayerI[player].MaxPoints = 31;
                    this.PlayerI[player].GestPoints = 25;
                    return;
                }
                if (BestAnons > 25) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].MaxPoints = 31;
                    this.PlayerI[player].GestPoints = 17;
                    return;
                }
            }
            if (MyLastAnons === 41) {
                this.PlayerI[player].MinBroiSpatii = 9;
            }
            if (MyLastAnons === 42) {
                this.PlayerI[player].MinBroiKari = 9;
            }
            if (MyLastAnons === 43) {
                this.PlayerI[player].MinBroiKupi = 9;
            }
            if (MyLastAnons === 44) {
                this.PlayerI[player].MinBroiPiki = 9;
            }
            if (MyLastAnons === 51) {
                this.PlayerI[player].MinBroiSpatii = 11;
            }
            if (MyLastAnons === 52) {
                this.PlayerI[player].MinBroiKari = 11;
            }
            if (MyLastAnons === 65) {
                this.PlayerI[player].MinPoints = 32;
                this.PlayerI[player].MaxPoints = 35;
                this.PlayerI[player].GestPoints = 32;
                return;
            }
        }
        ////////////////////////kakvo zna4i purvia otgovor
        let otgovor1: boolean = true;
        let broianon: number = 0;
        for (let i = 0; i < this.AnonBr - 1; i++) {
            if (player === 1) {
                if (this.Anon[i] < 2000 && this.Anon[i] > 1000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] < 4000 && this.Anon[i] > 3000) {
                    broianon++;
                }
            }
            if (player === 2) {
                if (this.Anon[i] < 3000 && this.Anon[i] > 2000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] < 5000 && this.Anon[i] > 4000) {
                    broianon++;
                }
            }
            if (player === 3) {
                if (this.Anon[i] < 4000 && this.Anon[i] > 3000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] < 2000 && this.Anon[i] > 1000) {
                    broianon++;
                }
            }
            if (player === 4) {
                if (this.Anon[i] < 5000 && this.Anon[i] > 4000) {
                    otgovor1 = false;
                }
                if (this.Anon[i] > 2000 && this.Anon[i] < 3000) {
                    broianon++;
                }
            }
        }
        if (broianon !== 1) {
            otgovor1 = false;
        }
        let Partner = 3;
        if (player === 2) {
            Partner = 4;
        }
        if (player === 4) {
            Partner = 2;
        }
        if (player === 3) {
            Partner = 1;
        }

        if (otgovor1) {
            ////////////az imam Baraj
            if (BestAnons < 15) {
                if (MyLastAnons === 31 && this.PlayerI[Partner].MinBroiSpatii < 4) {
                    this.PlayerI[player].MinPoints = 3;
                    this.PlayerI[player].GestPoints = 6;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiSpatii = 6;
                    return;
                }
                if (MyLastAnons === 41) {
                    this.PlayerI[player].MinBroiSpatii = 8;
                    return;
                }
                if (MyLastAnons === 51) {
                    this.PlayerI[player].MinBroiSpatii = 10;
                    return;
                }
                if (MyLastAnons === 32 && this.PlayerI[Partner].MinBroiKari < 4) {
                    this.PlayerI[player].MinPoints = 3;
                    this.PlayerI[player].GestPoints = 6;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiKari = 6;
                    return;
                }
                if (MyLastAnons === 42) {
                    this.PlayerI[player].MinBroiKari = 8;
                    return;
                }
                if (MyLastAnons === 52) {
                    this.PlayerI[player].MinBroiKari = 10;
                    return;
                }
                if (MyLastAnons === 33 && this.PlayerI[Partner].MinBroiKupi < 4) {
                    this.PlayerI[player].MinPoints = 3;
                    this.PlayerI[player].GestPoints = 6;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiKupi = 6;
                    return;
                }
                if (MyLastAnons === 43) {
                    this.PlayerI[player].MinBroiKupi = 8;
                    return;
                }
                if (MyLastAnons === 34 && this.PlayerI[Partner].MinBroiPiki < 4) {
                    this.PlayerI[player].MinPoints = 3;
                    this.PlayerI[player].GestPoints = 6;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiPiki = 6;
                    return;
                }
                if (MyLastAnons === 44) {
                    this.PlayerI[player].MinBroiPiki = 8;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 8) {
                //otgovor na Baraj
                if (MyLastAnons === 0) {
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 41 && BestAnons > 31) {
                    this.PlayerI[player].MinPoints = 13;
                    this.PlayerI[player].GestPoints = 14;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 51) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 16;
                    this.PlayerI[player].MaxPoints = 18;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 61) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 19;
                    this.PlayerI[player].MaxPoints = 30;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 42 && BestAnons > 32) {
                    this.PlayerI[player].MinPoints = 13;
                    this.PlayerI[player].GestPoints = 14;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 52) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 16;
                    this.PlayerI[player].MaxPoints = 18;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 62) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 19;
                    this.PlayerI[player].MaxPoints = 30;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 43 && BestAnons > 33) {
                    this.PlayerI[player].MinPoints = 13;
                    this.PlayerI[player].GestPoints = 14;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 43) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 16;
                    this.PlayerI[player].MaxPoints = 18;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 63) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 19;
                    this.PlayerI[player].MaxPoints = 30;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 44 && BestAnons > 34) {
                    this.PlayerI[player].MinPoints = 13;
                    this.PlayerI[player].GestPoints = 14;
                    this.PlayerI[player].MaxPoints = 14;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 44) {
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 16;
                    this.PlayerI[player].MaxPoints = 18;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 64) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 19;
                    this.PlayerI[player].MaxPoints = 30;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 12) {
                //otgovor na 1 spatia
                if (MyLastAnons === 0) {
                    if (BestAnons <= 15) {
                        this.PlayerI[player].MaxPoints = 7;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (BestAnons <= 25) {
                        this.PlayerI[player].MaxPoints = 11;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (BestAnons < 41) {
                        this.PlayerI[player].MaxPoints = 13;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                }
                if ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 11) {
                    this.PlayerI[player].MinPoints = 8;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
                if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                    this.PlayerI[player].MinPoints = 8;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
                if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                    this.PlayerI[player].MinPoints = 8;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
                if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                    this.PlayerI[player].MinPoints = 8;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
                if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                    this.PlayerI[player].MinPoints = 11;
                    this.PlayerI[player].GestPoints = 12;
                    this.PlayerI[player].MaxPoints = 12;
                    return;
                }
                if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                    if (BestAnons <= 11) {
                        this.PlayerI[player].MinPoints = 13;
                        this.PlayerI[player].GestPoints = 14;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (BestAnons > 11) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 13;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                }
                if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                    if (BestAnons <= 12) {
                        this.PlayerI[player].MinPoints = 13;
                        this.PlayerI[player].GestPoints = 14;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (BestAnons > 12) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 13;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                }
                if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                    if (BestAnons <= 13) {
                        this.PlayerI[player].MinPoints = 13;
                        this.PlayerI[player].GestPoints = 14;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (BestAnons > 13) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 13;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                }
                if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                    if (BestAnons <= 14) {
                        this.PlayerI[player].MinPoints = 13;
                        this.PlayerI[player].GestPoints = 14;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (BestAnons > 14) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 13;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                }
                if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                    if (BestAnons <= 11) {
                        this.PlayerI[player].MinPoints = 13;
                        this.PlayerI[player].GestPoints = 14;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (BestAnons > 11 && BestAnons <= 25) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 19;
                        return;
                    }
                }
                if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    if (BestAnons <= 25) {
                        this.PlayerI[player].MinPoints = 19;
                        this.PlayerI[player].GestPoints = 19;
                        this.PlayerI[player].MaxPoints = 30;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (BestAnons > 25) {
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 19;
                        return;
                    }
                }
                if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 45)) {
                    this.PlayerI[player].MinPoints = 19;
                    this.PlayerI[player].GestPoints = 19;
                    this.PlayerI[player].MaxPoints = 30;
                    this.PlayerI[player].AskingAsa = true;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 13) {
                //otgovor na 1 boia
                if (this.PlayerI[Partner].MinBroiSpatii === 4) {
                    if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 51 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 51)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiSpatii = 5;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 10;
                        this.PlayerI[player].GestPoints = 11;
                        this.PlayerI[player].MaxPoints = 11;
                        return;
                    }
                    if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                        //niama spatii
                        if (BestAnons > 12) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                        if (BestAnons <= 12) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                        return;
                    }
                    if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                        //niama spatii
                        if (BestAnons > 13) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                        if (BestAnons <= 13) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //niama spatii
                        if (BestAnons > 14) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                        if (BestAnons <= 14) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            return;
                        }
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].CloseAnons = true;
                            return;
                        }
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 19;
                            this.PlayerI[player].GestPoints = 19;
                            this.PlayerI[player].MaxPoints = 29;
                            this.PlayerI[player].AskingAsa = true;
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKari === 4) {
                    if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKari = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 52 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 52)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiKari = 5;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 11 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 11)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                        this.PlayerI[player].MinPoints = 10;
                        this.PlayerI[player].GestPoints = 11;
                        this.PlayerI[player].MaxPoints = 11;
                        return;
                    }
                    if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                        if (BestAnons > 11) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        if (BestAnons <= 11) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        return;
                    }
                    if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                        //niama spatii
                        if (BestAnons > 13) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                        if (BestAnons <= 13) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //niama spatii
                        if (BestAnons > 14) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                        if (BestAnons <= 14) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            return;
                        }
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].CloseAnons = true;
                            return;
                        }
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 19;
                            this.PlayerI[player].GestPoints = 19;
                            this.PlayerI[player].MaxPoints = 29;
                            this.PlayerI[player].AskingAsa = true;
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiKupi === 4) {
                    if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKupi = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 43 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 43)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiKupi = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 11 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 11)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 10;
                        this.PlayerI[player].GestPoints = 11;
                        this.PlayerI[player].MaxPoints = 11;
                        return;
                    }
                    if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                        //niama spatii
                        if (BestAnons > 11) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        if (BestAnons <= 11) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        return;
                    }
                    if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                        //niama spatii
                        if (BestAnons > 12) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                        if (BestAnons <= 12) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //niama spatii
                        if (BestAnons > 14) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                        if (BestAnons <= 14) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiPiki = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            return;
                        }
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].CloseAnons = true;
                            return;
                        }
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 19;
                            this.PlayerI[player].GestPoints = 19;
                            this.PlayerI[player].MaxPoints = 29;
                            this.PlayerI[player].AskingAsa = true;
                            return;
                        }
                    }
                }
                if (this.PlayerI[Partner].MinBroiPiki === 4) {
                    if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiPiki = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 44 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 44)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 11;
                        this.PlayerI[player].GestPoints = 12;
                        this.PlayerI[player].MaxPoints = 18;
                        this.PlayerI[player].MinBroiPiki = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 11 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 11)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 8;
                        this.PlayerI[player].MaxPoints = 9;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 15 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 15)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 10;
                        this.PlayerI[player].GestPoints = 11;
                        this.PlayerI[player].MaxPoints = 11;
                        return;
                    }
                    if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                        //niama spatii
                        if (BestAnons > 11) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        if (BestAnons <= 11) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiSpatii = 4;
                            return;
                        }
                        return;
                    }
                    if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                        //niama spatii
                        if (BestAnons > 12) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                        if (BestAnons <= 12) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKari = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                        //niama spatii
                        if (BestAnons > 13) {
                            this.PlayerI[player].MinPoints = 7;
                            this.PlayerI[player].GestPoints = 8;
                            this.PlayerI[player].MaxPoints = 9;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                        if (BestAnons <= 13) {
                            this.PlayerI[player].MinPoints = 12;
                            this.PlayerI[player].GestPoints = 13;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].MinBroiKupi = 4;
                            return;
                        }
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            return;
                        }
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 10;
                            this.PlayerI[player].GestPoints = 12;
                            this.PlayerI[player].MaxPoints = 18;
                            this.PlayerI[player].CloseAnons = true;
                            return;
                        }
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //niama spatii
                        if (BestAnons >= 15) {
                            this.PlayerI[player].MinPoints = 19;
                            this.PlayerI[player].GestPoints = 19;
                            this.PlayerI[player].MaxPoints = 29;
                            this.PlayerI[player].AskingAsa = true;
                            return;
                        }
                    }
                }
            }
            if (this.PlayerI[Partner].GestPoints === 16) {
                //otgovor na 1 NT
                if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
                if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
                if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
                if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
                if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 9;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 10;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
                if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 10;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
                if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 10;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
                if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 10;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
                if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 10;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 17) {
                //otgovor na 1 NT+
                if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 7;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
                if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 7;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
                if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 7;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
                if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 7;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
                if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 7;
                    this.PlayerI[player].GestPoints = 9;
                    this.PlayerI[player].MaxPoints = 15;
                    this.PlayerI[player].CloseAnons = true;
                    return;
                }
                if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 15;
                    this.PlayerI[player].GestPoints = 15;
                    this.PlayerI[player].MaxPoints = 28;
                    this.PlayerI[player].AskingAsa = true;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 18) {
                //otgovor na 2 boia
                if (this.PlayerI[Partner].MinBroiSpatii === 4) {
                    if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 4;
                        this.PlayerI[player].GestPoints = 5;
                        this.PlayerI[player].MaxPoints = 6;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 51 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 51)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiSpatii = 5;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 15;
                        this.PlayerI[player].GestPoints = 15;
                        this.PlayerI[player].MaxPoints = 28;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 5;
                        this.PlayerI[player].GestPoints = 6;
                        this.PlayerI[player].MaxPoints = 7;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                }
                if (this.PlayerI[Partner].MinBroiKari === 4) {
                    if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 4;
                        this.PlayerI[player].GestPoints = 5;
                        this.PlayerI[player].MaxPoints = 6;
                        this.PlayerI[player].MinBroiKari = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 52 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 52)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 5;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 15;
                        this.PlayerI[player].GestPoints = 15;
                        this.PlayerI[player].MaxPoints = 28;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 5;
                        this.PlayerI[player].GestPoints = 6;
                        this.PlayerI[player].MaxPoints = 7;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                    if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                }
                if (this.PlayerI[Partner].MinBroiKupi === 4) {
                    if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 4;
                        this.PlayerI[player].GestPoints = 5;
                        this.PlayerI[player].MaxPoints = 6;
                        this.PlayerI[player].MinBroiKupi = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 43 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 43)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 15;
                        this.PlayerI[player].GestPoints = 15;
                        this.PlayerI[player].MaxPoints = 28;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 5;
                        this.PlayerI[player].GestPoints = 6;
                        this.PlayerI[player].MaxPoints = 7;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                    if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        return;
                    }
                }
                if (this.PlayerI[Partner].MinBroiPiki === 4) {
                    if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 4;
                        this.PlayerI[player].GestPoints = 5;
                        this.PlayerI[player].MaxPoints = 6;
                        this.PlayerI[player].MinBroiPiki = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 35 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 44 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 44)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 7;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiPiki = 4;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                        //niama spatii
                        this.PlayerI[player].MinPoints = 15;
                        this.PlayerI[player].GestPoints = 15;
                        this.PlayerI[player].MaxPoints = 28;
                        this.PlayerI[player].AskingAsa = true;
                        return;
                    }
                    if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                        //za man6
                        this.PlayerI[player].MinPoints = 5;
                        this.PlayerI[player].GestPoints = 6;
                        this.PlayerI[player].MaxPoints = 7;
                        this.PlayerI[player].CloseAnons = true;
                        return;
                    }
                    if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiSpatii = 4;
                        return;
                    }
                    if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKari = 4;
                        return;
                    }
                    if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                        //ima spatii
                        this.PlayerI[player].MinPoints = 8;
                        this.PlayerI[player].GestPoints = 9;
                        this.PlayerI[player].MaxPoints = 12;
                        this.PlayerI[player].MinBroiKupi = 4;
                        return;
                    }
                }
            }
            if (this.PlayerI[Partner].GestPoints === 20) {
                //otgovor na 2 NT
                if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                    //ima spatii
                    this.PlayerI[player].MinPoints = 5;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiSpatii = 4;
                    return;
                }
                if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
                    //ima spatii
                    this.PlayerI[player].MinPoints = 5;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiKari = 4;
                    return;
                }
                if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
                    //ima spatii
                    this.PlayerI[player].MinPoints = 5;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiKupi = 4;
                    return;
                }
                if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
                    //ima spatii
                    this.PlayerI[player].MinPoints = 5;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 10;
                    this.PlayerI[player].MinBroiPiki = 4;
                    return;
                }
                if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 11;
                    this.PlayerI[player].GestPoints = 11;
                    this.PlayerI[player].MaxPoints = 20;
                    this.PlayerI[player].AskingAsa = true;
                    return;
                }
            }
            if (this.PlayerI[Partner].GestPoints === 25) {
                //otgovor na 3 NT
                if (MyLastAnons === 45 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 35)) {
                    //niama spatii
                    this.PlayerI[player].MinPoints = 6;
                    this.PlayerI[player].GestPoints = 7;
                    this.PlayerI[player].MaxPoints = 20;
                    this.PlayerI[player].AskingAsa = true;
                    return;
                }
            }
        }
        if (MyLastAnons === 0) {
            this.PlayerI[player].CloseAnons = true;
            return;
        }
        if (this.PlayerI[Partner].AskingAsa) {
            if (MyLastAnons === 31 || MyLastAnons === 41 || MyLastAnons === 51) {
                //niama spatii
                this.PlayerI[Partner].AskingAsa = false;
                this.PlayerI[player].BroiAsa = 1;
                return;
            }
            if (MyLastAnons === 32 || MyLastAnons === 42 || MyLastAnons === 52) {
                //niama spatii
                this.PlayerI[Partner].AskingAsa = false;
                this.PlayerI[player].BroiAsa = 2;
                return;
            }
            if (MyLastAnons === 33 || MyLastAnons === 43 || MyLastAnons === 53) {
                //niama spatii
                this.PlayerI[Partner].AskingAsa = false;
                this.PlayerI[player].BroiAsa = 3;
                return;
            }
        }
        if (MyLastAnons > 60) {
            this.PlayerI[player].CloseAnons = true;
            return;
        }
        if (this.Anon[this.AnonBr - 5] === player * 1000 + 11) {
            if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 12;
                this.PlayerI[player].GestPoints = 13;
                this.PlayerI[player].MaxPoints = 14;
                this.PlayerI[player].MinBroiSpatii = 4;
                return;
            }
            if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 12;
                this.PlayerI[player].GestPoints = 13;
                this.PlayerI[player].MaxPoints = 14;
                this.PlayerI[player].MinBroiSpatii = 4;
                return;
            }
            if (MyLastAnons === 25 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 25)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 11;
                this.PlayerI[player].GestPoints = 11;
                this.PlayerI[player].MaxPoints = 11;
                this.PlayerI[player].MinBroiSpatii = 4;
                return;
            }
            if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 11;
                this.PlayerI[player].GestPoints = 11;
                this.PlayerI[player].MaxPoints = 11;
                this.PlayerI[player].MinBroiKari = 4;
                return;
            }
            if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 11;
                this.PlayerI[player].GestPoints = 11;
                this.PlayerI[player].MaxPoints = 11;
                this.PlayerI[player].MinBroiKupi = 4;
                return;
            }
            if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
                //niama spatii
                this.PlayerI[player].MinPoints = 11;
                this.PlayerI[player].GestPoints = 11;
                this.PlayerI[player].MaxPoints = 11;
                this.PlayerI[player].MinBroiPiki = 4;
                return;
            }
        }
        if (MyLastAnons === 12 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 12)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKari === 0) {
                this.PlayerI[player].MinBroiKari = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKari > 0) {
                this.PlayerI[player].MinBroiKari++;
                return;
            }
        }
        if (MyLastAnons === 13 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 13)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKupi === 0) {
                this.PlayerI[player].MinBroiKupi = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKupi > 0) {
                this.PlayerI[player].MinBroiKupi++;
                return;
            }
        }
        if (MyLastAnons === 14 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 14)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiPiki === 0) {
                this.PlayerI[player].MinBroiPiki = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiPiki > 0) {
                this.PlayerI[player].MinBroiPiki++;
                return;
            }
        }
        if (MyLastAnons === 21 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 21)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiSpatii === 0) {
                this.PlayerI[player].MinBroiSpatii = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiSpatii > 0) {
                this.PlayerI[player].MinBroiSpatii++;
                return;
            }
        }
        if (MyLastAnons === 22 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 22)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKari === 0) {
                this.PlayerI[player].MinBroiKari = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKari > 0) {
                this.PlayerI[player].MinBroiKari++;
                return;
            }
        }
        if (MyLastAnons === 23 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 23)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKupi === 0) {
                this.PlayerI[player].MinBroiKupi = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKupi > 0) {
                this.PlayerI[player].MinBroiKupi++;
                return;
            }
        }
        if (MyLastAnons === 24 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 24)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiPiki === 0) {
                this.PlayerI[player].MinBroiPiki = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiPiki > 0) {
                this.PlayerI[player].MinBroiPiki++;
                return;
            }
        }
        if (MyLastAnons === 31 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 31)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiSpatii === 0) {
                this.PlayerI[player].MinBroiSpatii = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiSpatii > 0) {
                this.PlayerI[player].MinBroiSpatii++;
                return;
            }
        }
        if (MyLastAnons === 32 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 32)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKari === 0) {
                this.PlayerI[player].MinBroiKari = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKari > 0) {
                this.PlayerI[player].MinBroiKari++;
                return;
            }
        }
        if (MyLastAnons === 33 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 33)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiKupi === 0) {
                this.PlayerI[player].MinBroiKupi = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiKupi > 0) {
                this.PlayerI[player].MinBroiKupi++;
                return;
            }
        }
        if (MyLastAnons === 34 || ((MyLastAnons === 100 || MyLastAnons === 200) && BestAnons === 34)) {
            //niama spatii
            if (this.PlayerI[player].MinBroiPiki === 0) {
                this.PlayerI[player].MinBroiPiki = 4;
                return;
            }
            if (this.PlayerI[player].MinBroiPiki > 0) {
                this.PlayerI[player].MinBroiPiki++;
                return;
            }
        }
        if (MyLastAnons === 35) {
            this.PlayerI[player].CloseAnons = true;
        }
        if (MyLastAnons === 25) {
            this.PlayerI[player].CloseAnons = true;
        }
        if (MyLastAnons === 43) {
            this.PlayerI[player].CloseAnons = true;
        }
        if (MyLastAnons === 44) {
            this.PlayerI[player].CloseAnons = true;
        }
        if (MyLastAnons === 51) {
            this.PlayerI[player].CloseAnons = true;
        }
        if (MyLastAnons === 52) {
            this.PlayerI[player].CloseAnons = true;
        }
    }

    Timer3() {
        if (!this.Move) {
            if (this.SP) {
                if (this.Faza === 4 || (this.Faza === 6 && !this.Pribirane)) {
                    if (
                        (this.KoiENaRed === 1 && this.P1puska === 0) ||
                        (this.KoiENaRed === 2 && this.P2puska === 0) ||
                        (this.KoiENaRed === 3 && this.P3puska === 0) ||
                        (this.KoiENaRed === 4 && this.P4puska === 0)
                    ) {
                        if (this.Mor === 1) {
                            this.IntelectPlayer(this.KoiENaRed);
                            return;
                        }
                        if ((this.Mor === 2 || this.Mor === 4) && this.KoiENaRed !== 1) {
                            this.IntelectPlayer(this.KoiENaRed);
                            return;
                        }
                        if (this.Mor === 3 && this.KoiENaRed !== 1 && this.KoiENaRed !== 3) {
                            this.IntelectPlayer(this.KoiENaRed);
                            return;
                        }
                    }
                    if (
                        GlobalVariables.automatic_release &&
                        ((this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) ||
                            (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0))
                    ) {
                        this.Pusk1KartaZaPuskanePl1();
                    }
                }
                if (this.Faza === 3) {
                    this.WhoPlay = this.FindWP();
                    if (this.WhoPlay < 4) {
                        this.KoiENaRed = this.WhoPlay + 1;
                    } else {
                        this.KoiENaRed = 1;
                    }
                    this.KoiPuskaParvi = this.KoiENaRed;
                    this.strelka = this.KoiENaRed;
                    this.setState({});
                    for (let i = 1; i < 53; i++) {
                        if (Math.floor((this.k[i] - (this.k[i] % 100)) / 100) === this.LastSays[2]) {
                            this.k[i] = (this.k[i] % 100) + 500;
                        }
                    }
                    this.Faza = 4;
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.Faza === 4 || (this.Faza === 6 && !this.Pribirane)) {
                        if (
                            (this.KoiENaRed === 1 && this.P3OP && this.Mor === 1 && this.P1puska === 0) ||
                            (this.KoiENaRed === 2 && this.P4OP && this.Mor === 2 && this.P2puska === 0) ||
                            (this.KoiENaRed === 4 && this.P2OP && this.Mor === 4 && this.P4puska === 0) ||
                            (this.KoiENaRed === 2 && this.P2OP && this.Mor !== 2 && this.P2puska === 0) ||
                            (this.KoiENaRed === 3 && this.P3OP && this.Mor !== 3 && this.P3puska === 0) ||
                            (this.KoiENaRed === 4 && this.P4OP && this.Mor !== 4 && this.P4puska === 0)
                        ) {
                            if (this.CheckMessage()) {
                                let message = this.ReadMessage();
                                let card = this._ttoi(message);
                                if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                                } else {
                                }
                                return;
                            }
                        } else {
                            if (
                                (this.KoiENaRed === 1 && this.P1puska === 0) ||
                                (this.KoiENaRed === 2 && this.P2puska === 0) ||
                                (this.KoiENaRed === 3 && this.P3puska === 0) ||
                                (this.KoiENaRed === 4 && this.P4puska === 0)
                            ) {
                                if (
                                    this.Mor === 1 ||
                                    ((this.Mor === 2 || this.Mor === 4) && this.KoiENaRed !== 1) ||
                                    (this.Mor === 3 && this.KoiENaRed !== 1 && this.KoiENaRed !== 3)
                                ) {
                                    this.IntelectPlayer(this.KoiENaRed);
                                    return;
                                }
                            }
                        }
                    }
                    if (this.Faza === 3) {
                        this.WhoPlay = this.FindWP();
                        if (this.WhoPlay < 4) {
                            this.KoiENaRed = this.WhoPlay + 1;
                        } else {
                            this.KoiENaRed = 1;
                        }
                        this.KoiPuskaParvi = this.KoiENaRed;
                        this.strelka = this.KoiENaRed;
                        this.setState({});
                        for (let i = 1; i < 53; i++) {
                            if (Math.floor((this.k[i] - (this.k[i] % 100)) / 100) === this.LastSays[2]) {
                                this.k[i] = (this.k[i] % 100) + 500;
                            }
                        }
                        this.Faza = 4;
                        return;
                    }
                    if ((this.Faza === 4 || this.Faza === 6) && !this.Move) {
                        if (
                            (this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) ||
                            (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0)
                        ) {
                            if (this.online_timer <= 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                this.AutoPuskKard();
                            } else if (GlobalVariables.automatic_release) {
                                this.Pusk1KartaZaPuskanePl1();
                            }
                        }
                        return;
                    }
                } else {
                    if ((this.Faza === 4 || this.Faza === 6) && !this.Pribirane) {
                        if (this.watch) {
                            if (
                                ((this.KoiENaRed === 1 && this.P1puska === 0) ||
                                    (this.KoiENaRed === 2 && this.P2puska === 0) ||
                                    (this.KoiENaRed === 3 && this.P3puska === 0) ||
                                    (this.KoiENaRed === 4 && this.P4puska === 0)) &&
                                this.CheckMessage()
                            ) {
                                let card = this._ttoi(this.ReadMessage());
                                if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                                }
                                return;
                            }
                        }
                        if (
                            ((this.KoiENaRed === 1 && this.Mor === 1 && this.P1puska === 0) ||
                                (this.KoiENaRed === 2 && this.P2puska === 0) ||
                                (this.KoiENaRed === 3 && this.Mor !== 3 && this.P3puska === 0) ||
                                (this.KoiENaRed === 4 && this.P4puska === 0)) &&
                            this.CheckMessage()
                        ) {
                            let card = this._ttoi(this.ReadMessage());
                            if (this.PuskaneNaKartaN(card, this.KoiENaRed)) {
                            }
                            return;
                        }
                    }
                    if (this.Faza === 3) {
                        this.WhoPlay = this.FindWP();
                        if (this.WhoPlay < 4) {
                            this.KoiENaRed = this.WhoPlay + 1;
                        } else {
                            this.KoiENaRed = 1;
                        }
                        this.KoiPuskaParvi = this.KoiENaRed;
                        this.strelka = this.KoiENaRed;
                        this.setState({});
                        for (let i = 1; i < 53; i++) {
                            if (Math.floor((this.k[i] - (this.k[i] % 100)) / 100) === this.LastSays[2]) {
                                this.k[i] = (this.k[i] % 100) + 500;
                            }
                        }
                        this.Faza = 4;
                        return;
                    }
                    if ((this.Faza === 4 || this.Faza === 6) && !this.Move) {
                        if (
                            (this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) ||
                            (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0)
                        ) {
                            if (this.online_timer <= 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                this.AutoPuskKard();
                            } else if (GlobalVariables.automatic_release) {
                                this.Pusk1KartaZaPuskanePl1();
                            }
                        }
                        return;
                    }
                }
            }
        }
    }

    ProverkaImaLiKarta(cviat: number, Player: number) {
        for (let i = (Player - 1) * 13 + 1; i <= Player * 13; i++) {
            if (this.k[i] - (this.k[i] % 100) === cviat) {
                return true;
            }
        }
        return false;
    }

    ProverkaCursor(card: number) {
        if (this.TimeVzemane) {
            return false;
        }
        if (this.Move) {
            return false;
        }
        //	if(this.KoiPuskaParvi==1)return true;
        if (this.Faza < 3 || this.Faza > 6) {
            return false;
        }
        if (this.KoiENaRed === 1 && card < 14 && card > 0 && this.Mor !== 1) {
            if (this.ParviPuska === 0) {
                return true;
            } else {
                if (!this.ProverkaImaLiKarta(this.ParviPuska - (this.ParviPuska % 100), 1)) {
                    return true;
                } else {
                    if (this.k[card] - (this.k[card] % 100) === this.ParviPuska - (this.ParviPuska % 100)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
        if (this.KoiENaRed === 3 && card < 40 && card > 26 && this.Mor === 3) {
            if (this.ParviPuska === 0) {
                return true;
            } else {
                if (!this.ProverkaImaLiKarta(this.ParviPuska - (this.ParviPuska % 100), 3)) {
                    return true;
                } else {
                    if (this.k[card] - (this.k[card] % 100) === this.ParviPuska - (this.ParviPuska % 100)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
        return false;
    }

    AutoPuskKard() {
        if (this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) {
            for (let i = 1; i <= 13; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[1] !== 0 && this.k[i] !== 0) {
                        if (this.PuskaneNaKartaN(this.k[i], 1)) {
                            return;
                        }
                    }
                }
            }
        }
        if (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0) {
            for (let i = 27; i <= 39; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[27] !== 0 && this.k[i] !== 0) {
                        if (this.PuskaneNaKartaN(this.k[i], 3)) {
                            return;
                        }
                    }
                }
            }
        }
    }

    Pusk1KartaZaPuskanePl1() {
        let vc = 0;
        let pc = 0;
        if (this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) {
            for (let i = 1; i <= 13; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[1] !== 0 && this.k[i] !== 0) {
                        vc = vc + 1;
                        pc = i;
                    }
                }
            }
        }
        if (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0) {
            for (let i = 27; i <= 39; i++) {
                if (this.ProverkaCursor(i)) {
                    if (this.k[27] !== 0 && this.k[i] !== 0) {
                        vc = vc + 1;
                        pc = i;
                    }
                }
            }
        }
        if (vc === 1 && pc > 0) {
            if (this.ProverkaCursor(pc)) {
                if (this.k[pc] !== 0) {
                    if (this.PuskaneNaKartaN(this.k[pc], this.KoiENaRed)) {
                        return;
                    }
                }
            }
        }
    }

    PuskaneNaKartaN(Num: number, player: number): boolean {
        for (let i = (player - 1) * 13 + 1; i <= player * 13; i++) {
            if (Num === this.k[i]) {
                if (this.AviablePuskaneNaKarta(i, player)) {
                    this.PuskaneNaKarta(i);
                    return true;
                }
            }
        }
        return false;
    }

    PuskaneNaKarta(card: number) {
        let player = 1;
        if (card < 27 && card > 13) {
            player = 2;
        }
        if (card < 40 && card > 26) {
            player = 3;
        }
        if (card < 53 && card > 39) {
            player = 4;
        }
        if (this.k[card] === 0) {
            for (let i = (player - 1) * 13 + 1; i <= player * 13; i++) {
                if (this.AviablePuskaneNaKarta(i, player)) {
                    card = i;
                    break;
                }
            }
        }
        ////////////////////////////az sum go pisal/////////////////////
        if (card < 14 && card > 0) {
            this.CardP[53].image = this.CardP[card].image;
            this.CardP[53].image = this.CardP[card].image;
            this.CardsPoint[53].x = this.CardsPoint[card].x;
            this.CardsPoint[53].y = this.CardsPoint[card].y;
            this.BeginMove(this.CardsPoint[53], this.CardsPos[53], 53);

            this.P1puska = this.k[card];
            if (this.OP && !this.watch) {
                if (this.Server === true) {
                    if ((this.KoiENaRed === 1 && this.Mor !== 1) || !this.P3OP) {
                        if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                            this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
                        }
                    }
                } else {
                    if (this.KoiENaRed === 1 && this.Mor !== 1) {
                        this.props.m_pwin.SendGame('$|l|' + this.IntToString(this.k[card]));
                    }
                }
                if (
                    (this.type === 2 && this.BroiIgri === 1) ||
                    (this.type === 1 && (this.rubber_result.BroiUnderResult === 0 || this.rubber_result.BroiUpResult === 0))
                ) {
                    if (!this.op_start) {
                        this.props.m_pwin.SendGame('L');
                        this.op_start = true;
                    }
                }
            }
            if (this.KoiPuskaParvi !== 2) {
                this.KoiENaRed = 2;
                this.strelka = 2;
            } else {
                this.hand1[this.hand] = 2 - (this.Strelka() % 2);
                this.hand++;
                this.strelka = this.Strelka();
                this.KoiENaRed = this.Strelka();
                this.KoiPuskaParvi = this.Strelka();
            }
            if (this.KoiPuskaParvi === 1) {
                this.ParviPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 2) {
                this.ChetvartiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 3) {
                this.TretiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 4) {
                this.VtoriPuska = this.k[card];
            }
            if (this.Mor === 1) {
                this.RefreshMor = true;
            }
        }
        if (card < 27 && card > 13) {
            this.CardsPoint[54].x = this.CardsPoint[card].x;
            this.CardsPoint[54].y = this.CardsPoint[card].y;
            this.CardP[54].image = this.CardP[card].image;
            this.BeginMove(this.CardsPoint[54], this.CardsPos[54], 54);
            this.P2puska = this.k[card];
            if (this.OP && !this.watch) {
                if (this.Server === true) {
                    if ((this.P2OP && !this.P4OP && this.Mor === 2) || (!this.P2OP && (!this.P4OP || (this.P4OP && this.Mor !== 2)))) {
                        if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                            this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
                        }
                    }
                }
            }
            if (this.KoiPuskaParvi !== 3) {
                this.KoiENaRed = 3;
                this.strelka = 3;
            } else {
                this.hand1[this.hand] = 2 - (this.Strelka() % 2);
                this.hand++;
                this.strelka = this.Strelka();
                this.KoiENaRed = this.Strelka();
                this.KoiPuskaParvi = this.Strelka();
            }
            if (this.KoiPuskaParvi === 1) {
                this.VtoriPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 2) {
                this.ParviPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 3) {
                this.ChetvartiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 4) {
                this.TretiPuska = this.k[card];
            }
            if (this.Mor === 2) {
                this.RefreshMor = true;
            }
        }
        if (card < 40 && card > 26) {
            this.CardsPoint[55].x = this.CardsPoint[card].x;
            this.CardsPoint[55].y = this.CardsPoint[card].y;
            this.CardP[55].image = this.CardP[card].image;
            this.BeginMove(this.CardsPoint[55], this.CardsPos[55], 55);
            this.P3puska = this.k[card];
            if (this.OP && !this.watch) {
                if (this.Server === true) {
                    if (!this.P3OP || this.Mor === 3) {
                        if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                            this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
                        }
                    }
                } else {
                    if (this.Mor === 3) {
                        this.props.m_pwin.SendGame('$|l|' + this.IntToString(this.k[card]));
                    }
                }
            }
            if (this.KoiPuskaParvi !== 4) {
                this.KoiENaRed = 4;
                this.strelka = 4;
            } else {
                this.hand1[this.hand] = 2 - (this.Strelka() % 2);
                this.hand++;
                this.strelka = this.Strelka();
                this.KoiENaRed = this.Strelka();
                this.KoiPuskaParvi = this.Strelka();
            }
            if (this.KoiPuskaParvi === 1) {
                this.TretiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 2) {
                this.VtoriPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 3) {
                this.ParviPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 4) {
                this.ChetvartiPuska = this.k[card];
            }
            if (this.Mor === 3) {
                this.RefreshMor = true;
            }
        }
        if (card < 53 && card > 39) {
            this.CardsPoint[56].x = this.CardsPoint[card].x;
            this.CardsPoint[56].y = this.CardsPoint[card].y;
            this.CardP[56].image = this.CardP[card].image;
            this.BeginMove(this.CardsPoint[56], this.CardsPos[56], 56);
            this.P4puska = this.k[card];
            if (this.OP && !this.watch) {
                if (this.Server === true) {
                    if ((this.P4OP && !this.P2OP && this.Mor === 4) || (!this.P4OP && (!this.P2OP || (this.P2OP && this.Mor !== 4)))) {
                        if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                            this.props.m_pwin.SendGame('$|k|' + this.IntToString(this.k[card]));
                        }
                    }
                }
            }
            if (this.KoiPuskaParvi !== 1) {
                this.KoiENaRed = 1;
                this.strelka = 1;
            } else {
                this.hand1[this.hand] = 2 - (this.Strelka() % 2);
                this.hand++;
                this.strelka = this.Strelka();
                this.KoiENaRed = this.Strelka();
                this.KoiPuskaParvi = this.Strelka();
            }
            if (this.KoiPuskaParvi === 1) {
                this.ChetvartiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 2) {
                this.TretiPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 3) {
                this.VtoriPuska = this.k[card];
            }
            if (this.KoiPuskaParvi === 4) {
                this.ParviPuska = this.k[card];
            }
            if (this.Mor === 4) {
                this.RefreshMor = true;
            }
        }
        this.props.m_pwin.KillTimer(3);
        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
        this.DeleteCard(card);
        this.setState({});
        this.props.m_pwin.SetCursor();
        this.online_timer = this.online_timer_time;
    }

    DeleteCard(card: number) {
        if (card > 0 && card < 14) {
            for (let i = card; i < 13; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 13; i > 0; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 13 && card < 27) {
            for (let i = card; i < 26; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 26; i > 13; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 26 && card < 40) {
            for (let i = card; i < 39; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 39; i > 26; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
        if (card > 39 && card < 53) {
            for (let i = card; i < 52; i++) {
                if (this.k[i + 1] !== 0) {
                    this.k[i] = this.k[i + 1];
                    this.CardP[i].image = this.CardP[i + 1].image;
                }
            }
            for (let i = 52; i > 39; i--) {
                if (this.k[i] !== 0) {
                    this.k[i] = 0;
                    return;
                }
            }
        }
    }

    Strelka() {
        if (this.P1puska > 500 || this.P2puska > 500 || this.P3puska > 500 || this.P4puska > 500) {
            if (this.P1puska > this.P2puska && this.P1puska > this.P3puska && this.P1puska > this.P4puska) {
                return 1;
            }
            if (this.P2puska > this.P1puska && this.P2puska > this.P3puska && this.P2puska > this.P4puska) {
                return 2;
            }
            if (this.P3puska > this.P2puska && this.P3puska > this.P1puska && this.P3puska > this.P4puska) {
                return 3;
            }
            if (this.P4puska > this.P2puska && this.P4puska > this.P3puska && this.P4puska > this.P1puska) {
                return 4;
            }
        }
        if (this.ParviPuska > 400 && this.ParviPuska < 500) {
            for (let i = 420; i > 400; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
                if (this.P4puska === i) {
                    return 4;
                }
            }
        }
        //Ako se iska kupa
        if (this.ParviPuska > 300 && this.ParviPuska < 400) {
            for (let i = 320; i > 300; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
                if (this.P4puska === i) {
                    return 4;
                }
            }
        }
        //Ako se iska karo
        if (this.ParviPuska > 200 && this.ParviPuska < 300) {
            for (let i = 220; i > 200; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
                if (this.P4puska === i) {
                    return 4;
                }
            }
        }
        //Ako se iska spatia
        if (this.ParviPuska > 100 && this.ParviPuska < 200) {
            for (let i = 120; i > 100; i--) {
                if (this.P1puska === i) {
                    return 1;
                }
                if (this.P2puska === i) {
                    return 2;
                }
                if (this.P3puska === i) {
                    return 3;
                }
                if (this.P4puska === i) {
                    return 4;
                }
            }
        }
        return 1;
    }

    AviablePuskaneNaKarta(card: number, player: number) {
        if (this.ParviPuska === 0) {
            if (this.k[card] === 0) {
                return false;
            }
            return true;
        } else {
            let HaveCard: boolean = false;
            if (this.k[card] === 0) {
                return false;
            }
            for (let i = (player - 1) * 13 + 1; i <= player * 13; i++) {
                if (Math.floor(this.ParviPuska / 100) === Math.floor(this.k[i] / 100)) {
                    HaveCard = true;
                }
            }
            if (HaveCard) {
                if (Math.floor(this.ParviPuska / 100) === Math.floor(this.k[card] / 100)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
    }

    IntelectPlayer(player: number) {
        if (player === 1 || player === 3) {
            if (this.Mor === 1 || this.Mor === 3) {
                this.IntelectMakeAnons(player);
                return;
            } else {
                this.IntelectDefending(player);
                return;
            }
        }
        if (player === 2 || player === 4) {
            if (this.Mor === 2 || this.Mor === 4) {
                this.IntelectMakeAnons(player);
                return;
            } else {
                this.IntelectDefending(player);
                return;
            }
        }
    }

    FindCardIndex(card: number) {
        for (let i = 0; i < 53; i++) {
            if (this.k[i] === card) {
                return i;
            }
        }
        return 0;
    }

    Chistene(player: number) {
        let NeIgrani = []; //[6][15];//koi karti ne sa igrani
        let CARDS = []; //[6][14][2];//kartite koito sa ostanali podredeni po golemina
        for (let i = 0; i < 6; i++) {
            NeIgrani[i] = [];
            CARDS[i] = [];
            for (let j = 0; j < 14; j++) {
                CARDS[i][j] = [];
            }
        }

        for (let i = 0; i < 15; i++) {
            for (let j = 0; j < 6; j++) {
                NeIgrani[j][i] = false;
            }
        }
        for (let i = 0; i <= 52; i++) {
            if (this.k[i] > 0) {
                NeIgrani[Math.floor(this.k[i] / 100)][this.k[i] % 100] = true;
            }
        }
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < 14; j++) {
                CARDS[i][j][0] = 0;
                CARDS[i][j][1] = 0;
            }
            CARDS[i][0][0] = 1;
        }
        for (let i = 1; i <= 5; i++) {
            for (let j = 14; j > 0; j--) {
                if (NeIgrani[i][j]) {
                    CARDS[i][CARDS[i][0][0]][0] = i * 100 + j;
                    CARDS[i][CARDS[i][0][0]][1] = Math.floor((this.FindCardIndex(i * 100 + j) - 1) / 13) + 1;
                    CARDS[i][0][0]++;
                }
            }
        }
        let WhatPlay: number = this.LastSays[2];
        let BroiBoia: number[] = [0, 0, 0, 0, 0, 0];
        let BroiPlayer: number[] = [0, 0, 0, 0, 0, 0];
        let BroiPartner: number[] = [0, 0, 0, 0, 0, 0];
        let Potential: number[] = [0, 0, 0, 0, 0, 0];
        let Partner: number;
        let Oponent: number[] = [];
        let CardsForCleaning: number[] = [0, 0, 0, 0, 0, 0];
        if (player === 1) {
            Partner = 3;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 2) {
            Partner = 4;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        if (player === 3) {
            Partner = 1;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 4) {
            Partner = 2;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        for (let i = 1; i < 6; i++) {
            for (let j = 1; j < 14; j++) {
                if (CARDS[i][j][0] > 0) {
                    BroiBoia[i]++;
                    if (CARDS[i][j][1] === player) {
                        BroiPlayer[i]++;
                    }
                    if (CARDS[i][j][1] === Partner) {
                        BroiPartner[i]++;
                    }
                }
            }
        }
        for (let i = 1; i < 6; i++) {
            for (let j = 13; j > 0; j--) {
                if (CARDS[i][j][0] > 0 && CARDS[i][j][1] === player) {
                    CardsForCleaning[i] = CARDS[i][j][0];
                    break;
                }
            }
        }
        for (let i = 1; i < 6; i++) {
            let BOC = 0;
            for (let j = 0; j < 14; j++) {
                if (CARDS[i][j][0] > CardsForCleaning[i] && (CARDS[i][j][1] === Oponent[1] || CARDS[i][j][1] === Oponent[2])) {
                    BOC++;
                }
            }
            if (CARDS[i][1][0] === CardsForCleaning[i]) {
                if (BroiBoia[i] > 9) {
                    Potential[i] += 60;
                }
                if (BroiBoia[i] >= 5 && BroiBoia[i] <= 8) {
                    Potential[i] += 58;
                }
                if (BroiBoia[i] < 5 && BroiBoia[i] > 2) {
                    Potential[i] += 55;
                }
                if (BroiBoia[i] === 1 || BroiBoia[i] === 2) {
                    Potential[i] += 50;
                }
            }
            if (CARDS[i][2][0] === CardsForCleaning[i]) {
                if (BroiBoia[i] > 9) {
                    if (BOC === 0) {
                        Potential[i] += 45;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] > 1) {
                            Potential[i] += 5;
                        }
                        Potential[i] += 35;
                    }
                }
                if (BroiBoia[i] >= 5 && BroiBoia[i] <= 8) {
                    if (BOC === 0) {
                        Potential[i] += 45;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] > 1) {
                            Potential[i] += 5;
                        }
                        Potential[i] += 25;
                    }
                }
                if (BroiBoia[i] < 5 && BroiBoia[i] > 2) {
                    if (BOC === 0) {
                        Potential[i] += 40;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] > 1) {
                            Potential[i] += 7;
                        }
                        Potential[i] += 20;
                    }
                }
                if (BroiBoia[i] === 1 || BroiBoia[i] === 2) {
                    if (BOC === 0 && (BroiPartner[i] + BroiPlayer[i] > 2 || BroiPlayer[i] > 1)) {
                        Potential[i] += 30;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] > 1) {
                            Potential[i] += 5;
                        }
                        Potential[i] += 10;
                    }
                }
            }
            if (CARDS[i][3][0] === CardsForCleaning[i]) {
                if (BroiBoia[i] > 9) {
                    if (BOC === 0) {
                        Potential[i] += 25;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 3;
                        }
                        Potential[i] += 20;
                    }
                    if (BOC === 2) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 3;
                        }
                        Potential[i] += 15;
                    }
                }
                if (BroiBoia[i] >= 5 && BroiBoia[i] <= 8) {
                    if (BOC === 0) {
                        Potential[i] += 15;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 3;
                        }
                        Potential[i] += 10;
                    }
                    if (BOC === 2) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 3;
                        }
                        Potential[i] += 5;
                    }
                }
                if (BroiBoia[i] < 5 && BroiBoia[i] > 2) {
                    if (BOC === 0) {
                        Potential[i] += 5;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 3;
                        }
                    }
                }
            }
            if (CARDS[i][4][0] === CardsForCleaning[i]) {
                if (BroiBoia[i] > 9) {
                    if (BOC === 0) {
                        Potential[i] += 8;
                    }
                    if (BOC === 1) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 2;
                        }
                    }
                    if (BOC === 2) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 5;
                        }
                    }
                }
                if (BroiBoia[i] >= 5 && BroiBoia[i] <= 8) {
                    if (BOC === 1) {
                        if (BroiPlayer[i] >= 2) {
                            Potential[i] += 6;
                        }
                    }
                }
                if (BroiBoia[i] < 5 && BroiBoia[i] > 2) {
                    if (BOC === 0) {
                        Potential[i] += 5;
                    }
                    if (BOC === 1) {
                        Potential[i] += 3;
                    }
                }
            }
            if (CARDS[i][4][0] > CardsForCleaning[i]) {
                //slabi(za pokrivane na oniorite ili za duljina)
                if (BroiBoia[i] > 9) {
                    if (CARDS[i][3][1] === player) {
                        Potential[i] += 7;
                    }
                    if (CARDS[i][2][1] === player) {
                        Potential[i] += 12;
                    }
                    if (CARDS[i][1][1] === Partner) {
                        Potential[i] += 8;
                    }
                }
                if (BroiBoia[i] >= 5 && BroiBoia[i] <= 8) {
                    if (CARDS[i][3][1] === player) {
                        Potential[i] += 7;
                    }
                    if (CARDS[i][2][1] === player) {
                        Potential[i] += 12;
                    }
                    if (CARDS[i][1][1] === Partner) {
                        Potential[i] += 5;
                    }
                }
            }
            if (BroiPlayer[i] > BroiPartner[i]) {
                if (CARDS[i][3][1] === Partner) {
                    Potential[i] += 2;
                }
                if (CARDS[i][2][1] === Partner) {
                    Potential[i] += 4;
                }
                if (CARDS[i][1][1] === Partner) {
                    Potential[i] += 7;
                }
                Potential[i] += BroiPlayer[i] - Math.floor((BroiBoia[i] - (BroiPlayer[i] + BroiPartner[i])) / 2);
            }
        }
        for (let i = 1; i < 5; i++) {
            if (BroiPlayer[i] === 0) {
                Potential[i] = 10000;
            }
            if (WhatPlay < 5 && BroiPlayer[i] <= 2) {
                Potential[i] -= 5;
            }
        }
        Potential[5] += 3000;

        if (
            BroiPlayer[1] > 0 &&
            Potential[1] <= Potential[2] &&
            Potential[1] <= Potential[3] &&
            Potential[1] <= Potential[4] &&
            Potential[1] <= Potential[5]
        ) {
            for (let i = 13; i > 0; i--) {
                if (CARDS[1][i][1] === player) {
                    if (this.PuskaneNaKartaN(CARDS[1][i][0], player)) {
                        return;
                    }
                }
            }
        }
        if (
            BroiPlayer[2] > 0 &&
            Potential[2] <= Potential[1] &&
            Potential[2] <= Potential[3] &&
            Potential[2] <= Potential[4] &&
            Potential[2] <= Potential[5]
        ) {
            for (let i = 13; i > 0; i--) {
                if (CARDS[2][i][1] === player) {
                    if (this.PuskaneNaKartaN(CARDS[2][i][0], player)) {
                        return;
                    }
                }
            }
        }
        if (
            BroiPlayer[3] > 0 &&
            Potential[3] <= Potential[2] &&
            Potential[3] <= Potential[1] &&
            Potential[3] <= Potential[4] &&
            Potential[3] <= Potential[5]
        ) {
            for (let i = 13; i > 0; i--) {
                if (CARDS[3][i][1] === player) {
                    if (this.PuskaneNaKartaN(CARDS[3][i][0], player)) {
                        return;
                    }
                }
            }
        }
        if (
            BroiPlayer[4] > 0 &&
            Potential[4] <= Potential[2] &&
            Potential[4] <= Potential[3] &&
            Potential[4] <= Potential[1] &&
            Potential[4] <= Potential[5]
        ) {
            for (let i = 13; i > 0; i--) {
                if (CARDS[4][i][1] === player) {
                    if (this.PuskaneNaKartaN(CARDS[4][i][0], player)) {
                        return;
                    }
                }
            }
        }
        if (
            BroiPlayer[5] > 0 &&
            Potential[5] <= Potential[2] &&
            Potential[5] <= Potential[3] &&
            Potential[5] <= Potential[1] &&
            Potential[5] <= Potential[4]
        ) {
            for (let i = 13; i > 0; i--) {
                if (CARDS[5][i][1] === player) {
                    if (this.PuskaneNaKartaN(CARDS[5][i][0], player)) {
                        return;
                    }
                }
            }
        }
    }

    IntelectDefending(player: number) {
        let WhatPlay: number = this.LastSays[2]; //1-5//
        let Whose: number = 2 - (this.LastSays[0] % 2);
        let GetHands: number = 0;
        for (
            let i = 0;
            i < this.hand;
            i++ //smaita broia na vzetite ruce
        ) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        let BroiOstavashti: number[] = [0, 0, 0, 0, 0, 0]; //1-4 broia ostava6ti karti v vsiaka boia
        let NeIgrani = []; //koi karti na sa igrani
        let BestCards = []; //[6][14][2] kartite oito sa ostanali podredeni po golemina
        for (let j = 0; j < 6; j++) {
            NeIgrani[j] = [];
            BestCards[j] = [];
            for (let i = 0; i < 15; i++) {
                BestCards[j][i] = [];
                NeIgrani[j][i] = false;
            }
        }
        for (let i = 0; i <= 52; i++) {
            if (this.k[i] > 0) {
                NeIgrani[Math.floor(this.k[i] / 100)][this.k[i] % 100] = true;
            }
        }

        for (let i = 0; i < 15; i++) {
            for (let j = 0; j < 6; j++) {
                if (NeIgrani[j][i]) {
                    BroiOstavashti[j]++;
                }
            }
        }
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < 15; j++) {
                BestCards[i][j][0] = 0;
                BestCards[i][j][1] = 0;
            }
            BestCards[i][0][0] = 1;
        }
        for (let i = 1; i <= 5; i++) {
            for (let j = 14; j > 0; j--) {
                if (NeIgrani[i][j]) {
                    BestCards[i][BestCards[i][0][0]][0] = i * 100 + j;
                    BestCards[i][BestCards[i][0][0]][1] = Math.floor((this.FindCardIndex(i * 100 + j) - 1) / 13) + 1;
                    BestCards[i][0][0]++;
                }
            }
        }
        let Oponent: number[] = [];
        let Partner: number = 0; //opredelia koi e partniora
        if (player === 1) {
            Partner = 3;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 2) {
            Partner = 4;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        if (player === 3) {
            Partner = 1;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 4) {
            Partner = 2;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        let BroiPlayer: number[] = [0, 0, 0, 0, 0, 0];
        let BroiMor: number[] = [0, 0, 0, 0, 0, 0]; //po kolko karti v vseki cviat ima partniora i playera
        for (let i = (player - 1) * 13 + 1; i <= player * 13; i++) {
            if (this.k[i] !== 0) {
                BroiPlayer[Math.floor(this.k[i] / 100)]++;
            }
        }
        for (let i = (this.Mor - 1) * 13 + 1; i <= this.Mor * 13; i++) {
            if (this.k[i] !== 0) {
                BroiMor[Math.floor(this.k[i] / 100)]++;
            }
        }
        let Niama = [];
        for (let j = 0; j < 6; j++) {
            Niama[j] = [];
        }
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 6; j++) {
                Niama[i][j] = false;
            }
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////Igra Na Bez Koz//////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////
        if (WhatPlay === 5) {
            if (this.ParviPuska === 0) {
                //kogato igrae purvi
                let MoraESledMen: boolean = false;
                if (player === 1 && this.Mor === 2) {
                    MoraESledMen = true;
                }
                if (player === 2 && this.Mor === 3) {
                    MoraESledMen = true;
                }
                if (player === 3 && this.Mor === 4) {
                    MoraESledMen = true;
                }
                if (player === 4 && this.Mor === 1) {
                    MoraESledMen = true;
                }
                let BroiSureHands: number = 0; //tursim sigurnite ruce
                let SurePlayer: number[] = [0, 0, 0, 0, 0];
                for (let i = 1; i <= 4; i++) {
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] !== 0) {
                            if (BestCards[i][j][1] === player) {
                                if (BestCards[i][j][1] === player) {
                                    SurePlayer[i]++;
                                }
                            } else {
                                break;
                            }
                        }
                    }
                    BroiSureHands += SurePlayer[i];
                }
                if (BroiSureHands + (this.hand - GetHands) > 7 - this.level || BroiSureHands === 13 - this.hand) {
                    for (let i = 1; i <= 4; i++) {
                        if (SurePlayer[i] > 0) {
                            for (let j = 1; j < 15; j++) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                for (
                    let i = 1;
                    i <= 4;
                    i++ //ako ima cviat da raz4ita si go vzima
                ) {
                    if (
                        SurePlayer[i] >= 3 ||
                        (SurePlayer[i] >= Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiMor[i])) / 2) && SurePlayer[i] >= BroiMor[i])
                    ) {
                        for (let j = 1; j < 15; j++) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (
                    let i = 0;
                    i < this.AnonBr;
                    i++ //atakuva ako parta mu e kazal nekva boia
                ) {
                    if (Math.floor(this.Anon[i] / 1000) === Partner && this.Anon[i] % 1000 !== 0) {
                        if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                            if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                    return;
                                }
                            }
                            if (
                                BestCards[this.Anon[i] % 10][1][1] !== player &&
                                (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                BroiPlayer[i] <= 2
                            ) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                let WhoP;
                if (this.Mor === 1) {
                    WhoP = 3;
                }
                if (this.Mor === 2) {
                    WhoP = 4;
                }
                if (this.Mor === 3) {
                    WhoP = 1;
                }
                if (this.Mor === 4) {
                    WhoP = 2;
                }
                if (this.hand === 0) {
                    //kogato atakuva mora//ATAKUVA SILNOTO na mora ili podava na parta
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === this.Mor && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                if (
                                    BestCards[this.Anon[i] % 10][1][1] !== player &&
                                    (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                    BroiPlayer[i] <= 2
                                ) {
                                    for (
                                        let j = 14;
                                        j > 0;
                                        j-- //???BestCards e ot 0 do 13
                                    ) {
                                        if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === WhoP && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                for (let k = 1; k <= 4; k++) {
                                    if (k !== this.Anon[i] % 10) {
                                        if (
                                            BestCards[k][1][1] !== player &&
                                            (BestCards[k][2][1] !== player || BestCards[k][2][1] !== player) &&
                                            BroiPlayer[i] <= 2
                                        ) {
                                            for (let j = 14; j > 0; j--) {
                                                if (BestCards[k][j][1] === player) {
                                                    if (this.PuskaneNaKartaN(BestCards[k][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i <= 4; i++) {
                        if (BroiPlayer[i] >= 5) {
                            if (BestCards[i][1][1] === player && BestCards[i][2][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[1][1][0], player)) {
                                    return;
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i <= 4; i++) {
                        if (
                            BroiPlayer[i] >= BroiPlayer[1] &&
                            BroiPlayer[i] >= BroiPlayer[2] &&
                            BroiPlayer[i] >= BroiPlayer[3] &&
                            BroiPlayer[i] >= BroiPlayer[4]
                        ) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.hand > 0) {
                    //ina4e//atakuva silnoto ili slaboto na mora
                    for (let i = 0; i < 4; i++) {
                        if ((BroiMor[i] <= 1 || (BestCards[i][2][1] !== this.Mor && BestCards[i][3][1] !== this.Mor)) && !MoraESledMen) {
                            //slaboto
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (
                            ((BestCards[i][1][1] === this.Mor && BroiOstavashti[i] - BroiMor[i] >= 4) ||
                                (BestCards[i][2][1] === this.Mor && (BroiOstavashti[i] - BroiMor[i] >= 6 || BestCards[i][1][1] !== this.Mor))) &&
                            MoraESledMen
                        ) {
                            //slaboto
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 1;
                        i <= 4;
                        i++ //TURSI AKO IMA RUCE OT DULJINA
                    ) {
                        if (BroiPlayer[i] > Math.floor((BroiOstavashti[i] - BroiPlayer[i] - BroiMor[i]) / 2) && BroiPlayer[i] > BroiMor[i]) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }

                    for (let i = 1; i <= 4; i++) {
                        if (
                            BroiPlayer[i] >= BroiPlayer[1] &&
                            BroiPlayer[i] >= BroiPlayer[2] &&
                            BroiPlayer[i] >= BroiPlayer[3] &&
                            BroiPlayer[i] >= BroiPlayer[4]
                        ) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                this.PuskaneNaKarta((player - 1) * 13 + 1);
                return;
            }
            if (this.ParviPuska > 0) {
                if (this.VtoriPuska === 0) {
                    //kogato e vtori nared
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        //mora atakuva
                        if (
                            (player === 1 && this.Mor === 4) ||
                            (player === 2 && this.Mor === 1) ||
                            (player === 3 && this.Mor === 2) ||
                            (player === 4 && this.Mor === 3)
                        ) {
                            //mora e igral
                            let BestOtherCard = 0;
                            let SecOtherCard = 0;
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player
                                ) {
                                    BestOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestOtherCard
                                ) {
                                    SecOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                ) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestOtherCard) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                ) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecOtherCard) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        } //mora e sled men
                        else {
                            let BestMorCard = 0;
                            let SecMorCard = 0;
                            for (let j = 1; j < 14; j++) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                    BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                ) {
                                    BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                ) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                ) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        this.Chistene(player);
                        return;
                    }
                }
                ////////////////////////////////////////////////////////////////
                if (this.TretiPuska === 0 && this.VtoriPuska > 0) {
                    //kogato e treti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        //ako mora e predi nego
                        if (
                            (player === 1 && this.Mor === 4) ||
                            (player === 2 && this.Mor === 1) ||
                            (player === 3 && this.Mor === 2) ||
                            (player === 4 && this.Mor === 3)
                        ) {
                            //mora e igral
                            let BCParviPuska = 0;
                            let BestMyCard = 0;
                            let SecMyCard = 0;
                            let BCBestMyCard = 0;
                            let BCSecMyCard = 0;
                            for (let j = 1; j < 14; j++) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                                ) {
                                    SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let i = 1; i < 14; i++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                ) {
                                    BCBestMyCard++;
                                }
                            }
                            for (let i = 1; i < 14; i++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                ) {
                                    BCSecMyCard++;
                                }
                            }
                            for (let i = 1; i < 14; i++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                ) {
                                    BCParviPuska++;
                                }
                            }
                            if (Math.floor(this.ParviPuska / 100) === Math.floor(this.VtoriPuska / 100) && this.VtoriPuska > this.ParviPuska) {
                                if (SecMyCard > this.VtoriPuska && BCSecMyCard === BCBestMyCard) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BestMyCard > this.VtoriPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (BCBestMyCard < BCParviPuska) {
                                if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                    return;
                                }
                            }
                        } //mora e nego
                        else {
                            let BestMorCard = 0,
                                SecMorCard = 0,
                                ThirMorCard = 0;
                            for (let j = 1; j < 14; j++) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                    BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                ) {
                                    BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 1; j < 14; j++) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMorCard
                                ) {
                                    ThirMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                    break;
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                            this.VtoriPuska > this.ParviPuska
                                        ) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                            this.VtoriPuska > this.ParviPuska
                                        ) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard && SecMorCard > 0) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                            this.VtoriPuska > this.ParviPuska
                                        ) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard && ThirMorCard > 0) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (BestMorCard > this.ParviPuska) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (BestMorCard > this.ParviPuska) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard && this.ParviPuska < SecMorCard) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (
                                        BestMorCard > this.ParviPuska ||
                                        (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                    ) {
                                        if (BestMorCard > this.ParviPuska) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                if (
                                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard &&
                                                    this.ParviPuska < ThirMorCard
                                                ) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        this.Chistene(player);
                        return;
                    }
                }
                if (this.ChetvartiPuska === 0 && this.TretiPuska > 0) {
                    //kogato e 4etvarti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (
                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                            this.VtoriPuska % 100 > this.ParviPuska % 100 &&
                            (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100) || this.TretiPuska % 100 < this.VtoriPuska % 100)
                        ) {
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            for (let i = 14; i > 0; i--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.TretiPuska
                                ) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    } else {
                        this.Chistene(player);
                        return;
                    }
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////Igra Na Koz/////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////
        if (WhatPlay >= 1 && WhatPlay <= 4) {
            let MoraESledMen = false;
            if (player === 1 && this.Mor === 2) {
                MoraESledMen = true;
            }
            if (player === 2 && this.Mor === 3) {
                MoraESledMen = true;
            }
            if (player === 3 && this.Mor === 4) {
                MoraESledMen = true;
            }
            if (player === 4 && this.Mor === 1) {
                MoraESledMen = true;
            }
            let WhoP;
            if (this.Mor === 1) {
                WhoP = 3;
            }
            if (this.Mor === 2) {
                WhoP = 4;
            }
            if (this.Mor === 3) {
                WhoP = 1;
            }
            if (this.Mor === 4) {
                WhoP = 2;
            }
            if (this.ParviPuska === 0) {
                //kogato igrae purvi
                let BroiSureHands = 0; //tursim sigurnite ruce
                let SurePlayer: number[] = [0, 0, 0, 0, 0, 0];
                for (let i = 1; i <= 5; i++) {
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] !== 0) {
                            if (BestCards[i][j][1] === player) {
                                if (BestCards[i][j][1] === player) {
                                    SurePlayer[i]++;
                                }
                            } else {
                                break;
                            }
                        }
                    }
                    BroiSureHands += SurePlayer[i];
                }
                if (BroiOstavashti[5] === 0) {
                    //ako sa svu6ili kozovete-igrae kato bez koz
                    if (BroiSureHands + (this.hand - GetHands) > 7 - this.level || BroiSureHands === 13 - this.hand) {
                        for (let i = 1; i <= 4; i++) {
                            if (SurePlayer[i] > 0) {
                                for (let j = 1; j < 15; j++) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 1;
                        i <= 4;
                        i++ //ako ima cviat da raz4ita si go vzima
                    ) {
                        if (
                            SurePlayer[i] >= 3 ||
                            (SurePlayer[i] >= Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiMor[i])) / 2) && SurePlayer[i] >= BroiMor[i])
                        ) {
                            for (let j = 1; j < 15; j++) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva ako parta mu e kazal nekva boia
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === Partner && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                if (
                                    BestCards[this.Anon[i] % 10][1][1] !== player &&
                                    (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                    BroiPlayer[i] <= 2
                                ) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (this.hand === 0) {
                        //kogato atakuva mora//ATAKUVA SILNOTO na mora ili podava na parta
                        for (
                            let i = 0;
                            i < this.AnonBr;
                            i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                        ) {
                            if (Math.floor(this.Anon[i] / 1000) === this.Mor && this.Anon[i] % 1000 !== 0) {
                                if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                    if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                            return;
                                        }
                                    }
                                    if (
                                        BestCards[this.Anon[i] % 10][1][1] !== player &&
                                        (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                        BroiPlayer[i] <= 2
                                    ) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (
                            let i = 0;
                            i < this.AnonBr;
                            i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                        ) {
                            if (Math.floor(this.Anon[i] / 1000) === WhoP && this.Anon[i] % 1000 !== 0) {
                                if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                    if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                            return;
                                        }
                                    }
                                    for (let k = 1; k <= 4; k++) {
                                        if (k !== this.Anon[i] % 10) {
                                            if (
                                                BestCards[k][1][1] !== player &&
                                                (BestCards[k][2][1] !== player || BestCards[k][2][1] !== player) &&
                                                BroiPlayer[i] <= 2
                                            ) {
                                                for (let j = 14; j > 0; j--) {
                                                    if (BestCards[k][j][1] === player) {
                                                        if (this.PuskaneNaKartaN(BestCards[k][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i <= 4; i++) {
                            if (BroiPlayer[i] >= 5) {
                                if (BestCards[i][1][1] === player && BestCards[i][2][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[1][1][0], player)) {
                                        return;
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i <= 4; i++) {
                            if (
                                BroiPlayer[i] >= BroiPlayer[1] &&
                                BroiPlayer[i] >= BroiPlayer[2] &&
                                BroiPlayer[i] >= BroiPlayer[3] &&
                                BroiPlayer[i] >= BroiPlayer[4]
                            ) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //??? Niama cikal za i//???
                    if (this.hand > 0) {
                        //ina4e//atakuva silnoto ili slaboto na mora
                        for (let i = 1; i <= 4; i++) {
                            if ((BroiMor[i] <= 1 || (BestCards[i][2][1] !== this.Mor && BestCards[i][3][1] !== this.Mor)) && !MoraESledMen) {
                                //slaboto
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (
                                ((BestCards[i][1][1] === this.Mor && BroiOstavashti[i] - BroiMor[i] >= 4) ||
                                    (BestCards[i][2][1] === this.Mor && (BroiOstavashti[i] - BroiMor[i] >= 6 || BestCards[i][1][1] !== this.Mor))) &&
                                MoraESledMen
                            ) {
                                //slaboto
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (
                            let i = 1;
                            i <= 4;
                            i++ //TURSI AKO IMA RUCE OT DULJINA
                        ) {
                            if (BroiPlayer[i] > Math.floor((BroiOstavashti[i] - BroiPlayer[i] - BroiMor[i]) / 2) && BroiPlayer[i] > BroiMor[i]) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }

                        for (let i = 1; i <= 4; i++) {
                            if (
                                BroiPlayer[i] >= BroiPlayer[1] &&
                                BroiPlayer[i] >= BroiPlayer[2] &&
                                BroiPlayer[i] >= BroiPlayer[3] &&
                                BroiPlayer[i] >= BroiPlayer[4]
                            ) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.PuskaneNaKarta((player - 1) * 13 + 1);
                    return;
                }
                if (this.hand === 0) {
                    //kogato atakuva mora
                    for (let i = 1; i <= 4; i++) {
                        if (
                            BroiPlayer[5] > 0 &&
                            ((BroiPlayer[i] === 1 && BroiOstavashti[i] >= 3 && BestCards[i][2][1] !== player) ||
                                (BroiPlayer[i] === 2 && BroiOstavashti[i] >= 7 && BestCards[i][2][1] !== player && BestCards[i][3][1] !== player))
                        ) {
                            if (BestCards[i][1][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][1][0], player)) {
                                    return;
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva ako parta mu e kazal nekva boia
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === Partner && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                if (
                                    BestCards[this.Anon[i] % 10][1][1] !== player &&
                                    (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                    BroiPlayer[i] <= 2
                                ) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === this.Mor && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                if (
                                    BestCards[this.Anon[i] % 10][1][1] !== player &&
                                    (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                    BroiPlayer[i] <= 2
                                ) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //atakuva silnoto na mora(slaboto na atakuva6tia)
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === WhoP && this.Anon[i] % 1000 !== 0) {
                            if (this.Anon[i] % 100 !== 0 && this.Anon[i] % 10 < 5) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                for (let k = 1; k <= 4; k++) {
                                    if (k !== this.Anon[i] % 10) {
                                        if (
                                            BestCards[k][1][1] !== player &&
                                            (BestCards[k][2][1] !== player || BestCards[k][2][1] !== player) &&
                                            BroiPlayer[i] <= 2
                                        ) {
                                            for (let j = 14; j > 0; j--) {
                                                if (BestCards[k][j][1] === player) {
                                                    if (this.PuskaneNaKartaN(BestCards[k][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (BroiSureHands + (this.hand - GetHands) > 7 - this.level || BroiSureHands === 13 - this.hand) {
                    for (let i = 1; i <= 5; i++) {
                        if (SurePlayer[i] > 0 && (BroiMor[i] > 0 || BroiMor[5] === 0)) {
                            for (let j = 1; j < 15; j++) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                for (
                    let i = 1;
                    i <= 4;
                    i++ //ako ima cviat da raz4ita si go vzima
                ) {
                    if (
                        SurePlayer[i] >= 3 ||
                        (SurePlayer[i] >= Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiMor[i])) / 2) && SurePlayer[i] >= BroiMor[i])
                    ) {
                        for (let j = 1; j < 15; j++) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                if (this.hand > 0) {
                    //ina4e
                    for (
                        let i = 1;
                        i <= 4;
                        i++ //vzia predi da sa mu cakani
                    ) {
                        if (BestCards[i][1][1] === player && BestCards[i][2][1] === player) {
                            if (this.PuskaneNaKartaN(BestCards[i][1][0], player)) {
                                return;
                            }
                        }
                        if (BestCards[i][1][1] === player && BroiOstavashti[i] <= 8) {
                            if (this.PuskaneNaKartaN(BestCards[i][1][0], player)) {
                                return;
                            }
                        }
                    }
                    for (
                        let i = 0;
                        i < this.AnonBr;
                        i++ //ako parta e kazal ne6to(i mora ne e mn silen v nego) mu podava
                    ) {
                        if (Math.floor(this.Anon[i] / 1000) === Partner && this.Anon[i] % 1000 !== 0) {
                            if (
                                this.Anon[i] % 100 !== 0 &&
                                this.Anon[i] % 10 < 5 &&
                                BestCards[this.Anon[i] % 10][1][1] !== this.Mor &&
                                BestCards[this.Anon[i] % 10][3][1] !== this.Mor
                            ) {
                                if (BestCards[this.Anon[i] % 10][1][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][1][0], player)) {
                                        return;
                                    }
                                }
                                if (
                                    BestCards[this.Anon[i] % 10][1][1] !== player &&
                                    (BestCards[this.Anon[i] % 10][2][1] !== player || BestCards[this.Anon[i] % 10][2][1] !== player) &&
                                    BroiPlayer[i] <= 2
                                ) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[this.Anon[i] % 10][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[this.Anon[i] % 10][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i <= 4; i++) {
                        if ((BroiMor[i] <= 1 || (BestCards[i][2][1] !== this.Mor && BestCards[i][3][1] !== this.Mor)) && !MoraESledMen) {
                            //slaboto
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i <= 4; i++) {
                        if (
                            ((BestCards[i][1][1] === this.Mor && BroiOstavashti[i] - BroiMor[i] >= 4) ||
                                (BestCards[i][2][1] === this.Mor && (BroiOstavashti[i] - BroiMor[i] >= 6 || BestCards[i][1][1] !== this.Mor))) &&
                            MoraESledMen
                        ) {
                            //slaboto
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                this.PuskaneNaKarta((player - 1) * 13 + 1);
                return;
            }
            if (this.ParviPuska > 0) {
                if (this.VtoriPuska === 0) {
                    //kogato e vtori nared
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            //iska se koz
                            if (
                                (player === 1 && this.Mor === 4) ||
                                (player === 2 && this.Mor === 1) ||
                                (player === 3 && this.Mor === 2) ||
                                (player === 4 && this.Mor === 3)
                            ) {
                                //mora e igral
                                let BestOtherCard = 0;
                                let SecOtherCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player
                                    ) {
                                        BestOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestOtherCard
                                    ) {
                                        SecOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestOtherCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecOtherCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            } //mora e sled men
                            else {
                                let BestMorCard = 0;
                                let SecMorCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                    ) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (Math.floor(this.ParviPuska / 100) <= 4 && Math.floor(this.ParviPuska / 100) >= 1) {
                            // ne se iska koz
                            if (
                                (player === 1 && this.Mor === 4) ||
                                (player === 2 && this.Mor === 1) ||
                                (player === 3 && this.Mor === 2) ||
                                (player === 4 && this.Mor === 3)
                            ) {
                                //mora e igral
                                let BestOtherCard = 0;
                                let SecOtherCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player
                                    ) {
                                        BestOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestOtherCard
                                    ) {
                                        SecOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestOtherCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecOtherCard) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            } //mora e sled men
                            else {
                                let BestMorCard = 0;
                                let SecMorCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                    ) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                if (BestMorCard > 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                        ) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    for (let j = 14; j > 0; j--) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                        ) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard) {
                                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (BestMorCard === 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                        ) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        if (BroiPlayer[5] > 0) {
                            if (
                                (player === 1 && this.Mor === 4) ||
                                (player === 2 && this.Mor === 1) ||
                                (player === 3 && this.Mor === 2) ||
                                (player === 4 && this.Mor === 3)
                            ) {
                                //mora e igral
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[5][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            } // mora e sled nego
                            else {
                                let BestMorCard = 0;
                                let BestMorKoz = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[5][j][1] === this.Mor) {
                                        BestMorKoz = BestCards[5][j][0];
                                        break;
                                    }
                                }
                                if (BestMorCard > 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[5][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (BestMorCard === 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[5][j][1] === player && BestCards[5][j][0] > BestMorKoz) {
                                            if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[5][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            this.Chistene(player);
                            return;
                        }
                        this.Chistene(player);
                        return;
                    }
                }
                if (this.TretiPuska === 0 && this.VtoriPuska > 0) {
                    //kogato e treti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            if (
                                (player === 1 && this.Mor === 4) ||
                                (player === 2 && this.Mor === 1) ||
                                (player === 3 && this.Mor === 2) ||
                                (player === 4 && this.Mor === 3)
                            ) {
                                //mora e igral
                                let BCParviPuska = 0;
                                let BestMyCard = 0;
                                let SecMyCard = 0;
                                let BCBestMyCard = 0;
                                let BCSecMyCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                                    ) {
                                        SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let i = 1; i < 14; i++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                    ) {
                                        BCBestMyCard++;
                                    }
                                }
                                for (let i = 1; i < 14; i++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                    ) {
                                        BCSecMyCard++;
                                    }
                                }
                                for (let i = 1; i < 14; i++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                    ) {
                                        BCParviPuska++;
                                    }
                                }
                                if (Math.floor(this.ParviPuska / 100) === Math.floor(this.VtoriPuska / 100) && this.VtoriPuska > this.ParviPuska) {
                                    if (SecMyCard > this.VtoriPuska && BCSecMyCard === BCBestMyCard) {
                                        if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BestMyCard > this.VtoriPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (BCBestMyCard < BCParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                            } //mora e nego
                            else {
                                let BestMorCard = 0,
                                    SecMorCard = 0,
                                    ThirMorCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                    ) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMorCard
                                    ) {
                                        ThirMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                this.VtoriPuska > this.ParviPuska
                                            ) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                this.VtoriPuska > this.ParviPuska
                                            ) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard && SecMorCard > 0) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                this.VtoriPuska > this.ParviPuska
                                            ) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard && ThirMorCard > 0) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (BestMorCard > this.ParviPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (BestMorCard > this.ParviPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                    if (
                                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard &&
                                                        this.ParviPuska < SecMorCard
                                                    ) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                        if (
                                            BestMorCard > this.ParviPuska ||
                                            (this.VtoriPuska > this.ParviPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                        ) {
                                            if (BestMorCard > this.ParviPuska) {
                                                if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                    if (
                                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard &&
                                                        this.ParviPuska < ThirMorCard
                                                    ) {
                                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (Math.floor(this.ParviPuska / 100) !== 5) {
                            //ako ne e koz
                            if (this.VtoriPuska < 500) {
                                if (
                                    (player === 1 && this.Mor === 4) ||
                                    (player === 2 && this.Mor === 1) ||
                                    (player === 3 && this.Mor === 2) ||
                                    (player === 4 && this.Mor === 3)
                                ) {
                                    //mora e igral
                                    let BCParviPuska = 0;
                                    let BestMyCard = 0;
                                    let SecMyCard = 0;
                                    let BCBestMyCard = 0;
                                    let BCSecMyCard = 0;
                                    for (let j = 1; j < 14; j++) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                            BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                            break;
                                        }
                                    }
                                    for (let j = 1; j < 14; j++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                                        ) {
                                            SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                            break;
                                        }
                                    }
                                    for (let i = 1; i < 14; i++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                        ) {
                                            BCBestMyCard++;
                                        }
                                    }
                                    for (let i = 1; i < 14; i++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                        ) {
                                            BCSecMyCard++;
                                        }
                                    }
                                    for (let i = 1; i < 14; i++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] !== this.Mor
                                        ) {
                                            BCParviPuska++;
                                        }
                                    }
                                    if (
                                        Math.floor(this.ParviPuska / 100) === Math.floor(this.VtoriPuska / 100) &&
                                        this.VtoriPuska > this.ParviPuska
                                    ) {
                                        if (SecMyCard > this.VtoriPuska && BCSecMyCard === BCBestMyCard) {
                                            if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                return;
                                            }
                                        }
                                        if (BestMyCard > this.VtoriPuska) {
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                    if (BCBestMyCard < BCParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                } // mora e sled men
                                else {
                                    let BestMorCard = 0,
                                        SecMorCard = 0,
                                        ThirMorCard = 0;
                                    for (let j = 1; j < 14; j++) {
                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                            BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                            break;
                                        }
                                    }
                                    for (let j = 1; j < 14; j++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard
                                        ) {
                                            BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                            break;
                                        }
                                    }
                                    for (let j = 1; j < 14; j++) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMorCard &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMorCard
                                        ) {
                                            ThirMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                            break;
                                        }
                                    }
                                    if (BestMorCard !== 0) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                        this.VtoriPuska > this.ParviPuska
                                                    ) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                        this.VtoriPuska > this.ParviPuska
                                                    ) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard && SecMorCard > 0) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                                        this.VtoriPuska > this.ParviPuska
                                                    ) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska) {
                                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard && ThirMorCard > 0) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (BestMorCard > this.ParviPuska) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > BestMorCard) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (BestMorCard > this.ParviPuska) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                            if (
                                                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] > SecMorCard &&
                                                                this.ParviPuska < SecMorCard
                                                            ) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                                if (
                                                    BestMorCard > this.ParviPuska ||
                                                    (this.VtoriPuska > this.ParviPuska &&
                                                        Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100))
                                                ) {
                                                    if (BestMorCard > this.ParviPuska) {
                                                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska) {
                                                            if (
                                                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] > ThirMorCard &&
                                                                this.ParviPuska < ThirMorCard
                                                            ) {
                                                                if (
                                                                    this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)
                                                                ) {
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (BestMorCard === 0) {
                                        if (
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                            this.VtoriPuska > this.ParviPuska
                                        ) {
                                            for (let j = 14; j > 0; j--) {
                                                if (
                                                    BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                                    BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.VtoriPuska
                                                ) {
                                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        if (BroiPlayer[5] > 0) {
                            if (
                                (player === 1 && this.Mor === 4) ||
                                (player === 2 && this.Mor === 1) ||
                                (player === 3 && this.Mor === 2) ||
                                (player === 4 && this.Mor === 3)
                            ) {
                                //mora e igral
                                let BestOtherCard = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== this.Mor &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] !== player
                                    ) {
                                        BestOtherCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                if (BestOtherCard > this.ParviPuska) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[5][j][1] === player && BestCards[5][j][0] > this.VtoriPuska) {
                                            if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            } else {
                                let BestMorCard = 0;
                                let BestMorKoz = 0;
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === this.Mor) {
                                        BestMorCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                        break;
                                    }
                                }
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[5][j][1] === this.Mor) {
                                        BestMorKoz = BestCards[5][j][0];
                                        break;
                                    }
                                }
                                if (BestMorCard > 0) {
                                    if (
                                        (this.ParviPuska < this.VtoriPuska &&
                                            Math.floor(this.ParviPuska / 100) === Math.floor(this.VtoriPuska / 100)) ||
                                        BestMorCard > this.ParviPuska ||
                                        this.VtoriPuska > 500
                                    ) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[5][j][1] === player && BestCards[5][j][0] > this.VtoriPuska) {
                                                if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (BestMorCard === 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (
                                            BestCards[5][j][1] === player &&
                                            BestCards[5][j][0] > this.VtoriPuska &&
                                            BestCards[5][j][0] > BestMorKoz
                                        ) {
                                            if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                    if (
                                        this.VtoriPuska > 500 ||
                                        (this.ParviPuska < this.VtoriPuska && Math.floor(this.ParviPuska / 100) === Math.floor(this.VtoriPuska / 100))
                                    ) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[5][j][1] === player && BestCards[5][j][0] > this.VtoriPuska) {
                                                if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            this.Chistene(player);
                            return;
                        }
                        this.Chistene(player);
                        return;
                    }
                }
                if (this.ChetvartiPuska === 0 && this.TretiPuska > 0) {
                    //kogato e 4etvarti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            if (this.VtoriPuska < this.ParviPuska || this.VtoriPuska < this.TretiPuska) {
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.TretiPuska &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        if (Math.floor(this.ParviPuska / 100) !== 5) {
                            if (
                                this.ParviPuska < 500 &&
                                this.VtoriPuska < 500 &&
                                this.TretiPuska < 500 &&
                                ((this.VtoriPuska < this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) ||
                                    (this.VtoriPuska < this.TretiPuska && Math.floor(this.ParviPuska / 100) === Math.floor(this.TretiPuska / 100)))
                            ) {
                                for (let j = 14; j > 0; j--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.TretiPuska &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][j][0] > this.ParviPuska
                                    ) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        if (BroiPlayer[5] > 0) {
                            if (
                                (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) && this.VtoriPuska < 500) ||
                                (this.VtoriPuska > 500 && this.TretiPuska > this.VtoriPuska) ||
                                (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                                    (this.ParviPuska > this.VtoriPuska ||
                                        (this.TretiPuska > this.VtoriPuska &&
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.TretiPuska / 100)) ||
                                        this.TretiPuska > 500))
                            ) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[5][j][1] === player && BestCards[5][j][0] > this.TretiPuska) {
                                        if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            this.Chistene(player);
                            return;
                        } else {
                            this.Chistene(player);
                            return;
                        }
                    }
                }
            }
        }
    }

    IntelectMakeAnons(player: number) {
        let WhatPlay: number = this.LastSays[2]; //1-5//
        let Whose: number = 2 - (this.LastSays[0] % 2);
        let GetHands: number = 0;
        for (
            let i = 0;
            i < this.hand;
            i++ //smaita broia na vzetite ruce
        ) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        let BroiOstavashti: number[] = [0, 0, 0, 0, 0, 0]; //1-4 broia ostava6ti karti v vsiaka boia
        let NeIgrani = []; //koi karti na sa igrani
        let BestCards = []; //kartite oito sa ostanali podredeni po golemina
        for (let i = 0; i < 6; i++) {
            NeIgrani[i] = [];
            BestCards[i] = [];
            for (let i1 = 0; i1 < 15; i1++) {
                BestCards[i][i1] = [];
            }
        }
        for (let i = 0; i < 15; i++) {
            for (let j = 0; j < 6; j++) {
                NeIgrani[j][i] = false;
            }
        }
        for (let i = 0; i <= 52; i++) {
            if (this.k[i] > 0) {
                NeIgrani[Math.floor(this.k[i] / 100)][this.k[i] % 100] = true;
            }
        }

        for (let i = 0; i < 15; i++) {
            for (let j = 0; j < 6; j++) {
                if (NeIgrani[j][i]) {
                    BroiOstavashti[j]++;
                }
            }
        }
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < 14; j++) {
                BestCards[i][j][0] = 0;
                BestCards[i][j][1] = 0;
            }
            BestCards[i][0][0] = 1;
        }
        for (let i = 1; i <= 5; i++) {
            for (let j = 14; j > 0; j--) {
                if (NeIgrani[i][j]) {
                    BestCards[i][BestCards[i][0][0]][0] = i * 100 + j;
                    BestCards[i][BestCards[i][0][0]][1] = Math.floor((this.FindCardIndex(i * 100 + j) - 1) / 13) + 1;
                    BestCards[i][0][0]++;
                }
            }
        }
        let Oponent: number[] = [];
        let Partner: number = 0; //opredelia koi e partniora
        if (player === 1) {
            Partner = 3;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 2) {
            Partner = 4;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        if (player === 3) {
            Partner = 1;
            Oponent[1] = 2;
            Oponent[2] = 4;
        }
        if (player === 4) {
            Partner = 2;
            Oponent[1] = 1;
            Oponent[2] = 3;
        }
        let BroiPlayer: number[] = [0, 0, 0, 0, 0, 0];
        let BroiPartner: number[] = [0, 0, 0, 0, 0, 0]; //po kolko karti v vseki cviat ima partniora i playera
        for (let i = (player - 1) * 13 + 1; i <= player * 13; i++) {
            if (this.k[i] !== 0) {
                BroiPlayer[Math.floor(this.k[i] / 100)]++;
            }
        }
        for (let i = (Partner - 1) * 13 + 1; i <= Partner * 13; i++) {
            if (this.k[i] !== 0) {
                BroiPartner[Math.floor(this.k[i] / 100)]++;
            }
        }
        let Niama = [];
        for (let i = 0; i < 5; i++) {
            Niama[i] = [];
            for (let j = 0; j < 6; j++) {
                Niama[i][j] = false;
            }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////intelekta za bez koz//////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////
        if (WhatPlay === 5) {
            let BroiSureHands: number = 0; //tursim sigurnite ruce
            let SureHands: number[] = [0, 0, 0, 0, 0];
            let SureColor: boolean[] = [false, false, false, false, false];
            let SurePlayer: number[] = [0, 0, 0, 0, 0];
            let SurePartner: number[] = [0, 0, 0, 0, 0];
            for (let i = 1; i <= 4; i++) {
                for (let j = 1; j < 14; j++) {
                    if (BestCards[i][j][0] !== 0) {
                        if (BestCards[i][j][1] === player || BestCards[i][j][1] === Partner) {
                            SureHands[i]++;
                            if (BestCards[i][j][1] === player) {
                                SurePlayer[i]++;
                            }
                            if (BestCards[i][j][1] === Partner) {
                                SurePartner[i]++;
                            }
                        } else {
                            break;
                        }
                    }
                }
                SureHands[i] = Math.min(SureHands[i], Math.max(BroiPlayer[i], BroiPartner[i]));
                if (SureHands[i] >= BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) {
                    SureHands[i] = Math.max(BroiPlayer[i], BroiPartner[i]);
                }
                BroiSureHands += SureHands[i];
            }
            for (let i = 1; i < 5; i++) {
                if (SureHands[i] >= BroiOstavashti[i] - (BroiPartner[i] + BroiPlayer[i] + 1)) {
                    SureColor[i] = true;
                }
            }
            if (this.ParviPuska === 0) {
                if ((BroiSureHands + GetHands >= this.level + 6 || BroiSureHands >= 13 - this.hand) && BroiSureHands >= 1) {
                    //ako mu e suguren dogovora ili vsi4ki ostanali sa negovi
                    for (let i = 1; i < 5; i++) {
                        if (SurePlayer[i] >= 1) {
                            if (
                                BroiPlayer[i] <= BroiPartner[i] ||
                                SurePlayer[i] === SureHands[i] ||
                                SurePartner[i] === 0 ||
                                SurePlayer[i] > BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])
                            ) {
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (SurePartner[i] >= 1) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                } //ako ne e siguren dogovora
                else {
                    for (
                        let i = 1;
                        i < 5;
                        i++ //ako ima cviat koito e siguren
                    ) {
                        if (SureHands[i] > Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) / 2)) {
                            if (SurePlayer[i] >= 1) {
                                if (
                                    BroiPlayer[i] <= BroiPartner[i] ||
                                    SurePlayer[i] === SureHands[i] ||
                                    SurePartner[i] === 0 ||
                                    SurePlayer[i] > BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])
                                ) {
                                    for (let j = 1; j < 14; j++) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            if (SurePartner[i] >= 1) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //broia slizalkite
                    let BestPartCardP: number[] = [0, 0, 0, 0, 0];
                    let SecPartCardP: number[] = [0, 0, 0, 0, 0];
                    let BestMyCardP: number[] = [0, 0, 0, 0, 0];
                    let SecMyCardP: number[] = [0, 0, 0, 0, 0];
                    let ThirMyCardP: number[] = [0, 0, 0, 0, 0];
                    let BCBestPartCardP: number[] = [0, 0, 0, 0, 0];
                    let BCSecPartCardP: number[] = [0, 0, 0, 0, 0];
                    let BCBestMyCardP: number[] = [0, 0, 0, 0, 0];
                    let BCSecMyCardP: number[] = [0, 0, 0, 0, 0];
                    let BCThirMyCardP: number[] = [0, 0, 0, 0, 0];
                    let BCThirPartCardP: number[] = [0, 0, 0, 0, 0];
                    let ThirPartCardP: number[] = [0, 0, 0, 0, 0];
                    for (let i = 1; i < 5; i++) {
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === Partner) {
                                BestPartCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === Partner && BestCards[i][j][0] !== BestPartCardP[i]) {
                                SecPartCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === player) {
                                BestMyCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === player && BestCards[i][j][0] !== BestMyCardP[i]) {
                                SecMyCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === player && BestCards[i][j][0] !== BestMyCardP[i] && BestCards[i][j][0] !== SecMyCardP[i]) {
                                ThirMyCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][1] === Partner && BestCards[i][j][0] !== BestPartCardP[i] && BestCards[i][j][0] !== SecPartCardP[i]) {
                                ThirPartCardP[i] = BestCards[i][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > BestPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCBestPartCardP[i]++;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > SecPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCSecPartCardP[i]++;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > BestMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCBestMyCardP[i]++;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > SecMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCSecMyCardP[i]++;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > ThirMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCThirMyCardP[i]++;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[i][j][0] > ThirPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                                BCThirPartCardP[i]++;
                            }
                        }

                        //proverka za dupki
                        //proverka za silni karti
                        //proverka za impas
                        //za duljina
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BCBestMyCardP[i] === 1 &&
                            BCBestPartCardP[i] === 1 &&
                            BestMyCardP[i] > 0 &&
                            BestPartCardP[i] > 0 &&
                            (BroiPlayer[i] > 1 || BroiPartner[i] > 1)
                        ) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 1 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 0 && BCThirPartCardP[i] === 1 && ThirPartCardP[i] > 0 && BCSecPartCardP[i] === 1) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BCBestPartCardP[i] === 0 &&
                            BCThirPartCardP[i] === 1 &&
                            ThirPartCardP[i] > 0 &&
                            BCSecPartCardP[i] === 0 &&
                            BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i]) > 5
                        ) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 1 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (
                        let i = 1;
                        i < 5;
                        i++ //tursi ruce ot duljina
                    ) {
                        if (
                            SureHands[i] === Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) / 2) &&
                            Math.max(BroiPlayer[i], BroiPartner[i]) > Math.floor((BroiPlayer[i] + BroiPartner[i]) / 2)
                        ) {
                            if (SurePlayer[i] >= 1) {
                                if (
                                    BroiPlayer[i] <= BroiPartner[i] ||
                                    SurePlayer[i] === SureHands[i] ||
                                    SurePartner[i] === 0 ||
                                    SurePlayer[i] > BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])
                                ) {
                                    for (let j = 1; j < 14; j++) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            if (SurePartner[i] >= 1) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCSecPartCardP[i] === 1 && BCThirPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 2 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BCBestPartCardP[i] <= 2 &&
                            ((BCBestMyCardP[i] <= 2 && BCBestMyCardP[i] !== 0) || (BCSecMyCardP[i] <= 2 && BCSecMyCardP[i] !== 0)) &&
                            BroiPlayer[i] > 0 &&
                            BestPartCardP[i] > 0
                        ) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    let Slizalka: boolean[] = [false, false, false, false, false];
                    let HasSlizalka = 0;
                    for (let i = 1; i < 5; i++) {
                        if (SureHands[i] >= 2) {
                            if (SurePartner[i] >= 1) {
                                Slizalka[i] = true;
                                HasSlizalka++;
                            }
                        }
                    }
                    if (HasSlizalka) {
                        for (let i = 1; i < 5; i++) {
                            if ((HasSlizalka === 1 && BroiPartner[i] - HasSlizalka > 0) || (HasSlizalka > 1 && BroiPartner[i] > 0)) {
                                if (
                                    (BCBestMyCardP[i] === 0 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 1)) ||
                                    (BCBestMyCardP[i] === 1 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 2))
                                ) {
                                    for (let j = 1; j < 5; j++) {
                                        if (j !== i && Slizalka[j]) {
                                            for (let k = 13; k > 0; k--) {
                                                if (BestCards[j][k][1] === player) {
                                                    if (this.PuskaneNaKartaN(BestCards[j][k][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    for (let k = 13; k > 0; k--) {
                                        if (BestCards[i][k][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][k][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (Math.max(BroiPlayer[i], BroiPartner[i]) > Math.floor((BroiPlayer[i] + BroiPartner[i]) / 2) + 1) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (SurePlayer[i]) {
                            if (this.PuskaneNaKartaN(BestMyCardP[i], player)) {
                                return;
                            }
                        }
                        if (SurePartner[i]) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestMyCardP[i] >= 2 && BCBestPartCardP[i] >= 2) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    let idx = 1;
                    for (let i = 2; i < 5; i++) {
                        if (BroiPlayer[i] + BroiPartner[i] > BroiPlayer[idx] + BroiPartner[idx]) {
                            idx = i;
                        }
                    }
                    for (let j = 13; j > 0; j--) {
                        if (BestCards[idx][j][1] === player) {
                            if (this.PuskaneNaKartaN(BestCards[idx][j][0], player)) {
                                return;
                            }
                        }
                    }
                    //??//s edin
                    //igraia za tiah(sigurna raka)
                }
                this.PuskaneNaKarta((player - 1) * 13 + 1);
                return;
            }
            ////////////////////////////ako ne e za purvia////////////////
            if (this.ParviPuska > 0) {
                if (this.VtoriPuska === 0 && this.ParviPuska > 0) {
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        //igrae vtori
                        let BestPartCard = 0;
                        let BestMyCard = 0;
                        let SecMyCard = 0;
                        let ThirMyCard = 0;
                        let BCBestPartCard = 0;
                        let BCBestMyCard = 0;
                        let BCSecMyCard = 0;
                        let BCThirMyCard = 0;

                        for (let j = 1; j < 14; j++) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === Partner) {
                                BestPartCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                            ) {
                                SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMyCard
                            ) {
                                ThirMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestPartCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCBestPartCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCBestMyCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCSecMyCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > ThirMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCThirMyCard++;
                            }
                        }
                        if (BestMyCard > this.ParviPuska) {
                            if (BCBestPartCard === 0) {
                                if (BCBestMyCard >= 1) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard >= 1 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard >= 1 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (BCBestPartCard === 1) {
                                if (
                                    BroiSureHands > this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >
                                        (BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            (BroiPlayer[Math.floor(this.ParviPuska / 100)] + BroiPartner[Math.floor(this.ParviPuska / 100)])) /
                                            2
                                ) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (BCBestMyCard >= 1 && BestMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard >= 1 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard >= 1 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (BCBestPartCard === 2) {
                                if (
                                    BroiSureHands > this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >
                                        (BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            (BroiPlayer[Math.floor(this.ParviPuska / 100)] + BroiPartner[Math.floor(this.ParviPuska / 100)])) /
                                            2
                                ) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (player !== this.Mor) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BCSecMyCard === 1 && BCThirMyCard === 1 && SecMyCard !== 0) {
                                        if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (BCBestPartCard > 2) {
                                if (
                                    BroiSureHands > this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >
                                        (BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            (BroiPlayer[Math.floor(this.ParviPuska / 100)] + BroiPartner[Math.floor(this.ParviPuska / 100)])) /
                                            2
                                ) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (player !== this.Mor) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BCSecMyCard === 1 && BCThirMyCard === 1 && SecMyCard !== 0) {
                                        if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (player === this.Mor) {
                                    if (BCBestMyCard === 0) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BCSecMyCard === 1 && BCThirMyCard === 1 && SecMyCard !== 0) {
                                        if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 14; i > 0; i--) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                    return;
                                }
                            }
                        }
                    } //4isti karta
                    else {
                        this.Chistene(player);
                        return;
                    }
                }
                ////////////////////////////////////////////treti puska
                if (this.TretiPuska === 0 && this.VtoriPuska > 0) {
                    //igrae treti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        let BestMyCard = 0;
                        let SecMyCard = 0;
                        let ThirMyCard = 0;
                        let BCBestMyCard = 0;
                        let BCSecMyCard = 0;
                        let BCThirMyCard = 0;
                        let BCParviPuska = 0;
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                            ) {
                                SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMyCard
                            ) {
                                ThirMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCBestMyCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCSecMyCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > ThirMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCThirMyCard++;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCParviPuska++;
                            }
                        }
                        //
                        if (
                            BCParviPuska > 0 ||
                            (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) && this.VtoriPuska > this.ParviPuska)
                        ) {
                            //Ako ne e vlastna
                            if (
                                BroiSureHands + GetHands >= this.level + 6 ||
                                SureHands[Math.floor(this.ParviPuska / 100)] >
                                    BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                        (BroiPartner[Math.floor(this.ParviPuska / 100)] +
                                            Math.floor(BroiPlayer[Math.floor(this.ParviPuska / 100)]) / 2)
                            ) {
                                //vzima(ako moje)
                                if (
                                    BestMyCard > this.VtoriPuska &&
                                    BestMyCard > this.ParviPuska &&
                                    ((this.ParviPuska < this.VtoriPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) ||
                                        BCParviPuska > BCBestMyCard)
                                ) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                if (BCBestMyCard < BCParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) {
                                if (BestMyCard > this.VtoriPuska) {
                                    if (SecMyCard > this.VtoriPuska) {
                                        if (BCSecMyCard === 1 || BCSecMyCard === 2) {
                                            if (ThirMyCard > this.VtoriPuska && BCThirMyCard === 1) {
                                                if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                    return;
                                                }
                                            }
                                            if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (this.VtoriPuska < this.ParviPuska || Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                if (BestMyCard > this.ParviPuska) {
                                    if (BCBestMyCard < BCParviPuska) {
                                        if (BCParviPuska > 1) {
                                            if (BCThirMyCard === 1) {
                                                if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                    return;
                                                }
                                            }
                                            if (BCSecMyCard === 1) {
                                                if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                    return;
                                                }
                                            }
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            if (BCBestMyCard === 1 && (BCParviPuska >= 3 || BCSecMyCard === 1)) {
                                if (this.PuskaneNaKartaN(BCBestMyCard, player)) {
                                    return;
                                }
                            }
                        }
                        //
                        for (
                            let i = 13;
                            i > 0;
                            i-- //igrae nai slaba
                        ) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                    return;
                                }
                            }
                        }
                    } //4isti karta
                    else {
                        this.Chistene(player);
                        return;
                    }
                }
                ///////////////////////////////4etvarti Puska
                if (this.ChetvartiPuska === 0 && this.TretiPuska > 0) {
                    //igrae treti
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (
                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) &&
                            this.VtoriPuska % 100 > this.ParviPuska % 100 &&
                            (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100) || this.TretiPuska % 100 < this.VtoriPuska % 100)
                        ) {
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            for (let i = 14; i > 0; i--) {
                                if (
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.TretiPuska
                                ) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    } //4istia karta
                    else {
                        this.Chistene(player);
                        return;
                    }
                }
            }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////Igra Na Koz//////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////
        if (WhatPlay >= 1 && WhatPlay <= 4) {
            let BroiSureHands = 0; //tursim sigurnite ruce
            let SureHands: number[] = [0, 0, 0, 0, 0, 0];
            let SureColor: boolean[] = [false, false, false, false, false, false];
            let SurePlayer: number[] = [0, 0, 0, 0, 0, 0];
            let SurePartner: number[] = [0, 0, 0, 0, 0, 0];
            for (let i = 1; i <= 5; i++) {
                for (let j = 1; j < 14; j++) {
                    if (BestCards[i][j][0] !== 0) {
                        if (BestCards[i][j][1] === player || BestCards[i][j][1] === Partner) {
                            SureHands[i]++;
                            if (BestCards[i][j][1] === player) {
                                SurePlayer[i]++;
                            }
                            if (BestCards[i][j][1] === Partner) {
                                SurePartner[i]++;
                            }
                        } else {
                            break;
                        }
                    }
                }
                SureHands[i] = Math.min(SureHands[i], Math.max(BroiPlayer[i], BroiPartner[i]));
                if (SureHands[i] >= BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) {
                    SureHands[i] = Math.max(BroiPlayer[i], BroiPartner[i]);
                }
                BroiSureHands += SureHands[i];
            }
            for (let i = 1; i < 6; i++) {
                if (SureHands[i] >= BroiOstavashti[i] - (BroiPartner[i] + BroiPlayer[i] + 1)) {
                    SureColor[i] = true;
                }
            }
            //////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////parvia e na hod////////////////////////////////////////
            if (this.ParviPuska === 0) {
                let BestPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                let SecPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BestMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let SecMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let ThirMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCBestPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCSecPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCBestMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCSecMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCThirMyCardP: number[] = [0, 0, 0, 0, 0, 0];
                let BCThirPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                let ThirPartCardP: number[] = [0, 0, 0, 0, 0, 0];
                for (let i = 1; i < 6; i++) {
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === Partner) {
                            BestPartCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === Partner && BestCards[i][j][0] !== BestPartCardP[i]) {
                            SecPartCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === player) {
                            BestMyCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === player && BestCards[i][j][0] !== BestMyCardP[i]) {
                            SecMyCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === player && BestCards[i][j][0] !== BestMyCardP[i] && BestCards[i][j][0] !== SecMyCardP[i]) {
                            ThirMyCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][1] === Partner && BestCards[i][j][0] !== BestPartCardP[i] && BestCards[i][j][0] !== SecPartCardP[i]) {
                            ThirPartCardP[i] = BestCards[i][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > BestPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCBestPartCardP[i]++;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > SecPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCSecPartCardP[i]++;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > BestMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCBestMyCardP[i]++;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > SecMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCSecMyCardP[i]++;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > ThirMyCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCThirMyCardP[i]++;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[i][j][0] > ThirPartCardP[i] && (BestCards[i][j][1] === Oponent[1] || BestCards[i][j][1] === Oponent[2])) {
                            BCThirPartCardP[i]++;
                        }
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //puska da caka
                ) {
                    if (
                        BroiPartner[5] > 0 &&
                        BroiPlayer[i] > 0 &&
                        BroiPartner[i] === 0 &&
                        BroiOstavashti[i] - BroiPlayer[i] >= 1 &&
                        BroiPartner[5] <= BroiPlayer[5] &&
                        BCBestMyCardP[i] > 0
                    ) {
                        for (
                            let j = 13;
                            j > 0;
                            j-- //igrae nai slaba
                        ) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                if (
                    Math.max(BroiPlayer[5], BroiPartner[5]) >= Math.floor((BroiOstavashti[5] - (BroiPlayer[5] + BroiPartner[5])) / 2) + 1 &&
                    BroiOstavashti[5] - (BroiPlayer[5] + BroiPartner[5]) > 0
                ) {
                    //tegli koz
                    if (SureHands[5] >= Math.floor((BroiOstavashti[5] - (BroiPlayer[5] + BroiPartner[5])) / 2)) {
                        if (BCBestPartCardP[5] === 0) {
                            for (
                                let i = 13;
                                i > 0;
                                i-- //igrae nai slaba
                            ) {
                                if (BestCards[5][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (BCBestMyCardP[5] === 0) {
                            if (this.PuskaneNaKartaN(BestMyCardP[5], player)) {
                                return;
                            }
                        }
                    }
                    let i = 5;
                    if (
                        BCBestMyCardP[i] === 1 &&
                        BCBestPartCardP[i] === 1 &&
                        BestMyCardP[i] > 0 &&
                        BestPartCardP[i] > 0 &&
                        (BroiPlayer[i] > 1 || BroiPartner[i] > 1)
                    ) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 1 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    if (BCBestPartCardP[i] === 0 && BCThirPartCardP[i] === 1 && ThirPartCardP[i] > 0 && BCSecPartCardP[i] === 1) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    if (
                        BCBestPartCardP[i] === 0 &&
                        BCThirPartCardP[i] === 1 &&
                        ThirPartCardP[i] > 0 &&
                        BCSecPartCardP[i] === 0 &&
                        BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i]) > 5
                    ) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 1 && SecPartCardP[i] > 0) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    if (BCBestPartCardP[i] <= 2 && SecPartCardP[i] > 0) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                    for (let j = 13; j > 0; j--) {
                        if (BestCards[i][j][1] === player) {
                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                return;
                            }
                        }
                    }
                    if (BCBestMyCardP[5] === 0) {
                        if (this.PuskaneNaKartaN(BestMyCardP[5], player)) {
                            return;
                        }
                    }
                }
                if (BroiOstavashti[5] === BroiPlayer[5] + BroiPartner[5]) {
                    //Niamat Koz
                    if ((BroiSureHands >= this.level + 6 - GetHands || BroiSureHands === 13 - this.level) && BroiSureHands >= 1) {
                        //ako e siguren dogovora
                        for (let i = 1; i < 5; i++) {
                            if (BroiPartner[i] === 0 && SurePlayer[i] === 0 && BroiPartner[5] > 0) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (SurePlayer[i] >= 1 && (BroiPartner[i] >= BroiPlayer[i] || SurePartner[i] === 0 || SurePlayer[i] === SureHands[i])) {
                                for (let j = 1; j < 14; j++) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (SurePartner[i] >= 1) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        if (SurePlayer[5] >= 1) {
                            for (let j = 1; j < 14; j++) {
                                if (BestCards[5][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (SurePartner[5] >= 1) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[5][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[5][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    } //ako ne e siguren dogovora
                    else {
                        for (let i = 1; i < 5; i++) {
                            if (
                                SureHands[i] > Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) / 2) ||
                                Math.min(BroiPlayer[i], BroiPartner[i]) <= SureHands[i]
                            ) {
                                if (BroiPartner[i] < BroiPlayer[i] && SurePartner[i] > 0 && SurePlayer[i] < SureHands[i]) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (SurePlayer[i] > 0) {
                                    for (let j = 0; j < 14; j++) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (SurePartner[i] > 0) {
                                    for (let j = 14; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BroiPartner[i] === 0 && BroiPartner[5] > 0) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (
                            let i = 1;
                            i < 5;
                            i++ //tursi impas
                        ) {
                            if (
                                BCBestMyCardP[i] === 1 &&
                                BCBestPartCardP[i] === 1 &&
                                BestMyCardP[i] > 0 &&
                                BestPartCardP[i] > 0 &&
                                (BroiPlayer[i] > 1 || BroiPartner[i] > 1)
                            ) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 1 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BCBestPartCardP[i] === 0 && BCThirPartCardP[i] === 1 && ThirPartCardP[i] > 0 && BCSecPartCardP[i] === 1) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (
                                BCBestPartCardP[i] === 0 &&
                                BCThirPartCardP[i] === 1 &&
                                ThirPartCardP[i] > 0 &&
                                BCSecPartCardP[i] === 0 &&
                                BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i]) > 5
                            ) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 1 && SecPartCardP[i] > 0) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        let Slizane: boolean = false;
                        for (let i = 1; i < 5; i++) {
                            if (BroiPartner[i] > 0 && BroiPlayer[i] === 0 && BroiPlayer[5] > 0) {
                                for (let i1 = 1; i1 < 5; i1++) {
                                    if (
                                        (SurePartner[i1] > 0 && BroiPlayer[i1] > 0) ||
                                        (BroiPartner[5] >= 2 && SurePartner[5] >= 1 && BroiPlayer[5] >= 1)
                                    ) {
                                        Slizane = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (!Slizane) {
                            for (let i = 1; i < 5; i++) {
                                if (
                                    (Math.min(BroiPlayer[i], BroiPartner[i]) <= SureHands[i] - 1 &&
                                        BroiPlayer[i] !== BroiPartner[i] &&
                                        BroiPlayer[i] < BroiPartner[i] &&
                                        BroiPlayer[5] > 0) ||
                                    (BroiPartner[i] < BroiPlayer[i] && BroiPartner[5] > 0)
                                ) {
                                    if (BroiPartner[i] < BroiPlayer[i] && SurePartner[i] > 0 && SurePlayer[i] < SureHands[i]) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[i][j][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    if (SurePlayer[i] > 0) {
                                        for (let j = 0; j < 14; j++) {
                                            if (BestCards[i][j][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                    if (SurePartner[i] > 0) {
                                        for (let j = 14; j > 0; j--) {
                                            if (BestCards[i][j][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (BCSecPartCardP[i] === 1 && BCThirPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (BCBestPartCardP[i] === 2 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 1; i < 5; i++) {
                                if (
                                    BCBestPartCardP[i] <= 2 &&
                                    ((BCBestMyCardP[i] <= 2 && BCBestMyCardP[i] !== 0) || (BCSecMyCardP[i] <= 2 && BCSecMyCardP[i] !== 0)) &&
                                    BroiPlayer[i] > 0 &&
                                    BestPartCardP[i] > 0
                                ) {
                                    for (let j = 13; j > 0; j--) {
                                        if (BestCards[i][j][1] === player) {
                                            if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let Slizalka: boolean[] = [false, false, false, false, false];
                        let HasSlizalka = 0;
                        for (let i = 1; i < 5; i++) {
                            if (SureHands[i] >= 2) {
                                if (SurePartner[i] >= 1) {
                                    Slizalka[i] = true;
                                    HasSlizalka++;
                                }
                            }
                        }
                        if (HasSlizalka) {
                            for (let i = 1; i < 5; i++) {
                                if ((HasSlizalka === 1 && BroiPartner[i] - HasSlizalka > 0) || (HasSlizalka > 1 && BroiPartner[i] > 0)) {
                                    if (
                                        (BCBestMyCardP[i] === 0 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 1)) ||
                                        (BCBestMyCardP[i] === 1 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 2))
                                    ) {
                                        for (let j = 1; j < 5; j++) {
                                            if (j !== i && Slizalka[j]) {
                                                for (let k = 13; k > 0; k--) {
                                                    if (BestCards[j][k][1] === player) {
                                                        if (this.PuskaneNaKartaN(BestCards[j][k][0], player)) {
                                                            return;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        for (let k = 13; k > 0; k--) {
                                            if (BestCards[i][k][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[i][k][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (Math.max(BroiPlayer[i], BroiPartner[i]) > Math.floor((BroiPlayer[i] + BroiPartner[i]) / 2) + 1) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (SurePlayer[i]) {
                                if (this.PuskaneNaKartaN(BestMyCardP[i], player)) {
                                    return;
                                }
                            }
                            if (SurePartner[i]) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BCBestMyCardP[i] >= 2 && BCBestPartCardP[i] >= 2) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 1; i < 5; i++) {
                            if (BCBestPartCardP[i] === 1) {
                                for (let j = 13; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                        let idx = 1;
                        for (let i = 2; i < 5; i++) {
                            if (BroiPlayer[i] + BroiPartner[i] > BroiPlayer[idx] + BroiPartner[idx]) {
                                idx = i;
                            }
                        }
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[idx][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[idx][j][0], player)) {
                                    return;
                                }
                            }
                        }
                        //??//s edin
                        //igraia za tiah(sigurna raka)
                    }
                    this.PuskaneNaKarta((player - 1) * 13 + 1);
                    return;
                    ///
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        SureHands[i] > Math.floor((BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i])) / 2) ||
                        Math.min(BroiPlayer[i], BroiPartner[i]) <= SureHands[i]
                    ) {
                        if (BroiPartner[i] < BroiPlayer[i] && SurePartner[i] > 0 && SurePlayer[i] < SureHands[i]) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (SurePlayer[i] > 0) {
                            for (let j = 0; j < 14; j++) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (SurePartner[i] > 0) {
                            for (let j = 14; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BroiPartner[i] === 0 && BroiPartner[5] > 0) {
                        for (let j = 14; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (
                    let i = 1;
                    i < 5;
                    i++ //tursi impas
                ) {
                    if (
                        BCBestMyCardP[i] === 1 &&
                        BCBestPartCardP[i] === 1 &&
                        BestMyCardP[i] > 0 &&
                        BestPartCardP[i] > 0 &&
                        (BroiPlayer[i] > 1 || BroiPartner[i] > 1)
                    ) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 1 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BCBestPartCardP[i] === 0 && BCThirPartCardP[i] === 1 && ThirPartCardP[i] > 0 && BCSecPartCardP[i] === 1) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (
                        BCBestPartCardP[i] === 0 &&
                        BCThirPartCardP[i] === 1 &&
                        ThirPartCardP[i] > 0 &&
                        BCSecPartCardP[i] === 0 &&
                        BroiOstavashti[i] - (BroiPlayer[i] + BroiPartner[i]) > 5
                    ) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 1 && SecPartCardP[i] > 0) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                let Slizane1 = false;
                for (let i = 1; i < 5; i++) {
                    if (BroiPartner[i] > 0 && BroiPlayer[i] === 0 && BroiPlayer[5] > 0) {
                        for (let i1 = 1; i1 < 5; i1++) {
                            if ((SurePartner[i1] > 0 && BroiPlayer[i1] > 0) || (BroiPartner[5] >= 2 && SurePartner[5] >= 1 && BroiPlayer[5] >= 1)) {
                                Slizane1 = true;
                            }
                        }
                    }
                }
                if (!Slizane1) {
                    for (let i = 1; i < 5; i++) {
                        if (
                            (Math.min(BroiPlayer[i], BroiPartner[i]) <= SureHands[i] - 1 &&
                                BroiPlayer[i] !== BroiPartner[i] &&
                                BroiPlayer[i] < BroiPartner[i] &&
                                BroiPlayer[5] > 0) ||
                            (BroiPartner[i] < BroiPlayer[i] && BroiPartner[5] > 0)
                        ) {
                            if (BroiPartner[i] < BroiPlayer[i] && SurePartner[i] > 0 && SurePlayer[i] < SureHands[i]) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (SurePlayer[i] > 0) {
                                for (let j = 0; j < 14; j++) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (SurePartner[i] > 0) {
                                for (let j = 14; j > 0; j--) {
                                    if (BestCards[i][j][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCSecPartCardP[i] === 1 && BCThirPartCardP[i] === 2 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 1 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 0 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (BCBestPartCardP[i] === 2 && BCSecPartCardP[i] === 2 && BroiPlayer[i] > 0 && SecPartCardP[i] > 0) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    for (let i = 1; i < 5; i++) {
                        if (
                            BCBestPartCardP[i] <= 2 &&
                            ((BCBestMyCardP[i] <= 2 && BCBestMyCardP[i] !== 0) || (BCSecMyCardP[i] <= 2 && BCSecMyCardP[i] !== 0)) &&
                            BroiPlayer[i] > 0 &&
                            BestPartCardP[i] > 0
                        ) {
                            for (let j = 13; j > 0; j--) {
                                if (BestCards[i][j][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                let Slizalka: boolean[] = [false, false, false, false, false];
                let HasSlizalka = 0;
                for (let i = 1; i < 5; i++) {
                    if (SureHands[i] >= 2) {
                        if (SurePartner[i] >= 1) {
                            Slizalka[i] = true;
                            HasSlizalka++;
                        }
                    }
                }
                if (HasSlizalka) {
                    for (let i = 1; i < 5; i++) {
                        if ((HasSlizalka === 1 && BroiPartner[i] - HasSlizalka > 0) || (HasSlizalka > 1 && BroiPartner[i] > 0)) {
                            if (
                                (BCBestMyCardP[i] === 0 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 1)) ||
                                (BCBestMyCardP[i] === 1 && (BCSecMyCardP[i] === 1 || BCThirMyCardP[i] === 2))
                            ) {
                                for (let j = 1; j < 5; j++) {
                                    if (j !== i && Slizalka[j]) {
                                        for (let k = 13; k > 0; k--) {
                                            if (BestCards[j][k][1] === player) {
                                                if (this.PuskaneNaKartaN(BestCards[j][k][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let k = 13; k > 0; k--) {
                                    if (BestCards[i][k][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[i][k][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (Math.max(BroiPlayer[i], BroiPartner[i]) > Math.floor((BroiPlayer[i] + BroiPartner[i]) / 2) + 1) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (SurePlayer[i]) {
                        if (this.PuskaneNaKartaN(BestMyCardP[i], player)) {
                            return;
                        }
                    }
                    if (SurePartner[i]) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BCBestMyCardP[i] >= 2 && BCBestPartCardP[i] >= 2) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                for (let i = 1; i < 5; i++) {
                    if (BCBestPartCardP[i] === 1) {
                        for (let j = 13; j > 0; j--) {
                            if (BestCards[i][j][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[i][j][0], player)) {
                                    return;
                                }
                            }
                        }
                    }
                }
                let idx = 1;
                for (let i = 2; i < 5; i++) {
                    if (BroiPlayer[i] + BroiPartner[i] > BroiPlayer[idx] + BroiPartner[idx]) {
                        idx = i;
                    }
                }
                for (let j = 13; j > 0; j--) {
                    if (BestCards[idx][j][1] === player) {
                        if (this.PuskaneNaKartaN(BestCards[idx][j][0], player)) {
                            return;
                        }
                    }
                }
                this.PuskaneNaKarta((player - 1) * 13 + 1);
                return;
            }
            //////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////Ako Ne E Purvi///////////////////////////////////////
            if (this.ParviPuska > 0) {
                ////////////////////////////////////////this.VtoriPuska///////////////////////////////////////////
                if (this.VtoriPuska === 0) {
                    let BestPartCard = 0;
                    let BestMyCard = 0;
                    let SecMyCard = 0;
                    let ThirMyCard = 0;
                    let BCBestPartCard = 0;
                    let BCBestMyCard = 0;
                    let BCSecMyCard = 0;
                    let BCThirMyCard = 0;
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === Partner) {
                            BestPartCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                            BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                        ) {
                            SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMyCard
                        ) {
                            ThirMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestPartCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCBestPartCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCBestMyCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCSecMyCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > ThirMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCThirMyCard++;
                        }
                    }
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            if (BCBestPartCard === 0 && BestPartCard > this.ParviPuska) {
                                //Puska Niska ili
                                if (BCBestMyCard === 1 && BestMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard === 1 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard === 1 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCBestMyCard === 2 && BestMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard === 2 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard === 2 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (BCBestPartCard >= 1) {
                                if (
                                    BroiSureHands + GetHands >= this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >=
                                        BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            Math.floor(
                                                (BroiPartner[Math.floor(this.ParviPuska / 100)] + BroiPlayer[Math.floor(this.ParviPuska / 100)]) / 2
                                            )
                                ) {
                                    //vzima(ako moje)
                                    if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (BCBestPartCard === 2) {
                                    if (Partner === this.Mor) {
                                        if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (BCBestPartCard >= 2) {
                                    if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BCBestMyCard === 1 && SecMyCard > this.ParviPuska && Partner === this.Mor && SecMyCard <= 2) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            for (
                                let i = 13;
                                i > 0;
                                i-- //igrae nai slaba
                            ) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            if ((BCBestPartCard === 0 && BestPartCard > this.ParviPuska) || BroiPartner[Math.floor(this.ParviPuska / 100)] === 0) {
                                //Puska Niska ili
                                if (BCBestMyCard === 1 && BestMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard === 1 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard === 1 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCBestMyCard === 2 && BestMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCSecMyCard === 2 && SecMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                        return;
                                    }
                                }
                                if (BCThirMyCard === 2 && ThirMyCard > this.ParviPuska) {
                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                            if (BCBestPartCard >= 1) {
                                if (
                                    BroiPartner[Math.floor(this.ParviPuska / 100)] <= 2 ||
                                    BroiPlayer[Math.floor(this.ParviPuska / 100)] <= 2 ||
                                    BroiSureHands + GetHands >= this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >=
                                        BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            Math.floor(
                                                (BroiPartner[Math.floor(this.ParviPuska / 100)] + BroiPlayer[Math.floor(this.ParviPuska / 100)]) / 2
                                            )
                                ) {
                                    //vzima(ako moje)
                                    if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (BCBestPartCard === 2) {
                                    if (Partner === this.Mor) {
                                        if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (BCBestPartCard >= 2) {
                                    if (BCBestMyCard === 0 && BestMyCard > this.ParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                    if (BCBestMyCard === 1 && SecMyCard > this.ParviPuska && Partner === this.Mor && SecMyCard <= 2) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            for (
                                let i = 13;
                                i > 0;
                                i-- //igrae nai slaba
                            ) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        for (
                            let i = 13;
                            i > 0;
                            i-- //igrae nai slaba
                        ) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            this.Chistene(player);
                            return;
                        }
                        if (Math.floor(this.ParviPuska / 100) <= 4) {
                            if (BroiPlayer[5] === 0) {
                                this.Chistene(player);
                                return;
                            } //??
                            else {
                                if (BCBestPartCard === 0 && BestPartCard > this.ParviPuska) {
                                    this.Chistene(player);
                                    return;
                                }
                                if (BroiPartner[Math.floor(this.ParviPuska / 100)] === 0 && BroiPartner[5] > 0 && BroiPartner[5] <= BroiPlayer[5]) {
                                    this.Chistene(player);
                                    return;
                                }
                                for (
                                    let i = 13;
                                    i > 0;
                                    i-- //caka
                                ) {
                                    if (BestCards[5][i][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                            return;
                                        }
                                    }
                                }
                                this.Chistene(player);
                                return;
                            }
                        }
                    }
                }
                /////////////////////////////TretiIgrae////////////////////////////////////////////////
                if (this.VtoriPuska > 0 && this.TretiPuska === 0) {
                    let BestMyCard = 0;
                    let SecMyCard = 0;
                    let ThirMyCard = 0;
                    let BCBestMyCard = 0;
                    let BCSecMyCard = 0;
                    let BCThirMyCard = 0;
                    let BCParviPuska = 0;
                    for (let j = 1; j < 14; j++) {
                        if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                            BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                        ) {
                            SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let j = 1; j < 14; j++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard &&
                            BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== SecMyCard
                        ) {
                            ThirMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                            break;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCBestMyCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > SecMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCSecMyCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > ThirMyCard &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCThirMyCard++;
                        }
                    }
                    for (let i = 1; i < 14; i++) {
                        if (
                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                            (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                        ) {
                            BCParviPuska++;
                        }
                    }
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        if (this.ParviPuska > 500) {
                            //puska koz
                            if (
                                BCParviPuska > 0 ||
                                (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) && this.VtoriPuska > this.ParviPuska)
                            ) {
                                //Ako ne e vlastna
                                if (
                                    BroiSureHands + GetHands >= this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >=
                                        BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            Math.floor(
                                                (BroiPartner[Math.floor(this.ParviPuska / 100)] + BroiPlayer[Math.floor(this.ParviPuska / 100)]) / 2
                                            )
                                ) {
                                    //vzima(ako moje)
                                    if (
                                        (BestMyCard > this.VtoriPuska &&
                                            BestMyCard > this.ParviPuska &&
                                            ((this.ParviPuska < this.VtoriPuska &&
                                                Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) ||
                                                BCParviPuska > BCBestMyCard)) ||
                                        BCBestMyCard === 0
                                    ) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                    if (BCBestMyCard < BCParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) {
                                    if (BestMyCard > this.VtoriPuska) {
                                        if (SecMyCard > this.VtoriPuska) {
                                            if (BCSecMyCard === 1 || BCSecMyCard === 2) {
                                                if (ThirMyCard > this.VtoriPuska && BCThirMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                    return;
                                                }
                                            }
                                        }
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (this.VtoriPuska < this.ParviPuska || Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                    if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                        if (BCBestMyCard < BCParviPuska) {
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                    if (BestMyCard > this.ParviPuska) {
                                        if (BCBestMyCard < BCParviPuska) {
                                            if (BCParviPuska > 1) {
                                                if (BCThirMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (BCSecMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (BCBestMyCard === 1 && (BCParviPuska >= 3 || BCSecMyCard === 1)) {
                                    if (this.PuskaneNaKartaN(BCBestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            if (
                                BCParviPuska > 0 ||
                                (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100) && this.VtoriPuska > this.ParviPuska)
                            ) {
                                //Ako ne e vlastna
                                if (
                                    BroiPartner[Math.floor(this.ParviPuska / 100)] === 0 ||
                                    BroiSureHands + GetHands >= this.level + 6 ||
                                    SureHands[Math.floor(this.ParviPuska / 100)] >=
                                        BroiOstavashti[Math.floor(this.ParviPuska / 100)] -
                                            (BroiPartner[Math.floor(this.ParviPuska / 100)] + BroiPlayer[Math.floor(this.ParviPuska / 100)]) / 2
                                ) {
                                    //vzima(ako moje)
                                    if (
                                        BestMyCard > this.VtoriPuska &&
                                        BestMyCard > this.ParviPuska &&
                                        ((this.ParviPuska < this.VtoriPuska &&
                                            Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) ||
                                            BCParviPuska > BCBestMyCard)
                                    ) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100)) {
                                    if (BCBestMyCard < BCParviPuska) {
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (this.VtoriPuska > this.ParviPuska && Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) {
                                    if (BestMyCard > this.VtoriPuska) {
                                        if (SecMyCard > this.VtoriPuska) {
                                            if (BCSecMyCard === 1 || BCSecMyCard === 2) {
                                                if (ThirMyCard > this.VtoriPuska && BCThirMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                    return;
                                                }
                                            }
                                        }
                                        if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                            return;
                                        }
                                    }
                                }
                                if (
                                    this.VtoriPuska < this.ParviPuska ||
                                    (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) && this.VtoriPuska < 500)
                                ) {
                                    if (
                                        Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                                        BroiPartner[Math.floor(this.ParviPuska / 100)] === 0
                                    ) {
                                        if (BCBestMyCard < BCParviPuska) {
                                            if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                return;
                                            }
                                        }
                                    }
                                    if (BestMyCard > this.ParviPuska) {
                                        if (BCBestMyCard < BCParviPuska) {
                                            if (BCParviPuska > 1) {
                                                if (BCThirMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(ThirMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (BCSecMyCard === 1) {
                                                    if (this.PuskaneNaKartaN(SecMyCard, player)) {
                                                        return;
                                                    }
                                                }
                                                if (this.PuskaneNaKartaN(BestMyCard, player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (BCBestMyCard === 1 && (BCParviPuska >= 3 || BCSecMyCard === 1)) {
                                    if (this.PuskaneNaKartaN(BCBestMyCard, player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        for (
                            let i = 13;
                            i > 0;
                            i-- //igrae nai slaba
                        ) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                    return;
                                }
                            }
                        }
                    } else {
                        if (this.ParviPuska > 500) {
                            this.Chistene(player);
                            return;
                        } //caka ako moje ili propuska
                        else {
                            if (BroiPlayer[5] > 0) {
                                //caka(nadcakva)
                                if (BCParviPuska > 0 || (this.VtoriPuska > this.ParviPuska && this.VtoriPuska < 500) || this.VtoriPuska > 500) {
                                    for (let i = 14; i > 0; i--) {
                                        if (BestCards[5][i][1] === player && BestCards[5][i][0] > this.VtoriPuska) {
                                            if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            } else {
                                this.Chistene(player);
                                return;
                            }
                            this.Chistene(player);
                            return;
                        }
                    }
                }
                ////////////////////////////chetvarti igrae//////////////////////////////////////////
                if (this.TretiPuska > 0 && this.ChetvartiPuska === 0) {
                    if (BroiPlayer[Math.floor(this.ParviPuska / 100)] > 0) {
                        let BestPartCard = 0;
                        let BestMyCard = 0;
                        let SecMyCard = 0;
                        let BCBestMyCard = 0;
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === Partner) {
                                BestPartCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player) {
                                BestMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let j = 1; j < 14; j++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][j][1] === player &&
                                BestCards[Math.floor(this.ParviPuska / 100)][j][0] !== BestMyCard
                            ) {
                                SecMyCard = BestCards[Math.floor(this.ParviPuska / 100)][j][0];
                                break;
                            }
                        }
                        for (let i = 1; i < 14; i++) {
                            if (
                                BestCards[Math.floor(this.ParviPuska / 100)][i][0] > BestMyCard &&
                                (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[1] ||
                                    BestCards[Math.floor(this.ParviPuska / 100)][i][1] === Oponent[2])
                            ) {
                                BCBestMyCard++;
                            }
                        }
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            if (this.VtoriPuska > this.ParviPuska && this.VtoriPuska > this.TretiPuska) {
                                for (let i = 14; i > 0; i--) {
                                    if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (this.ParviPuska > this.VtoriPuska || this.TretiPuska > this.VtoriPuska) {
                                if (
                                    BestMyCard > this.ParviPuska &&
                                    BestMyCard > this.TretiPuska &&
                                    ((SecMyCard > this.ParviPuska && SecMyCard > this.TretiPuska) ||
                                        BCBestMyCard > 0 ||
                                        (BCBestMyCard === 0 && BestPartCard === 0) ||
                                        BroiOstavashti[5] - (BroiPlayer[5] + BroiPartner[5]) <= 2)
                                ) {
                                    for (let i = 14; i > 0; i--) {
                                        if (
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                            BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.TretiPuska
                                        ) {
                                            if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                        if (Math.floor(this.ParviPuska / 100) !== 5) {
                            if (
                                this.VtoriPuska < 500 &&
                                (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) ||
                                    this.ParviPuska > this.VtoriPuska ||
                                    (this.TretiPuska > this.ParviPuska && Math.floor(this.TretiPuska / 100) === Math.floor(this.ParviPuska / 100)))
                            ) {
                                for (let i = 14; i > 0; i--) {
                                    if (
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player &&
                                        BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.ParviPuska &&
                                        (BestCards[Math.floor(this.ParviPuska / 100)][i][0] > this.TretiPuska ||
                                            (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100) && this.TretiPuska < 500))
                                    ) {
                                        if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                            return;
                                        }
                                    }
                                }
                            }
                            for (let i = 14; i > 0; i--) {
                                if (BestCards[Math.floor(this.ParviPuska / 100)][i][1] === player) {
                                    if (this.PuskaneNaKartaN(BestCards[Math.floor(this.ParviPuska / 100)][i][0], player)) {
                                        return;
                                    }
                                }
                            }
                        }
                    } else {
                        if (Math.floor(this.ParviPuska / 100) === 5) {
                            this.Chistene(player);
                            return;
                        } else {
                            if (BroiPlayer[5] > 0) {
                                if (Math.floor(this.VtoriPuska / 100) === Math.floor(this.ParviPuska / 100)) {
                                    if (Math.floor(this.TretiPuska / 100) === Math.floor(this.ParviPuska / 100)) {
                                        if (this.VtoriPuska < this.ParviPuska || this.VtoriPuska < this.TretiPuska) {
                                            for (let i = 14; i > 0; i--) {
                                                if (BestCards[5][i][1] === player) {
                                                    if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (Math.floor(this.TretiPuska / 100) !== Math.floor(this.ParviPuska / 100) && this.TretiPuska < 500) {
                                        if (this.ParviPuska > this.VtoriPuska) {
                                            for (let i = 14; i > 0; i--) {
                                                if (BestCards[5][i][1] === player) {
                                                    if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (this.TretiPuska > 500) {
                                        for (let i = 14; i > 0; i--) {
                                            if (
                                                BestCards[5][i][1] === player &&
                                                BestCards[5][i][0] > this.ParviPuska &&
                                                BestCards[5][i][0] > this.TretiPuska
                                            ) {
                                                if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) && this.VtoriPuska < 500) {
                                    for (let i = 14; i > 0; i--) {
                                        if (
                                            BestCards[5][i][1] === player &&
                                            BestCards[5][i][0] > this.ParviPuska &&
                                            BestCards[5][i][0] > this.TretiPuska
                                        ) {
                                            if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                                if (
                                    Math.floor(this.VtoriPuska / 100) !== Math.floor(this.ParviPuska / 100) &&
                                    this.VtoriPuska > 500 &&
                                    this.TretiPuska > this.VtoriPuska
                                ) {
                                    for (let i = 14; i > 0; i--) {
                                        if (
                                            BestCards[5][i][1] === player &&
                                            BestCards[5][i][0] > this.ParviPuska &&
                                            BestCards[5][i][0] > this.TretiPuska
                                        ) {
                                            if (this.PuskaneNaKartaN(BestCards[5][i][0], player)) {
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                            this.Chistene(player);
                            return;
                        }
                    }
                }
            }
        }
    }

    PribiraneViziatka() {
        this.P1puska = 0;
        this.P2puska = 0;
        this.P3puska = 0;
        this.P4puska = 0;
        this.TimeVzemane = false;
        this.TimeVzemane1 = 1;
        this.Pribirane = false;
        this.setState({});
        this.props.m_pwin.KillTimer(2);
        this.props.m_pwin.SetTimer(2, this.Speed[2] * 100);
    }

    CalcUnderlineResult(Whose: number) {
        let GetHands = 0;
        for (let i = 0; i < 13; i++) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        let pisane1 = 0;
        if (GetHands >= this.level + 6) {
            if (this.Naddavane === 1 || this.Naddavane === 2) {
                pisane1 += this.level * 20;
            }
            if (this.Naddavane === 3 || this.Naddavane === 4) {
                pisane1 += this.level * 30;
            }
            if (this.Naddavane === 5) {
                pisane1 += this.level * 30 + 10;
            }
            if (this.Kontra > 0) {
                pisane1 = pisane1 * 2;
            }
            if (this.Rekontra > 0) {
                pisane1 = pisane1 * 4;
            }
            return pisane1;
        } else {
            return 0;
        }
    }

    CalkImps(pisane: number) {
        if (pisane < 20) {
            return 0;
        }
        if (pisane >= 20 && pisane <= 40) {
            return 1;
        }
        if (pisane >= 50 && pisane <= 80) {
            return 2;
        }
        if (pisane >= 90 && pisane <= 120) {
            return 3;
        }
        if (pisane >= 130 && pisane <= 160) {
            return 4;
        }
        if (pisane >= 170 && pisane <= 210) {
            return 5;
        }
        if (pisane >= 220 && pisane <= 260) {
            return 6;
        }
        if (pisane >= 270 && pisane <= 310) {
            return 7;
        }
        if (pisane >= 320 && pisane <= 360) {
            return 8;
        }
        if (pisane >= 370 && pisane <= 420) {
            return 9;
        }
        if (pisane >= 430 && pisane <= 490) {
            return 10;
        }
        if (pisane >= 500 && pisane <= 590) {
            return 11;
        }
        if (pisane >= 600 && pisane <= 750) {
            return 12;
        }
        if (pisane >= 760 && pisane <= 890) {
            return 13;
        }
        if (pisane >= 900 && pisane <= 1090) {
            return 14;
        }
        if (pisane >= 1100 && pisane <= 1290) {
            return 15;
        }
        if (pisane >= 1300 && pisane <= 1490) {
            return 16;
        }
        if (pisane >= 1500 && pisane <= 1790) {
            return 17;
        }
        if (pisane >= 1800 && pisane <= 1990) {
            return 18;
        }
        if (pisane >= 2000 && pisane <= 2240) {
            return 19;
        }
        if (pisane >= 2250 && pisane <= 2490) {
            return 20;
        }
        if (pisane >= 2500) {
            return 21;
        }
        return 0;
    }

    PisaneGameCurentResult() {
        let Whose = 2 - (this.LastSays[0] % 2);
        let GetHands = 0;
        if (this.BrPas === 4) {
            GetHands = 6;
        }
        for (let i = 0; i < 13; i++) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        let s1, s2, s3, s11, s12;
        if (Whose === 1) {
            s1 = this.Name[1] + '/' + this.Name[3];
        }
        if (Whose === 2) {
            s1 = this.Name[2] + '/' + this.Name[4];
        }
        if (this.level === 0 && this.Naddavane === 0) {
            s2 = 'Pass';
        }
        if (this.level === 1) {
            if (this.Naddavane === 1) {
                s2 = 'Club';
            }
            if (this.Naddavane === 2) {
                s2 = 'Diamond';
            }
            if (this.Naddavane === 3) {
                s2 = 'Heart';
            }
            if (this.Naddavane === 4) {
                s2 = 'Spade';
            }
            if (this.Naddavane === 5) {
                s2 = 'No Trump';
            }
        }
        if (this.level > 1) {
            if (this.Naddavane === 1) {
                s2 = 'Clubs';
            }
            if (this.Naddavane === 2) {
                s2 = 'Diamonds';
            }
            if (this.Naddavane === 3) {
                s2 = 'Hearts';
            }
            if (this.Naddavane === 4) {
                s2 = 'Spades';
            }
            if (this.Naddavane === 5) {
                s2 = 'No Trump';
            }
        }
        if (GetHands > this.level + 6) {
            s3 = this.GetLanguageText('Made') + ' ' + this.IntToString(GetHands - (this.level + 6)) + ' ';
            if (GetHands - (this.level + 6) === 1) {
                s3 += this.GetLanguageText('overtrick');
            }
            if (GetHands - (this.level + 6) > 1) {
                s3 += this.GetLanguageText('overtricks');
            }
        }
        if (GetHands === this.level + 6) {
            s3 = this.GetLanguageText('Made the contract');
        }
        if (GetHands < this.level + 6) {
            s3 = this.GetLanguageText('Were set by') + ' ' + this.IntToString(this.level + 6 - GetHands) + ' ';
            if (this.level + 6 - GetHands === 1) {
                s3 += this.GetLanguageText('trick');
            }
            if (this.level + 6 - GetHands > 1) {
                s3 += this.GetLanguageText('tricks');
            }
        }
        if (this.level === 0 && this.Naddavane === 0) {
            s11 = this.GetLanguageText('Contract') + ':' + this.GetLanguageText(s2);
            s3 = '';
            s1 = '';
        } else {
            s11 =
                this.GetLanguageText('Contract') +
                ':' +
                this.IntToString(this.level) +
                ' ' +
                this.GetLanguageText(s2) +
                ' ' +
                this.GetLanguageText('said by') +
                ' ' +
                s1;
        }
        s12 = s1 + ' ' + s3;
        this.GameResult = s11 + '\n' + s12;
        if (this.type === 2) {
            this.GameResult += '\n' + this.GetLanguageText('Duty') + ':';
            let duty: number;
            if (Whose === 1) {
                if (this.Zaduljenie1 > 0) {
                    this.GameResult += this.IntToString(this.Zaduljenie1);
                    duty = this.Zaduljenie1;
                } else {
                    this.GameResult += this.IntToString(-this.Zaduljenie2);
                    duty = -this.Zaduljenie2;
                }
            }
            if (Whose === 2) {
                if (this.Zaduljenie2 > 0) {
                    this.GameResult += this.IntToString(this.Zaduljenie2);
                    duty = this.Zaduljenie2;
                } else {
                    this.GameResult += this.IntToString(-this.Zaduljenie1);
                    duty = -this.Zaduljenie1;
                }
            }
            this.GameResult += '\n' + this.GetLanguageText('Made') + ':';
            this.GameResult += this.IntToString(this.Izkarani) + '\n';
            this.GameResult += this.GetLanguageText('Write') + ':';
            let pisane = this.Izkarani - duty;
            let imps: number;
            if (pisane >= 0) {
                imps = this.CalkImps(pisane);
            } else {
                imps = -this.CalkImps(-pisane);
            }
            this.GameResult += this.IntToString(imps);
            if (imps !== 1 && imps !== -1) {
                this.GameResult += ' ' + this.GetLanguageText('Imps');
            } else {
                this.GameResult += ' ' + this.GetLanguageText('Imp');
            }
            if (Whose === 1) {
                this.imp_result.result1[2][this.BroiIgri - 1] =
                    this.Name[1] +
                    '/' +
                    this.Name[3] +
                    ' ' +
                    this.GetLanguageText('Made') +
                    ': ' +
                    this.IntToString(imps) +
                    ' ' +
                    this.GetLanguageText('Imps');
                this.impove += imps;
            }
            if (Whose === 2) {
                this.imp_result.result1[2][this.BroiIgri - 1] =
                    this.Name[2] +
                    '/' +
                    this.Name[4] +
                    ' ' +
                    this.GetLanguageText('Made') +
                    ': ' +
                    this.IntToString(imps) +
                    ' ' +
                    this.GetLanguageText('Imps');
                this.impove -= imps;
            }
            if (this.impove > 0) {
                this.imp_result.result1[0][this.BroiIgri - 1] = this.IntToString(this.impove);
                this.imp_result.result1[1][this.BroiIgri - 1] = '0';
            }
            if (this.impove < 0) {
                this.imp_result.result1[1][this.BroiIgri - 1] = this.IntToString(-this.impove);
                this.imp_result.result1[0][this.BroiIgri - 1] = '0';
            }
            if (this.impove === 0) {
                this.imp_result.result1[0][this.BroiIgri - 1] = '0';
                this.imp_result.result1[1][this.BroiIgri - 1] = '0';
            }
        }
    }

    PisaneRubber() {
        let zone1 = this.zone;
        let zone2;
        this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2 - (this.LastSays[0] % 2) - 1] = this.CalcUnderlineResult(
            2 - (this.LastSays[0] % 2)
        );
        if (this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2 - (this.LastSays[0] % 2) - 1] > 0) {
            this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] += this.level;
            this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] += this.Naddavane * 10;
            if (this.Kontra !== 0) {
                this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] += 100;
            }
            if (this.Rekontra !== 0) {
                this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] += 200;
            }
            this.rubber_result.BroiUnderResult++;
            let suma1 = 0,
                suma2 = 0;
            for (let i = 0; i < this.rubber_result.BroiUnderResult; i++) {
                if (this.rubber_result.UnderResult[i][2] === -1) {
                    suma1 = 0;
                    suma2 = 0;
                } else {
                    suma1 += this.rubber_result.UnderResult[i][0];
                    suma2 += this.rubber_result.UnderResult[i][1];
                }
            }
            if (suma1 >= 100) {
                this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] = -1;
                this.rubber_result.BroiUnderResult++;
                if (this.zone === 1 || this.zone === 3) {
                    this.Rubber = true;
                }
                if (this.zone === 0) {
                    this.zone = 1;
                }
                if (this.zone === 2) {
                    this.zone = 3;
                }
            }
            if (suma2 >= 100) {
                this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][2] = -1;
                this.rubber_result.BroiUnderResult++;
                if (this.zone === 2 || this.zone === 3) {
                    this.Rubber = true;
                }
                if (this.zone === 0) {
                    this.zone = 2;
                }
                if (this.zone === 1) {
                    this.zone = 3;
                }
            }
        }
        zone2 = this.zone;
        this.zone = zone1;
        //////////////////////////////////////////////////////////////////////////////////
        let Whose = 2 - (this.LastSays[0] % 2);
        let GetHands = 0;
        for (let i = 0; i < 13; i++) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        if (GetHands > this.level + 6) {
            let dobri = GetHands - (this.level + 6);
            let pisane = 0;
            if (this.zone !== Whose && this.zone !== 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.Naddavane === 1 || this.Naddavane === 2) {
                        pisane = 20 * dobri;
                    } else {
                        pisane = 30 * dobri;
                    }
                }
                if (this.Kontra > 0) {
                    pisane = 100 * dobri;
                }
                if (this.Rekontra > 0) {
                    pisane = 200 * dobri;
                }
            }
            if (this.zone === Whose || this.zone === 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    if (this.Naddavane === 1 || this.Naddavane === 2) {
                        pisane = 20 * dobri;
                    } else {
                        pisane = 30 * dobri;
                    }
                }
                if (this.Kontra > 0) {
                    pisane = 200 * dobri;
                }
                if (this.Rekontra > 0) {
                    pisane = 400 * dobri;
                }
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = pisane;
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += dobri;
            if (this.zone === Whose || this.zone === 3) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 10;
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 100;
            if (this.Kontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 100;
            }
            if (this.Rekontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 200;
            }
            this.rubber_result.BroiUpResult++;
        }
        /////////////////////////////
        if (GetHands < this.level + 6) {
            let vutre6ni = this.level + 6 - GetHands;
            let pisane = 0;
            if (this.zone !== Whose && this.zone !== 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    pisane = 50 * vutre6ni;
                }
                if (this.Kontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 100 * vutre6ni;
                    }
                    if (vutre6ni === 2 || vutre6ni === 3) {
                        pisane = 200 * vutre6ni - 100;
                    }
                    if (vutre6ni > 3) {
                        pisane = 300 * vutre6ni - 400;
                    }
                }
                if (this.Rekontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 200 * vutre6ni;
                    }
                    if (vutre6ni === 2 || vutre6ni === 3) {
                        pisane = 400 * vutre6ni - 200;
                    }
                    if (vutre6ni > 3) {
                        pisane = 600 * vutre6ni - 800;
                    }
                }
            }
            if (this.zone === Whose || this.zone === 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    pisane = 100 * vutre6ni;
                }
                if (this.Kontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 200 * vutre6ni;
                    }
                    if (vutre6ni > 1) {
                        pisane = 300 * vutre6ni - 100;
                    }
                }
                if (this.Rekontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 400 * vutre6ni;
                    }
                    if (vutre6ni > 1) {
                        pisane = 600 * vutre6ni - 200;
                    }
                }
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2 - Whose] = pisane;
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += vutre6ni;
            if (vutre6ni > 9) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 10;
            }
            if (this.zone === Whose || this.zone === 3) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 10;
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 100;
            if (this.Kontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 100;
            }
            if (this.Rekontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 200;
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] += 1000;
            this.rubber_result.BroiUpResult++;
        }
        //////////////////////
        if (GetHands >= this.level + 6) {
            if (this.level === 6) {
                if (this.zone !== Whose && this.zone !== 3) {
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 500;
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 20;
                    this.rubber_result.BroiUpResult++;
                }
                if (this.zone === Whose || this.zone === 3) {
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 750;
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 21;
                    this.rubber_result.BroiUpResult++;
                }
            }
            if (this.level === 7) {
                if (this.zone !== Whose && this.zone !== 3) {
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 1000;
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 30;
                    this.rubber_result.BroiUpResult++;
                }
                if (this.zone === Whose || this.zone === 3) {
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 1500;
                    this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 31;
                    this.rubber_result.BroiUpResult++;
                }
            }
            if (this.Kontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 50;
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 40;
                this.rubber_result.BroiUpResult++;
            }
            if (this.Rekontra > 0) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 100;
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 50;
                this.rubber_result.BroiUpResult++;
            }
        }
        ///////////////////
        let broi: number[] = [0, 0, 0, 0];
        for (let i = 1; i < 53; i++) {
            if (this.Naddavane === 5) {
                if (this.NachalniKarti[i] % 100 === 14) {
                    broi[Math.floor((i - 1 - ((i - 1) % 13)) / 13)]++;
                }
            }
            if (this.Naddavane < 5 && this.Naddavane > 0) {
                if (this.NachalniKarti[i] % 100 > 9 && Math.floor((this.NachalniKarti[i] - (this.NachalniKarti[i] % 100)) / 100) === this.Naddavane) {
                    broi[Math.floor((i - 1 - ((i - 1) % 13)) / 13)]++;
                }
            }
        }
        if (broi[0] === 4 || broi[2] === 4) {
            if (this.Naddavane < 5) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][0] = 100;
            }
            if (this.Naddavane === 5) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][0] = 150;
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 60;
            this.rubber_result.BroiUpResult++;
        }
        if (broi[1] === 4 || broi[3] === 4) {
            if (this.Naddavane < 5) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][1] = 100;
            }
            if (this.Naddavane === 5) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][1] = 150;
            }
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 60;
            this.rubber_result.BroiUpResult++;
        }
        if (broi[0] === 5 || broi[2] === 5) {
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][0] = 150;
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 60;
            this.rubber_result.BroiUpResult++;
        }
        if (broi[1] === 5 || broi[3] === 5) {
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][1] = 150;
            this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 60;
            this.rubber_result.BroiUpResult++;
        }
        //////////////////////////
        if (this.Rubber) {
            if (this.zone === 3) {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 500;
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 10;
                this.rubber_result.BroiUpResult++;
            } else {
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][Whose - 1] = 700;
                this.rubber_result.UpResult[this.rubber_result.BroiUpResult][2] = 10;
                this.rubber_result.BroiUpResult++;
            }
        }
        this.zone = zone2;
        this.setState({});
    }

    GetPointsEndFits() {
        this.points1 = 0;
        this.points2 = 0;
        this.points3 = 0;
        this.points4 = 0;
        this.fits1 = 0;
        this.fits2 = 0;
        let BroiVBoia = [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ];
        for (let i = 1; i < 53; i++) {
            if (i < 14 && i > 0) {
                if (this.NachalniKarti[i] % 100 === 14) {
                    this.points1 += 4;
                }
                if (this.NachalniKarti[i] % 100 === 13) {
                    this.points1 += 3;
                }
                if (this.NachalniKarti[i] % 100 === 12) {
                    this.points1 += 2;
                }
                if (this.NachalniKarti[i] % 100 === 11) {
                    this.points1 += 1;
                }
            }
            if (i < 27 && i > 13) {
                if (this.NachalniKarti[i] % 100 === 14) {
                    this.points2 += 4;
                }
                if (this.NachalniKarti[i] % 100 === 13) {
                    this.points2 += 3;
                }
                if (this.NachalniKarti[i] % 100 === 12) {
                    this.points2 += 2;
                }
                if (this.NachalniKarti[i] % 100 === 11) {
                    this.points2 += 1;
                }
            }
            if (i < 40 && i > 26) {
                if (this.NachalniKarti[i] % 100 === 14) {
                    this.points3 += 4;
                }
                if (this.NachalniKarti[i] % 100 === 13) {
                    this.points3 += 3;
                }
                if (this.NachalniKarti[i] % 100 === 12) {
                    this.points3 += 2;
                }
                if (this.NachalniKarti[i] % 100 === 11) {
                    this.points3 += 1;
                }
            }
            if (i < 53 && i > 39) {
                if (this.NachalniKarti[i] % 100 === 14) {
                    this.points4 += 4;
                }
                if (this.NachalniKarti[i] % 100 === 13) {
                    this.points4 += 3;
                }
                if (this.NachalniKarti[i] % 100 === 12) {
                    this.points4 += 2;
                }
                if (this.NachalniKarti[i] % 100 === 11) {
                    this.points4 += 1;
                }
            }
        }
        for (let i = 0; i < 53; i++) {
            if ((i < 53 && i > 39) || (i < 27 && i > 13)) {
                BroiVBoia[1][Math.floor((this.NachalniKarti[i] - (this.NachalniKarti[i] % 100)) / 100) - 1]++;
            }
            if ((i < 40 && i > 26) || (i < 14 && i > 0)) {
                BroiVBoia[0][Math.floor((this.NachalniKarti[i] - (this.NachalniKarti[i] % 100)) / 100) - 1]++;
            }
        }
        for (let i = 0; i < 4; i++) {
            if (BroiVBoia[0][i] > 7) {
                this.fits1++;
            }
            if (BroiVBoia[1][i] > 7) {
                this.fits2++;
            }
        }
    }

    CalkZaduljenie(fits: number, zone1: number, points: number) {
        if (zone1 === 1) {
            if (fits === 0) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 0;
                }
                if (points === 21) {
                    return 70;
                }
                if (points === 22) {
                    return 80;
                }
                if (points === 23) {
                    return 80;
                }
                if (points === 24) {
                    return 170;
                }
                if (points === 25) {
                    return 190;
                }
                if (points === 26) {
                    return 400;
                }
                if (points === 27) {
                    return 400;
                }
                if (points === 28) {
                    return 420;
                }
                if (points === 29) {
                    return 450;
                }
                if (points === 30) {
                    return 460;
                }
                if (points === 31) {
                    return 460;
                }
                if (points === 32) {
                    return 480;
                }
                if (points === 33) {
                    return 950;
                }
                if (points === 34) {
                    return 980;
                }
                if (points === 35) {
                    return 980;
                }
                if (points === 36) {
                    return 990;
                }
                if (points === 37) {
                    return 1450;
                }
                if (points === 38) {
                    return 1480;
                }
                if (points > 38) {
                    return 1500;
                }
            }
            if (fits === 1) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 80;
                }
                if (points === 21) {
                    return 110;
                }
                if (points === 22) {
                    return 110;
                }
                if (points === 23) {
                    return 170;
                }
                if (points === 24) {
                    return 190;
                }
                if (points === 25) {
                    return 400;
                }
                if (points === 26) {
                    return 410;
                }
                if (points === 27) {
                    return 420;
                }
                if (points === 28) {
                    return 430;
                }
                if (points === 29) {
                    return 460;
                }
                if (points === 30) {
                    return 460;
                }
                if (points === 31) {
                    return 920;
                }
                if (points === 32) {
                    return 960;
                }
                if (points === 33) {
                    return 980;
                }
                if (points === 34) {
                    return 980;
                }
                if (points === 35) {
                    return 980;
                }
                if (points === 36) {
                    return 1450;
                }
                if (points === 37) {
                    return 1480;
                }
                if (points === 38) {
                    return 1480;
                }
                if (points > 38) {
                    return 1500;
                }
            }
            if (fits > 1) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 110;
                }
                if (points === 21) {
                    return 170;
                }
                if (points === 22) {
                    return 190;
                }
                if (points === 23) {
                    return 300;
                }
                if (points === 24) {
                    return 400;
                }
                if (points === 25) {
                    return 410;
                }
                if (points === 26) {
                    return 420;
                }
                if (points === 27) {
                    return 450;
                }
                if (points === 28) {
                    return 460;
                }
                if (points === 29) {
                    return 480;
                }
                if (points === 30) {
                    return 950;
                }
                if (points === 31) {
                    return 980;
                }
                if (points === 32) {
                    return 980;
                }
                if (points === 33) {
                    return 990;
                }
                if (points === 34) {
                    return 1450;
                }
                if (points === 35) {
                    return 1480;
                }
                if (points === 36) {
                    return 1480;
                }
                if (points === 37) {
                    return 1480;
                }
                if (points === 38) {
                    return 1500;
                }
                if (points > 38) {
                    return 1500;
                }
            }
        }
        ////////////////////////////////
        if (zone1 === 2) {
            if (fits === 0) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 0;
                }
                if (points === 21) {
                    return 70;
                }
                if (points === 22) {
                    return 80;
                }
                if (points === 23) {
                    return 80;
                }
                if (points === 24) {
                    return 270;
                }
                if (points === 25) {
                    return 290;
                }
                if (points === 26) {
                    return 600;
                }
                if (points === 27) {
                    return 600;
                }
                if (points === 28) {
                    return 620;
                }
                if (points === 29) {
                    return 650;
                }
                if (points === 30) {
                    return 660;
                }
                if (points === 31) {
                    return 660;
                }
                if (points === 32) {
                    return 680;
                }
                if (points === 33) {
                    return 1370;
                }
                if (points === 34) {
                    return 1400;
                }
                if (points === 35) {
                    return 1400;
                }
                if (points === 36) {
                    return 1440;
                }
                if (points === 37) {
                    return 2150;
                }
                if (points === 38) {
                    return 2180;
                }
                if (points > 38) {
                    return 2200;
                }
            }
            if (fits === 1) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 80;
                }
                if (points === 21) {
                    return 110;
                }
                if (points === 22) {
                    return 170;
                }
                if (points === 23) {
                    return 210;
                }
                if (points === 24) {
                    return 290;
                }
                if (points === 25) {
                    return 600;
                }
                if (points === 26) {
                    return 610;
                }
                if (points === 27) {
                    return 620;
                }
                if (points === 28) {
                    return 630;
                }
                if (points === 29) {
                    return 660;
                }
                if (points === 30) {
                    return 660;
                }
                if (points === 31) {
                    return 1370;
                }
                if (points === 32) {
                    return 1400;
                }
                if (points === 33) {
                    return 1400;
                }
                if (points === 34) {
                    return 1400;
                }
                if (points === 35) {
                    return 1430;
                }
                if (points === 36) {
                    return 2150;
                }
                if (points === 37) {
                    return 2180;
                }
                if (points === 38) {
                    return 2180;
                }
                if (points > 38) {
                    return 2200;
                }
            }
            if (fits > 1) {
                if (points < 20) {
                    return 0;
                }
                if (points === 20) {
                    return 190;
                }
                if (points === 21) {
                    return 270;
                }
                if (points === 22) {
                    return 290;
                }
                if (points === 23) {
                    return 400;
                }
                if (points === 24) {
                    return 600;
                }
                if (points === 25) {
                    return 610;
                }
                if (points === 26) {
                    return 620;
                }
                if (points === 27) {
                    return 650;
                }
                if (points === 28) {
                    return 660;
                }
                if (points === 29) {
                    return 680;
                }
                if (points === 30) {
                    return 1370;
                }
                if (points === 31) {
                    return 1400;
                }
                if (points === 32) {
                    return 1430;
                }
                if (points === 33) {
                    return 1430;
                }
                if (points === 34) {
                    return 2150;
                }
                if (points === 35) {
                    return 2180;
                }
                if (points === 36) {
                    return 2180;
                }
                if (points === 37) {
                    return 2180;
                }
                if (points === 38) {
                    return 2200;
                }
                if (points > 38) {
                    return 2200;
                }
            }
        }
        return 5;
    }

    CalcZadaljenie() {
        if (this.zone === 1 || this.zone === 3) {
            this.Zaduljenie1 = this.CalkZaduljenie(this.fits1, 2, this.points1 + this.points3);
        }
        if (this.zone === 2 || this.zone === 0) {
            this.Zaduljenie1 = this.CalkZaduljenie(this.fits1, 1, this.points1 + this.points3);
        }
        if (this.zone === 2 || this.zone === 3) {
            this.Zaduljenie2 = this.CalkZaduljenie(this.fits2, 2, this.points2 + this.points4);
        }
        if (this.zone === 1 || this.zone === 0) {
            this.Zaduljenie2 = this.CalkZaduljenie(this.fits2, 1, this.points2 + this.points4);
        }
    }

    CalcIzkarani() {
        this.Izkarani = 0;
        let Whose = 2 - (this.LastSays[0] % 2);
        let GetHands = 0;
        for (let i = 0; i < 13; i++) {
            if (this.hand1[i] === Whose) {
                GetHands++;
            }
        }
        if (GetHands >= this.level + 6) {
            if (this.Naddavane === 1 || this.Naddavane === 2) {
                this.Izkarani += 20 * this.level;
            }
            if (this.Naddavane === 3 || this.Naddavane === 4) {
                this.Izkarani += 30 * this.level;
            }
            if (this.Naddavane === 5) {
                this.Izkarani += 30 * this.level + 10;
            }
            if (this.level === 1 || (this.Naddavane !== 5 && this.level === 2)) {
                this.Izkarani += 50;
            }
            if (
                ((this.Naddavane === 1 || this.Naddavane === 2) && (this.level === 3 || this.level === 4)) ||
                ((this.Naddavane === 3 || this.Naddavane === 4) && this.level === 3) ||
                (this.Naddavane === 5 && this.level === 2)
            ) {
                if (this.zone === 3 || this.zone === Whose) {
                    this.Izkarani += 200;
                } else {
                    this.Izkarani += 100;
                }
            }
            if (
                ((this.Naddavane === 1 || this.Naddavane === 2) && this.level >= 5) ||
                ((this.Naddavane === 3 || this.Naddavane === 4) && this.level >= 4) ||
                (this.Naddavane === 5 && this.level >= 3)
            ) {
                if (this.zone === 3 || this.zone === Whose) {
                    this.Izkarani += 500;
                } else {
                    this.Izkarani += 300;
                }
            }
            if (this.level === 6) {
                if (this.zone === 3 || this.zone === Whose) {
                    this.Izkarani += 750;
                } else {
                    this.Izkarani += 500;
                }
            }
            if (this.level === 7) {
                if (this.zone === 3 || this.zone === Whose) {
                    this.Izkarani += 1500;
                } else {
                    this.Izkarani += 1000;
                }
            }
            if (this.Kontra > 0) {
                this.Izkarani += 50;
            }
            if (this.Rekontra > 0) {
                this.Izkarani += 100;
            }
        }
        if (GetHands < this.level + 6) {
            let vutre6ni = this.level + 6 - GetHands;
            let pisane = 0;
            if (this.zone !== Whose && this.zone !== 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    pisane = 50 * vutre6ni;
                }
                if (this.Kontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 100 * vutre6ni;
                    }
                    if (vutre6ni === 2 || vutre6ni === 3) {
                        pisane = 200 * vutre6ni - 100;
                    }
                    if (vutre6ni > 3) {
                        pisane = 300 * vutre6ni - 400;
                    }
                }
                if (this.Rekontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 200 * vutre6ni;
                    }
                    if (vutre6ni === 2 || vutre6ni === 3) {
                        pisane = 400 * vutre6ni - 200;
                    }
                    if (vutre6ni > 3) {
                        pisane = 600 * vutre6ni - 800;
                    }
                }
            }
            if (this.zone === Whose || this.zone === 3) {
                if (this.Kontra === 0 && this.Rekontra === 0) {
                    pisane = 100 * vutre6ni;
                }
                if (this.Kontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 200 * vutre6ni;
                    }
                    if (vutre6ni > 1) {
                        pisane = 300 * vutre6ni - 100;
                    }
                }
                if (this.Rekontra > 0) {
                    if (vutre6ni === 1) {
                        pisane = 400 * vutre6ni;
                    }
                    if (vutre6ni > 1) {
                        pisane = 600 * vutre6ni - 200;
                    }
                }
            }
            this.Izkarani = this.Izkarani - pisane;
        }
        if (GetHands > this.level + 6) {
            if (this.Kontra === 0 && this.Rekontra === 0) {
                if (this.Naddavane === 1 || this.Naddavane === 2) {
                    this.Izkarani += 20 * (GetHands - (this.level + 6));
                }
                if (this.Naddavane === 3 || this.Naddavane === 4 || this.Naddavane === 5) {
                    this.Izkarani += 30 * (GetHands - (this.level + 6));
                }
            }
            if (this.zone === 3 || this.zone === Whose) {
                if (this.Kontra > 0) {
                    this.Izkarani += 200 * (GetHands - (this.level + 6));
                }
                if (this.Rekontra > 0) {
                    this.Izkarani += 400 * (GetHands - (this.level + 6));
                }
            } else {
                if (this.Kontra > 0) {
                    this.Izkarani += 100 * (GetHands - (this.level + 6));
                }
                if (this.Rekontra > 0) {
                    this.Izkarani += 200 * (GetHands - (this.level + 6));
                }
            }
        }
        if (this.BrPas === 4) {
            this.Izkarani = 0;
        }
    }

    async InitRezDlg() {
        let FName: string[] = [];
        for (let i = 1; i <= this.Players; i++) {
            FName[i] = this.Name[i];
        }
        this.online_timer = this.online_timer_time;
        if (this.OP && !this.watch) {
            if (
                (this.type === 2 && this.BroiIgri === 1) ||
                (this.type === 1 && (this.rubber_result.BroiUnderResult > 0 || this.rubber_result.BroiUpResult > 0))
            ) {
                if (!this.op_start) {
                    this.props.m_pwin.SendGame('L');
                    this.op_start = true;
                }
            }
        }
        if (this.type === 1) {
            this.Faza = 9;
            this.PisaneGameCurentResult();
            this.PisaneRubber();
            if (this.OP) {
                let s1 = 0,
                    s2 = 0;
                for (let i = 0; i < this.rubber_result.BroiUnderResult; i++) {
                    s1 += this.rubber_result.UnderResult[i][0];
                    s2 += this.rubber_result.UnderResult[i][1];
                }
                for (let i = 0; i < this.rubber_result.BroiUpResult; i++) {
                    s1 += this.rubber_result.UpResult[i][0];
                    s2 += this.rubber_result.UpResult[i][1];
                }
                if (!this.watch) {
                    let rezult = 'X|' + this.IntToString(s1) + ' : ' + this.IntToString(s2);
                    this.props.m_pwin.SendGame(rezult);
                }
            }
            if (!this.watch) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GameResult);
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            if (this.Rubber) {
                this.rubber_result.FinalResult();
            }
            this.open_rez_dlg = true;
            this.online_timer = this.online_timer_time;
            let GetHands = 0;
            let Whose = 2 - (this.LastSays[0] % 2);
            for (let i = 0; i < 13; i++) {
                if (this.hand1[i] === Whose) {
                    GetHands++;
                }
            }
            this.rubber_result.GetHands = GetHands;
            this.rubber_result.level = this.level;
            this.rubber_result.Whose = Whose;
            this.rubber_result.zone = this.zone;
            if (this.props.m_pwin.state.CRubberResultVisible) {
                this.rubber_result.DeleteAllItems();
                this.rubber_result.AddRezults();
            } else {
                if (!this.watch) {
                    await this.rubber_result.DoModal();
                }
            }
            this.open_rez_dlg = false;
            if (this.Rubber) {
                let s1 = 0,
                    s2 = 0;
                let rezult = '';
                let win = 1;
                for (let i = 0; i < this.rubber_result.BroiUnderResult; i++) {
                    s1 += this.rubber_result.UnderResult[i][0];
                    s2 += this.rubber_result.UnderResult[i][1];
                }
                for (let i = 0; i < this.rubber_result.BroiUpResult; i++) {
                    s1 += this.rubber_result.UpResult[i][0];
                    s2 += this.rubber_result.UpResult[i][1];
                }
                if (!this.watch) {
                    if (s1 > s2) {
                        rezult = '$|n|1|' + this.IntToString(s1) + ' : ' + this.IntToString(s2);
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                            this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                        );
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    }
                    if (s1 < s2) {
                        rezult = '$|n|0|' + this.IntToString(s1) + ' : ' + this.IntToString(s2);
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                            this.GetLanguageText('The game profit') + ' ' + FName[2] + ' ' + this.GetLanguageText('and') + ' ' + FName[4]
                        );
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        win = 2;
                    }
                    if (s1 === s2) {
                        if (this.rubber_result.UnderResult[this.rubber_result.BroiUnderResult][0] > 0) {
                            rezult = '$|n|1|' + this.IntToString(s1) + ' : ' + this.IntToString(s2);
                            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                            );
                            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        } else {
                            win = 2;
                            rezult = '$|n|0|' + this.IntToString(s1) + ' : ' + this.IntToString(s2);
                            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                                this.GetLanguageText('The game profit') + ' ' + FName[2] + ' ' + this.GetLanguageText('and') + ' ' + FName[4]
                            );
                            await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        }
                    }
                    let br1_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Bridge Rubber', '');
                    let crezult = '';
                    let ctime = this.getCDateTime();
                    let player1 = FName[1];
                    let player2 = FName[2];
                    let player3 = FName[3];
                    let player4 = FName[4];
                    crezult =
                        ctime +
                        ' ' +
                        player1 +
                        ' ' +
                        this.GetLanguageText('and') +
                        ' ' +
                        player3 +
                        ' ' +
                        this.GetLanguageText('against') +
                        ' ' +
                        player2 +
                        ' ' +
                        this.GetLanguageText('and') +
                        ' ' +
                        player4 +
                        ' ';
                    crezult = crezult + this.GetLanguageText('scoring') + ' : ' + this.IntToString(s1) + ' - ' + this.IntToString(s2);
                    if (win === 1) {
                        crezult = crezult + 'w;';
                    } else {
                        crezult = crezult + 'l;';
                    }
                    await this.WriteProfileString('Options', 'Bridge Rubber', br1_rezult + crezult);
                    if (this.OP) {
                        this.props.m_pwin.SendGame(rezult);
                    }
                    this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                    if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                        if (this.OP) {
                            rezult = 'X|0 : 0';
                            this.props.m_pwin.SendGame(rezult);
                        }
                        this.InitNewGame();
                    } else {
                        if (this.OP) {
                            this.props.m_pwin.GoToLobby();
                        } else {
                            this.props.m_pwin.OnNewGame();
                        }
                        this.props.m_pwin.showAd();
                        return;
                    }
                } else {
                    this.InitNewGame();
                }
            } else {
                if (this.SP) {
                    this.InitGame();
                }
            }
        }
        if (this.type === 2) {
            let rezult = '';
            this.Faza = 9;
            this.GetPointsEndFits();
            this.CalcZadaljenie();
            this.CalcIzkarani();
            this.PisaneGameCurentResult();
            this.setState({});
            if (this.OP) {
                let rez1 = 0;
                let rez2 = 0;
                if (this.impove > 0) {
                    rez1 = this.impove;
                } else {
                    rez2 = -this.impove;
                }
                if (!this.watch) {
                    rezult = 'X|' + this.IntToString(rez1) + ' : ' + this.IntToString(rez2);
                    this.props.m_pwin.SendGame(rezult);
                }
            }
            if (!this.watch) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GameResult);
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.open_rez_dlg = true;
            this.online_timer = this.online_timer_time;
            if (this.props.m_pwin.state.CImpResultVisible) {
                this.imp_result.DeleteAllItems();
                this.imp_result.AddRezults();
            } else {
                if (!this.watch) {
                    await this.imp_result.DoModal();
                }
            }

            this.open_rez_dlg = false;
            if (this.BroiIgri >= 8 && this.impove !== 0) {
                let rez1 = 0;
                let rez2 = 0;
                let win = 1;
                if (this.impove > 0) {
                    rez1 = this.impove;
                    rezult = '$|n|1|' + this.IntToString(rez1) + ' : ' + this.IntToString(rez2);
                    if (!this.watch) {
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                            this.GetLanguageText('The game profit') + ' ' + FName[1] + ' ' + this.GetLanguageText('and') + ' ' + FName[3]
                        );
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    }
                } else {
                    win = 2;
                    rez2 = -this.impove;
                    rezult = '$|n|0|' + this.IntToString(rez1) + ' : ' + this.IntToString(rez2);
                    if (!this.watch) {
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                            this.GetLanguageText('The game profit') + ' ' + FName[2] + ' ' + this.GetLanguageText('and') + ' ' + FName[4]
                        );
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    }
                }
                if (!this.watch) {
                    let br2_rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Duplicate Bridge', '');
                    let crezult = '';
                    let ctime = this.getCDateTime();
                    let player1 = FName[1];
                    let player2 = FName[2];
                    let player3 = FName[3];
                    let player4 = FName[4];
                    crezult =
                        ctime +
                        ' ' +
                        player1 +
                        ' ' +
                        this.GetLanguageText('and') +
                        ' ' +
                        player3 +
                        ' ' +
                        this.GetLanguageText('against') +
                        ' ' +
                        player2 +
                        ' ' +
                        this.GetLanguageText('and') +
                        ' ' +
                        player4 +
                        ' ';
                    crezult = crezult + this.GetLanguageText('scoring') + ' : ' + this.IntToString(rez1) + ' - ' + this.IntToString(rez2);
                    if (win === 1) {
                        crezult = crezult + 'w;';
                    } else {
                        crezult = crezult + 'l;';
                    }
                    await this.WriteProfileString('Options', 'Duplicate Bridge', br2_rezult + crezult);
                    if (this.OP) {
                        this.props.m_pwin.SendGame(rezult);
                    }
                    this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(this.GetLanguageText('New Game'));
                    if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                        if (this.OP) {
                            rezult = 'X|0 : 0';
                            this.props.m_pwin.SendGame(rezult);
                        }
                        this.InitNewGame();
                    } else {
                        if (this.OP) {
                            this.props.m_pwin.GoToLobby();
                        } else {
                            this.props.m_pwin.OnNewGame();
                        }
                        this.props.m_pwin.showAd();
                        return;
                    }
                } else {
                    this.InitNewGame();
                }
            } else {
                if (this.SP) {
                    this.InitGame();
                }
            }
        }
        if (this.OP) {
            if (!this.watch) {
                this.Faza = 10;
            }
            if (this.Server) {
                for (let i = 2; i <= 4; i++) {
                    if (this.wait_opplayers[i]) {
                        this.Name[i] = this.wait_opnames[i];
                        this.show_user[i] = false;
                        this.props.m_pwin.GetPlayerRating(i);
                        if (i === 2) {
                            this.P2OP = true;
                            this.P2G = true;
                        }
                        if (i === 3) {
                            this.P3OP = true;
                            this.P3G = true;
                        }
                        if (i === 4) {
                            this.P4OP = true;
                            this.P4G = true;
                        }
                        this.SendResult(i);
                        let message = '$|v|' + this.IntToString(i);
                        this.props.m_pwin.SendGame(message);
                        this.setState({});
                    }
                }
                for (let i = 2; i <= 4; i++) {
                    if (this.wait_opplayers[i]) {
                        let new_name = '$|w|' + this.IntToString(i) + '|' + this.wait_opnames[i];
                        this.props.m_pwin.SendGame(new_name);
                        this.wait_opplayers[i] = false;
                        this.wait_opnames[i] = '';
                    }
                }
            }
        }
        //	}
        if (this.OP && !this.Server) {
            if (!this.watch) {
                this.props.m_pwin.SendGame('R');
            }
        }
        this.online_timer = this.online_timer_time * 1.5;
        this.ClearMessages();
    }

    InitNewGame() {
        this.op_start = false;
        this.pause = 0;
        if (this.type === 2) {
            this.impove = 0;
            this.zone = 2 - (this.KoiZapochva % 2);
            this.imp_result.Clean();
            this.BroiIgri = 0;
        }
        if (this.type === 1) {
            this.Rubber = false;
            this.zone = 0;
            this.rubber_result.Clean();
        }
        if (this.SP) {
            this.InitGame();
        }
        for (let i = 1; i < 5; i++) {
            this.last_Name[i] = this.Name[i];
        }
        this.props.m_pwin.pFrame.m_info_bar.AddNewGame();
        this.setState({});
        if (this.SP === true || !this.Server || (this.OP && !this.P2OP && !this.P3OP && !this.P4OP)) {
            //    this.props.m_pwin.showAd();
        }
    }

    SendResult(player: number) {
        if (this.type === 1) {
            if (player === 3) {
                for (let ii = 0; ii < this.rubber_result.BroiUnderResult; ii++) {
                    let message =
                        '$|u|' +
                        this.IntToString(player) +
                        '|1|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][0]) +
                        '|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][1]) +
                        '|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][2]);
                    this.props.m_pwin.SendGame(message);
                }
                for (let ii = 0; ii < this.rubber_result.BroiUpResult; ii++) {
                    let message =
                        '$|u|' +
                        this.IntToString(player) +
                        '|2|' +
                        this.IntToString(this.rubber_result.UpResult[ii][0]) +
                        '|' +
                        this.IntToString(this.rubber_result.UpResult[ii][1]) +
                        '|' +
                        this.IntToString(this.rubber_result.UpResult[ii][2]);
                    this.props.m_pwin.SendGame(message);
                }
            } else {
                for (let ii = 0; ii < this.rubber_result.BroiUnderResult; ii++) {
                    let message =
                        '$|u|' +
                        this.IntToString(player) +
                        '|1|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][1]) +
                        '|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][0]) +
                        '|' +
                        this.IntToString(this.rubber_result.UnderResult[ii][2]);
                    this.props.m_pwin.SendGame(message);
                }
                for (let ii = 0; ii < this.rubber_result.BroiUpResult; ii++) {
                    let message =
                        '$|u|' +
                        this.IntToString(player) +
                        '|2|' +
                        this.IntToString(this.rubber_result.UpResult[ii][1]) +
                        '|' +
                        this.IntToString(this.rubber_result.UpResult[ii][0]) +
                        '|' +
                        this.IntToString(this.rubber_result.UpResult[ii][2]);
                    this.props.m_pwin.SendGame(message);
                }
            }
        }
        if (this.type === 2) {
            if (player === 3) {
                for (let ii = 0; ii < this.BroiIgri; ii++) {
                    if (this.imp_result.result1[2][ii] !== '' || this.imp_result.result1[1][ii] !== '' || this.imp_result.result1[0][ii] !== '') {
                        if (this.imp_result.result1[2][ii] === '') {
                            this.imp_result.result1[2][ii] = '-';
                        }
                        if (this.imp_result.result1[0][ii] === '') {
                            this.imp_result.result1[0][ii] = '0';
                        }
                        if (this.imp_result.result1[1][ii] === '') {
                            this.imp_result.result1[1][ii] = '0';
                        }
                        let message =
                            '$|u|' + this.IntToString(player) + '|1|' + this.imp_result.result1[0][ii] + '|' + this.imp_result.result1[1][ii] + '|-';
                        this.props.m_pwin.SendGame(message);
                    }
                }
            } else {
                for (let ii = 0; ii < this.BroiIgri; ii++) {
                    if (this.imp_result.result1[2][ii] !== '' || this.imp_result.result1[1][ii] !== '' || this.imp_result.result1[0][ii] !== '') {
                        if (this.imp_result.result1[2][ii] === '') {
                            this.imp_result.result1[2][ii] = '-';
                        }
                        if (this.imp_result.result1[0][ii] === '') {
                            this.imp_result.result1[0][ii] = '0';
                        }
                        if (this.imp_result.result1[1][ii] === '') {
                            this.imp_result.result1[1][ii] = '0';
                        }
                        let message =
                            '$|u|' + this.IntToString(player) + '|1|' + this.imp_result.result1[1][ii] + '|' + this.imp_result.result1[0][ii] + '|-';
                        this.props.m_pwin.SendGame(message);
                    } else {
                        return;
                    }
                }
            }
        }
    }

    async Timer4() {
        if (!this.Move) {
            if (this.SP) {
                if (this.TimeVzemane) {
                    this.PribiraneViziatka();
                    return;
                }
                if (this.Faza === 7 && !this.TimeVzemane) {
                    for (let i = 1; i <= 52; i++) {
                        this.k[i] = this.NachalniKarti[i];
                        this.CardsPoint[i].x = this.CardsPos[i].x;
                        this.CardsPoint[i].y = this.CardsPos[i].y;
                    }
                    GlobalVariables.OtkritiKarti = 1;
                    this.KoiENaRed = 0;
                    this.LoadCards(false, false);
                    this.strelka = 0;
                    this.CalcUnderlineResult(2 - (this.LastSays[0] % 2));
                    this.Faza = 8;
                    this.props.m_pwin.KillTimer(4);
                    this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                    this.setState({});
                    return;
                }
                if (this.Faza === 8) {
                    await this.InitRezDlg();
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.TimeVzemane) {
                        this.PribiraneViziatka();
                        return;
                    }
                    if (this.Faza === 7 && !this.TimeVzemane) {
                        for (let i = 1; i <= 52; i++) {
                            this.k[i] = this.NachalniKarti[i];
                            this.CardsPoint[i].x = this.CardsPos[i].x;
                            this.CardsPoint[i].y = this.CardsPos[i].y;
                        }
                        GlobalVariables.OtkritiKarti = 1;
                        this.KoiENaRed = 0;
                        this.LoadCards(false, false);
                        this.strelka = 0;
                        this.CalcUnderlineResult(2 - (this.LastSays[0] % 2));
                        this.Faza = 8;
                        this.props.m_pwin.KillTimer(4);
                        this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                        return;
                    }
                    if (this.Faza === 8) {
                        if (this.type === 1) {
                            await this.InitRezDlg();
                        }
                        if (this.type === 2) {
                            if (this.P2G === true && this.P3G === true && this.P4G === true) {
                                await this.InitRezDlg();
                            }
                        }
                    }
                } else {
                    if (this.TimeVzemane) {
                        this.PribiraneViziatka();
                        return;
                    }
                    if (this.Faza === 7 && !this.TimeVzemane) {
                        for (let i = 1; i <= 52; i++) {
                            this.k[i] = this.NachalniKarti[i];
                            this.CardsPoint[i].x = this.CardsPos[i].x;
                            this.CardsPoint[i].y = this.CardsPos[i].y;
                        }
                        GlobalVariables.OtkritiKarti = 1;
                        this.KoiENaRed = 0;
                        this.LoadCards(false, false);
                        this.strelka = 0;
                        this.CalcUnderlineResult(2 - (this.LastSays[0] % 2));
                        this.Faza = 8;
                        this.props.m_pwin.KillTimer(4);
                        this.props.m_pwin.SetTimer(4, this.Speed[4] * 100);
                        if (!this.watch) {
                            return;
                        }
                    }
                    if (this.Faza === 8) {
                        if (this.type === 1) {
                            await this.InitRezDlg();
                        }
                        if (this.type === 2) {
                            if (!this.wait_new_game) {
                                this.wait_new_game = true;
                                await this.InitRezDlg();
                            }
                        }
                    }
                }
            }
        }
    }

    Timer7() {
        if (!this.watch && !this.Move) {
            if (
                this.OP &&
                (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server)
            ) {
                if (this.online_timer > 0) {
                    this.online_timer = this.props.m_pwin.props.isAppActive
                        ? this.online_timer - 1
                        : this.online_timer - Math.floor(this.online_timer_time / 3);
                }
                if (this.show_timer) {
                    this.show_timer = false;
                }
                this.setState({});
                if (
                    this.Faza === 9 ||
                    (this.Faza >= 4 &&
                        !this.Move &&
                        ((this.KoiENaRed === 1 && this.Mor !== 1 && this.P1puska === 0) ||
                            (this.KoiENaRed === 3 && this.Mor === 3 && this.P3puska === 0))) ||
                    (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0))
                ) {
                    if (this.online_timer === 5) {
                        if (
                            this.OP &&
                            (this.P2OP ||
                                this.wait_opplayers[2] ||
                                this.P3OP ||
                                this.wait_opplayers[3] ||
                                this.P4OP ||
                                this.wait_opplayers[4] ||
                                !this.Server)
                        ) {
                            if (
                                this.OP &&
                                this.Faza >= 4 &&
                                this.P1puska === 0 &&
                                !this.Move &&
                                ((this.KoiENaRed === 1 && this.Mor !== 1) || (this.KoiENaRed === 3 && this.Mor === 3))
                            ) {
                                if (GlobalVariables.Zvuk === 1) {
                                    this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                                }
                            }
                        }
                    }
                    if (this.KoiObiaviava === 0 && this.Faza === 2 && this.BrPas !== 4 && (this.BrPas !== 3 || this.Naddavane === 0)) {
                        if (this.online_timer <= 0) {
                            if (this.props.m_pwin.state.Bidding_BridgeVisible) {
                                this.online_timer = this.online_timer_time * 1.5;
                                this.bidd.OnPass();
                            }
                        }
                    }
                    if (this.Faza === 9) {
                        if (this.online_timer <= 0) {
                            if (this.open_rez_dlg) {
                                if (this.type === 1) {
                                    this.rubber_result.OnOK();
                                    this.online_timer = this.online_timer_time;
                                }
                                if (this.type === 2) {
                                    this.imp_result.OnOK();
                                    this.online_timer = this.online_timer_time;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    SendNamesP(player: number) {
        if (player === 2) {
            let message = '$|t|2|' + this.Name[3] + '|' + this.Name[4] + '|' + this.Name[1];
            this.props.m_pwin.SendGame(message);
        }
        if (player === 3) {
            let message = '$|t|3|' + this.Name[4] + '|' + this.Name[1] + '|' + this.Name[2];
            this.props.m_pwin.SendGame(message);
        }
        if (player === 4) {
            let message = '$|t|4|' + this.Name[1] + '|' + this.Name[2] + '|' + this.Name[3];
            this.props.m_pwin.SendGame(message);
        }
    }

    OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);
        if (this.OP) {
            if (this.OP && GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                this.cursor = GlobalConstants.CURSOR_WATCH;
                this.setState({});
                return;
            }
            if (this.P2OP || this.wait_opplayers[2] || this.P3OP || this.wait_opplayers[3] || this.P4OP || this.wait_opplayers[4] || !this.Server) {
                if (
                    this.Faza >= 4 &&
                    this.P1puska === 0 &&
                    !this.Move &&
                    ((this.KoiENaRed === 1 && this.Mor !== 1) || (this.KoiENaRed === 3 && this.Mor === 3))
                ) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.cursor = GlobalConstants.CURSOR_HAND;
                            this.setState({});
                            return;
                        }
                    }
                }
            }
        }
        if (this.Mor !== 1 && this.Faza > 3) {
            if (
                new CRect(
                    this.CardsPos[13 - this.hand].x * GlobalVariables.scale -
                        ((this.hand * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13) * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].y * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].x * GlobalVariables.scale -
                        ((this.hand * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13) * GlobalVariables.scale +
                        GlobalConstants.PLAYER_CARD_WIDTH * GlobalVariables.scale,
                    this.CardsPos[13 - this.hand].y * GlobalVariables.scale + GlobalConstants.PLAYER_CARD_HEIGHT * GlobalVariables.scale
                ).PtInRect(point) &&
                this.k[13 - this.hand] !== 0
            ) {
                if (this.ProverkaCursor(13 - this.hand)) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                } else {
                    if (this.KoiENaRed !== 1) {
                        this.cursor = GlobalConstants.CURSOR_WAIT;
                    } else {
                        this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                    }
                }
                this.setState({});
                return;
            }
            for (let i = 1; i < 40 - this.hand; i++) {
                let cardRect = this.CardRect[i];
                if (i < 13 - this.hand) {
                    cardRect = new CRect(
                        this.CardRect[i].left - ((13 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13,
                        this.CardRect[i].top,
                        this.CardRect[i].right -
                            ((13 - i) * (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH)) / 13 +
                            (GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH) / 13,
                        this.CardRect[i].bottom + GlobalConstants.PLAYER_CARD_WIDTH - GlobalConstants.CARD_WIDTH
                    );
                } else {
                    if (i < 27) {
                        i = 27;
                        cardRect = this.CardRect[i];
                    }
                }
                if (this.ScaleRect(cardRect).PtInRect(point) && this.k[i] !== 0) {
                    if (this.ProverkaCursor(i)) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                    } else {
                        if ((this.KoiENaRed === 1 && i < 14) || (this.KoiENaRed === 3 && this.Mor === 3 && i > 26 && i < 40)) {
                            this.cursor = GlobalConstants.CURSOR_NOT_ALLOWED;
                        } else {
                            if ((this.KoiENaRed !== 1 && i < 14) || (this.KoiENaRed !== 3 && this.Mor === 3 && i > 26 && i < 40)) {
                                this.cursor = GlobalConstants.CURSOR_WAIT;
                            }
                        }
                    }
                    this.setState({});
                    return;
                }
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    DrawResult() {
        let r1 = 0;
        let r2 = 0;
        let r11 = 0;
        let r21 = 0;
        if (this.type === 1) {
            for (let i = 0; i < this.rubber_result.BroiUnderResult; i++) {
                r1 += this.rubber_result.UnderResult[i][0];
                r2 += this.rubber_result.UnderResult[i][1];
                r11 += this.rubber_result.UnderResult[i][0];
                r21 += this.rubber_result.UnderResult[i][1];
                if (this.rubber_result.UnderResult[i][2] === -1) {
                    r11 = 0;
                    r21 = 0;
                }
            }
            for (let i = 0; i < this.rubber_result.BroiUpResult; i++) {
                r1 += this.rubber_result.UpResult[i][0];
                r2 += this.rubber_result.UpResult[i][1];
            }
            return this.DrawBorderText(
                this.GetLanguageText('Scoring') +
                    ' ' +
                    this.IntToString(r1) +
                    '(' +
                    this.IntToString(r11) +
                    ') : ' +
                    this.IntToString(r2) +
                    '(' +
                    this.IntToString(r21) +
                    ')',
                21,
                36,
                'DrawPoints'
            );
        }

        if (this.type === 2) {
            if (this.impove > 0) {
                r1 = this.impove;
            } else {
                r2 = -this.impove;
            }
            return this.DrawBorderText(
                this.GetLanguageText('Scoring') + ' ' + this.IntToString(r1) + ' : ' + this.IntToString(r2),
                21,
                36,
                'DrawPoints'
            );
        }
    }

    DrawObiaviavaneRN() {
        return this.obiaviavaneRN;
    }

    DrawView() {
        return (
            <View>
                {this.DrawNames()}
                {this.DrawTimer()}
                {this.DrawPause()}
                {this.DrawRating()}
                {this.DrawResult()}
                {this.DrawCards()}
                <DrawBid
                    Naddavane={this.Naddavane}
                    kojKazal={this.kojkazal}
                    Kontra={this.Kontra > 0 ? this.kojkazal : 0}
                    Rekontra={this.Rekontra > 0 ? this.kojkazal : 0}
                    positionBid={this.CardsPos[this.kojkazal === 1 ? 1 : this.kojkazal === 2 ? 14 : this.kojkazal === 3 ? 27 : 40]}
                    positionKontra={this.CardsPos[1 + (this.kojkazal - 1) * 13]}
                    positionRekontra={this.CardsPos[1 + (this.kojkazal - 1) * 13]}
                    level={this.level}
                    game={'Bridge'}
                />
                {this.DrawObiaviavaneRN()}
                {this.DrawStrelkaPng(this.strelka === 2 ? 4 : this.strelka === 4 ? 2 : this.strelka, this.props.m_pwin.game)}
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
            </View>
        );
    }

    render() {
        if (this.props.m_pwin.game === 'Bridge' && !this.loading && !this.loadingMain) {
            if (Platform.OS === 'web') {
                return (
                    <View key={'webview'}>
                        <div key={'mousemoveBridge'} onMouseMove={this.OnMouseMove.bind(this)}>
                            {this.DrawView()}
                        </div>
                    </View>
                );
            } else {
                return <View key={'mobileviewBridge'}>{this.DrawView()}</View>;
            }
        } else {
            return null;
        }
    }
}

export default CBridge;
