import CPoint from '../../cppComponents/CPoint';
import Domino from './CDomino';
import React from 'react';
import { View } from 'react-native';

class CDomino4 extends Domino {
    InitOP(server: boolean) {
        console.log(server);
    }
    async OnLButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async OnRButtonDown(point: CPoint) {
        console.log('point', point);
    }
    async ReceiveGame(strRecvd: string) {
        console.log(strRecvd);
        return true;
    }
    Timer7() {}
    Timer3() {}
    constructor(props) {
        super(props);
    }
    Timer1() {}

    DrawView() {
        return null;
    }

    render() {
        if (this.props.m_pwin.game === 'Domino4') {
            return <View key={'Domino4'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CDomino4;
