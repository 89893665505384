import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import GlobalVariables from '~/Components/GlobalVariables';

type Props = {
    checked: boolean;
    setChecked: Function;
};

const Checkbox = ({ checked, setChecked }: Props) => {
    return (
        <Pressable
            style={[
                styles.checkboxContainer,
                {
                    height: 20 * GlobalVariables.scale,
                    width: 20 * GlobalVariables.scale,
                    borderRadius: 5 * GlobalVariables.scale,
                },
            ]}
            onPress={() => setChecked(!checked)}
        >
            {checked ? <FontAwesome name="check" color={'#76CAC0'} size={12 * GlobalVariables.scale} /> : null}
        </Pressable>
    );
};

export default Checkbox;

const styles = StyleSheet.create({
    checkboxContainer: {
        borderColor: 'gray',
        borderWidth: 1,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
