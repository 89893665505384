import React, { Component } from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import ScrollbarWrapperVertical from '~/Components/core/ScrollbarWrapperVertical';
import GlobalConstants from '../../Components/GlobalConstants';
import GlobalVariables from '../../Components/GlobalVariables';
import { ParentComponentProps } from '../../Components/props/ParentComponentProps';

export default class DlgOnlineTables extends Component<ParentComponentProps> {
    tableHead = [];
    widthArr = [];
    tableData = [];

    rowActiveColor = 'rgb(0,120,215)';
    rowColor = 'rgb(105,123,130)';
    iconCellColor = 'rgb(102,102,102)';
    clickCount = 0;
    pressTimer: any;

    constructor(props: ParentComponentProps) {
        super(props);
        this.InitTable();
    }

    InitTable() {
        this.InitTableWidth();
        this.InitTableTitles();
    }

    InitTableWidth() {
        this.tableHead = [];
        this.widthArr = [];
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.12);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.2);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.12);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.12);
        if (this.props.parent.sel_online === 'Sergeant Major (3-5-8)') {
            this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.29);
            this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.15);
        } else {
            this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.44);
            this.widthArr.push(0);
        }
    }

    InitTableTitles() {
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Status'));
        let usernameTranslation = this.props.m_pwin.m_main.GetLanguageText('User');
        if (usernameTranslation === 'User') {
            usernameTranslation = this.props.m_pwin.m_main.GetLanguageText('Username'); // If Short Username word not exist
        }
        this.tableHead.push(usernameTranslation);
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Players'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Rating'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Other players'));
        if (this.props.parent.sel_online === 'Sergeant Major (3-5-8)') {
            this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Type'));
        } else {
            this.tableHead.push('');
        }

        if (Platform.OS === 'android') {
            this.tableHead.push('');
            this.widthArr.push(0);
            this.tableHead.push('');
            this.widthArr.push(0);
            this.tableHead.push('');
            this.widthArr.push(0);
        }
    }

    InsertItem(
        ico_index: number,
        name: string,
        pi: string,
        rating: string,
        ousers: string,
        type: string,
        stat: string,
        watch: string,
        close_game: string
    ) {
        this.tableData.push([ico_index, name, pi, rating, ousers, type, stat, watch, close_game]);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    DeleteAllItems() {
        this.tableData = [];
    }
    GetItemText(rowNumber: number, columnNumber: number): string {
        return this.tableData[rowNumber][columnNumber];
    }

    SetItemText(rowNumber: number, colNumber: number, text: string) {
        this.tableData[rowNumber][colNumber] = text;
    }

    RemoveFromList(index: number) {
        this.tableData.splice(index, 1);
    }

    UpdateRow(
        rowIndex: number,
        ico_index: number,
        name: string,
        pi: string,
        rating: string,
        ousers: string,
        type: string,
        stat: string,
        watch: string,
        close_game: string
    ) {
        this.tableData[rowIndex][0] = ico_index;
        this.tableData[rowIndex][1] = name;
        this.tableData[rowIndex][2] = pi;
        this.tableData[rowIndex][3] = rating;
        this.tableData[rowIndex][4] = ousers;
        this.tableData[rowIndex][5] = type;
        this.tableData[rowIndex][6] = stat;
        this.tableData[rowIndex][7] = watch;
        this.tableData[rowIndex][8] = close_game;
    }

    render() {
        const element = (data) => (
            <View>
                <Image
                    source={{
                        uri: GlobalConstants.IMAGES_FOLDER + '/files_1.0/online/iconStatus/t' + data + '.ico',
                    }}
                    style={styles.iconStatus}
                />
            </View>
        );
        return (
            <View style={styles.container}>
                <Table borderStyle={StyleSheet.flatten(styles.borderStyle)}>
                    <Row
                        data={this.tableHead}
                        widthArr={this.widthArr}
                        style={StyleSheet.flatten(styles.header)}
                        textStyle={StyleSheet.flatten(styles.text)}
                    />
                </Table>
                <ScrollbarWrapperVertical style={StyleSheet.flatten(styles.dataWrapper)}>
                    <Table borderStyle={StyleSheet.flatten(styles.borderStyle)}>
                        {(this.props.parent.m_search !== '' || this.props.parent.m_friends
                            ? this.tableData.filter(
                                  (item) =>
                                      (item[1].toLocaleLowerCase().indexOf(this.props.parent.m_search.toLocaleLowerCase()) >= 0 ||
                                          item[4].toLocaleLowerCase().indexOf(this.props.parent.m_search.toLocaleLowerCase()) >= 0) &&
                                      (!this.props.parent.m_friends || this.props.m_pwin.ExistInFriendsList(item[1]))
                              )
                            : this.tableData
                        ).map((rowData, index) => (
                            <TableWrapper key={index} style={StyleSheet.flatten(styles.tableWrapperStyle)} widthArr={this.widthArr}>
                                {rowData.map((cellData, cellIndex) =>
                                    this.widthArr[cellIndex] !== undefined ? (
                                        <Cell
                                            width={this.widthArr[cellIndex]}
                                            widthArr={this.widthArr}
                                            onPress={() => {
                                                this.clickCount++;
                                                if (this.clickCount === 2) {
                                                    clearTimeout(this.pressTimer);
                                                    if (this.props.parent.CheckJoinButton()) {
                                                        this.props.parent.OnConnectToGame();
                                                    }
                                                } else {
                                                    this.pressTimer = setTimeout(() => {
                                                        this.clickCount = 0;
                                                    }, 500);
                                                    this.props.parent.OnClickList1(rowData);
                                                }
                                            }}
                                            key={cellIndex}
                                            data={cellIndex === 0 ? element(cellData) : cellIndex > 5 ? '' : cellData}
                                            textStyle={StyleSheet.flatten(this.widthArr[cellIndex] > 0 ? styles.text : null)}
                                            style={StyleSheet.flatten({
                                                backgroundColor:
                                                    cellIndex === 0
                                                        ? this.iconCellColor
                                                        : this.props.parent.Select[0] === rowData[1]
                                                        ? this.rowActiveColor
                                                        : this.rowColor,
                                            })}
                                            numberOfLines={1}
                                        />
                                    ) : (
                                        <View key={cellIndex} />
                                    )
                                )}
                            </TableWrapper>
                        ))}
                    </Table>
                </ScrollbarWrapperVertical>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    borderStyle: {
        borderWidth: 1,
        borderColor: '#333333',
        borderStyle: 'solid',
    },
    header: { height: 23, backgroundColor: '#FFFFFF' },
    text: { paddingLeft: 2 },
    dataWrapper: { marginTop: -1 },
    tableWrapperStyle: { height: 26, flexDirection: 'row' },
    iconStatus: {
        width: 48,
        height: 14,
        margin: Platform.OS === 'android' ? 0 : 2,
    },
});
