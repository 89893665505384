import t from '../../translation';
import React, { Component } from 'react';
import { View, Platform, Dimensions, Pressable, StyleSheet, Image } from 'react-native';
import { modal } from '../../css/modal';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import { styles } from '../../css/container';
import GlobalConstants from '../Components/GlobalConstants';
import Button from '../Components/core/Button';
import config from '~/config';
import Text from '~/Components/core/Text';
import GlobalVariables from '~/Components/GlobalVariables';

class CIzborNaIgra extends Component<BaseDlgProps> {
    Game: number = 0;
    constructor(props: BaseDlgProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    CloseDialog() {
        this.props.m_pwin.setState({ CIzborNaIgraVisible: false });
    }

    OnBelot() {
        this.Game = 1;
        this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnSantase() {
        if (this.props.m_pwin.m_main.isGameEnable('Santase')) {
            this.Game = 2;
            this.props.m_pwin.CloseCIzborNaIgra();
        } else {
            this.MissingGame();
        }
    }

    OnBlato() {
        if (this.props.m_pwin.m_main.isGameEnable('Blato')) {
            this.Game = 3;
            this.props.m_pwin.CloseCIzborNaIgra();
        } else {
            this.MissingGame();
        }
    }

    OnTabla() {
        this.Game = 5;
        this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnBridge() {
        if (this.props.m_pwin.m_main.isGameEnable('Bridge')) {
            this.Game = 6;
            this.props.m_pwin.CloseCIzborNaIgra();
        } else {
            this.MissingGame();
        }
    }

    OnPoker() {
        this.MissingGame();
        //    this.Game = 7;
        //  this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnlineGame() {
        this.Game = 8;
        this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnDomino() {
        this.MissingGame();
        // this.Game = 9;
        //this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnOkey() {
        this.MissingGame();
        //    this.Game = 10;
        //    this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnNssc() {
        this.Game = 11;
        this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnMonopoly() {
        this.MissingGame();
        //    this.Game = 13;
        //  this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnSvara() {
        this.MissingGame();
        //  this.Game = 14;
        // this.props.m_pwin.CloseCIzborNaIgra();
    }

    OnBj() {
        this.MissingGame();
        //    this.Game = 15;
        //  this.props.m_pwin.CloseCIzborNaIgra();
    }

    async MissingGame() {
        if (!this.props.m_pwin.haveOpenMessages() && Platform.OS !== 'ios') {
            let message = t.t('This game is not available for (os) at this moment. Do you want to install another version?', { os: Platform.OS });
            this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
            if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                this.props.m_pwin.openPage(t.t('downloads_online'));
            }
        }
    }

    CloseGame() {
        if (Platform.OS === 'android') {
            //BackHandler.exitApp();
            this.props.m_pwin.setState({ CIzborNaIgraVisible: false });
        }
        if (Platform.OS === 'ios') {
            //  RNExitApp.exitApp();
            this.props.m_pwin.setState({ CIzborNaIgraVisible: false });
        }
        if (Platform.OS === 'web') {
            this.props.m_pwin.setState({ CIzborNaIgraVisible: false });
        }
    }

    render() {
        if (this.props.m_pwin.state.CIzborNaIgraVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View style={styles.row}>
                                {this.props.m_pwin.m_main.isGameVisible('Belote', false) ? (
                                    <Pressable
                                    style={[cstyle.gameButton, `${config.GAME}` === 'Belote' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                    onPress={this.OnBelot.bind(this)}
                                >
                                    <Image source={require('../../assets/Images/Belote/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                    <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Belote</Text>
                                </Pressable>
                                ) : null}
                                {this.props.m_pwin.m_main.isGameVisible('Bridge', false) ? (
                                    <Pressable
                                        style={[cstyle.gameButton, `${config.GAME}` === 'Bridge' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                        onPress={this.OnBridge.bind(this)}
                                    >
                                    <Image source={require('../../assets/Images/Bridge/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                    <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Bridge</Text>
                                </Pressable>
                                    
                                ) : null}
                               
                            </View>
                            <View style={styles.row}>
                                {this.props.m_pwin.m_main.isGameVisible('Tabla', false) ? (
                                    <Pressable
                                    style={[cstyle.gameButton, `${config.GAME}` === 'Bridge' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                    onPress={this.OnTabla.bind(this)}
                                >
                                    <Image source={require('../../assets/Images/Tabla/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                    <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Backgammon</Text>
                                </Pressable>
                                ) : null}
                                {this.props.m_pwin.m_main.isGameVisible('NSSC', false) ? (
                                    <Pressable
                                        style={[cstyle.gameButton, `${config.GAME}` === 'NSSC' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                        onPress={this.OnNssc.bind(this)}
                                        >
                                        <Image source={require('../../assets/Images/NSSC/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Ludo</Text>
                                    </Pressable>
                                ) : null}
                            </View>
                            <View style={styles.row}>
                                {this.props.m_pwin.m_main.isGameVisible('Blato', false) ? (
                                    <Pressable
                                        style={[cstyle.gameButton, `${config.GAME}` === 'Blato' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                        onPress={this.OnBlato.bind(this)}
                                    >
                                        <Image source={require('../../assets/Images/Blato/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Sergeant Major (3-5-8)</Text>
                                    </Pressable>
                                ) : null}
                                {this.props.m_pwin.m_main.isGameVisible('Santase', false) ? (
                                    <Pressable
                                        style={[cstyle.gameButton, `${config.GAME}` === 'Santase' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow, {margin: 5 * GlobalVariables.scale,  borderRadius: 38 * GlobalVariables.scale}]}
                                        onPress={this.OnSantase.bind(this)}
                                    >
                                        <Image source={require('../../assets/Images/Santase/favicon.png')} style={[cstyle.gameImage, {borderRadius: 30 * GlobalVariables.scale, width: 60 * GlobalVariables.scale, height: 60 * GlobalVariables.scale, marginVertical: 8 * GlobalVariables.scale, marginHorizontal: 12 * GlobalVariables.scale}]} />
                                        <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} style={cstyle.buttonText} color={'#111133'}>Schnapsen (66)</Text>
                                    </Pressable>
                                ) : null}
                            </View>
                            <View style={styles.row}>
                                {this.props.m_pwin.m_main.isGameVisible('OKEY', false) ? (
                                    <Button
                                        onPress={this.OnOkey.bind(this)}
                                        containerStyle={`${config.GAME}` === 'OKEY' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow}
                                        text="OKEY"
                                        textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    />
                                ) : null}
                                {this.props.m_pwin.m_main.isGameVisible('Poker', false) ? (
                                    <Button
                                        onPress={this.OnPoker.bind(this)}
                                        containerStyle={`${config.GAME}` === 'Poker' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow}
                                        text="Poker Texas Hold em"
                                        textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    />
                                ) : null}
                            </View>
                            <View style={styles.row}>
                                {this.props.m_pwin.m_main.isGameVisible('Domino2', false) ? (
                                    <Button
                                        onPress={this.OnDomino.bind(this)}
                                        containerStyle={`${config.GAME}` === 'Domino' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow}
                                        text="Domino"
                                        textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    />
                                ) : null}
                                {this.props.m_pwin.m_main.isGameVisible('Monopoly', false) ? (
                                    <Button
                                        onPress={this.OnMonopoly.bind(this)}
                                        containerStyle={`${config.GAME}` === 'Monopoly' ? modal.buttonStyle1PerRow : modal.buttonStyle2PerRow}
                                        text="Monopoly"
                                        textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    />
                                ) : null}
                            </View>
                            <View style={styles.row}>
                                <Button
                                    textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    onPress={this.OnlineGame.bind(this)}
                                    containerStyle={[modal.buttonStyle1PerRow, {height: 70 * GlobalVariables.scale}]}
                                    text="Online Game"
                                />
                            </View>
                            <View style={styles.row}>
                                <Button
                                    textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                    onPress={this.CloseGame.bind(this)}
                                    containerStyle={[modal.buttonStyle1PerRow, {height: 70 * GlobalVariables.scale}]}
                                    text="Close"
                                />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CIzborNaIgra;


const cstyle = StyleSheet.create({
    buttonText: {color: '#111133', fontWeight: '700'},
    gameButton: {flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#FFFFFF'},
    gameImage: { resizeMode: 'stretch'}
});
