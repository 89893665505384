import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class CResultOKEY extends Component<ParentDlgProps> {
    start: number;
    rez:number[][] = [];
    Player: string[] = [];
    frez: number[] = [];
    lrez: number[] = [];
    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.start = 0;
        this.props.m_pwin.setState({ CResultOKEYVisible: false });
    }

    async DoModal() {
        this.props.m_pwin.state.CResultOKEYVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CResultOKEYVisible) {
            await this.performTimeConsumingTask();
        }
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.state.CResultOKEYVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 20 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Announcement</Text>
                        </View>

                        <View style={styles.row}>
                            <Button
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle1PerRow}
                                onPress={this.OnExit.bind(this)}
                                text="OK"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CResultOKEY;
