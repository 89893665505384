import React, { Component } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import GlobalVariables from '../../Components/GlobalVariables';
import { RadioButton } from 'react-native-paper';
import { BlatoGetCardOptionProps } from './BlatoGetCardOptionProps';

export default class BlatoGetCardOption extends Component<BlatoGetCardOptionProps> {
    constructor(props: BlatoGetCardOptionProps) {
        super(props);
    }

    render() {
        return (
            <Pressable
                onPress={() => this.props.parent.onPressOption(this.props.color, this.props.numCard, this.props.player)}
                disabled={this.props.isDisableRadio}
                style={[
                    cstyles.cell,
                    cstyles.option,
                    this.props.isDisableRadio ? cstyles.disabled : cstyles.enabled,
                    {
                        width: 35 * GlobalVariables.scale,
                        height: 35 * GlobalVariables.scale,
                        marginHorizontal: 15 * GlobalVariables.scale,
                        marginVertical: 2 * GlobalVariables.scale,
                        borderRadius: 17.5 * GlobalVariables.scale,
                    },
                    // eslint-disable-next-line react-native/no-inline-styles
                    { backgroundColor: this.props.getRadioButtonStatus === 'checked' ? 'gray' : null },
                ]}
            >
                <RadioButton
                    value={
                        this.props.numCard.toString() +
                        (this.props.color ? +'_color_' + this.props.color.toString() : +'_player_' + this.props.player.toString())
                    }
                    onPress={() => this.props.parent.onPressOption(this.props.color, this.props.numCard, this.props.player)}
                    disabled={this.props.isDisableRadio}
                    status={this.props.getRadioButtonStatus}
                />
            </Pressable>
        );
    }
}

const cstyles = StyleSheet.create({
    cell: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2 * GlobalVariables.scale,
    },
    disabled: { borderColor: 'gray' },
    enabled: { borderColor: 'green' },
    option: {
        borderWidth: 2,
    },
});
