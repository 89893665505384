import ar from './assets/translations/arabic/translations.json';
import bg from './assets/translations/bulgarian/translations.json';
import hr from './assets/translations/croatian/translations.json';
import cz from './assets/translations/czech/translations.json';
import da from './assets/translations/danish/translations.json';
import du from './assets/translations/dutch/translations.json';
import en from './assets/translations/english/translations.json';
import fr from './assets/translations/french/translations.json';
import de from './assets/translations/german/translations.json';
import gr from './assets/translations/greek/translations.json';
import hu from './assets/translations/hungarian/translations.json';
import it from './assets/translations/italian/translations.json';
import mk from './assets/translations/macedonian/translations.json';
import pl from './assets/translations/polish/translations.json';
import pt from './assets/translations/portuguese/translations.json';
import ro from './assets/translations/romanian/translations.json';
import ru from './assets/translations/russian/translations.json';
import se from './assets/translations/serbian/translations.json';
import sl from './assets/translations/slovenian/translations.json';
import sp from './assets/translations/spanish/translations.json';
import sw from './assets/translations/swedish/translations.json';
import ua from './assets/translations/ukrainian/translations.json';
import tr from './assets/translations/turkish/translations.json';

class Translation {
    static translation: any = en;

    static setTranslation(language: string) {
        if (language === 'Arabic') {
            Translation.translation = ar;
        } else if (language === 'Bulgarian') {
            Translation.translation = bg;
        } else if (language === 'Croatian') {
            Translation.translation = hr;
        } else if (language === 'Czech') {
            Translation.translation = cz;
        } else if (language === 'Danish') {
            Translation.translation = da;
        } else if (language === 'Dutch') {
            Translation.translation = du;
        } else if (language === 'English') {
            Translation.translation = en;
        } else if (language === 'French') {
            Translation.translation = fr;
        } else if (language === 'German') {
            Translation.translation = de;
        } else if (language === 'Greek') {
            Translation.translation = gr;
        } else if (language === 'Hungarian') {
            Translation.translation = hu;
        } else if (language === 'Italian') {
            Translation.translation = it;
        } else if (language === 'Macedonian') {
            Translation.translation = mk;
        } else if (language === 'Polish') {
            Translation.translation = pl;
        } else if (language === 'Portuguese') {
            Translation.translation = pt;
        } else if (language === 'Romanian') {
            Translation.translation = ro;
        } else if (language === 'Russian') {
            Translation.translation = ru;
        } else if (language === 'Serbian') {
            Translation.translation = se;
        } else if (language === 'Slovenian') {
            Translation.translation = sl;
        } else if (language === 'Spanish') {
            Translation.translation = sp;
        } else if (language === 'Swedish') {
            Translation.translation = sw;
        } else if (language === 'Ukrainian') {
            Translation.translation = ua;
        } else if (language === 'Turkish') {
            Translation.translation = tr;
        } else {
            Translation.translation = en;
        }
    }

    static t(text: string, props: any = null) {
        if (Translation.translation == null || text == null) {
            return null;
        }
        var value = Translation.translation;
        value = value[text];
        if (value === undefined) {
            value = text;
        }
        if (props !== null && props !== undefined) {
            for (var key in props) {
                if (props.hasOwnProperty(key)) {
                    value = value.replace('(' + key + ')', props[key]);
                }
            }
        }
        if (value == null) {
            return text;
        }
        return value;
    }
}
export default Translation;
