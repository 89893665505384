import React, { Component } from 'react';
import { View, Pressable, Switch, StyleSheet, TextInput, Keyboard, ScrollView, Dimensions, Platform } from 'react-native';
import { styles } from '../../css/container';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import Button from '../Components/core/Button';
import GlobalConstants from '../Components/GlobalConstants';
import Text from '../Components/core/Text';
import GlobalVariables from '../Components/GlobalVariables';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import InputField from '~/Components/core/InputField';

class PlayersNamesScreen extends Component<BaseDlgProps> {
    ok: boolean = false;
    start: number;
    name1Ref: TextInput;
    name2Ref: TextInput;
    name3Ref: TextInput;
    name4Ref: TextInput;
    Name: string[] = [];
    pls: number[] = [];

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.initDIalog();

    }

    async initDIalog() {
        this.Name[1] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[1]', this.props.m_pwin.m_main.GetPlayer1Name());
        for (let i = 2; i < 11; i++) {
            this.Name[i] = await this.props.m_pwin.m_main.GetProfileString('Options', 'Name[' + i + ']', 'Computer' + i);
        }

        this.pls[1] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl1s', 0);
        this.pls[2] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl2s', 1);
        this.pls[3] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl3s', 0);
        this.pls[4] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl4s', 1);
        this.pls[5] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl5s', 1);
        this.pls[6] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl6s', 0);
        this.pls[7] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl7s', 0);
        this.pls[8] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl8s', 1);
        this.pls[9] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl9s', 0);
        this.pls[10] = await this.props.m_pwin.m_main.GetProfileInt('Options', 'pl10s', 1);
    }

    async saveNames() {
        for (let i = 1; i < 11; i++) {
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Name[' + i + ']', this.Name[i]);
            this.props.m_pwin.m_main.WriteProfileInt('Options', 'pl' + i + 's', this.pls[i]);
        }
        if (!this.props.m_pwin.IsGameOnline()) {
            for (let i = 1; i < 11; i++) {
                this.props.m_pwin.m_main.Name[i] = this.Name[i];
                this.props.m_pwin.m_main.pls[i] = this.pls[i];
            }

            if (this.props.m_pwin.game === 'Belot2') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.b2.Name[i] = this.Name[i];
                    this.props.m_pwin.b2.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Belot3') {

            }
            if (this.props.m_pwin.game === 'Belot4') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.b4.Name[i] = this.Name[i];
                    this.props.m_pwin.b4.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Blato') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_bla.Name[i] = this.Name[i];
                    this.props.m_pwin.m_bla.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Bridge') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_bri.Name[i] = this.Name[i];
                    this.props.m_pwin.m_bri.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Svara') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_sva.Name[i] = this.Name[i];
                    this.props.m_pwin.m_sva.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Domino2') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.dom2.Name[i] = this.Name[i];
                    this.props.m_pwin.dom2.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Domino3') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.dom3.Name[i] = this.Name[i];
                    this.props.m_pwin.dom3.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Domino4') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.dom4.Name[i] = this.Name[i];
                    this.props.m_pwin.dom4.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Monopoly') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.mon.Name[i] = this.Name[i];
                    this.props.m_pwin.mon.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'NSSC') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_nssc.Name[i] = this.Name[i];
                    this.props.m_pwin.m_nssc.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'OKEY') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_okey.Name[i] = this.Name[i];
                    this.props.m_pwin.m_okey.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Poker') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_pok.Name[i] = this.Name[i];
                    this.props.m_pwin.m_pok.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'PokerOmaha') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_poko.Name[i] = this.Name[i];
                    this.props.m_pwin.m_poko.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'DrawPoker') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_dpok.Name[i] = this.Name[i];
                    this.props.m_pwin.m_dpok.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'BJ') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_bj.Name[i] = this.Name[i];
                    this.props.m_pwin.m_bj.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Santase') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.m_san.Name[i] = this.Name[i];
                    this.props.m_pwin.m_san.pls[i] = this.pls[i];
                }
            }
            if (this.props.m_pwin.game === 'Tabla') {
                for (let i = 1; i < 11; i++) {
                    this.props.m_pwin.tab.Name[i] = this.Name[i];
                    this.props.m_pwin.tab.pls[i] = this.pls[i];
                }
            }
            this.props.m_pwin.setState({});
        }
        this.OnOk();
    }

    async DoModal() {
        this.props.m_pwin.state.PlayersNamesVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.PlayersNamesVisible) {
            await this.performTimeConsumingTask();
        }
        return this.ok ? GlobalConstants.IDOK : GlobalConstants.IDCANCEL;
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    OnOk() {
        this.ok = true;
        this.start = 0;
        this.props.m_pwin.setState({ PlayersNamesVisible: false });
    }

    OnCancel() {
        this.ok = false;
        this.start = 0;
        this.props.m_pwin.setState({ PlayersNamesVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.PlayersNamesVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 10 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 5 * GlobalVariables.scale,
                                width: 780 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Players Names</Text>
                        </View>
                        <ScrollView style={[styles.W100, { height: Dimensions.get('window').height * 0.7 }]}>
                            <View style={cstyles.contentContainer}>
                                <View style={[cstyles.namesContainer, { height: Dimensions.get('window').height * 0.58 }]}>
                                    <View style={styles.row}>
                                        <View style={cstyles.numColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>1</Text>
                                        </View>
                                        <View style={cstyles.nameColContainer}>
                                            <InputField
                                                blurOnSubmit={false}
                                                refinput={(ref: TextInput) => {
                                                    this.name1Ref = ref;
                                                }}
                                                onSubmitEditing={() => {
                                                    this.name2Ref.focus();
                                                }}
                                                autoFocus={false}
                                                onChangeText={(name) => (this.Name[1] = name)}
                                                keyboardType="default"
                                                maxLength={12}
                                                height={40 * GlobalVariables.scale}
                                                underlineColorAndroid="#f000"
                                                returnKeyType="next"
                                                defaultValue={this.Name[1]}
                                                required={true}
                                            />
                                        </View>
                                        <View style={cstyles.sexColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Man</Text>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={(value) => { value === true ? this.pls[1] = 1 : this.pls[1] = 0; this.setState({}) }}
                                                value={this.pls[1] === 1}
                                            />
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Woman</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={cstyles.numColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>2</Text>
                                        </View>
                                        <View style={cstyles.nameColContainer}>
                                            <InputField
                                                blurOnSubmit={false}
                                                refinput={(ref: TextInput) => {
                                                    this.name2Ref = ref;
                                                }}
                                                onSubmitEditing={() => {
                                                    this.name3Ref.focus();
                                                }}
                                                autoFocus={false}
                                                onChangeText={(name) => (this.Name[2] = name)}
                                                keyboardType="default"
                                                maxLength={12}
                                                height={40 * GlobalVariables.scale}
                                                underlineColorAndroid="#f000"
                                                returnKeyType="next"
                                                defaultValue={this.Name[2]}
                                                required={true}
                                            />
                                        </View>
                                        <View style={cstyles.sexColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Man</Text>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={(value) => { value === true ? this.pls[2] = 1 : this.pls[2] = 0; this.setState({}) }}
                                                value={this.pls[2] === 1}
                                            />
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Woman</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={cstyles.numColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>3</Text>
                                        </View>
                                        <View style={cstyles.nameColContainer}>
                                            <InputField
                                                blurOnSubmit={false}
                                                refinput={(ref: TextInput) => {
                                                    this.name3Ref = ref;
                                                }}
                                                onSubmitEditing={() => {
                                                    this.name4Ref.focus();
                                                }}
                                                autoFocus={false}
                                                onChangeText={(name) => (this.Name[3] = name)}
                                                keyboardType="default"
                                                maxLength={12}
                                                height={40 * GlobalVariables.scale}
                                                underlineColorAndroid="#f000"
                                                returnKeyType="next"
                                                defaultValue={this.Name[3]}
                                                required={true}
                                            />
                                        </View>
                                        <View style={cstyles.sexColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Man</Text>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={(value) => { value === true ? this.pls[3] = 1 : this.pls[3] = 0; this.setState({}) }}
                                                value={this.pls[3] === 1}
                                            />
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Woman</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={cstyles.numColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>4</Text>
                                        </View>
                                        <View style={cstyles.nameColContainer}>
                                            <InputField
                                                blurOnSubmit={false}
                                                refinput={(ref: TextInput) => {
                                                    this.name4Ref = ref;
                                                }}
                                                autoFocus={false}
                                                onChangeText={(name) => (this.Name[4] = name)}
                                                keyboardType="default"
                                                maxLength={12}
                                                height={40 * GlobalVariables.scale}
                                                underlineColorAndroid="#f000"
                                                returnKeyType="next"
                                                defaultValue={this.Name[4]}
                                                required={true}
                                            />
                                        </View>
                                        <View style={cstyles.sexColContainer}>
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Man</Text>
                                            <Switch
                                                style={cstyles.switchStyle}
                                                onValueChange={(value) => { value === true ? this.pls[4] = 1 : this.pls[4] = 0; this.setState({}) }}
                                                value={this.pls[4] === 1}
                                            />
                                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE} style={cstyles.sexText}>Woman</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </ScrollView>
                        <View style={[cstyles.buttonsContainer, { height: Dimensions.get('window').height * 0.12, paddingBottom: 10 * GlobalVariables.scale }]}>
                            <View style={styles.row}>
                                <Button
                                    textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                    onPress={() => this.saveNames()}
                                    text="Save"
                                    containerStyle={modal.buttonStyle3PerRow}
                                />
                                <Button
                                    textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                    containerStyle={modal.buttonStyle3PerRow}
                                    onPress={() => this.OnOk()}
                                    text="Close"
                                />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default PlayersNamesScreen;

const cstyles = StyleSheet.create({
    contentContainer: {justifyContent: 'space-between'},
    namesContainer: {},
    buttonsContainer: {justifyContent: 'flex-end', width: '100%'},
    sexText: { paddingHorizontal: 10 * GlobalVariables.scale },
    switchStyle: {
        height: 48,
        marginRight: 15,
        transform: Platform.OS === 'android' ? [{ scaleX: 2 }, { scaleY: 2 }] : [{ scaleX: 1 }, { scaleY: 1 }],
    },
    numColContainer: { width: '14%', alignItems: 'center' },
    nameColContainer: { width: '40%', justifyContent: 'center', padding: 5 },
    sexColContainer: { width: '46%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' },
    text: { height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 * GlobalVariables.scale },
    pools: { flexDirection: 'row' },
});
