import Constants from 'expo-constants';

export default {
    ADMOB_ANDROID: Constants.manifest?.extra?.ADMOB_ANDROID as string,
    ADMOB_IOS: Constants.manifest?.extra?.ADMOB_IOS as string,
    ADMOB_ANDROID_INTERSTITIAL: Constants.manifest?.extra?.ADMOB_ANDROID_INTERSTITIAL as string,
    ADMOB_IOS_INTERSTITIAL: Constants.manifest?.extra?.ADMOB_IOS_INTERSTITIAL as string,
    GAME: Constants.manifest?.extra?.GAME as string,
    FACEBOOK_LOGIN: Constants.manifest?.extra?.FACEBOOK_LOGIN as string,
    GOOGLE_LOGIN_ANDROID: Constants.manifest?.extra?.GOOGLE_LOGIN_ANDROID as string,
    GOOGLE_LOGIN_IOS: Constants.manifest?.extra?.GOOGLE_LOGIN_IOS as string,
    GOOGLE_LOGIN_WEB: Constants.manifest?.extra?.GOOGLE_LOGIN_WEB as string,
    GOOGLE_LOGIN_EXPO: Constants.manifest?.extra?.GOOGLE_LOGIN_EXPO as string,
    APP_STORE_CODE: Constants.manifest?.extra?.APP_STORE_CODE as string,
};
