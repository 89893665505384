import { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import React from 'react';
import GlobalVariables from '../GlobalVariables';
import { DrawChartProps } from '../props/DrawChartProps';
import CPicture from '../cppComponents/CPicture';
import ChatMessage from '../../DockBars/ChatBar/ChatMessage';

class DrawChatMessage extends Component<DrawChartProps> {
    text1: string = '';
    text2: string = '';
    text3: string = '';
    text4: string = '';
    t1: string = '';
    t2: string = '';
    t3: string = '';
    t4: string = '';
    rows = 1;
    chatLeft: string[] = [];
    chatRight: string[] = [];
    cstyles = stylesGenerator();

    constructor(props: DrawChartProps) {
        super(props);
        for (let i = 0; i < 4; i++) {
            this.chatLeft[i] = '/chat/left' + (i + 1) + '.png';
            this.chatRight[i] = '/chat/right' + (i + 1) + '.png';
        }
        this.text1 = this.props.text;
        if (this.text1.length > 25) {
            let sp = this.text1.indexOf(' ', 25);
            if (sp > 0) {
                this.rows = 2;
                this.text2 = this.text1.substring(sp + 1, this.text1.length);
                this.text1 = this.text1.substring(0, sp);
                let sp1 = this.text2.indexOf(' ', 25);
                if (sp1 > 0) {
                    this.rows = 3;
                    this.text3 = this.text2.substring(sp1 + 1, this.text2.length);
                    this.text2 = this.text2.substring(0, sp1);
                    let sp2 = this.text3.indexOf(' ', 25);
                    if (sp2 > 0) {
                        this.rows = 4;
                        this.text4 = this.text3.substring(sp2 + 1, this.text3.length);
                        this.text3 = this.text3.substring(0, sp2);
                    }
                }
            }
        }
        this.t1 = this.text1;
        this.t2 = this.text2;
        this.t3 = this.text3;
        this.t4 = this.text4;
    }

    render() {
        if (this.props.text !== '' && this.props.position !== undefined && this.props.position.x > 0 && this.props.position.y > 0) {
            let fontSize = 12;
            return (
                <View
                    style={[
                        this.cstyles.container,
                        {
                            left: this.props.position.x * GlobalVariables.scale,
                            top:
                                this.props.position.y * GlobalVariables.scale -
                                (this.rows === 1
                                    ? 22 * GlobalVariables.scale
                                    : this.rows === 2
                                    ? 39 * GlobalVariables.scale
                                    : this.rows === 3
                                    ? 56 * GlobalVariables.scale
                                    : 73 * GlobalVariables.scale),
                        },
                    ]}
                >
                    <View
                        style={[
                            this.cstyles.containerTop,
                            {
                                height:
                                    this.rows === 1
                                        ? 22 * GlobalVariables.scale
                                        : this.rows === 2
                                        ? 39 * GlobalVariables.scale
                                        : this.rows === 3
                                        ? 56 * GlobalVariables.scale
                                        : 73 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <CPicture
                            relative={true}
                            image={this.chatLeft[this.rows - 1]}
                            m_x={0}
                            m_y={0}
                            m_Height={this.rows === 1 ? 22 : this.rows === 2 ? 39 : this.rows === 3 ? 56 : 73}
                            m_Width={4}
                        />
                        <View
                            style={[
                                this.cstyles.textBackground,
                                {
                                    minWidth: 24 * GlobalVariables.scale,
                                    paddingLeft: 1 * GlobalVariables.scale,
                                    paddingRight: 1 * GlobalVariables.scale,
                                    marginLeft: -2 * GlobalVariables.scale,
                                    marginRight: -2 * GlobalVariables.scale,
                                    height:
                                        this.rows === 1
                                            ? 22 * GlobalVariables.scale
                                            : this.rows === 2
                                            ? 39 * GlobalVariables.scale
                                            : this.rows === 3
                                            ? 56 * GlobalVariables.scale
                                            : 73 * GlobalVariables.scale,
                                },
                            ]}
                        >
                            {this.t1 !== '' ? (
                                <View style={[this.cstyles.textView, { height: 17 * GlobalVariables.scale }]}>
                                    <ChatMessage
                                        parts={this.props.m_main.formatMessage(this.t1)}
                                        spec={this.props.m_main.spec}
                                        key={'t1'}
                                        index={1}
                                        fontSize={GlobalVariables.scale * fontSize}
                                        scale={true}
                                    />
                                </View>
                            ) : null}
                            {this.t2 !== '' ? (
                                <View style={[this.cstyles.textView, { height: 17 * GlobalVariables.scale }]}>
                                    <ChatMessage
                                        parts={this.props.m_main.formatMessage(this.t2)}
                                        spec={this.props.m_main.spec}
                                        key={'t2'}
                                        index={2}
                                        fontSize={GlobalVariables.scale * fontSize}
                                        scale={true}
                                    />
                                </View>
                            ) : null}
                            {this.t3 !== '' ? (
                                <View style={[this.cstyles.textView, { height: 17 * GlobalVariables.scale }]}>
                                    <ChatMessage
                                        parts={this.props.m_main.formatMessage(this.t3)}
                                        spec={this.props.m_main.spec}
                                        key={'t3'}
                                        index={3}
                                        fontSize={GlobalVariables.scale * fontSize}
                                        scale={true}
                                    />
                                </View>
                            ) : null}
                            {this.t4 !== '' ? (
                                <View style={[this.cstyles.textView, { height: 17 * GlobalVariables.scale }]}>
                                    <ChatMessage
                                        parts={this.props.m_main.formatMessage(this.t4)}
                                        spec={this.props.m_main.spec}
                                        key={'t4'}
                                        index={4}
                                        fontSize={GlobalVariables.scale * fontSize}
                                        scale={true}
                                    />
                                </View>
                            ) : null}
                        </View>
                        <CPicture
                            relative={true}
                            image={this.chatRight[this.rows - 1]}
                            m_x={0}
                            m_y={0}
                            m_Height={this.rows === 1 ? 22 : this.rows === 2 ? 39 : this.rows === 3 ? 56 : 73}
                            m_Width={4}
                        />
                    </View>
                    <View style={[this.cstyles.containerBottom, { marginTop: -1 * GlobalVariables.scale }]}>
                        <CPicture relative={true} image={'/chat/bottom.png'} m_x={0} m_y={0} m_Width={18} m_Height={13} />
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DrawChatMessage;

const stylesGenerator = () =>
    StyleSheet.create({
        textView: {
            justifyContent: 'center',
        },
        container: {
            position: 'absolute',
        },
        containerTop: {
            flexDirection: 'row',
        },
        containerBottom: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textBackground: {
            backgroundColor: 'rgb(234, 224, 206)',
            borderTopColor: 'rgb(44, 35, 29)',
            borderBottomColor: 'rgb(44, 35, 29)',
            borderTopWidth: 1,
            borderBottomWidth: 1,
            justifyContent: 'center',
        },
        text: {
            fontWeight: 'bold',
        },
    });
