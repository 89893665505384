import { Component } from 'react';
import { Image, View } from 'react-native';
import React from 'react';
import GlobalConstants from '../GlobalConstants';
import GlobalVariables from '../GlobalVariables';
import { styles } from '../../../css/container';
import { CPictureProps } from '../props/CPictureProps';

class CPicture extends Component<CPictureProps> {
    image: string;
    m_Width: number;
    m_Height: number;
    m_y: number;
    m_x: number;

    constructor(props: CPictureProps) {
        super(props);
    }

    LoadFile(image: string) {
        this.image = image;
    }

    Show(m_x: number, m_y: number, m_Width: number, m_Height: number) {
        this.m_x = m_x;
        this.m_y = m_y;
        this.m_Width = m_Width;
        this.m_Height = m_Height;
    }

    GetHeight() {
        return this.props.noScale ? this.props.m_Height : this.props.m_Height * GlobalVariables.scale;
    }

    GetWidth() {
        return this.props.noScale ? this.props.m_Width : this.props.m_Width * GlobalVariables.scale;
    }

    getFilePath() {
        return this.props.folder
            ? GlobalConstants.IMAGES_FOLDER + '/' + this.props.folder + '/' + this.props.image
            : GlobalConstants.IMAGES_FOLDER + '/' + GlobalVariables.folder + '/' + this.props.image;
    }

    render() {
        return (
            <View
                style={[
                    this.props.relative ? styles.containerRelative : styles.container,
                    {
                        top: this.props.noScale ? this.props.m_y : this.props.m_y * GlobalVariables.scale,
                        left: this.props.noScale ? this.props.m_x : this.props.m_x * GlobalVariables.scale,
                    },
                ]}
            >
                <View style={[styles.view, this.props.backgroundColor ? { backgroundColor: this.props.backgroundColor } : null]}>
                    <div
                        style={{
                            cursor: this.props.cursor !== undefined ? this.props.cursor : GlobalConstants.CURSOR_ARROW,
                        }}
                    >
                        <Image
                            source={{
                                uri: this.getFilePath(),
                            }}
                            style={[
                                styles.cpictureResizeMode,
                                {
                                    width:
                                        this.props.noScale || this.props.noScaleImage
                                            ? this.props.m_Width
                                            : this.props.m_Width * GlobalVariables.scale,
                                    height:
                                        this.props.noScale || this.props.noScaleImage
                                            ? this.props.m_Height
                                            : this.props.m_Height * GlobalVariables.scale,
                                },
                            ]}
                        />
                    </div>
                </View>
            </View>
        );
    }
}

export default CPicture;
