import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { modal } from '../../../css/modal';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import Button from '../../Components/core/Button';
import GlobalConstants from '~/Components/GlobalConstants';
import { styles } from '../../../css/container';

class IgraBJDlg extends Component<BaseDlgProps> {
    Game: number = 0;

    constructor(props) {
        super(props);
    }

    DoModal() {
        this.props.m_pwin.setState({ IgraBJDlgVisible: true });
    }

    OnSp() {
        this.Game = 1;
        this.props.m_pwin.CloseIgraNSSC();
    }

    async OnOp() {
        this.Game = 3;
        this.props.m_pwin.CloseIgraNSSC();
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        if (this.props.m_pwin.state.IgraBJDlgVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View style={modal.modalView}>
                        <View style={modal.mainBody}>
                            <View>
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnSp.bind(this)} text="Local Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={this.OnOp.bind(this)} text="Online Game" />
                                <Button textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE} onPress={() => this.props.m_pwin.OnNewGame()} text="Close" />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default IgraBJDlg;
