class CPictureParams {
    image: string;
    m_url: string;
    tooltip: string;
    m_Width: number;
    m_Height: number;
    m_y: number;
    m_x: number;
}
export default CPictureParams;
