import CMain from '../CMain';
import React from 'react';

import { Platform, View } from 'react-native';
import CPicture from '../cppComponents/CPicture';
import CPoint from '../cppComponents/CPoint';
import CRect from '../cppComponents/CRect';
import GlobalConstants from '../GlobalConstants';
import DrawDice from '../Draw/DrawDice';
import GlobalVariables from '../GlobalVariables';
import CSize from '../cppComponents/CSize';
import DrawNames from '../Draw/DrawNames';

class CNSSC extends CMain {
    async LoadBitmaps() {}
    color_index = 1;
    star = new CPicture(null);
    pionka = [];
    ppoint = [];
    pplacef = [];
    pplaceb = [];
    pplace = [];
    Old: CPoint = new CPoint(0, 0);
    FinPlace = [];
    xdiff: number;
    ydiff: number;
    OP = false;
    SP = false;
    dice1 = 6;
    mdice1 = 0;
    KoiENaRed = 0;
    Server = false;
    Faza = 0;
    PPos = [];
    dice_point = [];
    pip = [];
    loading: boolean = true;
    pionki = [];

    constructor(props) {
        super(props);
        this.initConstructor();
    }

    initConstructor() {
        this.dice1 = 6;
        this.mdice1 = 0;
        this.zar = false;
        this.KoiENaRed = 0;
        this.color_index = 1;
        this.P2OP = false;
        this.P3OP = false;
        this.P4OP = false;
        this.Server = false;
        this.Faza = 0;
        for (let t = 0; t < 5; t++) {
            this.PPos[t] = [];
            for (let i = 1; i < 5; i++) {
                this.PPos[t][i] = t * 10 + i + 200;
            }
        }
    }

    async ReceiveWachGameInitialization(init: string) {
        await this.OnLButtonDown(new CPoint(0, 0));
        let i: number;
        let a: number;
        await this.Init();
        this.InitGame();
        let vpos = init.indexOf('?');
        let n1str = init.substring(0, vpos);
        if (n1str !== '') {
            this.Name[1] = n1str;
        }
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[3] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);
        vpos = init.indexOf('?');
        this.Name[4] = init.substring(0, vpos);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.props.m_pwin.GetPlayerRating(3);
        this.props.m_pwin.GetPlayerRating(4);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.mdice1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.dice1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        //this.DELETE m_o_v_e_p = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.zar = true;
        } else {
            this.zar = false;
        }
        init = init.substring(vpos + 1, init.length);

        for (i = 1; i < 5; i++) {
            for (a = 1; a < 5; a++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.PPos[i][a] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
                if (this.PPos[i][a] < 100) {
                    this.ppoint[i][a].x = this.pplace[this.PPos[i][a]].x;
                    this.ppoint[i][a].y = this.pplace[this.PPos[i][a]].y;
                }
                if (this.PPos[i][a] > 200) {
                    this.ppoint[i][a].x = this.pplaceb[i][this.PPos[i][a] % 10].x;
                    this.ppoint[i][a].y = this.pplaceb[i][this.PPos[i][a] % 10].y;
                }
                if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                    this.ppoint[i][a].x = this.pplacef[i][this.PPos[i][a] % 10].x;
                    this.ppoint[i][a].y = this.pplacef[i][this.PPos[i][a] % 10].y;
                }
            }
        }
        if (init !== '') {
            vpos = init.indexOf('?');
            s1 = init.substring(0, vpos);
            this.color_index = this._ttoi(s1);
            init = init.substring(vpos + 1, init.length);
            await this.LoadPictures(this.color_index);
        }
        this.loadPionki();
        this.setState({});
    }

    SendWatchGameInitialization(watch_index: number) {
        let message = '$|z|' + this.IntToString(watch_index) + '|';
        message = message + this.Name[1] + '?' + this.Name[2] + '?' + this.Name[3] + '?' + this.Name[4] + '?';
        //Faza
        message = message + this.IntToString(this.Faza) + '?';
        message = message + this.IntToString(this.KoiENaRed) + '?';
        message = message + this.IntToString(this.mdice1) + '?';
        message = message + this.IntToString(this.dice1) + '?';
        message = message + this.IntToString(0) + '?'; //Delete m-o-v-e-p
        if (this.zar) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 1; a < 5; a++) {
                message = message + this.IntToString(this.PPos[i][a]) + '?';
            }
        }
        message = message + this.IntToString(this.color_index) + '?';
        if (message !== '') {
            this.SendGame(message);
        }
    }

    SendPlayerColor(player: number) {
        let message = '$|c|' + this.IntToString(player) + '|' + this.IntToString(((this.color_index + player - 2) % 4) + 1);
        this.SendGame(message);
    }

    SendInitialization(player: number) {
        let i: number;
        let a: number;
        let message = '$|q|' + this.IntToString(player) + '|';
        if (player === 2) {
            message = message + this.Name[3] + '?' + this.Name[4] + '?' + this.Name[1] + '?';
        }
        if (player === 3) {
            message = message + this.Name[4] + '?' + this.Name[1] + '?' + this.Name[2] + '?';
        }
        if (player === 4) {
            message = message + this.Name[1] + '?' + this.Name[2] + '?' + this.Name[3] + '?';
        }
        message = message + this.IntToString(this.Faza) + '?';
        if (player === 2) {
            let koi = '4';
            if (this.KoiENaRed > 1) {
                koi = this.IntToString(this.KoiENaRed - 1);
            }
            message = message + koi + '?';
        }
        if (player === 3) {
            let koi = this.IntToString(this.KoiENaRed + 2);
            if (this.KoiENaRed > 2) {
                koi = this.IntToString(this.KoiENaRed - 2);
            }
            message = message + koi + '?';
        }
        if (player === 4) {
            let koi = this.IntToString(this.KoiENaRed + 1);
            if (this.KoiENaRed === 4) {
                koi = this.IntToString(1);
            }
            message = message + koi + '?';
        }
        message = message + this.IntToString(this.mdice1) + '?';
        message = message + this.IntToString(this.dice1) + '?';
        if (this.zar) {
            message = message + '1?';
        } else {
            message = message + '0?';
        }

        if (player === 2) {
            for (i = 2; i < 5; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] - 10) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] - 10) + '?';
                    }
                }
            }
            for (i = 1; i < 2; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] + 30) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] + 30) + '?';
                    }
                }
            }
        }

        if (player === 3) {
            for (i = 3; i < 5; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] - 20) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] - 20) + '?';
                    }
                }
            }
            for (i = 1; i < 3; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] + 20) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] + 20) + '?';
                    }
                }
            }
        }
        if (player === 4) {
            for (i = 4; i < 5; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] - 30) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] - 30) + '?';
                    }
                }
            }
            for (i = 1; i < 4; i++) {
                for (a = 1; a < 5; a++) {
                    if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                        message = message + this.IntToString(this.PPos[i][a] + 10) + '?';
                    }
                    if (this.PPos[i][a] < 100) {
                        message = message + this.IntToString((56 + (this.PPos[i][a] - (player - 1) * 14)) % 56) + '?';
                    }
                    if (this.PPos[i][a] > 200) {
                        message = message + this.IntToString(this.PPos[i][a] + 10) + '?';
                    }
                }
            }
        }
        if (player === 2) {
            let koi = '4';
            if (this.KoiZapochva > 1) {
                koi = this.IntToString(this.KoiZapochva - 1);
            }
            message = message + koi + '?';
        }
        if (player === 3) {
            let koi = this.IntToString(this.KoiZapochva + 2);
            if (this.KoiZapochva > 2) {
                koi = this.IntToString(this.KoiZapochva - 2);
            }
            message = message + koi + '?';
        }
        if (player === 4) {
            let koi = this.IntToString(this.KoiZapochva + 1);
            if (this.KoiZapochva === 4) {
                koi = this.IntToString(1);
            }
            message = message + koi + '?';
        }
        if (message !== '') {
            this.SendGame(message);
        }
    }

    ReceiveInitialization(init: string) {
        let i: number;
        let a: number;
        this.InitGame();

        let vpos: number = init.indexOf('?');
        this.Name[2] = init.substring(0, vpos);
        if (this.Name[2] === 'Computer2' || this.Name[2] === 'Computer3' || this.Name[2] === 'Computer4') {
            this.Name[2] = 'Computer2';
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        this.Name[3] = init.substring(0, vpos);
        if (this.Name[3] === 'Computer2' || this.Name[3] === 'Computer3' || this.Name[3] === 'Computer4') {
            this.Name[3] = 'Computer3';
        }
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        this.Name[4] = init.substring(0, vpos);
        if (this.Name[4] === 'Computer2' || this.Name[4] === 'Computer3' || this.Name[4] === 'Computer4') {
            this.Name[4] = 'Computer4';
        }
        init = init.substring(vpos + 1, init.length);
        if (this.Name[2] !== 'Computer2') {
            this.last_Name[2] = this.Name[2];
        }
        if (this.Name[3] !== 'Computer3') {
            this.last_Name[3] = this.Name[3];
        }
        if (this.Name[4] !== 'Computer4') {
            this.last_Name[4] = this.Name[4];
        }
        vpos = init.indexOf('?');
        let s1 = init.substring(0, vpos);
        this.Faza = this._ttoi(s1);
        this.props.m_pwin.GetPlayerRating(1);
        this.props.m_pwin.GetPlayerRating(2);
        this.props.m_pwin.GetPlayerRating(3);
        this.props.m_pwin.GetPlayerRating(4);
        init = init.substring(vpos + 1, init.length);

        this.op_start = true;

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiENaRed = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.mdice1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.dice1 = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);

        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        if (s1 === '1') {
            this.zar = true;
        } else {
            this.zar = false;
        }
        init = init.substring(vpos + 1, init.length);

        for (i = 1; i < 5; i++) {
            for (a = 1; a < 5; a++) {
                vpos = init.indexOf('?');
                s1 = init.substring(0, vpos);
                this.PPos[i][a] = this._ttoi(s1);
                init = init.substring(vpos + 1, init.length);
                if (this.PPos[i][a] < 100) {
                    this.ppoint[i][a].x = this.pplace[this.PPos[i][a]].x;
                    this.ppoint[i][a].y = this.pplace[this.PPos[i][a]].y;
                }
                if (this.PPos[i][a] > 200) {
                    this.ppoint[i][a].x = this.pplaceb[i][this.PPos[i][a] % 10].x;
                    this.ppoint[i][a].y = this.pplaceb[i][this.PPos[i][a] % 10].y;
                }
                if (this.PPos[i][a] > 100 && this.PPos[i][a] < 190) {
                    this.ppoint[i][a].x = this.pplacef[i][this.PPos[i][a] % 10].x;
                    this.ppoint[i][a].y = this.pplacef[i][this.PPos[i][a] % 10].y;
                }
            }
        }
        vpos = init.indexOf('?');
        s1 = init.substring(0, vpos);
        this.KoiZapochva = this._ttoi(s1);
        init = init.substring(vpos + 1, init.length);
        this.loadPionki();
    }

    async ReceiveGame(f: string): Promise<boolean> {
        let strRecvd: string;
        if (f === 'a') {
            //Poluchavane na imenata client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let name = this.props.m_pwin.ReceiveString(12);
            for (let i = 2; i < 5; i++) {
                if (this.Name[i] === '') {
                    if (name !== 'Computer2' && name !== 'Computer3' && name !== 'Computer4') {
                        this.Name[i] = name;
                    } else {
                        if (i === 2) {
                            this.Name[2] = 'Computer2';
                        }
                        if (i === 3) {
                            this.Name[3] = 'Computer3';
                        }
                        if (i === 4) {
                            this.Name[4] = 'Computer4';
                            this.props.m_pwin.InvalidateStolove(3);
                        }
                    }
                    this.props.m_pwin.GetPlayerRating(i);
                    return true;
                }
            }
            return true;
        }
        if (f === 'b') {
            //Poluchavane na koi zapochva client
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (GlobalVariables.Zvuk === 1) {
                this.props.m_pwin.m_sndSound.Play(1112, 'center', 0, 0);
            }
            this.InitGame();
            let koj = this.props.m_pwin.ReceiveString(1);
            if (koj === '1' || koj === '2' || koj === '3' || koj === '4') {
                this.KoiZapochva = this.KoiENaRed = this._ttoi(koj);
                this.Faza = 1;
            }
            if (this.watch) {
                this.setState({});
            }
            return true;
        }
        if (f === 'c') {
            //Poluchavane na Cviat na pionkite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let costr = this.props.m_pwin.ReceiveString(1);
            if (costr !== '1' && costr !== '2' && costr !== '3' && costr !== '4') {
                costr = '1';
            }
            await this.LoadPictures(this._ttoi(costr));
            this.setState({});
            return true;
        }
        if (f === 'd') {
            //Poluchavane na anons
            return true;
        }
        if (f === 'e') {
            //Hvarliane na zarovete
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let dmess = this.props.m_pwin.ReceiveString(1);
            if (this.watch) {
                await this.Timer3();
                await this.ThrowDice(this._ttoi(dmess));
            } else {
                if (this.KoiENaRed === 1) {
                    await this.Timer3();
                }
                if (this.KoiENaRed > 1) {
                    await this.ThrowDice(this._ttoi(dmess));
                    if (this.pause_player !== '') {
                        this.pause_player = '';
                        this.setState({});
                    }
                } else {
                    this.props.m_pwin.SendErrorToLog('Hvarliane na zarovete predi da e svarshil Player1');
                }
            }
            return true;
        }
        if (f === 'f') {
            //Mestene na pionka
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            if (this.pause_player !== '') {
                this.pause_player = '';
                this.setState({});
            }
            let pi = this.props.m_pwin.ReceiveString(4);
            let pe = this.props.m_pwin.ReceiveString(4);
            let pla = this.props.m_pwin.ReceiveString(1);
            await this.ChangePlaces(this._ttoi(pi), this._ttoi(pla), this._ttoi(pe));
            return true;
        }
        if (f === 'g') {
            //Razpadane na vrazkata s niakoi ot igrachite
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(12);
            if (this.props.m_pwin.state.Dlg432PlayersVisible === true) {
                this.props.m_pwin.m_d432.DisconnectPlayer(strRecvd);
            } else {
                let name = strRecvd;
                this.online_timer = this.online_timer_time;
                if (this.pause_player !== '' && this.pause_player === name) {
                    this.pause_player = '';
                    this.setState({});
                }
                if (this.Server) {
                    if (name === this.Name[2]) {
                        this.P2OP = false;
                        this.P2G = true;
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[3]) {
                        this.P3OP = false;
                        this.P3G = true;
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.Name[4]) {
                        this.P4OP = false;
                        this.P4G = true;
                        this.Name[4] = 'Computer4';
                        this.props.m_pwin.InvalidateStolove(3);
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[2]) {
                        this.wait_opnames[2] = '';
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[3]) {
                        this.wait_opnames[3] = '';
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                    if (name === this.wait_opnames[4]) {
                        this.wait_opnames[4] = '';
                        this.setState({});
                        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                        return true;
                    }
                } else {
                    if (name === this.Name[2]) {
                        this.Name[2] = 'Computer2';
                        this.show_user[2] = false;
                        this.props.m_pwin.GetPlayerRating(2);
                        this.setState({});
                    }
                    if (name === this.Name[3]) {
                        this.Name[3] = 'Computer3';
                        this.show_user[3] = false;
                        this.props.m_pwin.GetPlayerRating(3);
                        this.setState({});
                    }
                    if (name === this.Name[4]) {
                        this.Name[4] = 'Computer4';
                        this.props.m_pwin.InvalidateStolove(3);
                        this.show_user[4] = false;
                        this.props.m_pwin.GetPlayerRating(4);
                        this.setState({});
                    }
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(name + ' ' + this.GetLanguageText('has left the game'));
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    return true;
                }
            }
            return true;
        }
        if (f === 'h') {
            this.P2G = true;
            return true;
        }
        if (f === 'i') {
            this.P3G = true;
            return true;
        }
        if (f === 'j') {
            this.P4G = true;
            return true;
        }
        if (f === 'k') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2OP = false;
            }
            if (strRecvd === '3') {
                this.P3OP = false;
            }
            if (strRecvd === '4') {
                this.P4OP = false;
            }
            if (strRecvd === '5') {
                this.P2OP = true;
            }
            if (strRecvd === '6') {
                this.P3OP = true;
            }
            if (strRecvd === '7') {
                this.P4OP = true;
            }
            return true;
        }
        if (f === 'l') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '1') {
                this.Server = true;
                this.props.m_pwin.InitIconsTooltips();
            }
            if (strRecvd === '0') {
                this.Server = false;
            }
            this.setState({});
            return true;
        }
        if (f === 'm') {
            return true;
        }
        if (f === 'n') {
            return true;
        }
        if (f === 'o') {
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            strRecvd = this.props.m_pwin.ReceiveString(1);
            if (strRecvd === '2') {
                this.P2G = false;
                if (this.Faza === 3) {
                    this.P2G = true;
                }
            }
            if (strRecvd === '3') {
                this.P3G = false;
                if (this.Faza === 3) {
                    this.P3G = true;
                }
            }
            if (strRecvd === '4') {
                this.P4G = false;
                if (this.Faza === 3) {
                    this.P4G = true;
                }
            }
            if (strRecvd === '5') {
                this.P2G = true;
            }
            if (strRecvd === '6') {
                this.P3G = true;
            }
            if (strRecvd === '7') {
                this.P4G = true;
            }
            return true;
        }
        if (f === 'p') {
            //Prisaediniavane na Igrach po vreme na igra Server
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 3) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.online_timer = this.online_timer_time;
            let player_name = this.props.m_pwin.ReceiveString(12);
            let pistr = this.props.m_pwin.ReceiveString(1);
            let rating = this.props.m_pwin.ReceiveString(30);
            let player_index = this._ttoi(pistr);
            this.wait_opnames[player_index] = player_name;
            if (this.watch) {
                this.Name[player_index] = player_name;
                this.show_user[player_index] = false;
                this.props.m_pwin.GetPlayerRating(player_index);
                this.setState({});
                return true;
            }
            if (!this.props.m_pwin.ExistInIgnoreList(player_name)) {
                let message =
                    this.GetLanguageText('Would you like to replace the computer') +
                    ' ( ' +
                    this.Name[player_index] +
                    ' )' +
                    ' ' +
                    this.GetLanguageText('with') +
                    ' ' +
                    this.GetLanguageText('user') +
                    ' ' +
                    player_name +
                    ' / ' +
                    this.GetLanguageText('Rating') +
                    ': ' +
                    rating +
                    '?';
                if (
                    this.last_Name[player_index] === player_name ||
                    this.props.m_pwin.isNoVipPlayer(this.Name[1]) ||
                    this.props.m_pwin.IsGuestUser(this.Name[1])
                ) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(message);
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    if (this.wait_opnames[player_index] !== player_name) {
                        return true;
                    }
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    if (player_index === 2) {
                        this.P2OP = true;
                        if (this.Faza === 1) {
                            this.P2G = false;
                        } else {
                            this.P2G = true;
                        }
                    }
                    if (player_index === 3) {
                        this.P3OP = true;
                        if (this.Faza === 1) {
                            this.P3G = false;
                        } else {
                            this.P3G = true;
                        }
                    }
                    if (player_index === 4) {
                        this.P4OP = true;
                        if (this.Faza === 1) {
                            this.P4G = false;
                        } else {
                            this.P4G = true;
                        }
                    }
                    this.setState({});
                    if (this.HaveWatch) {
                        message = '$|s|' + pistr + '|1';
                        this.SendGame(message);
                    }
                    let new_name = '$|w|' + this.IntToString(player_index) + '|' + player_name;
                    this.SendGame(new_name);
                    this.SendInitialization(player_index);
                    this.SendPlayerColor(player_index);
                    return true;
                }
                this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
                if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                    if (this.wait_opnames[player_index] !== player_name) {
                        return true;
                    }
                    this.Name[player_index] = player_name;
                    this.show_user[player_index] = false;
                    this.props.m_pwin.GetPlayerRating(player_index);
                    if (player_index === 2) {
                        this.P2OP = true;
                        if (this.Faza === 1) {
                            this.P2G = false;
                        } else {
                            this.P2G = true;
                        }
                    }
                    if (player_index === 3) {
                        this.P3OP = true;
                        if (this.Faza === 1) {
                            this.P3G = false;
                        } else {
                            this.P3G = true;
                        }
                    }
                    if (player_index === 4) {
                        this.P4OP = true;
                        if (this.Faza === 1) {
                            this.P4G = false;
                        } else {
                            this.P4G = true;
                        }
                    }
                    this.setState({});
                    if (this.HaveWatch) {
                        message = '$|s|' + pistr + '|1';
                        this.SendGame(message);
                    }
                    let new_name = '$|w|' + this.IntToString(player_index) + '|' + player_name;
                    this.SendGame(new_name);
                    this.SendInitialization(player_index);
                    this.SendPlayerColor(player_index);
                    return true;
                } else {
                    if (this.wait_opnames[player_index] !== player_name) {
                        return true;
                    }
                    message = '$|s|' + pistr + '|0';
                    this.SendGame(message);
                    if (this.last_refused_user === player_name) {
                        let title: string = this.GetLanguageText('Do you want to add (username) to your ignore list');
                        title = title.replace('(username)', player_name);
                        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(title + '?');
                        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                            this.props.m_pwin.AddToIgnoreList(player_name);
                        }
                    }
                    this.last_refused_user = player_name;
                }
            } else {
                let message = '$|x|' + player_name;
                this.SendGame(message);
                this.props.m_pwin.pFrame.m_chat_bar.AddMessage(
                    this.GetLanguageText('user') + ' ' + player_name + ' ' + this.GetLanguageText('is in the Ignore List') + '!'
                );
            }
            return true;
        }
        if (f === 'q') {
            //Prisaediniavane na Igrach po vreme na igra
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let ok = this.props.m_pwin.ReceiveString(1);
            if (ok === '1') {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please wait for the next deal');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.setState({});
            }
            if (ok === '0') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('This user refused to join this play');
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            if (ok === '2') {
                this.props.m_pwin.game = '';
                this.props.m_pwin.ShowChatBar(false);
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle("You can't connect to this user");
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                this.props.m_pwin.GoToLobby();
            }
            return true;
        }
        if (f === 'r') {
            //Poluchavane na rezultat ot WaitConnecting
            return true;
        }
        if (f === 's') {
            //Promiana na Imeto na igrach
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 2) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let pistr = this.props.m_pwin.ReceiveString(1);
            let player_index: number = this._ttoi(pistr);
            let player_name: string = this.props.m_pwin.ReceiveString(12);
            this.Name[player_index] = player_name;
            this.props.m_pwin.GetPlayerRating(player_index);
            this.setState({});
            return true;
        }
        if (f === 't') {
            //Ignoriran igrach
            this.props.m_pwin.game = '';
            this.props.m_pwin.ShowChatBar(false);
            return true;
        }
        if (f === 'u') {
            //SendWatchGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            this.HaveWatch = true;
            let windex = this.props.m_pwin.ReceiveString(3);
            this.SendWatchGameInitialization(this._ttoi(windex));
            return true;
        }
        if (f === 'v') {
            //ReceiveWachGameInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(300);
            await this.ReceiveWachGameInitialization(initstr);
            return true;
        }
        if (f === 'w') {
            //ReceiveInitialization
            let br_ = 0;
            for (let i = 0; i < this.props.m_pwin.m_pClientSocket.m_strRecv.length; i++) {
                if (this.props.m_pwin.m_pClientSocket.m_strRecv.charAt(i) === '|') {
                    br_ = br_ + 1;
                }
            }
            if (br_ < 1) {
                this.props.m_pwin.m_pClientSocket.RestoreMessage('$|' + f + '|');
                return false;
            }
            let initstr = this.props.m_pwin.ReceiveString(300);
            this.ReceiveInitialization(initstr);
            return true;
        }
        this.props.m_pwin.SendErrorToLog('Izpusnato e saobshtenie!nss4 : ' + f);
        return true;
    }

    Timer2() {}
    Timer4() {}

    SendKoiENaRed() {
        if (this.P2OP) {
            this.P2G = false;
            let koi = '4';
            if (this.KoiENaRed > 1) {
                koi = this.IntToString(this.KoiENaRed - 1);
            }
            this.SendGame('$|b|' + koi);
        }
        if (this.P3OP) {
            this.P3G = false;
            let koi = this.IntToString(this.KoiENaRed + 2);
            if (this.KoiENaRed > 2) {
                koi = this.IntToString(this.KoiENaRed - 2);
            }
            this.SendGame('$|e|' + koi);
        }
        if (this.P4OP) {
            this.P4G = false;
            let koi = this.IntToString(this.KoiENaRed + 1);
            if (this.KoiENaRed === 4) {
                koi = this.IntToString(1);
            }
            this.SendGame('$|h|' + koi);
        }
        if (this.HaveWatch) {
            this.SendGame('$|*|a|' + this.IntToString(this.KoiENaRed));
        }
    }

    Timer1() {
        if (this.KoiENaRed < 1) {
            return;
        }
        if (this.Faza === 3) {
            if (this.OP) {
                if (this.Server) {
                    if (this.P2G === true && this.P3G === true && this.P4G === true) {
                        if (this.P2OP === true) {
                            this.P2G = false;
                        }
                        if (this.P3OP === true) {
                            this.P3G = false;
                        }
                        if (this.P4OP === true) {
                            this.P4G = false;
                        }
                        this.InitNewGame();
                        this.SendKoiENaRed();
                        this.setState({});
                        this.online_timer = this.online_timer_time;
                    }
                } else {
                }
            }
        }
    }
    async Timer3() {
        if (this.KoiENaRed < 1) {
            return;
        }
        if (this.Faza === 1) {
            if (this.SP) {
                if (this.zar && !this.CanPlay(this.KoiENaRed)) {
                    this.zar = false;
                    this.mdice1 = 0;
                    this.ReloadDice();
                    if (this.dice1 !== 6) {
                        if (!this.IsFinnished(this.KoiENaRed)) {
                            this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                        }
                    }
                    this.setState({});
                }
                if (this.KoiENaRed === 1) {
                    if (this.zar) {
                    } else {
                        if (GlobalVariables.automatic_td) {
                            await this.ThrowDice(-1);
                        }
                    }
                }
                if (this.KoiENaRed > 1) {
                    if (this.zar) {
                        await this.Intelect(this.KoiENaRed);
                    }
                }
                if (this.KoiENaRed > 1) {
                    if (!this.zar) {
                        if (!this.IsFinnished(this.KoiENaRed)) {
                            await this.ThrowDice(-1);
                        }
                    }
                }
            }
            if (this.OP) {
                if (this.Server) {
                    if (this.zar && !this.CanPlay(this.KoiENaRed)) {
                        this.zar = false;
                        this.mdice1 = 0;
                        this.ReloadDice();
                        if (this.dice1 !== 6) {
                            if (!this.IsFinnished(this.KoiENaRed)) {
                                this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                            }
                        }
                        this.ReloadDice();
                    }
                    if (this.KoiENaRed === 1) {
                        if (this.zar) {
                            if (this.online_timer <= 0) {
                                this.sit_out_count = this.sit_out_count + 1;
                                if (this.sit_out_count >= this.sit_out_drop) {
                                    this.props.m_pwin.AutoSitOut();
                                    return;
                                }
                                await this.PlayRnd(this.KoiENaRed);
                            }
                        } else {
                            if (GlobalVariables.automatic_td || this.online_timer <= 0) {
                                if (this.online_timer <= 0) {
                                    this.sit_out_count = this.sit_out_count + 1;
                                    if (this.sit_out_count >= this.sit_out_drop) {
                                        this.props.m_pwin.AutoSitOut();
                                        return;
                                    }
                                }
                                await this.ThrowDice(-1);
                            }
                        }
                    }
                    if (this.KoiENaRed > 1) {
                        if (
                            (this.KoiENaRed === 2 && this.P2OP !== true) ||
                            (this.KoiENaRed === 3 && this.P3OP !== true) ||
                            (this.KoiENaRed === 4 && this.P4OP !== true)
                        ) {
                            if (this.zar) {
                                await this.Intelect(this.KoiENaRed);
                            }
                        }
                    }
                    if (this.KoiENaRed > 1) {
                        if (!this.zar) {
                            if (
                                (this.KoiENaRed === 2 && this.P2OP !== true) ||
                                (this.KoiENaRed === 3 && this.P3OP !== true) ||
                                (this.KoiENaRed === 4 && this.P4OP !== true)
                            ) {
                                if (!this.IsFinnished(this.KoiENaRed)) {
                                    await this.ThrowDice(-1);
                                }
                            }
                        }
                    }
                } else {
                    if (this.watch) {
                        if (this.zar && !this.CanPlay(this.KoiENaRed)) {
                            this.zar = false;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            if (this.dice1 !== 6) {
                                if (!this.IsFinnished(this.KoiENaRed)) {
                                    this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                                }
                            }
                            this.ReloadDice();
                        }
                    } else {
                        if (this.zar && !this.CanPlay(this.KoiENaRed)) {
                            this.zar = false;
                            this.mdice1 = 0;
                            this.ReloadDice();
                            if (this.dice1 !== 6) {
                                if (!this.IsFinnished(this.KoiENaRed)) {
                                    this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                                }
                            }
                            this.ReloadDice();
                        }
                        if (this.KoiENaRed === 1) {
                            if (this.zar) {
                                if (this.online_timer <= 0) {
                                    this.sit_out_count = this.sit_out_count + 1;
                                    if (this.sit_out_count >= this.sit_out_drop) {
                                        this.props.m_pwin.AutoSitOut();
                                        return;
                                    }
                                    await this.PlayRnd(this.KoiENaRed);
                                }
                            } else {
                                if (GlobalVariables.automatic_td || this.online_timer <= 0) {
                                    if (this.online_timer <= 0) {
                                        this.sit_out_count = this.sit_out_count + 1;
                                        if (this.sit_out_count >= this.sit_out_drop) {
                                            this.props.m_pwin.AutoSitOut();
                                            return;
                                        }
                                    }
                                    await this.ThrowDice(-1);
                                }
                            }
                        }
                    }
                }
            }
        }
        return;
    }

    FindPip() {
        for (let i = 0; i < 4; i++) {
            this.pip[i] = 0;
        }
        for (let i = 0; i < 4; i++) {
            for (let j = 1; j < 5; j++) {
                if (this.PPos[i + 1][j] > 200) {
                    this.pip[i] += 68;
                }
                if (this.PPos[i + 1][j] > 100 && this.PPos[i + 1][j] < 200 && this.PPos[i + 1][j] % 10 < 6) {
                    this.pip[i] += 6 - (this.PPos[i + 1][j] % 10);
                }
                if (this.PPos[i + 1][j] < 100) {
                    this.pip[i] += 62 - ((this.PPos[i + 1][j] + (4 - i) * 14) % 56);
                }
            }
        }
    }

    async Intelect(Player: number) {
        this.FindPip();
        let Table: number[] = [];
        for (let i = 0; i < 56; i++) {
            Table[i] = 0;
        }
        for (let i = 1; i < 5; i++) {
            for (let j = 1; j < 5; j++) {
                if (this.PPos[i][j] < 100) {
                    if (Table[this.PPos[i][j]] === 0) {
                        Table[this.PPos[i][j]] = 10 + i;
                    } else {
                        Table[this.PPos[i][j]] += 10;
                    }
                }
            }
        }
        let Moves = 0;
        for (let i = 1; i < 5; i++) {
            if (this.PPos[Player][i] < 100) {
                Moves += 62 - ((this.PPos[Player][i] + (5 - Player) * 14) % 56);
            }
        }
        let ToMove = [0, 0, 0, 0];
        for (let i = 0; i < 4; i++) {
            if (!this.CanPlayPionka(Player, i + 1)) {
                ToMove[i] -= 10000;
            }
            if (this.CanPlayPionka(Player, i + 1)) {
                if (this.PPos[Player][i + 1] > 100) {
                    if ((this.PPos[Player][i + 1] + this.mdice1) % 10 === 6 && this.pip[Player - 1] < 30) {
                        ToMove[i] += 50;
                    }
                    for (let k = 0; k < 4; k++) {
                        if (
                            (this.PPos[Player][i + 1] + this.mdice1) % 10 < 6 &&
                            this.PPos[Player][i + 1] + this.mdice1 === this.PPos[Player][k + 1]
                        ) {
                            ToMove[i] -= 5;
                        }
                    }
                }
                if (this.PPos[Player][i + 1] > 200 && this.mdice1 === 6) {
                    //izkarva
                    if (Table[14 * (Player - 1)] > 0 && Table[14 * (Player - 1)] % 10 !== Player) {
                        ToMove[i] += (Table[14 * Math.floor(Player - 1)] / 10) * 60;
                    }
                    if (Table[14 * (Player - 1) + 1] > 0 && Table[14 * (Player - 1) + 1] % 10 !== Player) {
                        ToMove[i] += 20;
                    }
                    if (Table[14 * (Player - 1) + 2] > 0 && Table[14 * (Player - 1) + 2] % 10 !== Player) {
                        ToMove[i] += 19;
                    }
                    if (Table[14 * (Player - 1) + 3] > 0 && Table[14 * (Player - 1) + 3] % 10 !== Player) {
                        ToMove[i] += 18;
                    }
                    if (Table[14 * (Player - 1) + 4] > 0 && Table[14 * (Player - 1) + 4] % 10 !== Player) {
                        ToMove[i] += 17;
                    }
                    if (Table[14 * (Player - 1) + 5] > 0 && Table[14 * (Player - 1) + 5] % 10 !== Player) {
                        ToMove[i] += 16;
                    }
                    if (Table[14 * (Player - 1) + 6] > 0 && Table[14 * (Player - 1) + 6] % 10 !== Player) {
                        ToMove[i] += 14;
                    }
                    if (Table[14 * (Player - 1) - 1] > 0 && Table[14 * (Player - 1) - 1] % 10 !== Player) {
                        ToMove[i] -= 20;
                    }
                    if (Table[14 * (Player - 1) - 2] > 0 && Table[14 * (Player - 1) - 2] % 10 !== Player) {
                        ToMove[i] -= 19;
                    }
                    if (Table[14 * (Player - 1) - 3] > 0 && Table[14 * (Player - 1) - 3] % 10 !== Player) {
                        ToMove[i] -= 18;
                    }
                    if (Table[14 * (Player - 1) - 4] > 0 && Table[14 * (Player - 1) - 4] % 10 !== Player) {
                        ToMove[i] -= 17;
                    }
                    if (Table[14 * (Player - 1) - 5] > 0 && Table[14 * (Player - 1) - 5] % 10 !== Player) {
                        ToMove[i] -= 16;
                    }
                    if (Table[14 * (Player - 1) - 6] > 0 && Table[14 * (Player - 1) - 6] % 10 !== Player) {
                        ToMove[i] -= 14;
                    }
                    if (Moves < 80) {
                        ToMove[i] += (80 - Moves) * 3;
                    }
                    if (Table[14 * (Player - 1)] % 10 === Player) {
                        ToMove[i] -= 120;
                    }
                }
                if (this.PPos[Player][i + 1] < 100) {
                    ToMove[i] += 4;
                    if (this.PPos[Player][i + 1] < 14 * (Player - 1) && this.PPos[Player][i + 1] + this.mdice1 >= 14 * (Player - 1)) {
                        //vkarva
                        ToMove[i] += 100;
                        if (Table[14 * (Player - 1) - 1] > 0 && Table[14 * (Player - 1) - 1] % 10 !== Player) {
                            ToMove[i] += 40;
                        }
                        if (Table[14 * (Player - 1) - 2] > 0 && Table[14 * (Player - 1) - 2] % 10 !== Player) {
                            ToMove[i] += 39;
                        }
                        if (Table[14 * (Player - 1) - 3] > 0 && Table[14 * (Player - 1) - 3] % 10 !== Player) {
                            ToMove[i] += 38;
                        }
                        if (Table[14 * (Player - 1) - 4] > 0 && Table[14 * (Player - 1) - 4] % 10 !== Player) {
                            ToMove[i] += 37;
                        }
                        if (Table[14 * (Player - 1) - 5] > 0 && Table[14 * (Player - 1) - 5] % 10 !== Player) {
                            ToMove[i] += 36;
                        }
                        if (Table[14 * (Player - 1) - 6] > 0 && Table[14 * (Player - 1) - 6] % 10 !== Player) {
                            ToMove[i] += 34;
                        }
                    } else {
                        let toend = (this.PPos[Player][i + 1] + (5 - Player) * 14) % 56;
                        ToMove[i] += toend;
                        if (this.PPos[Player][i + 1] % 14 === 0) {
                            for (let k = 1; k < 5; k++) {
                                if (this.PPos[Math.floor(this.PPos[Player][i + 1] / 14) + 1][k] > 200) {
                                    ToMove[i] -= (56 - (this.PPos[Player][i + 1] + ((14 * (5 - Player)) % 56))) * 2;
                                    break;
                                }
                            }
                        }
                        if (Table[(this.PPos[Player][i + 1] + this.mdice1) % 56] !== 0) {
                            //da udria
                            if (Table[(this.PPos[Player][i + 1] + this.mdice1) % 56] % 10 === Player) {
                                ToMove[i] -= 30;
                            }
                            if (Table[(this.PPos[Player][i + 1] + this.mdice1) % 56] % 10 !== Player) {
                                ToMove[i] += 150;
                                let KillPl = Table[(this.PPos[Player][i + 1] + this.mdice1) % 56] % 10;
                                let KillPull = (this.PPos[Player][i + 1] + this.mdice1) % 56;
                                let Bad = (KillPull + (5 - KillPl) * 14) % 56;
                                ToMove[i] += Bad * 6 * Math.floor(Table[(this.PPos[Player][i + 1] + this.mdice1) % 56] / 10);
                                if (this.pip[Player - 1] - this.pip[KillPl - 1] > 0) {
                                    ToMove[i] += (this.pip[Player - 1] - this.pip[KillPl - 1]) * 2;
                                }
                            }
                        }
                        ToMove[i] +=
                            (this.HowGoodIsPos((this.PPos[Player][i + 1] + this.mdice1) % 56, Player, Table) -
                                this.HowGoodIsPos(this.PPos[Player][i + 1], Player, Table)) *
                            3;
                    }
                }
            }
        }
        for (let i = 0; i < 4; i++) {
            if (ToMove[i] >= ToMove[0] && ToMove[i] >= ToMove[1] && ToMove[i] >= ToMove[2] && ToMove[i] >= ToMove[3]) {
                await this.ChangePlaces(i + 1, Player);
                break;
            }
        }
    }

    async ChangePlaces(Pionka: number, Player: number, Place: number = undefined) {
        this.props.m_pwin.KillTimer(3);
        if (typeof Place === 'undefined') {
            Place = Pionka + 5;
        }
        if (Player === 1) {
            if (this.PPos[Player][Pionka] > 200) {
                this.PPos[Player][Pionka] = 0;
                this.ppoint[Player][Pionka].x = this.pplace[0].x;
                this.ppoint[Player][Pionka].y = this.pplace[0].y;
                for (let i = 1; i < 5; i++) {
                    for (let j = 1; j < 5; j++) {
                        if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                            this.PPos[i][j] = 200 + 10 * i + j;
                            this.ppoint[i][j].x = this.pplaceb[i][j].x;
                            this.ppoint[i][j].y = this.pplaceb[i][j].y;
                        }
                    }
                }
                await this.ChangePlacesEnd(Pionka, Player, Place);
                return;
            }
            if (this.PPos[Player][Pionka] < 100) {
                if (this.PPos[Player][Pionka] + this.mdice1 < 56) {
                    this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1;
                    this.ppoint[Player][Pionka].x = this.pplace[this.PPos[Player][Pionka]].x;
                    this.ppoint[Player][Pionka].y = this.pplace[this.PPos[Player][Pionka]].y;
                    for (let i = 1; i < 5; i++) {
                        for (let j = 1; j < 5; j++) {
                            if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                                this.PPos[i][j] = 200 + 10 * i + j;
                                this.ppoint[i][j].x = this.pplaceb[i][j].x;
                                this.ppoint[i][j].y = this.pplaceb[i][j].y;
                            }
                        }
                    }
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if (this.PPos[Player][Pionka] + this.mdice1 >= 56) {
                    if (this.PPos[Player][Pionka] + this.mdice1 - 55 !== 6) {
                        this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1 - 55 + 110;
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                    if (this.PPos[Player][Pionka] + this.mdice1 - 55 === 6) {
                        this.PPos[Player][Pionka] = Place + 110;
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                }
            }
            if (this.PPos[Player][Pionka] > 100 && this.PPos[Player][Pionka] < 190) {
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 <= 5) {
                    this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 === 6) {
                    this.PPos[Player][Pionka] = Place + 110;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
            }
        }
        if (Player === 2) {
            if (this.PPos[Player][Pionka] > 200) {
                this.PPos[Player][Pionka] = 14;
                this.ppoint[Player][Pionka].x = this.pplace[14].x;
                this.ppoint[Player][Pionka].y = this.pplace[14].y;
                for (let i = 1; i < 5; i++) {
                    for (let j = 1; j < 5; j++) {
                        if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                            this.PPos[i][j] = 200 + 10 * i + j;
                            this.ppoint[i][j].x = this.pplaceb[i][j].x;
                            this.ppoint[i][j].y = this.pplaceb[i][j].y;
                        }
                    }
                }
                await this.ChangePlacesEnd(Pionka, Player, Place);
                return;
            }
            if (this.PPos[Player][Pionka] < 100) {
                if (this.PPos[Player][Pionka] >= 14 || (this.PPos[Player][Pionka] < 14 && this.PPos[Player][Pionka] + this.mdice1 < 14)) {
                    //
                    this.PPos[Player][Pionka] = (this.PPos[Player][Pionka] + this.mdice1) % 56;
                    this.ppoint[Player][Pionka].x = this.pplace[this.PPos[Player][Pionka]].x;
                    this.ppoint[Player][Pionka].y = this.pplace[this.PPos[Player][Pionka]].y;
                    for (let i = 1; i < 5; i++) {
                        for (let j = 1; j < 5; j++) {
                            if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                                this.PPos[i][j] = 200 + 10 * i + j;
                                this.ppoint[i][j].x = this.pplaceb[i][j].x;
                                this.ppoint[i][j].y = this.pplaceb[i][j].y;
                            }
                        }
                    }
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if (this.PPos[Player][Pionka] < 14 && this.PPos[Player][Pionka] + this.mdice1 >= 14) {
                    //
                    if (this.PPos[Player][Pionka] + this.mdice1 - 13 !== 6) {
                        //
                        this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1 - 13 + 100 + Player * 10; //
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                    if (this.PPos[Player][Pionka] + this.mdice1 - 13 === 6) {
                        //
                        this.PPos[Player][Pionka] = Place + (100 + Player * 10);
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                }
            }
            if (this.PPos[Player][Pionka] > 100 && this.PPos[Player][Pionka] < 200) {
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 < 6) {
                    this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 === 6) {
                    this.PPos[Player][Pionka] = Place + 100 + 10 * Player;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
            }
        }
        if (Player === 3) {
            if (this.PPos[Player][Pionka] > 200) {
                this.PPos[Player][Pionka] = 28;
                this.ppoint[Player][Pionka].x = this.pplace[28].x;
                this.ppoint[Player][Pionka].y = this.pplace[28].y;
                for (let i = 1; i < 5; i++) {
                    for (let j = 1; j < 5; j++) {
                        if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                            this.PPos[i][j] = 200 + 10 * i + j;
                            this.ppoint[i][j].x = this.pplaceb[i][j].x;
                            this.ppoint[i][j].y = this.pplaceb[i][j].y;
                        }
                    }
                }
                await this.ChangePlacesEnd(Pionka, Player, Place);
                return;
            }
            if (this.PPos[Player][Pionka] < 100) {
                if (this.PPos[Player][Pionka] >= 28 || (this.PPos[Player][Pionka] < 28 && this.PPos[Player][Pionka] + this.mdice1 < 28)) {
                    this.PPos[Player][Pionka] = (this.PPos[Player][Pionka] + this.mdice1) % 56;
                    this.ppoint[Player][Pionka].x = this.pplace[this.PPos[Player][Pionka]].x;
                    this.ppoint[Player][Pionka].y = this.pplace[this.PPos[Player][Pionka]].y;
                    for (let i = 1; i < 5; i++) {
                        for (let j = 1; j < 5; j++) {
                            if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                                this.PPos[i][j] = 200 + 10 * i + j;
                                this.ppoint[i][j].x = this.pplaceb[i][j].x;
                                this.ppoint[i][j].y = this.pplaceb[i][j].y;
                            }
                        }
                    }
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if (this.PPos[Player][Pionka] < 28 && this.PPos[Player][Pionka] + this.mdice1 >= 28) {
                    if (this.PPos[Player][Pionka] + this.mdice1 - 27 !== 6) {
                        this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1 - 27 + 100 + Player * 10;
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                    if (this.PPos[Player][Pionka] + this.mdice1 - 27 === 6) {
                        this.PPos[Player][Pionka] = Place + (100 + Player * 10);
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                }
            }
            if (this.PPos[Player][Pionka] > 100 && this.PPos[Player][Pionka] < 200) {
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 < 6) {
                    this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 === 6) {
                    this.PPos[Player][Pionka] = Place + 100 + 10 * Player;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
            }
        }
        if (Player === 4) {
            if (this.PPos[Player][Pionka] > 200) {
                this.PPos[Player][Pionka] = 42;
                this.ppoint[Player][Pionka].x = this.pplace[42].x;
                this.ppoint[Player][Pionka].y = this.pplace[42].y;

                for (let i = 1; i < 5; i++) {
                    for (let j = 1; j < 5; j++) {
                        if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                            this.PPos[i][j] = 200 + 10 * i + j;
                            this.ppoint[i][j].x = this.pplaceb[i][j].x;
                            this.ppoint[i][j].y = this.pplaceb[i][j].y;
                        }
                    }
                }
                await this.ChangePlacesEnd(Pionka, Player, Place);
                return;
            }
            if (this.PPos[Player][Pionka] < 100) {
                if (this.PPos[Player][Pionka] >= 42 || (this.PPos[Player][Pionka] < 42 && this.PPos[Player][Pionka] + this.mdice1 < 42)) {
                    this.PPos[Player][Pionka] = (this.PPos[Player][Pionka] + this.mdice1) % 56;
                    this.ppoint[Player][Pionka].x = this.pplace[this.PPos[Player][Pionka]].x;
                    this.ppoint[Player][Pionka].y = this.pplace[this.PPos[Player][Pionka]].y;
                    for (let i = 1; i < 5; i++) {
                        for (let j = 1; j < 5; j++) {
                            if (i !== Player && this.PPos[i][j] === this.PPos[Player][Pionka]) {
                                this.PPos[i][j] = 200 + 10 * i + j;
                                this.ppoint[i][j].x = this.pplaceb[i][j].x;
                                this.ppoint[i][j].y = this.pplaceb[i][j].y;
                            }
                        }
                    }
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if (this.PPos[Player][Pionka] < 42 && this.PPos[Player][Pionka] + this.mdice1 >= 42) {
                    if (this.PPos[Player][Pionka] + this.mdice1 - 41 !== 6) {
                        this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1 - 41 + 100 + Player * 10;
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                    if (this.PPos[Player][Pionka] + this.mdice1 - 41 === 6) {
                        this.PPos[Player][Pionka] = Place + (100 + Player * 10);
                        this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                        this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                        this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                        await this.ChangePlacesEnd(Pionka, Player, Place);
                        return;
                    }
                }
            }
            if (this.PPos[Player][Pionka] > 100 && this.PPos[Player][Pionka] < 200) {
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 < 6) {
                    this.PPos[Player][Pionka] = this.PPos[Player][Pionka] + this.mdice1;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
                if ((this.PPos[Player][Pionka] + this.mdice1) % 10 === 6) {
                    this.PPos[Player][Pionka] = Place + 100 + 10 * Player;
                    this.ppoint[Player][Pionka].x = this.pplacef[Player][this.PPos[Player][Pionka] % 10].x;
                    this.ppoint[Player][Pionka].y = this.pplacef[Player][this.PPos[Player][Pionka] % 10].y;
                    this.FinPlace[Player][this.PPos[Player][Pionka] % 10] = 1;
                    await this.ChangePlacesEnd(Pionka, Player, Place);
                    return;
                }
            }
        }
    }

    loadPionki() {
        this.pionki = [];
        let res: { index: number; y: number; x: number }[] = [];
        for (let i = 1; i < 5; i++) {
            for (let a = 1; a < 5; a++) {
                res.push({
                    index: i * 10 + a,
                    y: this.ppoint[i][a].y,
                    x: this.ppoint[i][a].x,
                });
            }
        }
        let sortedRes = res.sort((a, b) => ((a.y > b.y && a.x !== b.x) || (a.y > b.y + 9 && a.x === b.x) ? 1 : -1));

        for (let i = 0; i < sortedRes.length; i++) {
            this.pionki.push(
                <CPicture
                    image={this.pionka[Math.floor(sortedRes[i].index / 10)][sortedRes[i].index % 10].image}
                    m_x={this.ppoint[Math.floor(sortedRes[i].index / 10)][sortedRes[i].index % 10].x + GlobalVariables.pionkaMoveLeft}
                    m_y={this.ppoint[Math.floor(sortedRes[i].index / 10)][sortedRes[i].index % 10].y + GlobalVariables.pionkaMoveTop}
                    m_Width={GlobalVariables.pionkaWidth}
                    m_Height={GlobalVariables.pionkaHeight}
                    cursor={this.cursor}
                    folder={'files_2.0'}
                    key={'Pionka_' + sortedRes[i].index}
                />
            );
        }
    }

    async ChangePlacesEnd(Pionka: number, Player, Place) {
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(1200, 'center', 0, 0);
        }
        for (let i = 1; i < 5; i++) {
            for (let k = 1; k < 5; k++) {
                let c = 0;
                for (let j = 1; j < k; j++) {
                    if (this.PPos[i][k] === this.PPos[i][j] && this.PPos[i][j] < 160) {
                        c++;
                    }
                }
                if (this.PPos[i][k] < 100) {
                    this.ppoint[i][k].y = this.pplace[this.PPos[i][k]].y + 3 * c;
                }
                if (this.PPos[i][k] > 100 && this.PPos[i][k] < 200 && this.PPos[i][k] % 10 !== 6) {
                    this.ppoint[i][k].y = this.pplacef[i][this.PPos[i][k] % 10].y + 3 * c;
                }
            }
        }
        this.loadPionki();
        this.zar = false;
        if (this.OP) {
            if (!this.watch) {
                if (this.Server) {
                    if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                        if (Player === 1) {
                            this.SendGame('$|k|' + this.IntToString(Pionka) + '|' + this.IntToString(Place) + '|' + this.IntToString(Player));
                        } else {
                            if (
                                (Player === 2 && this.P2OP !== true) ||
                                (Player === 3 && this.P3OP !== true) ||
                                (Player === 4 && this.P4OP !== true)
                            ) {
                                this.SendGame('$|k|' + this.IntToString(Pionka) + '|' + this.IntToString(Place) + '|' + this.IntToString(Player));
                            }
                        }
                    }
                } else {
                    if (Player === 1) {
                        this.SendGame('$|k|' + this.IntToString(Pionka) + '|' + this.IntToString(Place) + '|' + this.IntToString(Player));
                    }
                }
            }
            this.online_timer = this.online_timer_time;
        }
        this.mdice1 = 0;
        this.setState({});
        if (this.dice1 !== 6) {
            if (!this.IsFinnished(Player)) {
                this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                this.setState({});
            }
        }
        if (this.IsFinnished(Player)) {
            await this.InitRezDlg(Player);
        }
        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
    }

    HowGoodIsPos(Place: number, Player: number, Table) {
        let que = 0;
        if (Place % 14 === 0) {
            for (let k = 1; k < 5; k++) {
                if (this.PPos[Math.floor(Place / 14) + 1][k] > 200) {
                    que -= 56 - (Place + ((14 * (5 - Player)) % 56));
                    break;
                }
            }
        }
        for (let i = 1; i < 7; i++) {
            if (Place >= 0 && !(Place < (5 - Player) * 14 && Place + i >= (5 - Player) * 14)) {
                if (Table[Place + i] > 0 && Table[Place + i] % 10 !== Player) {
                    que += 5;
                    que += (Place + i + (5 - Math.floor(Table[Place + i] / 10)) * 14) % 56;
                    if (
                        this.pip[Math.floor(Table[(Place + i - 1) % 56] / 10)] < 40 &&
                        this.pip[Math.floor(Table[(Place + i - 1) % 56] / 10)] - this.pip[Player - 1] > 30
                    ) {
                        que += 30;
                    }
                }
            }
        }
        for (let i = 1; i < 7; i++) {
            if (Table[(Place - i + 56) % 56] > 0 && Table[(Place - i + 56) % 56] % 10 !== Player) {
                let Pl1 = Table[(Place - i + 56) % 56] % 10;
                if (!(Place - i < (5 - Pl1) * 14 && Place >= (5 - Pl1) * 14)) {
                    if (!(this.pip[Pl1 - 1] < 40 && this.pip[Math.floor(Table[Pl1 - 1] / 10)] - this.pip[Player - 1] > 30)) {
                        que -= 56 - (Place + ((14 * (5 - Player)) % 56)) + 5;
                    }
                }
            }
        }
        return que;
    }

    GetPlayerPosition(player) {
        this.FindPip();
        let pos = 1;
        if (this.pip[player - 1] > this.pip[0] && player !== 1) {
            pos = pos + 1;
        }
        if (this.pip[player - 1] > this.pip[1] && player !== 2) {
            pos = pos + 1;
        }
        if (this.pip[player - 1] > this.pip[2] && player !== 3) {
            pos = pos + 1;
        }
        if (this.pip[player - 1] > this.pip[3] && player !== 4) {
            pos = pos + 1;
        }
        return pos;
    }

    InitNewGame() {
        this.op_start = false;
        this.InitGame();
        this.pause = 0;
        this.pause_player = '';
        for (let i = 1; i <= 4; i++) {
            this.last_Name[i] = this.Name[i];
        }
        this.setState({});
        if (this.SP === true || !this.Server || (this.OP && !this.P2OP && !this.P3OP && !this.P4OP)) {
            //    this.props.m_pwin.showAd();
        }
    }

    async InitRezDlg(Player: number) {
        this.Faza = 2;
        this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(this.GetLanguageText('The game profit2') + ' ' + this.Name[Player]);
        await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();

        if (!this.watch) {
            let nssc_rezult = await this.GetProfileString('Options', 'Ludo', '');
            let crezult = '';
            let ctime = this.getCDateTime();
            let player1 = this.Name[1];
            let player2 = this.Name[2];
            let player3 = this.Name[3];
            let player4 = this.Name[4];
            crezult = ctime + ' ' + player1 + ', ' + player2 + ', ' + player3 + ', ' + player4 + ' ';
            crezult = crezult + ': ' + this.IntToString(this.GetPlayerPosition(1));
            if (Player === 1) {
                crezult = crezult + 'w;';
            } else {
                crezult = crezult + 'l;';
            }
            if (this.OP) {
                if (Player === 1) {
                    let rezult = '$|n|1';
                    this.SendGame(rezult);
                } else {
                    let rezult = '$|n|' + this.IntToString(this.GetPlayerPosition(1));
                    this.SendGame(rezult);
                }
            }

            this.WriteProfileString('Options', 'Ludo', nssc_rezult + crezult);
            this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle('New Game');
            if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                if (this.SP) {
                    this.InitNewGame();
                }
            } else {
                if (this.OP) {
                    this.props.m_pwin.GoToLobby();
                } else {
                    this.props.m_pwin.OnNewGame();
                }
                this.props.m_pwin.showAd();
                return;
            }
            if (this.OP) {
                this.Faza = 3;
            }
            if (this.OP) {
                if (!this.Server) {
                    this.SendGame('R');
                }
            }
            this.setState({});
        }
    }

    async PlayRnd(Player: number) {
        if (this.KoiENaRed === 1) {
            //this.OnLButtonUp(new CPoint(0, 0));
        }
        if (this.Faza === 1) {
            for (let i = 1; i < 5; i++) {
                if (this.CanPlayPionka(Player, i)) {
                    await this.ChangePlaces(i, Player);
                    return;
                }
            }
        }
    }

    Timer7() {
        if (this.KoiENaRed < 1) {
            return;
        }
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.P3OP || this.P4OP || !this.Server)) {
                if (this.pause_player !== '') {
                    this.setState({});
                }
                if (this.online_timer > 0) {
                    this.online_timer = this.props.m_pwin.props.isAppActive
                        ? this.online_timer - 1
                        : this.online_timer - Math.floor(this.online_timer_time / 3);
                }
                if (this.online_timer === 5) {
                    if (this.Faza === 1 && this.KoiENaRed === 1) {
                        if (GlobalVariables.Zvuk === 1) {
                            this.props.m_pwin.m_sndSound.Play(1113, 'center', 0, 0);
                        }
                    }
                }
                this.setState({});
            }
        }
    }

    async LoadPictures(color: number) {
        this.color_index = color;
        for (let i = 1; i < 5; i++) {
            this.pionka[i] = [];
            for (let a = 1; a < 5; a++) {
                this.pionka[i][a] = new CPicture(null);
                this.pionka[i][a].LoadFile('nssc/pn' + (((i + 2 + color) % 4) + 1) + '.png');
            }
        }
        let dc = await this.GetProfileInt('Options', 'dicenssc', 1);
        this.LoadDice(dc);
        this.star.LoadFile('nssc/star.png');
        this.LoadFiles();
    }

    LoadDice(dc: number) {
        this.diceColor = dc;
    }

    DrawPause() {
        if (!this.watch && this.OP) {
            if (this.OP && (this.P2OP || this.P3OP || this.P4OP || !this.Server)) {
                if (this.Faza === 1 && this.KoiENaRed === 1) {
                    if (this.pause === 0) {
                        return (
                            <CPicture
                                image={'pause.gif'}
                                m_x={this.pause_rect.left}
                                m_y={this.pause_rect.top}
                                m_Width={20}
                                m_Height={20}
                                cursor={this.cursor}
                                key={'DrawPause'}
                            />
                        );
                    }
                }
            }
        }
        return null;
    }

    DrawTimer() {
        if (!this.watch && this.OP) {
            if (this.OP && (this.P2OP || this.P3OP || this.P4OP || !this.Server)) {
                if (this.Faza === 1 && this.KoiENaRed === 1) {
                    return this.DrawBorderText(this.IntToString(this.online_timer), this.pause_rect.left + 24, this.pause_rect.top, 'DrawTimer');
                }
            }
            if (this.Faza === 1 && this.KoiENaRed !== 1) {
                if (this.pause_player !== '' && this.online_timer === Math.floor(this.online_timer / 2) * 2) {
                    return this.DrawBorderText('Pause' + ': ' + this.pause_player, 650, this.pause_rect.top, 'DrawTimer');
                }
            }
        }
        return null;
    }

    async OnRButtonDown(point: CPoint) {
        if (
            this.ScaleRect(
                new CRect(
                    this.dice_point[this.KoiENaRed].x,
                    this.dice_point[this.KoiENaRed].y,
                    this.dice_point[this.KoiENaRed].x + GlobalVariables.diceWidth,
                    this.dice_point[this.KoiENaRed].y + GlobalVariables.diceHeight
                )
            ).PtInRect(point)
        ) {
            let ct = await this.GetProfileInt('Options', 'dicenssc', 1);
            let nt = this.GetNextDesign(ct, 'dice');
            if (ct !== nt) {
                this.WriteProfileInt('Options', 'dicenssc', nt);
                this.LoadDice(nt);
                this.ReloadDice();
            }
            return;
        }
        if (this.watch) {
            for (let i = 2; i <= 4; i++) {
                if (this.Name[i] === 'Computer2' || this.Name[i] === 'Computer3' || this.Name[i] === 'Computer4') {
                    let s: CSize = this.GetTextExtent(this.Name[i], 13);
                    let width = s.cx;
                    if (
                        this.ScaleRect(new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)).PtInRect(
                            point
                        )
                    ) {
                        this.props.m_pwin.m_dbo.auto_connect = this.Name[1];
                        this.props.m_pwin.GoToLobby();
                    }
                }
            }
        }
    }

    async OnLButtonDown(point: CPoint) {
        if (this.OP) {
            for (let i = 1; i <= 4; i++) {
                if (this.chat_message[i] !== '') {
                    this.chat_message[i] = '';
                    this.setState({});
                }
            }
            if (Platform.OS === 'web') {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.show_user[i]) {
                        this.show_user[i] = false;
                        this.props.m_pwin.openPage(this.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.Name[i]);
                    }
                }
            } else {
                if (GlobalVariables.show_pictures) {
                    let begin = 2;
                    if (this.watch) {
                        begin = 1;
                    }
                    for (let i = begin; i <= 4; i++) {
                        if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                            let s: CSize = this.GetTextExtent(this.Name[i], 13);
                            let width = s.cx;
                            if (
                                this.ScaleRect(
                                    new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                                ).PtInRect(point)
                            ) {
                                if (!this.show_user[i]) {
                                    this.show_user[i] = true;
                                    this.setState({});
                                }
                            } else {
                                if (this.show_user[i]) {
                                    this.show_user[i] = false;
                                    this.setState({});
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!this.watch) {
            if (this.OP && (this.P2OP || this.P3OP || this.P4OP || !this.Server)) {
                if (this.Faza === 1 && this.KoiENaRed === 1) {
                    if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                        if (this.pause === 0) {
                            this.pause = 1;
                            this.online_timer = 180;
                            this.setState({});
                            let p_str = '*|A|' + this.Name[1];
                            this.SendGame(p_str);
                            this.props.m_pwin.showAd();
                        }
                    }
                }
            }

            if (this.KoiENaRed === 1 && this.zar) {
                for (let i = 1; i < 5; i++) {
                    if (
                        new CRect(
                            this.ppoint[1][i].x * GlobalVariables.scale + GlobalVariables.pionkaMoveLeft * GlobalVariables.scale,
                            this.ppoint[1][i].y * GlobalVariables.scale + (GlobalVariables.pionkaMoveTop + 8) * GlobalVariables.scale,
                            this.ppoint[1][i].x * GlobalVariables.scale +
                                (GlobalVariables.pionkaMoveLeft - 7) * GlobalVariables.scale +
                                GlobalVariables.pionkaWidth * GlobalVariables.scale,
                            this.ppoint[1][i].y * GlobalVariables.scale +
                                (GlobalVariables.pionkaMoveTop - 2) * GlobalVariables.scale +
                                GlobalVariables.pionkaHeight * GlobalVariables.scale
                        ).PtInRect(point)
                    ) {
                        if (this.CanPlayPionka(1, i)) {
                            await this.ChangePlaces(i, 1);
                            this.sit_out_count = 0;
                            return;
                        }
                    }
                }
            }
            if (!this.zar && this.KoiENaRed === 1) {
                if (
                    this.dice_point[this.KoiENaRed].x * GlobalVariables.scale <= point.x &&
                    this.dice_point[this.KoiENaRed].y * GlobalVariables.scale <= point.y &&
                    this.dice_point[this.KoiENaRed].x * GlobalVariables.scale + GlobalVariables.diceWidth * GlobalVariables.scale >= point.x &&
                    this.dice_point[this.KoiENaRed].y * GlobalVariables.scale + GlobalVariables.diceHeight * GlobalVariables.scale >= point.y
                ) {
                    this.sit_out_count = 0;
                    await this.ThrowDice(-1);
                }
            }
        }
    }

    ReloadDice() {
        this.setState({});
    }

    CanPlay(Player) {
        for (let i = 1; i < 5; i++) {
            if (this.CanPlayPionka(Player, i)) {
                return true;
            }
        }
        return false;
    }

    CanPlayPionka(Player, Pionka) {
        if (this.PPos[Player][Pionka] > 200) {
            if (this.mdice1 === 6) {
                return true;
            } else {
                return false;
            }
        }
        if (this.PPos[Player][Pionka] < 100 && this.PPos[Player][Pionka] >= 0) {
            return true;
        }
        if (this.PPos[Player][Pionka] < 200 && this.PPos[Player][Pionka] >= 100) {
            if (this.mdice1 + (this.PPos[Player][Pionka] % 10) <= 6) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    IsFinnished(Player) {
        for (let i = 1; i < 5; i++) {
            if (this.PPos[Player][i] <= 105 + Player * 10 || this.PPos[Player][i] > 109 + Player * 10) {
                return false;
            }
        }
        return true;
    }

    async ThrowDice(d: number) {
        this.props.m_pwin.KillTimer(3);
        /*    if (this.KoiENaRed === 1) {
            d = 6;
        }*/
        if (this.zar && !this.CanPlay(this.KoiENaRed)) {
            this.zar = false;
            this.mdice1 = 0;
            if (this.dice1 !== 6) {
                if (!this.IsFinnished(this.KoiENaRed)) {
                    this.KoiENaRed = (this.KoiENaRed % 4) + 1;
                }
            }
        }
        if (d === -1) {
            this.dice1 = Math.floor(Math.random() * 6) + 1;
        } else {
            this.dice1 = d;
        }
        if (GlobalVariables.Zvuk === 1) {
            this.props.m_pwin.m_sndSound.Play(1111, 'center', 0, 0);
        }
        this.zar = true;
        this.mdice1 = this.dice1;
        if (this.OP) {
            if (!this.watch) {
                if (this.Server) {
                    if (this.KoiENaRed === 1) {
                        if (this.P2OP || this.P3OP || this.P4OP || this.HaveWatch) {
                            let message = '$|o|' + this.IntToString(this.dice1);
                            this.SendGame(message);
                        }
                    } else {
                        if (
                            (this.KoiENaRed === 2 && this.P2OP !== true) ||
                            (this.KoiENaRed === 3 && this.P3OP !== true) ||
                            (this.KoiENaRed === 4 && this.P4OP !== true)
                        ) {
                            let message = '$|o|' + this.IntToString(this.dice1);
                            this.SendGame(message);
                        }
                    }
                } else {
                    if (this.KoiENaRed === 1) {
                        let message = '$|o|' + this.IntToString(this.dice1);
                        this.SendGame(message);
                    }
                }
            }
        }
        this.online_timer = this.online_timer_time;
        if (this.OP && !this.watch) {
            if (this.KoiENaRed === 1) {
                if (!this.op_start) {
                    this.SendGame('L');
                    this.op_start = true;
                }
            }
        }
        this.setState({});
        this.props.m_pwin.SetTimer(3, this.Speed[3] * 100);
    }

    OnMouseMove(e) {
        let point = new CPoint(e.clientX - GlobalVariables.positionLeft, e.clientY - GlobalVariables.positionTop);

        if (this.OP) {
            if (GlobalVariables.show_pictures) {
                let begin = 2;
                if (this.watch) {
                    begin = 1;
                }
                for (let i = begin; i <= 4; i++) {
                    if (this.Name[i] !== 'Computer2' && this.Name[i] !== 'Computer3' && this.Name[i] !== 'Computer4') {
                        let s: CSize = this.GetTextExtent(this.Name[i], 13);
                        let width = s.cx;
                        if (
                            this.ScaleRect(
                                new CRect(this.NamePos[i].x, this.NamePos[i].y, this.NamePos[i].x + width, this.NamePos[i].y + 20)
                            ).PtInRect(point)
                        ) {
                            if (!this.show_user[i]) {
                                this.show_user[i] = true;
                                this.setState({});
                            }
                        } else {
                            if (this.show_user[i]) {
                                this.show_user[i] = false;
                                this.setState({});
                            }
                        }
                    }
                }
            }
            if (this.watch) {
                this.cursor = GlobalConstants.CURSOR_WATCH;
                this.setState({});
                return;
            }
            if (this.ScaleRect(this.pause_rect).PtInRect(point)) {
                if (this.P2OP || this.P3OP || this.P4OP || !this.Server) {
                    if (this.Faza === 1 && this.KoiENaRed === 1 && this.pause === 0) {
                        this.cursor = GlobalConstants.CURSOR_HAND;
                        this.setState({});
                        return;
                    }
                }
            }
        }
        if (this.KoiENaRed === 1) {
            if (this.zar) {
                for (let i = 1; i < 5; i++) {
                    if (
                        new CRect(
                            this.ppoint[1][i].x * GlobalVariables.scale + GlobalVariables.pionkaMoveLeft * GlobalVariables.scale,
                            this.ppoint[1][i].y * GlobalVariables.scale + (GlobalVariables.pionkaMoveTop + 8) * GlobalVariables.scale,
                            this.ppoint[1][i].x * GlobalVariables.scale +
                                (GlobalVariables.pionkaMoveLeft - 7) * GlobalVariables.scale +
                                GlobalVariables.pionkaWidth * GlobalVariables.scale,
                            this.ppoint[1][i].y * GlobalVariables.scale +
                                (GlobalVariables.pionkaMoveTop - 2) * GlobalVariables.scale +
                                GlobalVariables.pionkaHeight * GlobalVariables.scale
                        ).PtInRect(point)
                    ) {
                        if (this.CanPlayPionka(1, i) && this.KoiENaRed === 1 && this.zar && this.mdice1 > 0) {
                            this.cursor = GlobalConstants.CURSOR_HAND;
                            this.loadPionki();
                            this.setState({});
                            return;
                        }
                    }
                }
            } else {
                if (
                    this.dice_point[this.KoiENaRed].x * GlobalVariables.scale <= point.x &&
                    this.dice_point[this.KoiENaRed].y * GlobalVariables.scale <= point.y &&
                    this.dice_point[this.KoiENaRed].x * GlobalVariables.scale + GlobalVariables.diceWidth * GlobalVariables.scale >= point.x &&
                    this.dice_point[this.KoiENaRed].y * GlobalVariables.scale + GlobalVariables.diceHeight * GlobalVariables.scale >= point.y
                ) {
                    this.cursor = GlobalConstants.CURSOR_HAND;
                    this.setState({});
                    return;
                }
            }
        }
        if (this.cursor !== GlobalConstants.CURSOR_ARROW) {
            if (Platform.OS === 'web') {
                if (this.cursor === GlobalConstants.CURSOR_HAND) {
                    this.loadPionki();
                }
            }
            this.cursor = GlobalConstants.CURSOR_ARROW;
            this.setState({});
        }
    }

    async InitSP() {
        this.SP = true;
        this.OP = false;
        await this.Init();
        this.InitGame();
        this.loading = false;
    }

    async InitOP(Server: boolean) {
        this.Server = Server;
        this.OP = true;
        this.SP = false;
        await this.Init();
        this.InitGame();
        this.InitTimer(15);
        this.loading = false;
    }

    InitGame() {
        if (this.KoiZapochva < 4) {
            this.KoiZapochva = this.KoiZapochva + 1;
        } else {
            this.KoiZapochva = 1;
        }
        if ((this.OP && this.Server) || this.SP) {
            this.Faza = 1;
            this.KoiENaRed = this.KoiZapochva;
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 1; a < 5; a++) {
                this.ppoint[i][a].x = this.pplaceb[i][a].x;
                this.ppoint[i][a].y = this.pplaceb[i][a].y;
            }
        }
        this.Old.x = -1;
        this.Old.y = -1;
        for (let t = 1; t < 5; t++) {
            for (let i = 1; i < 10; i++) {
                this.FinPlace[t][i] = 0;
            }
        }
        for (let t = 1; t < 5; t++) {
            for (let i = 1; i < 5; i++) {
                this.PPos[t][i] = t * 10 + i + 200;
            }
        }
        this.dice1 = 6;
        this.mdice1 = 0;
        this.zar = false;
        if (this.OP) {
            if (!this.watch) {
                this.SendGame('$|y');
            }
            this.op_start = false;
        }
        for (let i = 1; i <= 4; i++) {
            if (this.last_Name[i] === '') {
                this.last_Name[i] = this.Name[i];
            }
        }
        this.loadPionki();
        this.setState({});
    }

    SendGame(msg: string) {
        this.props.m_pwin.SendGame(msg);
    }

    async Init() {
        this.op_start = false;
        this.Old.x = -1;
        this.Old.y = -1;
        for (let t = 1; t < 5; t++) {
            this.PosNaddavane[t] = new CPoint(0, 0);
            this.FinPlace[t] = [];
            for (let i = 1; i < 10; i++) {
                this.FinPlace[t][i] = 0;
            }
        }

        this.xdiff = 5;
        this.ydiff = 4;
        for (let t = 0; t < 56; t++) {
            this.pplace[t] = new CPoint(0, 0);
        }

        for (let t = 0; t < 6; t++) {
            this.pplace[t].x = 614 - 29 * t;
            this.pplace[t].y = 301;
        }
        this.pplace[6].x = 469;
        this.pplace[6].y = 335;
        for (let t = 0; t < 6; t++) {
            this.pplace[7 + t].x = 436;
            this.pplace[t + 7].y = 335 + 29 * t;
        }
        this.pplace[13].x = 394;
        this.pplace[13].y = 480;
        for (let t = 0; t < 13; t++) {
            this.pplace[26 - t].x = 789 - this.pplace[t].x;
            this.pplace[26 - t].y = this.pplace[t].y;
        }
        for (let t = 0; t < 7; t++) {
            this.pplace[26 - t].x -= 1;
        }
        for (let t = 0; t < 6; t++) {
            this.pplace[26 - t].y += 1;
        }
        this.pplace[27].x = 174;
        this.pplace[27].y = 260;
        for (let t = 0; t < 27; t++) {
            this.pplace[54 - t].x = this.pplace[t].x;
            this.pplace[54 - t].y = 520 - this.pplace[t].y;
        }
        for (let t = 0; t < 6; t++) {
            this.pplace[33 - t].y += 1;
            this.pplace[40 - t].x -= 1;
            this.pplace[47 - t].x -= 1;
        }
        this.pplace[55].x = 614;
        this.pplace[55].y = 260;
        for (let i = 1; i < 6; i++) {
            this.pplacef[i] = [];
            for (let a = 0; a < 10; a++) {
                this.pplacef[i][a] = new CPoint(0, 0);
            }
        }
        for (let t = 1; t < 6; t++) {
            this.pplacef[1][t].x = 614 - 29 * t;
            this.pplacef[1][t].y = 260;
        }
        this.pplacef[1][6].x = 439;
        this.pplacef[1][6].y = 260;
        this.pplacef[1][7].x = 414;
        this.pplacef[1][7].y = 260;
        this.pplacef[1][8].x = 439;
        this.pplacef[1][8].y = 235;
        this.pplacef[1][9].x = 439;
        this.pplacef[1][9].y = 285;

        for (let t = 1; t < 10; t++) {
            this.pplacef[3][t].x = 788 - this.pplacef[1][t].x;
            this.pplacef[3][t].y = this.pplacef[1][t].y;
        }
        for (let t = 1; t < 10; t++) {
            this.pplacef[2][t].x = this.pplacef[1][t].y + 134;
            this.pplacef[2][t].y = this.pplacef[1][t].x - 134;
        }
        for (let t = 1; t < 10; t++) {
            this.pplacef[4][t].x = this.pplacef[2][t].x;
            this.pplacef[4][t].y = 520 - this.pplacef[2][t].y;
        }
        ////////////////////////////////////////////do tuk
        this.Players = 4;
        this.pause = 0;
        this.pause_player = '';
        for (let i = 0; i < 5; i++) {
            this.pplaceb[i] = [];
            for (let a = 1; a < 5; a++) {
                this.pplaceb[i][a] = new CPoint(0, 0);
            }
        }

        this.pplaceb[1][1].x = 588;
        this.pplaceb[1][1].y = 432;
        this.pplaceb[1][2].x = 550;
        this.pplaceb[1][2].y = 432;
        this.pplaceb[1][3].x = 550;
        this.pplaceb[1][3].y = 470;
        this.pplaceb[1][4].x = 588;
        this.pplaceb[1][4].y = 470;

        this.pplaceb[2][1].x = 238;
        this.pplaceb[2][1].y = 432;
        this.pplaceb[2][2].x = 200;
        this.pplaceb[2][2].y = 432;
        this.pplaceb[2][3].x = 200;
        this.pplaceb[2][3].y = 470;
        this.pplaceb[2][4].x = 238;
        this.pplaceb[2][4].y = 470;

        this.pplaceb[3][1].x = 238;
        this.pplaceb[3][1].y = 48;
        this.pplaceb[3][2].x = 200;
        this.pplaceb[3][2].y = 48;
        this.pplaceb[3][3].x = 200;
        this.pplaceb[3][3].y = 86;
        this.pplaceb[3][4].x = 238;
        this.pplaceb[3][4].y = 86;

        this.pplaceb[4][1].x = 588;
        this.pplaceb[4][1].y = 48;
        this.pplaceb[4][2].x = 550;
        this.pplaceb[4][2].y = 48;
        this.pplaceb[4][3].x = 550;
        this.pplaceb[4][3].y = 86;
        this.pplaceb[4][4].x = 588;
        this.pplaceb[4][4].y = 86;

        let otmx = -15;
        let otmy = -15;

        for (let i = 0; i < 56; i++) {
            this.pplace[i].x = this.pplace[i].x + otmx;
            this.pplace[i].y = this.pplace[i].y + otmy;
        }
        for (let i = 1; i < 5; i++) {
            for (let a = 1; a < 5; a++) {
                this.pplaceb[i][a].x = this.pplaceb[i][a].x + otmx;
                this.pplaceb[i][a].y = this.pplaceb[i][a].y + otmy;
            }
            for (let a = 1; a < 10; a++) {
                this.pplacef[i][a].x = this.pplacef[i][a].x + otmx;
                this.pplacef[i][a].y = this.pplacef[i][a].y + otmy;
            }
        }

        for (let i = 1; i < 5; i++) {
            this.ppoint[i] = [];
            for (let a = 1; a < 5; a++) {
                this.ppoint[i][a] = new CPoint(0, 0);
                this.ppoint[i][a].x = this.pplaceb[i][a].x;
                this.ppoint[i][a].y = this.pplaceb[i][a].y;
            }
        }
        for (let i = 1; i < 5; i++) {
            this.dice_point[i] = new CPoint(0, 0);
            this.NamePos[i] = new CPoint(0, 0);
        }
        this.dice_point[1].x = 478;
        this.dice_point[1].y = 378;
        this.dice_point[2].x = 278;
        this.dice_point[2].y = 378;
        this.dice_point[3].x = 278;
        this.dice_point[3].y = 98;
        this.dice_point[4].x = 478;
        this.dice_point[4].y = 98;
        this.dice1 = 6;
        if (!this.OP) {
            this.Name[1] = await this.GetProfileString('Options', 'Name[1]', this.GetPlayer1Name());
            this.Name[2] = await this.GetProfileString('Options', 'Name[2]', 'Computer2');
            this.Name[3] = await this.GetProfileString('Options', 'Name[3]', 'Computer3');
            this.Name[4] = await this.GetProfileString('Options', 'Name[4]', 'Computer4');
        }
        this.NamePos[1].x = 650;
        this.NamePos[1].y = 462;
        this.NamePos[2].x = 50;
        this.NamePos[2].y = 462;
        this.NamePos[3].x = 50;
        this.NamePos[3].y = 85;
        this.NamePos[4].x = 650;
        this.NamePos[4].y = 85;

        this.PosNaddavane[1].x = 645;
        this.PosNaddavane[1].y = 455;
        this.PosNaddavane[2].x = 50;
        this.PosNaddavane[2].y = 455;
        this.PosNaddavane[3].x = 50;
        this.PosNaddavane[3].y = 75;
        this.PosNaddavane[4].x = 645;
        this.PosNaddavane[4].y = 75;

        if ((this.OP && this.Server) || this.SP) {
            this.KoiZapochva = this.KoiENaRed = Math.floor(Math.random() * 4) + 1;
        }

        await this.LoadPictures(1);
        if (this.P2OP === true) {
            this.P2G = false;
        } else {
            this.P2G = true;
        }
        if (this.P3OP === true) {
            this.P3G = false;
        } else {
            this.P3G = true;
        }
        if (this.P4OP === true) {
            this.P4G = false;
        } else {
            this.P4G = true;
        }
        this.pause_rect = new CRect(650, 484, 674, 498);
    }

    SendNames() {
        if (this.P2OP) {
            let message = '$|a|' + this.Name[3];
            this.SendGame(message);
            message = '$|a|' + this.Name[4];
            this.SendGame(message);
            message = '$|a|' + this.Name[1];
            this.SendGame(message);
        }
        if (this.P3OP) {
            let message = '$|d|' + this.Name[4];
            this.SendGame(message);
            message = '$|d|' + this.Name[1];
            this.SendGame(message);
            message = '$|d|' + this.Name[2];
            this.SendGame(message);
        }
        if (this.P4OP) {
            let message = '$|g|' + this.Name[1];
            this.SendGame(message);
            message = '$|g|' + this.Name[2];
            this.SendGame(message);
            message = '$|g|' + this.Name[3];
            this.SendGame(message);
        }
        if (this.HaveWatch) {
            let message = '$|t|1|' + this.Name[2] + '|' + this.Name[3] + '|' + this.Name[4];
            this.SendGame(message);
        }
    }

    DrawDice() {
        if (this.KoiENaRed >= 1) {
            return (
                <DrawDice
                    dc={this.diceColor}
                    dice1={this.dice1}
                    mdice1={this.mdice1}
                    m_x={this.dice_point[this.KoiENaRed].x}
                    m_y={this.dice_point[this.KoiENaRed].y}
                    cursor={this.cursor}
                />
            );
        } else {
            null;
        }
    }

    DrawPionki() {
        if (this.KoiENaRed >= 1) {
            return <View key={'DrawPionki'}>{this.pionki}</View>;
        } else {
            return null;
        }
    }

    DrawViewElements() {
        return (
            <View key={'DrawViewElements'}>
                <CPicture
                    image={'nssc/board' + this.color_index + '.gif'}
                    m_x={150}
                    m_y={16}
                    m_Width={488}
                    m_Height={488}
                    cursor={GlobalConstants.CURSOR_ARROW}
                    key={'boardweb'}
                />
                <DrawNames name={this.Name} position={this.NamePos} cursor={this.cursor} key={'names'} />
                {this.DrawTimer()}
                {this.DrawPause()}
                {this.DrawRating()}
                {this.DrawDice()}
                {this.DrawPionki()}
                {this.DrawUsers(this.props.m_pwin.game)}
                {this.DrawChatMessages()}
            </View>
        );
    }

    DrawView() {
        let res = [];
        if (Platform.OS === 'web') {
            res.push(
                <View key={'webviewNSSC'}>
                    <div key={'mousemove'} onMouseMove={this.OnMouseMove.bind(this)}>
                        {this.DrawViewElements()}
                    </div>
                </View>
            );
        } else {
            res.push(<View key={'mobileviewNSSC'}>{this.DrawViewElements()}</View>);
        }
        return res;
    }

    render() {
        if (this.props.m_pwin.game === 'NSSC' && !this.loading && !this.loadingMain) {
            return <View key={'NSSC'}>{this.DrawView()}</View>;
        } else {
            return null;
        }
    }
}

export default CNSSC;
