import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class DlgRezultatBlato extends Component<ParentDlgProps> {
    start: number;
    m_rez1: number = 0;
    m_rez2: number = 0;
    m_rez3: number = 0;
    name: string[] = [];
    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.OnOk();
    }

    OnOk() {
        this.start = 0;
    }

    OnInitDialog() {}

    async DoModal() {
        this.props.m_pwin.state.DlgRezultatBlatoVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgRezultatBlatoVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgRezultatBlatoVisible: false });
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.state.DlgRezultatBlatoVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 100 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 245 * GlobalVariables.scale,
                                width: 300 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.name[1]}</Text>
                            </View>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_rez1.toString()}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.name[2]}</Text>
                            </View>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_rez2.toString()}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.name[3]}</Text>
                            </View>
                            <View style={modal.buttonStyle2PerRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_rez3.toString()}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <Button
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle2PerRow}
                                onPress={this.OnOk.bind(this)}
                                text="OK"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgRezultatBlato;
