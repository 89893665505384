import React, { Component } from 'react';
import { View, Image, TouchableOpacity, Pressable, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { styles } from '../../css/container';
import LanguagePicker from './core/LanguagePicker';
import { LanguagePickerProps } from './props/LanguagePickerProps';
import { MaterialCommunityIcons, Foundation, Ionicons, FontAwesome5, AntDesign } from '@expo/vector-icons';
import GlobalVariables from './GlobalVariables';
import CPicture from './cppComponents/CPicture';
import t from '../../translation';
import GlobalConstants from './GlobalConstants';
import Tooltip from './core/Tooltip';
import ScrollbarWrapperVertical from './core/ScrollbarWrapperVertical';

class NavigationDrawerHeader extends Component<LanguagePickerProps> {
    lp: LanguagePicker;
    OFF_COLOR: string = '#333333';
    CARDS_COLOR: string = '#882222';
    grab: number;
    face: string;
    back: string;
    constructor(props) {
        super(props);
    }

    async toggleDrawer() {
        if (this.props.m_pwin.haveOpenDialog()) {
            this.props.m_pwin.closeAllDialogs();
            return;
        }
        if (this.props.m_pwin.IsGameOnline() && this.props.m_pwin.game !== '' && !this.props.m_pwin.m_main.watch) {
            this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle('Are you sure you want to leave the game?');
            if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDNO) {
                return;
            }
        }
        this.props.m_pwin.OnNewGame();
        this.props.m_pwin.showAd();
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadCardsSettings();
    }

    async loadCardsSettings() {
        this.grab = await this.props.m_pwin.m_main.GetProfileInt('Options', 'grabnew' + GlobalVariables.folder, 1);
        this.back = await this.props.m_pwin.m_main.GetProfileString('Options', 'backnew' + GlobalVariables.folder, 'back_1');
        this.face = await this.props.m_pwin.m_main.GetProfileString('Options', 'facenew', 'cards_1');
        this.setState({});
    }

    async loadFinished() {}

    async ChangeZvuk(sound: number) {
        GlobalVariables.Zvuk = await this.props.m_pwin.m_main.WriteProfileInt('Options', 'Sound', sound);
        this.setState({});
    }

    showHideChat() {
        this.props.m_pwin.state.CChatBarVisible = !this.props.m_pwin.state.CChatBarVisible;
        this.props.m_pwin.CalculateScale();
        if (this.props.m_pwin.game === 'NSSC') {
            this.props.m_pwin.m_nssc.loadPionki();
        }
        this.props.m_pwin.setState({});
    }

    showHideInfo() {
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'bidd' + this.props.m_pwin.game, !this.props.m_pwin.state.CInfoBarVisible ? 1 : 0);
        this.props.m_pwin.pFrame.m_info_bar.ShowWindow(!this.props.m_pwin.state.CInfoBarVisible);
        this.props.m_pwin.CalculateScale();
        this.props.m_pwin.setState({});
    }

    OnOtkritiKarti() {
        GlobalVariables.OtkritiKarti = GlobalVariables.OtkritiKarti === 1 ? 0 : 1;
        this.props.m_pwin.setState({});
    }

    render() {
        return (
            <View style={styles.navigationHeaderSafeAreaView}>
                <SafeAreaView>
                    <View style={styles.navigationHeaderBackgroundColor}>
                        <Tooltip content={t.t('New Game')} delay={1000}>
                            <TouchableOpacity onPress={() => this.toggleDrawer()}>
                                <Image source={require('../../assets/Images/drawerWhite.png')} style={styles.navigationHeaderImage} />
                            </TouchableOpacity>
                        </Tooltip>
                        <ScrollbarWrapperVertical
                            style={{
                                height: Dimensions.get('window').height - GlobalVariables.positionLeft /* TODO Get top and bottom insets */,
                            }}
                        >
                            <View style={styles.row}>
                                <Tooltip content={t.t('Web Site')} delay={1000}>
                                    <Pressable
                                        style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                        onPress={() =>
                                            this.props.m_pwin.openPage(this.props.m_pwin.m_main.GetLanguageText('index'), 'Favorite-Games.com')
                                        }
                                    >
                                        <Image source={require('../../assets/Images/favicon.png')} style={styles.websiteIcon} />
                                    </Pressable>
                                </Tooltip>
                            </View>
                            <Tooltip content={t.t('Language')} delay={1000}>
                                <View style={styles.row}>
                                    <LanguagePicker big={false} onRef={(ref: LanguagePicker) => (this.lp = ref)} m_pwin={this.props.m_pwin} />
                                </View>
                            </Tooltip>
                            <View style={styles.row}>
                                <Tooltip content={t.t('Sound')} delay={1000}>
                                    <Pressable
                                        style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                        onPress={() => this.ChangeZvuk(GlobalVariables.Zvuk === 1 ? 0 : 1)}
                                    >
                                        <MaterialCommunityIcons
                                            name={GlobalVariables.Zvuk === 1 ? 'volume-high' : 'volume-mute'}
                                            color={GlobalVariables.Zvuk === 1 ? GlobalConstants.ON_COLOR : this.OFF_COLOR}
                                            size={48}
                                        />
                                    </Pressable>
                                </Tooltip>
                            </View>
                            {this.props.m_pwin.IsCardApp() ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('Cards')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => this.props.m_pwin.OnCards()}
                                        >
                                            <MaterialCommunityIcons name="cards-playing-outline" color={GlobalConstants.ON_COLOR} size={48} />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.IsGameOnline() ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('You can allow or deny the entry of watchers into your game.')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => (this.props.m_pwin.IsGameOnlineServer() ? this.props.m_pwin.enableDisableWatch() : null)}
                                        >
                                            <MaterialCommunityIcons
                                                name={this.props.m_pwin.m_main.enable_watch_game ? 'eye' : 'eye-off'}
                                                color={this.props.m_pwin.IsGameOnlineServer() ? GlobalConstants.ON_COLOR : this.OFF_COLOR}
                                                size={48}
                                            />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.IsGameOnline() ? (
                                <View style={styles.row}>
                                    <Tooltip
                                        content={t.t('You can set a password for your game. Only users who know it can enter your game.')}
                                        delay={1000}
                                    >
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => (this.props.m_pwin.IsGameOnlineServer() ? this.props.m_pwin.addRemovePassword() : null)}
                                        >
                                            <MaterialCommunityIcons
                                                name={
                                                    this.props.m_pwin.close_game_m_filename.indexOf('close.gif') >= 0 ||
                                                    (this.props.m_pwin.close_game_m_filename === '' &&
                                                        this.props.m_pwin.m_dbo.m_table_password !== '')
                                                        ? 'lock-outline'
                                                        : 'lock-open-variant-outline'
                                                }
                                                color={this.props.m_pwin.IsGameOnlineServer() ? GlobalConstants.ON_COLOR : this.OFF_COLOR}
                                                size={48}
                                            />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.IsGameOnline() ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('Chat')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => this.showHideChat()}
                                        >
                                            <MaterialCommunityIcons
                                                name="chat"
                                                color={this.props.m_pwin.state.CChatBarVisible ? GlobalConstants.ON_COLOR : this.OFF_COLOR}
                                                size={48}
                                            />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.game === 'Belot4' ||
                            this.props.m_pwin.game === 'Belot3' ||
                            this.props.m_pwin.game === 'Belot2' ||
                            this.props.m_pwin.game === 'Blato' ||
                            this.props.m_pwin.game === 'Bridge' ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('Bidding')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => this.showHideInfo()}
                                        >
                                            <MaterialCommunityIcons
                                                name="history"
                                                color={this.props.m_pwin.state.CInfoBarVisible ? GlobalConstants.ON_COLOR : this.OFF_COLOR}
                                                size={48}
                                            />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.game !== '' && this.props.m_pwin.game !== 'NSSC' ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('Scoring')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => this.props.m_pwin.OnRezult()}
                                        >
                                            <Foundation name="results" color={GlobalConstants.ON_COLOR} size={50} />
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                            {this.props.m_pwin.IsGameOnline() ? (
                                <>
                                    <View style={styles.row}>
                                        <Tooltip content={t.t('Friends list')} delay={1000}>
                                            <Pressable
                                                style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                onPress={() => this.props.m_pwin.OnOnlinegameFriendslist()}
                                            >
                                                <FontAwesome5 name="user-friends" color={GlobalConstants.ON_COLOR} size={38} />
                                            </Pressable>
                                        </Tooltip>
                                    </View>
                                    <View style={styles.row}>
                                        <Tooltip content={t.t('Ignore Players')} delay={1000}>
                                            <Pressable
                                                style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                onPress={() => this.props.m_pwin.OnOnlinegameIgnorelist(this.props.m_pwin.pf)}
                                            >
                                                <FontAwesome5 name="user-slash" color={GlobalConstants.ON_COLOR} size={38} />
                                            </Pressable>
                                        </Tooltip>
                                    </View>
                                </>
                            ) : null}
                            <View style={styles.row}>
                                <Tooltip content={t.t('Statistics')} delay={1000}>
                                    <Pressable
                                        style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                        onPress={() => this.props.m_pwin.OpenStatisticDialog()}
                                    >
                                        <Ionicons name={'stats-chart'} color={GlobalConstants.ON_COLOR} size={48} />
                                    </Pressable>
                                </Tooltip>
                            </View>
                            <View style={styles.row}>
                                <Tooltip content={t.t('Help')} delay={1000}>
                                    <Pressable
                                        style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                        onPress={() =>
                                            this.props.m_pwin.openPage(
                                                this.props.m_pwin.m_main.GetLanguageText('online_game_help'),
                                                'Favorite-Games.com'
                                            )
                                        }
                                    >
                                        <MaterialCommunityIcons name={'help-circle'} color={GlobalConstants.ON_COLOR} size={48} />
                                    </Pressable>
                                </Tooltip>
                            </View>
                            <View style={styles.row}>
                                <Tooltip content={t.t('Options')} delay={1000}>
                                    <Pressable
                                        style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                        onPress={() => this.props.m_pwin.OpenSettingsDIalog()}
                                    >
                                        <AntDesign name={'setting'} color={GlobalConstants.ON_COLOR} size={48} />
                                    </Pressable>
                                </Tooltip>
                            </View>
                            {this.back &&
                            this.face &&
                            this.grab &&
                            (!this.props.m_pwin.IsGameOnline() || this.props.m_pwin.m_main.getIsTest()) &&
                            this.props.m_pwin.IsCardGame() ? (
                                <View style={styles.row}>
                                    <Tooltip content={t.t('Open Cards')} delay={1000}>
                                        <Pressable
                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                            onPress={() => this.OnOtkritiKarti()}
                                        >
                                            <View>
                                                <CPicture
                                                    image={
                                                        GlobalVariables.OtkritiKarti === 0
                                                            ? 'cards/' + this.back + '/' + this.grab + '.gif'
                                                            : 'cards/' + this.face + '/212.gif'
                                                    }
                                                    folder="files_1.0"
                                                    relative={true}
                                                    m_x={0}
                                                    m_y={0}
                                                    m_Height={67}
                                                    m_Width={48}
                                                    noScale={true}
                                                />
                                            </View>
                                        </Pressable>
                                    </Tooltip>
                                </View>
                            ) : null}
                        </ScrollbarWrapperVertical>
                    </View>
                </SafeAreaView>
            </View>
        );
    }
}
export default NavigationDrawerHeader;
