import React, { Component } from 'react';
import { Dimensions, Modal, View } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { WebView } from 'react-native-web-webview';
import { DlgWebViewProps } from '~/Components/props/DlgWebViewProps';

class DlgWebView extends Component<DlgWebViewProps> {
    sms_vip: string = '';
    sms_chips: string = '';
    url: string;
    title: string;
    closeButtonTitle: string;
    constructor(props: DlgWebViewProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.OnOk();
    }

    OnOk() {
        this.props.m_pwin.setState({ DlgWebViewVisible: false });
    }

    async DoModal() {
        this.props.m_pwin.state.DlgWebViewVisible = true;
        this.props.m_pwin.setState({});
        while (this.props.m_pwin.state.DlgWebViewVisible) {
            await this.performTimeConsumingTask();
        }
    }

    render() {
        if (this.url && this.title) {
            if (this.props.m_pwin.state.DlgWebViewVisible) {
                let ctime: number = new Date().getDate();
                return (
                    <Modal
                        animationType="none"
                        transparent={true}
                        supportedOrientations={['landscape']}
                        visible={this.props.m_pwin.state.DlgWebViewVisible}
                        statusBarTranslucent={true}
                        onRequestClose={() => {
                            this.OnExit.bind(this);
                        }}
                    >
                        <View
                            style={[
                                dialog.dialogView,
                                styles.W100,
                                styles.h100,
                                {
                                    top: 0 * GlobalVariables.scale,
                                    left: 0 * GlobalVariables.scale,
                                },
                            ]}
                        >
                            <View style={styles.doalogTitle}>
                                <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>{this.title}</Text>
                            </View>
                            <WebView
                                style={{ height: Dimensions.get('window').height - 86, width: Dimensions.get('window').width }}
                                originWhitelist={['*']}
                                source={{
                                    uri: this.url + (this.url.indexOf('?') > 0 ? '&' : '?') + 'time=' + this.props.m_pwin.m_main.IntToString(ctime),
                                }}
                                onError={(e) => console.log(e)}
                                onLoad={() => console.log('OnLoad')}
                            />
                            <View style={styles.row}>
                                <Button
                                    containerStyle={modal.buttonStyle3PerRow}
                                    textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE}
                                    onPress={this.OnOk.bind(this)}
                                    text={this.closeButtonTitle ? this.closeButtonTitle : 'Close'}
                                />
                            </View>
                        </View>
                    </Modal>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export default DlgWebView;
