/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { Dimensions, Platform, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { styles } from '../../../css/container';
import { dialog } from '../../../css/dialog';
import GlobalVariables from '../../Components/GlobalVariables';
import { modal } from '../../../css/modal';
import Text from '../../Components/core/Text';
import GlobalConstants from '../../Components/GlobalConstants';
import Button from './Button';
import { BaseDlgProps } from '../props/BaseDlgProps';
import CPicture from '../cppComponents/CPicture';

class LanguagePickerDlg extends Component<BaseDlgProps> {
    languages: string[] = [
        'Arabic',
        'Bulgarian',
        'Croatian',
        'Czech',
        'Danish',
        'Dutch',
        'English',
        'French',
        'German',
        'Greek',
        'Hungarian',
        'Italian',
        'Macedonian',
        'Polish',
        'Portuguese',
        'Romanian',
        'Russian',
        'Serbian',
        'Slovenian',
        'Spanish',
        'Swedish',
        'Turkish',
        'Ukrainian',
    ];

    constructor(props: BaseDlgProps) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    OnOK() {
        this.props.m_pwin.setState({ LanguagePickerDlgVisible: false });
    }

    render() {
        if (this.props.m_pwin.state.LanguagePickerDlgVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            styles.h100,
                            {
                                top: 0 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + (790 * GlobalVariables.scale - 400) / 2,
                                width: 400,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text noTranslate={true} textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE}>
                                Select Language
                            </Text>
                        </View>
                        <ScrollView
                            style={[
                                {
                                    height: Dimensions.get('window').height * 0.6,
                                    width: 350,
                                },
                            ]}
                        >
                            {this.languages.map((language: string, index) => (
                                <Pressable
                                    key={index}
                                    style={[styles.row, { height: 56 }]}
                                    onPress={async () => {
                                        await this.props.m_pwin.changeLanguage(language);
                                        this.OnOK();
                                    }}
                                >
                                    <View style={{ width: 180, justifyContent: 'flex-end' }}>
                                        <Text noTranslate={true} scaleText={false} textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE}>
                                            {language}
                                        </Text>
                                    </View>
                                    <View style={[cstyle.flagContainer, { width: 78, height: 54 }]}>
                                        <CPicture
                                            image={'languages2/' + language + '.png'}
                                            m_Width={72}
                                            m_Height={48}
                                            m_y={Platform.OS === 'web' ? 0 : 3}
                                            m_x={0}
                                            noScale={true}
                                            folder={'files_1.0'}
                                            cursor={GlobalConstants.CURSOR_HAND}
                                            relative={true}
                                        />
                                    </View>
                                </Pressable>
                            ))}
                        </ScrollView>
                        <View style={styles.row}>
                            <Button
                                containerStyle={modal.buttonStyle1PerRow}
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                onPress={() => this.OnOK()}
                                text="Close"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default LanguagePickerDlg;

const cstyle = StyleSheet.create({
    flagContainer: {
        backgroundColor: 'gray',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
