import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../../css/container';
import { dialog } from '../../../css/dialog';
import { modal } from '../../../css/modal';
import Button from '../core/Button';
import Text from '../core/Text';
import GlobalConstants from '../GlobalConstants';
import GlobalVariables from '../GlobalVariables';
import { BaseDlgProps } from '../props/BaseDlgProps';
import t from '../../../translation';

class COK extends Component<BaseDlgProps> {
    start: number;
    index: number;
    title: string;
    time: number = 30;
    constructor(props: BaseDlgProps) {
        super(props);

        setInterval(() => {
            if (this.props.m_pwin.messageOKVisible[this.index]) {
                if (this.props.m_pwin.IsGameOnline()) {
                    this.time = this.time - 1;
                }
                this.setState({});
                if (this.time <= 0) {
                    this.props.m_pwin.messageOKVisible[this.index] = false;
                    this.start = 0;
                }
            }
        }, 1000);
    }

    SetTitle(title: string) {
        this.title = t.t(title);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }
    OnOk() {
        this.start = 0;
    }
    OnCancel() {
        this.start = 0;
    }

    initTimer() {
        this.time = this.props.m_pwin.m_main.GetCloseDialogTimer();
        this.setState({});
    }

    async DoModal() {
        this.index = this.props.m_pwin.messageokIndex;
        this.props.m_pwin.messageokIndex = this.props.m_pwin.messageokIndex + 1;
        this.props.m_pwin.messageOKVisible[this.index] = true;
        this.props.m_pwin.setState({});
        this.start = 1;
        this.initTimer();
        while (this.start === 1 && this.time > 0 && this.props.m_pwin.messageOKVisible[this.index] && this.props.m_pwin.props.isAppActive) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.messageOKVisible[this.index] = false;
        this.props.m_pwin.setState({});
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.messageOKVisible[this.index]) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top:
                                    GlobalVariables.WindowHeight * 0.4 -
                                    Math.min(this.index * 80, GlobalVariables.WindowHeight * 0.4 - 20 * GlobalVariables.scale),
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{'Favorite Games ' + GlobalConstants.GAME_VERSION}</Text>
                        </View>
                        <View style={[styles.row, styles.padding1]}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.title}</Text>
                        </View>
                        <View style={styles.row}>
                            <Button
                                textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE}
                                containerStyle={modal.buttonStyle3PerRow}
                                onPress={this.OnCancel.bind(this)}
                                text="OK"
                            />
                        </View>
                        {this.props.m_pwin.IsGameOnline() ? (
                            <View style={styles.row}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.time.toString()}</Text>
                            </View>
                        ) : null}
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default COK;
