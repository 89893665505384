import { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import React from 'react';
import CPicture from '../cppComponents/CPicture';
import CPoint from '../cppComponents/CPoint';
import Text from '../core/Text';
import { styles } from '../../../css/container';
import GlobalVariables from '../GlobalVariables';
import GlobalConstants from '../GlobalConstants';

type Props = {
    Naddavane: number;
    kojKazal: number;
    Kontra?: number;
    Rekontra?: number;
    positionBid: CPoint;
    positionKontra?: CPoint;
    positionRekontra?: CPoint;
    level?: number;
    game?: string;
};

class DrawBid extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={cstyles.bidContainer}>
                {this.props.Naddavane > 0 && this.props.kojKazal > 0 ? (
                    <>
                        <CPicture
                            image={
                                this.props.game !== 'Bridge' ? 'bid/' + this.props.Naddavane + '-n.png' : 'bid/' + this.props.Naddavane + '-bn.png'
                            }
                            m_x={
                                this.props.positionBid.x +
                                (this.props.game !== 'Bridge' && this.props.game !== 'Blato' && this.props.kojKazal === 3 ? 57 - 20 : 0) +
                                (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? 57 - 32 : 0) +
                                (this.props.game === 'Belote' && this.props.kojKazal === 1
                                    ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 7) / 8
                                    : this.props.game === 'Bridge' && this.props.kojKazal === 1
                                    ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 12) / 13
                                    : 0) +
                                (this.props.game === 'Bridge' && this.props.kojKazal === 3 && (this.props.Kontra > 0 || this.props.Rekontra > 0)
                                    ? -20
                                    : 0)
                            }
                            m_y={
                                this.props.positionBid.y +
                                (this.props.game === 'Bridge' && this.props.kojKazal === 2 ? -60 : 0) +
                                (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -60 : 0) +
                                (this.props.game !== 'Bridge' && this.props.kojKazal === 4 ? 0 : 80 - 20)
                            }
                            folder="files_2.0"
                            m_Width={this.props.game === 'Bridge' ? 32 : 20}
                            m_Height={20}
                        />
                        <View
                            style={[
                                styles.containerAbsolute,
                                styles.center,
                                {
                                    top:
                                        (this.props.positionBid.y +
                                            (this.props.game === 'Bridge' && this.props.kojKazal === 2 ? -60 : 0) +
                                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -60 : 0) +
                                            (this.props.game !== 'Bridge' && this.props.kojKazal === 4 ? 0 : 80 - 20)) *
                                        GlobalVariables.scale,
                                    left:
                                        (this.props.positionBid.x +
                                            (this.props.game !== 'Bridge' && this.props.kojKazal === 3 ? 57 - 20 : 0) +
                                            (this.props.game === 'Bridge' &&
                                            this.props.kojKazal === 3 &&
                                            (this.props.Kontra > 0 || this.props.Rekontra > 0)
                                                ? -20
                                                : 0) +
                                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? 57 - 32 : 0)) *
                                            GlobalVariables.scale +
                                        (this.props.game === 'Belote' && this.props.kojKazal === 1
                                            ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 7) / 8
                                            : this.props.game === 'Bridge' && this.props.kojKazal === 1
                                            ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 12) / 13
                                            : 0) *
                                            GlobalVariables.scale,
                                    width: 12 * GlobalVariables.scale,
                                    height: 20 * GlobalVariables.scale,
                                },
                            ]}
                        >
                            <Text
                                color={this.props.Naddavane === 1 || this.props.Naddavane === 4 || this.props.Naddavane === 5 ? 'black' : 'red'}
                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                style={styles.boldFont}
                            >
                                {this.props.level ? this.props.level.toString() : null}
                            </Text>
                        </View>
                    </>
                ) : null}
                {this.props.Kontra > 0 ? (
                    <CPicture
                        image={
                            this.props.Naddavane === 1 || this.props.Naddavane === 4 || this.props.Naddavane === 5 ? 'bid/7-n1.png' : 'bid/7-n2.png'
                        }
                        m_x={
                            this.props.positionKontra.x +
                            (this.props.Kontra === 3 ? 57 - 20 : 0) +
                            (this.props.level ? 32 : 0) +
                            (this.props.Kontra === 3 && this.props.game === 'Bridge' ? -12 : 0) +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -20 : 0) +
                            (this.props.game !== 'Bridge' && this.props.Kontra === 1
                                ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 7) / 8
                                : this.props.game === 'Bridge' && this.props.Kontra === 1
                                ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 12) / 13
                                : 0)
                        }
                        m_y={
                            this.props.positionKontra.y +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 2 ? -60 : 0) +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -60 : 0) +
                            (this.props.game !== 'Bridge' && this.props.Kontra === 4 ? 0 : 80 - 20)
                        }
                        folder="files_2.0"
                        m_Width={20}
                        m_Height={20}
                    />
                ) : null}
                {this.props.Rekontra > 0 ? (
                    <CPicture
                        image={
                            this.props.Naddavane === 1 || this.props.Naddavane === 4 || this.props.Naddavane === 5 ? 'bid/8-n1.png' : 'bid/8-n2.png'
                        }
                        m_x={
                            this.props.positionRekontra.x +
                            (this.props.game !== 'Bridge' &&
                            this.props.positionBid.x === this.props.positionRekontra.x &&
                            this.props.positionBid.y === this.props.positionRekontra.y
                                ? 20
                                : 0) +
                            (this.props.Rekontra === 3 ? 57 - 20 : 0) +
                            (this.props.level ? 32 : 0) +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -20 : 0) +
                            (this.props.Rekontra === 3 && this.props.game === 'Bridge' ? -12 : 0) +
                            (this.props.game !== 'Bridge' && this.props.Rekontra === 1
                                ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 7) / 8
                                : this.props.game === 'Bridge' && this.props.Rekontra === 1
                                ? ((GlobalConstants.CARD_WIDTH - GlobalConstants.PLAYER_CARD_WIDTH) * 12) / 13
                                : 0)
                        }
                        m_y={
                            this.props.positionRekontra.y +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 2 ? -60 : 0) +
                            (this.props.game === 'Bridge' && this.props.kojKazal === 3 ? -60 : 0) +
                            (this.props.game !== 'Bridge' && this.props.Rekontra === 4 ? 0 : 80 - 20)
                        }
                        folder="files_2.0"
                        m_Width={20}
                        m_Height={20}
                    />
                ) : null}
            </View>
        );
    }
}

export default DrawBid;

export const cstyles = StyleSheet.create({
    bidContainer: {
        opacity: 0.78,
    },
});
