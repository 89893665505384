import { Component } from 'react';
import GlobalConstants from './GlobalConstants';
import { Audio } from 'expo-av';
import { Sound } from 'expo-av/build/Audio';
import GlobalVariables from './GlobalVariables';
import CCardGames from './CCardGames';
import { CCardGamesViewPointerProps } from './props/CCardGamesViewPointerProps';
import { Platform } from 'react-native';

class cSound extends Component<CCardGamesViewPointerProps> {
    sounds: Sound[] = [];

    m_main: CCardGames = new CCardGames(null);
    language: string;

    constructor(props: CCardGamesViewPointerProps) {
        super(props);
        this.loadSounds();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    PlayFile(fileName: string, sync: boolean) {
        if (GlobalVariables.Zvuk === 1) {
            if (this.props.m_pwin.props.isAppActive || Platform.OS === 'web') {
                if (this.sounds['Sound\\effects\\' + fileName]) {
                    this.sounds['Sound\\effects\\' + fileName].replayAsync().catch(() => {});
                }
            }
        }
    }

    async initLanguageSounds() {
        for (let i = 1; i <= 24; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
        for (let i = 31; i <= 37; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
        for (let i = 41; i <= 47; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
        for (let i = 51; i <= 57; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
        for (let i = 61; i <= 67; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
        for (let i = 71; i <= 77; i++) {
            this.sounds['Sound\\' + this.language + '\\' + i] = new Audio.Sound();
            this.sounds['Sound\\' + this.language + '\\' + (100 + i)] = new Audio.Sound();
        }
    }

    async initSounds() {
        console.log('initSounds');
        this.sounds['Sound\\0'] = new Audio.Sound();
        this.sounds['Sound\\dice'] = new Audio.Sound();
        this.sounds['Sound\\connect'] = new Audio.Sound();
        this.sounds['Sound\\no_time'] = new Audio.Sound();
        this.sounds['Sound\\moveok'] = new Audio.Sound();
        this.sounds['Sound\\showok'] = new Audio.Sound();
        this.sounds['Sound\\effects\\buyin'] = new Audio.Sound();
        this.sounds['Sound\\effects\\card'] = new Audio.Sound();
        this.sounds['Sound\\effects\\coins'] = new Audio.Sound();
        this.sounds['Sound\\effects\\deal'] = new Audio.Sound();
        this.sounds['Sound\\effects\\rand_cards'] = new Audio.Sound();
        this.sounds['Sound\\effects\\win_money'] = new Audio.Sound();
    }

    async loadLanguageSounds() {
        this.language = await this.m_main.GetSavedLanguage();
        console.log('loadLanguageSounds, this.language', this.language);
        if (this.language === 'Bulgarian' || this.language === 'Turkish') {
            await this.initLanguageSounds();
            for (let i = 1; i <= 24; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + i);
                    });
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
            for (let i = 31; i <= 37; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + i);
                    });
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
            for (let i = 41; i <= 47; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + i);
                    });
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
            for (let i = 51; i <= 57; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {});
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
            for (let i = 61; i <= 67; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + i);
                    });
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
            for (let i = 71; i <= 77; i++) {
                await this.sounds['Sound\\' + this.language + '\\' + i]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + i + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + i);
                    })
                    .catch(() => {});
                await this.sounds['Sound\\' + this.language + '\\' + (100 + i)]
                    .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '/' + this.language + '/' + (100 + i) + '.mp3' }, { shouldPlay: false })
                    .then(() => {
                        console.log('Load Language Sound File' + (100 + i));
                    })
                    .catch(() => {
                        console.log('Not Load Language Sound File' + (100 + i));
                    });
            }
        }
        console.log('Finish Loading Sounds');
    }

    async loadSounds() {
        console.log('Start Loading Sounds');
        await this.initSounds();
        await this.sounds['Sound\\0']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + '0.mp3' }, { shouldPlay: false })
            .then(() => {
                console.log('Load Sound File 0');
            })
            .catch(() => {
                console.log('Not Load Sound File 0');
            });
        await this.sounds['Sound\\dice'].loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'dice.mp3' }, { shouldPlay: false }).catch(() => {
            console.log('Load Sound File dice');
        });
        await this.sounds['Sound\\connect'].loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'connect.mp3' }, { shouldPlay: false }).catch(() => {
            console.log('Load Sound File connect');
        });
        await this.sounds['Sound\\no_time'].loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'no_time.mp3' }, { shouldPlay: false }).catch(() => {
            console.log('Load Sound File no_time');
        });
        await this.sounds['Sound\\moveok'].loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'moveok.mp3' }, { shouldPlay: false }).catch(() => {
            console.log('Load Sound File moveok');
        });
        await this.sounds['Sound\\showok'].loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'showok.mp3' }, { shouldPlay: false }).catch(() => {
            console.log('Load Sound File showok');
        });
        await this.sounds['Sound\\effects\\buyin']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/buyin.mp3' }, { shouldPlay: false })
            .then(() => {
                console.log('Load Sound File effects/buyin.mp3');
            })
            .catch(() => {
                console.log('Not Load Sound File effects/buyin.mp3');
            });
        await this.sounds['Sound\\effects\\card']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/card.mp3' }, { shouldPlay: false })
            .catch(() => {
                console.log('Not Load Sound File effects/card.mp3');
            });
        await this.sounds['Sound\\effects\\coins']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/coins.mp3' }, { shouldPlay: false })
            .catch(() => {
                console.log('Not Load Sound File effects/coins.mp3');
            });
        await this.sounds['Sound\\effects\\deal']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/deal.mp3' }, { shouldPlay: false })
            .catch(() => {
                console.log('Not Load Sound File effects/deal.mp3');
            });
        await this.sounds['Sound\\effects\\rand_cards']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/rand_cards.mp3' }, { shouldPlay: false })
            .catch(() => {
                console.log('Not Load Sound File effects/rand_cards.mp3');
            });
        await this.sounds['Sound\\effects\\win_money']
            .loadAsync({ uri: GlobalConstants.SOUND_FOLDER + 'effects/win_money.mp3' }, { shouldPlay: false })
            .catch(() => {
                console.log('Not Load Sound File effects/win_money.mp3');
            });
        await this.loadLanguageSounds();
    }

    Play(file: number, position: string, stereo: number, sex: number) {
        if (sex === 1) {
            file = file + 100;
        }

        let filepath = 'Sound\\' + this.language + '\\';
        filepath = filepath + this.m_main.IntToString(file);
        if (file === 0) {
            filepath = 'Sound\\0';
        }
        if (file === 1111) {
            filepath = 'Sound\\dice';
        }
        if (file === 1112) {
            filepath = 'Sound\\connect';
        }
        if (file === 1113) {
            filepath = 'Sound\\no_time';
        }
        if (file === 1200) {
            filepath = 'Sound\\moveok';
        }
        if (file === 1201) {
            filepath = 'Sound\\showok';
        }
        this.PlaySound(filepath);
    }
    PlaySound(filePath: string) {
        if (GlobalVariables.Zvuk === 1) {
            if (this.props.m_pwin.props.isAppActive || Platform.OS === 'web') {
                if (this.sounds[filePath]) {
                    this.sounds[filePath].replayAsync().catch(() => {});
                }
            }
        }
    }
}

export default cSound;
