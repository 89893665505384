import React, { Component } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';
import { Table, Row } from 'react-native-table-component-2';
import Button from '../Components/core/Button';
import ScrollbarWrapperVertical from '~/Components/core/ScrollbarWrapperVertical';

class DlgStatistika extends Component<BaseDlgProps> {
    m_pob: number = 0;
    m_zag: number = 0;
    tableHead = [];
    widthArr = [];
    tableData = [];
    start: number;
    cstyles = stylesGenerator();
    game: string = '';
    constructor(props: BaseDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    pushResultsToTable(rezult: string, game: string = '') {
        while (rezult.indexOf(';') > -1) {
            let tz_index = rezult.indexOf(';');
            if (rezult.charAt(tz_index - 1) === 'w') {
                this.m_pob = this.m_pob + 1;
            }
            if (rezult.charAt(tz_index - 1) === 'l') {
                this.m_zag = this.m_zag + 1;
            }
            let ins_str = rezult.substring(0, tz_index - 1);
            this.tableData.push(game !== '' ? [this.props.m_pwin.m_main.GetLanguageText(game) + ' : ' + ins_str] : [ins_str]);
            rezult = rezult.substring(tz_index + 1, rezult.length);
        }
    }

    InitTableWidth() {
        this.widthArr = [];
        this.widthArr.push(750 * GlobalVariables.scale);
    }

    async InitTable() {
        this.tableData = [];
        this.m_pob = 0;
        this.m_zag = 0;
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Game'));
        this.InitTableWidth();
        if (this.game !== '') {
            let rezult: string = await this.props.m_pwin.m_main.GetProfileString('Options', this.game, '');
            this.pushResultsToTable(rezult);
        } else {
            let rezult: string = await this.props.m_pwin.m_main.GetProfileString('Options', 'Belot4', '');
            this.pushResultsToTable(rezult, 'Belote 4 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Bridge Rubber', '');
            this.pushResultsToTable(rezult, 'Bridge Rubber');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Duplicate Bridge', '');
            this.pushResultsToTable(rezult, 'Duplicate Bridge');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Domino4', '');
            this.pushResultsToTable(rezult, 'Domino 4 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Domino3', '');
            this.pushResultsToTable(rezult, 'Domino 3 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Domino2', '');
            this.pushResultsToTable(rezult, 'Domino 2 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Belot3', '');
            this.pushResultsToTable(rezult, 'Belote 3 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Belot2', '');
            this.pushResultsToTable(rezult, 'Belote 2 Players');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Santase', '');
            this.pushResultsToTable(rezult, 'Schnapsen (66)');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Tabla', '');
            this.pushResultsToTable(rezult, 'Backgammon');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon - Tapa', '');
            this.pushResultsToTable(rezult, 'Backgammon - Tapa');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon - Gulbara', '');
            this.pushResultsToTable(rezult, 'Backgammon - Gulbara');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Backgammon 3 in 1', '');
            this.pushResultsToTable(
                rezult,
                this.props.m_pwin.m_main.GetLanguageText('Backgammon') + ' 3 ' + this.props.m_pwin.m_main.GetLanguageText('in') + ' 1'
            );
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Blato', '');
            this.pushResultsToTable(rezult, 'Sergeant Major (3-5-8)');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'OKEY', '');
            this.pushResultsToTable(rezult, 'OKEY');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Ludo', '');
            this.pushResultsToTable(rezult, 'Ludo');
            rezult = await this.props.m_pwin.m_main.GetProfileString('Options', 'Monopoly', '');
            this.pushResultsToTable(rezult, 'Monopoly');
        }
    }

    async OnInitDialog() {
        await this.InitTable();
    }

    async DoModal() {
        this.props.m_pwin.state.DlgStatistikaVisible = true;
        await this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgStatistikaVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgStatistikaVisible: false });
        return GlobalConstants.IDOK;
    }

    OnOK() {
        this.start = 0;
    }

    Delete() {
        this.tableData = [];
        if (this.game !== '') {
            this.props.m_pwin.m_main.WriteProfileString('Options', this.game, '');
        } else {
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Belot4', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Belot3', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Belot2', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Domino4', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Domino3', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Domino2', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Bridge Rubber', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Duplicate Bridge', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Tabla', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Santase', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Blato', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'OKEY', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Ludo', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Backgammon - Tapa', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Backgammon - Gulbara', '');
            this.props.m_pwin.m_main.WriteProfileString('Options', 'Backgammon 3 in 1', '');
        }
        this.setState({});
    }

    render() {
        if (this.props.m_pwin.state.DlgStatistikaVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 5 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Statistics</Text>
                        </View>
                        <View style={this.cstyles.statContainer}>
                            <View style={[this.cstyles.tableBackground, { marginBottom: 10 * GlobalVariables.scale }]}>
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    <Row
                                        data={this.tableHead}
                                        widthArr={this.widthArr}
                                        style={StyleSheet.flatten(this.cstyles.header)}
                                        textStyle={StyleSheet.flatten([
                                            this.cstyles.tabletext,
                                            { fontSize: GlobalConstants.LARGE_FONT_SIZE * GlobalVariables.scale },
                                        ])}
                                    />
                                </Table>
                                <ScrollbarWrapperVertical
                                    style={StyleSheet.flatten({ height: GlobalConstants.GAME_HEIGHT * 0.62 * GlobalVariables.scale })}
                                >
                                    <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                        {this.tableData.map((rowData, index) => (
                                            <Row
                                                key={index}
                                                data={rowData}
                                                widthArr={this.widthArr}
                                                height={40}
                                                style={StyleSheet.flatten(index % 2 === 0 ? this.cstyles.rowActiveColor : this.cstyles.rowColor)}
                                                textStyle={StyleSheet.flatten([
                                                    this.cstyles.tabletext,
                                                    { fontSize: GlobalConstants.MEDIUM_SMALL_FONT_SIZE * GlobalVariables.scale },
                                                ])}
                                                onPress={() => { return;}}
                                            />
                                        ))}
                                    </Table>
                                </ScrollbarWrapperVertical>
                            </View>
                            <View
                                style={[this.cstyles.result, { paddingBottom: 5 * GlobalVariables.scale, paddingLeft: 10 * GlobalVariables.scale }]}
                            >
                                <View style={[this.cstyles.right]}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Wins</Text>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}> : </Text>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_pob}</Text>
                                </View>
                                <View style={[this.cstyles.right]}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Losses</Text>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}> : </Text>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_zag}</Text>
                                </View>
                                <View style={[this.cstyles.right]}>
                                    <Button
                                        containerStyle={{ width: 160 * GlobalVariables.scale }}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        onPress={this.Delete.bind(this)}
                                        text="Delete"
                                    />
                                </View>
                                <View style={[this.cstyles.right]}>
                                    <Button
                                        containerStyle={{ width: 160 * GlobalVariables.scale }}
                                        textSize={GlobalConstants.LARGE_FONT_SIZE}
                                        onPress={this.OnOK.bind(this)}
                                        text="OK"
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgStatistika;

const stylesGenerator = () =>
    StyleSheet.create({
        result: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
        },
        statContainer: { width: '100%' },
        borderStyle: {
            borderWidth: 1,
            borderColor: '#333333',
            borderStyle: 'solid',
        },
        right: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: '25%',
        },
        tableBackground: {
            backgroundColor: 'white',
            width: '100%',
        },
        header: { height: 35, backgroundColor: '#FFFFFF' },
        text: { paddingLeft: 2, fontSize: GlobalConstants.LARGE_FONT_SIZE },
        tabletext: { paddingLeft: 2 },
        rowActiveColor: { backgroundColor: '#F7F6E7' },
        rowColor: { backgroundColor: '#E7E6E1' },
    });
