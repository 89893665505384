import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import GlobalVariables from '~/Components/GlobalVariables';
import BlatoGetCardOption from './BlatoGetCardOption';
import { BlatoGetCardRowProps } from './BlatoGetCardRowProps';

export default class CBlatoGetCardRow extends Component<BlatoGetCardRowProps> {
    constructor(props: BlatoGetCardRowProps) {
        super(props);
    }

    render() {
        return (
            <View style={[cstyles.biddRow, { height: 50 * GlobalVariables.scale }]}>
                <View style={cstyles.optionContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        color={1}
                        isDisableRadio={this.props.parent.isDisableRadioCard(this.props.numCard, 1)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, 1, undefined)}
                        parent={this.props.parent}
                    />
                </View>
                <View style={cstyles.optionContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        color={2}
                        isDisableRadio={this.props.parent.isDisableRadioCard(this.props.numCard, 2)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, 2, undefined)}
                        parent={this.props.parent}
                    />
                </View>
                <View style={cstyles.optionContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        color={3}
                        isDisableRadio={this.props.parent.isDisableRadioCard(this.props.numCard, 3)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, 3, undefined)}
                        parent={this.props.parent}
                    />
                </View>
                <View style={cstyles.optionContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        color={4}
                        isDisableRadio={this.props.parent.isDisableRadioCard(this.props.numCard, 4)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, 4, undefined)}
                        parent={this.props.parent}
                    />
                </View>
                <View style={cstyles.playerContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        player={1}
                        isDisableRadio={this.props.parent.isDisableRadioPlayer(this.props.numCard, 1)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, undefined, 1)}
                        parent={this.props.parent}
                    />
                </View>
                <View style={cstyles.playerContainer}>
                    <BlatoGetCardOption
                        numCard={this.props.numCard}
                        player={2}
                        isDisableRadio={this.props.parent.isDisableRadioPlayer(this.props.numCard, 2)}
                        getRadioButtonStatus={this.props.parent.getRadioButtonStatus(this.props.numCard, undefined, 2)}
                        parent={this.props.parent}
                    />
                </View>
            </View>
        );
    }
}

const cstyles = StyleSheet.create({
    biddRow: {
        flexDirection: 'row',
        width: '100%',
    },
    playerContainer: { width: '25%', justifyContent: 'center', alignItems: 'center' },
    optionContainer: { width: '12.5%', justifyContent: 'center', alignItems: 'center' },
});
