import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class DlgNaddavane extends Component<ParentDlgProps> {
    naddavane: number;
    kontra: boolean = false;
    rekontra: boolean = false;
    m_pas: number = -1;
    klik_o: boolean;
    start: number;

    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnInitDialog() {
        this.start = 1;
        this.m_pas = -1;
        this.klik_o = false;
        if (this.naddavane === 6 && this.kontra === false && this.rekontra === false) {
            this.start = 0;
            this.OnOK();
        }
    }

    async DoModal() {
        this.props.m_pwin.state.DlgNaddavaneVisible = true;
        this.OnInitDialog();
        this.setState({});
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgNaddavaneVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgNaddavaneVisible: false });
        return GlobalConstants.IDOK;
    }

    Onsp() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 0;
        this.OnOK();
    }
    Onka() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 1;
        this.OnOK();
    }
    Onku() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 2;
        this.OnOK();
    }
    Onpi() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 3;
        this.OnOK();
    }
    Onbe() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 4;
        this.OnOK();
    }
    Onvs() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 5;
        this.OnOK();
    }
    Onko() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 6;
        this.OnOK();
    }
    Onre() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = 7;
        this.OnOK();
    }
    Onpas() {
        this.klik_o = true;
        this.start = 0;
        this.m_pas = -1;
        this.OnOK();
    }
    async OnOK() {
        if (this.klik_o) {
            this.start = 0;
        }
    }

    render() {
        if (this.props.m_pwin.state.DlgNaddavaneVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 80 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Bidding</Text>
                        </View>
                        <View style={styles.row}>
                            <Button
                                disabled={this.naddavane > 0}
                                image={'bid/1-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onsp.bind(this)}
                                text="Club"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.naddavane > 1}
                                image={'bid/2-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onka.bind(this)}
                                text="Diamond"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.naddavane > 2}
                                image={'bid/3-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onku.bind(this)}
                                text="Heart"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.naddavane > 3}
                                image={'bid/4-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onpi.bind(this)}
                                text="Spade"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                        </View>
                        <View style={styles.row}>
                            <Button
                                disabled={this.naddavane > 4}
                                image={'bid/5-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onbe.bind(this)}
                                text="No Trump"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={this.naddavane > 5}
                                image={'bid/6-n.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onvs.bind(this)}
                                text="Trumps"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={!this.kontra}
                                image={'bid/7-n1.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onko.bind(this)}
                                text="Double"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                            <Button
                                disabled={!this.rekontra}
                                image={'bid/8-n2.png'}
                                imageWidth={30}
                                imageHeight={30}
                                containerStyle={[modal.buttonStyle4PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onre.bind(this)}
                                text="Redouble"
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                            />
                        </View>
                        <View style={styles.row}>
                            <Button
                                disabled={this.naddavane > 7}
                                textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}
                                containerStyle={[modal.buttonStyle1PerRow, {height: 72 * GlobalVariables.scale}]}
                                onPress={this.Onpas.bind(this)}
                                text="Pass"
                            />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgNaddavane;
