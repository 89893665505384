import React, { Component } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';

class DlgRezultatSantase extends Component<ParentDlgProps> {
    m_name1: string;
    m_name2: string;
    start: number;
    m_orez1: number = 0;
    m_orez2: number = 0;
    m_rez1: number = 0;
    m_rez2: number = 0;
    //int vz[13],kd[25];
    vz: number[] = [];
    kd: number[] = [];
    constructor(props: ParentDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnInitDialog() {}

    async DoModal() {
        this.OnInitDialog();
        this.props.m_pwin.state.DlgRezultatSantaseVisible = true;
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgRezultatSantaseVisible) {
            await this.performTimeConsumingTask();
        }
        return GlobalConstants.IDOK;
    }

    OnOK() {
        this.start = 0;
        this.props.m_pwin.setState({ DlgRezultatSantaseVisible: false });
    }

    AddRezults() {}

    DeleteAllItems() {}

    Invalidate() {}

    render() {
        if (this.props.m_pwin.state.DlgRezultatSantaseVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 80 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 120 * GlobalVariables.scale,
                                width: 550 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_name1}
                                </Text>
                            </View>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_name2}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_rez1.toString()}
                                </Text>
                            </View>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_rez2.toString()}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Total</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_orez1.toString()}
                                </Text>
                            </View>
                            <View style={cstyle.halfRow}>
                                <Text textSize={GlobalConstants.LARGE_FONT_SIZE} noTranslate={true}>
                                    {this.m_orez2.toString()}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle1PerRow} onPress={this.OnOK.bind(this)} text="Ок" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgRezultatSantase;

const cstyle = StyleSheet.create({
    halfRow: {
        width: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
