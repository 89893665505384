import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Table, Row } from 'react-native-table-component-2';
import ScrollbarWrapperVertical from '~/Components/core/ScrollbarWrapperVertical';
import GlobalConstants from '../../Components/GlobalConstants';
import GlobalVariables from '../../Components/GlobalVariables';
import { ParentComponentProps } from '../../Components/props/ParentComponentProps';

export default class DlgOnlineRooms extends Component<ParentComponentProps> {
    tableHead = [];
    widthArr = [];
    tableData = [];
    constructor(props: ParentComponentProps) {
        super(props);
        this.InitTableWidth();
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Room'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Tables'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Players'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Tournament'));
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Description'));
    }

    InitTableWidth() {
        this.widthArr = [];
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.25);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.14);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.14);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.17);
        this.widthArr.push(GlobalVariables.WindowWidth * 0.75 * 0.3);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    DeleteAllItems() {
        this.tableData = [];
    }

    AddRoom(room: string[]) {
        this.tableData.push(room);
    }

    UpdateRoom(room_name: string, tables: string, players: string, tournament_text: string, tournament: string) {
        for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i][0] === room_name) {
                this.tableData[i][1] = tables;
                this.tableData[i][2] = players;
                this.tableData[i][3] = tournament_text;
                this.tableData[i][6] = tournament;
                break;
            }
        }
    }

    GetItem(roomName: string) {
        return this.tableData.find((item) => item[0] === roomName);
    }

    GetItemText(rowNumber: number, columnNumber: number): string {
        return this.tableData[rowNumber][columnNumber];
    }

    render() {
        if (GlobalVariables.WindowHeight > GlobalConstants.SHOW_ROOMS_MIN_HEIGHT) {
            return (
                <View style={styles.container}>
                    <Table borderStyle={StyleSheet.flatten(styles.borderStyle)}>
                        <Row
                            data={this.tableHead}
                            widthArr={this.widthArr}
                            style={StyleSheet.flatten(styles.header)}
                            textStyle={StyleSheet.flatten(styles.text)}
                        />
                    </Table>
                    <ScrollbarWrapperVertical style={StyleSheet.flatten(styles.dataWrapper)}>
                        <Table borderStyle={StyleSheet.flatten(styles.borderStyle)}>
                            {this.tableData.map((rowData, index) => (
                                <Row
                                    key={index}
                                    data={rowData}
                                    widthArr={this.widthArr}
                                    height={24}
                                    style={StyleSheet.flatten(
                                        this.props.m_pwin.m_main.GetLanguageText(this.props.parent.sel_type) === rowData[0]
                                            ? styles.rowActiveColor
                                            : styles.rowColor
                                    )}
                                    textStyle={StyleSheet.flatten(styles.text)}
                                    onPress={() => this.props.parent.OnClickRooms(index)}
                                />
                            ))}
                        </Table>
                    </ScrollbarWrapperVertical>
                </View>
            );
        } else {
            return null;
        }
    }
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    borderStyle: {
        borderWidth: 1,
        borderColor: '#333333',
        borderStyle: 'solid',
    },
    header: { height: 22, backgroundColor: '#FFFFFF' },
    text: { fontWeight: '100', paddingLeft: 2 },
    dataWrapper: { marginTop: -1 },
    rowActiveColor: { backgroundColor: 'rgb(0,120,215)' },
    rowColor: { backgroundColor: 'rgb(105,123,130)' },
});
