import React, { Component } from 'react';
import { Dimensions, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import CPicture from '~/Components/cppComponents/CPicture';
import { styles } from '../../../css/container';
import { dialog } from '../../../css/dialog';
import { modal } from '../../../css/modal';
import Button from '../../Components/core/Button';
import Text from '../../Components/core/Text';
import GlobalConstants from '../../Components/GlobalConstants';
import GlobalVariables from '../../Components/GlobalVariables';
import { ParentDlgProps } from '../../Components/props/ParentDlgProps';
import CBlatoGetCardRow from './BlatoGetCardRow';

class CBlatoGetCards extends Component<ParentDlgProps> {
    start: number;
    name: string[] = [];
    m_name2: string;
    LastRez: number[] = [];
    Color: number;
    BroiBoiaPl: number[][] = []; //[4][5]
    m_name3: string;
    ObiaveniC: number[] = [];
    ObiaveniP: number[] = [];
    m_gr: number[] = [];
    m_pl: number[] = [];
    m_pp2: string;
    m_pp3: string;
    Colors: boolean[][] = []; //[5][14]
    Players: boolean[][] = []; //[3][14];

    constructor(props: ParentDlgProps) {
        super(props);
        this.OnInitArrays();
    }

    OnInitArrays() {
        for (let i1 = 0; i1 < 4; i1++) {
            this.BroiBoiaPl[i1] = [];
        }
    }

    OnInitDialog() {
        for (let i1 = 0; i1 < 14; i1++) {
            this.ObiaveniC[i1] = 0;
            this.ObiaveniP[i1] = 0;
        }
        for (let i1 = 0; i1 < 5; i1++) {
            this.Colors[i1] = [];
        }
        for (let i1 = 0; i1 < 13; i1++) {
            this.Players[i1] = [];
        }
        for (let i1 = 1; i1 < 14; i1++) {
            this.m_gr[i1] = -1;
            this.m_pl[i1] = -1;
        }
        this.Opresniavane();
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnExit() {
        this.OnOk();
    }

    OnOk() {
        this.start = 0;
    }

    async DoModal() {
        this.props.m_pwin.state.CBlatoGetCardsVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CBlatoGetCardsVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ CBlatoGetCardsVisible: false });
        return GlobalConstants.IDOK;
    }
    isDisableRadioCard(numCard: number, color: number): boolean {
        if (this.Color === color) {
            return true;
        }
        return !this.Colors[color][numCard];
    }

    getRadioButtonStatus(numCard: number, color: number, player: number): 'checked' | 'unchecked' {
        if (color) {
            if (this.Color === color) {
                return 'unchecked';
            }
            if (this.m_gr[numCard] === color - 1) {
                return 'checked';
            }
        }
        if (player) {
            if (this.m_pl[numCard] === player - 1) {
                return 'checked';
            }
        }
        return 'unchecked';
    }

    isDisableRadioPlayer(numCard: number, player: number): boolean {
        return !this.Players[player][numCard];
    }

    onPressOption(color: number, numCard: number, player: number) {
        console.log(numCard, color, player);
        if (color) {
            this.m_gr[numCard] = color - 1;
        }
        if (player) {
            this.m_pl[numCard] = player - 1;
        }
        this.Opresniavane();
    }

    GetInfo() {
        for (let i = 1; i < 14; i++) {
            this.ObiaveniC[i] = this.m_gr[i] + 1;
            this.ObiaveniP[i] = this.m_pl[i] + 1;
        }
    }
    TakeInfo() {
        for (let i = 1; i < 14; i++) {
            this.m_gr[i] = this.ObiaveniC[i] - 1;
            this.m_pl[i] = this.ObiaveniP[i] - 1;
        }
    }

    Opresniavane() {
        this.GetInfo();
        for (let i = 1; i <= 13; i++) {
            for (let j = 1; j <= 4; j++) {
                this.Colors[j][i] = true;
            }
            this.Players[1][i] = true;
            this.Players[2][i] = true;
        }
        for (let i = 1; i <= 13; i++) {
            for (let j = 1; j <= 4; j++) {
                if (i > this.LastRez[1]) {
                    this.Colors[j][i] = false;
                    this.Players[1][i] = false;
                    this.Players[2][i] = false;
                }
                if (j === this.Color) {
                    this.Colors[j][i] = false;
                }
            }
        }
        let markedP: number[] = [0, 0, 0];
        let markedC: number[] = [0, 0, 0, 0, 0];
        for (let i = 1; i < 14; i++) {
            if (this.ObiaveniP[i] > 0) {
                markedP[this.ObiaveniP[i]]++;
            }
            if (this.ObiaveniC[i] > 0) {
                markedC[this.ObiaveniC[i]]++;
            }
        }
        for (let i = 1; i < 3; i++) {
            if (markedP[i] + this.LastRez[i + 1] >= 0) {
                for (let j = 1; j < 14; j++) {
                    if (this.ObiaveniP[j] !== i) {
                        this.Players[i][j] = false;
                    }
                }
            }
        }
        for (let i = 1; i < 5; i++) {
            if (markedC[i] >= this.BroiBoiaPl[1][i]) {
                for (let j = 1; j < 14; j++) {
                    if (this.ObiaveniC[j] !== i) {
                        this.Colors[i][j] = false;
                    }
                }
            }
        }
        let breakPoint: boolean = false;
        for (let i = 1; i < 14; i++) {
            if (breakPoint) {
                break;
            }
            if (this.ObiaveniC[i] > 0 && this.ObiaveniP[i] <= 0) {
                for (let j = 1; j < 3; j++) {
                    if (this.Players[j][i]) {
                        this.ObiaveniP[i] = j;
                        markedP[j]++;
                        breakPoint = true;
                        break;
                    }
                }
            }
        }
        for (let i = 1; i < 14; i++) {
            if (breakPoint) {
                break;
            }
            if (this.ObiaveniP[i] > 0 && this.ObiaveniC[i] <= 0) {
                for (let j = 1; j < 5; j++) {
                    if (this.Colors[j][i]) {
                        this.ObiaveniC[i] = j;
                        markedC[j]++;
                        breakPoint = true;
                        break;
                    }
                }
            }
        }
        for (let i = 1; i < 3; i++) {
            if (markedP[i] + this.LastRez[i + 1] >= 0) {
                for (let j = 1; j < 14; j++) {
                    if (this.ObiaveniP[j] !== i) {
                        this.Players[i][j] = false;
                    }
                }
            }
        }
        for (let i = 1; i < 5; i++) {
            if (markedC[i] >= this.BroiBoiaPl[1][i]) {
                for (let j = 1; j < 14; j++) {
                    if (this.ObiaveniC[j] !== i) {
                        this.Colors[i][j] = false;
                    }
                }
            }
        }
        this.TakeInfo();
        this.m_pp2 = this.props.m_pwin.m_main.IntToString(this.LastRez[2] + markedP[1]);
        this.m_pp3 = this.props.m_pwin.m_main.IntToString(this.LastRez[3] + markedP[2]);
        //EnableRadioButtons();
        this.setState({});
    }

    render() {
        if (this.props.m_pwin.state.CBlatoGetCardsVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 0 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                                height:
                                    Dimensions.get('window').height * 0.81 -
                                    (this.props.m_pwin.state.CChatBarVisible ? GlobalConstants.CHAT_BAR_HEIGHT : 0),
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>Drawing of a cards</Text>
                        </View>
                        <View style={[styles.row, { height: 45 * GlobalVariables.scale }]}>
                            <View style={cstyles.headColors}>
                                <View style={cstyles.colorIcon}>
                                    <CPicture image={'bid/1-n.png'} m_x={0} m_y={0} folder="files_2.0" m_Width={40} m_Height={40} relative={true} />
                                </View>
                                <View style={cstyles.colorIcon}>
                                    <CPicture image={'bid/2-n.png'} m_x={0} m_y={0} folder="files_2.0" m_Width={40} m_Height={40} relative={true} />
                                </View>
                                <View style={cstyles.colorIcon}>
                                    <CPicture image={'bid/3-n.png'} m_x={0} m_y={0} folder="files_2.0" m_Width={40} m_Height={40} relative={true} />
                                </View>
                                <View style={cstyles.colorIcon}>
                                    <CPicture image={'bid/4-n.png'} m_x={0} m_y={0} folder="files_2.0" m_Width={40} m_Height={40} relative={true} />
                                </View>
                            </View>
                            <View style={cstyles.headNames}>
                                <View style={cstyles.headName}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_name2}</Text>
                                </View>
                                <View style={cstyles.headName}>
                                    <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_name3}</Text>
                                </View>
                            </View>
                        </View>
                        <ScrollView
                            style={[
                                cstyles.scrollViewSize,
                                {
                                    height:
                                        Dimensions.get('window').height * 0.48 -
                                        (this.props.m_pwin.state.CChatBarVisible ? GlobalConstants.CHAT_BAR_HEIGHT : 0),
                                },
                            ]}
                        >
                            <Pressable style={cstyles.content}>
                                {this.LastRez[1] > 0 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={1}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 1 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={2}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 2 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={3}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 3 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={4}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 4 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={5}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 5 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={6}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 6 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={7}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 7 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={8}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 8 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={9}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 9 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={10}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 10 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={11}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 11 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={12}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                                {this.LastRez[1] > 12 ? (
                                    <CBlatoGetCardRow
                                        parent={this}
                                        numCard={13}
                                        isDisableRadioCard={() => this.isDisableRadioCard}
                                        isDisableRadioPlayer={() => this.isDisableRadioPlayer}
                                        getRadioButtonStatus={() => this.getRadioButtonStatus}
                                    />
                                ) : null}
                            </Pressable>
                            <Pressable style={styles.row}>
                                <View style={cstyles.infoContainer}>
                                    <View style={cstyles.infoTextContainer}>
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_pp2}</Text>
                                    </View>
                                    <View style={cstyles.infoTextContainer}>
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE}>{this.m_pp3}</Text>
                                    </View>
                                </View>
                            </Pressable>
                        </ScrollView>

                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle3PerRow} onPress={this.OnOk.bind(this)} text="OK" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CBlatoGetCards;

const cstyles = StyleSheet.create({
    infoContainer: { width: '100%', paddingLeft: '50%', flexDirection: 'row' },
    infoTextContainer: { width: '50%', justifyContent: 'center', alignItems: 'center' },
    scrollViewSize: { width: '100%', marginTop: 5 },
    headName: { width: '50%', justifyContent: 'center', alignItems: 'center' },
    colorIcon: { width: '25%', justifyContent: 'center', alignItems: 'center' },
    contentColors: { borderWidth: 1, borderColor: 'gray', width: '50%' },
    contentPlayers: { flexDirection: 'row', borderWidth: 1, borderColor: 'gray', width: '50%' },
    content: { justifyContent: 'center', alignItems: 'center', width: '100%' },
    headColors: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%' },
    headNames: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%' },
});
