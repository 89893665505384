import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Button from '../Components/core/Button';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { ParentDlgProps } from '../Components/props/ParentDlgProps';
import { Table, Row } from 'react-native-table-component-2';

class CImpResult extends Component<ParentDlgProps> {
    tableHead = [];
    widthArr = [];
    tableData = [];
    start: number;
    fzone: number;
    zone: number[] = [];
    result = []; //[3][30];
    result1 = []; //[3][30];
    cstyles = stylesGenerator();
    constructor(props: ParentDlgProps) {
        super(props);
        for (let i = 0; i < 3; i++) {
            this.result[i] = [];
            this.result1[i] = [];
        }
        for (let i = 0; i < 30; i++) {
            this.zone[i] = 0;
            this.result[0][i] = -1;
            this.result[1][i] = -1;
            this.result[2][i] = -1;
            this.result1[0][i] = '';
            this.result1[1][i] = '';
            this.result1[2][i] = '';
        }
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    OnOK() {
        this.start = 0;
        this.props.m_pwin.setState({ CImpResultVisible: false });
    }

    DeleteAllItems() {
        this.tableData = [];
    }

    AddRezults() {
        this.zone[0] = this.fzone;
        for (let i = 1; i < 30; i++) {
            if (this.zone[i - 1] === 1 && i % 4 === 1) {
                this.zone[i] = 2;
            }
            if (this.zone[i - 1] === 2 && i % 4 === 1) {
                this.zone[i] = 1;
            }
            if (i % 4 === 2) {
                this.zone[i] = 3;
            }
            if (i % 4 === 3) {
                this.zone[i] = 0;
            }
            if (i % 4 === 0) {
                this.zone[i] = this.fzone;
            }
        }
        for (let i = 0; i < 30; i++) {
            let row = [];
            let col0 = '';
            let col1 = '';
            let col2 = '';
            let col3 = '';

            if (this.result1[2][i] !== '' || this.result1[1][i] !== '' || this.result1[0][i] !== '' || i < 8) {
                if (this.zone[i] === 0) {
                    col0 = this.props.m_pwin.m_main.GetLanguageText('None');
                }
                if (this.zone[i] === 1) {
                    col0 = this.props.m_pwin.m_main.GetLanguageText('N/S');
                }
                if (this.zone[i] === 2) {
                    col0 = this.props.m_pwin.m_main.GetLanguageText('E/W');
                }
                if (this.zone[i] === 3) {
                    col0 = this.props.m_pwin.m_main.GetLanguageText('N/S') + '  ' + this.props.m_pwin.m_main.GetLanguageText('E/W');
                }
                col3 = this.result1[2][i];
                col2 = this.result1[1][i];
                col1 = this.result1[0][i];
            }
            row[0] = col0;
            row[1] = col1;
            row[2] = col2;
            row[3] = col3;
            if (col0 !== '') {
                this.tableData.push(row);
            }
        }
    }
    Clean() {
        for (let i = 0; i < 30; i++) {
            this.result1[0][i] = '';
            this.result1[1][i] = '';
            this.result1[2][i] = '';
        }
    }

    InitTableWidth() {
        this.widthArr = [];
        this.widthArr.push(60 * GlobalVariables.scale);
        this.widthArr.push(60 * GlobalVariables.scale);
        this.widthArr.push(60 * GlobalVariables.scale);
        this.widthArr.push(260 * GlobalVariables.scale);
    }

    InitTable() {
        this.InitTableWidth();
        this.tableHead = [];
        this.tableData = [];
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Zone'));
        this.tableHead.push('S/N');
        this.tableHead.push('E/W');
        this.tableHead.push(this.props.m_pwin.m_main.GetLanguageText('Current Result'));
    }

    OnInitDialog() {
        this.InitTable();
        this.AddRezults();
    }

    async DoModal() {
        this.props.m_pwin.state.CImpResultVisible = true;
        this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.CImpResultVisible) {
            await this.performTimeConsumingTask();
        }
        return GlobalConstants.IDOK;
    }

    render() {
        if (this.props.m_pwin.state.CImpResultVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            {
                                top: GlobalVariables.positionTop + 20 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + 20 * GlobalVariables.scale,
                                width: 750 * GlobalVariables.scale,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.MEDIUM_FONT_SIZE}>Scoring</Text>
                        </View>
                        <View style={[this.cstyles.tableBackground, { marginBottom: 10 * GlobalVariables.scale }]}>
                            <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                <Row
                                    data={this.tableHead}
                                    widthArr={this.widthArr}
                                    style={StyleSheet.flatten(this.cstyles.header)}
                                    textStyle={StyleSheet.flatten([
                                        this.cstyles.text,
                                        { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                    ])}
                                    onPress={() => { return;}}
                                />
                            </Table>
                            <ScrollView style={StyleSheet.flatten(this.cstyles.dataWrapper)}>
                                <Table borderStyle={StyleSheet.flatten(this.cstyles.borderStyle)}>
                                    {this.tableData.map((rowData, index) => (
                                        <Row
                                            key={index}
                                            data={rowData}
                                            widthArr={this.widthArr}
                                            height={30}
                                            style={StyleSheet.flatten(index % 2 === 0 ? this.cstyles.rowActiveColor : this.cstyles.rowColor)}
                                            textStyle={StyleSheet.flatten([
                                                this.cstyles.text,
                                                { fontSize: GlobalConstants.SMALL_FONT_SIZE * GlobalVariables.scale },
                                            ])}
                                            onPress={() => { return;}}
                                        />
                                    ))}
                                </Table>
                            </ScrollView>
                        </View>
                        <View style={styles.row}>
                            <Button containerStyle={modal.buttonStyle4PerRow} onPress={this.OnOK.bind(this)} text="OK" />
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default CImpResult;

const stylesGenerator = () =>
    StyleSheet.create({
        borderStyle: {
            borderWidth: 1,
            borderColor: '#333333',
            borderStyle: 'solid',
        },
        tableBackground: {
            backgroundColor: 'white',
        },
        header: { height: 30, backgroundColor: '#FFFFFF' },
        text: { paddingLeft: 2 },
        dataWrapper: {
            maxHeight: Dimensions.get('window').height * 0.5,
        },
        rowActiveColor: { backgroundColor: '#F7F6E7' },
        rowColor: { backgroundColor: '#E7E6E1' },
    });
