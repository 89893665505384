/* eslint-disable react-native/no-inline-styles */
import { View, StyleSheet, Image, ImageBackground, Pressable, KeyboardAvoidingView, Keyboard, Switch, Platform, Dimensions } from 'react-native';
import React from 'react';
import { BaseDlgProps } from '../../Components/props/BaseDlgProps';
import GlobalConstants from '../../Components/GlobalConstants';
import { Picker } from '@react-native-picker/picker';
import Button from '../../Components/core/Button';
import CPictureParams from '../../Components/cppComponents/CPictureParams';
import Text from '../../Components/core/Text';
import DlgOnlineRooms from './DlgOnlineRooms';
import DlgOnlineTables from './DlgOnlineTables';
import GlobalVariables from '../../Components/GlobalVariables';
import { styles } from '../../../css/container';
import { dialog } from '../../../css/dialog';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import t from '../../../translation';
import InputField from '../../Components/core/InputField';
import { RadioButton } from 'react-native-paper';
import Tooltip from '../../Components/core/Tooltip';
import ScrollbarWrapper from '~/Components/core/ScrollbarWrapper';
import { TouchableWithoutFeedback } from 'react-native';
import config from '~/config';

class DlgOnline extends React.Component<BaseDlgProps> {
    m_eye: number;
    Select: string[] = [];
    m_static_online: string;
    m_static_reg: string;
    m_static_time: string;
    m_static_language: string;
    m_static_pmoney: string;
    m_static_prating: string;
    m_static_country: string;
    m_static_rating: string;
    p1_rating: number = 0;
    time_left: number;
    tournament_name: string;
    restart_price: number;
    tournament_free: boolean;
    m_static_tournament: string = '';
    personal_tournament_link: string;
    m_static_money: string;
    db_money: number;
    tournament_money: number;
    money: number;
    m_tournament: boolean = true;
    show_tournament_checkbox = false;
    enable_tournament_checkbox = false;
    m_static_tournament_rank: string;

    sel_player: string = '';
    tmp_sel_playr: string = '';
    show_player: number = 0;
    sel_type: string = '';
    sel_online: string = '';
    m_table_password: string = '';
    select_game: number = 0;
    Game: string;
    pass: string;
    user_tournament: string = '';
    user_enable_password: string = '';
    user_enable_watch: string = '';
    user_chat_message: string = '';
    user_emote_message: string = '';
    m_static_rooms: string = '';
    default_title: string = '';
    m_static_game: string = '';
    m_static_tbl: string = '';
    m_static_bet: string = '';
    m_static_show_friends: string = '';
    m_static_friends: string = '';
    m_player_info: string;
    user_pic: CPictureParams = new CPictureParams();
    user_pic_no_img: CPictureParams = new CPictureParams();
    m_rooms: DlgOnlineRooms;
    m_list: DlgOnlineTables;
    auto_connect: string = '';
    m_search: string = '';
    m_friends: boolean;
    m_game_description: string = '';
    GameType: number;
    Players: number;
    sblind: number;
    tour_pic: CPictureParams = new CPictureParams();
    m_username: string;

    tour_results_pic: CPictureParams = new CPictureParams();
    restart_tournament_pic: string;
    delete_stat_pic: string;
    delete_profile_pic: CPictureParams = new CPictureParams();
    edit_profile_pic: CPictureParams = new CPictureParams();
    add_pic: string;
    friend_pic: string;
    gift_pic: string;
    player_language_pic: CPictureParams = new CPictureParams();
    player_money_pic: CPictureParams = new CPictureParams();
    player_rating_pic: CPictureParams = new CPictureParams();
    os_pic: CPictureParams = new CPictureParams();
    player_stat_pic: CPictureParams = new CPictureParams();
    vip_pic: CPictureParams = new CPictureParams();
    rating_pic: CPictureParams = new CPictureParams();
    time_pic: CPictureParams = new CPictureParams();
    user_stat_pic: CPictureParams = new CPictureParams();
    money_pic: CPictureParams = new CPictureParams();
    player_pic: CPictureParams = new CPictureParams();
    player_pic_no_image: CPictureParams = new CPictureParams();
    flag_pic: CPictureParams = new CPictureParams();
    passwordVisibility: boolean = true;
    restart_tournament_tooltip: string;
    tour_results_tooltip: string;
    chips_tooltip: string;
    friend_tooltip: string;
    show_friend_tooltip: string;
    find_friend_tooltip: string;
    password_tooltip: string;
    eye_tooltip: string;
    online_users_tooltip: string;
    open_eye_tooltip: string;
    close_eye_tooltip: string;
    all_users_tooltip: string;
    rating_tooltip: string;
    stat_tooltip: string;
    delete_tooltip: string;
    player_tooltip: string;
    tournament_rank_tooltip: string;
    room_tooltip: string;
    user_tooltip: string;
    add_vip_tooltip: string;
    m_game_type: string;
    showGameType: boolean = false;
    m_game_type_combo: { label: string; value: string }[] = [];

    cstyles = stylesGenerator();
    games = this.props.m_pwin.m_main.getVisibleGames();
    addFriendsColor = 'rgb(125, 226, 78)';

    constructor(props: BaseDlgProps) {
        super(props);
    }

    LoadStaticImages() {
        this.tour_results_pic.image = '/files_1.0/online/Rooms/Tournaments.gif';
        this.restart_tournament_pic = '/files_1.0/online/restart_tournament.gif';
        this.rating_pic.image = '/files_1.0/online/rating.gif';
        this.user_stat_pic.image = '/files_1.0/online/stat.gif';
        this.delete_stat_pic = '/files_1.0/online/delete.gif';
        this.delete_profile_pic.image = '/files_1.0/online/delete.gif';
        this.edit_profile_pic.image = '/files_1.0/online/edit_profile.gif';
        this.add_pic = '/files_1.0/online/add.gif';
        this.gift_pic = '/files_1.0/online/gift.png';
        this.money_pic.image = '/files_1.0/online/money.gif';
        this.friend_pic = '/files_1.0/online/friend.gif';
    }

    onChangeTitle() {
        if (this.props.m_pwin.m_main.Title != '' && this.props.m_pwin.m_main.Title.length > 2) {
            let newTitle =
                this.props.m_pwin.m_main.Title.substring(5, this.props.m_pwin.m_main.Title.length) + this.props.m_pwin.m_main.Title.substring(0, 5);
            this.props.m_pwin.m_main.Title = newTitle;
            this.default_title = 'Favorite Games ' + GlobalConstants.GAME_VERSION + '   <<   ' + newTitle + '   >>';
            this.setState({ default_title: this.default_title });
        }
    }

    async OnInitDialog() {
        console.log('DlgOnline OnInitDialog');
        this.games = this.props.m_pwin.m_main.getVisibleGames();
        this.m_search = '';
        if (this.auto_connect === '') {
            this.m_table_password = '';
        }
        this.default_title =
            this.props.m_pwin.m_main.GetLanguageText('Online Game') +
            '   ->  ' +
            this.props.m_pwin.m_main.GetLanguageText('Select one game from a list or create new') +
            '!';
        this.Game = '';
        this.m_static_game = this.props.m_pwin.m_main.GetLanguageText('Game');
        this.m_static_rooms = this.props.m_pwin.m_main.GetLanguageText('Rooms') + ' / ' + this.props.m_pwin.m_main.GetLanguageText('Select a Room');
        this.m_static_tbl = this.props.m_pwin.m_main.GetLanguageText('Find user');
        this.time_left = -1;
        this.m_static_bet = this.props.m_pwin.m_main.GetLanguageText('Play with bet');

        this.m_static_show_friends = this.props.m_pwin.m_main.GetLanguageText('Show friends');

        this.m_static_friends = this.props.m_pwin.m_main.GetLanguageText('Add friends');
        if (this.props.m_pwin.GetGameConfig() === 'All') {
            let savedSelOnline = await this.props.m_pwin.m_main.GetProfileString('Options', 'sel_online', '');
            if (savedSelOnline === '') {
                if (this.props.m_pwin.m_main.language === 'Bulgarian') {
                    this.sel_online = 'Belote 4 Players';
                } else {
                    this.sel_online = 'Bridge Rubber';
                }
            } else {
                this.sel_online = savedSelOnline;
            }
        } else {
            if (this.props.m_pwin.GetGameConfig() === 'Belote') {
                this.sel_online = 'Belote 4 Players';
            }
            if (this.props.m_pwin.GetGameConfig() === 'Bridge') {
                this.sel_online = 'Bridge Rubber';
            }
            if (this.props.m_pwin.GetGameConfig() === 'NSSC') {
                this.sel_online = 'Ludo';
            }
            if (this.props.m_pwin.GetGameConfig() === 'Tabla') {
                this.sel_online = 'Backgammon';
            }
            if (this.props.m_pwin.GetGameConfig() === 'Blato') {
                this.sel_online = 'Sergeant Major (3-5-8)';
            }
            if (this.props.m_pwin.GetGameConfig() === 'Santase') {
                this.sel_online = 'Schnapsen (66)';
            }
        }
        this.m_list.InitTable();
        this.m_eye = 1;
        if (this.sel_online === 'Belote 4 Players') {
            this.select_game = 0;
        }
        if (this.sel_online === 'Belote 3 Players') {
            this.select_game = 1;
        }
        if (this.sel_online === 'Belote 2 Players') {
            this.select_game = 2;
        }
        if (this.sel_online === 'Schnapsen (66)') {
            this.select_game = 3;
        }
        if (this.sel_online === 'Backgammon') {
            this.select_game = 4;
        }
        if (this.sel_online === 'Backgammon - Tapa') {
            this.select_game = 5;
        }
        if (this.sel_online === 'Backgammon - Gulbara') {
            this.select_game = 6;
        }
        if (this.sel_online === 'Domino 4 Players') {
            this.select_game = 7;
        }
        if (this.sel_online === 'Domino 3 Players') {
            this.select_game = 8;
        }
        if (this.sel_online === 'Domino 2 Players') {
            this.select_game = 9;
        }
        if (this.sel_online === 'Bridge Rubber') {
            this.select_game = 10;
        }
        if (this.sel_online === 'Duplicate Bridge') {
            this.select_game = 11;
        }
        if (this.sel_online === 'OKEY') {
            this.select_game = 12;
        }
        if (this.sel_online === 'Ludo') {
            this.select_game = 13;
        }
        if (this.sel_online === 'Sergeant Major (3-5-8)') {
            this.select_game = 14;
        }
        if (this.sel_online === 'Chess') {
            this.select_game = 15;
        }
        if (this.sel_online === 'Poker Texas Hold em') {
            this.select_game = 16;
        }
        if (this.sel_online === 'Backgammon 3 in 1') {
            this.select_game = 17;
        }
        if (this.sel_online === 'Monopoly') {
            this.select_game = 18;
        }
        if (this.sel_online === 'Svara') {
            this.select_game = 19;
        }
        if (this.sel_online === 'Poker Omaha Hi') {
            this.select_game = 20;
        }
        this.InitPlayersCombo(this.sel_online);
        this.m_player_info = this.props.m_pwin.m_con.getUsername();
        this.tour_pic.image = '/files_1.0/online/Rooms/Tournaments.gif';
        this.tour_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('tournaments_online');

        this.rating_pic.image = '/files_1.0/online/rating.gif';
        this.rating_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('rating_page') + '&game=' + this.sel_online;
        this.time_pic.image = '/files_1.0/online/vip/NOVIP.png';

        this.user_stat_pic.image = '/files_1.0/online/stat.gif';
        this.user_stat_pic.m_url =
            this.props.m_pwin.m_main.GetLanguageText('results_page') +
            '&username=' +
            this.props.m_pwin.m_con.getUsername() +
            '&game=' +
            this.sel_online;

        let image = this.props.m_pwin.m_main.GetCustomImage(this.props.m_pwin.m_con.getUsername());
        if (image && image !== '-') {
            this.user_pic.image = image;
        } else {
            this.user_pic.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';
        }
        this.user_pic_no_img.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';

        this.user_pic.m_url =
            this.props.m_pwin.m_main.GetLanguageText('domain') + '/server/public/show_user.php?username=' + this.props.m_pwin.m_con.getUsername();
        this.edit_profile_pic.m_url =
            this.props.m_pwin.m_main.GetLanguageText('domain') + '/server/users/edit_account.php?lang=' + this.props.m_pwin.m_main.language;
        this.HidePlayerInfo(true, true);
        this.chips_tooltip = this.props.m_pwin.m_main.GetLanguageText('How many chips are available to the user in the account.');
        this.friend_tooltip = this.props.m_pwin.m_main.GetLanguageText('Make a list of your friends to find them more easily.');
        this.show_friend_tooltip = this.props.m_pwin.m_main.GetLanguageText('Show only games with your friends.');
        this.find_friend_tooltip = this.props.m_pwin.m_main.GetLanguageText('Type a username or part of it to find a specific user.');
        this.password_tooltip = this.props.m_pwin.m_main.GetLanguageText(
            'You can set a password for your game. Only users who know it can enter your game.'
        );
        this.eye_tooltip = this.props.m_pwin.m_main.GetLanguageText('You can allow or deny the entry of watchers into your game.');
        this.online_users_tooltip = this.props.m_pwin.m_main.GetLanguageText('List of all online users.');
        this.open_eye_tooltip = this.props.m_pwin.m_main.GetLanguageText('Allow watchers to enter your game.');
        this.close_eye_tooltip = this.props.m_pwin.m_main.GetLanguageText('Do not allow watchers to enter your game.');
        this.all_users_tooltip = this.props.m_pwin.m_main.GetLanguageText('List of all registered users.');
        this.rating_tooltip = this.props.m_pwin.m_main.GetLanguageText('Monthly rating ranking.');
        this.stat_tooltip = this.props.m_pwin.m_main.GetLanguageText('Statistics from the played games.');
        this.delete_tooltip = this.props.m_pwin.m_main.GetLanguageText('Delete all your scores for this game.');
        this.player_tooltip = this.props.m_pwin.m_main.GetLanguageText('My Details');
        this.edit_profile_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('Edit Profile.');
        this.delete_profile_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('Delete Profile');
        this.add_vip_tooltip = this.props.m_pwin.m_main.GetLanguageText('Add 30 days VIP to your account.');
        if (GlobalVariables.WindowHeight > GlobalConstants.SHOW_ROOMS_MIN_HEIGHT) {
            this.sel_type = await this.props.m_pwin.m_main.GetProfileString('Options', 'sel_type', 'General Room');
        } else {
            this.sel_type = 'General Room';
        }
        this.props.m_pwin.SendGame('E|' + this.sel_online);
        this.SendGameListen();
        this.Select[0] = '';
        this.CheckJoinButton();
        this.CheckWatchButton();
        this.CheckCreateButton();
    }

    showRestartTournamentImage(): boolean {
        if (
            this.tournament_name !== '' &&
            this.GetTournamentForSelectedRoom() !== 'NONE' &&
            (this.tournament_free ||
                (!this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername()) &&
                    !this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())))
        ) {
            return true;
        }
        return false;
    }

    async DoModal() {
        this.props.m_pwin.setState({ DlgOnlineVisible: true });
        await this.OnInitDialog();
        this.LoadStaticImages();
        this.setState({});
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    async InitPlayersCombo(game: string) {
        if (game === 'Poker Texas Hold em' || game === 'Poker Omaha Hi' || game === 'Svara') {
            this.showGameType = true;
            if (game === 'Poker Texas Hold em') {
                this.m_game_type = await this.props.m_pwin.m_main.GetProfileString('Options', 'poker_players', '10/10');
            }
            if (game === 'Poker Omaha Hi') {
                this.m_game_type = await this.props.m_pwin.m_main.GetProfileString('Options', 'pokero_players', '10/10');
            }
            if (game === 'Svara') {
                this.m_game_type = await this.props.m_pwin.m_main.GetProfileString('Options', 'svara_players', '10/10');
            }

            this.m_game_type_combo = [];
            if (game === 'Poker Texas Hold em' || game === 'Poker Omaha Hi' || game === 'Svara') {
                this.m_game_type_combo.push({ label: '2/2', value: '2/2' });
            }
            if (game === 'Svara') {
                this.m_game_type_combo.push({ label: '4/4', value: '4/4' });
            }
            if (game === 'Poker Texas Hold em' || game === 'Poker Omaha Hi' || game === 'Svara') {
                this.m_game_type_combo.push({ label: '6/6', value: '6/6' });
                this.m_game_type_combo.push({ label: '10/10', value: '10/10' });
            }
        } else if (game === 'Sergeant Major (3-5-8)') {
            this.showGameType = true;
            this.m_game_type_combo = [];
            this.m_game_type_combo.push({
                label: '15 ' + this.props.m_pwin.m_main.GetLanguageText('Round'),
                value: '15 ' + this.props.m_pwin.m_main.GetLanguageText('Round'),
            });
            this.m_game_type_combo.push({
                label: '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round'),
                value: '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round'),
            });
            this.m_game_type_combo.push({
                label: this.props.m_pwin.m_main.GetLanguageText('To 21 Points'),
                value: this.props.m_pwin.m_main.GetLanguageText('To 21 Points'),
            });
            let blato_type: number = await this.props.m_pwin.m_main.GetProfileInt('Options', 'blato_type', 15);
            if (blato_type === 15) {
                this.m_game_type = '15 ' + this.props.m_pwin.m_main.GetLanguageText('Round');
            }
            if (blato_type === 9) {
                this.m_game_type = '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round');
            }
            if (blato_type === 21) {
                this.m_game_type = this.props.m_pwin.m_main.GetLanguageText('To 21 Points');
            }
        } else {
            this.showGameType = false;
        }
        this.setState({});
    }

    UpdateNick(username: string) {
        this.pass = '-_unknow_password123';
        this.m_player_info = username;
        let image = this.props.m_pwin.m_main.GetCustomImage(username);
        if (image && image !== '-') {
            this.user_pic.image = image;
        } else {
            this.user_pic.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';
        }
        this.user_pic_no_img.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';
        this.user_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('domain') + '/server/public/show_user.php?username=' + username;
        this.rating_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('rating_page') + '&game=' + this.sel_online;
        this.user_stat_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('results_page') + '&username=' + username + '&game=' + this.sel_online;
        this.setState({});
    }

    async DeleteUserResultsForGame() {
        this.props.m_pwin.m_yesnochips.SetTitleAndChips(
            this.props.m_pwin.m_main.GetLanguageText('Delete results.'),
            this.props.m_pwin.m_main.GetLanguageText('Are you sure you want to delete all results from this game?'),
            this.props.m_pwin.m_main.delete_statistic_price
        );
        if ((await this.props.m_pwin.m_yesnochips.DoModal()) === GlobalConstants.IDOK) {
            if (this.db_money >= this.props.m_pwin.m_main.delete_statistic_price) {
                this.props.m_pwin.SendGame('*|Q|' + this.sel_online);
                this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
            } else {
                await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
            }
        }
    }

    SetInfo(info: string) {
        this.m_static_online = this.props.m_pwin.m_main.GetLanguageText('Online Players') + ': ' + info;
        this.setState({});
    }

    ShowAddPic() {
        if (this.time_left === -1) {
            return false;
        }
        let d = this.time_left / 1440;
        if (d < 30 && !this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            return true;
        }
        return false;
    }
    SetTime(time_text: string) {
        let time = this.props.m_pwin.m_main._ttoi(time_text);
        let vip_time = 0;
        if (time > 0) {
            let now: Date = new Date();
            let timeVip: Date = new Date(now.getTime() + time * 60 * 1000);
            vip_time = timeVip.getTime();
            console.log(timeVip);
            console.log(vip_time);
        }
        this.props.m_pwin.m_main.WriteProfileInt('Options', 'time_left', vip_time);
        this.time_left = time;
        let d = Math.floor(time / 1440);
        let h = Math.floor((time - d * 1440) / 60);
        let m = Math.floor(time % 60);
        let text = this.props.m_pwin.m_main.GetLanguageText('Time Left') + ': ';

        if (d >= 1) {
            text = text + this.props.m_pwin.m_main.IntToString(d) + ' ';
            if (d === 1) {
                text = text + this.props.m_pwin.m_main.GetLanguageText('day');
            } else {
                text = text + this.props.m_pwin.m_main.GetLanguageText('days');
            }
        }
        if (d > 0 && d < 100) {
            text = text + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ';
            text = text + this.props.m_pwin.m_main.IntToString(h) + ' ';
            text = text + this.props.m_pwin.m_main.GetLanguageText('h.');
        }
        if (d < 1) {
            text = text + this.props.m_pwin.m_main.IntToString(h) + ' ';
            text = text + this.props.m_pwin.m_main.GetLanguageText('h.');
            text = text + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ';
            text = text + this.props.m_pwin.m_main.IntToString(m) + ' ';
            text = text + this.props.m_pwin.m_main.GetLanguageText('min.');
        }
        this.m_static_time = text;

        if (time > 0) {
            this.time_pic.image = '/files_1.0/online/vip/VIP.png';
        } else {
            this.time_pic.image = '/files_1.0/online/vip/NOVIP.png';
            this.autoShowVipDlg();
        }
        this.setState({});
    }

    async autoShowVipDlg() {
        if (!this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            let noShowVipDlgCount = await this.props.m_pwin.m_main.GetProfileInt('Options', 'noShowVipDlgCount', 0);
            if (noShowVipDlgCount >= 10) {
                this.props.m_pwin.openVipDlg();
                this.props.m_pwin.m_main.WriteProfileInt('Options', 'noShowVipDlgCount', 0);
            } else {
                this.props.m_pwin.m_main.WriteProfileInt('Options', 'noShowVipDlgCount', noShowVipDlgCount + 1);
            }
        }
    }

    async Add30DaysVip() {
        this.props.m_pwin.m_yesnochips.SetTitleAndChips(
            this.props.m_pwin.m_main.GetLanguageText('Become a VIP.'),
            this.props.m_pwin.m_main.GetLanguageText('Add 30 days VIP to your account.'),
            this.props.m_pwin.m_main.vip_price
        );
        if ((await this.props.m_pwin.m_yesnochips.DoModal()) === GlobalConstants.IDOK) {
            if (this.db_money >= this.props.m_pwin.m_main.vip_price) {
                this.props.m_pwin.SendGame('*|R|' + this.props.m_pwin.m_con.getUsername());
                this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
            } else {
                await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
            }
        }
    }

    SetRegistrations(reg: string) {
        this.m_static_reg = this.props.m_pwin.m_main.GetLanguageText('Registrations') + ': ' + reg;
        this.setState({});
    }

    UpdateRoom(room_name: string, tables: string, players: string, tournament: string, game: string) {
        if (game === this.sel_online) {
            if (this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(room_name)) !== undefined) {
                let tournament_text = this.props.m_pwin.m_main.GetLanguageText('No');
                if (tournament === 'OPTION') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Yes') + '/' + this.props.m_pwin.m_main.GetLanguageText('No');
                }
                if (tournament === 'REQUIRED') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Yes');
                }
                if (tournament === 'FINISHED') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Played');
                }
                if (room_name === this.sel_type) {
                    let old_user_tournament = this.user_tournament;
                    this.user_tournament = tournament;
                    if (old_user_tournament !== this.user_tournament) {
                        this.TournamentCheckboxLogic(this.user_tournament);
                    }
                }
                this.m_rooms.UpdateRoom(this.props.m_pwin.m_main.GetLanguageText(room_name), tables, players, tournament_text, tournament);
                this.setState({});
            }
        }
    }

    UpdateRow(
        name: string,
        game: string,
        pi: string,
        stat: string,
        rating: string,
        watch: string,
        close_game: string,
        type,
        ousers: string,
        gameTypeFromServer: string
    ) {
        if (name !== '' && game === this.sel_online && type === this.sel_type) {
            if (this.sel_online === 'Poker Texas Hold em' || this.sel_online === 'Poker Omaha Hi') {
                let big_blind = this.props.m_pwin.m_main._ttof(rating);
                let MaxBuyIn = 0;
                let MinBuyIn = 0;
                if (big_blind === 0.02) {
                    MaxBuyIn = 4;
                }
                if (big_blind === 0.1) {
                    MaxBuyIn = 20;
                }
                if (big_blind === 1) {
                    MaxBuyIn = 200;
                }
                if (big_blind === 10) {
                    MaxBuyIn = 2000;
                }
                if (big_blind === 100) {
                    MaxBuyIn = 20000;
                }
                if (big_blind === 1000) {
                    MaxBuyIn = 200000;
                }
                MinBuyIn = MaxBuyIn / 2;
                rating =
                    this.props.m_pwin.m_main.FloatToString(MinBuyIn) +
                    '/' +
                    this.props.m_pwin.m_main.FloatToString(MaxBuyIn) +
                    ', ' +
                    this.props.m_pwin.m_main.FloatToString(big_blind);
            }
            if (this.sel_online === 'Svara') {
                let ante = this.props.m_pwin.m_main._ttof(rating);
                let limit = ante * 100;
                rating = this.props.m_pwin.m_main.FloatToString(limit) + ', ' + this.props.m_pwin.m_main.FloatToString(ante);
            }
            if (this.sel_online !== 'Poker Texas Hold em' && this.sel_online !== 'Poker Omaha Hi' && this.sel_online !== 'Svara') {
                if (rating.length < 2) {
                    rating = '0' + rating;
                }
            }
            this.UpdateList(name, game, pi, stat, rating, watch, close_game, type, ousers, gameTypeFromServer);
        }
        this.setState({});
    }

    UpdateList(
        name: string,
        game: string,
        pi: string,
        stat: string,
        rating: string,
        watch: string,
        close_game: string,
        type: string,
        ousers: string,
        gameTypeFromServer: string
    ) {
        let nIndex = -1;
        for (let i = 0; i < this.m_list.tableData.length; i++) {
            if (this.m_list.tableData[i][1] === name) {
                nIndex = i;
                break;
            }
        }
        if (nIndex !== -1) {
            let wi = this.props.m_pwin.m_main._ttoi(watch);
            let cgi = this.props.m_pwin.m_main._ttoi(close_game);
            if (
                close_game === '0' &&
                (pi === '2/2' ||
                    pi === '3/3' ||
                    pi === '4/4' ||
                    pi === '5/5' ||
                    pi === '6/6' ||
                    pi === '7/7' ||
                    pi === '8/8' ||
                    pi === '9/9' ||
                    pi === '10/10')
            ) {
                cgi = 2;
            }
            let ico_index = wi * 3 + cgi;
            if (stat === 'Play') {
                ico_index = ico_index + 6;
            }
            this.m_list.UpdateRow(
                nIndex,
                ico_index,
                name,
                pi,
                rating,
                ousers,
                this.TranslateGameTypeFromServer(gameTypeFromServer),
                stat,
                watch,
                close_game
            );
        }

        if (name === this.Select[0]) {
            this.Select[1] = pi;
            this.Select[2] = this.props.m_pwin.m_main.GetLanguageText(stat);
            this.Select[3] = rating;
            this.Select[4] = watch;
            this.Select[5] = close_game;
            this.Select[6] = ousers;
            this.Select[7] = gameTypeFromServer;
            let ggi = 'U|' + this.Select[0];
            this.props.m_pwin.SendGame(ggi); //??? Da proveria da li e nujno i dali samo rejtinga da ne smeniavam , ako e selektnat tozi user
            this.CheckWatchButton();
            this.CheckJoinButton();
        }
    }

    GetTotalPlayers(): number {
        if (this.Select[1] === undefined || this.sel_player === '') {
            return 0;
        }
        let players = this.Select[1].substring(0, this.Select[1].indexOf('/'));
        return this.props.m_pwin.m_main._ttoi(players);
    }

    GetUsername(player: number) {
        let username = '';
        if (this.props.m_pwin.state.DlgOnlineVisible) {
            if (player > 0) {
                let description: string = this.m_game_description;
                if (description !== '') {
                    let str_index = description.indexOf('>', 0);
                    while (str_index > 0) {
                        if (str_index > 0) {
                            description = description.substring(0, str_index - 1) + description.substring(str_index + 1, description.length);
                        }
                        str_index = description.indexOf('>', 0);
                    }
                    str_index = description.indexOf('/', 0);
                    if (str_index > 0) {
                        description = description.substring(0, str_index - 1);
                    }
                    description = description.split(',').join('');
                    description = description.split(' Computer').join('');
                    for (let i = 1; i <= player; i++) {
                        if (description !== '') {
                            let sp_index = description.indexOf(' ', 0);
                            if (sp_index >= 0) {
                                username = description.substring(0, sp_index);
                                username = username.split(' ').join('');
                                description = description.substring(sp_index + 1, description.length);
                            } else {
                                username = description;
                                this.show_player = i;
                            }
                        } else {
                            this.show_player = i;
                            return username;
                        }
                    }
                }
            }
        }
        return username;
    }

    SetPlayerInfo(
        playerUsername: string,
        name: string,
        city: string,
        rating: string,
        country: string,
        language: string,
        age: string,
        sex: string,
        description: string,
        image: string,
        os: string,
        vip: string,
        time: string,
        fcash: string
    ) {
        let username = this.GetUsername(this.show_player);
        if (username === playerUsername) {
            this.sel_player = this.tmp_sel_playr;
            this.m_static_pmoney = this.props.m_pwin.m_main.GetLanguageText('Fun Money') + ': ' + fcash;
            this.m_static_prating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ': ' + rating;
            this.m_static_country = this.props.m_pwin.m_main.GetLanguageText('Country') + ': ' + this.props.m_pwin.m_main.GetLanguageText(country);
            this.m_static_language =
                this.props.m_pwin.m_main.GetLanguageText('Language') + ' : ' + this.props.m_pwin.m_main.GetLanguageText(language);
            this.m_static_country = this.props.m_pwin.m_main.GetLanguageText('Country') + ': ' + this.props.m_pwin.m_main.GetLanguageText(country);
            this.flag_pic.image = '/files_1.0/flags/' + country + '.gif';
            this.flag_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('online_page') + '&wc=country=&wp=' + country;
            this.player_language_pic.image = '/files_1.0/online/language.gif';
            this.player_language_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('online_page') + '&wc=language=&wp=' + language;
            this.player_money_pic.image = '/files_1.0/online/money.gif';
            this.player_rating_pic.image = '/files_1.0/online/rating.gif';
            this.os_pic.image = '/files_1.0/online/os/' + os + '.png';
            this.os_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('online_page') + '&wc=game_number&wp=' + os;
            this.player_stat_pic.image = '/files_1.0/online/stat.gif';
            this.vip_pic.image = '/files_1.0/online/vip/' + vip + '.png';
            if (image !== '-') {
                this.player_pic.image = image;
            } else {
                this.player_pic.image = GlobalConstants.IMAGES_FOLDER + this.props.m_pwin.m_main.no_img[sex];
            }
            this.player_pic_no_image.image = GlobalConstants.IMAGES_FOLDER + this.props.m_pwin.m_main.no_img[sex];
            let player_time = this.props.m_pwin.m_main._ttoi(time);
            let d = player_time / 1440;
            let h = (player_time - d * 1440) / 60;
            let m = player_time % 60;
            let text = this.props.m_pwin.m_main.GetLanguageText('Time Left') + ': ';

            if (d >= 1) {
                text = text + d.toFixed(0) + ' ';
                if (d === 1) {
                    text = text + this.props.m_pwin.m_main.GetLanguageText('day');
                } else {
                    text = text + this.props.m_pwin.m_main.GetLanguageText('days');
                }
            }
            if (d > 0 && d < 100) {
                text = text + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ';
                text = text + h.toFixed(0) + ' ';
                text = text + this.props.m_pwin.m_main.GetLanguageText('h.');
            }
            if (d < 1) {
                text = text + this.props.m_pwin.m_main.IntToString(h) + ' ';
                text = text + this.props.m_pwin.m_main.GetLanguageText('h.');
                text = text + ' ' + this.props.m_pwin.m_main.GetLanguageText('and') + ' ';
                text = text + m.toFixed(0) + ' ';
                text = text + this.props.m_pwin.m_main.GetLanguageText('min.');
            }

            this.vip_pic.tooltip = text;
            this.player_stat_pic.m_url =
                this.props.m_pwin.m_main.GetLanguageText('results_page') + '&username=' + this.sel_player + '&game=' + this.sel_online; //Stat page
            this.player_rating_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('rating_page') + '&game=' + this.sel_online;

            let tool_tip = name;
            if (city !== '-') {
                tool_tip = tool_tip + ', ' + this.props.m_pwin.m_main.GetLanguageText('City') + '-' + city;
            }
            if (age === '0') {
                age = '-';
            }
            if (age !== '-') {
                tool_tip = tool_tip + ', ' + this.props.m_pwin.m_main.GetLanguageText('Age') + '-' + age;
            }
            let isex = this.props.m_pwin.m_main._ttoi(sex);
            if (isex === 1) {
                tool_tip = tool_tip + ', ' + this.props.m_pwin.m_main.GetLanguageText('Sex') + '-' + this.props.m_pwin.m_main.GetLanguageText('Man');
            }
            if (isex === 2) {
                tool_tip =
                    tool_tip + ', ' + this.props.m_pwin.m_main.GetLanguageText('Sex') + '-' + this.props.m_pwin.m_main.GetLanguageText('Woman');
            }
            if (description !== '-') {
                tool_tip = tool_tip + '. ' + description;
            }
            this.user_tooltip = tool_tip;
            this.flag_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('From which country the user has registered.');
            this.player_rating_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('Monthly rating ranking.');
            this.player_stat_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('Statistics from the played games.');
            this.os_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText(
                'This icon indicates from which operating system or browser the user is playing.'
            );
            this.player_money_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('How many chips are available to the user in the account.');
            this.player_language_pic.tooltip = this.props.m_pwin.m_main.GetLanguageText('What language the user has chosen in the game.');

            this.ShowGameInfo();
        }
        this.setState({});
    }

    ShowGameInfo() {}

    ChangeRoomTooltipAndIcon(tooltip: string, selected_room: string) {
        this.room_tooltip = tooltip;
        if (this.m_tournament) {
            this.tour_pic.image = '/files_1.0/online/Rooms/Tournaments.gif';
        } else {
            this.tour_pic.image = '/files_1.0/online/Rooms/' + selected_room + '.gif';
        }
    }

    async AddRoom(
        room_name: string,
        tournament: string,
        bet: string,
        access: string,
        description: string,
        chat_messages: string,
        emote_chat: string,
        enable_password: string,
        enable_watch: string,
        tables: string,
        players: string,
        game: string,
        tooltip: string,
        bet_sum: string
    ) {
        if (game === this.sel_online) {
            if (this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(room_name)) !== undefined) {
                this.UpdateRoom(room_name, tables, players, tournament, game);
            } else {
                let room: string[] = [];
                room = [];
                room[0] = this.props.m_pwin.m_main.GetLanguageText(room_name);
                room[1] = tables;
                room[2] = players;

                let tournament_text = this.props.m_pwin.m_main.GetLanguageText('No');
                if (tournament === 'OPTION') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Yes') + '/' + this.props.m_pwin.m_main.GetLanguageText('No');
                }
                if (tournament === 'REQUIRED') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Yes');
                }
                if (tournament === 'FINISHED') {
                    tournament_text = this.props.m_pwin.m_main.GetLanguageText('Played');
                }

                room[3] = tournament_text;
                room[4] = this.props.m_pwin.m_main.GetLanguageText(description);
                let bet_text = this.props.m_pwin.m_main.GetLanguageText('No');
                if (bet === 'OPTION') {
                    bet_text = this.props.m_pwin.m_main.GetLanguageText('Yes') + '/' + this.props.m_pwin.m_main.GetLanguageText('No');
                }
                if (bet === 'REQUIRED') {
                    bet_text = this.props.m_pwin.m_main.GetLanguageText('Yes');
                }
                room[5] = bet_text;
                room[6] = tournament;
                room[7] = access;
                room[8] = chat_messages;
                room[9] = emote_chat;
                room[10] = enable_password;
                room[11] = enable_watch;
                room[12] = tooltip;
                room[13] = bet_sum;

                if (this.sel_type === room_name) {
                    this.user_tournament = tournament;
                    this.TournamentCheckboxLogic(this.user_tournament);
                    this.ChangeRoomTooltipAndIcon(tooltip, this.sel_type);
                    this.user_enable_password = enable_password;
                    this.user_enable_watch = enable_watch;
                    this.user_chat_message = chat_messages;
                    this.user_emote_message = emote_chat;
                    this.m_static_rooms =
                        this.props.m_pwin.m_main.GetLanguageText('Room') + ' : ' + this.props.m_pwin.m_main.GetLanguageText(room_name);
                }
                this.m_rooms.AddRoom(room);
            }
        }
        this.setState({});
    }

    SetTableDescription(desc: string) {
        this.m_game_description = desc;

        if (desc !== '') {
            this.ShowPlayerInfo();
            this.CheckJoinButton();
        } else {
            this.HidePlayerInfo(true, false);
        }
        this.setState({});
    }

    ShowPlayerInfo() {
        let username = this.GetUsername(this.show_player);
        if (username !== 'Computer' && username !== '') {
            this.m_username = this.props.m_pwin.m_main.GetLanguageText('Username') + ' : ' + username;
            this.player_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('domain') + '/server/public/show_user.php?username=' + username;
            this.player_pic_no_image.m_url = this.props.m_pwin.m_main.GetLanguageText('domain') + '/server/public/show_user.php?username=' + username;
            this.tmp_sel_playr = username;
            let message = '*|B|' + username;
            this.props.m_pwin.SendGame(message);
        }
    }

    SendGameListen() {
        this.SetTableDescription('');
        this.Select[0] = '';
        this.m_list.DeleteAllItems();
        let ggi = 'V|' + this.sel_online + '|' + this.sel_type;
        this.props.m_pwin.SendGame(ggi);
        this.CheckJoinButton();
        this.CheckWatchButton();
        this.setState({});
    }

    RemoveFromList(username: string) {
        for (let i = 0; i < this.m_list.tableData.length; i++) {
            if (this.m_list.tableData[i][1] === username) {
                this.m_list.tableData.splice(i, 1);
                break;
            }
        }
        if (username === this.Select[0]) {
            this.SetTableDescription('');
            for (let i = 0; i < 8; i++) {
                this.Select[i] = '';
            }
            this.CheckJoinButton();
            this.CheckWatchButton();
        }
    }

    DeleteRow(username: string) {
        this.RemoveFromList(username);
        this.setState({});
    }

    AddRow(
        name: string,
        game: string,
        pi: string,
        stat: string,
        rating: string,
        watch: string,
        close_game: string,
        type: string,
        ousers: string,
        gameTypeFromServer: string
    ) {
        if (name !== '' && game === this.sel_online && type === this.sel_type) {
            if (this.sel_online === 'Poker Texas Hold em' || this.sel_online === 'Poker Omaha Hi') {
                let big_blind: number = this.props.m_pwin.m_main._ttof(rating);
                let MaxBuyIn: number = 0;
                let MinBuyIn: number = 0;
                if (big_blind === 0.02) {
                    MaxBuyIn = 4;
                }
                if (big_blind === 0.1) {
                    MaxBuyIn = 20;
                }
                if (big_blind === 1) {
                    MaxBuyIn = 200;
                }
                if (big_blind === 10) {
                    MaxBuyIn = 2000;
                }
                if (big_blind === 100) {
                    MaxBuyIn = 20000;
                }
                if (big_blind === 1000) {
                    MaxBuyIn = 200000;
                }
                MinBuyIn = MaxBuyIn / 2;
                rating =
                    this.props.m_pwin.m_main.FloatToString(MinBuyIn) +
                    '/' +
                    this.props.m_pwin.m_main.FloatToString(MaxBuyIn) +
                    ', ' +
                    this.props.m_pwin.m_main.FloatToString(big_blind);
            }
            if (this.sel_online === 'Svara') {
                let ante: number = this.props.m_pwin.m_main._ttof(rating);
                let limit: number = ante * 100;
                rating = this.props.m_pwin.m_main.FloatToString(limit) + ', ' + this.props.m_pwin.m_main.FloatToString(ante);
            }
            if (this.sel_online !== 'Poker Texas Hold em' && this.sel_online !== 'Poker Omaha Hi' && this.sel_online !== 'Svara') {
                if (rating.length < 2) {
                    rating = '0' + rating;
                }
            }

            this.AddToList(name, game, pi, stat, rating, watch, close_game, type, ousers, gameTypeFromServer);
        }
        this.setState({});
    }

    OnClickList1(rowData: any) {
        let old_select = this.Select[0];
        if (rowData !== undefined) {
            this.Select[0] = rowData[1];
            this.SetSelectParameters(rowData);
        } else {
            for (let i = 0; i < 8; i++) {
                this.Select[i] = '';
            }
        }
        if (this.Select[0] !== '') {
            if (old_select !== this.Select[0]) {
                this.m_game_description = '';
                this.HidePlayerInfo(false, false);
                this.show_player = 1;
                let ggi = 'U|' + this.Select[0];
                this.props.m_pwin.SendGame(ggi);
            }
        } else {
            this.show_player = 0;
            this.SetTableDescription('');
        }
        this.CheckWatchButton();
        this.CheckJoinButton();
        this.setState({});
    }

    HidePlayerInfo(full: boolean, init: boolean) {
        if (full) {
            this.sel_player = '';
        } else {
            this.m_username = this.props.m_pwin.m_main.GetLanguageText('Username') + ' : ';
            this.m_static_prating = this.props.m_pwin.m_main.GetLanguageText('Fun Money') + ':';
            this.m_static_prating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ' : ';
            this.m_static_country = this.props.m_pwin.m_main.GetLanguageText('Country') + ' : ';
            this.m_static_language = this.props.m_pwin.m_main.GetLanguageText('Language') + ' : ';
        }
        if (!init) {
            this.user_tooltip = '';
            this.player_pic.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';
            this.player_pic_no_image.image = GlobalConstants.IMAGES_FOLDER + '/files_1.0/no_img0.gif';
            this.setState({});
        }
    }

    SetSelectParameters(rowData: any) {
        this.Select[1] = rowData[2];
        this.Select[2] = rowData[6];
        this.Select[3] = rowData[3];
        this.Select[4] = rowData[7];
        this.Select[5] = rowData[8];
        this.Select[6] = rowData[4];
        this.Select[7] = rowData[5];
    }

    OnClickRooms(nSelected: number) {
        if (nSelected >= 0) {
            let new_sel_type = this.m_rooms.GetItemText(nSelected, 0);
            this.user_tournament = this.m_rooms.GetItemText(nSelected, 6);
            this.user_chat_message = this.m_rooms.GetItemText(nSelected, 8);
            this.user_emote_message = this.m_rooms.GetItemText(nSelected, 9);
            this.user_enable_password = this.m_rooms.GetItemText(nSelected, 10);
            this.user_enable_watch = this.m_rooms.GetItemText(nSelected, 11);
            let tooltip = this.m_rooms.GetItemText(nSelected, 12);
            this.m_static_rooms = this.props.m_pwin.m_main.GetLanguageText(new_sel_type);
            if (new_sel_type === this.props.m_pwin.m_main.GetLanguageText('General Room')) {
                new_sel_type = 'General Room';
            }
            if (new_sel_type === this.props.m_pwin.m_main.GetLanguageText('Free Room')) {
                new_sel_type = 'Free Room';
            }
            if (new_sel_type === this.props.m_pwin.m_main.GetLanguageText('Vip Room')) {
                new_sel_type = 'Vip Room';
            }
            if (new_sel_type === this.props.m_pwin.m_main.GetLanguageText('Tournaments')) {
                new_sel_type = 'Tournaments';
            }
            if (this.sel_type !== new_sel_type) {
                this.tour_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('tournaments_online');
                this.sel_type = new_sel_type;
                this.TournamentCheckboxLogic(this.user_tournament);
                this.ChangeRoomTooltipAndIcon(tooltip, this.sel_type);
                this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_type', this.sel_type);
                this.SendGameListen();
                this.CheckCreateButton();
                this.CheckJoinButton();
                this.CheckWatchButton();
                if (!this.isEnablePassword()) {
                    this.m_table_password = '';
                }
                if (!this.isEnableWatch()) {
                    this.m_eye = 1;
                }
            }
        }
        this.setState({});
    }

    isEnableWatch() {
        if (this.GetEnableWatchForSelectedRoom() === 'NOVIP') {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                return false;
            }
        }
        if (this.GetEnableWatchForSelectedRoom() === 'VIP') {
            if (
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())
            ) {
                return false;
            }
        }
        return true;
    }

    isEnablePassword() {
        if (this.GetEnablePasswordForSelectedRoom() === 'NOVIP') {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                return false;
            }
        }
        if (this.GetEnablePasswordForSelectedRoom() === 'VIP') {
            if (
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())
            ) {
                return false;
            }
        }
        return true;
    }

    GetEnableWatchForSelectedRoom() {
        if (this.sel_type !== '') {
            let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
            if (room !== undefined) {
                return room[11];
            }
        }
        return 'GUEST';
    }

    GetEnablePasswordForSelectedRoom() {
        if (this.sel_type !== '') {
            let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
            if (room !== undefined) {
                return room[10];
            }
        }
        return 'GUEST';
    }

    AddToList(
        name: string,
        game: string,
        pi: string,
        stat: string,
        rating: string,
        watch: string,
        close_game: string,
        type: string,
        ousers: string,
        gameTypeFromServer: string
    ) {
        let repeat: boolean = false;
        for (let i = 0; i < this.m_list.tableData.length; i++) {
            if (this.m_list.tableData[i][1] === name) {
                repeat = true;
            }
        }
        if (name !== '' && game === this.sel_online && type === this.sel_type) {
            if (!repeat) {
                if (this.auto_connect !== '' && name === this.auto_connect) {
                    this.Select[0] = name;
                    this.Select[1] = pi;
                    this.Select[2] = this.props.m_pwin.m_main.GetLanguageText(stat);
                    this.Select[3] = rating;
                    this.Select[4] = watch;
                    this.Select[5] = close_game;
                    this.Select[6] = ousers;
                    this.Select[7] = gameTypeFromServer;
                    this.OnConnectToGame();
                    this.auto_connect = '';
                    return;
                }
                let wi = this.props.m_pwin.m_main._ttoi(watch);
                let cgi = this.props.m_pwin.m_main._ttoi(close_game);
                if (
                    close_game === '0' &&
                    (pi === '2/2' ||
                        pi === '3/3' ||
                        pi === '4/4' ||
                        pi === '5/5' ||
                        pi === '6/6' ||
                        pi === '7/7' ||
                        pi === '8/8' ||
                        pi === '9/9' ||
                        pi === '10/10')
                ) {
                    cgi = 2;
                }
                let ico_index = wi * 3 + cgi;
                if (stat === 'Play') {
                    ico_index = ico_index + 6;
                }
                this.m_list.InsertItem(
                    ico_index,
                    name,
                    pi,
                    rating,
                    ousers,
                    this.TranslateGameTypeFromServer(gameTypeFromServer),
                    stat,
                    watch,
                    close_game
                );
            }
        }
        if (this.Select[0] !== '') {
            let nIndex = -1;
            for (let i = 0; i < this.m_list.tableData.length; i++) {
                if (this.m_list.GetItemText(i, 1) === this.Select[0]) {
                    nIndex = i;
                    break;
                }
            }
            if (nIndex !== -1) {
            } else {
                for (let i = 0; i < 8; i++) {
                    this.Select[i] = '';
                }
                this.CheckJoinButton();
                this.CheckWatchButton();
            }
        }
    }
    TranslateGameTypeFromServer(gameTypeFromServer: string) {
        if (gameTypeFromServer === '9') {
            return '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round');
        }
        if (gameTypeFromServer === '15') {
            return '15 ' + this.props.m_pwin.m_main.GetLanguageText('Round');
        }
        if (gameTypeFromServer === '21') {
            return this.props.m_pwin.m_main.GetLanguageText('To 21 Points');
        }
        return gameTypeFromServer;
    }

    SetTournamentParameters(tname: string, r_price: string, free, players, rank) {
        if (tname !== '' && tname !== '-') {
            this.tournament_name = tname;
            this.restart_price = this.props.m_pwin.m_main._ttof(r_price);
            this.tournament_free = true;
            if (free === '0') {
                this.tournament_free = false;
            }
            this.m_static_tournament = this.props.m_pwin.m_main.GetLanguageText('Participate in tournament');
            if (GlobalVariables.WindowWidth > GlobalConstants.SHOW_TOURNAMENT_NAME_MIN_WIDTH) {
                this.m_static_tournament = this.props.m_pwin.m_main.GetLanguageText('Participate in tournament') + ': ' + tname;
            }
            this.personal_tournament_link =
                this.props.m_pwin.m_main.GetLanguageText('domain') +
                '/server/public/tournament.php?tournament_name=' +
                tname +
                '&game=' +
                this.sel_online;
            if (this.m_tournament) {
                this.tour_pic.m_url = this.personal_tournament_link;
            } else {
                this.tour_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('tournaments_online');
            }
            this.TournamentCheckboxLogic(this.GetTournamentForSelectedRoom());
            this.m_static_tournament_rank = '#' + rank + '/' + players;
            let tooltipText: string = this.props.m_pwin.m_main.GetLanguageText('Your ranking in the tournament is (r) place by (p) participants.');
            if (rank === '-') {
                tooltipText = this.props.m_pwin.m_main.GetLanguageText('(p) users have participated in this tournament so far.');
            }
            tooltipText = tooltipText.replace('(r)', rank);
            tooltipText = tooltipText.replace('(p)', players);

            this.tournament_rank_tooltip = tooltipText;
        } else {
            this.tournament_name = '';
            this.restart_price = 0;
            this.tournament_free = true;
        }
        this.ChangeRoomTooltipAndIcon(this.GetTooltipForSelectedRoom(), this.sel_type);
        this.setState({});
    }

    ShowUserTournamentResultsPic(): boolean {
        if (this.tournament_name !== '' && this.GetTournamentForSelectedRoom() !== 'NONE') {
            return true;
        }
        return false;
    }

    TournamentCheckboxLogic(tournament: string) {
        if (tournament === 'REQUIRED') {
            this.m_tournament = true;
            this.enable_tournament_checkbox = false;
            this.show_tournament_checkbox = true;
        }
        if (tournament === 'OPTION') {
            this.m_tournament = true;
            this.enable_tournament_checkbox = true;
            this.show_tournament_checkbox = true;
        }
        if (tournament === 'NONE') {
            this.m_tournament = false;
            this.enable_tournament_checkbox = false;
            this.show_tournament_checkbox = false;

            if (this.sel_online === 'Poker Texas Hold em' || this.sel_online === 'Poker Omaha Hi' || this.sel_online === 'Svara') {
                let cmoney = this.props.m_pwin.m_main.FloatToString(this.tournament_money);
                this.money = this.tournament_money;
                if (this.m_tournament === false) {
                    cmoney = this.props.m_pwin.m_main.FloatToString(this.db_money);
                    this.money = this.db_money;
                }
                this.m_static_money = this.props.m_pwin.m_main.GetLanguageText('Fun Money') + ': ' + cmoney;
                this.m_static_rating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ': ' + cmoney;
            }
        }
        if (tournament === 'FINISHED') {
            this.m_tournament = false;
            this.enable_tournament_checkbox = true;
            this.show_tournament_checkbox = true;
        }
        if (
            !this.tournament_free &&
            (this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()))
        ) {
            this.m_tournament = false;
            this.enable_tournament_checkbox = true;
            this.show_tournament_checkbox = true;
        }
        if (tournament !== 'NONE' && this.tournament_free && this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            this.m_tournament = false;
            this.enable_tournament_checkbox = true;
            this.show_tournament_checkbox = true;
        }

        if (
            this.tournament_name !== '' &&
            tournament !== 'NONE' &&
            (this.tournament_free ||
                (!this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername()) &&
                    !this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())))
        ) {
            this.restart_tournament_tooltip = this.props.m_pwin.m_main.GetLanguageText(
                'From here you can restart your participation in the tournament.'
            );
        } else {
            this.restart_tournament_tooltip = '';
        }

        if (tournament === 'NONE') {
            this.tour_results_tooltip = '';
        } else {
            this.tour_results_tooltip = this.props.m_pwin.m_main.GetLanguageText('Results of your participation in the tournament.');
        }
        this.setState({});
    }

    SetRating(rating: string) {
        this.m_static_rating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ' : ' + rating;
        this.p1_rating = this.props.m_pwin.m_main._ttoi(rating);
        this.setState({});
    }
    SetMoney(tournament_chips: string, db_chips: string) {
        this.m_static_money = this.props.m_pwin.m_main.GetLanguageText('Fun Money') + ': ' + tournament_chips;
        this.db_money = this.props.m_pwin.m_main._ttof(db_chips);
        this.tournament_money = this.props.m_pwin.m_main._ttof(tournament_chips);
        this.money = this.tournament_money;
        if (this.sel_online === 'Poker Texas Hold em' || this.sel_online === 'Poker Omaha Hi' || this.sel_online === 'Svara') {
            this.m_static_rating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ': ' + db_chips;
        }
        this.setState({});
    }

    OnSelchangeFilterGame(game: string) {
        if (this.sel_online === game) {
            return;
        }
        this.sel_online = game;
        this.m_list.InitTable();
        this.props.m_pwin.SendGame('E|' + this.sel_online);
        if (game === 'Belote 4 Players') {
            this.select_game = 0;
        }
        if (game === 'Belote 3 Players') {
            this.select_game = 1;
        }
        if (game === 'Belote 2 Players') {
            this.select_game = 2;
        }
        if (game === 'Schnapsen (66)') {
            this.select_game = 3;
        }
        if (game === 'Backgammon') {
            this.select_game = 4;
        }
        if (game === 'Backgammon - Tapa') {
            this.select_game = 5;
        }
        if (game === 'Backgammon - Gulbara') {
            this.select_game = 6;
        }
        if (game === 'Domino 4 Players') {
            this.select_game = 7;
        }
        if (game === 'Domino 3 Players') {
            this.select_game = 8;
        }
        if (game === 'Domino 2 Players') {
            this.select_game = 9;
        }
        if (game === 'Bridge Rubber') {
            this.select_game = 10;
        }
        if (game === 'Duplicate Bridge') {
            this.select_game = 11;
        }
        if (game === 'OKEY') {
            this.select_game = 12;
        }
        if (game === 'Ludo') {
            this.select_game = 13;
        }
        if (game === 'Sergeant Major (3-5-8)') {
            this.select_game = 14;
        }
        if (game === 'Chess') {
            this.select_game = 15;
        }
        if (game === 'Poker Texas Hold em' || 'Poker Omaha Hi') {
            if (game === 'Poker Texas Hold em') {
                this.select_game = 16;
            }
            if (game === 'Poker Omaha Hi') {
                this.select_game = 20;
            }
        }
        if (game === 'Backgammon 3 in 1') {
            this.select_game = 17;
        }
        if (game === 'Monopoly') {
            this.select_game = 18;
        }
        if (game === 'Svara') {
            this.select_game = 19;
        }
        this.InitPlayersCombo(game);
        this.rating_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('rating_page') + '&game=' + game;
        this.user_stat_pic.m_url =
            this.props.m_pwin.m_main.GetLanguageText('results_page') + '&username=' + this.props.m_pwin.m_con.getUsername() + '&game=' + game;
        this.props.m_pwin.m_main.WriteProfileString('Options', 'sel_online', game);
        this.m_list.DeleteAllItems();
        this.m_rooms.DeleteAllItems();
        this.SendGameListen();
        this.m_static_rooms = this.props.m_pwin.m_main.GetLanguageText('Rooms') + ' / ' + this.props.m_pwin.m_main.GetLanguageText('Select a Room');
        this.CheckWatchButton();
        this.CheckCreateButton();
        this.CheckJoinButton();
    }
    CheckJoinButton() {
        let res: boolean = false;
        if (this.Select[0] !== '') {
            let pi = this.Select[1];
            if (
                pi !== '2/2' &&
                pi !== '3/3' &&
                pi !== '4/4' &&
                pi !== '5/5' &&
                pi !== '6/6' &&
                pi !== '7/7' &&
                pi !== '8/8' &&
                pi !== '9/9' &&
                pi !== '10/10'
            ) {
                res = true;
            } else {
                res = false;
            }
            if (res) {
                if (this.m_table_password === '' && this.Select[5] === '1') {
                    res = false;
                }
            }
        }
        return res;
    }
    CheckCreateButton() {
        return true;
    }

    OnButtonPrev() {
        this.HidePlayerInfo(false, false);
        if (this.show_player > 1) {
            this.show_player = this.show_player - 1;
        }
        this.ShowPlayerInfo();
    }

    OnButtonNext() {
        this.HidePlayerInfo(false, false);
        this.show_player = this.show_player + 1;
        this.ShowPlayerInfo();
    }

    CheckWatchButton() {
        let res: boolean = false;
        if (this.Select[0] !== '') {
            if (this.Select[4] === '1') {
                res = true;
            }
            if (this.m_table_password === '' && this.Select[5] === '1') {
                res = false;
            }
        }
        return res;
    }

    GetAccessForSelectedRoom() {
        if (this.sel_type !== '') {
            let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
            if (room !== undefined) {
                return room[7];
            }
        }
        return 'GUEST';
    }

    EnableChatMessagesForSelectedRoom() {
        if (this.sel_type !== '') {
            let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
            if (room !== undefined) {
                return room[8];
            }
        }
        return 'GUEST';
    }

    async OnValidateAccess() {
        if (this.GetAccessForSelectedRoom() === 'NOVIP') {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('To play in this room you must log in with your username.');
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                return false;
            }
        }
        if (this.GetAccessForSelectedRoom() === 'VIP') {
            if (
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())
            ) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('To play in this room you must be a VIP user.');
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
                }
                return false;
            }
        }
        return true;
    }

    CheckSymbols(s: string) {
        return this.props.m_pwin.m_main.CheckUsernameAndPassword(s);
    }

    OnBelot4Players() {
        this.Game = 'B4';
        this.props.m_pwin.CloseDlgOnline();
    }

    OnNSSC() {
        this.Game = 'Ludo';
        this.props.m_pwin.CloseDlgOnline();
    }

    OnBelot3Players() {
        this.Game = 'B3';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBelot2Players() {
        this.Game = 'B2';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnSantase() {
        this.Game = 'S';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBackgammon() {
        this.Game = 'Ba1';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBackgammonTapa() {
        this.Game = 'Ba2';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBackgammonGulbara() {
        this.Game = 'Ba3';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnDomino4Players() {
        this.Game = '';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnDomino3Players() {
        this.Game = 'Do4';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnDomino2Players() {
        this.Game = 'Do2';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBridgeRubber() {
        this.Game = 'Br1';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnBridgeImp() {
        this.Game = 'Br2';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnOKEY() {
        this.Game = 'OKEY';
        this.props.m_pwin.CloseDlgOnline();
    }
    async On358() {
        this.Game = '358';
        this.GameType = await this.props.m_pwin.m_main.GetProfileInt('Options', 'blato_type', 15);
        this.props.m_pwin.CloseDlgOnline();
    }
    OnChess() {
        this.Game = 'Chess';
        this.props.m_pwin.CloseDlgOnline();
    }
    async OnSvara() {
        if (this.money >= 2) {
            this.Game = 'Svara';
            let strp = this.m_game_type.substring(this.m_game_type.length - this.m_game_type.indexOf('/', 0), this.m_game_type.length);
            this.Players = this.props.m_pwin.m_main._ttoi(strp);
            this.props.m_pwin.CloseDlgOnline();
        } else {
            await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
        }
    }
    async OnOmahaPoker() {
        if (this.money >= 2) {
            this.Game = 'Poker Omaha Hi';
            let strp = this.m_game_type.substring(this.m_game_type.length - this.m_game_type.indexOf('/', 0), this.m_game_type.length);
            this.Players = this.props.m_pwin.m_main._ttoi(strp);
            this.props.m_pwin.CloseDlgOnline();
        } else {
            await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
        }
    }

    async OnTexasPoker() {
        if (this.money >= 2) {
            this.Game = 'Poker Texas Hold em';
            let strp = this.m_game_type.substring(this.m_game_type.length - this.m_game_type.indexOf('/', 0), this.m_game_type.length);
            this.Players = this.props.m_pwin.m_main._ttoi(strp);
            this.props.m_pwin.CloseDlgOnline();
        } else {
            await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
        }
    }
    OnBackgammon3in1() {
        this.Game = 'Ba31';
        this.props.m_pwin.CloseDlgOnline();
    }
    OnMonopoly() {
        this.Game = 'Monopoly';
        this.props.m_pwin.CloseDlgOnline();
    }

    async MissingGame() {
        if (Platform.OS !== 'ios') {
            let message = t.t('This game is not available for (os) at this moment. Do you want to install another version?', { os: Platform.OS });
            this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
            if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
                this.openPage(t.t('downloads_online'), 'Favorite-Games.com');
            }
        }
    }

    async OnGameCreate() {
        if (this.sel_type === '') {
            this.sel_type = 'General Room';
        }
        if (!(await this.OnValidateAccess())) {
            return;
        }
        if (!this.CheckSymbols(this.m_table_password)) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the password') +
                        '. ' +
                        this.props.m_pwin.m_main.GetLanguageText(
                            'The password can be up to 10 characters. May contain only the following characters: A-Z, a-z, 0-9, -, _'
                        )
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.m_table_password = '';
            return;
        }
        if (this.CheckCreateButton()) {
            if (this.select_game === 0) {
                if (this.props.m_pwin.m_main.isGameEnable('Belot4')) {
                    this.OnBelot4Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 1) {
                if (this.props.m_pwin.m_main.isGameEnable('Belot3')) {
                    this.OnBelot3Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 2) {
                if (this.props.m_pwin.m_main.isGameEnable('Belot2')) {
                    this.OnBelot2Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 3) {
                if (this.props.m_pwin.m_main.isGameEnable('Santase')) {
                    this.OnSantase();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 4) {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.OnBackgammon();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 5) {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.OnBackgammonTapa();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 6) {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.OnBackgammonGulbara();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 7) {
                if (this.props.m_pwin.m_main.isGameEnable('Domino4')) {
                    this.OnDomino4Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 8) {
                if (this.props.m_pwin.m_main.isGameEnable('Domino3')) {
                    this.OnDomino3Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 9) {
                if (this.props.m_pwin.m_main.isGameEnable('Domino2')) {
                    this.OnDomino2Players();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 10) {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge')) {
                    this.OnBridgeRubber();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 11) {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge') && this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge')) {
                    this.OnBridgeImp();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 12) {
                if (this.props.m_pwin.m_main.isGameEnable('OKEY')) {
                    this.OnOKEY();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 13) {
                if (this.props.m_pwin.m_main.isGameEnable('NSSC')) {
                    this.OnNSSC();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 14) {
                if (this.props.m_pwin.m_main.isGameEnable('Blato')) {
                    this.On358();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 15) {
                this.OnChess();
            }
            if (this.select_game === 16) {
                if (this.props.m_pwin.m_main.isGameEnable('Poker')) {
                    await this.OnTexasPoker();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 17) {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.OnBackgammon3in1();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 18) {
                if (this.props.m_pwin.m_main.isGameEnable('Monopoly')) {
                    this.OnMonopoly();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 19) {
                if (this.props.m_pwin.m_main.isGameEnable('Svara')) {
                    this.OnSvara();
                } else {
                    this.MissingGame();
                }
            }
            if (this.select_game === 20) {
                if (this.props.m_pwin.m_main.isGameEnable('PokerOmaha')) {
                    this.OnOmahaPoker();
                } else {
                    this.MissingGame();
                }
            }
        }
    }

    async OnGameWatch() {
        if (!(await this.OnValidateAccess())) {
            return;
        }
        if (!this.CheckSymbols(this.m_table_password)) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the password') +
                        '. ' +
                        this.props.m_pwin.m_main.GetLanguageText(
                            'The password can be up to 10 characters. May contain only the following characters: A-Z, a-z, 0-9, -, _'
                        )
                );
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.m_table_password = '';
            return;
        }
        if (this.Select[0] !== '') {
            if (this.Select[5] === '0') {
                this.m_table_password = '';
            }
            if (this.sel_online === 'Belote 4 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot4')) {
                    this.Game = 'WB4';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Belote 3 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot3')) {
                    this.Game = 'WB3';
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Belote 2 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot2')) {
                    this.Game = 'WB2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Schnapsen (66)') {
                if (this.props.m_pwin.m_main.isGameEnable('Santase')) {
                    this.Game = 'WS';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'WBa1';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon - Tapa') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'WBa2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon - Gulbara') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'WBa3';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon 3 in 1') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'WBa31';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 2 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino2')) {
                    this.Game = 'WDo2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 3 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino3')) {
                    this.Game = 'WDo3';
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 4 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino4')) {
                    this.Game = 'WDo4';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Bridge Rubber') {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge')) {
                    this.Game = 'WBr1';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Duplicate Bridge') {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge') && this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge')) {
                    this.Game = 'WBr2';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Ludo') {
                if (this.props.m_pwin.m_main.isGameEnable('NSSC')) {
                    this.Game = 'WLudo';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Monopoly') {
                if (this.props.m_pwin.m_main.isGameEnable('Monopoly')) {
                    this.Game = 'WMonopoly';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'OKEY') {
                if (this.props.m_pwin.m_main.isGameEnable('OKEY')) {
                    this.Game = 'WOKEY';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Sergeant Major (3-5-8)') {
                if (this.props.m_pwin.m_main.isGameEnable('Blato')) {
                    this.Game = 'W358';
                    this.GameType = 15;
                    let selGameType = this.Select[7];
                    if (selGameType === '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round')) {
                        this.GameType = 9;
                    }
                    if (selGameType === this.props.m_pwin.m_main.GetLanguageText('To 21 Points')) {
                        this.GameType = 21;
                    }
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }

            if (this.sel_online === 'Poker Texas Hold em') {
                if (this.props.m_pwin.m_main.isGameEnable('Poker')) {
                    this.Game = 'WPT';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strb.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Poker Omaha Hi') {
                if (this.props.m_pwin.m_main.isGameEnable('PokerOmaha')) {
                    this.Game = 'WPO';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strb.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Svara') {
                if (this.props.m_pwin.m_main.isGameEnable('Svara')) {
                    this.Game = 'WSV';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strb.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Chess') {
                this.Game = 'WChess';
                this.Players = 2;
            }

            if (
                this.Game === 'WBa1' ||
                this.Game === 'WBa2' ||
                this.Game === 'WBa3' ||
                this.Game === 'WBa31' ||
                this.Game === 'WDo2' ||
                this.Game === 'WS' ||
                this.Game === 'WB4' ||
                this.Game === 'WB3' ||
                this.Game === 'WB2' ||
                this.Game === 'WBr1' ||
                this.Game === 'WBr2' ||
                this.Game === 'WOKEY' ||
                this.Game === 'WLudo' ||
                this.Game === 'W358' ||
                this.Game === 'WChess' ||
                this.Game === 'WPT' ||
                this.Game === 'WMonopoly' ||
                this.Game === 'WPO' ||
                this.Game === 'WSV'
            ) {
                this.props.m_pwin.CloseDlgOnline();
            }
        } else {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please select a game');
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                return;
            }
        }
        this.props.m_pwin.showAd();
    }

    async OnConnectToGame() {
        if (!(await this.OnValidateAccess())) {
            return;
        }
        if (!this.CheckSymbols(this.m_table_password)) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the password') +
                        '. ' +
                        this.props.m_pwin.m_main.GetLanguageText(
                            'The password can be up to 10 characters. May contain only the following characters: A-Z, a-z, 0-9, -, _'
                        )
                );
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.m_table_password = '';
            return;
        }
        if (this.Select[0] !== '') {
            if (this.Select[4] === '1') {
                this.m_eye = 1;
            } else {
                this.m_eye = 0;
            }
            if (this.Select[5] === '0') {
                this.m_table_password = '';
            }
            if (this.sel_online === 'Belote 4 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot4')) {
                    this.Game = 'CB4';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Belote 3 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot3')) {
                    this.Game = 'CB3';
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Belote 2 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Belot2')) {
                    this.Game = 'CB2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Schnapsen (66)') {
                if (this.props.m_pwin.m_main.isGameEnable('Santase')) {
                    this.Game = 'CS';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'CBa1';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }

            if (this.sel_online === 'Backgammon - Tapa') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'CBa2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon - Gulbara') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'CBa3';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Backgammon 3 in 1') {
                if (this.props.m_pwin.m_main.isGameEnable('Tabla')) {
                    this.Game = 'CBa31';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 2 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino2')) {
                    this.Game = 'CDo2';
                    this.Players = 2;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 3 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino3')) {
                    this.Game = 'CDo3';
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Domino 4 Players') {
                if (this.props.m_pwin.m_main.isGameEnable('Domino4')) {
                    this.Game = 'CDo4';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Bridge Rubber') {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge')) {
                    this.Game = 'CBr1';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Duplicate Bridge') {
                if (this.props.m_pwin.m_main.isGameEnable('Bridge') && this.props.m_pwin.m_main.isGameEnable('Duplicate Bridge')) {
                    this.Game = 'CBr2';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'OKEY') {
                if (this.props.m_pwin.m_main.isGameEnable('OKEY')) {
                    this.Game = 'COKEY';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Ludo') {
                if (this.props.m_pwin.m_main.isGameEnable('NSSC')) {
                    this.Game = 'CLudo';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Monopoly') {
                if (this.props.m_pwin.m_main.isGameEnable('Monopoly')) {
                    this.Game = 'CMonopoly';
                    this.Players = 4;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Sergeant Major (3-5-8)') {
                if (this.props.m_pwin.m_main.isGameEnable('Blato')) {
                    this.Game = 'C358';
                    this.GameType = 15;
                    let selGameType = this.Select[7];
                    if (selGameType === '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round')) {
                        this.GameType = 9;
                    }
                    if (selGameType === this.props.m_pwin.m_main.GetLanguageText('To 21 Points')) {
                        this.GameType = 21;
                    }
                    this.Players = 3;
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Poker Texas Hold em') {
                if (this.props.m_pwin.m_main.isGameEnable('Poker')) {
                    this.Game = 'CPT';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strb.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Poker Omaha Hi') {
                if (this.props.m_pwin.m_main.isGameEnable('PokerOmaha')) {
                    this.Game = 'CPO';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strp.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            if (this.sel_online === 'Svara') {
                if (this.props.m_pwin.m_main.isGameEnable('Svara')) {
                    this.Game = 'CSV';
                    let strp = this.Select[1];
                    strp = strp.substring(strp.length - strp.indexOf('/', 0) - 1, strp.length);
                    this.Players = this.props.m_pwin.m_main._ttoi(strp);
                    let strb = this.Select[3];
                    strb = strb.substring(strb.length - strb.indexOf(', ', 0) - 1, strb.length);
                    this.sblind = this.props.m_pwin.m_main._ttof(strb);
                } else {
                    this.MissingGame();
                    return;
                }
            }
            this.props.m_pwin.CloseDlgOnline();
        } else {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle('Please select a game');
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
        }
    }

    async OnClickWatchValidation() {
        if (this.GetEnableWatchForSelectedRoom() === 'NOVIP') {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        'To disable or enable watching of your table in this room must log in with your username.'
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                this.m_eye = 1;
                this.setState({});
                return false;
            }
        }
        if (this.GetEnableWatchForSelectedRoom() === 'VIP') {
            if (
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())
            ) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        'To disable or enable watching of your table in this room must be a VIP user.'
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
                }
                this.m_eye = 1;
                this.setState({});
                return false;
            }
        }
        return true;
    }

    async OnChangeTablePassword(password: string) {
        this.m_table_password = password;
        if (!this.CheckSymbols(this.m_table_password)) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('Unacceptable characters in the password') +
                        '. ' +
                        this.props.m_pwin.m_main.GetLanguageText(
                            'The password can be up to 10 characters. May contain only the following characters: A-Z, a-z, 0-9, -, _'
                        )
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.m_table_password = '';
            this.setState({});
            return;
        }
        if (this.GetEnablePasswordForSelectedRoom() === 'NOVIP') {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('In order to set a password in this room you must log in with your username.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                }
                this.m_table_password = '';
                this.setState({});
                return;
            }
        }
        if (this.GetEnablePasswordForSelectedRoom() === 'VIP') {
            if (
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())
            ) {
                if (!this.props.m_pwin.haveOpenMessages()) {
                    this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                        this.props.m_pwin.m_main.GetLanguageText('You must be a VIP user to set a password in this room.')
                    );
                    await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
                }
                this.m_table_password = '';
                this.setState({});
                return;
            }
        }

        this.CheckJoinButton();
        this.CheckWatchButton();
        this.setState({});
    }

    OnCloseEye() {
        this.m_eye = 0;
        this.setState({});
        if (this.OnClickWatchValidation()) {
            this.props.m_pwin.m_main.WriteProfileString('Options', 'eye', '0');
        }
    }

    OnOpenEye() {
        this.m_eye = 1;
        this.setState({});
        if (this.OnClickWatchValidation()) {
            this.props.m_pwin.m_main.WriteProfileString('Options', 'eye', '1');
        }
    }

    async OnStaticTournament() {
        this.m_tournament = !this.m_tournament;
        await this.CheckTournamentLogic();
        this.setState({});
    }

    async CheckTournamentLogic() {
        if (this.GetTournamentForSelectedRoom() === 'FINISHED') {
            this.RestartTournament();
            this.m_tournament = false;
            return;
        } else if (
            !this.tournament_free &&
            (this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername()) ||
                this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()))
        ) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('This tournament is for VIP users only.')
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
            }
            this.m_tournament = false;
            return;
        } else if (this.tournament_free && this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('To participate in tournaments you must be registered.')
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            this.m_tournament = false;
            return;
        }

        if (this.sel_online === 'Poker Texas Hold em' || this.sel_online === 'Poker Omaha Hi' || this.sel_online === 'Svara') {
            let cmoney = this.props.m_pwin.m_main.FloatToString(this.tournament_money);
            this.money = this.tournament_money;
            this.tour_pic.m_url = this.personal_tournament_link;
            if (this.m_tournament === false) {
                cmoney = this.props.m_pwin.m_main.FloatToString(this.db_money);
                this.money = this.db_money;
                this.tour_pic.m_url = this.props.m_pwin.m_main.GetLanguageText('tournaments_online');
            }
            this.m_static_money = this.props.m_pwin.m_main.GetLanguageText('Fun Money') + ': ' + cmoney;
            this.m_static_rating = this.props.m_pwin.m_main.GetLanguageText('Rating') + ': ' + this.props.m_pwin.m_main.FloatToString(this.db_money);
        }
        this.ChangeRoomTooltipAndIcon(this.GetTooltipForSelectedRoom(), this.sel_type);
    }

    async RestartTournament() {
        if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            if (!this.props.m_pwin.haveOpenMessages()) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    this.props.m_pwin.m_main.GetLanguageText('To participate in tournaments you must be registered.')
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
        } else {
            if (this.GetTournamentForSelectedRoom() === 'FINISHED') {
                this.props.m_pwin.m_yesnochips.SetTitleAndChips(
                    this.props.m_pwin.m_main.GetLanguageText('Your participation in this tournament is over.'),
                    this.props.m_pwin.m_main.GetLanguageText('Do you want to start your participation in the tournament from the beginning?'),
                    this.restart_price
                );
            } else {
                this.props.m_pwin.m_yesnochips.SetTitleAndChips(
                    this.props.m_pwin.m_main.GetLanguageText('All recorded results in the current tournament will be deleted.'),
                    this.props.m_pwin.m_main.GetLanguageText('Do you want to start your participation in the tournament from the beginning?'),
                    this.restart_price
                );
            }
            if ((await this.props.m_pwin.m_yesnochips.DoModal()) === GlobalConstants.IDOK) {
                if (this.db_money >= this.restart_price) {
                    this.props.m_pwin.SendGame('*|O|' + this.sel_online + '|' + this.props.m_pwin.m_main.FloatToString(this.restart_price));
                    this.props.m_pwin.m_sndSound.PlayFile('win_money', false);
                } else {
                    await this.props.m_pwin.DoYouWantToBecomeAVIPUser("You don't have enough chips. Do you want to load?");
                }
            }
        }
    }

    GetTooltipForSelectedRoom(): string {
        let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
        if (room !== undefined) {
            return room[12];
        }
        return '';
    }

    GetTournamentForSelectedRoom(): string {
        if (this.sel_type !== '') {
            let room = this.m_rooms.GetItem(this.props.m_pwin.m_main.GetLanguageText(this.sel_type));
            if (room !== undefined) {
                return room[6];
            }
        }
        return 'NONE';
    }

    OnChangeSearch(search: string) {
        this.m_search = search;
        this.setState({});
    }

    ValidateFriendsOption() {
        if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                this.props.m_pwin.m_main.GetLanguageText('In order to add friends you need to log in with your username.')
            );
            this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            this.m_friends = false;
            return false;
        }
        return true;
    }

    OnFriendLogic() {
        if (!this.ValidateFriendsOption()) {
            return;
        }
    }

    OnFriends() {
        this.m_friends = !this.m_friends;
        this.OnFriendLogic();
        this.setState({});
    }

    async OnOnlinegameFriendslist() {
        await this.props.m_pwin.OnOnlinegameFriendslist();
        if (this.props.m_pwin.m_main.friends_list.length === 0) {
            this.m_friends = false;
        }
        this.setState({});
    }

    async openTournamentResults() {
        let url =
            this.props.m_pwin.m_main.GetLanguageText('domain') +
            '/server/public/tournament_results.php?username=' +
            this.props.m_pwin.m_con.getUsername() +
            '&tournament_name=' +
            this.tournament_name +
            '&game=' +
            this.sel_online;
        this.openPage(url, this.tour_results_tooltip);
    }

    async deleteProfile() {
        let message = t.t('Do you want to delete your profile: (username)?', { username: this.props.m_pwin.m_con.getUsername() });
        this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].SetTitle(message);
        if ((await this.props.m_pwin.m_yesno[this.props.m_pwin.messageyesnoIndex].DoModal()) === GlobalConstants.IDOK) {
            this.props.m_pwin.m_delete_profile.DoModal();
        }
    }

    async openProfile() {
        //
        let password = await this.props.m_pwin.m_main.GetProfileString('Options', 'last_password', '');

        let url =
            'https://favorite-games.com/server/login-mobile.php?username=' +
            this.props.m_pwin.m_con.getUsername() +
            '&password=' +
            password +
            '&language=' +
            this.props.m_pwin.m_main.language;
        this.openPage(url, 'Profile');
    }

    openPage(url: string, tooltip: string) {
        //    if(Platform.OS === 'ios' && (this.props.m_pwin.GetGameConfig() === 'Santase' || this.props.m_pwin.GetGameConfig() === 'All')) {
        //        return;
        //    }
        this.props.m_pwin.m_webView.title = tooltip;
        if (Platform.OS !== 'web') {
            if (url.indexOf('?') > 0) {
                url = url + '&store=' + this.props.m_pwin.m_main.GetAppStoreCode() + '&platform=' + Platform.OS;
            } else {
                url = url + '?store=' + this.props.m_pwin.m_main.GetAppStoreCode() + '&platform=' + Platform.OS;
            }
        }
        if (url.indexOf('?') > 0) {
            url = url + '&lang=' + this.props.m_pwin.m_main.language;
        } else {
            url = url + '?lang=' + this.props.m_pwin.m_main.language;
        }
        this.props.m_pwin.m_webView.url = url;
        this.props.m_pwin.m_webView.DoModal();
    }

    async showRewardedAd() {
        this.props.m_pwin.showRewardedAd();
    }

    async OnSelendcancelComboPlayers(cplayers: string) {
        this.m_game_type = cplayers;
        if (this.sel_online === 'Poker Texas Hold em') {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'poker_players', cplayers);
        }
        if (this.sel_online === 'Poker Omaha Hi') {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'pokero_players', cplayers);
        }
        if (this.sel_online === 'Svara') {
            await this.props.m_pwin.m_main.WriteProfileString('Options', 'svara_players', cplayers);
        }
        if (this.sel_online === 'Sergeant Major (3-5-8)') {
            let blato_type: number = 15;
            if (cplayers === '9 ' + this.props.m_pwin.m_main.GetLanguageText('Round')) {
                blato_type = 9;
            }
            if (cplayers === this.props.m_pwin.m_main.GetLanguageText('To 21 Points')) {
                blato_type = 21;
            }
            await this.props.m_pwin.m_main.WriteProfileInt('Options', 'blato_type', blato_type);
        }
        this.setState({});
    }

    render() {
        if (this.props.m_pwin.state.DlgOnlineVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    {this.props.m_pwin.state.DlgOnlineVisible ? (
                        <View style={[dialog.dialogView, dialog.fullScreen]}>
                            <View
                                style={[
                                    styles.doalogTitle,
                                    {
                                        height: GlobalConstants.MEDIUM_FONT_SIZE * 1.6,
                                        paddingLeft: 10 * GlobalVariables.scale,
                                        justifyContent: 'flex-start',
                                    },
                                ]}
                            >
                                <Text scaleText={false} textSize={GlobalConstants.MEDIUM_SMALL_FONT_SIZE} numberOfLines={1}>
                                    {this.default_title.substring(0, Math.round(Dimensions.get('window').width - 20) / 6.6)}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: '100%',
                                    height: GlobalVariables.WindowHeight - GlobalConstants.MEDIUM_FONT_SIZE * 1.6,
                                    flexDirection: 'row',
                                    backgroundColor: 'rgb(56, 74, 84)',
                                }}
                            >
                                <View style={{ width: '75%' }}>
                                    <View style={{ flexDirection: 'row', width: '100%', backgroundColor: 'rgb(96,134,147)' }}>
                                        <View style={{ flexDirection: 'row', width: '50%', alignItems: 'center' }}>
                                            <Pressable
                                                style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                onPress={() => this.openPage(this.tour_pic.m_url, this.tournament_rank_tooltip)}
                                            >
                                                <Tooltip content={this.room_tooltip} delay={1000}>
                                                    <Image
                                                        source={{
                                                            uri: GlobalConstants.IMAGES_FOLDER + this.tour_pic.image,
                                                        }}
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            margin: 2,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Pressable>

                                            <Text
                                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                scaleText={false}
                                                style={{ color: 'white', paddingLeft: 3 }}
                                            >
                                                {GlobalVariables.WindowWidth > 640 ? this.sel_type : ''}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <Text
                                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                scaleText={false}
                                                style={{ color: 'white', paddingRight: 3 }}
                                            >
                                                Game
                                            </Text>
                                            {this.games.length === 1 ? (
                                                <Text
                                                    textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                    scaleText={false}
                                                    style={{ color: 'white', paddingRight: 3 }}
                                                >
                                                    {this.sel_online}
                                                </Text>
                                            ) : (
                                                <Picker
                                                    style={{
                                                        width: 225,
                                                        height: 30,
                                                        fontSize: GlobalConstants.MEDIUM_FONT_SIZE,
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                    }}
                                                    itemStyle={this.cstyles.pickerRow}
                                                    selectedValue={this.sel_online}
                                                    onValueChange={(itemValue) => this.OnSelchangeFilterGame(itemValue)}
                                                >
                                                    {this.games.map((game, index) => (
                                                        <Picker.Item label={game.label} value={game.value} key={'DlgOnlineGamesPicker' + index} />
                                                    ))}
                                                </Picker>
                                            )}
                                            {this.showGameType ? (
                                                <Picker
                                                    style={{
                                                        width: 135,
                                                        height: 30,
                                                        fontSize: GlobalConstants.MEDIUM_FONT_SIZE,
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                    }}
                                                    itemStyle={this.cstyles.pickerRow}
                                                    selectedValue={this.m_game_type}
                                                    onValueChange={(itemValue) => this.OnSelendcancelComboPlayers(itemValue)}
                                                >
                                                    {this.m_game_type_combo.map((gt, index) => (
                                                        <Picker.Item label={gt.label} value={gt.value} key={'SelectPlayers-Rounds' + index} />
                                                    ))}
                                                </Picker>
                                            ) : null}
                                        </View>
                                    </View>
                                    <DlgOnlineRooms
                                        onRef={(ref: DlgOnlineRooms) => (this.m_rooms = ref)}
                                        parent={this}
                                        m_pwin={this.props.m_pwin}
                                        key={'DlgOnlineRooms'}
                                    />
                                    <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start' }}>
                                            {this.show_tournament_checkbox ? (
                                                <Switch
                                                    disabled={!this.enable_tournament_checkbox}
                                                    style={{
                                                        margin: 4,
                                                        transform:
                                                            Platform.OS === 'ios'
                                                                ? [{ scaleX: 0.6 }, { scaleY: 0.6 }]
                                                                : [{ scaleX: 1 }, { scaleY: 1 }],
                                                        height: 20,
                                                    }}
                                                    onValueChange={this.OnStaticTournament.bind(this)}
                                                    value={this.m_tournament}
                                                />
                                            ) : null}
                                            {this.show_tournament_checkbox ? (
                                                <Text
                                                    disabled={!this.enable_tournament_checkbox}
                                                    style={{ color: 'white', padding: 2 }}
                                                    scaleText={false}
                                                    noTranslate={true}
                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                >
                                                    {this.m_static_tournament + ' '}
                                                </Text>
                                            ) : null}
                                        </View>

                                        <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start', height: 28 }}>
                                            <Tooltip content={this.m_static_friends} delay={1000}>
                                                <Pressable
                                                    style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                    onPress={() => this.OnOnlinegameFriendslist()}
                                                >
                                                    <View>
                                                        <FontAwesome5 name="user-friends" color={this.addFriendsColor} size={20} />
                                                    </View>
                                                </Pressable>
                                            </Tooltip>
                                            {GlobalVariables.WindowHeight > GlobalConstants.SHOW_ADD_FRIENDS_TEXT_MIN_WIDTH ? (
                                                <Tooltip content={this.m_static_friends} delay={1000}>
                                                    <Text
                                                        style={{ color: 'white', padding: 2 }}
                                                        scaleText={false}
                                                        noTranslate={true}
                                                        textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                        onPress={() => this.OnOnlinegameFriendslist()}
                                                    >
                                                        {this.m_static_friends}
                                                    </Text>
                                                </Tooltip>
                                            ) : null}
                                        </View>

                                        {!this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) &&
                                        this.props.m_pwin.m_main.friends_list.length > 0 ? (
                                            <Tooltip content={this.show_friend_tooltip} delay={1000}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start' }}>
                                                    <Switch
                                                        style={{
                                                            margin: 4,
                                                            transform:
                                                                Platform.OS === 'ios'
                                                                    ? [{ scaleX: 0.6 }, { scaleY: 0.6 }]
                                                                    : [{ scaleX: 1 }, { scaleY: 1 }],
                                                            height: 20,
                                                        }}
                                                        onValueChange={this.OnFriends.bind(this)}
                                                        value={this.m_friends}
                                                    />
                                                    <Text
                                                        style={{ color: 'white', padding: 2 }}
                                                        scaleText={false}
                                                        noTranslate={true}
                                                        textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                    >
                                                        {this.m_static_show_friends}
                                                    </Text>
                                                </View>
                                            </Tooltip>
                                        ) : null}

                                        <View style={{ alignItems: 'center', alignSelf: 'flex-start', height: 28, justifyContent: 'center' }}>
                                            <Tooltip content={this.find_friend_tooltip} delay={1000}>
                                                <View>
                                                    <KeyboardAvoidingView behavior={'padding'}>
                                                        <InputField
                                                            autoFocus={false}
                                                            height={20}
                                                            onChangeText={(m_search) => this.OnChangeSearch(m_search)}
                                                            placeholder={this.m_static_tbl}
                                                            placeholderTextColor="#8b9cb5"
                                                            keyboardType="default"
                                                            onSubmitEditing={Keyboard.dismiss}
                                                            underlineColorAndroid="#f000"
                                                            returnKeyType="done"
                                                            leftIcon="human"
                                                            rightIcon="close"
                                                            value={this.m_search}
                                                            onRightIconPress={() => this.OnChangeSearch('')}
                                                            style={{ width: Math.min(GlobalVariables.WindowWidth / 8, 90) }}
                                                            textContentType="none"
                                                            key={'DlgOnlineSearch'}
                                                        />
                                                    </KeyboardAvoidingView>
                                                </View>
                                            </Tooltip>
                                        </View>
                                    </View>
                                    <DlgOnlineTables
                                        onRef={(ref: DlgOnlineTables) => (this.m_list = ref)}
                                        parent={this}
                                        m_pwin={this.props.m_pwin}
                                        key={'DlgOnlineTables'}
                                    />
                                    <Text
                                        style={{ color: 'white', padding: 2 }}
                                        textSize={GlobalConstants.SMALL_FONT_SIZE}
                                        scaleText={false}
                                        noTranslate={true}
                                    >
                                        {this.m_game_description !== '' ? this.m_game_description : ' '}
                                    </Text>
                                </View>
                                <View style={{ width: '25%', height: GlobalVariables.WindowHeight - GlobalConstants.MEDIUM_FONT_SIZE * 1.6 }}>
                                    <View
                                        style={{
                                            width: '99.4%',
                                            backgroundColor: 'rgb(96, 134, 147)',
                                            marginBottom: 1,
                                            marginLeft: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            onPress={this.OnGameCreate.bind(this)}
                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                            containerStyle={{ width: '90%', height: GlobalConstants.MEDIUM_FONT_SIZE * 2.8 * GlobalVariables.scale }}
                                            text="Create"
                                        />
                                        <Button
                                            onPress={this.OnConnectToGame.bind(this)}
                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                            containerStyle={{ width: '90%', height: GlobalConstants.MEDIUM_FONT_SIZE * 2.8 * GlobalVariables.scale }}
                                            text="Join"
                                            disabled={!this.CheckJoinButton()}
                                        />
                                        <Button
                                            onPress={this.OnGameWatch.bind(this)}
                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                            containerStyle={{ width: '90%', height: GlobalConstants.MEDIUM_FONT_SIZE * 2.8 * GlobalVariables.scale }}
                                            text="Watch"
                                            disabled={!this.CheckWatchButton()}
                                        />

                                        <View
                                            style={
                                                (styles.row,
                                                {
                                                    height: GlobalConstants.MEDIUM_FONT_SIZE * 2.2 * GlobalVariables.scale,
                                                    width: '90%',
                                                    justifyContent: 'center',
                                                })
                                            }
                                        >
                                            <Tooltip content={this.password_tooltip} delay={1000}>
                                                <View>
                                                    <KeyboardAvoidingView behavior={'padding'}>
                                                        <InputField
                                                            autoFocus={false}
                                                            height={24 * GlobalVariables.scale}
                                                            onChangeText={(m_table_password) => this.OnChangeTablePassword(m_table_password)}
                                                            placeholder={'Password'}
                                                            placeholderTextColor="#8b9cb5"
                                                            keyboardType="default"
                                                            onSubmitEditing={Keyboard.dismiss}
                                                            underlineColorAndroid="#f000"
                                                            returnKeyType="done"
                                                            leftIcon="lock"
                                                            value={this.m_table_password}
                                                            textContentType="none"
                                                            key={'DlgOnlinePass'}
                                                        />
                                                    </KeyboardAvoidingView>
                                                </View>
                                            </Tooltip>
                                        </View>

                                        {GlobalVariables.WindowHeight > GlobalConstants.SHOW_ROOMS_MIN_HEIGHT ? (
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    width: '80%',
                                                    height: GlobalConstants.MEDIUM_FONT_SIZE * 2.2 * GlobalVariables.scale,
                                                }}
                                            >
                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                                                    <Tooltip content={this.close_eye_tooltip} delay={1000}>
                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                            <RadioButton
                                                                value="0"
                                                                status={this.m_eye === 0 ? 'checked' : 'unchecked'}
                                                                onPress={() => this.OnCloseEye()}
                                                            />

                                                            <MaterialCommunityIcons name="eye-off" size={20 * GlobalVariables.scale} />
                                                        </View>
                                                    </Tooltip>
                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                                                    <Tooltip content={this.open_eye_tooltip} delay={1000}>
                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                            <RadioButton
                                                                value="1"
                                                                status={this.m_eye === 1 ? 'checked' : 'unchecked'}
                                                                onPress={() => this.OnOpenEye()}
                                                            />

                                                            <MaterialCommunityIcons name="eye" size={20 * GlobalVariables.scale} />
                                                        </View>
                                                    </Tooltip>
                                                </View>
                                            </View>
                                        ) : null}
                                    </View>
                                    <View
                                        style={{
                                            height:
                                                GlobalVariables.WindowHeight > GlobalConstants.SHOW_ROOMS_MIN_HEIGHT
                                                    ? GlobalVariables.WindowHeight -
                                                      272 * GlobalVariables.scale -
                                                      GlobalConstants.MEDIUM_FONT_SIZE * 1.6
                                                    : GlobalVariables.WindowHeight -
                                                      239 * GlobalVariables.scale -
                                                      GlobalConstants.MEDIUM_FONT_SIZE * 1.6,
                                        }}
                                    >
                                        <ScrollbarWrapper>
                                            <TouchableWithoutFeedback>
                                                <View
                                                    style={{
                                                        width: '99.4%',
                                                        backgroundColor: 'rgb(96, 134, 147)',
                                                        marginLeft: 1,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                        <Text
                                                            noTranslate={true}
                                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                            scaleText={false}
                                                            style={{
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                paddingBottom: GlobalVariables.WindowWidth * 0.003,
                                                            }}
                                                        >
                                                            {this.props.m_pwin.m_con.getUsername()}
                                                        </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <View style={{ width: '33.1%', justifyContent: 'flex-end' }}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                {this.ShowUserTournamentResultsPic() ? (
                                                                    <Tooltip content={this.tour_results_tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.openTournamentResults()}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.tour_results_pic.image,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                ) : null}

                                                                {this.showRestartTournamentImage() ? (
                                                                    <Tooltip content={this.restart_tournament_tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.RestartTournament()}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.restart_tournament_pic,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                ) : null}
                                                            </View>
                                                            {this.ShowUserTournamentResultsPic() ? (
                                                                <Tooltip content={this.tournament_rank_tooltip} delay={1000}>
                                                                    <Pressable
                                                                        onPress={() =>
                                                                            this.openPage(this.tour_pic.m_url, this.tournament_rank_tooltip)
                                                                        }
                                                                    >
                                                                        <Text
                                                                            style={{ color: 'white', padding: 2 }}
                                                                            textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                            scaleText={false}
                                                                            noTranslate={true}
                                                                        >
                                                                            {this.m_static_tournament_rank}
                                                                        </Text>
                                                                    </Pressable>
                                                                </Tooltip>
                                                            ) : null}
                                                            <View style={{ flexDirection: 'row' }}></View>
                                                        </View>
                                                        <View style={{ width: '37.55%', justifyContent: 'flex-start' }}>
                                                            {this.user_pic.image ? (
                                                                <View>
                                                                    <Tooltip content={this.player_tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                {
                                                                                    opacity: args.pressed ? 0.5 : 1,
                                                                                    backgroundColor: 'transparent',
                                                                                    width: '94%',
                                                                                    paddingTop: 7 * GlobalVariables.scale,
                                                                                },
                                                                            ]}
                                                                            onPress={() => this.openPage(this.user_pic.m_url, this.player_tooltip)}
                                                                        >
                                                                            <ImageBackground
                                                                                source={{
                                                                                    uri: this.user_pic_no_img.image,
                                                                                }}
                                                                                style={this.cstyles.avatarStyleNoImg}
                                                                            >
                                                                                <ImageBackground
                                                                                    source={{
                                                                                        uri: this.user_pic.image,
                                                                                    }}
                                                                                    style={this.cstyles.avatarStyle}
                                                                                >
                                                                                    <Image
                                                                                        source={{
                                                                                            uri: this.props.m_pwin.m_main.frame,
                                                                                        }}
                                                                                        style={this.cstyles.frameStyle}
                                                                                    />
                                                                                </ImageBackground>
                                                                            </ImageBackground>
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                </View>
                                                            ) : null}
                                                        </View>
                                                        <View style={{ width: '28.65%', justifyContent: 'flex-end' }}>
                                                            {!this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername()) ? (
                                                                <>
                                                                    <Tooltip content={this.edit_profile_pic.tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.openProfile()}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.edit_profile_pic.image,
                                                                                }}
                                                                                style={[this.cstyles.iconImage, this.cstyles.iconPadding]}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                    <Tooltip content={this.delete_profile_pic.tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.deleteProfile()}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri:
                                                                                        GlobalConstants.IMAGES_FOLDER + this.delete_profile_pic.image,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                </>
                                                            ) : null}
                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Tooltip content={this.rating_tooltip} delay={1000}>
                                                            <Pressable
                                                                style={(args) => [
                                                                    { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                ]}
                                                                onPress={() => this.openPage(this.rating_pic.m_url, this.rating_tooltip)}
                                                            >
                                                                <Image
                                                                    source={{
                                                                        uri: GlobalConstants.IMAGES_FOLDER + this.rating_pic.image,
                                                                    }}
                                                                    style={this.cstyles.iconImage}
                                                                />
                                                            </Pressable>
                                                        </Tooltip>
                                                        <Text
                                                            style={{ color: 'white', padding: 2 }}
                                                            textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                            scaleText={false}
                                                            noTranslate={true}
                                                        >
                                                            {this.m_static_rating}
                                                        </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', width: '85%' }}>
                                                        <Pressable
                                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                            onPress={() => this.props.m_pwin.DoYouWantToBecomeAVIPUser()}
                                                        >
                                                            <Image
                                                                source={{
                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.time_pic.image,
                                                                }}
                                                                style={this.cstyles.iconImage}
                                                            />
                                                        </Pressable>
                                                        {this.ShowAddPic() ? (
                                                            <Tooltip content={this.add_vip_tooltip} delay={1000}>
                                                                <Pressable
                                                                    style={(args) => [
                                                                        { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                    ]}
                                                                    onPress={() => this.Add30DaysVip()}
                                                                >
                                                                    <Image
                                                                        source={{
                                                                            uri: GlobalConstants.IMAGES_FOLDER + this.add_pic,
                                                                        }}
                                                                        style={this.cstyles.iconImage}
                                                                    />
                                                                </Pressable>
                                                            </Tooltip>
                                                        ) : null}
                                                        <Text
                                                            style={{ color: 'white', padding: 2 }}
                                                            textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                            scaleText={false}
                                                            noTranslate={true}
                                                        >
                                                            {this.m_static_time}
                                                        </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Tooltip content={this.chips_tooltip} delay={1000}>
                                                            <Pressable
                                                                style={(args) => [
                                                                    { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                ]}
                                                                onPress={() => this.props.m_pwin.DoYouWantToBecomeAVIPUser()}
                                                            >
                                                                <Image
                                                                    source={{
                                                                        uri: GlobalConstants.IMAGES_FOLDER + this.money_pic.image,
                                                                    }}
                                                                    style={this.cstyles.iconImage}
                                                                />
                                                            </Pressable>
                                                        </Tooltip>
                                                        <Tooltip content={this.chips_tooltip} delay={1000}>
                                                            <View>
                                                                <Text
                                                                    style={{ color: 'white', padding: 2 }}
                                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                    scaleText={false}
                                                                    noTranslate={true}
                                                                >
                                                                    {this.m_static_money}
                                                                </Text>
                                                            </View>
                                                        </Tooltip>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Tooltip content={this.stat_tooltip} delay={1000}>
                                                            <Pressable
                                                                style={(args) => [
                                                                    { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                ]}
                                                                onPress={() => this.openPage(this.user_stat_pic.m_url, this.stat_tooltip)}
                                                            >
                                                                <Image
                                                                    source={{
                                                                        uri: GlobalConstants.IMAGES_FOLDER + this.user_stat_pic.image,
                                                                    }}
                                                                    style={this.cstyles.iconImage}
                                                                />
                                                            </Pressable>
                                                        </Tooltip>

                                                        <Tooltip content={this.delete_tooltip} delay={1000}>
                                                            <Pressable
                                                                style={(args) => [
                                                                    { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                ]}
                                                                onPress={() => this.DeleteUserResultsForGame()}
                                                            >
                                                                <Image
                                                                    source={{
                                                                        uri: GlobalConstants.IMAGES_FOLDER + this.delete_stat_pic,
                                                                    }}
                                                                    style={this.cstyles.iconImage}
                                                                />
                                                            </Pressable>
                                                        </Tooltip>
                                                    </View>
                                                </View>
                                            </TouchableWithoutFeedback>
                                            <TouchableWithoutFeedback>
                                                <View
                                                    style={{
                                                        width: '99.4%',
                                                        backgroundColor: 'rgb(96, 134, 147)',
                                                        marginLeft: 1,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Text
                                                            textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                            scaleText={false}
                                                            style={{ color: 'white', fontWeight: 'bold' }}
                                                        >
                                                            Server info
                                                        </Text>
                                                    </View>
                                                    <Tooltip content={this.online_users_tooltip} delay={1000}>
                                                        <Pressable
                                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                            onPress={() =>
                                                                this.openPage(
                                                                    this.props.m_pwin.m_main.GetLanguageText('online_page'),
                                                                    this.online_users_tooltip
                                                                )
                                                            }
                                                        >
                                                            <Text
                                                                style={{ color: 'white', padding: 2 }}
                                                                textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                scaleText={false}
                                                                noTranslate={true}
                                                            >
                                                                {this.m_static_online}
                                                            </Text>
                                                        </Pressable>
                                                    </Tooltip>
                                                    <Tooltip content={this.all_users_tooltip} delay={1000}>
                                                        <Pressable
                                                            style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                            onPress={() =>
                                                                this.openPage(
                                                                    this.props.m_pwin.m_main.GetLanguageText('users_page'),
                                                                    this.all_users_tooltip
                                                                )
                                                            }
                                                        >
                                                            <Text
                                                                style={{ color: 'white', padding: 2 }}
                                                                textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                scaleText={false}
                                                                noTranslate={true}
                                                            >
                                                                {this.m_static_reg}
                                                            </Text>
                                                        </Pressable>
                                                    </Tooltip>
                                                </View>
                                            </TouchableWithoutFeedback>
                                            {this.sel_player !== '' ? (
                                                <TouchableWithoutFeedback>
                                                    <View
                                                        style={{
                                                            width: '99.4%',
                                                            backgroundColor: 'rgb(96, 134, 147)',
                                                            marginLeft: 1,
                                                            marginBottom: 1,
                                                        }}
                                                    >
                                                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                            <Text
                                                                noTranslate={true}
                                                                textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                                                scaleText={false}
                                                                style={{ color: 'white', fontWeight: 'bold' }}
                                                            >
                                                                {this.sel_player}
                                                            </Text>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ width: '33.1%' }}>
                                                                <View style={{ alignItems: 'flex-end', paddingRight: '4.9%', height: 30 }}>
                                                                    {this.sel_player !== '' && this.show_player > 1 ? (
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.OnButtonPrev()}
                                                                        >
                                                                            <MaterialCommunityIcons name="skip-previous" size={30} />
                                                                        </Pressable>
                                                                    ) : null}
                                                                </View>
                                                                <View
                                                                    style={{
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'flex-end',
                                                                        height: Platform.OS === 'web' ? 'calc(100% - 30px)' : null,
                                                                    }}
                                                                >
                                                                    <Tooltip content={this.vip_pic.tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.props.m_pwin.DoYouWantToBecomeAVIPUser()}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.vip_pic.image,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                    <Tooltip content={this.stat_tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() =>
                                                                                this.openPage(this.player_stat_pic.m_url, this.stat_tooltip)
                                                                            }
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.player_stat_pic.image,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                    <Tooltip content={this.os_pic.tooltip} delay={1000}>
                                                                        <Pressable
                                                                            style={(args) => [
                                                                                { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                            ]}
                                                                            onPress={() => this.openPage(this.os_pic.m_url, this.os_pic.tooltip)}
                                                                        >
                                                                            <Image
                                                                                source={{
                                                                                    uri: GlobalConstants.IMAGES_FOLDER + this.os_pic.image,
                                                                                }}
                                                                                style={this.cstyles.iconImage}
                                                                            />
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                </View>
                                                            </View>
                                                            <View style={{ width: '37.55%', justifyContent: 'flex-start' }}>
                                                                {this.player_pic.image && this.sel_player !== '' ? (
                                                                    <Tooltip content={this.user_tooltip} delay={1000}>
                                                                        <View>
                                                                            <Pressable
                                                                                style={(args) => [
                                                                                    {
                                                                                        opacity: args.pressed ? 0.5 : 1,
                                                                                        backgroundColor: 'transparent',
                                                                                        width: '94%',
                                                                                        paddingTop: 7 * GlobalVariables.scale,
                                                                                    },
                                                                                ]}
                                                                                onPress={() =>
                                                                                    this.openPage(this.player_pic.m_url, this.user_tooltip)
                                                                                }
                                                                            >
                                                                                <ImageBackground
                                                                                    source={{
                                                                                        uri: this.player_pic_no_image.image,
                                                                                    }}
                                                                                    style={this.cstyles.avatarStyleNoImg}
                                                                                >
                                                                                    <ImageBackground
                                                                                        source={{
                                                                                            uri: this.player_pic.image,
                                                                                        }}
                                                                                        style={this.cstyles.avatarStyle}
                                                                                    >
                                                                                        <Image
                                                                                            source={{
                                                                                                uri: this.props.m_pwin.m_main.frame,
                                                                                            }}
                                                                                            style={this.cstyles.frameStyle}
                                                                                        />
                                                                                    </ImageBackground>
                                                                                </ImageBackground>
                                                                            </Pressable>
                                                                        </View>
                                                                    </Tooltip>
                                                                ) : null}
                                                            </View>
                                                            <View style={{ width: '28.65%', alignItems: 'flex-start' }}>
                                                                {this.sel_player !== '' && this.show_player < this.GetTotalPlayers() ? (
                                                                    <Pressable
                                                                        style={(args) => [
                                                                            { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                        ]}
                                                                        onPress={() => this.OnButtonNext()}
                                                                    >
                                                                        <MaterialCommunityIcons name="skip-next" size={30} />
                                                                    </Pressable>
                                                                ) : null}
                                                            </View>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Tooltip content={this.player_rating_pic.tooltip} delay={1000}>
                                                                <Pressable
                                                                    style={(args) => [
                                                                        { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                    ]}
                                                                    onPress={() =>
                                                                        this.openPage(this.player_rating_pic.m_url, this.player_rating_pic.tooltip)
                                                                    }
                                                                >
                                                                    <Image
                                                                        source={{
                                                                            uri: GlobalConstants.IMAGES_FOLDER + this.player_rating_pic.image,
                                                                        }}
                                                                        style={this.cstyles.iconImage}
                                                                    />
                                                                </Pressable>
                                                            </Tooltip>
                                                            <Tooltip content={this.player_rating_pic.tooltip} delay={1000}>
                                                                <Text
                                                                    style={{ color: 'white', padding: 2 }}
                                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                    scaleText={false}
                                                                    noTranslate={true}
                                                                >
                                                                    {this.m_static_prating}
                                                                </Text>
                                                            </Tooltip>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Tooltip content={this.player_money_pic.tooltip} delay={1000}>
                                                                <Pressable
                                                                    style={(args) => [
                                                                        { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                    ]}
                                                                    onPress={() => this.props.m_pwin.DoYouWantToBecomeAVIPUser()}
                                                                >
                                                                    <Image
                                                                        source={{
                                                                            uri: GlobalConstants.IMAGES_FOLDER + this.player_money_pic.image,
                                                                        }}
                                                                        style={this.cstyles.iconImage}
                                                                    />
                                                                </Pressable>
                                                            </Tooltip>
                                                            <Tooltip content={this.player_money_pic.tooltip} delay={1000}>
                                                                <Text
                                                                    style={{ color: 'white', padding: 2 }}
                                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                    scaleText={false}
                                                                    noTranslate={true}
                                                                >
                                                                    {this.m_static_pmoney}
                                                                </Text>
                                                            </Tooltip>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Tooltip content={this.player_language_pic.tooltip} delay={1000}>
                                                                <Pressable
                                                                    style={(args) => [
                                                                        { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                    ]}
                                                                    onPress={() =>
                                                                        this.openPage(
                                                                            this.player_language_pic.m_url,
                                                                            this.player_language_pic.tooltip
                                                                        )
                                                                    }
                                                                >
                                                                    <Image
                                                                        source={{
                                                                            uri: GlobalConstants.IMAGES_FOLDER + this.player_language_pic.image,
                                                                        }}
                                                                        style={this.cstyles.iconImage}
                                                                    />
                                                                </Pressable>
                                                            </Tooltip>
                                                            <Tooltip content={this.player_language_pic.tooltip} delay={1000}>
                                                                <Text
                                                                    style={{ color: 'white', padding: 2 }}
                                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                    scaleText={false}
                                                                    noTranslate={true}
                                                                >
                                                                    {this.m_static_language}
                                                                </Text>
                                                            </Tooltip>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Tooltip content={this.flag_pic.tooltip} delay={1000}>
                                                                <Pressable
                                                                    style={(args) => [
                                                                        { opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' },
                                                                    ]}
                                                                    onPress={() => this.openPage(this.flag_pic.m_url, this.flag_pic.tooltip)}
                                                                >
                                                                    <Image
                                                                        source={{
                                                                            uri: GlobalConstants.IMAGES_FOLDER + this.flag_pic.image,
                                                                        }}
                                                                        style={this.cstyles.flagImage}
                                                                    />
                                                                </Pressable>
                                                            </Tooltip>
                                                            <Tooltip content={this.flag_pic.tooltip} delay={1000}>
                                                                <Text
                                                                    style={{ color: 'white', padding: 2 }}
                                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                                    scaleText={false}
                                                                    noTranslate={true}
                                                                >
                                                                    {this.m_static_country}
                                                                </Text>
                                                            </Tooltip>
                                                        </View>
                                                    </View>
                                                </TouchableWithoutFeedback>
                                            ) : (
                                                <View
                                                    style={{
                                                        width: '99.4%',
                                                        height: '70%',
                                                        backgroundColor: 'rgb(96, 134, 147)',
                                                        marginLeft: 1,
                                                        marginBottom: 1,
                                                    }}
                                                />
                                            )}
                                        </ScrollbarWrapper>
                                    </View>
                                    <View
                                        style={{
                                            width: '99.4%',
                                            height: 47 * GlobalVariables.scale,
                                            backgroundColor: 'rgb(96, 134, 147)',
                                            marginLeft: 1,
                                            position: 'absolute',
                                            top:
                                                Dimensions.get('window').height - 45 * GlobalVariables.scale - GlobalConstants.MEDIUM_FONT_SIZE * 1.8,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            onPress={() => this.props.m_pwin.CancelDlgOnline()}
                                            textSize={GlobalConstants.MEDIUM_FONT_SIZE}
                                            containerStyle={{ width: '90%', height: GlobalConstants.MEDIUM_FONT_SIZE * 2.8 * GlobalVariables.scale }}
                                            text="Exit"
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) : null}
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgOnline;

const stylesGenerator = () =>
    StyleSheet.create({
        cbuttonStyle: {
            width: '33.3%',
            marginLeft: 5,
            marginRight: 5,
            alignItems: 'center',
        },
        avatarStyle: {
            width: '100%',
            resizeMode: 'contain',
        },
        avatarStyleNoImg: {
            width: '100%',
            resizeMode: 'contain',
        },
        frameStyle: {
            width: '106%',
            aspectRatio: 0.8333,
            margin: '-3%',
        },
        iconPadding: {
            marginBottom: 20,
        },
        iconImage: {
            width: 20,
            height: 20,
            margin: 2,
        },
        flagImage: {
            width: 21,
            height: 14,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
        },
        pickerRow: {
            height: 35,
            fontSize: GlobalConstants.SMALL_FONT_SIZE,
        },
        inputStyle: {
            width: '100%',
            flex: 1,
        },
    });
