import { Component } from 'react';
import { Text, View } from 'react-native';
import React from 'react';
import GlobalVariables from '../GlobalVariables';
import { styles } from '../../../css/container';
import { TextOutProps } from '../props/TextOutProps';
import { StyleSheet } from 'react-native';
class BorderText extends Component<TextOutProps> {
    constructor(props: TextOutProps) {
        super(props);
    }

    render() {
        return (
            <View style={styles.container}>
                <View
                    style={[
                        cstyles.background,
                        {
                            left: this.props.position.x * GlobalVariables.scale,
                            top: this.props.position.y * GlobalVariables.scale,
                            padding: 1 * GlobalVariables.scale,
                        },
                    ]}
                >
                    <Text style={[cstyles.borderText, { fontSize: GlobalVariables.scale * this.props.fontSize }]} numberOfLines={1}>
                        {this.props.text}
                    </Text>
                </View>
            </View>
        );
    }
}

export default BorderText;

const cstyles = StyleSheet.create({
    background: {
        backgroundColor: '#e8e6b3',
    },
    borderText: {
        fontWeight: 'bold',
        backgroundColor: '#e8e6b3',
        padding: 1,
        borderColor: 'rgb(44, 35, 29)',
        borderStyle: 'solid',
        borderWidth: GlobalVariables.scale,
    },
});
