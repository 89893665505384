import React, { Component } from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { styles } from '../../../css/container';
import Text from '../../Components/core/Text';
import GlobalConstants from '../../Components/GlobalConstants';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { ParentDlgProps } from '../../Components/props/ParentDlgProps';
import * as Font from 'expo-font';
import Tooltip from '~/Components/core/Tooltip';

class Watchers extends Component<ParentDlgProps> {
    ON_COLOR: string = '#225522';
    OFF_COLOR: string = '#333333';

    selectedUser: string | null = null;
    chat_off: string[] = [];
    loading: boolean = true;

    constructor(props: ParentDlgProps) {
        super(props);
    }
    componentDidMount() {
        this.props.onRef(this);
        Font.loadAsync({
            ...Entypo.font,
            ...MaterialCommunityIcons.font,
            entypo: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf'),
            'material-community': require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        }).then(() => {
            this.loading = false;
            this.setState({});
        });
    }

    EnableChat(username: string) {
        for (let i = 0; i < 100; i++) {
            if (this.chat_off[i] === username) {
                return false;
            }
        }
        return true;
    }

    selectUsername(username: string) {
        this.selectedUser = username;
        this.setState({});
    }

    OnStaticShow() {
        if (this.selectedUser !== null) {
            let url =
                this.props.m_pwin.m_main.GetLanguageText('domain') +
                '/server/public/show_user.php?username=' +
                this.selectedUser +
                '&lang=' +
                this.props.m_pwin.m_main.language;
            this.props.m_pwin.openPage(url);
        }
    }

    async OnStaticChat() {
        //Done
        if (!this.props.m_pwin.m_main.watch) {
            if (this.props.m_pwin.IsGuestUser(this.props.m_pwin.m_con.getUsername())) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    'In order to be able to stop the chat of watchers from the table, you must log in with your username.'
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
            }
            if (this.props.m_pwin.isNoVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].SetTitle(
                    'In order to be able to stop the chat of watchers from the table, you must be a VIP user.'
                );
                await this.props.m_pwin.m_ok[this.props.m_pwin.messageokIndex].DoModal();
                await this.props.m_pwin.DoYouWantToBecomeAVIPUser();
            }
            if (this.props.m_pwin.isVipPlayer(this.props.m_pwin.m_con.getUsername())) {
                if (this.selectedUser !== '') {
                    if (this.EnableChat(this.selectedUser)) {
                        this.ChatOff(this.selectedUser, false, true);
                    } else {
                        this.ChatOff(this.selectedUser, true, true);
                    }
                }
            }
        }
    }

    ChatOff(username: string, enable: boolean, send: boolean) {
        if (enable) {
            for (let i = 0; i < 100; i++) {
                if (this.chat_off[i] !== undefined) {
                    if (this.chat_off[i] === username) {
                        for (let a = i; a < 99; a++) {
                            this.chat_off[a] = this.chat_off[a + 1];
                        }
                        if (send) {
                            let text = '(chaton) ' + username;
                            this.props.parent.m_text = text;
                            this.props.parent.OnStaticSend();
                        }

                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < 100; i++) {
                if (this.chat_off[i] === undefined) {
                    this.chat_off[i] = username;
                    if (send) {
                        let text = '(chatoff) ' + username;
                        this.props.parent.m_text = text;
                        this.props.parent.OnStaticSend();
                    }
                    break;
                }
            }
        }
        this.setState({});
    }

    render() {
        if (this.loading) {
            return null;
        } else {
            return (
                <View style={cstyle.container}>
                    <View style={styles.row}>
                        <View>
                            <View>
                                <Text scaleText={false} textSize={GlobalConstants.MEDIUM_FONT_SIZE} color="black">
                                    Watchers
                                </Text>
                            </View>
                            <View style={[cstyle.watchContainer]}>
                                <ScrollView style={cstyle.scrollContainer}>
                                    {this.props.parent.watchers.map((username, index) => (
                                        <View
                                            key={index}
                                            style={this.selectedUser === username ? cstyle.selectedUserBackground : cstyle.notSelectedUserBackground}
                                        >
                                            <Pressable
                                                style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                                onPress={() => this.selectUsername(username)}
                                            >
                                                <Text
                                                    color="black"
                                                    scaleText={false}
                                                    style={cstyle.watchText}
                                                    textSize={GlobalConstants.SMALL_FONT_SIZE}
                                                >
                                                    {username}
                                                </Text>
                                            </Pressable>
                                        </View>
                                    ))}
                                </ScrollView>
                            </View>
                        </View>
                        <View style={[cstyle.buttonContainer]}>
                            <Tooltip content={this.props.m_pwin.m_main.GetLanguageText('Kick')} delay={1000}>
                                <Pressable
                                    disabled={this.selectedUser === null || this.props.m_pwin.m_main.watch}
                                    style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                    onPress={() => this.props.parent.OnStaticKick()}
                                >
                                    <Entypo
                                        name={'remove-user'}
                                        color={this.selectedUser !== null && !this.props.m_pwin.m_main.watch ? this.ON_COLOR : this.OFF_COLOR}
                                        size={23}
                                    />
                                </Pressable>
                            </Tooltip>
                            <Tooltip content={this.props.m_pwin.m_main.GetLanguageText('Invite')} delay={1000}>
                                <Pressable
                                    disabled={
                                        this.selectedUser === null || this.props.m_pwin.m_main.watch || !this.props.m_pwin.HaveFreePositionsToTable()
                                    }
                                    style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                    onPress={() =>
                                        this.selectedUser === null || this.props.m_pwin.m_main.watch || !this.props.m_pwin.HaveFreePositionsToTable()
                                            ? null
                                            : this.props.parent.OnStaticAdd()
                                    }
                                >
                                    <Entypo
                                        name={'add-user'}
                                        color={
                                            this.selectedUser !== null &&
                                            !this.props.m_pwin.m_main.watch &&
                                            this.props.m_pwin.HaveFreePositionsToTable()
                                                ? this.ON_COLOR
                                                : this.OFF_COLOR
                                        }
                                        size={23}
                                    />
                                </Pressable>
                            </Tooltip>
                            <Tooltip content={this.props.m_pwin.m_main.GetLanguageText('Start/Stop Chat')} delay={1000}>
                                <Pressable
                                    disabled={this.selectedUser === null || this.props.m_pwin.m_main.watch}
                                    style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                    onPress={() => this.OnStaticChat()}
                                >
                                    <MaterialCommunityIcons
                                        name={this.EnableChat(this.selectedUser) ? 'chat-remove' : 'chat'}
                                        color={this.selectedUser !== null && !this.props.m_pwin.m_main.watch ? this.ON_COLOR : this.OFF_COLOR}
                                        size={23}
                                    />
                                </Pressable>
                            </Tooltip>
                            <Tooltip content={this.props.m_pwin.m_main.GetLanguageText('Information')} delay={1000}>
                                <Pressable
                                    disabled={this.selectedUser === null}
                                    style={(args) => [{ opacity: args.pressed ? 0.5 : 1, backgroundColor: 'transparent' }]}
                                    onPress={() => this.OnStaticShow()}
                                >
                                    <Entypo name={'info-with-circle'} color={this.selectedUser !== null ? this.ON_COLOR : this.OFF_COLOR} size={23} />
                                </Pressable>
                            </Tooltip>
                        </View>
                    </View>
                </View>
            );
        }
    }
}

export default Watchers;

const cstyle = StyleSheet.create({
    selectedUserBackground: {
        backgroundColor: 'rgb(0, 120,215)',
        margin: 1,
    },
    notSelectedUserBackground: {
        backgroundColor: 'rgb(240,240,240)',
        margin: 1,
    },
    container: {
        height: GlobalConstants.CHAT_BAR_HEIGHT,
        width: 150,
        margin: 1,
        marginLeft: 2,
    },
    buttonContainer: {
        height: '100%',
        width: 28,
        paddingLeft: 2,
    },
    scrollContainer: {
        width: 117,
        height: 80,
    },
    watchContainer: {
        borderColor: '#555555',
        borderStyle: 'solid',
        borderWidth: 1,
        margin: 1,
        width: 120,
        height: 80,
    },
    watchText: {
        fontWeight: 'bold',
    },
});
