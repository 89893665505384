import React, { Component } from 'react';
import { Dimensions, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import Button from '~/Components/core/Button';
import CPicture from '~/Components/cppComponents/CPicture';
import CPoint from '~/Components/cppComponents/CPoint';
import { styles } from '../../css/container';
import { dialog } from '../../css/dialog';
import { modal } from '../../css/modal';
import Text from '../Components/core/Text';
import GlobalConstants from '../Components/GlobalConstants';
import GlobalVariables from '../Components/GlobalVariables';
import { BaseDlgProps } from '../Components/props/BaseDlgProps';

class DlgKarti extends Component<BaseDlgProps> {
    start: number;
    cstyles = stylesGenerator();
    grab: number;
    backdll: string;
    facedll: string;
    NFDll: string[] = [];
    NBDll: string[] = [];
    back_point: CPoint[] = [];
    invert: number;
    cardsFolder: string;
    yes: boolean;
    face = [];
    back = [];
    cardFaceName: string[] = [];
    cardBackName: string[] = [];

    constructor(props: BaseDlgProps) {
        super(props);
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(GlobalConstants.IDOK);
            }, 100)
        );
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    async OnInitDialog() {
        this.back = [];
        this.face = [];
        this.cardsFolder = 'files_2.0';
        if (GlobalVariables.scale <= 1.5) {
            this.cardsFolder = 'files_1.5';
        }
        if (GlobalVariables.scale <= 1) {
            this.cardsFolder = 'files_1.0';
        }

        for (let i = 1; i <= 11; i++) {
            if (i === 3) {
                i++;
            }
            if (i === 6 || i === 9) {
                i = i + 2;
            }

            this.face[i] = [];
            for (let a = 114; a > 110; a--) {
                this.face[i].push(
                    <View style={this.cstyles.noactive} key={'view' + a}>
                        <Pressable
                            onPress={() => {
                                this.facedll = 'cards_' + i;
                                this.setState({});
                            }}
                            style={[this.cstyles.cardPadding]}
                            key={i + 'face' + a}
                        >
                            <CPicture
                                image={'/cards/cards_' + i + '/' + a + '.gif'}
                                folder={'files_2.0'}
                                relative={true}
                                m_x={0}
                                m_y={0}
                                m_Width={GlobalConstants.CARD_WIDTH}
                                m_Height={GlobalConstants.CARD_HEIGHT}
                                noScale={true}
                                cursor={GlobalConstants.CURSOR_HAND}
                            />
                        </Pressable>
                    </View>
                );
            }
        }

        for (let i = 1; i <= 16; i = i + 2) {
            if (this.cardsFolder !== 'files_1.0') {
                if (i === 3) {
                    i = 9;
                }
                if (i === 13) {
                    i = 15;
                }
            } else {
                if (i === 13) {
                    i = 15;
                }
            }
            this.back[i] = [];
            for (let a = 1; a <= 4; a++) {
                if (i === 5 && a === 2) {
                    break;
                }
                if (i === 7 && a === 4) {
                    break;
                }
                if (i === 13 || i === 14) {
                    break;
                }
                this.back[i].push(
                    <Pressable
                        onPress={() => {
                            this.backdll = 'back_' + (Math.floor(i / 2) + 1);
                            this.grab = a;
                            this.setState({});
                        }}
                        style={this.cstyles.cardPadding}
                        key={i + 'back' + a}
                    >
                        <CPicture
                            image={'/cards/back_' + (Math.floor(i / 2) + 1) + '/' + a + '.gif'}
                            folder={this.cardsFolder}
                            relative={true}
                            m_x={0}
                            m_y={0}
                            m_Width={GlobalConstants.CARD_WIDTH}
                            m_Height={GlobalConstants.CARD_HEIGHT}
                            noScale={true}
                            cursor={GlobalConstants.CURSOR_HAND}
                        />
                    </Pressable>
                );
            }
            if (i !== 5 && i !== 7 && i !== 13 && i !== 14) {
                this.back[i + 1] = [];
                for (let a = 1; a <= 4; a++) {
                    if (i === 1 && a === 4) {
                        break;
                    }
                    if (i === 3 && a === 3) {
                        break;
                    }
                    if (i === 9 && a === 4) {
                        break;
                    }
                    if (i === 11 && a === 3) {
                        break;
                    }
                    this.back[i + 1].push(
                        <Pressable
                            onPress={() => {
                                this.backdll = 'back_' + (Math.floor(i / 2) + 1);
                                this.grab = a + 4;
                                this.setState({});
                            }}
                            style={this.cstyles.cardPadding}
                            key={i + 'back' + (a + 4)}
                        >
                            <CPicture
                                image={'/cards/back_' + (Math.floor(i / 2) + 1) + '/' + (a + 4) + '.gif'}
                                folder={this.cardsFolder}
                                relative={true}
                                m_x={0}
                                m_y={0}
                                m_Width={GlobalConstants.CARD_WIDTH}
                                m_Height={GlobalConstants.CARD_HEIGHT}
                                noScale={true}
                                cursor={GlobalConstants.CURSOR_HAND}
                            />
                        </Pressable>
                    );
                }
            }
        }
        this.cardFaceName[1] = 'Favorite';
        this.cardFaceName[2] = 'Favorite New';
        this.cardFaceName[4] = 'Poker playing cards';
        this.cardFaceName[5] = 'Large Index';
        this.cardFaceName[8] = 'Classic';
        this.cardFaceName[11] = 'Favorite2';
        this.cardBackName[1] = 'Favorite';
        this.cardBackName[9] = 'Art';
        this.cardBackName[11] = 'Favorite2';
        this.cardBackName[15] = 'Taro';
        this.setState({});
    }

    async DoModal() {
        this.props.m_pwin.state.DlgKartiVisible = true;
        await this.OnInitDialog();
        this.start = 1;
        this.props.m_pwin.setState({});
        while (this.start === 1 && this.props.m_pwin.state.DlgKartiVisible) {
            await this.performTimeConsumingTask();
        }
        this.props.m_pwin.setState({ DlgKartiVisible: false });
        if (this.yes === true || this.props.m_pwin.m_main.getIsTest()) {
            return GlobalConstants.IDOK;
        } else {
            return GlobalConstants.IDNO;
        }
    }

    OnOK() {
        this.yes = true;
        this.start = 0;
    }

    OnCancel() {
        this.yes = false;
        this.start = 0;
    }

    render() {
        if (this.props.m_pwin.state.DlgKartiVisible) {
            return (
                <View style={[styles.modal, { width: Dimensions.get('window').width, height: Dimensions.get('window').height }]}>
                    <View
                        style={[
                            dialog.dialogView,
                            // eslint-disable-next-line react-native/no-inline-styles
                            {
                                top: GlobalVariables.positionTop + 5 * GlobalVariables.scale,
                                left: GlobalVariables.positionLeft + (790 * GlobalVariables.scale - 400) / 2,
                                width: 400,
                            },
                        ]}
                    >
                        <View style={styles.doalogTitle}>
                            <Text textSize={GlobalConstants.SUPER_EXTRA_LARGE_FONT_SIZE}>Cards</Text>
                        </View>
                        <View style={this.cstyles.cardsContainer}>
                            <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Face</Text>
                            <ScrollView style={[this.cstyles.scrollViewSize, { height: Dimensions.get('window').height * 0.3 }]}>
                                {this.face.map((subres, index) => (
                                    <View
                                        key={'face' + index}
                                        style={[
                                            this.cstyles.cardsRowContainer,
                                            this.facedll === 'cards_' + index ? this.cstyles.active : this.cstyles.noactive,
                                        ]}
                                    >
                                        <Text textSize={GlobalConstants.LARGE_FONT_SIZE} scaleText={false} color={'black'}>
                                            {this.cardFaceName[index]}
                                        </Text>
                                        <View style={[this.cstyles.cardsRow]}>{subres}</View>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>
                        <View style={this.cstyles.cardsContainer}>
                            <Text textSize={GlobalConstants.EXTRA_LARGE_FONT_SIZE}>Back</Text>
                            <ScrollView style={[this.cstyles.scrollViewSize, { height: Dimensions.get('window').height * 0.3 }]}>
                                {this.back.map((subres, index) => (
                                    <View style={this.cstyles.cardsRowContainer} key={'back' + index}>
                                        {this.cardBackName[index] !== undefined ? (
                                            <Text textSize={GlobalConstants.LARGE_FONT_SIZE} scaleText={false} color={'black'}>
                                                {this.cardBackName[index]}
                                            </Text>
                                        ) : null}
                                        <View style={[this.cstyles.cardsRow]}>
                                            {subres.map((card, index2) => (
                                                <View
                                                    style={
                                                        this.backdll === 'back_' + Math.floor((index + 1) / 2) &&
                                                        ((this.grab < 5 && this.grab === index2 + 1 && (index + 1) % 2 === 0) ||
                                                            (this.grab > 4 && this.grab - 4 === index2 + 1 && (index + 1) % 2 === 1))
                                                            ? this.cstyles.active
                                                            : this.cstyles.noactive
                                                    }
                                                    key={'back' + index + '_' + index2}
                                                >
                                                    {card}
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.row}>
                                <Button
                                    textSize={GlobalConstants.LARGE_FONT_SIZE}
                                    containerStyle={[modal.buttonStyle2PerRow, {height: 35 * GlobalVariables.scale}]}
                                    onPress={this.OnOK.bind(this)}
                                    text="Save"
                                />
                                <Button
                                    textSize={GlobalConstants.LARGE_FONT_SIZE}
                                    containerStyle={[modal.buttonStyle2PerRow, {height: 35 * GlobalVariables.scale}]}
                                    onPress={this.OnCancel.bind(this)}
                                    text="Cancel"
                                />
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default DlgKarti;

const stylesGenerator = () =>
    StyleSheet.create({
        cardPadding: { paddingHorizontal: 1 },
        active: { borderColor: 'green', borderWidth: 2 },
        noactive: { borderColor: '#DDDDDD', borderWidth: 2 },
        scrollViewSize: { width: 270, marginTop: 5, backgroundColor: 'white' },
        cardsRow: { flexDirection: 'row', width: 252, height: GlobalConstants.CARD_HEIGHT + 3 },
        cardsContainer: { width: 270, justifyContent: 'center', alignItems: 'center' },
        cardsRowContainer: { width: 270, justifyContent: 'center', alignItems: 'center', marginBottom: 3 },
    });
